import React from 'react';
import U from '@nanaio/util';
import Link from '../../com/ui/link';

export default class VendorUsers extends React.Component {
  state = { users: [] };

  async componentWillMount() {
    const query = { [`orgs.${global.id()}`]: { $exists: true } };
    const r = await U.api('post', 'users/search', { query });
    this.setState({ users: r });
  }

  render() {
    const t = this;
    if (!t.state.users.length) {
      return <div className="text-center">No Users Found</div>;
    }
    return (
      <div>
        {t.state.users.map(u => (
          <Link key={u.id} className="card card-body" to={`/users/${u.id}`}>
            <div>{u.profile.fullName}</div>
            <div>{u.profile.phone}</div>
            <div>{u.email}</div>
          </Link>
        ))}
      </div>
    );
  }
}
