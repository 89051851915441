import '../../../style/admin-modal.css';

import React from 'react';
import { Col,Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';

export default class _Modal extends React.Component {
  static contextTypes = { t: T.object };

  static propTypes = {
    cancelText: T.string,
    className: T.string,
    footerCom: T.any,
    hideCancel: T.bool,
    hideFooter: T.bool,
    hideHeader: T.bool,
    id: T.string,
    isOpen: T.bool,
    onClose: T.func,
    onRemove: T.any,
    onSubmit: T.func,
    saveText: T.string,
    submit: T.func,
    title: T.string,
    titleCom: T.any,
    centered: T.bool,
    scrollable: T.bool,
    wrapClassName: T.string,
    modalClassName: T.string,
    backdropClassName: T.string,
    contentClassName: T.string,
    headerClassName: T.string,
    bodyClassName: T.string,
    footerClassName: T.string,
  };

  constructor(p) {
    super(p);
    this.state = { loading: false, removing: false };
    this.removeButtonText = this.removeButtonText.bind(this);
    this.saveButtonText = this.saveButtonText.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  removeButtonText() {
    if (this.state.removing) {
      return <Icon name="loading" spin size={42} />;
    }
    return this.props.removeText || 'Remove';
  }

  saveButtonText() {
    if (this.state.loading) {
      return <Icon name="loading" spin size={42} />;
    }
    return this.props.saveText || 'Save';
  }

  async onRemove() {
    if (this.state.removing) {
      return;
    }
    this.setState({ removing: true });
    await this.props.onRemove();
    this.setState({ removing: false });
  }

  async onSave() {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });
    await (this.props.onSubmit || this.props.submit)();
    this.setState({ loading: false });
  }

  toggle() {
    if (this.props.onToggle) {
      this.props.onToggle();
    }
    if (this.context.t) {
      this.context.t.setState(state => {
        _.set(state, this.props.id || 'modal', false);
        _.set(state, 'error', '');
        if (this.props.onClose) {
          this.props.onClose();
        }
        return state;
      });
    }
  }

  render() {
    const { t } = this.context;
    let isOpen = _.get(t, `state.${this.props.id || 'modal'}`);
    if (!_.isUndefined(this.props.isOpen)) {
      isOpen = this.props.isOpen;
    }
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        className={this.props.className}
        centered={this.props.centered}
        scrollable={this.props.scrollable}
        wrapClassName={this.props.wrapClassName}
        modalClassName={this.props.modalClassName}
        backdropClassName={this.props.backdropClassName}
        contentClassName={this.props.contentClassName}
      >
        {this.props.hideX && <style>{'.modal-header [type=button] { display: none }'}</style>}
        {!this.props.hideHeader && (
          <ModalHeader className={this.props.headerClassName} toggle={this.toggle} charCode="X">
            {this.props.titleCom ? (
              this.props.titleCom
            ) : (
              <span className="header-title" style={{ fontSize: '16px' }}>
                {this.props.title}
              </span>
            )}
          </ModalHeader>
        )}
        <ModalBody
          className={`${this.props.bodyClassName} ${this.props.hideFooter ? ` no-footer` : ``}`}
        >
          {this.props.children}
        </ModalBody>
        {!this.props.hideFooter && (
          <ModalFooter className={this.props.footerClassName}>
            {this.props.footerCom ? (
              this.props.footerCom
            ) : (
              <Row className="w-100 no-gutters">
                {!this.props.hideSave && (
                  <Col>
                    <div className="btn-col w-100 save text-center" onClick={this.onSave}>
                      {this.saveButtonText()}
                    </div>
                  </Col>
                )}
                {!this.props.hideCancel && (
                  <Col>
                    <div
                      className="btn-col w-100 cancel text-center"
                      data-cy="cancel"
                      onClick={this.toggle}
                    >
                      {this.props.cancelText || 'Cancel'}
                    </div>
                  </Col>
                )}
                {this.props.onRemove && (
                  <Col>
                    <div className="btn-col w-100 remove text-center" onClick={this.onRemove}>
                      {this.removeButtonText()}
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}
