import React from 'react';
import { Card, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Avatar, Icon } from '@/components';

const styles = theme => ({
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class AvatarCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes, link, text, author, date } = this.props;

    return (
      <Card className={classes.card}>
        <div
          className="d-flex justify-content-between px-3 py-3"
          style={{ alignItems: 'flex-start' }}
        >
          <Avatar name={author} size="medium" />
          <div className="flex-1 px-3">
            <p>{text}</p>
            <small className="text-muted">
              {author} &bull; {date}
            </small>
          </div>

          {link && (
            <IconButton onClick={link} aria-expanded={this.state.expanded} aria-label="Edit">
              <Icon name="edit" size={25} className="text-grey-dark" />
            </IconButton>
          )}
        </div>
      </Card>
    );
  }
}

AvatarCard.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.func,
  text: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
};

export default withStyles(styles)(AvatarCard);
