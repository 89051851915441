import _ from 'lodash';
import moment from 'moment';

type FilterRule = {
  condition?: 'IS' | 'IS_NOT';
  values?: string[];
};

export const isValidString = (value?: string): boolean => Boolean(value && value.trim().length > 0);

export const isValidDate = (value?: string): boolean => Boolean(value && moment(value).isValid());

export const isValidNumber = (value?: string | number): boolean =>
  Boolean(value && parseInt(value.toString(10), 10) > 0);

export const validateName = (value?: string): string =>
  isValidString(value) ? '' : 'Please enter the incentive name';

export const validateStartDate = (value?: string): string =>
  isValidDate(value) ? '' : 'Please select a valid start date';

export const validateEndDate = (value?: string): string =>
  isValidDate(value) ? '' : 'Please select a valid end date';

export const validateEndDateIsAfterStartDate = (startDate?: string, endDate?: string): string =>
  moment(startDate).isSameOrBefore(endDate) ? '' : 'The end date must be after the start date';

export const validateFiltersConnector = (
  marketRule?: FilterRule,
  proRule?: FilterRule,
  filtersConnector?: string
): string => {
  const hasMarketRule = Boolean(marketRule?.condition) || Boolean(marketRule?.values?.length);
  const hasProRule = Boolean(proRule?.condition) || Boolean(proRule?.values?.length);
  const hasFiltersConnector = isValidString(filtersConnector);

  if (hasMarketRule && hasProRule && !hasFiltersConnector) {
    return 'Please select a condition';
  }

  return '';
};

export const validateFilterRuleCondition = (rule: FilterRule | undefined): string => {
  const hasCondition = Boolean(rule?.condition);
  const hasValues = Boolean(rule?.values?.length);

  if (hasValues && !hasCondition) {
    return 'Please select a condition';
  }

  return '';
};

export const validateFilterRuleValues = (
  rule: FilterRule | undefined,
  entity: 'market' | 'technician'
): string => {
  const hasCondition = Boolean(rule?.condition);
  const hasValues = Boolean(rule?.values?.length);

  if (hasCondition && !hasValues) {
    return `Please select at least one ${entity}`;
  }

  return '';
};

export const validateJobsCompleted = (value?: string): string =>
  isValidNumber(value) ? '' : 'Please select the number of jobs';

export const validatePayment = (value?: number): string =>
  isValidNumber(value) ? '' : 'Please enter a valid amount';
