import React, { ReactNode, useState } from 'react';
import { Task, U, VisitPro } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import nullthrows from 'nullthrows';
import { APIError, Avatar, Button, Icon, IconProps, Text } from '@/components';
import type { Props as BadgeProps } from '@/components/core/Badge';
import { useGetPro } from '@/hooks/api/pro';
import { apiOrigin } from '../../../../config/const';
import type { Variant } from './LastVisit';
import { onCall } from './util';

const PLACEHOLDER_PHOTO_URL = '../../../../img/customerPortal/profilePicPlaceholder.png';

const iconByVariant: Record<string, { color: BadgeProps['color']; name: IconProps['name'] }> = {
  awaitingProConfirm: { color: 'accent-orange', name: 'help' },
  awaitingCxConfirm: { color: 'danger', name: 'send' },
  confirmed: { color: 'success', name: 'check' },
  searchingForPro: { color: 'danger', name: 'circle' },
};

type Props = {
  className: string;
  task: Task;
  pro?: VisitPro;
  variant: Variant | 'closed';
};

export default function ProCard({ className, task, pro, variant }: Props): JSX.Element {
  const [twilioError, setTwilioError] = useState<ReactNode>();
  const proId = nullthrows(pro?.id);
  const proQuery = useGetPro(proId);

  const proName = proQuery.data?.user.fullName;
  const pictureUrl = !!proQuery.data?.user.pictureUrl
    ? U.normalizePictureUrl(apiOrigin, proQuery.data.user.pictureUrl)
    : PLACEHOLDER_PHOTO_URL;

  const handleCall = async () => {
    const error = await onCall(task);
    if (error) {
      setTwilioError(<APIError className="my-2" error={error} />);
    } else {
      setTwilioError(undefined);
    }
  };

  const badgeProps =
    variant === 'closed'
      ? undefined
      : {
          color: iconByVariant[variant].color,
          iconProps: { name: iconByVariant[variant].name },
        };

  const timezone = U.timezone(task.serviceAddress);

  return (
    <>
      {twilioError}
      <div className={className}>
        <Avatar
          badgeProps={badgeProps}
          badgePosition="topRight"
          className="m-1"
          name={proName}
          pictureUrl={pictureUrl}
          size="medium"
          variant="circle"
        />
        {variant === 'closed' ? (
          <div className="flex grow flex-col">
            <Text className="secondary">{proName}</Text>
            {pro?.onSiteTime && (
              <Text className="mt-1 text-grey-dark " type="helper">
                Last visit on {mt(pro?.onSiteTime).tz(timezone).format('ddd, MMM D, YYYY')}
              </Text>
            )}
          </div>
        ) : (
          <>
            <div className="ml-5 flex grow flex-col ">
              <Text className="text-grey-dark" type="label">
                Your Technician
              </Text>
              <Text className="mt-1">{proName}</Text>
            </div>
            <Button onClick={handleCall} size="large" variant="link">
              <div className="flex space-x-2">
                <Icon name="phone_outline" className="w-4 text-primaryCTA" />
                <Text className="text-primaryCTA" type="button">
                  Call
                </Text>
              </div>
            </Button>
          </>
        )}
      </div>
    </>
  );
}
