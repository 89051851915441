import React from 'react';
import PropTypes from 'prop-types';
import { supportArticleUrls } from '@/config/const';
import { openLink } from '../../utils';
import Button from '../core/Button';
import Text from '../core/Text';
import Switch from '../form/Switch';
import theme from '../theme';
import Overlay from './Overlay';

const styles = {
  container: {
    width: '100%',
    ...theme.shadowTop,
    zIndex: 3,
  },
};

export default function Footer({
  error,
  isLoading,
  isNotifyCx,
  mapIsOpen,
  onCancel,
  onSave,
  regionName,
  toggleNotifyCx,
}) {
  const openTutorial = () =>
    openLink({ newTab: true, url: supportArticleUrls.adminSchedulingHelp });

  return (
    <div
      className={`flex flex-row border border-grey-medium p-4 ${mapIsOpen ? 'relative' : ''}`}
      style={styles.container}
    >
      {mapIsOpen && <Overlay />}
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button className="ml-5" onClick={openTutorial} variant="outline">
        View Tutorial
      </Button>
      <Text className="ml-5" color="danger" type="headline-6">
        {error}
      </Text>
      <div className="flex-1" />
      <Text>{regionName}</Text>
      <Text className="ml-4" color="grey.dark" type="body-2">
        Notify Customer?
      </Text>
      <Switch className="ml-2" isActive={isNotifyCx} onClick={toggleNotifyCx} />
      <Button
        className="ml-5"
        cypressId="save-changes"
        disabled={isLoading}
        onClick={onSave}
        variant="primary"
      >
        Save Changes
      </Button>
    </div>
  );
}

Footer.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isNotifyCx: PropTypes.bool.isRequired,
  mapIsOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  regionName: PropTypes.string,
  toggleNotifyCx: PropTypes.func.isRequired,
};
