import * as Table from '@nanaio/table';
import { Range } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment-timezone';

export enum CompassDirection {
  NORTH = 'north',
  NORTH_EAST = 'northEast',
  EAST = 'east',
  SOUTH_EAST = 'southEast',
  SOUTH = 'south',
  SOUTH_WEST = 'southWest',
  WEST = 'west',
  NORTH_WEST = 'northWest',
}
export enum Move {
  ADD = 'add',
  MOVE = 'move',
  REMOVE = 'remove',
}

export type PartialReportTable = Omit<Table.ReportTable, 'timeRange'> & {
  timeRange?: Range<m.MomentInput>;
};

export enum View {
  REPORT = 'report',
  REQUEST = 'request',
  TABLE = 'table',
}

export const viewOptions = [View.REPORT, View.REQUEST, View.TABLE];
