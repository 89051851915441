import React from 'react';
import _ from 'lodash';

const emptyState = {
  cancelled: 0,
  claimed: 0,
  completed: 0,
  inProgress: 0,
  pending: 0,
  recall: 0,
  visitCompleted: 0,
};

export const getJobCount = ({ date, items }) => {
  const b2b = { ...emptyState };
  const b2c = { ...emptyState };
  const total = { ...emptyState };
  const bPros = {};
  const cPros = {};
  const tPros = {};
  items = items
    .filter(i => {
      if ((!i.preferred && i.type === 'pending') || i.type === 'suggested') {
        return;
      }
      if (i.date !== date) {
        return;
      }
      return !!i;
    })
    .map(i => {
      if (!['cancelled', 'pending', 'waitingOnSchedule'].includes(i.group)) {
        tPros[i.group] = true;
        if (_.get(i, 'job.customer.org.id')) {
          bPros[i.group] = true;
        } else {
          cPros[i.group] = true;
        }
      }
      return i;
    });
  _.uniqBy(items, 'job.id').map(i => {
    total[i.type] += 1;
    if (_.get(i, 'job.customer.org.id')) {
      b2b[i.type] += 1;
    } else {
      b2c[i.type] += 1;
    }
  });
  total.pros = _.compact(_.keys(tPros)).length;
  b2b.pros = _.compact(_.keys(bPros)).length;
  b2c.pros = _.compact(_.keys(cPros)).length;
  b2b.total = b2b.claimed + b2b.completed + b2b.inProgress;
  b2c.total = b2c.claimed + b2c.completed + b2c.inProgress;
  total.total = total.claimed + total.completed + total.inProgress;
  return { b2b, b2c, total };
};

export const JobCount = ({ count }) => {
  const { b2b, b2c, total } = count;
  return (
    <div className="header-2">
      <div className="container">
        <div className="row">
          <div className="col header-col red">
            <div>
              <div className="hcircle-icon bg-danger" />
              <p>{total.pending} Pending</p>
            </div>
            <p className="text-center">
              {b2b.pending} / {b2c.pending}
            </p>
          </div>
          <div className="col header-col black">
            <div>
              <div className="hcircle-icon bg-dark" />
              <p>{total.total} Visits</p>
            </div>
            <p className="text-center">
              {b2b.total} / {b2c.total}
            </p>
          </div>
          <div className="col header-col blue">
            <div>
              <div className="hcircle-icon bg-primary" />
              <p>{total.claimed} Claimed</p>
            </div>
            <p className="text-center">
              {b2b.claimed} / {b2c.claimed}
            </p>
          </div>
          <div className="col header-col purple">
            <div>
              <div className="hcircle-icon bg-purple" />
              <p>{total.inProgress} In Progress</p>
            </div>
            <p className="text-center">
              {b2b.inProgress} / {b2c.inProgress}
            </p>
          </div>
          <div className="col header-col green">
            <div>
              <div className="hcircle-icon bg-success" />
              <p>{total.completed} Complete</p>
            </div>
            <p className="text-center">
              {b2b.completed} / {b2c.completed}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col header-col green">
            <div>
              <div className="hcircle-icon bg-success" />
              <p>{total.visitCompleted} Followup Scheduled</p>
            </div>
            <p className="text-center">
              {b2b.visitCompleted} / {b2c.visitCompleted}
            </p>
          </div>
          <div className="col header-col cancelled">
            <div>
              <div className="hcircle-icon bg-light" />
              <p>{total.cancelled} Cancelled</p>
            </div>
            <p className="text-center">
              {b2b.cancelled} / {b2c.cancelled}
            </p>
          </div>
          <div className="col header-col orange">
            <div>
              <div className="hcircle-icon bg-warning" />
              <p>{total.recall} Recall</p>
            </div>
            <p className="text-center">
              {b2b.recall} / {b2c.recall}
            </p>
          </div>
          <div className="col header-col info">
            <div>
              <div className="hcircle-icon bg-info" />
              <p>{total.pros} Pros</p>
            </div>
            <p className="text-center">
              {b2b.pros} / {b2c.pros}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
