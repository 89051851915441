import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { connect } from 'react-redux';
import { T } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { canRescheduleVisit,getVisits } from './Appointments/util';
import ScheduleModal from './ScheduleModal';

// a popup for scheduling a recall
class ScheduleRecall extends React.Component {
  constructor(p) {
    super(p);

    const parts = _.get(p.job, 'parts.itemsArray', []);
    const { requestedVisits, comingUpVisits, pastVisits } = getVisits(p.workOrder);
    const lastComingUpVisit = comingUpVisits[comingUpVisits.length - 1];
    const lastPastVisit = pastVisits[pastVisits.length - 1];

    this.state = {
      modal: false,
      showFollowup:
        !m(T.lastVisitStart(p.job)).isAfter(m().startOf('day')) &&
        !_.find(parts, part => T.partPreOrderStatuses.includes(part.status)) &&
        !(
          requestedVisits.length > 0 ||
          Boolean(lastComingUpVisit && canRescheduleVisit(lastComingUpVisit)) ||
          Boolean(lastPastVisit && canRescheduleVisit(lastPastVisit))
        ),
    };
  }

  closeModal = () => {
    this.setState({ modal: false });
  };

  openModal = () => {
    this.setState({ modal: true, error: false });
  };

  render() {
    if (T.isClosed(this.props.job)) {
      return null;
    }

    return (
      <>
        <div>
          {this.props.button ? (
            // This prop is used by the Mark as Received button on the Shipments section.
            <div onClick={this.openModal}>{this.props.button}</div>
          ) : (
            this.state.showFollowup && (
              <button
                className="button-dark w-100  weight-600 mb-2 text-center"
                onClick={this.openModal}
              >
                Request Follow-Up
              </button>
            )
          )}
        </div>
        <ScheduleModal isOpen={this.state.modal} onClose={this.closeModal} variant="newVisit" />
      </>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const workOrder = s.workorders[job.metadata.workOrderId];

  return { job, workOrder };
})(ScheduleRecall);
