import React, { Fragment, memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@/components';

function Stepper({ steps, selectedIndex, className, onChange }) {
  const isLarge = steps.some(step => typeof step !== 'string');

  return (
    <div className={classnames('apply-stepper', className, { 'apply-stepper--large': isLarge })}>
      <div className="apply-stepper__steps">
        {steps.map((step, index) => {
          const title = isLarge ? step.title : step;
          const isSelected = index === selectedIndex;

          return (
            <Fragment key={title}>
              <div
                onClick={onChange ? () => onChange(index) : undefined}
                className={classnames('apply-stepper__indicator', {
                  'apply-stepper__indicator--selected': isSelected,
                })}
              >
                <div className="apply-stepper__indicator__content" />
                <div className="apply-stepper__indicator__glow" />
              </div>

              <div className="flex h-full flex-col items-start justify-center pt-1">
                {isLarge ? (
                  <>
                    <Text type="headline-5" className={classnames({ 'opacity-30': !isSelected })}>
                      {step.title}
                    </Text>

                    <Text
                      type="subtitle-1"
                      className={classnames('mt-2 opacity-80', { hidden: !isSelected })}
                    >
                      {step.subtitle}
                    </Text>
                  </>
                ) : (
                  <Text className={classnames({ 'font-semibold': isSelected })}>{step}</Text>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>

      <div className="apply-stepper__line" />
    </div>
  );
}

Stepper.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  selectedIndex: PropTypes.number.isRequired,
  steps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, subtitle: PropTypes.string })),
  ]).isRequired,
};

export default memo(Stepper);
