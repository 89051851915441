import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Task, TaskAuthorization, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Button, Icon, Text } from '@/components';
import AuthorizationModal from './AuthorizationModal';

const labelByRequirement = {
  [T.AuthorizationRequirement.RECALL]: 'Recall Job',
  [T.AuthorizationRequirement['2ND_ROUND_PARTS']]: 'Additional Parts Added',
  [T.AuthorizationRequirement['2ND_ROUND_CANT_REPAIR']]: 'Unit Marked As Unrepairable',
  [T.AuthorizationRequirement.CANT_REPAIR]: 'Unit Marked As Unrepairable',
};

const labelByResolution = {
  [T.AuthorizationResolution.CONTINUE]: 'Authorized to Continue Repair',
  [T.AuthorizationResolution.CLOSE]: 'Exchange or Cash Out Authorized',
};

type Props = {
  task: Task;
};

function Title({ authorization }: { authorization: TaskAuthorization }): JSX.Element {
  if (!authorization.resolution) {
    return (
      <div className="align-items-end flex-row ">
        <Text type="button" noWrap>
          <Icon color="accent.orange" name="warning" size={20} className="-mb-1 mr-1" />
          {labelByRequirement[authorization.requirement]}
        </Text>
        <Text className="ml-1 truncate" noWrap>
          <Icon name="arrow_right" size={18} className="-mb-1 mr-1" />
          Unresolved
        </Text>
      </div>
    );
  }

  return (
    <div className="align-items-end flex-row">
      <Text type="button" noWrap>
        <Icon color="success" name="check" size={20} className="-mb-1 mr-1" />
        {labelByRequirement[authorization.requirement]}
      </Text>
      <Text className="ml-1 truncate" noWrap>
        <Icon name="arrow_right" size={18} className="-mb-1 mr-1" />
        {labelByResolution[authorization.resolution]}
      </Text>
    </div>
  );
}

function Authorization({
  authorization,
  timezone,
}: {
  authorization: TaskAuthorization;
  timezone: string;
}): JSX.Element {
  return (
    <div>
      <Title authorization={authorization} />
      <div className="ml-6">
        <Text className="text-grey-dark">
          Created by:{' '}
          <a
            className="font-semibold text-primaryCTA"
            target="_blank"
            href={`/users/${authorization.createdBy.id}`}
            rel="noreferrer"
          >
            {authorization.createdBy.name}
          </a>
        </Text>
        <Text className="text-grey-dark">
          Created on:{' '}
          <Text tag="span">
            {mt(authorization.createTime).tz(timezone).format('M/D/YY h:mma (z)')}
          </Text>
        </Text>
        {!authorization.resolution && (
          <Text className="text-grey-dark">
            Call attempts: <Text tag="span">{authorization.callAttempts.length}</Text>
          </Text>
        )}
        {!!authorization.callReferenceNumber && (
          <Text className="text-grey-dark">
            Call reference number: <Text tag="span">{authorization.callReferenceNumber}</Text>
            <CopyToClipboard text={authorization.callReferenceNumber} onCopy={() => {}}>
              <Icon name="content_copy" size={16} color="primaryCTA" className="-mb-1 ml-1" />
            </CopyToClipboard>
          </Text>
        )}
        {!!authorization.caseNumber && (
          <Text className="text-grey-dark">
            Case number: <Text tag="span">{authorization.caseNumber}</Text>
            <CopyToClipboard text={authorization.caseNumber} onCopy={() => {}}>
              <Icon name="content_copy" size={16} color="primaryCTA" className="-mb-1 ml-1" />
            </CopyToClipboard>
          </Text>
        )}
        {!!authorization.note && (
          <Text className="text-grey-dark">
            Case notes: <Text tag="span">{authorization.note}</Text>
          </Text>
        )}
      </div>
    </div>
  );
}

export default function Authorizations({ task }: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const timezone = U.timezone(task.serviceAddress);

  return (
    <div>
      <div className="row">
        <div className="col-sm-6 align-center mb-3 flex-row justify-between">
          <Text type="label">Vendor Authorizations</Text>
          <Button size="small" onClick={() => setShowModal(true)}>
            Add New
          </Button>
        </div>
      </div>
      <div className="row">
        {task.authorizations?.map(authorization => (
          <div className="col-sm-6 mb-[10px]">
            <Authorization
              timezone={timezone}
              authorization={authorization}
              key={mt(authorization.createTime).valueOf()}
            />
          </div>
        ))}
      </div>

      {showModal && <AuthorizationModal task={task} onClose={() => setShowModal(false)} />}
    </div>
  );
}
