// eslint-disable-next-line simple-import-sort/imports
import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import U, { Address, County } from '@nanaio/util';
import invariant from 'invariant';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import TextInput, { Props as TextInputProps, Value } from './TextInput'; // eslint-disable-line import/no-cycle
import { InputProps, Type } from './Input'; // eslint-disable-line import/no-cycle

type IncompleteAddress = { formattedAddress: string; unit?: string };

export const isCompleteAddress = (
  value: Address | County | IncompleteAddress | undefined
): value is Address | County => {
  return !!value && 'gTimezone' in value;
};

export type Props = {
  disabled?: boolean;
  error?: string;
  id?: string;
  leftIcon?: TextInputProps['leftIcon'];
  noUnit?: boolean;
  onChange: (address?: Address | County | IncompleteAddress) => void;
  placeholder?: string;
  type?: (typeof types)[number];
  value?: Address | County | IncompleteAddress;
};

export const types = [Type.ADDRESS, Type.CITY, Type.COUNTY];

/** This function is used to coerce broad input props into narrow component-specific props. */
export const getProps = ({ onChange, type, value, ...rest }: InputProps): Props => ({
  onChange: nullthrows(onChange),
  type: type as (typeof types)[number],
  value: value as Address | County | IncompleteAddress,
  ...rest,
});

export default function AddressInput({
  disabled,
  error,
  id = Type.ADDRESS,
  leftIcon,
  noUnit,
  onChange,
  placeholder,
  type = Type.ADDRESS,
  value,
}: Props): ReactElement {
  const inputRef = useRef<HTMLInputElement>();
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();
  const unit = value && 'unit' in value ? value.unit : undefined;
  // if there is no value don't collect a unit because we won't be able to propagate it to
  // the parent and if a value is entered later the unit will be lost
  const unitDisabled = disabled || !isCompleteAddress(value);

  const autocompleteOptions = useMemo(() => {
    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address', 'geometry', 'name'],
    };
    if (type === Type.CITY) {
      return { ...options, types: ['(cities)'] };
    }
    if (type === Type.COUNTY) {
      return { ...options, types: ['(regions)'] };
    }
    return { ...options, types: ['address'] };
  }, [type]) as google.maps.places.AutocompleteOptions;

  const autocompleteCanInstantiate = !!(window.google && inputRef.current);

  autocompleteRef.current = useMemo(() => {
    if (autocompleteCanInstantiate) {
      return new window.google.maps.places.Autocomplete(
        nullthrows(inputRef.current),
        autocompleteOptions
      );
    }
  }, [autocompleteCanInstantiate, autocompleteOptions]);

  const autocompleteDidInstantiate = !!autocompleteRef.current;

  const handleSuggestionPicked = useCallback(() => {
    const place = nullthrows(autocompleteRef.current).getPlace();
    const address = U.addressFromPlace(
      place,
      type === Type.COUNTY // fall back to PST for counties
    );
    if (type === Type.COUNTY) {
      const county = _.pick(address, [
        'county',
        'formattedAddress',
        'geoCoordinates',
        'region',
        'gTimezone',
      ]);
      onChange(county as County);
    } else {
      if (unit) {
        address.unit = unit;
      }
      onChange(address);
    }
  }, [unit, onChange, type, autocompleteRef]);

  useEffect(() => {
    if (!autocompleteDidInstantiate) {
      return;
    }
    const listener = nullthrows(autocompleteRef.current).addListener(
      'place_changed',
      handleSuggestionPicked
    );
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [autocompleteDidInstantiate, handleSuggestionPicked]);

  const handleInputChange = useCallback(
    (address: Value) => {
      onChange({ formattedAddress: address as string, ...(unit ? { unit } : {}) });
    },
    [onChange, unit]
  );

  const handleUnitChange = useCallback(
    (unit: Value) => {
      invariant(isCompleteAddress(value), '');
      onChange({ ...value, unit: unit as string });
    },
    [value, onChange]
  );

  return (
    <div className="flex">
      <TextInput
        className="flex-1"
        disabled={disabled}
        error={error}
        id={`autocomplete-${id}`}
        inputRef={ref => {
          inputRef.current = ref as HTMLInputElement;
        }}
        onChange={handleInputChange}
        placeholder={placeholder}
        value={value?.formattedAddress}
        leftIcon={leftIcon}
      />
      {type === Type.ADDRESS && !noUnit && (
        <div className="ml-4" style={{ width: 60 }}>
          <TextInput
            disabled={unitDisabled}
            onChange={handleUnitChange}
            placeholder="Unit"
            value={unit}
          />
        </div>
      )}
    </div>
  );
}
