import React from 'react';
import { T, Task } from '@nanaio/util';
import type { BoostReason } from '@nanaio/util/dist/task';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import { Icon, Input, InputType, Modal, Text } from '@/components';

export type DraftBoost = Partial<Pick<NonNullable<Task['boost']>, 'pros' | 'reason' | 'notify'>>;

type Props = {
  boost: Task['boost'];
  draftBoost: DraftBoost;
  initialBoost: DraftBoost;
  onRemove: () => void;
  onSelectTargetAudience: () => void;
  onSelectReason: (reason: BoostReason) => void;
};

export default function BoostJob({
  boost,
  draftBoost,
  initialBoost,
  onRemove,
  onSelectTargetAudience,
  onSelectReason,
}: Props): JSX.Element {
  const formattedReasons = T.boostReasons.map(reason => _.capitalize(_.startCase(reason)));

  const canSave =
    !_.isEqual(initialBoost, draftBoost) && draftBoost.reason && draftBoost.pros?.length;

  return (
    <>
      <Modal.Header title={boost ? 'Edit Boost' : 'Boost Job'} />
      <Modal.Body className="p-4">
        {boost ? (
          <>
            <Text className="mt-4 text-center" type="headline-6">
              Edit Boost
            </Text>
            <Text className="mb-6 text-center" color="grey.dark">
              Last boosted on {moment(boost.lastModified).format('ddd, MMM D, YYYY, h:mma')} by{' '}
              {boost.boostedBy.name}
            </Text>
          </>
        ) : (
          <>
            <Text className="mt-4 text-center" type="headline-6">
              Boost Job
            </Text>
            <Text className="mb-6 text-center" color="grey.dark">
              This job will be promoted to eligible techs in the app.
            </Text>
          </>
        )}

        <Text className="mt-4" color="grey.dark">
          Audience
        </Text>

        <div className="mt-4 flex items-start border-b border-grey-medium pb-4">
          <Icon color="grey.dark" name="person_outline" />
          <div className="ml-4 flex-1">
            <Text type="button">Boost Job To</Text>
            <Text color="grey.dark">Select the techs you want to reach with this boost</Text>
          </div>
          <div>
            <button className="flex" onClick={onSelectTargetAudience} type="button">
              <Text type="label" color="primary">
                {draftBoost.pros?.length
                  ? `${pluralize('technician', draftBoost.pros.length, true)}`
                  : 'Select'}
              </Text>
              <Icon name="chevron_right" />
            </button>
          </div>
        </div>

        <div className="mt-4 flex items-start border-b border-grey-medium pb-4">
          <Icon color="grey.dark" name="message" />
          <div className="ml-4 flex-1">
            <Text type="button">Reason for Boosting</Text>
            <Text color="grey.dark">Select why these techs were chosen to see this boost</Text>
            <Input
              className="mt-1"
              id="reason"
              label=""
              placeholder="Select reason"
              type={InputType.SEARCH}
              options={formattedReasons}
              value={_.capitalize(_.startCase(draftBoost.reason || 'Select Reason'))}
              onChange={reason =>
                onSelectReason(_.toUpper(_.snakeCase(reason as string)) as BoostReason)
              }
            />
          </div>
        </div>

        <div className="mt-4 flex items-start">
          <Icon color="grey.dark" name="notifications_none" />
          <div className="ml-4 flex-1">
            <Text type="button">Notify Technicians</Text>
            <Text color="grey.dark">Alert targeted users on the boosted job</Text>
          </div>
          <div>
            <Input id="notify" label={draftBoost.notify ? 'Yes' : 'No'} type={InputType.SWITCH} />
          </div>
        </div>

        {boost && (
          <div className="mt-6 inline-flex items-start">
            <Icon className="mr-2 text-danger" name="delete_outline" />
            <Text color="danger" onClick={onRemove}>
              Remove Boost
            </Text>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        closeText="Cancel"
        disableSaveButton={!canSave}
        saveText={boost ? 'Update' : 'Boost Job'}
      />
    </>
  );
}
