import React from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import { Button, Modal, Text } from '../../core';

type Props = {
  columnIndex: number;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  error?: string;
  onChange: (index: number, value: unknown) => void;
  onClose: () => void;
  onReset: () => void;
  onSave: () => Promise<void>;
  query: Table.Query;
  values: Table.IdsAndValue[];
};

export default function EditCell({
  columnIndex,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  error,
  onChange,
  onClose,
  onReset,
  onSave,
  query,
  values,
}: Props): JSX.Element {
  const column = query.columns[columnIndex];

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={`Edit ${column.label}`} />
      <Modal.Body className="p-4">
        <Text color="danger" type="body-2">
          {error}
        </Text>
        {_.map(values, ({ ids }, i) =>
          column.editUi({
            column,
            key: i,
            label: ids.join(' > '),
            onChange: value => onChange(i, value),
            optionsMap:
              databaseIdToTableIdToColumnKeyToOptionIdToOption[column.databaseId]?.[column.table]?.[
                column.key
              ] || column.options,
            value: values[i].value,
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="ml-4" onClick={onReset} variant="secondary">
          Reset
        </Button>
        <Button className="ml-auto" onClick={onSave} variant="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
