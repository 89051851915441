import React, { useState } from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Badge, Button, Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import HoverPopup from '@/task/layout/TaskLayout/Header1/HoverPopup';
import Link from '../../../../com/ui/link';
import ChangeAddress from '../../../actions/ChangeAddress';
import ChangeCustomer from '../../../actions/ChangeCustomer';
import ChangeService from '../../../actions/ChangeService';

type Props = {
  taskId: string;
};

export default function CustomerInfo({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const escalationTitle = () => {
    if (task.metadata.escalationType === T.EscalationType.DAMAGE) {
      return 'Damage Escalation';
    }
    if (task.metadata.escalationType === T.EscalationType.REFUND) {
      return 'Refund Escalation';
    }

    const level = task.metadata.escalationLevel || '1';
    return `Level ${level} Escalation`;
  };

  const [changeCustomer, setChangeCustomer] = useState(false);

  const address = task.serviceAddress || {};
  const isEscalation = task.tags.includes(T.Tag.ESCALATE);

  return (
    <div className="flex-1">
      <Text type="subtitle-2">{task.shortId}</Text>
      <div className="mb-0 mt-0 flex items-center">
        <Text type="subtitle-2">
          {T.make(task) && `${T.make(task) || ''} `}
          {task.title}
        </Text>
        {/*= ==================== CUSTOMER INFO BUTTON ==================== */}

        <HoverPopup
          button={
            <Link to={`/users/${task.customer.user.id}`}>
              &nbsp;for {_.get(task, 'customer.user.fullName')}
            </Link>
          }
          menu={
            <div className="p-4">
              <div className="align-center flex">
                <Text type="button">{task.customer.user.fullName}</Text>
                <Badge color="grey-dark" className="ml-2">
                  Customer
                </Badge>
              </div>
              <hr className="my-1" />
              <div className="flex items-center">
                <Icon name="phone" color="#3E8FCF" size={14} />
                <Text className="ml-2 flex-1">{task.customer.user.phone}</Text>
              </div>

              <div className="flex items-center">
                <Icon name="email_outline" color="#3E8FCF" size={14} />
                <Text className="ml-2 max-w-[250px] break-words ">{task.customer.user.email}</Text>
                <Icon
                  className="ml-2 cursor-pointer"
                  name="edit"
                  onClick={() => setChangeCustomer(true)}
                  size={16}
                />
              </div>

              <br />
              <Button
                href={`/users/${task.customer.user.id}`}
                target="_blank"
                size="small"
                variant="primary-outline"
              >
                View Profile
              </Button>
            </div>
          }
        />

        {/*= ==================== END OF CUSTOMER INFO BUTTON ==================== */}

        {isEscalation && (
          <Badge color="danger" className="ml-2" style={{ fontSize: '16px' }}>
            {escalationTitle()}
          </Badge>
        )}
        <ChangeService taskId={taskId} />
      </div>
      <div className="flex items-center">
        <Text>
          {address.streetNumber} {address.route}, {address.locality} {address.region}{' '}
          {address.postalCode}
        </Text>
        <ChangeAddress taskId={taskId} />
      </div>
      {!!address.unit && <h4>Unit {address.unit}</h4>}
      {changeCustomer && (
        <ChangeCustomer onClose={() => setChangeCustomer(false)} taskId={taskId} />
      )}
    </div>
  );
}
