import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import T from 'prop-types';
import { updateTask } from '../../../com/task';
import { Modal } from '../../../com/ui/form';

class Delete extends React.Component {
  getChildContext = () => ({ t: this });

  static childContextTypes = { t: T.object };

  state = { modal: true };

  constructor(p) {
    super(p);
    this.submit = this.submit.bind(this);
  }

  async submit() {
    const visits = _.values(this.props.task.visits).length;
    const changes = [{ action: 'remove', path: `visits.${visits - 1}` }];
    const r = await updateTask(global.id(), changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.close();
  }

  render() {
    return (
      <Modal
        className="partModal sm"
        title="Delete Visit"
        submit={this.submit}
        onClose={this.props.close}
      >
        <div className="text-danger">{this.state.error}</div>
        <div>Are you sure?</div>
      </Modal>
    );
  }
}

export default connect(s => ({
  task: s.tasks[global.id()],
}))(Delete);
