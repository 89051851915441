import 'react-sliding-pane/dist/react-sliding-pane.css';
import './map.css';

import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import T from 'prop-types';
import store from 'store2';
import { Icon } from '@/components';
import { Date, Search } from '../../com/ui/form';
import Loader from '../../com/ui/loader';
import { statusIconLegend,statusIcons } from './com/config';
import { filterGroups,filterItems } from './com/filter';
import { getJobCount, JobCount } from './com/jobCount';
import loadItems from './com/loadItems';
import { getMarkers } from './com/mapMarkers';
import TimelineUI from './com/timeline';

class Schedule extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    const today = mt().tz('America/Los_Angeles').startOf('day').valueOf();

    let hideMapType = store.get('schedule.hideMapType');
    if (!_.isObject(hideMapType)) {
      hideMapType = {};
    }
    this.state = {
      date: mt().tz('America/Los_Angeles').startOf('day').valueOf(),
      hideMapType,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      queryStart: m(today).add(-1, 'w').valueOf(),
      proFilter: [],
      serviceFilter: [],
      showMap: true,
      vendorFilter: [],
      view: store.get('admin.schedule.view') || 'jobs',
      visibleDate: today,
    };
    this.load({ date: this.state.date }, true);
    document.title = _.startCase(global.location.pathname.split('/')[1]);
    U.api('post', 'orgs/search', { query: { status: { $ne: 'inactive' } } }, true);
  }

  componentWillReceiveProps(p) {
    if (this.props.pathname !== p.pathname) {
      this.openView(p.pathname === '/map' ? 'jobs' : 'map');
    }
  }

  componentWillUnmount() {
    clearInterval(this.mapInterval);
    clearInterval(this.loadInterval);
    clearTimeout(this.loadTimeout);
  }

  componentWillUpdate(p, s) {
    const now = this.state.items && this.state.groups;
    const next = s.items && s.groups;
    if (!now && next) {
      this.initMap(next);
    }
  }

  filter = ({ proFilter, serviceFilter, vendorFilter }) => {
    proFilter = proFilter || this.state.proFilter;
    serviceFilter = serviceFilter || this.state.serviceFilter;
    vendorFilter = vendorFilter || this.state.vendorFilter;
    const { serviceMap } = this.props;
    const activeItemMap = filterItems({
      ...this.state,
      ...this.props,
      proFilter,
      serviceFilter,
      vendorFilter,
    });
    const activeGroupMap = filterGroups({
      ...this.state,
      ...this.props,
      proFilter,
      serviceFilter,
      vendorFilter,
    });
    const markers = getMarkers({
      ...this.state,
      proFilter,
      serviceFilter,
      vendorFilter,
      serviceMap,
      me: this.props.me,
    });
    this.setState(s => {
      _.set(s, 'map.markers', markers);
      s.activeItemMap = activeItemMap;
      s.activeGroupMap = activeGroupMap;
      return s;
    });
  };

  getHeaderTimeslots = () => {
    if (!this.state.timeslots || !this.state.taskItems) {
      return [];
    }
    const proId = _.get(this.state.groups, `${this.state.hoverGroupIndex}.id`);
    if (!proId) {
      return [];
    }
    if (['cancelled', 'pendingConfirmation'].includes(proId)) {
      const timeslots = this.state.taskItems.filter(
        i => i.group === proId && i.date === this.state.visibleDate
      );
      return _.sortBy(timeslots, 'startTime');
    }
    const timeslots = _.filter(this.state.timeslots[proId], t => {
      const date = mt(t.startTime).tz('America/Los_Angeles').startOf('day').valueOf();
      return date === this.state.visibleDate;
    });
    return timeslots
      .map(t => {
        t.depart = _.get(t, 'eta.departureTime');
        t.arrivalTime = m(_.get(t, 'eta.arrivalTime') || t.startTime).valueOf();
        t.finishTime = m(_.get(t, 'eta.finishTime') || t.endTime).valueOf();
        return t;
      })
      .sort((a, b) => {
        if (a.arrivalTime < b.arrivalTime) {
          return -1;
        }
        if (a.arrivalTime > b.arrivalTime) {
          return 1;
        }
        return a.id < b.id ? -1 : 1;
      });
  };

  initMap = state => {
    state = state || this.state;
    if (state.view !== 'map') {
      return;
    }
    if (state.items && state.groups) {
      clearInterval(this.mapInterval);
      let mapElement = _.get(state, 'map.mapElement');
      // center map on the Bay Area if no regions specified
      let center = { lat: 37.6124295, lng: -122.2239053 };
      if (
        _.get(this.props.region, 'geoCoordinates.lat') &&
        _.get(this.props.region, 'geoCoordinates.lng')
      ) {
        center = this.props.region.geoCoordinates;
      }
      if (!mapElement) {
        mapElement = new global.google.maps.Map(document.getElementById('map'), {
          center,
          zoom: 10,
        });
      }
      const trafficLayer = new global.google.maps.TrafficLayer();
      trafficLayer.setMap(mapElement);

      const infoWindow = new global.google.maps.InfoWindow({
        disableAutoPan: true,
        map: mapElement,
      });
      const map = { mapElement, infoWindow, markers: _.get(state.map, 'markers', []) };
      map.markers = getMarkers({ ...state, map, me: this.props.me });
      this.setState({ map });
    }
    this.loadInterval = setInterval(() => this.load({ date: this.state.date }), 1000 * 60 * 5);
    Modal.setAppElement(this.el);
  };

  load = async ({ date }) => {
    if (document.hidden) {
      return;
    }
    if (this.state.date !== date) {
      await this.loadAvailability({ date, groups: this.state.groups });
    }
    this.setState(s => {
      s.activeItemMap = filterItems({ ...this.state, ...this.props, date });
      s.activeGroupMap = filterGroups({ ...this.state, ...this.props, date });
      s.count = getJobCount({ ...this.state, date });
      return s;
    });
    this.setState({ date });
    if (!this.state.loading) {
      this.setState({ loading: true });
      const { abandoned, calls, groups, items, groupMap, loadRange, partVendors } = await loadItems(
        { date }
      );
      await this.loadAvailability({ date, groups });
      const count = getJobCount({ date, items });
      const activeItemMap = filterItems({ ...this.state, items, ...this.props });
      const activeGroupMap = filterGroups({ ...this.state, items, groups, ...this.props });
      const nextState = {
        loading: false,
        groups,
        items,
        groupMap,
        loadRange,
        count,
        calls,
        activeItemMap,
        activeGroupMap,
        abandoned,
        partVendors,
      };
      this.setState(nextState);
      this.initMap();
      clearTimeout(this.loadTimeout);
      this.loadTimeout = setTimeout(
        () => {
          this.load({ date, timeReload: true });
        },
        1000 * 60 * 5
      );
    }
  };

  loadAvailability = async ({ date }) => {
    const availability = await U.api('post', 'pros/availability', { date: U.toPSTDate(date) });
    this.setState({ availability });
    this.initMap();
  };

  onDateChange = date => {
    this.setState({ hideTimeline: true });
    setTimeout(() => this.setState(s => ({ ...s, hideTimeline: false })), 200);
    const pstDate = mt(date)
      .tz('America/Los_Angeles')
      .month(m(date).month())
      .date(m(date).date())
      .startOf('day')
      .valueOf();
    this.load({ date: pstDate });
  };

  onItemClick = itemId => {
    const item = this.state.items.find(i => i.id === itemId);
    if (item) {
      window.open(`/tasks/${item.job.id}`, '_blank').focus();
    }
  };

  openView = (view, itemId = '') => {
    store.set('admin.schedule.view', view);
    const nextState = { ...this.state, itemId, map: '', view };
    this.setState(nextState);
    if (view === 'map') {
      this.initMap(nextState);
    }
  };

  toggleMapType = type => {
    const hideMapType = _.merge({}, this.state.hideMapType);
    hideMapType[type] = !hideMapType[type];
    const markers = getMarkers({ ...this.state, hideMapType, me: this.props.me });
    this.setState(s => {
      s.hideMapType = hideMapType;
      s = _.set(s, 'map.markers', markers);
      return s;
    });
    store.set('schedule.hideMapType', hideMapType);
  };

  render() {
    if (!this.state.items || !this.state.groups) {
      return <Loader />;
    }
    return (
      <div className="schedule">
        <div className="version2">
          <div className="bg-dark p-3">
            <div style={{ maxWidth: '1440px' }} className="row mx-auto">
              <div className="col map-title">
                <h2>
                  <strong>{m(this.state.date).format('ddd, MMM D')}</strong>
                </h2>
              </div>
              <div className="col">
                <Date id="date" label="" onChange={this.onDateChange} />
              </div>
              <div className="col">
                <Search
                  id="proFilter"
                  hint="Pro Filter"
                  className="mb-0"
                  onChange={proFilter => this.filter({ proFilter })}
                  label=""
                  multi
                  options={this.state.groups}
                />
              </div>
              <div className="col">
                <Search
                  id="vendorFilter"
                  hint="Vendor Filter"
                  className="mb-0"
                  onChange={vendorFilter => this.filter({ vendorFilter })}
                  label=""
                  multi
                  options={this.props.orgs}
                />
              </div>
              <div className="col">
                <Search
                  id="serviceFilter"
                  hint="Service Filter"
                  className="mb-0"
                  onChange={serviceFilter => this.filter({ serviceFilter })}
                  label=""
                  multi
                  options={this.props.services}
                />
              </div>
              <div className="col">
                {this.state.view === 'jobs' && (
                  <div className="btn btn-secondary" onClick={() => this.openView('map')}>
                    View Map
                  </div>
                )}
                {this.state.view === 'map' && (
                  <div className="btn btn-secondary" onClick={() => this.openView('jobs')}>
                    View Jobs
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="top-header">
            <JobCount count={this.state.count} />
          </div>
          <div
            style={{
              position: 'relative',
              ...(this.state.view !== 'map' ? { height: 0, width: 0 } : { flex: 1 }),
            }}
          >
            <div id="map" />
            <div className={`map-legend${this.state.view !== 'map' ? ' d-none' : ''}`}>
              {statusIconLegend.map((v, n) => (
                <div
                  key={n}
                  className="d-flex align-items-center pointer mb-3"
                  onClick={() => this.toggleMapType(v)}
                >
                  <div className="w-12 p-3">
                    {this.state.hideMapType[v] ? (
                      <Icon name="check_box_outline_blank" />
                    ) : (
                      <Icon name="check_box_outline" />
                    )}
                  </div>
                  <div className="map-legend-icon">
                    <img
                      height="25px"
                      alt=""
                      key={v.key || v}
                      src={statusIcons[v.key || v]}
                      className="mr-3"
                    />
                  </div>
                  {_.startCase(v.label || v)}
                </div>
              ))}
            </div>
          </div>
          {this.state.view === 'jobs' && (
            <TimelineUI
              availability={this.state.availability}
              date={this.state.date}
              groups={this.state.groups}
              items={this.state.items}
              groupMap={this.state.groupMap}
              openView={this.openView}
              vendorFilter={this.state.vendorFilter}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(s => {
  const me = s.users[s.me.userId];
  const roles = U.user.roles(s);
  const orgs = _.sortBy(
    _.values(s.orgs).filter(v => v.status !== 'inactive'),
    'name'
  );
  const { pathname } = s.router.location;
  const serviceMap = s.services;
  const services = _.sortBy(_.values(s.services), 'name');
  const region = _.first(s.me.regions);
  return { me, pathname, region, roles, services, serviceMap, orgs };
})(Schedule);
