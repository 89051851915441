import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { useLegacySelector } from '@/hooks';
import OpportunityNotifications from './com/OpportunityNotifications';
import QuickActions from './com/QuickActions';
import Tabs from './com/tabs';
import Visits from './com/visits';

type Props = {
  taskId: string;
};

export default function Details({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  const rawPictures = task.metadata.taskProblemQuestions?.pictures || {};
  const pictures = [];
  _.mapValues(rawPictures, (v, id) => pictures.push({ url: v.url, id }));

  const { customersupport } = roles;

  return (
    <div>
      <QuickActions taskId={taskId} />
      {customersupport && task.status === T.Status.PENDING_CONFIRMATION && (
        <OpportunityNotifications task={task} />
      )}
      <Visits job={task} />

      <Tabs taskId={taskId} />
    </div>
  );
}
