import React from 'react';
import { T, Task } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import mt, { MomentInput } from 'moment-timezone';
import { Icon, Text } from '@/components';
import type { Variant } from './LastVisit';

type Props = {
  className?: string;
  phase2StartTime: MomentInput;
  phase3StartTime: MomentInput;
  task: Task;
  variant: Variant;
};

export default function CountdownBanner({
  className,
  phase2StartTime,
  phase3StartTime,
  task,
  variant,
}: Props): JSX.Element {
  const pendingTime = T.getLastStatusStartTime(T.Status.PENDING_CONFIRMATION, task);
  const lastUpdateTime = variant === 'searchingForProPhase1' ? pendingTime : phase2StartTime;
  const nextUpdateTime = variant === 'searchingForProPhase1' ? phase2StartTime : phase3StartTime;

  const lastUpdateText = mt(lastUpdateTime).fromNow();
  const nextUpdateText = mt(nextUpdateTime).fromNow(true);

  return (
    <div className={classNames('flex items-center p-2', className)}>
      {variant === 'searchingForProPhase3' ? (
        <>
          <Icon name="warning_amber" className="mr-2 text-white" size={16} />
          <Text type="helper" className="mt-0.5 text-white">
            <b>Action Needed</b>
          </Text>
        </>
      ) : (
        <>
          <Icon name="schedule" className="mr-2 text-white" size={16} />
          <Text type="helper" className="mt-0.5 text-white">
            <b>Last Updated: {lastUpdateText}</b> (next update in {nextUpdateText})
          </Text>
        </>
      )}
    </div>
  );
}
