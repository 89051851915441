import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Column from './Column';

const styles = {
  container: { display: 'grid', overflow: 'auto' },
  grid: { display: 'flex', flexWrap: 'nowrap' },
};

export default function Grid({
  className = '',
  getColumnWidth,
  getRowHeight,
  getRowKey,
  rows,
  stickyColumnCount = 0,
  stickyRowCount = 0,
  style = {},
}) {
  if (!rows.length) {
    return null;
  }

  return (
    <div className={className} style={{ ...styles.container, ...style }}>
      <div style={styles.grid}>
        {_.times(rows[0].length, columnIndex => (
          <Column
            {...{
              columnIndex,
              getColumnWidth,
              getRowHeight,
              getRowKey,
              key: columnIndex,
              rows,
              stickyColumnCount,
              stickyRowCount,
            }}
          />
        ))}
      </div>
    </div>
  );
}

Grid.propTypes = {
  className: PropTypes.string,
  getColumnWidth: PropTypes.func.isRequired,
  getRowHeight: PropTypes.func.isRequired,
  getRowKey: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  stickyColumnCount: PropTypes.number,
  stickyRowCount: PropTypes.number,
  style: U.stylePropType,
};
