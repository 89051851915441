import { WorkOrder } from '@nanaio/util';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

type FtcBF = NonNullable<WorkOrder['ftcBF']>;
export type Step = NonNullable<FtcBF['steps']>[0];

export const useUpdateFtcBFSteps = (id: string): UseMutationResult<WorkOrder> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: newFtcBF =>
      AXIOS_INSTANCE.put<WorkOrder>(`/api/workOrders/${id}/ftcBF`, newFtcBF).then(
        response => response.data
      ),
    onSuccess: data => {
      queryClient.setQueryData(['workOrder', id], data);
    },
  });
};
