import React from 'react';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ProAvatar from '@/task/details/com/ProAvatar';
import { openLink } from '../../../utils';
import Avatar from '../../core/Avatar';
import Copyable from '../../core/Copyable';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import Cell from '../Cell';
import { getUnassignItems, proPropType } from '../util';

export default function Pro({
  leftAlignDropdown,
  mapIsOpen,
  mapProId,
  pro = { user: {} },
  setMapProId,
  setVisit,
  visit,
  visitPro,
  workOrder,
}) {
  let VisitPro = { ...visitPro };

  if (!VisitPro && pro.id) {
    VisitPro = visit.pros.find(visitPro => visitPro.id === pro.id);
  }

  const changeRole = (task, role) => {
    const proIndex = _.findIndex(visit.pros, pro => pro.id === VisitPro.id);
    let taskIndex = _.findIndex(visit.pros[proIndex].tasks, proTask => proTask.id === task.id);
    const newVisit = _.cloneDeep(visit);
    if (taskIndex === -1) {
      newVisit.pros[proIndex].tasks.push({ id: task.id });
      taskIndex = newVisit.pros[proIndex].tasks.length - 1;
    }
    newVisit.pros[proIndex].tasks[taskIndex].role = role;
    setVisit(newVisit);
  };

  const changeStatus = status => {
    const proIndex = _.findIndex(visit.pros, pro => pro.id === VisitPro.id);
    const newVisit = _.cloneDeep(visit);
    newVisit.pros[proIndex].status = status;
    setVisit(newVisit);
  };

  let iconName;
  let badgeColor;
  if (!pro.ineligibleReason) {
    if (VisitPro.status === W.VisitProStatus.CANCELLED) {
      iconName = 'close';
      badgeColor = 'grey-dark';
    } else if ([W.VisitProStatus.CONFIRMED, W.VisitProStatus.LEFT_SITE].includes(VisitPro.status)) {
      iconName = 'check';
      badgeColor = 'success';
    } else if (VisitPro.status === W.VisitProStatus.EN_ROUTE) {
      iconName = 'truck_fast_outline';
      badgeColor = 'primaryCTA';
    } else if (VisitPro.status === W.VisitProStatus.NO_SHOW) {
      iconName = 'priority_high';
      badgeColor = 'danger';
    } else if (VisitPro.status === W.VisitProStatus.ON_SITE) {
      iconName = 'home_outline';
      badgeColor = 'primaryCTA';
    } else if (VisitPro.status === W.VisitProStatus.PENDING) {
      iconName = 'help';
      badgeColor = 'icons-orange';
    }
  }

  const badgeProps = iconName ? { iconProps: { name: iconName }, color: badgeColor } : undefined;

  const name = pro.user.fullName || W.proName({ proId: pro.id, workOrder });

  const openProfile = () => openLink({ url: `/pros/${pro.id}`, newTab: true });

  const statusItems = VisitPro
    ? W.visitProStatusOptions.map(status => ({
        title: _.startCase(status),
        disabled: VisitPro.status === status,
        onClick: () => changeStatus(status),
      }))
    : [];

  const unassignItems = getUnassignItems({ setVisit, visit, visitPro: VisitPro, workOrder });
  const avatarBgClass = `bg-${badgeColor}`;

  return (
    <Cell
      className="flex flex-row border border-t-0 border-grey-medium bg-white p-4"
      hasOverlay={mapIsOpen && mapProId !== pro.id}
      isFixed
      onMouseEnter={() => setMapProId && setMapProId(pro.id)}
    >
      <Dropdown
        hideChevron
        items={statusItems}
        leftAlign
        trigger={
          pro.id ? (
            <ProAvatar proId={pro.id} variant="circle" size="xlarge" badgeProps={badgeProps} />
          ) : (
            <Avatar
              {...{
                className: avatarBgClass,
                badgeProps,
                name: pro.user.fullName || W.proName({ proId: pro.id, workOrder }),
              }}
              variant="circle"
            />
          )
        }
      />
      <div className="flex-1">
        <Text color="primaryCTA" noWrap onClick={openProfile} type="subtitle-1">
          {name}
        </Text>
        {pro.ineligibleReason ? (
          <Text color="danger" type="body-2">
            {pro.ineligibleReason}
          </Text>
        ) : (
          workOrder.tasks.map(task => {
            const role = _.startCase(
              _.get(
                _.get(VisitPro, 'tasks', []).find(proTask => proTask.id === task.id),
                'role'
              )
            );
            return (
              <div className="flex flex-row" key={task.id}>
                <Text className="mr-2" color="grey.dark" type="body-2">
                  {T.appliance(task)}:
                </Text>
                {pro.role === U.pro.Role.MENTEE ? (
                  <Text color="font.dark" type="body-2">
                    Mentee
                  </Text>
                ) : (
                  <Dropdown
                    items={_.compact(W.visitProRoleOrder)
                      .filter(role => role !== U.pro.Role.MENTEE)
                      .map(role => ({
                        disabled: !W.visitProCanAddRole({ role, taskId: task.id, visit }),
                        onClick: () => changeRole(task, role),
                        title: _.startCase(role),
                      }))}
                    leftAlign={leftAlignDropdown}
                    trigger={
                      <Text type="body-2" color={role ? 'font.dark' : 'danger'}>
                        {role || 'Select role'}
                      </Text>
                    }
                  />
                )}
              </div>
            );
          })
        )}
      </div>
      <div className="flex flex-col justify-between">
        {!pro.ineligibleReason && (
          <Dropdown
            className="mb-4"
            hideChevron
            items={unassignItems}
            title="Unassign Technician:"
            trigger={<Icon color="danger" name="close" />}
          />
        )}
        <Copyable text={pro.user.phone}>
          <Icon color="primaryCTA" name="phone" />
        </Copyable>
      </div>
    </Cell>
  );
}

Pro.propTypes = {
  leftAlignDropdown: PropTypes.bool,
  mapIsOpen: PropTypes.bool.isRequired,
  mapProId: PropTypes.string,
  pro: proPropType,
  setMapProId: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  visitPro: W.visitProPropType,
  workOrder: W.propType.isRequired,
};
