import React from 'react';
import _ from 'lodash';
import type { StepProps as Props } from './ConfirmationFlowModal';
import StepModalHeader from './StepModalHeader';
import TermsFormStep from './TermsFormStep';

const componentBySubStepNum = {
  1: TermsFormStep,
};

export default function TermsStep({
  currentStep,
  task,
  subStepNum,
  onCloseStep,
  onNextSubStep,
  onPreviousSubStep,
}: Props): JSX.Element {
  const title = 'Accept Terms';

  const Component =
    componentBySubStepNum[subStepNum as unknown as keyof typeof componentBySubStepNum];

  return (
    <>
      <StepModalHeader {...{ onPreviousSubStep, title, subStepNum, onCloseStep, currentStep }} />
      <Component {...{ onNextSubStep, task }} />
    </>
  );
}
