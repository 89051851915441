import { T, Task, U, Visit,W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';

export const onCall = async (task: Task): Promise<string | undefined> => {
  const userId = task.customer.user.id;
  const proUserId = T.proUserId(task) || '';
  let phone = _.get(task, `metadata.proxy.${proUserId}.${userId}.phone`) as string | undefined;
  if (!phone) {
    const changes = [{ action: 'add', path: 'metadata.proxy' }];
    const response = await U.api<Task>('put', `tasks/${task.id}`, changes);
    if ('errMsg' in response) {
      return response.errMsg;
    }
    phone = _.get(response, `metadata.proxy.${proUserId}.${userId}.phone`) as string | undefined;
    if (phone) {
      U.redux.set(`tasks.${response.id}`, response);
    } else {
      return;
    }
  }
  document.location.href = `tel:${phone}`;
};

export const getVisits = (
  workOrder: WorkOrder
): Record<'requestedVisits' | 'comingUpVisits' | 'pastVisits', Visit[]> => {
  // Confirmed implicitly includes CONFIRMED, CANCELLED and NO_SHOW customer visit status
  // Confirmed implicitly includes CONFIRMED, CANCELLED, EN_ROUTE, ON_SITE, NO_SHOW, and LEFT_SITE pro visit status
  const [requestedVisits, confirmedVisits] = _.partition(
    _.cloneDeep(workOrder.visits),
    visit =>
      visit.cx.status === W.VisitCustomerStatus.PENDING ||
      W.getPrimaryPro(visit)?.status === W.VisitProStatus.PENDING
  );

  // Past implicitly includes CANCELLED and NO_SHOW customer visit status
  // Past implicitly includes CANCELLED, NO_SHOW, and LEFT_SITE pro visit status
  const [comingUpVisits, pastVisits] = _.partition(_.cloneDeep(confirmedVisits), visit => {
    const primaryPro = W.getPrimaryPro(visit);
    return (
      mt(visit.slot.start).isAfter(mt()) &&
      visit.cx.status === W.VisitCustomerStatus.CONFIRMED &&
      (!primaryPro?.status ||
        primaryPro.status === W.VisitProStatus.CONFIRMED ||
        primaryPro.status === W.VisitProStatus.EN_ROUTE ||
        primaryPro.status === W.VisitProStatus.ON_SITE)
    );
  });

  return { requestedVisits, comingUpVisits, pastVisits };
};

export const canRescheduleVisit = (workOrder: WorkOrder, visit: Visit): boolean => {
  const isLastVisit = workOrder.visits && visit && workOrder.visits.length - 1 === visit.id;

  return isLastVisit && visit.slot && mt(visit.slot.start).isAfter() && !T.isClosed(workOrder);
};
