import React from 'react';
import { Text } from '@/components';
import { apiOrigin, isProdBuild, isProdDb, isTestBuild, showEnvToast } from '../config/const';

export default function EnvToast(): JSX.Element {
  if (!showEnvToast || isProdBuild || isTestBuild) {
    return <></>;
  }
  return (
    <div className="fixed bottom-2 right-2 z-[10000] rounded-sm bg-danger p-2">
      <Text color="white" type="helper">
        <b>Prod DB:</b> {isProdDb ? 'Yes' : 'No'}
      </Text>
      <Text color="white" className="mt-2" type="helper">
        <b>API Origin:</b> {apiOrigin}
      </Text>
    </div>
  );
}
