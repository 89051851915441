import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

const Money = (p, { t }) => {
  let value = _.get(t.state, p.id, '');
  value = value === '' ? value : value / 100;
  return (
    <Group {...p} prefix="$">
      <input
        autoFocus={p.autoFocus}
        className="form-control"
        type="number"
        value={value}
        onChange={e => {
          let val = e.target.value === '' ? undefined : +e.target.value * 100;
          if (val) {
            val = _.round(val);
          }
          onChange(t, p.id, val);
          if (p.onChange) {
            p.onChange(val);
          }
        }}
        placeholder={p.hint || _.get(p.options, '[0]')}
        disabled={p.disabled}
        onKeyPress={e =>
          p.enter && (e.keyCode || e.which === 13) && p.enter(_.get(t.state, p.id, ''))
        }
      />
    </Group>
  );
};

Money.contextTypes = { t: T.object.isRequired };

Money.propTypes = {
  id: T.string.isRequired,
  label: T.string,
  type: T.string,
  value: T.number,
  enter: T.func,
  req: T.bool,
};

export default Money;
