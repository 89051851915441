import React, { useCallback, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Alert, APIError, Button, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import Input, { Type } from '@/components/form/Input';
import { useLazyLegacyAPI } from '@/hooks';

type Props = {
  task: Task;
  onClose: () => void;
};

export default function ChangeArrivalInstructions({ task, onClose }: Props): JSX.Element {
  const origForm = _.pick(task, ['serviceAddress']);
  const [form, setForm] = useState(_.cloneDeep(origForm));
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => <APIError className="mb-10" error={error} />,
    save: true,
    method: 'put',
  });

  const handleSubmit = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }
    if (changesMade) {
      const data = await updateTask([{ path: 'serviceAddress', value: form.serviceAddress }]);
      if (!data) {
        return;
      }
    }
    onClose();
  }, [changesMade, form.serviceAddress, submitRef, updateTask, onClose]);

  return (
    <Modal isOpen onClose={onClose} hideOnBackdropClick={false} height={700}>
      <Modal.Header title="Update Arrival Instructions" />
      <Modal.Body className="p-4">
        <Form debounce={50} onChange={setForm} value={form} ref={submitRef}>
          <Alert className="mt-2" variant="info">
            Customers can also change their arrival instructions online on the Profile page.
          </Alert>
          {taskToUpdate.error}
          <Text type="headline-6" className="mt-6">
            Arrival Instructions
          </Text>
          <Text className="mt-2 text-grey-dark">
            We’ll share this with the assigned technician 24 hours before the visit begins.
          </Text>
          <Input
            className="mt-6"
            id="serviceAddress.arrivalInstructions"
            label=""
            type={Type.TEXT}
            placeholder="What is the gate code? Where should the technician park?"
            multiline
          />
          <Text type="helper" className="mt-1 text-grey-dark">
            This will apply to all of the customer’s jobs
          </Text>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={handleSubmit} submit disabled={!changesMade}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
