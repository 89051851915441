import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import U, { WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import { Loader } from '@/components';
import { useLegacySelector } from '@/hooks';

export default function WorkOrderLayout(): JSX.Element {
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrderId = global.id() || '';
  const workOrder = workOrders[workOrderId];
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.get(workOrder, 'tasks.0.id')) {
      dispatch(push(`/tasks/${workOrder.tasks[0].id}`));
    } else {
      void U.api<WorkOrder>('get', `workOrders/${workOrderId}`, ['save']).then(workOrder => {
        if (workOrder && !('errMsg' in workOrder) && _.get(workOrder, 'tasks.0.id')) {
          dispatch(push(`/tasks/${workOrder.tasks[0].id}`));
        }
      });
    }
  }, [dispatch, workOrder, workOrderId]);

  return <Loader isLoading fullscreen />;
}
