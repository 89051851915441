import React, { ReactNode, Suspense } from 'react';

const widthBySize = {
  small: 20,
  large: 36,
};

type Props = {
  className?: string;
  cypressId?: string;
  isLoading?: boolean;
  size?: 'small' | 'large';
  variant?: 'light' | 'dark';
  fullscreen?: boolean;
};

export default function Loader({
  className,
  cypressId,
  isLoading,
  size = 'small',
  variant = 'dark',
  fullscreen = false,
}: Props): JSX.Element | null {
  if (!isLoading) {
    return null;
  }

  const width = widthBySize[size];

  const LoaderWidget = (
    <div className={className} data-cy={cypressId}>
      <img
        data-cy="loader"
        src={`/img/circular_progress_indicator-${variant}.gif`}
        alt=""
        style={{ width }}
        className="mx-auto"
      />
    </div>
  );

  if (fullscreen) {
    return (
      <div className="absolute inset-0 z-[99999] flex animate-fadeIn items-center justify-center bg-white bg-opacity-90">
        {LoaderWidget}
      </div>
    );
  }
  return <>{LoaderWidget}</>;
}

type SuspenseProps = Props & {
  children: ReactNode;
};

export function SuspenseLoader({ children, ...props }: SuspenseProps): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Suspense fallback={<Loader isLoading {...props} />}>{children}</Suspense>;
}
