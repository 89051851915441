import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Alert, Text } from '@/components';
import { useLegacySelector } from '@/hooks';

export default function UnifiedBookingFlowAlert(): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const taskId = nullthrows(global.id());
  const task = tasks[taskId];

  const enteredWOPSTime =
    T.getLastStatusStartTime(T.Status.WAITING_ON_PRE_SCREEN, task) || task.createTime;

  return (
    <Alert className="mb-5" variant="warning">
      <Text type="page-title" className="mb-2 flex">
        Unified Booking Flow
      </Text>

      <Text type="body-2" color="grey.dark">
        Before moving forward, the customer needs to confirm their repair details by{' '}
        {m(enteredWOPSTime).add(48, 'hours').format('ddd, MMM D, h:mma')}. If there is no response
        by the specified date, the status will auto-transition to <b>Cancel Requested</b>. Please
        reach out to confirm with the customer before cancelling the job. If they’d like to proceed,
        change the job status to <b>Waiting On Pre Screen</b>, so they continue to confirm online.
      </Text>
    </Alert>
  );
}
