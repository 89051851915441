import React, { ReactNode, useCallback, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import { workOrderEvent } from '@/com/analytics';
import { APIError } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';
import ApplianceModelManualStep from './ApplianceModelManualStep';
import ApplianceModelPhotoStep from './ApplianceModelPhotoStep';
import type { SubStepProps as Props } from './ConfirmationFlowModal';

export type ModelStepProps = Props & {
  onToggleEntryMethod: () => void;
  onCantFind: () => Promise<void>;
  updateError: ReactNode;
};

export default function ApplianceModelStep({ task, onNextSubStep }: Props): JSX.Element {
  const curModel = task.metadata.taskQuestions?.Model;
  const curSerial = task.metadata.taskQuestions?.['Serial #'];
  const [manualEntry, setManualEntry] = useState(!!(curModel || curSerial));

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={
          <>
            Unable to save changes to model number
            <br />
            {error}
          </>
        }
      />
    ),
    save: true,
    method: 'put',
  });

  const handleCantFind = useCallback(async () => {
    const changes = [
      { path: 'metadata.taskQuestions.Model', value: curModel || 'Unknown Model Num' },
      { path: 'metadata.taskQuestions.Serial #', value: curSerial || 'Unknown Serial Num' },
    ];
    const data = await updateTask(changes);
    if (!data) {
      return;
    }
    const eventInfo = {
      variant: 'ftc',
      step: 'modelAndSerial',
      changes,
      couldNotFind: true,
      photoUploaded: false,
    };

    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);

    await onNextSubStep();
  }, [curModel, curSerial, onNextSubStep, updateTask]);

  const onToggleEntryMethod = useCallback(() => {
    setManualEntry(!manualEntry);
  }, [manualEntry]);

  const Component = manualEntry ? ApplianceModelManualStep : ApplianceModelPhotoStep;

  return (
    <Component
      updateError={taskToUpdate.error}
      {...{
        onNextSubStep,
        onToggleEntryMethod,
        onCantFind: handleCantFind,
        task,
      }}
    />
  );
}
