import './index.css';

import React, { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { ConnectedRouter, push } from 'react-router-redux';
import { useDeepCompareEffect, usePreviousDistinct } from '@nanaio/hooks';
import { State, T, U, User, W } from '@nanaio/util';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import store2 from 'store2';
import { useGetFeatures } from '@/hooks/api/features';
import { identify, loggedOutEvent, start } from './com/analytics';
import api from './com/api';
import EnvToast from './com/EnvToast';
import ExitViewAs from './com/ExitViewAs';
import store, { history } from './com/store';
import { saveIncludeRoles } from './com/user';
import { getParams } from './com/util';
import { Loader } from './components';
import { analyticsEnabled, gaTrackingNumber, isProdBuild } from './config/const';
import Routes from './config/routes';
import publicRoutes from './config/routes/public';
import { useLegacySelector } from './hooks';
import SiteDown from './pages/public/siteDown';
import { idFromURL } from './utils';

const queryClient = new QueryClient();

if (isProdBuild) {
  console.log = () => null; // eslint-disable-line no-console
}

if (analyticsEnabled) {
  start();
}

//@ts-expect-error global.api definition in util is different
global.api = api;
global.id = idFromURL;
global.m = m;
global.mt = mt;
// TODO remove all references to global.s and replace with redux
// eslint-disable-next-line @typescript-eslint/unbound-method
global.s = store.getState;
global.store = store;
global.store2 = store2;
global.T = T;
global.U = U;
global.W = W;

type MeResponse = {
  user: User;
};

type PublicRoutes = {
  [key: string]: { body: JSX.Element; nav: JSX.Element };
};

export default function Page(): JSX.Element {
  const dispatch = useDispatch();
  const meRaw = useLegacySelector(state => state.me);
  const users = useLegacySelector(state => state.users);
  const meUser = users[meRaw.userId];
  const regions = useLegacySelector(state => state.regions);
  const myRegions = U.region.regionsFromReduxMe({ me: meRaw, regions } as State);
  const me = {
    ...meRaw,
    regions: myRegions,
  };
  const router = useLegacySelector(state => state.router);
  const path = router.location?.pathname || '';
  const { idPath } = router;
  const roles = U.user.roles();
  const isRolesArrayEmpty = _.isEmpty(roles);
  const isSupport = roles[U.user.Role.CUSTOMERSUPPORT];
  const route = (router.location?.pathname || '').split('/').join(' ');

  const services = useLegacySelector(state => state.services);
  const brands = useLegacySelector(state => state.brands);

  const [loadedMe, setLoadedMe] = useState(false);
  const [loadedViewAsUser, setLoadedViewAsUser] = useState(false);
  const [siteIsDown, setSiteIsDown] = useState(false);

  const featureQuery = useGetFeatures();

  const featuresNotLoaded = _.isEmpty(featureQuery.data);
  const regionsNotLoaded = _.isEmpty(regions);
  const servicesNotLoaded = _.isEmpty(services);
  const brandsNotLoaded = _.isEmpty(brands);

  useEffect(() => {
    // site down
    void fetch('/status.txt').then(async r => {
      const text = await r.text();
      if (_.includes(text, 'down')) {
        setSiteIsDown(true);
      }
    });
  }, []);

  useEffect(() => {
    // if a user attempts to access a task without being logged in, redirect to login and then bring back to the task
    if (idPath && path === `/tasks/${idPath}` && !me.token) {
      const redirect = `/login?r=${encodeURIComponent(path)}`;
      const eventInfo = {
        action: 'accessDenied',
        error: 'User tried to access task without being logged in.',
        identifierType: '',
        redirect: redirect,
        usingPassword: false,
      };
      loggedOutEvent().track('v1_userLogin', eventInfo);
      dispatch(push(redirect));
    }
  }, [dispatch, idPath, me.token, path]);

  useEffect(() => {
    // parse URL for city search if this page is a public landing page with a city name in the URL
    if (isRolesArrayEmpty && !['/', '/city', '/services'].includes(path)) {
      // derive which page will be rendered from the url, copied from ./config/routes/index.js loadRoutes fn
      const newPath = path
        .replace(/[a-f\d]{24}/g, 'id')
        .toLowerCase()
        .split('/')
        .filter(v => v);
      let activePath = '';
      const routes = publicRoutes as PublicRoutes;
      for (let i = 0; i < newPath.length; i++) {
        const pathString = _.reduce(newPath.slice(0, i + 1), (a, b) => `${a}/${b}`) || '';
        if (routes[pathString]) {
          activePath = pathString;
        }
      }

      // load cities for the home page, city page, and service pages
      if (['', 'city'].includes(activePath) || _.startsWith(activePath, 'services')) {
        const parts = _.compact(global.location.pathname.split('/')).map(part => _.startCase(part));
        const query = { locality: { $in: parts } };
        void U.api('post', 'cities/search', { query }).then(r => {
          const city = _.get(r, '0.locality') as string | undefined;
          const county = _.get(r, '0.county') as string | undefined;
          U.redux.set(['form.city', city, 'form.county', county, 'phone', gaTrackingNumber]);
        });
      }
    }
  }, [path, isRolesArrayEmpty]);

  useEffect(() => {
    if (isSupport) {
      if (regionsNotLoaded) {
        void U.api('post', 'regions/search', { limit: -1 }, ['save']);
      }
    }
  }, [isSupport, regionsNotLoaded]);

  useEffect(() => {
    if (servicesNotLoaded) {
      void U.service.loadServices();
    }
  }, [servicesNotLoaded]);

  useEffect(() => {
    if (brandsNotLoaded) {
      void U.service.loadBrands();
    }
  }, [brandsNotLoaded]);

  useEffect(() => {
    const { r: redirectPath, t: token } = getParams();
    if (token) {
      void U.api('post', 'auth/local?includeRoles=true', { type: 'local', token }).then(
        response => {
          if (_.get(response, 'token')) {
            saveIncludeRoles(response);
          }
          const eventInfo = {
            action: 'login',
            error: '',
            identifierType: 'token',
            redirect: '',
            usingPassword: false,
          };
          if (redirectPath && typeof redirectPath === 'string') {
            dispatch(push(redirectPath));
            eventInfo.redirect = redirectPath;
          }
          loggedOutEvent().track('v1_userLogin', eventInfo);
        }
      );
    }
  }, [dispatch]);

  const previousMe = usePreviousDistinct(me);
  useDeepCompareEffect(() => {
    store2.set('me', me);
    if (previousMe && !_.isEqual(me.regions || [], previousMe.regions || [])) {
      global.location.reload();
    }
  }, [me, previousMe]);

  const loadMe = useCallback(async () => {
    if (!loadedMe) {
      const response = await U.api<MeResponse>('get', 'users/me?includeRoles=true');
      if (!response || 'errMsg' in response) {
        return;
      } else {
        U.redux.set([`users.${response.user.id}`, response.user]);
        setLoadedMe(true);
      }
    }
  }, [loadedMe]);

  useDeepCompareEffect(() => {
    if (me.userId && me.token && !meUser) {
      void loadMe();
    }
  }, [loadMe, me, meUser]);

  const loadViewAsUser = useCallback(async () => {
    if (!loadedViewAsUser) {
      const response = await U.api<User>('get', `users/${me.userId}`);
      if (!response || 'errMsg' in response) {
        return;
      } else {
        U.redux.set([`users.${response.id}`, response, 'me.roles', response.roles]);
        setLoadedViewAsUser(true);
      }
    }
  }, [loadedViewAsUser, me.userId]);

  useDeepCompareEffect(() => {
    if (U.user.isViewAsUser(me) && !loadedViewAsUser) {
      void loadViewAsUser();
    }
  }, [loadViewAsUser, me]);

  useDeepCompareEffect(() => {
    if (featureQuery.isSuccess) {
      identify(me, featureQuery.data);
    }
  }, [featureQuery, me]);

  useEffect(() => {
    if (route && loadedMe && !featuresNotLoaded) {
      loggedOutEvent().navigate(route);
    }
  }, [featuresNotLoaded, loadedMe, route]);

  if (siteIsDown) {
    return <SiteDown />;
  }
  const isSupportButRegionsNotLoaded = isSupport && regionsNotLoaded;
  const isLoggedInButNotLoaded = me.userId && me.token && !meUser && !loadedMe;
  const isViewAsButNotLoaded = U.user.isViewAsUser(me) && !loadedViewAsUser;
  if (
    brandsNotLoaded ||
    isLoggedInButNotLoaded ||
    isViewAsButNotLoaded ||
    featuresNotLoaded ||
    servicesNotLoaded ||
    isSupportButRegionsNotLoaded
  ) {
    return <Loader fullscreen size="large" isLoading />;
  }

  return (
    <div>
      <Routes />
      <ExitViewAs />
      <EnvToast />
    </div>
  );
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history} store={store}>
      <QueryClientProvider client={queryClient}>
        <Page />
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>
);
