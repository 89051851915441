import React, { CSSProperties } from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import Cell from './Cell';
import Header from './Header';

type Props = {
  cell: Table.Cell;
  columnHeaderDepth: number;
  columnIndex: number;
  columnTableVectors: Table.TableVector[];
  isActive: boolean;
  onClick: () => void;
  query: Table.Query;
  rowHeaderDepth: number;
  rowIndex: number;
  rowTableVectors: Table.TableVector[];
  showPoints: boolean;
  style: CSSProperties;
  tableVectorIdToIsCollapsed: Record<string, boolean>;
  vectors: Table.Vector[];
};

export default function CellIndex({
  cell,
  columnHeaderDepth,
  columnIndex,
  columnTableVectors,
  isActive,
  onClick,
  query,
  rowHeaderDepth,
  rowIndex,
  rowTableVectors,
  showPoints,
  style,
  tableVectorIdToIsCollapsed,
  vectors,
}: Props): JSX.Element {
  let cellUI;
  const isTopLeft = columnIndex < rowHeaderDepth && rowIndex < columnHeaderDepth;

  if (isTopLeft) {
    cellUI = <Cell {...{ cell, isActive, isTopLeft, pointCount: cell.pointCount, rowIndex }} />;
  } else if (columnIndex < rowHeaderDepth || rowIndex < columnHeaderDepth) {
    cellUI = (
      <Header
        {...{
          cell,
          columnHeaderDepth,
          columnIndex,
          columnTableVectors,
          isActive,
          onClick,
          query,
          rowHeaderDepth,
          rowIndex,
          rowTableVectors,
          showPoints,
          style,
          tableVectorIdToIsCollapsed,
          vectors,
        }}
      />
    );
  } else {
    cellUI = <Cell {...{ cell, isActive, rowIndex, showPoints }} />;
  }

  return (
    <div onClick={isTopLeft ? undefined : onClick} style={style}>
      {cellUI}
    </div>
  );
}
