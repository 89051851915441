import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';

class Draft extends React.Component {
  static propTypes = { state: T.object.isRequired };

  constructor(p) {
    super(p);
    this.createDraft = this.createDraft.bind(this);
  }

  async createDraft() {
    const firstName = _.get(this.props.state, 'profile.firstName', '');
    const lastName = _.get(this.props.state, 'profile.lastName', '');
    let title = 'Job Draft: ';
    if (firstName || lastName) {
      title += `${firstName} ${lastName}, `;
    }
    const form = {
      ..._.omit(this.props.state, 'error'),
      email: _.trim(this.props.state.email),
    };
    const request = {
      owner: this.props.ticketOwner,
      tags: ['jobDraft'],
      title: `${title} ${m().format('MMM D, h:mm A')}`,
      desc: JSON.stringify(form),
      refType: 'taskDraft',
    };
    const r = await U.api('post', 'issues', request);
    if (r.id) {
      window.open(`/tickets/${r.id}`).focus();
      const changes = [
        {
          action: 'replace',
          path: 'refId',
          value: r.id,
        },
      ];
      U.api('put', `issues/${r.id}`, changes);
    }
  }

  render() {
    return (
      <div>
        <CopyToClipboard text={this.props.url}>
          <div className="common-Button" onClick={this.createDraft}>
            Create Job Draft Ticket
          </div>
        </CopyToClipboard>
      </div>
    );
  }
}

export default connect(s => {
  const ticketOwner = {
    id: s.me.userId,
    name: _.get(s.users[s.me.userId], 'profile.fullName'),
  };
  return { ticketOwner };
})(Draft);
