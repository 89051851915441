import React from 'react';
import { Icon, InteractiveTooltip, Text } from '@/components';

export default function RefundTooltip(): JSX.Element {
  return (
    <InteractiveTooltip
      title="How do refunds work?"
      description={
        <div>
          <Text className="text-grey-dark">Refunds will be reviewed and processed manually. </Text>
          <Text className="mt-4 text-grey-dark">
            Our claims department will submit your claim for authorization. Your home warranty
            provider will then verify the refund claim and determine the amount that can be approved
            for refund.
          </Text>
          <Text className="mt-4 text-grey-dark">
            If your claim is approved, your refund will be processed to the original payment method
            within 1-2 weeks.
          </Text>
        </div>
      }
      placement="bottom"
      trigger={
        <div className="flex space-x-1">
          <Icon name="info_outline" className="text-primaryCTA" size={16} />
          <Text className="text-primaryCTA" type="button">
            How do refunds work?
          </Text>
        </div>
      }
    />
  );
}
