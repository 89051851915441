import React, { useState } from 'react';
import U, { User } from '@nanaio/util';
import { Input, Modal, Text } from '@/components';
import { supportEmail } from '@/config/const';

type Props = {
  onClose: () => void;
  user: User;
};

export default function LinkGmail({ onClose, user }: Props): JSX.Element {
  const [mailbox, setMailbox] = useState<number | undefined>(user?.metadata?.gmailSupportNumber);
  const [url, setUrl] = useState<string>();

  const handleChange = (value: unknown) => {
    const stringValue = value as string;
    setUrl(stringValue);
    const mailbox = (stringValue || '').match(/\/u\/(\d+)/)?.[1];
    if (mailbox) {
      setMailbox(Number(mailbox));
    }
  };

  const handleSave = () => {
    void U.api(
      'put',
      `users/${user.id}`,
      [{ path: 'metadata.gmailSupportNumber', value: mailbox }],
      ['save']
    );
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} onSave={handleSave}>
      <Modal.Header title={`Specify ${supportEmail} Mailbox Number`} />
      <Modal.Body className="p-4">
        <Text>
          Login to {supportEmail}, the url should look similar to{' '}
          <strong>https://mail.google.com/mail/u/0/#inbox</strong>. Paste it into the below textbox.
        </Text>
        <Input className="mt-4" label="Gmail Mailbox Url" onChange={handleChange} value={url} />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
