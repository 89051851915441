import React from 'react';
import { Avatar, IconProps, Text } from '@/components';
import { companyName } from '@/config/const';

// md breakpoint from tailwind config
const MD = 768;

type Data = {
  title: string;
  text: string;
  icon: IconProps['name'];
};

const data: Data[] = [
  {
    title: 'We Are Local',
    text: `Technicians on ${companyName} are around the corner when you need them.`,
    icon: 'navigation_variant_outline',
  },
  {
    title: 'Free Diagnosis',
    text: 'Diagnosis is free if you proceed with the repair.',
    icon: 'check_circle_outline',
  },
  {
    title: '$25 Off Labor',
    text: 'Limited Offer: Get $25 off Labor for repairs when you book online.',
    icon: 'tag_outline',
  },
  {
    title: 'Same Day Service',
    text: 'A certified, licensed technician will come out on the same day.',
    icon: 'calendar',
  },
  {
    title: 'Free 1-Year Warranty',
    text: 'Your parts are covered for 365 days — So you don’t pay a dime!',
    icon: 'shield_outline',
  },
  {
    title: 'Flexible Scheduling',
    text: 'We work around your schedule, with 3 convenient arrival windows daily - 7 days a week.',
    icon: 'clock_outline',
  },
  {
    title: '100% Satisfaction',
    text: 'We have one mission: making you happy. We will try our best to make it right.',
    icon: 'star_outline',
  },
  {
    title: 'Timely Support',
    text: 'Access fast, quality support when you need it!',
    icon: 'help_outline',
  },
  {
    title: 'Licensed, Certified, Background Checked Technicians',
    text: `Technicians on ${companyName} are extensively vetted in the onboarding process.`,
    icon: 'account_outline',
  },
];

export default (): JSX.Element => (
  <section className="px-12 py-32">
    <div className="text-center">
      <div className="d-flex justify-content-center align-items-center">
        <Text color="primary" className="uppercase" type="label">
          All-In-One Place
        </Text>
      </div>
      <Text
        className="text-normal mb-4 mt-8"
        type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}
      >
        Get the Best Service at the Best Price
      </Text>
    </div>

    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
      {data.map((t, n) => (
        <div className="flex-1" key={n}>
          <div className="flex flex-col items-center py-6 text-center md:flex-row md:text-left">
            <Avatar
              className="!mr-0 mb-2 self-center bg-[#F4F9FC] md:mb-0 md:mr-4"
              iconProps={{ name: t.icon, color: 'primary' }}
              variant="circle"
              size="large"
            />
            <div className="flex-1">
              <Text type="button">{t.title}</Text>
              <Text color="grey.dark">{t.text}</Text>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);
