import React, { useEffect, useMemo, useRef,useState } from 'react';
import { usePreviousDistinct } from '@nanaio/hooks';
import {
  ErrorResponse,
  ExternalPartSearchResult,
  Part,
  PartStatus,
  PartVendor,
  Recommendation,
  T,
  Task,
  U,
} from '@nanaio/util';
import { DeliveryMethod } from '@nanaio/util/dist/part';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Alert, Button, Icon, Input, InputType, Modal, Switch, Text, Tooltip } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import { useLegacySelector } from '@/hooks';
import { useGetNextPartStatuses } from '@/hooks/api/nextPartStatuses';
import { openLink } from '../../../utils';
import Inventory from '../inventory';
import PartSearch from '../partSearch';
import AuthorizationInfo from './AuthorizationInfo';

function Title({ number, title }: { number: string; title: string }): JSX.Element {
  return (
    <div className="mb-4 flex">
      <div className="rounded-circle mr-4 flex h-6 w-6 justify-center border border-black">
        <Text className="mt-[0.5px] inline-block" type="subtitle-2">
          {number}
        </Text>
      </div>
      <Text type="subtitle-2">{title}</Text>
    </div>
  );
}

type PartUpdateResponse = {
  task: Task;
};

type Props = {
  onClose: () => void;
  partId: string;
};

export default function EditPart({ onClose, partId }: Props): JSX.Element {
  const { me, org, task, workOrder } = useLegacySelector(state => {
    const { me } = state;
    const taskId = global.id() || '';
    const task = state.tasks[taskId];
    const orgId = T.orgId(task) || '';
    const org = state.orgs[orgId];
    const workOrder = state.workorders[task.metadata.workOrderId];

    return { me, org, task, workOrder };
  });

  const getFormInitialValues = (): Part => {
    const initialValues = _.cloneDeep(task.parts?.items?.[partId]) || {
      approvalStatus: T.PartApprovalStatus.NOT_REQUIRED,
      associatedDiagnosesIndexes: [] as string[],
      deliveryMethod: DeliveryMethod.SHIPPED,
      owner: { type: 'corporate', id: 'nana', name: 'Nana' },
      status: T.PartStatus.NEEDS_TO_BE_ORDERED,
      taxable: true,
      unit: { value: 1 },
    };

    initialValues.associatedDiagnosesIndexes = (initialValues.associatedDiagnosesIndexes || []).map(
      index => String(index)
    );

    if (!initialValues.deliveryMethod) {
      initialValues.deliveryMethod = DeliveryMethod.SHIPPED;
    }

    return initialValues as Part;
  };

  const [error, setError] = useState<string>();
  const [deliveryMethodError, setDeliveryMethodError] = useState<string>();
  const [isNotRecommended, setIsNotRecommended] = useState<boolean>();
  const [notifyCx, setNotifyCx] = useState(false);
  const [notifyPro, setNotifyPro] = useState(false);
  const [part, setPart] = useState<Part>(getFormInitialValues());
  const submitRef = React.createRef<Ref>();
  // FIXME @syntaxbliss We are not forcing VMs to associate parts and diagnoses for the time being...
  // const [associatedDiagnosesError, setAssociatedDiagnosesError] = useState('');
  const [partSearchIsOpen, setPartSearchIsOpen] = useState(false);
  const [recallTask, setRecallTask] = useState<Task>();
  const [vendors, setVendors] = useState<{ id: string; name: string }[]>([]);

  const startingPartStatus = useRef<PartStatus>(part.status);

  const recallId = task.recall?.pastId;
  useEffect(() => {
    void U.api<PartVendor[]>('post', 'partVendors/search').then(vendors => {
      if ('errMsg' in vendors) {
        return setError('Failed to load vendors');
      }
      const options = vendors.map(vendor => ({ id: vendor.name, name: _.startCase(vendor.name) }));
      setVendors(_.sortBy(options, 'name'));
    });
    if (recallId) {
      void U.api<Task>('get', `tasks/${recallId}`).then(recallTask => {
        if ('errMsg' in recallTask) {
          return setError('Failed to load recall task');
        }
        setRecallTask(recallTask);
      });
    }
  }, [recallId]);

  const nextPartStatusesQuery = useGetNextPartStatuses(startingPartStatus.current);

  const nextStatusKeys = useMemo(
    () => nextPartStatusesQuery.data?.nextStatuses || [startingPartStatus.current],
    [nextPartStatusesQuery.data?.nextStatuses]
  );

  const statusOptions = useMemo(() => {
    const allowedStatuses = [...nextStatusKeys, startingPartStatus.current];
    const options = T.partStatusOptions.filter(option => allowedStatuses.includes(option.id));
    return options;
  }, [nextStatusKeys, startingPartStatus]);

  const hasPartApprovalRole = me.roles.includes(U.user.Role.PART_APPROVAL);
  useEffect(() => {
    const approvalReason = T.getApprovalRequiredReasons({
      isManualPrice: false,
      org,
      part,
      partNumberKnown: true,
      recallTask,
      task,
      workOrder,
    });

    const checkIfPartRequiresMasterTechnicianApproval =
      !hasPartApprovalRole &&
      approvalReason.length &&
      !_.isEqual(part.approvalReason, approvalReason) &&
      !T.partApprovalRequired(part) &&
      !task.tags.includes(T.Tag.PARTS_APPROVED_BY_MT);

    if (checkIfPartRequiresMasterTechnicianApproval) {
      setPart({
        ...part,
        approvalReason: _.map(approvalReason, 'id'),
        approvalStatus: T.PartApprovalStatus.REQUESTED,
      });
    }
  }, [hasPartApprovalRole, org, part, recallTask, task, workOrder]);

  const hasRequiredFields = (part: Part | undefined): boolean =>
    !!part &&
    !!part.costPrice &&
    !!part.desc &&
    !!part.owner?.id &&
    !!part.partNumber &&
    !!part.retailPrice &&
    !!part.unit?.value;
  const previousPart = usePreviousDistinct(part);
  const shouldGetRecommendation = !hasRequiredFields(previousPart) && hasRequiredFields(part);
  useEffect(() => {
    if (shouldGetRecommendation) {
      const newPart = _.cloneDeep(part);

      if (newPart.associatedDiagnosesIndexes) {
        newPart.associatedDiagnosesIndexes = newPart.associatedDiagnosesIndexes.map(index =>
          Number(index)
        );
      }
      void U.api<Recommendation[]>('post', `tasks/${task.id}/parts/recommendation`, newPart).then(
        response => {
          // Undefined responses from this endpoint are not an error.
          if (response !== undefined && 'errMsg' in response) {
            return setError('Failed to get recommendations');
          }
          const isNotRecommended = _.some(
            response,
            recommendation =>
              recommendation.partId === 'undefined' && recommendation.class === 'not_approved'
          );
          setIsNotRecommended(isNotRecommended);
        }
      );
    }
  }, [part, shouldGetRecommendation, task.id]);

  const canRemove = partId && T.partCanRemove({ me, part });

  const diagnosesOptions = useMemo(() => {
    const diagnoses = task.metadata.taskQuestions?.diagnosis || [];

    return diagnoses.map((diagnosis, index) => ({
      id: String(index),
      name: T.diagnosisCodeToString(diagnosis, false),
    }));
  }, [task.metadata.taskQuestions?.diagnosis]);

  const getNotify = () => {
    let notify = notifyCx && notifyPro ? 'both' : 'none';
    if (notifyCx !== notifyPro) {
      notify = notifyCx ? 'customer' : 'pro';
    }
    return notify;
  };

  const onApprovalStatusChange = (approvalStatus?: string) => {
    if (approvalStatus === T.PartApprovalStatus.REQUESTED) {
      setPart({ ...part, approvalNotes: undefined, approvalReason: [], approvalStatus });
    }
  };

  const openAddVendor = () => openLink({ newTab: true, url: 'reports/part-vendors' });

  const openUrl = () => part.url && openLink({ newTab: true, url: part.url });

  const savePartUpdate = (response: PartUpdateResponse | ErrorResponse) => {
    if ('errMsg' in response) {
      setError(response.errMsg);
      return;
    }

    void U.redux.updateTask(response.task);
    onClose();
  };

  const remove = async () => {
    const response = await U.api<PartUpdateResponse>(
      'delete',
      `tasks/${task.id}/parts/${partId}?notify=${getNotify()}`
    );
    savePartUpdate(response);
  };

  const submit = async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }
    setError(undefined);
    setDeliveryMethodError(undefined);

    if (part.deliveryMethod === U.part.DeliveryMethod.PICKUP) {
      if (!part.vendorLocation || !part.vendor) {
        setDeliveryMethodError('Please select a vendor location');
        return;
      }
      const vendor = await U.api<PartVendor>('get', `partVendors/findByName/${part.vendor}`);
      if (!vendor || 'errMsg' in vendor) {
        setDeliveryMethodError(`Failed to load vendor, ${part.vendor}`);
        return;
      }
      if (!vendor.pickupOrderPrepMinutes) {
        setDeliveryMethodError('Vendor has no pickup order prep time listed');
        return;
      }
      if (!vendor.pickupOrderHoldDays) {
        setDeliveryMethodError('Vendor has no pickup order hold time listed');
        return;
      }
      if (!vendor.locations) {
        setDeliveryMethodError('Vendor has no locations');
        return;
      }
      const location = vendor.locations.find(l => l.name === part.vendorLocation);
      if (!location) {
        setDeliveryMethodError('Vendor location not found');
        return;
      }
      if (!location.services?.includes(U.partVendor.Service.PICKUP)) {
        setDeliveryMethodError('Vendor location does not provide pickup');
        return;
      }
      if (!vendor.defaultOpenHours?.some(d => d.from) && !location.openHours?.some(d => d.from)) {
        setDeliveryMethodError('Vendor location has no pickup hours');
        return;
      }
    }

    // FIXME @syntaxbliss We are not forcing VMs to associate parts and diagnoses for the time being...
    // TODO: the "required" error message should be applied automatically when submitting the form.
    // There is a ticket pending for this issue: https://nanaio.atlassian.net/browse/TC-2130
    //   let associatedDiagnosesError = '';

    //   if (!part.associatedDiagnosesIndexes.length) {
    //     associatedDiagnosesError = 'Required';
    //   }

    //   setAssociatedDiagnosesError(associatedDiagnosesError);

    //   if (associatedDiagnosesError.length) {
    //     return;
    //   }

    const duplicateError =
      "Warning: you are adding a duplicate part number. Try again only if you're sure this is not an error.";
    if (
      !partId &&
      T.parts(task).some(p => p.partNumber === part.partNumber) &&
      error !== duplicateError
    ) {
      return setError(duplicateError);
    }

    let response;

    const newPart = _.cloneDeep(part);

    if (newPart.associatedDiagnosesIndexes) {
      newPart.associatedDiagnosesIndexes = newPart.associatedDiagnosesIndexes.map(index =>
        Number(index)
      );
    }

    if (partId) {
      // update the part
      response = await U.api<PartUpdateResponse>(
        'put',
        `tasks/${task.id}/parts/${partId}?notify=${getNotify()}`,
        {
          part: newPart,
        }
      );
    } else {
      // add the part
      response = await U.api<PartUpdateResponse>(
        'post',
        `tasks/${task.id}/parts?notify=${getNotify()}`,
        {
          part: newPart,
        }
      );
    }
    if (response && 'errMsg' in response) {
      return setError(response.errMsg);
    }
    savePartUpdate(response);
  };

  const updatePartFromSearchResult = (
    partSearchResult: ExternalPartSearchResult,
    selectedVendorLocation: string
  ) => {
    const partUpdate = {
      ...T.getPartInfoFromExternalPartSearch(partSearchResult),
      vendorLocation: selectedVendorLocation,
    };
    // @ts-expect-error TODO: Part search logic in server needs to be typed fully and maybe available gets added.
    setError(partSearchResult.available ? '' : 'Warning: this part is out of stock on the vendor');
    setPartSearchIsOpen(false);
    setPart(_.merge({}, part, partUpdate));
  };

  const deliveryMethodOptions = U.toOptions([
    { id: U.part.DeliveryMethod.SHIPPED, name: 'Ship to Customer' },
    { id: U.part.DeliveryMethod.PICKUP, name: 'In-Store Pickup' },
  ]);

  const disabledDeliveryMethod = useMemo(() => {
    return ![...T.partRequestedStatuses, T.PartStatus.ESTIMATE].includes(part.status);
  }, [part.status]);

  return (
    <Modal isOpen onClose={onClose} width={1000} hideOnBackdropClick={false}>
      <Modal.Header title={partId ? 'Edit Part' : 'Add New Part'} />
      <Modal.Body className="p-4">
        <Form debounce={500} onChange={setPart} value={part} ref={submitRef}>
          {partSearchIsOpen ? (
            <div>
              <PartSearch
                part={part}
                partNumber={part.partNumber}
                togglePartSearch={() => setPartSearchIsOpen(!partSearchIsOpen)}
                updatePartFromSearchResult={updatePartFromSearchResult}
              />
              <Button onClick={() => setPartSearchIsOpen(false)} variant="secondary">
                Back
              </Button>
            </div>
          ) : (
            <div>
              <Inventory partNumber={part.partNumber} />
              <div className="grid grid-cols-2">
                {/* PRODUCT INFO */}
                <div className="mr-4">
                  <Title number="1" title="Product Info" />
                  <Input id="mfgCode" />
                  <div className="flex">
                    <Input
                      className="mr-4 flex-1"
                      id="partNumber"
                      onEnter={() => setPartSearchIsOpen(!partSearchIsOpen)}
                      required
                    />
                    <div>
                      <Button
                        className="mt-6"
                        onClick={() => setPartSearchIsOpen(true)}
                        size="medium"
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                  <Input
                    id="status"
                    options={statusOptions}
                    required
                    tooltip="Part status may only make one step change at a time. If frequently you need to make multiple manual status changes for a part, contact eng about automating this workflow."
                  />
                  <Input id="desc" label="Description" required />
                  <Input
                    id="url"
                    label="URL"
                    labelRightUI={<Icon name="exit_to_app" onClick={openUrl} />}
                  />
                  <Input
                    id="vendor"
                    label="Part Vendor"
                    labelRightUI={
                      <Text color="primaryCTA" onClick={openAddVendor} type="button">
                        + Add New Vendor
                      </Text>
                    }
                    options={vendors}
                  />
                  <Input id="vendorLocation" />
                  <Input
                    // FIXME @syntaxbliss We are not forcing VMs to associate parts and diagnoses for the time being...
                    // error={associatedDiagnosesError}
                    id="associatedDiagnosesIndexes"
                    label="Associated Diagnoses"
                    multiple
                    options={diagnosesOptions}
                    required
                    tooltip="Select the diagnoses this part helps treat or resolve. This will help us more accurately predict what parts will be needed per diagnosis."
                  />
                  <Input id="purpose" multiline />
                </div>
                {/* PURCHASE ORDER INFO */}
                <div className="ml-4">
                  <Title number="2" title="Purchase Order Info" />
                  <Input
                    id="owner"
                    label="Purchased By"
                    object
                    options={T.partOwnerOptions({ isSupport: true, job: task, org })}
                    required
                    type={InputType.SEARCH}
                  />
                  <Input id="unit.value" label="Quantity" required type={InputType.NUMBER} />
                  <div className="grid grid-cols-3 gap-x-4">
                    <Input id="costPrice" label="Unit Cost Price" required type={InputType.MONEY} />
                    <Input
                      id="retailPrice"
                      label="Unit Retail Price"
                      required
                      type={InputType.MONEY}
                    />
                    <Input
                      id="pricePerUnit"
                      label="Price to Customer"
                      type={InputType.MONEY}
                      disabled
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Input
                      id="vendorOrderNumber"
                      required={
                        part.status === T.PartStatus.ORDERED &&
                        part.deliveryMethod === U.part.DeliveryMethod.PICKUP
                      }
                    />
                    <Input id="userSetEta" label="ETA" type={InputType.DATE} />
                    <Input id="vendorInvoiceNumber" />
                    <Input id="vendorBackorderNumber" />
                    <Input id="customerPaying" type={InputType.BOOL} />
                    <Input id="proPaying" type={InputType.BOOL} />
                    <Input id="responsibleProId" options={workOrder.pros} label="Pro to Charge" />
                  </div>
                  <Input
                    disabled={disabledDeliveryMethod}
                    direction="column"
                    tooltip="This cannot be edited after parts have been ordered."
                    error={deliveryMethodError}
                    id="deliveryMethod"
                    onClick={value => setPart({ ...part, deliveryMethod: value as DeliveryMethod })}
                    options={deliveryMethodOptions}
                    required
                    type={InputType.RADIO}
                  />
                </div>
              </div>
              <div className="mb-8 mt-4 border border-grey-dark" />
              <div className="grid grid-cols-2">
                {/* AUTHORIZATION INFO */}
                <div className="mr-4">
                  <Title number="3" title="Authorization Info" />
                  <AuthorizationInfo
                    me={me}
                    onApprovalStatusChange={onApprovalStatusChange}
                    part={part}
                  />
                </div>
                {/* RETURN INFO */}
                <div className="ml-4">
                  <Title number="4" title="Return Info" />
                  <Input id="returnReason" options={U.part.returnReasonOptions} />
                  <Input id="hasCore" type={InputType.BOOL} />
                  {part.hasCore && <Input id="coreReturned" type={InputType.BOOL} />}
                  <Input id="returnCreditNumber" />
                  <Input
                    id="returnCreditAmount"
                    required={part.status === T.PartStatus.CREDITED}
                    type={InputType.MONEY}
                  />
                </div>
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
      {!partSearchIsOpen && (
        <Modal.Footer customUI>
          <div className="flex">
            {canRemove && (
              <Button className="mr-4" onClick={remove} variant="secondary">
                Delete Part
              </Button>
            )}
            <Text color="danger">{error}</Text>
            {isNotRecommended && (
              <Alert hideIcon variant="warning">
                <div className="align-center flex">
                  <Icon className="mr-2" name="analytics" />
                  <Tooltip node="This is an automated prediction based on historical data and may not be accurate.">
                    <Text>
                      Part prediction engine does NOT recommend this part for the work order. Are
                      you sure you want to add this?
                    </Text>
                  </Tooltip>
                </div>
              </Alert>
            )}
          </div>
          <div className="flex">
            <div className="mr-8" style={{ width: 250 }}>
              <div className="mb-2 flex justify-end">
                <Text className="mr-1" noWrap>
                  {notifyCx ? 'Yes, notify' : "No, don't notify"} the{' '}
                  <Text tag="span" type="button">
                    customer
                  </Text>
                </Text>
                <Switch isActive={notifyCx} onClick={setNotifyCx} />
              </div>
              <div className="flex justify-end">
                <Text className="mr-1" noWrap>
                  {notifyPro ? 'Yes, notify' : "No, don't notify"} the{' '}
                  <Text tag="span" type="button">
                    technician
                  </Text>
                </Text>
                <Switch isActive={notifyPro} onClick={setNotifyPro} />
              </div>
            </div>
            <Button onClick={submit} submit>
              {partId ? 'Save Changes' : 'Add Part'}
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
