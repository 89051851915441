import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Bool, Number, Search, Text, Textbox } from '../../../com/ui/form';
import { loadUsers } from '../../../com/user';
import { emailRe } from '../../../config/const';
import vendors from '../../../config/vendors';

export const getErrors = ({ canSchedule, form, services, state }) => {
  const warrantyId = _.get(state, 'customer.org.id');
  const isCchsFidelity = [vendors.crossCountry, vendors.fidelity].includes(warrantyId);
  const isAhsGe = [vendors.americanHomeShield, vendors.ge].includes(warrantyId);
  const isAssurant = vendors.assurant === warrantyId;
  if (isCchsFidelity && !_.get(state, 'vendor.workOrderNumber')) {
    return 'Work order number required';
  }
  if (!_.get(state, 'metadata.taskQuestions.Tell us more')) {
    return 'Tell us more missing';
  }
  if (!state.email) {
    return 'Email missing';
  }
  if (!emailRe.test(state.email)) {
    return 'Invalid email';
  }
  if (!_.get(state, 'profile.phone')) {
    return 'Phone missing';
  }
  if (U.trimPhone(state.profile.phone).length < 10) {
    return 'Please use a 10-digit phone number';
  }
  if (!_.get(state, 'profile.firstName')) {
    return 'First name missing';
  }
  if (!_.get(state, 'profile.lastName')) {
    return 'Last name missing';
  }
  if (!_.get(state, 'profile.address.streetNumber')) {
    return 'Missing street number';
  }
  if (!_.get(state, 'profile.address.route')) {
    return 'Missing street';
  }
  if (!_.get(state, 'profile.address.locality')) {
    return 'Missing city';
  }
  if (!_.get(state, 'profile.address.region')) {
    return 'Missing state';
  }
  if (!_.get(state, 'profile.address.postalCode')) {
    return 'Missing zip';
  }
  if (!_.get(state, 'profile.address.county')) {
    return 'Missing county';
  }
  if (!_.get(state, 'profile.referredBy')) {
    return 'Referred by required';
  }
  if (!_.get(state, 'serviceCatalogs.0.id')) {
    return 'Service missing';
  }
  if (_.get(services, 'serviceCatalogs.0.name') === 'Cleaning' && !state.frequency) {
    return 'Frequency missing';
  }
  // All jobs except assurant jobs require 3+ timeslots unless the user has "schedule" role
  if (
    _.values(form.availTSlots).length < 3 &&
    !canSchedule &&
    !state.skipAvailability &&
    !isAssurant
  ) {
    return 'Select at least 3 timeslots or create a draft and ask a manager to create';
  }
  if (state.paymentMethod === 'Existing Card' && !state.stripeCardId && !state.skipAvailability) {
    return 'Card required if "Existing Card" is selected';
  }
  if (state.paymentMethod === 'No Card' && !state.noCardReason) {
    return 'Reason required if "No Card" is selected';
  }
  const landlordPhone = _.get(state, 'landlord.phone');
  if (landlordPhone && landlordPhone.replace(/\D/g, '').length !== 10) {
    return 'Landlord phone must be 10 digits';
  }
  const day = m(_.get(state, 'availTSlots.0')).day();
  if (_.get(state, 'availTSlots.0') && !canSchedule && [0, 6].includes(day) && isAhsGe) {
    return "AHS and GE can't have a weekend preferred time slot. If it must be booked create a job draft and ask a manager to book it";
  }
  if (!canSchedule && _.get(state, 'vendor.status', 'active') !== 'active') {
    return "Vendor status isn't active. If it must be booked create a job draft and ask a manager to book it";
  }
  return '';
};

export const postCard = async () => {
  const r = await global.stripe.createToken(global.card);
  if (r.error) {
    U.redux.set('form.error', r.error.message);
  } else {
    U.redux.set('form.error', '');
    return r.token.id;
  }
};

export const LineValue = ({ label, value }) => (
  <div>
    <div className="pull-right">{value}</div>
    <div>{label}</div>
  </div>
);

export const loadOrgUsers = async () => {
  const query = { orgs: { $exists: true } };
  loadUsers({ query, limit: 1000 });
};

export const frequencies = [
  { id: 1, name: 'Weekly' },
  { id: 2, name: 'Every 2 weeks' },
  { id: 4, name: 'Every 4 weeks' },
  { id: 0, name: 'Just Once' },
];

export const types = {
  bool: Bool,
  number: Number,
  pick: Search,
  text: Text,
  textbox: Textbox,
};
