import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Alert, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import { useGetFeatures } from '@/hooks/api/features';

export default function TwoHourTimeSlotAlert(): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const taskId = nullthrows(global.id());
  const task = tasks[taskId];
  const servicePowerTimeBand = task.metadata?.servicePowerTimeBand;

  const featureData = useGetFeatures();

  const isSquareTrade2HourSlots =
    featureData.data?.squareTrade2HourSlots &&
    servicePowerTimeBand &&
    Object.keys(T.SQUARE_TRADE_SERVICEPOWER_TIME_BAND_SLOTS_MAP).includes(servicePowerTimeBand);

  if (!isSquareTrade2HourSlots) {
    return <></>;
  }

  return (
    <Alert className="mb-5" variant="warning">
      <Text type="page-title" className="mb-2 flex">
        Square Trade Work Order
      </Text>

      <Text type="body-2" color="grey.dark">
        This Work Order uses 2-hour time slots, not 4.
      </Text>
    </Alert>
  );
}
