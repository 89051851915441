import _ from 'lodash';

export const filterItems = ({ items, proFilter, serviceFilter, serviceMap }) => {
  const out = {};
  items.filter(i => {
    let isMatch;
    if (proFilter.length && !_.includes(proFilter, i.group)) {
      isMatch = false;
    } else if (serviceFilter.length) {
      const filterPaths = serviceFilter.map(i => serviceMap[i].path);
      const itemPath = _.get(serviceMap[i.job.serviceCatalogs[0].id], 'path');
      isMatch = !!filterPaths.find(p => _.includes(p, itemPath) || _.includes(itemPath, p));
    } else {
      isMatch = true;
    }
    out[i.id] = isMatch;
  });

  return out;
};

export const filterGroups = ({ date, items = [], groups, proFilter }) => {
  const out = {};
  items = items.filter(i => i.date === date);
  groups.filter(g => {
    let filterVisible;
    const hasVisits = !!items.find(i => i.group === g.id);
    if (proFilter.length && !_.includes(proFilter, g.id)) {
      filterVisible = false;
    } else {
      filterVisible = true;
    }
    out[g.id] = { filterVisible, hasVisits };
  });
  return out;
};
