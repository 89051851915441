import React, { useState } from 'react';
import { U, W, WorkOrder, WorkOrderTag } from '@nanaio/util';
import _ from 'lodash';
import { Modal, SearchInput, Text } from '@/components';
import { useLegacySelector } from '@/hooks';

type Props = {
  taskId: string;
};

export default function WorkOrderTags({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const [isOpen, setIsOpen] = useState(false);
  const [originalTags, setOriginalTags] = useState<WorkOrderTag[]>([]);
  const [tags, setTags] = useState<WorkOrderTag[]>(_.uniq(workOrder?.tags));

  const handleOpen = () => {
    setIsOpen(true);
    setOriginalTags(_.uniq(workOrder.tags));
  };

  const handleSave = async () => {
    await U.api<WorkOrder>('put', `workOrders/${workOrder.id}/tags`, tags, ['save']);
    setIsOpen(false);
  };

  const removedTags = _.difference(originalTags, tags).map(tag =>
    U.optionToString(W.tagOptions, tag as string)
  );

  const tagNames = tags.map(tag => U.optionToString(W.tagOptions, tag as string));

  return (
    <>
      <div className="flex cursor-pointer" onClick={handleOpen}>
        <Text className="mr-2 underline" color="primaryCTA" type="button">
          Tags:
        </Text>
        <div>
          <Text>{tagNames.filter((tag, i) => !(i % 2)).join(', ')}</Text>
          <Text>{tagNames.filter((tag, i) => i % 2).join(', ')}</Text>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header title="Edit Tags" />
        <Modal.Body className="p-4">
          <SearchInput
            capitalize
            multiple
            onChange={value => setTags(value as WorkOrderTag[])}
            options={W.tagOptions}
            sort
            value={tags}
          />
          {!!removedTags.length && (
            <Text className="mt-4" color="danger">
              Removed Tags: {removedTags.join(', ')}
            </Text>
          )}
        </Modal.Body>
        <Modal.Footer askConfirmation={!!removedTags.length} onSave={handleSave} />
      </Modal>
    </>
  );
}
