import React, { useState } from 'react';
import { Task, U, Visit } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Button, Modal, Text } from '@/components';

type Props = {
  task: Task;
  visit: Visit;
};

export function VisitNotes({ task, visit }: Props): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const timezone = U.timezone(task.serviceAddress);
  const visitNote = _.find(task.notes, { visitId: visit.id });

  if (!visitNote) {
    return <></>;
  }
  const startTime = visit.slot.start;
  const endTime = visit.slot.end;

  return (
    <>
      <Button className="ml-4" variant="outline" onClick={() => setShowModal(true)} size="medium">
        View Tech's Notes
      </Button>
      <Modal isOpen={showModal} height={700} onClose={() => setShowModal(false)}>
        <Modal.Header title="View Visit Notes" />
        <Modal.Body className="p-6">
          <Text color="grey.dark" type="button">
            Author
          </Text>
          <Text className="mt-2">{visitNote.user.fullName}</Text>
          <Text color="grey.dark" className="mt-6" type="button">
            Visit Date
          </Text>
          <Text className="mt-2">
            {mt(startTime).tz(timezone).format('ddd, MMM D, YYYY')} between{' '}
            {mt(startTime).tz(timezone).format('h A')} and{' '}
            {mt(endTime).tz(timezone).format('h A (z)')}
          </Text>
          <Text color="grey.dark" className="mt-6" type="button">
            Create Time
          </Text>
          <Text className="mt-2">
            {mt(visitNote.createTime).tz(timezone).format('ddd, MMM D, YYYY, h:mm A (z)')}
          </Text>
          <Text color="grey.dark" className="mt-6" type="button">
            Note
          </Text>
          <Text className="mt-2">{visitNote.content}</Text>
        </Modal.Body>
        <Modal.Footer hideCloseButton>
          <Button className="w-full" onClick={() => setShowModal(false)} size="medium">
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
