import React, { useState } from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Icon, Text } from '@/components';
import { companyLogoAndName, companyName } from '@/config/const';
import { useLegacySelector } from '@/hooks';
import { logout } from '../user/func';
import Link from './link';

export default function CxNav(): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const me = useLegacySelector(state => state.me);
  const jobs = _.values(tasks);
  const openOrgJobs = _.values(jobs).filter(
    t => T.hasOrg(t) && !['completed', 'cancelled'].includes(t.status)
  );
  const hasOpenOrgJobs = !_.isEmpty(openOrgJobs);

  const hideNav = _.isEmpty(me.roles);

  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(!visible);
  };

  type Link = {
    'data-cy'?: string;
    children: string;
    to: string;
    onClick?: () => void;
  };

  type LinkList = Array<Link | Link[]>;

  const links: LinkList = [
    { children: 'My Bookings', to: '/tasks' },
    [
      { children: 'Profile', to: '/profile' },
      {
        children: 'Logout',
        to: '/',
        'data-cy': 'logout',
        onClick: () => {
          logout();
        },
      },
    ],
  ];
  if (!hasOpenOrgJobs) {
    links.push({
      children: 'Book a Service',
      to: '/book',
    });
  }

  if (hideNav) {
    return <></>;
  }

  return (
    <nav className="fixed left-0 top-0 z-[999] flex w-full items-center border-b border-grey-medium bg-white">
      <div className="inline-flex items-center pl-4">
        {global.id() ? (
          <Link
            to="/tasks"
            className="flex items-center justify-center rounded-full border bg-white p-2 hover:bg-black hover:text-white"
          >
            <Icon name="arrow_left" />
          </Link>
        ) : (
          <Link to="/" name="nav-go-home">
            <img src={companyLogoAndName} alt={companyName} style={{ height: 32 }} />
          </Link>
        )}
      </div>
      <div className="flex-1 flex-row justify-end">
        {_.map(links, (link, n) =>
          !_.isArray(link) ? (
            <Link
              className="pointer hidden items-center justify-center border-r border-grey-medium p-4 hover:bg-background-light sm:flex"
              data-cy={link['data-cy']}
              onClick={() => (link.onClick ? link.onClick() : undefined)}
              to={link.to}
              key={n}
            >
              <Text className="mx-2" type="button">
                {link.children}
              </Text>
            </Link>
          ) : (
            <div className="hidden sm:block" key={n}>
              <div data-cy="account-nav" className="group">
                <Link
                  to="/login"
                  name="nav-go-login"
                  className="pointer group flex items-center justify-center p-4 transition-all duration-200 ease-in-out hover:bg-background-light"
                >
                  <Icon name="account_circle" color="#D1D8DE" size={25} />
                  <Text className="mx-2" type="button">
                    Account
                  </Text>
                  <Icon name="expand_more" color="#666A73" size={14} />
                </Link>
                <div className="absolute right-0 z-[1] hidden min-w-[200px] transition-all duration-200 ease-out group-hover:block">
                  <div className="rounded-b bg-white shadow-floating">
                    {_.map(link, link => (
                      <Link
                        data-cy={link['data-cy']}
                        className="pointer group/link flex flex-row items-center p-4 no-underline transition-all duration-200 ease-in-out hover:bg-background-lightest"
                        to={link.to}
                        onClick={link.onClick}
                        key={link.children}
                      >
                        <Text className="flex-1 group-hover/link:text-primaryCTA-hover">
                          {link.children}
                        </Text>
                        <Icon
                          name="chevron_right"
                          size={25}
                          color="grey.dark"
                          className=" group-hover/link:text-primaryCTA-hover"
                        />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {/* =============== TOGGLE MENU ============== */}
      <section className="relative flex sm:hidden">
        <Icon
          className="pointer m-4 hover:text-primaryCTA-hover"
          name="menu"
          size={25}
          color="#000"
          onClick={toggleMenu}
        />
        {visible && (
          <div className="fixed left-0 top-0 z-[1200] h-full w-full overflow-hidden bg-white">
            <div className="flex h-[66px] justify-end border-b border-grey-medium p-4">
              <div className="flex items-center justify-between">
                <Icon
                  className="pointer hover:text-primaryCTA-hover"
                  name="close"
                  size={25}
                  color="#000"
                  onClick={toggleMenu}
                />
              </div>
            </div>
            {_.map(links, (link, n) =>
              !Array.isArray(link) ? (
                <Link
                  data-cy={link['data-cy']}
                  className="pointer group/link flex flex-row items-center p-4 no-underline transition-all duration-200 ease-in-out hover:bg-background-lightest"
                  to={link.to}
                  onClick={() => {
                    toggleMenu();
                    if (link.onClick) {
                      link.onClick();
                    }
                  }}
                  key={link.children}
                >
                  <Text className="flex-1 group-hover/link:text-primaryCTA-hover">
                    {link.children}
                  </Text>
                  <Icon
                    name="chevron_right"
                    size={25}
                    color="grey.dark"
                    className=" group-hover/link:text-primaryCTA-hover"
                  />
                </Link>
              ) : (
                <div key={n}>
                  {_.map(link, link => (
                    <Link
                      data-cy={link['data-cy']}
                      className="pointer group/link flex flex-row items-center p-4 no-underline transition-all duration-200 ease-in-out hover:bg-background-lightest"
                      to={link.to}
                      onClick={() => {
                        toggleMenu();
                        if (link.onClick) {
                          link.onClick();
                        }
                      }}
                      key={link.children}
                    >
                      <Text className="flex-1 group-hover/link:text-primaryCTA-hover">
                        {link.children}
                      </Text>
                      <Icon
                        name="chevron_right"
                        size={25}
                        color="grey.dark"
                        className=" group-hover/link:text-primaryCTA-hover"
                      />
                    </Link>
                  ))}
                </div>
              )
            )}
          </div>
        )}
      </section>
    </nav>
  );
}
