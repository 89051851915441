import React, { useMemo, useRef } from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Button, Icon, InteractiveTooltip, Modal, Text, VendorLogo } from '@/components';
import type { Ref } from '@/components/core/InteractiveTooltip';
import type { LandingStepProps } from './LandingPage';

export default function WelcomeStep({
  steps,
  deadline,
  onLeave,
  onStepClick,
  workOrder,
  helpButton,
  lists,
}: LandingStepProps): JSX.Element {
  const vendorById = _.invert(U.org.vendors);
  const vendorShortName =
    workOrder.org && workOrder.org.id in vendorById ? vendorById[workOrder.org.id] : undefined;

  const continueStepIndex = steps.findIndex(step => step.completed < step.total);

  const appliances = useMemo(() => workOrder.tasks.map(T.appliance), [workOrder]);

  const tooltipRef = useRef<Ref>(null);

  return (
    <>
      <Modal.Header
        rightContent={
          <span className="flex items-center justify-end">
            <InteractiveTooltip
              // overwrite text-right set by spacer
              className="text-left"
              title="Are You Sure You Want To Leave This Page?"
              description={`Your work order may be canceled if not confirmed by ${deadline.format(
                'ddd, MMM D, hh:mma (z)'
              )}.`}
              primaryButtonProps={{
                size: 'medium',
                children: 'Yes',
                onClick: onLeave,
              }}
              ref={tooltipRef}
              secondaryButtonProps={{
                size: 'medium',
                children: 'No',
                onClick: () => nullthrows(tooltipRef.current).dismiss(),
              }}
              trigger={
                <Icon className="-mr-1 text-grey-dark hover:text-secondary" name="exit_to_app" />
              }
            />
          </span>
        }
        spacerContentWidth={40}
      />
      <Modal.Body className="p-6">
        <section>
          {vendorShortName && (
            <Text type="label" className="mt-4 text-center text-grey-dark">
              A trusted partner of
              <VendorLogo className="ml-2 inline-flex" vendorName={vendorShortName} size="small" />
            </Text>
          )}

          <Text type="headline-6" className="mt-6 text-center">
            Get Started to Get Your Repair
            <br />
            Completed Faster
          </Text>

          <div className="mt-5 flex flex-row justify-center space-x-2 text-center">
            <Icon className="text-primaryCTA" name="schedule" size={14} />
            <Text type="label">
              You’ll need 5 min and to be near your{' '}
              {appliances.length > 2 ? 'appliances' : appliances.join(' and ').toLowerCase()}
            </Text>
          </div>
        </section>
        {lists}

        <div className="my-8 border-b border-grey-medium" />
        {helpButton}
      </Modal.Body>
      <Modal.Footer customUI>
        <Button
          className="w-full"
          size="xlarge"
          variant="primary"
          onClick={() => onStepClick(continueStepIndex === -1 ? 0 : continueStepIndex)}
        >
          {continueStepIndex === -1 ? 'Start' : 'Continue'}
        </Button>
      </Modal.Footer>
    </>
  );
}
