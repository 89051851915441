import * as Table from '@nanaio/table';
import U, { Option } from '@nanaio/util';
import _ from 'lodash';

/** Returns index of vector within vectors of the same direction. */
export const getDirectionIndex = ({
  direction,
  directionToVectors,
  index,
}: {
  direction: Table.Direction;
  directionToVectors: Record<Table.Direction, Table.Vector[]>;
  index: number;
}): number =>
  direction === Table.Direction.COLUMN
    ? index
    : index - U.length(directionToVectors[Table.Direction.COLUMN]);

export const getModuleOptions = ({
  databaseIdToTableIdToColumnIdToColumn,
  directionToVectors,
  index,
  query,
  vector,
}: {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  directionToVectors: Record<string, Table.Vector[]>;
  index: number;
  query: Table.Query;
  vector: Table.Vector;
}): Partial<Table.Column>[] => {
  const idToVector = _.keyBy(query.analytics.vectors, 'id');
  const inferDimension = query.analytics.vectors[index + 1]?.depth > vector.depth;
  let moduleOptions: Partial<Table.Column>[] = _.values(
    databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]
  );
  if (inferDimension) {
    moduleOptions = moduleOptions.filter(module => Table.moduleIsDimension(module));
  }
  moduleOptions.push(
    ...directionToVectors[vector.direction]
      .filter(vector => vector.moduleId)
      .map(vector => ({
        group: [`${_.startCase(vector.direction)}s`],
        id: `${vector.direction}-${vector.id}`,
        name: Table.getVectorName({
          databaseIdToTableIdToColumnIdToColumn,
          idToVector,
          query,
          vector,
          vectors: query.analytics.vectors,
        }),
      }))
  );
  return moduleOptions.filter(option => !option.noAnalytics);
};

export const getFlippedQuery = ({ query }: { query: Table.Query }): Table.Query => {
  const columnVectors = query.analytics.vectors.filter(
    vector => vector.direction === Table.Direction.COLUMN
  );
  const rowVectors = query.analytics.vectors.filter(
    vector => vector.direction === Table.Direction.ROW
  );
  const queryCopy = _.cloneDeep(query);
  queryCopy.analytics.vectors = [
    ...rowVectors.map(vector => ({ ...vector, direction: Table.Direction.COLUMN })),
    ...columnVectors.map(vector => ({ ...vector, direction: Table.Direction.ROW })),
  ].map(vector => ({
    ...vector,
    operations: vector.operations.map(operation => {
      if (operation === Table.Operation.PERCENT_OF_COLUMN) {
        return Table.Operation.PERCENT_OF_ROW;
      }
      if (operation === Table.Operation.PERCENT_OF_ROW) {
        return Table.Operation.PERCENT_OF_COLUMN;
      }
      return operation;
    }),
  }));

  for (const vector of queryCopy.analytics.vectors) {
    vector.formula = vector.formula.map(moduleId => {
      if (moduleId.startsWith(Table.Direction.COLUMN)) {
        return moduleId.replace(Table.Direction.COLUMN, Table.Direction.ROW);
      }
      if (moduleId.startsWith(Table.Direction.ROW)) {
        return moduleId.replace(Table.Direction.ROW, Table.Direction.COLUMN);
      }
      return moduleId;
    });

    if (vector.moduleId?.startsWith(Table.Direction.COLUMN)) {
      vector.moduleId = vector.moduleId.replace(Table.Direction.COLUMN, Table.Direction.ROW);
    } else if (vector.moduleId?.startsWith(Table.Direction.ROW)) {
      vector.moduleId = vector.moduleId.replace(Table.Direction.ROW, Table.Direction.COLUMN);
    }
  }

  return queryCopy;
};

export const getVectorAndChildIndexes = ({
  index,
  vectors,
}: {
  index: number;
  vectors: Table.Vector[];
}): number[] => {
  const { depth } = vectors[index];
  const out = [index];
  for (let i = index + 1; vectors[i]?.depth > depth; i++) {
    out.push(i);
  }
  return out;
};

export const getVectorOperationOptions = ({
  databaseIdToTableIdToColumnIdToColumn,
  query,
  vector,
}: {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  query: Table.Query;
  vector: Table.Vector;
}): Option[] => {
  let options = Table.operationOptions;
  const module = vector.moduleId
    ? databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][vector.moduleId]
    : undefined;
  if (module?.type !== Table.ColumnType.BOOL) {
    options = options.filter(option => option.id !== Table.Operation.IS_DIMENSION);
  }
  return options;
};

export const getVisibleRows = ({
  rows,
  tableVectorIdToIsCollapsed,
  tableVectors,
}: {
  rows: Table.Cell[][];
  tableVectorIdToIsCollapsed: Record<string, boolean>;
  tableVectors: Table.TableVector[];
}): Table.Cell[][] => {
  const columnIndexToIsCollapsed: boolean[] = [];
  const directionToTableVectors = _.groupBy(tableVectors, 'direction');
  const rowIndexToIsCollapsed: boolean[] = [];
  _.forEach(tableVectorIdToIsCollapsed, (isCollapsed, tableVectorId) => {
    const tableVector = isCollapsed && tableVectors.find(vector => vector.id === tableVectorId);
    if (tableVector) {
      const indexToCollapsed =
        tableVector.direction === Table.Direction.COLUMN
          ? columnIndexToIsCollapsed
          : rowIndexToIsCollapsed;
      const tableVectors = directionToTableVectors[tableVector.direction];
      const startIndex = _.findIndex(tableVectors, tv => tv.id === tableVector.id);
      for (let i = startIndex + 1; i < tableVectors.length; i++) {
        if (tableVectors[i].depth < tableVector.depth) {
          break;
        }
        indexToCollapsed[i] = true;
      }
    }
  });
  const visibleRows = rows
    .filter((row, i) => !rowIndexToIsCollapsed[i])
    .map(row => row.filter((cell, i) => !columnIndexToIsCollapsed[i]));
  return visibleRows;
};
