import React, { useMemo } from 'react';
import { T, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { openLink } from '../../../utils';
import Copyable from '../../core/Copyable';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import User from '../User';
import { getUnassignItems, proPropType } from '../util';

export default function Pro({
  leftAlignDropdown,
  mapIsOpen,
  mapProId,
  pro = { user: {} },
  setMapProId,
  setVisit,
  visit,
  visitPro,
  workOrder,
}) {
  let VisitPro = { ...visitPro };

  if (!VisitPro && pro.id) {
    VisitPro = visit.pros.find(visitPro => visitPro.id === pro.id);
  }

  const changeRole = (task, role) => {
    const proIndex = _.findIndex(visit.pros, pro => pro.id === VisitPro.id);
    const taskIndex = _.findIndex(visit.pros[proIndex].tasks, proTask => proTask.id === task.id);
    const newVisit = _.cloneDeep(visit);
    newVisit.pros[proIndex].tasks[taskIndex].role = role;
    const unassignedProIndex = _.findIndex(
      visit.pros,
      pro => !pro.id && pro.tasks.find(proTask => proTask.id === task.id && proTask.role === role)
    );
    if (unassignedProIndex !== -1) {
      newVisit.pros = newVisit.pros.filter((pro, i) => i !== unassignedProIndex);
    }
    setVisit(newVisit);
  };

  const name = pro.user.fullName || W.proName({ proId: pro.id, workOrder });

  const openProfile = () => openLink({ url: `/pros/${pro.id}`, newTab: true });

  const unassignItems = getUnassignItems({ setVisit, visit, visitPro: VisitPro, workOrder });

  const badgeProps = useMemo(() => {
    let iconName = 'help';
    let badgeColor = 'accent-orange';
    if (W.isProRestricted(workOrder, pro.id)) {
      iconName = 'minus_thick';
      badgeColor = 'danger';
    }
    return { iconProps: { name: iconName }, color: badgeColor };
  }, [workOrder, pro.id]);

  const restrictedProData = workOrder.restrictedPros?.find(
    restrictedPro => restrictedPro.proId === pro.id
  );

  if (restrictedProData) {
    pro.ineligibleReason = `Unclaimed On ${m(restrictedProData.time).format('M/D/YYYY')}`;
  }

  return (
    <User
      hasOverlay={mapIsOpen && mapProId !== pro.id}
      badgeProps={badgeProps}
      name={name}
      onMouseEnter={() => setMapProId(pro.id)}
    >
      <div className="flex-1">
        <Text color="primaryCTA" noWrap onClick={openProfile} type="subtitle-1">
          {name}
        </Text>
        {pro.ineligibleReason ? (
          <Text color="danger" type="body-2">
            {pro.ineligibleReason}
          </Text>
        ) : (
          workOrder.tasks.map(task => {
            const currentRole = _.startCase(
              _.get(
                _.get(VisitPro, 'tasks', []).find(proTask => proTask.id === task.id),
                'role'
              )
            );
            return (
              <div className="flex flex-row" key={task.id}>
                <Text className="mr-2" color="grey.dark" type="body-2">
                  {T.appliance(task)}:
                </Text>
                <Dropdown
                  items={_.compact(W.visitProRoleOrder).map(role => ({
                    disabled: _.startCase(role) === currentRole,
                    onClick: () => changeRole(task, role),
                    title: _.startCase(role),
                  }))}
                  leftAlign={leftAlignDropdown}
                  trigger={
                    <Text type="body-2" color={currentRole ? 'font.dark' : 'danger'}>
                      {currentRole || 'Select role'}
                    </Text>
                  }
                />
              </div>
            );
          })
        )}
      </div>
      <div className="flex flex-col justify-between">
        {!pro.ineligibleReason && (
          <Dropdown
            className="mb-4"
            hideChevron
            items={unassignItems}
            title="Unassign Technician:"
            trigger={<Icon color="danger" name="close" />}
          />
        )}
        <Copyable text={pro.user.phone}>
          <Icon color="primaryCTA" name="phone" />
        </Copyable>
      </div>
    </User>
  );
}

Pro.propTypes = {
  leftAlignDropdown: PropTypes.bool,
  mapIsOpen: PropTypes.bool.isRequired,
  mapProId: PropTypes.string,
  pro: proPropType,
  setMapProId: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  visitPro: W.visitProPropType,
  workOrder: W.propType.isRequired,
};
