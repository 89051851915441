import React from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import { Input, InputType } from '../../form';

const editUi = ({
  column,
  key,
  label,
  onChange,
  optionsMap,
  value,
}: {
  column?: Table.Column;
  key?: number | string;
  label?: string;
  onChange: (value?: Value | Value[]) => void;
  optionsMap: IdToOption;
  value?: string | number;
}): JSX.Element => (
  <Input
    capitalize={column?.camelCase}
    key={key}
    label={label}
    multiple={column?.isArray}
    onChange={value => {
      onChange(value);
    }}
    options={optionsMap}
    type={InputType.SEARCH}
    value={value}
  />
);

const filterUi = ({
  column,
  onChange,
  optionsMap = {},
  value,
}: {
  column: Table.Column;
  onChange?: (id?: string, value?: Value) => void;
  optionsMap?: Record<string, Option>;
  value?: Search;
}): JSX.Element => (
  <div>
    <Input
      capitalize={column.camelCase}
      cypressId={`include-${column?.key}`}
      label="Include"
      multiple
      onChange={value => onChange && onChange('include', value as number | string)}
      options={optionsMap}
      type={InputType.SEARCH}
      value={value?.include}
    />
    <Input
      capitalize={column.camelCase}
      cypressId={`exclude-${column?.key}`}
      label="Exclude"
      multiple
      onChange={value => onChange && onChange('exclude', value as number | string)}
      options={optionsMap}
      type={InputType.SEARCH}
      value={value?.exclude}
    />
  </div>
);

export type IdToOption = Record<string, Option>;

export type Search = { exclude: Value[]; include: Value[] };

export type Value = string | number;

const pickModule: Partial<Table.Column> = { editUi, filterUi };

export default pickModule;
