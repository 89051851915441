import React, { useState } from 'react';
import { T, Task, TaskStatus } from '@nanaio/util';
import _ from 'lodash';
import { APIError, FormControl, Modal, SearchInput, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

type Props = {
  onClose: () => void;
  taskId: string;
};

export default function ChangeStatus({ onClose, taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const orgs = useLegacySelector(state => state.orgs);
  const orgId = T.orgId(task) || '';
  const org = orgs[orgId];

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  let statusOptions = T.statusOptions.filter(status => status.id !== T.Status.CANCELLED);
  if (workOrder.invoice?.balance !== 0 && T.type(org) === 'b2c') {
    statusOptions = statusOptions.filter(
      status => ![T.Status.CUSTOMER_NO_SHOW, T.Status.PRO_NO_SHOW].includes(status.id)
    );
  }
  const cantReopen = T.isClosed(task) && !roles.admin && !roles.agentManager && !roles.vm;

  const [error, setError] = useState('');
  const [notify, setNotify] = useState('both');
  const [status, setStatus] = useState(task.status);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}?notify=${notify}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  if (cantReopen) {
    return <></>;
  }

  const handleSave = async () => {
    setError('');
    if (!notify) {
      return setError('Notify required');
    }
    if (!status) {
      return setError('Status required');
    }
    const changes = [{ action: 'replace', path: 'status', value: status }];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Change Status" />
      <Modal.Body className="p-4">
        <Text className="mb-4" color="danger" type="button">
          {error}
        </Text>
        {taskToUpdate.error}
        <FormControl label="Status">
          <SearchInput
            capitalize
            onChange={value => setStatus(value as TaskStatus)}
            options={statusOptions}
            value={status}
          />
        </FormControl>
        <FormControl label="Notify">
          <SearchInput
            capitalize
            onChange={value => setNotify(value as string)}
            options={['both', 'customer', 'none', 'pro']}
            value={notify}
          />
        </FormControl>
      </Modal.Body>
      <Modal.Footer onSave={handleSave} />
    </Modal>
  );
}
