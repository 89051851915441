import { useCallback, useState } from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { loggedOutEvent } from '@/com/analytics';
import type { Brand } from '@/pages/book/steps-V2/util';

// https://docs.google.com/document/d/1yqbwTfofMAGvC4yOTs7DaQNTEci_i102uqea8QFLLDQ/edit
const SUPPORTED_BRANDS: Brand[] = [
  'Amana',
  'GE',
  'Hotpoint',
  'Kenmore',
  'Kitchen Aid',
  'LG',
  'Maytag',
  'Samsung',
  'Whirlpool',
];

type Args = {
  onChange: (key: string, value: unknown) => void;
  postalCode: string;
  serviceId: string;
  startDate: mt.MomentInput;
  serviceName: string | undefined;
  brand?: Brand;
};

type Response = {
  availability: Record<string, number>;
  inactiveRegion: boolean;
  inactiveRegionReason: string | null;
};

export default function useLoadAvailability({
  onChange,
  postalCode,
  serviceId,
  startDate,
  serviceName,
  brand,
}: Args): [() => Promise<void>, { loading: boolean }] {
  const [loading, setLoading] = useState(false);
  const tz = U.timezone({ postalCode });

  const loadAvailability = useCallback(async () => {
    setLoading(true);
    const response = await U.api<Response>('post', 'availability', {
      address: { postalCode },
      startTime: startDate,
      endTime: mt(startDate).tz(tz).add(15, 'd').valueOf(),
      serviceId,
    });
    const unsupportedBrand = !!brand && !SUPPORTED_BRANDS.includes(brand);
    if (unsupportedBrand || response.inactiveRegion || 'errMsg' in response) {
      loggedOutEvent().track('v1_b2cBookingFlowNoAvailability', {
        reason: unsupportedBrand
          ? 'UNSUPPORTED_BRAND'
          : response.inactiveRegionReason || 'INVALID_ZIP',
        brand,
        service_name: serviceName,
        zip: postalCode,
      });
      onChange('noAvailability', true);
    } else {
      const timesWithAvailability = _.pickBy(response.availability, value => value);
      const startOfToday = mt().tz(tz).startOf('d');
      const numAvailableByDaysFromToday = _.countBy(
        _.toPairs(timesWithAvailability),
        ([timestamp]) => mt(+timestamp).tz(tz).startOf('d').diff(startOfToday, 'd')
      );
      const capacity = _.size(timesWithAvailability) / _.size(response.availability);
      loggedOutEvent().track('v1_b2cBookingFlowAvailability', {
        capacity: `${Math.round(capacity * 100)}%`,
        num_avail_by_days_from_today: numAvailableByDaysFromToday,
        service_name: serviceName,
        zip: postalCode,
      });
      onChange('availability', response.availability);
    }
    setLoading(false);
  }, [postalCode, startDate, tz, serviceId, brand, serviceName, onChange]);

  return [loadAvailability, { loading }];
}
