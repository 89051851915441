import React, { useState } from 'react';
import { T, Task, U, WorkOrder } from '@nanaio/util';
import moment from 'moment';
import Alert from '@/components/core/Alert';
import Badge from '@/components/core/Badge';
import Button from '@/components/core/Button';
import Icon from '@/components/core/Icon';
import Text from '@/components/core/Text';
import ScheduleModal from './ScheduleModal';

type Props = {
  task: Task;
  workOrder: WorkOrder;
};

export default function ScheduleAlert({ task, workOrder }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    U.redux.set('form.waitingOnScheduleFlow', true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSuccess = () => {
    // Close the Schedule window right away if the previous technician survey has been answered.
    // Otherwise leave it open and it will call the survey.
    // TODO move this functionality to the schedule widget itself, not really any good reason for this to be in the parent.
    if (!!task.metadata?.taskQuestions?.hadPreviousTechnician) {
      setIsOpen(false);
    }
  };

  const visits = W.uncancelledVisits(workOrder);
  const hasFutureAvailability = visits?.some(visit =>
    visit.cx.availability.some(slot => moment(slot.start).isAfter(moment()))
  );
  const statusAllowsScheduling = [
    T.Status.WAITING_ON_SCHEDULE,
    T.Status.PARTS_SHIPPED,
    T.Status.READY_FOR_FOLLOWUP,
  ].includes(task.status);

  if (hasFutureAvailability || !statusAllowsScheduling) {
    return <></>;
  }

  const timezone = U.timezone(task.serviceAddress);
  const parts = task.parts?.itemsArray ?? [];
  const hasShippedParts = parts.some(part => part.status === T.PartStatus.SHIPPED);
  const eta = task.parts?.overallEta ? moment(task.parts?.overallEta).tz(timezone) : undefined;
  const hadDiagnosisVisit = !!visits.length && !!task.metadata.taskQuestions?.diagnosis?.length;

  return (
    <>
      <Alert hideIcon variant="warning" className="mb-4">
        <div className="flex flex-col items-start lg:flex-row">
          <div className="flex-1 pr-3">
            <div className="flex flex-col items-start lg:flex-row">
              <Text type="page-title" className="mb-2 mr-3 ">
                {hadDiagnosisVisit || hasShippedParts
                  ? 'Schedule your Repair Appointment'
                  : 'Schedule your Diagnostic Appointment'}
              </Text>
              <Badge
                className="order-first mb-3 flex lg:order-last lg:-mt-1 lg:mb-0"
                color="accent-orange"
                iconProps={{ name: 'warning_amber' }}
                size="medium"
                variant="solid-faded"
              >
                Action Needed
              </Badge>
            </div>

            <Text type="body-2" color="grey.dark" className="mb-4 lg:mb-0">
              {hasShippedParts
                ? 'Your parts have been shipped. Please schedule your appointment based on the estimated arrival time of your parts. Please note most parts take 3-5 business days to be delivered. If you are unavailable for any of these times please contact our support team via the message bubble in the bottom right of your screen and type ‘select new appointment times.'
                : 'Please provide your availability for your diagnostic appointment. We will notify you once your appointment has been confirmed.'}
            </Text>
          </div>

          <div className="flex flex-col self-stretch lg:self-start" style={{ flexBasis: 0 }}>
            <Button size="medium" className="mb-4 self-stretch px-4" onClick={handleOpen}>
              <div className="flex justify-center">
                <Icon color="white" name="calendar_month" className="mr-2" />
                <Text noWrap color="white" type="smallButton" className="flex justify-center">
                  Provide Availability
                </Text>
              </div>
            </Button>

            {hasShippedParts && eta && (
              <div className="flex space-x-2">
                <Icon name="info_outline" color="accent.orange" className="mr-1" />
                <Text className="flex items-start self-stretch" type="helper">
                  <span>
                    Your parts are estimated to arrive on{' '}
                    <b>
                      {eta.format('MMM D, YYYY')} by {eta.format('h:mma')}
                    </b>
                    .
                  </span>
                </Text>
              </div>
            )}
          </div>
        </div>
      </Alert>

      <ScheduleModal
        isOpen={isOpen}
        onClose={handleClose}
        onSuccess={handleSuccess}
        variant="newVisit"
      />
    </>
  );
}
