import { datadogRum } from '@datadog/browser-rum';
import { Me, Task, U, WorkOrder } from '@nanaio/util';
import type { Data, LoggerWithCapture } from '@nanaio/util/dist/analytics';
import * as Sentry from '@sentry/browser';
import { CaptureConsole, Dedupe } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import _ from 'lodash';
import { isDevBuild } from '@/config/const';

const { augmentData, Logger } = U.analytics;

const BREADCRUMB_WHITELIST = new Set(['xhr', 'fetch', 'event', 'navigation']);

const payloadGetterByKey = {
  task: (task: Task) => ({
    task_id: task.id,
  }),
  me: (me: Me, data: Data) => {
    const features = _.transform(
      data.features || {},
      (props: Record<string, boolean>, value: unknown, key: string) => {
        props[_.snakeCase(key)] = Boolean(value);
      }
    );
    return {
      // some of these are redundant with what is included from LoggableException to
      // ensure errors captured from Sentry.Native.ErrorBoundary include the same payloads
      ...data.route?.props,
      environment: data.environment,
      features,
      page: data.route?.page,
      // TODO add email to me
      me: _.pick(me, 'trueMe.userId'),
      user: {
        id: me.userId,
        email: data.pro?.user?.email || data.user?.email,
        username: data.pro?.user?.fullName || data.user?.profile?.fullName,
      },
    };
  },
  workOrder: (workOrder: WorkOrder) => ({
    work_order_id: workOrder.id,
  }),
};

class SentryLogger extends Logger implements LoggerWithCapture {
  capture(error: Error | string, props: Record<string, unknown> = {}) {
    if (_.isString(error)) {
      Sentry.captureMessage(error, { extra: props });
    } else {
      Sentry.captureException(error, { extra: props });
    }
  }

  identify() {}

  reset() {}

  navigate(event: string, _data: Data, _props: Record<string, unknown>): void {
    Sentry.addBreadcrumb({
      category: 'navigation',
      data: { event },
    });
  }

  start() {
    Sentry.init({
      autoSessionTracking: true,
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (!breadcrumb.category || !BREADCRUMB_WHITELIST.has(breadcrumb.category)) {
          // null is filtered by sentry
          return null;
        }

        if (hint?.input) {
          if (hint.input instanceof FormData) {
            const input: Record<string, unknown> = {};
            for (const [key, value] of hint.input.entries()) {
              input[key] = value;
            }
            // attempt to enrich breadcrumb data
            breadcrumb = { ...breadcrumb, data: { ...breadcrumb.data, input } };
          } else if (Array.isArray(hint.input)) {
            breadcrumb = { ...breadcrumb, data: { ...breadcrumb.data, input: hint.input } };
          }
        }

        return breadcrumb;
      },
      beforeSend: event => {
        // pull what we can from redux
        const data = augmentData(isDevBuild);
        const { environment, user, ...extra } = this.getPayload(data, event.extra || {});
        if (isDevBuild) {
          // eslint-disable-next-line no-console
          console.log(...(event.exception?.values || []));
          // eslint-disable-next-line no-console
          console.log(environment, user, extra);
        }
        Object.assign(event, { environment, extra, user });
        return event;
      },
      debug: isDevBuild,
      dsn: 'https://478cc767cd5a410b91253fcfd41c0f3d@o312845.ingest.sentry.io/1783401',
      integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] }), new Dedupe()],
      normalizeDepth: 5,
      release: process.env.REACT_APP_GITHUB_SHA,
      tracesSampleRate: 0.1,
    });

    const datadogRumSessionId = datadogRum.getInternalContext()?.session_id;

    if (datadogRumSessionId) {
      Sentry.setContext('Datadog RUM', {
        'Session Replay': `https://app.datadoghq.com/rum/replay/sessions/${datadogRumSessionId}`,
        'Session ID': datadogRumSessionId,
      });
      Sentry.setTag('dd.session', datadogRumSessionId);
    }
  }

  track(event: string, _data: Data, props: Record<string, unknown>): void {
    // for breadcrumbs we don't want the full payload, just any event specific props
    // so we stub data but still run the props through getPayload so they're fully sanitized
    const payload = this.getPayload({}, props);
    Sentry.addBreadcrumb({
      category: 'event',
      data: { ...payload, event },
    });
  }
}
// @ts-expect-error TODO use mappable type instead of record in order to support optional keys
export default new SentryLogger(payloadGetterByKey);
