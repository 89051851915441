import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import Chip, { Props } from './Chip';

export default {
  title: 'Core/Chip',
  component: Chip,
  args: {
    className: 'max-w-[80px]',
    onClick: () => alert('onClick'),
    children: '8-12 PM',
    variant: 'primary',
  },
} as ComponentMeta<typeof Chip>;

const Template: ComponentStory<typeof Chip> = args => <Chip {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Variants = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Variant prop options</p>
    <p className="mt-6">primary</p>
    <Default {...args} variant="primary" />
    <p className="mt-6">secondary</p>
    <Default {...args} variant="secondary" />
    <p className="mt-6">disabled</p>
    <Default {...args} disabled />
  </div>
);
