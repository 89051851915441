import React, { createElement, ReactElement, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import Textarea from 'react-textarea-autosize';
import U from '@nanaio/util';
import classnames from 'classnames';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import type { Props as IconProps } from '../core/Icon';
import Icon from '../core/Icon';
import Text from '../core/Text';
import theme from '../theme';
import { InputProps, Type } from './Input'; // eslint-disable-line import/no-cycle

// convert raw value to a user-friendly display
const formatDisplay = {
  email: (value?: string) => value,
  money: (value?: number) => {
    if (value === undefined) {
      return '';
    }
    return value ? value / 100 : value;
  },
  number: (value?: number) => (value === undefined ? '' : value),
  percent: (value?: number) => {
    if (value === undefined) {
      return '';
    }
    return _.isNumber(value) ? _.round(value * 100) : value;
  },
  phone: (value?: string) => {
    if (!value) {
      return '';
    }
    const trimmed = U.trimPhone(value);
    const newValue = _.times(10, i => trimmed[i] || ' ').join('');
    return `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6, 10)}`;
  },
  text: (value?: string) => value,
  zipCode: (value?: string) => value,
};

// convert user-friendly display to raw value
const formatValue = {
  email: (value?: string | undefined) => value,
  money: (value?: number | undefined) => (value === undefined ? undefined : Number(value) * 100),
  number: (value?: number | undefined) => (value === undefined ? undefined : Number(value)),
  percent: (value?: number | undefined) => (value === undefined ? undefined : Number(value) / 100),
  phone: (value?: string | undefined) => U.trimPhone(value),
  text: (value?: string | undefined) => value,
  zipCode: (value?: string | undefined) =>
    value === undefined ? undefined : _.replace(value, /\D/g, '').slice(-5),
};

export const getProps = ({ inputRef, onChange, type, value, ...rest }: InputProps): Props => {
  const base = {
    inputRef,
    onChange: nullthrows(onChange) as (value: Value) => void,
  };
  if (type === Type.MONEY || type === Type.NUMBER || type === Type.PERCENT) {
    return {
      ...base,
      type,
      value: value as number | undefined,
      ...rest,
    };
  }
  return {
    ...base,
    type: type as Type.EMAIL | Type.PHONE | Type.TEXT | Type.ZIP_CODE,
    value: value as string | undefined,
    ...rest,
  };
};

export const isValid = {
  [Type.EMAIL]: (value: string): boolean => !value || U.emailRegex.test(value),
  [Type.PHONE]: (value: string): boolean => !value || U.phoneRegex.test(value),
};

const typeToSuffix = { money: '$', percent: '%' };

const typeToInputType = { money: Type.NUMBER, number: Type.NUMBER, percent: Type.NUMBER };

const getMask = (type?: string) => {
  let mask;

  if (type === Type.PHONE) {
    mask = '(999) 999-9999';
  } else if (type === Type.ZIP_CODE) {
    mask = '99999';
  }

  return mask;
};

export const types = [
  Type.EMAIL,
  Type.MONEY,
  Type.NUMBER,
  Type.PERCENT,
  Type.PHONE,
  Type.TEXT,
  Type.ZIP_CODE,
];

const lineHeight = '38px';
const IconSide = { LEFT: 'left', RIGHT: 'right' };

type ControlIconProps = {
  color?: keyof typeof theme.flatColors;
  inline?: boolean;
  name: IconProps['name'];
  side: (typeof IconSide)[keyof typeof IconSide];
};

function ControlIcon({ color = 'secondary', inline, name, side }: ControlIconProps): ReactElement {
  const style = {
    ...(theme.flatColors[color] ? { color: theme.flatColors[color] } : {}),
    lineHeight,
  } as React.CSSProperties;

  if (inline) {
    style.top = '50%';
    style.transform = 'translateY(-50%)';
  }

  const classes = classnames('absolute top-2.5', {
    'left-0': side === IconSide.LEFT && inline,
    'left-4': side === IconSide.LEFT && !inline,
    'right-0': side === IconSide.RIGHT && inline,
    'right-4': side === IconSide.RIGHT && !inline,
  });

  return <Icon name={name} className={classes} style={style} />;
}

type BaseProps = {
  className?: string;
  cypressId?: string;
  debounceWait?: number;
  disabled?: boolean;
  error?: string;
  id?: string;
  inline?: boolean;
  innerClassName?: string;
  inputRef?: (ref: HTMLInputElement | HTMLTextAreaElement | InputMask | null) => void;
  leftIcon?: IconProps['name'];
  minLength?: number;
  multiline?: boolean;
  onChange: (value: Value) => void;
  onClick?: () => void;
  onDebounce?: (value: Value) => void;
  onEnter?: (value: Value) => void;
  onFocus?: () => void;
  placeholder?: string;
  showCharacterCount?: boolean;
};

type EmailProps = BaseProps & { type: Type.EMAIL; value?: string };
type MoneyProps = BaseProps & { type: Type.MONEY; value?: number };
type NumberProps = BaseProps & { type: Type.NUMBER; value?: number };
type PercentProps = BaseProps & { type: Type.PERCENT; value?: number };
type PhoneProps = BaseProps & { type: Type.PHONE; value?: string };
type TextProps = BaseProps & { type?: Type.TEXT; value?: string };
type ZipCodeProps = BaseProps & { type: Type.ZIP_CODE; value?: string };

export type Props =
  | EmailProps
  | MoneyProps
  | NumberProps
  | PercentProps
  | PhoneProps
  | TextProps
  | ZipCodeProps;

export type Value = number | string | undefined;

export default function TextInput({
  className = '',
  cypressId,
  debounceWait,
  disabled,
  error,
  id,
  inline,
  innerClassName,
  inputRef,
  leftIcon,
  multiline,
  onChange,
  onClick,
  onDebounce,
  onEnter,
  onFocus,
  placeholder,
  type = Type.TEXT,
  value,
}: Props): ReactElement {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
  const [stateValue, setStateValue] = useState<typeof value>(value);

  useEffect(() => {
    if (value !== stateValue) {
      setStateValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
  // adding stateValue to useEffect dependencies will break the component

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value: Value;
    if (type === Type.MONEY || type === Type.NUMBER || type === Type.PERCENT) {
      value = formatValue[type](event.target.value === '' ? undefined : Number(event.target.value));
    } else {
      value = formatValue[type](event.target.value === '' ? undefined : event.target.value);
    }
    setStateValue(value);
    const debounceContextUpdate = debounceWait && !onDebounce;
    if (onDebounce || debounceContextUpdate) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(
        setTimeout(() => {
          if (debounceContextUpdate) {
            onChange(value);
          }
          if (onDebounce) {
            onDebounce(value);
          }
        }, debounceWait)
      );
    }
    if (onChange && !debounceContextUpdate) {
      onChange(value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onEnter && (event.keyCode || event.which === 13)) {
      onEnter((event.target as HTMLTextAreaElement).value as string | number);
    }
  };

  const style = {
    ...theme.formControl,
    backgroundColor: theme.colors.white,
    color: disabled ? theme.colors.icons.grey : theme.colors.font.dark,
  };

  const component = multiline ? Textarea : 'input';
  const mask = getMask(type);
  placeholder = placeholder || type !== Type.PHONE ? placeholder : '(       )        -';

  const classes = classnames('relative flex flex-row', className);
  const componentClasses = classnames(
    {
      'mt-1 w-full border-transparent bg-transparent p-0 focus:outline-none': inline,
      'pl-8': leftIcon && inline,
      'pl-12': leftIcon && !inline,
      'pr-8': error && inline,
      'border-danger pr-12': error && !inline,
    },
    innerClassName
  );

  let propValue;
  if (type === Type.MONEY || type === Type.NUMBER || type === Type.PERCENT) {
    propValue = formatDisplay[type](stateValue as number);
  } else {
    propValue = formatDisplay[type](stateValue as string);
  }

  const props = _.pickBy(
    {
      className: componentClasses,
      'data-cy': cypressId,
      disabled,
      id,
      mask,
      maxRows: multiline ? 5 : undefined,
      minRows: multiline ? 2 : undefined,
      onChange: handleChange,
      onFocus,
      onKeyPress: handleKeyPress,
      placeholder,
      ref: inputRef,
      style,
      type: typeToInputType[type as keyof typeof typeToInputType],
      value: propValue === undefined ? '' : propValue,
    },
    value => value !== undefined
  );

  return (
    <div className={classes} onClick={onClick}>
      {leftIcon && (
        <ControlIcon inline={inline} name={leftIcon} side={IconSide.LEFT} color="grey.dark" />
      )}

      <div className="w-full">
        {mask ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <InputMask {...props} mask={mask} ref={inputRef} />
        ) : (
          //@ts-expect-error props type is not sufficiently narrow here
          createElement(component, props)
        )}
        {type in typeToSuffix && !error && (
          <Text className="absolute top-0 right-0 mr-4" style={{ lineHeight }}>
            {typeToSuffix[type as keyof typeof typeToSuffix]}
          </Text>
        )}
      </div>

      {error ? (
        <ControlIcon inline={inline} name="alert_circle" side={IconSide.RIGHT} color="danger" />
      ) : null}
    </div>
  );
}
