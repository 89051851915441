import React from 'react';
import { Icon } from '@/components';
import { emailRe } from '../../../config/const';

export default class Step4 extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      value: '',
      password: '',
      confirm: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  onChange(id, e) {
    if (id === 'accept') {
      this.setState({ accept: !this.state.accept });
    } else if (id === 'value') {
      this.setState({ value: e.target.value.toLowerCase() });
    } else {
      this.setState({ [id]: e.target.value });
    }
  }

  async onSubmit() {
    const { type, email, phone } = this.props.getStore();
    const requireInput = !(type === 'email' ? email : phone);
    const { value, password, confirm, accept } = this.state;
    if (requireInput) {
      if (!value) {
        return this.setState({ error: `${type === 'email' ? 'Phone' : 'Email'} required` });
      }
      if (type === 'email' && value.replace(/\D/g, '').length !== 10) {
        return this.setState({ error: '10-digit phone required' });
      }
      if (type === 'phone' && !emailRe.test(value)) {
        return this.setState({ error: 'Invalid email' });
      }
    }
    if (!password) {
      return this.setState({ error: 'Password required' });
    }
    if (password.length < 8) {
      return this.setState({ error: '8-character password required' });
    }
    if (!/[A-Z]/.test(password)) {
      return this.setState({ error: 'Password must contain uppercase' });
    }
    if (!/[a-z]/.test(password)) {
      return this.setState({ error: 'Password must contain lowercase' });
    }
    if (!/[0-9]/.test(password)) {
      return this.setState({ error: 'Password must contain number' });
    }
    if (password !== confirm) {
      return this.setState({ error: 'Passwords do not match' });
    }
    if (!accept) {
      return this.setState({ error: 'Please accept terms and conditions' });
    }
    const out = { password, confirm };
    if (requireInput) {
      out[type === 'email' ? 'phone' : 'email'] = value;
    }
    this.props.updateStore(out);
    this.props.jumpToStep(5);
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  render() {
    const t = this;
    const { type, email, phone } = t.props.getStore();
    const requireInput = !(type === 'email' ? email : phone);
    return (
      <section>
        <div className="container">
          <div className="text-left">
            <button className="back-button" onClick={t.goBack}>
              <Icon name="arrow_left" size={30} />
            </button>
          </div>
          <div className="animatedContent">
            <h1>Complete your Profile</h1>
            <h3>Please create a password to access your account.</h3>
            <ul className="no-list multi-inputs">
              {requireInput && (
                <li>
                  <input
                    type="text"
                    placeholder={type === 'email' ? 'Phone' : 'Email'}
                    className="user-input"
                    value={t.state.value}
                    onChange={e => t.onChange('value', e)}
                    autoFocus
                  />
                  <div className="user-check-icon">
                    <Icon name="check_circle" size={16} />
                  </div>
                </li>
              )}
              <li>
                <input
                  type="password"
                  placeholder="New password (8 character min)"
                  className="pass-input"
                  value={t.state.password}
                  onChange={e => t.onChange('password', e)}
                />
              </li>
              <li>
                <input
                  type="password"
                  placeholder="Confirm new password"
                  className="pass-input"
                  value={t.state.confirm}
                  onChange={e => t.onChange('confirm', e)}
                />
              </li>
            </ul>
            <br />
            <div className="text-danger">{t.state.error}</div>

            <p className="d-flex justify-content-center align-center mt-3">
              {t.state.agree ? (
                <Icon
                  color="#000"
                  name="check_box_outline"
                  className="pointer mr-3"
                  onClick={() => this.onChange('agree')}
                />
              ) : (
                <Icon
                  color="#000"
                  name="check_box_outline_blank"
                  className="pointer mr-3"
                  onClick={() => this.onChange('agree')}
                />
              )}
              <span style={{ marginLeft: '1em' }}>
                I accept the{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </a>
              </span>
            </p>
            <button onClick={t.onSubmit} className="primary mt-4 flex items-center">
              <Icon name="lock" size={16} /> Secure my Account
            </button>
          </div>
        </div>
      </section>
    );
  }
}
