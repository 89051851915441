import React from 'react';
import { connect } from 'react-redux';
import { Col,Row } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Icon, Paper } from '@/components';
import { Bool } from '../../com/ui/form';
import Link from '../../com/ui/link';

class ReviewEdit extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = this.props.review || {};
    this.updateIsRead = this.updateIsRead.bind(this);
  }

  componentWillMount() {
    U.api('get', `reviews/${global.id()}`, true);
    if (this.props.review && !this.props.job) {
      U.search({ id: this.props.review.refId, limit: 100, save: true });
    }
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(this.props.review, p.review)) {
      this.setState(p.review);
    }
    if (p.review && !this.props.review) {
      U.search({ id: p.review.refId, limit: 100, save: true });
    }
  }

  updateIsRead() {
    const changes = [
      {
        action: 'replace',
        path: 'metadata.isRead',
        value: !_.get(this.props.review, 'metadata.isRead'),
      },
    ];
    U.api('put', `reviews/${global.id()}`, changes, true);
  }

  render() {
    return (
      <section className="reviews-edit-page">
        <nav>
          <Row style={{ width: '100%' }}>
            <Col
              className="nav-item  align-items-center -inline-flex flex-1 text-left"
              style={{ justifyContent: 'flex-start' }}
            >
              <IconButton aria-label="Go Back" href="/reviews">
                <Icon name="arrow_left" color="#9499A3" size={25} />
              </IconButton>
            </Col>
            <Col
              className="nav-item d-inline-flex align-items-center flex-1 text-center"
              style={{ justifyContent: 'center' }}
            >
              <div className="title">Edit Review</div>
            </Col>
            <Col
              className="nav-item d-inline-flex align-items-center flex-1 text-right"
              style={{ justifyContent: 'flex-end' }}
            >
              <Link className="nav-button" to={`/tasks/${this.state.refId}`}>
                Open Job
              </Link>
            </Col>
          </Row>
        </nav>
        <div className="container">
          <Paper className="reviews-edit-card m-2 p-2" variant="elevated">
            <div className="reviews-title">
              <div className="reviews-avatar">
                <Icon name="account_circle" color="#5195FF" size={50} />
              </div>
              <h2>
                {_.startCase(_.get(this.props.job, 'customer.user.fullName', 'Unknown Name'))}
              </h2>
              <label>
                {_.get(this.state, 'task.customer.org.name', 'B2C Customer')} &bull; Created{' '}
                {m(this.state.createTime).format('M/D/YY h:mm A')} (
                {m(this.state.createTime).fromNow()})
              </label>
              <br />
              <br />
              <Link className="nav-button d-block" to={`/tasks/${this.state.refId}`}>
                View Job
              </Link>
            </div>
            <div className="reviews-body">
              <div className="rating-stars flex flex-row">
                {_.times(this.state.rating, () => {
                  return <Icon name="star" color="#FFBD48" size={25} />;
                })}
                {_.times(5 - this.state.rating, () => {
                  return <Icon name="star" color="#DDE1E7" size={25} />;
                })}
              </div>
              {this.state.message ? (
                <p>{this.state.message}</p>
              ) : (
                <p className="text-muted">
                  The user did not write a review, and has left just a rating
                </p>
              )}
            </div>

            {this.state.tags && (
              <div className="border-box">
                <label>Tagged as:</label>
                <p>{this.state.tags}</p>
              </div>
            )}

            {_.get(this.state, 'task.metadata.workOrderNumber') && (
              <div className="border-box">
                <label>Work Order Number:</label>
                <p>{_.get(this.state, 'task.metadata.workOrderNumber')}</p>
              </div>
            )}

            <div className="reviews-footer">
              <div className="reviews-footer-status">
                <label>Mark review as read:</label>
                <Bool id="metadata.isRead" label="" onChange={this.updateIsRead} />
              </div>
            </div>
          </Paper>
        </div>
      </section>
    );
  }
}

export default connect(s => {
  const review = s.reviews[global.id()];
  const job = s.tasks[_.get(review, 'refId')];
  return { job, review };
})(ReviewEdit);
