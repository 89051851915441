import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

class Percent extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({ value: _.get(this.context.t.state, this.props.id) });
  }

  componentWillReceiveProps(p) {
    this.setState({ value: _.get(this.context.t.state, p.id) });
  }

  onChange(e) {
    const value = e.target.value === '' ? undefined : +e.target.value / 100;
    this.setState({ value });
    const p = this.props;

    if (this.props.debounce) {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => p.debounce(value), p.debounceLength || 1000),
      });
    } else {
      onChange(this.context.t, p.id, value);
    }
    if (p.onChange) {
      p.onChange(value);
    }
  }

  render() {
    const p = this.props;
    const { t } = this.context;
    const { value } = this.state;
    return (
      <Group {...p} suffix="%">
        <input
          className="form-control"
          type="number"
          value={value === undefined ? '' : _.isNumber(value) ? _.round(value * 100) : ''}
          onChange={this.onChange}
          placeholder={p.hint || _.get(p.options, '[0]')}
          disabled={p.disabled}
          onKeyPress={e =>
            p.enter && (e.keyCode || e.which === 13) && p.enter(_.get(t.state, p.id, ''))
          }
        />
      </Group>
    );
  }
}

Percent.contextTypes = { t: T.object.isRequired };

Percent.propTypes = {
  debounce: T.func,
  debounceLength: T.number,
  enter: T.func,
  id: T.string.isRequired,
  label: T.string,
  req: T.bool,
  type: T.string,
  value: T.number,
};

export default Percent;
