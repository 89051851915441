import React, { useState } from 'react';
import { Task, U } from '@nanaio/util';
import _ from 'lodash';
import { APIError, FormControl, Icon, Modal, SearchInput, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

type Props = {
  taskId: string;
};

export default function ChangeService({ taskId }: Props): JSX.Element {
  const services = useLegacySelector(state => state.services);
  const options = _.sortBy(
    _.values(services).filter(v => U.service.isLeaf(services, v.id)),
    'name'
  );

  const tasks = useLegacySelector(state => state.tasks);
  const initialValue = tasks[taskId].serviceCatalogs[0].id;

  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${taskId}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  const submit = async () => {
    setError('');
    if (!value) {
      return setError('Service required');
    }
    const service = services[value];
    const changes = [
      {
        action: 'replace',
        path: 'serviceCatalogs',
        value: [{ _id: service.id, id: service.id, name: service.name }],
      },
    ];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <div className="flex items-center">
        <Icon className="ml-2" name="edit" onClick={() => setIsOpen(true)} />
      </div>
      <Modal isOpen={isOpen} onSave={submit} onClose={() => setIsOpen(false)}>
        <Modal.Header title="Change Service" />
        <Modal.Body className="p-4">
          <Text className="mb-4" color="danger" type="button">
            {error}
          </Text>
          {taskToUpdate.error}
          <FormControl label="Service" required>
            <SearchInput
              options={options}
              value={value}
              onChange={value => setValue(value as string)}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}
