export const colors = {
  black: '#000',
  blue: '#0F63C4',
  border: '#F0F0F0',
  green: '#59AE49',
  grey: '#868686',
  red: '#DB2A2A',
  white: '#FFF',
  avatar: [
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#ecf0f1',
    '#95a5a6',
  ],
};
