import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Cell from '../Cell';

const styles = {
  slot: {
    width: 116,
    backgroundImage: 'url("/img/DayOffStripes.png")',
    backgroundSize: '100% 100%',
  },
};

export default function Day({ startTimes }) {
  return (
    <Cell className="flex flex-row" date={startTimes[0]}>
      <div className="flex flex-row border-b border-grey-medium">
        {startTimes.map(time => {
          return <div className="h-24 cursor-pointer" key={time} style={styles.slot} />;
        })}
      </div>
    </Cell>
  );
}

Day.propTypes = {
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
};
