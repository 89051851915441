import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Slot, T, Task, U, Visit, W } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import mt, { MomentInput } from 'moment-timezone';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { Alert, Loader, Text } from '@/components';
import { supportEmail } from '@/config/const';
import { useLegacySelector } from '@/hooks';
import ScheduleContainer from '@/pages/book/steps-V2/Availability/ScheduleContainer';
import SelectedDates from '@/pages/book/steps-V2/Availability/SelectedDates';
import useLoadAvailability, { AvailabilityResponse } from './hooks/useLoadAvailability';

export type SchedulePickerRef = {
  submit: () => Promise<void>;
};

type SchedulingState = {
  availability?: AvailabilityResponse['availability'];
  availTSlots: AvailabilityResponse['availability'];
  firstAvailableTime?: number;
  loadMore?: boolean;
};

type Props = {
  onError?: (errorMessage: string | undefined) => void;
  onSuccess?: (() => Promise<void>) | (() => void);
  task?: Task;
  variant: 'newVisit' | 'confirm' | 'reschedule';
};

function SchedulePickerContent({
  task: taskProp,
  onError,
  onSuccess,
  variant,
  forwardedRef,
}: Props & { forwardedRef: ForwardedRef<SchedulePickerRef> }): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = taskProp || tasks[nullthrows(global.id())];
  const timezone = U.timezone(task.serviceAddress);
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];
  const timeSlotSelectionErrorMessage = _.includes(
    workOrder?.tags,
    W.Tag.EXPERIMENTAL_SAMSUNG_BOOKING_FLOW
  )
    ? `Please select 3 or more timeslots. 2 of which must be 24 hours in the future. If you are unavailable for any of these times please contact our support team via email at ${supportEmail} with your available dates and times`
    : 'Please select 3 or more timeslots, 2 of which must be 24 hours in the future';
  const lastVisit = W.lastVisit(workOrder);
  const proId = W.proId(workOrder);
  const pro = lastVisit && lastVisit.pros.find(pro => pro.id === proId);
  let proAvailabilityArray: Slot[] = [];
  if (pro) {
    proAvailabilityArray = pro.availability || [];
  }
  const proAvailability = _.keyBy(proAvailabilityArray, slot => mt(slot.start).valueOf());

  // TODO Refactor to get this from Util
  const slotsRequired = 2;

  const initSchedulingState: SchedulingState = {
    availTSlots: {},
  };

  const [daysToLoad, setDaysToLoad] = useState(16);

  const [state, setState] = useState<SchedulingState>(initSchedulingState);

  const [error, setError] = useState<string>();

  const onChange = useCallback((id: string, value: unknown) => {
    setState(state => _.set({ ...state }, id, value));
  }, []);

  const [loadAvailability, { loading, error: availabilityError }] = useLoadAvailability({
    firstAvailableTime: state.firstAvailableTime,
    isBookingExperiment: _.includes(workOrder?.tags, W.Tag.EXPERIMENTAL_SAMSUNG_BOOKING_FLOW),
    minScheduleTime: nullthrows(T.minScheduleTime(task)).valueOf(),
    onChange,
    startDate: T.minScheduleDate(nullthrows(T.minScheduleTime(task)).valueOf()),
    task,
  });

  useEffect(() => {
    if (!state.availability && !loading) {
      void loadAvailability(daysToLoad);
    }
  }, [daysToLoad, loadAvailability, loading, state.availability]);

  useEffect(() => {
    if (state.loadMore) {
      setDaysToLoad(daysToLoad + 16);
      onChange('loadMore', false);
      void loadAvailability(daysToLoad + 16);
    }
  }, [daysToLoad, loadAvailability, onChange, state.loadMore]);

  const submit = async () => {
    const { firstAvailableTime } = state;

    const selectedTimes = _.keys(_.pickBy(state.availTSlots));
    if (loading) {
      return;
    }
    let bypassWarning;
    if (variant === 'confirm' && selectedTimes.find(startTime => proAvailability[+startTime])) {
      bypassWarning = true;
    }
    const minScheduleTime: MomentInput = T.minScheduleTime(task);
    const slots = selectedTimes.map(startTime => ({
      startTime: mt(+startTime),
      endTime: mt(+startTime).clone().add(4, 'h'),
    }));

    if (slots.length < slotsRequired) {
      setError(timeSlotSelectionErrorMessage);
      if (onError) {
        onError(timeSlotSelectionErrorMessage);
      }
      return;
    }
    if (
      !bypassWarning &&
      !T.availTSlotsAreValid({
        minScheduleTime,
        slots,
      })
    ) {
      setError(timeSlotSelectionErrorMessage);
      if (onError) {
        onError(timeSlotSelectionErrorMessage);
      }
      return;
    }

    const isReschedule = ['confirm', 'reschedule'].includes(variant) && workOrder.visits.length;

    // construct visit
    const visit = isReschedule ? {} : W.createFollowup(workOrder);
    const previousVisit = _.cloneDeep(visit);
    const availability = selectedTimes.sort().map((startTime, i) => ({
      start: mt(+startTime).valueOf(),
      end: mt(+startTime).add(4, 'h').valueOf(),
      preferred: i === 0,
    }));
    visit.cx = {
      availability,
      firstOfferedTime: mt(firstAvailableTime).toDate(),
      status: W.VisitCustomerStatus.CONFIRMED,
    };
    if (isReschedule) {
      const lastVisit = nullthrows(W.lastVisit(workOrder));
      visit.id = lastVisit.id;
      visit.pros = lastVisit.pros;
    }
    [visit.slot] = availability;

    let response;
    if (isReschedule) {
      response = await U.api<Visit>(
        'put',
        `workOrders/${workOrder.id}/visits/${nullthrows(visit.id)}`,
        visit,
        ['save']
      );
    } else {
      response = await U.api<Visit>('post', `workOrders/${workOrder.id}/visits`, visit, ['save']);
    }
    void U.api('put', `workOrders/${workOrder.id}/minScheduleTime`, {
      minScheduleTime,
    });
    if (!response || 'errMsg' in response) {
      setError(response.errMsg);
      if (onError) {
        onError(response.errMsg);
      }
      return;
    }
    const eventInfo = {
      variant: variant,
      previous_visit: previousVisit,
      visit: visit,
    };
    workOrderEvent().track('v1_workOrderTaskAvailabilitySubmitted', eventInfo);
    if (onSuccess) {
      void onSuccess();
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    submit: async () => {
      await submit();
    },
  }));

  const handleToggleSlot = (date: string) => {
    const isActive = !!_.get(state, `availTSlots.${date}`);
    onChange(`availTSlots.${date}`, !isActive);
  };

  if (!timezone || !state.availability) {
    return <Loader isLoading fullscreen />;
  }
  const selectedDates = _.keys(_.pickBy(state.availTSlots));

  return (
    <div>
      {availabilityError && (
        <Alert className="mb-4" variant="error">
          {availabilityError}
        </Alert>
      )}
      {!onError && error && (
        <Alert className="mb-4" variant="error">
          {error}
        </Alert>
      )}
      {state.availability && (
        <>
          <ScheduleContainer
            onChange={onChange}
            onToggleSlot={handleToggleSlot}
            state={{
              availability: state.availability,
              zip: { value: task.serviceAddress.postalCode },
              availTSlots: state.availTSlots,
            }}
          />
          <div className="mb-6 mt-4 flex justify-between">
            <Text className="text-grey-dark" type="helper">
              Minimum of {slotsRequired} times must be provided.
            </Text>
            <Text
              className={classNames({
                'text-danger': selectedDates.length < slotsRequired,
                'text-success': selectedDates.length >= slotsRequired,
              })}
              type="helper"
            >
              {selectedDates.length}/{slotsRequired}
            </Text>
          </div>

          <hr />
          <SelectedDates
            onClick={handleToggleSlot}
            state={{
              zip: { value: task.serviceAddress.postalCode },
              availTSlots: state.availTSlots,
            }}
          />
        </>
      )}
    </div>
  );
}

export default forwardRef<SchedulePickerRef, Props>(function SchedulePicker(
  props: Props,
  ref
): JSX.Element {
  return <SchedulePickerContent {...props} forwardedRef={ref} />;
});
