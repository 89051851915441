import React from 'react';
import { Icon, InteractiveTooltip, Text } from '@/components';

export default function ModelNumToolTip(): JSX.Element {
  return (
    <InteractiveTooltip
      title="Why do we need the model and serial number"
      description="Every model has it’s own specific solutions. We need to know the exact brand and model number of your appliance to find the right repair parts."
      placement="bottom"
      trigger={
        <div className="flex space-x-1">
          <Icon name="info_outline" className="text-primaryCTA" size={16} />
          <Text className="text-primaryCTA" type="button">
            Why do we need this?
          </Text>
        </div>
      }
    />
  );
}
