import React, { useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { sendPageview } from '@/com/marketing';
import { Alert, Text } from '@/components';
import { useGetFeatures } from '@/hooks/api/features';
import LoadingIndicator from '@/pages/book/steps-V2/com/LoadingIndicator';
import useLoadAvailability from '@/pages/book/steps-V2/hooks/useLoadAvailability';
import Footer from '../com/Footer';
import { BookingState } from '../util';
import ScheduleContainer from './ScheduleContainer';
import SelectedDates from './SelectedDates';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Availability({ onChange, state, ...props }: Props): JSX.Element {
  const featureQuery = useGetFeatures();
  const b2cAvailability1Slot = featureQuery.isSuccess
    ? featureQuery.data.b2cAvailability1Slot
    : false;

  const { serviceId, startDate } = state;
  const serviceName = state.services.find(service => service.id === state.serviceId)?.name;

  const [loadAvailability, { loading }] = useLoadAvailability({
    onChange,
    postalCode: nullthrows(state.zip.value),
    serviceId: nullthrows(serviceId),
    startDate,
    serviceName,
  });

  useEffect(() => {
    if (!state.availability && !loading) {
      void loadAvailability();
    }
  }, [loadAvailability, loading, state.availability]);

  useEffect(() => {
    sendPageview(`booking-page/appointment`);
  }, []);

  const handleToggleSlot = (date: string) => {
    const isActive = !!_.get(state, `availTSlots.${date}`);
    if (b2cAvailability1Slot) {
      onChange('availTSlots', isActive ? {} : { [date]: true });
    } else {
      onChange(`availTSlots.${date}`, !isActive);
    }
  };

  if (!state.availability || loading) {
    return <LoadingIndicator isLoading={loading} text="Searching for a technician..." />;
  }

  const selectedDates = _.keys(_.pickBy(state.availTSlots));
  const slotsAreValid = b2cAvailability1Slot ? !!selectedDates.length : selectedDates.length >= 2;

  return (
    <section>
      <Text type="headline-6">
        {b2cAvailability1Slot
          ? 'When would you like your technician to arrive?'
          : 'Suggest 2+ Times You’re Available'}
      </Text>
      <Text className="mt-2">
        {b2cAvailability1Slot
          ? 'Select an arrival time below.'
          : 'Your technician will confirm one of the times you provided.'}
      </Text>

      <ScheduleContainer onToggleSlot={handleToggleSlot} state={state} />

      <div className="md:mx-8">
        {!b2cAvailability1Slot && (
          <div className="mt-4 flex justify-between">
            <Text className="text-grey-dark" type="helper">
              Minimum of 2 times must be provided.
            </Text>
            <Text
              className={classNames({
                'text-danger': selectedDates.length < 2,
                'text-success': selectedDates.length >= 2,
              })}
              type="helper"
            >
              {selectedDates.length}/2
            </Text>
          </div>
        )}
      </div>
      <hr className="mt-6" />

      <SelectedDates onClick={handleToggleSlot} state={state} />

      {!b2cAvailability1Slot && (
        <Alert icon="clock_fast" className="mt-6">
          <Text className="text-secondary-5">
            <strong className="font-semibold">
              Most service requests receive confirmation in under two hours.
            </strong>{' '}
            As soon as your appointment time is secured, we’ll send you an update.
          </Text>
        </Alert>
      )}

      <Footer {...props} disabled={!slotsAreValid} />
    </section>
  );
}
