import React from 'react';
import { companyName } from '@/config/const';
import Footer from '../../com/ui/footer';
import Link from '../../com/ui/link';

// eslint-disable-next-line react/prefer-stateless-function
export default class ProCenter2 extends React.Component {
  render() {
    return (
      <div>
        <section id="ProCenter">
          <div className="pro-container py-5">
            <div className="container">
              <div style={{ height: 60 }} />
              <h1 className="weight-600 text-center">
                Welcome to the {companyName} Technician Center
              </h1>
              <h3 className="text-center">How can we help?</h3>
              <br />
              <br />
              <Link to="/apply">
                <div className="card-block box-shadow">
                  <div>
                    <img alt="repair man" src="/img/repair-man-vector.png" height="50" />
                    <p>Join as a Professional</p>
                  </div>
                </div>
              </Link>
              <Link to="/academy">
                <div className="card-block box-shadow">
                  <div>
                    <img alt="faq" src="/img/faq-right-image.jpg" height="50" />
                    <p>I Want to Learn</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
