import React, { useEffect, useState } from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';

const Inventory = ({ partNumber }) => {
  const getInventory = async partNumber => {
    const query = { partNumber };
    const inventory = await U.api('post', 'inventory/search', { query });
    const sum = _.sum(inventory.map(ir => ir.totQty));
    return { sum, inventory };
  };

  const initInventoryResult = {
    sum: 0,
    inventory: [],
  };

  const [inventoryResult, setInventoryResult] = useState(initInventoryResult);

  useEffect(() => {
    if (partNumber?.length) {
      // otherwise returns lots of results for blank partnumber
      getInventory(partNumber).then(getInventoryResult => setInventoryResult(getInventoryResult));
    }
  }, [partNumber]);

  let { sum, inventory } = inventoryResult;

  if (sum > 0) {
    return (
      <div className="mb-3 text-right">
        <div className="text-danger">PART {partNumber} FOUND IN INVENTORY</div>
        {inventory.map((i, n) => (
          <a
            key={n}
            href={`/inventory/${i.id}`}
            className="link-blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${i.owner?.name}'s Inventory (${i.totQty})`}{' '}
          </a>
        ))}
      </div>
    );
  }
  return null;
};

export default Inventory;
