import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware,createStore } from 'redux';
import reducer from './reducer';

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);
const store = createStore(reducer, applyMiddleware(middleware));

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;

export default store;
