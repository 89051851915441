import React, { useCallback, useState } from 'react';
import { Change, Option, T, Task } from '@nanaio/util';
import _ from 'lodash';
import { APIError, APIErrorBoundary, Button, Form, Input, InputType, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import Authorizations from './Authorizations';
import Diagnosis from './Diagnosis';

const TASK_QUESTIONS_PATH = 'metadata.taskQuestions';

type Props = {
  isOpen: boolean;
  task: Task;
};

export default function Questions({ isOpen, task }: Props): JSX.Element {
  const { questions } = useLegacySelector(state => {
    const { brands, services } = state;
    const orgId = T.orgId(task) || '';
    const org = state.orgs[orgId];
    const user = state.users[state.me.userId];
    const workOrder = state.workorders[task.metadata.workOrderId];
    const questions = T.questions({ brands, org, services, task, user, workOrder }).filter(
      question => !_.includes(question.id, 'Images')
    );
    const questionsMap: Record<string, boolean> = {};
    questions.forEach(question => {
      questionsMap[question.id] = true;
    });
    _.mapValues(_.get(task, TASK_QUESTIONS_PATH), (_, key) => {
      if (!questionsMap[`${TASK_QUESTIONS_PATH}.${key}`]) {
        questions.push({
          id: `${TASK_QUESTIONS_PATH}.${key}`,
          label: key,
          type: 'legacy',
        });
      }
    });
    return { questions };
  });

  const questionIds = questions.map(question => question.id);
  const origForm = _.pick(task, questionIds);
  const [form, setForm] = useState(_.cloneDeep(origForm));
  const changesMade = !_.isEqual(form, origForm);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to save questions</>} />
    ),
    save: true,
    method: 'put',
  });

  const handleSave = useCallback(async () => {
    if (!changesMade) {
      return;
    }
    const changes: Change[] = [];
    questions.forEach(question => {
      const before = _.get(origForm, question.id) as unknown;
      const after = _.get(form, question.id) as unknown;
      // skip diagnosis questions
      if (!_.isEqual(before, after) && question.type !== 'diagnosis') {
        changes.push({ path: question.id, value: after });
      }
    });
    if (changes.length) {
      await updateTask(changes);
    }
  }, [changesMade, form, origForm, questions, updateTask]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="container-fluid pt-4">
      <Form onChange={setForm} value={form}>
        {taskToUpdate.error}

        <Authorizations task={task} />

        <div className="row">
          {_.map(questions, question => {
            if (question.type === 'diagnosis') {
              return (
                <APIErrorBoundary key={question.id}>
                  <Diagnosis question={question} task={task} />
                </APIErrorBoundary>
              );
            }
            const value = _.get(form, question.id) as unknown;
            if (_.endsWith(question.id, 'Make')) {
              question.label = `Make (${_.get(task, question.id) as string})`;
            }
            return (
              <div className="col-sm-6" key={question.id}>
                {question.id !== 'metadata.taskQuestions.diagnosisCodes' && (
                  <>
                    {(question.type === 'select' || question.type === 'pick') && (
                      <Input
                        {...question} // eslint-disable-line react/jsx-props-no-spreading
                        options={question.options as Option[]}
                        value={value}
                        type={InputType.SEARCH}
                      />
                    )}
                    {question.type === 'bool' && (
                      <Input
                        {...question} // eslint-disable-line react/jsx-props-no-spreading
                        value={value as boolean | undefined}
                        type={InputType.BOOL}
                      />
                    )}
                    {question.type === 'number' && (
                      <Input
                        {...question} // eslint-disable-line react/jsx-props-no-spreading
                        value={value as string | number | undefined}
                        type={InputType.NUMBER}
                      />
                    )}
                    {question.type === 'text' && (
                      <Input
                        {...question} // eslint-disable-line react/jsx-props-no-spreading
                        value={value as string | number | undefined}
                        type={InputType.TEXT}
                      />
                    )}
                    {question.type === 'textbox' && (
                      <Input
                        {...question} // eslint-disable-line react/jsx-props-no-spreading
                        value={value as string | number | undefined}
                        multiline
                        showCharacterCount={
                          question.id === 'metadata.taskQuestions.repairDescription'
                        }
                        type={InputType.TEXT}
                      />
                    )}
                    {question.type === 'legacy' && (
                      <div className="mb-3">
                        <Text type="label">{question.label}</Text>
                        <Text>
                          {_.isObject(value)
                            ? _.reduce(_.keys(value), (a, b) => `${a}, ${b}`)
                            : JSON.stringify(value)}
                        </Text>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Button className="mb-3 mt-3 w-full" onClick={handleSave} disabled={!changesMade} submit>
          Save Changes
        </Button>
      </Form>
    </div>
  );
}
