import React from 'react';
import { Button,Text } from '@/components';

export default function GrowingTeam(): JSX.Element {
  return (
    <div className="bg-primaryCTA-3">
      <div className="container mx-auto justify-center py-16 text-center" style={{ maxWidth: 580 }}>
        <Text className="text-center text-3xl font-semibold text-font-dark">
          Our team is growing
        </Text>
        <Text className="mb-4 mt-4 text-center text-lg text-font-dark">
          When you work on something you care about, with people you enjoy spending time with, it’s
          an amazing feeling.
        </Text>
        <a href="careers">
          <Button>See open positions</Button>
        </a>
      </div>
    </div>
  );
}
