import React, { useEffect, useReducer, useState } from 'react';
import U from '@nanaio/util';
import classnames from 'classnames';
import { cloneDeep, isEqual, times } from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Button from '@/components/core/Button';
import Text from '@/components/core/Text';
import {
  getCanMentor,
  getSlotIsActive,
  initialState,
  loadAvailability,
  reducer,
  save,
  setProStatus,
  setTimezone,
  toggleMentorship,
  toggleSlot,
} from '@/pages/pro/technicianReducer';
import Loader from '../../../com/ui/loader';
import Switch from '../../form/Switch';
import AvailabilityContainer from './AvailabilityContainer';

const weekPropType = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string.isRequired,
    hours: PropTypes.arrayOf(PropTypes.bool),
  })
);

const mentorshipWeekPropType = PropTypes.arrayOf(
  PropTypes.shape({
    hours: PropTypes.arrayOf(PropTypes.bool),
  })
);

export default function TechnicianAvailability({ pro, proId }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hasChanges, setHasChanges] = useState(false);

  const {
    isActive,
    mentorshipWeek,
    originalIsActive,
    originalMentorshipWeek,
    originalWeek,
    saving,
    timezone,
    week,
    loading,
  } = state;
  const notServingNow = !isActive && pro.status !== 'applicant';

  const handleSave = () => {
    save(
      dispatch,
      isActive,
      originalIsActive,
      pro,
      proId,
      week,
      originalWeek,
      mentorshipWeek,
      originalMentorshipWeek,
      timezone
    );
  };

  useEffect(() => {
    if (!week) {
      if (timezone) {
        loadAvailability(dispatch, timezone, proId);
      } else if (pro) {
        setTimezone(dispatch, U.timezone(pro.user.address));
        setProStatus(dispatch, pro);
      } else {
        U.api('get', `pros/${proId}`, ['save']);
      }
    }
  }, [pro, proId, timezone, week]);

  useEffect(() => {
    let changes = isActive !== originalIsActive || !isEqual(week, originalWeek);

    if (!changes && mentorshipWeek && originalMentorshipWeek) {
      const newMentorshipWeek = cloneDeep(mentorshipWeek);

      times(7).forEach(dayIndex => {
        const hours = times(12, () => false);
        const canMentor = getCanMentor(week, dayIndex);

        if (!canMentor) {
          newMentorshipWeek[dayIndex] = { hours };
        }
      });

      changes = !isEqual(newMentorshipWeek, originalMentorshipWeek);
    }

    setHasChanges(changes);
  }, [isActive, originalIsActive, week, originalWeek, mentorshipWeek, originalMentorshipWeek]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AvailabilityContainer saveDisabled={saving || !hasChanges} onSave={handleSave}>
      {times(7, i => (i === 6 ? 0 : i + 1)).map(dayIndex => (
        <DayAvailability
          dayIndex={dayIndex}
          disableControls={saving}
          getCanMentor={getCanMentor}
          getSlotIsActive={getSlotIsActive}
          isMentor={pro.role === 'mentor'}
          key={dayIndex}
          mentorshipWeek={mentorshipWeek}
          notServingNow={notServingNow}
          onToggleMentorship={() => toggleMentorship(dispatch, mentorshipWeek, dayIndex)}
          onToggleSlot={slotIndex => toggleSlot(dispatch, week, dayIndex, slotIndex)}
          week={week}
        />
      ))}
    </AvailabilityContainer>
  );
}

TechnicianAvailability.propTypes = {
  pro: PropTypes.shape({
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    user: PropTypes.shape({
      address: PropTypes.shape({
        county: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  proId: PropTypes.string.isRequired,
};

function DayAvailability({
  dayIndex,
  disableControls,
  getCanMentor,
  getSlotIsActive,
  isMentor,
  mentorshipWeek,
  notServingNow,
  onToggleMentorship,
  onToggleSlot,
  week,
}) {
  const classes = classnames('flex items-center py-2', { 'border-b border-grey-medium': dayIndex });
  const canMentor = getCanMentor(week, dayIndex);
  const mentorToggleValue =
    !notServingNow && canMentor && getSlotIsActive(mentorshipWeek, dayIndex);

  return (
    <div className={classes}>
      <Text type="button" color="font.dark" className="w-40">
        {m().day(dayIndex).format('dddd')}
      </Text>

      {['8am - 12pm', '12pm - 4pm', '4pm - 8pm'].map((time, slotIndex) => {
        const isActive = getSlotIsActive(week, dayIndex, slotIndex);

        return (
          <Button
            className={slotIndex ? 'ml-2' : ''}
            disabled={disableControls}
            key={time}
            onClick={() => onToggleSlot(slotIndex)}
            variant={isActive && !notServingNow ? 'primary-outline' : 'secondary-outline'}
          >
            {time}
          </Button>
        );
      })}

      {isMentor ? (
        <div className="item-center ml-auto flex">
          <Text type="helper" color="font.dark">
            {mentorToggleValue ? 'Available to mentor' : 'Not available to mentor'}
          </Text>
          <Switch
            disabled={disableControls || !canMentor}
            isActive={mentorToggleValue}
            onClick={onToggleMentorship}
            className="ml-4"
          />
        </div>
      ) : null}
    </div>
  );
}

DayAvailability.propTypes = {
  dayIndex: PropTypes.number.isRequired,
  disableControls: PropTypes.bool,
  getCanMentor: PropTypes.func.isRequired,
  getSlotIsActive: PropTypes.func.isRequired,
  isMentor: PropTypes.bool,
  mentorshipWeek: mentorshipWeekPropType.isRequired,
  notServingNow: PropTypes.bool,
  onToggleMentorship: PropTypes.func.isRequired,
  onToggleSlot: PropTypes.func.isRequired,
  week: weekPropType.isRequired,
};
