import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Date, Money, Percent, Select, Text } from '../../../com/ui/form';
import Link from '../../../com/ui/link';

class EditPromo extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(props) {
    super(props);
    this.state = _.merge({}, props.promo);
    U.api('post', 'promos/search', undefined, true);
  }

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state) && props.promo) {
      this.setState(props.promo);
    }
  }

  activate = async () => {
    const r = await U.api('put', `promos/${global.id()}/activate`);
    this.setState({ ...r, _change: false });
  };

  deactivate = async () => {
    const r = await U.api('put', `promos/${global.id()}/deactivate`);
    this.setState({ ...r, _change: false });
  };

  render() {
    const { promo } = this.props;
    if (!promo || _.isEmpty(this.state) || !this.props.services.length) {
      return null;
    }
    return (
      <div className="container">
        <div className="d-flex">
          <Link to="/promos" className="btn btn-primary mb-3">
            Back
          </Link>
        </div>
        <p className="text-danger">{this.state.error}</p>
        <div className="row">
          <Text id="name" className="col-sm-6" disabled />
          <Text id="code" className="col-sm-6" disabled />
          <Text id="description" className="col-sm-12" disabled />
          <Select id="status" className="col-sm-6" cap disabled />
        </div>
        <div className="row">
          <Date id="schedule.startTime" className="col-sm-6" disabled />
          <Date id="schedule.endTime" className="col-sm-6" disabled />
        </div>
        <div className="row">
          <Select id="type" options={['flat', 'percent']} cap disabled className="col-sm-6" />
          {this.state.type === 'flat' && <Money id="rate" req disabled className="col-sm-6" />}
          {this.state.type === 'percent' && <Percent id="rate" req disabled className="col-sm-6" />}
        </div>
        <div className="row">
          <Text id="chargeTypes" className="col-sm-12" label="Charge types" disabled />
        </div>
        {this.state.status === 'active' && (
          <div className="btn btn-danger ml-auto" onClick={this.deactivate}>
            Deactivate
          </div>
        )}
        {this.state.status === 'inactive' && (
          <div className="btn btn-success ml-auto" onClick={this.activate}>
            Activate
          </div>
        )}
      </div>
    );
  }
}

export default connect(s => {
  const services = _.values(s.services);
  const promo = s.promos[global.id()];
  return { promo, services };
})(EditPromo);
