import * as Table from '@nanaio/table';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { CompassDirection, Move } from '../types';
import { getDirectionIndex, getVectorAndChildIndexes } from '../util';
import handleMoveHelper from './handleMove';

type Props = {
  action: Move;
  compassDirection?: CompassDirection;
  index: number;
  query: Table.Query;
};

export default function handleMove({ action, compassDirection, index, query }: Props): Table.Query {
  const queryCopy = _.cloneDeep(query);
  const directionToVectors = _.groupBy(
    queryCopy.analytics.vectors,
    'direction'
  ) as unknown as Record<Table.Direction, Table.Vector[]>;
  const vector = queryCopy.analytics.vectors[index];
  const directionIndex = getDirectionIndex({
    direction: vector.direction,
    directionToVectors,
    index,
  });
  let vectors = directionToVectors[vector.direction];

  if (action === Move.ADD) {
    const vectorAndChildIndexes = getVectorAndChildIndexes({ index: directionIndex, vectors });

    if (compassDirection === CompassDirection.NORTH_WEST) {
      vectors = [
        ...vectors.slice(0, directionIndex),
        Table.getVector(vector),
        ...vectors
          .slice(directionIndex, directionIndex + vectorAndChildIndexes.length)
          .map(vector => ({ ...vector, depth: vector.depth + 1 })),
        ...vectors.slice(directionIndex + vectorAndChildIndexes.length),
      ];
    } else {
      let sliceIndex = directionIndex + 1;
      if (compassDirection === CompassDirection.SOUTH) {
        sliceIndex = (_.last(vectorAndChildIndexes) as number) + 1;
      } else if (compassDirection === CompassDirection.NORTH) {
        sliceIndex = directionIndex;
      }

      const currentVector = vectors[directionIndex];
      const depth =
        currentVector.depth + (compassDirection === CompassDirection.SOUTH_EAST ? 1 : 0);
      vectors = [
        ...vectors.slice(0, sliceIndex),
        Table.getVector({ depth, direction: vector.direction }),
        ...vectors.slice(sliceIndex),
      ];
    }
  } else if (action === Move.MOVE) {
    vectors = handleMoveHelper({
      compassDirection: nullthrows(compassDirection),
      directionIndex,
      index,
      vectors,
    });
  } else if (action === Move.REMOVE) {
    const indexesToRemove = getVectorAndChildIndexes({ index: directionIndex, vectors });
    const oppositeVectors =
      vector.direction === Table.Direction.COLUMN
        ? directionToVectors[Table.Direction.ROW]
        : directionToVectors[Table.Direction.COLUMN];
    const removedVectorIds = _.map(
      vectors.filter((_, i) => indexesToRemove.includes(i)),
      'id'
    );
    vectors = vectors.filter((_, i) => !indexesToRemove.includes(i));

    if (vectors.length === 0) {
      vectors = [Table.getVector({ depth: 0, direction: vector.direction, moduleId: 'total' })];
    }

    for (const vector of oppositeVectors) {
      if (vector.sort?.tableVector) {
        vector.sort.tableVector = vector.sort.tableVector.filter(
          vector => !removedVectorIds.includes(vector.id)
        );
        if (!vector.sort.tableVector.length) {
          vector.sort.tableVector = [{ id: vectors[0].id }];
        }
      }
    }
  }

  queryCopy.analytics.vectors = [
    ...(vector.direction === Table.Direction.COLUMN
      ? vectors
      : directionToVectors[Table.Direction.COLUMN]),
    ...(vector.direction === Table.Direction.ROW
      ? vectors
      : directionToVectors[Table.Direction.ROW]),
  ];
  return queryCopy;
}
