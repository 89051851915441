import './login2019.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import StepZilla from 'react-stepzilla';
import U from '@nanaio/util';
import _ from 'lodash';
import store from 'store2';
import { forwardToZendesk } from '@/com/user/zendeskUtil';
import { getParams } from '../../../com/util';
import ClaimAccountMsg from './ClaimAccountMsg';
import CreateProfile from './CreateProfile'; // 2
import Login from './Login'; // 1
import NewPassword from './NewPassword'; // 3
import PasswordResetSuccess from './PasswordResetSuccess'; // 7
import RecoveryCode from './RecoveryCode'; // 5
import ResetPassword from './ResetPassword'; // 6
import Start from './Start'; // 0
import Step5 from './Step5'; // 4
import Step9 from './Step9'; // 8

const stepComs = [
  Start,
  Login,
  CreateProfile,
  NewPassword,
  Step5,
  RecoveryCode,
  ResetPassword,
  PasswordResetSuccess,
  Step9,
  ClaimAccountMsg,
];

class LoginPage extends Component {
  constructor(p) {
    super(p);
    const redirect = getParams().r;
    if (redirect) {
      store.set('login.redirect', redirect);
    }

    const returnTo = getParams().return_to;
    if (returnTo) {
      store.set('login.returnTo', returnTo);
    }

    this.state = {
      email: '',
      password: '',
      name: '',
      phone: '',
      stepHistory: [0],
    };
    this.getStore = this.getStore.bind(this);
    this.updateStore = this.updateStore.bind(this);
    if (p.token) {
      if (returnTo) {
        void forwardToZendesk({
          token: p.token,
          returnTo,
          setError: value => this.setState({ error: value }),
        });
      } else {
        const fixedRedirect = _.replace(redirect || 'workorders', /^\//, '');
        p.dispatch(push(`/${fixedRedirect}`));
      }
    }
    const jobId = global.id();
    if (jobId) {
      U.api('get', `tasks/${jobId}/hasFakeEmail`).then(hasFakeEmail =>
        U.redux.set('form.hasFakeEmail', hasFakeEmail)
      );
    }
  }

  componentWillReceiveProps(p) {
    if (this.props.token !== p.token && p.token) {
      p.dispatch(push('/workorders'));
    }
  }

  getStore() {
    return this.state;
  }

  updateStore(update) {
    this.setState(update);
  }

  render() {
    const props = _.pick(this, ['getStore', 'updateStore']);
    const steps = stepComs.map((s, n) => ({
      name: `Step${n}`,
      component: React.createElement(s, props),
    }));
    return (
      <section>
        <div id="Login2019">
          <StepZilla
            dontValidate
            steps={steps}
            preventEnterSubmission
            nextTextOnFinalActionStep="Save"
            showSteps={false}
            showNavigation={false}
            onStepChange={step => this.setState({ stepHistory: [...this.state.stepHistory, step] })}
          />
        </div>
      </section>
    );
  }
}

export default connect(s => {
  const token = _.get(s.me, 'token');
  return { token };
})(LoginPage);
