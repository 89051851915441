import React from 'react';
import { T,U } from '@nanaio/util';
import _ from 'lodash';
import PT from 'prop-types';
import { Modal, Search, Text } from '../../../com/ui/form';

export default class EditCatalogPartCategory extends React.Component {
  static propTypes = {
    id: PT.string,
    isEdit: PT.bool,
    isOpen: PT.bool,
    onRemove: PT.func,
    onSuccess: PT.func.isRequired,
    toggleOpen: PT.func.isRequired,
  };

  static childContextTypes = { t: PT.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { loading: true };
    this.loadCatalogPartCategoryIntoState(p);
  }

  loadCatalogPartCategoryIntoState = async p => {
    const catalogPartCategory = p.id ? await U.api('get', `catalogpartcategories/${p.id}`) : {};
    this.setState({ loading: false, catalogPartCategory });
  };

  onRemove = async () => {
    const { errMsg } = await U.api('delete', `catalogpartcategories/${this.props.id}`);
    if (errMsg) {
      return this.setState({ errMsg });
    }
    this.props.onRemove();
  };

  submit = async () => {
    if (!this.state.catalogPartCategory.displayName) {
      return this.setState({ errMsg: 'Display name required' });
    }

    let apiResult;
    if (this.props.id) {
      // update existing
      apiResult = await U.api(
        'put',
        `catalogpartcategories/${this.props.id}`,
        this.state.catalogPartCategory
      );
    } else {
      // add new
      apiResult = await U.api('post', 'catalogpartcategories', this.state.catalogPartCategory);
    }
    if (apiResult.errMsg) {
      return this.setState({ error: apiResult.errMsg });
    }
    this.props.onSuccess(apiResult);
  };

  render() {
    return (
      <Modal
        title={`${this.props.isEdit ? 'Edit' : 'Add'} Catalog Part Category`}
        isOpen={this.props.isOpen}
        submit={this.submit}
        onClose={this.props.toggleOpen}
        onRemove={this.onRemove}
        className="ui-modal md ui-select-search"
      >
        <p className="text-danger">{this.state.errMsg}</p>
        <Text
          id="catalogPartCategory.displayName"
          label="Display Name (ex My Catalog Part Category)"
          disabled={this.props.isEdit}
        />
        <Text
          id="catalogPartCategory.name"
          label="Name (ex myCatalogPartCategory)"
          disabled={this.props.isEdit}
        />
        <Search
          id="catalogPartCategory.productType"
          label="Type"
          className="col-sm-6"
          options={T.catalogPartCategoryProductTypes}
          cap
          disabled={this.props.isEdit}
        />
        <Search
          id="catalogPartCategory.partFailureReason"
          label="Part Failure Reason"
          className="col-sm-6"
          options={T.vendorPartFailureReasonOptions}
          cap
          disabled={this.props.isEdit}
        />
      </Modal>
    );
  }
}
