import React, { useState } from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import nullthrows from 'nullthrows';
import { Loading } from '../Button.stories';
import Icon from '../Icon';
import Text from '../Text';
import Modal from '.';

export default {
  title: 'Core/Modal',
  component: Modal,
  decorators: [
    Story => (
      <div className="flex">
        <Story />
      </div>
    ),
  ],
} as ComponentMeta<typeof Modal>;

const Template: ComponentStory<typeof Modal> = args => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => setIsOpen(false);
  const onRemove = () => {
    alert('remove');
  };
  return <Modal {...args} isOpen={isOpen} onClose={onClose} onRemove={onRemove} />;
};

const BODY_CONTENT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Velit euismod in pellentesque massa placerat. In dictum non consectetur a erat. Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing. Pharetra et ultrices neque ornare. Leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Hendrerit gravida rutrum quisque non.';

export const Default = Template.bind({});
Default.args = {
  children: (
    <>
      <Modal.Header />
      <Modal.Body>
        <Text className="p-4">{BODY_CONTENT}</Text>
      </Modal.Body>
      <Modal.Footer />
    </>
  ),
};

export const CustomHeader = Template.bind({});
CustomHeader.args = {
  children: (
    <>
      <Modal.Header
        onBack={() => {}}
        progress={0.3}
        rightContent={
          <span className="flex items-center justify-end">
            <Icon
              className="mr-3 text-grey-dark hover:cursor-pointer hover:text-secondary"
              name="help_outline"
              size={20}
            />
            <Icon
              className="-mr-1 text-grey-dark  hover:cursor-pointer hover:text-secondary"
              name="exit_to_app"
              size={20}
            />
          </span>
        }
        spacerContentWidth={60}
        subtitle="1 of 3"
        title="Modal Title"
      />
      <Modal.Body>
        <Text className="p-4">{BODY_CONTENT}</Text>
      </Modal.Body>
      <Modal.Footer
        closeText="Cancel"
        saveText="Submit"
        onSave={nullthrows(Loading.args).onClick}
      />
    </>
  ),
};

export const DisableBackdropDismiss = Template.bind({});
DisableBackdropDismiss.args = {
  children: (
    <>
      <Modal.Header />
      <Modal.Body>
        <Text className="p-4">{BODY_CONTENT}</Text>
      </Modal.Body>
      <Modal.Footer
        closeText="Cancel"
        saveText="Submit"
        onSave={nullthrows(Loading.args).onClick}
      />
    </>
  ),
  hideOnBackdropClick: false,
};

export const CustomHeight = Template.bind({});
CustomHeight.args = {
  height: 600,
  children: (
    <>
      <Modal.Header />
      <Modal.Body>
        <Text className="p-4">{BODY_CONTENT}</Text>
      </Modal.Body>
      <Modal.Footer />
    </>
  ),
  hideOnBackdropClick: false,
};
