import React, { CSSProperties, ReactNode, useContext, useState } from 'react';
import classnames from 'classnames';
import nullthrows from 'nullthrows';
import Icon from '../../core/Icon';
import Context from '../../core/Modal/Context';
import Text from '../../core/Text';
import theme from '../../theme';
import type { InputProps } from '../Input';
import ItemUI from './Item';

export type DropdownItem = {
  description?: string;
  disabled?: boolean;
  onClick: () => void;
  title: string;
};

export const getProps = ({ items, ...rest }: InputProps): Props => ({
  items: nullthrows<DropdownItem[]>(items),
  ...rest,
});

export type Props = {
  chevronClassName?: string;
  className?: string;
  cypressId?: string;
  hideChevron?: boolean;
  items: DropdownItem[];
  leftAlign?: boolean;
  style?: CSSProperties;
  title?: string;
  titleClassName?: string;
  titleStyle?: CSSProperties;
  trigger?: ReactNode;
};

export default function Dropdown({
  chevronClassName = '',
  className = '',
  cypressId,
  hideChevron,
  items,
  leftAlign,
  style = {},
  title,
  titleClassName = '',
  titleStyle = {},
  trigger,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const isDisabled = !items.length;

  const { isInModal } = useContext(Context);

  const handleClick = (item: DropdownItem) => {
    if (item.disabled || !item.onClick) {
      return;
    }
    item.onClick();
    setIsOpen(false);
  };

  return (
    <div>
      <div
        className={isOpen ? 'fixed inset-0' : ''}
        onClick={isOpen ? () => setIsOpen(false) : undefined}
      />
      <div className={`${isInModal ? '' : 'relative'} ${className}`} style={style}>
        <div
          className={`flex flex-row justify-between ${isDisabled ? '' : 'cursor-pointer'}`}
          onClick={isDisabled ? undefined : () => setIsOpen(!isOpen)}
        >
          <span data-cy={cypressId ? `${cypressId}-trigger` : undefined}>{trigger}</span>
          {!hideChevron && (
            <Icon
              className={classnames('ml-4', chevronClassName)}
              color="font.dark"
              name={isOpen ? 'expand_more' : 'expand_less'}
            />
          )}
        </div>
        {isOpen && (
          <div className={isInModal ? 'absolute' : ''}>
            <div
              className={`animated fadeIn faster absolute rounded border border-grey-medium bg-white p-4 ${
                leftAlign ? 'left' : 'right'
              }-0`}
              style={{ width: 200, zIndex: 2, ...theme.shadowBottom }}
            >
              <Text className={titleClassName} type="body-2" style={titleStyle}>
                {title}
              </Text>
              {items.map((item, i) => (
                <ItemUI
                  cypressId={cypressId ? `${cypressId}-option-${i}` : undefined}
                  description={item.description}
                  disabled={item.disabled}
                  index={i}
                  isLast={i === items.length - 1}
                  key={item.title}
                  onClick={() => handleClick(item)}
                  title={item.title}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
