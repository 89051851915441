import U from '@nanaio/util';
import { difference } from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';

export const initialState = {
  days: null,
  loading: true,
  newDay: null,
  originalDays: null,
  saving: false,
  timezone: null,
};

export function sortDaysStrategy(a, b) {
  return Number(b.replaceAll('-', '')) - Number(a.replaceAll('-', ''));
}

export async function loadAvailability(dispatch, proId, timezone, timeslotIdByDay) {
  dispatch({ type: 'LOAD_AVAILABILITY' });

  const query = {
    'purpose.0.targetType': 'menteeAvailability',
    'resource.id': proId,
    startTime: { $gte: m().startOf('month') },
  };
  const response = await U.api('post', 'timeslots/search', { query });
  const days = (response?.[proId] ?? [])
    .map(timeSlot => {
      const day = mt(timeSlot.startTime).tz(timezone).format('YYYY-MM-DD');

      timeslotIdByDay[day] = timeSlot._id;

      return day;
    })
    .sort(sortDaysStrategy);

  dispatch({ type: 'LOAD_AVAILABILITY_FINISHED', payload: { days } });
}

export function setTimezone(dispatch, timezone) {
  dispatch({ type: 'SET_TIMEZONE', payload: { timezone } });
}

export function setDays(dispatch, days, newDay) {
  dispatch({ type: 'SET_DAYS', payload: { days, newDay } });
}

export async function save(dispatch, originalDays, days, timeslotIdByDay, pro, proId, timezone) {
  dispatch({ type: 'SAVE' });

  const removedIds = difference(originalDays, days).map(day => timeslotIdByDay[day]);

  await Promise.all(removedIds.map(id => U.api('delete', `timeslots/${id}`)));

  const addedDays = difference(days, originalDays);

  await Promise.all(
    addedDays.map(day => {
      const dayInLocal = m(day, 'YYYY-MM-DD');
      const dayInTimezone = mt()
        .tz(timezone)
        .year(dayInLocal.year())
        .month(dayInLocal.month())
        .date(dayInLocal.date())
        .startOf('day');
      const startTime = dayInTimezone.clone().hour(8);
      const endTime = dayInTimezone.clone().hour(20);
      const timeSlot = {
        title: `Mentee Availability for ${pro.user.fullName}`,
        resource: { id: proId, resourceType: 'serviceprovider' },
        purpose: [{ targetType: 'menteeAvailability' }],
        startTime,
        endTime,
      };

      return U.api('post', 'timeslots', timeSlot);
    })
  );

  await loadAvailability(dispatch, proId, timezone, timeslotIdByDay);

  dispatch({ type: 'SAVE_FINISHED' });
}

export function reducer(state, { type, payload }) {
  switch (type) {
    default:
      return state;

    case 'LOAD_AVAILABILITY':
      return {
        ...state,
        loading: true,
      };

    case 'LOAD_AVAILABILITY_FINISHED':
      return {
        ...state,
        loading: false,
        originalDays: payload.days,
        days: payload.days,
        newDay: payload.days[0],
      };

    case 'SET_DAYS':
    case 'SET_TIMEZONE':
      return {
        ...state,
        ...payload,
      };

    case 'SAVE':
      return {
        ...state,
        saving: true,
      };

    case 'SAVE_FINISHED':
      return {
        ...state,
        saving: false,
      };
  }
}
