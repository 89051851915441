import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import theme from '@/components/theme';
import Icon, { iconByName, Props } from './Icon';
import Text from './Text';

const themeColors = Object.keys(theme.flatColors);
const allNames = Object.keys(iconByName);

const handleButtonClick = () => {
  alert('Icon Clicked');
};

export default {
  title: 'Core/Icon',
  component: Icon,
  args: {
    name: 'check_circle',
    size: 18,
    onClick: undefined,
  },
  argTypes: {
    name: { control: { type: 'inline-radio' } },
    color: { control: { type: 'inline-radio' }, options: themeColors },
  },
} as ComponentMeta<typeof Icon>;

const Template: ComponentStory<typeof Icon> = args => <Icon {...args} />;

export const Default = Template.bind({});
Default.args = { name: 'check' };

export const AllIcons = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <Text className="text-center" type="headline-6">
      All name prop options
    </Text>
    <Text className="mb-4 text-center">
      We are using MaterialDesignIcons library (
      <a href="https://materialdesignicons.com/" target="blank">
        link
      </a>
      )
    </Text>
    <div className="grid grid-cols-2 gap-x-2 gap-y-6 md:grid-cols-4 lg:grid-cols-6">
      {allNames.map(name => (
        <div className="flex flex-col items-center text-center" key={name}>
          <Text type="helper">{name}</Text>
          <Default {...args} name={name as Props['name']} />
        </div>
      ))}
    </div>
  </div>
);

// New modules should use tailwind colors but in order for this component to replace
// the existing icons without redefining every color this option exists.
export const CustomColor = Template.bind({});
CustomColor.args = {
  color: '#E84F89',
};

export const Spin = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Spin prop options</p>
    <p className="mt-6">true: spin around once every 2 seconds</p>
    <Default {...args} spin />
    <p className="mt-6">false: no spin</p>
    <Default {...args} />
    <p className="mt-6">1: spin around once a second</p>
    <Default {...args} spin={1} />
    <p className="mt-6">10: spin around once every 10 seconds</p>
    <Default {...args} spin={10} />
  </div>
);

export const InlineTest = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <Text type="headline-6">Inline Tests</Text>
    <Text type="helper">Backgrounds colored to make vertical alignment more clear</Text>
    <Text type="helper">
      The most common display option should be in a parent with flex and items-center
    </Text>
    <Text type="helper">Other options may be appropriate depending for multiline layouts.</Text>
    <div className="mt-6 flex items-center bg-success">
      <Default {...args} />
      In a div that has flex and items-center
    </div>
    <div className="mt-6 flex items-center bg-success">
      <Default {...args} />
      <Text>In a div that has flex and items-center, and this Text in a component</Text>
    </div>
    <div className="mt-6 flex bg-accent-orange">
      <Default {...args} />
      In a div with flex
    </div>
    <p className="mt-6 bg-accent-orange">
      <Default {...args} />
      In a p tag.
    </p>
    <div className="mt-6 bg-accent-orange">
      <Default {...args} />
      In a div
    </div>
    <div className="mt-6 bg-danger">
      <Default {...args} />
      <Text>In a div with a Text component</Text>
    </div>
    <div className="mt-6 bg-danger">
      <Default {...args} />
      <p>In a div with a p component</p>
    </div>
    <div className="mt-6 bg-accent-orange">
      <Default {...args} />
      <span>In a div with a span component</span>
    </div>
    <Text className="mt-6 bg-accent-orange">
      <Default {...args} />
      In a Text component
    </Text>
    <div className="mt-6 h-[50px] bg-accent-orange">
      <Default {...args} />
      In a big div
    </div>
    <div className="mt-6 flex h-[50px] bg-accent-orange">
      <Default {...args} />
      In a big div with flex (shows potential issue if self-center is added to Icon)
    </div>
  </div>
);

export const OnClick = Template.bind({});
OnClick.args = {
  onClick: handleButtonClick,
};

export const Tooltip = Template.bind({});
Tooltip.args = {
  tooltip: { node: <>Tooltip goes here</> },
};
