import React from 'react';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { openLink } from '../../../utils';
import Copyable from '../../core/Copyable';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import User from '../User';
import { getUnassignedPros, proPropType } from '../util';

export default function Pro({
  mapIsOpen,
  mapProId,
  pro = { user: {} },
  proMap,
  setMapProId,
  setSearchTechIsOpen,
  setVisit,
  visit,
  workOrder,
}) {
  const isMentee = pro.role === U.pro.Role.MENTEE;
  const assignPro = index => {
    const newVisit = _.cloneDeep(visit);
    if (index === -1) {
      newVisit.pros.push({
        availability: [],
        id: pro.id,
        status: W.VisitProStatus.PENDING,
        tasks: workOrder.tasks.map(task => ({
          id: task.id,
          ...(isMentee ? { role: U.pro.Role.MENTEE } : {}),
        })),
      });
    } else {
      newVisit.pros[index] = {
        availability: [],
        id: pro.id,
        status: W.VisitProStatus.PENDING,
        tasks: newVisit.pros[index].tasks,
      };
    }
    if (setSearchTechIsOpen) {
      setSearchTechIsOpen(false);
    }
    setVisit(newVisit);
  };

  const unassignedPros = getUnassignedPros(visit);
  const hasUnassignedMenteeRole = !!unassignedPros.find(pro =>
    pro.tasks.find(task => task.role === U.pro.Role.MENTEE)
  );

  const assignItems = [
    ...unassignedPros.map(({ tasks }, index) => {
      const isUnassignedMenteeRole = tasks[0].role === U.pro.Role.MENTEE;
      const disabled =
        // don't allow techs to be assigned as mentees or mentees assigned as techs
        (isUnassignedMenteeRole && !isMentee) || (isMentee && !isUnassignedMenteeRole);
      return {
        description: tasks.map(task => {
          const appliance = T.appliance(workOrder.tasks.find(woTask => woTask.id === task.id));
          return `${appliance}: ${_.startCase(task.role)}`;
        }),
        disabled,
        onClick: () => assignPro(index),
        title: `Technician ${index + 1}`,
      };
    }),
    ...(!(isMentee && hasUnassignedMenteeRole)
      ? [
          {
            title: isMentee && !hasUnassignedMenteeRole ? 'Assign as Mentee' : 'Assign to new role',
            onClick: () => assignPro(-1),
          },
        ]
      : []),
  ];

  let iconName;
  let badgeColor;
  if (pro.declinedReasons) {
    iconName = 'thumb_down_outline';
    badgeColor = 'danger';
  } else if (
    workOrder.tasks.find(task => _.get(task, `metadata.oppNotif.pros.${pro.id}.notifyTime`))
  ) {
    iconName = 'notifications_none';
    badgeColor = 'success';
  }

  const badgeProps = iconName ? { iconProps: { name: iconName }, color: badgeColor } : undefined;

  let declinedReasons;
  if (pro.declinedReasons) {
    declinedReasons = Array.isArray(pro.declinedReasons)
      ? _.map(pro.declinedReasons, reason => _.upperFirst(_.lowerCase(reason.brief))).join(', ')
      : // Legacy
        'Not interested';
  }

  const openProfile = () => openLink({ url: `/pros/${pro.id}`, newTab: true });
  return (
    <User
      hasOverlay={mapIsOpen && mapProId !== pro.id}
      badgeProps={badgeProps}
      name={pro.user.fullName}
      onMouseEnter={() => setMapProId && setMapProId(pro.id)}
      proId={pro.id}
    >
      <div className="min-w-0 flex-1">
        <div className="flex flex-row">
          <Text color="primaryCTA" noWrap onClick={openProfile} type="subtitle-1">
            {pro.user.fullName}
          </Text>
          {_.isNumber(_.get(pro, 'stats.ratingAutomatic')) && (
            <div className="flex flex-row">
              <Icon className="mx-1" color="icons.orange" name="star" type="helper" />
              <Text type="body-2">{_.round(pro.stats.ratingAutomatic, 1)}</Text>
            </div>
          )}
        </div>
        <div>
          {pro.role !== undefined && (
            <div className="flex flex-row">
              <Text className="mr-2" color="grey.dark" type="body-2">
                Role:
              </Text>
              <Text type="body-2">{_.upperFirst(pro.role)}</Text>
            </div>
          )}
          {pro.homeDistance !== undefined && (
            <div className="flex flex-row">
              <Text className="mr-2" color="grey.dark" type="body-2">
                Home:
              </Text>
              <Text type="body-2">{_.round(pro.homeDistance)} mi away</Text>
            </div>
          )}

          {declinedReasons != null && (
            <div className="flex flex-row">
              <Text
                noWrap
                className="mr-2 overflow-hidden text-ellipsis"
                color="danger"
                type="body-2"
              >
                {declinedReasons}
              </Text>
            </div>
          )}
          {declinedReasons == null && _.get(pro, 'stats.lastVisit') && (
            <div className="flex flex-row">
              <Text className="mr-2" color="grey.dark" type="body-2">
                Last Job:
              </Text>
              <Text type="body-2">{m().diff(pro.stats.lastVisit, 'days')} days ago</Text>
            </div>
          )}
        </div>
      </div>
      <div className="justify-between">
        {proMap[pro.id] && (
          <Dropdown
            className="mb-4"
            hideChevron
            items={assignItems}
            title="Assign to Role:"
            titleClassName="text-grey-dark"
            trigger={<Icon color="primaryCTA" name="add" />}
          />
        )}
        <Copyable text={pro.user.phone}>
          <Icon color="primaryCTA" name="phone" />
        </Copyable>
      </div>
    </User>
  );
}

Pro.propTypes = {
  mapIsOpen: PropTypes.bool.isRequired,
  mapProId: PropTypes.string,
  pro: proPropType,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  setMapProId: PropTypes.func.isRequired,
  setSearchTechIsOpen: PropTypes.func,
  setVisit: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
