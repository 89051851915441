import './service_style.scss';

import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import Scrollchor from 'react-scrollchor';
import { Col, Row } from 'reactstrap';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Icon } from '@/components';
import Footer from '../../com/ui/footer';
import UILink from '../../com/ui/link';
import { getBrand } from '../../com/ui/path';
import { getIsChrome } from '../../com/util';
import { bearsLicense, companyName, gaTrackingNumber } from '../../config/const';
import Brands, { brands } from '../public/home/brands';
import HowWork from '../public/home/HowWork';
import PressTabs from '../public/home/PressTabs';
import Testimonials from '../public/home/testimonials';
import countyToCities from './countyToCities.json';

const imgByBrand = _.mapValues(
  _.keyBy(brands, brand => _.toUpper(brand.name)),
  'src'
);
const isChrome = getIsChrome();
const imgExtension = isChrome ? '.webp' : '.png';
const values = queryString.parse(window.location.search);

const phone = {
  extra: U.trimPhone(gaTrackingNumber, '(NNN) NNN-NNNN'),
  styled: U.trimPhone(gaTrackingNumber, '(NNN) NNN-NNNN'),
  plain: gaTrackingNumber,
};

const appliances = {
  Refrigerator: true,
  Dishwasher: true,
  Oven: true,
  Stove: true,
  Washer: true,
  Dryer: true,
  Microwave: true,
};

const getAppliance = () => {
  const parts = global.location.pathname.split('/').slice(1).map(_.startCase).reverse();
  const appliance = parts.find(p => appliances[p]);
  return appliance;
};

class ServiceV2 extends React.Component {
  constructor(p) {
    super(p);
    const minScheduleTime = T.minScheduleTime();
    U.redux.set('minScheduleTime', minScheduleTime);
    const minStartDate = T.minScheduleDate(minScheduleTime);
    this.state = {
      availTSlots: {},
      modal: false,
      brand: getBrand(),
      appliance: getAppliance(),
      promo: '10-off',
      services: p.services,
      step: 0,
      minScheduleTime,
      startDate: minStartDate,
      minStartDate,
    };
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.selectModal();
    }
  };

  onChange = (id, value) => {
    this.setState(s => _.set(s, id, value));
  };

  selectModal = () => {
    const zip = _.get(this.state, 'zip.value');
    if (!/^\d{5}$/.test(zip)) {
      return this.setState({ zipError: 'Enter a 5-digit zip code before continuing' });
    }
    const { serviceNameToService } = this.props;
    const { appliance, brand } = this.state;
    const serviceId = _.get(serviceNameToService[`${appliance} Repair`], 'id');
    global.location = _.compact(['/book', zip, serviceId, brand]).join('/');
  };

  renderCta = () => {
    const { zipError } = this.state;
    return (
      <div className="mb-4 mt-4">
        <div id="inline_input" className="hidden-md-down">
          <div className="inline_input--icon px-2">
            <img src="/img/lightningicon/address.svg" alt="address" style={{ height: '25px' }} />
          </div>
          <NumberFormat
            data-cy="zip"
            placeholder="Enter 5-digit zip code"
            format="#####"
            // eslint-disable-next-line no-underscore-dangle
            onKeyDown={this.handleKeyDown}
            onValueChange={v => this.onChange('zip', v)}
            value={this.zip}
          />
          <UILink name="checkAvailability">
            <button
              data-cy="availability-btn"
              onClick={this.selectModal}
              className="d-flex align-items-center justify-content-center"
              type="button"
            >
              <img
                src="/img/lightningicon/date_input.svg"
                style={{ height: '16px' }}
                className="mr-1"
                alt="date"
              />
              <p>
                <b className="text-center text-white">Check Availability</b>
              </p>
            </button>
          </UILink>
        </div>
        <div className="block_input hidden-md-up">
          <div className="block_input--field">
            <div className="input--icon px-2">
              <img src="/img/lightningicon/address.svg" alt="address" style={{ height: '25px' }} />
            </div>
            <NumberFormat
              placeholder="Enter 5-digit zip code"
              format="#####"
              // eslint-disable-next-line no-underscore-dangle
              onKeyDown={this.handleKeyDown}
              onValueChange={v => this.onChange('zip', v)}
              value={this.zip}
            />
          </div>
          <UILink name="checkAvailability">
            <button
              onClick={this.selectModal}
              className="block_input--btn d-flex align-items-center justify-content-center mt-3"
              type="button"
            >
              <img
                src="/img/lightningicon/date_input.svg"
                style={{ height: '16px' }}
                className="mr-1"
                alt="date"
              />
              <p>
                <b className="text-center text-white">Check Availability</b>
              </p>
            </button>
          </UILink>
        </div>
        {zipError && <label className="text-red animated fadeIn mt-2">{zipError}</label>}
      </div>
    );
  };

  render() {
    const { appliance, brand, modal } = this.state;
    const { city, county, countyCities } = this.props;
    const state = _.startCase(values.state);
    const iframe = state
      ? `https://www.google.com/maps/embed/v1/place?key=${U.const.googleApiKey}&q=${encodeURI(
          `${city} ${state} county`
        )}`
      : `https://www.google.com/maps/embed/v1/place?key=${U.const.googleApiKey}&q=${encodeURI(
          `${county} county`
        )}`;
    const brandImg = imgByBrand[_.toUpper(brand)];

    return (
      <main id="ServicesStyle" className={modal ? `fixed` : ``}>
        <section className="container">
          {/*= =================JUMBOTRON==================== */}
          <section className="jumbotron">
            <Row className="no-gutters" id="to-book">
              <Col lg="6" md="12" sm="12" xs="12" className="jumbo-title">
                <div className="jumbo-title-container">
                  {brandImg && (
                    <img
                      src={brandImg}
                      alt={brand}
                      className="mb-6 max-h-8 max-w-[180px] object-contain md:max-h-12"
                    />
                  )}
                  <h1 className="weight-600">
                    Your {city && ` ${city}`}
                    {brand && ` ${brand}`} {appliance || `Appliance`} Repair Team
                  </h1>
                  <div className="d-flex mt-1">
                    <h6 className="text-gray weight-600">
                      All jobs covered by a 365 day guarantee
                    </h6>
                  </div>
                  {this.renderCta(true)}

                  <div className="d-flex align-items-center">
                    <img
                      src="/img/lightningicon/quote.svg"
                      alt="quote"
                      style={{ height: '16px' }}
                    />
                    <p className="weight-600 ml-2 flex-1">
                      Book online and save <b className="text-green">$25 Off Labor</b>
                    </p>
                  </div>
                </div>
              </Col>

              {/* Absolute positioned, right-aligned photo */}
              <Col lg="6" md="12" sm="12" xs="12">
                <div
                  className="jumbo-photo"
                  style={{
                    backgroundImage: 'url(/img/servicepage/marketing.jpg)',
                  }}
                />
              </Col>
            </Row>
          </section>

          {/*= =================PRESS===================== */}
          <section className="pl-[50px]">
            <PressTabs />
          </section>

          {/*= =================HOW IT WORKS===================== */}
          <section className="cta-conclusion px-4 py-5">
            <h2 className="weight-700 mb-4 text-center">How it Works</h2>
            <HowWork />
          </section>

          {/*= =================TRUST PILOT===================== */}
          <section className="px-4 py-5">
            <Testimonials />
          </section>

          {/*= =================WHY NANA===================== */}
          <section className="cta-why">
            <Row className="d-flex justify-content-between align-items-center">
              <Col lg="6" md="8" sm="12" xs="12">
                <div className="cta-why--text">
                  <h2 className="weight-700 hidden-sm-down mb-2">
                    Why Choose {companyName} Appliance Repair?
                  </h2>
                  <h2 className="weight-700 hidden-sm-up mb-2 text-center">
                    Why Choose {companyName}
                  </h2>

                  <div className="mt-lg-0 mt-md-0 mt-3">
                    {[
                      'Track every step of your repair online',
                      'Diagnostic fee waived with repair',
                      'Background checked technicians',
                      `${companyName}™ Satisfaction Guarantee`,
                    ].map(t => (
                      <div key={t} className="d-flex align-items-center mb-3">
                        <Icon name="check_circle" color="#1598CB" height={16} />
                        <p className="weight-600 flex-1 pl-2">{t}</p>
                      </div>
                    ))}
                  </div>

                  <div className="hidden-sm-down">
                    <Row className="mt-4">
                      <Col className="py-3">
                        <h4>365 day</h4>
                        <small className="text-muted weight-800">PARTS GUARANTEE</small>
                      </Col>
                      <Col className="py-3">
                        <h4>{bearsLicense}</h4>
                        <small className="text-muted weight-800">BEARS LICENSE</small>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              {/* Absolute positioned, right-aligned photo */}
              <Col lg="6" md="4" sm="12" xs="12">
                <div
                  className="cta-why--photo"
                  style={{
                    backgroundImage: `url(/img/servicepage/${
                      isChrome ? `` : `min/`
                    }founder-collage${window.innerWidth <= 767 ? '-mobile' : ''}${imgExtension})`,
                  }}
                />
              </Col>
            </Row>
          </section>

          {/*= =================CTA BLURB 1================== */}
          <section className="cta-blurb-1 d-flex align-items-center justify-content-between">
            <div className="cta-text flex-1">
              <h2 className="weight-600 text-lg-left text-md-left text-center">
                Schedule an Appointment
              </h2>

              <div className="hidden-s-up mb-3 mt-3">
                <UILink to={`tel:${phone.plain}`} name="call">
                  <button type="button">
                    <Icon name="phone" size={18} color="#fff" />
                    <h5 className="weight-700 ml-2 text-white">{phone.styled}</h5>
                  </button>
                </UILink>
                <Scrollchor to="#to-book" animate={{ offset: -500, duration: 300 }}>
                  <button className="white mt-2" type="button">
                    <h5 className="weight-700 text-blue">Book Online (Save $25 Off Labor)</h5>
                  </button>
                </Scrollchor>
              </div>

              <div className="d-flex align-items-start hidden-s-down py-2">
                <Icon name="phone" size={35} color="#54698D" />
                <div className="ml-2 flex-1">
                  <UILink to={`tel:${phone.plain}`} name="call">
                    <h2 className="weight-600 text-darkblue">{phone.styled}</h2>
                  </UILink>
                  <Scrollchor to="#to-book" animate={{ offset: -500, duration: 300 }}>
                    <h5 className="text-muted weight-700 mt-3">
                      or <span className="text-darkblue">book online</span> and save $25 off labor
                    </h5>
                  </Scrollchor>
                </div>
              </div>
            </div>
            <div
              className="cta-img hidden-s-down"
              style={{
                backgroundImage: `url(/img/servicepage/${
                  isChrome ? `` : `min/`
                }customersupport${imgExtension})`,
              }}
            />
          </section>

          {/*= =================LOCATIONS================== */}
          {city && (
            <section className="cta-cities">
              <Row>
                <Col lg="6" md="4" sm="12" xs="12">
                  <div className="cta-cities--map">
                    <iframe width="100%" frameBorder="0" src={iframe} title="Map" />
                  </div>
                </Col>
                <Col lg="6" md="8" sm="12" xs="12">
                  <div className="cta-cities-text">
                    <h2 className="weight-700 text-lg-left text-md-left mb-4 text-center">
                      Local to your Neighborhood
                    </h2>
                    <div className="grid-list">
                      {!_.isEmpty(countyCities) ? (
                        countyCities.map(t => (
                          <div key={t} className="grid-3-item">
                            <p className="weight-600 text-muted-link">{t}</p>
                          </div>
                        ))
                      ) : (
                        <div style={{ maxWidth: '500px' }}>
                          <p className="weight-600 lh-17 text-muted">
                            {companyName} has a team of top-rated appliance repair professionals in{' '}
                            {state && city
                              ? `${city}, ${state}`
                              : state
                                ? `${state}`
                                : city
                                  ? `${city}`
                                  : `your area`}
                            . Our pros have been vetted and are certified to work on all major
                            appliance brands. All repairs can be requested and tracked on our
                            website, and in most cases we can provide same-day repairs.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
          )}

          {/*= =================BRANDS================== */}
          <Brands />

          {/*= =================BRANDS================== */}
          <section className="cta-conclusion">
            <div className="cta-conclusion-container">
              <Scrollchor to="#to-book" animate={{ offset: -500, duration: 300 }}>
                <h2 className="weight-700 mb-1 text-center">
                  Book Online and Save <b className="text-green">$25 Off Labor</b>
                </h2>
              </Scrollchor>
              <p className="weight-600 text-muted hidden-sm-down text-center">
                Let us connect you with the best {brand && `${brand} `}
                {appliance || `Appliance`} Technician{city && ` in ${city}`}
              </p>
              {this.renderCta()}
            </div>
          </section>
        </section>
        <Footer />
      </main>
    );
  }
}

ServiceV2.propTypes = {
  city: PropTypes.string,
  county: PropTypes.string,
  countyCities: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceNameToService: PropTypes.objectOf(U.service.propType).isRequired,
};

export default connect(s => {
  const serviceNameToService = U.toMap(s.services, 'name');
  const { city, county } = s.form;
  const countyCities = _.values(countyToCities[county]) || countyToCities.default;
  return { city, county, countyCities, serviceNameToService };
})(ServiceV2);
