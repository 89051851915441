import { MutableRefObject, useRef } from 'react';
import _ from 'lodash';

export const useDeepCompareMemoRef = <T>(value: T): MutableRefObject<T> => {
  const ref = useRef<T>();

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  // @ts-expect-error Typescript is failing to infer that at this point,
  // ref.current will always be of type T
  return ref;
};

export default useDeepCompareMemoRef;
