import React from 'react';
import { Text } from '@/components';

// md breakpoint from tailwind config
const MD = 768;

export const brands = [
  { name: 'Samsung', src: '/img/brands/Brand=Samsung.svg', width: 109 },
  { name: 'GE', src: '/img/brands/Brand=GE.svg', width: 32 },
  { name: 'Whirlpool', src: '/img/brands/Brand=Whirlpool.svg', width: 85 },
  { name: 'LG', src: '/img/brands/Brand=LG.svg', width: 67 },
  { name: 'Amana', src: '/img/brands/Brand=Amana.svg', width: 95 },
  { name: 'HotPoint', src: '/img/brands/Brand=HotPoint.svg', width: 111 },
  { name: 'Kenmore', src: '/img/brands/Brand=Kenmore (New).svg', width: 102 },
  { name: 'KitchenAid', src: '/img/brands/Brand=KitchenAid.svg', width: 163 },
  { name: 'Maytag', src: '/img/brands/Brand=Maytag New.svg', width: 132 },
];

type Props = {
  className: string;
};

export default function Brands({ className }: Props): JSX.Element {
  return (
    <div className={`my-8 ${className}`}>
      <div className="mx-auto max-w-[1200px] text-center">
        <Text className="text-normal" type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}>
          Brands we Service
        </Text>
        <Text className="mt-4 text-grey-dark">We have experts in every brand.</Text>
        <div className="grid grid-cols-2 md:grid-cols-3">
          {brands.map(brand => (
            <div className="flex h-full w-full justify-center py-8" key={brand.name}>
              <img src={brand.src} alt={brand.name} height={32} width={brand.width} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
