import React, { useCallback, useMemo } from 'react';
import { Part, T, Task, U } from '@nanaio/util';
import _ from 'lodash';
import { Button, Icon, Text } from '@/components';
import TaskWithAlternativeDiagnosis from './TaskWithAlternativeDiagnosis';
import TaskWithoutAlternativeDiagnosis from './TaskWithoutAlternativeDiagnosis';
import {
  getDiagnosisRelatedPartsAllowingDiagnosisDeletion,
  reassociatePartsToDiagnoses,
} from './util';

type DiagnosisDeletionControlsProps = {
  diagnosisIndex: number;
  isMTDiagnosis: boolean;
  mostRecentDiagnosis?: ReturnType<typeof T.getMostRecentDiagnosis>;
  onDeleteButtonClick: (() => void) | undefined;
  partsTiedOnlyToThisDx: Part[];
  partsTiedToMultipleDx: Part[];
  task: Task;
};

export default function DiagnosisDeletionControls({
  diagnosisIndex,
  isMTDiagnosis,
  mostRecentDiagnosis,
  onDeleteButtonClick,
  partsTiedOnlyToThisDx,
  partsTiedToMultipleDx,
  task,
}: DiagnosisDeletionControlsProps): JSX.Element {
  const partsNeedToBeReassociated = useMemo(
    () => partsTiedOnlyToThisDx.length > 0 && !_.isUndefined(mostRecentDiagnosis),
    [partsTiedOnlyToThisDx, mostRecentDiagnosis]
  );

  const handleRemoveDiagnosisWithAssociatedParts = useCallback(async () => {
    if (diagnosisIndex === undefined) {
      return;
    }
    // The button is disabled if onDeleteButtonClick is undefined so this is just for TS
    if (!onDeleteButtonClick) {
      return;
    }
    // FIXME: @syntaxbliss Parts tied to MT diagnoses will be covered in a future PR
    const isMTDx = isMTDiagnosis;

    if (isMTDx) {
      return onDeleteButtonClick();
    }

    const partsAllowingDiagnosisDeletion = getDiagnosisRelatedPartsAllowingDiagnosisDeletion(
      diagnosisIndex,
      task
    );
    const mostRecentDiagnosis = T.getMostRecentDiagnosis(task, diagnosisIndex, isMTDx);
    const partsWithUpdatedDxAssociation = reassociatePartsToDiagnoses(
      diagnosisIndex,
      partsAllowingDiagnosisDeletion,
      mostRecentDiagnosis
    );

    for (const part of partsWithUpdatedDxAssociation) {
      // eslint-disable-next-line no-await-in-loop
      await U.api<Task>('put', `tasks/${task.id}/parts/${part.id}`, { part });
    }

    return onDeleteButtonClick();
  }, [diagnosisIndex, isMTDiagnosis, onDeleteButtonClick, task]);

  return (
    <div className="mt-4">
      <Button
        className="flex px-0"
        disabled={!onDeleteButtonClick}
        onClick={handleRemoveDiagnosisWithAssociatedParts}
        variant="link"
      >
        <Icon name="trash_can_outline" className="text-danger" size={16} />
        <Text type="label" className="ml-2 text-danger">
          {partsNeedToBeReassociated ? 'Reassociate Parts and' : ''} Delete Diagnosis
        </Text>
      </Button>

      {/* FIXME: @syntaxbliss Parts tied to MT diagnoses will be covered in a future PR */}

      {!isMTDiagnosis && partsTiedOnlyToThisDx.length > 0 && _.isUndefined(mostRecentDiagnosis) && (
        <TaskWithoutAlternativeDiagnosis partsTiedOnlyToThisDx={partsTiedOnlyToThisDx} />
      )}

      {!isMTDiagnosis &&
        partsTiedOnlyToThisDx.length > 0 &&
        !_.isUndefined(mostRecentDiagnosis) && (
          <TaskWithAlternativeDiagnosis
            mostRecentDiagnosis={mostRecentDiagnosis}
            partsTiedOnlyToThisDx={partsTiedOnlyToThisDx}
            partsTiedToMultipleDx={partsTiedToMultipleDx}
          />
        )}
    </div>
  );
}
