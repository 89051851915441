import React from 'react';
import { Button, Text } from '@/components';
import { companyName } from '@/config/const';

// md breakpoint from tailwind config
const MD = 768;

const chatBubbles = [
  {
    text: 'Scheduled an appointment',
    time: '3h ago',
  },
  {
    text: 'A technician is en route to your home',
    time: '1h ago',
  },
  {
    text: 'Refrigerator repaired',
    time: '2m ago',
  },
  {
    text: 'Enjoy your day 👋',
    time: '2m ago',
  },
];

const differences = [
  'Get the best High-End service for the job.',
  'Get support, access, and warranties for all services.',
  'Get notified at every turn.',
  'Customer approval before payment.',
];

export default function HowDifferent(): JSX.Element {
  return (
    <div className="px-4">
      <div className="grid grid-cols-1 md:grid-cols-2">
        {[
          <div key={0}>
            <div className="relative w-full">
              <div className="absolute left-0 top-0 w-full">
                {chatBubbles.map(bubble => (
                  <div key={bubble.text} className="mb-3 flex content-center items-center">
                    <div className="mb-2 inline-flex rounded-full bg-primary px-4 py-2">
                      <Text className="text-white">{bubble.text}</Text>
                    </div>
                    <Text className="pl-2 text-grey-dark">{bubble.time}</Text>
                  </div>
                ))}
              </div>
              <div className="w-[90%]">
                <img alt="Home" src="/img/vector-house.svg" width={520} height={476} />
              </div>
            </div>
          </div>,
          <div key={1}>
            <Text color="primary" className="uppercase" type="label">
              Revolutionizing Home Services
            </Text>
            <Text
              className="text-normal mt-1"
              type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}
            >
              How {companyName} is different
            </Text>
            <ul className="list-none">
              {differences.map(difference => (
                <li key={difference} className="mb-4 flex items-center">
                  <div className="flex-0 h-[10px] w-[10px] rounded-full bg-success" />
                  <Text className="ml-2 flex-1" color="grey.dark">
                    {difference}
                  </Text>
                </li>
              ))}
            </ul>

            <Button className="mr-2" href="/book">
              Book Now
            </Button>
          </div>,
        ]}
      </div>
    </div>
  );
}
