import axios from 'axios';
import { apiOrigin, logApi } from '@/config/const';
import store from '../com/store';

export const AXIOS_INSTANCE = axios.create({ baseURL: apiOrigin });

const getToken = () => Promise.resolve(store.getState()?.me?.token);

// Add a request interceptor
AXIOS_INSTANCE.interceptors.request.use(
  async function (config) {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (logApi) {
      // eslint-disable-next-line no-console
      console.log(
        'axios req',
        config.method,
        `${config.baseURL || ''}${config.url || ''}`,
        'data: ',
        config.data
      );
    }
    return config;
  },
  function (error) {
    // TODO - send error to Sentry
    // eslint-disable-next-line no-console
    console.error('Error requesting data:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  function (response) {
    if (logApi) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // eslint-disable-next-line no-console
      console.log(
        'axios res',
        response.config.method,
        `${response.config.baseURL || ''}${response.config.url || ''}`,
        response.data
      );
    }
    return response;
  },
  function (error) {
    // TODO - send error to Sentry
    // eslint-disable-next-line no-console
    console.error('Error with response data:', error);
    return Promise.reject(error);
  }
);
