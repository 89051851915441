import React from 'react';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import Group, { onChange } from './group';

export default class Date extends React.Component {
  static contextTypes = {
    t: T.object.isRequired,
  };

  static propTypes = {
    endOfDay: T.bool,
    id: T.string.isRequired,
    onChange: T.func,
    value: T.oneOfType([T.number, T.string]),
  };

  constructor(p) {
    super(p);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    // call on change if value is a valid date or empty
    if (event.target.value && !event.target.value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return;
    }
    let value = m(event.target.value).valueOf();
    if (this.props.endOfDay) {
      value = m(value).endOf('day').valueOf();
    }
    onChange(this.context.t, this.props.id, value);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    let value = '';
    if (_.get(this.context.t.state, this.props.id)) {
      value = m(_.get(this.context.t.state, this.props.id)).format('YYYY-MM-DD');
    }
    return (
      <Group {...this.props}>
        <input
          autoFocus={this.props.autoFocus}
          type="date"
          className="form-control"
          onChange={this.onChange}
          value={value}
          placeholder="YYYY-MM-DD"
        />
      </Group>
    );
  }
}
