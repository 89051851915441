import React from 'react';
import { T,U } from '@nanaio/util';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Bool, Number, Search, Select, Text } from '../../com/ui/form';

const props = ['location.unit', 'location.shelf', 'location.bin', 'boxNum'];
const columnOptions = [
  'owner',
  'partNumber',
  { id: 'qty', name: 'Quantity' },
  { id: 'location.unit', name: 'Rack' },
  { id: 'location.shelf', name: 'Row' },
  { id: 'location.bin', name: 'Bin' },
  'boxNum',
];

export default class AddInventory extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { activeRow: 0, manualItems: [{}] };
    this.importFile = this.importFile.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentWillMount() {
    const query = { status: { $in: ['active', 'inactive', 'notServingNow'] } };
    const pros = await U.api('post', 'pros/search', { query, limit: 1000 });
    let ownerOptions = _.sortBy(
      pros.map(p => ({ id: p.id, name: _.get(p, 'user.fullName') })),
      'name'
    );
    ownerOptions = [
      { id: 'nana', name: 'Nana' },
      { id: 'ard', name: 'Appliance Repair Doctor' },
      ...ownerOptions,
    ];
    const ownerMap = U.toMap(ownerOptions);
    this.setState({ ownerOptions, ownerMap });
  }

  componentWillUpdate(p, s) {
    const lastItem = _.last(s.manualItems);
    const itemCount = s.manualItems.length;
    if (_.get(lastItem, 'partNumber')) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState(s => _.set(s, `manualItems.${itemCount}`, _.pick(lastItem, 'owner')));
    }
  }

  getSkuId(sku) {
    const propValues = props.map(p => _.get(sku, p) || '');
    return _.reduce(propValues, (a, b) => `${a}-${b}`);
  }

  importFile(e) {
    const fileReader = new FileReader();
    fileReader.onloadend = e =>
      this.setState({
        ignoreHeader: true,
        csvItems: Papa.parse(e.target.result).data,
      });
    fileReader.readAsText(e.target.files[0]);
  }

  async onSave(type) {
    let items;
    if (type === 'csv') {
      // map csv columns to inventory item props
      if (U.findMatches(['owner', 'partNumber', 'qty'], this.state.rowTypes).length !== 3) {
        return this.setState({
          csvError: 'Use the dropdowns to select Owner, Part Number, Qty columns',
        });
      }
      items = this.state.csvItems
        .map(i => {
          const out = {};
          _.map(this.state.rowTypes, (v, n) => {
            if (v) {
              _.set(out, v, i[n]);
            }
          });
          out.owner = (out.owner || '').toLowerCase();
          out.qty = +out.qty;
          return out;
        })
        .filter(i => this.state.ownerMap[i.owner] && i.partNumber && i.qty);
    } else {
      items = this.state[`${type}Items`].filter(i => i.partNumber && i.qty && i.owner);
    }
    this.setState({ [`${type}Progress`]: { top: 0, bot: items.length } });
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const query = { partNumber: item.partNumber, 'owner.id': item.owner };
      // eslint-disable-next-line no-await-in-loop
      const existingItem = _.get(await U.api('post', 'inventory/search', { query }), '0');
      if (existingItem) {
        /* if parts with this part number already exist in inventory add the
          additional quantity to the existing quantity */
        const newSkuId = this.getSkuId(item);
        const existingSkuIds = _.map(existingItem.sku, s => this.getSkuId(s));
        const skuMatchIndex = _.indexOf(existingSkuIds, newSkuId);
        let changes;
        if (skuMatchIndex === -1) {
          changes = [
            {
              action: 'replace',
              path: `sku.${existingSkuIds.length}`,
              value: _.pick(item, ['qty', 'location', 'boxNum', 'floor']),
            },
          ];
        } else {
          changes = [
            {
              action: 'replace',
              path: `sku.${skuMatchIndex}.qty`,
              value: existingItem.sku[skuMatchIndex].qty + item.qty,
            },
          ];
        }
        // eslint-disable-next-line no-await-in-loop
        await U.api('put', `inventory/${existingItem._id}`, changes);
      } else {
        /* if no parts with this part number exist in inventory create a new 
          inventory item */
        const newItem = {
          partNumber: item.partNumber,
          owner:
            item.owner === 'nana'
              ? { id: 'nana', name: 'Nana', type: 'corporate' }
              : {
                  id: item.owner,
                  name: this.state.ownerMap[item.owner].name,
                  type: T.PartOwner.PRO,
                },
          sku: [
            _.pickBy(
              {
                floor: item.floor,
                qty: item.qty,
                boxNum: item.boxNum,
                location: item.location,
              },
              _.identity
            ),
          ],
        };
        // eslint-disable-next-line no-await-in-loop
        await U.api('post', 'inventory', newItem);
      }
      this.setState(s => _.set(s, `${type}Progress.top`, i + 1));
    }
  }

  render() {
    const { csvProgress, manualProgress } = this.state;
    return (
      <div className="p-3" style={{ minHeight: '100vh' }}>
        <div className="d-flex">
          {csvProgress && (
            <span className="mr-3">{`${csvProgress.top} / ${csvProgress.bot} uploaded`}</span>
          )}
          {!!U.length(this.state.csvItems) && (
            <div className="btn btn-primary mr-3" onClick={() => this.onSave('csv')}>
              Save Csv Upload
            </div>
          )}
          <div className="form-group mr-3">
            <label>Import Csv</label>
            <input className="form-control" type="file" onChange={this.importFile} />
          </div>
          {this.state.csvItems && <Bool id="ignoreHeader" />}
        </div>
        {this.state.csvItems && (
          <div>
            <div>Match columns to inventory properties</div>
            <div>Owner can be "nana", "ard", or "pro id"</div>
            <div className="text-danger">{this.state.csvError}</div>
            <table className="table-sm table-striped table-hover table">
              <thead>
                <tr>
                  {_.times(U.length(this.state.csvItems[1]), n => (
                    <th key={n}>
                      <Select id={`rowTypes.${n}`} cap label="" options={columnOptions} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.csvItems.slice(0, 10).map((r, rIndex) => (
                  <tr key={rIndex}>
                    {_.map(r, (c, n) => (
                      <td key={n}>{!rIndex && this.state.ignoreHeader ? `${c} (ignored)` : c}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-5 pt-3" style={{ borderTop: '1px solid black' }}>
          <div className="d-flex">
            {this.state.manualItems[0].partNumber && this.state.manualItems[0].owner && (
              <div className="btn btn-primary mr-3" onClick={() => this.onSave('manual')}>
                Save Manual Upload
              </div>
            )}
            {manualProgress && (
              <span className="mr-3">
                {`${manualProgress.top} / ${manualProgress.bot} uploaded`}
              </span>
            )}
          </div>
          <table
            className="table-sm table-striped table-hover table"
            style={{ tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th>Owner*</th>
                <th>Part Number*</th>
                <th>Quantity*</th>
                <th>Rack</th>
                <th>Row</th>
                <th>Bin</th>
                <th>Box Number</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.state.manualItems, (i, n) => {
                const missingProps = !i.owner || !i.partNumber || !i.qty;
                return (
                  <tr key={n} className={missingProps ? 'table-danger' : ''}>
                    <td>
                      <Search
                        id={`manualItems.${n}.owner`}
                        options={this.state.ownerOptions}
                        label=""
                      />
                    </td>
                    <td>
                      <Text
                        id={`manualItems.${n}.partNumber`}
                        label=""
                        trans={v => v.toUpperCase()}
                      />
                    </td>
                    <td>
                      <Number id={`manualItems.${n}.qty`} label="" />
                    </td>
                    <td>
                      <Text id={`manualItems.${n}.location.unit`} label="" />
                    </td>
                    <td>
                      <Text id={`manualItems.${n}.location.shelf`} label="" />
                    </td>
                    <td>
                      <Text id={`manualItems.${n}.location.bin`} label="" />
                    </td>
                    <td>
                      <Text id={`manualItems.${n}.boxNum`} label="" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
