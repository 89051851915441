import './review.scss';

import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import Loader from '@/com/ui/loader';
import { getParams } from '@/com/util';
import { Icon } from '@/components';
import { companyLogoGray, companyName } from '@/config/const';
import Negative from './negative';
import Positive from './positive';

export default class Review extends React.Component {
  constructor(p) {
    super(p);
    const { taskId, rate } = getParams();
    const state = { taskId, loading: true };
    if (rate) {
      state.rating = +rate;
    }
    this.state = { ...state, submit: false };
    this.onRate = this.onRate.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    if (this.state.taskId) {
      const job = await U.api('get', `tasks/${this.state.taskId}`);
      if (!job.errMsg) {
        this.setState({ job, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  onRate(rating) {
    this.setState({ rating: rating + 1 });
  }

  submit() {
    this.setState({ submitted: true });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (this.state.submitted === true && this.state.rating === 5) {
      return <Positive {...this.state} />;
    }
    if (this.state.submitted === true) {
      return <Negative {...this.state} />;
    }
    return (
      <section id="TaskReview">
        <section className="login--page reviews" style={{ paddingTop: 0 }}>
          <div className="login--container">
            <div className="login--block">
              <div style={{ textAlign: 'center', paddingBottom: '1.5em' }}>
                <img
                  src={companyLogoGray}
                  style={{ height: '25px', margin: '0 auto' }}
                  alt={companyName}
                />
              </div>
              <h3 className="weight-500">Rate your Experience</h3>
              <p className="text-muted text-base">
                How would you rate your experience with {companyName}?
              </p>
              <div className="login--form">
                <ul
                  className={`no-list inline-list star-row d-flex align-items-center justify-content-center ${this.state.rating}`}
                >
                  {_.times(5, n => (
                    <li className={`star-item ${n + 1}`} onClick={() => this.onRate(n)} key={n}>
                      <Icon
                        name="star"
                        size={32}
                        color={n + 1 <= this.state.rating ? '#E6B647' : '#B5B8C1'}
                      />
                    </li>
                  ))}
                </ul>
                {this.state.rating ? (
                  <button onClick={() => this.submit()}>Submit</button>
                ) : (
                  <button className="disabled">Submit</button>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
