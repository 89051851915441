import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { Textbox } from '../../../com/ui/form';
import Link from '../../../com/ui/link';

export default class Negative extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { tags: [], writeANote: false, reviewSent: false };
    this.sendReview = this.sendReview.bind(this);
  }

  componentWillUnmount() {
    if (!this.state.reviewSent) {
      this.sendReview();
    }
  }

  sendReview() {
    const review = {
      refId: this.props.taskId,
      rating: this.props.rating,
      message: this.state.message,
      tags: this.state.tags,
    };
    U.api('post', 'reviews', review);
    this.setState({ reviewSent: true });
  }

  render() {
    const t = this;
    const toggleTag = tag => {
      if (t.state.tags.includes(tag)) {
        return t.setState({ tags: t.state.tags.filter(t => t !== tag) });
      }
      t.setState({ tags: [...t.state.tags, tag] });
    };

    return (
      <section id="TaskReview">
        {!t.state.reviewSent ? (
          <section className="login--page reviews" style={{ paddingTop: 0 }}>
            <div className="login--container md">
              <div className="login--block md">
                <h5 className="weight-500">
                  We&apos;re so sorry about your Experience. What went wrong?
                </h5>
                <div className="login--form">
                  <div className="checklist negative mb-3">
                    {U.review.tagOptions.map((t, n) => (
                      <div
                        className={this.state.tags.includes(t) ? 'badge-item active' : 'badge-item'}
                        onClick={() => toggleTag(t)}
                        key={t}
                      >
                        <div className="select-letter mr-2">
                          {String.fromCharCode(97 + n).toUpperCase()}
                        </div>
                        <p className="flex-1" style={{ color: '#4670B2', textAlign: 'left' }}>
                          {t}
                        </p>
                        {this.state.tags.includes(t) && (
                          <Icon name="check" size="16" color="#4670B2" />
                        )}
                      </div>
                    ))}
                  </div>
                  {t.state.writeANote ? (
                    <Textbox id="message" hint="Type here" label="" />
                  ) : (
                    <div className="text-center">
                      <button
                        className="fb"
                        onClick={() => t.setState({ writeANote: true })}
                        style={{ cursor: 'pointer' }}
                      >
                        Write a note
                      </button>
                    </div>
                  )}
                  <button className="mt-2" onClick={t.sendReview}>
                    Submit Review
                  </button>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="login--page">
            <div className="login--container md">
              <div className="login--block md">
                <h5 className="weight-500">
                  We&apos;ll make it right, {_.get(this.props.job, 'customer.user.fullName')}.
                </h5>
                <p>
                  Thanks for sharing. We have one mission: Making people happy. A member of our
                  customer service will reach out to you as soon as possible.
                </p>
                <br />
                <Link to="/">
                  <button>Go back home</button>
                </Link>
              </div>
            </div>
          </section>
        )}
      </section>
    );
  }
}
