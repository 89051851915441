import React from 'react';
import classNames from 'classnames';
import { Icon, IconProps, Text } from '@/components';

type Status = 'pending' | 'actionNeeded' | 'confirmed';

const iconByStatus: Record<Status, IconProps['name']> = {
  pending: 'schedule',
  actionNeeded: 'warning_amber',
  confirmed: 'check_circle_outline',
};

export const iconColorByStatus = {
  actionNeeded: 'text-danger',
  confirmed: 'text-success',
  pending: 'text-accent-orange',
};

const copyByStatus = {
  pending: {
    title: 'Pending:',
    description: 'The date and time for your appointment are not yet confirmed',
  },
  actionNeeded: {
    title: 'Action Needed',
    description: '',
  },
  confirmed: {
    title: 'Your appointment is confirmed',
    description: '',
  },
};

export type Props = {
  status: Status;
};

export default function StatusTitle({ status }: Props): JSX.Element {
  return (
    <div className="flex">
      <Icon
        name={iconByStatus[status]}
        className={classNames('mr-2 w-4', iconColorByStatus[status])}
        size={16}
      />
      <Text type="helper">
        <b>{copyByStatus[status].title}</b> {copyByStatus[status].description}
      </Text>
    </div>
  );
}
