import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenteeAvailability from '@/components/pages/pro/MenteeAvailability';
import TechnicianAvailability from '@/components/pages/pro/TechnicianAvailability';

function Availability({ pro, proId }) {
  return pro.role === 'mentee' ? (
    <MenteeAvailability pro={pro} proId={proId} />
  ) : (
    <TechnicianAvailability pro={pro} proId={proId} />
  );
}

Availability.propTypes = {
  pro: PropTypes.object.isRequired,
  proId: PropTypes.string.isRequired,
};

export default connect(state => {
  const proId = global.id() || state.me.proId;
  const pro = state.pros[proId];

  return { proId, pro };
})(Availability);
