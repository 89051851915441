import React, { CSSProperties, ReactElement } from 'react';
import classnames from 'classnames';
import theme from '../theme';
import type { InputProps } from './Input';

export const getProps = ({ value, ...rest }: InputProps): Props => ({
  value: typeof value === 'boolean' ? value : undefined,
  ...rest,
});

export type Props = {
  className?: string;
  disabled?: boolean;
  isActive?: boolean;
  onChange?: (value: boolean) => void;
  onClick?: (value: boolean) => void;
  style?: CSSProperties;
  value?: boolean;
};

export default function Switch({
  className = '',
  disabled,
  isActive,
  onChange,
  onClick,
  style = {},
  value,
}: Props): ReactElement {
  const newOnChange = onChange || onClick;
  const newValue = isActive || value;
  const classes = classnames('relative cursor-pointer rounded-lg', className);
  const dotClasses = classnames('absolute -mt-1 h-5 w-5 rounded-full', { 'right-0': newValue });

  const handleClick = () => {
    if (!disabled && newOnChange) {
      newOnChange(!newValue);
    }
  };

  const styles = {
    bar: {
      backgroundColor: newValue
        ? theme.hexToRGB(theme.colors.primaryCTA, disabled ? 0.1 : 0.3)
        : theme.hexToRGB(theme.colors.grey.dark, disabled ? 0.1 : 0.3),
      width: '34px',
      height: '14px',
      ...style,
    },
    dot: {
      backgroundColor: newValue
        ? theme.hexToRGB(theme.colors.primaryCTA, disabled ? 0.5 : 0)
        : theme.hexToRGB(theme.colors.grey.dark, disabled ? 0.5 : 0),
    },
  };

  return (
    <div className={classes} onClick={handleClick} style={styles.bar}>
      <div className={dotClasses} style={styles.dot} />
    </div>
  );
}
