import React, { CSSProperties } from 'react';
import { vendorLogos } from '../../config/vendorLogos';

const vendorNames = Object.keys(vendorLogos);

const valueBySize = {
  small: 24,
  medium: 32,
  large: 48,
  xlarge: 64,
};

export type Props = {
  vendorName: (typeof vendorNames)[number];
  size: keyof typeof valueBySize | number;
  isPublic?: boolean;
  className?: string;
  style?: CSSProperties;
};

/**
 * Component to display an svg image of a vendor logo
 * @param size set height either custom int or small-24px, medium-32px, large-48px, xlarge-64px
 */
export default function VendorLogo({
  vendorName,
  size = 'xlarge',
  isPublic = false,
  className = '',
  style = {},
}: Props): JSX.Element | null {
  if (!(vendorName in vendorLogos)) {
    return <> {vendorName}</>;
  }
  const vendor = vendorLogos[vendorName];

  if (vendor.hidePublic && isPublic) {
    return null;
  }

  if (!vendor.img) {
    return <>{vendor.displayName || vendorName}</>;
  }

  if (typeof size === 'string') {
    size = valueBySize[size];
  }

  style = { ...style, height: size };

  return <img src={vendor.img} className={className} style={style} alt={vendor.displayName} />;
}
