import React from 'react';
import U from '@nanaio/util';
import m from 'moment';
import PropTypes from 'prop-types';
import theme from '../theme';
import Overlay from './Overlay';

const classNames = {
  fixed: 'border-r border-grey-medium',
  standard: 'border-r border-grey-4',
  today: 'border-r border-secondary',
};

const styles = {
  fixed: { ...theme.shadowRight, zIndex: 1 },
  standard: {},
  today: {},
};

export default function Cell({
  children,
  className = '',
  date,
  hasOverlay,
  isFixed,
  onMouseEnter,
  style = {},
}) {
  const isToday = m(date).startOf('day').isSame(m().startOf('day'));
  let cellStyle = styles.fixed;
  if (!isFixed) {
    cellStyle = isToday ? styles.today : styles.standard;
  }
  let type = 'fixed';
  if (!isFixed) {
    type = isToday ? 'today' : 'standard';
  }

  return (
    <div
      className={`${hasOverlay ? 'relative' : ''} ${classNames[type]} ${className}`}
      onMouseEnter={onMouseEnter}
      style={{ ...cellStyle, ...style }}
    >
      {hasOverlay && <Overlay />}
      {children}
    </div>
  );
}

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  date: U.timePropType,
  hasOverlay: PropTypes.bool,
  isFixed: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  style: U.stylePropType,
};
