import React from 'react';
import * as Table from '@nanaio/table';
import TableComponent from '@/components/Table';
import Edit from './edit';

export default function CatalogPartCategoriesTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.catalogPartCategory} // eslint-disable-line react/jsx-props-no-spreading
      addUi={(props: Table.AddUiProps) => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
      editUi={(props: Table.EditUiProps) => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}
