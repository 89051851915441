import React, { ReactNode } from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import { mdiInformationOutline } from '@mdi/js';
// This component is calling Icon from @mdi/react directly to avoid a circular dependency.
import Icon from '@mdi/react';
import U from '@nanaio/util';
import _ from 'lodash';
import Text from '../Text';
import { getArrowClasses, tooltipClasses } from './classes';

type Props = {
  children?: ReactNode;
  className?: string;
  iconColor?: string;
  node?: ReactNode | string;
  noWrap?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  placement?: Config['placement'];
  trigger?: ReactNode;
};

/**
 * Our wrapper class for react-popper-tooltip
 *
 * @param children  Content the tooltip is in reference to (not trigger or tooltip text)
 * @param node  this is the actual tooltip content
 * @param trigger this is content that will trigger the tooltip to be shown when moused over
 * @param iconColor can be any tailwind color, may need to add to safelist if not used elsewhere
 */
export default function Tooltip({
  children,
  iconColor = 'primaryCTA',
  className = '',
  node,
  noWrap,
  onClick,
  onMouseEnter,
  onMouseLeave,
  placement,
  trigger,
}: Props): JSX.Element {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible, state } =
    usePopperTooltip({ placement });

  if (!node) {
    return (
      <div
        className={className}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children || trigger || null}
      </div>
    );
  }

  return (
    <div
      className={`relative flex ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <div ref={setTriggerRef}>
        {trigger || (
          <Icon
            className={`relative text-${iconColor} -mt-0.5 mb-1 ml-1`}
            path={mdiInformationOutline}
            style={{ height: 14 }}
          />
        )}
      </div>
      {visible && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div ref={setTooltipRef} {...getTooltipProps({ className: tooltipClasses })}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...getArrowProps({ className: getArrowClasses(state?.placement) })} />
          {U.toArray(node).map(node =>
            U.isText(node) ? (
              <Text color="white" key={node as string} noWrap={noWrap} className="overflow-hidden">
                {node}
              </Text>
            ) : (
              node
            )
          )}
        </div>
      )}
    </div>
  );
}
