import React from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import { Badge, Table as TableComponent } from '@/components';
import { kebabColors } from '@/components/core/Badge';
import AddVendor from './add';

const StatusUI = ({ value }: { value: string }) => {
  const statusToColor = {
    [U.org.Status.ACTIVE]: kebabColors[1],
    [U.org.Status.APPLICANT]: kebabColors[7],
    [U.org.Status.CREDIT_HOLD]: kebabColors[3],
    [U.org.Status.INACTIVE]: kebabColors[6],
  };
  return (
    <Badge color={statusToColor[value as keyof typeof statusToColor]}>{_.startCase(value)}</Badge>
  );
};

export default function VendorsTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.vendor} // eslint-disable-line react/jsx-props-no-spreading
      addUi={(props: Table.AddUiProps) => <AddVendor {...props} />} // eslint-disable-line react/jsx-props-no-spreading
      uiModuleIdToModule={{ status: { cellUi: StatusUI } }}
    />
  );
}
