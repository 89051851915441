import React from 'react';

export default ({ context, contextType }) =>
  WrappedComponent => {
    class WithContext extends React.Component {
      static childContextTypes = contextType;

      getChildContext() {
        return context;
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithContext;
  };
