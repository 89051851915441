import React, { useState } from 'react';
import { Task, TaskAuthorization, U } from '@nanaio/util';
import _ from 'lodash';
import { Form, Input, InputType, Modal } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';

const requirementOptions = [
  { id: T.AuthorizationRequirement.RECALL, name: 'Recall Job' },
  { id: T.AuthorizationRequirement.CANT_REPAIR, name: 'Unit Marked As Unrepairable' },
];

const resolutionOptions = [
  { id: T.AuthorizationResolution.CONTINUE, name: 'Yes - Continue with repair' },
  { id: T.AuthorizationResolution.CLOSE, name: 'No - Unit will be replaced or cashed out' },
];

type Props = {
  onClose: () => void;
  task: Task;
};

export default function AuthorizationModal({ onClose, task }: Props): JSX.Element {
  const [authorization, setAuthorization] = useState<Partial<TaskAuthorization>>({
    callAttempts: [],
  });
  const orgId = T.orgId(task);
  const isSamsung = orgId === U.org.vendors.samsung;
  const requiredValues = [
    'requirement',
    'resolution',
    authorization.resolution === T.AuthorizationResolution.CLOSE
      ? 'caseNumber'
      : 'callReferenceNumber',
  ] as const;
  const disableSaveButton = requiredValues.some(value => !authorization[value]);

  const [updateAuthorizations, { error }] = useLazyLegacyAPI<Task>(
    `tasks/${task.id}/authorizations`,
    {
      method: 'put',
      save: true,
    }
  );

  const handleSave = async () => {
    const task = await updateAuthorizations(authorization);
    if (!task || 'errMsg' in task) {
      return;
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} hideOnBackdropClick={false}>
      <Modal.Header title="Add Authorization" />
      <Modal.Body className="p-4">
        {error && <div className="mb-4">{error}</div>}
        <Form onChange={setAuthorization} value={authorization}>
          <Input
            id="requirement"
            label="Authorization Type"
            options={requirementOptions}
            type={InputType.SEARCH}
            required
          />

          <Input
            id="resolution"
            label="Would the vendor like to continue with the repair?"
            options={resolutionOptions}
            type={InputType.SEARCH}
            required
          />

          {authorization.resolution && (
            <>
              {authorization.resolution === T.AuthorizationResolution.CLOSE ? (
                <Input
                  id="caseNumber"
                  label={isSamsung ? 'Exchange Number' : 'Case Number'}
                  type={InputType.TEXT}
                  required
                />
              ) : (
                <Input
                  id="callReferenceNumber"
                  label="Call Reference Number"
                  type={InputType.TEXT}
                  required
                />
              )}

              <Input
                id="note"
                label="Case Notes"
                placeholder="Start typing here"
                type={InputType.TEXT}
                multiline
              />
            </>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer disableSaveButton={disableSaveButton} onSave={handleSave} />
    </Modal>
  );
}
