import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { updateTask } from '../../com/task';
import { Modal, Number } from '../../com/ui/form';

class ShipmentLabel extends React.Component {
  static propTypes = { id: T.string };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.getShipmentProps = this.getShipmentProps.bind(this);
    this.loadEntity = this.loadEntity.bind(this);
    this.printLabel = this.printLabel.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(p) {
    if (!this.props.id && p.id) {
      this.setState({
        modal: true,
        pdf: false,
        carrierService: 'fedex_ground',
        weight: 16,
      });
    }
  }

  getShipmentProps(direction) {
    if (!this.state.modal) {
      return [];
    }
    return [
      { label: 'Id', render: v => v.id, apiPath: 'id', hideRender: true },
      { label: 'Name', render: v => v.name, apiPath: 'name' },
      { label: 'Phone', render: v => v.phone, apiPath: 'phone' },
      {
        label: 'Company',
        apiPath: 'company_name',
        render: v => {
          if (direction === 'to' && v.name !== this.props.job.customer.user.fullName) {
            return `CUST: ${this.props.job.customer.user.fullName}`;
          }
          return v.company;
        },
      },
      {
        label: 'Address Line 1',
        render: v => `${v.address.streetNumber} ${v.address.route}`,
        apiPath: 'address_line1',
      },
      { label: 'Address Line 2', render: v => v.address.unit, apiPath: 'address_line2' },
      { label: 'City', render: v => v.address.locality, apiPath: 'city_locality' },
      { label: 'State', render: v => v.address.region, apiPath: 'state_province' },
      { label: 'Zip', render: v => v.address.postalCode, apiPath: 'postal_code' },
      {
        label: 'Is Residential Address',
        render: v => (v.isResidential ? 'Yes' : 'No'),
        apiPath: 'address_residential_indicator',
      },
    ];
  }

  async loadEntity(entity) {
    if (_.isEmpty(entity) || !entity.id || entity.id === 'other') {
      return entity;
    }
    let { type } = entity;
    if (!type) {
      type = this.props.vendors[entity.id] ? 'vendor' : 'user';
    }
    if (!type) {
      return { error: 'not found' };
    }
    const serverEntity = ['vendor', 'nana'].includes(type) ? 'orgs' : 'users';
    const r = await U.api('get', `${serverEntity}/${entity.id}`);
    if (!r) {
      return entity;
    }

    if (['vendor', 'nana'].includes(type)) {
      return {
        type,
        id: r.id,
        name: r.name,
        phone: r.phone,
        address: r.address,
        company: r.name,
        isResidential: false,
      };
    }
    return {
      type,
      id: r.id,
      name: _.get(r, 'profile.fullName'),
      phone: _.get(r, 'profile.phone'),
      address: _.get(r, 'profile.address'),
      isResidential: true,
    };
  }

  async printLabel() {
    if (!this.state.carrierService) {
      return this.setState({ error: 'Carrier service required' });
    }
    if (!this.state.weight) {
      return this.setState({ error: 'Weight required' });
    }
    const createRecipient = (v, direction) => {
      const out = { country_code: 'US' };
      this.getShipmentProps(direction).map(p => {
        const value = p.render(v);
        if (value) {
          out[p.apiPath] = value;
        }
      });
      return out;
    };
    const request = {
      method: 'post',
      path: 'labels',
      body: {
        shipment: {
          service_code: _.get(this.props.job, `parts.shipping.${this.props.id}.service`),
          ship_to: createRecipient(this.props.recipients.to, 'to'),
          ship_from: createRecipient(this.props.recipients.from, 'from'),
          packages: [
            {
              weight: {
                value: this.state.weight,
                unit: 'ounce',
              },
            },
          ],
        },
      },
    };
    const r = await U.api('post', 'shipengine', request);
    if (!r || r.message) {
      return this.setState({ error: _.get(r, 'message', 'Unable to connect') });
    }
    if (r.errors && r.errors.length) {
      const error = _.reduce(
        r.errors.map(e => e.message),
        (a, b) => `${a}, ${b}`
      );
      this.setState({ error });
    }
    const pdf = _.get(r, 'label_download.pdf');
    this.setState({ pdf });
    const partIds = _.get(this.props.job, `shipping.${this.props.id}.parts`, []);

    const track = {
      resolved: true,
      id: r.tracking_number,
      carrier: 'fedex',
    };

    const shippingTotal = {
      total: _.get(r, 'shipment_cost.amount', 0) * 100,
    };

    const changes = [
      {
        action: 'replace',
        path: `parts.shipping.${this.props.id}.track`,
        value: track,
      },
      {
        action: 'replace',
        path: `parts.shipping.${this.props.id}.price`,
        value: shippingTotal,
      },
      {
        action: 'replace',
        path: `parts.shipping.${this.props.id}.shipmentLabelPdf`,
        value: pdf,
      },
      ...partIds.map(id => ({
        action: 'replace',
        path: `parts.items.${id}.status`,
        value: 'shipped',
      })),
    ];
    await updateTask(this.props.job.id, changes);

    try {
      const v = window.open(pdf, '_blank');
      v.focus();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }

    this.props.onSuccess(true);
    this.setState({ modal: false });
  }

  render() {
    return (
      <div>
        <Modal
          title="Create Label"
          submit={!this.state.error && this.printLabel}
          saveText="Print Label"
          onClose={this.props.onClose}
          className="partModal"
        >
          <div className="text-danger">{this.state.error}</div>
          <div className="row">
            <Number id="weight" label="Weight (in ounces)" className="col-sm-6 col-lg-4" req />
          </div>
          <div className="row">
            {['from', 'to'].map(v => (
              <div className="col" key={v}>
                <table className="table-sm table-striped table-hover table">
                  <thead>
                    <tr>
                      <th>{_.startCase(v)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getShipmentProps(v)
                      .filter(p => !p.hideRender)
                      .map((p, i) => (
                        <tr key={i}>
                          <td>{p.label}</td>
                          <td>{p.render(this.props.recipients[v]) || '-'}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          {this.state.pdf && (
            <a
              href={this.state.pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mt-3"
            >
              Open Pdf
            </a>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const shipment = _.get(job, `parts.shipping.${p.id}`, {});
  const recipients = {};
  _.map(['from', 'to'], v => {
    const out = _.pick(shipment[v], ['address', 'id', 'name', 'phone', 'type']);
    out.isResidential = !['vendor', 'nana'].includes(out.type);
    if (['vendor', 'nana'].includes(out.type)) {
      out.company = out.name;
    }
    recipients[v] = out;
  });
  return { job, recipients };
})(ShipmentLabel);
