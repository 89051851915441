import React, { useCallback, useState } from 'react';
import { DiagnosisOptions, FormattedQuestion, T, Task, TaskDiagnosis, U, W } from '@nanaio/util';
import _ from 'lodash';
import { Avatar, Button, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import DiagnosisActivity from './DiagnosisActivity';
import DiagnosisModal from './DiagnosisModal';
import { DIAGNOSIS_PATH, isMTDiagnosis, MT_DIAGNOSIS_PATH } from './util';

const TECH_PHOTO_URL = '../../../../img/customerPortal/profilePicPlaceholder.png';
const MASTER_TECH_PHOTO_URL = '../../../../img/customerPortal/masterTechIcon.png';

const copyByQuestionId = {
  [DIAGNOSIS_PATH]: {
    title: 'Technician Diagnoses',
    emptyTitle: 'No diagnoses added',
    emptyBody:
      'Once the assigned technician has diagnosed the issue(s), their findings will be displayed here.',
  },
  [MT_DIAGNOSIS_PATH]: {
    title: 'MT Recommended Diagnoses',
    emptyTitle: 'No recommendations added',
    emptyBody:
      'Add issues and recommend possible diagnoses by tapping the button above.  This will be shared to the assigned technician.',
  },
};

type Props = {
  question: FormattedQuestion;
  task: Task;
};

// Displays the information for both tech and MT diagnosis.
// Intended to be used in the Questions component.
export default function Diagnosis({ question, task }: Props): JSX.Element {
  const { isAssociateProblemWithDiagnosisEnabled } = useLegacySelector(state => {
    const workOrder = state.workorders[task.metadata.workOrderId];
    const isAssociateProblemWithDiagnosisEnabled = workOrder.tags.includes(
      W.Tag.PROBLEMS_AND_DIAGNOSES_ASSOCIATION
    );
    return { isAssociateProblemWithDiagnosisEnabled };
  });

  const [editDiagnosis, setEditDiagnosis] = useState<TaskDiagnosis | undefined>();
  const [diagnosisIndex, setDiagnosisIndex] = useState<number | undefined>();
  const [diagnosisOptions, setDiagnosisOptions] = useState<DiagnosisOptions | undefined>();

  const openDiagnosis = useCallback(
    (editDiagnosis: TaskDiagnosis | undefined, diagnosisIndex: number | undefined) => {
      if (diagnosisIndex === undefined) {
        diagnosisIndex = U.length(task, question.id);
      } else {
        diagnosisIndex = +diagnosisIndex;
      }
      if (!editDiagnosis) {
        editDiagnosis = { code: [], diagnosis: '', problem: '' };
      }
      setDiagnosisIndex(diagnosisIndex);
      setDiagnosisOptions(T.diagnosisOptions(task));
      setEditDiagnosis(editDiagnosis);
    },
    [question.id, task]
  );

  const copy = copyByQuestionId[question.id as keyof typeof copyByQuestionId];
  const diagnosisArray = _.get(task, question.id) as TaskDiagnosis[];
  const pictureUrl = isMTDiagnosis(question.id) ? MASTER_TECH_PHOTO_URL : TECH_PHOTO_URL;
  return (
    <>
      <div className="col-sm-6 pb-4" key={question.id}>
        <div className="flex justify-between">
          <Text className="mt-2" type="label">
            {copy.title}
          </Text>
          <Button onClick={() => openDiagnosis(undefined, undefined)} size="small">
            Add New
          </Button>
        </div>
        {_.isEmpty(diagnosisArray) ? (
          <div className="my-6 flex">
            <div>
              <Avatar
                className="bg-secondary bg-opacity-5"
                iconProps={{ name: 'clipboard_pulse_outline', color: 'icons.grey' }}
                size="large"
                variant="circle"
              />
            </div>
            <div className="flex flex-col">
              <Text className="text-secondary-5" type="button">
                {copy.emptyTitle}
              </Text>
              <Text className="mt-2 text-grey-dark">{copy.emptyBody}</Text>
            </div>
          </div>
        ) : (
          _.map(diagnosisArray, (diagnosis, index) => (
            <div key={index}>
              {index !== 0 && <hr />}
              <div className="my-6">
                <div className="flex flex-row">
                  <div>
                    <Avatar
                      className="bg-secondary bg-opacity-5"
                      iconProps={{ name: 'clipboard_pulse_outline', color: 'icons.grey' }}
                      pictureUrl={pictureUrl}
                      size="medium"
                      variant="circle"
                    />
                  </div>
                  <div className="w-full">
                    <div className="d-flex justify-content-between">
                      <DiagnosisActivity
                        diagnosisIndex={index}
                        questionId={question.id}
                        task={task}
                      />
                      <Button
                        className="py-0"
                        onClick={() => openDiagnosis(diagnosis, index)}
                        variant="link"
                        size="small"
                      >
                        Edit
                      </Button>
                    </div>
                    {isAssociateProblemWithDiagnosisEnabled && (
                      <div className="mt-1 flex space-x-2">
                        <Text className="font-normal text-secondary" type="label">
                          Problem:
                        </Text>
                        <Text className="text-secondary" type="label">
                          {diagnosis.problem}
                        </Text>
                      </div>
                    )}
                    <Text className="mt-4">{diagnosis.diagnosis}</Text>
                    <Text className="mt-4 text-grey-dark" type="label">
                      {_.join(diagnosis.code, ' > ')}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {diagnosisIndex !== undefined && (
        <DiagnosisModal
          code={editDiagnosis?.code}
          diagnosis={editDiagnosis?.diagnosis}
          diagnosisIndex={diagnosisIndex}
          diagnosisOptions={diagnosisOptions}
          diagnosisQuestionId={question.id}
          isAssociateProblemWithDiagnosisEnabled={isAssociateProblemWithDiagnosisEnabled}
          isMTDiagnosis={isMTDiagnosis(question.id)}
          onClose={() => setEditDiagnosis(undefined)}
          problem={editDiagnosis?.problem}
          task={task}
        />
      )}
    </>
  );
}
