import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import { supportPhoneNumber } from '@/config/const';
import Alert, { Props } from './Alert';
import Text from './Text';

const onDismissed = () => {
  alert('Dismissed');
};

const handleButtonClick = () => {
  alert('Button Clicked');
};

export default {
  title: 'Core/Alert',
  component: Alert,
  variant: 'error',
  args: {
    children: 'Unable to Upload Photo',
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof Alert>;

const Template: ComponentStory<typeof Alert> = args => <Alert {...args} />;

export const Default = Template.bind({});
Default.args = { variant: 'error' };

export const Variants = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Variant prop options</p>
    <p className="mt-6">default</p>
    <Default {...args} />
    <p className="mt-6">error</p>
    <Default {...args} variant="error" />
    <p className="mt-6">info</p>
    <Default {...args} variant="info" />
    <p className="mt-6">success</p>
    <Default {...args} variant="success" />
    <p className="mt-6">warning</p>
    <Default {...args} variant="warning" />
  </div>
);

export const LongDesc = Template.bind({});
LongDesc.args = {
  variant: 'error',
  title: 'And with a title',
  children:
    'This is an alert with a very long title. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Velit euismod in pellentesque massa placerat. In dictum non consectetur a erat. Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing. Pharetra et ultrices neque ornare. Leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Hendrerit gravida rutrum quisque non.',
  dismissable: true,
};

export const HideIcon = Template.bind({});
HideIcon.args = {
  variant: 'error',
  children: 'Icon Hidden',
  hideIcon: true,
};

export const CustomIcon = Template.bind({});
CustomIcon.args = {
  variant: 'success',
  children: 'Custom Icon',
  icon: 'credit_card_outline',
};

export const AutoDismiss = Template.bind({});
AutoDismiss.args = {
  variant: 'error',
  children: 'Dismissing after 5 seconds',
  dismissAfterSeconds: 5,
};

export const DismissAction = Template.bind({});
DismissAction.args = {
  variant: 'error',
  children: 'Click dismiss to see message',
  dismissable: true,
  onDismissed: onDismissed,
};

export const ConfirmRepairBanner = Template.bind({});
ConfirmRepairBanner.args = {
  variant: 'warning',
  title: 'Confirm Your Repair',
  children: 'Please ensure your service address, contact info, and repair details are correct.',
  primaryButtonProps: {
    size: 'medium',
    children: 'Get Started',
    onClick: () => handleButtonClick(),
  },
  secondaryButtonProps: {
    size: 'medium',
    children: 'Contact Support',
    href: `tel:${supportPhoneNumber}`,
  },
  helperText: (
    <div className="flex place-items-center space-x-1">
      <Text className="text-grey-dark" type="helper">
        Please confirm by <b>Jan 22, 2022</b> at <b>11:59PM</b>
      </Text>
    </div>
  ),
};
