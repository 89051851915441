import './schedule.css';

import React from 'react';
import _ from 'lodash';
import m from 'moment';

export default class Schedule extends React.Component {
  state = {
    start: m().startOf('week').valueOf(),
  };

  render() {
    const t = this;
    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col" />
          {_.times(12, n => (
            <div key={n} className="col">
              {m(t.state.start)
                .add(8 + n, 'h')
                .format('ha')}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
