import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Modal, Search, Text } from '../../com/ui/form';
import { emailRe } from '../../config/const';

class UserAdd extends React.Component {
  static propTypes = {
    isOpen: T.bool,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.search = this.search.bind(this);
    this.submit = this.submit.bind(this);
  }

  async search(email) {
    const r = await U.api('post', 'users/search', { query: { email } });
    if (r.length) {
      return this.setState({ error: 'this user is already registered' });
    }
    this.setState(state => ({ ...state, error: '' }));
  }

  async submit() {
    const { email, phone, firstName, lastName } = this.state;
    if (!email) {
      return this.setState({ error: 'email required' });
    }
    if (!emailRe.test(email)) {
      return this.setState({ error: 'invalid email' });
    }
    if (!phone) {
      return this.setState({ error: 'phone required' });
    }
    if (!firstName) {
      return this.setState({ error: 'first name required' });
    }
    if (!lastName) {
      return this.setState({ error: 'last name required' });
    }
    const body = {
      email: _.trim(email),
      profile: {
        firstName: _.trim(firstName),
        lastName: _.trim(lastName),
        phone: U.trimPhone(phone),
      },
    };
    if (this.props.roles.admin && _.values(this.state.roles).length) {
      body.roles = this.state.roles;
    }
    const r = await U.api('post', 'users', body);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.toggleOpen();
    this.setState({ email: '', phone: '', firstName: '', lastName: '' });
    window.open(`/users/${r.user._id}`, '_blank').focus();
  }

  render() {
    return (
      <Modal
        className="ui-modal md ui-select-search"
        title="Add User"
        submit={this.submit}
        isOpen={this.props.isOpen}
        onClose={this.props.toggleOpen}
      >
        <p className="text-danger">{this.state.error}</p>
        <div className="row">
          <Text id="email" className="col-sm-6" debounce={this.search} req />
          <Text id="phone" className="col-sm-6" req />
          <Text id="firstName" className="col-sm-6" req />
          <Text id="lastName" className="col-sm-6" req />
        </div>
        {this.props.roles.admin && <Search id="roles" options={U.user.roleOptions} multi />}
      </Modal>
    );
  }
}

export default connect(s => ({ roles: U.user.roles(s) }))(UserAdd);
