import React from 'react';
import { connect } from 'react-redux';
import { T,U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Address, Money, Number, Search, Text, Textbox } from '../../com/ui/form';

const state = { owner: 'nana', qty: 1 };
const props = [
  'partNumber',
  'owner',
  'sku',
  'desc',
  'price',
  'status',
  'location',
  'totQty',
  'notes',
];

class EditInventory extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = state;
    this.submit = this.submit.bind(this);
    this.isChange = this.isChange.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
  }

  async componentWillMount() {
    const item = await U.api('get', `inventory/${global.id()}`, true);
    if (item.id) {
      this.setState(item);
    }
    if (this.props.owners.length === 1) {
      const query = { status: { $ne: 'applicant' } };
      U.api('post', 'pros/search', { query, limit: -1 }, true);
    }
  }

  componentWillUpdate(p, s) {
    if (_.isEmpty(this.state)) {
      return;
    }
    if (!_.isEqual(this.state.sku, s.sku)) {
      const totQty = _.sum(_.values(s.sku).map(v => v.qty || 0));
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ totQty });
    }
  }

  isChange() {
    if (_.isEmpty(this.state) || !this.props.inventory) {
      return false;
    }
    return props.find(p => !_.isEqual(this.props.inventory[p], this.state[p]));
  }

  async submit() {
    if (!this.state.partNumber) {
      return this.setState({ error: 'Part number required' });
    }
    if (!this.state.owner) {
      return this.setState({ error: 'Owner required' });
    }
    const changes = props
      .filter(p => !_.isEqual(this.props.inventory[p], this.state[p]))
      .map(p => ({ action: 'replace', path: p, value: this.state[p] }));
    if (changes.length) {
      const r = await U.api('put', `inventory/${global.id()}`, changes, true);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
    }
  }

  addGroup() {
    const sku = _.values(this.state.sku);
    this.setState({ sku: [...sku, { qty: 1 }] });
  }

  removeGroup(index) {
    const sku = _.values(this.state.sku).filter((s, i) => i !== index);
    this.setState({ sku });
  }

  render() {
    return (
      <div>
        <div className="card card-body">
          <div className="text-danger">{this.state.error}</div>
          <div className="row">
            <Text id="partNumber" className="col-sm-4" req disabled />
            <Number id="totQty" label="Total Quantity" className="col-sm-4" disabled />
            <Search
              id="owner.id"
              label="Owner"
              className="col-sm-4"
              options={this.props.owners}
              req
              disabled
            />
          </div>
          {_.values(this.state.sku).map((s, i) => (
            <div key={i} style={{ border: '1px solid black' }}>
              <div className="pull-right link-blue" onClick={() => this.removeGroup(i)}>
                Remove Group
              </div>
              <strong>Group {i + 1}</strong>
              <div key={i} className="row">
                <Number id={`sku.${i}.qty`} label="Quantity" className="col-sm-4" req />
                <Address id={`sku.${i}.location.address`} className="col-sm-8" />
              </div>
              <div className="row">
                <Text id={`sku.${i}.floor`} className="col" />
                <Text id={`sku.${i}.location.unit`} className="col" />
                <Text id={`sku.${i}.location.shelf`} className="col" />
                <Text id={`sku.${i}.location.bin`} className="col" />
              </div>
            </div>
          ))}
          <div className="pull-right link-blue" onClick={this.addGroup}>
            Add Group
          </div>
          <div className="row">
            <Text id="desc" label="Description" className="col-sm-6" />
            <Text id="status" className="col-sm-6" />
            <Money id="price.ebay" className="col-sm-4" />
            <Money id="price.retail" className="col-sm-4" />
            <Money id="price.wholesale" className="col-sm-4" />
          </div>
          <div className="row">
            <Textbox id="notes" label="Notes" className="col-sm-12" />
          </div>
          {this.isChange() && (
            <div className="btn btn-primary" onClick={this.submit}>
              Save
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(s => {
  const owners = [{ id: 'nana', name: 'Nana', type: T.PartOwner.CORPORATE }];
  _.sortBy(_.values(s.pros), 'name').map(p =>
    owners.push({ type: T.PartOwner.PRO, name: p.name, id: p.id })
  );
  return {
    inventory: s.inventory[global.id()],
    owners,
  };
})(EditInventory);
