import React from 'react';
import { U } from '@nanaio/util';
import m from 'moment';
import PT from 'prop-types';
import { Number, Select, Text } from '../../com/ui/form';

export default function ReturnPartStep3({
  buyLabel,
  buyLabelLoading,
  disabled,
  estimatedRate,
  getRates,
  hasShipmentInfo,
  packedBoxes,
  partReturn,
  partsInShipment,
  titleCom,
  voidLabel,
}) {
  return (
    <div id="AdminForm" className="container">
      {titleCom}
      <section className="stacked-form row">
        <div className="col pr-5">
          <p className="legend mb-3 py-2">RMA Number</p>
          <Text
            id="partReturn.referenceNumber"
            label="RMA No."
            hint=""
            disabled
            className="d-flex stacked-form-input"
          />
          <Text
            id="partReturn.externalReferenceNumber"
            label="External RMA No."
            hint=""
            className="d-flex stacked-form-input"
          />
          <p className="legend d-flex align-items-center mb-3 mt-4 py-2">
            <span className="flex-1">Package</span>
            {!!packedBoxes?.length && (
              <span className="text-muted">(Recommended {packedBoxes[0].box.reference})</span>
            )}
          </p>
          <div className="form-group d-flex stacked-form-input dimensions">
            <label className="d-block">Dimensions (W x L x H)</label>
            <div className="d-flex">
              <Number
                id="partReturn.width.value"
                min={0}
                hint="W"
                label=""
                disabled={disabled}
                className="mr-2"
              />
              <Number
                id="partReturn.length.value"
                hint="L"
                label=""
                disabled={disabled}
                className="mr-2"
              />
              <Number
                id="partReturn.height.value"
                min={0}
                hint="H"
                label=""
                className="mr-2"
                disabled={disabled}
              />
              <Select id="partReturn.height.unit" label="" options={['inch']} cap disabled />
            </div>
          </div>
          <div className="form-group d-flex stacked-form-input weight">
            <label className="d-block">Weight</label>
            <div className="d-flex">
              <Number
                id="partReturn.weight.value"
                min={0}
                label=""
                className="mr-2 flex-1"
                disabled={disabled}
              />
              <Select id="partReturn.weight.unit" label="" options={['pound']} cap disabled />
            </div>
          </div>
          {!hasShipmentInfo && (
            <button
              className="d-block primary full-width mt-4"
              onClick={getRates}
              disabled={!!estimatedRate}
            >
              Get Estimate
            </button>
          )}
        </div>
        <div className="col pl-5">
          <div className="summary text-muted">
            {hasShipmentInfo ? (
              <div className="px-3 py-4">
                <p className="mb-2 text-center">Label Purchased</p>
                <table className="full-width">
                  <tbody>
                    <tr>
                      <td>Tracking No:</td>
                      <td className="text-dark text-right">{partReturn?.track?.id}</td>
                    </tr>
                    <tr>
                      <td>Shipping Cost:</td>
                      <td className="text-dark text-right">
                        {U.toMoney(partReturn.returnShippingCost)}
                      </td>
                    </tr>
                    <tr>
                      <td>Expected to Ship:</td>
                      <td className="text-dark text-right">
                        {m(partReturn?.track?.expectedToShip).format('ddd, MMM 1, YYYY')}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button className="d-block secondary full-width" onClick={voidLabel}>
                  Void Label
                </button>
              </div>
            ) : (
              <div className="px-3 py-4">
                <p className="mb-2 text-center">Estimate Shipping Cost</p>
                <div className="estimate text-dark mb-3">
                  {/* eslint-disable-next-line camelcase */}
                  {estimatedRate ? `$${estimatedRate?.shipping_amount?.amount}` : '--'}
                </div>
                <button
                  className="d-block primary full-width mb-3"
                  disabled={buyLabelLoading || !estimatedRate}
                  onClick={buyLabel}
                >
                  Purchase Label
                </button>
              </div>
            )}
            <div className="summary-items px-3 py-4">
              <div className="row mb-4">
                <div className="col">
                  <p className="mb-3">Ship From:</p>
                  <p>
                    <strong className="text-dark">{partReturn.shipFrom.name}</strong>
                    <br />
                    {U.addressLine12(partReturn.shipFrom.address)}
                    <br />
                    {U.addressLine3(partReturn.shipFrom.address)}
                    <br />
                    {U.trimPhone(partReturn.shipFrom.phone, true)}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-3">Ship To:</p>
                  <p>
                    <strong className="text-dark">{partReturn.shipTo.name}</strong>
                    <br />
                    {U.addressLine12(partReturn.shipTo.address)}
                    <br />
                    {U.addressLine3(partReturn.shipTo.address)}
                    <br />
                    {U.trimPhone(partReturn.shipTo.phone, true)}
                  </p>
                </div>
              </div>
              <p className="mb-2">Return Items ({partsInShipment.length})</p>
              <div>
                <table className="full-width">
                  <tbody>
                    {partsInShipment.map((part, i) => (
                      <React.Fragment key={`part-${i}`}>
                        <tr>
                          <td colSpan="4" className="pt-2">
                            <strong className="text-dark">{part.desc}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-2">Return Qty:</td>
                          <td className="text-dark">
                            {part.returnQuantity} of {part?.unit?.value}
                          </td>
                          <td className="pr-2">Return Value:</td>
                          <td className="text-dark">
                            {U.toMoney(part.returnQuantity * part.pricePerUnit)}
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-2">P/N:</td>
                          <td className="text-dark">{part.partNumber}</td>
                          <td className="pr-2">Return ID:</td>
                          <td className="text-dark">{part.returnShortId}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
ReturnPartStep3.propTypes = {
  buyLabel: PT.func.isRequired,
  buyLabelLoading: PT.bool.isRequired,
  disabled: PT.bool.isRequired,
  estimatedRate: PT.object,
  getRates: PT.func.isRequired,
  hasShipmentInfo: PT.bool.isRequired,
  packedBoxes: PT.array,
  partReturn: PT.object.isRequired,
  partsInShipment: PT.array.isRequired,
  titleCom: PT.object.isRequired,
  voidLabel: PT.func.isRequired,
};
