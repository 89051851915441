import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import _ from 'lodash';
import { Avatar, Icon } from '@/components';

class RatePro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      rating: 0,
      tags: [],
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onStarClick = nextValue => {
    this.setState({ rating: nextValue });
  };

  onTagClick = tag => {
    if (this.state.tags.includes(tag)) {
      this.setState({ tags: this.state.tags.filter(t => t !== tag) });
    } else {
      this.setState({ tags: [...this.state.tags, tag] });
    }
  };

  render() {
    const { rating } = this.state;
    const task = global.tasks[global.id()];
    if (!task) {
      return null;
    }
    const proName = _.get(task, 'visits.0.assignee.user.firstName');

    return (
      <div id="rateModal">
        <Button className="rateBtn" onClick={this.toggle}>
          {this.props.buttonLabel}Review {proName}
        </Button>
        <Modal
          id="rateModal"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalBody>
            <div className="closer">
              <p onClick={this.toggle} className="closeBtn">
                <Icon name="close" size={16} />
              </p>
            </div>
            <div className="header">
              <div className="container">
                <Row>
                  <Col md="2" sm="12" xs="12">
                    <Avatar name={proName} size="16" />
                  </Col>
                  <Col md="10" sm="12" xs="12">
                    <h5 className="mb-3 mt-3">
                      How was your experience, <br />
                      with {proName}?
                    </h5>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      starColor="#FBBF1F"
                      emptyStarColor="#CBCBCB" /* color of non-selected icons, default `#333` */
                      value={rating}
                      onStarClick={this.onStarClick}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ratingText">
              <div className="wrapper">
                <textarea
                  rows="4"
                  cols="50"
                  placeholder="Write about your experience. Comments and ratings are anonymously shared with the pro."
                />
                {rating === 0 || rating >= 4 ? (
                  <div className="badges">
                    {[
                      'Excellent Service',
                      'Very Friendly',
                      'Very Responsive',
                      'On-Time',
                      'Very Professional',
                      'Extremely skilled',
                      'Efficient',
                    ].map(t => (
                      <div
                        className={this.state.tags.includes(t) ? 'badge-item active' : 'badge-item'}
                        onClick={() => this.onTagClick(t)}
                        key={t}
                      >
                        {t}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="badges negative">
                    {[
                      'Unprofessional',
                      'Not Responsive',
                      'Arrived late',
                      'No Show',
                      'Submitted Incorrect Invoice',
                      'Behavior',
                      'Did not help',
                      'Other',
                    ].map(t => (
                      <div
                        className={this.state.tags.includes(t) ? 'badge-item active' : 'badge-item'}
                        onClick={() => this.onTagClick(t)}
                        key={t}
                      >
                        {t}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-100 px-2 py-3">
              <Button className="w-100 py-3" color="primary" onClick={this.toggle}>
                SUBMIT
              </Button>{' '}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default RatePro;
