import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, Icon } from '@/components';
import Schedule from '@/components/Schedule';
import { Address, Bool, Search, Select, Text } from '../../../com/ui/form';
import { referredBy } from '../../../com/user';

class CustomerInformation extends React.Component {
  state = {};

  onReferralCodeBlur = () => {
    const { setState, state } = this.props;
    if (!_.get(state, 'profile.referredBy') && _.get(state, 'referralCode')) {
      setState(s => _.set(s, 'profile.referredBy', 'Previous Customer'));
    }
  };

  createTaskVisit = async ({ notify, visit }) => {
    const { setState } = this.props;
    const toTaskSlot = slot => ({
      startTime: m(slot.start).valueOf(),
      endTime: m(slot.end).valueOf(),
      preferred: slot.preferred,
    });

    const taskVisit = {
      availTSlots: visit.cx.availability.map(toTaskSlot),
      confirmed: true,
      preferredTimeSlot: toTaskSlot(visit.slot),
    };
    if (visit.pros[0].id) {
      taskVisit.assignee = { id: visit.pros[0].id };
      const pro = await U.api('get', `pros/${visit.pros[0].id}`);
      if (pro) {
        taskVisit.techAvailability = {
          [pro.user.id]: {
            availTSlots: visit.pros[0].availability.map(toTaskSlot),
            name: pro.user.fullName,
          },
        };
      }
    }
    setState({ notify, visit: taskVisit });
    this.setState({ scheduleIsOpen: false });
  };

  loadDuplicates = async () => {
    const { state } = this.props;
    const jobQueries = [];
    const userQueries = [];
    if (_.get(state, 'profile.firstName') && _.get(state, 'profile.lastName')) {
      jobQueries.push({
        'customer.user.firstName': state.profile.firstName,
        'customer.user.lastName': state.profile.lastName,
      });
      userQueries.push({
        'profile.firstName': state.profile.firstName,
        'profile.lastName': state.profile.lastName,
      });
    }
    if (_.get(state, 'profile.phone')) {
      jobQueries.push({ 'customer.user.phone': state.profile.phone });
      userQueries.push({ 'profile.phone': state.profile.phone });
    }
    if (state.email) {
      jobQueries.push({ 'customer.user.email': state.email });
    }
    if (_.get(state, 'vendor.workOrderNumber')) {
      jobQueries.push({ 'metadata.workOrderNumber': state.vendor.workOrderNumber });
    }
    const r = await Promise.all([
      !jobQueries.length ? [] : U.api('post', 'tasks/search', { query: { $or: jobQueries } }),
      !userQueries.length ? [] : U.api('post', 'users/search', { query: { $or: userQueries } }),
    ]);
    this.setState({ duplicateJobs: r[0], duplicateUsers: r[1] });
  };

  renderOpenCalendar = () => {
    const { state } = this.props;
    const hasCounty = !!_.get(state, 'profile.address.county');
    const hasService = !!_.get(state, 'serviceCatalogs.0.id');
    if (!hasCounty && !hasService) {
      return <div>County & Service required</div>;
    }
    if (!hasCounty) {
      return <div>County required</div>;
    }
    if (!hasService) {
      return <div>Service required</div>;
    }
    return (
      <Button
        cypressId="availability"
        type="primary"
        onClick={() => this.setState({ scheduleIsOpen: true })}
      >
        Open Availability
      </Button>
    );
  };

  renderRegionWarning = () => {
    const { regions, services, state } = this.props;
    if (_.get(state, 'profile.address.geoCoordinates') && _.get(state, 'serviceCatalogs.0.id')) {
      const regionIds = U.region.fromAddress(state.profile.address, regions);
      const region = U.region.mostSpecific(regionIds.map(id => regions[id]));
      const bookingAllowed =
        !region?.service?.isExclude &&
        region?.service?.values.some(id =>
          U.service.isSubcategory(services[_.get(state, 'serviceCatalogs.0.id')], services[id])
        );
      if (!bookingAllowed) {
        return (
          <div className="alert alert-danger" role="alert">
            Inactive Region - {region?.name || 'No Region'}
          </div>
        );
      }
    }
    return null;
  };

  render() {
    const { getUser, services, state } = this.props;
    const { duplicateJobs, duplicateUsers, scheduleIsOpen } = this.state;
    const serviceId = _.get(state.serviceCatalogs, '0.id');
    const workOrder = {
      cx: {
        address: _.get(state, 'profile.address'),
        name: _.get(state, 'profile.fullName'),
      },
      tasks: [
        {
          customer: { org: { id: _.get(state, 'customer.org.id') } },
          serviceCatalogs: [{ id: serviceId, name: _.get(services[serviceId], 'name') }],
        },
      ],
      visits: [],
    };

    return (
      <div className="info">
        <div className="card-head">
          <div className="row">
            <h5 className="title col flex items-center">
              <div className="icon flex items-center justify-center">
                <Icon name="person" size={16} />
              </div>
              Customer Information
            </h5>
          </div>
        </div>
        <div className="card-form">
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {(!!U.length(duplicateUsers) || !!U.length(duplicateJobs)) && (
              <div className="row mb-3">
                {_.isArray(duplicateUsers) && !!U.length(duplicateUsers) && (
                  <div className="col">
                    <strong>Similar Users</strong>
                    {duplicateUsers.map(user => (
                      <div key={user.id}>
                        <a
                          href={`/users/${user.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-blue"
                        >
                          {user.profile.fullName}, {user.profile.phone}, {user.profile.email}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                {_.isArray(duplicateJobs) && !!U.length(duplicateJobs) && (
                  <div className="col">
                    <strong>Similar Jobs</strong>
                    {duplicateJobs.map(task => (
                      <div key={task.id}>
                        <a
                          href={`/tasks/${task.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-blue"
                        >
                          {task.title}, {task.customer.user.fullName},{' '}
                          {m(T.lastVisitStart(task)).format('M/D/YY h:mma')}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="row">
            <Text
              hint="first name"
              id="profile.firstName"
              className="col-sm-6"
              onBlur={this.loadDuplicates}
            />
            <Text
              hint="last name"
              id="profile.lastName"
              className="col-sm-6"
              onBlur={this.loadDuplicates}
            />
            <Text
              hint="email address"
              id="email"
              debounce={v => getUser(v)}
              onBlur={this.loadDuplicates}
              className="col-sm-6"
            />
            <Text
              hint="(   )    - "
              id="profile.phone"
              className="col-sm-6"
              onBlur={this.loadDuplicates}
            />
          </div>
          {this.renderRegionWarning()}
          <Address id="profile.address" />
          <div className="row">
            <Text id="profile.address.streetNumber" className="col-sm-2" />
            <Text
              id="profile.address.route"
              label="Street Name (only use if Address dropdown not working)"
              className="col-sm-10"
            />
          </div>
          <div className="row">
            <Text id="profile.address.locality" label="City" className="col-sm-4" />
            <Text id="profile.address.county" className="col-sm-3" />
            <Search
              id="profile.address.region"
              label="State"
              className="col-sm-3"
              options={U.states}
            />
            <Text id="profile.address.postalCode" label="Zip" className="col-sm-2" />
          </div>
          <div className="row">
            <div className="col-sm-3">{this.renderOpenCalendar()}</div>
            <Bool id="skipAvailability" className="col-sm-3" />
            <Text
              hint="(   )    - "
              id="profile.altPhone"
              className="col-sm-3"
              onBlur={this.loadDuplicates}
            />
            <Text id="profile.altEmail" className="col-sm-3" onBlur={this.loadDuplicates} />
          </div>
          <div className="row">
            <Select
              hint="referred by"
              id="profile.referredBy"
              className="col-sm-6"
              options={referredBy}
            />
            <Text
              hint="referral code"
              id="referralCode"
              className="col-sm-6"
              onBlur={this.onReferralCodeBlur}
            />
          </div>
          {state.email && !state.id && (
            <div className="btn btn-primary" onClick={this.saveUser}>
              Save User
            </div>
          )}
        </div>
        <Schedule
          isOpen={scheduleIsOpen}
          onCancel={() => this.setState({ scheduleIsOpen: false })}
          onSave={this.createTaskVisit}
          minScheduleTime={T.minScheduleTime(undefined, 1)}
          visitId={-1}
          workOrder={workOrder}
        />
      </div>
    );
  }
}

CustomerInformation.propTypes = exact({
  dispatch: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  getUser: PropTypes.func.isRequired,
  regions: PropTypes.objectOf(U.region.propType).isRequired,
  services: PropTypes.objectOf(U.service.propType),
  setState: PropTypes.func.isRequired,
  state: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    profile: U.user.profilePropType,
    serviceCatalogs: PropTypes.arrayOf(
      PropTypes.exact({ id: PropTypes.string, name: PropTypes.string })
    ),
    vendor: U.org.propType,
  }),
});

export default connect(s => {
  const { services } = s;
  return { services };
})(CustomerInformation);
