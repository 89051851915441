import { U } from '@nanaio/util';

export const activePro = {
  modifyTime: '2020-02-03T18:22:05.457Z',
  _id: '5e348c6a8f4651067f3dfde1',
  user: {
    fullName: 'Active Pro',
    phone: '3335559998',
    lastName: 'Pro',
    firstName: 'Active',
    address: {
      county: 'Alameda',
      formattedAddress: '777 Broadway, Oakland, CA 94607, USA',
      geoCoordinates: { lat: 37.800203, lng: -122.274328 },
      locality: 'Oakland',
      postalCode: '94607',
      region: 'CA',
      route: 'Broadway',
      streetNumber: '777',
    },
    id: '5e348b898e18c2063bc15b48',
    email: 'prog@repairPlatform.com',
    twilio: null,
  },
  __v: 4,
  metadata: {},
  tags: [],
  payrollType: 'contractor',
  createTime: '2020-01-31T20:22:02.080Z',
  status: 'active',
  serviceCatalogIds: ['5e333c52b6503d001ead518b'],
  serviceAreas: [
    { region: 'CA', locality: 'Oakland' },
    { region: 'CA', locality: 'Emeryville' },
    { region: 'CA', locality: 'Alameda' },
  ],
  id: '5e348c6a8f4651067f3dfde1',
};

export const activeProUser = {
  modifyTime: '2020-01-30T21:42:45.205Z',
  _id: '5e348b898e18c2063bc15b48',
  profile: {
    address: {
      county: 'Alameda',
      formattedAddress: '777 Broadway, Oakland, CA 94607, USA',
      geoCoordinates: { lat: 37.800203, lng: -122.274328 },
      locality: 'Oakland',
      postalCode: '94607',
      region: 'CA',
      route: 'Broadway',
      streetNumber: '777',
    },
    firstName: 'Active',
    lastName: 'Pro',
    phone: '3335559998',
    fullName: 'Active Pro',
  },
  email: 'prog@repairPlatform.com',
  __v: 0,
  tags: [],
  payment: {},
  status: 'active',
  createTime: '2020-01-30T20:28:03.945Z',
  auth: { hasPwd: true, socialProfiles: [] },
  roles: [U.user.Role.USER, U.user.Role.SERVICEPROVIDER],
  id: '5e348b898e18c2063bc15b48',
  org: {
    id: 'dummy',
  },
};

export const cleanupPro = {
  modifyTime: '2020-02-03T18:28:11.313Z',
  metadata: {},
  _id: '5e3866062ddc2a02d6f1f53e',
  user: {
    fullName: 'Cleanup Pro',
    phone: '1115559903',
    lastName: 'Pro',
    firstName: 'Cleanup',
    address: {
      county: 'Alameda',
      formattedAddress: '900 Fallon St, Oakland, CA 94607, USA',
      geoCoordinates: { lat: 37.7966066, lng: -122.2632418 },
      locality: 'Oakland',
      postalCode: '94607',
      region: 'CA',
      route: 'Fallon Street',
      streetNumber: '900',
    },
    id: '5e3865fe2ddc2a02d6f1f53c',
    email: 'clean@repairPlatform.com',
    twilio: null,
  },
  __v: 0,
  tags: [],
  payrollType: 'contractor',
  createTime: '2020-02-03T18:27:18.820Z',
  status: 'onHold',
  serviceCatalogIds: [],
  serviceAreas: [],
  id: '5e3866062ddc2a02d6f1f53e',
};

export const oaklandCustomer = {
  modifyTime: '2020-01-30T21:42:45.205Z',
  _id: '5e333c53b6503d001ead51d4',
  profile: {
    address: {
      streetNumber: '4145',
      route: 'Broadway Terrace',
      region: 'CA',
      postalCode: '94611',
      locality: 'Oakland',
      geoCoordinates: { lng: -122.2241132, lat: 37.8414068 },
      formattedAddress: '4145 Broadway Terrace, Oakland, CA 94611, USA',
      county: 'Alameda',
      gTimezone: 'America/Los_Angeles',
    },
    referredBy: 'Referral',
    firstName: 'User',
    lastName: 'Repair Platform',
    phone: '5553333333',
    fullName: 'User Repair Platform',
  },
  email: 'user@repairPlatform.com',
  __v: 0,
  tags: [],
  payment: {},
  status: 'active',
  createTime: '2020-01-30T20:28:03.945Z',
  auth: { hasPwd: true, socialProfiles: [] },
  roles: [U.user.Role.USER],
  id: '5e333c53b6503d001ead51d4',
  org: {
    id: 'dummy',
  },
};

export const admin = {
  _id: '5edd52c5a1a79d001249801f',
  payment: {},
  roles: [
    U.user.Role.USER,
    U.user.Role.ADMIN,
    U.user.Role.CUSTOMERSUPPORT,
    U.user.Role.DISCOUNT,
    U.user.Role.EXPORT,
    U.user.Role.PROMO,
    U.user.Role.PROPAY,
    U.user.Role.RECORDINGS,
    U.user.Role.REFUND,
    U.user.Role.SCHEDULE,
  ],
  status: 'active',
  tags: [],
  profile: {
    confirmedContacts: [],
    firstName: 'Admin',
    lastName: 'Repair Platform',
    phone: '5551111111',
    address: {
      county: 'Alameda',
      formattedAddress: '2363 Mariner Square Dr, Alameda, CA 94501, USA',
      geoCoordinates: { lat: 37.7892076, lng: -122.2772812 },
      locality: 'Alameda',
      postalCode: '94501',
      region: 'CA',
      route: 'Mariner Square Drive',
      streetNumber: '2363',
    },
    fullName: 'Admin Repair Platform',
  },
  email: 'admin@repairPlatform.com',
  auth: {
    password:
      'A0v9yCpdRdDUFe7tzYXARR3+FZgL5KHU5HnszLycnXjoEr0cUb9TCwnSX75kMAbZxFbfPKVmV0UQk6msExDRZA==',
    salt: 'ZudJcdSxnL+qmxRC56XbZQ==',
  },
  createTime: '2020-06-07T20:49:09.598Z',
  contactConfirmed: true,
  __v: 0,
};
