import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import theme from '../../theme';
import Text from '.';

const themeColors = Object.keys(theme.flatColors);

export default {
  title: 'Core/Text',
  component: Text,
  args: {
    children: 'The quick brown fox jumped over the lazy dog',
  },
  argTypes: {
    color: {
      control: { type: 'inline-radio' },
      options: themeColors,
    },
    type: {
      control: { type: 'inline-radio' },
    },
  },
} as ComponentMeta<typeof Text>;

// eslint-disable-next-line react/jsx-props-no-spreading
const Template: ComponentStory<typeof Text> = args => <Text {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Playground = Template.bind({});
Playground.args = {};

export const LongText = Template.bind({});
LongText.args = {
  children:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Velit euismod in pellentesque massa placerat. In dictum non consectetur a erat. Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing. Pharetra et ultrices neque ornare. Leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Hendrerit gravida rutrum quisque non.',
};

export const TruncatedText = Template.bind({});
TruncatedText.args = {
  children:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Velit euismod in pellentesque massa placerat. In dictum non consectetur a erat. Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing. Pharetra et ultrices neque ornare. Leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Hendrerit gravida rutrum quisque non.',
  lines: 2,
  onClick: undefined,
};
TruncatedText.decorators = [
  Story => (
    <div>
      <div className="flex w-[400px] items-center justify-center border p-4">{Story()}</div>
    </div>
  ),
];
TruncatedText.parameters = {
  // disables Chromatic's snapshotting on a story level
  // the truncated text feature really doesn't work well. perhaps when its improved we can renable snapshots
  chromatic: { disableSnapshot: true },
};

export const NestedChildren = Template.bind({});
NestedChildren.args = {
  children: <>Submit Availability</>,
};
