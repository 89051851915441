import React from 'react';
import { connect } from 'react-redux';
import { push, RouterAction } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import { Text } from '../../components';

const reports = [
  { text: 'Brands', link: 'brands' },
  { text: 'Capacity', link: 'capacity' },
  { text: 'Cities', link: 'cities' },
  { text: 'Deposits', link: 'deposits' },
  { text: 'Earnings', link: 'earnings' },
  { text: 'Flags', link: 'flags' },
  { text: 'Incentives', link: 'incentives' },
  { text: 'In-App Communications', link: 'inAppCommunications' },
  { text: 'Locations', link: 'locations' },
  { text: 'Mentorship', link: 'mentorship' },
  { text: 'Not Covered Services', link: 'notCoveredServices' },
  { text: 'Opportunities By Pro', link: 'opportunities-by-pro' },
  { text: 'Part Catalog', link: 'catalog-parts' },
  { text: 'Part Catalog Categories', link: 'catalog-part-categories' },
  { text: 'Part Vendors', link: 'part-vendors' },
  { text: 'Payments', link: 'payments' },
  { text: 'Performance', link: 'performance' },
  { text: 'Permissions', link: 'permissions' },
  { text: 'Pricing Packages', link: 'pricing-packages' },
  { text: 'Pricing Rules', link: 'pricing-rules' },
  { text: 'Pricing Regions', link: 'pricing-regions' },
  { text: 'Pricing Vendors', link: 'pricing-vendors' },
  { text: 'Pro Performance', link: 'pro-performance' },
  { text: 'Promos', link: 'promos' },
  { text: 'Regions', link: 'regions' },
  { text: 'Resources', link: 'resources' },
  { text: 'Part Status Graph', link: 'statuses' },
  { text: 'Queries', link: 'queries' },
  { text: 'Samsung Models', link: 'samsung-models' },
  { text: 'Subsidies', link: 'subsidies' },
  { text: 'Supply / Demand', link: 'demand' },
  { text: 'Tags', link: 'tags' },
  { text: 'Tools', link: 'tools' },
];

const TableOfContents = ({ dispatch }: { dispatch: (path: RouterAction) => void }) => (
  <div className="m-4 grid grid-cols-3 gap-x-4 gap-y-2 rounded-md border border-grey-medium bg-white p-4">
    {reports.map(report => (
      <Text
        color="primary"
        key={report.text}
        onClick={() => dispatch(push(`/reports/${report.link}`))}
      >
        {report.text}
      </Text>
    ))}
  </div>
);

export default connect(s => ({ roles: U.user.roles(s) }))(TableOfContents);
