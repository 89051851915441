import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { TreePick } from '../../com/ui/form';

class Skills extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { skills: this.props.pro.serviceCatalogIds };
  }

  componentWillUpdate(p, s) {
    if (!_.isEqual(this.state.skills, s.skills)) {
      const changes = [{ action: 'replace', path: 'serviceCatalogIds', value: s.skills }];
      U.api('put', `serviceproviders/${this.props.pro.id}`, changes, true);
    }
  }

  render() {
    return (
      <div className="cp-profile animated fadeInRight" style={{ width: '100%' }}>
        <h5>Edit Skills</h5>
        <TreePick id="skills" options={this.props.services} />
      </div>
    );
  }
}

export default connect(s => ({
  form: s.form,
  services: _.values(s.services),
  pro: s.pros[global.id()],
}))(Skills);
