import React, { useMemo } from 'react';
import { T, Task, U, WorkOrder } from '@nanaio/util';
import mt from 'moment-timezone';
import { Text } from '@/components';
import Parts from '../Parts';
import CoveragePolicy from './CoveragePolicy';
import NextSteps from './NextSteps';
import PostVisitSummary from './PostServiceSummary';
import RefundPolicy from './RefundPolicy';
import StatusAndReason from './StatusAndReason';
import TaskClosedAlert from './TaskClosedAlert';

export type Status = 'closed' | 'returnParts';

type Props = {
  task: Task;
  workOrder: WorkOrder;
};
export default function TaskClosed({ task, workOrder }: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);

  const status: Status = useMemo(() => {
    if (task.parts?.itemsArray?.some(part => T.partNeedsReturnStatuses.includes(part.status))) {
      return 'returnParts';
    }
    return 'closed';
  }, [task]);

  const lastVisitTime = T.lastVisit(task)?.onSiteTime;
  const lastVisitString = lastVisitTime
    ? ` as of your last visit on ${mt(lastVisitTime).tz(timezone).format('ddd, MMM Do, YYYY')}`
    : '.';

  return (
    <>
      <TaskClosedAlert status={status} task={task} />

      <div className="mt-8 text-center">
        <Text type="headline-6">Work Order Successfully Closed</Text>
        <Text className="mt-2 text-secondary-5">
          Here’s a summary of the work completed{lastVisitString}
        </Text>
      </div>

      <StatusAndReason task={task} />

      <NextSteps status={status} />

      <PostVisitSummary task={task} workOrder={workOrder} />

      {status === 'returnParts' && (
        <>
          <div className="my-10 h-[1px] bg-grey-medium" />
          <Parts task={task} />
        </>
      )}

      <div className="my-10 h-[1px] bg-grey-medium" />

      <CoveragePolicy task={task} />

      <RefundPolicy />
    </>
  );
}
