import React from 'react';
import { Shipment, Task, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Icon } from '@/components';
import { companyName } from '@/config/const';
import ScheduleRecall from './ScheduleRecall';

const title: Record<string, string> = {
  Ordered: 'Preparing Shipment',
  Accepted: 'Shipment Accepted',
  'In Transit': 'Package In-Transit',
  Delivered: 'Package Delivered',
  Exception: 'Shipment Exception',
  Unknown: 'Shipment (Status: Unknown)',
  'Delivery Attempt': 'Delivery Attempted',
};

type Props = {
  task: Task;
};

export default function Shipments({ task }: Props): JSX.Element {
  const shipments = task.parts ? _.merge({}, task.parts.shipping) : undefined;

  const getRecipientsFromShipment = (shipment: Shipment) => {
    const from = {
      name: shipment.from.name,
      generalAddress: shipment.from.address?.streetNumber
        ? `${shipment.from.address.locality}, ${shipment.from.address.region} ${shipment.from.address.postalCode}`
        : null,
      address: `${shipment.from.address?.streetNumber} ${shipment.from.address?.route},${
        shipment.from.address?.unit ? ` ${shipment.from.address?.unit},` : ``
      } ${shipment.from.address?.locality}, ${shipment.from.address?.region} ${
        shipment.from.address?.postalCode
      }`,
    };

    const shipToAddress = 'formattedAddress' in shipment.to ? shipment.to : shipment.to.address;

    const to = {
      name: 'name' in shipment.to ? shipment.to.name : '',
      type: 'type' in shipment.to ? shipment.to.type : 'other',
      generalAddress: `${shipToAddress.locality}, ${shipToAddress.region} ${shipToAddress.postalCode}`,
      address: shipToAddress.streetNumber
        ? `${shipToAddress.streetNumber} ${shipToAddress.route},${
            shipToAddress.unit ? ` ${shipToAddress.unit},` : ``
          } ${shipToAddress.locality}, ${shipToAddress.region} ${shipToAddress.postalCode}`
        : `${
            shipment.shippingAddress?.formattedAddress
              ? shipment.shippingAddress.formattedAddress
              : ''
          }`,
    };
    return { from, to };
  };

  const timezone = U.timezone(task.serviceAddress);

  const userShipments = _.values(shipments).filter(
    part =>
      ('type' in part.to && part.to.type === 'customer') ||
      ('type' in part.to && part.to.type === 'user') ||
      part.shippingAddress?.formattedAddress === task.serviceAddress.formattedAddress ||
      ('formattedAddress' in part.to &&
        part.to.formattedAddress === task.serviceAddress.formattedAddress) ||
      ('type' in part.to && part.to.type === 'other')
  );

  return (
    <div className="card-box">
      <div className="card-box--header">Shipments ({_.keys(userShipments).length})</div>

      <div className="card-box--content no-padding">
        {_.map(userShipments, (shipment, id) => {
          const { from, to } = getRecipientsFromShipment(shipment);
          const trackingId = shipment.track?.id;
          const trackingUrl = shipment.track?.url;
          const status = shipment.status;
          const createTime = shipment.createTime;
          const carrier = shipment.carrier;
          const eta = shipment.receiveTime;
          return (
            <div className="card-part shipment-card" style={{ padding: 0 }} key={id}>
              <div className="flex-1">
                <div
                  className="d-flex align-items-center px-lg-3 px-md-3 py-2"
                  style={{ background: '#f2f2f2' }}
                >
                  <small className="text-muted weight-600">
                    {_.startCase(carrier)}
                    {status && ` Status: ${_.startCase(status)}`} {trackingId && ` (${trackingId})`}
                  </small>
                </div>
                <div className="border-bottom d-block d-lg-flex d-md-flex justify-content-center align-items-center px-3 py-3">
                  <div className="flex-1 pl-2 pr-2">
                    <h6 className="weight-600">
                      {title[_.startCase(status)]}{' '}
                      <span className="text-muted">
                        ({shipment.parts.length}/{_.values(_.get(task, 'parts.items')).length}{' '}
                        items)
                      </span>
                    </h6>
                    <small>
                      <span className="text-muted">Shipment Contents:</span>{' '}
                      <span className="weight-600 text-blue">
                        {shipment.parts
                          .map(id => task.parts?.items?.[id]?.desc)
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    </small>
                  </div>

                  <div className="d-inline-flex">
                    <ScheduleRecall
                      button={
                        <button className="secondary mt-lg-0 mt-md-0 mr-2 mt-2">
                          Mark as Received
                        </button>
                      }
                    />
                    {trackingUrl && (
                      <a
                        className="mt-lg-0 mt-md-0 mt-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={trackingUrl}
                      >
                        <button className="primary">Track</button>
                      </a>
                    )}
                  </div>
                </div>
                <div className="px-lg-3 px-md-3 py-2">
                  <ul className="shipment-timeline">
                    <li className="shipment-timeline--item">
                      <div className="timeline--counter from">A</div>
                      <div className="timeline--text flex-1">
                        <p className="text-bold weight-600">
                          {from.name === companyName ? `${companyName} Home Services` : from.name}
                        </p>
                        <small className="flex items-center" style={{ color: '#252525' }}>
                          <Icon className="text-muted mr-2" name="place" size={16} />
                          {from.generalAddress ? from.generalAddress : from.name}{' '}
                          <Icon className="text-muted ml-3 mr-2" name="calendar_blank" size={16} />{' '}
                          {mt(createTime).tz(timezone).format('ddd, MMM DD, YYYY')}{' '}
                        </small>
                      </div>
                    </li>
                    <li className="shipment-timeline--item">
                      <div className="timeline--counter to">B</div>
                      <div className="timeline--text flex-1">
                        <p className="text-bold weight-600">
                          {to.name === 'Other' ? `Your ${companyName} Technician` : to.name}
                        </p>
                        <small className="flex items-center" style={{ color: '#252525' }}>
                          <Icon className="text-muted mr-2" name="place" size={16} />
                          {to.generalAddress}{' '}
                          <Icon className="text-muted ml-3 mr-2" name="calendar_blank" size={16} />{' '}
                          {mt(eta).tz(timezone).format('ddd, MMM DD, YYYY')}{' '}
                        </small>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
