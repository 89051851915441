import '../../style/page/part.css';

import React from 'react';
import { connect } from 'react-redux';
import Scrollchor from 'react-scrollchor';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Avatar, Button, Text } from '@/components';
import { apiOrigin } from '../../config/const';
import { openLink } from '../../utils';
import AutoOrderParts from './autoOrderParts';
import EditPart from './EditPart';
import EditShipment from './editShipment';
import EditStatus from './EditStatus';
import PartCard from './PartCard';
import PurchaseOrderNumbers from './PurchaseOrderNumbers';
import ResendCommunications from './ResendCommunications';
import ReturnPart from './returnPart';
import ShipmentLabel from './shipmentLabel';

export const color = {
  bg: '#f7fafc',
  bold: '#2E3B48',
  muted: '#9FA4A8',
  border: '#e3e8ee',
  blue: '#1A6BC6',
  green: '#40D78F',
  orange: '#FD9926',
  red: '#BC523B',
  darkGreen: '#24b47e',
  purple: '#8E69EC',
  lightblue: '#0DB1ED',
  pink: '#E84F89',
};

class Parts extends React.Component {
  static propTypes = {
    canEdit: PropTypes.exact({ canEdit: PropTypes.bool, reason: PropTypes.string }),
    job: PropTypes.object,
    shipments: PropTypes.object,
    partOrders: PropTypes.array,
    partNames: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    taskId: PropTypes.string,
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  partNumbers = [];

  constructor(p) {
    super(p);
    this.state = { checked: {} };
  }

  componentWillMount() {
    this.partNumbers = this.getPartNumbers();
    if (this.partNumbers.length) {
      const query = { 'owner.id': 'nana', partNumber: { $in: this.partNumbers } };
      U.api('post', 'inventory/search', { query }, true);
    }
  }

  addPart = () => {
    this.setState({ addPart: true });
  };

  closeEditPart = () => {
    this.setState({ addPart: false, activePart: false });
  };

  openAutoOrderPartsModal = () => {
    this.setState({ showAutoOrderPartsModal: true });
  };

  closeAutoOrderPartsModal = () => {
    this.setState({ showAutoOrderPartsModal: false });
  };

  getPartNumbers() {
    return _.values(_.get(this.props.job, 'parts.items')).map(p =>
      (p.partNumber || '').toUpperCase()
    );
  }

  editPart = partId => {
    this.setState({ activePart: partId });
  };

  returnPart = partId => {
    this.setState({ returnPartId: partId });
  };

  editShipment = shipmentId => {
    this.setState({ shipmentId, isTaskUpdatedByShipmentLabel: false });
  };

  printLabel = shipmentId => {
    if (shipmentId) {
      this.setState({ shipmentId, shipmentLabelId: shipmentId });
    } else {
      this.setState({ shipmentLabelId: false });
    }
  };

  toggleCheck = id => {
    this.setState(s => _.set(s, `checked.${id}`, !this.state.checked[id]));
  };

  openEditStatus = () => {
    this.setState({ editStatus: true });
  };

  /**
   * returns a list of unique part order statuses ordered by displayOrder - for the parts tables
   *
   * @param {Array} itemsArray
   */
  getUniqueStatuses = itemsArray => {
    return _.sortBy(_.uniq(_.map(itemsArray, item => item.status)), elem => {
      return _.get(this.props.partNames[elem], 'displayOrder', Infinity);
    });
  };

  renderFile = id => {
    if (!_.get(this.props.job, `metadata.taskProblemQuestions.pictures.${id}.url`)) {
      return null;
    }
    const url = `${apiOrigin}/images${_.get(
      this.props.job,
      `metadata.taskProblemQuestions.pictures.${id}.url`
    )}`;
    if (U.fileType(url) === 'video') {
      return <video width="50" height="50" controls src={url} />;
    }
    return (
      <a href={url} rel="noopener noreferrer" target="_blank" className="mr-2" key={id}>
        <img alt={`upload ${id}`} src={url} style={{ width: '50px' }} />
      </a>
    );
  };

  getPartReturnReferenceNumber = part => {
    const parts = U.timeSort(T.parts(this.props.job));
    const index = _.findIndex(parts, p => p._id === part._id);
    return `PR-${this.props.job.shortId}-${index + 1}`;
  };

  searsModelSearchRedirect = () => {
    const model = T.model(this.props.job);
    openLink({
      newTab: true,
      url:
        'https://www.searspartsdirect.com/search' +
        (model ? `?q=${encodeURIComponent(model)}` : ''),
    });
  };

  render() {
    const parts = _.get(this.props.job, 'parts.itemsArray', []);
    const uniqueStatuses = this.getUniqueStatuses(parts);
    const partCount = _.keys(_.get(this.props.job, 'parts.items')).length;
    const activeTab = this.state.tab;
    const returnStatuses = T.partReturnStatuses;
    const numberOfPartsChecked = _.countBy(this.state.checked).true;
    const checkedPartIds = _.keys(this.state.checked).filter(key => this.state.checked[key]);
    // Edit status button should only be shown if all checked parts are the same status
    const checkedParts = checkedPartIds.map(partId => this.props.job.parts.items[partId]);
    const partStatus = checkedParts[0]?.status;

    const isEditStatusShown = partStatus && checkedParts.every(part => part.status === partStatus);

    return (
      <main id="PartCrm">
        <section>
          {/* =========== HEADER COMPONENT =========== */}
          <div className="bg-white">
            {/* Title */}
            <div className="flex justify-between p-4">
              <Text className="flex-1 place-self-center" type="headline-6">
                All Parts ({partCount})
              </Text>

              {isEditStatusShown && (
                <Button className="mr-2" onClick={this.openEditStatus} variant="secondary">
                  Edit Status ({numberOfPartsChecked})
                </Button>
              )}

              <Button variant="outline" onClick={this.viewDiagram}>
                View Diagram
              </Button>

              {this.props.canEdit.canEdit && (
                <Button className="ml-4" onClick={this.addPart}>
                  Add Part
                </Button>
              )}
            </div>
            {/* Tabs */}
            <div className="flex items-center border-t border-grey-medium px-4">
              {uniqueStatuses.map((status, n) => {
                const title = _.get(this.props.partNames[status], 'name');
                const count = _.filter(parts, ['status', status]).length;
                return (
                  <Scrollchor
                    key={n}
                    to={title}
                    afterAnimate={() => this.setState({ tab: title })}
                    className={`mr-8 inline-flex cursor-pointer items-center py-4 transition-all  ease-in-out hover:opacity-50 ${
                      title === activeTab
                        ? `border-b-2 border-secondary`
                        : !activeTab && n === 0
                          ? `border-b-2 border-secondary`
                          : ``
                    }`}
                  >
                    <Text type="page-title">{title}</Text>
                    <div
                      className="badge ml-2"
                      style={{
                        backgroundColor: returnStatuses.includes(status) ? color.red : '#3e3e3e',
                      }}
                    >
                      {count}
                    </div>
                  </Scrollchor>
                );
              })}
            </div>
          </div>

          {/* =========== PARTS COMPONENT =========== */}
          <div className="bg-background-medium">
            {uniqueStatuses &&
              _.map(uniqueStatuses, status => {
                const title = _.get(this.props.partNames[status], 'name');
                const count = _.filter(parts, ['status', status]).length;
                return (
                  <div id={title} key={status}>
                    <div className="flex items-center justify-between p-4">
                      <Text type="page-title">{title}</Text>
                      <div
                        className="badge ml-2"
                        style={{
                          backgroundColor: returnStatuses.includes(status) ? color.red : '#3e3e3e',
                        }}
                      >
                        {count}
                      </div>
                      <div className="flex-1" />
                      {status === T.PartStatus.READY_FOR_AUTO_ORDERING &&
                        this.props.job.status !== T.Status.WAITING_ON_APPROVAL &&
                        !T.hasFlag(this.props.job, T.Flags.PARTS_NEED_MT_APPROVAL) && (
                          <Button onClick={this.openAutoOrderPartsModal}>Auto Order Parts</Button>
                        )}
                    </div>
                    {_.map(_.filter(parts, ['status', status]), part => (
                      <PartCard
                        isSelected={this.state.checked[part._id]}
                        key={part._id}
                        onEdit={() => this.editPart(part._id)}
                        onReturn={() => this.returnPart(part._id)}
                        onSelect={() => this.toggleCheck(part._id)}
                        part={part}
                        task={this.props.job}
                      />
                    ))}
                  </div>
                );
              })}

            {_.isEmpty(uniqueStatuses) && (
              <div className="flex flex-row bg-white p-4">
                <Avatar
                  className="bg-secondary bg-opacity-5"
                  iconProps={{
                    className: 'text-secondary text-opacity-25',
                    name: 'barcode',
                  }}
                  size="large"
                  variant="circle"
                />
                <div className="flex flex-col">
                  <Text className="text-secondary text-opacity-75" type="button">
                    No Parts Added
                  </Text>
                  <Text className="mt-2 text-secondary text-opacity-50">
                    No parts have been added to this job yet.
                  </Text>
                </div>
              </div>
            )}
          </div>

          {/* =========== SHIPMENTS COMPONENT =========== */}
          <div className="mt-2 bg-white p-4">
            <div className="mb-6 flex justify-between">
              <Text className="flex-1 place-self-center" type="subtitle-2">
                Shipments ({_.keys(this.props.shipments).length})
              </Text>
              {this.props.canEdit.canEdit && (
                <Button onClick={() => this.editShipment('add')}>Add Shipping</Button>
              )}
            </div>

            {_.keys(this.props.shipments).length > 0 ? (
              <table className="border-separate border-spacing-y-4" width="100%">
                <thead>
                  <tr>
                    <th>
                      <Text color="grey.dark">ETA</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Parts</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Sender</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Recipient</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Tracking</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Total</Text>
                    </th>
                    <th>
                      <Text color="grey.dark">Last tracking</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(this.props.shipments, (shipment, id) => {
                    let shipmentEta = '';
                    if (shipment.track?.expectedDelivery) {
                      shipmentEta = m(shipment.track.expectedDelivery).format('ddd, M/D/YY');
                    } else if (shipment.receiveTime) {
                      shipmentEta = m(shipment.receiveTime).format('ddd, M/D/YY');
                    }

                    const shipmentFromName = shipment.from?.name || 'Unknown';
                    let shipmentToName = 'Unknown';
                    if (shipment.to?.name) {
                      shipmentToName = shipment.to.name;
                    } else if (shipment.shippingAddress?.locality) {
                      shipmentToName = `${shipment.shippingAddress.locality}, ${shipment.shippingAddress.region}`;
                    }

                    const shipmentIsRegisteredBool = !!shipment.isRegisteredWithTrackingService;
                    const shipmentLastTrackingUpdateColor = shipmentIsRegisteredBool
                      ? 'success'
                      : 'danger';
                    const shipmentLastTrackingUpdateTimeMsg = shipment.lastTrackingUpdateTime
                      ? m(shipment.lastTrackingUpdateTime).format('ddd, M/D/YY')
                      : 'Not tracked!';

                    const shipmentCarrier = shipment.carrier || 'other';

                    const displayShipmentCarrier =
                      T.carrierCodes.find(cc => cc.id === shipmentCarrier)?.name || 'Other';
                    return (
                      <tr key={id}>
                        <td>
                          <Text>{shipmentEta}</Text>
                        </td>
                        <td>
                          <ul>
                            {_.map(
                              T.shipmentTitleArray({ ...this.props, shipment }),
                              (e, index) => (
                                <li key={index}>
                                  <Text>{e}</Text>
                                </li>
                              )
                            )}
                          </ul>
                        </td>
                        <td>
                          <Text>{shipmentFromName}</Text>
                        </td>
                        <td>
                          <Text>{shipmentToName}</Text>
                        </td>
                        <td>
                          <Text>
                            {displayShipmentCarrier} -{' '}
                            {shipment.track?.id ? (
                              shipment.track.url ? (
                                <a
                                  href={shipment.track.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="link-blue"
                                >
                                  {shipment.track.id}
                                </a>
                              ) : (
                                shipment.track.id
                              )
                            ) : (
                              `None`
                            )}
                          </Text>
                        </td>
                        <td>
                          <Text>{U.toMoney(shipment.price?.total)}</Text>
                        </td>
                        <td>
                          <Text color={shipmentLastTrackingUpdateColor}>
                            {shipmentLastTrackingUpdateTimeMsg}
                          </Text>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-row">
                <Avatar
                  className="bg-secondary bg-opacity-5"
                  iconProps={{
                    className: 'text-secondary text-opacity-25',
                    name: 'truck_fast_outline',
                  }}
                  size="large"
                  variant="circle"
                />
                <div className="flex flex-col">
                  <Text className="text-secondary text-opacity-75" type="button">
                    No Shipments Added
                  </Text>
                  <Text className="mt-2 text-secondary text-opacity-50">
                    No shipments have been added to this job yet.
                  </Text>
                </div>
              </div>
            )}
          </div>

          {/* =========== ORDERS COMPONENT =========== */}
          <div className="mt-2 bg-white p-4">
            <div className="mb-6 flex justify-between">
              <Text className="flex-1 place-self-center" type="subtitle-2">
                Auto Orders ({this.props.partOrders.length})
              </Text>
            </div>

            {this.props.partOrders.length > 0 ? (
              <table className="border-separate border-spacing-y-4" width="100%">
                <thead>
                  <tr>
                    <th className="pr-2">
                      <Text color="grey.dark">Order #</Text>
                    </th>
                    <th className="pr-2">
                      <Text color="grey.dark">Vendor</Text>
                    </th>
                    <th className="pr-2">
                      <Text color="grey.dark">Date</Text>
                    </th>
                    <th className="pr-2">
                      <Text color="grey.dark">Status</Text>
                    </th>
                    <th className="pr-2">
                      <Text color="grey.dark">Message</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.partOrders.map(partOrder => (
                    <tr key={partOrder._id}>
                      <td className="pr-2">
                        <Text>{partOrder.orderNumber}</Text>
                      </td>
                      <td className="pr-2">
                        <Text>{partOrder.vendor}</Text>
                      </td>
                      <td className="pr-2">
                        <Text>{m(partOrder.createTime).format('M/D/YY')}</Text>
                      </td>
                      <td className="pr-2">
                        <Text>{partOrder.status}</Text>
                      </td>
                      <td className="pr-2">
                        <Text>{partOrder.errorMessage || '-'}</Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-row">
                <Avatar
                  className="bg-secondary bg-opacity-5"
                  iconProps={{
                    className: 'text-secondary text-opacity-25',
                    name: 'note_outline',
                  }}
                  size="large"
                  variant="circle"
                />
                <div className="flex flex-col">
                  <Text className="text-secondary text-opacity-75" type="button">
                    No Auto Orders
                  </Text>
                  <Text className="mt-2 text-secondary text-opacity-50">
                    No orders have been auto-ordered yet
                  </Text>
                </div>
              </div>
            )}
          </div>

          {/* =========== BOTTOM BUTTONS =========== */}
          <div className="p-4">
            <PurchaseOrderNumbers taskId={this.props.job.id} />
            <ResendCommunications partIds={_.keys(this.state.checked)} taskId={this.props.job.id} />
          </div>

          {/* =========== MODALS =========== */}
          {(this.state.addPart || this.state.activePart) && (
            <EditPart onClose={this.closeEditPart} partId={this.state.activePart} />
          )}
          <ReturnPart id={this.state.returnPartId} onClose={() => this.returnPart(false)} />
          <AutoOrderParts
            isOpen={this.state.showAutoOrderPartsModal}
            onClose={this.closeAutoOrderPartsModal}
          />
          <EditShipment
            isTaskUpdatedByShipmentLabel={this.state.isTaskUpdatedByShipmentLabel}
            id={this.state.shipmentId}
            onClose={() => this.editShipment(false)}
            printLabel={this.printLabel}
          />
          <ShipmentLabel
            id={this.state.shipmentLabelId}
            onClose={() => this.printLabel(false)}
            onClick={() => this.printLabel(false)}
            onSuccess={isTaskUpdated =>
              this.setState({ isTaskUpdatedByShipmentLabel: isTaskUpdated })
            }
          />
          {this.state.editStatus && (
            <EditStatus
              onClose={() => this.setState({ editStatus: false })}
              parts={checkedParts}
              status={partStatus}
              task={this.props.job}
            />
          )}
        </section>
      </main>
    );
  }
}

export default connect((s, p) => {
  const taskId = p.taskId || global.id();
  const job = s.tasks[taskId];
  const workOrder = s.workorders[job.metadata.workOrderId];
  const org = s.orgs[T.orgId(job)];
  const { me, services } = s;
  const user = s.users[me.userId];

  const isSupport = U.user.roles(s).customersupport;
  const canEdit = T.billCanEdit({
    isSupport,
    org,
    services,
    task: job,
    type: job.invoice ? 'invoice' : 'estimate',
    user,
    workOrder,
  });
  const shipments = _.merge({}, _.get(job, 'parts.shipping'));
  const partOrders = _.cloneDeep(job.parts?.ordersArray || []);
  const partNames = U.toMap(T.partStatusOptions);
  const purchaseOrderNumbers = [];
  _.map(_.get(job, 'metadata.purchaseOrderNumber'), (value, number) => {
    if (value) {
      purchaseOrderNumbers.push(number);
    }
  });
  return {
    canEdit,
    job,
    workOrder,
    shipments,
    partOrders,
    partNames,
    purchaseOrderNumbers,
  };
})(Parts);
