import React, { useState } from 'react';
import { File, Part, Task, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Modal, Text } from '@/components';
import { apiOrigin } from '../../config/const';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  part: Part;
  task: Task;
};

export default function PartPhotos({ isOpen, onClose, part, task }: Props): JSX.Element {
  const [active, setActive] = useState<number>();

  const handleToggleActive = (i: number) => {
    if (active === i) {
      setActive(undefined);
    } else {
      setActive(i);
    }
  };
  const files = _.groupBy(
    _.sortBy(
      _.map(_.get(task, 'metadata.taskProblemQuestions.pictures'), (p: File, id: string) => {
        return { ...p, id, url: `${apiOrigin}/images${p.url}`, type: U.fileType(p.url) };
      }),
      'time'
    ),
    'type'
  );
  const photos = _.get(files, 'image', []).filter(photo => part.images.includes(photo.checksum));
  return (
    <Modal onClose={onClose} isOpen={isOpen} width={850}>
      <Modal.Header title={`${part.partNumber || 'Unknown #'} - ${part.desc || ''}`} />
      <Modal.Body>
        <div className="p-4">
          <Text className="mb-4">Left-click to zoom, right-click to download</Text>
          {photos && (
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              {photos.map((photo, i) => {
                if (active !== undefined && active !== i) {
                  return <></>;
                }
                return (
                  <div key={i} className="pointer" onClick={() => handleToggleActive(i)}>
                    <Text>
                      <b>Tag:</b> {_.startCase(photo.tag)}
                    </Text>
                    <img
                      alt={photo.tag}
                      src={photo.url}
                      style={
                        i === active
                          ? { width: '820px', maxWidth: 'none', maxHeight: 'none' }
                          : { maxWidth: '400px', maxHeight: '400px' }
                      }
                    />
                    <div className="flex justify-between pt-1">
                      <span>{m(photo.time).format('M/D/YY h:mma')}</span>
                      {photo.location && (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link-blue"
                          href={`https://www.google.com/maps/search/?q=${photo.location.latitude},${photo.location.longitude}`}
                        >
                          Open In Maps
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
