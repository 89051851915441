import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Button, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import Link from '../../../com/ui/link';
import ReferCard from './ReferCard';
import Sidebar from './Sidebar';
import TaskCard from './TaskCard';
import VendorRebookMessage from './VendorRebookMessage';

export default function UserTasks(): JSX.Element {
  const tasksRaw = useLegacySelector(state => state.tasks);
  const tasks = _.values(tasksRaw);
  const hasOpenJob = !!tasks.find(
    t => ![T.Status.COMPLETED, T.Status.CANCELLED].includes(t.status)
  );
  const openOrgJobs = tasks.filter(
    t => T.hasOrg(t) && ![T.Status.COMPLETED, T.Status.CANCELLED].includes(t.status)
  );
  const hasOpenOrgJobs = !_.isEmpty(openOrgJobs);

  return (
    <section>
      <div className="mx-auto flex max-w-1200 flex-col md:flex-row">
        <div className="w-full px-4 py-3 md:w-2/3 md:py-5">
          {/* ================= TITLE ==================== */}
          <div className="mb-4 flex items-center justify-between">
            <div className="flex-1 text-center">
              <Text type="headline-5">Your Bookings</Text>
            </div>
            {!hasOpenOrgJobs && (
              <Link to="/book" name="return-cx-book">
                <Button size="medium" variant={hasOpenJob ? 'outline' : 'primary'}>
                  Book a new service
                </Button>
              </Link>
            )}
          </div>

          {/* ================= TASK CARDS ==================== */}
          {tasks.map(task => (
            <TaskCard key={task.id} task={task} />
          ))}

          <div style={{ height: 40 }} />
          <VendorRebookMessage />

          {/* ================= REFER CARD ==================== */}
          <ReferCard />
        </div>

        {/* ================ SIDEBAR ================ */}
        <div className="w-full md:w-1/3">
          <Sidebar />
        </div>
      </div>
      <div style={{ height: 200 }} />
    </section>
  );
}
