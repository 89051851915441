import React, { useState } from 'react';
import U from '@nanaio/util';
import downloadFile from 'js-file-download';
import { cloneDeep } from 'lodash';
import { companyName } from '@/config/const';
import { Modal, Number,Text } from '../../com/ui/form';
import { parseCSVFile } from '../../utils/csv';

const COLUMNS = [
  { text: 'Part Number*', key: 'partNumber', Component: Text },
  { text: 'Quantity*', key: 'qty', Component: Number },
  { text: 'Rack', key: 'unit', Component: Text },
  { text: 'Row', key: 'shelf', Component: Text },
  { text: 'Bin', key: 'bin', Component: Text },
  { text: 'Box Number', key: 'boxNum', Component: Text },
];

const getRowError = (errors, index) => {
  let result = [];

  if (errors) {
    const rowError = errors.find(e => e.row === index);

    if (rowError) {
      result = rowError.path;
    }
  }

  return result;
};

export default function InventoryOverride() {
  const [csvData, setCSVData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState(null);
  const [success, setSuccess] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleFileChange = async event => {
    try {
      setCSVData(await parseCSVFile(event.target.files[0], COLUMNS));
      setErrorMessage('');
      setValidationErrors(null);
    } catch (e) {
      setErrorMessage(e);
    }
  };

  const handleInputChange = (rowIndex, cellIndex, value) => {
    const newCSVData = cloneDeep(csvData);

    newCSVData[rowIndex][cellIndex] = typeof value === 'undefined' ? '' : value.toString();

    setCSVData(newCSVData);
  };

  const handleSubmit = async () => {
    setSuccess(false);

    const response = await U.api('post', '/inventory/override', csvData);

    setValidationErrors(response.errors);
    setModalIsOpen(false);

    if (!response.errors) {
      setSuccess(true);
    }
  };

  const downloadCSV = async (url, name) => {
    const response = await U.api('get', url);

    if (Array.isArray(response)) {
      downloadFile(
        new Blob(response, { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' }),
        name,
        'text/csv'
      );
      setErrorMessage('');
    } else if (response.errMsg) {
      setErrorMessage(response.errMsg);
    } else {
      setErrorMessage('Something went wrong, please try again.');
    }
  };

  const handleDownloadClick = () => {
    downloadCSV('inventory/csv-import-template', 'csv-import-template.csv');
  };

  const handleBackupClick = async () => {
    downloadCSV('inventory/csv-backup', 'csv-backup.csv');
  };

  return (
    <div className="p-3" style={{ minHeight: '100vh' }}>
      <div className="d-flex">
        <div className="form-group row w-100 align-items-end">
          <div className="col-md-4">
            <label htmlFor="csv">Import CSV</label>
            <input
              id="csv"
              accept=".csv"
              className="form-control"
              type="file"
              onChange={handleFileChange}
            />
          </div>

          {csvData.length ? (
            <OverrideInventoryControls
              isOpen={modalIsOpen}
              onCancel={() => setModalIsOpen(false)}
              onConfirm={handleSubmit}
              onOverrideClick={() => setModalIsOpen(true)}
              onBackupClick={handleBackupClick}
            />
          ) : null}

          <div className="col-md-4 ml-auto text-right" onClick={handleDownloadClick}>
            <div className="btn btn-primary">Download Template</div>
          </div>
        </div>
      </div>

      {success ? <div className="alert alert-success">Inventory overwritten.</div> : null}
      {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}

      {csvData.length ? (
        <>
          <div className="mt-5 pt-3" style={{ borderTop: '1px solid black' }} />

          <table
            className="table-sm table-striped table-hover table"
            style={{ tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                {COLUMNS.map(({ text, key }) => (
                  <th key={key}>{text}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {csvData.map((row, rowIndex) => {
                const rowError = getRowError(validationErrors, rowIndex);

                return (
                  <tr key={rowIndex}>
                    {Array.from(row, (cell, cellIndex) => {
                      const { Component, key } = COLUMNS[cellIndex];
                      const invalidCell = rowError.includes(key);
                      const props = {
                        className: invalidCell ? 'is-invalid' : '',
                        id: `${rowIndex}-${cellIndex}`,
                        label: '',
                        onChange: value => handleInputChange(rowIndex, cellIndex, value),
                      };

                      if (key === 'qty') {
                        props.value = cell - 0;
                      } else {
                        props.def = cell;
                      }

                      return (
                        <td key={`${rowIndex}-${cellIndex}`}>
                          <Component {...props} />

                          {invalidCell ? (
                            <div className="invalid-feedback">This field is invalid</div>
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}

function OverrideInventoryControls({
  isOpen,
  onConfirm,
  onCancel,
  onOverrideClick,
  onBackupClick,
}) {
  return (
    <>
      <div className="col-md-4">
        <div className="btn btn-primary mr-3" onClick={onOverrideClick}>
          Override inventory
        </div>
      </div>

      <Modal
        title="Warning"
        isOpen={isOpen}
        submit={onConfirm}
        onClose={onCancel}
        className="ui-modal"
        cancelText="No"
        saveText="Yes"
      >
        <p className="text-center">
          Are you sure you want to continue and override the current inventory? This action cannot
          be undone, and it will only affect inventory records owned by {companyName}. Click{' '}
          <button
            type="button"
            onClick={onBackupClick}
            className="btn btn-link btn-no-capitalize link-blue m-0 p-0"
          >
            here
          </button>{' '}
          to download a backup of your inventory that you can later import if something goes wrong.
        </p>
      </Modal>
    </>
  );
}
