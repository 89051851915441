import '../../style/config/user';

import PartsConfirmation from '@/pages/confirmation/partsReceived';
import RescheduleConfirmation from '@/pages/confirmation/reschedule';
import PageUserProfile from '@/pages/user/profile';
import ComUiUserLayout from '../../com/ui/CxAndProLayout';
import TaskLayout from '../../task/layout';
import TaskList from '../../task/list';
import Sign from '../../task/user/sign';
import publicRoutes from './public';

const userRoutes = {
  '': { body: TaskList, layout: ComUiUserLayout },
  'parts-confirmation': { body: PartsConfirmation },
  profile: { body: PageUserProfile, layout: ComUiUserLayout },
  'reschedule-confirmation': { body: RescheduleConfirmation },
  tasks: { body: TaskList, layout: ComUiUserLayout },
  'tasks/id': { body: TaskLayout, layout: ComUiUserLayout },
  'tasks/id/sign': { body: Sign, layout: ComUiUserLayout },
};

const routes = Object.assign(publicRoutes, userRoutes);

export default routes;
