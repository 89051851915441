import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { copyText } from '../../utils';
import Icon from './Icon';

export default function Copyable({ children, text }) {
  const [showCopied, setShowCopied] = useState(false);

  if (!text) {
    return children;
  }

  if (showCopied) {
    return <Icon color="success" name="check" />;
  }

  const handleClick = () => {
    copyText(text);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 3000);
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {children}
    </div>
  );
}

Copyable.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
};
