import { Brand } from '@nanaio/util';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import _, { Dictionary } from 'lodash';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchBrands = async (): Promise<Dictionary<Brand>> => {
  const { data } = await AXIOS_INSTANCE.get<Brand[]>('/api/brands');
  const brands = _.keyBy(data, 'name');
  return brands;
};

export const useGetBrands = (): UseQueryResult<Dictionary<Brand>> => {
  return useQuery({
    queryKey: ['brands'],
    queryFn: () => fetchBrands(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
