import React from 'react';
import { connect } from 'react-redux';
import { Fab } from '@material-ui/core';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { companyName } from '@/config/const';
import { Bool, Modal, Money, Select, Text, Textbox } from '../../com/ui/form';
import Ledger from './ledger';

class ProPay extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { flags: [], form: {} };
    this.compute = this.compute.bind(this);
    this.getMessage = this.getMessage.bind(this);
    this.loadTransactions = this.loadTransactions.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.openCredit = this.openCredit.bind(this);
    this.openDebit = this.openDebit.bind(this);
    this.submit = this.submit.bind(this);
    this.togglePayFlag = this.togglePayFlag.bind(this);
    U.api('get', `workorders/${p.workOrder.id}/payflags`).then(flags => this.setState({ flags }));
    this.loadTransactions();
  }

  compute() {
    U.api('put', `workorders/${this.props.workOrder.id}/propay/refresh`, undefined, true);
  }

  getMessage(amount) {
    const name = T.userName(this.props.job);
    return `A debit of ${U.toMoney(amount)} has been made from the ${name} job.`;
  }

  async loadTransactions() {
    const query = {
      'reference.id': [this.props.workOrder.id, ..._.map(this.props.workOrder.tasks, 'id')],
      tranType: { $in: ['credit', 'debit'] },
    };
    const transactions = await U.api('post', 'payments/search', { query });
    const query2 = { _id: { $in: _.uniq(_.map(transactions, 'byUserId')) } };
    const projection = { 'profile.fullName': 1 };
    const users = await U.api('post', 'users/search', { query: query2, projection });
    for (const transaction of transactions) {
      transaction.byUserName = _.get(
        users.find(u => u.id === transaction.byUserId),
        'profile.fullName'
      );
    }
    const query3 = {
      $or: [
        { _id: { $in: _.uniq(_.map(transactions, 'forUserId')) } },
        { 'user.id': { $in: _.uniq(_.map(transactions, 'forUserId')) } },
      ],
    };
    const projection2 = { user: 1 };
    const pros = await U.api('post', 'pros/search', {
      query: query3,
      projection: projection2,
    });
    for (const transaction of transactions) {
      transaction.forUserName = pros.find(p =>
        [p.id, p.user.id].includes(transaction.forUserId)
      ).user.fullName;
    }
    this.setState({ transactions });
  }

  onAmountChange(amount) {
    if (this.state.form.isDebit) {
      this.setState({ message: this.getMessage(amount) });
    }
  }

  openCredit(proId) {
    const propay = _.find(this.props.workOrder.invoice.proPay.pros, { proId });
    const modal = true;
    const isDebit = false;
    const max = propay.balance;
    const amount = max;
    const userId = proId;
    const methodOfPayment = 'bankAccount';
    this.setState({ modal, form: { isDebit, max, userId, amount, methodOfPayment } });
  }

  async openDebit(proId) {
    const proPay = this.props.workOrder.invoice.proPay.pros.find(v => v.proId === proId);
    const modal = true;
    const due = proPay.total - proPay.totalPaid + proPay.totalRefund;
    const max = -due < 0 ? proPay.totalPaid - proPay.totalRefund : -due;
    const isDebit = true;
    const amount = max;
    const message = this.getMessage(amount);
    const sendMessage = true;
    const userId = proId;
    const methodOfPayment = 'bankAccount';
    this.setState({
      modal,
      message,
      sendMessage,
      form: { isDebit, max, userId, amount, methodOfPayment },
    });
  }

  async submit() {
    if (!this.state.form.methodOfPayment) {
      return this.setState({ error: 'Method of payment required' });
    }
    if (!this.state.form.amount) {
      return this.setState({ error: 'Amount required' });
    }
    const requireReason = this.state.form.amount !== this.state.form.max;
    if (requireReason && !this.state.form.reason) {
      return this.setState({ error: 'Reason required' });
    }

    const action = this.state.form.isDebit ? 'debit' : 'credit';
    const body = _.pick(this.state.form, ['amount', 'methodOfPayment', 'reason', 'checkNumber']);
    body.metadata = _.pick(this.state.form, ['checkNumber', 'methodOfPayment', 'reason']);
    const r = await U.api(
      'put',
      `workorders/${this.props.workOrder.id}/propay/${this.state.form.userId}/${action}`,
      body,
      true
    );
    if (!r || r.errMsg) {
      return this.setState({ error: _.get(r, 'errMsg', 'Error') });
    }
    if (this.state.sendMessage) {
      const userId = _.get(await U.api('get', `pros/${this.state.form.userId}`), 'user.id');
      U.api('post', 'messages', {
        sms: { body: this.state.message },
        type: 'proPay',
        user: userId,
      });
      const changes = [{ action: 'add', path: 'notes', value: { content: this.state.message } }];
      U.api('put', `tasks/${this.props.job.id}`, changes, true);
    }
    await this.loadTransactions();
    this.setState({ form: {}, error: '', modal: false });
  }

  async togglePayFlag(proId, flag) {
    if (flag.autoPay !== 'unflag') {
      return;
    }
    await U.api(
      'put',
      `workorders/${this.props.workOrder.id}/payFlags/toggle/${proId}/${flag.id}`,
      undefined,
      true
    );
    const flags = await U.api('get', `workorders/${this.props.workOrder.id}/payflags`);
    this.setState({ flags });
  }

  render() {
    const propay = _.get(this.props.workOrder, 'invoice.proPay', []);
    const requireReason = this.state.form.amount !== this.state.form.max;
    const isCustDispute = _.values(this.props.job.tags).includes('dispute');
    const isProDispute = _.values(this.props.job.tags).includes('proDispute');
    return (
      <div>
        {isCustDispute && <div className="alert alert-danger">Customer Dispute</div>}
        {isProDispute && <div className="alert alert-danger">Pro Dispute</div>}
        {propay.legacy && <div>This job's pro pay is marked as legacy</div>}
        {_.map(propay.pros, (p, i) => {
          if (!p.proId) {
            return;
          }
          const flags = _.get(
            _.find(this.state.flags, f => f.proId === p.proId),
            'flags',
            []
          );
          const ignoredFlags = _.get(
            _.find(this.props.workOrder.ignoredPayFlags, v => v.proId === p.proId),
            'ignoredFlags'
          );
          return (
            <div className="px-3 py-3" key={i}>
              <div className="d-flex justify-content-between py-3">
                <h2>{p.proName}</h2>
                {_.get(this.props.job, 'metadata.bonus') && (
                  <h2 className="text-success">+20% labor bonus</h2>
                )}
              </div>
              {flags.length ? (
                <div>
                  <div>Flags: (check to ignore and allow automatic payment)</div>
                  {_.sortBy(flags, 'name').map((v, i) => (
                    <div
                      key={i}
                      className="pointer ml-3 flex flex-row items-center"
                      onClick={() => this.togglePayFlag(p.proId, v)}
                    >
                      {v.autoPay === 'unflag' ? (
                        _.includes(ignoredFlags, v.id) ? (
                          <Icon className="mr-2" name="check_box_outline" size={16} />
                        ) : (
                          <Icon className="mr-2" name="check_box_outline_blank" size={16} />
                        )
                      ) : (
                        <Icon name="close_thick" className="mr-2" size={16} />
                      )}
                      {v.name}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  Flags: <span className="text-success">No Flags</span>
                </div>
              )}
              {this.props.job.customer.org && (
                <div className="gray-blurb mb-3 text-center">
                  <label>This is a home warranty job</label>
                </div>
              )}
              {!!_.values(p.chargeItems).length && (
                <div className="mb-4">
                  <table className="invoice-editable-table">
                    <tbody>
                      <tr className="invoiceHeader">
                        <td>Name</td>
                        <td>Work Item</td>
                        <td>Quantity</td>
                        <td>Type</td>
                        <td>To Pro</td>
                        <td>To {companyName}</td>
                        <td>Amount</td>
                      </tr>
                      {_.map(p.chargeItems, (i, n) => (
                        <tr className="invoiceRow" key={n}>
                          <td>{i.description}</td>
                          <td>
                            {_.get(
                              _.find(this.props.workOrder.tasks, t => t.id === i.taskId),
                              'title'
                            )}
                          </td>
                          <td>{i.quantity}</td>
                          <td>{i.type}</td>
                          <td>{U.toMoney(i.proPrice * i.quantity)}</td>
                          <td>
                            {U.toMoney((i.unitPrice - i.proPrice) * i.quantity - i.discountPrice)}
                          </td>
                          <td>{U.toMoney(i.unitPrice * i.quantity - i.discountPrice)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <table className="invoice-editable-table">
                <tbody>
                  <tr className="invoiceHeader">
                    <td>Total</td>
                    <td>Balance</td>
                    <td>Paid</td>
                    <td>Refund</td>
                  </tr>
                  <tr className="invoiceRow">
                    <td>{U.toMoney(p.total)}</td>
                    <td>{U.toMoney(p.balance)}</td>
                    <td>{U.toMoney(p.paid)}</td>
                    <td>{U.toMoney(p.refund)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex mt-3">
                {p.balance > 0 && W.isValid(this.props.workOrder) && (
                  <Fab
                    variant="extended"
                    aria-label="Save"
                    className="m-invoice-btn charge mr-2"
                    onClick={() => this.openCredit(p.proId)}
                  >
                    Credit to {p.proName}
                  </Fab>
                )}
                {p.balance < 0 && (
                  <Fab
                    variant="extended"
                    aria-label="Save"
                    className="m-invoice-btn return mr-2"
                    onClick={() => this.openDebit(p.proId)}
                  >
                    Debit from {p.proName}
                  </Fab>
                )}
              </div>
            </div>
          );
        })}
        <Ledger transactions={this.state.transactions} />
        <Fab
          variant="extended"
          aria-label="Save"
          className="m-invoice-btn return m-3"
          onClick={this.compute}
        >
          Refresh
        </Fab>

        <Modal
          className="partModal sm"
          submit={this.submit}
          title={this.state.form.isDebit ? 'Debit from Pro' : 'Credit to Pro'}
        >
          <div className="text-danger">{this.state.error}</div>
          <Select id="form.methodOfPayment" options={['bankAccount', 'cheque']} cap />
          {this.state.form.methodOfPayment === 'cheque' && <Text id="form.checkNumber" />}
          <Money id="form.amount" onChange={this.onAmountChange} />
          {requireReason && <Text id="form.reason" label="Reason for partial amount" />}
          {this.state.form.isDebit && <Bool id="sendMessage" />}
          {this.state.form.isDebit && this.state.sendMessage && <Textbox id="message" />}
        </Modal>
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const workOrder = s.workorders[job.metadata.workOrderId];
  if (!workOrder) {
    return {};
  }
  const pros = _.values(s.pros).filter(p => _.get(job, `proPay.pros.${p.id}`));
  return { pros, job, workOrder };
})(ProPay);
