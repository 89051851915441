import { Column, ColumnType, DatabaseId, Table } from '@nanaio/table';
import _ from 'lodash';
import { SamsungModel, Symptom,SymptomsList } from './types';

export const loadSymptoms = async (): Promise<SymptomsList> => {
  let symptoms = U.redux.get<SymptomsList>('samsungModelsSymptoms');

  if (_.isEmpty(symptoms)) {
    const symptomsResponse = await U.api<SymptomsList>('post', 'samsungModels/symptoms');
    if ('errMsg' in symptomsResponse) {
      return { symptoms1: [], symptoms2: [], symptoms3: [] };
    }
    symptoms = symptomsResponse;
    U.redux.set('samsungModelsSymptoms', symptoms);
  }

  return symptoms;
};

const keyToModule: Record<string, Partial<Column>> = {
  createTime: { type: ColumnType.TIME },
  make: { path: ['make'] },
  model: { path: ['model'] },
  manufacturerDateMin: { path: ['manufacturerDateMin'] },
  manufacturerDateMax: { path: ['manufacturerDateMax'] },
  requiresControlBoard: { path: ['requiresControlBoard'] },
  pcb: { path: ['pcb'] },
  symptom1: {
    loadStore: ({ store }: { store: SymptomsList }) =>
      store ? Promise.resolve(store) : loadSymptoms(),
    storeKey: 'symptoms',
    path: ['symptom1'],
    cellText: ({ store, row }: { store: SymptomsList; row: SamsungModel }) => {
      const symptom = store.symptoms1?.find(s => s.code === row.symptom1) as Symptom;
      return `${symptom?.code} - ${symptom?.description}`;
    },
  },
  symptom2: {
    storeKey: 'symptoms',
    path: ['symptom2'],
    cellText: ({ store, row }: { store: SymptomsList; row: SamsungModel }) => {
      const symptom = store.symptoms2?.find(
        s => s.symptom1 === row.symptom1 && s.code === row.symptom2
      ) as Symptom;
      return `${symptom.code} - ${symptom.description}`;
    },
  },
  symptom3: {
    storeKey: 'symptoms',
    path: ['symptom3'],
    cellText: ({ store, row }: { store: SymptomsList; row: SamsungModel }) => {
      const symptom = store.symptoms3?.find(
        s => s.symptom1 === row.symptom1 && s.symptom2 === row.symptom2 && s.code === row.symptom3
      ) as Symptom;
      return `${symptom?.code} - ${symptom?.description}`;
    },
  },
};

const samsungModelsTable: Table = {
  databaseId: DatabaseId.DEFAULT,
  defaultQuery: {
    columns: [
      'make',
      'model',
      'manufacturerDateMin',
      'manufacturerDateMax',
      'requiresControlBoard',
      'pcb',
      'symptom1',
      'symptom2',
      'symptom3',
    ],
  },
  keyToModule,
  name: 'samsungModel',
  rowUrl: ({ row }: { row: { id: string } }) => `/samsung-models/${row.id}`,
};

export default samsungModelsTable;
