import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import T from 'prop-types';
import { updateTask } from '../../com/task';
import { Modal, Search } from '../../com/ui/form';
import { initStripe,loadUser } from '../../com/user';

class AddCardLink extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      card: 'newCard',
    };
    this.open = this.open.bind(this);
    this.removeCard = this.removeCard.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillUpdate(p, s) {
    if (this.state.modal !== s.modal && s.modal) {
      setTimeout(() => {
        const isTest = _.values(this.props.task.tags).includes('test');
        initStripe(isTest ? 'test' : 'default');
      }, 1000);
    }
  }

  open() {
    this.setState({ modal: true });
    loadUser(_.get(this.props.task, 'customer.user.id'));
  }

  async submit() {
    if (this.state.card && this.state.card !== 'newCard') {
      const changes = [
        { action: 'replace', path: 'payment', value: { stripeCardId: this.state.card } },
      ];
      const r = await updateTask(global.id(), changes);
      if (r.errMsg) {
        this.setState({ error: r.errMsg });
      } else {
        this.setState({ modal: false });
      }
    } else {
      const r = await global.stripe.createToken(global.card);
      if (r.error) {
        this.setState({ error: r.error.message });
      } else {
        this.setState({ error: '' });
        const changes = [
          { action: 'replace', path: 'payment', value: { stripeToken: r.token.id } },
        ];
        const r0 = await updateTask(global.id(), changes);
        if (r0.errMsg) {
          this.setState({ error: r0.errMsg });
        } else {
          this.setState({ modal: false });
        }
      }
    }
  }

  async removeCard() {
    const changes = [{ action: 'remove', path: 'payment.card' }];
    const r = await updateTask(this.props.task.id, changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.setState({ modal: false });
  }

  render() {
    const cards = [
      ..._.get(this.props.user, 'payment.stripe.cards', []).map(v => ({ id: v.id, name: v.last4 })),
      { id: 'newCard', name: 'New Card' },
    ];
    const remove = _.get(this.props.task, 'payment.card') && this.removeCard;
    return (
      <div>
        <div onClick={this.open}>{this.props.content}</div>
        <Modal className="partModal sm" title="Add Card" submit={this.submit} onRemove={remove}>
          <div className="text-danger">{this.state.error}</div>
          <div id="card-errors" />
          {!!cards.length && <Search id="card" options={cards} />}
          <div className="form-group mx-3">
            <label>Add Card</label>
            <div id="card-element" />
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(s => {
  const task = s.tasks[global.id()];
  const user = s.users[_.get(task, 'customer.user.id')];
  return { task, user };
})(AddCardLink);
