import React, { useState } from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Loader from '../../core/Loader';
import Modal from '../../core/Modal';
import TextInput from '../../form/TextInput';
import AssignedPro from '../AssignedRows/Pro';
import EligiblePro from '../EligibleRows/Pro';
import InterestedPro from '../InterestedRows/Pro';
import { proPropType } from '../util';

function Result({ pro, proMap, setSearchTechIsOpen, setVisit, visit, workOrder }) {
  const isAssigned = visit.pros.find(
    visitPro =>
      visitPro.id === pro.id && visitPro.tasks.find(task => task.role !== W.VisitProRole.INTERESTED)
  );
  const isEligible = !visit.pros.find(visitPro => visitPro.id === pro.id);
  const isIneligible = pro.ineligibleReason;
  const isInterested = !isAssigned && visit.pros.find(visitPro => visitPro.id === pro.id);

  if (isAssigned || isIneligible) {
    const visitPro = visit.pros.find(visitPro => visitPro.id === pro.id);
    return <AssignedPro leftAlignDropdown {...{ pro, setVisit, visit, visitPro, workOrder }} />;
  }
  if (isInterested) {
    const visitPro = visit.pros.find(visitPro => visitPro.id === pro.id);
    return <InterestedPro leftAlignDropdown {...{ pro, setVisit, visit, visitPro, workOrder }} />;
  }
  if (isEligible) {
    return <EligiblePro {...{ pro, proMap, setSearchTechIsOpen, setVisit, visit, workOrder }} />;
  }
  return null;
}

Result.propTypes = {
  pro: proPropType.isRequired,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};

export default function SearchTech({
  isOpen,
  onClose,
  proMap,
  pros,
  scheduleStart,
  setSearchTechIsOpen,
  setVisit,
  taskId,
  visit,
  workOrder,
}) {
  const [ineligiblePros, setIneligiblePros] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState();

  let filteredPros = [...pros, ...ineligiblePros];
  if (search) {
    filteredPros = filteredPros.filter(pro =>
      pro.user.fullName.toLowerCase().includes(search.toLowerCase())
    );
  }

  const onDebounce = async value => {
    if (value.length < 3) {
      return;
    }
    const query = {
      _id: { $nin: _.map([...pros, ...ineligiblePros], 'id') },
      status: { $ne: 'applicant' },
      'user.fullName': `||${value}||`,
    };
    setIsLoading(true);
    const results = await U.api('post', 'pros/search', { query });
    const reasons = await Promise.all(
      results.map(pro =>
        U.api('post', 'availability', {
          endTime: m(scheduleStart).add(1, 'week'),
          jobId: taskId,
          proId: pro.id,
          startTime: scheduleStart,
          visit,
        })
      )
    );
    setIneligiblePros(results.map((pro, i) => ({ ...pro, ineligibleReason: reasons[i].reason })));
    setIsLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Search Technician">
        <TextInput
          leftIcon="search"
          value={search}
          onChange={setSearch}
          onDebounce={onDebounce}
          placeholder="Search Name"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <Loader className="mb-4 text-center" isLoading={isLoading} />
          {filteredPros.map(pro => (
            <Result
              {...{ key: pro.id, pro, proMap, setSearchTechIsOpen, setVisit, visit, workOrder }}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

SearchTech.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  pros: PropTypes.arrayOf(proPropType).isRequired,
  scheduleStart: U.timePropType,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  taskId: PropTypes.string,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
