import React from 'react';
import { loggedOutEvent } from '@/com/analytics';

export default class Step9 extends React.Component {
  constructor(p) {
    super(p);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  sendCode() {
    const eventInfo = {
      action: 'recoveryCodeRequested',
      error: '',
      identifierType: '',
      redirect: '',
      usingPassword: false,
    };
    loggedOutEvent().track('v1_userLogin', eventInfo);
    this.props.jumpToStep(5);
  }

  render() {
    const { jumpToStep, getStore } = this.props;
    const { email } = getStore();

    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 className="weight-500">Verify your Account</h3>
            <p className="text-muted">
              We found an existing account under <span className="text-blue">{email}</span>. Tap the
              button below to send a 6-digit code to your phone and email to verify and secure your
              account.
            </p>

            <button onClick={() => this.sendCode()} className="primary mt-4">
              Send code to {email}
            </button>
            <p className="text-muted mt-3">
              or{' '}
              <span className="text-blue cursor-pointer" onClick={() => jumpToStep(0)}>
                go back
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
