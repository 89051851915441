import React, { useEffect, useState } from 'react';
import { T, Task, U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Button, Text } from '@/components';
import Schedule from '@/components/Schedule';
import { useLegacySelector } from '@/hooks';
import BoostJobButton from './BoostJobButton';

type Props = {
  taskId: string;
};

export default function QuickActions({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  const cantReopen = T.isClosed(task) && !roles.admin && !roles.agentManager && !roles.vm;

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const [otherJobsAtThisAddress, setOtherJobsAtThisAddress] = useState(false);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const address = task.serviceAddress;

  useEffect(() => {
    const query = {
      status: 'pendingConfirmation',
      'serviceAddress.region': address.region,
      'serviceAddress.locality': address.locality,
      'serviceAddress.route': address.route,
      'serviceAddress.streetNumber': address.streetNumber,
      'serviceAddress.postalCode': address.postalCode,
      'serviceAddress.unit': address.unit,
      createTime: { $gte: m().add(-120, 'days').valueOf() },
      _id: { $ne: taskId },
    };
    void U.api<Task[]>('post', 'tasks/search', { query }).then(jobs => {
      if (jobs.length) {
        setOtherJobsAtThisAddress(true);
      }
    });
  }, [taskId, address]);

  const setReadyForFollowup = () => {
    // change the job status to needs followup
    const changes = [{ path: 'status', value: T.Status.READY_FOR_FOLLOWUP }];
    void U.api('put', `tasks/${task.id}`, changes, ['save']);
  };

  const toggleBonus = () => {
    // add or remove labor bonus which makes the job more likely to be claimed
    const changes = [
      {
        path: 'metadata.bonus',
        value: !_.get(task, 'metadata.bonus'),
      },
    ];
    void U.api('put', `tasks/${task.id}`, changes, ['save']);
  };

  const toggleBroadcastAllNow = () => {
    // broadcast to all pros simultaneously instead of one-at-a-time
    const changes = [
      {
        path: 'metadata.oppNotif.broadcastAllNow',
        value: !_.get(task, 'metadata.oppNotif.broadcastAllNow'),
      },
    ];
    void U.api('put', `tasks/${task.id}`, changes, ['save']);
  };

  const toggleBroadcastIgnoreRecall = () => {
    // open opportunity to all pros instead of only the original recall pro
    const changes = [
      {
        path: 'metadata.oppNotif.ignoreRecall',
        value: !_.get(task, 'metadata.oppNotif.ignoreRecall'),
      },
    ];
    void U.api('put', `tasks/${task.id}`, changes, ['save']);
  };

  const toggleBroadcastIgnoreSameAddress = () => {
    // ignore same-address opportunity check
    const changes = [
      {
        path: 'metadata.oppNotif.ignoreSameAddress',
        value: !_.get(task, 'metadata.oppNotif.ignoreSameAddress'),
      },
    ];
    void U.api('put', `tasks/${task.id}`, changes, ['save']);
  };

  // render button if the job is pending and some time has elapsed
  const renderBoostPay = () => {
    if (_.get(task, 'metadata.bonus')) {
      return (
        <Button onClick={toggleBonus} size="medium">
          <Text color="white" type="button">
            Remove Bonus
          </Text>
        </Button>
      );
    }
    if (_.get(task, 'visits.0') && !_.get(task, 'visits.1')) {
      const cutoffHours = 2;
      const visitCreateTime = m(task.visits[0].createTime).valueOf();
      if (m(visitCreateTime).valueOf() < m().add(-cutoffHours, 'h').valueOf()) {
        return (
          <Button onClick={toggleBonus} size="medium">
            <Text color="white" type="button">
              Add Bonus
            </Text>
          </Button>
        );
      }
    }
    return null;
  };

  const renderBoostJob = () => {
    if (roles.promo && (task.boost || (!T.proId(task) && !T.isClosed(task)))) {
      return <BoostJobButton />;
    }
    return null;
  };

  const renderBroadcastAllNow = () => {
    // render button if some eligible pros haven't been notified
    const rawPros = task.metadata.oppNotif?.pros;
    const pros = _.values(rawPros);
    if (task.status === 'pendingConfirmation' && pros.length && pros.find(p => !p.notifyTime)) {
      return (
        <Button onClick={toggleBroadcastAllNow} size="medium">
          <Text color="white" type="button">
            {_.get(task, 'metadata.oppNotif.broadcastAllNow') && 'Undo '}
            Broadcast All Now
          </Text>
        </Button>
      );
    }
    return null;
  };

  const renderBroadcastIgnoreRecall = () => {
    // render button if this job is a recall
    if (task.recall?.pastId) {
      return (
        <Button onClick={toggleBroadcastIgnoreRecall} size="medium">
          <Text color="white" type="button">
            {_.get(task, 'metadata.oppNotif.ignoreRecall') && ' Undo '}
            Broadcast Ignore Recall
          </Text>
        </Button>
      );
    }
    return null;
  };

  const renderBroadcastIgnoreSameAddress = () => {
    // render button if there are multiple jobs at this address
    if (otherJobsAtThisAddress) {
      return (
        <Button onClick={toggleBroadcastIgnoreSameAddress} size="medium">
          <Text color="white" type="button">
            {_.get(task, 'metadata.oppNotif.ignoreSameAddress') && 'Undo '}
            Broadcast Ignore Same Address
          </Text>
        </Button>
      );
    }
    return null;
  };

  const renderCreateVisit = () => {
    if (cantReopen) {
      return null;
    }
    // render create visit button
    const visitCount = T.visits(task).length;
    return (
      <Button onClick={() => setScheduleIsOpen(true)} size="medium">
        <Text color="white" type="button">
          Create {visitCount ? 'Followup' : 'First Visit'}
        </Text>
        {T.askCard({ job: task }) && (
          <Text color="danger" type="button">
            (Ask for CC)
          </Text>
        )}
      </Button>
    );
  };

  const renderIds = () => {
    const wol = task.metadata.workOrderLineId;
    return (
      <div>
        <Text>Task Id: {task.id}</Text>
        {wol && <Text>{wol && `WOL Id: ${wol}`}</Text>}
      </div>
    );
  };

  const renderReadyForFollowup = () => {
    if (cantReopen) {
      return null;
    }
    // render button if the job isn't already in needs followup status
    if (![T.Status.CANCELLED, T.Status.READY_FOR_FOLLOWUP].includes(task.status)) {
      return (
        <Button onClick={setReadyForFollowup} size="medium">
          <Text color="white" type="button">
            Ready For Followup
          </Text>
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="flex items-center space-x-4 border-b border-grey-medium bg-background-lightest
      p-4"
      >
        {renderIds()}
        <div className="flex-1" />
        {renderBroadcastIgnoreRecall()}
        {renderBroadcastIgnoreSameAddress()}
        {renderBroadcastAllNow()}
        {renderReadyForFollowup()}
        {renderBoostPay()}
        {renderBoostJob()}
        {renderCreateVisit()}
      </div>
      <Schedule
        isBookingExperiment={_.includes(workOrder?.tags, W.Tag.EXPERIMENTAL_SAMSUNG_BOOKING_FLOW)}
        isOpen={scheduleIsOpen}
        onCancel={() => setScheduleIsOpen(false)}
        taskId={taskId}
        visitId={-1}
        workOrder={workOrder}
      />
    </>
  );
}
