import React, { useCallback, useState } from 'react';
import { WorkOrder } from '@nanaio/util';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { APIError,Button, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import termsHeaderImage from '@/img/customerPortal/termsHeader.png';
import TermsAndConditionsForm from './TermsAndConditionsForm';

type Props = {
  onClose: () => void;
};

export default function ModalContent({ onClose }: Props): JSX.Element {
  const { workOrder } = useLegacySelector(state => {
    const taskId = global.id() || '';
    const task = state.tasks[taskId];
    const workOrder = state.workorders[task.metadata.workOrderId];
    return { workOrder };
  });

  const [form, setForm] = useState({
    terms: false,
    release: false,
  });

  const submitRef = React.createRef<Ref>();

  const [updateWorkOrder, workOrderToUpdate] = useLazyLegacyAPI<WorkOrder>(
    `workOrders/${workOrder.id}/cxAcceptTerms`,
    {
      errorRender: ({ error }) => (
        <APIError
          className="mb-10"
          error={error}
          text={<>Unable to save changes to work order. {error}</>}
        />
      ),
      save: true,
      method: 'post',
    }
  );

  const handleAcceptClick = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }

    const data = await updateWorkOrder();
    if (!data) {
      return;
    }

    const eventInfo = {
      variant: 'ftc',
      step: 'termsAccepted',
    };
    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);

    onClose();
  }, [onClose, submitRef, updateWorkOrder]);

  return (
    <>
      <Modal.Body className="p-4">
        <div className="mt-8 flex justify-center">
          <img className="mx-auto" src={termsHeaderImage} alt="" style={{ maxHeight: 96 }} />
        </div>

        <Text type="headline-5" className="mt-2 text-center">
          Review and Accept Terms
        </Text>

        <Text color="grey.dark" className="mt-2 text-center">
          Please read the following terms and agree before continuing.
        </Text>

        <div className="mt-10">
          {workOrderToUpdate.error}
          <Form onChange={setForm} value={form} ref={submitRef}>
            <TermsAndConditionsForm />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button
          className="w-full"
          disabled={!form.terms || !form.release}
          onClick={handleAcceptClick}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
}
