import React, { memo, useEffect,useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@/components';
import { companyName } from '@/config/const';

function Testimonials() {
  const [selected, setSelected] = useState(0);
  const [bubblesMarginTop, setBubblesMarginTop] = useState(0);
  const quoteRefs = useRef([]);
  const bubbles = useRef([
    {
      image: '/img/apply/testimonial-1.png',
      position: { top: 24, left: 22 },
      quote:
        'The ' +
        companyName +
        " platform has a huge influence on people's lives. Simply by offering the opportunity to be in control of your life.",
      author: 'Milan - Appliance Technician in Sacramento, CA',
    },
    {
      image: '/img/apply/testimonial-2.png',
      position: { top: 25, right: 23 },
      quote:
        'I like the platform because I can choose the job I’m comfortable completing.  I also like choosing my work week by the jobs I accept. Best thing is I make many women feel comfortable by doing the work.',
      author: 'Robin - Appliance Technician in Dallas, TX',
    },
    {
      image: '/img/apply/testimonial-3.png',
      position: { top: 138, left: 142 },
      quote:
        'My earnings immediately jumped. Better yet, I get to work when I want to work and select the repairs I want to do.',
      author: 'Luis - Appliance Technician in San Jose, CA',
    },
    {
      image: '/img/apply/testimonial-4.png',
      position: { top: 223, left: 48 },
      quote:
        'I was looking for a different career. A different job. I needed change. So, I decided to check ' +
        companyName +
        '. What it changes is the time. I spend more time with my family.',
      author: 'Boris - Appliance Technician in Oakland, CA',
    },
    {
      image: '/img/apply/testimonial-5.png',
      position: { top: 263, right: 33 },
      quote:
        'I used to be a limousine driver and now with ' +
        companyName +
        ' I just work Monday through Friday, 8 to 5, and I make a lot more money than before.',
      author: 'Raphael - Appliance Technician in Austin, TX',
    },
  ]).current;

  // Given that some quotes are longer than others, if we was to position the bubbles container with "position: static"
  // it would end up jumping up and down every time the currently selected quote changes. That wouldn't look good, so
  // we place the container with "position: absolute" to keep it always in the same position. Then we calculate here
  // which is the larger quote and that's the gap (plus some extra room) we set between the bubbles container and its
  // parent.
  const updateMarginTop = () => {
    const max = Math.max(...quoteRefs.current.map(e => e?.getBoundingClientRect().height));
    setBubblesMarginTop(max + 64);
  };

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setSelected((selected + 1) % bubbles.length);
    }, 3200);

    return () => {
      clearTimeout(currentTimeout);
    };
  }, [selected, bubbles.length]);

  useEffect(() => {
    window.addEventListener('resize', updateMarginTop);
    // Wait for the screen to be fully loaded and rendered before calculating the bubbles container margin.
    // "useLayoutEffect" can't ensure that, so we have to go vanilla here...
    window.addEventListener('load', updateMarginTop);

    return () => {
      window.removeEventListener('resize', updateMarginTop);
      window.removeEventListener('load', updateMarginTop);
    };
  }, []);

  return (
    <div className="apply-testimonials">
      <div className="apply-testimonials__container apply-bg-grey pt-12 lg:flex lg:pt-0">
        <div className="apply-container justify-between gap-y-12 lg:grid lg:px-4">
          <img className="m-auto lg:hidden" alt="quote" src="/svg/quote.svg" />

          <div className="apply-testimonials__texts mx-6 mt-12 lg:m-0">
            {bubbles.map(({ quote, author }, index) => (
              <div
                key={quote}
                className={classnames('apply-testimonials__texts__text', {
                  'apply-testimonials__texts__text--selected': selected === index,
                })}
                ref={e => {
                  quoteRefs.current[index] = e;
                }}
              >
                <img className="hidden lg:block" alt="quote" src="/svg/quote.svg" />

                <Text
                  type="headline-5"
                  color="black"
                  className="text-center font-normal leading-9 lg:mt-12 lg:text-left"
                >
                  {quote}
                </Text>

                <Text type="subtitle-1" color="grey.dark" className="mt-6 text-center lg:text-left">
                  {author}
                </Text>
              </div>
            ))}
          </div>

          <div
            style={{ marginTop: `${bubblesMarginTop}px` }}
            className="apply-testimonials__bubbles mx-auto lg:m-0"
          >
            {bubbles.map(({ image, position }, index) => (
              <Bubble
                key={image}
                onClick={() => setSelected(index)}
                image={image}
                position={position}
                selected={selected === index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Bubble({ image, position, selected, onClick }) {
  const classes = classnames('apply-testimonials__bubble', {
    'apply-testimonials__bubble--selected': selected,
  });

  return (
    <div className={classes} style={position}>
      <div className="apply-testimonials__bubble__inner">
        <img
          alt={image}
          className="apply-testimonials__bubble__image"
          onClick={onClick}
          src={image}
        />
      </div>
    </div>
  );
}

Bubble.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }).isRequired,
  selected: PropTypes.bool,
};

export default memo(Testimonials);
