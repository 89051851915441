import React, { useCallback, useMemo } from 'react';
import { OpenHours } from '@nanaio/util/dist/partVendor';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Input, InputType, Text } from '@/components';

function generateTimeOptions(): { id: string; name: string }[] {
  const timeOptions = [];

  for (let i = 0; i < 48; i++) {
    const time = m({ hour: Math.floor(i / 2), minute: (i % 2) * 30 });
    const id = time.format('HHmm');
    const name = time.format('h:mm A');
    timeOptions.push({ id, name });
  }

  return timeOptions;
}
const timeOptions = generateTimeOptions();

function DayDisplay({
  day,
  dayChecks,
  disabled,
  formKey,
  handleDayCheck,
}: {
  day: number;
  dayChecks: boolean[];
  disabled?: boolean;
  formKey: string;
  handleDayCheck: (day: number, value: boolean) => void;
}): JSX.Element {
  return (
    <div className="mt-1 grid grid-cols-3">
      <div className="flex items-center">
        <Input
          className="mb-0 self-center"
          disabled={disabled}
          onChange={value => handleDayCheck(day, value)}
          label={m().day(day).format('dddd')}
          type={InputType.CHECK}
          value={dayChecks[day]}
        />
      </div>
      <div className="col-span-2">
        {dayChecks[day] ? (
          <div className="flex flex-grow flex-row items-center space-x-4">
            <Input
              className="mb-0 flex-grow"
              disabled={disabled}
              id={`${formKey}.${day}.from`}
              label=""
              options={timeOptions}
              type={InputType.SEARCH}
            />
            <Text className="self-center" color={disabled ? 'grey.dark' : undefined}>
              to
            </Text>
            <Input
              className="mb-0 flex-grow"
              disabled={disabled}
              id={`${formKey}.${day}.to`}
              label=""
              options={timeOptions}
              type={InputType.SEARCH}
            />
          </div>
        ) : (
          <Text className="pb-2.5 pt-2" color={disabled ? 'grey.dark' : undefined}>
            Closed
          </Text>
        )}
      </div>
    </div>
  );
}

type Props = {
  disabled?: boolean;
  formKey: string;
  onChange: (openHours: OpenHours[]) => void;
  openHours: OpenHours[];
};

export default function Hours({ disabled, formKey, onChange, openHours }: Props): JSX.Element {
  openHours = openHours || [];
  const dayChecks = useMemo(() => {
    return openHours.map(day => {
      return !!(day.from || day.to);
    });
  }, [openHours]);

  const handleDayCheck = useCallback(
    (index: number, value: boolean) => {
      if (!value) {
        onChange(
          openHours?.map((day, i) => {
            if (i === index) {
              return {};
            }
            return day;
          })
        );
      } else {
        _.times(7, i => {
          if (!openHours?.[i]) {
            nullthrows(openHours).push({});
          }
        });
        onChange(
          openHours?.map((day, i) => {
            if (i === index) {
              return { from: '0900', to: '1700' };
            }
            return day;
          })
        );
      }
    },
    [onChange, openHours]
  );

  return (
    <div className="flex-grow flex-col">
      {_.times(7, i => {
        let day = i + 1;
        if (day === 7) {
          day = 0;
        }
        return (
          <DayDisplay
            key={i}
            day={day}
            dayChecks={dayChecks}
            disabled={disabled}
            formKey={formKey}
            handleDayCheck={handleDayCheck}
          />
        );
      })}
    </div>
  );
}
