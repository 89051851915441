import React, { useState } from 'react';
import { Flag, Flags as FlagsEnum, T, Task, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Button, FormControl, Icon, Modal, SearchInput, Text, TextInput } from '@/components';
import { companyName } from '@/config/const';
import { useLegacySelector } from '@/hooks';
import HoverPopup from '@/task/layout/TaskLayout/Header1/HoverPopup';

export type Props = {
  taskId: string;
};

export default function Flags({ taskId }: Props): JSX.Element {
  const me = useLegacySelector(state => state.me);
  const users = useLegacySelector(state => state.users);
  const userId = me.userId;
  const user = users[me.userId];
  const roles = _.keyBy(me.roles);

  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const flags: Flag[] = _.map(task.flags);

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const [form, setForm] = useState<Partial<Flag>>({});
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onAdd = () => {
    setShowModal(true);
    setForm({});
    setError('');
  };

  const onSave = async () => {
    if (!form.name) {
      return setError('Name required');
    }
    if (form.name === T.Flags.REPLACEMENT_RECOMMENDED) {
      if (!form.recommendedBy) {
        return setError('Recommended by required');
      }
      if (!form.note) {
        return setError('Notes required');
      }
    }
    const changes = [
      {
        path: `flags.${form.name}`,
        value: form,
      },
    ];
    const response = await U.api<Task>('put', `tasks/${taskId}`, changes, ['save']);
    if (response && 'errMsg' in response) {
      return setError(response.errMsg);
    }
    return setShowModal(false);
  };

  const openFlag = (flag: Flag) => {
    if (roles.customersupport) {
      setForm(flag);
      setShowModal(true);
      setError('');
    }
  };

  const toggleUnflag = () => {
    if (form.unflag) {
      setForm(prevForm => _.omit(prevForm, 'unflag'));
    } else {
      setForm(prevForm => ({
        ...prevForm,
        unflag: {
          by: {
            userId,
            fullName: user.profile.fullName,
          },
          time: m().toDate(),
        },
      }));
    }
  };

  return (
    <>
      <div className="flex flex-row flex-wrap items-center" style={{ maxWidth: '500px' }}>
        <Icon name="tags" color={flags.find(f => !f.unflag) ? 'danger' : 'primaryCTA'} size={24} />
        &nbsp; <Text className="mr-2">Flags</Text>
        {flags.map((flag, i) => (
          <HoverPopup
            key={i}
            button={
              <Button
                className="relative m-1 mr-2"
                onClick={() => openFlag(flag)}
                size="small"
                variant={flag.unflag ? 'outline' : 'danger'}
              >
                {_.startCase(flag.name)}
              </Button>
            }
            menu={
              <div className="p-4">
                {flag.note && (
                  <div className="mb-4">
                    <Text type="button">Notes</Text>
                    <Text>{flag.note}</Text>
                  </div>
                )}
                {flag.createTime && (
                  <div className="mb-3">
                    <Text type="button">Create Time</Text>
                    <Text>{m(flag.createTime).format('M/D/YY h:mm A')}</Text>
                  </div>
                )}
                {flag.by && (
                  <div className="mb-3">
                    <Text type="button">Creator</Text>
                    <Text>{flag.by.fullName}</Text>
                  </div>
                )}
                {flag.unflag && (
                  <div className="mb-3">
                    <Text type="button">Unflagged By</Text>
                    <Text>{_.get(flag.unflag, 'by.fullName')}</Text>
                  </div>
                )}
                {flag.unflag && (
                  <div className="mb-3">
                    <Text type="button">Unflagged Time</Text>
                    <Text>{m(flag.unflag.time).format('M/D/YY h:mm A')}</Text>
                  </div>
                )}
              </div>
            }
          />
        ))}
        {roles.customersupport && (
          <Button className="m-1" onClick={onAdd} size="small">
            Add Flag
          </Button>
        )}
      </div>
      <Modal isOpen={showModal} onSave={onSave} onClose={() => setShowModal(false)}>
        <Modal.Header title="Edit Flag" />
        <Modal.Body>
          <div className="p-4">
            <Text className="mb-4" color="danger" type="button">
              {error}
            </Text>
            <Button className="mb-4" onClick={toggleUnflag} variant="primary-outline" size="small">
              {form.unflag ? 'Flag' : 'Unflag'}
            </Button>
            <FormControl className="mt-4" label="Name" required>
              <SearchInput
                options={T.flagOptions}
                onChange={value =>
                  setForm(prevForm => ({
                    ...prevForm,
                    name: value as FlagsEnum,
                  }))
                }
                value={form.name}
              />
            </FormControl>
            <FormControl className="mt-4" label="Pro">
              <SearchInput
                options={workOrder.pros}
                onChange={value =>
                  setForm(prevForm => ({
                    ...prevForm,
                    proId: value as string,
                  }))
                }
                value={form.proId}
              />
            </FormControl>
            {form.name === T.Flags.REPLACEMENT_RECOMMENDED && (
              <FormControl className="mt-4" label="Recommended By" required>
                <SearchInput
                  options={['Customer', companyName, 'OEM']}
                  onChange={value =>
                    setForm(prevForm => ({
                      ...prevForm,
                      recommendedBy: value as string,
                    }))
                  }
                  value={form.recommendedBy}
                />
              </FormControl>
            )}
            <FormControl
              className="mt-4"
              label="Note"
              required={form.name === T.Flags.REPLACEMENT_RECOMMENDED}
            >
              <TextInput
                onChange={value =>
                  setForm(prevForm => ({
                    ...prevForm,
                    note: value as string,
                  }))
                }
                multiline
                value={form.note}
              />
            </FormControl>
            {form.createTime && (
              <div>
                <Text type="button">Create Time</Text>
                <Text>{m(form.createTime).format('M/D/YY h:mm A')}</Text>
              </div>
            )}
            {form.by && (
              <div className="mt-4">
                <Text type="button">Creator</Text>
                <Text>{form.by.fullName}</Text>
              </div>
            )}
            {form.unflag && (
              <div className="mt-4">
                <Text type="button">Unflagged By</Text>
                <Text>{_.get(form.unflag, 'by.fullName')}</Text>
              </div>
            )}
            {form.unflag && (
              <div className="mt-4">
                <Text type="button">Unflagged Time</Text>
                <Text>{m(form.unflag.time).format('M/D/YY h:mm A')}</Text>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}
