import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import PropTypes from 'prop-types';
import { APIErrorBoundary, Avatar, Badge, Button, Icon, Modal, Text, Tooltip } from '@/components';
import theme from '@/components/theme';
import { useLegacySelector } from '@/hooks';
import { apiOrigin } from '../../config/const';
import { openLink } from '../../utils';
import EditApproval from './EditApproval';
import PartPhotos from './PartPhotos';

const smallFont = { fontSize: 14 };

function DisplayDiagnosis({ task, dxIndex }) {
  nullthrows(task.metadata.taskQuestions.diagnosis);
  return (
    <div className="flex items-center">
      <Text style={smallFont}>
        {T.diagnosisCodeToString(task.metadata.taskQuestions.diagnosis[dxIndex], false)}
      </Text>
      <Tooltip
        node={
          <div className="flex-column flex">
            <Text type="page-title" color="white" weight={600}>
              {T.diagnosisCodeToString(task.metadata.taskQuestions.diagnosis[dxIndex], false)}
            </Text>

            <Text color="white" className="mt-2">
              {task.metadata.taskQuestions.diagnosis[dxIndex].diagnosis}
            </Text>
          </div>
        }
      />
    </div>
  );
}

function PartCard({ isSelected, onEdit, onReturn, onSelect, me, part, task }) {
  const { pickupPro } = useLegacySelector(state => {
    const { pros } = state;
    const pickupPro = part.pickupProId ? pros[part.pickupProId] : undefined;
    return { pickupPro };
  });
  const [approvalIsOpen, setApprovalIsOpen] = useState();
  const [approvalStatus, setApprovalStatus] = useState();
  const [clearNotes, setClearNotes] = useState();
  const [photoIsOpen, setPhotoIsOpen] = useState();
  const [partPhotosOpen, setPartPhotosOpen] = useState();

  useEffect(() => {
    if (part.pickupProId && !pickupPro) {
      void U.api('get', `pros/${part.pickupProId}`, ['save']);
    }
  }, [part.pickupProId, pickupPro]);

  const approvalInfo = part.approvalStatus
    ? [
        ['Reason', U.getOptionNames(T.partApprovalReasonOptions, part.approvalReason).join(', ')],
        ['Part Failure', U.getOptionName(T.partFailureReasonOptions, part.failureReason)],
        ['Late Diagnosis', U.getOptionName(T.lateDiagnosisReasonOptions, part.lateDiagnosisReason)],
        part.approvalStatus === T.PartApprovalStatus.REQUESTED
          ? [
              'Requested by',
              [
                part.approvalRequestedBy
                  ? `${part.approvalRequestedBy.firstName} ${part.approvalRequestedBy.lastName[0]}.`
                  : 'System',
                `(${m(part.approvalRequestedTime).format('M/D/YY')})`,
              ].join(' '),
            ]
          : [
              'Approver',
              [
                part.approvedBy
                  ? `${part.approvedBy.firstName} ${part.approvedBy.lastName[0]}.`
                  : 'System',
                `(${m(part.approvalReceivedTime).format('M/D/YY')})`,
              ].join(' '),
            ],
        ['Approval Note', part.approvalNotes],
      ].filter(v => v[1])
    : [];

  const autoOrder = _.find(
    task.parts.ordersArray,
    order => order.orderNumber && order.orderNumber === part.vendorOrderNumber
  );
  let autoOrderColor;
  let autoOrderStatus;
  if (autoOrder) {
    switch (autoOrder.status) {
      case 'created':
      case 'attempted':
        autoOrderStatus = 'Auto Order Processing';
        autoOrderColor = 'accent.orange';
        break;
      case 'placed':
      case 'shipped':
      case 'some-delivered':
      case 'return':
      case 'delivered':
        autoOrderStatus = `Auto Ordered`;
        autoOrderColor = 'success';
        break;
      case 'error':
        autoOrderStatus = `Auto Order Fail: '${_.startCase(autoOrder.errorMessage) || 'NA'}'`;
        autoOrderColor = 'danger';
        break;
      case 'archive':
        autoOrderStatus = `Auto Order Archived`;
        autoOrderColor = 'success';
        break;
      default:
        autoOrderColor = 'accent.orange';
        autoOrderStatus = `Auto Order - ${autoOrder.status}`;
        break;
    }
  }

  let imageUrl;
  if (part.images[0]) {
    imageUrl = `${apiOrigin}/images${
      _.values(task.metadata.taskProblemQuestions?.pictures).find(
        picture => picture.checksum === part.images[0]
      )?.url
    }`;
  }

  const returnReason = part.returnReason
    ? U.part.returnReasonOptions.find(reason => reason.id === part.returnReason)?.name
    : null;

  const info = [
    ['CX Price', `${U.toMoney(part.pricePerUnit)}/unit`],
    ['Unit Retail', U.toMoney(part.retailPrice * part.unit.qty)],
    ['Owner', part.owner?.name?.slice(0, 20)],
    [
      'Vendor',
      part.url ? (
        <Text
          color="primaryCTA"
          onClick={() => openLink({ newTab: true, url: part.url })}
          tag="span"
          style={smallFont}
        >
          {_.startCase(part.vendor)} {part.vendorLocation}
        </Text>
      ) : (
        part.vendor
      ),
    ],
    [
      'Delivery Method',
      part.deliveryMethod === U.part.DeliveryMethod.PICKUP ? (
        <div className="flex space-x-1">
          <Icon color="accent.orange" name="store" />
          <Text style={smallFont}>In-Store Pickup</Text>
        </div>
      ) : (
        <div className="flex space-x-1">
          <Icon color="success" name="truck_fast_outline" />
          <Text style={smallFont}>Shipping</Text>
        </div>
      ),
    ],
    ['Picked Up By', pickupPro?.user.fullName],
    ['Return Reason', returnReason],
    [autoOrder ? 'Auto order #' : 'Order #', part.vendorOrderNumber],
    ['Invoice #', part.vendorInvoiceNumber],
  ].filter(v => v[1]);

  const openApproval = approvalStatus => {
    setApprovalStatus(approvalStatus);
    setClearNotes(Boolean(approvalStatus));
    setApprovalIsOpen(true);
  };

  const partReturn = _.find(task.parts.returns, partReturn => partReturn._id === part.partReturnId);

  let returnAction;
  if (T.partReturnStatuses.includes(part.status)) {
    if (!partReturn) {
      returnAction = 'Start Return';
    } else if (partReturn.status === 'requested') {
      returnAction = 'Continue Return';
    } else if (partReturn.status === 'labelGenerated') {
      returnAction = 'Approve Return';
    } else if (partReturn.status === 'approved') {
      returnAction = 'View Return';
    }
  }

  const statusToUI = {
    [T.PartApprovalStatus.APPROVED]: { color: 'success', icon: 'check', title: 'Part Approved' },
    [T.PartApprovalStatus.NOT_APPROVED]: {
      color: 'danger',
      icon: 'close',
      title: 'Part Not Approved',
    },
    [T.PartApprovalStatus.REQUESTED]: {
      color: 'accent-orange',
      icon: 'warning',
      title: 'Pending Approval',
    },
  };

  let timeline = [
    { name: 'Requested', time: part.createTime },
    {
      name: autoOrder ? autoOrderStatus : 'Ordered',
      time: part.partOrderedDate,
      color: autoOrderColor,
    },
    { name: 'Shipped', time: part.partShippedDate },
    { name: part.track?.delivered ? 'Delivered' : 'ETA', time: part.track?.expectedDelivery },
    { name: 'Received', time: part.partReceivedDate },
    { name: 'Installed', time: part.status === 'used' && T.lastVisitEnd(task) },
  ];
  if (partReturn) {
    timeline.push(
      ...[
        { name: 'Return Started', time: partReturn.requestedDate },
        { name: 'Label Created', time: partReturn.labelGeneratedDate },
        { name: 'Awaiting Shipment', time: partReturn.approvedDate },
      ]
    );
  }
  timeline = timeline.filter(value => value.time);

  const trackPart = () =>
    openLink({
      newTab: true,
      url: part.track?.url || `https://t.17track.net/en#nums=${part.trackingNumber}`,
    });

  return (
    <div className="flex items-start border border-grey-medium bg-white p-4">
      <Icon
        className="mr-4"
        color={isSelected ? 'success' : 'grey.dark'}
        name={isSelected ? 'check_circle' : 'radio_button_unchecked'}
        onClick={onSelect}
      />
      <Avatar
        className="bg-background-medium"
        pictureUrl={part.images[0] ? imageUrl : undefined}
        onClick={part.images[0] ? () => setPhotoIsOpen(true) : null}
        badgeProps={{ color: 'grey-dark', children: part.unit.value, variant: 'outline' }}
        badgePosition="topRight"
        iconProps={{ name: 'image', color: 'grey.dark' }}
      />
      <div className="mr-2">
        <Text type="page-title">{part.partNumber}</Text>
        <Text color="grey.dark" style={smallFont}>
          {part.desc}
        </Text>
        <table className="mt-2">
          <tbody>
            {_.times(_.max([info.length, timeline.length]), i => (
              <tr key={i}>
                <td>
                  {info[i] && (
                    <Text className="mr-2" color="grey.dark" style={smallFont}>
                      {info[i][0]}:
                    </Text>
                  )}
                </td>
                <td>
                  {_.isString(info[i]?.[1]) ? (
                    <Text className="mr-6" style={smallFont}>
                      {info[i][1]}
                    </Text>
                  ) : (
                    info[i]?.[1]
                  )}
                </td>
                <td>
                  {timeline[i] && (
                    <Text
                      className="mr-2"
                      color={timeline[i].color || 'grey.dark'}
                      style={smallFont}
                    >
                      {timeline[i].name}:
                    </Text>
                  )}
                </td>
                <td>
                  {timeline[i] && (
                    <Text style={smallFont}>{m(timeline[i].time).format('M/D/YY')}</Text>
                  )}
                </td>
              </tr>
            ))}
            {(part.associatedDiagnosesIndexes || []).length > 0 && (
              <tr>
                <td className="align-top">
                  <Text className="mr-2" color="grey.dark" style={smallFont}>
                    Diagnoses:
                  </Text>
                </td>

                <td colSpan={3}>
                  {part.associatedDiagnosesIndexes.map(dxIndex => (
                    <APIErrorBoundary key={dxIndex} variant="icon">
                      <DisplayDiagnosis task={task} dxIndex={dxIndex} />
                    </APIErrorBoundary>
                  ))}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-1 items-center justify-center">
        {![undefined, T.PartApprovalStatus.NOT_REQUIRED].includes(part.approvalStatus) && (
          <div
            className="mx-4 inline-block rounded border border-grey-medium"
            style={theme.shadowXl}
          >
            <div className="p-4">
              <div className="flex items-start">
                <Badge
                  className="mr-4"
                  color={statusToUI[part.approvalStatus].color}
                  iconProps={{ name: statusToUI[part.approvalStatus].icon }}
                  variant="solid-faded"
                />
                <div>
                  <div className="flex items-start justify-between">
                    <Text className="mb-2" style={smallFont} type="button">
                      {statusToUI[part.approvalStatus].title}
                    </Text>
                    <Text
                      color="primaryCTA"
                      style={smallFont}
                      type="button"
                      onClick={() => openApproval()}
                    >
                      {part.approvalStatus === T.PartApprovalStatus.PENDING ? 'Edit' : 'View'}
                    </Text>
                  </div>
                  <table>
                    <tbody>
                      {approvalInfo.map(info => (
                        <tr key={info[0]}>
                          <td>
                            <Text className="mr-2" color="grey.dark" style={smallFont}>
                              {info[0]}:
                            </Text>
                          </td>
                          <td>
                            <Text
                              lines={info[0] === 'Approval Note' ? 1 : undefined}
                              style={smallFont}
                            >
                              {info[1]}
                            </Text>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {part.ppoVersion &&
                part.approvalClass !== 'unknown' &&
                part.approvalStatus === T.PartApprovalStatus.REQUESTED && (
                  <div className="mt-2 flex items-center">
                    <div className="text-center" style={{ width: 26 }}>
                      <Icon name="analytics" />
                    </div>
                    <Tooltip node="This is an automated prediction based on historical data and may not be accurate.">
                      <Text className="ml-4 mr-1" type="label">
                        Parts prediction engine thinks we should{' '}
                        {part.hasPPOApproval || part.approvalClass === 'approved' ? '' : 'NOT'}{' '}
                        approve this
                      </Text>
                    </Tooltip>
                  </div>
                )}
            </div>
            {me.roles.includes(U.user.Role.PART_APPROVAL) && (
              <div>
                {part.approvalStatus === T.PartApprovalStatus.REQUESTED && (
                  <div
                    className="pointer border-left-0 border-right-0 border-bottom-0 flex border border-grey-medium"
                    style={theme.shadowXl}
                  >
                    <div
                      className="flex flex-1 justify-center border-r border-grey-medium p-2"
                      onClick={() => openApproval(T.PartApprovalStatus.APPROVED)}
                    >
                      <Icon color="success" className="mr-1" name="check" />
                      <Text color="success" style={smallFont}>
                        Approve
                      </Text>
                    </div>
                    <div
                      className="flex flex-1 justify-center p-2"
                      onClick={() => openApproval(T.PartApprovalStatus.NOT_APPROVED)}
                    >
                      <Icon color="danger" className="mr-1" name="close" />
                      <Text color="danger" style={smallFont}>
                        Not Approve
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <Button className="mb-2 block" onClick={onEdit} size="medium" variant="secondary">
          Edit Part
        </Button>
        {returnAction && (
          <Button className="mb-2 block" onClick={onReturn} size="medium" variant="secondary">
            {returnAction}
          </Button>
        )}
        {part.approvalStatus !== T.PartApprovalStatus.REQUESTED && (
          <Button
            className="mb-2 block"
            onClick={() => openApproval(T.PartApprovalStatus.REQUESTED)}
            size="medium"
            variant="secondary"
          >
            Require Approval
          </Button>
        )}
        {(part.track?.url || part.trackingNumber) && (
          <Button className="block" onClick={trackPart} size="medium" variant="secondary">
            Track Part
          </Button>
        )}
        {part.images?.length > 0 && (
          <Button
            className="block"
            onClick={() => setPartPhotosOpen(true)}
            size="medium"
            variant="secondary"
          >
            View Photos
          </Button>
        )}
      </div>
      {approvalIsOpen && (
        <EditApproval
          onClose={() => setApprovalIsOpen(false)}
          part={_.omit(
            { ...part, approvalStatus: approvalStatus || part.approvalStatus },
            _.compact([
              clearNotes && 'approvalNotes',
              approvalStatus === T.PartApprovalStatus.REQUESTED && 'approvalReason',
            ])
          )}
          task={task}
        />
      )}
      <Modal onClose={() => setPhotoIsOpen(false)} isOpen={photoIsOpen}>
        <Modal.Header title={part.desc} />
        <Modal.Body>
          <img alt={part.desc} src={imageUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      <PartPhotos
        isOpen={partPhotosOpen}
        onClose={() => setPartPhotosOpen(false)}
        part={part}
        task={task}
      />
    </div>
  );
}

PartCard.propTypes = {
  isSelected: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  me: U.user.mePropType.isRequired,
  part: T.partPropType.isRequired,
  task: T.propType.isRequired,
};

export default connect(s => ({ me: s.me }))(PartCard);
