import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import propTypes from 'prop-types';
import { Button } from '@/components';
import { Percent, Search } from '../../com/ui/form';
import Loader from '../../com/ui/loader';

class Flags extends React.Component {
  static childContextTypes = { t: propTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    const flagOrder = [
      T.Flags.MARK_ON_SITE,
      T.Flags.NO_PICTURES,
      T.Flags.INCOMPLETE_DIAGNOSIS,
      T.Flags.NO_ESTIMATE,
      T.Flags.ADD_PART,
      T.Flags.MORE_INFO_NEEDED,
    ];
    const flags = _.sortBy(
      T.flagOptions
        .filter(flag => flag.penalize)
        .map(flag => ({
          ...flag,
          rate: 1,
          index: flagOrder.indexOf(flag.id) === -1 ? Infinity : flagOrder.indexOf(flag.id),
        })),
      'index'
    );
    this.state = {
      blockedProRate: U.toPercent(1),
      flags,
      loading: true,
      pros: [],
      regions: [],
      simulationOpen: false,
    };
    this.loadJobs();
  }

  getRows = () => {
    if (!this.state.regions.length) {
      return this.state.pros;
    }
    return this.state.pros.filter(pro =>
      U.region
        .fromAddress(pro.user.address, this.props.regionOptions)
        .find(regionId => this.state.regions.includes(regionId))
    );
  };

  loadJobs = async () => {
    let pros = await U.api('get', 'pros/flags');
    pros = pros
      .sort((a, b) => {
        if (a.flags.oppBlocked !== b.flags.oppBlocked) {
          return a.flags.oppBlocked ? -1 : 1;
        }
        if (a.flags.blockingFlags.length !== b.flags.blockingFlags.length) {
          return a.flags.blockingFlags.length > b.flags.blockingFlags.length ? -1 : 1;
        }
        if (a.flags.flaggedTasks.length !== b.flags.flaggedTasks.length) {
          return a.flags.flaggedTasks.length > b.flags.flaggedTasks.length ? -1 : 1;
        }
        return _.get(a, 'user.fullName') < _.get(b, 'user.fullName') ? -1 : 1;
      })
      .map(pro => {
        pro.taskIdToFlags = _.groupBy(pro.flags.blockingFlags, 'task');
        return pro;
      });
    this.setState({ loading: false, pros });
  };

  simulate = () => {
    this.setState({ blockedProRate: false });
    clearTimeout(this.timeout);
    const This = this;
    this.timeout = setTimeout(() => {
      const idToFlag = _.keyBy(this.state.flags, 'id');
      const alteredFlags = this.state.flags.filter(flag => flag.rate !== 1);
      const blockedPros = This.state.pros.filter(pro => pro.flags.blockingFlags.length);
      const simulations = [];
      for (let i = 0; i < 100; i++) {
        let count = 0;
        for (const pro of blockedPros) {
          let flaggedTaskCount = 0;
          for (const taskFlags of _.values(pro.taskIdToFlags)) {
            let remainingFlags = _.map(taskFlags, 'flag');
            for (const alteredFlag of alteredFlags) {
              if (Math.random() > (alteredFlag.rate || 0)) {
                remainingFlags = remainingFlags.filter(flag => {
                  if (flag === alteredFlag.id) {
                    return false;
                  }
                  const index = idToFlag[flag].index;
                  if (index === Infinity || alteredFlag.index === Infinity) {
                    return true;
                  }
                  return index > alteredFlag.index;
                });
              }
            }
            if (remainingFlags.length) {
              flaggedTaskCount += 1;
            }
          }
          if (flaggedTaskCount) {
            count += 1;
          }
        }
        simulations.push(count);
      }
      const blockedProRate = U.toPercent(_.mean(simulations) / blockedPros.length);
      This.setState({ blockedProRate });
    }, 1000);
  };

  toggleSimulation = () => {
    this.setState({ simulationOpen: !this.state.simulationOpen });
  };

  render() {
    return (
      <div className="m-3">
        <div className="flex">
          <Search id="regions" className="mr-4 flex-1" multi options={this.props.regionOptions} />
          <Button onClick={this.toggleSimulation}>
            {this.state.simulationOpen ? 'Hide' : 'Show'} Simulation
          </Button>
        </div>
        {this.state.simulationOpen && (
          <div className="flex">
            <table>
              <thead>
                <tr>
                  <th>Flag</th>
                  <th>Rate</th>
                </tr>
              </thead>
              {this.state.flags.map((flag, i) => {
                const disabled =
                  Number.isFinite(flag.index) &&
                  this.state.flags
                    .slice(flag.index + 1)
                    .some(flag => flag.rate !== 1 && flag.index !== Infinity);
                return (
                  <tr key={flag.name}>
                    <td>{_.startCase(flag.name)}</td>
                    <td>
                      <Percent
                        id={`flags.${i}.rate`}
                        label=""
                        disabled={disabled}
                        onChange={this.simulate}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="mr-4">Blocked Pro Rate: {this.state.blockedProRate}</div>
          </div>
        )}
        {this.state.loading ? (
          <div className="relative">
            <Loader />
          </div>
        ) : (
          <table className="table-sm table-striped table-hover table">
            <thead>
              <tr>
                <th>Pro</th>
                <th>Blocked</th>
                <th>Blocking Flags</th>
                <th>All Flags</th>
              </tr>
            </thead>
            <tbody>
              {this.getRows().map((pro, i) => (
                <tr key={i}>
                  <td>
                    <a
                      href={`/pros/${pro.id}`}
                      className="link-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_.get(pro, 'user.fullName', 'Unassigned')}
                    </a>
                  </td>
                  <td>
                    {pro.flags.oppBlocked ? <div className="text-danger">Yes</div> : <div>No</div>}
                  </td>
                  <td>
                    {_.map(pro.taskIdToFlags, (flags, taskId) => (
                      <a
                        href={`/tasks/${taskId}`}
                        className="link-blue d-block"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={taskId}
                      >
                        {_.map(flags, 'flag').map(_.startCase).join(', ')}
                      </a>
                    ))}
                  </td>
                  <td>
                    {pro.flags.flaggedTasks.map(task => (
                      <a
                        href={`/tasks/${task.id}`}
                        className="link-blue d-block"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={task.id}
                      >
                        {T.proFlags(task)
                          .map(flag => _.startCase(flag.name))
                          .join(', ')}
                      </a>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default connect(s => {
  const regionOptions = _.sortBy(_.values(s.regions), 'name');
  return { regionOptions };
})(Flags);
