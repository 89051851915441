import React from 'react';

export default React.createContext<{
  isInModal: boolean;
  onClose?: () => void;
  onRemove?: () => void;
  onSave?: () => void;
}>({
  isInModal: false,
});
