import React, { ReactNode, useState } from 'react';
import { T, Task, U, Visit as VisitType, W,WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { APIError, Button, Paper, Text } from '@/components';
import { useGetFeatures } from '@/hooks/api/features';
import ScheduleModal from '../ScheduleModal';
import { canRescheduleVisit,onCall } from './util';

type Props = {
  task: Task;
  visit: VisitType;
  workOrder: WorkOrder;
};

export default function Visit({ task, visit, workOrder }: Props): JSX.Element {
  const [twilioError, setTwilioError] = useState<ReactNode>();
  const [isOpen, setIsOpen] = useState(false);
  const featureQuery = useGetFeatures();
  const squareTrade2HourSlots = featureQuery.isSuccess
    ? featureQuery.data.squareTrade2HourSlots
    : false;
  const timezone = U.timezone(task.serviceAddress);

  const showCall = _.find(_.get(_.last(workOrder.visits), 'pros'), pro =>
    W.visitProIsScheduled(pro, true)
  );

  const showReschedule = canRescheduleVisit(workOrder, visit);
  const scheduledPros = visit.pros.filter(pro => W.visitProIsScheduled(pro));
  if (!scheduledPros.length) {
    scheduledPros.push({ availability: [], status: W.VisitProStatus.PENDING, tasks: [] });
  }

  const handleCall = async () => {
    const error = await onCall(task);
    if (error) {
      setTwilioError(<APIError className="my-2" error={error} />);
    } else {
      setTwilioError(undefined);
    }
  };

  return (
    <>
      <Paper className="mt-4 p-2 text-center" variant="elevated">
        <article className="flex justify-between">
          {/* DATE */}
          {visit.slot && (
            <div className="w-20 border-r border-r-grey-medium py-2 pr-2 text-center">
              <Text className="text-danger" type="subtitle-1">
                {mt(visit.slot.start).tz(timezone).format('ddd')}
              </Text>
              <Text type="subtitle-1">{mt(visit.slot.start).tz(timezone).format('DD')}</Text>
              <Text className="text-grey-dark">
                {mt(visit.slot.start).tz(timezone).format('MMM')}
              </Text>
            </div>
          )}
          {/* TIME */}
          <div className="my-2 ml-4 flex-1 text-left">
            {visit.slot && (
              <Text>
                {squareTrade2HourSlots
                  ? T.getTimeSlotDisplay(task, 'start', visit.slot.start).format('h:mmA - ')
                  : mt(visit.slot.start).tz(timezone).format('h:mmA - ')}
                {squareTrade2HourSlots
                  ? T.getTimeSlotDisplay(task, 'end', visit.slot.end).format('h:mmA')
                  : mt(visit.slot.end).tz(timezone).format('h:mmA')}
              </Text>
            )}
            {/* PROS */}
            {scheduledPros.map((pro, i) => (
              <div className="flex space-x-2" key={i}>
                <Text>{W.proName({ proId: pro.id, workOrder }) || 'Unclaimed'}</Text>
                {workOrder.status === W.Status.CANCELLED ||
                pro.status === W.VisitProStatus.CANCELLED ? (
                  <Text className="text-danger">
                    <b>Cancelled</b>
                  </Text>
                ) : pro.status === W.VisitProStatus.PENDING ? (
                  <Text className="text-danger">
                    This visit time has NOT been confirmed with the technician as of now.
                  </Text>
                ) : (
                  <Text>
                    <b>{_.startCase(pro.status)}</b>
                  </Text>
                )}
              </div>
            ))}
            {twilioError}
            {/* Visit Buttons */}
            <div className="mt-1">
              {/* CALL */}
              {showCall && (
                <Button onClick={handleCall} size="small" variant="outline">
                  Call
                </Button>
              )}
              {/* RESCHEDULE */}
              {showReschedule && (
                <Button
                  className="ml-4"
                  onClick={() => setIsOpen(true)}
                  size="small"
                  variant="outline"
                >
                  Reschedule
                </Button>
              )}
            </div>
          </div>
        </article>
      </Paper>
      <ScheduleModal isOpen={isOpen} onClose={() => setIsOpen(false)} variant="reschedule" />
    </>
  );
}
