import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const styles = {
  fixedTop: { background: 'white', position: 'sticky' },
};

export default function Row({ columnIndex, stickyRowCount, getRowHeight, row, rowIndex }) {
  const itemStyles = {
    height: getRowHeight(rowIndex),
    minHeight: getRowHeight(rowIndex),
  };

  if (rowIndex < stickyRowCount) {
    const zIndex = stickyRowCount - rowIndex;
    const top = _.sum(_.times(rowIndex, getRowHeight));
    return (
      <div key={rowIndex} style={{ ...itemStyles, ...styles.fixedTop, top, zIndex }}>
        {row[columnIndex]}
      </div>
    );
  }

  return (
    <div key={rowIndex} style={itemStyles}>
      {row[columnIndex]}
    </div>
  );
}

Row.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  stickyRowCount: PropTypes.number.isRequired,
  getRowHeight: PropTypes.func.isRequired,
  row: PropTypes.arrayOf(PropTypes.node).isRequired,
  rowIndex: PropTypes.number.isRequired,
};
