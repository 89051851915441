import React from 'react';
import { Col,Row } from 'reactstrap';
import { Icon } from '@/components';

export default class Step5 extends React.Component {
  constructor(p) {
    super(p);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  render() {
    return (
      <section>
        <div className="container">
          <Row className="login-nav">
            <Col lg="3" md="3" sm="3" xs="3">
              <button className="back-button" onClick={this.goBack}>
                <Icon name="arrow_left" size={30} />
              </button>
            </Col>
            <Col lg="6" md="6" sm="6" xs="6">
              <ul className="no-list inline-list step-dots">
                <li className="active">
                  <Icon name="circle" size={16} />
                </li>
                <li className="">
                  <Icon name="circle" size={16} />
                </li>
                <li className="">
                  <Icon name="circle" size={16} />
                </li>
              </ul>
            </Col>
            <Col lg="3" md="3" sm="3" xs="3" />
          </Row>

          <div className="animatedContent">
            <h1>Forgot your password?</h1>
            <h3>No worries! Select an option to receive your recovery code</h3>
            <div className="select-option" onClick={() => this.jumpToStep(6)}>
              <div className="d-flex align-center">
                <Icon name="cellphone" size={30} />
                <div className="ml-3">
                  <label>via SMS</label>
                  <h5>(xxx) xxx-9011</h5>
                </div>
              </div>
            </div>

            <div className="select-option" onClick={() => this.jumpToStep(6)}>
              <div className="d-flex align-center">
                <Icon name="email_outline" size={30} />
                <div className="ml-3">
                  <label>via Email</label>
                  <h5>&bull;&bull;&bull;&bull;nis@gmail.com</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
