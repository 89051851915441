import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import useBreakpoints from '@/hooks/useBreakpoints';
import Body from './Body';
import Context from './Context';
import Footer from './Footer';
import Header from './Header';

type Props = {
  animate?: boolean;
  children: ReactNode;
  className?: string;
  hideOnBackdropClick?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  onRemove?: () => void;
  onSave?: () => void;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
};

function Modal({
  animate = true,
  children,
  className,
  hideOnBackdropClick = false,
  isOpen,
  onClose,
  onRemove,
  onSave,
  width = 500,
  height = 'auto',
}: Props): JSX.Element | null {
  const [breakpoint] = useBreakpoints();

  const origOverflowRef = useRef<string>();

  useEffect(() => {
    if (isOpen) {
      origOverflowRef.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else if (origOverflowRef.current !== undefined) {
      // handle the case where the modal was previously shown but is now hidden
      document.body.style.overflow = origOverflowRef.current;
      origOverflowRef.current = undefined;
    }
    return () => {
      // handle the case where the modal was previously shown but is now unmounted
      if (origOverflowRef.current !== undefined) {
        document.body.style.overflow = origOverflowRef.current;
      }
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const classes = twMerge(
    classnames(
      'flex flex-col items-stretch overflow-hidden bg-white sm:max-h-full sm:rounded',
      { 'animated fadeInUp faster': animate },
      className
    )
  );

  return (
    <Context.Provider value={{ isInModal: true, onClose, onRemove, onSave }}>
      <div
        className="fixed inset-0 z-[1060] flex items-center justify-center bg-black/50 sm:p-8"
        {...(hideOnBackdropClick ? { onClick: onClose } : {})}
      >
        <div
          className={classes}
          onClick={event => event.stopPropagation()}
          // TODO configure TW to not use !important so we can do this with classes
          style={{
            width: breakpoint === 'XS' ? '100%' : width,
            height: breakpoint === 'XS' ? '100%' : height,
          }}
        >
          {children}
        </div>
      </div>
    </Context.Provider>
  );
}

Modal.Body = Body;
Modal.Context = Context;
Modal.Footer = Footer;
Modal.Header = Header;

export default Modal;
