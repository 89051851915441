import React from 'react';
import * as Table from '@nanaio/table';
import U, { Address, Note, Region } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { loadRegionOptions } from '@/com/util';
import { Text } from '../core';

// render address cell body
function RenderAddress({
  column,
  row,
}: {
  column: Table.Column;
  row: Table.Row;
}): JSX.Element | null {
  const lastPath = _.last(column.path);
  if (!lastPath) {
    return null;
  }
  const address = _.get(row, lastPath.replace('.formattedAddress', '')) as Address;
  if (!address) {
    return null;
  }
  return (
    <div>
      <Text>{U.addressLine12(address)}</Text>
      <Text color="grey.dark">{U.addressLine3(address)}</Text>
    </div>
  );
}

const regionModule = {
  loadOptionsMap: async () => _.keyBy(await loadRegionOptions(), 'id'),
  loadOptionsOnMount: true,
  noPath: true,
  projection: ['county', 'locality', 'postalCode', 'region'],
  query: ({
    column,
    optionsMap,
    search,
  }: {
    column: Table.Column;
    optionsMap: Record<string, Region>;
    search: { include: string[] } | { exclude: string[] };
  }): Table.QueryField => {
    const [path] = U.toArray(column.path);
    let query;
    if ('include' in search && U.length(search.include)) {
      const regions = search.include.map(id => optionsMap[id]);
      query = U.region.query({ addressPath: path, regions });
    } else if ('exclude' in search) {
      const regions = search.exclude.map(id => optionsMap[id]);
      query = U.region.query({ addressPath: path, isExclude: true, regions });
    }
    return { ...query, _baseLevel: true };
  },
};

const filterValue = ({ search, value }: { search?: string; value?: Address }): boolean => {
  if (!value) {
    return false;
  }
  let trimmedSearch = _.trim(search);
  if (trimmedSearch.match(/^\d+$/)) {
    return value.streetNumber === trimmedSearch || value.postalCode === trimmedSearch;
  }
  trimmedSearch = trimmedSearch.toLowerCase();
  return (
    _.includes(U.addressToString(value).toLowerCase(), trimmedSearch) ||
    value.formattedAddress?.toLowerCase().includes(trimmedSearch)
  );
};

// address columns
export const addressModel: Record<string, Partial<Table.Column>> = {
  address: {
    cellText: ({ value }: { value: Address[] }): string[] => [
      value.map(v => U.addressToString(v)).join(', '),
    ],
    cellUi: RenderAddress,
    description: 'Displays the full address',
    filter: ({ search, value }: { search?: string; value: Address[] }) =>
      value.some(value => filterValue({ search, value })),
    filterValue,
    group: ['Address'],
    noPath: true,
    query: ({ column, search }: { column: Table.Column; search: string }): Table.QueryField => {
      const [path] = U.toArray(column.path);
      const trimmedSearch = _.trim(search);
      if (trimmedSearch.match(/^\d+$/)) {
        // if search is a number assume user is looking for exact match for street number or zip
        if (trimmedSearch.length !== 5) {
          return { _baseLevel: true, [`${path}.streetNumber`]: trimmedSearch };
        }
        return {
          _baseLevel: true,
          $or: [
            { [`${path}.streetNumber`]: trimmedSearch },
            { [`${path}.postalCode`]: trimmedSearch },
          ],
        };
      }
      if (trimmedSearch.match(/^\d+ [a-zA-Z]+$/)) {
        // assume user is searching for exact street number & partial route match
        const parts = trimmedSearch.split(' ');
        return {
          _baseLevel: true,
          [`${path}.streetNumber`]: parts[0],
          [`${path}.route`]: `||${parts[1]}||`,
        };
      }
      return { _baseLevel: true, [`${path}.formattedAddress`]: `||${search}||` };
    },
    width: 250,
  },
  addressCity: { description: 'Address city', group: ['Address'], path: ['locality'] },
  addressCounty: {
    description: 'Address county',
    group: ['Address'],
    path: ['county'],
    isCounty: true,
  },
  addressLat: {
    description: 'Address latitude',
    group: ['Address'],
    path: ['geoCoordinates.lat'],
    type: Table.ColumnType.NUMBER,
  },
  addressLng: {
    description: 'Address longitude',
    group: ['Address'],
    path: ['geoCoordinates.lng'],
    type: Table.ColumnType.NUMBER,
  },
  addressMarket: {
    ...regionModule,
    cellValue: ({
      column,
      row,
      optionsMap,
    }: {
      column: Table.Column;
      row: Table.Row;
      optionsMap: Record<string, Region>;
    }): string => {
      // if path is an array instead of a string _.get treats it differently
      const path = column.path.length === 1 ? column.path[0] : column.path;
      const region = U.region.regionFromAddress(
        _.get(row, path) as Address,
        _.values(optionsMap)
      )?.id;
      return region || 'Unknown';
    },
    description: 'The most specific region containing the address',
    group: ['Address'],
  },
  addressRecentMarketId: {
    description: 'Id of market containing this address',
    group: ['Address'],
    path: ['recentMarketId'],
  },
  addressRecentRegionIds: {
    description: 'Ids of regions containing this address',
    group: ['Address'],
    path: ['recentRegionIds'],
  },
  addressRegions: {
    ...regionModule,
    cellValue: ({
      column,
      row,
      optionsMap,
    }: {
      column: Table.Column;
      row: Table.Row;
      optionsMap: Record<string, Region>;
    }): string[] => {
      // if path is an array instead of a string _.get treats it differently
      const path = column.path.length === 1 ? column.path[0] : column.path;
      return U.region.fromAddress(_.get(row, path) as Address, _.values(optionsMap));
    },
    description: 'All regions containing the address',
    group: ['Address'],
  },
  addressState: {
    description: 'Address state',
    group: ['Address'],
    options: U.states,
    path: ['region'],
  },
  addressStreet: { description: 'Address street', group: ['Address'], path: ['route'] },
  addressStreetNumber: {
    description: 'Address street number',
    group: ['Address'],
    path: ['streetNumber'],
  },
  addressTimezone: { description: 'Address timezone', group: ['Address'], path: ['gTimezone'] },
  addressZip: { description: 'Address zip', group: ['Address'], path: ['postalCode'] },
};

// note column
const noteCellValue = ({ column, row }: { column: Table.Column; row: Table.Row }): string[] => {
  let notes;
  if (column.path.length === 2) {
    const objects = _.get(row, column.path[0], []) as object[];
    notes = _.flatten(
      objects.map(v => {
        const idToNote = _.get(v, column.path[1]) as Record<string, Note>;
        return _.values(idToNote);
      })
    );
  } else {
    notes = _.get(row, column.path[0]) as Note[];
  }
  return notes.map(note => note.content);
};

function NotesCellUi({
  column,
  row,
}: {
  column: Table.Column;
  row: Table.Row;
}): JSX.Element | null {
  let notes;
  if (column.path.length === 2) {
    const objects = _.get(row, column.path[0], []) as object[];
    notes = _.flatten(
      objects.map(v => {
        const idToNote = _.get(v, column.path[1]) as Record<string, Note>;
        return _.values(idToNote);
      })
    );
  } else {
    notes = _.get(row, column.path[0]) as Note[];
  }
  notes = U.timeSort(notes).reverse();
  if (!notes.length) {
    return null;
  }
  return (
    <div className="group">
      <Text className="group-hover:hidden">{notes[0]?.content}</Text>
      <div className="relative -mx-1 -mt-1 hidden overflow-y-auto rounded border border-grey-dark bg-white p-1 group-hover:block">
        {notes.map(note => (
          <div className="mb-4" key={note._id}>
            <Text>{note.content}</Text>
            <div className="flex justify-between">
              <Text color="primary">{note?.user?.fullName}</Text>
              <Text color="grey.dark">{m(note.createTime).format('M/D/YY h:mma')}</Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export const noteModel = {
  notes: {
    description: 'Notes',
    noExport: true,
    path: 'notes',
    width: 300,
    parentClass: 'p-0',
    cellValue: noteCellValue,
    queryNotAllowed: true,
    cellUi: NotesCellUi,
  },
};
