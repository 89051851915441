import React, { useEffect, useReducer, useRef, useState } from 'react';
import U from '@nanaio/util';
import classnames from 'classnames';
import { isEqual, without } from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Calendar from '@/components/core/Calendar';
import Icon from '@/components/core/Icon';
import Text from '@/components/core/Text';
import {
  initialState,
  loadAvailability,
  reducer,
  save,
  setDays,
  setTimezone,
  sortDaysStrategy,
} from '@/pages/pro/menteeReducer';
import Loader from '../../../com/ui/loader';
import AvailabilityContainer from './AvailabilityContainer';

const DATE_FORMAT = 'YYYY-MM-DD';

export default function MenteeAvailability({ pro, proId }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hasChanges, setHasChanges] = useState(false);

  const minDate = m().add(1, 'day').startOf('day');
  const timeslotIdByDay = useRef({});

  const { days, timezone, loading, originalDays, saving } = state;

  const isDateSelected = date => days.includes(m(date).format(DATE_FORMAT));

  const handleDayClick = day => {
    const dayString = m(day).format(DATE_FORMAT);

    if (isDateSelected(day)) {
      const newDays = without(days, dayString);

      setDays(dispatch, newDays, newDays[0]);
    } else {
      setDays(dispatch, days.concat(dayString).sort(sortDaysStrategy), dayString);
    }
  };

  const handleSave = () => {
    save(dispatch, originalDays, days, timeslotIdByDay.current, pro, proId, timezone);
  };

  useEffect(() => {
    if (!days) {
      if (timezone) {
        loadAvailability(dispatch, proId, timezone, timeslotIdByDay.current);
      } else if (pro) {
        setTimezone(dispatch, U.timezone(pro.user.address));
      } else {
        U.api('get', `pros/${proId}`, ['save']);
      }
    }
  }, [days, pro, proId, timezone]);

  useEffect(() => {
    if (days && originalDays) {
      setHasChanges(!isEqual(days, originalDays));
    }
  }, [days, originalDays]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AvailabilityContainer saveDisabled={saving || !hasChanges} onSave={handleSave}>
      <div className="mt-6 flex items-stretch">
        <Calendar
          minDate={minDate.toDate()}
          tileContent={({ date }) => {
            const classes = classnames('tile-inner', {
              'tile-inner--selected': days.includes(m(date).format(DATE_FORMAT)),
            });

            return (
              <div className={classes}>
                <Text type="body-2" color="font.dark">
                  {m(date).format('D')}
                </Text>
              </div>
            );
          }}
          onClickDay={handleDayClick}
        />

        <div className="flex-column ml-28 flex flex-auto">
          <Text type="subtitle-2" color="font.dark" className="w-100 flex-initial">
            Selected Availability:
          </Text>

          <div
            className="w-100 mt-6 flex-auto overflow-y-auto bg-grey-light px-4"
            style={{ maxHeight: '50vh' }}
          >
            {days.map((day, index) => {
              const classes = classnames('flex items-center justify-between', {
                'border-t border-grey-medium': index,
              });

              return (
                <div key={day} className={classes}>
                  <Text type="body-2" color="secondary">
                    {m(day).format('ddd, MMMM D, YYYY')}
                  </Text>

                  <button
                    type="button"
                    className="-pr-4 flex h-14 w-14 items-center justify-center"
                    onClick={() => handleDayClick(m(day).toDate())}
                  >
                    <Icon color="#878787" name="close" size={20} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </AvailabilityContainer>
  );
}

MenteeAvailability.propTypes = {
  pro: PropTypes.shape({
    status: PropTypes.string.isRequired,
    user: PropTypes.shape({
      address: PropTypes.shape({
        county: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  proId: PropTypes.string.isRequired,
};
