import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Bool, Modal, Select, Textbox } from '../../../../com/ui/form';

class AddNote extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    this.state = { content: p.content };
  }

  getChildContext = () => ({ t: this });

  onClose = () => {
    const { jobId } = this.props;
    const { content } = this.state;
    U.redux.set(`form.${jobId}note`, content);
  };

  onSubmit = async () => {
    const { jobId, onSubmit } = this.props;
    const { content, from, shareWithPro, shareWithVendor, type } = this.state;
    if (!from) {
      return this.setState({ error: 'From required' });
    }
    const changes = [
      {
        action: 'add',
        path: 'notes',
        value: _.pickBy(
          { content, from, shareWithPro, shareWithVendor, type },
          value => value !== undefined
        ),
      },
    ];
    const response = await U.api('put', `tasks/${jobId}`, changes, ['save']);
    if (!response || response.errMsg) {
      return this.setState({ error: _.get(response, 'errMsg', 'Error') });
    }
    this.setState({
      content: undefined,
      error: undefined,
      from: undefined,
      shareWithPro: undefined,
      type: undefined,
      modal: false, // eslint-disable-line react/no-unused-state
    });
    if (onSubmit) {
      onSubmit();
    }
  };

  openModal = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modal: true, shareWithPro: false, shareWithVendor: false });
  };

  showVendorAdd = () => {
    const { vendorNotesEnabled } = this.props;
    return vendorNotesEnabled;
  };

  render() {
    const { error, id, shareWithVendor } = this.state;
    return (
      <div>
        <div className="btn btn-primary mr-3" onClick={this.openModal}>
          Add Note
        </div>
        <Modal
          className="partModal sm"
          title="Add Note"
          submit={this.onSubmit}
          onRemove={id && this.removeNote}
          onClose={this.onClose}
        >
          <div className="text-danger">{error}</div>
          <Select id="from" options={T.noteFromOptions} req />
          <Select id="type" options={T.noteTypeOptions} />
          <Textbox id="content" hint='Write a new note ✍️ Press "Enter" to submit' autoFocus />
          <Bool id="shareWithPro" />
          {this.showVendorAdd() ? (
            <div className="flex flex-row items-center">
              {shareWithVendor ? (
                <Icon
                  name="check_box_outline"
                  size={16}
                  onClick={() => this.setState({ shareWithVendor: !shareWithVendor })}
                />
              ) : (
                <Icon
                  name="check_box_outline_blank"
                  size={16}
                  onClick={() => this.setState({ shareWithVendor: !shareWithVendor })}
                />
              )}
              Also add as a note in vendor portal
            </div>
          ) : (
            ''
          )}
        </Modal>
      </div>
    );
  }
}

AddNote.propTypes = {
  jobId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  vendorNotesEnabled: PropTypes.bool,
};

export default connect(s => {
  const jobId = global.id();
  const job = s.tasks[global.id()];
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const vendorNotesEnabled = _.get(org, 'liaison.enabledStatuses', []).includes('addNote');
  const content = s.form[`${jobId}note`];
  return { content, jobId, vendorNotesEnabled };
})(AddNote);
