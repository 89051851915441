import React, { useCallback, useEffect, useState } from 'react';
import { Pro, U, User } from '@nanaio/util';
import _ from 'lodash';
import { APIError, Input, Loader, Modal, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import TokenLink from './TokenLink';

interface ErrorProps {
  error: string;
}

const UserErrorRender = ({ error }: ErrorProps) => (
  <APIError className="mb-10" error={error} text={<>Unable to search users. {error}</>} />
);

type QueryType = {
  roles?: { $ne?: string };
  email?: string;
  'profile.firstName'?: string;
  'profile.lastName'?: string;
};

type Props = {
  onClose: () => void;
};

export default function ViewAs({ onClose }: Props): JSX.Element {
  const { me, task } = useLegacySelector(state => {
    const me = state.me;
    const taskId = global.id() || '';
    const task = state.tasks[taskId];
    return {
      me,
      task,
    };
  });

  const [search, setSearch] = useState<string | undefined>();
  const [users, setUsers] = useState<User[]>([]);

  const [userSearch, userSearchResponse] = useLazyLegacyAPI<User[]>('users/search', {
    errorRender: UserErrorRender,
    method: 'post',
  });

  const [proSearch, proSearchResponse] = useLazyLegacyAPI<Pro[]>('pros/search', {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to search pros. {error}</>} />
    ),
    method: 'post',
  });

  const handleSearch = useCallback(
    async (value?: unknown) => {
      if (!value || typeof value !== 'string') {
        return;
      }

      let query: QueryType = { roles: { $ne: U.user.Role.ADMIN } };
      if (value.includes('@')) {
        query = { email: value };
      } else {
        const parts = _.compact(value.split(' '));
        const firstName = parts[0];
        const lastName = _.reduce(parts.slice(1), (a, b) => `${a} ${b}`);
        if (firstName) {
          query = { ...query, 'profile.firstName': `||${firstName}||` };
        }
        if (lastName) {
          query = { ...query, 'profile.lastName': `||${lastName}||` };
        }
      }
      const data = await userSearch({ query });
      if (data) {
        setUsers(_.sortBy(data, 'profile.fullName'));
      }
    },
    [userSearch]
  );

  const email = task?.customer?.user?.email;
  useEffect(() => {
    if (email) {
      void handleSearch(email);
    }
  }, [handleSearch, email]);

  const viewAsUser = useCallback(
    async (user: User) => {
      let pro: Pro | null = null;
      if (_.includes(user.roles, U.user.Role.SERVICEPROVIDER)) {
        const data = await proSearch({ query: { 'user.id': user.id } });
        if (data) {
          [pro] = data;
        }
      }
      const newMe = _.cloneDeep(me);
      const trueMe = { userId: newMe.userId, proId: newMe.proId, roles: newMe.roles };
      newMe.trueMe = trueMe;
      newMe.userId = user.id;
      newMe.proId = pro?.id;
      newMe.roles = user.roles;
      newMe.role = U.user.role(user);
      const changes = [
        'me',
        newMe,
        'tasks',
        {},
        'timeslots',
        {},
        `users.${user.id}`,
        user,
        'visits',
        {},
      ];
      U.redux.set(changes);
      const redirect = global.location.pathname.startsWith('/tasks')
        ? global.location.pathname
        : '/tasks';
      global.location.assign(redirect);
    },
    [me, proSearch]
  );

  return (
    <Modal isOpen onClose={onClose} height={800} width={650}>
      <Modal.Header title="View as User" />
      <Modal.Body className="p-4">
        {userSearchResponse.error}
        {proSearchResponse.error}
        <TokenLink />
        <Input
          debounceDelay={1000}
          label="Search by Name or Email"
          onChange={setSearch}
          onDebounce={handleSearch}
          value={search}
        />
        {userSearchResponse.loading && <Loader isLoading />}
        {!userSearchResponse.loading && !!users.length && (
          <>
            <Text className="mt-4">Click on an option below</Text>
            {users.map(user => (
              <Text className="p-4" key={user.id} onClick={() => viewAsUser(user)}>
                {user.profile.fullName} ({user.email})
              </Text>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
