import React, { useEffect, useState } from 'react';
import { Task, U } from '@nanaio/util';
import _ from 'lodash';
import { APIError, FormControl, Modal, SearchInput, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

type Props = {
  onClose: () => void;
  taskId: string;
};

export default function ChangeVendor({ onClose, taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];
  const initialOrgId = task.customer.org?.id || '';

  const rawOrgs = useLegacySelector(state => state.orgs);
  let orgs = _.values(rawOrgs).filter(
    v =>
      v.id === initialOrgId ||
      (v.phone && v.email && !['inactive', 'creditHold'].includes(v.status))
  );
  orgs = _.sortBy(orgs, 'name');
  const foundOrg = orgs.find(v => v.id === initialOrgId);

  const [error, setError] = useState('');
  const [orgId, setOrgId] = useState(foundOrg?.id);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && ((!foundOrg && initialOrgId) || _.isEmpty(rawOrgs))) {
      setLoading(true);
      void U.api('post', 'orgs/search', { limit: -1 }, true);
    }
  }, [foundOrg, initialOrgId, loading, rawOrgs]);

  const onSubmit = async () => {
    if (!orgId) {
      return setError('Vendor required.');
    }

    const changes = [{ action: 'replace', path: 'customer.org', value: { id: orgId } }];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }
    onClose();
  };

  return (
    <>
      <Modal isOpen onSave={onSubmit} onClose={onClose}>
        <Modal.Header title="Change Vendor" />
        <Modal.Body className="p-4">
          <Text className="mb-4" color="danger" type="button">
            {error}
          </Text>
          {taskToUpdate.error}
          <FormControl label="Vendor" required>
            <SearchInput
              options={orgs}
              value={orgId}
              onChange={value => setOrgId(value as string)}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}
