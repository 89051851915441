/* eslint-disable array-callback-return */
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import store from 'store2';
import { stripeKey } from '../../config/const';
import { loggedOutEvent } from '../analytics';
import { goals } from '../marketing';
import { logout } from './func';

export const referredBy = [
  'Google',
  'Home Advisor',
  'Home Warranty',
  'Magnets',
  'Other',
  'Previous Customer',
  'Referral',
  'Thumbtack',
  'Yelp',
];

export const loadUser = async id => {
  const user = await U.api('get', `users/${id}`);
  if (user) {
    U.redux.set(`users.${user.id}`, user);
  }
  return user;
};

export const loadUsers = async (query = {}) => {
  if (!query.limit) {
    query.limit = 100;
  }
  const r = await U.api('post', 'users/search', query);
  if (!r || r.errMsg) {
    return r;
  }
  U.redux.merge({ users: U.toMap(r) });
  return r;
};

export const mapMe = r => {
  const roleOrder = [
    U.user.Role.ADMIN,
    U.user.Role.CUSTOMERSUPPORT,
    U.user.Role.SERVICEPROVIDER,
    U.user.Role.USER,
  ];
  const out = {
    proId: _.get(r, 'roles.serviceprovider.id'),
    roles: r.user.roles,
    token: r.token,
    trueMe: {
      roles: r.user.roles,
      proId: _.get(r, 'roles.serviceprovider.id'),
      userId: r.user._id,
    },
    userId: r.user._id,
  };
  out.role = roleOrder.find(r => out.roles.includes(r));
  return out;
};

export const saveIncludeRoles = r => {
  const fail = { me: { roles: {} } };
  if (!r) {
    U.redux.set('me', { roles: {} });
    return fail;
  }
  if (r.errMsg) {
    logout();
  }
  global.token = r.token;
  const { user } = r;
  const me = mapMe(r);
  if (_.get(r, 'roles.serviceprovider')) {
    const pro = r.roles.serviceprovider;
    U.redux.set([`users.${user.id}`, user, 'me', me, `pros.${pro.id}`, pro]);
    return { me, pro, user };
  }
  U.redux.set([`users.${user.id}`, user, 'me', me]);
  return { me, user };
};

export const loadMe = async token => {
  token = token || global.token || (await store.getItem('token'));
  if (!token) {
    return U.redux.set('me', { role: 'guest' });
  }
  global.token = token;
  const r = await U.api('get', 'users/me?includeRoles=true');
  if (!r || r.errMsg) {
    return r;
  }
  return saveIncludeRoles({ ...r, token });
};

export const updateUser = async (id, changes) => {
  const r = await U.api('put', `users/${id}`, changes);
  changes = changes.map(c => {
    if (_.isString(c.value)) {
      c.value = _.trim(c.value);
    }
    return c;
  });
  if (r.errMsg) {
    return r;
  }
  U.redux.set(`users.${id}`, r);
  return r;
};

export const postUser = async user => {
  const body = {
    email: user.email,
    profile: _.pick(user, ['firstName', 'lastName', 'phone', 'address']),
  };
  body.profile.phone = U.trimPhone(body.profile.phone);
  const r = await U.api('post', 'users', body);
  if (!r || r.errMsg) {
    return { errMsg: _.get(r, 'errMsg', 'Error') };
  }
  U.redux.set(`users.${r._id}`, r);
  return r;
};

const ccStyle = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

export const initStripe = () => {
  setTimeout(() => {
    // eslint-disable-next-line no-multi-assign
    const stripe = (global.stripe = global.Stripe(stripeKey));
    const elements = stripe.elements();
    // eslint-disable-next-line no-multi-assign
    const card = (global.card = elements.create('card', {
      hidePostalCode: true,
    }));
    card.mount('#card-element', { style: ccStyle });
    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }, 0);
};

export const statusOptions = ['active', 'suspended'];

export const mapUser = r => {
  const me = {
    role: r.user.roles.includes(U.user.Role.CUSTOMERSUPPORT)
      ? 'admin'
      : r.user.roles.includes(U.user.Role.SERVICEPROVIDER)
        ? 'pro'
        : 'user',
    userId: r.user.id,
    proId: _.get(r, 'roles.serviceprovider.id'),
    roles: U.toMap(r.user.roles),
  };
  if (r.token) {
    me.token = r.token;
    store.set('token', r.token);
  } else {
    me.token = store.get('token');
  }

  if (me.proId) {
    U.redux.set([
      'me',
      me,
      `pros.${me.proId}`,
      r.roles.serviceprovider,
      `users.${me.userId}`,
      r.user,
    ]);
  } else {
    U.redux.set(['me', me, `users.${me.userId}`, r.user]);
  }
  return me;
};

const redirect = () => global.store.dispatch(push('/workorders'));

export const login = async ({ email, password, phone, redirect, isResettingPassword = false }) => {
  const request = { type: 'local', phone, email, password };
  const response = await U.api('post', 'auth/local?includeRoles=true', request);
  if (response.message) {
    response.errMsg = response.message;
  }

  const identifierIsEmail = _.includes(email, '@');
  const eventInfo = {
    action: isResettingPassword ? 'createOrResetPassword' : 'login',
    error: '',
    identifierType: identifierIsEmail ? 'email' : 'phone',
    redirect: redirect,
    usingPassword: true,
  };

  if (response.errMsg) {
    eventInfo.error = response.errMsg;
    if (response.errMsg === 'Authentication failed') {
      U.redux.set('form.error', 'This email is not associated with an account. Please sign up.');
    } else if (response.errMsg.startsWith('Authentication')) {
      U.redux.set('form.error', `Please login in with ${response.errMsg.split('- ')[1]}`);
    }
    loggedOutEvent().track('v1_userLogin', eventInfo);
    return response;
  }
  loggedOutEvent().track('v1_userLogin', eventInfo);
  if (redirect) {
    redirect(saveIncludeRoles(response).me.roles);
  } else {
    saveIncludeRoles(response);
  }
  return response;
};

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const signup = async ({ firstName, lastName, email, password, address, phone }) => {
  if (!new RegExp(emailRe).test(email)) {
    return { errMsg: 'Invalid email address' };
  }
  const r = await U.api('post', 'users?includeRoles=true', {
    profile: { firstName: _.trim(firstName), lastName: _.trim(lastName), address, phone },
    email,
    auth: { provider: 'local', password },
  });
  if (r.errMsg) {
    return r;
  }
  goals.userSignup();
  const { me } = saveIncludeRoles(r);
  redirect(me.roles);
  mapUser(r);
  return r;
};
