import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';

export const load = id => U.api('get', `https://api.myjson.com/bins/${id}`);

export const filterMin = ({ rows, min }) => {
  if (!min) {
    return rows;
  }
  if (_.isObject(min)) {
    return rows.filter(r => _.sumBy(r.data.slice(0, min.length), min.key) >= min.value);
  }
  return rows.filter(r => _.sumBy(r.data.slice(0, min[1]), 'top') >= min[0]);
};

export const getIntervals = ({ interval, start, end }) => {
  const length = _.floor((end - start) / 86400000);
  if (interval === 'month') {
    const out = [{ start: 0, end: m(end).date() - 1 }];
    while (_.last(out).end < length) {
      const daysInMonth = m()
        .add(-(_.last(out).end + 1), 'd')
        .date();
      const newEnd = daysInMonth + _.last(out).end;
      out.push({ start: _.last(out).end + 1, end: newEnd < length ? newEnd : length });
    }
    return out;
  }
  if (interval === 'week') {
    const out = [{ start: 0, end: m(end).day() }];
    _.times(Math.floor((length - out[0].end) / 7), n =>
      out.push({ start: out[0].end + 1 + n * 7, end: out[0].end + (n + 1) * 7 })
    );
    return out;
  }
  return _.times(length, n => ({ start: n, end: n }));
};

export const sortRows = ({ rows, sort, interval }) => {
  const first = rows.find(r => r.name === 'Total');
  const body = rows.filter(r => r.name !== 'Total');

  const sortIndex = interval === 'month' ? 0 : 1;
  if (rows.find(r => r.name === 'Total')) {
    rows = [first, ...body];
  }
  if (sort === 'alphabeticalAscending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  if (sort === 'alphabeticalDescending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)),
    ];
  }
  if (sort === 'ratioAscending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        const aRatio = a.cells[sortIndex].top / a.cells[sortIndex].bot || 0;
        const bRatio = b.cells[sortIndex].top / b.cells[sortIndex].bot || 0;
        if (aRatio > bRatio) {
          return -1;
        }
        if (aRatio < bRatio) {
          return 1;
        }
        return a.name < b.name ? -1 : 1;
      }),
    ];
  }
  if (sort === 'ratioDescending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        const aRatio = a.cells[sortIndex].top / a.cells[sortIndex].bot || 0;
        const bRatio = b.cells[sortIndex].top / b.cells[sortIndex].bot || 0;
        if (aRatio > bRatio) {
          return -1;
        }
        if (aRatio < bRatio) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  if (sort === 'numeratorAscending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        if (a.cells[sortIndex].top < b.cells[sortIndex].top) {
          return -1;
        }
        if (a.cells[sortIndex].top > b.cells[sortIndex].top) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  if (sort === 'numeratorDescending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        if (a.cells[sortIndex].top > b.cells[sortIndex].top) {
          return -1;
        }
        if (a.cells[sortIndex].top < b.cells[sortIndex].top) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  if (sort === 'denominatorAscending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        if (a.cells[sortIndex].bot < b.cells[sortIndex].bot) {
          return -1;
        }
        if (a.cells[sortIndex].bot > b.cells[sortIndex].bot) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  if (sort === 'denominatorDescending') {
    return [
      rows[0],
      ...rows.slice(1).sort((a, b) => {
        if (a.cells[sortIndex].bot > b.cells[sortIndex].bot) {
          return -1;
        }
        if (a.cells[sortIndex].bot < b.cells[sortIndex].bot) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];
  }
  return rows;
};
