import React from 'react';
import { U } from '@nanaio/util';
import classnames from 'classnames';
import { Icon, IconProps, Text } from '@/components';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  icon: IconProps['name'];
  title: string | JSX.Element;
  variant?: 'light' | 'success';
};

export default function Panel({
  children,
  className,
  icon,
  title,
  variant = 'light',
}: Props): JSX.Element {
  const classes = classnames(
    'w-full rounded-lg border p-4',
    {
      'border-grey-medium bg-white': variant === 'light',
      'border-green-50 bg-green-15': variant === 'success',
    },
    className
  );
  const iconColor = variant === 'success' ? 'success' : 'primaryCTA';

  return (
    <div className={classes}>
      <div className="flex items-start">
        <Icon color={iconColor} name={icon} size={20} />

        {U.isText(title) ? (
          <Text type="label" className="ml-4">
            {title}
          </Text>
        ) : (
          title
        )}
      </div>

      <div className="ml-8 mt-4">
        <div className="grid grid-cols-6 items-center gap-3">{children}</div>
      </div>
    </div>
  );
}
