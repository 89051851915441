import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import { Modal, Text } from '@/components';

export default function CellModal({
  activeCell,
  baseCapacity,
  cellData,
  getCapacity,
  getCounts,
  idToRegion,
  idToWorkOrder,
  onClose,
}) {
  const statusToOrder = { pending: 1, claimed: 2, cancelled: 3 };
  let slots =
    cellData[activeCell.regionId]?.slots?.[
      `${activeCell.date.valueOf()}-${activeCell.slotIndex}`
    ] || [];
  slots = _.sortBy(slots, slot => statusToOrder[slot.status]);
  const counts = getCounts(slots);
  const capacity = getCapacity({
    baseCapacity,
    claimedCount: counts.claimed,
    date: activeCell.date,
    pendingCount: counts.pending,
    regionId: activeCell.regionId,
    slotIndex: activeCell.slotIndex,
  });
  const fulfillmentRate = counts.claimed / (counts.claimed + counts.pending);

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header
        title={`${
          idToRegion[activeCell.regionId]?.name || 'All Regions'
        } - ${activeCell.date.format('ddd, MMM Do,')} ${
          ['8 - 12', '12 - 4', '4 - 8'][activeCell.slotIndex]
        }`}
      />
      <Modal.Body>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Text>Agent Book: {capacity.agentCanBook ? 'Yes' : 'No'}</Text>
                </th>
                <th>
                  <Text>Cx Book: {capacity.customerCanBook ? 'Yes' : 'No'}</Text>
                </th>
                <th>
                  <Text>Capacity: {_.round(capacity.capacity, 2)}</Text>
                </th>
              </tr>
            </thead>
          </table>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Text>Claimed: {_.round(counts.claimed, 2)}</Text>
                </th>
                <th>
                  <Text>Pending: {_.round(counts.pending, 2)}</Text>
                </th>
                <th>
                  <Text>Cancelled: {_.round(counts.cancelled, 2)}</Text>
                </th>
                <th>
                  <Text>Fulfillment Rate: {U.toPercent(fulfillmentRate)}</Text>
                </th>
              </tr>
            </thead>
          </table>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Text>Job</Text>
                </th>
                <th>
                  <Text>Status</Text>
                </th>
                <th>
                  <Text>Visit Count</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {slots.map(slot => {
                const workOrder = idToWorkOrder[slot.workOrderId];
                return (
                  <tr key={`${slot.workOrderId}-${slot.visitId}`}>
                    <td>
                      <Text color="primaryCTA">
                        <a
                          href={`/workOrders/${slot.workOrderId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {workOrder.cx.name} {W.title(workOrder)} ({slot.visitId})
                        </a>
                      </Text>
                    </td>
                    <td>
                      <Text>{_.startCase(slot.status)}</Text>
                    </td>
                    <td>
                      <Text>{_.round(slot.weight, 2)}</Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
