import React, { useState } from 'react';
import _ from 'lodash';
import { Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import Cancel from '../../../actions/cancel';
import EscalateLink from '../../../actions/escalate';
import MarkAsRecall from '../../../actions/MarkAsRecall';
import HoverPopup from './HoverPopup';

type Props = {
  taskId: string;
};

export default function MoreActions({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const [cancelOpen, setCancelOpen] = useState(false);
  const [recallOpen, setRecallOpen] = useState(false);

  const address = task.serviceAddress || {};
  const addressString = encodeURIComponent(address.formattedAddress);
  const mapLink = `https://www.google.com/maps/search/?api=1&query=${addressString}`;

  return (
    <>
      <div className="ml-4">
        <HoverPopup
          button={
            <div className="pointer flex h-14 w-14 items-center justify-center rounded-full border border-black bg-white transition-all ease-in-out group-hover:bg-black">
              <Icon name="settings" className="text-font-dark group-hover:text-white" size={25} />
            </div>
          }
          menu={
            <div className="w-full">
              <ul className="">
                <li className="pointer p-4 hover:bg-background-light">
                  <a
                    className="no-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={mapLink}
                  >
                    <div className="flex items-center justify-between">
                      <Text>Open In Maps</Text>
                      <Icon name="map" color="#A1A4AB" size={15} />
                    </div>
                  </a>
                </li>
                <li className="pointer p-4 hover:bg-background-light">
                  <EscalateLink taskId={taskId} />
                </li>
                <li className="pointer p-4 hover:bg-background-light">
                  <div
                    className="flex items-center justify-between"
                    onClick={() => setCancelOpen(true)}
                  >
                    <Text>Cancel</Text>
                    <Icon name="close" color="#A1A4AB" size={15} />
                  </div>
                </li>
                <li className="pointer p-4 hover:bg-background-light">
                  <div
                    className="flex items-center justify-between"
                    onClick={() => setRecallOpen(true)}
                  >
                    <Text>Recall</Text>
                    <Icon name="medical_bag" color="#A1A4AB" size={15} />
                  </div>
                </li>
              </ul>
            </div>
          }
        />
      </div>
      {recallOpen && <MarkAsRecall onClose={() => setRecallOpen(false)} taskId={taskId} />}
      {cancelOpen && <Cancel onClose={() => setCancelOpen(false)} taskId={task.id} />}
    </>
  );
}
