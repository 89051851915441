import React, { useCallback, useState } from 'react';
import { PartVendor } from '@nanaio/util';
import _ from 'lodash';
import { Alert,APIError, Modal } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';
import Profile from './Profile';

const DEFAULT_OPEN_HOURS = _.times(7, _.constant({}));
const DEFAULT_PART_VENDOR = {
  accountNumber: '',
  defaultOpenHours: [...DEFAULT_OPEN_HOURS],
  name: '',
  locations: [],
  hasBestAvailableOrdering: false,
  pickupInstructions: '',
  pickupOrderHoldDays: 0,
  pickupOrderPrepMinutes: 0,
  returnWindowDays: 0,
  website: '',
};

type Props = {
  isOpen: boolean;
  onSuccess: (data: PartVendor) => void;
  toggleOpen: () => void;
};

export default function AddPartVendor({ isOpen, onSuccess, toggleOpen }: Props): JSX.Element {
  const [partVendor, setPartVendor] = useState<Partial<PartVendor>>(DEFAULT_PART_VENDOR);

  const [error, setError] = useState<string | null>(null);

  const [newPartVendor, newPartVendorResponse] = useLazyLegacyAPI<PartVendor>(`partVendors`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={<>Unable to add new part vendor. {error}</>}
      />
    ),
    method: 'post',
  });

  const submit = useCallback(async () => {
    if (!partVendor.name) {
      return setError('Name required');
    }
    setError(null);

    const data = await newPartVendor(partVendor);
    if (!data) {
      return;
    }
    onSuccess(data);
  }, [newPartVendor, onSuccess, partVendor]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleOpen}
      onSave={submit}
      hideOnBackdropClick={false}
      width={1200}
    >
      <Modal.Header title="Add Part Vendor" />
      <Modal.Body className="p-4">
        {error && (
          <Alert className="mb-10" variant="error">
            {error}
          </Alert>
        )}
        {newPartVendorResponse.error}
        <Profile isEdit={false} isInModal onChange={setPartVendor} partVendor={partVendor} />
      </Modal.Body>
      <Modal.Footer noValidate />
    </Modal>
  );
}
