import React, { CSSProperties, useState } from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import { Button, Icon } from '../../core';
import { Input } from '../../form';
import { checkColumnWidth, fixedColumnCount } from '../util';

type Props = {
  checkedRows: Record<string, boolean>;
  columnIndex: number;
  rows: Table.Row[];
  onLoadColumn: () => Promise<void>;
  onSearch: ({ columnIndex, value }: { columnIndex: number; value: unknown }) => Promise<void>;
  onTextFilterChange: (columnIndex: number, value: string) => void;
  openEditColumn: (module: Table.Column, clickedSettingsIcon?: boolean) => void;
  query: Table.Query;
  style: CSSProperties;
  toggleAllRowsChecked: () => void;
};

export default function Filter({
  checkedRows,
  columnIndex,
  rows,
  onLoadColumn,
  onSearch,
  onTextFilterChange,
  openEditColumn,
  query,
  style,
  toggleAllRowsChecked,
}: Props): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
    void onLoadColumn();
  };

  const nonFixedColumnIndex = columnIndex - fixedColumnCount;
  const module = query.columns[nonFixedColumnIndex];
  const newStyle = { ...style, width: columnIndex ? module?.width : checkColumnWidth };

  // check / uncheck all
  if (!columnIndex) {
    const checkedRowCount = rows.filter(row => checkedRows[row._id]).length;
    const allRowsChecked = checkedRowCount > 0 && checkedRowCount === rows.length;
    return (
      <div
        className="align-center flex cursor-pointer justify-center"
        onClick={toggleAllRowsChecked}
        style={newStyle}
      >
        <Icon
          className={allRowsChecked ? 'text-primaryCTA' : ''}
          name={allRowsChecked ? 'check_box' : 'check_box_outline_blank'}
          size={20}
        />
      </div>
    );
  }

  if (module.unloaded && !isLoaded) {
    return (
      <div className="flex justify-center border border-grey-medium p-1" style={newStyle}>
        <Button onClick={handleLoad} variant="secondary">
          Load
        </Button>
      </div>
    );
  }

  // filter
  const searchType = query?.columns?.[nonFixedColumnIndex]?.search?.type;
  const onClick = () => openEditColumn(module, true);
  let icon = (
    <Icon
      color="grey.dark"
      cypressId={`settings-${module.key}`}
      name="settings"
      onClick={onClick}
    />
  );
  if (searchType === Table.SearchType.HAS_VALUE) {
    icon = <Icon color="success" name="check" onClick={onClick} />;
  } else if (searchType === Table.SearchType.LOAD) {
    icon = <Icon color="primary" name="download" onClick={onClick} />;
  } else if (searchType === Table.SearchType.NO_VALUE) {
    icon = <Icon color="danger" name="close" onClick={onClick} />;
  } else if (searchType === Table.SearchType.NOT_EQUAL) {
    icon = <Icon color="accent.orange" name="report_off" onClick={onClick} />;
  } else if (searchType === Table.SearchType.PIVOT) {
    icon = <Icon color="primary" name="pivot_table_chart" onClick={onClick} />;
  } else if (searchType === Table.SearchType.UNIQUE) {
    icon = <Icon color="primary" name="filter_alt" onClick={onClick} />;
  }

  return (
    <div
      className="flex cursor-pointer justify-between border border-grey-medium p-1"
      style={newStyle}
    >
      {!module.hideFilter && (
        <Input
          className="flex-1"
          cypressId={`filter-${module.key}`}
          debounceDelay={500}
          disabled={!module.textFilterEnabled}
          innerClassName="text-black"
          onClick={() => !module.textFilterEnabled && openEditColumn(module)}
          onDebounce={(value: unknown) => onTextFilterChange(nonFixedColumnIndex, value as string)}
          onEnter={value => onSearch({ columnIndex: nonFixedColumnIndex, value })}
          placeholder="Search"
          value={module.search?.text}
        />
      )}
      {!module.hideFilter && icon}
    </div>
  );
}
