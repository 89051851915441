import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import Context from '@/components/form/Context';
import Icon from './Icon';
import Loader from './Loader';
import Text from './Text';
import Tooltip from './Tooltip';

const classNamesByVariant = {
  'button-danger':
    'bg-danger hover:bg-danger-hover group-hover:bg-danger-hover disabled:bg-danger disabled:opacity-25',
  'button-outline':
    'ring-1.5 ring-grey-medium bg-white hover:ring-secondary group-hover:ring-secondary disabled:bg-grey-light disabled:opacity-60 disabled:ring-grey-medium',
  'button-primary':
    'bg-primaryCTA hover:bg-primaryCTA-hover group-hover:bg-primaryCTA-hover disabled:bg-primaryCTA disabled:opacity-25',
  'button-primary-outline':
    'ring-1.5 ring-primary bg-primary-light hover:opacity-60 group-hover:opacity-60 disabled:opacity-30',
  'button-secondary':
    'bg-background-light hover:opacity-60 group-hover:opacity-60 disabled:opacity-30',
  'button-secondary-outline':
    'ring-1.5 ring-grey-dark bg-background-medium hover:opacity-60 group-hover:opacity-60 disabled:opacity-30',
  'button-link': 'bg-transparent disabled:opacity-50',
  'text-danger': 'text-white',
  'text-outline': 'text-secondary disabled:text-grey-dark',
  'text-primary': 'text-white',
  'text-primary-outline': 'text-primary-regular',
  'text-secondary': 'text-font-dark',
  'text-secondary-outline': 'text-grey-dark',
  'text-link': 'text-primaryCTA hover:text-primaryCTA-hover',
};

const xPaddingBySize = {
  small: ' px-4 ',
  medium: ' px-4 ',
  large: ' px-6 ',
  xlarge: ' px-6 ',
};

const yPaddingBySize = {
  small: ' py-1.25 ',
  medium: ' py-2 ',
  large: ' py-2.5 ',
  xlarge: ' py-3.5 ',
};

const heightBySize = {
  small: 30,
  medium: 36,
  large: 42,
  xlarge: 48,
};

export type Option = {
  id?: number | string;
  name: ReactNode;
  onClick?: (id?: number | string) => Promise<void> | void;
  tooltip?: string;
};

export type Props = {
  children: ReactNode;
  className?: string;
  cypressId?: string;
  disabled?: boolean;
  href?: string;
  onClick?: (id?: number | string) => Promise<void> | void;
  options?: Option[];
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  style?: CSSProperties;
  submit?: boolean;
  target?: string;
  tooltip?: { node: ReactNode };
  variant?: (typeof VARIANTS)[number];
};

const styles = {
  container: { borderWidth: 0, outline: 0 },
};

const VARIANTS = [
  'danger',
  'outline',
  'primary',
  'secondary',
  'primary-outline',
  'secondary-outline',
  'link',
] as const;

export default function Button({
  children,
  className = '',
  cypressId,
  disabled,
  href,
  onClick,
  options,
  size,
  style = {},
  submit,
  target,
  tooltip,
  variant = 'primary',
}: Props): ReactElement {
  const { onSubmitClick, submitIsDisabled } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const newDisabled = submit && submitIsDisabled ? true : disabled;

  const handleClick = (id?: number | string) => {
    if (submit && onSubmitClick) {
      void onSubmitClick();
    }
    if (newDisabled || isLoading || !onClick) {
      return;
    }
    setIsLoading(true);
    const sendClick = async () => {
      await onClick(id);
      if (mounted.current) {
        setIsLoading(false);
      }
    };
    // add slight delay so that thread doesn't get locked processing before showing spinner
    setTimeout(() => {
      void sendClick();
    }, 20);
  };

  const isText = U.isText(children);

  const textType = size === 'small' || size === 'medium' ? 'smallButton' : 'button';

  const body = (
    <>
      {isText ? (
        <Text className={classNamesByVariant[`text-${variant}`]} noWrap type={textType}>
          {children}
        </Text>
      ) : (
        children
      )}
      {isLoading && (
        <div className="absolute inset-0">
          <div
            className={`absolute h-full w-full opacity-80 ${
              classNamesByVariant[`button-${variant}`]
            }`}
          />
          <Loader
            className="absolute left-1/2 top-1/2 -ml-2.5 -mt-2.5"
            isLoading
            variant={variant === 'primary' ? 'light' : 'dark'}
          />
        </div>
      )}
    </>
  );

  let buttonClass = `rounded-lg disabled:cursor-auto @apply disabled:pointer-event-none ${
    classNamesByVariant[`button-${variant}`]
  }`;

  if (!className.split(' ').some(className => className.startsWith('px-'))) {
    buttonClass += size ? xPaddingBySize[size] : ' px-5 ';
  }
  if (!className.split(' ').some(className => className.startsWith('py-'))) {
    buttonClass += size ? yPaddingBySize[size] : ' py-4 ';
  }

  const props = {
    className: buttonClass,
    disabled,
    // pass nothing to handleClick rather than the click event because whatever
    // is passed to handleClick will be passed to this components onClick prop
    onClick: onClick ? () => handleClick() : undefined,
    style: { ...styles.container, ...style, transition: 'all 200ms ease' },
  };

  if (options?.length) {
    return (
      <div className={`group relative ${className}`}>
        <div className="flex">
          <button
            type="button"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={`${props.className} rounded-r-none`}
            data-cy={cypressId}
          >
            {body}
          </button>
          <button
            type="button"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={`${props.className
              .replace(/px-\d/, 'px-1')
              .replace(/py-\d/, '')} rounded-l-none`}
            style={{ ...props.style, marginLeft: 1, height: size ? heightBySize[size] : 52 }}
          >
            <Icon
              className={classNamesByVariant[`text-${variant}` as keyof typeof classNamesByVariant]}
              name="expand_more"
              size={30}
            />
          </button>
        </div>
        <div className="absolute left-0 right-0 z-10 hidden group-hover:block">
          {options.map((option, i) => (
            <div
              className="cursor-pointer border border-grey-medium bg-white p-3"
              key={option.id || (U.isText(option.name) ? option.name?.toString() : i)}
              onClick={() => (option.onClick ? option.onClick() : handleClick(option.id))}
            >
              <Tooltip node={option.tooltip}>
                {U.isText(option.name) ? (
                  <Text color="black" type="button">
                    {option.name}
                  </Text>
                ) : (
                  option.name
                )}
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    );
  }

  props.className += ` relative overflow-hidden ${className}`;

  if (href) {
    props.className += ' hover:no-underline focus:no-underline';

    return (
      <div className="flex">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <a href={href} target={target} {...props}>
          {body}
        </a>
      </div>
    );
  }

  if (tooltip) {
    // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
    return <Tooltip {...tooltip} trigger={<button {...props}>{body}</button>} />;
  }

  return (
    // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
    <button {...props} data-cy={cypressId}>
      {body}
    </button>
  );
}
