import 'rodal/lib/rodal.css';

import React from 'react';
import T from 'prop-types';
import Rodal from 'rodal';

export default class RModal extends React.Component {
  static propTypes = {
    button: T.node,
    modal: T.node,
  };

  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <div>
        <div onClick={this.show}>{this.props.button}</div>

        <Rodal
          visible={this.state.visible}
          onClose={this.hide}
          showMask
          className="rodal_modal"
          closeMaskOnClick={false}
        >
          {this.props.modal}
        </Rodal>
      </div>
    );
  }
}
