import React, { useCallback, useEffect, useState } from 'react';
import { PartVendor } from '@nanaio/util';
import { OpenHours } from '@nanaio/util/dist/partVendor';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Alert, APIError, Avatar, Button,Form, Input, InputType, Text } from '@/components';
import ServerEntityInfo from '@/components/pages/admin/ServerEntityInfo';
import { useLazyLegacyAPI } from '@/hooks';
import Hours from './Hours';

type Props = {
  isEdit?: boolean;
  isInModal?: boolean;
  onChange?: (partVendor: Partial<PartVendor>) => void;
  onRemove?: () => void;
  partVendor: Partial<PartVendor>;
};

export default function Profile({
  isEdit = true,
  isInModal = false,
  onChange,
  onRemove,
  partVendor: initialPartVendor,
}: Props): JSX.Element {
  const [partVendor, setPartVendor] = useState<Partial<PartVendor>>(initialPartVendor);
  partVendor.defaultOpenHours = partVendor.defaultOpenHours || [];
  partVendor.id = partVendor.id || '';
  const [error, setError] = useState<string | null>(null);

  const [updatePartVendor, updatePartVendorResponse] = useLazyLegacyAPI<PartVendor>(
    `partVendors/${partVendor.id}`,
    {
      errorRender: ({ error }) => (
        <APIError
          className="mb-10"
          error={error}
          text={<>Unable to update part vendor. {error}</>}
        />
      ),
      method: 'put',
    }
  );

  const [deletePartVendor, deletePartVendorResponse] = useLazyLegacyAPI<PartVendor>(
    `partVendors/${partVendor.id}`,
    {
      errorRender: ({ error }) => (
        <APIError
          className="mb-10"
          error={error}
          text={<>Unable to delete part vendor. {error}</>}
        />
      ),
      method: 'delete',
    }
  );

  useEffect(() => {
    if (onChange) {
      onChange(partVendor);
    }
  }, [onChange, partVendor]);

  const handleRemove = useCallback(async () => {
    const data = await deletePartVendor();
    if (!data) {
      return;
    }
    if (onRemove) {
      onRemove();
    }
  }, [deletePartVendor, onRemove]);

  const submit = useCallback(async () => {
    if (!partVendor.name) {
      return setError('Name required');
    }
    setError(null);

    const changes = Object.keys(partVendor).map(path => ({
      action: 'replace',
      path,
      value: partVendor[path as keyof PartVendor],
    }));
    const data = await updatePartVendor(changes);
    if (!data) {
      return;
    }
  }, [partVendor, updatePartVendor]);

  const handleDayChange = useCallback(
    (openHours: OpenHours[]) => {
      setPartVendor({
        ...partVendor,
        defaultOpenHours: openHours,
      });
    },
    [partVendor]
  );

  return (
    <div className="p-4">
      {error && (
        <Alert className="mb-10" variant="error">
          {error}
        </Alert>
      )}
      {updatePartVendorResponse.error}
      {deletePartVendorResponse.error}
      <Form
        className="grid grid-cols-1 gap-x-10 gap-y-10 lg:grid-cols-2"
        value={partVendor}
        onChange={setPartVendor}
      >
        <div className="flex flex-row">
          <Avatar iconProps={{ name: 'business', color: 'white' }} size="medium" variant="circle" />
          <div className="flex-grow flex-col">
            <Text type="label">Vendor Details</Text>
            <Text color="grey.dark">Set the business profile</Text>
            <Input id="name" label="Vendor Name" type={InputType.TEXT} className="mt-6" required />
            <Input id="website" label="Website" type={InputType.TEXT} className="mt-6" />
            <Input
              id="accountNumber"
              label="Account Number"
              type={InputType.TEXT}
              className="mt-6"
            />
            <Input
              id="hasBestAvailableOrdering"
              label="Supports Best Available Ordering"
              type={InputType.SWITCH}
            />
          </div>
        </div>

        <div className="flex flex-row">
          <Avatar iconProps={{ name: 'schedule', color: 'white' }} size="medium" variant="circle" />
          <div className="flex-grow flex-col">
            <div className="mb-6">
              <Text type="label">Default Open Hours</Text>
              <Text color="grey.dark">Set the default hours the business is open</Text>
            </div>
            <Hours
              formKey="defaultOpenHours"
              onChange={handleDayChange}
              openHours={partVendor.defaultOpenHours}
            />
          </div>
        </div>

        <div className="flex flex-row">
          <Avatar
            iconProps={{ name: 'truck_fast_outline', color: 'white' }}
            size="medium"
            variant="circle"
          />
          <div className="flex-grow flex-col">
            <Text type="label">Default Return Information</Text>
            <Text color="grey.dark">Set the default return window</Text>
            <div className="flex flex-row items-end">
              <Input
                id="returnWindowDays"
                label="Return Window"
                type={InputType.TEXT}
                className="mb-0 mt-6 grow"
                tooltip="How long will the vendor will accept returns for? (After the order has been received)"
              />
              <Text className="pb-2.5 pl-4">days</Text>
            </div>
          </div>
        </div>

        <div className="flex flex-row">
          <Avatar
            iconProps={{ name: 'package_closed', color: 'white' }}
            size="medium"
            variant="circle"
          />
          <div className="flex-grow flex-col">
            <Text type="label">Default Pickup Settings</Text>
            <Text color="grey.dark">Set the default pickup times and instructions</Text>
            <div className="flex flex-row items-end">
              <Input
                id="pickupOrderPrepMinutes"
                label="Order Prep Time"
                type={InputType.TEXT}
                className="mb-0 mt-6 grow"
                tooltip="How long does it take to prepare an order for pickup? (After order has been placed) This attribute determines when an order is moved to “Ready for Pickup”."
              />
              <Text className="pb-2.5 pl-4">minutes</Text>
            </div>
            <div className="flex flex-row items-end">
              <Input
                id="pickupOrderHoldDays"
                label="Order Hold Time"
                type={InputType.TEXT}
                className="mb-0 mt-6 grow"
                tooltip="How long will the vendor hold onto the order for?  (After the order is ready for pickup)"
              />
              <Text className="pb-2.5 pl-4">days</Text>
            </div>
            <Input
              id="pickupInstructions"
              label="Pickup Instructions"
              multiline
              type={InputType.TEXT}
              className="mt-6"
            />
          </div>
        </div>
      </Form>
      {isEdit && (
        <ServerEntityInfo
          createTime={partVendor.createTime}
          entityName="Part Vendor"
          id={nullthrows(partVendor.id)}
          lastModified={partVendor.lastModified}
          onRemove={handleRemove}
        />
      )}
      {!isInModal && (
        <div>
          <Button className="mt-4" onClick={submit} size="medium">
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
}
