import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Avatar } from '@/components';
import { useLegacySelector } from '@/hooks';
import CustomerInfo from './CustomerInfo';
import MoreActions from './MoreActions';
import PaymentButton from './PaymentButton';
import StatusButton from './StatusButton';
import VendorButton from './VendorButton';
import VisitConfirmStatus from './VisitConfirmStatus';

type Props = {
  taskId: string;
};

export default function Header1({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const orgs = useLegacySelector(state => state.orgs);
  const orgId = T.orgId(task);
  const org = orgId ? orgs[orgId] : undefined;

  const isB2C = T.type(org) === 'b2c';

  return (
    <div className="border-b border-grey-medium bg-white p-4">
      <div className="d-flex justify-content-between align-center">
        <Avatar
          size="xlarge"
          className="bg-background-medium"
          iconProps={
            isB2C ? { name: 'person', color: '#3E8FCF' } : { name: 'business', color: '#7B82D7' }
          }
        />
        <CustomerInfo taskId={taskId} />

        <section className="hidden items-center sm:flex">
          <VisitConfirmStatus taskId={taskId} />

          <StatusButton taskId={taskId} />

          <VendorButton taskId={taskId} />

          <PaymentButton taskId={taskId} />
        </section>

        <MoreActions taskId={taskId} />
      </div>
    </div>
  );
}
