import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import moment from 'moment';
import ConfirmRepairBanner from './ConfirmRepairBanner';

// Fixed date so calendar is always the rendered the same for storybook tests
const CALENDAR_START_DATE = '2021-07-10';

const onClick = () => {
  alert('Button Clicked');
};

export default {
  title: 'Pages/user/FTCBookingFlow/Confirm Repair Banner',
  component: ConfirmRepairBanner,
  args: {
    status: 'confirm',
    dateTime: 1651594085019,
    name: 'John',
    vendorName: 'GE',
    onClick,
    timezone: 'America/Los_Angeles',
  },
  argTypes: {
    status: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof ConfirmRepairBanner>;

const Template: ComponentStory<typeof ConfirmRepairBanner> = args => (
  <ConfirmRepairBanner {...args} />
);

export const Default = Template.bind({});
Default.args = {};

// This isn't just being dry.  There is a known bug in storybook with functions named "add".
// https://github.com/storybookjs/storybook/issues/12208
const hours = 48;

export const Confirm = Template.bind({});
Confirm.args = {
  status: 'confirm',
  dateTime: moment(CALENDAR_START_DATE).add(hours, 'hours'),
  timezone: moment.tz.guess(),
};

export const Continue = Template.bind({});
Continue.args = {
  dateTime: moment(CALENDAR_START_DATE).add(hours, 'hours'),
  timezone: moment.tz.guess(),
  status: 'continue',
};

export const CancelRequested = Template.bind({});
CancelRequested.args = {
  dateTime: moment(CALENDAR_START_DATE).add(hours, 'hours'),
  timezone: moment.tz.guess(),
  status: 'cancelRequested',
};

export const Cancelled = Template.bind({});
Cancelled.args = {
  dateTime: moment(CALENDAR_START_DATE).add(hours, 'hours'),
  timezone: moment.tz.guess(),
  status: 'cancelled',
};

export const Reviewing = Template.bind({});
Reviewing.args = {
  status: 'reviewing',
};
