import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { Alert, Button, Loader, Modal, Text } from '@/components';
import SchedulePicker, { SchedulePickerRef } from '../SchedulePicker';
import type { StepProps as Props } from './ConfirmationFlowModal';
import StepModalHeader from './StepModalHeader';

export default function AppointmentStep({
  currentStep,
  task,
  onNextSubStep,
  onCloseStep,
  onPreviousSubStep,
  subStepNum,
}: Props): JSX.Element {
  const title = 'Request Appointment';
  const ref = useRef<SchedulePickerRef>(null);
  const [error, setError] = useState<ReactNode>();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (ref.current) {
      void ref.current.submit();
    }
  }, [ref]);

  const handleSuccess = useCallback(async () => {
    if (!currentStep.completed) {
      setLoading(true);
      await onNextSubStep();
      setLoading(false);
    } else {
      onCloseStep();
    }
  }, [currentStep, onCloseStep, onNextSubStep]);

  const handleError = (errorMessage: string | undefined) => {
    if (errorMessage === undefined) {
      setError(undefined);
    } else {
      setError(<Alert variant="error">{errorMessage}</Alert>);
    }
  };

  return (
    <>
      <Loader isLoading={loading} fullscreen />
      <StepModalHeader {...{ onPreviousSubStep, title, subStepNum, onCloseStep, currentStep }} />
      <Modal.Body>
        <div className="p-4">
          {!error && (
            <Alert variant="info" icon="event">
              Provide your availability so we can find the best technician available for your job.
              We'll reach out to notify you once your appointment time is confirmed.
            </Alert>
          )}
          {error}
          <Text className="mt-8 text-center" type="headline-6">
            Set Your Availability
          </Text>
          <Text className="mb-8 mt-4 text-center text-grey-dark">
            Choose 2 or more times for your technician to arrive
          </Text>
          <SchedulePicker
            task={task}
            ref={ref}
            onSuccess={handleSuccess}
            onError={handleError}
            variant="confirm"
          />
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={handleClick}>
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
