import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import _ from 'lodash';
import { APIError, Button, Icon, Text } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

interface ErrorProps {
  error: string;
}

const TokenLinkErrorRender = ({ error }: ErrorProps) => (
  <APIError className="mb-10" error={error} text={<>Unable to generate token link. {error}</>} />
);

type GetTokenResponse = {
  token: string;
  tags: string[];
  _id: string;
  userId: string;
  sentTo: string;
  createTime: string;
  __v: number;
  id: string;
};

export default function TokenLink(): JSX.Element {
  const { task } = useLegacySelector(state => {
    const taskId = global.id() || '';
    const task = state.tasks[taskId];
    return {
      task,
    };
  });

  const [tokenCopied, setTokenCopied] = useState(false);
  const [tokenLink, setTokenLink] = useState<string | undefined>();

  const [token, tokenResponse] = useLazyLegacyAPI<GetTokenResponse>('tokens', {
    errorRender: TokenLinkErrorRender,
    method: 'post',
  });

  useEffect(() => {
    if (task?.id) {
      const getToken = async () => {
        const data = await token({
          userId: task.customer.user.id,
          sentTo: task.customer.user.email,
        });
        if (data) {
          const origin = global.location.origin;
          setTokenLink(`${origin}?t=${data.token}&r=%2Ftasks%2F${task.id}`);
        }
      };
      void getToken();
    }
  }, [task?.id, task?.customer.user.id, task?.customer.user.email, token]);

  if (!tokenLink) {
    return <>{tokenResponse.error}</>;
  }

  return (
    <>
      {tokenResponse.error}
      <div className="mb-10">
        <Text type="label">Token Link</Text>

        <CopyToClipboard
          text={tokenLink}
          onCopy={() => {
            setTokenCopied(true);
            setTimeout(() => setTokenCopied(false), 1000);
          }}
        >
          <Button className="flex space-x-2 pl-0 text-left" variant="link" size="small">
            <Text color="primaryCTA">
              View with link sent to customer, {task?.customer.user.fullName}
            </Text>
            <Icon name="content_copy" size={14} />
            {tokenCopied ? <Text type="helper">Copied.</Text> : null}
          </Button>
        </CopyToClipboard>
        <Text type="helper">
          Click to copy, Ctrl+Shift+N to open Incognito Window, paste and go
        </Text>
      </div>
    </>
  );
}
