import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import CheckRadio from './CheckRadio';

export default {
  title: 'Core/CheckRadio',
  component: CheckRadio,
  decorators: [
    Story => (
      <div className="flex">
        <Story />
      </div>
    ),
  ],
} as ComponentMeta<typeof CheckRadio>;

const Template: ComponentStory<typeof CheckRadio> = args => <CheckRadio {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Checked = Template.bind({});
Checked.args = {
  checked: true,
};

export const Disabled = Template.bind({});
Disabled.args = {
  disabled: true,
};
