import React from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import { Input, InputType } from '../../form';

const editUi = ({
  id,
  key,
  label,
}: {
  id?: string;
  key?: number | string;
  label?: string;
}): JSX.Element => <Input id={id} key={key} label={label} type={InputType.BOOL} />;

const filterUi = ({
  onChange,
  value,
}: {
  onChange?: (id: string, value?: boolean) => void;
  value?: boolean;
}): JSX.Element => (
  <Input
    label="Filter"
    onChange={value => onChange && onChange('', value as boolean)}
    placeholder="Show All"
    type={InputType.BOOL}
    value={value}
  />
);

const boolModule: Partial<Table.Column> = { editUi, filterUi };

export default boolModule;
