/* eslint-disable react/no-children-prop */
import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import Badge, { kebabColors, Props } from './Badge';
import Text from './Text';

export default {
  title: 'Core/Badge',
  component: Badge,
  args: {
    onClick: () => alert('onClick'),
    style: {
      fontSize: 14,
    },
    iconProps: { name: 'check_circle_outline' },
    children: 'Badge',
  },
} as ComponentMeta<typeof Badge>;

const Template: ComponentStory<typeof Badge> = args => <Badge {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const AllColorsAllVariants = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <Text className="text-center" type="headline-6">
      All color prop options
    </Text>
    <div className="grid grid-cols-8 gap-x-2 gap-y-6">
      {kebabColors.map(color => (
        <div className="flex flex-col items-center space-y-4 text-center" key={color}>
          <Text type="label">{color}</Text>
          <Default {...args} color={color as Props['color']} variant="solid-faded" />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid-faded"
            iconProps={undefined}
          />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid-faded"
            children={undefined}
          />
          <Default {...args} color={color as Props['color']} variant="solid-faded" float />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid-faded"
            float
            iconProps={undefined}
          />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid-faded"
            float
            children={undefined}
          />
          <Default {...args} color={color as Props['color']} variant="solid" />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid"
            iconProps={undefined}
          />
          <Default {...args} color={color as Props['color']} variant="solid" children={undefined} />
          <Default {...args} color={color as Props['color']} variant="solid" float />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid"
            float
            iconProps={undefined}
          />
          <Default
            {...args}
            color={color as Props['color']}
            variant="solid"
            float
            children={undefined}
          />
          <Default {...args} color={color as Props['color']} variant="outline" />
          <Default
            {...args}
            color={color as Props['color']}
            variant="outline"
            iconProps={undefined}
          />
          <Default
            {...args}
            color={color as Props['color']}
            variant="outline"
            children={undefined}
          />
          <Default {...args} color={color as Props['color']} variant="outline" float />
          <Default
            {...args}
            color={color as Props['color']}
            variant="outline"
            float
            iconProps={undefined}
          />
          <Default
            {...args}
            color={color as Props['color']}
            variant="outline"
            float
            children={undefined}
          />
        </div>
      ))}
    </div>
  </div>
);

// Decorated text will not be reformatted to the badge colors and should be avoided.
export const DecoratedText = Template.bind({});
DecoratedText.args = {
  color: 'success',
  iconProps: { name: 'check_circle_outline' },
  children: (
    <Text className="bg-danger text-primaryCTA" noWrap type="body-2">
      Badge
    </Text>
  ),
};

export const FloatPropOptions = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Float prop options</p>
    <p className="mt-6">Float</p>
    <Default {...args} float />
    <p className="mt-6">No Float</p>
    <Default {...args} />
  </div>
);

export const IconOnly = Template.bind({});
IconOnly.args = {
  iconProps: { name: 'check_circle_outline' },
  children: undefined,
};

// This currently renders nothing.  We could have this display some error state or throw an error.
// Although those seem like severe responses to an undefined badge.
export const NoChildrenNoIcon = Template.bind({});
NoChildrenNoIcon.args = {
  iconProps: undefined,
  children: undefined,
};

export const SizePropOptions = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Size prop options</p>
    <p className="mt-6">small</p>
    <Default {...args} size="small" />
    <p className="mt-6">medium</p>
    <Default {...args} size="medium" />
  </div>
);

export const TextOnly = Template.bind({});
TextOnly.args = { iconProps: undefined, children: 'Badge' };

export const VariantPropOptions = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Variant prop options</p>
    <p className="mt-6">Solid-Faded</p>
    <Default {...args} variant="solid-faded" />
    <p className="mt-6">Outline</p>
    <Default {...args} variant="outline" />
    <p className="mt-6">Solid</p>
    <Default {...args} variant="solid" />
  </div>
);
