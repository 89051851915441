import React from 'react';
import { Icon, Modal } from '@/components';
import type { StepProps } from './ConfirmationFlowModal';

type Props = Pick<StepProps, 'onCloseStep' | 'onPreviousSubStep' | 'subStepNum' | 'currentStep'> & {
  title: string;
};

export default function StepModalHeader({
  onPreviousSubStep,
  subStepNum,
  currentStep,
  title,
  onCloseStep,
}: Props): JSX.Element {
  const subTitleText = `${subStepNum} of ${currentStep.total} steps`;
  return (
    <Modal.Header
      onBack={onPreviousSubStep}
      progress={subStepNum / currentStep.total}
      spacerContentWidth={60}
      subtitle={subTitleText}
      title={title}
      rightContent={
        <span onClick={onCloseStep} className="flex justify-end">
          <Icon
            className="-mr-1 text-grey-dark hover:cursor-pointer hover:text-secondary"
            name="close"
            size={24}
          />
        </span>
      }
    />
  );
}
