import React, { ReactElement } from 'react';
import classNames from 'classnames';
import nullthrows from 'nullthrows';
import Text from '../core/Text';
import type { InputProps } from './Input';
import Radio from './Radio';

export const getProps = ({ onClick, options, value, ...rest }: InputProps): Props => ({
  onClick: nullthrows(onClick),
  options: nullthrows(options) as RadioOption[],
  value: value as number | string,
  ...rest,
});

export type RadioOption = { text: string; value: number | string };

export type Props = {
  direction?: 'column' | 'row';
  disabled?: boolean;
  error?: string;
  onClick: (value: number | string) => void;
  options: RadioOption[];
  size?: 'small' | 'medium' | 'large';
  value?: number | string;
};

export default function RadioGroup({
  direction = 'row',
  disabled,
  error,
  onClick,
  options,
  size = 'medium',
  value,
}: Props): ReactElement {
  return (
    <div
      className={classNames('mt-2', {
        'flex-row': direction === 'row',
        'flex-col': direction === 'column',
      })}
    >
      {options.map((option, index) => {
        const isSelected = option.value === value;

        return (
          <div
            className={classNames('group mr-6 flex flex-row', {
              'mt-3': direction === 'column' && index !== 0 && size === 'small',
              'mt-5': direction === 'column' && index !== 0 && size !== 'small',
            })}
            key={option.value}
            onClick={disabled ? undefined : () => onClick(option.value)}
          >
            <Radio isSelected={isSelected} disabled={disabled} error={error} size={size} />

            <Text
              className={classNames({
                'mt-0.5': size === 'medium',
                'mt-1': size === 'large',
              })}
              color={disabled ? 'grey.dark' : undefined}
              type="body-2"
              tag="p"
            >
              {option.text}
            </Text>
          </div>
        );
      })}
    </div>
  );
}
