import React, { useEffect, useMemo,useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDeepCompareEffect } from '@nanaio/hooks';
import { incentive, U } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import { APIError,Confirm, Form } from '@/components';
import { useLegacyAPI } from '@/hooks';
import Loader from '../../../com/ui/loader';
import BasicInformation from './com/BasicInformation';
import FiltersPanel from './com/FiltersPanel';
import Header from './com/Header';
import JobRulesetPanel from './com/JobRulesetPanel';
import PaymentPanel from './com/PaymentPanel';
import * as validators from './validators';

const EMPTY_ALERT_OBJECT = { type: '', message: '' };
const FORM_INITIAL_VALUES = {
  isActive: true,
  name: '',
  startDate: undefined,
  endDate: undefined,
  filtersConnector: undefined,
  marketRule: { condition: undefined, values: [] },
  proRule: { condition: undefined, values: [] },
  jobsCompleted: '',
  payment: '',
};

const validateForm = values => {
  const startDateValidation = validators.validateStartDate(values.startDate);
  const endDateValidation = validators.validateEndDate(values.endDate);

  const errors = {
    name: validators.validateName(values.name),
    startDate: startDateValidation,
    endDate: endDateValidation,
    jobsCompleted: validators.validateJobsCompleted(values.jobsCompleted),
    payment: validators.validatePayment(values.payment),
    filtersConnector: validators.validateFiltersConnector(
      values.marketRule,
      values.proRule,
      values.filtersConnector
    ),
    marketRuleCondition: validators.validateFilterRuleCondition(values.marketRule),
    marketRuleValues: validators.validateFilterRuleValues(values.marketRule, 'market'),
    proRuleCondition: validators.validateFilterRuleCondition(values.proRule),
    proRuleValues: validators.validateFilterRuleValues(values.proRule, 'technician'),
  };

  if (!startDateValidation && !endDateValidation) {
    errors.endDate = validators.validateEndDateIsAfterStartDate(values.startDate, values.endDate);
  }

  return errors;
};

const formHasErrors = errors => Object.values(errors).some(error => error !== '');

function IncentiveForm({ regions = [] }) {
  const {
    loading: loadingPros,
    error: loadingProsError,
    data: pros,
  } = useLegacyAPI('pros/search', {
    body: { query: { status: { $nin: [U.pro.Status.APPLICANT] } }, limit: -1 },
    method: 'post',
  });

  const currentIncentiveID = useRef(global.id());
  const isReady = useRef(false);
  const sortedRegions = useMemo(() => {
    if (_.isEmpty(regions)) {
      return [];
    }

    return _.orderBy(Object.values(regions), [region => region.name.toLowerCase()], ['asc']);
  }, [regions]);
  const sortedPros = useMemo(() => {
    if (_.isEmpty(pros)) {
      return [];
    }

    return _.orderBy(pros, [pro => pro.user.fullName.toLowerCase()], ['asc']).map(pro => ({
      id: pro.id,
      name: pro.user.fullName,
    }));
  }, [pros]);

  const [loading, setLoading] = useState(Boolean(currentIncentiveID.current) || loadingPros);
  const [form, setForm] = useState(_.cloneDeep(FORM_INITIAL_VALUES));
  const [formInitialValues, setFormInitialValues] = useState(_.cloneDeep(FORM_INITIAL_VALUES));
  const [errors, setErrors] = useState({
    ..._.omit(
      _.mapValues(FORM_INITIAL_VALUES, () => ''),
      ['marketRule', 'proRule']
    ),
    filtersConnector: '',
    marketRuleCondition: '',
    marketRuleValues: '',
    proRuleCondition: '',
    proRuleValues: '',
  });

  const [confirmOpened, setConfirmOpened] = useState(false);
  const [alert, setAlert] = useState({ ...EMPTY_ALERT_OBJECT });
  const [disableControls, setDisableControls] = useState(false);

  const formDidChange = () => _.isEqual(form, formInitialValues);

  const resetAlert = () => setAlert({ ...EMPTY_ALERT_OBJECT });

  const handleSaveClick = async () => {
    const errors = validateForm(form);
    const hasErrors = formHasErrors(errors);

    setErrors(errors);

    if (!hasErrors) {
      resetAlert();

      const data = {
        name: form.name.trim(),
        isActive: form.isActive,
        startDate: moment(form.startDate).format('YYYY-MM-DD'),
        endDate: moment(form.endDate).format('YYYY-MM-DD'),
        payment: form.payment,
        jobRuleset: [
          {
            condition: 'HAS_COMPLETED',
            values: [form.jobsCompleted],
          },
        ],
        // FIXME @syntaxbliss
        // For phase 1 we're not going to use this field, but we still need to send it the server for
        // validation purposes.
        timeRuleset: [
          {
            condition: 'BY',
            values: [moment(form.endDate).format('YYYY-MM-DD')],
          },
        ],
      };

      const hasMarketRuleset = Boolean(form.marketRule.condition && form.marketRule.values.length);
      const hasProRuleset = Boolean(form.proRule.condition && form.proRule.values.length);

      if (hasMarketRuleset) {
        data.marketRuleset = [{ ...form.marketRule }];
      }

      if (hasProRuleset) {
        data.proRuleset = [{ ...form.proRule }];
      }

      if (hasMarketRuleset && hasProRuleset) {
        data.filtersConnector = form.filtersConnector;
      }

      let response;

      if (currentIncentiveID.current) {
        response = await U.api('put', `incentive/${currentIncentiveID.current}`, data);
      } else {
        response = await U.api('post', 'incentive', data);
      }

      if (response?.errMsg) {
        setAlert({ type: 'error', message: response.errMsg });
      } else {
        currentIncentiveID.current = response.id;

        setAlert({ type: 'success', message: 'Saved' });
        setDisableControls(!response.isEditable);
        setFormInitialValues(form);
      }
    }
  };

  const handleCancelClick = () => {
    if (formDidChange()) {
      window.close();
    } else {
      setConfirmOpened(true);
    }
  };

  const handleStatusSwitchClick = async isActive => {
    setForm({ ...form, isActive });

    if (disableControls) {
      const response = await U.api('put', `incentive/${currentIncentiveID.current}/status`, {
        isActive,
      });

      if (response?.errMsg) {
        setAlert({ type: 'error', message: response.errMsg });
      } else {
        setAlert({ type: 'success', message: 'Saved' });
      }
    }
  };

  // Name validation
  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, name: validators.validateName(form.name) }));
    }
  }, [form.name]);

  // Start date validation
  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, startDate: validators.validateStartDate(form.startDate) }));
    }
  }, [form.startDate]);

  // End date validation
  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, endDate: validators.validateEndDate(form.endDate) }));
    }
  }, [form.endDate]);

  // End date prior to start date validation
  useEffect(() => {
    if (isReady.current && form.startDate && form.endDate) {
      const startDateIsValid = !validators.validateStartDate(form.startDate).length;
      const endDateIsValid = !validators.validateEndDate(form.endDate).length;

      if (startDateIsValid && endDateIsValid) {
        setErrors(errors => ({
          ...errors,
          endDate: validators.validateEndDateIsAfterStartDate(form.startDate, form.endDate),
        }));
      }
    }
  }, [form.startDate, form.endDate]);

  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        filtersConnector: validators.validateFiltersConnector(
          form.marketRule,
          form.proRule,
          form.filtersConnector
        ),
      }));
    }
  }, [form.marketRule, form.proRule, form.filtersConnector]);

  // Market rule condition validation
  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        marketRuleCondition: validators.validateFilterRuleCondition(form.marketRule),
      }));
    }
  }, [form.marketRule]);

  // Market rule values validation
  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        marketRuleValues: validators.validateFilterRuleValues(form.marketRule, 'market'),
      }));
    }
  }, [form.marketRule]);

  // Pro rule condition validation
  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        proRuleCondition: validators.validateFilterRuleCondition(form.proRule),
      }));
    }
  }, [form.proRule]);

  // Pro rule values validation
  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        proRuleValues: validators.validateFilterRuleValues(form.proRule, 'technician'),
      }));
    }
  }, [form.proRule]);

  // Jobs completed validation
  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        jobsCompleted: validators.validateJobsCompleted(form.jobsCompleted),
      }));
    }
  }, [form.jobsCompleted]);

  // Payment validation
  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, payment: validators.validatePayment(form.payment) }));
    }
  }, [form.payment]);

  // Each field in this form is validated in real-time (i.e.: the validation will be fired as soon as the user makes a
  // change to the field). The "mounted" pattern aims to avoid the validations from being fired before the user makes
  // any change.
  useEffect(() => {
    document.title = `${currentIncentiveID.current ? 'Edit' : 'New'} incentive`;

    if (!isReady.current) {
      isReady.current = true;
    }

    if (currentIncentiveID.current) {
      U.api('get', `incentive/${currentIncentiveID.current}`).then(response => {
        if (response?.errMsg) {
          setAlert({ type: 'error', message: response.errMsg });
        } else {
          const formValues = {
            isActive: response.isActive,
            name: response.name,
            startDate: moment(response.startDate.substr(0, 10)).toDate(),
            endDate: incentive.convertIncentiveEndDateToDisplayDate(response.endDate),
            filtersConnector: response.filtersConnector,
            marketRule: response.marketRuleset?.[0] || { condition: undefined, values: [] },
            proRule: response.proRuleset?.[0] || { condition: undefined, values: [] },
            jobsCompleted: response.jobRuleset[0].values[0],
            payment: response.payment,
          };

          setForm(formValues);
          setFormInitialValues(formValues);
          setDisableControls(!response.isEditable);
        }

        setLoading(false);
      });
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (loadingProsError) {
    return <APIError error={loadingProsError} />;
  }

  return (
    <Form onChange={setForm} value={form} className="min-h-screen bg-background-medium">
      <Confirm
        isOpen={confirmOpened}
        onCancel={() => setConfirmOpened(false)}
        onConfirm={() => window.close()}
      >
        You have unsaved changes. Do you want to cancel the edit?
      </Confirm>

      <div className="bg-white py-8">
        <div className="container">
          <Header
            alert={alert}
            disableSaveButton={formDidChange() || disableControls}
            isActiveSwitchValue={form.isActive}
            onCancelClick={handleCancelClick}
            onIsActiveSwitchChange={handleStatusSwitchClick}
            onSaveClick={handleSaveClick}
            resetAlert={resetAlert}
          />
        </div>

        <div className="container mt-4 border-t border-grey-medium">
          <BasicInformation
            disableControls={disableControls}
            errors={_.pick(errors, ['name', 'startDate', 'endDate'])}
          />
        </div>
      </div>

      <div className="py-8">
        <div className="container">
          <FiltersPanel
            disableControls={disableControls}
            errors={_.pick(errors, [
              'filtersConnector',
              'marketRuleCondition',
              'marketRuleValues',
              'proRuleCondition',
              'proRuleValues',
            ])}
            pros={sortedPros}
            regions={sortedRegions}
          />

          <JobRulesetPanel
            disableControls={disableControls}
            errors={_.pick(errors, ['jobsCompleted'])}
          />

          <PaymentPanel disableControls={disableControls} errors={_.pick(errors, ['payment'])} />
        </div>
      </div>
    </Form>
  );
}

export default connect(state => {
  const { regions } = state;

  return { regions };
})(IncentiveForm);
