import React from 'react';
import * as Table from '@nanaio/table';
import { Icon, Table as TableComponent } from '@/components';

export default function InventoryTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.inventory} // eslint-disable-line react/jsx-props-no-spreading
      additionalButtonsUi={
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/inventory/override"
          className="ui-table-btn primary d-flex align-items-center mr-2"
        >
          <div className="d-inline-flex align-items-center mr-2">
            <Icon color="#fff" name="publish" size={18} />
          </div>
          <p>Override</p>
        </a>
      }
    />
  );
}
