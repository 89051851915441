import React from 'react';
import { PricingPackage,Task } from '@nanaio/util';
import { useLegacyAPI } from '@/hooks';

type Props = {
  task: Task;
};

export default function PricingPackageDetails({ task }: Props): JSX.Element {
  const id = task.pricingPackageId;
  const { data } = useLegacyAPI<PricingPackage>(`pricing/${id}`, {
    body: { limit: -1 },
    method: 'get',
  });

  if (!data) {
    return <></>;
  }

  return (
    <div className="border-bottom py-4">
      <p className="weight-600">Pricing Package Details</p>
      <div className="d-flex justify-content-between py-2">
        <label>Name:</label>
        <div>{data.name}</div>
      </div>
    </div>
  );
}
