import React, { useCallback } from 'react';
import ModalContent from '../TermsAndConditions/ModalContent';
import type { SubStepProps as Props } from './ConfirmationFlowModal';

export default function TermsFormStep({ onNextSubStep }: Props): JSX.Element {
  const handleNext = useCallback(async () => {
    await onNextSubStep();
  }, [onNextSubStep]);

  return (
    <>
      <ModalContent onClose={handleNext} />
    </>
  );
}
