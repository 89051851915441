import React from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Alert, Text } from '@/components';
import { useLegacySelector } from '@/hooks';

export default function BonusAlerts(): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const taskId = nullthrows(global.id());
  const task = tasks[taskId];

  if (!task.flexibleBonus) {
    return <></>;
  }

  if (
    [T.FlexibleBonusStatus.APPLIED, T.FlexibleBonusStatus.BOOSTED].includes(
      task.flexibleBonus.status
    )
  ) {
    return (
      <Alert className="mb-5" variant="success">
        <Text type="page-title" className="mb-2 flex">
          {(task.flexibleBonus.status as string) === T.FlexibleBonusStatus.BOOSTED
            ? 'Boost and '
            : ''}{' '}
          Bonus Automatically Applied on {m(task.flexibleBonus.appliedAt).format('l')}
        </Text>

        <Text color="grey.dark">You cannot edit or remove this bonus at this time.</Text>
      </Alert>
    );
  }

  if (task.flexibleBonus.status === T.FlexibleBonusStatus.IGNORED) {
    return (
      <Alert className="mb-5" variant="warning">
        <Text type="page-title" className="mb-2 flex">
          Not Eligible For Bonus
        </Text>

        <Text color="grey.dark">You cannot apply a bonus to this job at this time.</Text>
      </Alert>
    );
  }

  return <></>;
}
