import React, { memo, useRef } from 'react';
import { Text } from '@/components';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function Vendors() {
  const logos = useRef([
    'general-electric',
    'cinch',
    'fahw',
    'miele',
    'samsung',
    'assurant',
  ]).current;
  const [, breakpointSize] = useBreakpoints();

  return (
    <div className="apply-vendors apply-container items-center justify-between px-4 py-12 lg:flex lg:py-8">
      <Text
        type={breakpointSize >= Breakpoints.LG ? 'subtitle-2' : 'headline-6'}
        color="grey.dark"
        className="apply-vendors__text text-center lg:text-left"
      >
        Choose from thousands of jobs across 56+ leading companies.
      </Text>

      <div className="mt-4 grid grid-cols-3 gap-4 lg:mt-0 lg:grid-cols-6">
        {logos.map(logo => (
          <div key={logo} className="m-auto flex h-16 p-2">
            <img
              alt={logo}
              src={`/img/apply/vendors-${logo}.png`}
              className="h-full object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(Vendors);
