import React, { useEffect, useState } from 'react';
import { Text } from '@/components';
import { companyLogoWhite, companyNameFullLegal, social, supportUrl } from '../../config/const';
import Link from './link';

type Appliance = {
  src: string;
  name: string;
  link: string;
};
const appliancesOne: Appliance[] = [
  {
    src: '/img/footer/refrigerator.svg',
    name: 'Refrigerator',
    link: 'services/refrigerator',
  },
  {
    src: '/img/footer/washer.svg',
    name: 'Washer',
    link: 'services/washer',
  },
  {
    src: '/img/footer/dryer.svg',
    name: 'Dryer',
    link: 'services/dryer',
  },
];

const appliancesTwo: Appliance[] = [
  {
    src: '/img/footer/dishwasher.svg',
    name: 'Dishwasher',
    link: 'services/dishwasher',
  },
  {
    src: '/img/footer/oven.svg',
    name: 'Oven',
    link: 'services/oven',
  },
  {
    src: '/img/footer/stove.svg',
    name: 'Stove',
    link: 'services/stove',
  },
];

const connectData = [
  {
    to: '/login',
    name: 'Login/Sign Up',
    shortName: 'login',
  },
  {
    to: '/academy',
    name: 'Appliance Classes',
    shortName: 'academy',
  },
  {
    to: '/apply',
    name: 'Become a Pro',
    shortName: 'BecomeaPro',
  },
  {
    to: '/contact',
    name: 'Contact Us',
    shortName: 'contact',
  },
];

export default function Footer(): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkWidth = () => setIsMobile(window.innerWidth < 480);

    checkWidth();
    window.addEventListener('resize', checkWidth, false);

    return () => {
      window.removeEventListener('resize', checkWidth, false);
    };
  }, []);

  const logo = (
    <div className="mt-1 flex lg:mr-14">
      <Link to="/" name={isMobile ? 'footer-home-mobile' : 'footer-home-desktop'}>
        <img alt="logo" src={companyLogoWhite} height={16} width={100} />
      </Link>
    </div>
  );

  const about = (
    <div className="flex flex-col items-center">
      <div className="flex flex-wrap items-center">
        <Text className="order-0 w-1/2 pb-3 text-xl font-semibold text-white ">About</Text>
        <div className="w-1/2" />
        <div className="order-1 mb-1 w-1/2 flex-none lg:w-1/4">
          <Link
            to="/history"
            className="no-underline"
            name={isMobile ? 'footer-story-mobile' : 'footer-story-desktop'}
          >
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Our Story</Text>
          </Link>
        </div>
        <div className="order-3 mb-1 w-1/2 flex-none lg:order-4 lg:w-1/4">
          <Link
            to="/cities"
            className="no-underline"
            name={isMobile ? 'footer-cities-mobile' : 'footer-cities-desktop'}
          >
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Cities</Text>
          </Link>
        </div>
        <div className="order-5 mb-1 w-1/2 flex-none lg:order-2 lg:w-1/4">
          <Link
            to={supportUrl}
            className="no-underline"
            name={isMobile ? 'footer-faq-mobile' : 'footer-faq-desktop'}
          >
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Support</Text>
          </Link>
        </div>
        <div className="order-6 mb-1 w-1/2 flex-none lg:order-5 lg:w-1/4">
          <Link to={window.location.origin + '/careers'} className="no-underline">
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Careers</Text>
          </Link>
        </div>
        <div className="lg:order:3 order-2 mb-1 w-1/2 flex-none lg:w-1/2">
          <Link
            to="/terms"
            className="no-underline"
            name={isMobile ? 'footer-terms-mobile' : 'footer-terms-desktop'}
          >
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Terms and Conditions</Text>
          </Link>
        </div>
        <div className="order-4 mb-1 w-1/2 flex-none md:order-6 lg:w-1/2">
          <Link
            to="/privacy"
            className="no-underline"
            name={isMobile ? 'footer-privacy-mobile' : 'footer-privacy-desktop'}
          >
            <Text className="text-secondary-4 hover:text-primaryCTA-6">Privacy</Text>
          </Link>
        </div>
      </div>
    </div>
  );
  const connect = (
    <div className="flex flex-col">
      <div className="flex flex-wrap">
        <Text className="order-0 w-1/2 pb-3 text-xl font-semibold text-white ">Connect</Text>
        <div className="w-1/2" />
        {connectData.map(item => (
          <div key={item.shortName} className="mb-1 w-1/2 flex-none">
            <Link
              to={item.to}
              className="no-underline hover:text-primaryCTA-6"
              name={
                isMobile ? `footer-${item.shortName}-mobile` : `footer-${item.shortName}-desktop`
              }
            >
              <Text className="text-secondary-4 hover:text-primaryCTA-6">{item.name}</Text>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );

  const socialSection = (
    <div className="mt-12 flex pt-0.5 lg:ml-4">
      <div className="mr-2">
        <a href={social.instagram} target="_blank" rel="noopener noreferrer" className="group mr-2">
          <img
            className="block h-6 group-hover:hidden"
            alt="instagram"
            src="/img/footer/instagram.svg"
            height={24}
            width={24}
          />
          <img
            className="hidden h-6 group-hover:block"
            alt="instagram"
            src="/img/footer/instagramHover.svg"
            height={24}
            width={24}
          />
        </a>
      </div>
      <div className="mr-2">
        <a href={social.linkedIn} target="_blank" rel="noopener noreferrer" className="group mr-2">
          <img
            className="block h-6 group-hover:hidden"
            alt="linkedin"
            src="/img/footer/linkedin.svg"
            height={24}
            width={24}
          />
          <img
            className="hidden h-6 group-hover:block"
            alt="linkedin"
            src="/img/footer/linkedinHover.svg"
            height={24}
            width={24}
          />
        </a>
      </div>
      <div className="mr-1">
        <a href={social.twitter} target="_blank" rel="noopener noreferrer" className="group mr-2">
          <img
            className="block h-6 group-hover:hidden"
            alt="twitter"
            src="/img/footer/twitter.svg"
            height={24}
            width={24}
          />
          <img
            className="hidden h-6 group-hover:block"
            alt="twitter"
            src="/img/footer/twitterHover.svg"
            height={24}
            width={24}
          />
        </a>
      </div>
      <div>
        <a href={social.facebook} target="_blank" rel="noopener noreferrer" className="group mr-2">
          <img
            className="block h-6 group-hover:hidden"
            alt="facebook"
            src="/img/footer/facebook.svg"
            height={24}
            width={24}
          />
          <img
            className="hidden h-6 group-hover:block"
            alt="facebook"
            src="/img/footer/facebookHover.svg"
            height={24}
            width={24}
          />
        </a>
      </div>
    </div>
  );

  const services = (appliances: Appliance[]) => (
    <div className="flex h-40 items-start justify-center">
      {appliances.map(item => (
        <Link
          key={item.name}
          to={item.link}
          className="w-28 text-center no-underline sm:w-32"
          name={
            isMobile
              ? `footer-services-${item.name}-mobile`
              : `footer-services-${item.name}-desktop`
          }
        >
          <div className="group">
            <img
              className="absolute z-10 my-2 ml-4 scale-50 transform transition duration-300 ease-in-out group-hover:scale-100 sm:ml-6"
              src="/img/footer/highlight.svg"
              alt=""
            />
            <img className="absolute z-20 my-2 ml-4 sm:ml-6" src={item.src} alt={item.name} />

            <Text className="mb-1 pt-24 text-white transition duration-300 ease-in-out group-hover:text-primaryCTA-6">
              {item.name}
            </Text>
            <Text className="rounded-xl bg-primaryCTA-6 text-white opacity-0 transition duration-300 ease-in-out group-hover:opacity-100">
              Order Repair
            </Text>
          </div>
        </Link>
      ))}
    </div>
  );

  return (
    <section className="bg-secondary">
      <div className="mx-auto max-w-1200 p-0 pt-4 md:p-8">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <div>{services(appliancesOne)}</div>
          <div>{services(appliancesTwo)}</div>
        </div>
        <hr className="mx-8 mb-6 bg-secondary-5 lg:mx-0" />
        <div className="flex flex-col items-center lg:flex-row lg:items-start lg:gap-4">
          <div className="order-last lg:order-first">{logo}</div>
          <div className="w-80 px-2 pb-8 sm:w-96 lg:px-0 lg:pb-0">{about}</div>
          <div className="w-80 px-2 sm:w-96 lg:px-0">{connect}</div>
          <div className="">{socialSection}</div>
        </div>
        <div className="mt-8">
          <Text className="text-center text-sm text-secondary-4 lg:text-left">
            ©Copyright {new Date().getFullYear()} {companyNameFullLegal} All rights reserved.
          </Text>
        </div>
      </div>
    </section>
  );
}
