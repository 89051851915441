import React from 'react';
import { Col,Row } from 'reactstrap';
import { Icon } from '@/components';
import { appName, companyName } from '@/config/const';
import Link from '../../com/ui/link';

export default () => (
  <div id="ApplicationTracker2">
    <div className="container">
      <h1 className="animated fadeIn">Welcome to {companyName}</h1>
      <br />
      <br />
      <Row>
        <Col>
          <div className="applicationTimeline">
            <div className="timelineContainer animated fadeIn">
              <div className="applicationStep active flex-row">
                <div>
                  <div className="tracker-icon">
                    <Icon name="check" size={16} />
                  </div>
                </div>

                <div className="flex-1">
                  <div className="tracker-text">
                    <h4 className="sub-heading-3">Applied</h4>
                    <p className="sub-text-2" />
                  </div>
                </div>
              </div>

              <div className="applicationStep active flex-row">
                <div>
                  <div className="tracker-icon">
                    <Icon name="search" size={16} />
                  </div>
                </div>

                <div className="flex-1">
                  <div className="tracker-text">
                    <h4 className="sub-heading-3">Background Screening</h4>
                    <p className="sub-text-2">
                      Your application has received views and interactions.{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className="applicationStep active success flex-row">
                <div>
                  <div className="tracker-icon">
                    <Icon name="email" size={16} />
                  </div>
                </div>

                <div className="flex-1">
                  <div className="tracker-text">
                    <h4 className="sub-heading-3">Results</h4>
                    <p className="sub-text-2 result-text">
                      Congratulations! 🎉 You have been accepted as a {companyName} Technician! So
                      what&apos;s next? You&apos;ll receive an email with instructions on how to
                      download the {appName} soon. After that, you can check the app for listings in
                      your area and accept your first job!
                      <br />
                      <br />
                      Best, {companyName}
                    </p>
                  </div>
                </div>
              </div>

              <div className="applicationLine" />
            </div>
          </div>
        </Col>
        <Col md="7" sm="12" xs="12">
          <div className="infocards">
            <div className="card card-block animated fadeInUp">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Profile</p>
                </Col>
                <Col className="text-right">
                  <Link to="/profile" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-1">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Skills</p>
                </Col>
                <Col className="text-right">
                  <Link to="/skills" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-2">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Service Area</p>
                </Col>
                <Col className="text-right">
                  <Link to="/service-area" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-3">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Application Info</p>
                </Col>
                <Col className="text-right">
                  <Link to="/application-info" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
