import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { updateTask } from '../../com/task';
import { Bool, Modal, Search, Select, Text, Textbox } from '../../com/ui/form';

class EscalateLink extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.loadUsers = this.loadUsers.bind(this);
    this.openEscalate = this.openEscalate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async loadUsers() {
    const query = { roles: U.user.Role.CUSTOMERSUPPORT };
    let users = await U.api('post', 'users/search', { query });
    users = users.map(u => ({ ...u, name: _.get(u, 'profile.fullName', '?') }));
    users = _.sortBy(users, 'name');
    this.setState({ users });
  }

  openEscalate() {
    this.setState({
      isEscalation: !this.props.isEscalation,
      type: _.get(this.props.job, 'metadata.escalationType'),
      level: _.get(this.props.job, 'metadata.escalationLevel', '1'),
      createTicket: !this.props.isEscalation,
      error: '',
      title: '',
      desc: '',
      owner: '',
      modal: true,
    });
    this.loadUsers();
  }

  async onSubmit() {
    if (!this.state.type) {
      return this.setState({ error: 'Type required' });
    }
    if (this.state.createTicket) {
      if (!this.state.title) {
        return this.setState({ error: 'Title required' });
      }
      if (!this.state.desc) {
        return this.setState({ error: 'Description required' });
      }
      const ticket = {
        title: this.state.title,
        desc: this.state.desc,
        refType: 'task',
        refId: this.props.job.id,
      };
      if (this.state.owner) {
        ticket.owner = this.state.users.find(u => u.id === this.state.owner);
      }
      U.api('post', 'issues', ticket, true);
    }
    const tags = _.values(this.props.job.tags).filter(t => t !== 'escalate');
    if (this.state.isEscalation) {
      tags.push('escalate');
    }
    const content = `${`ESCALATION ${this.state.isEscalation ? 'OPEN' : 'CLOSE'} - ${
      this.state.type
    }`}${this.state.title ? ` - ${this.state.title}` : ''}${
      this.state.desc ? ` - ${this.state.desc}` : ''
    }`;
    const changes = [
      { action: 'replace', path: 'tags', value: tags },
      { action: 'add', path: 'notes', value: { content } },
      {
        action: 'replace',
        path: 'metadata.escalationType',
        value: this.state.type,
      },
    ];
    if (this.state.type === 'customerService') {
      changes.push({
        action: 'replace',
        path: 'metadata.escalationLevel',
        value: this.state.level,
      });
    }
    const r = await updateTask(this.props.job.id, changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.setState({ modal: false });
  }

  render() {
    const levelOptions = [
      { id: '1', name: '1 (Moderate)' },
      { id: '2', name: '2 (Severe)' },
    ];
    return (
      <div>
        <div>
          <div onClick={this.openEscalate}>
            {this.props.isEscalation ? (
              <div
                className="d-flex align-center justify-content-between"
                style={{ color: '#B83A3A' }}
              >
                De-Escalate <Icon name="warning" color="#B83A3A" size={15} />
              </div>
            ) : (
              <div className="d-flex align-center justify-content-between">
                Escalate <Icon name="warning" color="#A1A4AB" size={15} />
              </div>
            )}
          </div>
        </div>
        <Modal title="Escalate" submit={this.onSubmit}>
          <div className="text-danger">{this.state.error}</div>
          <Bool id="isEscalation" />
          {this.state.isEscalation && (
            <div>
              <Select id="type" options={['customerService', 'damage', 'refund']} cap req />
              {this.state.type === 'customerService' && (
                <Select id="level" options={levelOptions} />
              )}
              <Bool id="createTicket" />
              {this.state.createTicket && (
                <div>
                  <Text id="title" />
                  <Textbox id="desc" label="Description" />
                  <Search id="owner" label="Owner" options={this.state.users} />
                </div>
              )}
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const isEscalation = _.includes(job.tags, 'escalate');
  return { job, isEscalation };
})(EscalateLink);
