import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import U from '@nanaio/util';
import classNames from 'classnames';
import Text from './Text';

type Variant = 'primary' | 'secondary';

const classNamesByVariant = {
  primary: 'bg-primaryCTA-3 ring-primaryCTA',
  secondary: 'ring-grey-medium',
  'text-primary': 'text-primaryCTA',
  'text-secondary': 'text-secondary',
};

export type Props = {
  children: ReactNode;
  cypressId?: string;
  variant?: Variant;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  className?: string;
};

export default function Chip({
  children,
  variant = 'primary',
  cypressId,
  disabled,
  onClick,
  className = '',
}: Props): ReactElement {
  return (
    <div
      data-cy={cypressId}
      className={classNames(
        'rounded  px-1 py-2 text-center ring-1',
        { 'cursor-pointer': !!onClick },
        disabled ? 'bg-grey-light ring-grey-medium' : classNamesByVariant[variant || ''],
        className
      )}
      onClick={onClick}
    >
      {U.isText(children) ? (
        <Text
          noWrap
          type="button"
          className={
            disabled ? 'text-grey-dark' : classNamesByVariant[`text-${variant}`] || 'text-primary'
          }
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
}
