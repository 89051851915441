import React from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Icon } from '@/components';

export default [
  {
    name: 'Type',
    width: 175,
    renderCell: ({ blockingFlags, job }) => {
      if (job.type === 'flag') {
        return (
          <div style={{ whiteSpace: 'normal' }}>
            {T.proFlags(job).map((flag, i) => {
              let className = '';
              if (
                blockingFlags.find(
                  blockingFlag => blockingFlag.task === job.id && blockingFlag.flag === flag.name
                )
              ) {
                className = 'text-danger';
              }
              return (
                <div key={i} className={className}>
                  {flag.name}
                </div>
              );
            })}
          </div>
        );
      }
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => <div key={job.id}>{_.startCase(job.type)}</div>);
    },
  },
  {
    name: 'Time',
    width: 280,
    renderCell: ({ job }) => {
      if (['flag', 'needsFollowup'].includes(job.type)) {
        return mt(T.lastVisitStart(job)).tz(job.serviceAddress.gTimezone).format('ddd, M/D/YY');
      }
      const timezone = job.timezone || _.get(job, 'jobs.0.tasks.0.serviceAddress.gTimezone');
      return _.map(job.availability, ({ date, isPast, slots }) => (
        <div key={date} className="d-flex">
          <span style={{ width: '100px' }} className={isPast ? 'text-danger' : ''}>
            {mt(date).tz(timezone).format('ddd, M/D/YY')}
          </span>
          {slots.map(s => {
            if (!s.cxAvailable) {
              return <div style={{ width: '60px', background: U.color.grey }} />;
            }
            const color = s.proAvailable ? U.color.green : U.color.blue;
            return (
              <div
                key={s.startTime}
                style={{ width: '60px', textAlign: 'center', background: color, color: 'white' }}
              >
                {s.preferred && <Icon name="star" size={16} />}
                {s.timeString}
              </div>
            );
          })}
        </div>
      ));
    },
  },
  {
    name: 'Title',
    width: 200,
    renderCell: ({ job }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return _.flatten(group.map(g => g.tasks || g)).map(job => {
        const service = _.replace(job.serviceCatalogs[0].name, ' Repair', '');
        const brand = _.get(job, 'metadata.taskQuestions.Make');
        return <div key={job.id}>{(brand ? `${brand} ` : '') + service}</div>;
      });
    },
  },
  {
    name: 'Customer',
    width: 200,
    renderCell: ({ job }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => (
        <div key={job.id}>{U.titleCase(_.get(job, 'customer.user.fullName'))}</div>
      ));
    },
  },
  {
    name: 'Location',
    width: 200,
    renderCell: ({ job }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => (
        <div key={job.id}>
          {job.serviceAddress.locality}, {job.serviceAddress.region}
        </div>
      ));
    },
  },
  {
    name: 'Home Dist',
    width: 50,
    renderCell: ({ job, pro }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => (
        <div key={job.id}>
          {_.round(
            U.distance(
              _.get(job, 'serviceAddress.geoCoordinates'),
              _.get(pro, 'user.address.geoCoordinates')
            )
          )}
        </div>
      ));
    },
  },
  {
    name: 'Labor',
    width: 75,
    getValue: ({ job }) => U.toMoney(job.basePay),
  },
  {
    name: 'Bonus',
    width: 75,
    getValue: ({ job }) => U.toMoney(job.bonusPay),
  },
  {
    name: 'Total',
    width: 75,
    getValue: ({ job }) => U.toMoney(job.totalPay),
  },
  {
    name: 'Vendor',
    width: 150,
    getValue: ({ job }) => _.get(job, 'customer.org.name'),
  },
  {
    name: 'Duration',
    width: 75,
    renderCell: ({ job }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => <div key={job.id}>{job.duration}</div>);
    },
  },
  {
    name: 'Tags',
    width: 300,
    renderCell: ({ job }) => {
      const group = job.type === 'route' ? job.jobs : [job];
      return group.map(job => <div key={job.id}>{_.reduce(job.tags, (a, b) => `${a}, ${b}`)}</div>);
    },
  },
];
