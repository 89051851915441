import '../../style/config/pro';

import PageProAvailability from '@/pages/pro/availability';
import PageProLinkBank from '@/pages/pro/linkBank';
import PageProServiceArea from '@/pages/pro/serviceArea';
import PageProSkills from '@/pages/pro/skills';
import PageUserProfile from '@/pages/user/profile';
import ComUiUserLayout from '../../com/ui/CxAndProLayout';
import TaskLayout from '../../task/layout';
import TaskList from '../../task/list';
import publicRoutes from './public';

const proRoutes = {
  '': { body: TaskList, layout: ComUiUserLayout },
  availability: { body: PageProAvailability, layout: ComUiUserLayout },
  linkBank: { body: PageProLinkBank, layout: ComUiUserLayout },
  profile: { body: PageUserProfile, layout: ComUiUserLayout },
  'service-area': { body: PageProServiceArea, layout: ComUiUserLayout },
  skills: { body: PageProSkills, layout: ComUiUserLayout },
  tasks: { body: TaskList, layout: ComUiUserLayout },
  'tasks/id': { body: TaskLayout, layout: ComUiUserLayout },
};

const routes = Object.assign(publicRoutes, proRoutes);

export default routes;
