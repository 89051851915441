import React from 'react';
import { Pro, Region } from '@nanaio/util';
import _ from 'lodash';
import { Input, Text } from '@/components';
import MarketRulesetPanel from './MarketRulesetPanel';
import Panel from './Panel';
import ProRulesetPanel from './ProRulesetPanel';

const FILTERS_CONNECTOR = [
  { id: 'ALL', name: 'All' },
  { id: 'ANY', name: 'Any' },
];

type PanelTitleProps = {
  disableControls?: boolean;
  error: string;
};

function PanelTitle({ disableControls, error }: PanelTitleProps): JSX.Element {
  return (
    <div className="ml-4 flex">
      <Text type="label" className="mt-2.5 self-start">
        If
      </Text>

      <div className="ml-3 w-28">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={error}
          id="filtersConnector"
          label=""
          options={FILTERS_CONNECTOR}
        />

        <Text color="danger" type="helper" className="mt-1">
          {error}
        </Text>
      </div>

      <Text type="label" className="ml-3 mt-2.5 self-start">
        of the following conditions are met
      </Text>
    </div>
  );
}

type Props = {
  disableControls?: boolean;
  errors: Errors;
  pros: Pro[];
  regions: Region[];
};

interface Errors {
  filtersConnector: string;
  marketRuleCondition: string;
  marketRuleValues: string;
  proRuleCondition: string;
  proRuleValues: string;
}

export default function FiltersPanel({
  disableControls,
  errors,
  pros,
  regions,
}: Props): JSX.Element {
  return (
    <Panel
      icon="filter_alt"
      title={<PanelTitle disableControls={disableControls} error={errors.filtersConnector} />}
    >
      <MarketRulesetPanel
        disableControls={disableControls}
        errors={_.pick(errors, ['marketRuleCondition', 'marketRuleValues'])}
        regions={regions}
      />

      <ProRulesetPanel
        disableControls={disableControls}
        errors={_.pick(errors, ['proRuleCondition', 'proRuleValues'])}
        pros={pros}
      />
    </Panel>
  );
}
