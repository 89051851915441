import { Part, T, Task, TaskDiagnosis } from '@nanaio/util';
import _ from 'lodash';

export const getDiagnosisRelatedPartsAllowingDiagnosisDeletion = (
  diagnosisIndex: number,
  task: Task
): Part[] => {
  const taskParts = task.parts?.itemsArray || [];

  return taskParts.filter(
    part =>
      (part.associatedDiagnosesIndexes || []).includes(diagnosisIndex) &&
      T.partAllowsDiagnosisDeletion(part)
  );
};

//TODO Move this to the diagnosis modal no reason for it exist here.
export const reassociatePartsToDiagnoses = (
  diagnosisIndexToDelete: number,
  parts: Part[],
  mostRecentDiagnosis:
    | {
        index: number;
        diagnosis: TaskDiagnosis;
      }
    | undefined
): Part[] => {
  return parts.reduce((acc: Part[], part) => {
    const newPart = _.cloneDeep(part);

    newPart.associatedDiagnosesIndexes = newPart.associatedDiagnosesIndexes || [];

    // part is tied to more than 1 dx => update in the associated indexes any value greater than the one we are removing
    if (newPart.associatedDiagnosesIndexes.length > 1) {
      // remove from the associated indexes the index of the diagnosis we want to delete
      newPart.associatedDiagnosesIndexes.splice(
        newPart.associatedDiagnosesIndexes.findIndex(i => diagnosisIndexToDelete === i),
        1
      );

      // subtract 1 to any index greater than the one we are deleting
      for (let i = 0; i < newPart.associatedDiagnosesIndexes.length; i += 1) {
        if (newPart.associatedDiagnosesIndexes[i] > diagnosisIndexToDelete) {
          newPart.associatedDiagnosesIndexes[i] -= 1;
        }
      }

      acc.push(newPart);
    }

    // part is tied to only 1 dx => if necessary, switch to the new diagnosis index
    else if (mostRecentDiagnosis && diagnosisIndexToDelete !== mostRecentDiagnosis.index) {
      newPart.associatedDiagnosesIndexes = [mostRecentDiagnosis.index];

      acc.push(newPart);
    }

    return acc;
  }, []);
};
