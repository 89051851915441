import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import nullthrows from 'nullthrows';
import Button from '../Button';
import Context from './Context';

type Props = {
  askConfirmation?: boolean;
  children?: ReactNode;
  closeText?: string;
  customUI?: boolean;
  disableSaveButton?: boolean;
  hideCloseButton?: boolean;
  noValidate?: boolean;
  onClose?: () => void;
  onRemove?: () => Promise<void> | void;
  onSave?: () => Promise<void> | void;
  removeText?: string;
  saveText?: string;
};

export default function Footer({
  askConfirmation,
  children,
  closeText = 'Close',
  customUI,
  disableSaveButton,
  hideCloseButton,
  noValidate,
  saveText = 'Save',
  removeText = 'Remove',
  ...props
}: Props): ReactElement | null {
  const context = useContext(Context);
  const { onClose, onRemove, onSave } = { ...context, ...props };
  const [showSaveWarning, setShowSaveWarning] = useState(false);
  const [showRemoveWarning, setShowRemoveWarning] = useState(false);

  if (!onClose && !onSave && !children) {
    return null;
  }

  const handleSave = async () => {
    if (askConfirmation && !showSaveWarning) {
      setShowSaveWarning(true);
    } else {
      await nullthrows(onSave)();
    }
  };

  const handleRemove = async () => {
    if (askConfirmation && !showRemoveWarning) {
      setShowRemoveWarning(true);
    } else {
      await nullthrows(onRemove)();
    }
  };

  const classes =
    'border-t border-grey-medium flex flex-row justify-between p-4 bg-white rounded-b relative';

  // inverse of shadow-md
  const style = { boxShadow: '0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)' };

  if (customUI) {
    return <div className={classes}>{children}</div>;
  }

  return (
    <div className={classes} style={style}>
      {onClose && !hideCloseButton ? (
        <Button size="medium" variant="outline" onClick={onClose}>
          {closeText}
        </Button>
      ) : (
        <div />
      )}
      {children}
      {onRemove && (
        <Button
          cypressId="remove-modal"
          size="medium"
          disabled={disableSaveButton}
          onClick={handleRemove}
          variant="danger"
        >
          {showRemoveWarning ? 'Are you sure?' : removeText}
        </Button>
      )}
      {onSave && (
        <Button
          cypressId="submit-modal"
          size="medium"
          disabled={disableSaveButton}
          onClick={handleSave}
          submit={!noValidate}
        >
          {showSaveWarning ? 'Are you sure?' : saveText}
        </Button>
      )}
    </div>
  );
}
