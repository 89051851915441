import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import { vendorLogos } from '../../config/vendorLogos';
import VendorLogo, { Props } from './VendorLogo';

const vendorNames = Object.keys(vendorLogos);

export default {
  title: 'Core/Vendor Logo',
  component: VendorLogo,
  args: { vendorName: 'assurant' },
  argTypes: {
    vendorName: {
      control: { type: 'select' },
      options: vendorNames,
    },
  },
} as ComponentMeta<typeof VendorLogo>;

const Template: ComponentStory<typeof VendorLogo> = args => <VendorLogo {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Sizes = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Size prop options</p>
    <p className="mt-6">small (24px)</p>
    <Default {...args} size="small" />
    <p className="mt-6">medium (32px)</p>
    <Default {...args} size="medium" />
    <p className="mt-6">large (48px)</p>
    <Default {...args} size="large" />
    <p className="mt-6">xlarge (64px)</p>
    <Default {...args} size="xlarge" />
  </div>
);

export const BadName = Template.bind({});
BadName.args = { vendorName: 'Vendor Name Does Not Match' };
