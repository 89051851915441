import React, { useMemo } from 'react';
import { WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import { CheckList, Text } from '@/components';
import { Props as CheckListProps } from '@/components/core/CheckList';
import List from '@/components/pages/user/FTCBookingFlow/List';
import { companyName } from '@/config/const';
import {
  needsDiagnosis,
  needsDiagnosisAndStashedVisits,
  preDiagnosed,
  preDiagnosedAndStashedVisits,
} from './welcomeListItems';

type Props = {
  deadline: moment.Moment;
  onStepClick: (index: number) => void;
  items: CheckListProps['items'];
  vendorFullName: string | undefined;
  workOrder: WorkOrder;
};

export default function LandingPageLists({
  deadline,
  onStepClick,
  items,
  vendorFullName,
  workOrder,
}: Props): JSX.Element {
  const needsDiagnosisSurvey = workOrder.needsDiagnosisSurvey ?? true;
  const hasVisitsAtCreate = workOrder.visitsAtCreate.length > 0;

  const listItems = useMemo(() => {
    if (!needsDiagnosisSurvey && !hasVisitsAtCreate) {
      return preDiagnosed;
    }
    if (!needsDiagnosisSurvey && hasVisitsAtCreate) {
      return preDiagnosedAndStashedVisits;
    }

    if (needsDiagnosisSurvey && !hasVisitsAtCreate) {
      return needsDiagnosis;
    }
    return needsDiagnosisAndStashedVisits;
  }, [needsDiagnosisSurvey, hasVisitsAtCreate]);

  return (
    <>
      <Text className="mt-14">
        Complete these steps by{' '}
        <b>
          {deadline.format('ddd, MMMM, D')} at {deadline.format('hh:mma')} ({deadline.format('z')})
        </b>
      </Text>

      <CheckList className="mt-4" items={items} onClick={onStepClick} />

      <Text type="helper" className="mt-5 text-grey-dark">
        * Your work order will be canceled and sent back to {vendorFullName} if there’s no response
        by the designated date.
      </Text>

      <div className="my-8 border-b border-grey-medium" />

      <List header={`A look into the next steps at ${companyName}`} items={listItems} />
    </>
  );
}
