import React from 'react';
import _ from 'lodash';
import { Button, Icon, Modal, Text } from '@/components';
import { LandingStepProps } from './LandingPage'; // eslint-disable-line import/no-cycle

export default function ConfirmationStep({
  onLeave,
  workOrder,
  helpButton,
  lists,
}: LandingStepProps): JSX.Element {
  return (
    <>
      <Modal.Header rightContent={<div className="h-4" />} spacerContentWidth={40} />
      <Modal.Body className="p-6">
        <section>
          <div className="align-center mx-auto mt-10 flex h-16 w-16 justify-center rounded-[32px] bg-success bg-opacity-10">
            <Icon className="text-success" name="check" size={20} />
          </div>
          <Text type="headline-6" className="mt-8 text-center">
            We&apos;re Getting Everything Ready
            <br />
            For Your Repair
          </Text>

          <Text className="mt-5 text-center text-grey-dark">
            Thank you, {workOrder.cx.firstName}! We&apos;ll be reaching out within the next 48
            hours.
          </Text>
        </section>
        {lists}
        <div className="my-8 border-b border-grey-medium" />
        {helpButton}
      </Modal.Body>
      <Modal.Footer customUI>
        <Button className="w-full" size="xlarge" variant="primary" onClick={onLeave}>
          Done
        </Button>
      </Modal.Footer>
    </>
  );
}
