import React from 'react';
import * as Table from '@nanaio/table';
import { Range } from '@nanaio/util';
import _ from 'lodash';
import { Input, InputType } from '../../form';

const filterUi = ({
  onChange,
  value,
}: {
  onChange?: (id: string, value: number) => void;
  value?: Range<number>;
}): JSX.Element => (
  <div>
    <label>Set price range</label>
    <div className="d-flex align-items-center mt-1">
      <Input
        className="m-0 flex-1"
        label="Min"
        onChange={value => onChange && onChange('start', value as number)}
        type={InputType.MONEY}
        value={value?.start}
      />
      <div className="d-inline-flex align-center px-3">
        <p>-</p>
      </div>
      <Input
        className="m-0 flex-1"
        label="Max"
        onChange={value => onChange && onChange('end', value as number)}
        type={InputType.MONEY}
        value={value?.end}
      />
    </div>
  </div>
);

const moneyModule: Partial<Table.Column> = { filterUi };

export default moneyModule;
