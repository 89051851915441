import React from 'react';
import _ from 'lodash';
import { useLegacySelector } from '@/hooks';
import UserTaskDetails from '../user';
import TaskLayout from './TaskLayout';

export default function Layout(): JSX.Element {
  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  if (roles.customersupport) {
    return <TaskLayout />;
  }
  if (roles.serviceprovider) {
    // There is currently no plan for pros to be able to view tasks via web
    return <></>;
  }
  return <UserTaskDetails />;
}
