import React from 'react';
import _ from 'lodash';
import { Text } from '@/components';
import { companyName } from '@/config/const';
import { useLegacySelector } from '@/hooks';

export default function VendorRebookMessage(): JSX.Element {
  const tasksRaw = useLegacySelector(state => state.tasks);
  const tasks = _.values(tasksRaw);
  const vendor = tasks.find(task => _.get(task, 'customer.org.name'))?.customer.org?.name;

  if (!vendor) {
    return <></>;
  }

  return (
    <div className="rounded bg-background-lightest px-3 py-3">
      <Text color="grey.dark">
        Tip: Need to book a new repair under {vendor}? Please contact them first to place a work
        order request and ask them to send {companyName} the work order!
      </Text>
    </div>
  );
}
