export const imgMap = {
  'Central AC Install': 'ac.min.jpg',
  'Central AC Repair': 'ac.min.jpg',
  'Wall/Portable AC Install': 'ac.min.jpg',
  'Wall/Portable AC Repair': 'ac.min.jpg',
  'Heating Install': 'ac.min.jpg',
  'Heating Repair': 'ac.min.jpg',
  'Ventilation Install': 'ac.min.jpg',
  'Ventilation Repair': 'ac.min.jpg',
  'Wall Heater Install': 'ac.min.jpg',
  'Wall Heater Repair': 'ac.min.jpg',
  'Water Heater': 'ac.min.jpg',

  Cleaning: 'cleaning.min.jpg',
  'Dishwasher Install': 'dishwasher.min.jpg',
  'Dishwasher Maintenance': 'dishwasher.min.jpg',
  'Dishwasher Repair': 'dishwasher.min.jpg',
  'Dryer Install': 'dryer.min.jpg',
  'Dryer Maintenance': 'dryer.min.jpg',
  'Dryer Repair': 'dryer.min.jpg',
  'Oven Install': 'oven.min.jpg',
  'Oven Maintenance': 'oven.min.jpg',
  'Oven Repair': 'oven.min.jpg',
  'Refrigerator Install': 'fridge.min.jpg',
  'Refrigerator Maintenance': 'fridge.min.jpg',
  'Refrigerator Repair': 'fridge.min.jpg',
  'Stove Install': 'stove.min.jpg',
  'Stove Maintenance': 'stove.min.jpg',
  'Stove Repair': 'stove.min.jpg',
  'Washer Install': 'washer.min.jpg',
  'Washer Maintenance': 'washer.min.jpg',
  'Washer Repair': 'washer.min.jpg',
};
