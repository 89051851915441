import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import U, { PricingPackage } from '@nanaio/util';
import _ from 'lodash';
import { Form, Input, InputType, Modal, Text } from '@/components';
import { server } from '../../../server';

const fields = [
  { label: 'Additional Appliance Rate', path: 'workOrderBonus', noPay: true },
  { path: 'additionalDiagnosis' },
  { path: 'additionalMaintenance' },
  { path: 'customerInstruct' },
  { path: 'diagnosis' },
  { path: 'fixedRate', noPay: true },
  { path: 'helperFee' },
  { path: 'labor.flatRate' },
  { path: 'labor.hourlyRate' },
  { path: 'maintenance' },
  { path: 'minorRepair' },
  { path: 'partUpcharge' },
  { path: 'workOrderLabor', noPay: true },
];
const pricingTypeToFields = {
  'Labor bonus for one completed task': [
    'diagnosis',
    'helperFee',
    'labor.flatRate',
    'workOrderBonus',
  ],
  'Labor per Appliance': [
    'additionalMaintenance',
    'additionalDiagnosis',
    'customerInstruct',
    'diagnosis',
    'helperFee',
    'labor.flatRate',
    'maintenance',
    'minorRepair',
  ],
  'Labor per Work Order': ['diagnosis', 'helperFee', 'workOrderLabor'],
  Uniprice: ['diagnosis', 'fixedRate'],
};

type Props = {
  id?: string;
  moduleIdToModule?: Record<string, Table.Column>;
  toggleOpen: () => void;
};

export default function EditPackage({ id, moduleIdToModule, toggleOpen }: Props): JSX.Element {
  const [error, setError] = useState<string>();
  const [pricingPackage, setPricingPackage] = useState<Partial<PricingPackage>>({});
  const [pricingPackageOptions, setPricingPackageOptions] = useState<PricingPackage[]>([]);
  const [pricingType, setPricingType] = useState<keyof typeof pricingTypeToFields>('Uniprice');

  useEffect(() => {
    if (id) {
      void U.api<PricingPackage>('get', `pricing/${id}`).then(response => {
        if (!('errMsg' in response)) {
          setPricingPackage(response);
        }
      });
    } else {
      const pricingPackage = {};
      _.forEach(moduleIdToModule, module => {
        if (module.type === 'money') {
          _.set(pricingPackage, module.path[0], 0);
        }
      });
      setPricingPackage(pricingPackage);
      void U.api<PricingPackage[]>('post', 'pricing/search', { limit: -1 }).then(response => {
        if (Array.isArray(response)) {
          setPricingPackageOptions(response);
        }
      });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyPricingPackage = (idToCopy?: string) => {
    const pricingPackage = pricingPackageOptions.find(option => option.id === idToCopy);
    setPricingPackage(_.pick(pricingPackage, ['pricing', 'propay']));
  };

  const handleSave = async () => {
    if (pricingPackage.partUpcharge?.rate && pricingPackage.partUpcharge?.rate < 0) {
      return setError('Part upcharge cannot be negative');
    }
    if (pricingPackage.partUpcharge?.rate && pricingPackage.partUpcharge?.rate > 1) {
      return setError('Part upcharge cannot exceed 100%');
    }
    const packageCopy = _.cloneDeep(pricingPackage);
    if (!packageCopy.pricing?.labor) {
      _.set(packageCopy, 'pricing.labor', {});
    }
    if (id) {
      delete packageCopy._id;
      delete packageCopy.endTime;
      delete packageCopy.startTime;
    }

    await server.pricing.createPricingPackage(packageCopy);
  };

  return (
    <Form onChange={setPricingPackage} value={pricingPackage}>
      <Modal isOpen>
        <Modal.Header title={id ? 'Update Package' : 'Create Package'} />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          <div className="grid grid-cols-2 gap-4">
            {!id && (
              <Input
                label="Copy From"
                onChange={copyPricingPackage}
                options={pricingPackageOptions}
                sort
              />
            )}
            {!id && (
              <Input
                label="Pricing Type"
                onChange={value => setPricingType(value as keyof typeof pricingTypeToFields)}
                options={[
                  'Uniprice',
                  'Labor per Appliance',
                  'Labor per Work Order',
                  'Labor bonus for one completed task',
                ]}
                value={pricingType}
              />
            )}
            <Input id="name" required />
            <Input id="startTime" type={InputType.DATE} />
            {_.flatten(
              fields.map(field =>
                field.path === 'partUpcharge'
                  ? [
                      <Input
                        id="partUpcharge.rate"
                        label="Part Upcharge Rate"
                        type={InputType.PERCENT}
                      />,
                      <Input
                        id="partUpcharge.limitToRetailPrice"
                        label="Limit to Retail Price"
                        type={InputType.BOOL}
                      />,
                    ]
                  : [
                      <Input
                        id={`pricing.${field.path}`}
                        disabled={!id && !pricingTypeToFields[pricingType].includes(field.path)}
                        label={field.label || _.startCase(field.path)}
                        type={InputType.MONEY}
                      />,
                      !field.noPay && (
                        <Input
                          id={`propay.${field.path}`}
                          label={`Pay ${field.label || _.startCase(field.path)}`}
                          type={InputType.MONEY}
                        />
                      ),
                    ]
              )
            )}
          </div>
          <Text color="danger">{error}</Text>
        </Modal.Body>
        <Modal.Footer onClose={toggleOpen} onSave={handleSave} />
      </Modal>
    </Form>
  );
}
