/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment';
import PropTypes from 'prop-types';
import { Text } from '../com/ui/form';
import Loader from '../com/ui/loader';

const ISO_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g;
const PRETTY_DATE_FORMAT = 'M/D/YY h:mma z';
const TIMESTAMP_REGEX = /[0-9]{13}/g;

class Log extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    this.state = { loading: true, timezone: U.timezone(p.job.serviceAddress) };
    this.loadActivity();
  }

  getChildContext = () => ({ t: this });

  filterActivity = () => {
    const { activity, search } = this.state;
    if (search) {
      const lowerSearch = search.toLowerCase();
      return activity.filter(a => _.includes(a.search, lowerSearch));
    }
    return activity;
  };

  loadActivity = async () => {
    const { job, tasks } = this.props;
    const { timezone } = this.state;
    const query = { refId: [job.id, job.metadata.workOrderId] };
    let activity = await U.api('post', 'activities/search', { query, limit: -1 });
    activity = _.sortBy(
      activity.map(activity => {
        activity.createTime = mt(activity.createTime).valueOf();
        activity.search = `${_.get(activity, 'byUser.firstName', '')} ${activity.type} ${
          activity.title
        }`;
        activity.title = (activity.title || '')
          .replace(TIMESTAMP_REGEX, v => mt(+v).tz(timezone).format(PRETTY_DATE_FORMAT))
          .replace(ISO_REGEX, v => mt(v).tz(timezone).format(PRETTY_DATE_FORMAT));
        // make descriptions more user-friendly
        if (activity.type === 'ChargeItemCreate') {
          let json = activity.title;
          try {
            json = JSON.parse(activity.title.replace('Creating charge item: ', ''));
          } catch (e) {}
          activity.search += `Added ${_.startCase(json.type)}`;
          activity.title = (
            <div>
              {`Added ${_.startCase(json.type)}`}
              <div className="text-muted">{JSON.stringify(json)}</div>
            </div>
          );
        } else if (activity.type === 'ChargeItemDelete') {
          const json = JSON.parse(activity.title.replace('Deleting charge item: ', ''));
          activity.search += `Removed ${_.startCase(json.type)}`;
          activity.title = (
            <div>
              {`Removed ${_.startCase(json.type)}`}
              <div className="text-muted">{JSON.stringify(json)}</div>
            </div>
          );
        } else if (activity.type === 'InvoiceCreate') {
          const json = JSON.parse(activity.title.replace('Creating invoice: ', ''));
          const titles = json.acceptances.map(v => {
            const task = tasks[v.taskId];
            return `${v.accepted ? 'Approved' : 'Declined'} ${_.get(task, 'title', '')} ${
              !v.accepted && _.startCase(v.declineReason)
            } ${v.taskId}`;
          });
          titles.forEach(t => {
            activity.search += t;
          });
          activity.title = (
            <div>
              {titles.map(t => (
                <div key={t}>{t}</div>
              ))}
            </div>
          );
        } else if (activity.type === 'PartsReplace') {
          // BEFORE = { ..., "status":"used", ... }; AFTER = { ..., "status":"Installed", ...}
          activity.title = activity.title.replace(
            /"status":"([^"]+)/g,
            (match, status) => match.slice(0, 10) + U.getOptionName(T.partStatusOptions, status)
          );
          activity.search = activity.title;
        } else if (activity.type === 'TaskMetadata') {
          if (
            activity.title === 'Task metadata change - {"action":"remove","path":"recall.pastId`"}'
          ) {
            activity.title = 'Un-mark as recall';
            activity.search = activity.title;
          } else if (activity.title.includes('recall.pastId')) {
            const [taskId] = activity.title.match(/[a-f0-9]{24}/);
            activity.search = 'Mark as recall for';
            activity.title = (
              <div>
                Mark as recall for{' '}
                <a
                  href={`/tasks/${taskId}`}
                  className="link-blue"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  this job
                </a>
              </div>
            );
          }
        }
        activity.search = activity.search.toLowerCase();
        return activity;
      }),
      'createTime'
    ).reverse();
    this.setState({ activity, loading: false });
  };

  render() {
    const { loading, timezone } = this.state;
    if (loading || !timezone) {
      return <Loader />;
    }
    return (
      <div className="p-4">
        <Text id="search" />
        <div className="mr-3 text-right">{timezone}</div>
        {this.filterActivity().map(a => (
          <div key={a.id} className="d-flex p-3" style={{ borderBottom: '1px solid grey' }}>
            <div className="flex-1">
              <div>{a.title}</div>
              <p style={{ color: '#6c757d' }}>
                {_.get(a, 'byUser.firstName')}
                <Badge className="blue-badge ml-2">{_.startCase(a.type)}</Badge>
                {a.ddTraceId && (
                  <a
                    href={`https://app.datadoghq.com/apm/trace/${a.ddTraceId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/img/logos/Datadog.svg"
                      alt="Datadog Trace"
                      className="ml-2.5 inline w-5"
                    />
                  </a>
                )}
              </p>
            </div>
            <div>
              <div>{mt(a.createTime).tz(timezone).format('M/D/YY h:mm:ssa z')}</div>
              <div>{mt(a.createTime).fromNow()}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

Log.propTypes = {
  job: T.propType,
  tasks: PropTypes.objectOf(T.propType),
  // eslint-disable-next-line react/no-unused-prop-types
  taskId: PropTypes.string,
};

export default connect((s, p) => {
  const { tasks } = s;
  const job = s.tasks[p.taskId];
  return { job, tasks };
})(Log);
