import React, { createRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loggedOutEvent } from '@/com/analytics';
import { Alert, Button, Input,Text } from '@/components';
import { companyName } from '@/config/const';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function ApplicationForm({
  alert,
  alertType,
  emailError,
  firstNameError,
  isSubmitting,
  lastNameError,
  onSubmit,
  phoneNumberError,
  zipCodeError,
}) {
  const [, breakpointSize] = useBreakpoints();
  const firstNameInputRef = createRef();
  const alreadyFocused = useRef();

  useEffect(() => {
    if (!alreadyFocused.current) {
      firstNameInputRef.current.focus();
      alreadyFocused.current = true;
    }
  }, [firstNameInputRef]);

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    loggedOutEvent().track('v1_applicationFormSubmitted', { source: 'modalForm' });
    onSubmit();
  };

  return (
    <div className="flex-1 px-12 py-6">
      <Text
        type={breakpointSize >= Breakpoints.LG ? 'headline-3' : 'headline-5'}
        color="black"
        tag="h2"
        className="text-center leading-9 lg:text-left lg:font-semibold"
      >
        Let’s get your application started
      </Text>

      <form onSubmit={handleSubmit} className="mt-6 grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            error={firstNameError}
            id="firstName"
            inputRef={e => {
              firstNameInputRef.current = e;
            }}
            placeholder="First Name"
          />

          <Input placeholder="Last Name" error={lastNameError} id="lastName" />
        </div>

        <Input error={emailError} id="email" placeholder="Email" type="email" />
        <Input error={phoneNumberError} id="phoneNumber" placeholder="Phone Number" type="phone" />
        <Input error={zipCodeError} id="zipCode" placeholder="Zip Code" type="zipCode" />

        {alert ? <Alert variant={alertType}>{alert}</Alert> : null}

        <Button submit disabled={isSubmitting} className="ml-auto hidden w-52 lg:block">
          Continue
        </Button>
      </form>

      <Text type="helper" color="grey.dark" className="mt-8">
        <b>You must be 18 or over to sign up.</b> By proceeding, I agree to the {companyName}
        <a href="/terms">
          <Text type="helper" color="primaryCTA" tag="span">
            Terms and Conditions
          </Text>
        </a>{' '}
        and acknowledge that you read the{' '}
        <a href="/privacy">
          <Text type="helper" color="primaryCTA" tag="span">
            Privacy Policy
          </Text>
        </a>
        .
        <br />
        <br />
        By proceeding, I am also consenting to receive calls or SMS messages, including by automatic
        dialer, from {companyName} and its affiliates to the number I provide.
      </Text>
    </div>
  );
}

ApplicationForm.propTypes = {
  alert: PropTypes.string,
  alertType: PropTypes.oneOf(['warning', 'error']),
  emailError: PropTypes.string,
  firstNameError: PropTypes.string,
  isSubmitting: PropTypes.bool,
  lastNameError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  phoneNumberError: PropTypes.string,
  zipCodeError: PropTypes.string,
};

export default ApplicationForm;
