import classNames from 'classnames';

export const tooltipClasses =
  'bg-white border-white rounded-lg w-[350px] fixed p-4 flex flex-column z-[9999] drop-shadow-long justify-start';

export function getArrowClasses(placement?: string): string {
  const arrowBefore = `before:border-solid before:block before:h-0 before:w-0 before:m-auto before:content-['']`;
  const arrowAfter = `after:border-solid after:block after:h-0 after:w-0 after:m-auto after:content-[''] after:absolute`;
  const arrow = `h-4 w-4 absolute`;

  const bottomBase = '-left-2 top-0 -mt-[0.94rem] ';
  const bottomBefore =
    'before:absolute before:-top-[1px] before:border-t-transparent before:border-r-transparent before:border-b-white before:border-l-transparent before:border-t-0 before:border-r-[0.94rem] before:border-b-[1.0rem] before:border-l-[0.94rem]';
  const bottomAfter =
    'after:border-t-transparent after:border-r-transparent after:border-b-white after:border-l-transparent after:border-t-0 after:border-r-[0.94rem] after:border-b-[1.0rem] after:border-l-[0.94rem]';
  const bottom = classNames(bottomBase, bottomBefore, bottomAfter);

  const topBase = 'bottom-0 -left-2 -mb-[0.94rem]';
  const topBefore =
    'before:absolute before:top-[1px] before:border-t-white before:border-r-transparent before:border-b-transparent before:border-l-transparent before:border-t-[1.0rem] before:border-r-[0.94rem] before:border-b-0 before:border-l-[0.94rem]';
  const topAfter =
    'after:border-t-white after:border-r-transparent after:border-b-transparent after:border-l-transparent after:border-t-[1.0rem] after:border-r-[0.94rem] after:border-b-0 after:border-l-[0.94rem]';
  const top = classNames(topBase, topBefore, topAfter);

  const rightBase = 'left-0 -top-2 -ml-[0.94rem]';
  const rightBefore =
    'before:border-t-transparent before:border-r-white before:border-b-transparent before:border-l-transparent before:border-t-[0.94rem] before:border-r-[1.0rem] before:border-b-[0.94rem] before:border-l-0';
  const rightAfter =
    'after:left-[6px] after:top-0 after:border-t-transparent after:border-r-white after:border-b-transparent after:border-l-transparent after:border-t-[0.94rem] after:border-r-[1.0rem] after:border-b-[0.94rem] after:border-l-0';
  const right = classNames(rightBase, rightBefore, rightAfter);

  const leftBase = 'right-0 -top-2 -mr-[0.74rem]';
  const leftBefore =
    'before:border-t-transparent before:border-r-transparent before:border-b-transparent before:border-l-white before:border-t-[0.94rem] before:border-r-0 before:border-b-[0.94rem] before:border-l-[1.0rem]';
  const leftAfter =
    'after:left-[3px] after:top-0 after:border-t-transparent after:border-r-transparent after:border-b-transparent after:border-l-white after:border-t-[0.94rem] after:border-r-0 after:border-b-[0.94rem] after:border-l-[1.0rem]';
  const left = classNames(leftBase, leftBefore, leftAfter);

  return classNames(arrowBefore, arrowAfter, arrow, {
    [bottom]: placement?.includes('bottom'),
    [top]: placement?.includes('top'),
    [right]: placement?.includes('right'),
    [left]: placement?.includes('left'),
  });
}
