import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Money, Select, Text } from '../../com/ui/form';

class Refund extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.oneOf(['customer', 'org']),
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.getGatewayOptions = this.getGatewayOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(p) {
    if (p.isOpen && !this.props.isOpen) {
      this.setState({
        amount: p.bill.balance > 0 ? 0 : -p.bill.balance,
        paymentGateway: p.user === 'customer' && T.hasCard(p.workorder) ? 'card' : false,
      });
    }
  }

  getGatewayOptions() {
    return _.compact([
      this.props.user === 'customer' &&
        T.hasCard(this.props.workorder) && {
          id: 'card',
          name: `Card ending in ${_.get(this.props.workorder, 'payment.card.last4')}`,
        },
      this.props.user === 'org' && { id: 'bankAccount', name: 'Bank Account' },
      'Cash',
      'Cheque',
      this.props.user === 'customer' && 'QuickBooks',
      'Square',
      'Stripe',
      this.props.user === 'org' &&
        T.hasCard(this.props.workorder) && {
          id: 'card',
          name: `Card ending in ${_.get(this.props.workorder, 'payment.card.last4')}`,
        },
    ]);
  }

  async onSubmit() {
    const maxRefund = this.props.bill.balance > 0 ? 0 : -this.props.bill.balance;
    if (!this.state.paymentGateway) {
      return this.setState({ error: 'Payment gateway required' });
    }
    if (this.state.amount > maxRefund) {
      return this.setState({ error: `Max amount is ${U.toMoney(maxRefund)}` });
    }
    if (!this.state.reason) {
      return this.setState({ error: 'Please enter a reason' });
    }

    const body = {
      amount: this.state.amount,
      paymentGateway: this.state.paymentGateway,
      toOrg: this.props.user === 'org',
    };
    const r = await U.api('put', `workorders/${this.props.workorder.id}/refund`, body, true);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    } else {
      const changes = [{ action: 'add', path: 'notes', value: { content: this.state.reason } }];
      U.api('put', `tasks/${this.props.job.id}`, changes, true);
      this.props.onClose();
    }
  }

  render() {
    return (
      <Modal
        className="partModal sm"
        title="Refund"
        submit={this.onSubmit}
        saveText="Refund"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <p className="text-danger">{this.state.error}</p>
        <Select id="paymentGateway" options={this.getGatewayOptions()} />
        <Money id="amount" />
        <Text id="reason" />
      </Modal>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const workorder = s.workorders[job.metadata.workOrderId];
  const bill = workorder.invoice[`to${_.startCase(p.user)}`];
  return { bill, job, workorder };
})(Refund);
