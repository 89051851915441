import React from 'react';
import { Text } from '@/components';

export const Confirmation = (): JSX.Element => {
  return (
    <ul className="ml-4 list-disc">
      <li>
        <Text>
          Make sure your appliance is easily accessible,{' '}
          <i>
            has no items inside it (e.g., dishes, food), and the area around it is clear of personal
            belongings
          </i>
        </Text>
      </li>
      <li>
        <Text>Confirm the delivery of your parts</Text>
      </li>
      <li>
        <Text>
          Have someone who will be present for the entire visit{' '}
          <i>who knows where the electrical panel is and how to shut off water if needed</i>
        </Text>
      </li>
      <li>
        <Text>Add arrival instructions to your profile</Text>
      </li>
      <li>
        <Text>
          If you have pets, put them in a separate area away from where the technician will be
          working
        </Text>
      </li>
      <li>
        <Text>
          Expect the technician to work in the area for 30-60 minutes. Avoid entering the work area
          or <i>touching the appliance</i> until the technician tells you it is safe to do so
        </Text>
      </li>
      <li>
        <Text>
          If your repair is for an ice maker, you have the option to put your fridge into defrost 24
          hours before your scheduled visit time in order to speed up the repair. Please note that
          this may result in food spoilage, and we recommend you place freezer contents in a cooler
          or a temperature controlled environment.
        </Text>
      </li>
    </ul>
  );
};
