import React, { useRef, useState } from 'react';
import ReactSelect from 'react-select';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import FormControl from '@/components/form/FormControl'; // eslint-disable-line import/no-cycle
import theme from '@/components/theme';
import Text, { TYPES } from '../../core/Text';
import Tooltip from '../../core/Tooltip';
import type { InputProps } from '../Input';
import TextInput, { Value } from '../TextInput'; // eslint-disable-line import/no-cycle
import Multilevel from './Multilevel';
import {
  getFormattedOptions,
  getOptionId,
  getSelectedOption,
  isOther,
  Option,
  OTHER_OPTION,
  ReactSelectOption,
} from './util';

export const getProps = ({ onChange, options, ...rest }: InputProps): Props => ({
  onChange: nullthrows(onChange),
  options: _.values(options) as Option[] | Record<string, Option>,
  ...rest,
});

type OptionTypeBase = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type Props = {
  // the component will be focused, such that you can type in the search bar without first clicking it
  autoFocus?: boolean;
  // capitalize options, useful if options is an array of camel-cased strings
  capitalize?: boolean;
  // used for E2E tests
  cypressId?: string;
  // prevent the user from changing the input value
  disabled?: boolean;
  // show the component in error state
  error?: string;
  // allows the user to type a custom answer when Other is selected
  freeformOther?: boolean;
  // options will be rendered under non-selectable group headings. Requires nested options, ex options[i].options
  grouped?: boolean;
  // passes an id as html attribute to the component
  id?: string;
  // adds an other option to the option array
  injectOtherOption?: boolean;
  // allows user to remove the currently selected value by hitting backspace. This calls onChange with undefined
  isClearable?: boolean;
  // determines which option is selected. use when the input value doesn't match option or option.id
  isMatch?: ({ option, value }: { option: Option; value?: unknown | unknown[] }) => boolean;
  // options will be rendered in multilevel dropdowns. Requires group array, ex options[i].group
  multilevel?: boolean;
  // controls the direction the multilevel dropdowns expands
  multilevelPlacement?: 'left' | 'right';
  // the width of a multilevel dropdown level
  multilevelWidth?: number;
  // allow multiple options to be selected. value will be an array
  multiple?: boolean;
  // use if the value of the input is an object rather than a string.
  // TODO: deprecate this in favor of a combination of isMatch and transformOnChange
  object?: boolean;
  // called when the drop down selection changes
  onChange: (value: unknown) => void;
  // called when a user clicks on the dropdown
  onFocus?: () => void;
  // an array of options for the user to choose
  options: Option[] | Record<string, Option>;
  // min length of characters for other field
  otherMinLength?: number;
  // will be displayed in the drop down search bar when no value is selected
  placeholder?: string;
  // renders an X in the search bar which the user can click to clear the value
  removable?: boolean;
  renderOption?: (props: { label: string; value: unknown }) => JSX.Element;
  // sort options alphabetically
  sort?: boolean;
  // modify the value before saving it to the form
  // TODO: deprecate in favor the Input component's transformOutput prop
  transformOnChange?: (value: unknown) => unknown;
  // the value of the dropdown. will be an array if multiple prop is true
  value?: unknown | unknown[];
};

export default function SearchInput({
  autoFocus,
  capitalize,
  cypressId,
  disabled,
  error,
  freeformOther,
  grouped,
  id,
  injectOtherOption,
  isClearable = true,
  isMatch,
  multilevel,
  multilevelPlacement,
  multilevelWidth = 200,
  multiple,
  object,
  onChange,
  onFocus,
  options,
  otherMinLength = 50,
  placeholder,
  removable,
  renderOption,
  sort,
  transformOnChange,
  value,
}: Props): JSX.Element {
  const hoverOption = useRef<ReactSelectOption | undefined>();
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const arrayOptions = _.values(options);

  const formattedOptions = getFormattedOptions({
    capitalize,
    freeformOther,
    injectOtherOption,
    grouped,
    multiple,
    options: arrayOptions,
    sort,
    value,
  });

  const handleChange = (option?: OptionTypeBase | null) => {
    let newValue;
    if (object) {
      if (multiple) {
        newValue =
          option === null
            ? []
            : (newValue = _.compact(
                (option as ReactSelectOption[]).map(option =>
                  arrayOptions.find(
                    (pOption): boolean | undefined =>
                      getOptionId(pOption) === (option.value || option)
                  )
                )
              ));
      } else {
        newValue =
          option === null
            ? undefined
            : arrayOptions.find(
                (pOption): boolean | undefined =>
                  getOptionId(pOption) === (option as ReactSelectOption).value
              );
      }
    } else if (multiple) {
      newValue =
        option === null
          ? []
          : _.compact((option as ReactSelectOption[]).map(option => option.value || option));
    } else {
      newValue = option === null ? undefined : (option as ReactSelectOption).value;
    }
    onChange(transformOnChange ? transformOnChange(newValue) : newValue);
  };

  // Keeps the freeform other entry field displayed after text in the box is erased.
  const handleOtherChange = (otherValue: Value) => {
    if (otherValue === undefined) {
      onChange(OTHER_OPTION.value);
    } else {
      onChange(otherValue);
    }
  };

  const selectedOption = getSelectedOption({
    formattedOptions,
    isMatch,
    multiple,
    object,
    options: arrayOptions,
    value,
  });

  const setHoverOption = (value?: ReactSelectOption): void => {
    hoverOption.current = value;
  };

  const hasValue = value !== undefined;
  const isValueInOptions = formattedOptions.some(option => option.value === value);
  const isOtherInOptions = formattedOptions.some(option => isOther(option.value));
  const showOther =
    !multiple && (isOther(value) || (isOtherInOptions && hasValue && !isValueInOptions));

  return (
    <div data-cy={cypressId} ref={selectRef}>
      <ReactSelect
        autoFocus={autoFocus}
        components={
          multilevel
            ? {
                Menu: () => (
                  <Multilevel
                    {...{
                      formattedOptions,
                      inputValue: inputValue?.toLowerCase(),
                      placement: multilevelPlacement,
                      setHoverOption,
                      sort,
                      value,
                      width: (multilevelWidth || selectRef?.current?.offsetWidth) as number,
                    }}
                  />
                ),
              }
            : {}
        }
        formatOptionLabel={(
          { description, label, value: optionValue },
          { context }
        ): JSX.Element => (
          <Tooltip
            className="flex justify-between"
            iconColor={context === 'menu' && optionValue === value ? 'white' : undefined}
            node={
              (isOpen || context === 'value') && typeof description === 'string'
                ? description
                : undefined
            }
          >
            {renderOption ? (
              renderOption({ label, value: optionValue })
            ) : (
              <Text
                color={context === 'menu' && optionValue === value ? 'white' : undefined}
                cypressId={cypressId ? `${cypressId}-${String(optionValue)}` : undefined}
              >
                {label}
              </Text>
            )}
          </Tooltip>
        )}
        onBlur={() => {
          if (hoverOption.current) {
            handleChange(hoverOption.current);
          }
        }}
        getOptionValue={option => JSON.stringify(option)}
        id={id}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={multiple}
        isOptionDisabled={(option: ReactSelectOption) => Boolean(option.disabled)}
        key={`select-${JSON.stringify(selectedOption)}`}
        menuPortalTarget={document.body}
        onChange={handleChange}
        onFocus={() => onFocus && onFocus()}
        onInputChange={setInputValue}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
        options={formattedOptions}
        placeholder={placeholder}
        styles={{
          clearIndicator: base => ({
            ...base,
            padding: 0,
            display: removable ? 'inherit' : 'none',
          }),
          control: base => ({
            ...base,
            ...TYPES['body-2'],
            minWidth: 100,
            ...(error ? { borderColor: theme.colors.danger } : {}),
          }),
          groupHeading: base => ({
            ...base,
            ...TYPES.button,
            backgroundColor: theme.colors.grey.light,
            color: theme.colors.grey.dark,
            padding: 12,
            textTransform: 'none',
          }),
          menuList: base => ({ ...base, ...TYPES['body-2'], maxHeight: '50vh' }),
          menuPortal: base => ({ ...base, zIndex: 1000000000001 }),
          option: (
            base,
            { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
          ) => {
            let backgroundColor = base.backgroundColor;
            if (isSelected) {
              backgroundColor = theme.colors.primaryCTA;
            } else if (isFocused) {
              backgroundColor = theme.hexToRGB(theme.colors.primaryCTA, 0.5);
            }
            return { ...base, backgroundColor: String(backgroundColor) };
          },
          placeholder: base => ({ ...base, ...TYPES['body-2'] }),
          singleValue: base => ({
            ...base,
            color: 'black',
            textOverflow: 'inherit',
            position: 'static',
            transform: 'none',
            width: '-webkit-fill-available',
          }),
          valueContainer: base => ({
            ...base,
            ...(multiple ? {} : { flexWrap: 'nowrap' }),
            paddingRight: 0,
          }),
        }}
        value={showOther ? { label: 'Other' } : selectedOption}
      />
      {showOther && freeformOther && (
        <FormControl>
          <TextInput
            className="mt-1"
            minLength={otherMinLength}
            multiline
            onChange={handleOtherChange}
            placeholder="Tell us more"
            value={value === 'other' ? undefined : String(value)}
          />
        </FormControl>
      )}
    </div>
  );
}
