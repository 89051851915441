import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import Text from '@/components/core/Text';
import { supportArticleUrls } from '@/config/const';
import { needsDiagnosis } from '../../../../task/user/com/ConfirmationFlow/welcomeListItems';
import List, { ListItemProps } from './List';

const defaultItems: ListItemProps[] = [
  {
    title: 'Title 1',
    description: 'Description 1',
  },
  {
    title: 'Lorem ipsum Title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et.',
  },
  {
    title: 'Title 3',
    description: 'Description 3',
  },
];

const linkExampleItems: ListItemProps[] = [
  {
    description: 'Locate the model number sticker on your appliance',
    link: {
      title: 'Where can I find this?',
      url: supportArticleUrls.modelStickerHelp,
    },
  },
  {
    description: 'Position your camera in front of the sticker on your appliance',
  },
  {
    description: 'Take a photo',
    timeEstimate: 'Takes 1 to 2 days',
  },
  {
    description: 'Check that the model no. and serial no. are legible',
  },
];

export default {
  title: 'pages/user/FTCBookingFlow/List',
  component: List,
  args: {
    items: defaultItems,
  },
} as ComponentMeta<typeof List>;

const Template: ComponentStory<typeof List> = args => <List {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const WithHeaderAndFooter = Template.bind({});
WithHeaderAndFooter.args = {
  header: 'Some copy about the List',
  footer: '*Footer text about the content in the list.',
};

export const StepsVariantWithLinkAndTimeEstimate = Template.bind({});
StepsVariantWithLinkAndTimeEstimate.args = {
  header: 'Instructions',
  items: linkExampleItems,
  variant: 'steps',
};

export const FTCWelcomeScreen = Template.bind({});
FTCWelcomeScreen.args = {
  header: 'A look into the next steps in your repair.',
  items: needsDiagnosis,
  footer: (
    <div>
      <Text className="mt-6 text-grey-dark" type="helper">
        *Due to the high volume of orders we are receiving, pre-diagnosis may be skipped in order to
        avoid delays to your work order.
      </Text>
      <Text className="mt-6 text-grey-dark" type="helper">
        *If parts were not delivered by the day of your appointment, we will reach to reschedule.
      </Text>
    </div>
  ),
};
