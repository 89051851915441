import React, { useCallback, useMemo, useState } from 'react';
import { T, Task, U } from '@nanaio/util';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { APIError, Button, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import Input, { Type } from '@/components/form/Input';
import { useLazyLegacyAPI } from '@/hooks';

type Props = {
  onStepClick: () => void;
  task: Task;
};

export default function FormStep({ onStepClick, task }: Props): JSX.Element {
  const [form, setForm] = useState({ reason: '', feedback: '' });
  const submitRef = React.createRef<Ref>();

  const reasonOptions = useMemo(
    () => T.closeReasons.map(reason => ({ id: reason.id, name: reason.name })),
    []
  );

  const [handleCloseTask, updatedTask] = useLazyLegacyAPI<Task>(`tasks/${task.id}/cxCloseTask`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to Close Task</>} />
    ),
    method: 'post',
    save: true,
  });

  const handleSubmit = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }

    const { feedback, reason } = form;
    const closeTaskBody = { cxCloseFeedback: feedback, cxCloseReason: reason };

    const taskData = await handleCloseTask(closeTaskBody);
    if (!taskData) {
      return;
    }

    workOrderEvent().track('v1_workOrderTaskClosedByCX');

    onStepClick();
  }, [form, handleCloseTask, onStepClick, submitRef]);

  const disableSubmit =
    form.reason === '' || (form.reason === T.CloseReason.OTHER && form.feedback.trim() === '');
  return (
    <>
      <Modal.Header title="Close Work Order" subtitle="2 of 2 steps" progress={1} />
      <Modal.Body>
        <div className="p-6">
          {updatedTask.error}
          <Text className="text-center" type="headline-6">
            Share your reason for closing this work order
          </Text>
          <Text className="mt-2 text-center" color="grey.dark">
            We’d love any feedback you might have for us!
          </Text>

          <Form onChange={setForm} value={form} ref={submitRef}>
            <Input
              className="mt-10 flex-col"
              direction="column"
              id="reason"
              label="Reason For Closing Work Order"
              onClick={value => setForm({ ...form, reason: value as string })}
              options={U.toOptions(reasonOptions)}
              required
              type={Type.RADIO}
            />
            <Input
              className="mt-6"
              id="feedback"
              label="Additional Feedback"
              type={Type.TEXT}
              placeholder="Enter here"
              required={form.reason === T.CloseReason.OTHER}
              multiline
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button
          className="w-full"
          disabled={disableSubmit}
          onClick={handleSubmit}
          variant="danger"
          submit
        >
          Close Work Order
        </Button>
      </Modal.Footer>
    </>
  );
}
