import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import invariant from 'invariant';
import Icon from '../core/Icon';
import Text from '../core/Text';
import Tooltip from '../core/Tooltip';
import HelperCount from './HelperCount';
import { Type } from './Input'; // eslint-disable-line import/no-cycle

type Props = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  helper?: string;
  inline?: boolean;
  label?: string;
  labelLeftUI?: ReactNode;
  labelRightUI?: ReactNode;
  onLabelClick?: () => void;
  onRemove?: () => void;
  required?: boolean;
  style?: CSSProperties;
  tooltip?: ReactNode;
  type?: Type;
};

export default function FormControl({
  children,
  className = '',
  disabled,
  error,
  helper,
  inline,
  label,
  labelLeftUI,
  labelRightUI,
  onLabelClick,
  onRemove,
  required,
  style,
  tooltip,
  type,
}: Props): JSX.Element {
  const child = React.Children.only(children) as ReactElement;
  const { maxLength, minLength, value, showCharacterCount } = child.props as {
    maxLength: number | undefined;
    minLength: number | undefined;
    value: string | undefined;
    showCharacterCount: boolean | undefined;
  };
  invariant(
    !maxLength || !minLength || minLength < maxLength,
    'minLength must be less than maxLength'
  );

  const classes = classnames(className, {
    'mb-4': !className.includes('mb-') && !inline,
    'rounded-lg px-4 py-3': inline,
    'bg-red-15': inline && error,
  });
  const isInlineIcon = type && [Type.CHECK, Type.SWITCH].includes(type);
  const labelClasses = classnames({
    'opacity-40': disabled,
    'mr-1': type === 'switch',
    'mb-1': type !== 'switch',
  });

  let counter;
  const length = typeof value === 'string' ? value.length : 0;
  if (typeof minLength === 'number' && minLength) {
    helper = `Minimum ${minLength} characters`;
    counter = <HelperCount count={length} min={minLength} className="ml-2 mt-2" />;
  }
  if (typeof maxLength === 'number' && maxLength && (!minLength || length > minLength)) {
    helper = `Maximum ${maxLength} characters`;
    counter = <HelperCount count={length} max={maxLength} min={minLength} className="ml-2 mt-1" />;
  }
  if (!counter && showCharacterCount) {
    counter = <HelperCount count={length} className="ml-2 mt-1" />;
  }

  const content =
    error || helper ? (
      <Text className="mt-1 flex-1" color={error ? 'danger' : 'grey.dark'} type="helper">
        {error || helper}
      </Text>
    ) : (
      <div className="flex-1" />
    );

  return (
    <>
      <div className={classes} style={style}>
        <div className="flex justify-between">
          <div
            className={`flex items-center ${onLabelClick ? 'cursor-pointer' : ''}`}
            onClick={onLabelClick}
          >
            {type === Type.CHECK && children}
            {label && (
              <div>
                <Text type={type === Type.CHECK ? undefined : 'label'} className={labelClasses}>
                  {label}
                  {required && (
                    <Text
                      className={labelClasses}
                      color="danger"
                      tag="span"
                      type={type === Type.CHECK ? undefined : 'label'}
                    >
                      *
                    </Text>
                  )}
                </Text>
                {type === Type.CHECK && content}
              </div>
            )}
            {disabled && label ? (
              <img className="-mt-1 ml-1.5" src="/svg/lock.svg" alt="lock" />
            ) : (
              labelLeftUI
            )}
            {tooltip && <Tooltip node={tooltip} />}
          </div>
          {labelRightUI}
          {onRemove && <Icon color="danger" name="close" onClick={onRemove} />}
          {type === Type.SWITCH && children}
        </div>

        {!isInlineIcon && children}

        {!inline && (
          <div className="flex-row items-center">
            {type !== Type.CHECK && content}
            {minLength || maxLength || showCharacterCount ? counter : null}
          </div>
        )}
      </div>

      {inline && (
        <div className="flex-row items-center">
          {content}
          {minLength || maxLength || showCharacterCount ? counter : null}
        </div>
      )}
    </>
  );
}
