import React, { useEffect, useState } from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Grid from '../core/Grid';
import Modal from '../core/Modal';
import DateInput from '../form/DateInput';
import getAssignedRows from './AssignedRows';
import getCustomerRow from './CustomerRow';
import getEligibleRows from './EligibleRows';
import Footer from './Footer';
import getHeaderRow from './HeaderRow';
import SearchTech from './HeaderRow/SearchTech';
import getInterestedRows from './InterestedRows';
import Map from './Map';
import getUnassignedRows from './UnassignedRows';
import { firstColumnWidth } from './util';

export default function Schedule({
  capacityType,
  isOpen,
  minScheduleTime,
  onCancel,
  onSave,
  taskId,
  visitId,
  workOrder,
}) {
  const [availability, setAvailability] = useState([]);
  const [capacity, setCapacity] = useState([]);
  const [error, setError] = useState();
  const [goToDateIsOpen, setGoToDateIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mapDate, setMapDate] = useState(m());
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [mapProId, setMapProId] = useState(W.proId(workOrder));
  const [notify, setNotify] = useState('customer');
  const [pros, setPros] = useState([]);
  const [proMap, setProMap] = useState({});
  const [regionName, setRegionName] = useState();
  const [scheduleStart, setScheduleStart] = useState();
  const [searchTechIsOpen, setSearchTechIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState('Home');
  const [startTimes, setStartTimes] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [visit, setVisit] = useState();
  const [weekIsLoading, setWeekIsLoading] = useState(false);

  const addMissingRoles = visit => {
    if (!visit) {
      return undefined;
    }
    const roles = [W.VisitProRole.PRIMARY, W.VisitProRole.HELPER, W.VisitProRole.INTERESTED];
    return {
      ...visit,
      pros: visit.pros.map(pro => {
        const newPro = { ...pro };
        newPro.tasks = newPro.tasks.map(task => {
          const newTask = { ...task };
          if (!newTask.role) {
            newTask.role = roles.find(role => W.visitProCanAddRole({ role, taskId, visit }));
          }
          return newTask;
        });
        return newPro;
      }),
    };
  };

  const loadWeek = async scheduleStart => {
    if (visitId === -1) {
      const lastVisit = _.last(workOrder.visits) || { pros: [] };
      const newVisit = { cx: { availability: [] } };
      const pros = lastVisit.pros
        .filter(pro => pro.id && pro.tasks.find(task => task.role !== W.VisitProRole.INTERESTED))
        .map(pro => ({
          availability: [],
          id: pro.id,
          status: W.VisitProStatus.PENDING,
          tasks: pro.tasks.map(task => ({
            id: task.id,
            role:
              task.role === W.VisitProRole.INTERESTED ? W.VisitProRole.NOT_ATTENDING : task.role,
          })),
        }));
      if (!pros.length) {
        pros.push({
          availability: [],
          status: W.VisitProStatus.PENDING,
          tasks: workOrder.tasks.map(task => ({ id: task.id, role: W.VisitProRole.PRIMARY })),
        });
      }
      newVisit.pros = pros;
      setVisit(newVisit);
    }

    setIsLoading(true);
    setWeekIsLoading(true);
    const request = {
      isBookingExperiment: _.includes(workOrder?.tags, W.Tag.EXPERIMENTAL_SAMSUNG_BOOKING_FLOW),
      jobId: taskId,
      startTime: m(scheduleStart).startOf('day'),
      endTime: m(scheduleStart).add(1, 'week'),
      minScheduleTime,
      options: { capacityType, excludeAvailabilityTimeSlots: true, includeData: true },
    };
    if (!workOrder.id) {
      request.address = workOrder.cx.address;
      request.orgId = workOrder.tasks[0].customer.org?.id;
      request.serviceId = workOrder.tasks[0].serviceCatalogs[0].id;
    }
    const response = await U.api('post', 'availability', request);
    const proMap = _.mapValues(response.data.pros, pro => {
      const task = workOrder.tasks.find(task =>
        _.get(task, `metadata.opportunityDeclined.${pro.id}`)
      );
      const declinedReasons = task?.metadata.opportunityDeclined[pro.id];
      const out = {
        ...pro,
        homeDistance: U.distance(
          _.get(pro, 'user.address.geoCoordinates'),
          _.get(workOrder, 'cx.address.geoCoordinates')
        ),
      };
      if (declinedReasons) {
        out.declinedReasons = declinedReasons;
      }
      return out;
    });
    const startTimes = _.keys(response.availability).map(Number);
    setAvailability(_.values(response.availability));
    setCapacity(_.values(response.capacity));
    setIsLoading(false);
    setMapDate(m(startTimes[0]).startOf('day'));
    setPros(_.values(proMap));
    setProMap(proMap);
    setRegionName(response.data.regionName);
    setScheduleStart(mt(startTimes[0]).tz(response.timeZoneId).startOf('day'));
    setStartTimes(startTimes);
    setTimeSlots(response.data.timeSlots);
    setWeekIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      setError();
      setNotify('customer');
      setTimezone(U.timezone(workOrder.cx.address));
      setVisit(addMissingRoles(workOrder.visits[visitId]));
      loadWeek();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isOpen || !visit) {
    return null;
  }

  const getAvailabilityPath = (id, visit) => {
    if (id === 'cx') {
      return 'cx.availability';
    }
    return `pros.${_.findIndex(visit.pros, pro => pro.id === id)}.availability`;
  };

  const handleDateChange = date => {
    setGoToDateIsOpen(false);
    loadWeek(date);
  };

  const handleSave = async () => {
    if (!_.get(visit, 'cx.status')) {
      return setError('Please select a customer status from the second row.');
    }
    if (!_.get(visit, 'slot.start')) {
      return setError('Please select a visit time at the top row.');
    }
    if (visit.pros.find(pro => pro.tasks.find(task => !task.role))) {
      return setError('Select roles for each appliance');
    }
    if (onSave) {
      onSave({ notify, visit });
    } else {
      setIsLoading(true);
      let response;
      if (visitId === -1) {
        response = await U.api(
          'post',
          `workOrders/${workOrder.id}/visits?notify=${notify}`,
          visit,
          ['save']
        );
      } else {
        response = await U.api(
          'put',
          `workOrders/${workOrder.id}/visits/${visitId}?notify=${notify}`,
          visit,
          ['save']
        );
      }
      const minScheduleTime = startTimes[availability.indexOf(1)];
      U.api('put', `workOrders/${workOrder.id}/minScheduleTime`, { minScheduleTime });
      setIsLoading(false);
      if (response.errMsg) {
        setError(response.errMsg);
      } else {
        onCancel();
      }
    }
    return undefined;
  };

  const modalOnClose = () => setGoToDateIsOpen(false);

  const onSetStatus = status => setVisit(_.set(_.cloneDeep(visit), 'cx.status', status));

  const removePro = index =>
    setVisit({ ...visit, pros: visit.pros.filter((pro, proIndex) => index !== proIndex) });

  const toggleAvailability = (id, startTime) => {
    const path = getAvailabilityPath(id, visit);
    const newVisit = _.cloneDeep(visit);
    let availability = _.get(newVisit, path);
    const index = _.findIndex(availability, slot => m(slot.start).isSame(startTime));
    if (index === -1) {
      availability.push({
        start: m(startTime).format(),
        end: m(startTime).add(4, 'hours').format(),
      });
    } else {
      availability = availability.filter((slot, i) => i !== index);
    }
    availability = _.sortBy(availability, slot => m(slot.start).valueOf());
    setVisit(_.set(newVisit, path, availability));
  };

  const togglePreferred = (id, index, event, startTime) => {
    event.stopPropagation();
    const path = getAvailabilityPath(id, visit);
    const newVisit = _.cloneDeep(visit);
    const availability = _.map(_.get(newVisit, path), (slot, i) => {
      const newSlot = { ...slot };
      if (i === index) {
        newSlot.preferred = !newSlot.preferred;
      } else {
        delete newSlot.preferred;
      }
      return newSlot;
    });
    if (index === -1) {
      availability.push({
        start: m(startTime).format(),
        end: m(startTime).add(4, 'hours').format(),
        preferred: true,
      });
    }
    setVisit(_.set(newVisit, path, availability));
  };

  const updateSlot = (path, startTime) =>
    setVisit(
      _.set(_.cloneDeep(visit), path, {
        start: m(startTime).format(),
        end: m(startTime).add(4, 'hours').format(),
      })
    );

  const rows = [
    getHeaderRow({
      availability,
      capacity,
      isLoading,
      loadWeek,
      mapDate,
      mapIsOpen,
      proMap,
      scheduleStart,
      setGoToDateIsOpen,
      setMapDate,
      setMapIsOpen,
      setSearchTechIsOpen,
      startTimes,
      updateSlot,
      visit,
      weekIsLoading,
    }),
    getCustomerRow({
      availability,
      capacity,
      mapIsOpen,
      onSetStatus,
      startTimes,
      toggleAvailability,
      togglePreferred,
      visit,
      workOrder,
    }),
    ...getUnassignedRows({
      mapIsOpen,
      removePro,
      setSearchTechIsOpen,
      setVisit,
      startTimes,
      visit,
      workOrder,
    }),
    ...getAssignedRows({
      mapIsOpen,
      mapProId,
      proMap,
      setMapProId,
      setVisit,
      startTimes,
      timeSlots,
      toggleAvailability,
      togglePreferred,
      visit,
      workOrder,
    }),
    ...getInterestedRows({
      mapIsOpen,
      mapProId,
      proMap,
      pros,
      setMapProId,
      setVisit,
      startTimes,
      timeSlots,
      toggleAvailability,
      togglePreferred,
      visit,
      workOrder,
    }),
    ...getEligibleRows({
      mapIsOpen,
      mapProId,
      proMap,
      pros,
      setMapProId,
      setSortBy,
      setVisit,
      sortBy,
      startTimes,
      timeSlots,
      visit,
      workOrder,
    }),
  ];

  return (
    <div className="fixed inset-0 flex flex-col bg-white" style={{ zIndex: 9999 }}>
      {mapIsOpen && <Map {...{ mapProId, mapDate, proMap, timeSlots, timezone, workOrder }} />}
      <Grid
        className="w-full flex-1"
        getColumnWidth={index => (index ? 349 : firstColumnWidth)}
        getRowHeight={index => rows[index].height}
        getRowKey={index => rows[index].key}
        rows={_.map(rows, 'content')}
        stickyColumnCount={1}
        stickyRowCount={2}
      />
      <Footer
        error={error}
        isLoading={isLoading || weekIsLoading}
        isNotifyCx={notify === 'customer'}
        mapIsOpen={mapIsOpen}
        onCancel={onCancel}
        onSave={handleSave}
        regionName={regionName}
        toggleNotifyCx={() => setNotify(notify === 'customer' ? 'none' : 'customer')}
      />
      <Modal isOpen={goToDateIsOpen} onClose={modalOnClose}>
        <Modal.Header title="Go to Date" />
        <Modal.Body>
          <DateInput
            className="p-10"
            onChange={handleDateChange}
            requireConfirmation
            value={scheduleStart}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
      <SearchTech
        {...{
          isOpen: searchTechIsOpen,
          onClose: () => setSearchTechIsOpen(false),
          proMap,
          pros,
          scheduleStart,
          setSearchTechIsOpen,
          setVisit,
          taskId,
          visit,
          workOrder,
        }}
      />
    </div>
  );
}

Schedule.propTypes = exact({
  capacityType: PropTypes.oneOf(['manual', 'mixed', 'pro']),
  isOpen: PropTypes.bool,
  minScheduleTime: U.timePropType,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  taskId: PropTypes.string,
  visitId: PropTypes.number,
  workOrder: W.propType,
});
