import { useCallback, useState } from 'react';
import { Address, Task, U } from '@nanaio/util';
import _ from 'lodash';
import mt, { MomentInput } from 'moment-timezone';

export type AvailabilityResponse = {
  address: Address;
  availability: {
    [key: string]: boolean;
  };
  hasAvailability: boolean;
  inactiveRegion: boolean;
  timeZoneId: string;
};

type Args = {
  firstAvailableTime: number | undefined;
  isBookingExperiment: boolean;
  minScheduleTime: MomentInput;
  onChange: (key: string, value: unknown) => void;
  startDate: mt.MomentInput;
  task: Task;
};

export default function useLoadAvailability({
  firstAvailableTime,
  isBookingExperiment,
  minScheduleTime,
  onChange,
  startDate,
  task,
}: Args): [(daysToLoad: number) => Promise<void>, { loading: boolean; error: string | undefined }] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const tz = U.timezone(task.serviceAddress);

  const loadAvailability = useCallback(
    async (daysToLoad: number) => {
      setLoading(true);
      const response = await U.api<AvailabilityResponse>('post', 'availability', {
        isBookingExperiment,
        jobId: task.id,
        startTime: startDate,
        endTime: mt(startDate).tz(tz).add(daysToLoad, 'd').valueOf(),
        options: { ignoreDispatch: true },
        minScheduleTime,
      });
      if ('errMsg' in response) {
        onChange('availability', {});
        setError('Unable to load availability. Please wait a moment and try again.');
      } else {
        let newFirstAvailableTime: number | undefined;
        const firstAvailableTimeString = _.findKey(
          response.availability,
          (value, key) => value && +key > (minScheduleTime as number)
        );
        if (_.isString(firstAvailableTimeString)) {
          newFirstAvailableTime = +firstAvailableTimeString;
          if (!firstAvailableTime || newFirstAvailableTime < firstAvailableTime) {
            onChange('firstAvailableTime', newFirstAvailableTime);
          }
        }
        onChange('availability', response.availability);

        setError(undefined);
      }
      setLoading(false);
    },
    [isBookingExperiment, task.id, startDate, tz, minScheduleTime, onChange, firstAvailableTime]
  );

  return [loadAvailability, { loading, error }];
}
