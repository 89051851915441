import React from 'react';
import { Icon, Paper, Text } from '@/components';
import { companyName } from '@/config/const';
import ReturnTooltip from '../CloseTask/ReturnTooltip';
import type { Status } from './';

type Props = {
  status: Status;
};

export default function NextSteps({ status }: Props): JSX.Element {
  return (
    <div className="mt-10">
      <Text className="text-secondary" type="page-title">
        Next Steps for You
      </Text>
      <Paper className="mt-4 p-4" variant="lifted">
        {status === 'returnParts' && (
          <>
            <div className="flex">
              <Icon className="mr-4" name="truck_cargo_container" color="primaryCTA" />
              <div>
                <Text type="button">Return ordered parts</Text>
                <Text className="mb-4 mt-4" color="grey.dark">
                  We’ll send a return label with further instructions to your email within 5 to 7
                  business days.
                </Text>
                <ReturnTooltip />
                <Text className="mt-8" color="grey.dark" type="helper">
                  Note: Installed parts will no longer be returnable and may not be covered under
                  your home warranty provider policy. If your policy does not cover the cost of the
                  installed parts, we will reach out for further instructions.
                </Text>
              </div>
            </div>
            <div className="my-4 h-[1px] bg-grey-medium" />
          </>
        )}
        <div className="flex">
          <Icon className="mr-4" name="chat_processing_outline" color="primaryCTA" />
          <div>
            <Text type="button">Share your service experience</Text>
            <Text className="mb-4 mt-4" color="grey.dark">
              Watch for an email inviting you to share your experience with {companyName}. We value
              your feedback and use it to continually improve our service
            </Text>
          </div>
        </div>
      </Paper>
    </div>
  );
}
