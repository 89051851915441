import React from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Search } from '../../../com/ui/form';

export default class Recall extends React.Component {
  static propTypes = {
    setState: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { options: [], recall: {} };
    this.getDaysAgo = this.getDaysAgo.bind(this);
    this.loadPreviousTasks = this.loadPreviousTasks.bind(this);
    if (props.state.id) {
      this.loadPreviousTasks(props.id);
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.state.id !== props.state.id) {
      this.loadPreviousTasks(props.state.id);
      if (this.props.state.id && props.state.id) {
        props.setState({ recall: {} });
      }
    }
  }

  getDaysAgo() {
    if (
      this.props.state.recall.pastId &&
      _.get(this.state.optionMap, this.props.state.recall.pastId)
    ) {
      const { lastVisitStart } = this.state.optionMap[this.props.state.recall.pastId];
      return _.floor((m().valueOf() - lastVisitStart) / 86400000);
    }
  }

  async loadPreviousTasks(userId) {
    if (!userId) {
      return this.setState({ options: [] });
    }
    const query = { 'customer.user.id': userId };
    if (global.id()) {
      query._id = { $ne: global.id() };
    }
    const r = await U.api('post', 'tasks/search', { query });
    const options = r.map(j => {
      const lastVisitStart = T.lastVisitStart(j);
      const pro = _.get(T.lastVisit(j), 'assignee.user.fullName', 'Unassigned');
      const name = `${m(lastVisitStart).format('M/D/YY')}, ${j.title.split(' ')[0]}, ${pro}, ${
        j.shortId
      }`;
      return { id: j.id, name, lastVisitStart };
    });
    const optionMap = U.toMap(options);
    this.setState({ options, optionMap });
  }

  render() {
    return (
      <div className="info">
        <div className="card-head">
          <h5 className="title flex items-center">
            <div className="icon flex items-center justify-center">
              <Icon name="medical_bag" size={16} />
            </div>
            Recall
          </h5>
        </div>
        <div className="card-form">
          {!this.state.options.length ? (
            <div>No previous tasks found</div>
          ) : (
            <div>
              <Search id="recall.pastId" options={this.state.options} />
              {this.props.recall?.pastId && (
                <a
                  className="link-blue"
                  target="_blanks"
                  href={`/tasks/${this.props.recall.pastId}`}
                >
                  View Task
                </a>
              )}
            </div>
          )}
          {this.props.recall?.pastId && (
            <div className="mb-3 text-danger">
              <strong>This job was {this.getDaysAgo()} days ago</strong>
            </div>
          )}
          <strong>WHAT IS A RECALL APPOINTMENT</strong>
          <div>
            {
              '-Prior part that was installed and has failed from a repair conducted within 1yr from repair date. '
            }
            <strong>
              (If customer is stating this, please advise them that if the original part was not the
              failure a new service call fee of $90 applies).
            </strong>
          </div>
          <div>
            -Labor only stands for 90 days, any repair after 90 days is accessed a new labor charge.
          </div>
          <div>
            {
              '-123 rule applies for scheduling a recall. We will not service a call after 3 visits (diagnostic, repair, failed repair correction). '
            }
            <strong>
              If the repair fails on the third visit the pro needs to remove the original parts
              installed and contact the office for a refund.
            </strong>
          </div>
          <div>
            Repairs with no part installed is considered a service and adjustment and DOES NOT
            qualify as a recall.
          </div>
        </div>
      </div>
    );
  }
}
