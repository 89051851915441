import React from 'react';
import Spinner from 'react-spinkit'; // https://github.com/KyleAMathews/react-spinkit
import U from '@nanaio/util';
import T from 'prop-types';

export const Button = (props, { loading }) => {
  const { children, click, wait, ok, center, wide, style = {} } = props;
  if (center) {
    style.margin = '0 auto';
  }
  if (wide) {
    style.width = '100%';
  }
  const onClick = () => {
    if (typeof wait !== 'undefined' && !loading) {
      U.redux.set('form.check', true);
      if (ok && click.then) {
        click().then(isOk => isOk && ok());
      } else {
        click();
      }
    }
    if (!wait) {
      click();
    }
  };
  return (
    <div className="btn btn-primary" onClick={onClick} style={style}>
      {loading && wait ? (
        <div className="load">
          <Spinner noFadeIn spinnerName="folding-cube" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

Button.propTypes = {
  click: T.func.isRequired,
  wait: T.any,
  ok: T.func,
  center: T.any,
  wide: T.any,
};
Button.contextTypes = {
  loading: T.bool,
};
