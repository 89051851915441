import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

class Number extends React.Component {
  state = {};

  render() {
    const p = this.props;
    const { t } = this.context;
    // eslint-disable-next-line no-underscore-dangle
    const _onChange = e => {
      const value = e.target.value === '' ? undefined : +e.target.value;
      if (_.isNumber(p.max) && value > p.max) {
        return this.setState({ error: `Max is ${p.max}` });
      }
      if (this.state.error) {
        this.setState({ error: false });
      }
      onChange(t, p.id, value);
      if (p.onChange) {
        p.onChange(value);
      }
      if (p.debounce) {
        clearTimeout(this.state.timeout);
        this.setState({
          timeout: setTimeout(
            () => p.debounce(_.get(t.state, p.id, p.def || '')),
            p.debounceLength || 1000
          ),
        });
      }
    };
    let value = p.value || _.get(t.state, p.id);

    if (_.isUndefined(value)) {
      value = '';
    }
    return (
      <Group {...p}>
        <input
          autoFocus={this.props.autoFocus}
          className="form-control"
          type="number"
          value={value}
          max={p.max}
          min={p.min}
          onChange={_onChange}
          placeholder={p.hint || _.get(p.options, '[0]')}
          disabled={p.disabled}
          onKeyPress={e =>
            p.enter && (e.keyCode || e.which === 13) && p.enter(_.get(t.state, p.id, ''))
          }
        />
        <div className="text-danger">{this.state.error}</div>
      </Group>
    );
  }
}

Number.contextTypes = { t: T.object.isRequired };

Number.propTypes = {
  id: T.string.isRequired,
  label: T.string,
  type: T.string,
  value: T.number,
  enter: T.func,
  req: T.bool,
  max: T.number,
  min: T.number,
};

export default Number;
