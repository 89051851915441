import React from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import { TableCell } from '../types';
import Body from './Body';
import Filter from './Filter';
import Header from './Header';

type Props = {
  cell: TableCell;
  checkedRows: Record<string, boolean>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  editUi?: Table.EditUi;
  rows: Table.Row[];
  onLoadColumn: () => Promise<void>;
  onSearch: (props: {
    columnIndex?: number;
    limit?: number;
    moduleIdToOptions?: Record<string, Record<string, Option>>;
    query?: Table.Query;
    refreshIds?: string[];
    refreshQuery?: Record<string, unknown>;
    value?: unknown;
  }) => Promise<void>;
  onSort: (columnIndex: number) => void;
  onTextFilterChange: (columnIndex: number, value: string) => void;
  openEditCell: ({ columnIndex, row }: { columnIndex: number; row: Table.Row }) => void;
  openEditColumn: (module: Table.Column, clickedSettingsIcon?: boolean) => void;
  openEditQueryPopup: () => void;
  openLink: (row: Table.Row) => void;
  query: Table.Query;
  rowUrl?: (props: {
    row: Table.Row;
    pivotColumn?: Table.Column;
    pivotValue?: unknown;
  }) => string | undefined;
  setEditUi: (row: Table.Row) => void;
  toggleAllRowsChecked: () => void;
  toggleRowCheck: (row: Table.Row) => void;
};

export default function Cell({
  cell,
  checkedRows,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  editUi,
  rows,
  onLoadColumn,
  onSearch,
  onSort,
  onTextFilterChange,
  openEditCell,
  openEditColumn,
  openEditQueryPopup,
  openLink,
  query,
  rowUrl,
  setEditUi,
  toggleAllRowsChecked,
  toggleRowCheck,
}: Props): JSX.Element {
  if (cell.rowIndex === 0) {
    return (
      <Header
        {...{
          columnIndex: cell.columnIndex,
          onSort,
          openEditQueryPopup,
          query,
          style: cell.style,
        }}
      />
    );
  }

  if (cell.rowIndex === 1) {
    return (
      <Filter
        {...{
          checkedRows,
          columnIndex: cell.columnIndex,
          rows,
          onLoadColumn,
          onSearch,
          onTextFilterChange,
          openEditColumn,
          query,
          style: cell.style,
          toggleAllRowsChecked,
        }}
      />
    );
  }

  return (
    <Body
      {...{
        checkedRows,
        columnIndex: cell.columnIndex,
        databaseIdToTableIdToColumnKeyToOptionIdToOption,
        editUi,
        rows,
        openEditCell,
        openLink,
        query,
        rowIndex: cell.rowIndex,
        rowUrl,
        setEditUi,
        style: cell.style,
        toggleRowCheck,
      }}
    />
  );
}
