import React, { useEffect, useRef,useState } from 'react';
import type { NotCoveredService,Org } from '@nanaio/util';
import _ from 'lodash';
import { Form, Input } from '@/components';

type Props = {
  keyAlreadySavedError: boolean;
  onChange: (form: NotCoveredService) => void;
  orgs: Org[];
  service?: NotCoveredService;
};

const emptyService: NotCoveredService = {
  id: '',
  name: '',
  key: '',
  description: '',
  vendorIds: [],
  createTime: new Date(),
};

export default function NotCoveredServiceForm({
  keyAlreadySavedError,
  onChange,
  orgs,
  service = emptyService,
}: Props): JSX.Element {
  const isReady = useRef(false);
  const [form, setForm] = useState<NotCoveredService>(service);
  const [errors, setErrors] = useState({ name: '', key: '' });

  useEffect(() => {
    if (isReady.current) {
      setForm(form => ({ ...form, key: _.camelCase(form.name) }));
    }
  }, [form.name]);

  useEffect(() => {
    if (isReady.current) {
      const errors = { name: '', key: '' };

      if (!form.name?.trim().length) {
        errors.name = 'This field is required';
      }

      if (!form.key?.trim().length) {
        errors.key = 'This field is required';
      }

      setErrors(errors);
    }
  }, [form.name, form.key]);

  useEffect(() => {
    if (!isReady.current) {
      isReady.current = true;
    }
  }, []);

  useEffect(() => {
    onChange(form);
  }, [onChange, form]);

  useEffect(() => {
    if (keyAlreadySavedError) {
      setErrors(errors => ({ ...errors, key: 'This key has already been saved' }));
    }
  }, [keyAlreadySavedError]);

  return (
    <Form onChange={setForm} value={form}>
      <Input
        error={errors.name}
        id="name"
        placeholder="Example: Any Cosmetic or Physical Defects"
      />
      <Input error={errors.key} id="key" placeholder="Example: anyCosmeticOrPhysicalDefects" />
      <Input
        id="description"
        multiline
        placeholder="Example: Any cosmetic or physical defects present on the appliance"
      />
      <Input sort multiple options={orgs} id="vendorIds" label="Vendors" />
    </Form>
  );
}
