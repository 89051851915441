import { U } from '@nanaio/util';
import type { Data } from '@nanaio/util/dist/analytics';
import { AnalyticsBrowser } from '@segment/analytics-next';
import _ from 'lodash';
import { isDevBuild, segmentWriteKey } from '@/config/const';

const { Logger, StandardPayload } = U.analytics;

const payloadGetterByKey = StandardPayload;

class SegmentLogger extends Logger {
  analytics?: AnalyticsBrowser;

  identify(id: string | undefined, traits: Record<string, unknown>): void {
    const args = _.compact([id, traits]);
    void this.analytics?.identify(...args);
  }

  reset() {
    void this.analytics?.reset();
  }

  navigate(event: string, data: Data, props: Record<string, unknown>): void {
    // don't want to mess up engagement metrics in prod
    if (U.user.isViewAsUser(data.me) && !isDevBuild) {
      return;
    }
    const payload = this.getPayload(data, props);
    if (isDevBuild) {
      // eslint-disable-next-line no-console
      console.log(event, payload);
    }
    void this.analytics?.page(event, payload, {
      traits: payload.features as Record<string, boolean>,
    });
  }

  start() {
    this.analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
  }

  track(event: string, data: Data, props: Record<string, unknown>): void {
    // don't want to mess up engagement metrics in prod
    if (U.user.isViewAsUser(data.me) && !isDevBuild) {
      return;
    }
    const payload = this.getPayload(data, props);
    if (isDevBuild) {
      // eslint-disable-next-line no-console
      console.log(event, payload);
    }
    void this.analytics?.track(event, payload, {
      traits: payload.features as Record<string, boolean>,
    });
  }
}
// @ts-expect-error TODO use mappable type instead of record in order to support optional keys
export default new SegmentLogger(payloadGetterByKey);
