import React from 'react';

type Props = {
  button: JSX.Element;
  menu: JSX.Element;
};

export default function HoverPopup({ button, menu }: Props): JSX.Element {
  return (
    <div className="group relative inline-block">
      <div className="relative inline-block">{button}</div>
      <div
        className="absolute right-0 top-[100%] z-[99999999] hidden min-w-[250px] border border-grey-medium 
    bg-white opacity-0 shadow-floating group-hover:flex group-hover:opacity-100"
      >
        {menu}
      </div>
    </div>
  );
}
