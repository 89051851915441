import React, { ChangeEvent, ReactNode, useCallback, useRef, useState } from 'react';
import { Task } from '@nanaio/util';
import compression from 'browser-image-compression';
import _ from 'lodash';
import { workOrderEvent } from '@/com/analytics';
import { Alert, APIError, Button, Icon, Loader, Modal, Text } from '@/components';
import List, { ListItemProps } from '@/components/pages/user/FTCBookingFlow/List';
import { useLazyLegacyAPI } from '@/hooks';
import { apiOrigin, supportArticleUrls } from '../../../../config/const';
import type { ModelStepProps as Props } from './ApplianceModelStep';
import ModelNumToolTip from './ModelNumTooltip';

const instructionListItems: ListItemProps[] = [
  {
    description: 'Locate the model number sticker on your appliance',
    link: {
      title: 'Where can I find this?',
      url: supportArticleUrls.modelStickerHelp,
    },
  },
  {
    description: 'Position your camera in front of the sticker on your appliance',
  },
  {
    description: 'Take a photo',
  },
  {
    description: 'Check that the model no. and serial no. are legible',
  },
];

const PLACEHOLDER_PHOTO_URL = '../../../../img/customerPortal/modelStickerPlaceholder.png';
const PHOTO_OPTIONS = {
  maxSizeMB: 0.75,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export default function ApplianceModelPhotoStep({
  task,
  onNextSubStep,
  onToggleEntryMethod,
  onCantFind,
  updateError,
}: Props): JSX.Element {
  const [imageError, setImageError] = useState<ReactNode | undefined>();
  const uploadRef = useRef<HTMLInputElement>(null);
  const picturesById = task.metadata.taskProblemQuestions?.pictures || {};
  const pictureId = Object.keys(picturesById)[0];
  const pictureUrl = pictureId
    ? `${apiOrigin}/images${picturesById[pictureId].url}`
    : PLACEHOLDER_PHOTO_URL;

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={
          <>
            Unable to save changes to model number
            <br />
            {error}
          </>
        }
      />
    ),
    save: true,
    method: 'put',
  });

  const handleRemoveImage = useCallback(async () => {
    const changes = [
      {
        action: 'remove',
        path: `metadata.taskProblemQuestions.pictures.${pictureId}`,
      },
    ];
    await updateTask(changes);
  }, [pictureId, updateTask]);

  const handleNext = useCallback(async () => {
    const eventInfo = {
      variant: 'ftc',
      step: 'modelAndSerial',
      couldNotFind: false,
      photoUploaded: true,
    };

    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);
    await onNextSubStep();
  }, [onNextSubStep]);

  const handleUpload = useCallback(async () => {
    if (pictureId) {
      await handleRemoveImage();
    }
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }, [handleRemoveImage, pictureId]);

  const handlePickImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setImageError(undefined);
      if (event.currentTarget.files === null) {
        return;
      }
      if (!_.startsWith(event.currentTarget.files[0].type, 'image')) {
        setImageError(
          <Alert variant="error" className="mb-10">
            Only images may be uploaded at this stage.
          </Alert>
        );
        return;
      }

      const reader = new FileReader();
      const blob = await compression(event.currentTarget.files[0], PHOTO_OPTIONS);
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const changes = [
          {
            action: 'add',
            path: 'metadata.taskProblemQuestions.pictures',
            value: reader.result,
            tag: 'model',
          },
        ];
        await updateTask(changes);
      };
    },
    [updateTask]
  );

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          {taskToUpdate.error}
          {updateError}
          {imageError}
          <Text className="mt-6 text-center text-font-dark" type="headline-6">
            Take a Photo of the Model Number Sticker
          </Text>
          <Text className="mt-4 text-center text-grey-dark">
            Make sure the model no. and serial no. is captured
          </Text>
          <div className="mt-4 flex w-full justify-center">
            <ModelNumToolTip />
          </div>
          <div className="relative mt-10 w-full overflow-hidden rounded-lg bg-background-light ring-[1px] ring-icons-grey">
            <img className="mx-auto" src={pictureUrl} alt="" style={{ maxHeight: 285 }} />
            {taskToUpdate.loading && (
              <div className="absolute left-0 top-0 h-[285px] w-full bg-grey-light bg-opacity-40">
                <Loader className="absolute left-1/2 top-[140px]" size="large" isLoading />
              </div>
            )}
            {pictureId && (
              <div className="absolute bottom-4 right-4">
                <Button
                  variant="outline"
                  size="xlarge"
                  onClick={handleRemoveImage}
                  disabled={taskToUpdate.loading}
                >
                  <Icon name="trash_can_outline" size={16} />{' '}
                </Button>
              </div>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handlePickImage}
            style={{ display: 'none' }}
            ref={uploadRef}
          />

          <Button className="mt-6 w-full" onClick={handleUpload} disabled={taskToUpdate.loading}>
            {pictureId ? 'Retake Photo' : 'Upload Photo'}
          </Button>

          <List
            className="mt-12"
            header="Instructions"
            items={instructionListItems}
            variant="steps"
          />
          <div className="flex  h-[18px] w-full justify-center border-b-[1px] border-grey-dark">
            <Text className="w-[48px] bg-white px-4 py-2.5 text-grey-dark" type="label">
              or
            </Text>
          </div>

          <Button className="mt-6 w-full" onClick={onToggleEntryMethod} variant="outline">
            Manually Enter
          </Button>
          <div className="flex w-full justify-center">
            <Button className="mt-2 w-full" onClick={onCantFind} variant="link">
              I couldn't find the sticker
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button
          className="w-full"
          onClick={handleNext}
          submit
          disabled={!pictureId || taskToUpdate.loading}
        >
          Next
        </Button>
      </Modal.Footer>
    </>
  );
}
