import React, { useRef, useState } from 'react';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Button, Modal, Text } from '@/components';
import { companyName } from '@/config/const';
import { useLegacySelector } from '@/hooks';
import SchedulePicker, { SchedulePickerRef } from './SchedulePicker';
import Survey from './survey';

const headlineByVariant = {
  newVisit: 'Select Possible Arrival Windows',
  confirm: 'Confirm Visit Time',
  reschedule: 'Select New Possible Arrival Windows',
};

type Props = {
  isOpen: boolean;
  onSuccess?: () => void;
  variant: 'newVisit' | 'confirm' | 'reschedule';
  onClose: () => void;
};

export default function ScheduleModal({ isOpen, onSuccess, variant, onClose }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[nullthrows(global.id())];
  const form = useLegacySelector(state => state.form);
  const { waitingOnScheduleFlow } = form;
  if (!onSuccess) {
    // Most callers don't need to differentiate functionality between onClose and onSuccess
    // So for most cases we just set the onSuccess function to close the window.
    // If a caller needs custom functionality though they can set their own onSuccess function.
    onSuccess = onClose;
  }

  const [success, setSuccess] = useState(false);
  const ref = useRef<SchedulePickerRef>(null);

  const shouldShowSurvey = () => {
    return !_.get(task, 'visits.1') && !_.get(task, 'metadata.taskQuestions.hadPreviousTechnician');
  };

  const handleSuccess = () => {
    if (onSuccess && waitingOnScheduleFlow) {
      onSuccess();
    }

    setSuccess(true);
    if (!shouldShowSurvey()) {
      onClose();
    }
  };

  const onClick = () => {
    if (ref.current) {
      void ref.current.submit();
    }
  };

  if (success && shouldShowSurvey()) {
    return (
      <Modal height={800} hideOnBackdropClick={false} isOpen={isOpen} onClose={onClose} width={700}>
        <Modal.Header />
        <Modal.Body>
          <div>
            <Text className="py-4 text-center" type="headline-6">
              Tell us More
            </Text>
            <hr />
            <div className="p-4">
              <Text>
                Thank you for the opportunity to service your appliance(s). Please take a moment to
                answer the question below, your response will help us maintain a great quality of
                service.
              </Text>
              <Text className="mb-5 mt-3">We appreciate you here at {companyName}. Thanks.</Text>
              <Survey onComplete={onSuccess} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal height={800} hideOnBackdropClick={false} isOpen={isOpen} onClose={onClose} width={700}>
      <Modal.Header />
      <Modal.Body>
        <div>
          <Text className="py-4 text-center" type="headline-6">
            {headlineByVariant[variant]}
          </Text>
          <hr />
          <div>
            <div className="p-4">
              <SchedulePicker onSuccess={handleSuccess} ref={ref} variant={variant} />
              {variant === 'confirm' && (
                <div>
                  Please confirm one of the times your technician has selected OR select 3 or more
                  timeslots, 2 of which must be 24 hours in the future
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={onClick}>
          Submit Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
