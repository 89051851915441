import React, { useEffect, useMemo, useState } from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import nullthrows from 'nullthrows';
import pluralize from 'pluralize';
import { Alert, Loader, Modal, Text } from '@/components';
import { useLegacyAPI, useLegacySelector } from '@/hooks';
import type { DraftBoost } from './BoostJob';
import Pro, { AvailabilityPro } from './Pro';

type Availability = {
  data: { pros: { [key: string]: AvailabilityPro } };
};

type Props = {
  draftBoost: DraftBoost;
  onCancel: () => void;
  onSave: (draftAudience: string[]) => void;
};

export default function TargetAudience({ draftBoost, onCancel, onSave }: Props): JSX.Element {
  const audience = useMemo(() => _.sortBy(draftBoost.pros?.map(pro => pro.id) || []), [draftBoost]);
  const [draftAudience, setDraftAudience] = useState(audience);
  const [numNoLongerEligible, setNumNoLongerEligible] = useState(0);

  const { jobId, workOrder } = useLegacySelector(state => {
    const jobId = nullthrows(global.id());
    const job = state.tasks[jobId];
    const workOrder = state.workorders[job.metadata.workOrderId];
    return { jobId, workOrder };
  });

  const { loading, error, data } = useLegacyAPI<Availability>('availability', {
    body: {
      jobId,
      startTime: moment().startOf('day'),
      endTime: moment().add(1, 'week'),
      options: {
        excludeAvailabilityTimeSlots: true,
        includeData: true,
      },
    },
    method: 'post',
  });

  const pros = useMemo<AvailabilityPro[] | undefined>(() => {
    if (data) {
      const pros = _.values(data.data.pros)
        .filter(pro => pro.role !== U.pro.Role.MENTEE)
        .map(pro => {
          const homeDistance = U.distance(
            pro.user.address?.geoCoordinates,
            workOrder?.cx?.address?.geoCoordinates
          );
          const task = workOrder.tasks.find(task =>
            _.get(task, `metadata.opportunityDeclined.${pro.id}`)
          );
          return {
            ...pro,
            homeDistance: homeDistance == null ? homeDistance : _.round(homeDistance),
            // @ts-expect-error TODO fix task
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            declinedReasons: task && task.metadata.opportunityDeclined[pro.id],
          };
        });
      return _.orderBy(
        pros,
        [pro => audience.indexOf(pro.id), 'homeDistance', 'stats.ratingAutomatic'],
        ['desc', 'asc', 'desc']
      );
    }
  }, [data, audience, workOrder]);

  useEffect(() => {
    if (data) {
      const prosNoLongerEligible = _.difference(draftAudience, _.keys(data.data.pros));
      if (prosNoLongerEligible.length) {
        setDraftAudience(_.without(draftAudience, ...prosNoLongerEligible));
        setNumNoLongerEligible(prosNoLongerEligible.length);
      }
    }
  }, [data, draftAudience]);

  const handleSelect = (proId: string) => {
    if (draftAudience.includes(proId)) {
      setDraftAudience(_.without(draftAudience, proId));
    } else {
      // ensure the draft audience is always ordered the same in order to only enable saving when
      // it's actually changed
      setDraftAudience(_.sortBy(draftAudience.concat(proId)));
    }
  };

  const handleSave = () => {
    onSave(draftAudience);
  };

  const body = loading ? <Loader className="mx-auto my-4" isLoading size="large" /> : error;

  const canSave = !_.isEqual(audience, draftAudience) && draftAudience.length;

  return (
    <>
      <Modal.Header onBack={onCancel} title="Target Audience" />
      <Modal.Body className="p-4">
        <Text className="mt-4 text-center" type="headline-6">
          Who should see this boost?
        </Text>
        <Text className="mb-6 text-center" color="grey.dark">
          Select the techs you want to reach with this boost.
        </Text>
        {!!numNoLongerEligible && (
          <Alert variant="warning">
            {pluralize('technician', numNoLongerEligible, true)} no longer eligible for boost
          </Alert>
        )}
        {pros
          ? pros.map(pro => (
              <Pro
                isSelected={draftAudience.includes(pro.id)}
                key={pro.id}
                onSelect={handleSelect}
                pro={pro}
              />
            ))
          : body}
      </Modal.Body>
      <Modal.Footer
        closeText="Cancel"
        onClose={onCancel}
        onSave={handleSave}
        disableSaveButton={!canSave}
      />
    </>
  );
}
