import React, { useCallback, useEffect,useMemo, useState } from 'react';
import { DiagnosisOptions, T, Task, TaskDiagnosis } from '@nanaio/util';
import _ from 'lodash';
import { APIError, FormControl, Modal, SearchInput, Text, TextInput } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import DiagnosisDeletionControls from './DiagnosisDeletionControls';

const DIAGNOSIS_MIN_LENGTH = 50;

const DIAGNOSIS_NOTES = [
  'What inspections or tests were performed?',
  'How did those test(s) confirm your diagnosis?',
  'What work needs to be done?',
  'What part needs to be replaced?',
];

type Props = {
  code?: string[];
  diagnosis?: string;
  diagnosisIndex: number;
  diagnosisOptions?: DiagnosisOptions;
  diagnosisQuestionId: string;
  isAssociateProblemWithDiagnosisEnabled: boolean;
  isMTDiagnosis: boolean;
  onClose: () => void;
  problem?: string;
  task: Task;
};

export default function DiagnosisModal({
  code,
  diagnosis,
  diagnosisIndex,
  diagnosisOptions = {},
  diagnosisQuestionId,
  isAssociateProblemWithDiagnosisEnabled,
  isMTDiagnosis,
  onClose,
  problem,
  task,
}: Props): JSX.Element {
  const { problemOptions } = useLegacySelector(state => {
    const { brands, services } = state;
    const orgId = T.orgId(task) || '';
    const org = state.orgs[orgId];
    const user = state.users[state.me.userId];
    const workOrder = state.workorders[task.metadata.workOrderId];
    const questions = T.questions({ brands, org, services, task, user, workOrder });
    const problemQuestion = questions.find(question => T.isProblemQuestion(question.id));
    const problemOptions = problemQuestion?.options || {};
    return { problemOptions };
  });
  const [levels, setLevels] = useState<string[]>([]);
  const [text, setText] = useState('');
  const [localProblem, setLocalProblem] = useState('');

  const {
    canDeleteDiagnosis,
    isNew,
    isOpen,
    mostRecentDiagnosis,
    partsTiedOnlyToThisDx,
    partsTiedToMultipleDx,
  } = useMemo(() => {
    const isOpen = !_.isUndefined(code);
    const isNew = isOpen && code.length === 0;
    const mostRecentDiagnosis = T.getMostRecentDiagnosis(task, diagnosisIndex);
    const parts = task.parts?.itemsArray || [];
    const partsAllowingDxDeletion = parts.filter(
      part =>
        (part.associatedDiagnosesIndexes || []).includes(diagnosisIndex) &&
        T.partAllowsDiagnosisDeletion(part)
    );
    const [partsTiedOnlyToThisDx, partsTiedToMultipleDx] = _.partition(
      partsAllowingDxDeletion,
      part => {
        const { associatedDiagnosesIndexes = [] } = part;

        return associatedDiagnosesIndexes.length === 1;
      }
    );

    let canDeleteDiagnosis = !isNew;

    // if there is at least one part that is only associated to this dx, then it can only be deleted if:
    // * the part is in a status that allows the dx to be deleted
    // * there is analternative dx to associate the part with
    if (
      canDeleteDiagnosis &&
      !isMTDiagnosis && // FIXME: @syntaxbliss Parts tied to MT diagnoses will be covered in a future PR
      T.canDeleteDiagnosis(task, diagnosisIndex) &&
      partsTiedOnlyToThisDx.length > 0
    ) {
      canDeleteDiagnosis = !_.isUndefined(mostRecentDiagnosis);
    }

    return {
      canDeleteDiagnosis,
      isNew,
      isOpen,
      mostRecentDiagnosis,
      partsTiedOnlyToThisDx,
      partsTiedToMultipleDx,
    };
  }, [code, task, diagnosisIndex, isMTDiagnosis]);

  const getOptionsForLevel = useCallback(
    (level: number): string[] => {
      // we are against the first level, so we return the base options
      if (!level) {
        return Object.keys(diagnosisOptions);
      }

      // get the next level of diagnosis options down the tree based on the user's selection
      const path = levels.slice(0, level).join('.');
      const data = _.get(diagnosisOptions, path, {});

      // we are against a leaf, there are no more options down the tree
      if (data === true || (_.isObject(data) && '_leaf' in data)) {
        return [];
      }

      return Object.keys(data);
    },
    [diagnosisOptions, levels]
  );

  const handleChange = useCallback(
    (level: number, value: string) => {
      // the "slice" is for saving the case when a user already having a selection at a deeper level changes a
      // selection at a previous level. in that case we need to prune all selections after the lowest level selected.
      const newLevels = levels.slice(0, level + 1);

      newLevels[level] = value;

      setLevels(newLevels);
    },
    [levels]
  );

  const disableSaveButton = useMemo(() => {
    const leafReached = getOptionsForLevel(levels.length).length === 0;
    const diagnosisEntered = (text || '').trim().length >= DIAGNOSIS_MIN_LENGTH;
    const problemEntered =
      (localProblem && isAssociateProblemWithDiagnosisEnabled) ||
      !isAssociateProblemWithDiagnosisEnabled;

    return !leafReached || !diagnosisEntered || !problemEntered;
  }, [getOptionsForLevel, levels, text, localProblem, isAssociateProblemWithDiagnosisEnabled]);

  useEffect(() => {
    setLevels(code?.length ? code.slice(0) : []);
    setText(diagnosis || '');
    setLocalProblem(problem || '');
  }, [code, diagnosis, problem]);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to save questions</>} />
    ),
    save: true,
    method: 'put',
  });

  const handleRemoveDiagnosis = useCallback(async () => {
    if (diagnosisIndex === undefined) {
      return;
    }
    const diagnoses = _.get(task, diagnosisQuestionId, []) as TaskDiagnosis[];
    const filteredDiagnoses = diagnoses.filter((_, index) => index !== diagnosisIndex);
    const changes = [{ path: diagnosisQuestionId, value: filteredDiagnoses }];
    const response = await updateTask(changes);
    if (!response) {
      return;
    }
    onClose();
  }, [diagnosisIndex, diagnosisQuestionId, onClose, task, updateTask]);

  const handleSaveDiagnosis = useCallback(async () => {
    if (diagnosisIndex === undefined) {
      return;
    }
    const data = { code: levels, diagnosis: text, problem: localProblem };
    const changes = [
      {
        path: `${diagnosisQuestionId}.${diagnosisIndex.toString()}`,
        value: data,
      },
    ];
    const response = await updateTask(changes);
    if (!response) {
      return;
    }
    onClose();
  }, [diagnosisIndex, diagnosisQuestionId, levels, localProblem, onClose, text, updateTask]);

  const showLevels =
    !isAssociateProblemWithDiagnosisEnabled ||
    (isAssociateProblemWithDiagnosisEnabled && localProblem);
  return (
    <Modal isOpen={isOpen} onClose={onClose} hideOnBackdropClick={false}>
      <Modal.Header
        title={`${isNew ? 'Add' : 'Edit'} ${isMTDiagnosis ? 'Master Tech' : 'Tech'} Diagnosis`}
      />
      <Modal.Body className="p-4">
        <div className="grid gap-2">
          {taskToUpdate.error}
          {isAssociateProblemWithDiagnosisEnabled && (
            <div>
              <FormControl className="mt-4" label="Select Problem" required>
                <SearchInput
                  options={problemOptions}
                  onChange={value => setLocalProblem(value as string)}
                  value={localProblem}
                />
              </FormControl>
            </div>
          )}
          {showLevels &&
            _.times(levels.length + 1).map(currentLevel => {
              const options = getOptionsForLevel(currentLevel);
              const isFirstChild = currentLevel === 0;

              if (options.length) {
                const control = (
                  <SearchInput
                    onChange={value => handleChange(currentLevel, value as string)}
                    options={options}
                    value={levels[currentLevel]}
                  />
                );

                if (isFirstChild) {
                  return (
                    <FormControl
                      key={levels[currentLevel]}
                      className="mb-0"
                      label="Select Root Cause"
                      required
                    >
                      {control}
                    </FormControl>
                  );
                }

                return React.cloneElement(control, { key: levels[currentLevel] });
              }

              return (
                <div key={levels[currentLevel]}>
                  <FormControl className="mb-0 mt-4" label="Describe Your Diagnosis" required>
                    <Text type="helper">
                      <ul className="ml-4 mt-1 list-disc">
                        {DIAGNOSIS_NOTES.map((note, index) => (
                          <li key={index}>
                            <Text type="helper">{note}</Text>
                          </li>
                        ))}
                      </ul>
                    </Text>
                  </FormControl>
                  <FormControl>
                    <TextInput
                      className="mt-2"
                      innerClassName="resize-none"
                      minLength={DIAGNOSIS_MIN_LENGTH}
                      multiline
                      onChange={value => setText(value as string)}
                      value={text}
                    />
                  </FormControl>
                </div>
              );
            })}
        </div>

        {!isNew && (
          <DiagnosisDeletionControls
            diagnosisIndex={diagnosisIndex}
            isMTDiagnosis={isMTDiagnosis}
            mostRecentDiagnosis={mostRecentDiagnosis}
            onDeleteButtonClick={canDeleteDiagnosis ? handleRemoveDiagnosis : undefined}
            partsTiedOnlyToThisDx={partsTiedOnlyToThisDx}
            partsTiedToMultipleDx={partsTiedToMultipleDx}
            task={task}
          />
        )}
      </Modal.Body>

      <Modal.Footer disableSaveButton={disableSaveButton} onSave={handleSaveDiagnosis} />
    </Modal>
  );
}
