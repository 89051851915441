import React, { useState } from 'react';
import _ from 'lodash';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Tooltip from '../../core/Tooltip';
import { ReactSelectOption } from './util';

type GroupProps = {
  depth?: number;
  maxDepth: number;
  options: ReactSelectOption[];
  placement?: 'left' | 'right';
  setHoverOption: (value?: ReactSelectOption) => void;
  sort?: boolean;
  value?: unknown | unknown[];
  width: number;
};

const Groups = ({
  depth = 0,
  maxDepth,
  options,
  setHoverOption,
  placement,
  sort,
  value: propValue,
  width,
}: GroupProps) => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>();

  const groups = _.groupBy(options, `group.${depth}`);
  let newOptions = [
    ..._.keys(groups)
      .filter(key => key !== 'undefined')
      .map(
        key =>
          ({ children: groups[key], disabled: true, label: key, value: key }) as ReactSelectOption
      ),
    ..._.values(groups.undefined),
  ];
  if (sort) {
    newOptions = newOptions.sort((a, b) => {
      if (Boolean(a.children) !== Boolean(b.children)) {
        return a.children ? -1 : 1;
      }
      return (a.menuLabel || a.label) < (b.menuLabel || b.label) ? -1 : 1;
    });
  }
  const hasChildren = newOptions.some(option => option.children);

  return (
    <div
      className={`rounded border border-grey-medium bg-white py-1 ${
        hasChildren ? '' : 'overflow-y-auto'
      }`}
      style={{ maxHeight: hasChildren ? undefined : 406, width: width }}
    >
      {newOptions.map(({ children, description, value, ...rest }: ReactSelectOption, i) => {
        const option = { children, description, value, ...rest };
        return (
          <div
            className="relative"
            key={value}
            onMouseEnter={() => setHoverIndex(i)}
            onMouseLeave={() => setHoverIndex(0)}
          >
            {children && hoverIndex === i && (
              <div
                className="absolute"
                style={{
                  left: placement === 'left' ? -(depth + 1) * width : undefined,
                  right: placement === 'left' ? undefined : -width,
                  top: -4,
                  width: width,
                }}
              >
                <Groups
                  depth={depth + 1}
                  maxDepth={maxDepth}
                  options={children}
                  setHoverOption={setHoverOption}
                  value={propValue}
                  width={width}
                />
              </div>
            )}
            <Tooltip
              className={`flex ${
                placement === 'left' ? '' : 'justify-between'
              } px-3 py-2 hover:bg-primaryCTA hover:bg-opacity-50 ${
                option.value === propValue ? 'bg-primaryCTA' : 'bg-white'
              }`}
              iconColor={value === propValue ? 'white' : undefined}
              node={description}
              onMouseEnter={() => setHoverOption(option)}
              onMouseLeave={() => setHoverOption()}
            >
              {option.children && placement === 'left' && (
                <Icon className="mr-4" name="chevron_left" />
              )}
              <Text
                color={option.value === propValue ? 'white' : undefined}
                cypressId={`option-${String(value)}`}
              >
                {option.menuLabel || option.label}
              </Text>
              {option.children && placement !== 'left' && <Icon name="chevron_right" />}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

type Props = {
  formattedOptions: ReactSelectOption[];
  inputValue?: string;
  placement?: 'left' | 'right';
  setHoverOption: (value?: ReactSelectOption) => void;
  sort?: boolean;
  value?: unknown | unknown[];
  width: number;
};

export default function Multilevel({
  formattedOptions,
  inputValue,
  placement,
  setHoverOption,
  sort,
  value,
  width,
}: Props): JSX.Element {
  let newFormattedOptions = formattedOptions;
  const maxDepth = Number(_.max(_.map(newFormattedOptions, 'group.length'))) + 1;
  if (inputValue) {
    newFormattedOptions = newFormattedOptions.filter(option =>
      option.label.toLowerCase().includes(inputValue)
    );
  }

  return (
    <div
      className="overflow-y-auto"
      style={{
        height: '50vh',
        paddingLeft: placement === 'left' ? (maxDepth - 1) * width : undefined,
        marginLeft: placement === 'left' ? -(maxDepth - 1) * width : undefined,
        width: maxDepth * width,
      }}
    >
      <Groups
        options={newFormattedOptions}
        {...{ maxDepth, placement, setHoverOption, sort, value, width }}
      />
    </div>
  );
}
