import React from 'react';
import * as Table from '@nanaio/table';
import U, { ServerEntity } from '@nanaio/util';
import _ from 'lodash';
import TableComponent from './index'; // eslint-disable-line import/no-cycle
import * as typeToModuleUi from './moduleTypes';
import EditModule from './popups/EditModule';

type Props = {
  moduleIdToModule: Record<string, Table.Column>;
  onClose: () => void;
  table: string;
};

export default function Columns({ moduleIdToModule, onClose, table }: Props): JSX.Element {
  const settings = {
    ...Table.databases.default.column,
    addUi: (props: Table.AddUiProps) => <EditModule {...props} />, // eslint-disable-line react/jsx-props-no-spreading
    editUi: (props: Table.EditUiProps) => <EditModule {...props} />, // eslint-disable-line react/jsx-props-no-spreading
    loadFunc: async ({ apiQuery }: { apiQuery: { _id?: string } }): Promise<ServerEntity[]> => {
      const modules = _.sortBy(_.values(moduleIdToModule), 'name');
      if (apiQuery._id) {
        const partialModule = await U.api<Partial<Table.Column>>('get', `column/${apiQuery._id}`);
        if (!('errMsg' in partialModule)) {
          const module = Table.getColumn({
            databaseId: Table.DatabaseId.DEFAULT,
            module: partialModule,
            table,
            typeToModuleUi,
          });
          return _.uniqBy([module, ...modules], 'id') as unknown as ServerEntity[];
        }
      }
      return modules as unknown as ServerEntity[];
    },
    name: table,
    onCloseModules: onClose,
    parentModuleIdToModule: moduleIdToModule,
  };

  return <TableComponent {...settings} />; // eslint-disable-line react/jsx-props-no-spreading
}
