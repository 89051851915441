import React from 'react';
import { Text } from '@/components';
import { companyLogoAndName, companyName } from '@/config/const';

export default function SiteDown() {
  return (
    <div className="d-flex container" style={{ height: '100vh' }}>
      <div className="flex flex-col justify-center">
        <img className="mb-5" src={companyLogoAndName} style={{ width: '50%' }} alt={companyName} />
        <Text type="subtitle-2">Sorry, we&apos;re down for scheduled maintenance right now.</Text>
      </div>
    </div>
  );
}
