import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Table from '@/components/Table';
import { settings } from '../workOrder/table';

const JobsTable = p => {
  const queryOverride = {
    columns: [
      {
        key: 'vendorName',
        search: { text: _.get(p, 'org.name'), value: { include: [_.get(p, 'org.id')] } },
      },
    ],
  };
  return <Table {...settings} queryOverride={queryOverride} />; // eslint-disable-line react/jsx-props-no-spreading
};

export default connect(s => ({ org: s.orgs[global.id()] }))(JobsTable);
