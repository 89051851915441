import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Icon } from '@/components';
import ChangeArrivalInstructions from '../../../actions/ChangeArrivalInstructions';
import ChangeCustomer from '../../../actions/ChangeCustomer';
import PricingPackageDetails from './PricingPackageDetails';
import TermsAcceptedDate from './TermsAcceptedDate';

const icon = {
  user: 'account_circle',
  email: 'email_outline',
  phone: 'phone',
  card: 'credit_card',
  home: 'home',
};

class Sidebar extends React.Component {
  constructor(p) {
    super(p);
    this.state = { timezone: U.timezone(this.props.job.serviceAddress) };
  }

  getData = job => {
    const checkIcon = 'p-check.svg';
    const inactiveIcon = 'p-dot.svg';
    const activeIcon = 'p-dot-active.svg';
    const errorIcon = 'p-dot-error.svg';
    return [
      {
        status: 'Request Received',
        icon:
          job.status === 'pendingConfirmation' || job.status === 'waitingOnSchedule'
            ? activeIcon
            : checkIcon,
        state:
          job.status === 'pendingConfirmation' || job.status === 'waitingOnSchedule'
            ? 'active'
            : '',
      },
      {
        status: job.status === 'cancelled' ? 'Cancelled' : 'Assigned',
        icon:
          job.status === 'assigned'
            ? activeIcon
            : job.status === 'pendingConfirmation' || job.status === 'waitingOnSchedule'
              ? inactiveIcon
              : job.status === 'cancelled'
                ? errorIcon
                : checkIcon,
        state: job.status === 'assigned' || job.status === 'cancelled' ? 'active' : '',
      },
      {
        status: job.status === 'proNoShow' ? 'Pro No Show' : 'Enroute',
        icon:
          job.status === 'enRoute'
            ? activeIcon
            : job.status === 'pendingConfirmation' ||
                job.status === 'waitingOnSchedule' ||
                job.status === 'assigned' ||
                job.status === 'cancelled'
              ? inactiveIcon
              : job.status === 'proNoShow'
                ? errorIcon
                : checkIcon,
        state: job.status === 'enRoute' || job.status === 'proNoShow' ? 'active' : '',
      },
      {
        status: 'On Site',
        icon:
          job.status === 'onSite'
            ? activeIcon
            : job.status === 'pendingConfirmation' ||
                job.status === 'waitingOnSchedule' ||
                job.status === 'assigned' ||
                job.status === 'enRoute' ||
                job.status === 'proNoShow' ||
                job.status === 'cancelled'
              ? inactiveIcon
              : checkIcon,
        state: job.status === 'onSite' ? 'active' : '',
      },
      {
        status: job.status === 'customerNoShow' ? 'Customer No Show' : 'In Progress',
        icon:
          job.status === 'pendingConfirmation' ||
          job.status === 'waitingOnSchedule' ||
          job.status === 'assigned' ||
          job.status === 'enRoute' ||
          job.status === 'onSite' ||
          job.status === 'proNoShow' ||
          job.status === 'cancelled'
            ? inactiveIcon
            : job.status === 'customerNoShow'
              ? errorIcon
              : checkIcon,
        state: '',
      },
      {
        status:
          job.status === 'needsFollowup'
            ? 'Needs Follow-Up'
            : job.status === 'partsRequested'
              ? 'Parts Requested'
              : job.status === 'partsReceived'
                ? 'Parts Received'
                : job.status === 'waitingOnApproval'
                  ? 'Waiting on Approval'
                  : job.status === 'partsOrdered'
                    ? 'Parts Ordered'
                    : 'Completed',
        icon:
          job.status === 'completed' ||
          job.status === 'needsFollowup' ||
          job.status === 'partsRequested' ||
          job.status === 'partsOrdered' ||
          job.status === 'partsReceived' ||
          job.status === 'waitingOnApproval'
            ? activeIcon
            : job.status === 'pendingConfirmation' ||
                job.status === 'waitingOnSchedule' ||
                job.status === 'assigned' ||
                job.status === 'enRoute' ||
                job.status === 'onSite' ||
                job.status === 'proNoShow' ||
                job.status === 'customerNoShow' ||
                job.status === 'cancelled'
              ? inactiveIcon
              : checkIcon,
        state:
          job.status === 'completed' ||
          job.status === 'needsFollowup' ||
          job.status === 'partsRequested' ||
          job.status === 'partsOrdered' ||
          job.status === 'partsReceived' ||
          job.status === 'waitingOnApproval'
            ? 'active'
            : '',
      },
    ];
  };

  getPhoneNumber = phoneNumber => {
    return _.get(this.state.phoneNumbers, `+1${phoneNumber}.carrier.type`, '');
  };

  getDurations = () => {
    return [
      'durationCreateToComplete',
      { id: 'durationCreateToVisitSchedule', type: 'hours' },
      'durationCreateToFirstVisit',
      'durationCompleteToNow',
    ]
      .map(v => {
        const id = _.get(v, 'id') || v;
        const type = _.get(v, 'type') || 'days';
        const name = _.startCase(id.replace('duration', ''));
        const value = T[id](this.props.job);
        const valueText =
          type === 'hours'
            ? `${_.round(U.millisecondsToHours(value), 1)} hours`
            : `${_.round(U.millisecondsToDays(value), 1)} days`;
        return { name, value, valueText };
      })
      .filter(v => v.value !== undefined);
  };

  render() {
    const { roles, job, taskId } = this.props;
    const confirmedContacts = _.filter(
      job.customer.user.confirmedContacts,
      v => ![job.customer.user.email, job.customer.user.phone].includes(v)
    );
    const durations = this.getDurations();
    const pastRecallId = job.recall?.pastId;
    const futureRecallId = job.recall?.futureId;
    return (
      <div className="task-column-side">
        <div className="card-block--border card-shadow bg-white" style={{ height: '100%' }}>
          {/*= ================== HEADER INFORMATION ============================ */}
          {pastRecallId && (
            <a
              className="d-block mb-3"
              href={`/tasks/${pastRecallId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className={T.isNanaRecall(job) ? 'bg-danger' : 'bg-accent-orange'}
                style={{
                  width: '100%',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ color: 'white', fontFamily: 'Myriad', fontSize: 16, fontWeight: 600 }}>
                  {T.isNanaRecall(job) ? 'This is a Recall' : 'This is a vendor recall only'}
                </p>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    background: 'none',
                    border: '2px solid white',
                    borderRadius: 3,
                  }}
                >
                  <p
                    style={{ color: 'white', fontFamily: 'Myriad', fontSize: 16, fontWeight: 600 }}
                  >
                    View Original Job
                  </p>
                </button>
              </div>
            </a>
          )}
          {futureRecallId && (
            <a
              className="d-block mb-3"
              href={`/tasks/${futureRecallId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  width: '100%',
                  background: '#E83A3A',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ color: 'white', fontFamily: 'Myriad', fontSize: 16, fontWeight: 600 }}>
                  Has a future recall
                </p>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    background: 'none',
                    border: '2px solid white',
                    borderRadius: 3,
                  }}
                >
                  <p
                    style={{ color: 'white', fontFamily: 'Myriad', fontSize: 16, fontWeight: 600 }}
                  >
                    View Recall
                  </p>
                </button>
              </div>
            </a>
          )}
          <div
            className="persona d-flex align-center border-bottom"
            style={{ paddingBottom: '16px' }}
          >
            {job.customer.user.fullName && (
              <div className="letter-persona mr-2">
                {_.first(job.customer.user.firstName)}
                {_.first(job.customer.user.lastName)}
              </div>
            )}
            <div>
              {job.customer.user.id && roles.customersupport ? (
                <a
                  href={`/users/${job.customer.user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4 className="weight-600 lh-15 mb-0">{job.customer.user.fullName}</h4>
                </a>
              ) : (
                <h4 className="weight-600 lh-15 mb-0">{job.customer.user.fullName}</h4>
              )}
              <p className="lh-15 mb-0">
                <span className="text-muted">
                  {_.get(job, 'customer.org.name', 'B2C Customer')}
                </span>
              </p>
            </div>
          </div>

          {/*= ================== CONTACT INFORMATION ============================ */}
          <div className="border-bottom py-4">
            <p className="weight-600">Customer Contact</p>
            {/*= ================== EMAIL ============================ */}
            <div className="flex py-2 align-top">
              <Icon name={icon.email} color="#A1A4AB" size={15} />
              <div className="ml-2">
                <label>Email Address</label>
                <div className="justify-content-between flex flex-wrap">
                  {U.isFakeEmail(job.customer.user.email) ? (
                    <p className="text-muted">none</p>
                  ) : (
                    <div className="flex flex-wrap">
                      <p data-cy="customerEmail" className="max-w-[200px] break-words">
                        {job.customer.user.email}
                        {_.get(job.customer.user.contacts, [
                          job.customer.user.email,
                          'bounced',
                        ]) && <span className="text-danger"> bounced</span>}
                      </p>
                      {_.includes(job.customer.user.confirmedContacts, job.customer.user.email) && (
                        <Icon className="ml-2 text-success" name="check_circle" size={16} />
                      )}
                    </div>
                  )}
                  {!U.isFakeEmail(job.customer.user.email) && (
                    <CopyToClipboard
                      text={job.customer.user.email}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <button className="btn btn-default icon copy-button-group">
                        <Icon name="content_copy" size={14} />
                        {this.state.copied ? <div className="snackbar">Copied.</div> : null}
                      </button>
                    </CopyToClipboard>
                  )}
                  <button
                    className="btn btn-default icon copy-button-group ml-1"
                    onClick={() => this.setState({ changeCustomer: true })}
                  >
                    <Icon name="edit" size={14} />
                  </button>
                </div>
              </div>
            </div>
            {/*= ================== ALT EMAIL ============================ */}
            {job.customer.user.altEmail && !U.isFakeEmail(job.customer.user.altEmail) && (
              <div className="d-flex py-2 align-top">
                <Icon email={icon.email} color="#A1A4AB" size={15} />
                <div className="ml-2 flex-1">
                  <label>Alt Email Address</label>
                  <div className="d-flex justify-content-between flex-wrap">
                    <p data-cy="customerEmail" style={{ overFlowWrap: 'break-word', flex: 1 }}>
                      {job.customer.user.altEmail}
                      {_.get(job.customer.user.contacts, [
                        job.customer.user.altEmail,
                        'bounced',
                      ]) && <span className="text-danger"> bounced</span>}
                      {_.includes(
                        job.customer.user.confirmedContacts,
                        job.customer.user.altEmail
                      ) && <Icon className="ml-2 text-success" name="check_circle" size={16} />}
                    </p>
                    <CopyToClipboard
                      text={job.customer.user.altEmail}
                      onCopy={() => this.setState({ copiedAltEmail: true })}
                    >
                      <button className="btn btn-default icon copy-button-group">
                        <Icon name="content_copy" size={14} />
                        {this.state.copiedAltEmail ? <div className="snackbar">Copied.</div> : null}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {/*= ================== PHONE ============================ */}
            <div className="d-flex py-2 align-top">
              <Icon name={icon.phone} color="#A1A4AB" size={15} />
              <div className="ml-2 flex-1">
                <label>Phone Number</label>
                {job.customer.user.phone ? (
                  <div className="d-flex justify-content-between">
                    <div className="flex flex-wrap">
                      <p className="flex-1">
                        {job.customer.user.phone}{' '}
                        {_.get(job.customer.user.contacts, [
                          `+1${job.customer.user.phone}`,
                          'type',
                        ])}
                        {_.get(job.customer.user.contacts, [
                          `+1${job.customer.user.phone}`,
                          'error',
                        ]) && <span className="text-danger"> error</span>}
                      </p>
                      {_.includes(job.customer.user.confirmedContacts, job.customer.user.phone) && (
                        <Icon className="ml-2 text-success" name="check_circle" size={16} />
                      )}
                    </div>
                    <CopyToClipboard
                      text={job.customer.user.phone}
                      onCopy={() => this.setState({ copied2: true })}
                    >
                      <button className="btn btn-default icon copy-button-group">
                        <Icon name="content_copy" size={14} />
                        {this.state.copied2 ? <div className="snackbar">Copied.</div> : null}
                      </button>
                    </CopyToClipboard>
                  </div>
                ) : (
                  <p className="text-muted">none</p>
                )}
              </div>
            </div>
            {/*= ================== ADDITIONAL CONTACTS ============================ */}
            {!!confirmedContacts.length && (
              <div className="d-flex py-2 align-top">
                <div className="ml-2 flex-1">
                  <label>Additional Confirmed Contacts</label>
                  <br />
                  {confirmedContacts.map(v => _.trim(`${v} ${this.getPhoneNumber(v)}`)).join(', ')}
                  <Icon className="ml-2 text-success" name="check_circle" size={16} />
                </div>
              </div>
            )}
            {/*= ================== ALT PHONE ============================ */}
            {!!job.customer.user.altPhone && (
              <div className="d-flex py-2 align-top">
                <Icon name={icon.phone} color="#A1A4AB" size={15} />
                <div className="ml-2 flex-1">
                  <label>Alt Phone Number</label>
                  <div className="d-flex justify-content-between">
                    <p className="flex-1">
                      {job.customer.user.altPhone} {this.getPhoneNumber(job.customer.user.altPhone)}
                    </p>
                    <CopyToClipboard
                      text={job.customer.user.altPhone}
                      onCopy={() => this.setState({ copied3: true })}
                    >
                      <button className="btn btn-default icon copy-button-group">
                        <Icon name="content_copy" size={14} />
                        {this.state.copied3 ? <div className="snackbar">Copied.</div> : null}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
            {/*= ================== ARRIVAL INSTRUCTIONS ============================ */}
            <section className="d-flex py-2 align-top">
              <Icon name={icon.home} color="#A1A4AB" size={15} />
              <div className="ml-2 flex-1">
                <label>Arrival Instructions</label>
                <div className="d-flex justify-content-between">
                  <p className="flex-1">{job.serviceAddress.arrivalInstructions ?? 'none'}</p>
                  <CopyToClipboard
                    text={job.serviceAddress.arrivalInstructions}
                    onCopy={() => this.setState({ copied4: true })}
                  >
                    <button className="btn btn-default icon copy-button-group">
                      <Icon name="content_copy" size={14} />
                      {this.state.copied4 ? <div className="snackbar">Copied.</div> : null}
                    </button>
                  </CopyToClipboard>
                  <button
                    className="btn btn-default icon copy-button-group ml-1"
                    onClick={() => this.setState({ changeArrivalInstructions: true })}
                  >
                    <Icon name="edit" size={14} />
                  </button>
                </div>
              </div>
            </section>
            {/*= ================== TIMEZONE ============================ */}
            <div>{this.state.timezone}</div>
          </div>

          {/*= ================== MANAGER INFORMATION ============================ */}
          {roles.customersupport && !!this.props.managers.length && (
            <div className="border-bottom py-4">
              <p className="weight-600">Managers</p>
              {this.props.managers
                .map(v => v.name)
                .sort()
                .join(', ')}
            </div>
          )}

          {/*= ================== LANDLORD INFORMATION ============================ */}
          {_.get(job, 'metadata.landlord') ? (
            <div className="border-bottom py-4">
              <p className="weight-600">Landlord Contact</p>
              <div className="d-flex py-2 align-top">
                <Icon name={icon.user} color="#A1A4AB" size={15} />
                <div className="ml-2">
                  <label>Full Name</label>
                  {_.get(job, 'metadata.landlord.name') ? (
                    <p>{_.get(job, 'metadata.landlord.name')}</p>
                  ) : (
                    <p className="text-muted">none</p>
                  )}
                </div>
              </div>
              <div className="d-flex py-2 align-top">
                <Icon name={icon.email} color="#A1A4AB" size={15} />
                <div className="ml-2 flex-1">
                  <label>Email Address</label>
                  {_.get(job, 'metadata.landlord.email') ? (
                    <div className="d-flex justify-content-between">
                      <p className="flex-1">{_.get(job, 'metadata.landlord.email')}</p>
                      <CopyToClipboard
                        text={_.get(job, 'metadata.landlord.email')}
                        onCopy={() => this.setState({ copied4: true })}
                      >
                        <button className="btn btn-default icon copy-button-group">
                          <Icon name="content_copy" size={14} />
                          {this.state.copied4 ? <div className="snackbar">Copied.</div> : null}
                        </button>
                      </CopyToClipboard>
                    </div>
                  ) : (
                    <p className="text-muted">none</p>
                  )}
                </div>
              </div>
              <div className="d-flex py-2 align-top">
                <Icon name={icon.phone} color="#A1A4AB" size={15} />
                <div className="ml-2 flex-1">
                  <label>Phone Number</label>
                  {_.get(job, 'metadata.landlord.phone') ? (
                    <div className="d-flex justify-content-between">
                      <p className="flex-1">{_.get(job, 'metadata.landlord.phone')}</p>
                      <CopyToClipboard
                        text={_.get(job, 'metadata.landlord.phone')}
                        onCopy={() => this.setState({ copied3: true })}
                      >
                        <button className="btn btn-default icon copy-button-group">
                          <Icon name="content_copy" size={14} />
                          {this.state.copied3 ? <div className="snackbar">Copied.</div> : null}
                        </button>
                      </CopyToClipboard>
                    </div>
                  ) : (
                    <p className="text-muted">none</p>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* DURATIONS */}
          {roles.customersupport && !!durations.length && (
            <div className="border-bottom py-4">
              {durations.map((v, i) => (
                <div key={i} className="d-flex justify-content-between py-2">
                  <label>{v.name}:</label>
                  <div>{v.valueText}</div>
                </div>
              ))}
            </div>
          )}

          {/*= ================== AUTH INFORMATION ============================ */}
          {T.type(this.props.org) === 'b2b' && (
            <div className="border-bottom py-4">
              <p className="weight-600">Authorization Details</p>
              <div className="d-flex justify-content-between py-2">
                <label>Auth No:</label>
                <div>{this.props.authNumberValue}</div>
              </div>
              <div className="d-flex justify-content-between py-2">
                <label>Claim No:</label>
                <div>{this.props.claimNumberValue}</div>
              </div>
              <div className="d-flex justify-content-between py-2">
                <label>Work Order:</label>
                <div>{_.get(this.props.job, 'metadata.workOrderNumber')}</div>
              </div>
              <div className="d-flex justify-content-between py-2">
                <label>Billing Work Order:</label>
                <div>{_.get(this.props.workOrder, 'billingWorkOrderNumber')}</div>
              </div>
              {_.get(this.props.workOrder, 'vendorPortalAccount') && (
                <div className="d-flex justify-content-between py-2">
                  <label>Vendor Portal Account:</label>
                  <div>{_.get(this.props.workOrder, 'vendorPortalAccount')}</div>
                </div>
              )}
            </div>
          )}

          <PricingPackageDetails task={job} />

          <TermsAcceptedDate workOrder={this.props.workOrder} />

          {/*= ================== CUSTOMER'S PAST JOBS CARDS ============================ */}
          <div className="py-4">
            <p className="weight-600 mb-2">
              {job.customer.user.firstName}&apos;s Other Jobs ({this.props.customerJobs.length})
            </p>
            {this.props.customerJobs.map((j, n) => (
              <a
                key={n}
                href={`/tasks/${j.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="card-block--border clickable d-block mb-2 mr-3 bg-white"
              >
                <div className="d-flex justify-content-between">
                  <div className="flex-1">
                    <p className="weight-600">
                      #
                      {`${j.index} ${_.get(j, 'metadata.taskQuestions.Make', '')} ${
                        j.title.split(' ')[0]
                      }`}
                    </p>
                    <p className="text-muted">
                      with{' '}
                      {_.get(
                        j,
                        `visits.0.assignee.user.${
                          roles.customersupport ? 'fullName' : 'firstName'
                        }`,
                        'Unassigned'
                      )}
                    </p>
                  </div>
                  <div className="text-right">
                    <label className="d-block">{m(T.lastVisitEnd(j)).format('MM/DD/YY')}</label>
                    <small className="d-block text-muted">
                      <Badge color="dark">
                        {_.get(j, 'status', '-') === 'pendingConfirmation'
                          ? `Pending`
                          : _.get(j, 'status', '-')}
                      </Badge>
                    </small>
                  </div>
                </div>
                {_.get(j, 'metadata.workOrderNumber') && (
                  <small>WO# {_.get(j, 'metadata.workOrderNumber')}</small>
                )}
              </a>
            ))}
          </div>
        </div>
        {this.state.changeCustomer && (
          <ChangeCustomer
            onClose={() => this.setState({ changeCustomer: false })}
            taskId={taskId}
          />
        )}
        {this.state.changeArrivalInstructions && (
          <ChangeArrivalInstructions
            task={this.props.job}
            onClose={() => this.setState({ changeArrivalInstructions: false })}
          />
        )}
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const roles = U.user.roles(s);
  const workOrder = s.workorders[job.metadata.workOrderId];
  let customerJobs = _.flatten(
    _.map(
      _.values(s.workorders).filter(
        workOrder =>
          workOrder.cx.id === job.customer.user.id || workOrder.cx.phone === job.customer.user.phone
      ),
      'tasks'
    )
  );
  customerJobs = _.sortBy(customerJobs, j => m(j.createTime).valueOf())
    .map((j, n) => ({ ...j, index: n + 1 }))
    .reverse()
    .filter(j => {
      if (j.id === job.id) {
        return false;
      }
      if (
        !roles.customersupport &&
        !T.visits(j).find(v => _.get(v, 'assignee.id') === s.me.proId)
      ) {
        return false;
      }
      return true;
    });
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const copay = T.copayAmount({ job, org });
  const authNumberValue = T.authNumberValue(job);
  const claimNumberValue = T.claimNumberValue(job);
  const regions = U.region.fromAddress(job.serviceAddress, s.regions).map(id => s.regions[id]);
  const managers = _.compact(_.uniqBy(_.flatten(regions.map(r => r.managers)), 'id'));
  return {
    job,
    org,
    customerJobs,
    copay,
    authNumberValue,
    claimNumberValue,
    roles,
    managers,
    workOrder,
  };
})(Sidebar);
