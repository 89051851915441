import React, { useRef, useState } from 'react';
import * as Table from '@nanaio/table';
import { ErrorResponse, Option } from '@nanaio/util';
import _ from 'lodash';
import { useDeepCompareMemoRef } from '@/hooks';
import { Button, Modal, Text } from '../../core';
import { Input, InputType } from '../../form';
import { BulkUpdateResult } from '../types';

type Props = {
  bulkUpdate?: Table.BulkUpdate;
  bulkUpdateResult?: BulkUpdateResult;
  checkedRowCount: number;
  column: Table.Column;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  isBulkUpdating: boolean;
  onBulkUpdate: () => Promise<void>;
  onBulkUpdateActionChange: (action: Table.BulkUpdate['action']) => void;
  onBulkUpdateValueChange: (value: unknown[]) => void;
  onClose: () => void;
  onFilterChange: (value?: unknown, propEditColumn?: Table.Column) => void;
  onSearchTypeChange: (type: keyof typeof Table.SearchType) => void;
  query: Table.Query;
  resetBulkUpdate: () => void;
  rows: Table.Row[];
  table: Table.Table;
};

export default function EditColumn({
  bulkUpdate,
  bulkUpdateResult,
  checkedRowCount,
  column,
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  isBulkUpdating,
  rows,
  onBulkUpdate,
  onBulkUpdateActionChange,
  onBulkUpdateValueChange,
  onClose,
  onFilterChange,
  onSearchTypeChange,
  query,
  resetBulkUpdate,
  table,
}: Props): JSX.Element {
  let buttonText = query.analytics.isOpen ? 'Done' : 'Search';
  if (isBulkUpdating) {
    buttonText = 'Bulk Update';
  }
  const canBulkUpdate = column.isEditable && !!checkedRowCount;
  const columnIndexRef = useRef(
    _.findIndex(
      query.columns,
      module => module.key === column.key && module.linkBy === column.linkBy
    )
  );
  const [filter, setFilter] = useState<Table.QueryField | undefined>(
    _.cloneDeep(query.columns[columnIndexRef.current].search?.value as Table.QueryField)
  );
  const filterRef = useDeepCompareMemoRef(filter);

  const handleFilterChange = (path?: string, value?: unknown) => {
    let filterCopy = _.cloneDeep(filterRef.current);
    if (path && filterCopy === undefined) {
      filterCopy = {};
    }
    if (path && typeof filterCopy === 'object') {
      filterCopy[path] = value;
    } else if (
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      typeof value === 'string'
    ) {
      filterCopy = value;
    }
    setFilter(filterCopy);
  };

  const handleSubmit = () => {
    if (!_.isEqual(filter, query.columns[columnIndexRef.current].search?.value)) {
      onFilterChange(filter);
    }
    if (isBulkUpdating) {
      void onBulkUpdate();
    } else {
      onClose();
    }
  };

  let linkByOptions;
  const relatedTable = table.relatedTables?.find(
    relatedTable => relatedTable.name === column.table
  );
  if (relatedTable) {
    linkByOptions = _.flatten(
      _.map(relatedTable.links, ({ moduleKeys }) =>
        moduleKeys.map(
          moduleKey =>
            databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][moduleKey]
        )
      )
    );
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={`${isBulkUpdating ? 'Updating ' : ''} ${column.label}`} />
      <Modal.Body className="p-4">
        {column.filterUi &&
          column.filterUi({
            column,
            rows,
            onChange: handleFilterChange,
            optionsMap:
              databaseIdToTableIdToColumnKeyToOptionIdToOption[column.databaseId]?.[column.table]?.[
                column.key
              ] || column.options,
            value: filter,
          })}
        <Input
          id={`columns.${columnIndexRef.current}.search.isPartialExclude`}
          label="Is Partial Exclude"
          tooltip="If set to Yes, and some cell values match the filter while others don't, display the row minus any values that don't match. If set to No (default), and the cell contains an excluded value, hide the row entirely."
          type={InputType.BOOL}
        />
        <Input
          id={`columns.${columnIndexRef.current}.search.type`}
          label="Advanced Search"
          onChange={type => onSearchTypeChange(type as keyof typeof Table.SearchType)}
          options={Table.searchTypeOptions}
          removable
        />
        <Text color="danger">{bulkUpdateResult?.error}</Text>
        {canBulkUpdate && column.isArray && (
          <Input
            capitalize
            label="Bulk Update Action"
            onChange={action => onBulkUpdateActionChange(action as Table.BulkUpdateAction)}
            options={Table.bulkUpdateActionOptions}
            value={bulkUpdate?.action}
          />
        )}
        {canBulkUpdate && (
          <div data-cy="bulk-update">
            {column.editUi({
              column,
              label: 'Bulk Update Value',
              onChange: onBulkUpdateValueChange,
              optionsMap:
                databaseIdToTableIdToColumnKeyToOptionIdToOption[column.databaseId]?.[
                  column.table
                ]?.[column.key] || column.options,
              value: bulkUpdate?.value,
            })}
          </div>
        )}
        {bulkUpdateResult && !_.isEmpty(bulkUpdateResult) && (
          <div className="flex">
            <Text className="flex-1">Total: {bulkUpdateResult.total}</Text>
            <Text className="flex-1">Succeeded: {bulkUpdateResult.succeeded}</Text>
            <Text className="flex-1">Failed: {bulkUpdateResult.failed}</Text>
          </div>
        )}
        {_.filter(bulkUpdateResult?.responses, 'response.errMsg').map(response => (
          <Text key={response.id}>
            {response.id}: {(response.response as ErrorResponse).errMsg}
          </Text>
        ))}
        {linkByOptions && (
          <Input
            id={`columns.${columnIndexRef.current}.linkBy`}
            label="Link By"
            options={linkByOptions}
            tooltip="When a module from another table is added it will join based this module."
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ml-4"
          onClick={isBulkUpdating ? resetBulkUpdate : () => setFilter(undefined)}
          variant="secondary"
        >
          Reset
        </Button>
        <Button className="ml-auto" cypressId="submit-filter" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
