import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import Group, { onChange } from './group';

class TreePick extends React.Component {
  static contextTypes = { t: T.object };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  static propTypes = {
    id: T.string.isRequired,
    label: T.string,
    options: T.array.isRequired,
  };

  state = {};

  render() {
    const options = this.props.options || [];
    const value = _.get(this.context.t.state, this.props.id);
    const selectedOptions = _.map(value, id => options.find(o => o.id === id)).filter(v => v);
    const text = selectedOptions.map(o => (
      <div key={o.id} className="badge animated fadeIn">
        {o.name}
      </div>
    ));
    const root = options.find(o => !o.parent);
    const onClick = id => {
      const turnOn = !value.includes(id);
      const focusedNode = options.find(o => o.id === id);
      let out;
      if (turnOn) {
        out = [
          id,
          ...value.filter(vId => {
            const option = options.find(o => o.id === vId);
            return !option.path.includes(id) && !focusedNode.path.includes(vId);
          }),
        ];
      } else {
        out = value.filter(vId => {
          if (vId === id) {
            return false;
          }
          const option = options.find(o => o.id === vId);
          return !option.path.includes(id);
        });
      }
      onChange(this.context.t, this.props.id, out);
    };
    const Node = (node = root) => {
      const active = _.find(value, v => node && node.path.includes(v));
      const icon = active ? 'check-icon completed' : 'check-icon';
      const text = active ? 'selected' : 'notSelected';
      return (
        <div className="check-list">
          <div
            id="check-item"
            className={text}
            style={{ cursor: 'pointer' }}
            onClick={() => onClick(node.id)}
          >
            <div className={icon}>
              <Icon name="check" size={16} />
            </div>{' '}
            <p>{_.get(node, 'name')}</p>
          </div>
          <div className="child-item">
            {options
              .filter(i => i.parent === node.id)
              .map(i => (
                <div className="sub-item" key={i.id}>
                  {Node(i)}
                </div>
              ))}
          </div>
        </div>
      );
    };
    return (
      <div id="ClientSkills">
        <Group {...this.props}>
          <div className="cs-container">
            <div className="left-fixed">
              <div className="cl-tags">{text || 'Select'}</div>
            </div>
            <div className="right-fixed">{Node()}</div>
          </div>
        </Group>
      </div>
    );
  }
}

export default connect((s, p) => ({
  onChange: v => U.redux.set(`form.${p.id}`, v),
  value: _.get(s, `form.${p.id}`, []),
}))(TreePick);
