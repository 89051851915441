import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Select,Text } from '../../com/ui/form';

class Email extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['estimate', 'invoice']),
    user: PropTypes.oneOf(['customer', 'org']),
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { customer: p.user, email: p.email, type: p.type };
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit() {
    if (!this.state.customer) {
      return this.setState({ error: 'Customer required' });
    }
    if (!this.state.type) {
      return this.setState({ error: 'Type required' });
    }
    if (!this.state.email) {
      return this.setState({ error: 'Email required' });
    }
    const request = _.pick(this.state, ['customer', 'email', 'type']);
    await U.api('post', `workorders/${this.props.workorder.id}/${this.props.type}/email`, request);
    this.props.onClose();
  }

  onCustomerChange(customer) {
    if (customer === 'org') {
      this.setState({ email: _.get(this.props.org, 'email', '') });
    } else {
      this.setState({ email: this.props.job.customer.user.email });
    }
  }

  render() {
    return (
      <Modal
        title="Email Bill"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        submit={this.onSubmit}
      >
        <div className="text-danger">{this.state.error}</div>
        <Select
          id="customer"
          options={this.props.customerOptions}
          onChange={this.onCustomerChange}
        />
        <Select id="type" options={this.props.typeOptions} cap />
        <Text id="email" />
      </Modal>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const workorder = s.workorders[job.metadata.workOrderId];
  const customerOptions = _.compact([
    { id: 'user', name: job.customer.user.fullName },
    T.hasOrg(job) && { id: 'org', name: job.customer.org.name },
  ]);
  const typeOptions = _.compact([workorder.estimate && 'estimate', workorder.invoice && 'invoice']);
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const email = p.user === 'org' ? _.get(org, 'email') : job.customer.user.email;
  return { customerOptions, typeOptions, email, org, workorder, job };
})(Email);
