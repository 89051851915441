import './availability-container.css';

import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/core/Button';
import Text from '@/components/core/Text';

const BULLET_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
};

const Bullet = memo(({ variant, text, className }) => {
  const classes = classnames('flex flex-row items-center', { [className]: className });
  const bulletClasses = classnames('mb-1.5 mr-2 h-4 w-4 rounded-full border', {
    'border-primary-regular bg-primary-light': variant === BULLET_VARIANTS.primary,
    'border-grey-dark bg-background-medium': variant === BULLET_VARIANTS.secondary,
  });

  return (
    <div className={classes}>
      <div className={bulletClasses} />
      <Text type="body-2">{text}</Text>
    </div>
  );
});

Bullet.defaultProps = {
  className: '',
};

Bullet.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.keys(BULLET_VARIANTS)).isRequired,
};

export default function AvailabilityContainer({ onSave, saveDisabled, children }) {
  return (
    <div className="availability-container h-full pt-2">
      <div className="h-full bg-background-light pt-8">
        <div className="container rounded border border-grey-medium bg-white px-0 shadow-lg">
          <div className="flex items-center justify-between p-6">
            <Text type="headline-6" color="font.dark">
              Set Availability
            </Text>
            <Button
              className="availability-container__save py-0"
              disabled={saveDisabled}
              onClick={onSave}
              variant="primary"
            >
              Save Changes
            </Button>
          </div>

          <div className="border-t border-grey-medium p-6">
            <div className="flex items-center">
              <Bullet variant={BULLET_VARIANTS.primary} text="Available" />
              <Bullet variant={BULLET_VARIANTS.secondary} text="Not available" className="ml-6" />
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

AvailabilityContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
};
