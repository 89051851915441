import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import U, { Address, Region, Service, StandardOption, User } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import type { Dispatch } from 'redux';
import { Button, Form, Input, InputType, Text } from '@/components';
import { idFromURL, openLink } from '../../../utils';

type Props = {
  dispatch: Dispatch;
  regions: Record<string, Region>;
  services: Record<string, Service>;
};

function EditRegion({ dispatch, regions, services }: Props): JSX.Element {
  const [error, setError] = useState<string>();
  const [regionId, setRegionId] = useState(idFromURL());
  const originalRegion = regionId ? regions[regionId] : undefined;
  const [region, setRegion] = useState(originalRegion || {});
  const [users, setUsers] = useState<StandardOption[]>([]);
  if ('name' in region) {
    document.title = (region as Region).name;
  }

  useEffect(() => {
    if (regionId) {
      void U.api<Region>('get', `regions/${regionId}`, ['save']).then(response => {
        if ('id' in response) {
          setRegion(response);
        }
      });
    }
    void U.api<User[]>('post', 'users/search', {
      limit: -1,
      projection: { id: 1, 'profile.fullName': 1 },
      query: { roles: U.user.Role.CUSTOMERSUPPORT },
    }).then(users => {
      if (Array.isArray(users)) {
        const userOptions = _.sortBy(users, 'profile.fullName').map(user => ({
          id: user.id,
          name: user.profile.fullName,
        }));
        setUsers(userOptions);
      }
    });
  }, [regionId]);

  const getSlotLabel = (dayIndex: number, slotIndex: number) =>
    `${['All Days', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex + 1]} ${
      ['8-12', '12-4', '4-8'][slotIndex]
    }`;

  const handleSaveMany = (id: string, value = '') => {
    setRegion(region => {
      const newValues = _.uniq(_.compact(value.split(/,? /)));
      return _.set(_.cloneDeep(region), `${id}.values`, newValues);
    });
  };

  const handleCenterOnCity = (value?: Address) =>
    setRegion(region => ({ ...region, geoCoordinates: value?.geoCoordinates }));

  const handleDelete = async () => {
    const response = await U.api<Region>('delete', `regions/${nullthrows(regionId)}`);
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else {
      openLink({ dispatch, url: 'reports/regions' });
    }
  };

  const handleSave = async () => {
    const requiredProps = ['zip', 'city', 'county', 'region', 'state'];
    if (!requiredProps.some(prop => U.length(region, `${prop}.values`))) {
      return setError('Include or exclude at least 1 row');
    }
    const newRegion = _.cloneDeep(region as Region);
    requiredProps.forEach(prop => {
      if (!_.compact(_.get(newRegion, `${prop}.values`) as unknown[]).length) {
        if (
          prop === 'zip' ||
          prop === 'city' ||
          prop === 'county' ||
          prop === 'region' ||
          prop === 'state'
        ) {
          newRegion[prop] = { isExclude: false, values: [] };
        }
      }
    });
    newRegion.managers = (newRegion.managers || []).filter(manager => manager?.id);
    let response;
    if (regionId) {
      const changes = _.keys(newRegion)
        .filter(
          key =>
            !_.isEqual(
              newRegion[key as keyof typeof newRegion],
              originalRegion ? originalRegion[key as keyof typeof originalRegion] : undefined
            )
        )
        .map(key => ({ path: key, value: newRegion[key as keyof typeof newRegion] }));
      response = await U.api<Region>('put', `regions/${regionId}`, changes, ['save']);
    } else {
      response = await U.api<Region>('post', 'regions', newRegion, ['save']);
      if (!('errMsg' in response)) {
        setRegionId(response.id);
      }
    }
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else {
      setError(undefined);
      setRegion(response);
    }
    return undefined;
  };

  return (
    <Form
      className="container mx-auto mt-4"
      onChange={setRegion}
      originalValue={originalRegion}
      value={region}
    >
      <div className="grid grid-cols-4 gap-x-4">
        <Input label="Center on City" type={InputType.CITY} onChange={handleCenterOnCity} />
        <Input id="cxSupportNumber" type={InputType.PHONE} />
        <Input id="hidden" type={InputType.BOOL} />
        <Input id="geoCoordinates.lat" type={InputType.NUMBER} />
        <Input id="geoCoordinates.lng" type={InputType.NUMBER} />
        <Input id="managers" multiple object options={users} />
        <Input
          id="minFillRateToAllowBooking"
          placeholder={`default ${U.region.minFillRateToAllowBooking * 100}`}
          type={InputType.PERCENT}
        />
        <Input id="reviewUrl" />
        <Input id="name" required />
        <Input id="timezone" options={U.region.timezoneOptions} required />
        <Input id="type" capitalize options={U.region.typeOptions} required />
        <Input
          id="volumeTargetPercents.serviceBench"
          placeholder="default 25"
          type={InputType.PERCENT}
        />
        <Input
          id="volumeTargetPercents.servicePower"
          placeholder="default 40"
          type={InputType.PERCENT}
        />
      </div>
      <Text>Automated Capacity Visit Limits</Text>
      <div className="grid grid-cols-7 gap-x-4">
        {_.times(21, i => {
          const dayIndex = i % 7;
          const slotIndex = _.floor(i / 7);
          return (
            <Input
              id={`capacity.${dayIndex}-${slotIndex}`}
              disabled
              key={i}
              label={getSlotLabel(dayIndex, slotIndex)}
            />
          );
        })}
      </div>
      <Text>Manual Capacity Visit Limits</Text>
      <div className="grid grid-cols-8 gap-x-4">
        {_.times(24, i => {
          const dayIndex = (i % 8) - 1;
          const slotIndex = _.floor(i / 8);
          return (
            <Input
              id={`jobLimit.${slotIndex}.${dayIndex + 1}`}
              key={i}
              label={getSlotLabel(dayIndex, slotIndex)}
              type={InputType.NUMBER}
            />
          );
        })}
      </div>
      <div>
        <Input id="zip.isExclude" className="mr-4 inline-block" type={InputType.BOOL} width={150} />
        <Input
          className="mr-4 inline-block"
          label="Bulk Zips"
          onChange={value => handleSaveMany('zip', value)}
          tooltip="Paste a space or comma separated list of values"
          width={150}
        />
        {_.times(U.length(region, 'zip.values') + 1, i => (
          <Input
            id={`zip.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`Zip ${i + 1}`}
            removable
            width={100}
          />
        ))}
      </div>
      <div>
        <Input
          id="city.isExclude"
          className="mr-4 inline-block"
          type={InputType.BOOL}
          width={150}
        />
        {_.times(U.length(region, 'city.values') + 1, i => (
          <Input
            id={`city.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`City ${i + 1}`}
            removable
            type={InputType.CITY}
            width={150}
          />
        ))}
      </div>
      <div>
        <Input
          id="county.isExclude"
          className="mr-4 inline-block"
          type={InputType.BOOL}
          width={150}
        />
        <Input
          className="mr-4 inline-block"
          label="Bulk Counties"
          onChange={value => handleSaveMany('county', value)}
          tooltip="Paste a space or comma separated list of values"
          width={150}
        />
        {_.times(U.length(region, 'county.values') + 1, i => (
          <Input
            id={`county.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`County ${i + 1}`}
            removable
            width={150}
          />
        ))}
      </div>
      <div>
        <Input
          id="region.isExclude"
          className="mr-4 inline-block"
          type={InputType.BOOL}
          width={150}
        />
        {_.times(U.length(region, 'region.values') + 1, i => (
          <Input
            id={`region.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`Region ${i + 1}`}
            options={_.values(regions).filter(region => region.id !== regionId)}
            removable
            sort
            width={150}
          />
        ))}
      </div>
      <div>
        <Input
          id="state.isExclude"
          className="mr-4 inline-block"
          type={InputType.BOOL}
          width={150}
        />
        {_.times(U.length(region, 'state.values') + 1, i => (
          <Input
            id={`state.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`State ${i + 1}`}
            options={U.states}
            removable
            width={150}
          />
        ))}
      </div>
      <div>
        <Input
          id="service.isExclude"
          className="mr-4 inline-block"
          type={InputType.BOOL}
          width={150}
        />
        {_.times(U.length(region, 'service.values') + 1, i => (
          <Input
            id={`service.values.${i}`}
            className="mr-4 inline-block"
            key={i}
            label={`Service ${i + 1}`}
            options={_.values(services)}
            removable
            sort
            width={150}
          />
        ))}
      </div>
      <Text color="danger">{error}</Text>
      <div className="flex justify-between">
        <Button onClick={handleSave} submit>
          Save
        </Button>
        <Button onClick={handleDelete} variant="secondary">
          Delete
        </Button>
      </div>
    </Form>
  );
}

export default connect(s => ({ regions: s.regions, services: s.services }))(EditRegion);
