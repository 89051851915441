import './socialProofPopup.css';

import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Icon } from '@/components';
import { imgMap } from './data';

export default class SocialProofPopup extends React.Component {
  constructor(p) {
    super(p);
    this.state = { count: 0, opening: false };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.close(true);
  }

  async componentWillMount() {
    setTimeout(async () => {
      if (!global.token) {
        const r = await U.api('post', 'tasks/search/teaser');
        this.setState({ tasks: r });
      }
    }, 500);
  }

  open() {
    if (this.unmount) {
      return;
    }
    this.setState({ opening: true, count: this.state.count + 1 });
    setTimeout(this.close, 10000);
  }

  close(initial) {
    if (this.unmount) {
      return;
    }
    this.setState({ opening: false });
    setTimeout(this.open, initial ? 1000 : _.random(5000, 15000));
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  render() {
    const t = this;
    if (t.state.closed) {
      return null;
    }
    const show =
      global.location.pathname === '/' ||
      global.location.pathname.startsWith('/city') ||
      global.location.pathname.startsWith('/services');
    if (!show || !t.state.tasks) {
      return null;
    }
    const item = t.state.tasks[t.state.count % t.state.tasks.length];
    return (
      <div
        className={`socialProofPopup flex flex-row items-center ${
          t.state.opening ? 'opening' : ''
        }`}
      >
        <Icon
          className="float-right cursor-pointer p-2 text-grey-light hover:text-font-dark"
          name="close_thick"
          onClick={() => t.setState({ closed: true })}
          size={16}
        />
        <div className="flex-row">
          <div className="catalog-img">
            <img src={`/img/sales_pop/${imgMap[item.title]}`} height="100px" alt="" />
          </div>
          <div className="catalog-info">
            <div>
              <label>
                {item.fName} in <span className="strong">{item.city}</span> just booked:
              </label>
              <p>{item.title}</p>
              <small>{m(item.cTime).fromNow()}</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
