import '../../style/page/part.css';

import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PT from 'prop-types';
import { Icon } from '@/components';
import { Modal } from '../../com/ui/form';
import ReturnPartStep1 from './returnPartStep1';
import ReturnPartStep2 from './returnPartStep2';
import ReturnPartStep3 from './returnPartStep3';
import ReturnPartStep4 from './returnPartStep4';

const MODAL_BODY_CLASSNAME = 'admin-modal_body';

class ReturnPart extends React.Component {
  static propTypes = {
    id: PT.any,
    onClose: PT.func.isRequired,
    job: PT.object,
    shortId: PT.string,
  };

  static childContextTypes = { t: PT.object };

  getChildContext = () => ({ t: this });

  state = { step: 1 };

  async UNSAFE_componentWillReceiveProps(p) {
    if (!this.props.id && p.id) {
      const part = _.get(p.job, `parts.items.${p.id}`);
      const partVendorOrderNumber = _.get(part, 'vendorOrderNumber');
      let { partReturnId } = part;
      let partReturn = _.get(this.props.job, 'parts.returns', []).find(v => v._id === partReturnId);
      let step = 1;

      let returnableParts = T.parts(this.props.job).filter(
        p =>
          p.vendorOrderNumber === partVendorOrderNumber &&
          ['needsReturn', 'returnRequested'].includes(p.status)
      );

      if (!partReturn) {
        // new part return
        partReturnId = null;
        if (part.partReturnId) {
          // bad part return id / part return not found so clobber completely
          const changes = returnableParts.map(rp => {
            return { action: 'replace', path: `parts.items.${rp._id}.partReturnId`, value: null };
          });
          await U.api('put', `tasks/${this.props.job.id}`, changes, ['save']);
        }
        part.partReturnId = null;
        part.returnShortId = this.getPartReturnPartShortId(part);
        part.status = 'returnRequested';
        part.returnQuantity = 1;
        part.returnReason = 'neverUsedOpenBox';
        const numberOfExistingPartReturns = _.get(this.props.job, 'parts.returns', []).length;
        partReturn = {
          parts: [part.id],
          quantity: 1,
          weight: { unit: 'pound', value: 0 },
          length: { unit: 'inch', value: 0 },
          width: { unit: 'inch', value: 0 },
          height: { unit: 'inch', value: 0 },
          referenceNumber: `PR-${this.props.job.shortId}-${numberOfExistingPartReturns + 1}`,
          externalReferenceNumber: '',
          vendor: part.vendor,
          vendorOrderNumber: part.vendorOrderNumber,
          status: 'requested',
          shipFrom: { id: '', name: '', phone: '', address: {} },
          shipTo: { id: '', name: '', phone: '', address: {} },
          label: {},
        };

        returnableParts = await Promise.all(
          returnableParts.map(async rp => {
            rp.returnShortId = this.getPartReturnPartShortId(rp);
            if (!_.isNumber(rp.returnQuantity)) {
              rp.returnQuantity = rp.status === 'returnRequested' ? 1 : 0;
            }
            if (!rp.returnReason) {
              rp.returnReason = 'neverUsedOpenBox';
            }
            if (!rp.loadedCatalogWeightAndDimensions) {
              rp = await this.setPartUnitWeightAndDimensions(rp);
            }
            return rp;
          })
        );

        const changes = returnableParts.map(rp => {
          return { action: 'replace', path: `parts.items.${rp._id}`, value: rp };
        });
        await U.api('put', `tasks/${this.props.job.id}`, changes, ['save']);
      } else if (partReturn.status === 'labelGenerated') {
        step = 3;
      } else if (partReturn.status === 'approved') {
        step = 4;
      } else if (returnableParts.length === 1 && returnableParts[0].status === 'needsReturn') {
        returnableParts[0].status = 'returnRequested';
        returnableParts[0].returnQuantity = 1;
      }

      const [shipToOptions, shipFromOptions] = await Promise.all([
        this.getReturnAddressesForPartVendor(part.vendor),
        this.getReturnAddressesForPro(p.pro),
      ]);

      const estimatedRates = [];

      // set state
      this.setState({
        part,
        partReturn,
        partReturnId,
        returnableParts,
        shipFromOptions,
        shipToOptions,
        estimatedRates,
        modal: true,
        notify: 'customer',
        partVendorOrderNumber,
        step,
      });

      if (step === 4) {
        this.initReturnCredit();
      }
    }
  }

  back = () => {
    this.setState({ step: this.state.step - 1 });
  };

  buyLabel = async () => {
    this.setState({ buyLabelLoading: true });
    // reflexively save the part return
    await this.savePartReturn();

    const partReturnId = this.getPartReturnId();

    // buy label
    const task = await U.api(
      'post',
      `tasks/${this.props.job.id}/part-return/${partReturnId}/buy-label`,
      {}, // later on we may want to pass the chosen shipment method
      ['save']
    );

    if (task.errMsg) {
      this.setState({
        error: `Invalid response from Buy Label API, ${task.errMsg}`,
        buyLabelLoading: false,
      });
    } else {
      const partReturns = _.get(task, 'parts.returns');
      const partReturn = _.find(partReturns, p => p._id === partReturnId);
      this.setState({ partReturn, buyLabelLoading: false });
    }
  };

  changeReturnStatus = (index, status) => {
    const partsInShipment = this.getPartsInShipment();
    let amount = 0;
    if (status === 'credited') {
      amount = partsInShipment[index].costPrice * partsInShipment[index].unit.value;
    }
    this.setState(s => _.set(s, `returnCredit.${index}.amount`, amount));
  };

  getPartReturnReferenceNumber = partReturn => {
    const partReturns = U.timeSort(_.get(this.props.job, 'parts.returns', []));
    let index = _.findIndex(partReturns, r => r._id === partReturn._id);
    if (index === -1) {
      index = 0;
    }
    return `PR-${this.props.job.shortId}-${index + 1}`;
  };

  getPartReturnPartShortId = part => {
    const parts = U.timeSort(T.parts(this.props.job));
    const index = _.findIndex(parts, p => p._id === part._id);
    return `PR-${this.props.job.shortId}-${index + 1}`;
  };

  getPartWeightAndDimensions = async partNumber => {
    const partWeightAndDimensions = await U.api('get', `parts/shipping-info/${partNumber}`);
    return partWeightAndDimensions;
  };

  getPartsInShipment = () => {
    return this.state.partReturn.parts.map(id => this.props.job.parts.items[id]);
  };

  getRates = async () => {
    const shipFromPostalCode = _.get(this.state, 'partReturn.shipFrom.address.postalCode');
    const shipToPostalCode = _.get(this.state, 'partReturn.shipTo.address.postalCode');
    const weightValue = _.get(this.state, 'partReturn.weight.value');
    const lengthValue = _.get(this.state, 'partReturn.length.value');
    const widthValue = _.get(this.state, 'partReturn.width.value');
    const heightValue = _.get(this.state, 'partReturn.height.value');
    const estimatedRates = await U.api(
      'get',
      `parts/ship-engine/estimate-rates/raw?carrierCode=fedex&fromPostalCode=${shipFromPostalCode}&toPostalCode=${shipToPostalCode}&weightValue=${weightValue}&lengthValue=${lengthValue}&widthValue=${widthValue}&heightValue=${heightValue}`
    );
    const estimatedRate =
      estimatedRates.find(r => r.service_code === 'fedex_ground') ||
      estimatedRates.find(r => r.service_code === 'fedex_express_saver');
    this.setState({ estimatedRate }, () => {
      const [body] = document.getElementsByClassName(MODAL_BODY_CLASSNAME);
      if (body) {
        body.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  };

  getReturnAddressesForPartVendor = async vendorName => {
    const [vendor, nana] = await Promise.all([
      U.api('get', `partvendors/findbyname/${vendorName}`),
      U.api('get', `partvendors/findbyname/nana`),
    ]);
    const locations = [..._.get(vendor, 'locations', []), ..._.get(nana, 'locations', [])].filter(
      location => location.services.includes(U.partVendor.Service.RETURN)
    );
    // need a 1-based string id because of falsey checks in select
    return locations.map((v, index) => ({ ...v, id: String(index + 1) }));
  };

  getReturnAddressesForPro = async pro => {
    const nana = await U.api('get', `partvendors/findbyname/nana`);
    const locations = [
      ...(pro
        ? [
            {
              _id: pro.user.id,
              name: pro.user.fullName,
              phone: pro.user.phone,
              address: pro.user.address,
              type: 'pro',
            },
          ]
        : []),
      ..._.get(nana, 'locations', []).filter(location =>
        location.services.includes(U.partVendor.Service.RETURN)
      ),
    ];
    // need a 1-based string id because of falsey checks in select
    return locations.map((v, index) => ({ ...v, id: String(index + 1) }));
  };

  initReturnCredit = () => {
    const returnCredit = this.getPartsInShipment().map(part => ({
      amount: part.returnCreditAmount,
      status: part.returnCreditStatus,
    }));
    const originalReturnCredit = [...returnCredit];
    this.setState({ returnCredit, originalReturnCredit });
  };

  next = async () => {
    const nextStep = this.state.step + 1;
    if (nextStep === 2) {
      if (
        _.find(
          this.state.returnableParts,
          p => p.status === 'returnRequested' && !_.isBoolean(p.returnIsUnopenedPackaging)
        )
      ) {
        return this.setState({ error: 'In Original Packaging required' });
      }
      this.recalculateCombinedPartReturnWnD();
      const changes = _.flatten(
        this.state.returnableParts.map(p => [
          { path: `parts.items.${p._id}.status`, value: p.status },
          {
            path: `parts.items.${p._id}.returnIsUnopenedPackaging`,
            value: p.returnIsUnopenedPackaging,
          },
        ])
      );
      await U.api('put', `tasks/${this.props.job.id}`, changes, ['save']);
    } else if (nextStep === 3) {
      const requiredProps = [
        'shipFrom.address.geoCoordinates',
        'shipFrom.name',
        'shipFrom.phone',
        'shipTo.address.geoCoordinates',
        'shipTo.name',
        'shipTo.phone',
      ];
      for (const prop of requiredProps) {
        if (!_.get(this.state.partReturn, prop)) {
          return this.setState({ error: 'Ship From and Ship To address required' });
        }
      }
      const partReturnItems = _.get(this.state, 'returnableParts', [])
        .filter(part => part.status === 'returnRequested')
        .map(p => ({
          description: p.returnShortId,
          width: _.get(p, 'unitWidth.value'),
          length: _.get(p, 'unitLength.value'),
          depth: _.get(p, 'unitHeight.value'),
          keepFlat: false,
          quantity: _.get(p, 'returnQuantity'),
          weight: _.get(p, 'unitWeight.value'),
        }));

      const packedBoxes = await U.api('post', 'parts/dimensions', partReturnItems);
      if ('errMsg' in packedBoxes) {
        return this.setState({ error: packedBoxes.errMsg });
      }
      if (packedBoxes.length > 1) {
        return this.setState({ error: 'These parts do not fit into 1 box. Please remove some.' });
      }
      this.setState(s => {
        _.set(s, 'partReturn.width.value', packedBoxes[0].box.outerWidth);
        _.set(s, 'partReturn.length.value', packedBoxes[0].box.outerLength);
        _.set(s, 'partReturn.height.value', packedBoxes[0].box.outerDepth);
        _.set(s, 'partReturn.weight.value', packedBoxes[0].boxWeight);
        return s;
      });
      this.setState({ packedBoxes });
    } else if (nextStep === 4) {
      this.setState(s => {
        _.set(s, 'partReturn.status', 'approved');
        return s;
      });
    }
    this.setState({ step: nextStep, error: false });
    this.savePartReturn();
  };

  onShipFromAddressChange = address => {
    const partReturn = {
      ...this.state.partReturn,
      shipFrom: _.pick(address, ['name', 'phone', 'address']),
    };
    this.setState({ partReturn });
  };

  onShipToAddressChange = address => {
    const partReturn = {
      ...this.state.partReturn,
      shipTo: _.pick(address, ['name', 'phone', 'address']),
    };
    this.setState({ partReturn });
  };

  recalculateCombinedPartReturnWnD = () => {
    const wnd = {
      quantity: 0,
      weight: { unit: 'pound', value: 0 },
      length: { unit: 'inch', value: 0 },
      width: { unit: 'inch', value: 0 },
      height: { unit: 'inch', value: 0 },
    };

    const partReturn = { ...this.state.partReturn, ...wnd };
    this.setState({ partReturn });
  };

  resendEmail = async () => {
    const partReturnId = this.getPartReturnId() || _.get(this.state.part, 'partReturnId');
    U.api('put', `tasks/${this.props.job.id}/part-return/${partReturnId}/email`);
  };

  getPartReturnId = () => {
    const returnableParts = T.parts(this.props.job).filter(
      p =>
        p.vendorOrderNumber === this.state.partVendorOrderNumber &&
        ['needsReturn', 'returnRequested'].includes(p.status)
    );
    const partReturnId = _.get(
      returnableParts.find(
        p => ['needsReturn', 'returnRequested'].includes(p.status) && p.partReturnId
      ),
      'partReturnId'
    );
    return partReturnId;
  };

  savePartReturn = async () => {
    // update part
    const changes = [];
    this.state.returnableParts.map(p => {
      const path = `parts.items.${p._id}`;
      ['returnQuantity', 'returnReason', 'unitHeight', 'unitLength', 'unitWeight', 'unitWidth'].map(
        v => changes.push({ path: `${path}.${v}`, value: p[v] })
      );
    });
    await U.api('put', `tasks/${this.props.job.id}`, changes, ['save']);

    // upsert part return
    const { partReturn } = this.state;
    const partReturnId = this.getPartReturnId();

    if (partReturnId) {
      // update existing part return
      const r = await U.api(
        'put',
        `tasks/${this.props.job.id}/part-return/${partReturnId}`,
        partReturn,
        ['save']
      );
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
    } else {
      // create new part return
      const r = await U.api('post', `tasks/${this.props.job.id}/part-return`, partReturn, ['save']);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
    }
  };

  saveReturnCredit = async () => {
    const partsInShipment = this.getPartsInShipment();
    const changes = [];
    _.map(this.state.returnCredit, (_return, i) => {
      if (!_.get(_return, 'status')) {
        return;
      }
      const partId = partsInShipment[i]._id;
      changes.push(
        { path: `parts.items.${partId}.returnCreditStatus`, value: _return.status },
        { path: `parts.items.${partId}.returnCreditAmount`, value: _return.amount || 0 }
      );
    });
    if (changes.length) {
      const r = await U.api('put', `tasks/${this.props.job.id}`, changes, ['save']);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
      this.initReturnCredit();
    }
  };

  setPartUnitWeightAndDimensions = async part => {
    const wnd = await this.getPartWeightAndDimensions(part.partNumber);
    const newPartWnd = {
      unitWeight: { ...wnd.weight },
      unitLength: { ...wnd.length },
      unitWidth: { ...wnd.width },
      unitHeight: { ...wnd.height },
      loadedCatalogWeightAndDimensions: true,
    };

    return { ...part, ...newPartWnd };
  };

  /* Disables Custom Address Field in Step 2 */
  toggleCustomAddress = property => {
    const state = U.setToggle({ ...this.state }, `customAddress${property}`);
    const partReturn = {
      ...state.partReturn,
      [`ship${property}`]: { id: '', name: '', phone: '', address: {} },
    };
    this.setState({
      ...state,
      partReturn,
    });
  };

  toggleReturnablePartCheck = i => {
    if (this.state.partReturn.status !== 'requested') {
      return;
    }

    const returnableParts = _.get(this.state, 'returnableParts', []);
    const nextStatus =
      returnableParts[i].status === 'needsReturn' ? 'returnRequested' : 'needsReturn';
    const nextReturnQuantity = nextStatus === 'returnRequested' ? 1 : 0;

    returnableParts[i].returnShortId = this.getPartReturnPartShortId(returnableParts[i]);
    returnableParts[i].returnQuantity = nextReturnQuantity;
    returnableParts[i].status = nextStatus;
    const partReturnPartIds = _.map(
      returnableParts.filter(p => p.status === 'returnRequested'),
      '_id'
    );
    this.setState(s => {
      s.returnableParts = returnableParts;
      s.partReturn.parts = partReturnPartIds;
      return s;
    });
  };

  voidLabel = async () => {
    await U.api(
      'post',
      `tasks/${this.props.job.id}/part-return/${this.getPartReturnId()}/void-label`,
      undefined,
      ['save']
    );
    this.setState({ estimatedRate: false });
  };

  render() {
    const { onClose, pro } = this.props;
    const { part } = this.state;
    const partReturnId = this.getPartReturnId();
    const partReturn = _.find(_.get(this.props.job, 'parts.returns'), r => r._id === partReturnId);
    const hasShipmentInfo = !!partReturn?.track?.id;

    const steps = {
      1: {
        title: 'Start Return',
        desc: 'Initiate Return',
        button: { name: 'Choose Destination', to: this.next },
      },
      2: {
        title: 'Choose Destination',
        desc: 'Select Return Destination',
        button: { name: 'Prepare Label', to: this.next },
      },
      3: {
        title: 'Prepare Label',
        desc: 'Select Return Destination',
        button: hasShipmentInfo ? { name: 'Approve Return', to: this.next } : undefined,
      },
      4: {
        title: 'Label Created',
        desc: '',
      },
    };
    const isReturnStatusRequested = _.get(this.state, 'partReturn.status') === 'requested';

    const titleCom = (
      <section className="section d-flex align-items-start">
        <div className="flex-1">
          <div className="section_title mb-2">{steps[this.state.step].title}</div>
          <p className="text-muted">
            {U.titleCase(_.get(part, 'vendor'))} Order #{_.get(part, 'vendorOrderNumber')}
          </p>
        </div>
        {this.state.step < 3 && (
          <div className="items">
            <p className="text-muted mb-2">Items that need return</p>
            <ul>
              {_.get(this.state, 'returnableParts', []).map(part => (
                <li key={`returnpart-${part.id}`}>
                  {_.get(part, 'desc')} ({_.get(part, 'unit.value')})
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
    );

    let modalBody = '';
    if (this.state.step === 1) {
      modalBody = (
        <ReturnPartStep1
          disabled={!isReturnStatusRequested}
          partReturn={this.state.partReturn}
          returnableParts={this.state.returnableParts}
          titleCom={titleCom}
          toggleReturnablePartCheck={this.toggleReturnablePartCheck}
        />
      );
    } else if (this.state.step === 2) {
      modalBody = (
        <ReturnPartStep2
          customAddressFrom={!!this.state.customAddressFrom}
          customAddressTo={!!this.state.customAddressTo}
          disabled={!isReturnStatusRequested}
          onShipFromAddressChange={this.onShipFromAddressChange}
          onShipToAddressChange={this.onShipToAddressChange}
          shipFromOptions={this.state.shipFromOptions}
          shipToOptions={this.state.shipToOptions}
          titleCom={titleCom}
          toggleCustomAddress={this.toggleCustomAddress}
        />
      );
    } else if (this.state.step === 3) {
      modalBody = (
        <ReturnPartStep3
          buyLabel={this.buyLabel}
          buyLabelLoading={this.state.buyLabelLoading}
          disabled={!isReturnStatusRequested}
          estimatedRate={this.state.estimatedRate}
          getRates={this.getRates}
          hasShipmentInfo={hasShipmentInfo}
          packedBoxes={this.state.packedBoxes}
          partReturn={this.state.partReturn}
          partsInShipment={this.getPartsInShipment()}
          titleCom={titleCom}
          voidLabel={this.voidLabel}
        />
      );
    } else if (this.state.step === 4) {
      modalBody = (
        <ReturnPartStep4
          changeReturnStatus={this.changeReturnStatus}
          originalReturnCredit={this.state.originalReturnCredit}
          partsInShipment={this.getPartsInShipment()}
          pro={pro}
          partReturn={this.state.partReturn}
          resendEmail={this.resendEmail}
          returnCredit={this.state.returnCredit}
          saveReturnCredit={this.saveReturnCredit}
        />
      );
    }

    const header = (
      <div className="d-flex align-items-end">
        {/* Back Button - Disabled on First Step */}
        {this.state.step !== 4 && (
          <div style={{ opacity: this.state.step === 1 ? 0 : 1 }}>
            <IconButton
              className="back-button"
              aria-label="Select"
              onClick={this.back}
              size="medium"
            >
              <Icon name="arrow_left" color="#3e3e3e" size={24} />
            </IconButton>
          </div>
        )}

        {/* Progress Line */}
        <div className="return-tracker">
          <div className="return-tracker-ui">
            <div
              className="return-tracker-ui-line"
              style={{ width: `${(this.state.step * 100) / 4}%` }}
            />
          </div>

          <div className="d-flex justify-content-between">
            {_.values(steps).map((t, n) => {
              const isActive = this.state.step === n + 1;
              return (
                <div key={n} className={`return-tracker-label ${isActive ? `active` : ``}`}>
                  {t.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );

    return (
      <Modal
        titleCom={header}
        wrapClassName="admin-modal partCrmModal"
        modalClassName="admin-modal_container"
        backdropClassName="admin-modal_backdrop"
        className="admin-modal_dialog"
        contentClassName="admin-modal_dialog_inner_container"
        headerClassName="admin-modal_header"
        bodyClassName={`${MODAL_BODY_CLASSNAME} step-${this.state.step}`}
        footerClassName="admin-modal_footer"
        centered
        onClose={onClose}
        hideFooter={this.state.showPartSearch}
        footerCom={
          <div id="PartCrm" className="d-flex justify-content-between" style={{ width: '100%' }}>
            {/* Go Back Button */}
            {this.state.step > 1 && this.state.step < 4 && (
              <button className="secondary py-3" onClick={this.back}>
                Go Back
              </button>
            )}
            <div className="text-center text-danger">{this.state.error}</div>
            {this.state.step !== 4 && steps[this.state.step].button && (
              <button className="primary py-3" onClick={steps[this.state.step].button.to}>
                {steps[this.state.step].button.name}
              </button>
            )}
          </div>
        }
      >
        <div className="text-center text-danger">{this.state.error}</div>
        {modalBody}
      </Modal>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const isSupport = U.user.roles(s).customersupport;
  const proIds = _.compact(
    _.uniq(_.values(_.get(job, 'visits')).map(v => _.get(v, 'assignee.id')))
  );
  const pro = s.pros[_.last(proIds)];
  const part = _.get(job, `parts.items.${p.id}`);
  if (part && !part.taxable) {
    part.taxable = true;
  }
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const isV2 = m(job.createTime).valueOf() >= 1549872000000;
  const { me } = s;
  const maxProOwnedPartPrice = _.get(org, 'metadata.maxProOwnedPartPrice');
  return {
    pro,
    part,
    job,
    isV2,
    me,
    maxProOwnedPartPrice,
    org,
    isSupport,
  };
})(ReturnPart);
