import { push } from 'react-router-redux';
import _ from 'lodash';
import type { ParsedQuery } from 'query-string';
import queryString from 'query-string';
import type { Dispatch } from 'redux';
import { apiOrigin, expoCurrentPort, isDevBuild } from '../config/const';

export function copyText(value?: number | string): void {
  const el = document.createElement('textarea');
  el.value = value === undefined ? '' : value.toString();
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

type Value = null | number | string | Date;
type Row = Value[];

export function exportToCsv(filename: string, rows: Row[]): void {
  const processRow = (row: Row): string => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : (row[j] || '').toString();
      if (row[j] instanceof Date) {
        innerValue = (row[j] as Date).toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if ('msSaveBlob' in navigator) {
    // IE 10+
    // @ts-expect-error Untyped
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const getParams = (): ParsedQuery => queryString.parse(global.location.search);

export const idFromString = (str: string): string | undefined =>
  str.match(/\b[a-fA-F0-9]{24}\b/)?.[0];

export const idFromURL = (path?: string): string | undefined =>
  idFromString(path || global.location.pathname);

export function openLink({
  dispatch,
  newTab,
  url,
}: {
  dispatch?: Dispatch;
  newTab?: boolean;
  url: string;
}): void {
  if (newTab) {
    if (_.startsWith(url, 'http')) {
      window.open(url, '_blank');
    } else {
      window.open(_.startsWith(url, '/') ? url : `/${url}`, '_blank');
    }
  } else if (_.startsWith(url, 'http')) {
    global.location.assign(url);
  } else if (dispatch) {
    dispatch(push(_.startsWith(url, '/') ? url : `/${url}`));
  }
}

/**
 * Generates a deep link to the Pro App.
 * @param {object} queryParams The params to appended to the deep link's querystring
 * @returns {string} A deep link to the Pro App
 */
export const generateProAppDeepLink = (params: Record<string, unknown>): string => {
  const qs = `?${queryString.stringify(params)}`;

  if (isDevBuild) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `exp://127.0.0.1:${expoCurrentPort}/--/${qs}`;
  }

  return `${apiOrigin}/app.html${qs}`;
};
