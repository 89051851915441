import React, { useState } from 'react';
import { Task, User } from '@nanaio/util';
import _ from 'lodash';
import {
  Address,
  APIError,
  BoolInput,
  Button,
  FormControl,
  Icon,
  Modal,
  Text,
  TextInput,
} from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

type Props = {
  taskId: string;
};

export default function ChangeAddress({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];
  const userId = task.customer.user.id;

  const [address, setAddress] = useState(task.serviceAddress);
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [override, setOverride] = useState(false);
  const [saveToCustomerProfileAddress, setSaveToCustomerProfileAddress] = useState(true);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${taskId}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  const [updateUser, userToUpdate] = useLazyLegacyAPI<User>(`users/${userId}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  const submit = async () => {
    setError('');

    if (!address.streetNumber) {
      return setError('Street Number is required');
    }
    if (!address.locality) {
      return setError('City is required');
    }
    if (!address.county) {
      return setError('County is required');
    }
    if (!address.region) {
      return setError('State is required');
    }
    if (!address.postalCode) {
      return setError('Zip is required');
    }

    const changes = [
      {
        action: 'replace',
        path: 'serviceAddress',
        value: address,
      },
    ];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }

    if (saveToCustomerProfileAddress) {
      const userChanges = [
        {
          action: 'replace',
          path: 'profile.address',
          value: address,
        },
      ];
      const userUpdateResponse = await updateUser(userChanges);
      if (!userUpdateResponse || 'errMsg' in userUpdateResponse) {
        return;
      }
    }
    setIsOpen(false);
  };

  const toggleOverride = () => {
    setOverride(!override);
  };

  return (
    <>
      <div className="flex items-center">
        <Icon className="ml-2" name="edit" onClick={() => setIsOpen(true)} />
      </div>
      <Modal isOpen={isOpen} onSave={submit} onClose={() => setIsOpen(false)}>
        <Modal.Header title="Change Address" />
        <Modal.Body className="p-4">
          <Text className="mb-4" color="danger" type="button">
            {error}
          </Text>
          {taskToUpdate.error}
          {userToUpdate.error}
          <FormControl label="Address Autocomplete (try this first)">
            <Address
              value={address}
              onChange={value => setAddress(value as Task['serviceAddress'])}
            />
          </FormControl>
          <Button onClick={toggleOverride} variant="primary-outline" size="small">
            {override ? 'Lock fields' : "Override (if autocomplete isn't working)"}
          </Button>
          <FormControl className="mt-4" label="Street Number" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.streetNumber}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, streetNumber: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="Route" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.route}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, route: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="Unit">
            <TextInput
              value={address.unit}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, unit: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="City" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.locality}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, locality: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="County" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.county}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, county: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="State" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.region}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, region: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="Zip" disabled={!override}>
            <TextInput
              disabled={!override}
              value={address.postalCode}
              onChange={value =>
                setAddress(prevAddress => ({ ...prevAddress, postalCode: value as string }))
              }
            />
          </FormControl>
          <FormControl className="mt-4" label="Save to Customer Profile">
            <BoolInput
              value={saveToCustomerProfileAddress}
              onChange={() => setSaveToCustomerProfileAddress(!saveToCustomerProfileAddress)}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}
