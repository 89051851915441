import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import Icon from './Icon';
import Paper, { Props } from './Paper';
import Text from './Text';

const placeholder = (
  <div className="flex flex-col justify-center p-4 text-center">
    <div className="mx-auto">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-grey-light">
        <Icon className="text-grey-dark" name="refresh" size={24} />
      </div>
    </div>
    <Text className="mt-4" type="button">
      Replace this frame
    </Text>
    <Text className="mt-2 text-grey-dark">This is a placeholder</Text>
  </div>
);

export default {
  title: 'Core/Paper',
  component: Paper,
  variant: 'flat',
  args: {
    children: placeholder,
    className: 'w-[260px]',
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof Paper>;

const Template: ComponentStory<typeof Paper> = args => <Paper {...args} />;

export const Default = Template.bind({});
Default.args = { variant: 'flat' };

export const Variants = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Variant prop options</p>
    <p className="mt-6">flat</p>
    <Default {...args} variant="flat" />
    <p className="mt-6">elevated</p>
    <Default {...args} variant="elevated" />
    <p className="mt-6">lifted</p>
    <Default {...args} variant="lifted" />
    <p className="mt-6">floating</p>
    <Default {...args} variant="floating" />
  </div>
);

export const LongDesc = Template.bind({});
LongDesc.args = {
  variant: 'flat',
  children:
    'This is an paper sheet with a lot of text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis. Quam adipiscing vitae proin sagittis. Vel facilisis volutpat est velit egestas dui id. Ac auctor augue mauris augue neque gravida in fermentum et. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Velit euismod in pellentesque massa placerat. In dictum non consectetur a erat. Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing. Pharetra et ultrices neque ornare. Leo vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Hendrerit gravida rutrum quisque non.',
};
