import { Address, ErrorResponse, Service, T, Task, U, W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';

export type Brand = keyof typeof U.brands;

export type StepName =
  | 'Address'
  | 'Availability'
  | 'Card'
  | 'Confirmation'
  | 'Contact'
  | 'Details'
  | 'Model'
  | 'NoAvailability'
  | 'Service';

export type BookingState = {
  address?: Address;
  arrivalInstructions?: string;
  availability?: Record<string, boolean>;
  availTSlots: Record<string, boolean>;
  billingAddress?: Address;
  billingName?: string;
  email?: string;
  firstName?: string;
  job?: Task;
  lastName?: string;
  metadata: {
    taskQuestions: {
      Make?: Brand;
      Model?: string;
      'Serial #'?: string;
      'Tell us more'?: string;
      "What's the problem?"?: string;
      'The stove needs?'?: string;
      'The Oven needs?'?: string;
      'The Washer needs?'?: string;
      'The Dryer needs?'?: string;
    };
  };
  minScheduleTime: m.MomentInput;
  noAvailability?: boolean;
  payment: { stripeToken?: string };
  phone?: string;
  serviceId?: string;
  services: Service[];
  startDate: m.MomentInput;
  step: number;
  terms?: boolean;
  unit?: string;
  useShippingForBilling: boolean;
  zip: { value?: string };
};

export const postWorkOrder = async (
  state: BookingState & { startTimes: m.MomentInput[]; userId: string; landingPage: string }
): Promise<ErrorResponse | WorkOrder> => {
  const availability = _.values(state.startTimes)
    .sort()
    .map(t => ({
      start: t,
      end: m(t).add(4, 'h').valueOf(),
    }));
  const visit = {
    cx: {
      availability,
      status: W.VisitCustomerStatus.CONFIRMED,
    },
    slot: availability[0],
  };
  const org = {
    id: U.org.vendors.b2c,
  };
  const task = {
    customer: {
      org,
      userId: state.userId,
    },
    payment: state.payment,
    serviceAddress: state.address,
    serviceCatalogIds: [state.serviceId],
    status: T.Status.PENDING_CONFIRMATION,
    metadata: {
      landingPage: state.landingPage,
      taskQuestions: state.metadata.taskQuestions,
    },
  };
  const tags = [W.Tag.FTC_BOOKING_FLOW_V_2_CUSTOMER_CONFIRMED];
  const response = await U.api<WorkOrder>('post', 'workOrders', {
    cxTermsAcceptDate: Date.now(),
    minScheduleTime: U.redux.get('minScheduleTime'),
    org,
    tags,
    tasks: [task],
    visits: [visit],
  });
  return response;
};
