import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Copyable from './Copyable';
import Icon from './Icon';

export default {
  title: 'Core/Copyable',
  component: Copyable,
  args: {
    text: '+506 8810-7328',
    children: <Icon color="primaryCTA" name="content_copy" />,
  },
} as ComponentMeta<typeof Copyable>;

const Template: ComponentStory<typeof Copyable> = args => <Copyable {...args} />;

export const Default = Template.bind({});
Default.args = {};
