import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Text } from '@/components';
import { companyName } from '@/config/const';
import Link from '../../../com/ui/link';

const tabs = [
  { name: 'culture', pathname: '/about', title: `${companyName}'s Culture` },
  { name: 'history', pathname: '/history', title: 'Our History' },
  { name: 'careers', pathname: `${window.location.origin}/careers`, title: 'Open Roles' },
];
const DEFAULT_TAB = 'culture';

export default function AboutNav(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);

  useEffect(() => {
    const tab = tabs.find(
      tab => tab.pathname === window.location.pathname || tab.pathname === window.location.href
    );
    setCurrentTab(tab ? tab.name : DEFAULT_TAB);
  }, []);

  return (
    <section className="mt-16 pt-1">
      <div className="bg-primaryCTA-3 pt-px" />
      <section className="bg-grey-light">
        <div className="container mx-auto max-w-1200">
          <div className="flex h-12 pl-6 text-primaryCTA sm:pl-32">
            {tabs.map(tab => (
              <Link key={tab.name} to={tab.pathname} className="no-underline">
                <Text
                  className={classNames('p-3  text-primaryCTA outline-none sm:h-12', {
                    'bg-white  hover:text-primaryCTA-5': currentTab === tab.name,
                    'g-grey-light': currentTab !== tab.name,
                  })}
                >
                  {tab.title}
                </Text>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
}
