import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Search } from '../../../com/ui/form';

class Promos extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
  }

  addPromo = async id => {
    const r = await U.api('put', `workOrders/${this.props.workOrder.id}/promos/${id}`, undefined, [
      'save',
    ]);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    }
  };

  loadPromos = async () => {
    const promoOptions = _.sortBy(await U.api('post', 'promos/search', { limit: -1 }), 'name');
    this.setState({ promoOptions });
  };

  removePromo = async promo => {
    const r = await U.api('delete', `workOrders/${this.props.workOrder.id}/promos/${promo.id}`, [
      'save',
    ]);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    }
  };

  render() {
    return (
      <div className="p-3">
        <div className="text-danger">{this.state.error}</div>
        <Search
          id="addPromo"
          className="mb-5"
          onFocus={this.loadPromos}
          options={this.state.promoOptions}
          onChange={this.addPromo}
        />
        {_.map(this.props.workOrder.promos, (p, i) => (
          <div key={i}>
            {p.name}{' '}
            <div className="btn btn-default ml-5" onClick={() => this.removePromo(p)}>
              Remove
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const workOrder = s.workorders[job.metadata.workOrderId];
  return { workOrder };
})(Promos);
