import React from 'react';
import _ from 'lodash';
import T from 'prop-types';

const propTypes = {
  items: T.array.isRequired,
};

const JSON = props => {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }
  return (
    <div className="d-flex">
      {_.map(props.items, (v, n) => (
        <pre key={n}>{JSON.stringify(v, null, 2)}</pre>
      ))}
    </div>
  );
};

JSON.propTypes = propTypes;

export default JSON;
