import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Bool, Search, Select } from '../../com/ui/form';
import Recall from './Add/recall';
import { types } from './Add/util';

// This is used when adding a task to an existing workOrder from the task details page
class TaskAdd extends React.Component {
  getChildContext = () => ({ t: this });

  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    if (!p.workOrder) {
      U.api('get', `workOrders/${global.id()}`, ['save']);
    }
    document.title = 'New Task';
    this.state = {
      task: {},
      error: false,
      loading: false,
      notify: 'all',
    };
  }

  /**
   * clear the state of the component
   */
  resetState = () => {
    this.setState({
      task: {},
      error: false,
      loading: false,
      notify: 'all',
    });
  };

  /**
   * validate that we are ready to submit the task
   * @returns An error message if we are not ready for submission
   */
  validate = () => {
    const { task } = this.state;
    if (!_.get(task, 'serviceCatalogs.0.id')) {
      return 'Service missing';
    }
    if (!_.get(task, 'metadata.taskQuestions.Tell us more')) {
      return 'Tell us more missing';
    }
    if (!this.state.notify) {
      return 'Notify missing';
    }
  };

  /**
   * submit the task
   */
  submit = async () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });

    // check we are ready to submit the task
    const error = this.validate();
    if (error) {
      this.setState({ error, loading: false });
      return;
    }

    // submit the task
    const response = await U.api(
      'post',
      `workorders/${this.props.workOrder.id}/tasks?notify=${this.state.notify}`,
      this.state.task
    );
    // surface any errors
    if (response.errMsg) {
      this.setState({ error: response.errMsg, loading: false });
      return;
    }

    // reset state after successful task creation
    this.resetState();
  };

  render() {
    const { task } = this.state;
    const { brands, services, user, workOrder } = this.props;
    let questions = T.questions({ brands, services, task, user, workOrder });
    questions = _.filter(questions, q => types[q.type]);
    _.forEach(questions, q => {
      q.id = `task.${q.id}`;
    });

    return (
      <div id="adminBooking">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {/* What Type of Service? */}
              <div className="info">
                <div className="card-head">
                  <h5 className="title flex items-center">
                    <div className="icon flex items-center justify-center">
                      <Icon name="build_circle" size={16} />
                    </div>
                    What type of Service?
                  </h5>
                </div>
                <div className="card-form">
                  <div className="row">
                    <Search
                      id="task.serviceCatalogs.0.id"
                      label="Service"
                      options={this.props.serviceOptions}
                      hint="Service"
                      className="col"
                      sort
                    />
                    <Bool id="prevMaintenance" className="col" />
                  </div>
                </div>
              </div>

              {/* Warranty */}
              <Recall setState={this.setState} state={this.state} />
            </div>

            <div className="col-sm-6">
              {/* Job Details */}
              <div className="info">
                <div className="card-head">
                  <h5 className="title flex items-center">
                    <div className="icon flex items-center justify-center">
                      <Icon name="edit" size={16} />
                    </div>
                    Job Details
                  </h5>
                </div>
                <div className="card-form">
                  <div className="row">
                    {questions.map((q, i) => (
                      <div className="col-6" key={i}>
                        {React.createElement(types[q.type], q)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Notify */}
              <label>Notify:</label>
              <Select id="notify" options={['all', 'customer', 'none']} label="" cap />

              {/* Buttons */}
              <div className="d-flex">
                <div
                  data-cy="submitRequest"
                  className="common-Button secondary mr-2"
                  onClick={this.submit}
                >
                  {this.state.loading ? <Icon name="loading" spin size={42} /> : 'Submit Request'}
                </div>
              </div>
              <div style={{ position: 'relative', display: 'block', marginTop: '2em' }}>
                {this.state.error && (
                  <div className="alert alert-danger flex items-center">
                    <Icon name="alert_circle" size={16} /> {this.state.error}
                  </div>
                )}
                {this.state.successTaskId && (
                  <a
                    data-cy="success"
                    href={`/tasks/${this.state.successTaskId}`}
                    className="alert alert-success"
                  >
                    Success! Click to view task.
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <style>{'.form-group .item { padding: .5rem; }'}</style>
      </div>
    );
  }
}

export default connect(s => {
  const workOrder = s.workorders[global.id()];
  const serviceOptions = _.values(s.services).filter(service =>
    U.service.isLeaf(s.services, service.id)
  );
  const { brands, services } = s;
  const user = s.users[s.me.userId];
  return {
    brands,
    services,
    user,
    workOrder,
    serviceOptions,
  };
})(TaskAdd);
