import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { Search, Text } from '../../../com/ui/form';
import { stripeKey } from '../../../config/const';

const style = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const initStripe = () =>
  setTimeout(() => {
    // eslint-disable-next-line no-multi-assign
    const stripe = (global.stripe = global.Stripe(stripeKey));
    const elements = stripe.elements();
    // eslint-disable-next-line no-multi-assign
    const card = (global.card = elements.create('card', { hidePostalCode: true }));
    if (document.getElementById('card-element')) {
      card.mount('#card-element', { style });
      card.addEventListener('change', event => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    }
  }, 0);

class Payment extends React.Component {
  static propTypes = { state: T.object.isRequired };

  componentDidMount() {
    initStripe();
  }

  componentWillUpdate(p) {
    if (!this.props.promosDisabled && p.promosDisabled) {
      U.redux.set('form.promos', []);
    }
    const wasTest = _.values(this.props.form.roles).includes(U.user.Role.TESTER);
    const isTest = _.values(p.form.roles).includes(U.user.Role.TESTER);
    if (wasTest !== isTest) {
      initStripe(isTest ? 'test' : 'default', this);
    }
    if ((p.form.promo || '').startsWith('#')) {
      U.redux.set('form.promo', p.form.promo.replace(/#/, ''));
    }
    if (this.props.state.paymentMethod !== 'New Card' && p.form.paymentMethod === 'New Card') {
      initStripe(isTest ? 'test' : 'default');
    }
  }

  render() {
    const cards = _.get(this.props.state, 'payment.stripe.cards', []).map(c => ({
      ...c,
      name: c.last4,
    }));
    const options = ['New Card', 'No Card', cards.length ? 'Existing Card' : null].filter(v => v);
    return (
      <div className="info">
        <div className="card-head">
          <div className="row">
            <h5 className="title col-sm-6 flex items-center">
              <div className="icon flex items-center justify-center">
                <Icon name="credit_card_outline" size={16} />
              </div>
              Payment
            </h5>
          </div>
        </div>
        <div className="card-form">
          <Search
            id="promos"
            options={this.props.promoOptions}
            multi
            noSort
            onFocus={() => U.api('post', 'promos/search', undefined, true)}
            disabled={this.props.promosDisabled}
          />
          <Search default="New Card" id="paymentMethod" options={options} inline />
          <div className={this.props.state.paymentMethod !== 'New Card' && 'hidden'}>
            <div id="card-errors" />
            <div className="form-group mx-3">
              <label>Add Card</label>
              <div id="card-element" />
            </div>
          </div>
          {this.props.state.paymentMethod === 'Existing Card' && (
            <Search id="stripeCardId" label="Existing Cards" options={cards} />
          )}
          {this.props.state.paymentMethod === 'No Card' && (
            <Text req id="noCardReason" label="Reason for not giving card" />
          )}
        </div>
      </div>
    );
  }
}

export default connect(s => {
  const promosDisabled = _.get(s, 'form.price.labor.options.0.id') === 17600;
  let promos = _.values(s.promos)
    .filter(p => p.status === 'active')
    .map(p => ({ id: p.code, name: p.name }));
  promos = promos.sort((a, b) => {
    if (a.id === 'SAVE20' && b.id !== 'SAVE20') {
      return 1;
    }
    if (a.id !== 'SAVE20' && b.id === 'SAVE20') {
      return -1;
    }
    return a.name < b.name ? -1 : 1;
  });
  return {
    promosDisabled,
    form: s.form,
    promoOptions: promos,
  };
})(Payment);
