import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Modal, Search } from '../../com/ui/form';

class Brands extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { pro: p.pro };
    this.addRestriction = this.addRestriction.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.toggleTier = this.toggleTier.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(this.props.pro, p.pro)) {
      this.setState({ pro: p.pro });
    }
  }

  openModal(type) {
    this.setState({ modal: true, brands: [], service: '', type });
  }

  async addRestriction() {
    if (!this.state.type) {
      return this.setState({ error: 'Type required' });
    }
    if (!this.state.service) {
      return this.setState({ error: 'Service required' });
    }
    if (!this.state.brands.length) {
      return this.setState({ error: 'Brands required' });
    }
    const changes = [
      {
        action: 'replace',
        path: `eligibleBrands.${this.state.type}List.${this.state.service}`,
        value: this.state.brands,
      },
    ];
    await U.api('put', `serviceproviders/${global.id()}`, changes, true);
    this.setState({ modal: false });
  }

  async onUpdate(path) {
    const changes = [
      { action: 'replace', path: path.replace('pro.', ''), value: _.get(this.state, path) },
    ];
    await U.api('put', `serviceproviders/${global.id()}`, changes, true);
  }

  async onRemove(type, serviceId) {
    const value = _.omit(_.get(this.state.pro, `eligibleBrands.${type}List`), serviceId);
    const changes = [{ action: 'replace', path: `eligibleBrands.${type}List`, value }];
    await U.api('put', `serviceproviders/${global.id()}`, changes, true);
  }

  toggleTier(action, tier) {
    let brands = _.values(this.state.brands);
    _.mapValues(this.props.brandMap, ({ type }, name) => {
      if (type === tier) {
        if (action === 'add') {
          brands = [...brands.filter(b => b !== name), name];
        } else {
          brands = brands.filter(b => b !== name);
        }
      }
    });
    this.setState({ brands });
  }

  render() {
    return (
      <div>
        <div className="container-fluid pt-5">
          <a
            className="btn btn-primary mb-3"
            href="/reports/time/conversion-by-vendor"
            target="_blank"
            rel="noopener noreferrer"
          >
            View conversion Rate
          </a>
          {!this.props.hideExclude && (
            <div>
              <div className="d-flex">
                <h3 className="mr-3 flex-1">Excluded Brands</h3>
                <div className="btn btn-primary" onClick={() => this.openModal('exclude')}>
                  Add Restriction
                </div>
              </div>
              {_.map(_.get(this.props.pro, 'eligibleBrands.excludeList'), (brands, serviceId) => {
                const path = `pro.eligibleBrands.excludeList.${serviceId}`;
                return (
                  <div>
                    <Search
                      id={path}
                      options={this.props.brands}
                      multi
                      label="Brands"
                      className="mr-3 flex-1"
                      style={{ width: '1000px' }}
                    />
                    <div className="d-flex">
                      <div className="btn btn-primary mr-3" onClick={() => this.onUpdate(path)}>
                        Save
                      </div>
                      <div
                        className="btn btn-secondary"
                        onClick={() => this.onRemove('exclude', serviceId)}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!this.props.hideInclude && (
            <div>
              <div className="d-flex">
                <h3 className="mr-3 flex-1">Included Brands</h3>
                <div className="btn btn-primary" onClick={() => this.openModal('include')}>
                  Add Restriction
                </div>
              </div>
              {_.map(_.get(this.props.pro, 'eligibleBrands.includeList'), (brands, serviceId) => {
                const path = `pro.eligibleBrands.includeList.${serviceId}`;
                return (
                  <div key={serviceId}>
                    <Search
                      id={path}
                      options={this.props.brands}
                      multi
                      className="flex-1"
                      label={_.get(this.props.serviceMap[serviceId], 'name')}
                      style={{ width: '1000px' }}
                    />
                    <div className="d-flex">
                      <div className="btn btn-primary mr-3" onClick={() => this.onUpdate(path)}>
                        Save
                      </div>
                    </div>
                    <div>
                      <div
                        className="btn btn-secondary"
                        onClick={() => this.onRemove('include', serviceId)}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Modal title="Add Restriction" submit={this.addRestriction}>
          <div className="text-danger">{this.state.error}</div>
          <Search id="type" options={['exclude', 'include']} />
          <Search id="service" options={this.props.services} />
          <Search id="brands" multi options={this.props.brands} />
          <div className="row">
            <div className="col">
              <div
                className="btn btn-primary mb-3"
                onClick={() => this.toggleTier('add', 'standard')}
              >
                Add Standard
              </div>
              <div className="btn btn-danger" onClick={() => this.toggleTier('remove', 'standard')}>
                Remove Standard
              </div>
            </div>
            <div className="col">
              <div
                className="btn btn-primary mb-3"
                onClick={() => this.toggleTier('add', 'premium')}
              >
                Add Premium
              </div>
              <div className="btn btn-danger" onClick={() => this.toggleTier('remove', 'premium')}>
                Remove Premium
              </div>
            </div>
            <div className="col">
              <div
                className="btn btn-primary mb-3"
                onClick={() => this.toggleTier('add', 'luxury')}
              >
                Add Luxury
              </div>
              <div className="btn btn-danger" onClick={() => this.toggleTier('remove', 'luxury')}>
                Remove Luxury
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(s => {
  const brandMap = s.brands;
  const brands = _.keys(s.brands)
    .sort()
    .map(b => ({ id: b, name: b }));
  const brands2 = _.keys(s.brands).sort();
  const serviceMap = s.services;
  const services = _.sortBy(_.values(s.services), 'name');
  const pro = s.pros[global.id()];
  const hideExclude = !!_.values(_.get(pro, 'eligibleBrands.includeList')).length;
  const hideInclude = !!_.values(_.get(pro, 'eligibleBrands.excludeList')).length;
  return { brandMap, brands, services, pro, serviceMap, brands2, hideExclude, hideInclude };
})(Brands);

// additional pm checks
