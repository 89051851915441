import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import nullthrows from 'nullthrows';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';
import InteractiveTooltip, { Ref } from '.';

const ref = React.createRef<Ref>();

const options = [
  'top',
  'bottom',
  'right',
  'left',
  'auto',
  'auto-start',
  'auto-end',
  'top-start',
  'top-end',
  'bottom-start',
  'bottom-end',
  'right-start',
  'right-end',
  'left-start',
  'left-end',
];

const handleButtonClick = () => {
  nullthrows(ref.current).dismiss();
};

export default {
  title: 'Core/Interactive Tooltip',
  component: InteractiveTooltip,
  parameters: {
    backgrounds: {
      default: 'light',
    },
  },
  args: {
    title: 'Interactive Tooltip Title',
    description: 'Interactive Tooltip Description.',
    trigger: 'Click Here to see Tooltip',
    placement: 'bottom-end',
  },
  argTypes: {
    placement: { control: { type: 'select', options } },
  },
  decorators: [
    Story => (
      <div>
        Tooltip story is centered so that you can more easily test placement.
        <div className="flex h-96 w-full items-center justify-center">
          <Story />
        </div>
      </div>
    ),
  ],
} as ComponentMeta<typeof InteractiveTooltip>;

const Template: ComponentStory<typeof InteractiveTooltip> = args => (
  <InteractiveTooltip {...args} />
);

export const Default = Template.bind({});
Default.args = {};

export const ConfirmClose = Template.bind({});
ConfirmClose.args = {
  title: 'Are you sure?',
  description: 'Your work order will be cancelled if not confirmed by Wed, Apr 20, 11:59pm.',
  primaryButtonProps: {
    size: 'medium',
    children: 'No, Continue Form',
    onClick: () => handleButtonClick(),
  },
  ref,
  secondaryButtonProps: {
    size: 'medium',
    children: 'Yes, Exit',
    onClick: () => handleButtonClick(),
  },
  trigger: <Icon name="exit_to_app" className="text-grey-dark" size={24} />,
};

export const BrandInfo = Template.bind({});
BrandInfo.args = {
  title: 'Why do we need the brand and model number?',
  description:
    'Every model has it’s own specific solutions. We need to know the exact brand and model number of your appliance to find the right repair parts.',
  placement: 'bottom',
  trigger: (
    <div className="flex space-x-1">
      <Icon name="info_outline" className="text-primaryCTA" size={16} />
      <Text className="text-primaryCTA" type="button">
        Why do we need this?
      </Text>
    </div>
  ),
};

export const HowToScan = Template.bind({});
HowToScan.args = {
  title: 'How to scan text from your iPhone',
  description: (
    <div>
      <Text className="text-grey-dark">Only supported on models with iOS 15.4 or later.</Text>
      <br />
      <Text className="text-grey-dark">
        In the text entry field, tap the cursor and select Scan Text (or the icon). Point the camera
        at the text you wish to scan and tap insert.'
      </Text>
    </div>
  ),
  trigger: (
    <div className="flex space-x-1">
      <Text className="text-primaryCTA" type="button">
        How do I scan this?
      </Text>
    </div>
  ),
};

export const ConfirmCloseOnModal = Template.bind({});
ConfirmCloseOnModal.args = {
  className: 'text-left',
  title: 'Are you sure?',
  description: 'Your work order will be cancelled if not confirmed by Wed, Apr 20, 11:59pm.',
  primaryButtonProps: {
    size: 'medium',
    children: 'No, Continue Form',
    onClick: () => handleButtonClick(),
  },
  ref,
  secondaryButtonProps: {
    size: 'medium',
    children: 'Yes, Exit',
    onClick: () => handleButtonClick(),
  },
  trigger: <Icon name="exit_to_app" className="text-danger" size={24} />,
};
ConfirmCloseOnModal.decorators = [
  Story => (
    <Modal isOpen>
      <Modal.Header
        rightContent={
          <span className="flex items-center justify-end">
            <Story />
          </span>
        }
      />
      <Modal.Body>
        <div className="h-96" />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  ),
];
