import React from 'react';
import { Loader, Text } from '@/components';

type Props = { isLoading: boolean; text: string };

export default function LoadingIndicator({ isLoading, text }: Props): JSX.Element {
  if (!isLoading) {
    return <></>;
  }

  return (
    <div className="fixed inset-0 flex animate-fadeIn items-center justify-center">
      <Loader isLoading fullscreen size="large" />
      <Text className="relative z-[99999] mt-24" type="page-title">
        {text}
      </Text>
    </div>
  );
}
