import React from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Avatar, Button, Icon, Text } from '@/components';
import { useGetFeatures } from '@/hooks/api/features';
import { BookingState } from '../util';

type Props = {
  onClick: (date: string) => void;
  state: Pick<BookingState, 'availTSlots' | 'zip'>;
};

export default function SelectedDates({ onClick, state }: Props): JSX.Element {
  const featureQuery = useGetFeatures();
  const b2cAvailability1Slot = featureQuery.isSuccess
    ? featureQuery.data.b2cAvailability1Slot
    : false;

  const selectedDates = _.keys(_.pickBy(state.availTSlots));
  const tz = U.timezone({ postalCode: state.zip.value });

  return (
    <section className="mt-6">
      <Text className="mb-4" type="page-title">
        {b2cAvailability1Slot ? 'Estimated Arrival Time' : 'Selected Times'}
      </Text>
      {selectedDates.length === 0 ? (
        <div className="mt-6 flex">
          <Avatar
            className="bg-background-light"
            iconProps={{ name: 'calendar', color: 'grey.4' }}
            variant="circle"
            size="large"
          />
          <div>
            <Text className="mt-1 text-secondary-5" type="button">
              No availability added
            </Text>
            <Text className="mt-1 text-secondary-5">To get started, select a timeslot above</Text>
          </div>
        </div>
      ) : (
        <div>
          {selectedDates.map(date => {
            const startName = mt(+date).tz(tz).format('ha').toLowerCase();
            const endName = mt(+date).tz(tz).add(4, 'hours').format('ha').toLowerCase();
            const betweenText = `between ${startName}-${endName}`;
            return (
              <div className="mb-3 flex justify-between" key={date}>
                <div className="align-center flex space-x-2">
                  <Text type="button">{mt(+date).tz(tz).format('ddd, MMM DD')}</Text>
                  <Text className="text-grey-dark">{betweenText}</Text>
                </div>
                <Button
                  className="pointer px-1"
                  size="small"
                  variant="link"
                  onClick={() => onClick(date)}
                >
                  <Icon name="trash_can_outline" className="text-primaryCTA" size={16} />
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
}
