import React, { memo,useCallback, useEffect, useState } from 'react';
import U from '@nanaio/util';
import { Button, Form, Icon, Input, InputType, Loader } from '@/components';

const PaymentForm = memo(function PaymentForm({ mentorProId, menteeProId, onPaid, totalPaid }) {
  const [amount, setAmount] = useState(undefined);
  const [error, setError] = useState('');
  const [paying, setPaying] = useState(false);

  const payMentor = async () => {
    const payload = {
      user: { proId: mentorProId },
      paymentMethod: 'bankAccount',
      type: 'credit',
      amount,
      description: 'mentorship',
      metadata: {
        menteeProId,
      },
    };

    setPaying(true);

    try {
      const response = await U.api('post', 'transfers', payload);

      if (response.errMsg) {
        throw new Error(response.errMsg);
      }

      onPaid();
    } catch (err) {
      setError(err.message);
      setPaying(false);
    }
  };

  const handleSubmit = () => {
    let error = '';

    if (amount <= 0 || !Number.isFinite(amount)) {
      error = 'The amount must be a number greater than 0.';
    }

    setError(error);

    if (!error) {
      payMentor();
    }
  };

  return (
    <Form>
      <div className="d-flex">
        <Input
          className="grow-1 mb-0 mr-1"
          id={`${mentorProId}-${menteeProId}-${totalPaid}`}
          label=""
          onChange={setAmount}
          value={amount}
          type={InputType.MONEY}
        />

        <Button className="ml-1" disabled={paying} size="small" onClick={handleSubmit}>
          {paying ? <Icon name="loading" spin size={16} /> : 'Pay'}
        </Button>
      </div>

      {error ? <p className="mb-0 mt-1 text-danger">{error}</p> : null}
    </Form>
  );
});

export default function MentorshipReport() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  const generateReport = useCallback(async () => {
    setLoading(true);

    try {
      const response = await U.api('get', 'workorders/mentorship-report');

      if (response.errMsg) {
        throw new Error(response.errMsg);
      }

      setData(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    generateReport();
  }, [generateReport]);

  return loading ? (
    <Loader isLoading fullscreen />
  ) : (
    <div className="d-flex w-100 p-2">
      {error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <div className="table_container_body">
          <table className="table-sm table-striped table-hover table">
            <thead>
              <tr>
                <th className="text-center align-middle">Mentor</th>
                <th className="text-center align-middle">Mentee</th>
                <th className="text-center align-middle"># Jobs Completed (Pair)</th>
                <th className="text-center align-middle"># Jobs Completed (Solo)</th>
                <th className="text-center align-middle">Total $ Paid Mentor</th>
                <th className="text-center align-middle">Total $ Paid Mentee</th>
                <th className="text-center align-middle">Pay Mentor</th>
              </tr>
            </thead>

            <tbody>
              {data.data.map(row => {
                const { primary, mentee, count, paidToMentor, paidToMentee, menteeSoloJobs } = row;

                return (
                  <tr key={`${primary}-${mentee}`}>
                    <td>{data.refs[primary]?.fullName}</td>
                    <td>{data.refs[mentee]?.fullName}</td>
                    <td className="text-center align-middle">{count}</td>
                    <td className="text-center align-middle">{menteeSoloJobs}</td>
                    <td className="text-right align-middle">${(paidToMentor / 100).toFixed(2)}</td>
                    <td className="text-right align-middle">${(paidToMentee / 100).toFixed(2)}</td>
                    <td className="align-middle">
                      <PaymentForm
                        mentorProId={data.refs[primary]?.proId}
                        menteeProId={data.refs[mentee]?.proId}
                        totalPaid={paidToMentor}
                        onPaid={generateReport}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
