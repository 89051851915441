import React, { useState } from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Button, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import ChangeStatus from '../../../actions/ChangeStatus';

type Props = {
  taskId: string;
};

export default function StatusButton({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)} variant="secondary">
        <Text type="button">{_.startCase(task.status)}</Text>
        {task.status === T.Status.CANCELLED && (
          <Text>
            {T.closeReasons.find(reason => reason.id === task.metadata.cancelReason)?.name ||
              task.metadata.cancelReason}
          </Text>
        )}
        {task.status === T.Status.CANCEL_REQUESTED && (
          <Text>{_.startCase(task.metadata.cancelRequestReason)}</Text>
        )}
        {task.status === T.Status.COMPLETED && task.metadata.cxCloseReason && (
          <Text>
            CX Closed Job -{' '}
            {task.metadata.cxCloseReason === T.CloseReason.OTHER
              ? task.metadata.cxCloseFeedback
              : T.closeReasons.find(reason => reason.id === task.metadata.cxCloseReason)?.name}
          </Text>
        )}
      </Button>

      {modalOpen && <ChangeStatus onClose={() => setModalOpen(false)} taskId={taskId} />}
    </>
  );
}
