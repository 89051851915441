import _ from 'lodash';
import { apiOrigin, zendeskURL } from '@/config/const';

type Props = {
  token: string;
  returnTo: string;
  setError: (error: string) => void;
};

export const forwardToZendesk = ({ token, returnTo, setError }: Props): void => {
  // Get Zendesk Token
  void fetch(`${apiOrigin}/api/users/me/zendesk`, {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    if (!response) {
      return setError('Empty Response');
    }
    return response.text().then(zendeskToken => {
      // Using Zendesk Token to redirect to Zendesk
      global.location.assign(
        `${zendeskURL}/access/jwt?jwt=${zendeskToken}&return_to=${encodeURIComponent(returnTo)}`
      );
    });
  });
};
