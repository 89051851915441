import React, { memo, useEffect, useRef,useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@/components';
import { appName, companyName } from '@/config/const';
import Stepper from './stepper';

function HowItWorks() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const slides = useRef([
    {
      title: 'Join the Platform',
      subtitle: `Apply and complete your onboarding to become eligible to claim jobs on the ${companyName} platform.`,
      backgroundImage: '/img/apply/how-it-works-1.png',
    },
    {
      title: 'Claim the Jobs You Want',
      subtitle:
        'Build your own schedule with jobs based on your skills, work radius, and when you want to work. Just tap “Claim” and the job is yours.',
      backgroundImage: '/img/apply/how-it-works-2.png',
    },
    {
      title: 'Complete Your Repairs',
      subtitle: `Use the ${appName} to submit your diagnosis, order parts, invoice customers, and complete your repairs.`,
      backgroundImage: '/img/apply/how-it-works-3.png',
    },
    {
      title: 'Get Paid',
      subtitle:
        'Receive a direct deposit to the account of your choice within 48hrs of completing each repair. Increase your earnings with monthly incentives.',
      backgroundImage: '/img/apply/how-it-works-4.png',
    },
  ]).current;

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setSelectedIndex((selectedIndex + 1) % slides.length);
    }, 3200);

    return () => {
      clearTimeout(currentTimeout);
    };
  }, [selectedIndex, slides.length]);

  return (
    <div className="apply-hiw">
      <div className="apply-container">
        <div className="px-6 pb-16 pt-24">
          <Text type="headline-3" className="text-center lg:hidden" tag="h3">
            How {companyName} Works
          </Text>

          <Slider slides={slides} selectedIndex={selectedIndex} onChange={setSelectedIndex} />
        </div>
      </div>
    </div>
  );
}

function Slider({ slides, selectedIndex, onChange }) {
  return (
    <div className="apply-hiw__slider lg:w-full">
      <SliderTexts
        slides={slides}
        className="apply-hiw__slider__texts lg:hidden"
        selectedIndex={selectedIndex}
      />

      <div className="apply-hiw__slider__container lg:mt-0 lg:grid lg:justify-center">
        <SliderControls
          className="apply-hiw__slider__controls lg:hidden"
          onChange={onChange}
          selectedIndex={selectedIndex}
          slides={slides}
        />

        <SliderSlides
          className="apply-hiw__slider__slides lg:w-full"
          slides={slides}
          selectedIndex={selectedIndex}
        />

        <div className="hidden lg:block">
          <Text type="headline-2" className="mt-24 font-bold" tag="h3">
            How {companyName} Works
          </Text>

          <Stepper
            className="mt-12"
            onChange={onChange}
            selectedIndex={selectedIndex}
            steps={slides.map(({ title, subtitle }) => ({ title, subtitle }))}
          />
        </div>
      </div>
    </div>
  );
}

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

function SliderTexts({ className, slides, selectedIndex }) {
  return (
    <>
      {slides.map(({ title, subtitle }, index) => (
        <div
          key={title}
          className={classnames(className, 'apply-hiw-slider-texts', {
            'apply-hiw-slider-texts--selected': selectedIndex === index,
          })}
        >
          <Text type="headline-5" className="text-center" tag="h5">
            {`${index + 1}. ${title}`}
          </Text>

          <Text className="mt-1 text-center opacity-80">{subtitle}</Text>
        </div>
      ))}
    </>
  );
}

SliderTexts.propTypes = {
  className: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

function SliderControls({ className, slides, selectedIndex, onChange }) {
  const classes = classnames(className, 'apply-hiw-slider-controls');

  return (
    <div className={classes}>
      {slides.map(({ title }, index) => (
        <button
          key={title}
          type="button"
          className={classnames('apply-hiw-slider-controls__control', {
            'apply-hiw-slider-controls__control--selected': selectedIndex === index,
          })}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
}

SliderControls.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

function SliderSlides({ className, slides, selectedIndex }) {
  const classes = classnames(className, 'apply-hiw-slider-slides');

  return (
    <div className={classes}>
      <div className="absolute right-0 top-0 -mr-20 -mt-6 lg:hidden">
        <img src="/svg/apply-benefits-slider-background.svg" alt="background" />
      </div>

      <div className="absolute bottom-0 -mb-8 -ml-16 lg:hidden">
        <img src="/svg/apply-benefits-slider-background.svg" alt="background" />
      </div>

      <div className="apply-hiw-slider-slides__slides">
        {slides.map(({ backgroundImage }) => (
          <div
            key={backgroundImage}
            className="apply-hiw-slider-slides__slides__slide"
            style={{
              backgroundImage: `url("${backgroundImage}")`,
              transform: `translateY(-${100 * selectedIndex}%)`,
            }}
          />
        ))}
      </div>

      <img className="pointer-events-none relative" src="/img/apply/phone.png" alt="phone" />
    </div>
  );
}

SliderSlides.propTypes = {
  className: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

export default memo(HowItWorks);
