import React, { ReactElement } from 'react';
import nullthrows from 'nullthrows';
import type { InputProps } from './Input';
import SearchInput from './Search'; // eslint-disable-line import/no-cycle

export const getProps = ({ onChange, value, ...rest }: InputProps): Props => ({
  onChange: nullthrows(onChange),
  value: typeof value === 'boolean' ? value : undefined,
  ...rest,
});

export type Props = {
  disabled?: boolean;
  onChange: (value?: boolean) => void;
  placeholder?: string;
  value?: boolean;
};

export default function BoolInput({
  disabled,
  onChange,
  placeholder = 'Select',
  value,
}: Props): ReactElement {
  const handleChange = (value?: 'No' | 'Yes') => {
    if (value === 'Yes') {
      onChange(true);
    } else if (value === 'No') {
      onChange(false);
    } else {
      onChange();
    }
  };

  let newValue;
  if (value === true) {
    newValue = 'Yes';
  } else if (value === false) {
    newValue = 'No';
  }

  return (
    <SearchInput
      disabled={disabled}
      onChange={value => handleChange(value as 'No' | 'Yes' | undefined)}
      options={['Yes', 'No']}
      placeholder={placeholder}
      value={newValue}
    />
  );
}
