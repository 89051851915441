import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { companyName } from '@/config/const';
import { Text, Textbox } from '../../../com/ui/form';

export default class Survey extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  static propTypes = { onComplete: PropTypes.func.isRequired };

  state = {};

  onBack = () => {
    this.setState({ step: 0 });
  };

  onNext = () => {
    if (!this.state.answer) {
      return this.setState({ error: 'Please select an option' });
    }
    if (['other', 'yes'].includes(this.state.answer)) {
      this.setState({ step: 1 });
    } else {
      this.onSubmit();
    }
  };

  onSubmit = async () => {
    const changes = [
      {
        path: 'metadata.taskQuestions.hadPreviousTechnician',
        value: _.startCase(this.state.answer),
      },
      {
        path: 'metadata.taskQuestions.hadPreviousTechnicianOther',
        value: this.state.other,
      },
      {
        path: 'metadata.taskQuestions.previousTechnicianOutcome',
        value: this.state.details,
      },
    ].filter(change => change.value);
    const job = await U.api('put', `tasks/${global.id()}`, changes, ['save']);
    this.props.onComplete(job);
  };

  render() {
    if (!this.state.step) {
      const otherSelected =
        this.state.answer === 'other' ||
        (this.state.other && !['yes', 'no'].includes(this.state.answer));
      return (
        <div>
          <div className="mb-3" style={{ fontWeight: 600 }}>
            Has a technician (outside of {companyName}) done any work on the appliance(s) you need
            repaired, in the past 30 days?
          </div>
          <div
            className="flex cursor-pointer items-center"
            onClick={() => this.setState({ answer: 'yes' })}
          >
            {this.state.answer === 'yes' ? (
              <Icon className="mr-3" color="#479DC8" name="radio_button_checked" size={20} />
            ) : (
              <Icon className="mr-3" color="#000" name="radio_button_unchecked" size={20} />
            )}
            <div>Yes</div>
          </div>
          <div
            className="mt-3 flex cursor-pointer items-center"
            onClick={() => this.setState({ answer: 'no' })}
          >
            {this.state.answer === 'no' ? (
              <Icon className="mr-3" color="#479DC8" name="radio_button_checked" size={20} />
            ) : (
              <Icon className="mr-3" color="#000" name="radio_button_unchecked" size={20} />
            )}
            <div>No</div>
          </div>
          <div className="flex items-center">
            {otherSelected ? (
              <Icon
                className="mr-3 cursor-pointer"
                color="#479DC8"
                name="radio_button_checked"
                onClick={() => this.setState({ answer: 'other' })}
                size={20}
              />
            ) : (
              <Icon
                className="mr-3 cursor-pointer"
                color="#000"
                name="radio_button_unchecked"
                onClick={() => this.setState({ answer: 'other' })}
                size={20}
              />
            )}
            <div className="pointer mr-3" onClick={() => this.setState({ answer: 'other' })}>
              Other:
            </div>
            <Text
              id="other"
              className="flex-1"
              label=""
              onChange={() => this.setState({ answer: 'other' })}
            />
          </div>
          <div className="text-danger">{this.state.error}</div>
          <div
            className={`button-dark mt-3 ${this.state.answer ? '' : 'disabled'}`}
            style={{ width: '100px' }}
            onClick={this.onNext}
          >
            Next
          </div>
        </div>
      );
    }
    return (
      <div>
        <Textbox
          id="details"
          label="Please describe what the technician did and the outcome"
          className="mb-3 mt-5"
        />
        <div className="d-flex">
          <div className="button-light mr-3" onClick={this.onBack}>
            Back
          </div>
          <div className="button-dark" style={{ width: '100px' }} onClick={this.onSubmit}>
            Submit
          </div>
        </div>
      </div>
    );
  }
}
