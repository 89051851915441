import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Table from '@nanaio/table';
import { ErrorResponse, PricingRule, Service, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Form, Input, InputType, Modal, Table as TableComponent, Text } from '@/components';

type Options = {
  brandTiers: string[];
  customerTypes: string[];
  packageNames: string[];
  regionNames: string[];
  services: { id: string; name: string }[];
};

function EditPackageComponent({
  id,
  onSuccess,
  toggleOpen,
}: Table.AddUiProps | Table.EditUiProps): JSX.Element {
  const [error, setError] = useState<string>();
  const idToService = U.redux.get<Record<string, Service>>('services');
  const [options, setOptions] = useState<Options>();
  const [pricingRules, setPricingRules] = useState<Partial<PricingRule>[]>([{}]);

  useEffect(() => {
    void U.api<Options>('get', 'pricing/rule/options').then(response => {
      if (!('errMsg' in response)) {
        setOptions(response);
      }
    });
    if (id) {
      void U.api<PricingRule>('get', `pricing/rule/${id}`).then(response => {
        if (!('errMsg' in response)) {
          setPricingRules([response]);
        }
      });
    }
  }, [id]);

  const addRule = () => setPricingRules(pricingRules => [...pricingRules, {}]);

  const handleSave = async () => {
    let response: PricingRule | ErrorResponse;
    if (id) {
      const [pricingRule] = pricingRules;
      if (pricingRule.endTime && m(pricingRule.endTime).isBefore(m().startOf('d'))) {
        return setError('End time must be today or in the future');
      }
      response = await U.api<PricingRule>('put', `pricing/rule/${id}`, [
        { path: 'endTime', value: pricingRule.endTime },
      ]);
    } else {
      response = await U.api<PricingRule>('post', 'pricing/rule/create', pricingRules);
    }
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else {
      onSuccess(response);
    }
  };

  const removeRule = (index: number) =>
    setPricingRules(pricingRules => pricingRules.filter((rule, i) => i !== index));

  return (
    <Form onChange={setPricingRules} value={pricingRules}>
      <Modal isOpen>
        <Modal.Header title={id ? 'Update Rule' : 'Create Rule'} />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          {_.times(pricingRules.length, i => {
            const serviceModifierOptions = pricingRules[i]?.service?.id
              ? idToService[nullthrows(pricingRules[i]?.service?.id)].modifiers
              : undefined;
            return (
              <div key={i}>
                <div className="flex justify-between">
                  <Text type="subtitle-1">Rule {i + 1}</Text>
                  {i === pricingRules.length - 1 && (
                    <Text onClick={addRule} color="primaryCTA">
                      + Add New Rule
                    </Text>
                  )}
                  {!!i && (
                    <Text color="danger" onClick={() => removeRule(i)}>
                      Remove Rule
                    </Text>
                  )}
                </div>
                <Input
                  id={`${i}.brandTier`}
                  disabled={Boolean(id)}
                  label="Brand Tier (optional)"
                  options={options?.brandTiers}
                />
                <Input
                  id={`${i}.customerType`}
                  disabled={Boolean(id)}
                  label="Customer Type"
                  options={options?.customerTypes}
                  required
                />
                {id && <Input id={`${i}.endTime`} type={InputType.DATE} />}
                <Input
                  id={`${i}.packageName`}
                  disabled={Boolean(id)}
                  label="Package Name"
                  options={options?.packageNames}
                  required
                />
                <Input
                  id={`${i}.regionName`}
                  disabled={Boolean(id)}
                  label="Region (optional)"
                  options={options?.regionNames}
                />
                <Input
                  id={`${i}.service`}
                  disabled={Boolean(id)}
                  label="Service"
                  object
                  options={options?.services}
                  required
                />
                {serviceModifierOptions && (
                  <Input
                    id={`${i}.serviceModifier`}
                    disabled={Boolean(id)}
                    label="Service Modifier"
                    options={serviceModifierOptions}
                  />
                )}
                {id && <Input id={`${i}.startTime`} disabled type={InputType.DATE} />}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer onClose={toggleOpen} onSave={handleSave} />
      </Modal>
    </Form>
  );
}

const EditPackage = connect(s => ({ services: s }))(EditPackageComponent);

export default function PricingRulesTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.pricingRule} // eslint-disable-line react/jsx-props-no-spreading
      addUi={EditPackage}
      editUi={EditPackage}
    />
  );
}
