import React from 'react';
import { Mention, MentionItem,MentionsInput } from 'react-mentions';
import { Text } from '../../core';
import { FormControl, Input, InputType } from '../../form';
import theme from '../../theme';
import { Event, Option } from './types';

type Props = {
  copyFromEmailBody: boolean;
  handleMessageChange: (id: string, event: Event, mentionItems: MentionItem[]) => void;
  handleSmsToChange: (value: string) => void;
  options: Option[];
  setCopyFromEmailBody: (value: boolean) => void;
  showEmail?: boolean;
  sms?: string;
  smsError?: string;
  smsTo?: string;
  smsToOptions: string[];
};

export default function Sms({
  copyFromEmailBody,
  handleMessageChange,
  handleSmsToChange,
  options,
  setCopyFromEmailBody,
  showEmail,
  sms,
  smsError,
  smsTo,
  smsToOptions,
}: Props): JSX.Element {
  return (
    <div>
      <Text color="danger">{smsError}</Text>
      <Input
        capitalize
        label="Sms To"
        onChange={value => handleSmsToChange(value as string)}
        options={smsToOptions}
        value={smsTo}
      />
      <FormControl label="Sms">
        <MentionsInput
          allowSpaceInQuery
          onChange={(e, _v, _p, m) => handleMessageChange('sms', e, m)}
          style={{ control: theme.formControl }}
          value={sms || ''}
        >
          <Mention trigger="@" data={options} />
        </MentionsInput>
      </FormControl>
      {showEmail && (
        <Input
          label="Copy From Email Body"
          onChange={value => setCopyFromEmailBody(value as boolean)}
          type={InputType.BOOL}
          value={copyFromEmailBody}
        />
      )}
    </div>
  );
}
