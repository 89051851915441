import React from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from '@/components';
import TableComponent from '@/components/Table';
import AddPro from './add';

const StatusUI = ({ value }) => {
  const statusToColor = {
    [U.pro.Status.ACTIVE]: 'success',
    [U.pro.Status.APPLICANT]: 'primary',
    [U.pro.Status.BACKGROUND_SCREENING]: 'primary',
    [U.pro.Status.ACCOUNT_ON_HOLD]: 'accent-orange',
    [U.pro.Status.NOT_SERVING_NOW]: 'danger',
    [U.pro.Status.REJECTED]: 'grey-dark',
    [U.pro.Status.SUSPENDED]: 'danger',
    [U.pro.Status.VACATION_MODE]: 'danger',
  };
  return <Badge color={statusToColor[value]}>{_.startCase(value)}</Badge>;
};
StatusUI.propTypes = { value: PropTypes.string.isRequired };

const uiModuleIdToModule = {
  status: { cellUi: StatusUI },
};

export const getSettings = () => ({
  ...Table.databases.default.pro,
  addUi: AddPro,
  uiModuleIdToModule,
});

export default function ProsTable() {
  return <TableComponent {...getSettings()} />; // eslint-disable-line react/jsx-props-no-spreading
}
