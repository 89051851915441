import _ from 'lodash';
import m from 'moment';

export const getTagOptions = () => [
  'Completed Jobs Bonus',
  'Tier 1',
  'Tier 2',
  ..._.times(12, n => m().add(-n, 'M').format('YYYY-MM')),
];

export const paymentMethodOptions = ['bankAccount', 'cash'];
