import React from 'react';
import { DatabaseId } from '@nanaio/table';
import _ from 'lodash';
import TableComponent from '@/components/Table';
import { loadCatalogPartCategoryOptions } from '../../../com/util';
import Edit from './edit';

export const getModuleIdToModule = () => ({
  partNumber: {},
  mfgCode: {},
  name: {},
  appliance: {},
  category: { loadOptions: loadCatalogPartCategoryOptions, loadOptionsOnMount: true },
  desc: {},
  availability: {}, // ["backorder", "available", "nla", "unknown"]
  costPrice: { type: 'money' },
  retailPrice: { type: 'money' },
  qtyAvailable: { type: 'number' },
  brand: {},
  model: {},
  allAppliances: {},
  allBrands: {},
  allCategories: {},
  allModels: {},
  productType: {},
  lastScrapedTime: { type: 'time' },
  createTime: { type: 'time' },
});

const settings = {
  databaseId: DatabaseId.DEFAULT,
  defaultQuery: {
    columns: [
      'name',
      'mfgCode',
      'partNumber',
      'desc',
      'availability',
      'costPrice',
      'retailPrice',
      'qtyAvailable',
      'category',
      'appliance',
      'brand',
      'model',
      'allAppliances',
      'allBrands',
      'allModels',
      'allCategories',
      'lastScrapedTime',
      'createTime',
    ],
  },
  keyToModule: getModuleIdToModule(),
  name: 'catalogPart',
  namePlural: 'catalogParts',
  loadUrl: 'catalogparts/search',
  rowUrl: ({ row }) => `/reports/catalog-parts/${row.id}`,
};

export default function CatalogPartsTable() {
  return (
    <TableComponent
      {...settings} // eslint-disable-line react/jsx-props-no-spreading
      addUi={props => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
      editUi={props => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}
