import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import m from 'moment';
import { Button } from '@/components';
import { loadTasks, updateTask } from '../com/task';
import Notes from '../com/ui/notes';
import Communication from './details/com/communication';

class TaskNotes extends React.Component {
  loadPreviousTasks = () => {
    const query = { 'customer.user.id': this.props.task.customer.user.id };
    loadTasks({ query });
  };

  render() {
    return (
      <section className="admin-main-body">
        <div className="vc-container">
          <div className="h-screen">
            <Communication notesOnly />
          </div>
          {!!this.props.matchingTasks.length && (
            <div className="px-3 py-4" style={{ backgroundColor: '#fafafa' }}>
              <h5 className="text-bold text-center">Past Visits</h5>
            </div>
          )}
          {this.props.matchingTasks.map((t, n) => (
            <div key={t.id} style={{ backgroundColor: '#fff' }}>
              <Notes
                updateFunc={updateTask}
                id={t.id}
                savePath="notes"
                loadPath={`tasks.${t.id}.notes`}
                linkText="Open Job"
                link={`/tasks/${t.id}`}
                title={`Visit #${n + 1} on ${m(t.lastVisitDate).format('MM/DD/YY')}`}
              />
            </div>
          ))}
          <div className="d-flex justify-content-center mt-4 pb-4">
            <Button onClick={this.loadPreviousTasks} size="small">
              Load Notes from Previous Tasks
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default connect((s, p) => {
  const task = s.tasks[p.taskId];
  const matchingTasks = _.values(s.tasks).filter(
    t => t.customer.user.id === task.customer.user.id && t.id !== task.id
  );
  return { task, matchingTasks };
})(TaskNotes);
