import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { sendPageview } from '@/com/marketing';
import { Alert, Input, InputType, Text } from '@/components';
import { isValid } from '@/components/form/TextInput';
import { companyName } from '@/config/const';
import Footer from './com/Footer';
import { BookingState } from './util';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Contact({ onChange, onNext, state, ...props }: Props): JSX.Element {
  const [stateErrors, setStateErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    sendPageview(`booking-page/contact`);
  }, []);

  const isDisabled = !state.firstName || !state.lastName || !state.email || !state.phone;

  const getStateErrors = useCallback(() => {
    const errors: Record<string, string> = {};
    if (!isValid[InputType.PHONE](state.phone || '')) {
      errors.phone = '10-digit mobile number is required';
    }
    if (!isValid[InputType.EMAIL](state.email || '')) {
      errors.email = 'Valid email address is required';
    }
    return errors;
  }, [state.phone, state.email]);

  const errors = useMemo(() => {
    return _.pick(getStateErrors(), _.keys(stateErrors));
  }, [stateErrors, getStateErrors]);

  const error = useMemo(() => {
    if (_.isEmpty(errors)) {
      return '';
    }
    return _.size(errors) === 1 ? _.values(errors)[0] : 'Please correct all errors';
  }, [errors]);

  const handleNext = useCallback(async () => {
    const stateErrors = getStateErrors();
    if (!_.isEmpty(stateErrors)) {
      setStateErrors(stateErrors);
      return;
    }
    await onNext();
  }, [getStateErrors, onNext]);

  return (
    <>
      <section>
        <Text type="headline-6">What is your contact information?</Text>
        <Text className="mt-2">Appointments will be confirmed via SMS and email.</Text>

        <div className="mt-6">
          {!!error && (
            <Alert className="mb-10" variant="error">
              {error}
            </Alert>
          )}
          <div className="flex">
            <Input
              id="firstName"
              label="First Name"
              onChange={e => {
                onChange('firstName', e);
              }}
              value={state.firstName}
              placeholder="Enter here"
              required
              className="mr-2 flex-1"
            />
            <Input
              id="lastName"
              label="Last Name"
              onChange={e => {
                onChange('lastName', e);
              }}
              value={state.lastName}
              placeholder="Enter here"
              required
              className="ml-2 flex-1"
            />
          </div>

          <Input
            id="phone"
            label="Mobile Number"
            onChange={e => {
              onChange('phone', e);
            }}
            value={state.phone}
            placeholder="Enter here"
            required
            leftIcon="phone_outline"
            type={InputType.PHONE}
            className="mt-2"
            error={errors.phone}
            helper={`By providing your phone number, you agree to receive text messages from ${companyName}. Message and data rates may apply. Message frequency varies.`}
          />

          <Input
            id="email"
            label="Email Address"
            onChange={e => {
              onChange('email', e);
            }}
            value={state.email}
            placeholder="Enter here"
            required
            leftIcon="email_outline"
            className="mt-5"
            error={errors.email}
            type={InputType.EMAIL}
          />

          <Alert icon="shield_lock_outline" className="mt-7">
            <div className="flex items-center">
              <Text className="ml-4 text-secondary-5">
                <strong className="font-semibold">
                  To protect your privacy, we mask your email and phone number.
                </strong>{' '}
                We will assign a temporary number for your technician to contact you.
              </Text>
            </div>
          </Alert>

          <Footer {...props} onNext={handleNext} disabled={isDisabled} error={error} />
        </div>
      </section>
    </>
  );
}
