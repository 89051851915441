import React, { useState } from 'react';
import { W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { openLink } from '../../utils';
import Badge from '../core/Badge';
import Icon from '../core/Icon';
import Text from '../core/Text';
import theme from '../theme';
import { timeSlotsPropType } from './util';

const styles = {
  active: {
    border: `2px solid ${theme.colors.primary}`,
    background: theme.hexToRGB(theme.colors.primary, 0.1),
  },
  disabled: { backgroundImage: 'url("/img/DayOffStripes.png")', backgroundSize: '100% 100%' },
  interested: {
    border: `2px solid ${theme.colors.accent.orange}`,
    background: theme.hexToRGB(theme.colors.accent.orange, 0.15),
  },
  slot: {
    width: '99px',
    border: '2px solid transparent',
  },
};

const statusToIcon = {
  cancelled: 'close',
  confirmed: 'check',
  enRoute: 'car',
  leftSite: 'check',
  noShow: 'warning',
  onSite: 'place',
  pending: 'help_outline',
};

export default function Slot({
  availability,
  availabilityIndex,
  disableHover,
  hasBorder,
  isDisabled, // agent is not allowed to mark available
  isInterested,
  isUnavailable,
  slotId,
  timeSlots,
  toggleAvailability,
  togglePreferred,
}) {
  const [isHover, setIsHover] = useState(false);

  const handleHover = isHover => !disableHover && setIsHover(isHover);

  let containerClassName = 'border-b border-grey-medium';
  let containerStyle = { height: 100, width: 118 };
  if (hasBorder && !isUnavailable) {
    containerClassName += ' border-l border-r';
  }
  if (toggleAvailability && !isDisabled) {
    containerClassName += ' cursor-pointer';
  }

  return (
    <div
      className={containerClassName}
      data-cy={slotId}
      style={{
        ...containerStyle,
        ...(isUnavailable ? styles.disabled : {}),
      }}
      onClick={() => (!isDisabled || availabilityIndex !== -1) && toggleAvailability?.()}
      onMouseEnter={() => !isDisabled && handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {availabilityIndex === -1 && !isHover && (
        <div
          className="m-2 h-20 overflow-y-auto rounded"
          style={{ ...styles.slot, width: hasBorder ? 99 : 100 }}
        >
          {timeSlots.map(timeSlot => (
            <Badge
              className="mb-1 cursor-pointer"
              key={timeSlot.taskId}
              iconProps={{ name: statusToIcon[timeSlot.proStatus || W.VisitProStatus.PENDING] }}
              variant="solid-faded"
              onClick={
                !toggleAvailability
                  ? () => openLink({ newTab: true, url: `/tasks/${timeSlot.taskId}` })
                  : undefined
              }
            >
              {_.isNumber(timeSlot.distance) ? timeSlot.distance : '?'} mi away
            </Badge>
          ))}
        </div>
      )}
      {(availabilityIndex !== -1 || isHover) && (
        <div className="m-2 flex-1 bg-white">
          <div
            className="m-0 flex h-20 flex-col justify-between overflow-y-auto rounded p-2"
            style={{
              ...styles.slot,
              ...(isInterested ? styles.interested : styles.active),
              ...(isHover && availabilityIndex === -1 ? { opacity: 0.5 } : {}),
            }}
          >
            <Text color={isInterested ? 'accent.orange' : 'primary'} type="button">
              Option{availabilityIndex !== -1 && ` ${availabilityIndex + 1}/${availability.length}`}
            </Text>
            <div className="ml-auto">
              <Icon
                color={isInterested ? 'accent.orange' : 'primary'}
                name={availability[availabilityIndex]?.preferred ? 'heart' : 'heart_outline'}
                onClick={event => togglePreferred && togglePreferred(event)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Slot.propTypes = {
  availability: PropTypes.arrayOf(W.slotPropType).isRequired,
  availabilityIndex: PropTypes.number.isRequired,
  disableHover: PropTypes.bool,
  hasBorder: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isInterested: PropTypes.bool,
  isUnavailable: PropTypes.bool.isRequired,
  slotId: PropTypes.string,
  timeSlots: timeSlotsPropType.isRequired,
  toggleAvailability: PropTypes.func,
  togglePreferred: PropTypes.func,
};
