import React from 'react';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import pf from 'phone-formatter';
import { Button, Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';

export default function AuthInfo(): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const taskId = nullthrows(global.id());
  const task = tasks[taskId];

  const orgs = useLegacySelector(state => state.orgs);
  const orgId = task.customer.org?.id || '';
  const org = orgs[orgId];

  if (!org) {
    return <></>;
  }

  return (
    <div>
      <a href={`/vendors/${org.id}`} target="_blank" rel="noopener noreferrer">
        <Text type="button">{org.name}</Text>
      </a>

      <hr className="my-1" />

      {org.phone && (
        <div className="flex items-center">
          <Icon name="phone" color="primaryCTA" size={14} />
          <Text className="ml-2 flex-1">
            {pf.format(org.phone, '(NNN) NNN-NNNN')}
            {org.phoneExt && ` x ${org.phoneExt}`}
          </Text>
        </div>
      )}

      <div className="flex items-center">
        <Icon name="email_outline" color="primaryCTA" size={14} />
        <Text className="ml-2 flex-1">{org.email}</Text>
      </div>

      <div>
        <br />
        <Button href={`/vendors/${org.id}`} target="_blank" size="small" variant="primary-outline">
          View Profile
        </Button>
      </div>
    </div>
  );
}
