import React, { useEffect,useState } from 'react';
import { NotCoveredService, Org, ServerEntity,U } from '@nanaio/util';
import { APIError, Button,Loader, Modal } from '@/components';
import { useLazyLegacyAPI,useLegacyAPI } from '@/hooks';
import NotCoveredServiceForm from './com/NotCoveredServiceForm';

type Props = {
  id?: string;
  onRemove?: () => void;
  onSuccess: (service: ServerEntity & NotCoveredService) => void;
  toggleOpen: () => void;
};

export default function NotCoveredServiceModal({
  id,
  onRemove,
  onSuccess,
  toggleOpen,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentService, setCurrentService] = useState<NotCoveredService | null>(null);
  const [saveError, setSaveError] = useState({ keyTaken: false, unexpected: false });
  const [showRemoveWarning, setShowRemoveWarning] = useState(false);

  const orgs = useLegacyAPI<Org[]>('orgs/search', {
    body: { limit: -1 },
    method: 'post',
  });

  const [loadServiceToEdit, serviceToEdit] = useLazyLegacyAPI<NotCoveredService>(
    `/notCoveredServices/${id as string}`,
    { method: 'get' }
  );

  const [removeService, serviceToRemove] = useLazyLegacyAPI<NotCoveredService>(
    `/notCoveredServices/${id as string}`,
    { method: 'delete' }
  );

  const disableSaveButton =
    !currentService?.name?.length || !currentService?.key?.length || isSaving;
  const hasError =
    orgs.error || serviceToEdit.error || serviceToRemove.error || saveError.unexpected;
  let showForm = !hasError && !orgs.loading && !serviceToEdit.loading && !isLoading;

  if (id) {
    showForm = Boolean(showForm && serviceToEdit.data);
  }

  const handleFormChange = (form: NotCoveredService) => setCurrentService(form);

  const handleSave = () => {
    setSaveError({ keyTaken: false, unexpected: false });
    setIsSaving(true);

    let promise;

    if (currentService?.id) {
      promise = U.api<NotCoveredService>(
        'put',
        `/notCoveredServices/${currentService.id}`,
        currentService
      );
    } else {
      promise = U.api<NotCoveredService>('post', '/notCoveredServices', currentService);
    }

    void promise
      .then(response => {
        if ('errMsg' in response) {
          if (response.errMsg?.includes('This key has already been saved')) {
            setSaveError({ ...saveError, keyTaken: true });
          } else {
            setSaveError({ ...saveError, unexpected: true });
          }
        } else {
          onSuccess(response as ServerEntity & NotCoveredService);
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleRemove = async () => {
    if (id) {
      if (showRemoveWarning) {
        await removeService();
      } else {
        setShowRemoveWarning(true);
      }
    }
  };

  useEffect(() => {
    if (id) {
      void loadServiceToEdit();
    }
  }, [id, loadServiceToEdit]);

  useEffect(() => {
    if (!orgs.loading && !serviceToEdit.loading) {
      setIsLoading(false);
    }
  }, [orgs.loading, serviceToEdit.loading]);

  useEffect(() => {
    if (serviceToRemove.data && onRemove) {
      onRemove();
    }
  }, [serviceToRemove.data, onRemove]);

  return (
    <Modal isOpen onClose={toggleOpen}>
      <Modal.Header title={`${id ? 'Edit' : 'Add'} Not Covered Service`} />

      <Modal.Body className="p-4">
        <Loader className="text-center" isLoading={isLoading} />

        {hasError && (
          <APIError error="'We were unable to complete this operation at this time. Please try again later.'" />
        )}

        {showForm && (
          <NotCoveredServiceForm
            keyAlreadySavedError={saveError.keyTaken}
            onChange={handleFormChange}
            orgs={orgs.data || []}
            service={serviceToEdit.data}
          />
        )}
      </Modal.Body>

      {showForm && (
        <Modal.Footer customUI>
          {id && (
            <Button
              disabled={serviceToRemove.loading}
              onClick={handleRemove}
              size="small"
              variant="secondary"
            >
              {showRemoveWarning ? 'Are you sure?' : 'Remove'}
            </Button>
          )}

          <Button
            className="ml-auto"
            disabled={disableSaveButton}
            onClick={handleSave}
            size="small"
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
