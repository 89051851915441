import React from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TableComponent from '@/components/Table';
import { Modal, Select, Text } from '../com/ui/form';

// Edit message popup for un-sent messages
class EditMessageModal extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.editableProps = [
      'jobId',
      'email.body',
      'email.subject',
      'email.to.0',
      'sms.body',
      'sms.to.0',
      'push.body',
      'push.title',
      'push.to',
    ];
  }

  async UNSAFE_componentWillReceiveProps(p) {
    const { isOpen } = this.props;
    if (!isOpen && p.isOpen) {
      const message = await U.api('get', `messages/${p.id}`);
      this.setState({ message, originalMessage: message });
    }
  }

  getChildContext = () => ({ t: this });

  submit = async () => {
    const { onSuccess, toggleOpen } = this.props;
    const { message, originalMessage } = this.state;
    if (!message.status) {
      return this.setState({ error: 'Status required' });
    }
    const changes = _.filter(
      ['status', ...this.editableProps],
      prop => !_.isEqual(_.get(message, prop), _.get(originalMessage, prop))
    ).map(path => ({ action: 'replace', path, value: _.get(message, path) }));
    if (changes.length) {
      const response = await U.api('put', `messages/${message.id}`, changes);
      if (response.errMsg) {
        return this.setState({ error: response.errMsg });
      }
      onSuccess(response);
    } else {
      toggleOpen();
    }
    return undefined;
  };

  render() {
    const { isOpen, toggleOpen } = this.props;
    const { error, message = {}, originalMessage } = this.state;
    const editable = _.get(originalMessage, 'status') === 'pending';
    return (
      <Modal
        title={`${editable ? 'Edit' : 'View'} Message`}
        isOpen={isOpen}
        submit={editable && this.submit}
        onClose={toggleOpen}
        className="ui-modal md ui-select-search"
        hideSave={!editable}
      >
        <p className="text-danger">{error}</p>
        {message.jobId && <a href={`/tasks/${message.jobId}`}>Open Job</a>}
        {editable ? (
          <div className="row">
            <Select
              id="message.status"
              options={_.uniq([message.status, 'notSent'])}
              cap
              className="col-sm-6"
            />
            {this.editableProps.map(prop => (
              <Text
                key={prop}
                id={`message.${prop}`}
                label={_.startCase(prop)}
                className="col-sm-6"
              />
            ))}
          </div>
        ) : (
          <div className="row">
            {['status', ...this.editableProps].map(prop => (
              <div key={prop} className="col-sm-6">
                <span style={{ fontWeight: 600 }}>{_.startCase(prop)}: </span>
                {JSON.stringify(_.get(message, prop, '')).slice(0, 200)}
              </div>
            ))}
          </div>
        )}
      </Modal>
    );
  }
}

EditMessageModal.childContextTypes = { t: PropTypes.shape({}) };

EditMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default function MessagesTable() {
  return <TableComponent {...Table.databases.default.message} editUi={EditMessageModal} />; // eslint-disable-line react/jsx-props-no-spreading
}
