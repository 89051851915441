import React, { useMemo } from 'react';
import { Task, W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import { Paper, Text } from '@/components';
import LastVisit from './LastVisit';
import { getVisits } from './util';
import Visit from './Visit';

type Props = {
  task: Task;
  workOrder: WorkOrder;
};

export default function Appointments({ task, workOrder }: Props): JSX.Element {
  const { requestedVisits, comingUpVisits, pastVisits } = useMemo(
    () => getVisits(workOrder),
    [workOrder]
  );

  return (
    <div className="mt-9">
      <Text type="subtitle-2">Your Appointments</Text>

      {_.isEmpty(workOrder.visits) ? (
        <Paper className="mt-4 p-4 text-center" variant="elevated">
          <img alt="date" src="/img/date.svg" className="mt-4 h-[50px]" />
          <Text className="mt-8" type="page-title">
            No Visits Scheduled
          </Text>
          <Text className="mb-4 text-grey-dark">
            To confirm your appointment, you'll need to submit the date and times you're available
            for service.
          </Text>
        </Paper>
      ) : (
        <>
          {!_.isEmpty(requestedVisits) && (
            <>
              <Text className="mt-6 text-grey-dark" type="page-title">
                Requests ({requestedVisits.length})
              </Text>
              {requestedVisits.reverse().map((visit, requestIndex) => (
                <LastVisit key={requestIndex} task={task} visit={visit} workOrder={workOrder} />
              ))}
            </>
          )}
          {!_.isEmpty(comingUpVisits) && (
            <>
              <Text className="mt-6 text-grey-dark" type="page-title">
                Coming Up ({comingUpVisits.length})
              </Text>
              {comingUpVisits.reverse().map((visit, confirmedIndex) => {
                return W.getPrimaryPro(visit)?.status === W.VisitProStatus.CONFIRMED ? (
                  <LastVisit key={confirmedIndex} task={task} visit={visit} workOrder={workOrder} />
                ) : (
                  <Visit key={confirmedIndex} task={task} visit={visit} workOrder={workOrder} />
                );
              })}
            </>
          )}
          {!_.isEmpty(pastVisits) && (
            <>
              <Text className="mt-6 text-grey-dark" type="page-title">
                Past ({pastVisits.length})
              </Text>
              {pastVisits.reverse().map((visit, confirmedIndex) => (
                <Visit key={confirmedIndex} task={task} visit={visit} workOrder={workOrder} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}
