import React from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import type { IconProps } from '@/components';
import Cell from './Cell';

type Props = {
  cell: Table.Cell;
  columnIndex: number;
  columnTableVectors: Table.TableVector[];
  isActive: boolean;
  rowHeaderDepth: number;
  rowIndex: number;
  rowTableVectors: Table.TableVector[];
  showPoints: boolean;
  tableVectorIdToIsCollapsed: Record<string, boolean>;
  vectors: Table.Vector[];
};

export default function Header({
  cell,
  columnIndex,
  columnTableVectors,
  isActive,
  rowHeaderDepth,
  rowIndex,
  rowTableVectors,
  showPoints,
  tableVectorIdToIsCollapsed,
  vectors,
}: Props): JSX.Element {
  const icons: IconProps['name'][] = [];
  const tableVector =
    columnIndex < rowHeaderDepth ? rowTableVectors[rowIndex] : columnTableVectors[columnIndex];
  const isCollapsed = Boolean(tableVectorIdToIsCollapsed[tableVector?.id]);
  const shouldShowSortIcons = columnTableVectors.length || rowTableVectors.length;
  const sortedVectors = vectors.filter(vector => Table.getIsVectorMatch({ tableVector, vector }));
  const directionToVectors = _.groupBy(sortedVectors, 'direction');

  if (shouldShowSortIcons) {
    if (directionToVectors[Table.Direction.ROW]) {
      if (directionToVectors[Table.Direction.ROW].some(vector => !vector.sort?.isDescending)) {
        icons.push('menu_up');
      }
      if (directionToVectors[Table.Direction.ROW].some(vector => vector.sort?.isDescending)) {
        icons.push('menu_down');
      }
    }
    if (directionToVectors[Table.Direction.COLUMN]) {
      if (directionToVectors[Table.Direction.COLUMN].some(vector => !vector.sort?.isDescending)) {
        icons.push('menu_left');
      }
      if (directionToVectors[Table.Direction.COLUMN].some(vector => vector.sort?.isDescending)) {
        icons.push('menu_right');
      }
    }
  }

  return <Cell {...{ cell, icons, isActive, isCollapsed, isHeader: true, rowIndex, showPoints }} />;
}
