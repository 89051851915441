import '../../task/layout/admin.css';

import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Icon } from '@/components';
import { loadTasks } from '../../com/task';
import Link from '../../com/ui/link';
import Profile from './profile';

class Jobs extends React.Component {
  componentWillMount() {
    loadTasks({ query: { 'customer.user.id': global.id() } });
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(p.orgIds, this.props.orgIds)) {
      const query = { _id: { $in: p.orgIds } };
      U.api('post', 'orgs/search', { query }, true);
    }
  }

  hasItemsInArray(array) {
    return Array.isArray(array) && array.length;
  }

  getVisitsPerJob = job => {
    const { visits } = job;
    const sortedVisits = _.orderBy(visits, ['preferredTimeSlot.startTime'], ['desc']);
    const keys = Object.keys(sortedVisits);
    if (this.hasItemsInArray(keys)) {
      return keys.map((key, n) => {
        const visit = sortedVisits[key];
        const service = _.get(job, 'serviceCatalogs[0].name', 'not found');
        const status = _.startCase(_.get(visit, 'status', 'not found'));
        const jobId = _.get(job, 'id');
        const cxBalance = U.toMoney(_.get(job, 'customerInvoice.balance'));
        const cxDue = _.get(job, 'customerInvoice.balance') > 0;
        const cxTotal = U.toMoney(_.get(job, 'customerInvoice.total'));
        const orgBalance = U.toMoney(_.get(job, 'orgInvoice.balance'));
        const orgDue = _.get(job, 'orgInvoice.balance') > 0;
        const orgTotal = U.toMoney(_.get(job, 'orgInvoice.total'));
        const startTime = _.get(visit, 'preferredTimeSlot.startTime');
        const fromNow = m(_.get(visit, 'preferredTimeSlot.startTime')).fromNow();
        const assignee = _.get(visit, 'assignee.user.fullName', 'unassigned');
        const tags = job.recall?.pastId ? 'Recall' : n ? 'Followup' : '1st visit';
        const org = _.get(job, 'customer.org.name');

        return (
          <div key={n} className="visit">
            <div className="cx-header">Visit {keys.length - n}</div>
            <Link key={n} to={`/tasks/${jobId}`} className="cx-list---item">
              <div className="cx-list--item_left">
                <label>{m(startTime).format('ddd').toUpperCase()}</label>
                <p className="text-blue">{m(startTime).format('DD')}</p>
                <label>{m(startTime).format('MMM').toUpperCase()}</label>
              </div>

              <div className="cx-list--item_center">
                <label>
                  {m(startTime).format('hA')} - {m(startTime).add('4', 'hour').format('hA')}{' '}
                  <span className="text-muted">({fromNow})</span>
                </label>
                <p className="text-blue">
                  {service} with {assignee}
                </p>
                <ul className="no-list inline-list">
                  <li className="pr-2">
                    <label>
                      <span className="text-muted">Status:</span> {status}
                    </label>
                  </li>
                  <li className="pr-2">
                    <label>
                      <span className="text-muted">&bull; Tag:</span> {tags}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="cx-list--item_right">
                <div className="d-flex align-items-center justify-content-end py-2">
                  <div className="mr-1">
                    <Icon name="account_circle" size={16} color="#D0D3DA" />
                  </div>
                  <p className="text-dark">
                    <span className="text-muted">Client:</span>{' '}
                    <span className={cxDue ? 'text-red' : 'text-green'}>{cxBalance} due</span> /{' '}
                    {cxTotal} total
                  </p>
                </div>
                {org && (
                  <div className="d-flex align-items-center justify-content-end py-2">
                    <div className="mr-1">
                      <Icon name="business" size={16} color="#D0D3DA" />
                    </div>
                    <p className="text-dark">
                      <span className="text-muted">{org}:</span>{' '}
                      <span className={orgDue ? 'text-red' : 'text-green'}>{orgBalance} due</span> /{' '}
                      {orgTotal} total
                    </p>
                  </div>
                )}
              </div>
            </Link>
          </div>
        );
      });
    }

    return (
      <div className="px-4 py-4 text-center">
        <h1>💼</h1>
        <div className="pb-2">
          <h5>No visits yet</h5>
        </div>
      </div>
    );
  };

  getJobs = jobs => {
    if (this.hasItemsInArray(jobs)) {
      return jobs.map((job, i) => {
        return (
          <div key={i}>
            <div className="cx-header job-title">
              Job
              <span className="text-muted job-create-date">
                created {m(job.createTime).format('MM/DD/YYYY')}
              </span>
            </div>
            <div className="visits">{this.getVisitsPerJob(job)}</div>
          </div>
        );
      });
    }

    return (
      <div className="px-4 py-4 text-center">
        <h1>💼</h1>
        <div className="pb-2">
          <h5>No jobs booked yet</h5>
        </div>
        <p className="text-muted" style={{ fontWeight: 500 }}>
          Get started by tapping the plus button on the top right hand corner.
        </p>
      </div>
    );
  };

  render() {
    const { jobs, cxTotal, cxBalance, orgTotal, orgBalance } = this.props;

    const summaryRow = [
      { label: 'Total Jobs', icon: 'calendar_range', value: jobs.length, color: 'text-dark' },
      {
        label: 'Lifetime Total',
        icon: 'account_balance',
        value: U.toMoney(cxTotal + orgTotal),
        color: 'text-dark',
      },
      {
        label: 'Total Due',
        icon: 'timeline',
        value: U.toMoney(cxBalance + orgBalance),
        color: cxBalance + orgBalance > 0 ? 'text-red' : 'text-green',
      },
    ];

    return (
      <section id="Admin">
        <section id="cx-profile">
          <div className="cx-profile--container mb-2">
            <div className="cx-header justify-content-start flex-row">
              {summaryRow.map((t, n) => (
                <div key={n} className="d-flex align-items-center mr-5">
                  <div className="rounded-icon mr-2">
                    <Icon name={t.icon} size={18} color="#C3CCD7" />
                  </div>
                  <div>
                    <label className="weight-700 text-muted flex-1">{t.label}</label>
                    <h5 className={t.color}>{t.value}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="cx-profile--container">
            <div className="cx-header flex-row">
              <p className="weight-700 text-dark flex-1">All Jobs ({jobs.length})</p>
              <Link to={`/tasks/add?email=${this.props.user.email}`}>
                <Icon name="add" size={25} color="#D0D3DA" />
              </Link>
            </div>
            <div className="cx-list">{this.getJobs(jobs)}</div>
          </div>
        </section>
        <br />
        <Profile />
      </section>
    );
  }
}

export default connect(s => {
  const customerId = global.id();
  const user = s.users[global.id()];
  const jobs = _.values(s.tasks).filter(t => t.customer.user.id === customerId);
  const visits = [];
  jobs.map(job => {
    const { customerInvoice, orgInvoice } = job;
    visits.push({ customerInvoice, orgInvoice });
  });
  const orgIds = _.uniq(_.compact(jobs.map(j => _.get(j, 'customer.org.id'))));
  const summary = {
    cxTotal: Object.values(visits).reduce(
      (r, { customerInvoice }) => r + _.get(customerInvoice, 'total', 0),
      0
    ),
    cxBalance: Object.values(visits).reduce(
      (r, { customerInvoice }) => r + _.get(customerInvoice, 'balance', 0),
      0
    ),
    orgTotal: Object.values(visits).reduce(
      (r, { orgInvoice }) => r + _.get(orgInvoice, 'total', 0),
      0
    ),
    orgBalance: Object.values(visits).reduce(
      (r, { orgInvoice }) => r + _.get(orgInvoice, 'balance', 0),
      0
    ),
  };

  return { user, jobs, orgIds, ...summary };
})(Jobs);
