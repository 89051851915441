import React, { useMemo, useState } from 'react';
import { MentionItem } from 'react-mentions';
import * as Table from '@nanaio/table';
import U, { Message, Option } from '@nanaio/util';
import _ from 'lodash';
import { Modal, Text } from '../../core';
import { Input } from '../../form';
import * as typeToModuleUi from '../moduleTypes';
import Email from './Email';
import Errors from './Errors';
import Preview from './Preview';
import Sms from './Sms';
import { Event, Row } from './types';

type Request = {
  email?: { body: string; subject: string; to: string };
  sendNow: true;
  sms?: { body: string; to: string };
  type: 'bulkMessage';
};

type Props = {
  checkedRows: Row[];
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  databaseIdToTableIdToRows: Table.Depth2<Table.Row[]>;
  databaseIdToTableIdToTable: Table.Depth2<Table.Table>;
  onClose: () => void;
  query: Table.Query;
  roles: Record<string, string>;
  rows: Row[];
  table: Table.Table;
};

export default function MessageUI({
  checkedRows,
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  databaseIdToTableIdToRows,
  databaseIdToTableIdToTable,
  onClose,
  query,
  roles,
  rows,
  table,
}: Props): JSX.Element {
  const [copyFromEmailBody, setCopyFromEmailBody] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const emailToOptions = U.memoize(
    //@ts-expect-error TODO Fix types in Util
    useMemo,
    _.map(
      _.values(databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]).filter(
        module => module.isEmail
      ),
      'id'
    )
  );
  const [errors, setErrors] = useState<(string | undefined)[]>([]);
  const [emailError, setEmailError] = useState('');
  const [emailTo, setEmailTo] = useState('');
  const [messageRows, setMessageRows] = useState<Row[]>(
    (checkedRows.length ? checkedRows : rows).filter(
      row => !row.status || ![U.pro.Status.SUSPENDED, U.user.Status.SUSPENDED].includes(row.status)
    )
  );
  const [moduleIds, setModuleIds] = useState<string[]>([]);
  const options = U.memoize(
    //@ts-expect-error TODO Fix types in Util
    useMemo,
    _.map(databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table], module => ({
      id: module.id,
      display: module.name,
    }))
  );
  const [progress, setProgress] = useState<{ bot: number; top: number }>();
  const [sms, setSms] = useState('');
  const [smsError, setSmsError] = useState('');
  const [smsTo, setSmsTo] = useState('');
  const smsToOptions = U.memoize(
    //@ts-expect-error TODO Fix types in Util
    useMemo,
    _.map(
      _.values(databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]).filter(
        module => module.isPhone
      ),
      'id'
    )
  );
  const [type, setType] = useState<'both' | 'email' | 'sms'>();

  const disableRow = (index: number) => {
    setMessageRows(messageRows => {
      const newMessageRows = _.cloneDeep(messageRows);
      newMessageRows[index].disabled = !newMessageRows[index].disabled;
      return newMessageRows;
    });
  };

  const updateCells = async (moduleId?: string) => {
    const newModuleIds = _.compact(_.uniq([...moduleIds, moduleId]));
    const { query: queryCopy } = await Table.loadOrUpdateQuery({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToTable,
      query: { ...query, columns: newModuleIds.map(id => ({ key: id })) },
      roles,
      typeToModuleUi,
    });
    const newRows = Table.addCellsToRows({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToColumnKeyToOptionIdToOption,
      databaseIdToTableIdToRows,
      databaseIdToTableIdToTable,
      query: queryCopy,
    })[table.databaseId]?.[table.name];
    setModuleIds(newModuleIds);
    setMessageRows(newRows);
  };

  const handleMessageChange = (id: string, event: Event, newValue: MentionItem[]) => {
    if (id === 'emailBody') {
      setEmailBody(event.target.value);
      if (copyFromEmailBody) {
        setSms(event.target.value);
      }
    } else if (id === 'emailSubject') {
      setEmailSubject(event.target.value);
    } else if (id === 'emailTo') {
      setEmailTo(event.target.value);
    } else if (id === 'sms') {
      setSms(event.target.value);
    }
    void updateCells(_.last(newValue)?.id);
  };

  const handleEmailToChange = (moduleId: string) => {
    setEmailTo(moduleId);
    void updateCells(moduleId);
  };

  const handleSmsToChange = (moduleId: string) => {
    setSmsTo(moduleId);
    void updateCells(moduleId);
  };

  const templateToText = (template: string, row: Row) =>
    _.replace(
      template,
      /@\[[a-zA-Z0-9 ]+]\(([a-zA-Z0-9 ]+)\)/g,
      (v, moduleId) =>
        U.toArray(
          _.join(_.map(row.cells[moduleId as keyof typeof row.cells]?.values, 'text'), ', ')
        )[0]
    ).replace(/\\/g, '');

  const getRows = () => {
    let newRows: Row[] = [];
    if (emailTo && smsTo) {
      newRows = messageRows.filter(
        row => row.cells[emailTo]?.values.length || row.cells[smsTo]?.values.length
      );
    } else if (emailTo) {
      newRows = messageRows.filter(row => row.cells[emailTo]?.values.length);
    } else if (smsTo) {
      newRows = messageRows.filter(row => row.cells[smsTo]?.values.length);
    }
    return newRows.map(row => {
      const newRow = _.cloneDeep(row);
      if (emailSubject) {
        newRow.emailSubject = templateToText(emailSubject, row);
      }
      if (emailBody) {
        newRow.emailBody = templateToText(emailBody, row);
      }
      if (sms) {
        newRow.sms = templateToText(sms, row);
      }
      return newRow;
    });
  };

  const onSend = async () => {
    if (emailTo) {
      if (!emailSubject || !emailBody) {
        return setEmailError('Subject and body required');
      }
    }
    if (smsTo && !sms) {
      setSmsError('Body required');
    }
    const rows = getRows().filter(row => !row.disabled);
    const requests: Request[] = [];
    if (type && ['both', 'email'].includes(type) && emailTo) {
      rows
        .map(row => {
          const email = String(row.cells[emailTo]?.values[0]?.text);
          return { email, row: row };
        })
        .filter(row => _.includes(row.email, '@'))
        .forEach(row => {
          requests.push({
            email: {
              to: row.email,
              body: _.replace(row.row.emailBody as string, /\n/g, '<br />'),
              subject: row.row.emailSubject as string,
            },
            sendNow: true,
            type: 'bulkMessage',
          });
        });
    }
    if (type && ['both', 'sms'].includes(type) && smsTo) {
      rows
        .map(row => {
          const phone = U.trimPhone(String(row.cells[smsTo]?.values[0]?.text));
          return { phone, row: row };
        })
        .filter(row => row.phone.length === 10)
        .forEach(row => {
          requests.push({
            sms: { to: row.phone, body: row.row.sms as string },
            sendNow: true,
            type: 'bulkMessage',
          });
        });
    }
    setProgress({ top: 0, bot: requests.length });
    const chunks = _.chunk(requests, 6);
    const errors: (string | undefined)[] = [];
    for (const [i, chunk] of chunks.entries()) {
      const responses = await Promise.all(
        // eslint-disable-next-line no-loop-func
        chunk.map(async request => {
          const response = await U.api<Message>('post', 'messages', request);
          if (!('errMsg' in response)) {
            const smsResult = response.sms?.ids?.[0];
            if (typeof smsResult === 'string') {
              try {
                const json = JSON.parse(smsResult) as { moreInfo: string };
                return json.moreInfo;
              } catch (e) {
                return undefined;
              }
            }
          }
          return undefined;
        })
      );
      errors.push(...responses);
      setProgress({ bot: requests.length, top: _.min([(i + 1) * 6, requests.length]) as number });
    }
    setEmailError('');
    setSmsError('');
    setErrors(errors);
    return undefined;
  };

  const showEmail = type && ['both', 'email'].includes(type);
  const showSms = type && ['both', 'sms'].includes(type);
  const enabledRows = messageRows.filter(r => !r.disabled);
  const title = `Message${progress ? ` Progress: ${progress.top} / ${progress.bot}` : ''}`;
  const emailCount = enabledRows.filter(row =>
    row.cells[emailTo]?.values.some(({ value }) => value)
  ).length;
  const smsCount = enabledRows.filter(row =>
    row.cells[smsTo]?.values.some(({ value }) => value)
  ).length;
  const nameKey = _.findKey(
    databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table],
    option => option.isName
  );

  return (
    <Modal isOpen onClose={onClose} width={1000}>
      <Modal.Header title={title} />
      <Modal.Body className="p-4">
        {!!errors.length && <Errors errors={errors} />}

        <div className="grid grid-cols-2 gap-x-4">
          <Input
            capitalize
            label="Type"
            onChange={type => setType(type as 'both' | 'email' | 'sms')}
            options={['both', 'email', 'sms']}
            value={type}
          />
          <div>
            <Text>Type &quot;@&quot; to insert template keys into messages.</Text>
            <Text>Type &quot;\&quot; to escape the @ symbol.</Text>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-4">
          {showEmail && (
            <Email
              {...{
                emailBody,
                emailError,
                emailSubject,
                emailTo,
                emailToOptions,
                handleEmailToChange,
                handleMessageChange,
                options,
              }}
            />
          )}
          {showSms && (
            <Sms
              {...{
                copyFromEmailBody,
                handleMessageChange,
                handleSmsToChange,
                options,
                setCopyFromEmailBody,
                showEmail,
                sms,
                smsError,
                smsTo,
                smsToOptions,
              }}
            />
          )}

          {(showEmail || showSms) && (
            <Preview
              {...{
                disableRow,
                emailBody,
                emailCount,
                emailSubject,
                emailTo,
                nameKey,
                rows: getRows(),
                sms,
                smsCount,
                smsTo,
              }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer onSave={onSend} />
    </Modal>
  );
}
