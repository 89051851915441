import React, { useEffect, useState } from 'react';
import { usePreviousDistinct } from '@nanaio/hooks';
import { U } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import mt from 'moment-timezone';
import nullthrows from 'nullthrows';
import { Icon, Text } from '@/components';
import { BookingState } from '../util';
import Slot from './Slot';

// md breakpoint from tailwind config
const MD = 768;
const PAGE_WIDTH = 528;

type Props = {
  onChange?: (key: string, value: unknown) => void;
  onToggleSlot: (date: string) => void;
  state: Pick<BookingState, 'availability' | 'availTSlots' | 'zip'>;
};

export default function ScheduleContainer({ onChange, onToggleSlot, state }: Props): JSX.Element {
  const [daysPerPage, setDaysPerPage] = useState(window.innerWidth < MD ? 15 : 4);
  const prevDaysPerPage = usePreviousDistinct(daysPerPage);

  const tz = U.timezone({ postalCode: state.zip.value });
  const timeslotsByDay = _.groupBy(_.toPairs(state.availability), ([timeslot]) =>
    mt(+timeslot).tz(tz).startOf('d').valueOf()
  );
  const days = _.keys(timeslotsByDay).map(Number);
  const firstSlotWithAvailability = nullthrows(_.findKey(state.availability, value => value));
  const firstDayWithAvailability = mt(+firstSlotWithAvailability).tz(tz).startOf('d').valueOf();
  const firstPageWithAvailability = Math.floor(
    days.indexOf(firstDayWithAvailability) / daysPerPage
  );

  const [currPage, setCurrPage] = useState(firstPageWithAvailability);

  useEffect(() => {
    const handleResize = () => {
      setDaysPerPage(window.innerWidth < MD ? 15 : 4);
    };
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // if switching from desktop to mobile reset the curr page
    if (prevDaysPerPage && prevDaysPerPage < daysPerPage) {
      setCurrPage(0);
    }
  }, [daysPerPage, prevDaysPerPage]);

  const totalPages = days.length / daysPerPage;

  const handleBack = () => {
    setCurrPage(currPage - 1);
  };

  const handleForward = () => {
    if (currPage < totalPages - 1) {
      setCurrPage(currPage + 1);
    } else if (onChange) {
      // onChange is only populated in the CX Portal where we allow booking up further out
      // if we're at the end of the current availability, load more
      setCurrPage(currPage + 1);
      onChange('loadMore', true);
    }
  };

  const pageX = currPage * -PAGE_WIDTH;
  const showBack = currPage > 0;
  const showForward = onChange ? true : currPage < totalPages - 1;

  return (
    <div className="mt-6 flex justify-between md:-mx-3">
      <div
        className={classNames('hidden items-center md:flex', {
          invisible: !showBack,
        })}
      >
        <Icon className="pointer" onClick={handleBack} name="chevron_left" size={36} />
      </div>
      <div className="overflow-x-auto md:overflow-hidden" style={{ width: PAGE_WIDTH }}>
        <div
          className="flex transition-transform duration-300"
          style={{ transform: `translateX(${pageX}px)` }}
        >
          {days.map(day => (
            <div key={day}>
              <div className="text-center">
                <Text className="text-grey-dark" type="button">
                  {mt(day).tz(tz).format('ddd')}
                </Text>
                <Text type="button">{mt(day).tz(tz).format('MMM D')}</Text>
              </div>
              <div>
                {timeslotsByDay[day].map(([timeslot, value]) => {
                  const isActive = !!_.get(state, `availTSlots.${timeslot}`);
                  const isDisabled = !value;
                  const slotStart = mt(+timeslot).tz(tz);
                  return (
                    <Slot
                      key={timeslot}
                      onClick={() => onToggleSlot(timeslot)}
                      isActive={isActive}
                      isDisabled={isDisabled}
                      slotStart={slotStart}
                    />
                  );
                })}
              </div>
            </div>
          ))}
          <div
            className={classNames('flex items-center md:hidden', {
              invisible: !showForward,
            })}
          >
            <Icon className="pointer" onClick={handleForward} name="chevron_right" size={36} />
          </div>
        </div>
      </div>
      <div
        className={classNames('hidden items-center md:flex', {
          invisible: !showForward,
        })}
      >
        <Icon className="pointer" onClick={handleForward} name="chevron_right" size={36} />
      </div>
    </div>
  );
}
