import React from 'react';
import U from '@nanaio/util';
import classNames from 'classnames';
import { Button, Icon, IconProps, Modal, Text } from '@/components';
import { supportUrl } from '@/config/const';

const PHONE = '8662136262';

type ItemProps = {
  className?: string;
  icon: IconProps['name'];
  href: string;
  title: string;
  description: string;
};

function Item({ className, icon, href, title, description }: ItemProps): JSX.Element {
  return (
    <Button
      variant="link"
      href={href}
      className={classNames('flex w-full rounded-md p-4 hover:bg-background-light', className)}
    >
      <Icon name={icon} />
      <div className="mx-4 flex-1 text-left">
        <Text type="button">{title}</Text>
        <Text className="text-grey-dark">{description}</Text>
      </div>
      <div className="self-center">
        <Icon name="chevron_right" />
      </div>
    </Button>
  );
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function HelpModal({ isOpen, onClose }: Props): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Help" />
      <Modal.Body className="p-4 pb-20 pt-6">
        <Text className="text-center" type="headline-6">
          Are you stuck or in need of assistance with booking a repair?
        </Text>
        <Text className="mt-4 text-center">Choose an option below</Text>

        <Item
          className="mt-6"
          href={`tel:${PHONE}`}
          icon="phone_outline"
          title={`Call Us at ${U.trimPhone(PHONE, true)}`}
          description="Our customer support team is here to assist you from 8AM to 5PM (PST) everyday"
        />

        <hr className="mx-2" />

        <Item
          href={supportUrl}
          icon="help_outline"
          title="Visit our Help Center"
          description="Find answers to commonly asked questions"
        />
      </Modal.Body>
    </Modal>
  );
}
