import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Date, Modal, Search, Select, Text } from '../../com/ui/form';
import { loadUsers } from '../../com/user';
import { getParams } from '../../com/util';

class AddTicket extends React.Component {
  static propTypes = {
    isOpen: T.bool,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    const params = getParams();
    if (params.refType && params.refId) {
      this.state = { modal: true, ...params };
    } else {
      this.state = {};
    }
    this.loadUsers = this.loadUsers.bind(this);
    this.submit = this.submit.bind(this);
  }

  loadUsers() {
    if (!global.supportUsersLoaded) {
      const query = { roles: U.user.Role.CUSTOMERSUPPORT };
      loadUsers({ query });
      global.supportUsersLoaded = true;
    }
  }

  async submit() {
    if (!this.state.title) {
      return this.setState({ error: 'Title required' });
    }
    const body = _.pick(this.state, [
      'title',
      'desc',
      'status',
      'priority',
      'dueDate',
      'tags',
      'refType',
      'refId',
    ]);
    if (this.state.owner) {
      const user = this.props.users.find(u => u.id === this.state.owner);
      if (user) {
        body.owner = user;
      }
    }
    body.creator = this.props.creator;
    const r = U.api('post', 'issues', body, true);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.setState({ error: '' });
    this.props.toggleOpen();
    if (body.refType === 'task' && body.refId) {
      const job = await U.api('get', `/tasks/${body.refId}`);
      if (job) {
        global.store.dispatch(push('/tickets'));
      }
    }
  }

  render() {
    return (
      <Modal
        className="ui-modal md ui-select-search"
        title="Add Tickets"
        submit={this.submit}
        isOpen={this.props.isOpen}
        onClose={this.props.toggleOpen}
      >
        <p className="text-danger">{this.state.error}</p>
        <Text id="title" req />
        <Text id="desc" label="Description" />
        <div className="row">
          <Search
            id="owner"
            label="Owner"
            onInputChange={this.loadUsers}
            options={this.props.users}
            className="col-sm-6"
          />
          <Select id="status" options={U.issue.statusOptions} cap className="col-sm-6" />
          <Select id="priority" className="col-sm-6" options={_.times(5, n => n + 1)} />
          <Search
            id="tags"
            label="Tags"
            multi
            className="col-sm-6"
            options={U.issue.tagOptions}
            cap
          />
          <Select id="refType" className="col-sm-6" options={U.issue.refTypeOptions} cap />
          <Text id="refId" className="col-sm-6" />
        </div>
        <Date id="dueDate" />
      </Modal>
    );
  }
}

export default connect(s => {
  const creator = {
    id: s.me.userId,
    name: _.get(s.users[s.me.userId], 'profile.fullName'),
  };
  let users = _.values(s.users)
    .filter(u => _.includes(u.roles, U.user.Role.CUSTOMERSUPPORT))
    .map(u => ({ id: u.id, name: _.get(u, 'profile.fullName', 'No Name') }));
  users = _.sortBy(users, 'name');
  return {
    creator,
    users,
  };
})(AddTicket);
