import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import T from 'prop-types';
import { pageView } from '../marketing';

// eslint-disable-next-line no-underscore-dangle
const _Link = p => {
  let onClick = () => {
    if (p.name) {
      pageView(null, { click: _.kebabCase(p.name) });
    }
    if (p.onClick) {
      p.onClick();
    }
  };
  if (p.to && p.to.startsWith('tel:')) {
    const props = { href: p.to, ..._.omit(p, ['dispatch', 'to', 'children']), onClick };
    return <a {...props}>{p.children}</a>;
  }
  if (!p.to) {
    const props = { ..._.omit(p, ['dispatch', 'to']), onClick };
    return React.createElement(p.type || 'div', props);
  }
  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (p.name) {
      pageView(null, { click: _.kebabCase(p.name) });
    }
    if (p.to && p.to.startsWith('http')) {
      global.location = p.to;
      return;
    }
    p.dispatch(push(p.to));
    if (p.onClick) {
      p.onClick();
    }
    window.scrollTo(0, 0);
  };
  const props = { href: p.to, ..._.omit(p, ['dispatch', 'onClick', 'to', 'tag']), onClick };
  _.set(props, 'style.cursor', 'pointer');
  return React.createElement(p.type || 'a', props);
};

_Link.propTypes = {
  name: T.string,
  onClick: T.func,
  to: T.string,
  children: T.node,
  className: T.string,
  style: T.object,
};

export const Link = connect()(_Link);

export default Link;
