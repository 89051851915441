import React, { useEffect, useRef, useState } from 'react';
import * as Table from '@nanaio/table';
import { AnalyticsRequest } from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import { Form, Input, InputType, Modal, Table as TableComponent, Text } from '@/components';

function EditUi({
  id,
  keyToModule,
  onSuccess,
  table,
  toggleOpen,
}: Table.AddUiProps | Table.EditUiProps): JSX.Element {
  const timeModuleKeyOptionsRef = useRef<string[]>(
    _.map(
      table.columns.filter(module => keyToModule[module.key].type === Table.ColumnType.TIME),
      'key'
    )
  );
  const [analyticsRequest, setAnalyticsRequest] = useState<Partial<AnalyticsRequest>>({
    isRunDaily: false,
    isRunNow: true,
    intervalCount: 1,
    intervalLength: Table.Duration.MONTH,
    status: Table.AnalyticsRequestStatus.PENDING,
    table,
    timeModuleKey: timeModuleKeyOptionsRef.current.find(key => key === 'createTime'),
  });
  const [error, setError] = useState<string>();
  const intervalLengthOptions = Table.durationOptions.filter(option => !option.includes('slot'));

  useEffect(() => {
    if (id) {
      void U.api<Table.AnalyticsRequest>('get', `analyticsRequests/${id}`).then(
        analyticsRequest => {
          if (!('errMsg' in analyticsRequest)) {
            setAnalyticsRequest(analyticsRequest);
          }
        }
      );
    }
  }, [id]);

  const handleSave = async () => {
    const request = await U.api<Table.AnalyticsRequest>(
      'post',
      'analytics/requests',
      analyticsRequest
    );
    if ('errMsg' in request) {
      setError(request.errMsg);
    } else {
      onSuccess(request);
    }
  };

  return (
    <Form onChange={setAnalyticsRequest} value={analyticsRequest}>
      <Modal isOpen>
        <Modal.Header title={id ? 'Update Request' : 'Create Request'} />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          <div className="grid grid-cols-2 gap-x-4">
            <Input id="isRunDaily" label="Run Daily" required type={InputType.BOOL} />
            <Input id="isRunNow" label="Run Now" required type={InputType.BOOL} />
            <Input id="intervalCount" required type={InputType.NUMBER} />
            <Input id="intervalLength" options={intervalLengthOptions} required />
            <Input
              id="status"
              options={[
                Table.AnalyticsRequestStatus.CANCELLED,
                Table.AnalyticsRequestStatus.PENDING,
              ]}
              required
            />
            <Input id="timeModuleKey" options={timeModuleKeyOptionsRef.current} required sort />
          </div>
        </Modal.Body>
        <Modal.Footer onClose={toggleOpen} onSave={handleSave} />
      </Modal>
    </Form>
  );
}

type Props = {
  keyToModule: Record<string, Table.Column>;
  table: Table.Query;
};

export default function AnalyticsRequestsTable({ keyToModule, table }: Props): JSX.Element {
  return (
    <div className="relative">
      <TableComponent
        {...Table.databases.default.analyticsRequest} // eslint-disable-line react/jsx-props-no-spreading
        addUi={props => <EditUi {...{ ...props, keyToModule, table }} />} // eslint-disable-line react/jsx-props-no-spreading
        editUi={props => <EditUi {...{ ...props, keyToModule, table }} />} // eslint-disable-line react/jsx-props-no-spreading
        embed
      />
    </div>
  );
}
