import './apply.css';

import React, { useCallback, useEffect, useState } from 'react';
import U from '@nanaio/util';
import { loggedOutEvent } from '@/com/analytics';
import { Form } from '@/components';
import Footer from '../../../com/ui/footer';
import ApplicationConfirmationStep from './application-confirmation-step';
import ApplicationForm from './application-form';
import ApplicationModal from './application-modal';
import Benefits from './benefits';
import Calculator from './calculator';
import FAQ from './faq';
import Hero from './hero';
import HowItWorks from './how-it-works';
import Mission from './mission';
import Social from './social';
import Testimonials from './testimonials';
import Vendors from './vendors';

const Step = {
  APPLICATION: 'application',
  CONFIRMATION: 'confirmation',
};

const validateRequired = (value, field) => {
  if (!value?.trim().length) {
    return `${field} required`;
  }

  return '';
};

const validateEmail = (value, field) => {
  const isEmptyErrorMessage = validateRequired(value, field);

  if (isEmptyErrorMessage) {
    return isEmptyErrorMessage;
  }

  if (!U.emailRegex.test(value.trim())) {
    return 'Invalid email';
  }

  return '';
};

const validatePhoneNumber = value => {
  if (U.trimPhone(value).toString().length !== 10) {
    return '10-digit phone required';
  }

  return '';
};

const validateZipCode = value => {
  if (value.replace(/\D/g, '').length !== 5) {
    return '5-digit zip code required';
  }

  return '';
};

const formHasErrors = errors => Object.values(errors).some(e => e.length);

const getFormErrorsObject = (values, isHeroStaticForm) => {
  const errors = {
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: validatePhoneNumber(values.phoneNumber),
    zipCodeError: validateZipCode(values.zipCode),
  };

  if (!isHeroStaticForm) {
    errors.firstNameError = validateRequired(values.firstName, 'First Name');
    errors.lastNameError = validateRequired(values.lastName, 'Last Name');
    errors.emailError = validateEmail(values.email, 'Email');
  }

  return errors;
};

const collectUTMParams = () => {
  return window.location.search
    .replace(/^\?/, '')
    .split('&')
    .reduce((obj, fragment) => {
      const [param, value] = fragment.split('=');

      if (param.startsWith('utm_')) {
        obj[param] = value;
      }

      return obj;
    }, {});
};

export default function ApplyPage() {
  const [currentStep, setCurrentStep] = useState(Step.APPLICATION);
  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    zipCode: '',
  });
  const [errors, setErrors] = useState({
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    phoneNumberError: '',
    zipCodeError: '',
  });
  const [untouched, setUntouched] = useState({
    email: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
    zipCode: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: undefined });
  const [fountainApplicationURL, setFountainApplicationURL] = useState('');

  const openFountainApplication = useCallback(
    url => {
      window.open(url || fountainApplicationURL, 'fountain-application');
    },
    [fountainApplicationURL]
  );

  const generateFountainApplicationURL = (id, stageId, openIt) => {
    const url = `https://www.fountain.com/portal/nana/applications/${id}/data_collection/new?stage_id=${stageId}`;

    setFountainApplicationURL(url);

    if (openIt) {
      openFountainApplication(url);
    }
  };

  const handleSubmit = async isHeroStaticForm => {
    if (isSubmitting) {
      return;
    }

    const errors = getFormErrorsObject(form, isHeroStaticForm);
    const hasErrors = formHasErrors(errors);
    const alert = { message: '', type: undefined };

    setErrors(errors);

    if (!hasErrors) {
      let response;

      setIsSubmitting(true);

      if (isHeroStaticForm) {
        response = await U.api('post', 'customerio/forms/apply', {
          phone: form.phoneNumber,
          zip: form.zipCode,
        });
      } else {
        response = await U.api('post', 'pros/application', {
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phoneNumber,
          zip: form.zipCode,
          utmParams: collectUTMParams(),
        });
      }

      if (response?.errMsg) {
        if (response.errMsg.includes('DUPLICATE')) {
          loggedOutEvent().track('v1_applicationFormDuplicateSubmitted', {
            form: JSON.stringify(form),
          });
          alert.message =
            'The phone number you entered is associated with an existing application. To confirm your identity and access your application, please enter the email address associated with your application.';
          alert.type = 'warning';
        } else {
          alert.message = response.errMsg;
          alert.type = 'error';
        }
      } else if (isHeroStaticForm) {
        setModalOpen(true);
      }

      setAlert(alert);
      setIsSubmitting(false);

      if (!isHeroStaticForm && !response.errMsg) {
        generateFountainApplicationURL(response.id, response.stage.id, true);
        setCurrentStep(Step.CONFIRMATION);
      }
    }
  };

  const getOnSubmitCallback = step => {
    if (step === Step.APPLICATION) {
      return () => {
        loggedOutEvent().track('v1_applicationFormSubmitted', { source: 'modalFooter' });
        handleSubmit();
      };
    }

    return openFountainApplication;
  };

  // This is for real-time input validation. The effect is triggered as soon as the component is mounted, and that will
  // produce an error message to be shown even before the user types in something. To avoid that, we flag the inputs as
  // "untouched" until the users enters at least one character.
  // We use one effect per field to avoid showing errors on untouched inputs.
  useEffect(() => {
    const isEmpty = !form.firstName?.trim().length;

    if (untouched.firstName) {
      if (!isEmpty) {
        setUntouched(values => ({ ...values, firstName: false }));
      }
    } else {
      setErrors(values => ({
        ...values,
        firstNameError: validateRequired(form.firstName, 'First Name'),
      }));
    }
  }, [form.firstName, untouched.firstName]);

  useEffect(() => {
    const isEmpty = !form.lastName?.trim().length;

    if (untouched.lastName) {
      if (!isEmpty) {
        setUntouched(values => ({ ...values, lastName: false }));
      }
    } else {
      setErrors(values => ({
        ...values,
        lastNameError: validateRequired(form.lastName, 'Last Name'),
      }));
    }
  }, [form.lastName, untouched.lastName]);

  useEffect(() => {
    const isEmpty = !form.email?.trim().length;

    if (untouched.email) {
      if (!isEmpty) {
        setUntouched(values => ({ ...values, email: false }));
      }
    } else {
      setErrors(values => ({ ...values, emailError: validateEmail(form.email, 'Email') }));
    }
  }, [form.email, untouched.email]);

  useEffect(() => {
    const isEmpty = !form.phoneNumber?.trim().length;

    if (untouched.phoneNumber) {
      if (!isEmpty) {
        setUntouched(values => ({ ...values, phoneNumber: false }));
      }
    } else {
      setErrors(values => ({ ...values, phoneNumberError: validatePhoneNumber(form.phoneNumber) }));
    }
  }, [form.phoneNumber, untouched.phoneNumber]);

  useEffect(() => {
    const isEmpty = !form.zipCode?.trim().length;

    if (untouched.zipCode) {
      if (!isEmpty) {
        setUntouched(values => ({ ...values, zipCode: false }));
      }
    } else {
      setErrors(values => ({ ...values, zipCodeError: validateZipCode(form.zipCode) }));
    }
  }, [form.zipCode, untouched.zipCode]);

  return (
    <Form className="apply mt-16 pt-1" onChange={setForm} value={form}>
      <Hero
        alert={alert.message}
        alertType={alert.type}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        phoneNumberError={errors.phoneNumberError}
        showModal={() => setModalOpen(true)}
        zipCodeError={errors.zipCodeError}
      />
      <Vendors />
      <div className="mx-3 h-0.5 bg-icons-grey opacity-20" />
      <Benefits />
      <Calculator onGetStartedClick={() => setModalOpen(true)} />
      <Testimonials />
      <HowItWorks />
      <Mission />
      <Social />
      <div className="mx-3 h-0.5 bg-icons-grey opacity-20" />
      <FAQ />
      <Footer />

      <ApplicationModal
        buttonText={currentStep === Step.APPLICATION ? 'Continue' : 'Complete Application'}
        isOpen={modalOpen}
        isSubmitting={isSubmitting}
        onClick={getOnSubmitCallback(currentStep)}
        onClose={() => setModalOpen(false)}
      >
        {currentStep === Step.APPLICATION ? (
          <ApplicationForm
            alert={alert.message}
            alertType={alert.type}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            {...errors} // eslint-disable-line react/jsx-props-no-spreading
          />
        ) : (
          <ApplicationConfirmationStep name={form.firstName} link={fountainApplicationURL} />
        )}
      </ApplicationModal>
    </Form>
  );
}
