import React from 'react';
import { Input, InputType, Text } from '@/components';
import { companyName } from '@/config/const';

export default function TermsAndConditionsForm(): JSX.Element {
  return (
    <div>
      <div className="flex">
        <Text type="label">Terms of Service and Privacy</Text>
        <Text type="label" color="danger">
          *
        </Text>
      </div>
      <div className="mt-4 flex">
        <Input id="terms" label="" type={InputType.CHECK} required />
        <Text color="secondary" className="ml-4">
          I have read and agree to the {companyName}{' '}
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            <Text color="primaryCTA" tag="span">
              Terms of Service
            </Text>
          </a>{' '}
          and{' '}
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            <Text color="primaryCTA" tag="span">
              Privacy Policy
            </Text>
          </a>
        </Text>
      </div>
      <div className="mt-6 flex">
        <Text type="label">Release and Waiver of Liability</Text>
        <Text type="label" color="danger">
          *
        </Text>
      </div>
      <div className="mt-4 flex">
        <Input id="release" label="" type={InputType.CHECK} required />
        <Text color="secondary" className="ml-4">
          I have read and agree to the{' '}
          <a href="/customer-release" target="_blank" rel="noopener noreferrer">
            <Text color="primaryCTA" tag="span">
              Release Of All Claims Waiver
            </Text>
          </a>
        </Text>
      </div>
    </div>
  );
}
