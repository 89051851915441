import React, { memo, useEffect,useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loggedOutEvent } from '@/com/analytics';
import { Button,Dropdown, Text } from '@/components';

function Calculator({ onGetStartedClick }) {
  const [selectedUnits, setSelectedUnits] = useState(3);
  const [selectedDays, setSelectedDays] = useState(4);
  const [result, setResult] = useState(0);

  const unitsItems = useRef(
    Array.from({ length: 6 }, (_, i) => ({
      title: `${i + 1} appliance${i ? 's' : ''}`,
      onClick: () => setSelectedUnits(i),
    }))
  ).current;
  const daysItems = useRef(
    Array.from({ length: 7 }, (_, i) => ({
      title: `${i + 1} day${i ? 's' : ''}`,
      onClick: () => setSelectedDays(i),
    }))
  ).current;
  const textProps = useRef({
    tag: 'span',
    type: 'headline-3',
    color: 'background.medium',
    className: 'font-normal opacity-50',
  }).current;

  useEffect(() => {
    setResult(90 * (selectedUnits + 1) * (selectedDays + 1) * 4);
  }, [selectedUnits, selectedDays]);

  const handleGetStartedClick = () => {
    loggedOutEvent().track('v1_getStartedClicked', { source: 'calculator' });
    onGetStartedClick();
  };

  return (
    <div className="apply-calculator">
      <div className="apply-container lg:px-4">
        <div className="apply-calculator__container bg-cover bg-center px-4 pb-6 pt-24 text-center lg:rounded-2xl">
          <Text {...textProps}>Repair</Text>

          <div className="ml-4 mr-2 inline-block">
            <Dropdown
              items={unitsItems}
              leftAlign
              trigger={
                <Text
                  tag="span"
                  type="headline-3"
                  color="white"
                  className="font-semibold underline"
                >
                  {unitsItems[selectedUnits].title} a day
                </Text>
              }
              chevronClassName="apply-calculator__chevron"
            />
          </div>

          <Text {...textProps}>for</Text>

          <div className="ml-4 mr-2 inline-block">
            <Dropdown
              items={daysItems}
              leftAlign
              trigger={
                <Text
                  tag="span"
                  type="headline-3"
                  color="white"
                  className="font-semibold underline"
                >
                  {daysItems[selectedDays].title} a week
                </Text>
              }
              chevronClassName="apply-calculator__chevron"
            />
          </div>

          <br />

          <Text {...textProps}>and earn up to</Text>

          <Text tag="span" type="headline-3" color="white" className="ml-2 font-semibold">
            ${result.toLocaleString()}/month
          </Text>

          <div className="hidden lg:block">
            <Button className="mt-12 w-52" onClick={handleGetStartedClick}>
              Get Started
            </Button>
          </div>

          <Text
            type="helper"
            color="background.light"
            className="mx-auto mt-24 max-w-md opacity-60"
          >
            This is an estimate and is not a promise or guarantee of future earnings.
            <br />
            The estimate is based on an average of a $90 payout per repair.
          </Text>
        </div>
      </div>
    </div>
  );
}

Calculator.propTypes = {
  onGetStartedClick: PropTypes.func.isRequired,
};

export default memo(Calculator);
