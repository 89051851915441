import '../../style/config/support';

import PageUserProfile from '@/pages/user/profile';
import AdminActivities from '../../admin/Activities';
import AdminInventoryAdd from '../../admin/inventory/add';
import AdminInventoryEdit from '../../admin/inventory/edit';
import AdminInventoryOverride from '../../admin/inventory/override';
import AdminInventoryTable from '../../admin/inventory/Table';
import AdminLayout from '../../admin/Layout';
import AdminMessagesTable from '../../admin/messages';
import AdminPartVendorTable from '../../admin/partVendor';
import AdminPartVendorEdit from '../../admin/partVendor/Edit';
import AdminProEdit from '../../admin/pro/edit';
import AdminPro from '../../admin/pro/table';
import AdminReport from '../../admin/report';
import AdminIncentiveForm from '../../admin/report/incentives/form';
import AdminPromoEdit from '../../admin/report/promos/edit';
import AdminReportRegionsEdit from '../../admin/report/regions/Edit';
import AdminReportTableOfContents from '../../admin/report/TableOfContents';
import AdminReviewEdit from '../../admin/review/edit';
import AdminReviewTable from '../../admin/review/table';
import SamsungModelsTable from '../../admin/samsungModels';
import AdminSchedule from '../../admin/schedule';
import AdminTicketEdit from '../../admin/ticket/edit';
import AdminTicketTable from '../../admin/ticket/Table';
import AdminUserEdit from '../../admin/user/edit';
import AdminUserTable from '../../admin/user/Table';
import AdminVendorEdit from '../../admin/vendor/edit';
import AdminVendorTable from '../../admin/vendor/Table';
import AdminJobAdd from '../../admin/workOrder/Add';
import AdminWorkOrderAdd from '../../admin/workOrder/AddTask';
import AdminWorkOrders from '../../admin/workOrder/table';
import WorkOrderCollectionsLetter from '../../task/collectionsLetter';
import TaskLayout from '../../task/layout';
import TaskWorkOrderLayout from '../../task/layout/WorkOrderLayout';
import publicRoutes from './public';

const supportRoutes = {
  '': { body: AdminWorkOrders, layout: AdminLayout },
  activities: { body: AdminActivities, layout: AdminLayout },
  'incentives/add': { body: AdminIncentiveForm, layout: AdminLayout },
  'incentives/id': { body: AdminIncentiveForm, layout: AdminLayout },
  inventory: { body: AdminInventoryTable, layout: AdminLayout },
  'inventory/add': { body: AdminInventoryAdd, layout: AdminLayout },
  'inventory/id': { body: AdminInventoryEdit, layout: AdminLayout },
  'inventory/override': { body: AdminInventoryOverride, layout: AdminLayout },
  messages: { body: AdminMessagesTable, layout: AdminLayout },
  'samsung-models': { body: SamsungModelsTable, layout: AdminLayout },
  'part-vendors': { body: AdminPartVendorTable, layout: AdminLayout },
  'part-vendors/id': { body: AdminPartVendorEdit, layout: AdminLayout },
  profile: { body: PageUserProfile, layout: AdminLayout },
  pros: { body: AdminPro, layout: AdminLayout },
  'pros/pay': { body: AdminPro, layout: AdminLayout },
  'pros/id': { body: AdminProEdit, layout: AdminLayout },
  'promos/id': { body: AdminPromoEdit, layout: AdminLayout },
  'reports/regions/add': { body: AdminReportRegionsEdit, layout: AdminLayout },
  'reports/regions/id': { body: AdminReportRegionsEdit, layout: AdminLayout },
  'reports/table-of-contents': { body: AdminReportTableOfContents, layout: AdminLayout },
  reports: { body: AdminReport, layout: AdminLayout },
  reviews: { body: AdminReviewTable, layout: AdminLayout },
  'reviews/id': { body: AdminReviewEdit, layout: AdminLayout },
  schedule: { body: AdminSchedule, layout: AdminLayout },
  'tasks/add': { body: AdminJobAdd, layout: AdminLayout },
  'tasks/id': { body: TaskLayout, layout: AdminLayout },
  tickets: { body: AdminTicketTable, layout: AdminLayout },
  'tickets/id': { body: AdminTicketEdit, layout: AdminLayout },
  users: { body: AdminUserTable, layout: AdminLayout },
  'users/id': { body: AdminUserEdit, layout: AdminLayout },
  vendors: { body: AdminVendorTable, layout: AdminLayout },
  'vendors/id': { body: AdminVendorEdit, layout: AdminLayout },
  workorders: { body: AdminWorkOrders, layout: AdminLayout },
  'workorders/id/collections-letter': { body: WorkOrderCollectionsLetter },
  'workorders/id': { body: TaskWorkOrderLayout, layout: AdminLayout },
  'workorders/id/tasks/add': { body: AdminWorkOrderAdd, layout: AdminLayout },
};

const routes = Object.assign(publicRoutes, supportRoutes);

export default routes;
