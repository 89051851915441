import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { Money } from '../../com/ui/form';

class Preference extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.remove = this.remove.bind(this);
    this.showSave = this.showSave.bind(this);
    this.onSave = this.onSave.bind(this);
    this.state = { pro: p.pro };
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(this.props.pro, p.pro)) {
      this.setState({ pro: p.pro });
    }
  }

  remove(path) {
    this.setState(s => _.omit(s, `pro.${path}`));
  }

  showSave() {
    const current = _.get(this.props.pro, 'preference.opportunity.labor');
    const next = _.get(this.state.pro, 'preference.opportunity.labor');
    return !_.isEqual(current, next);
  }

  async onSave() {
    const changes = [
      {
        action: 'replace',
        path: 'preference.opportunity.labor',
        value: _.get(this.state.pro, 'preference.opportunity.labor'),
      },
    ];
    const r = await U.api('put', `serviceproviders/${this.props.pro.id}`, changes, true);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    }
  }

  render() {
    return (
      <div className="crm-profile">
        <div className="text-danger">{this.state.error}</div>
        <div className="cp-profile">
          <div className="d-flex justify-content-between py-3">
            <h2 className="flex-1">Preference</h2>
            {this.showSave() && (
              <div className="btn btn-primary" onClick={this.onSave}>
                Save
              </div>
            )}
          </div>
          <div className="text-danger">{this.state.error}</div>
          <div className="d-flex">
            <Money id="pro.preference.opportunity.labor.start" label="Opportunity Labor Start" />
            <Icon
              name="close_thick"
              className="pointer ml-3"
              onClick={() => this.remove('preference.opportunity.labor.start')}
              size={16}
            />
          </div>
          <div className="d-flex">
            <Money id="pro.preference.opportunity.labor.end" label="Opportunity Labor End" />
            <Icon
              name="close"
              className="pointer ml-3"
              onClick={() => this.remove('preference.opportunity.labor.end')}
              size={16}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => {
  const pro = s.pros[global.id()];
  return { pro };
})(Preference);
