import React, { useMemo } from 'react';
import Text from '@/components/core/Text';

type Props = {
  className?: string;
  count: number;
  min?: number;
  max?: number;
};

export default function HelperCount({ className, count, min = 0, max = 0 }: Props): JSX.Element {
  const color = useMemo(() => {
    if (min) {
      if (count < min / 2) {
        return 'danger';
      }
      if (count < min) {
        return 'accent.orange';
      }
      if (!max) {
        return 'success';
      }
    }
    if (max) {
      if (count > max) {
        return 'danger';
      }
      if (count > min + (max - min) / 2) {
        return 'accent.orange';
      }
      return 'success';
    }
    return 'grey.dark';
  }, [count, max, min]);

  const total = min && (count < min || !max) ? min : max || '';

  return (
    <Text className={className} color={color} type="helper">
      {count}
      {total ? ` / ${total}` : ''}
    </Text>
  );
}
