import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Pro, Region, User, WorkOrder } from '@nanaio/util';
import U from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import {
  APIErrorBoundary,
  Icon,
  IconProps,
  Input,
  Loader,
  SuspenseLoader,
  Text,
} from '@/components';
import { useLegacySelector } from '@/hooks';
import useBreakpoints from '@/hooks/useBreakpoints';
import { logout } from '../../com/user/func';
import { companyLogoWhite, companyName, isProdDb } from '../../config/const';
import { openLink } from '../../utils';
import configLinks from './config.json';
import Link from './Link';
import Regions from './Regions';
import ViewAs from './ViewAs';

type Props = {
  children: ReactNode;
};
type ConfigLink = {
  highlightPath?: string;
  icon?: IconProps['name'];
  id: string;
  navPath: string;
  showAlways?: boolean;
};

function AdminLayoutContent({ children }: Props): JSX.Element {
  const [currentBreakpoint] = useBreakpoints();
  const dispatch = useDispatch();
  const [id, setId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [regionIsOpen, setRegionIsOpen] = useState(false);
  const [searchError, setSearchError] = useState<string>();
  const [viewAsIsOpen, setViewAsIsOpen] = useState(false);

  const router = useLegacySelector(state => state.router);
  const path = router.location?.pathname || '';
  const regions = useLegacySelector(state => U.region.regionsFromReduxMe(state));

  const links = configLinks as ConfigLink[];

  const background = isProdDb ? 'bg-primary' : 'bg-accent-purple';

  const handleIdSearch = async (id: string | undefined, legacySidSearch = false): Promise<void> => {
    if (!id?.length) {
      openLink({ dispatch, url: '/tasks' });
      setSearchError(undefined);
    } else if (id.length === 6 || legacySidSearch) {
      setIsLoading(true);
      const workOrders = await U.api<WorkOrder[]>('post', 'workOrders/search', {
        query: { 'tasks.shortId': id.toUpperCase() },
      });
      const task =
        Array.isArray(workOrders) && workOrders.length
          ? workOrders[0].tasks.find(task => task.shortId === id.toUpperCase())
          : undefined;
      if (task) {
        setIsLoading(false);
        setSearchError(undefined);
        openLink({ dispatch, url: `/tasks/${task.id}` });
      } else {
        setIsLoading(false);
        setSearchError('Job not found');
      }
    } else if (id.length === 24) {
      const workOrders = await U.api<WorkOrder[]>('post', 'workOrders/search', {
        query: { $or: [{ _id: id }, { 'tasks._id': id }] },
      });
      if (Array.isArray(workOrders) && workOrders.length) {
        const [workOrder] = workOrders;
        const task =
          workOrder.id === id.toLowerCase()
            ? workOrder.tasks[0]
            : workOrder.tasks.find(task => task.id === id.toLowerCase());

        openLink({ dispatch, url: `/tasks/${nullthrows(task).id}` });
      }
      const pros = await U.api<Pro[]>('post', 'pros/search', { query: { _id: id } });
      if (Array.isArray(pros) && pros.length) {
        const [pro] = pros;
        openLink({ dispatch, url: `/pros/${pro.id}` });
      }
      const users = await U.api<User[]>('post', 'users/search', { query: { _id: id } });
      if (Array.isArray(users) && users.length) {
        const [user] = users;
        openLink({ dispatch, url: `/users/${user.id}` });
      }
      setSearchError(undefined);
    } else {
      setSearchError('Id should be 6 or 24 characters');
    }
  };

  const handleIdChange = (id: string | undefined) => {
    setId(id?.toUpperCase());
    void handleIdSearch(id);
  };

  const linkToOnClick = { logout, viewAs: () => setViewAsIsOpen(true) };

  const width = currentBreakpoint === '2XL' ? 150 : 100;

  const title = isProdDb ? (
    <img alt={companyName} src={companyLogoWhite} style={{ width }} />
  ) : (
    <Text color="white" type="headline-5">
      STAGE
    </Text>
  );

  return (
    <div>
      <div className="adminLayout">
        <div
          className={`md:hidden ${background} flex justify-between px-3`}
          onClick={() => setIsOpen(!isOpen)}
          style={{ height: 50 }}
        >
          {title}
          <Icon color="white" name="menu" size={24} />
        </div>
        <div className="flex">
          <div
            className={`h-screen overflow-y-auto ${background} ${isOpen ? '' : 'hidden md:block'}`}
            style={{ width }}
          >
            <div className="mx-2 my-4 hidden justify-between md:flex">
              {title}
              <Loader className="text-white" isLoading={isLoading} />
            </div>
            <Input
              onChange={value => handleIdChange(value)}
              onEnter={id => handleIdSearch(id as string, true)}
              placeholder="Id"
              value={id}
            />
            <Text color="white">{searchError}</Text>
            <div className="cursor-pointer" onClick={() => setRegionIsOpen(true)}>
              <div className="mx-2 my-3 flex justify-between">
                <Text color="white">Regions</Text>
                <Icon color="white" name="expand_more" />
              </div>
              <Text className="bg-white p-3">
                {regions
                  .filter((region: Region) => region.type === 'region')
                  .map((region: Region) => region.name)
                  .sort()
                  .join(', ')}
              </Text>
              <div className="mx-2 my-3 flex justify-between">
                <Text color="white">Markets</Text>
                <Icon color="white" name="expand_more" />
              </div>
              <Text className="bg-white p-3">
                {regions
                  .filter((region: Region) => region.type === 'market')
                  .map((region: Region) => region.name)
                  .sort()
                  .join(', ')}
              </Text>
            </div>
            <ul className="nana-sidebar--list">
              {links.map((link, i) => (
                <Link
                  dispatch={dispatch}
                  highlightPath={link.highlightPath}
                  icon={currentBreakpoint === '2XL' ? link.icon : undefined}
                  id={link.id || link.navPath}
                  key={link.id || i}
                  navPath={link.navPath}
                  // @ts-expect-error This intentionally returns a undefined sometimes which is inherently unsafe
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  onClick={linkToOnClick[link.id]}
                  path={path}
                />
              ))}
            </ul>
          </div>
          <div className="relative h-screen flex-1 overflow-hidden bg-background-light">
            <div className="max-h-screen overflow-y-auto">{children}</div>
          </div>
        </div>
      </div>
      {regionIsOpen && <Regions onClose={() => setRegionIsOpen(false)} />}
      {viewAsIsOpen && <ViewAs onClose={() => setViewAsIsOpen(false)} />}
    </div>
  );
}

export default function AdminLayout({ children }: Props): JSX.Element {
  return (
    <APIErrorBoundary>
      <SuspenseLoader>
        <AdminLayoutContent>{children}</AdminLayoutContent>
      </SuspenseLoader>
    </APIErrorBoundary>
  );
}
