import React from 'react';
import { Me, Part, T, U } from '@nanaio/util';
import _ from 'lodash';
import { Input } from '@/components';

type Props = {
  me: Me;
  onApprovalStatusChange?: (approvalStatus?: string) => void;
  part: Part;
};

export default function AuthorizationInfo({
  me,
  onApprovalStatusChange,
  part,
}: Props): JSX.Element {
  // Convert failure reason options to a mutable string array
  const failureReasons = [...T.partFailureReasonOptions];
  return (
    <div>
      <Input
        id="approvalStatus"
        capitalize
        onChange={onApprovalStatusChange}
        options={T.partApprovalStatusOptions.map(option => {
          const disabled =
            [T.PartApprovalStatus.APPROVED, T.PartApprovalStatus.NOT_APPROVED].includes(option) &&
            !me.roles.includes(U.user.Role.PART_APPROVAL);
          return { id: option, name: _.startCase(option), disabled };
        })}
        tooltip="If requested, it means that this part Needs MT approval. On submit the job will be flagged as “Needs MT Approval”"
      />
      {(!!part.approvalReason?.length ||
        part.approvalStatus === T.PartApprovalStatus.REQUESTED) && (
        <Input
          id="approvalReason"
          multiple
          options={T.partApprovalReasonOptions}
          required={!part._id}
        />
      )}
      <Input freeformOther injectOtherOption id="failureReason" options={failureReasons} />
      <Input
        id="lateDiagnosisReason"
        freeformOther
        injectOtherOption
        options={T.lateDiagnosisReasonOptions}
        tooltip="When a new part is added (1) to a recall job; or (2) During or after the second visit-- the technician needs to explain why this part wasn’t diagnosed in the first visit."
      />
      <Input
        id="purpose"
        label="What does this part do?"
        multiline
        placeholder="Example: This relay opens & closes the electrical pathway from the outlet to the rest of the dryer. It's stuck."
      />
      <Input id="approvalNotes" label="Approval Notes (tech can see this)" multiline />
    </div>
  );
}
