import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import { BrandLogo } from '.';
import RadioButtonTile from './RadioButtonTile';

export default {
  title: 'Core/Radio Button Tile',
  component: RadioButtonTile,
  args: {
    title: 'Title',
    description: 'Description',
    variant: 'row',
    isDisabled: false,
    isSelected: false,
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof RadioButtonTile>;

const Template: ComponentStory<typeof RadioButtonTile> = args => <RadioButtonTile {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Row = Template.bind({});
Row.args = { variant: 'row' };

export const RowDisabled = Template.bind({});
RowDisabled.args = { variant: 'row', isDisabled: true };

export const Tile = Template.bind({});
Tile.args = {
  title: 'Wolf',
  description: <BrandLogo brandName="Wolf" size="small" />,
  variant: 'tile',
};

export const TileDisabled = Template.bind({});
TileDisabled.args = {
  title: 'Samsung',
  description: <BrandLogo brandName="Samsung" size="small" />,
  variant: 'tile',
  isDisabled: true,
};
