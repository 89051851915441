import React, { memo, useRef } from 'react';
import { Text } from '@/components';
import { companyName } from '@/config/const';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function Benefits() {
  const cards = useRef([
    {
      icon: 'verified-user',
      title: 'Claim Jobs, Not Leads',
      text: 'Choose from thousands of verified jobs from 56+ leading manufactures, warranty, and property management companies.',
    },
    {
      icon: 'monetization-on',
      title: 'Paid Within 48 Hours',
      text: 'Receive a direct deposit to the account of your choice within 48 hours of completing each job.',
    },
    {
      icon: 'loyalty',
      title: 'Discounted Parts',
      text: "Free part delivery to your customer's homes and discounts at the nation's largest parts distributors.",
    },
    {
      icon: 'calendar-new',
      title: 'All-in-One App',
      text: 'Claim jobs, manage your schedule, and invoice customers from the palm of your hand.',
    },
    {
      icon: 'book',
      title: 'OEM & Service Resources',
      text: 'Exclusive access to appliance service manuals and OEM support lines.',
    },
    {
      icon: 'support-agent',
      title: 'Customer Success',
      text: 'Focus on doing what you love — We provide a customer service team for you and your customers.',
    },
    {
      icon: 'message',
      title: 'Master Tech Support',
      text: 'Access to master technicians with a combined 100+ years of experience to help with the trickiest repairs.',
    },
    {
      icon: 'web',
      title: `${companyName} Community`,
      text: 'Exclusive access to our community—which includes free training, an online community board, and yearly in-person events.',
    },
  ]).current;
  const [, breakpointSize] = useBreakpoints();

  return (
    <div className="apply-benefits apply-container px-6 py-24 lg:px-4">
      <Text
        type={breakpointSize >= Breakpoints.LG ? 'headline-2' : 'headline-3'}
        className="apply-benefits__title mx-auto text-center"
        tag="h3"
      >
        Earnings and Benefits You Can Count On
      </Text>

      <div className="flex flex-col flex-wrap items-stretch md:flex-row">
        {cards.map(card => (
          <div
            key={card.icon}
            className="apply-benefits__card mt-14 flex w-full flex-col rounded-xl p-4 md:w-1/2 md:flex-auto md:items-start xl:w-1/4"
          >
            <div className="relative">
              <img
                className="apply-benefits__card__background"
                src="/svg/apply-benefits-icon-background.svg"
                alt={card.icon}
              />
              <img
                className="apply-benefits__card__icon absolute"
                src={`/svg/${card.icon}.svg`}
                alt={card.icon}
              />
            </div>

            <Text type="headline-6" className="mt-6">
              {card.title}
            </Text>

            <Text color="grey.dark" className="mt-1 text-center md:text-left">
              {card.text}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(Benefits);
