// eslint-disable-next-line max-classes-per-file
import '../style/homePage.css';

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import T from 'prop-types';
import { Button, Icon, Paper, Text } from '@/components';
import { companyName } from '@/config/const';
import Footer from '../../../com/ui/footer';
import { getBrand } from '../../../com/ui/path';
import Brands from './brands';
import HowDifferent from './HowDifferent';
import HowWork from './HowWork';
import PressTabs from './PressTabs';
import ReadyToGetItFixed from './ReadyToGetItFixed';
import Testimonials, { trustedAmount } from './testimonials';
import WhyUs from './WhyUs';

// md breakpoint from tailwind config
const MD = 768;

const Bold = p => <span style={{ fontWeight: 600 }}>{p.children}</span>;

class HomePage extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(props) {
    super(props);
    this.state = {
      isMobile: '',
      textmask: '',
      submitted: false,
      name: '',
      email: '',
      phone: '',
      message: '',
    };
  }

  componentDidMount() {
    window.addEventListener(
      'resize',
      () => this.setState({ isMobile: window.innerWidth < 480 }),
      false
    );
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { city } = this.props;
    const brandName = getBrand();

    return (
      <>
        <section className="bg-cover bg-center pb-16 pt-[152px] md:bg-[url('/public/img/oven-vector.min.jpg')]">
          <div className="mx-auto max-w-1200 p-4">
            <div className="flex-1 px-4 text-center md:text-left">
              <div className="mb-8 flex flex-row items-center md:items-start">
                <img
                  alt="shield"
                  className="-mt-1"
                  src="/img/shield.min.png"
                  height={25}
                  width={24}
                />
                <Text className="ml-1 self-center uppercase" type="label">
                  All Jobs are 100% Guaranteed & Insured
                </Text>
              </div>

              <div className="w-full md:max-w-[600px]">
                <Text
                  className="font-normal"
                  type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}
                >
                  <span>
                    Book Same Day {brandName && `${brandName || ''} `}
                    <Bold>Appliance</Bold>
                    {' Repair '}
                    {!!city && (
                      <span>
                        {'in '}
                        <Bold>{city || ''}</Bold>{' '}
                      </span>
                    )}
                    {` with ${companyName}`}
                  </span>
                </Text>

                <Text className="mt-4 text-grey-dark" type="subtitle-1">
                  We are family. Reliable, honest, high-quality, professional, and on-time service.
                  Our mission is to make you happy.
                </Text>
              </div>

              <Button
                className="mt-10 flex w-full flex-row content-center justify-center md:max-w-[400px]"
                href="/book"
                variant="primary"
              >
                <Icon color="white" name="calendar" size={15} />
                <Text className="ml-2" color="white" type="button">
                  Book Online
                </Text>
              </Button>

              <PressTabs />
            </div>
          </div>
        </section>

        <section
          className="pb-2"
          style={{
            backgroundImage: '-webkit-linear-gradient(120deg, #F5F9FD 80%, #fff 0)',
          }}
        >
          <div className="mx-auto max-w-1200">
            <Paper className="rounded bg-white p-4" variant="elevated">
              <HowWork />
            </Paper>
          </div>
        </section>

        <section className="hidden bg-[#F5F9FD] p-4 sm:block">
          <div className="mx-auto max-w-1200">
            <Text color="grey.dark" className="text-normal mb-1 text-center" type="subtitle-2">
              Trusted by {trustedAmount} happy customers in {city || `your local area`}
            </Text>
          </div>
        </section>

        <div className="mx-auto max-w-1200">
          <WhyUs />
        </div>

        <section className="border-b border-b-grey-medium py-8">
          <div className="mx-auto max-w-1200">
            <HowDifferent />
          </div>
        </section>

        <section className="border-b border-b-grey-medium py-8">
          <div className="mx-auto max-w-1200">
            <Testimonials />
          </div>
        </section>

        <Brands />

        <section className="bg-[#F5F9FD] py-[15vh]">
          <div className="mx-auto max-w-1200">
            <ReadyToGetItFixed />
          </div>
        </section>

        <main id="Bootstrapped">
          <div id="V8">
            <style>{'.navbar { display: none; }'}</style>
            <Footer />
          </div>
        </main>
      </>
    );
  }
}

export default connect(s => s.form)(HomePage);
