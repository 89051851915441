import React from 'react';
import { Col,Row } from 'reactstrap';
import _ from 'lodash';
import { Icon } from '@/components';
import { companyName } from '@/config/const';
import Link from '../../com/ui/link';

const applicant = () => (
  <div className="applicationTimeline">
    <div className="timelineContainer animated fadeIn">
      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon active">
            <Icon name="check" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Applied</h4>
            <p className="sub-text-2" />
          </div>
        </div>
      </div>

      <div className="applicationStep flex-row">
        <div>
          <div className="tracker-icon active">
            <Icon name="search" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Background Screening</h4>
          </div>
        </div>
      </div>

      <div className="applicationStep flex-row">
        <div>
          <div className="tracker-icon">
            <Icon name="email" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Results</h4>
            <p className="sub-text-2" />
          </div>
        </div>
      </div>

      <div className="applicationLine" />
    </div>
  </div>
);

const backgroundScreening = () => (
  <div className="applicationTimeline">
    <div className="timelineContainer animated fadeIn">
      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon active">
            <Icon name="check" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Applied</h4>
            <p className="sub-text-2" />
          </div>
        </div>
      </div>

      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon active">
            <Icon name="search" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Background Screening</h4>
            <p className="sub-text-2">Your application has received views and interactions. </p>
          </div>
        </div>
      </div>

      <div className="applicationStep flex-row">
        <div>
          <div className="tracker-icon">
            <Icon name="email" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Results</h4>
            <p className="sub-text-2" />
          </div>
        </div>
      </div>

      <div className="applicationLine" />
    </div>
  </div>
);

const rejected = () => (
  <div className="applicationTimeline">
    <div className="timelineContainer animated fadeIn">
      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon">
            <Icon name="check" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Applied</h4>
            <p className="sub-text-2" />
          </div>
        </div>
      </div>

      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon">
            <Icon name="search" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Background Screening</h4>
            <p className="sub-text-2">Your application has received views and interactions. </p>
          </div>
        </div>
      </div>

      <div className="applicationStep active flex-row">
        <div>
          <div className="tracker-icon">
            <Icon name="email" size={16} />
          </div>
        </div>

        <div className="flex-1">
          <div className="tracker-text">
            <h4 className="sub-heading-3">Results</h4>
            <p className="sub-text-2">
              Hello, Thank you so much for giving us the opportunity to consider for employment.
              Your interest in our company means a lot! We've reviewed your application and
              unfortunately, it's not a match for what we're specifically looking for this time
              around. We know it takes a lot to submit an application, so we want you to know how
              much we truly appreciate you doing so! We'll make sure to hang onto your resume as
              we're growing all the time and there may be a better match soon! We wish you all the
              best!
              <br />
              Best, {companyName}
            </p>
          </div>
        </div>
      </div>

      <div className="applicationLine" />
    </div>
  </div>
);

const pages = { applicant, backgroundScreening, rejected };

export const Application = () => (
  <div id="ApplicationTracker">
    <div className="container">
      <h1 className="animated fadeIn">Track your Application</h1>
      <br />
      <br />
      <Row>
        <Col>{pages[_.pro().status]()}</Col>
        <Col md="7" sm="12" xs="12">
          <div className="infocards">
            <div className="card card-block animated fadeInUp">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Profile</p>
                </Col>
                <Col className="text-right">
                  <Link to="/profile" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-1">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Skills</p>
                </Col>
                <Col className="text-right">
                  <Link to="/skills" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-2">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Service Area</p>
                </Col>
                <Col className="text-right">
                  <Link to="/service-area" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card card-block animated fadeInUp delay-3">
              <Row className="no-gutters">
                <Col>
                  <p style={{ margin: '0px' }}>Application Info</p>
                </Col>
                <Col className="text-right">
                  <Link to="/application-info" className="link">
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
