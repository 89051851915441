import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import Profile from '@/pages/user/profile';
import { loadTasks } from '../../com/task';
import Loader from '../../com/ui/loader';
import EmptyPro from './emptyPro';
import EmptyUser from './emptyUser';
import UserTasks from './UserTasks';

class TaskList extends React.Component {
  state = { loading: true };

  async componentWillMount() {
    const { roles } = this.props;
    if (!roles.customersupport && roles.serviceprovider) {
      const query = { 'visits.assignee.id': this.props.me.proId };
      await U.api('post', 'tasks/search', { query }, true);
    } else if (roles.customersupport && global.location.pathname.startsWith('/pro')) {
      const query = { 'visits.assignee.id': global.id() };
      await U.api('post', 'tasks/search', { query }, true);
    } else {
      await loadTasks();
    }
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    const { hasVisits, roles } = this.props;
    if (!hasVisits) {
      return roles.serviceprovider ? <EmptyPro /> : <EmptyUser />;
    }
    return roles.serviceprovider ? <Profile /> : <UserTasks />;
  }
}

export default connect(s => {
  const { me } = s;
  const roles = U.user.roles(s);
  const hasVisits = !_.isEmpty(s.tasks);
  return { hasVisits, me, roles };
})(TaskList);
