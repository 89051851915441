/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Me, U } from '@nanaio/util';
import type { Data } from '@nanaio/util/dist/analytics';
import _ from 'lodash';
import { analyticsEnabled,isDevBuild } from '@/config/const';
import DataDogRumLogger from './DataDogRumLogger';
import SegmentLogger from './SegmentLogger';
import SentryLogger from './SentryLogger';

const { Analytics, LoggableEvent, LoggableException, LoggedInEvent, WorkOrderEvent } = U.analytics;

const analytics = new Analytics(
  analyticsEnabled ? [DataDogRumLogger, SegmentLogger, SentryLogger] : []
);

export function identify(me: Me, features: Record<string, unknown>): void {
  const state = global.store.getState();
  const user = state.users[me?.userId];
  const pro = me.proId ? state.pros[me.proId] : undefined;
  const proRole = pro?.role;
  const traits = _.transform(
    features,
    (props: Record<string, boolean>, value: unknown, key: string) => {
      props[_.snakeCase(key)] = Boolean(value);
    }
  );
  analytics.identify(
    me?.userId,
    U.trimUndefined({
      ...traits,
      name: user?.profile.fullName,
      email: user?.email,
      address: user?.profile.address,
      proRole,
    })
  );
}

export function start(): void {
  analytics.start();
}

export function reset(): void {
  analytics.reset();
}

export function loggedOutEvent(data?: Data) {
  return new LoggableEvent(analytics, isDevBuild, data);
}

export function loggedInEvent(data?: Data) {
  return new LoggedInEvent(analytics, isDevBuild, data);
}

export function workOrderEvent(data?: Data) {
  return new WorkOrderEvent(analytics, isDevBuild, data);
}

export function exception(data?: Data) {
  return new LoggableException(analytics, isDevBuild, data);
}
