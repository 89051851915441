import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { act } from 'react-dom/test-utils';
import { expect } from '@jest/globals';
import _ from 'lodash';
import RadioGroup from './RadioGroup';

let container: HTMLDivElement | null = null;

beforeEach(() => {
  // setup a DOM element as a render target
  container = document.createElement('div');
  document.body.appendChild(container);
});
afterEach(() => {
  // cleanup on exiting
  if (container) {
    unmountComponentAtNode(container);
    container.remove();
  }
  container = null;
});

describe('RadioGroup', () => {
  it('initializes with no clicked radio buttons', () => {
    const onClick = jest.fn();

    act(() => {
      render(
        <RadioGroup
          options={[
            { text: 'Option 1', value: 'option1' },
            { text: 'Option 2', value: 'option2' },
            { text: 'Option 3', value: 'option3' },
          ]}
          onClick={onClick}
        />,
        container
      );
    });

    const buttons = _.values(document.querySelectorAll('.qa-radio-button'));

    expect(buttons).not.toHaveLength(0);

    for (const button of buttons) {
      expect(button).toBeDefined();
      expect(button.classList).toContain('qa-radio-button-unmarked');
    }
  });

  it('updates the radio button icon when value supplied', () => {
    const onClick = jest.fn();

    act(() => {
      render(
        <RadioGroup
          options={[
            { text: 'Option 1', value: 'option1' },
            { text: 'Option 2', value: 'option2' },
            { text: 'Option 3', value: 'option3' },
          ]}
          onClick={onClick}
          value="option1"
        />,
        container
      );
    });

    const button = document.querySelector('.qa-radio-button');

    expect(button).toBeDefined();
    expect(button?.classList).toContain('qa-radio-button-marked');
  });

  it('does not render selected radio button when invalid value', () => {
    const onClick = jest.fn();

    act(() => {
      render(
        <RadioGroup
          options={[
            { text: 'Option 1', value: 'option1' },
            { text: 'Option 2', value: 'option2' },
            { text: 'Option 3', value: 'option3' },
          ]}
          onClick={onClick}
          value="optionBad"
        />,
        container
      );
    });

    const buttons = _.values(document.querySelectorAll('.qa-radio-button'));

    expect(buttons).not.toHaveLength(0);

    for (const button of buttons) {
      expect(button).toBeDefined();
      expect(button.classList).toContain('qa-radio-button-unmarked');
    }
  });
});
