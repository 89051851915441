import { Option, Region, U } from '@nanaio/util';
import _ from 'lodash';

export { copyText, generateProAppDeepLink,getParams, openLink } from '../utils';

export const getIsChrome = (): boolean => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    return true;
  }
  return (
    isChromium != null && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false
  );
};

export const loadCatalogPartCategoryOptions = async (): Promise<Option[]> => {
  const projection = { _id: 1, name: 1, displayName: 1 };
  const response = await U.api<{ _id: string; name: string; displayName: string }[]>(
    'post',
    'catalogpartcategories/search',
    {
      limit: -1,
      projection,
    }
  );
  if ('errMsg' in response) {
    return [];
  }
  return _.sortBy(
    _.map(response, p => ({ id: p.name, name: p.displayName })),
    'name'
  );
};

export const loadOrgOptions = async (): Promise<Option[]> => {
  const projection = { id: 1, name: 1 };
  const response = await U.api<{ id: string; name: string }[]>('post', 'orgs/search', {
    limit: -1,
    projection,
  });
  if ('errMsg' in response) {
    return [];
  }
  return _.sortBy(response, 'name');
};

export const loadProOptions = async (): Promise<Option[]> => {
  const projection = { _id: 1, 'user.fullName': 1, 'metadata.referralSource': 1 };
  const response = await U.api<
    { _id: string; user: { fullName: string }; metadata: { referralSource: string } }[]
  >('post', 'pros/search', { limit: -1, projection });
  if ('errMsg' in response) {
    return [];
  }
  return _.sortBy(
    _.map(response, p => ({ ...p, id: p._id, name: p.user.fullName })),
    'name'
  );
};

export const loadRegionOptions = async (): Promise<Option[]> => {
  const { regions } = global.store.getState();
  const response = _.isEmpty(regions)
    ? await U.api<Region[]>('post', 'regions/search', { limit: -1 })
    : _.values(regions);
  if ('errMsg' in response) {
    return [];
  }
  return _.sortBy(response, 'name');
};
