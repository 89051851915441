import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Number, Search } from '../../com/ui/form';
import Loader from '../../com/ui/loader';

class WorkArea extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = _.merge({}, p.pro);
    this.addCity = this.addCity.bind(this);
    this.getWorkArea = this.getWorkArea.bind(this);
    this.loadCities = this.loadCities.bind(this);
    this.onSave = this.onSave.bind(this);
    this.removeCity = this.removeCity.bind(this);
    this.loadCities(_.get(p.pro, 'user.address.region'));
    this.getWorkArea(this.state);
  }

  addCity(id, path) {
    if (!id) {
      return;
    }
    const city = _.pick(
      _.find(this.state.cityOptions, c => c.id === id),
      ['locality', 'region']
    );
    if (!_.isEmpty(city)) {
      this.setState(s => {
        U.setPush(s, `workArea.${path}`, city, _.uniq);
        this.getWorkArea(s);
        return s;
      });
    }
  }

  componentWillReceiveProps(p) {
    if (!this.props.pro && p.pro) {
      this.setState(_.merge({}, p.pro));
      this.loadCities(_.get(p.pro, 'user.address.region'));
      this.getWorkArea(p.pro);
    }
  }

  async getWorkArea(pro) {
    const geoCoordinates = _.get(pro, 'user.address.geoCoordinates');
    if (!geoCoordinates) {
      return;
    }
    this.setState({ loading: true });
    const workArea = pro.workArea || {};
    const bounds = U.boundingBox(geoCoordinates, workArea.radius);
    const query = {
      $or: [
        {
          'geoCoordinates.lat': { $gte: bounds.minLat, $lte: bounds.maxLat },
          'geoCoordinates.lng': { $gte: bounds.minLon, $lte: bounds.maxLon },
        },
        { locality: { $in: _.map(workArea.include, 'locality') } },
      ],
    };
    let cities = await U.api('post', 'cities/search', { query, limit: -1 });
    cities = _.sortBy(
      _.filter(cities, c => {
        if (_.find(workArea.include, _.pick(c, ['locality', 'region']))) {
          return true;
        }
        if (_.find(workArea.exclude, _.pick(c, ['locality', 'region']))) {
          return;
        }
        return U.distance(geoCoordinates, c.geoCoordinates) <= workArea.radius;
      }),
      'locality'
    );
    this.setState({ cities, loading: false });
  }

  async loadCities(state) {
    if (!state) {
      return;
    }
    const query = { region: state };
    const cityOptions = await U.api('post', 'cities/search', { query, limit: -1 });
    this.setState({ cityOptions });
  }

  onSave() {
    const changes = [{ action: 'replace', path: 'workArea', value: this.state.workArea }];
    U.api('put', `serviceproviders/${this.props.pro.id}`, changes, true);
  }

  removeCity(index, path) {
    const out = _.filter(_.get(this.state, `workArea.${path}`), (v, i) => i !== index);
    this.setState(s => {
      _.set(s, `workArea.${path}`, out);
      this.getWorkArea(s);
      return s;
    });
  }

  render() {
    if (!this.props.pro) {
      return <Loader />;
    }
    if (!_.get(this.props.pro, 'user.address.geoCoordinates')) {
      return (
        <div>
          <div>Set pro's home address before configuring work area</div>
          <a href={`/pros/${this.props.pro.id}/profile`}>Edit Profile</a>
        </div>
      );
    }
    return (
      <div className="container pt-3">
        <div className="row">
          <Search
            id="user.address.region"
            label="State"
            options={U.states}
            onChange={this.loadCities}
            className="col"
          />
          <Number
            id="workArea.radius"
            debounce={() => this.getWorkArea(this.state)}
            debounceLength={500}
            className="col"
          />
        </div>
        <div className="row">
          <div className="col">
            <div>
              <strong>Include (even outside radius)</strong>
              <Search
                id="include"
                label="Search"
                options={this.state.cityOptions}
                nameKey="locality"
                sort
                onChange={v => this.addCity(v, 'include')}
              />
              {_.map(_.get(this.state.workArea, 'include'), (c, i) => (
                <div key={i} onClick={() => this.removeCity(i, 'include')}>
                  {c.locality}, {c.region} <span className="pointer link-blue">remove</span>
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <div>
              <strong>Exclude (even inside radius)</strong>
              <Search
                id="exclude"
                label="Search"
                options={this.state.cityOptions}
                nameKey="locality"
                sort
                onChange={v => this.addCity(v, 'exclude')}
              />
              {_.map(_.get(this.state.workArea, 'exclude'), (c, i) => (
                <div key={i} onClick={() => this.removeCity(i, 'exclude')}>
                  {c.locality}, {c.region} <span className="pointer link-blue">remove</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <strong>Work Area</strong>
        </div>
        {this.state.loading ? (
          <div>Loading ...</div>
        ) : (
          <div className="row">
            {_.map(this.state.cities, (c, i) => (
              <div key={i} className="col-sm-4">
                {c.locality}, {c.region}
              </div>
            ))}
          </div>
        )}
        {!_.isEqual(this.state.workArea, _.get(this.props.pro, 'workArea')) && (
          <div className="btn btn-primary mt-3" onClick={this.onSave}>
            Save
          </div>
        )}
      </div>
    );
  }
}

export default connect(s => {
  const pro = s.pros[global.id()];
  return { pro };
})(WorkArea);
