import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Loader from './Loader';

export default {
  title: 'Core/Loader',
  component: Loader,
  args: {
    isLoading: true,
  },
  decorators: [
    Story => (
      <div className="flex">
        <div>
          <Story />
          This text is below the story in the HTML
        </div>
      </div>
    ),
  ],
} as ComponentMeta<typeof Loader>;

const Template: ComponentStory<typeof Loader> = args => <Loader {...args} />;

export const Default = Template.bind({});
Default.args = {};
Default.parameters = {
  // disables Chromatic's snapshotting on a story level
  chromatic: { disableSnapshot: true },
};

export const Fullscreen = Template.bind({});
Fullscreen.args = { fullscreen: true };
Fullscreen.parameters = {
  // disables Chromatic's snapshotting on a story level
  chromatic: { disableSnapshot: true },
};
