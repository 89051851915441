import React, { ReactNode, useMemo } from 'react';
import U from '@nanaio/util';
import classnames from 'classnames';
import Icon, { Props as IconProps } from './Icon';
import Text from './Text';

// TODO a utility to generate a kebab case list of colors from tailwind.config.js
// would make this function unnecessary.  But until that is written this list needs
// to be hard coded somewhere in order for typescript to use it.
export const kebabColors = [
  'secondary',
  'success',
  'primaryCTA',
  'danger',
  'accent-orange',
  'accent-purple',
  'grey-dark',
  'primary',
] as const;

export type Props = {
  children?: ReactNode;
  color?: (typeof kebabColors)[number];
  className?: string;
  float?: boolean;
  iconProps?: Omit<IconProps, 'size'>;
  onClick?: () => void;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
  variant?: 'outline' | 'solid' | 'solid-faded';
};

export default function Badge({
  children,
  className,
  color = 'secondary',
  iconProps,
  onClick,
  float = false,
  size = 'small',
  style = {},
  variant = 'solid',
}: Props): JSX.Element | null {
  const textColor = `text-${color}`;
  const backgroundColor = `bg-${color}`;
  const outlineColor = `ring-${color}`;

  const textColorClasses = useMemo(() => {
    return classnames({
      [textColor]: variant === 'solid-faded' || variant === 'outline',
      'text-white': variant === 'solid',
    });
  }, [textColor, variant]);

  // vertical alignment padding is being set different for the icon and children (text) because
  // text being naturally centered looks a little off since the centering includes the space below the line
  const iconPadding = classnames({
    'inline-flex py-1': size === 'small',
    'inline-flex py-1.5': size === 'medium',
  });
  const childrenPadding = classnames({
    'pb-[2.5px] pt-[5.5px]': size === 'small',
    'pb-[4.5px] pt-[7.5px]': size === 'medium',
  });

  const innerClasses = classnames('inline-flex items-center space-x-0.5 rounded-full', {
    'px-2': size === 'small' && children,
    'px-1': size === 'small' && !children,
    'px-1.5': size === 'medium' && !children,
    'px-3': size === 'medium' && children,
    'bg-opacity-10': variant === 'solid-faded',
    'ring-white': float && variant !== 'outline',
    'ring-1.5 ring-inset': float || variant === 'outline',
    [backgroundColor]: variant === 'solid-faded' || variant === 'solid',
    'bg-white': variant === 'outline',
    [outlineColor]: variant === 'outline',
  });

  const shadowClasses = classnames(
    { 'inline-flex rounded-full bg-white shadow-elevation': float },
    className
  );

  const newChildren = useMemo(() => {
    if (children && U.isText(children)) {
      return (
        <Text className={textColorClasses} type="label" noWrap>
          {children}
        </Text>
      );
    }
    return children;
  }, [children, textColorClasses]);

  if (!newChildren && !iconProps) {
    return null;
  }

  return (
    <div className={shadowClasses}>
      <div className={innerClasses} onClick={onClick} style={style}>
        {iconProps && (
          <div className={classnames(iconPadding, textColorClasses)}>
            <Icon {...iconProps} size={14} />
          </div>
        )}
        {newChildren && <div className={childrenPadding}>{newChildren}</div>}
      </div>
    </div>
  );
}
