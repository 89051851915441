import React, { useState } from 'react';
import { U } from '@nanaio/util';
import { ComponentMeta } from '@storybook/react';
import Form from './Form';
import Input, { Type } from './Input';

export default {
  title: 'Form/RadioGroup',
  component: Form,
  args: {
    disabled: false,
    direction: 'row',
    size: 'medium',
  },
  argTypes: {
    direction: { control: { type: 'inline-radio' }, options: ['column', 'row'] },
    size: { control: { type: 'inline-radio' }, options: ['small', 'medium', 'large'] },
  },
} as ComponentMeta<typeof Form>;

const reasonOptions = [
  'Reason 1',
  'Replacing unit',
  'Chose a different company',
  'Scheduling issues Testing a really long reason that might wrap to multiple lines of text to see how this radio option vertical alignment works with really long wrapped text.',
  'Unsatisfied with service.',
  'Other',
];

export const RadioGroup = ({
  disabled,
  direction,
  size,
}: {
  disabled: boolean;
  direction: 'column' | 'row';
  size: 'small' | 'medium' | 'large';
}): JSX.Element => {
  const [form, setForm] = useState({ reason: '', feedback: '', phone: '' });
  return (
    <Form onChange={setForm} value={form}>
      <Input
        disabled={disabled}
        direction={direction}
        id="reason"
        label="Reasons"
        type={Type.RADIO}
        options={U.toOptions(reasonOptions)}
        onClick={value => setForm({ ...form, reason: value as string })}
        size={size}
      />
      <Input id="email" label="Feedback" type={Type.TEXT} required={form.reason === 'Other'} />
      <Input id="phone" label="Phone" type={Type.PHONE} />
    </Form>
  );
};
