import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import Papa from 'papaparse';
import T from 'prop-types';
import { Icon } from '@/components';
import { Address, Bool, Select } from '../../com/ui/form';

export default class Cities extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { cities: [], results: [], ignoreHeader: true };
    this.importFile = this.importFile.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.uploadCities = this.uploadCities.bind(this);
  }

  onRemove(index) {
    const cities = this.state.cities.filter((v, n) => n !== index);
    this.setState({ cities });
  }

  async onSave(cities) {
    cities = cities || this.state.cities;
    this.setState({ progress: { top: 0, bot: cities.length } });
    const results = cities.map(() => 'uploading');
    this.setState({ results });
    const groups = _.ceil(cities.length / 6);
    this.setState({
      progress: { top: 0, bot: cities.length, action: 'Uploading Cities' },
    });
    for (let i = 0; i < groups; i++) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        _.times(6, async n => {
          const index = i * 6 + n;
          if (cities[index]) {
            const r = await U.api('post', 'cities', cities[index]);
            const result = _.get(r, 'message') || _.get(r, 'errmsg') || 'success';
            this.setState(s => _.set(s, `results.${index}`, result));
          }
        })
      );
      this.setState(s => _.set(s, 'progress.top', (i + 1) * 6));
    }
  }

  importFile(e) {
    const fileReader = new FileReader();
    fileReader.onloadend = e =>
      this.setState({
        csvUpload: true,
        rows: Papa.parse(e.target.result).data,
      });
    fileReader.readAsText(e.target.files[0]);
  }

  async uploadCities() {
    const cityIndex = _.indexOf(this.state.rowTypes, 'city');
    const stateIndex = _.indexOf(this.state.rowTypes, 'state');
    if (cityIndex === -1 || stateIndex === -1) {
      return this.setState({ uploadError: 'Select city & state columns' });
    }
    const rows = this.state.rows.slice(+this.state.ignoreHeader);
    const cities = [];
    const groups = _.ceil(rows.length / 6);
    this.setState({
      progress: { top: 0, bot: rows.length, action: 'Autocomplete Address' },
    });
    for (let i = 0; i < groups; i++) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        _.times(6, async n => {
          const row = rows[i * 6 + n];
          if (!row || !row[cityIndex] || !row[stateIndex]) {
            return;
          }
          const address = encodeURIComponent(`${row[cityIndex]}, ${row[stateIndex]}`);
          const url =
            `https://maps.googleapis.com/maps/api/geocode/json?` +
            `address=${address}&key=${U.const.googleApiKey}`;
          const result = _.get(await (await fetch(url)).json(), 'results.0');
          return cities.push(U.addressFromPlace(result));
        })
      );
      this.setState(s => _.set(s, 'progress.top', (i + 1) * 6));
    }
    this.setState({ cities });
    this.onSave(cities);
  }

  render() {
    const { progress } = this.state;
    return (
      <div>
        {progress && `${progress.top} / ${progress.bot} ${progress.action}`}
        <div className="form-group">
          <label>Import Csv</label>
          <input className="form-control" type="file" onChange={this.importFile} />
        </div>
        {this.state.rows && (
          <div>
            <Bool id="ignoreHeader" />
            <div>
              Select city and state columns using the dropdowns below. (Only first 10 rows are
              shown)
            </div>
            <div className="text-danger">{this.state.uploadError}</div>
            <d className="btn btn-primary" onClick={this.uploadCities}>
              Upload
            </d>
            <table className="table-sm table-striped table-hover table">
              <thead>
                <tr>
                  {_.times(this.state.rows[0].length, n => (
                    <th key={n}>
                      <Select id={`rowTypes.${n}`} options={['city', 'state']} cap label="" />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.rows.slice(0, 10).map((r, rIndex) => (
                  <tr key={rIndex}>
                    {_.map(r, (c, n) => (
                      <td key={n}>{!rIndex && this.state.ignoreHeader ? `${c} (ignored)` : c}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div style={{ height: '1px', backgroundColor: 'black' }} />
        {!this.state.csvUpload && (
          <d>
            <div className="row">
              {_.times(this.state.cities.length + 1, n => (
                <div className="col-sm-2" key={n}>
                  <div className="d-flex">
                    <Address city id={`cities.${n}`} label={`City ${n}`} />
                    <Icon
                      name="close_thick"
                      className="pointer text-danger"
                      onClick={() => this.onRemove(n)}
                      size={16}
                    />
                  </div>
                </div>
              ))}
            </div>
            <d className="btn btn-primary" onClick={() => this.onSave()}>
              Save
            </d>
          </d>
        )}
        <d>Only first 1000 results are shown</d>
        <table className="table-sm table-striped table-hover mt-3 table">
          <thead>
            <tr>
              <th>City</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {_.compact(this.state.cities)
              .slice(0, 1000)
              .map((c, n) => (
                <tr key={n}>
                  <td>
                    {c.locality}, {c.region}
                  </td>
                  <td>{this.state.results[n]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
