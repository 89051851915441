import React from 'react';
import { connect } from 'react-redux';
import { T } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import { workOrderEvent } from '@/com/analytics';
import { Button } from '@/components';
import { updateTask } from '../../../com/task';
import { Number, Select, Text, Textbox } from '../../../com/ui/form';

const types = { pick: Select, number: Number, text: Text, textbox: Textbox };

class Questions extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = _.merge({}, p.job);
    this.onSave = this.onSave.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  onSave() {
    const changes = [
      {
        action: 'replace',
        path: 'metadata.taskQuestions',
        value: _.get(this.state, 'metadata.taskQuestions'),
      },
    ];
    updateTask(this.props.job.id, changes);
    this.setState({ isOpen: false });
    workOrderEvent().track('v1_workOrderTaskDetailsUpdated');
  }

  toggleEdit() {
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
  }

  render() {
    return (
      <div className="card-box mt-7">
        <div className="card-box--header d-flex justify-content-between">
          <div className="flex-1">Job Details</div>
          <Button variant="link" onClick={this.toggleEdit}>
            Update Details
          </Button>
        </div>
        <div className="card-box--content padding-0 ">
          <div className="card-box--shadow">
            <ul className="no-list">
              {this.props.questions.map((q, i) => {
                const answer = _.get(this.props.job, q.id);
                return (
                  <li key={i} className="border-bottom last-no-border py-4">
                    <p className="weight-600 lh-15 text-black">{q.label}</p>
                    <p>{_.isObject(answer) ? _.keys(answer) : (answer || '-').toString()}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <Rodal
          visible={this.state.isOpen}
          onClose={this.toggleEdit}
          showMask
          className="rodal_modal"
          closeMaskOnClick={false}
        >
          <div style={{ height: 25 }} />
          <div className="rodal-title mb-3 text-center">Edit Details</div>
          <div id="newForm" className="m-3 px-3">
            {this.props.questions
              .filter(q => types[q.type])
              .map((q, i) => React.createElement(types[q.type], { ...q, key: i }))}
            <button
              onClick={this.onSave}
              className="button-dark w-100 d-block d-flex justify-content-center align-center mb-2 text-center"
            >
              Save
            </button>
          </div>
        </Rodal>
      </div>
    );
  }
}

export default connect(state => {
  const { form } = state;
  const task = state.tasks[global.id()];
  const { brands, services } = state;
  const orgId = T.orgId(task) || '';
  const org = state.orgs[orgId];
  const user = state.users[state.me.userId];
  const workOrder = state.workorders[task.metadata.workOrderId];
  const questions = T.questions({ brands, org, services, task, user, workOrder });
  return { form, job: task, questions };
})(Questions);
