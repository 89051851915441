import React from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Button, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import { copyText } from '../../../../com/util';
import Flags from './Flags';
import TaskTags from './TaskTags';

type Props = {
  taskId: string;
};

export default function Header2({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[nullthrows(taskId)];
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];
  const bills = [];
  if (workOrder.invoice) {
    const orgBill = workOrder.invoice.toOrg;
    const userBill = workOrder.invoice.toCustomer;
    if (orgBill) {
      bills.push({ name: 'B2B balance', amount: orgBill.balance });
    }
    if (userBill) {
      bills.push({ name: 'CX balance', amount: userBill.balance });
    }
  } else if (workOrder.estimate) {
    const orgBill = workOrder.estimate.toOrg;
    const userBill = workOrder.estimate.toCustomer;
    if (orgBill) {
      bills.push({ name: 'B2B estimate', amount: orgBill.total });
    }
    bills.push({ name: 'CX estimate', amount: userBill.total });
  }
  let orgPortalLink = '';
  let copyPortalLink = '';
  const workOrderNumber = task.metadata.workOrderNumber;
  const orgName = task.customer.org?.name;
  if (workOrderNumber) {
    if (orgName === 'American Home Shield') {
      orgPortalLink = `https://www.ahs.com/contractor/portal/dispatches/dispatch/${workOrderNumber}/coverage-details`;
    }
    if (orgName === 'First American Home Warranty') {
      copyPortalLink = `https://homewarranty.firstam.com/account/contractor/workorders/detail?id=${workOrderNumber}`;
    }
  }

  return (
    <div className="flex w-full border-b border-grey-medium bg-white p-2">
      {!!bills.length && (
        <div className="border-r border-grey-medium px-2">
          {_.map(bills, b => (
            <Text key={b.name}>
              {b.name}: {U.toMoney(b.amount)}
            </Text>
          ))}
        </div>
      )}
      <div className="border-r border-grey-medium px-2">
        <Text>Created: {m(task.createTime).format('M/D/YY h:mma')}</Text>
        {task.creator ? (
          <Text>
            {task.creator.fullName}, {T.creatorType(task)}, {T.creatorMedium(task)}
          </Text>
        ) : (
          <Text>Unknown Creator</Text>
        )}
      </div>
      {workOrder.invoice && (
        <div className="border-r border-grey-medium px-2">
          <Text>Propay balance: {U.toMoney(workOrder.invoice.proPay.balance)}</Text>
          <Text>Propay total: {U.toMoney(workOrder.invoice.proPay.total)}</Text>
        </div>
      )}
      <div className="border-r border-grey-medium px-2">
        <Flags taskId={taskId} />
      </div>
      <div className="border-r border-grey-medium px-2">
        <TaskTags taskId={taskId} />
      </div>
      {orgPortalLink && (
        <Button
          className="my-auto ml-2"
          href={orgPortalLink}
          target="_blank"
          variant="primary-outline"
          size="medium"
        >
          Open In Org Portal
        </Button>
      )}
      {copyPortalLink && (
        <Button
          className="my-auto ml-2"
          onClick={() => copyText(copyPortalLink)}
          variant="primary-outline"
          size="medium"
        >
          Copy Portal Link
        </Button>
      )}
    </div>
  );
}
