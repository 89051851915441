/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Button, { Option, Props } from './Button';

export default {
  title: 'Core/Button',
  component: Button,
  args: {
    children: 'Button',
    size: 'small',
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
    size: { control: { type: 'inline-radio' } },
  },
  decorators: [
    Story => (
      <div className="flex">
        <Story />
      </div>
    ),
  ],
} as ComponentMeta<typeof Button>;

const Template: ComponentStory<typeof Button> = args => <Button {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Variants = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Variant prop options</p>
    <p className="mt-6">primary</p>
    <Default {...args} variant="primary" />
    <p className="mt-6">outline</p>
    <Default {...args} variant="outline" />
    <p className="mt-6">link</p>
    <Default {...args} variant="link" href="http://www.google.com" />
    <p className="mt-6">secondary</p>
    <Default {...args} variant="secondary" />
    <p className="mt-6">danger</p>
    <Default {...args} variant="danger" />
  </div>
);

export const Sizes = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Size prop options</p>
    <p className="mt-6">small (30px)</p>
    <Default {...args} size="small" />
    <p className="mt-6">medium (36px)</p>
    <Default {...args} size="medium" />
    <p className="mt-6">large (40px)</p>
    <Default {...args} size="large" />
    <p className="mt-6">xlarge (48px)</p>
    <Default {...args} size="xlarge" />
  </div>
);

export const Modal = Template.bind({});
Modal.args = {
  children: 'Get Started',
  size: 'xlarge',
  variant: 'primary',
  className: 'w-full',
};

const optionArray: Option[] = [
  { id: 1000, name: '1,000' },
  { id: 5000, name: '5,000' },
  { id: 10000, name: '10,000' },
  { id: 20000, name: '20,000' },
  { id: 30000, name: '30,000' },
  { id: 40000, name: '40,000' },
  { id: 50000, name: '50,000' },
];

export const DropDown = Template.bind({});
DropDown.args = {
  children: 'Load',
  size: 'small',
  options: optionArray,
  variant: 'primary',
};

export const Tooltip = Template.bind({});
Tooltip.args = {
  children: 'Tooltip',
  size: 'medium',
  tooltip: {
    node: 'Test of the tooltip text. The quick brown fox jumps over the lazy dog.',
  },
  variant: 'primary',
};

const load = async () =>
  new Promise<void>(resolve => {
    setTimeout(() => resolve(), 2000);
  });

export const Loading = Template.bind({});
Loading.args = {
  children: 'Loading',
  onClick: async () => {
    await load();
  },
  size: 'medium',
  variant: 'primary',
};
