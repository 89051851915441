import React from 'react';
import { T, Task } from '@nanaio/util';
import _ from 'lodash';
import { Text } from '@/components';
import { useLegacySelector } from '@/hooks';

type Props = {
  task: Task;
};

export default function OpportunityNotifications({ task }: Props): JSX.Element {
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  if (_.isEmpty(_.get(task, 'metadata.oppNotif.pros'))) {
    if (!workOrder.hasCustomerConfirmed) {
      return (
        <Text color="danger" type="button" className="p-4">
          Opportunity notifications are not sent until the customer confirms via customer portal.
        </Text>
      );
    }
    if (task.tags.includes(T.Tag.TEST)) {
      return (
        <Text color="danger" type="button" className="p-4">
          Opportunity notifications are not sent for tasks with "Test" tag
        </Text>
      );
    }
    return <Text className="p-4">No pros notified</Text>;
  }

  const rawPros = task.metadata?.oppNotif?.pros;
  const pros = _.sortBy(_.values(rawPros), 'order');
  const notified = pros
    .filter(pro => pro.notifyTime)
    .map(pro => ({ link: `/pros/${pro.id}/opportunities`, name: pro.name }));
  const notNotified = pros
    .filter(pro => !pro.notifyTime)
    .map(pro => ({ link: `/pros/${pro.id}`, name: pro.name }));

  return (
    <div className="p-4">
      <Text>
        Notified:{' '}
        {notified.map(({ link, name }, i, array) => (
          <Text key={link} tag="span">
            <a href={link} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
            {i !== array.length - 1 && ', '}
          </Text>
        ))}
      </Text>
      <Text className="mt-4">
        Not Notified:{' '}
        {notNotified.map(({ link, name }, i, array) => (
          <Text key={link} tag="span">
            <a href={link} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
            {i !== array.length - 1 && ', '}
          </Text>
        ))}
      </Text>
    </div>
  );
}
