import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { T,Task, U } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { APIError, BrandLogo, Button, Loader, Modal, RadioTileList, Text } from '@/components';
import { choiceType } from '@/components/core/RadioTileList';
import Form, { Ref } from '@/components/form/Form';
import Input, { Type } from '@/components/form/Input';
import { useLazyLegacyAPI } from '@/hooks';
import { brandLogos } from '../../../../config/brandLogos';
import type { SubStepProps as Props } from './ConfirmationFlowModal';

const NUMBER_OF_BRANDS_TO_SHOW = 20;
const ALL_BRANDS = Object.keys(U.brands).sort();

type EventType = {
  variant: string;
  step: string;
  changes?: { path: string; value: unknown }[];
  changesMade: boolean;
  couldNotFind: boolean;
};

export default function ApplianceBrandStep({ task, onNextSubStep }: Props): JSX.Element {
  const origForm = _.pick(task.metadata.taskQuestions, ['Make']);
  const [nextWithoutBrand, setNextWithoutBrand] = useState(false);
  const [form, setForm] = useState({ ...origForm });
  const [isNextWithoutBrandLoading, setIsNextWithoutBrandLoading] = useState(false);
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();
  const applianceName = T.appliance(task);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to save changes to brand</>} />
    ),
    save: true,
    method: 'put',
  });

  const handleNext = useCallback(
    async (bypassValidation?: boolean) => {
      if (!bypassValidation && !nullthrows(submitRef.current).submit()) {
        return;
      }
      const eventInfo: EventType = {
        variant: 'ftc',
        step: 'brand',
        changesMade,
        couldNotFind: nextWithoutBrand,
      };
      if (changesMade) {
        const changes = [{ path: 'metadata.taskQuestions.Make', value: form.Make }];
        const data = await updateTask(changes);
        if (!data) {
          return;
        }
        eventInfo.changes = changes;
      }

      workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);
      setIsNextWithoutBrandLoading(false);
      await onNextSubStep();
    },
    [changesMade, form.Make, nextWithoutBrand, onNextSubStep, submitRef, updateTask]
  );

  useEffect(() => {
    if (nextWithoutBrand) {
      setNextWithoutBrand(false);
      void handleNext(true);
    }
  }, [handleNext, nextWithoutBrand]);

  const handleCantFind = () => {
    setForm({ Make: undefined });
    setIsNextWithoutBrandLoading(true);
    setNextWithoutBrand(true);
  };

  const brandChoices: choiceType[] = useMemo(() => {
    const choices = Object.entries(brandLogos);
    const sortedChoices = choices
      .sort((a, b) => b[1].popularity - a[1].popularity)
      .slice(0, NUMBER_OF_BRANDS_TO_SHOW);
    return sortedChoices.map(choice => ({
      name: choice[0],
      description: <BrandLogo brandName={choice[0]} size="small" />,
    }));
  }, []);

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          <Form onChange={setForm} value={form} ref={submitRef}>
            {taskToUpdate.error}
            <Text className="mt-6 text-center text-font-dark" type="headline-6">
              {`Select the Brand of Your ${applianceName}`}
            </Text>
            <Text className="mt-4 text-center text-grey-dark">
              This will help us more accurately diagnose the issue.
            </Text>
            <Input
              className="mt-10"
              id="Make"
              label=""
              placeholder="Search brand"
              type={Type.SEARCH}
              options={ALL_BRANDS}
              required
            />
            <Text className="mt-14 text-font-dark">Or Select From Popular Brands</Text>
            <RadioTileList
              className="mt-4 justify-center"
              choices={brandChoices}
              variant="tile"
              arrangement="twoColumn"
              value={form.Make}
              onSelection={(choice: string | undefined) => setForm({ Make: choice })}
            />
            <div className="relative mt-10 flex w-full justify-center">
              <Button className="" variant="link" onClick={handleCantFind}>
                I can't find the brand
              </Button>
              {isNextWithoutBrandLoading && (
                <div className="absolute inset-0">
                  <Loader
                    className="absolute left-1/2 top-1/2 -ml-2.5 -mt-2.5"
                    isLoading
                    variant="dark"
                  />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={async () => handleNext()} submit disabled={!form.Make}>
          {origForm.Make === undefined || changesMade ? 'Save' : 'Next'}
        </Button>
      </Modal.Footer>
    </>
  );
}
