import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import T from 'prop-types';
import { Icon, Text } from '@/components';

const handleRemove = (t, id, onRemove) => {
  if (_.isFunction(onRemove)) {
    onRemove(id);
  }
  t.setState(state => {
    const parts = _.split(id, '.');
    if (parts.length > 1) {
      const index = Number(parts.pop());
      const parentId = parts.join('.');
      const parentValue = _.get(state, parentId);
      if (_.isArray(parentValue)) {
        const newArray = parentValue.filter((_, i) => i !== index);
        return _.set(state, parentId, newArray);
      }
    }
    return _.omit(state, id);
  });
};

const Group = ({ children, id, label, prefix, suffix, className, req, onRemove, style }, { t }) => {
  if (label == null) {
    label = _.startCase(_.last(id.split('.')));
  }
  if (req) {
    label += '*';
  }
  return (
    <div data-cy={id} className={classNames('form-group', className)} style={style}>
      {label ? (
        <Text className="d-block" type="label">
          {label}
        </Text>
      ) : null}
      <div className="d-flex">
        {prefix || suffix ? (
          <div className="input-group">
            {prefix ? <span className="input-group-addon">{prefix}</span> : null}
            {children}
            {suffix ? <span className="input-group-addon">{suffix}</span> : null}
          </div>
        ) : (
          children
        )}
        {onRemove && (
          <Icon
            name="close_thick"
            className="pointer pl-1"
            onClick={() => handleRemove(t, id, onRemove)}
            size={16}
          />
        )}
      </div>
    </div>
  );
};

Group.contextTypes = { t: T.object.isRequired };

Group.propTypes = {
  children: T.any.isRequired,
  className: T.string,
  id: T.string.isRequired,
  label: T.any,
  onRemove: T.oneOfType([T.bool, T.func]),
  prefix: T.string,
  req: T.bool,
  style: T.object,
  suffix: T.string,
};

export default Group;

export const onChange = (t, id, value) => {
  t.setState(state => _.set({ ...state, _change: true }, id, value));
};
