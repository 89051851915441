import React from 'react';
import { Task } from '@nanaio/util';
import { Alert } from '@/components';

type Props = {
  task: Task;
};

export default function InfoProvidedNotice({ task }: Props): JSX.Element {
  const vendorFullName = task.customer.org ? task.customer.org.name : 'your home warranty company';

  return (
    <Alert className="mb-10" variant="info" icon="shield_lock_outline">
      Some details were provided by {vendorFullName}. Double check each field to make sure they are
      correct and update if needed.
    </Alert>
  );
}
