/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Table from '@nanaio/table';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Table as TableComponent, Text } from '@/components';
import { automationEmail, liaisonEmail } from '@/config/const';
import { useDeepCompareMemoRef } from '@/hooks';
import { idFromURL } from '@/utils';
import AddNote from './addNote';
import CallAttempted from './callAttempted';
import LinkGmail from './LinkGmail';
import loadFunc from './load';
import SendMessage from './sendMessage';

function Communication({ notesOnly, roles, task, user, workOrder }) {
  const [idToUser, setIdToUser] = useState({});
  const idToUserRef = useDeepCompareMemoRef(idToUser);
  const idToTask = _.keyBy(workOrder.tasks, 'id');
  const [nameToUser, setNameToUser] = useState({});
  const nameToUserRef = useDeepCompareMemoRef(nameToUser);
  const [linkGmailIsOpen, setLinkGmailIsOpen] = useState(false);
  const [tableIsVisible, setTableIsVisible] = useState(true);
  const taskRef = useDeepCompareMemoRef(task);
  const [timeoutId, setTimeoutId] = useState();
  const zendeskIdToUserRef = useRef({});

  useEffect(() => {
    let userIds = [..._.map(task.notes, 'user.id'), workOrder.cx.id];
    for (const task of workOrder.tasks) {
      for (const visit of task.visits) {
        userIds.push(visit.assignee?.user?.id);
      }
    }
    userIds = _.compact(_.uniq(userIds));
    U.api('post', 'users/search', {
      limit: -1,
      projection: { email: 1, id: 1, roles: 1, 'profile.fullName': 1, zendeskId: 1 },
      query: { $or: [{ _id: { $in: userIds } }, { roles: U.user.Role.CUSTOMERSUPPORT }] },
    }).then(users => {
      setIdToUser(_.keyBy(users, 'id'));
      setNameToUser(_.keyBy(users, 'profile.fullName'));
      zendeskIdToUserRef.current = _.keyBy(
        users.filter(user => user.zendeskId),
        'zendeskId'
      );
    });
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProxyPhoneNumbers = () => {
    return _.values(task.metadata.proxy).map(v => {
      const pair = {};
      _.keys(v)
        .filter(k => k.length === 24)
        .forEach(k => {
          pair[k] = { phone: v[k].phone };
        });
      if (pair[workOrder.cx.id]) {
        pair[workOrder.cx.id].name = workOrder.cx.name;
      }
      T.visits(task).forEach(visit => {
        if (pair[_.get(visit, 'assignee.user.id')]) {
          pair[visit.assignee.user.id].name = visit.assignee.user.fullName;
        }
      });
      return _.values(pair);
    });
  };

  const rowPropType = PropTypes.shape({
    _id: PropTypes.string,
    body: PropTypes.string.isRequired,
    deleted: PropTypes.bool,
    getUrl: PropTypes.func,
    shareWithPro: PropTypes.bool,
    taskId: PropTypes.string,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    userId: PropTypes.string,
  });

  const refreshTable = () => {
    setTableIsVisible(false);
    setTimeoutId(setTimeout(() => setTableIsVisible(true), 100));
  };

  const toggleDeleted = async note => {
    await U.api(
      'put',
      `tasks/${task.id}`,
      [{ action: 'replace', path: `notes.${note._id}.deleted`, value: !_.get(note, 'deleted') }],
      ['save']
    );
    refreshTable();
  };

  const toggleVisible = async note => {
    await U.api(
      'put',
      `tasks/${task.id}`,
      [{ path: `notes.${note._id}.shareWithPro`, value: !note.shareWithPro }],
      ['save']
    );
    refreshTable();
  };

  const ActionsCell = ({ row }) =>
    row.type === 'note' && row._id ? (
      <div>
        <Icon
          className="block"
          color="grey.dark"
          name={row.shareWithPro ? 'lock open' : 'lock'}
          onClick={() => toggleVisible(row)}
          size={15}
          tooltip={{ node: row.shareWithPro ? 'Hide from pro' : 'Show to pro' }}
        />
        <Icon
          color="grey.dark"
          name={row.deleted ? 'undo' : 'delete'}
          onClick={() => toggleDeleted(row)}
          size={15}
          tooltip={{ node: row.deleted ? 'Undo deletion' : 'Delete' }}
        />
      </div>
    ) : null;

  ActionsCell.propTypes = { row: rowPropType.isRequired };

  const BodyCell = ({ row }) => (
    <div>
      {row.taskId && row.taskId !== task.id ? (
        <div>
          <a
            className="text-primaryCTA"
            href={`/tasks/${row.taskId}`}
            onClick={event => event.stopPropagation()}
            rel="noreferrer"
            target="_blank"
          >
            REGARDING {(idToTask[row.taskId]?.title || 'different job').toUpperCase()}
          </a>{' '}
          <Text>{row.body}</Text>
        </div>
      ) : (
        <Text>{row.body}</Text>
      )}
      {row.mediaUrl && (
        <a href={row.mediaUrl} target="_blank" rel="noreferrer">
          <img src={row.mediaUrl} alt="" style={{ height: 100 }} />
        </a>
      )}
    </div>
  );

  BodyCell.propTypes = { row: rowPropType.isRequired };

  const getUser = row =>
    idToUserRef.current[row.userId] ||
    zendeskIdToUserRef.current[row.userId] ||
    nameToUserRef.current[row.from];

  const settings = {
    loadFunc: () => {
      return loadFunc({ notesOnly, roles, task: taskRef.current, workOrder });
    },
    renderLeftCell: ({ row }) => (row.type === 'email' || row.url ? <Icon name="launch" /> : null),
    rowUrl: ({ row }) => {
      if (row.type === 'email') {
        const mailbox = user?.metadata?.gmailSupportNumber;
        if (mailbox === undefined || _.isNaN(mailbox)) {
          return setLinkGmailIsOpen(true);
        }
        return row.getUrl(user);
      }
      return row.url;
    },
    uiModuleIdToModule: {
      actions: { cellUi: ({ row }) => <ActionsCell row={row} /> },
      body: { cellUi: ({ row }) => <BodyCell row={row} /> },
      fromRoles: {
        cellValue: ({ row, moduleKeyToId }) => {
          const user = getUser(row);
          if (user) {
            return user.roles;
          }
          const moduleId = moduleKeyToId.fromType;
          if (row.cells[moduleId]?.values[0]?.value === 'cx') {
            return [U.user.Role.USER];
          }
          if (row.cells[moduleId]?.values[0]?.value === 'pro') {
            return [U.user.Role.SERVICEPROVIDER];
          }
          if (['Liaison', 'Nana'].includes(row.from)) {
            return [U.user.Role.CUSTOMERSUPPORT];
          }
        },
      },
      fromType: {
        cellValue: ({ row }) => {
          const user = getUser(row);
          if (user) {
            if (user.email === liaisonEmail) {
              return 'liaison';
            }
            if (user.email === automationEmail) {
              return 'nana';
            }
            if (user.roles.includes(U.user.Role.MT)) {
              return 'mt';
            }
            if (user.roles.includes(U.user.Role.VM)) {
              return 'vm';
            }
            if (user.roles.includes(U.user.Role.CUSTOMERSUPPORT)) {
              return 'agent';
            }
            if (user.roles.includes(U.user.Role.SERVICEPROVIDER)) {
              return 'pro';
            }
          }
          if (
            ['No-reply', task.customer.user.fullName, `+1${workOrder.cx.phone}`].includes(row.from)
          ) {
            return 'cx';
          }
          if (row.from?.includes('Nana')) {
            return 'nana';
          }
          if (_.map(workOrder.pros, 'name').includes(row.from)) {
            return 'pro';
          }
          if (['note', 'ticket'].includes(row.type)) {
            return 'agent';
          }
          if (['call', 'text'].includes(row.type) && row.to === `+1${workOrder.cx.phone}`) {
            return 'pro';
          }
          return 'cx';
        },
      },
    },
  };

  const pairs = getProxyPhoneNumbers();

  return (
    <div>
      <div className="flex p-4">
        <AddNote onSubmit={refreshTable} />
        <CallAttempted />
        {!notesOnly && <SendMessage updateEmails={refreshTable} updateSms={refreshTable} />}
        {/* PROXY NUMBER PAIRS */}
        {!notesOnly && !!pairs.length && (
          <div className="d-flex ml-auto">
            <div className="mr-3">Masked numbers</div>
            {pairs.map(p => (
              <div key={`${p[0].phone} ${p[1].phone}`}>
                <div>
                  {p[0].phone} {p[1].name}
                </div>
                <div>
                  {p[1].phone} {p[0].name}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="relative">
        {tableIsVisible && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TableComponent {...Table.databases.default.communication} {...settings} />
        )}
      </div>
      {linkGmailIsOpen && <LinkGmail onClose={() => setLinkGmailIsOpen(false)} user={user} />}
    </div>
  );
}

Communication.propTypes = {
  notesOnly: PropTypes.bool,
  roles: U.user.rolesPropType.isRequired,
  task: T.propType.isRequired,
  user: U.user.propType.isRequired,
  workOrder: W.propType.isRequired,
};

export default connect(s => {
  const roles = U.user.roles(s);
  const task = s.tasks[idFromURL()];
  const user = s.users[s.me.userId];
  const workOrder = s.workorders[task.metadata.workOrderId];
  return { roles, task, user, workOrder };
})(Communication);
