import { apiOrigin } from '../../../config/const';

export const socialLogin = provider => {
  const address = `${apiOrigin}/auth/${provider}?sReturnUrl=${encodeURI(
    global.location.pathname === '/'
      ? global.location.href.slice(0, -1)
      : global.location.href.replace(global.location.search, '')
  )}`;
  global.location = address;
};
