import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Modal, Search, Text } from '../../../com/ui/form';

export default class EditTag extends React.Component {
  static propTypes = {
    id: T.string,
    isEdit: T.bool,
    isOpen: T.bool,
    onRemove: T.func,
    onSuccess: T.func.isRequired,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  state = {};

  async componentWillReceiveProps(p) {
    if (!this.props.isOpen && p.isOpen) {
      const tag = p.id ? await U.api('get', `tags/${p.id}`) : {};
      this.setState({ loading: false, tag });
    }
  }

  onNameChange = v => {
    this.setState(s => _.set(s, 'tag.name', _.camelCase(v)));
  };

  onRemove = async () => {
    const r = await U.api('delete', `tags/${this.props.id}`);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.onRemove();
  };

  submit = async () => {
    if (!this.state.tag.displayName) {
      return this.setState({ errMsg: 'Display name required' });
    }
    if (!this.state.tag.name) {
      return this.setState({ errMsg: 'Name required' });
    }
    let r;
    if (this.props.id) {
      const changes = _.map(['displayName', 'name', 'roles'], path => ({
        path,
        value: _.get(this.state.tag, path),
      }));
      r = await U.api('put', `tags/${this.props.id}`, changes);
    } else {
      r = await U.api('post', 'tags', this.state.tag);
    }
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.onSuccess(r);
  };

  render() {
    return (
      <Modal
        title={`${this.props.isEdit ? 'Edit' : 'Add'} Tag`}
        isOpen={this.props.isOpen}
        submit={this.submit}
        onClose={this.props.toggleOpen}
        onRemove={this.onRemove}
        className="ui-modal md ui-select-search"
      >
        <p className="text-danger">{this.state.error}</p>
        <Text id="tag.displayName" label="Display Name (ex My Tag)" onChange={this.onNameChange} />
        <Text id="tag.name" label="Name (ex myTag)" />
        <Search id="tag.roles" label="Roles" options={U.user.roleOptions} cap multi />
      </Modal>
    );
  }
}
