import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

class Text extends React.Component {
  state = { timeout: null };

  render() {
    const p = this.props;
    const { t } = this.context;
    const trans = p.trans || (v => v);
    const value = _.get(t.state, p.id, p.def || '') || '';
    // eslint-disable-next-line no-underscore-dangle
    const _onChange = e => {
      onChange(t, p.id, trans(e.target.value));
      if (p.debounce) {
        clearTimeout(this.state.timeout);
        this.setState({
          timeout: setTimeout(
            () => p.debounce(_.get(t.state, p.id, p.def || '')),
            p.debounceLength || 1000
          ),
        });
      }
      if (p.onChange) {
        p.onChange(e.target.value);
      }
    };
    return (
      <Group {...p}>
        <input
          className="form-control"
          id={p.id}
          value={value}
          onBlur={e => p.onBlur && p.onBlur(e.target.value)}
          onChange={_onChange}
          onKeyPress={e => p.enter && (e.keyCode || e.which === 13) && p.enter(e.target.value)}
          placeholder={p.hint || _.get(p.options, '[0]')}
          disabled={p.disabled}
          type={p.secure ? 'password' : 'text'}
          autoFocus={p.autoFocus}
          spellCheck="false"
        />
      </Group>
    );
  }
}

Text.contextTypes = { t: T.object.isRequired };

Text.propTypes = {
  id: T.string.isRequired,
  label: T.string,
  type: T.string,
  hint: T.string,
  enter: T.func,
  disabled: T.bool,
  trans: T.func,
  req: T.bool,
  debounce: T.func,
  debounceLength: T.number,
  secure: T.bool,
  autoFocus: T.bool,
};

export default Text;
