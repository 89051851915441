import classNames from 'classnames';

export const tooltipClasses =
  'bg-grey-dark border-grey-dark rounded border max-w-[300px] fixed p-2 flex flex-column z-[1059] shadow-lg justify-start';

export function getArrowClasses(placement?: string): string {
  const arrowBefore = `before:border-solid before:block before:h-0 before:w-0 before:m-auto before:content-['']`;
  const arrowAfter = `after:border-solid after:block after:h-0 after:w-0 after:m-auto after:content-[''] after:absolute`;
  const arrow = `h-4 w-4 absolute`;

  const bottomBase = 'left-0 top-0 -mt-[0.4rem]';
  const bottomBefore =
    'before:absolute before:-top-[1px] before:border-t-transparent before:border-r-transparent before:border-b-grey-dark before:border-l-transparent before:border-t-0 before:border-r-[0.5rem] before:border-b-[0.4rem] before:border-l-[0.5rem]';
  const bottomAfter =
    'after:border-t-transparent after:border-r-transparent after:border-b-grey-dark after:border-l-transparent after:border-t-0 after:border-r-[0.5rem] after:border-b-[0.4rem] after:border-l-[0.5rem]';
  const bottom = classNames(bottomBase, bottomBefore, bottomAfter);

  const topBase = 'bottom-0 left-0 -mb-[1rem]';
  const topBefore =
    'before:absolute before:top-[1px] before:border-t-grey-dark before:border-r-transparent before:border-b-transparent before:border-l-transparent before:border-t-[0.4rem] before:border-r-[0.5rem] before:border-b-0 before:border-l-[0.5rem]';
  const topAfter =
    'after:border-t-grey-dark after:border-r-transparent after:border-b-transparent after:border-l-transparent after:border-t-[0.4rem] after:border-r-[0.5rem] after:border-b-0 after:border-l-[0.5rem]';
  const top = classNames(topBase, topBefore, topAfter);

  const rightBase = 'left-0 -ml-[0.7rem]';
  const rightBefore =
    'before:border-t-transparent before:border-r-grey-dark before:border-b-transparent before:border-l-transparent before:border-t-[0.5rem] before:border-r-[0.4rem] before:border-b-[0.5rem] before:border-l-0';
  const rightAfter =
    'after:left-[6px] after:top-0 after:border-t-transparent after:border-r-grey-dark after:border-b-transparent after:border-l-transparent after:border-t-[0.5rem] after:border-r-[0.4rem] after:border-b-[0.5rem] after:border-l-0';
  const right = classNames(rightBase, rightBefore, rightAfter);

  const leftBase = 'right-0 -mr-[0.7rem]';
  const leftBefore =
    'before:border-t-transparent before:border-r-transparent before:border-b-transparent before:border-l-grey-dark before:border-t-[0.5rem] before:border-r-0 before:border-b-[0.5rem] before:border-l-[0.4rem]';
  const leftAfter =
    'after:left-[3px] after:top-0 after:border-t-transparent after:border-r-transparent after:border-b-transparent after:border-l-grey-dark after:border-t-[0.5rem] after:border-r-0 after:border-b-[0.5rem] after:border-l-[0.4rem]';
  const left = classNames(leftBase, leftBefore, leftAfter);

  return classNames(arrowBefore, arrowAfter, arrow, {
    [bottom]: placement?.includes('bottom'),
    [top]: placement?.includes('top'),
    [right]: placement?.includes('right'),
    [left]: placement?.includes('left'),
  });
}
