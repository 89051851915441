import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { history } from '../../../com/store';
import { postJob } from '../../../com/task';
import { Bool, Search, Select } from '../../../com/ui/form';
import { loadUsers, postUser, updateUser } from '../../../com/user';
import { getParams } from '../../../com/util';
import Authorization from './authorization';
import CustomerInformation from './customerInformation';
import Draft from './draft';
import Payment from './payment';
import Recall from './recall';
import { frequencies, getErrors, postCard, types } from './util';

const getOption = ({ serviceId, make, model }) => {
  const { brands, services } = global.s();
  const service = services[serviceId];
  if (!service) {
    return;
  }
  const plan = _.get(service, `plans.${service.activePlan}`);
  let optionId = 'standard';
  if (_.get(plan, 'price.servicePrice.options', []).find(o => o.brandType)) {
    optionId = _.get(brands, `${make}.type`, 'standard');
    if (model) {
      optionId = _.get(brands[make], `models.${model}.type`, 'standard');
    }
  }
  const option = _.get(plan, 'price.servicePrice.options', []).find(o => o.id === optionId);
  return { plan, option };
};

// This is used when adding a new workOrder and task from the workOrder table page
class TaskAdd extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    document.title = 'New Work Order';
    this.setState = this.setState.bind(this);
    U.redux.set('minScheduleTime', m().add(1, 'h'));
  }

  state = { localSave: false };

  getChildContext = () => ({ t: this });

  async UNSAFE_componentWillMount() {
    const { email } = getParams();
    if (email) {
      this.getUser(email);
    }
    const ticketId = global.location.pathname.split('/')[3];
    let form = { notify: 'customer' };
    try {
      if (ticketId && ticketId.length === 24) {
        const ticket = await U.api('get', `issues/${ticketId}`);
        if (ticket) {
          form = JSON.parse(ticket.desc);
        }
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    // if this is not cleared the form can't be submitted because it is in an un-ending loading state
    delete form.loading;
    // we don't save CC to job drafts, so it's better to switch this back to No.
    form.paymentMethod = 'No Card';
    if (_.isUndefined(form.skipAvailability)) {
      form.skipAvailability = false;
    }
    this.setState(form);
    // if the draft already has an email it's necessary to load the user
    if (form.email) {
      this.getUser(form.email);
    }
  }

  UNSAFE_componentWillUpdate(p, s) {
    if (p.form.dirty) {
      const error = getErrors(p);
      if (p.form.error !== error) {
        U.redux.set('form.error', error);
      }
    }
    const { timeslot } = this.state;
    if (!_.isEqual(timeslot, s.timeslot)) {
      _.set(`form.timeslot`, s.timeslot);
    }
  }

  getUser = async email => {
    const user = _.values(await loadUsers({ query: { email } }))[0];
    if (user) {
      this.setState(user);
      if (_.get(user, 'payment.stripe.cards', []).length) {
        this.setState({ paymentMethod: 'Existing Card' });
      }
    } else {
      this.setState({ id: '', payment: { stripe: { cards: [] } }, paymentMethod: 'New Card' });
    }
  };

  setReferredBy = value => {
    this.setState(s => _.set(s, 'profile.referredBy', value));
  };

  submit = async () => {
    const { canSchedule, form, serviceOptions, services, user } = this.props;
    const {
      activeProId,
      email,
      id,
      loading: stateLoading,
      make,
      noCardReason,
      notify,
      other,
      paymentMethod,
      profile,
      stripeCardId,
    } = this.state;
    if (stateLoading) {
      return;
    }
    this.setState({ loading: true });
    const service = serviceOptions.find(s => s.id === _.get(this.state, 'serviceCatalogs.0.id'));
    const error = getErrors({
      canSchedule,
      form,
      services,
      state: this.state,
    });
    const loading = !error;
    U.redux.set('form.error', error || '');
    if (error) {
      return this.setState({ dirty: true, error, loading });
    }

    // find existing user or create a new one
    let userId = id;
    if (!userId) {
      const body = { email, profile };
      body.profile.firstName = _.trim(body.profile.firstName);
      body.profile.lastName = _.trim(body.profile.lastName);
      body.profile.fullName = `${body.profile.firstName} ${body.profile.lastName}`;
      body.profile.phone = U.trimPhone(body.profile.phone);

      const r = await U.api('post', 'users', body);
      if (r.errCode || r.errMsg) {
        if (r.errCode === 'u100') {
          const r0 = await U.api('post', 'users/search', { query: { email: body.email } });
          userId = _.get(r0, '0.id');
          if (!userId) {
            return U.redux.set('form.error', 'User not found');
          }
        } else {
          return U.redux.set('form.error', _.get(r, 'errMsg', 'Error'));
        }
      } else {
        userId = r.user._id;
      }
    } else {
      const changes = ['profile.referredBy']
        .filter(p => _.get(user, p) !== _.get(this.state, p))
        .map(p => ({
          action: 'replace',
          path: p,
          value: _.get(this.state, p),
        }));
      if (changes.length) {
        updateUser(userId, changes);
      }
    }

    // payment info
    const payment = {};
    if (paymentMethod === 'New Card') {
      payment.stripeToken = await postCard(userId);
    } else if (paymentMethod === 'Existing Card') {
      payment.stripeCardId = stripeCardId;
    } else {
      payment.noCardReason = noCardReason;
    }

    const out = {
      ...this.state,
      address: profile.address,
      userId,
      payment,
      isSupport: true,
      proId: activeProId,
    };
    if (_.get(out, 'landlord.phone')) {
      out.landlord.phone = out.landlord.phone.replace(/\D/g, '');
    }
    if (notify) {
      out.url = `tasks/?skipNotify=${notify}`;
    }
    if (out.proId) {
      out.url = 'tasks/?skipNotify=all';
    }
    try {
      const { option } = getOption({
        serviceId: service.id,
        make,
        model: other,
      });
      out.priceOptionId = option.id;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    const r = await postJob(out);
    if (!r || r.errMsg) {
      return this.setState({
        error: _.get(r, 'errMsg', 'Error'),
        loading: false,
      });
    }

    // reset state after successful booking
    this.setState({
      duplicateUsers: [],
      email: false,
      metadata: false,
      payment: false,
      profile: false,
      serviceCatalogs: false,
      successTaskId: r.replace('/tasks/', ''),
    });
    try {
      global.card.clear();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    push('/tasks/add');
    const ticketId = global.location.pathname.split('/')[3];
    if (ticketId && ticketId.length === 24) {
      const changes = [{ action: 'replace', path: 'status', value: 'completed' }];
      U.api('put', `issues/${ticketId}`, changes);
    }
    U.redux.set('form', {
      paymentMethod: 'New Card',
      notify: 'customer',
      skipAvailability: false,
    });
    global.scrollTo(0, 0);
    this.setState({ loading: false, error: '', visit: false });
  };

  saveUser = async () => {
    const r = await postUser(this.state);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    U.redux.set('form', _.merge(this.state, r));
  };

  createTestJob = async () => {
    const { services } = this.props;
    const task = {
      customer: { userId: 'TEST' },
      payment: { card: { id: 'TEST', last4: 'TEST' } },
      serviceAddress: {
        county: 'San Francisco',
        geoCoordinates: { lat: 37.79332, lng: -122.39276100000001 },
        locality: 'San Francisco',
        region: 'CA',
        formattedAddress: '1 Mission St, San Francisco, CA 94105, USA',
        streetNumber: '1',
        route: 'Mission Street',
        postalCode: '94105',
      },
      serviceCatalogIds: [_.values(services).find(s => s.name === 'Dishwasher Repair').id],
      status: 'pendingConfirmation',
      visits: [
        {
          availTSlots: [
            {
              startTime: m().startOf('day').hour(16).valueOf(),
              endTime: m().startOf('day').hour(20).valueOf(),
            },
          ],
          preferredTimeSlot: {
            startTime: m().startOf('day').hour(16).valueOf(),
            endTime: m().startOf('day').hour(20).valueOf(),
          },
        },
      ],
    };
    const r = await U.api('post', 'workOrders', { tasks: [task] });
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    // reset state after successful booking
    this.setState({
      duplicateUsers: [],
      email: false,
      metadata: false,
      payment: false,
      profile: false,
      serviceCatalogs: false,
      successTaskId: r.tasks[0].id,
    });
    try {
      global.card.clear();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  render() {
    const { brands, regions, serviceOptions, services, reqUser } = this.props;
    const { error, loading, localSave, successTaskId, serviceCatalogs } = this.state;
    const service = serviceOptions.find(service => service.id === _.get(serviceCatalogs, '0.id'));
    const task = { serviceCatalogs };
    const questions = T.questions({
      brands,
      services,
      task,
      user: reqUser,
      workOrder: { tasks: [task] },
    }).filter(question => types[question.type]);
    return (
      <div id="adminBooking">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {/* Customer Information */}
              <CustomerInformation
                regions={regions}
                services={services}
                state={this.state}
                getUser={this.getUser}
                setState={this.setState}
              />
              {/* Payment */}
              <Payment setState={this.setState} state={this.state} />
              {/* Authorization */}
              <Authorization
                loadDuplicates={this.loadDuplicates}
                setReferredBy={this.setReferredBy}
                setState={this.setState}
                state={this.state}
              />
              {/* Warranty */}
              <Recall setState={this.setState} state={this.state} />
            </div>
            <div className="col-sm-6">
              {/* What Type of Service? */}
              <div className="info">
                <div className="card-head">
                  <h5 className="title flex items-center">
                    <div className="icon flex items-center justify-center">
                      <Icon name="build" size={16} />
                    </div>
                    What type of Service?
                  </h5>
                </div>
                <div className="card-form">
                  <div className="row">
                    <Search
                      id="serviceCatalogs.0.id"
                      label="Service"
                      options={serviceOptions}
                      hint="Service"
                      className="col"
                      sort
                    />
                    {_.get(service, 'name') === 'Cleaning' && (
                      <Select
                        id="frequency"
                        options={frequencies}
                        label="Frequency"
                        className="col"
                      />
                    )}
                    <Bool id="prevMaintenance" className="col" />
                  </div>
                </div>
              </div>
              {/* Job Details */}
              <div className="info">
                <div className="card-head">
                  <h5 className="title flex items-center">
                    <div className="icon flex items-center justify-center">
                      <Icon name="edit" size={16} />
                    </div>
                    Job Details
                  </h5>
                </div>
                <div className="card-form">
                  <div className="row">
                    {questions.map(q => (
                      <div className="col-6" key={q.id}>
                        {React.createElement(types[q.type], q)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className="d-flex">
                <div
                  data-cy="submitRequest"
                  className="common-Button secondary mr-2"
                  onClick={this.submit}
                >
                  {loading ? <Icon name="loading" spin size={42} /> : 'Submit Request'}
                </div>
                <Draft state={this.state} />
                <div className="common-Button ml-auto" onClick={this.createTestJob}>
                  Create Test Job
                </div>
              </div>
              <div style={{ position: 'relative', display: 'block', marginTop: '2em' }}>
                {error && (
                  <div className="alert alert-danger flex items-center">
                    <Icon name="alert_circle" size={16} /> {error}
                  </div>
                )}
                {successTaskId && (
                  <a
                    data-cy="success"
                    href={`/tasks/${successTaskId}`}
                    className="alert alert-success"
                  >
                    Success! Click to view task.
                  </a>
                )}
              </div>
              {localSave && <div className="alert animated fadeInUp">Draft saved!</div>}
              <label>Send notifications to:</label>
              <Select id="notify" hint="All" options={['customer', 'none']} label="" cap />
            </div>
          </div>
        </div>
        <style>{'.form-group .item { padding: .5rem; }'}</style>
      </div>
    );
  }
}

TaskAdd.propTypes = {
  canSchedule: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  regions: PropTypes.objectOf(U.region.propType).isRequired,
  serviceOptions: PropTypes.arrayOf(U.service.propType).isRequired,
  services: PropTypes.objectOf(U.service.propType).isRequired,
  store: PropTypes.shape({}),
  user: U.user.propType.isRequired,
  reqUser: U.user.propType.isRequired,
};

export default connect(s => {
  const warrantyTaskId =
    global.location.search.startsWith('?warranty=') && global.location.search.slice(10);
  if (warrantyTaskId) {
    history.push(global.location.href.split('?')[0]);
  }
  const serviceOptions = _.values(s.services).filter(service =>
    U.service.isLeaf(s.services, service.id)
  );
  const user = s.users[s.form.id];
  const reqUser = s.users[s.me.userId];
  const out = {
    brands: s.brands,
    canSchedule: !!U.user.roles(s).schedule,
    form: s.form,
    regions: s.regions,
    serviceOptions,
    services: s.services,
    store: s,
    user,
    reqUser,
  };
  if (warrantyTaskId) {
    const task = s.tasks[warrantyTaskId];
    if (!task) {
      return out;
    }
    task.client = s.users[task.clientId];
    out.warrantyTask = task;
  }
  return out;
})(TaskAdd);
