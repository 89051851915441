import React, { ReactNode, useMemo } from 'react';
import { T, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import nullthrows from 'nullthrows';
import LandingPageHelpButton from '@/components/pages/user/FTCBookingFlow/LandingPageHelpButton';
import { supportArticleUrls } from '@/config/const';
import type { Step } from '@/hooks/api/workOrderFtcBF';
import ConfirmationStep from './ConfirmationStep'; // eslint-disable-line import/no-cycle
import LandingPageLists from './LandingPageLists';
import WelcomeStep from './WelcomeStep'; // eslint-disable-line import/no-cycle

type Props = {
  steps: ReadonlyArray<Step>;
  deadline: moment.Moment;
  onLeave: () => void;
  onStepClick: (index: number) => void;
  workOrder: WorkOrder;
};

export type LandingStepProps = Props & {
  helpButton: ReactNode;
  lists: ReactNode;
};

export default function LandingPage({
  steps,
  deadline,
  onLeave,
  onStepClick,
  workOrder,
}: Props): JSX.Element {
  const appliances = useMemo(() => workOrder.tasks.map(T.appliance), [workOrder]);
  const vendorFullName = workOrder.org ? workOrder.org.name : 'your home warranty company';

  const items = useMemo(() => {
    let nextActive = true;
    return steps.map((step, index) => {
      let title;
      if (step.name === 'appliance') {
        title = `Verify ${appliances[index]} Details`;
      } else if (step.name === 'appointment') {
        title = 'Request an Appointment';
      } else if (step.name === 'profile') {
        title = 'Confirm Your Profile';
      } else if (step.name === 'terms') {
        title = 'Review and Accept Terms';
      }
      const checked = step.completed === step.total;
      const subtext = checked ? 'Completed' : `${step.completed} of ${step.total} done`;
      const disabled = !nextActive;
      nextActive = checked;
      return {
        checked,
        disabled,
        subtext,
        title: nullthrows(title, 'Unknown step type'),
      };
    });
  }, [steps, appliances]);

  const allStepsCompleted = useMemo(() => {
    return steps.every(step => {
      return step.completed === step.total;
    });
  }, [steps]);

  const handleHelpCenterClick = () => {
    window.open(supportArticleUrls.preScreenHelp, '_blank')?.focus();
  };

  const helpButton = <LandingPageHelpButton {...{ handleHelpCenterClick }} />;
  const lists = (
    <LandingPageLists {...{ deadline, onStepClick, items, vendorFullName, workOrder }} />
  );

  const Component = allStepsCompleted ? ConfirmationStep : WelcomeStep;

  return <Component {...{ steps, deadline, onLeave, onStepClick, workOrder, helpButton, lists }} />;
}
