import { activePro, oaklandCustomer } from './users';

export const workOrderWithPro = {
  tags: [],
  _id: '605bd03da2368f0060fd38da',
  payment: { card: { id: 'TEST', last4: 'TEST' }, paymentGateway: 'stripe' },
  createTime: '2021-03-24T23:50:21.932Z',
  status: 'pendingConfirmation',
  salesTaxRate: { rate: 0.09, time: 1616629821934 },
  tasks: [
    {
      customer: {
        user: {
          confirmedContacts: [],
          email: oaklandCustomer.email,
          firstName: oaklandCustomer.profile.firstName,
          fullName: oaklandCustomer.profile.fullName,
          id: oaklandCustomer.id,
          lastName: oaklandCustomer.profile.lastName,
          phone: oaklandCustomer.profile.phone,
        },
      },
      status: 'pendingConfirmation',
      tags: [],
      visits: [
        {
          availTSlots: [{ startTime: 1616626800000, endTime: 1616641200000 }],
          preferredTimeSlot: { startTime: 1616626800000, endTime: 1616641200000 },
          confirmed: true,
          createdBy: { id: '602ac2a210c5960014fe3ac3', name: 'Admin Repair Platform' },
          createTime: '2021-03-24T23:50:21.961Z',
          status: 'pendingConfirmation',
          type: 'first',
          id: 0,
          techAvailability: {
            [activePro.user.id]: {
              availTSlots: [],
              name: activePro.user.fullName,
              lastUpdate: 1616630015030,
            },
          },
          role: 'primary',
          assignee: {
            id: activePro.id,
            user: {
              altPhone: '',
              confirmedContacts: [],
              email: activePro.user.email,
              altEmail: '',
              firstName: activePro.user.firstName,
              fullName: activePro.user.fullName,
              id: activePro.user.id,
              lastName: activePro.user.lastName,
              pictureUrl: null,
              phone: activePro.user.phone,
            },
          },
          assignedBy: { id: '602ac2a210c5960014fe3ac3', name: 'Admin Repair Platform' },
          assignedTimeslotId: '605bd0fffd5dcd00bebda6ce',
        },
      ],
      _id: '605bd03da2368f0060fd38db',
      payment: { card: { id: 'TEST', last4: 'TEST' }, paymentGateway: 'stripe' },
      serviceAddress: oaklandCustomer.profile.address,
      createTime: '2021-03-24T23:50:21.919Z',
      metadata: {
        workOrderId: '605bd03da2368f0060fd38da',
        notesForCustomer: '',
        salesTaxRate: { rate: 0.09, time: 1616629822008 },
      },
      creator: {
        id: '602ac2a210c5960014fe3ac3',
        email: 'admin@repairPlatform.com',
        fullName: 'Admin Repair Platform',
        firstName: 'Admin',
      },
      serviceCatalogs: [
        {
          _id: '602ac2a110c5960014fe3a58',
          name: 'Dishwasher Repair',
          id: '602ac2a110c5960014fe3a58',
        },
      ],
      title: 'Dishwasher Repair',
      pricingPackageId: '602ac2a410c5960014fe3b0b',
      lastModified: '2021-03-24T23:50:21.997Z',
      shortId: 'SL38DB',
      recall: {},
      lastPro: 'Active Pro',
      lastVisit: {
        availTSlots: [{ startTime: 1616626800000, endTime: 1616641200000 }],
        preferredTimeSlot: { startTime: 1616626800000, endTime: 1616641200000 },
        confirmed: true,
        createdBy: { id: '602ac2a210c5960014fe3ac3', name: 'Admin Repair Platform' },
        createTime: '2021-03-24T23:50:21.961Z',
        status: 'pendingConfirmation',
        type: 'first',
        id: 0,
        techAvailability: {
          [activePro.user.id]: {
            availTSlots: [],
            name: activePro.user.fullName,
            lastUpdate: 1616630015030,
          },
        },
        role: 'primary',
        assignee: {
          id: activePro.id,
          user: {
            altPhone: '',
            confirmedContacts: [],
            email: activePro.user.email,
            altEmail: '',
            firstName: activePro.user.firstName,
            fullName: activePro.user.fullName,
            id: activePro.user.id,
            lastName: activePro.user.lastName,
            pictureUrl: null,
            phone: activePro.user.phone,
          },
        },
        assignedBy: { id: '602ac2a210c5960014fe3ac3', name: 'Admin Repair Platform' },
        assignedTimeslotId: '605bd0fffd5dcd00bebda6ce',
      },
      lastVisitEnd: 1616641200000,
      lastVisitStart: 1616626800000,
      userNameAbbr: 'User Na.',
      id: '605bd03da2368f0060fd38db',
    },
  ],
  ignoredPayFlags: [],
  jobs: [
    {
      tags: [],
      id: '605bd03da2368f0060fd38db',
      service: { id: '602ac2a110c5960014fe3a58', name: 'Dishwasher Repair' },
      status: 'pendingConfirmation',
    },
  ],
  promos: [],
  pros: [
    {
      address: activePro.user.address,
      email: activePro.user.email,
      firstName: activePro.user.firstName,
      id: activePro.id,
      name: activePro.user.fullName,
      phone: activePro.user.phone,
    },
  ],
  visits: [
    {
      cx: {
        availability: [{ end: '2021-03-25T03:00:00.000Z', start: '2021-03-24T23:00:00.000Z' }],
        status: 'confirmed',
      },
      pros: [
        {
          status: 'pending',
          id: activePro.id,
          availability: [],
          tasks: [{ id: '605bd03da2368f0060fd38db', role: 'primary' }],
        },
      ],
      slot: { end: '2021-03-25T03:00:00.000Z', start: '2021-03-24T23:00:00.000Z' },
      createTime: '2021-03-24T23:50:21.000Z',
      id: 0,
    },
  ],
  __v: 4,
  shortId: 'SL38DA',
  cx: {
    address: oaklandCustomer.profile.address,
    email: oaklandCustomer.email,
    firstName: oaklandCustomer.profile.firstName,
    id: oaklandCustomer.id,
    name: oaklandCustomer.profile.fullName,
    phone: oaklandCustomer.profile.phone,
  },
  org: {},
  id: '605bd03da2368f0060fd38da',
  creator: {
    id: '602ac2a210c5960014fe3ac3',
    email: 'admin@repairPlatform.com',
    fullName: 'Admin Repair Platform',
    firstName: 'Admin',
  },
  customer: {
    user: {
      confirmedContacts: [],
      email: oaklandCustomer.email,
      firstName: oaklandCustomer.profile.firstName,
      fullName: oaklandCustomer.profile.fullName,
      id: oaklandCustomer.id,
      lastName: oaklandCustomer.profile.lastName,
      phone: oaklandCustomer.profile.phone,
    },
  },
  serviceAddress: oaklandCustomer.profile.address,
};
