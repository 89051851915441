import '../../style/admin-modal.css';

import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Button, Icon, Text } from '@/components';
import { Text as TextInput } from '../../com/ui/form';
import PartSearchData from './PartSearchData';

export default class PartSearch extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(props) {
    super(props);
    this.togglePartSearch = this.props.togglePartSearch;
    this.updatePartFromSearchResult = this.props.updatePartFromSearchResult;
    this.state = {
      loading: true,
      part: this.props.part,
      partNumber: this.props.partNumber,
      partSearchResult: {
        totalResults: 0,
        exactMatches: [],
        allMatches: [],
      },
      selectedIndex: 0,
    };
    this.getPartSearchResults();
  }

  getPartSearchResults = async () => {
    const searchUrl = `parts/products/search?q=${this.state.partNumber}&doMarconePartSearch=1`;
    const partSearchResult = await U.api('post', searchUrl);
    this.setState({
      partSearchResult,
      loading: false,
    });
  };

  updatePart = async () => {
    const selectedPartSearchResult =
      this.state.partSearchResult.exactMatches[this.state.selectedIndex];
    const selectedVendorLocation = this.state[`vendorLocation-${this.state.selectedIndex}`]
      ? this.state[`vendorLocation-${this.state.selectedIndex}`].split(':')[1]
      : '';
    this.updatePartFromSearchResult(selectedPartSearchResult, selectedVendorLocation);
  };

  updateSelectedIndex = async selectedIndex => {
    return this.setState({ selectedIndex });
  };

  render() {
    const exactMatches = _.get(this.state.partSearchResult, 'exactMatches', []);
    const partNumber = _.get(this.state.part, 'partNumber', '');
    let googlePartSearchQuery = partNumber;

    if (!_.isEmpty(exactMatches)) {
      const selectedIndex = this.state.selectedIndex || 0;
      const externalPartSearchResult = exactMatches[selectedIndex];
      const mfgCode = _.get(externalPartSearchResult, 'mfgCode', '');
      const partDesc = `${mfgCode} ${_.get(externalPartSearchResult, 'desc', '')}`;
      googlePartSearchQuery += ` ${partDesc}`;
    }

    return (
      <div className="container" style={{ maxWidth: '750px', margin: '0px auto' }}>
        <div className="admin_input-search mb-3">
          <div className="admin_input-search-icon pl-2">
            <Icon name="search" size={16} color="#C7C7C7" />
          </div>
          <TextInput
            id="partNumber"
            label=""
            hint="Part Number"
            debounce={this.getPartSearchResults}
          />
          <button type="button" className="input-search-button" onClick={this.getPartSearchResults}>
            Search
          </button>
        </div>

        {this.state.loading && (
          <div className="w-25 mx-auto my-auto">
            <Icon name="loading" spin size={42} />
          </div>
        )}

        {!_.isEmpty(exactMatches) && (
          <div className="py-2">
            {exactMatches.map((exactMatch, index) => {
              return (
                <PartSearchData
                  key={`${exactMatch.partNumber}_${exactMatch.externalApi}`}
                  partData={exactMatch}
                  index={index}
                  isSelected={this.state.selectedIndex === index}
                  updateSelectedIndex={this.updateSelectedIndex}
                />
              );
            })}

            <div className="d-flex justify-content-between my-8">
              <div className="d-inline-flex align-items-center animated fadeIn">
                <p className="mb-0 mr-1">
                  Need more results?
                  <a
                    href={`https://google.com/search?q=${encodeURIComponent(
                      googlePartSearchQuery
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-link text-decoration-none"
                  >
                    &nbsp;Search on Google
                  </a>
                </p>
                <Icon name="open_in_new" size={16} color="#0070c9" />
              </div>

              <Button onClick={this.updatePart}>Select part</Button>
            </div>
          </div>
        )}

        {/*= ===========Empty State: No Matches================ */}
        {_.isEmpty(exactMatches) && !this.state.loading && (
          <div id="AdminFont" className="animated fadeIn px-3 py-5 text-center">
            <img
              alt="no matches"
              className="mx-auto"
              src="/img/empty_state.png"
              style={{ height: 180 }}
            />
            <br />
            <br />
            <Text>No results found for &apos;{_.get(this.state, 'part.partNumber')}&apos;</Text>
            <Text color="grey.dark">
              Try adjusting your search to find what you&apos;re looking for.
            </Text>
            <div className="d-inline-flex align-items-center animated fadeIn">
              <Text>
                Need more results?
                <a
                  href={`https://google.com/search?q=${encodeURIComponent(googlePartSearchQuery)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-link text-decoration-none"
                >
                  &nbsp;Search on Google
                </a>
              </Text>
              <Icon name="open_in_new" size={16} color="#0070c9" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
