import { datadogRum } from '@datadog/browser-rum';
import { U } from '@nanaio/util';
import _ from 'lodash';
import { datadog as datadogConfig } from '@/config/const';

const { Logger } = U.analytics;

class DataDogRumLogger extends Logger {
  identify(id: string | undefined, traits: Record<string, unknown>): void {
    if (id) {
      datadogRum.setUser({ id, ...traits });
    }
  }

  reset() {
    datadogRum.setUser({});
  }

  navigate() {}

  start() {
    const { applicationId, clientToken } = datadogConfig;
    if (applicationId && clientToken) {
      datadogRum.init({
        // See https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
        applicationId: applicationId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: 'web',
        allowedTracingOrigins: ['https://nanahome.com', /https:\/\/.*\.nanahome\.com/],
        version: process.env.REACT_APP_GITHUB_SHA,
        env: process.env.NODE_ENV || 'development',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
  }

  track() {}
}

// @ts-expect-error TODO use mappable type instead of record in order to support optional keys
export default new DataDogRumLogger({});
