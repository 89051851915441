import React from 'react';
import Footer from '../../../com/ui/footer';
import AboutNav from './aboutNav';

export default function Jobs(): JSX.Element {
  return (
    <div>
      <AboutNav />
      <div id="grnhse_app" />
      <Footer />
    </div>
  );
}
