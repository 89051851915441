import React from 'react';
import _ from 'lodash';
import { Check, Text } from '@/components';
import { companyName } from '@/config/const';
import { BookingState } from '../util';

type Props = {
  error?: string;
  onChange: (key: string, value: unknown) => void;
  state: BookingState;
};

export default function TermsCheckbox({ state, onChange, error }: Props): JSX.Element {
  return (
    <div className="my-6">
      <div className="flex">
        <Check
          onChange={event => {
            onChange('terms', event);
          }}
          value={state.terms}
        />

        <Text color="secondary">
          I have read and agree to the {companyName}{' '}
          <Text
            className="underline hover:no-underline"
            weight={600}
            tag="a"
            href="/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Text>
          ,{' '}
          <Text
            className="underline hover:no-underline"
            weight={600}
            tag="a"
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Text>
          , and{' '}
          <Text
            className="underline hover:no-underline"
            weight={600}
            tag="a"
            href="/customer-release"
            target="_blank"
            rel="noopener noreferrer"
          >
            Waiver Release
          </Text>
          .
        </Text>
      </div>
      {!!error && (
        <Text className="mt-1" color="danger" type="helper">
          {error}
        </Text>
      )}
    </div>
  );
}
