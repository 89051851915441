import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Service, T, U } from '@nanaio/util';
import _ from 'lodash';
import { loggedOutEvent } from '@/com/analytics';
import { useLegacySelector } from '@/hooks';
import { getBrand } from '../../com/ui/path';
import StepsV2 from './steps-V2';
import { APPLIANCES } from './steps-V2/Service-V2';
import { BookingState, Brand } from './steps-V2/util';

const getZip = () => {
  const parts = global.location.pathname.split('/').slice(1).reverse();
  const zip = parts.find(p => /^\d{5}$/.test(p));
  return zip;
};

const getServiceId = (services: Service[]) => {
  const serviceIds = _.map(services, 'id');
  const parts = global.location.pathname.split('/').slice(1).reverse();
  const serviceId = parts.find(p => serviceIds.includes(p));
  return serviceId;
};

export default function Book(): JSX.Element {
  const { services } = useLegacySelector(state => {
    const serviceNameMap = _.keyBy(state.services, 'name');
    const applianceNames = _.keys(APPLIANCES).map(k => `${k} Repair`);
    const services = _.values(_.pick(serviceNameMap, applianceNames));
    return { services };
  });

  const getInitialState = (): BookingState => {
    // use default 4 hour buffer
    const minScheduleTime = T.minScheduleTime();
    const minStartDate = T.minScheduleDate(minScheduleTime);
    const serviceId = getServiceId(services);
    const zip = getZip();
    const brand = getBrand() as Brand | undefined;
    const serviceName = services.find(service => service.id === serviceId)?.name;
    const data = { service_name: serviceName, zip };

    let step = 0;

    if (serviceId && brand && zip) {
      step = 1;
      loggedOutEvent().track('v1_b2cBookingFlowStepCompleted', {
        stepName: 'Service',
        ...data,
      });
      loggedOutEvent().track('v1_b2cBookingFlowStepCompleted', {
        stepName: 'Zip',
        ...data,
      });
    }

    return {
      availTSlots: {},
      metadata: { taskQuestions: { Make: brand } },
      minScheduleTime,
      payment: {},
      serviceId,
      services,
      startDate: minStartDate,
      step,
      zip: { value: zip },
      useShippingForBilling: true,
    };
  };

  const initialState = useRef(getInitialState());
  const [state, setState] = useState(initialState.current);

  useEffect(() => {
    const { minScheduleTime } = initialState.current;
    U.redux.set('minScheduleTime', minScheduleTime);
  }, []);

  const onChange = useCallback((id: string, value: unknown) => {
    setState(state => _.set({ ...state }, id, value));
  }, []);

  return <StepsV2 state={state} onChange={onChange} />;
}
