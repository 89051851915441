import React, { createElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { useDeepCompareEffect } from '@nanaio/hooks';
import { Org, T, Task, U, WorkOrder } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import { Link } from '@/com/ui/link';
import { Loader, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import Bill from '../../bill';
import Details from '../../details';
import Log from '../../log';
import Notes from '../../notes';
import Parts from '../../parts';
import ProPay from '../../proPay';
import BonusAlerts from './BonusAlerts';
import Header1 from './Header1';
import Header2 from './Header2';
import Sidebar from './Sidebar';
import TwoHourTimeSlotAlert from './TwoHourTimeSlotAlert';
import UnifiedBookingFlowAlert from './UnifiedBookingFlowAlert';
import WorkOrderHeader from './WorkOrderHeader';

type Query = {
  $or: Record<string, string>[];
  _id: {
    $ne: string;
  };
};

export default function TaskLayout(): JSX.Element {
  const dispatch = useDispatch();
  const router = useLegacySelector(state => state.router);
  const path = (router.location?.pathname || '').replace(/\/$/, '').toLowerCase();

  const tasks = useLegacySelector(state => state.tasks);
  const taskId = path.includes('tasks') ? global.id() || '' : '';
  const task = taskId ? tasks[taskId] : undefined;

  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = task ? workOrders[task.metadata.workOrderId] : undefined;

  const orgs = useLegacySelector(state => state.orgs);
  const orgId = task?.customer.org?.id;
  const org = orgId ? orgs[orgId] : undefined;

  const estimateCount = _.values(_.get(workOrder, 'estimate.chargeItems')).length;
  const invoiceCount = _.values(_.get(workOrder, 'invoice.chargeItems')).length;
  const partsCount = _.sumBy(T.parts(task), 'unit.value') || 0;
  const propayCount = _.values(_.get(workOrder, 'invoice.proPay.pros')).length;
  const noteCount = _.values(_.get(task, 'notes')).length;
  const pages = [
    {
      path: `/tasks/${taskId}/details`,
      com: () => <Details taskId={taskId} />,
      show: true,
      text: 'Details',
      isDefault: true,
    },
    {
      path: `/tasks/${taskId}/invoice`,
      com: () => <Bill taskId={taskId} type="invoice" />,
      show: !!_.get(workOrder, 'estimate') || !!_.get(workOrder, 'invoice'),
      text: invoiceCount ? `Invoice (${invoiceCount})` : 'Invoice',
    },
    {
      path: `/tasks/${taskId}/parts`,
      com: Parts,
      show: true,
      text: partsCount ? `Parts (${partsCount})` : 'Parts',
    },
    {
      path: `/tasks/${taskId}/notes`,
      com: () => <Notes taskId={taskId} />,
      show: true,
      text: noteCount ? `Notes (${noteCount})` : 'Notes',
    },
    {
      path: `/tasks/${taskId}/estimate`,
      com: () => <Bill taskId={taskId} type="estimate" />,
      show: true,
      text: estimateCount ? `Estimate (${estimateCount})` : 'Estimate',
    },
    {
      path: `/tasks/${taskId}/propay`,
      com: () => <ProPay taskId={taskId} />,
      show: roles.propay,
      text: propayCount ? `Propay (${propayCount})` : 'Propay',
    },
    {
      path: `/tasks/${taskId}/log`,
      com: () => <Log taskId={taskId} />,
      show: !!roles.customersupport,
      text: 'Log',
    },
  ]
    .filter(page => page.show)
    .map(page => ({ ...page, active: page.path === path }));
  if (!pages.find(p => p.active)) {
    const defaultPage = pages.find(p => p.isDefault);
    if (defaultPage) {
      defaultPage.active = true;
    }
  }

  document.title = task
    ? `${task.customer.user.fullName.slice(0, 15)} - ${T.appliance(task)}`
    : 'Loading Task...';

  // Use effect that watches for taskId to change and reloads tasks.  Also watches for shortId in url
  useEffect(() => {
    if (taskId) {
      void U.api<Task>('get', `tasks/${taskId}`, ['save']);
    } else {
      // load task by short id
      const shortId = global.location.pathname
        .split('/')
        .find(v => v.match(/^[a-zA-Z0-9]{6}$/))
        ?.toUpperCase();
      if (shortId) {
        void U.api<Task[]>('post', 'tasks/search', { query: { shortId } }, ['save']).then(tasks => {
          if (!('errMsg' in tasks) && tasks[0]) {
            dispatch(push(`/tasks/${tasks[0].id}`));
          }
        });
      }
    }
  }, [dispatch, taskId]);

  // Use effect that watches for taskId to change and reloads issues
  useEffect(() => {
    if (taskId) {
      void U.api('post', 'issues/search', { query: { refId: taskId } }, ['save']);
    }
  }, [taskId]);

  // Use Effect that watches for task or taskId to change and reloads tasks and workorders
  useDeepCompareEffect(() => {
    if (task && !workOrder) {
      const query: Query = {
        $or: [{ 'cx.id': task.customer.user.id }],
        _id: { $ne: task.metadata.workOrderId },
      };
      if (task.customer.user.phone) {
        query.$or.push({ 'cx.phone': task.customer.user.phone });
      }
      void U.api<WorkOrder[]>('post', 'workOrders/search', { query }, ['save']);
      if (task && U.length(task, 'metadata.workOrderId') === 24) {
        void U.api('get', `workorders/${task.metadata.workOrderId}`, ['save']);
      }
    }
  }, [task, taskId, workOrder]);

  // Use Effect that watches for orgId to change and reloads org
  useEffect(() => {
    if (orgId && !org) {
      void U.api<Org>('get', `orgs/${orgId}`, ['save']);
    }
  }, [orgId, org]);

  if (!roles.customersupport) {
    return <></>;
  }
  if (!taskId || !task || !workOrder) {
    return <Loader isLoading fullscreen />;
  }

  const activePage = pages.find(page => page.active);

  return (
    <div id="AdminTaskDetails" className="h-full bg-[#E6EBF1]">
      <WorkOrderHeader taskId={taskId} />
      <Header1 taskId={taskId} />
      <Header2 taskId={taskId} />
      <section>
        <div className="flex w-full flex-row">
          <div className="hidden w-1/4 flex-col md:flex">
            <Sidebar taskId={taskId} />
          </div>
          <div className="flex w-full flex-col p-0 md:w-3/4 md:p-4">
            <UnifiedBookingFlowAlert />

            <TwoHourTimeSlotAlert />

            <BonusAlerts />

            <div className="no-list flex flex-row">
              {pages.map(({ path, text, active }) => (
                <Link
                  to={path}
                  key={path}
                  className={classNames(
                    'group z-[1] flex w-full cursor-pointer items-center justify-center rounded-t border border-b-0 py-2 transition-all hover:underline',
                    {
                      'border-grey-medium bg-white text-font-dark': active,
                    },
                    { 'border-transparent text-grey-dark': !active }
                  )}
                >
                  <Text
                    className={classNames(
                      { 'text-font-dark': active },
                      { 'text-grey-dark group-hover:text-font-dark': !active }
                    )}
                    type="button"
                  >
                    {text}
                  </Text>
                </Link>
              ))}
            </div>
            <div className="rounded-b-lg bg-white p-0 shadow-floating">
              {activePage && createElement(activePage.com)}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
