import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Date, Modal, Money, Percent, Search, Select, Text } from '../../../com/ui/form';

const start = {
  modal: false,
  name: undefined,
  code: undefined,
  description: undefined,
  status: undefined,
  startTime: undefined,
  endTime: undefined,
  type: undefined,
  rate: undefined,
  chargeTypes: [],
  error: '',
};

const chargeTypes = ['labor', 'parts', { name: 'diagnosis', id: 'serviceCall' }];

class AddPromo extends React.Component {
  static propTypes = {
    isOpen: T.bool,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = start;
  }

  submit = async () => {
    const error = error => this.setState({ error });
    if (!this.state.name) {
      return error('name requred');
    }
    if (!this.state.code) {
      return error('code required');
    }
    if (!this.state.description) {
      return error('description required');
    }
    if (!this.state.status) {
      return error('status required');
    }
    if (!this.state.type) {
      return error('type required');
    }
    if (!this.state.rate) {
      return error('rate required');
    }

    const req = _.pick(this.state, [
      'name',
      'code',
      'description',
      'status',
      'startTime',
      'endTime',
      'type',
      'rate',
      'chargeTypes',
    ]);
    const r = await U.api('post', 'promos', req, true);
    if (!r || r.errMsg) {
      return this.setState({ error: _.get(r, 'errMsg') });
    }
    this.props.toggleOpen();
    this.setState(start);
  };

  render() {
    return (
      <div>
        <Modal
          title="Add Promo"
          submit={this.submit}
          isOpen={this.props.isOpen}
          onClose={this.props.toggleOpen}
        >
          <p className="text-danger">{this.state.error}</p>
          <div className="row">
            <Text id="name" className="col-sm-6" req />
            <Text id="code" className="col-sm-6" req />
          </div>
          <div className="row">
            <Text id="description" className="col-sm-12" req />
          </div>
          <div className="row">
            <Select id="status" options={['active', 'inactive']} cap req className="col-sm-6" />
          </div>
          <div className="row">
            <Date id="startTime" className="col-sm-6" />
            <Date id="endTime" className="col-sm-6" />
          </div>
          <div className="row">
            <Select id="type" options={['flat', 'percent']} cap req className="col-sm-6" />
            {this.state.type === 'flat' && <Money id="rate" req className="col-sm-6" />}
            {this.state.type === 'percent' && <Percent id="rate" req className="col-sm-6" />}
          </div>
          <div className="row">
            <Search
              id="chargeTypes"
              hint="Only apply promo to certain charge types..."
              className="col-sm-12"
              label="Charge types"
              multi
              options={chargeTypes}
            />
          </div>
        </Modal>
        <style>{'.modal-dialog { max-width: 600px !important; }'}</style>
      </div>
    );
  }
}

export default connect(s => {
  const services = _.values(s.services);
  return { services };
})(AddPromo);
