import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Avatar, Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import Link from '../../com/ui/link';
import {
  academyName,
  academyUrl,
  companyLogoAndName,
  companyName,
  supportPhoneNumber,
  supportUrl,
} from '../../config/const';

const navLinks = [
  {
    name: 'nav-book',
    title: 'Book a Repair',
    link: '/',
  },
  {
    name: 'nav-becomePro',
    title: 'Join as a Pro',
    link: '/apply',
  },
  {
    name: 'nav-academy',
    title: academyName,
    link: academyUrl,
  },
  {
    name: 'nav-support',
    title: 'Support',
    link: supportUrl,
  },
  {
    name: 'nav-about',
    title: 'About',
    link: '/about',
  },
  {
    name: 'nav-tel',
    title: supportPhoneNumber,
    link: `tel:${supportPhoneNumber}`,
  },
];

function MobileMenu() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section className="relative">
      <Icon
        className="pointer hover:text-primaryCTA-hover"
        name="menu"
        size={25}
        color="#000"
        onClick={toggleMenu}
      />
      {isVisible && (
        <div className="fixed left-0 top-0 z-[1200] h-full w-full overflow-hidden bg-white">
          <div className="flex h-[66px] justify-end border-b border-grey-medium p-4">
            <div className="flex items-center justify-between">
              <Link to="/login" name="nav-login" onClick={toggleMenu} className="mr-2 no-underline">
                <Text color="primaryCTA hover:text-primaryCTA-hover" className="mr-4">
                  Customer Login
                </Text>
              </Link>
              <Icon
                className="pointer hover:text-primaryCTA-hover"
                name="close"
                size={25}
                color="#000"
                onClick={toggleMenu}
              />
            </div>
          </div>
          {navLinks.map(item => (
            <Link
              name={item.name}
              to={item.link}
              className="pointer group flex h-[70px] flex-row items-center p-4 no-underline transition-all duration-200 ease-in-out hover:bg-background-lightest"
              onClick={toggleMenu}
              key={item.title}
            >
              <Text className="flex-1 group-hover:text-primaryCTA-hover">{item.title}</Text>
              <Icon
                name="chevron_right"
                size={25}
                color="grey.dark"
                className=" group-hover:text-primaryCTA-hover"
              />
            </Link>
          ))}
        </div>
      )}
    </section>
  );
}

export default function NanaNav(): JSX.Element {
  const me = useLegacySelector(state => state.me);
  const users = useLegacySelector(state => state.users);
  const roles = _.keyBy(me.roles);
  const loggedIn = !_.isEmpty(roles);
  const user = users[me.userId];
  const userName = loggedIn ? user.profile.fullName : '';

  const [isTop, setIsTop] = useState(true);

  document.addEventListener('scroll', () => {
    const isTopNew = window.scrollY < 50;
    if (isTop !== isTopNew) {
      setIsTop(isTopNew);
    }
  });

  const isSelected = (name: string) => {
    if (window.location.pathname === '/' && name === 'nav-book') {
      return true;
    }
    if (window.location.pathname === '/apply' && name === 'nav-becomePro') {
      return true;
    }
    if (
      (window.location.pathname === '/about' ||
        window.location.pathname === '/history' ||
        window.location.pathname === '/careers') &&
      name === 'nav-about'
    ) {
      return true;
    }

    return false;
  };

  return (
    <section
      className={classNames('fixed left-0 top-0 z-[999] w-full bg-white', {
        'shadow-elevation': !isTop,
      })}
    >
      <div className="relative mx-auto flex h-[66px] w-full max-w-[1240px] flex-row items-center bg-white px-4 py-1">
        <Link to="/" name="nav-home" className="py-2 pr-4">
          <img src={companyLogoAndName} height={32} alt={companyName} width={100} />
        </Link>

        <div className="flex flex-row items-center justify-between space-x-4">
          {navLinks.map(link => (
            <Link
              name={link.name}
              to={link.link}
              className={classNames('pointer hidden items-center p-4 no-underline lg:inline-flex', {
                'mb-0.5 mt-7 flex-col': isSelected(link.name),
              })}
              key={link.name}
            >
              <Text
                className={classNames('hover:text-primaryCTA-hover', {
                  'text-primaryCTA': link.name === 'nav-tel',
                  'text-primary': isSelected(link.name),
                })}
              >
                {link.title}
              </Text>
              {isSelected(link.name) && <div className="mt-6 h-0.5 w-full bg-primary" />}
            </Link>
          ))}
        </div>

        {!loggedIn && (
          <Link
            name="nav-login"
            to="/login"
            className="ml-auto mr-2 whitespace-nowrap no-underline"
          >
            <Text className="mr-4 text-primaryCTA hover:text-primaryCTA-hover">Customer Login</Text>
          </Link>
        )}
        {loggedIn && userName && (
          <Link name="nav-login" to="/login" className=" ml-auto mr-2 flex">
            <div className="pointer inline-flex items-center hover:opacity-80">
              <Avatar name={userName} size={38} variant="circle" />
            </div>
          </Link>
        )}

        <div className="flex lg:hidden">
          <MobileMenu />
        </div>
      </div>
    </section>
  );
}
