import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Icon } from '@/components';
import { copyText } from '../../com/util';
import { openLink } from '../../utils';
import Charge from './Charge';
import EditItem from './EditItem';
import Email from './Email';
import Refund from './Refund';

const color = { bold: '#2E3B48' };

class Items extends React.Component {
  state = {};

  onDelete = () => {
    const { type, workOrder } = this.props;
    U.api('delete', `workOrders/${workOrder.id}/${type}`, undefined, true);
  };

  onDownload = async () => {
    const { type, user, workOrder } = this.props;
    const { pdfDownloading } = this.state;
    if (pdfDownloading) {
      return;
    }
    this.setState({ pdfDownloading: true });
    const pdfResp = await U.api('get', `workorders/${workOrder.id}/${type}/pdf/${user}`);
    if (_.get(pdfResp, 'errMsg')) {
      return;
    }
    const { filename } = pdfResp;

    // thanks for blob decoding instructions https://stackoverflow.com/a/45872086/331994
    // base64 string
    const base64str = pdfResp.blob;

    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    this.setState({ pdfDownloading: false });
  };

  onMouseEnter = () => {
    this.setState({ dropdown: true });
  };

  onMouseLeave = () => {
    this.setState({ dropdown: false });
  };

  onRefresh = () => {
    const { type, workOrder } = this.props;
    U.api('put', `workOrders/${workOrder.id}/${type}/refresh`, undefined, ['save']);
  };

  closeEditItem = () => {
    this.setState({ type: false, metadata: false, pricePerUnit: false, unit: false, owner: false });
    this.editItem(false);
  };

  copySignatureLink = async () => {
    const { job } = this.props;
    // copies a link to the clipboard that can be pasted in an email / sms to cx asking them to sign
    const r = await U.api('post', 'tokens', { userId: job.customer.user.id });
    const link = `${global.location.origin}?t=${r.token}&r=${encodeURIComponent(
      `/tasks/${job.id}/sign`
    )}`;
    copyText(link);
  };

  discountText = item => {
    const discount = _.get(item, 'metadata.discount');
    let discountText;
    if (discount) {
      const owner = _.startCase(_.get(discount, 'owner.name', 'Split'));
      if (discount.type === 'amount') {
        discountText = `$${_.round(discount.amount / 100, 2)}`;
      } else {
        discountText = `${discount.percent}%`;
      }
      discountText += ` ${owner}`;
    }
    return discountText;
  };

  editItem = item => {
    if (item === undefined) {
      this.setState({ editItem: 'new' });
    } else if (['part', 'shipping'].includes(item.type)) {
      const { dispatch, job } = this.props;
      dispatch(push(`/tasks/${job.id}/parts`));
    } else {
      this.setState({ editItem: item._id });
    }
  };

  toggleCharge = () => {
    const { chargeModal } = this.state;
    this.setState({ chargeModal: !chargeModal });
  };

  toggleDropdown = () => {
    const { dropdown } = this.state;
    this.setState({ dropdown: !dropdown });
  };

  toggleRefund = () => {
    const { refundModal } = this.state;
    this.setState({ refundModal: !refundModal });
  };

  toggleEmail = () => {
    const { email } = this.state;
    this.setState({ email: !email });
  };

  renderTotal = (bill, label, key, isBlue) => {
    return (
      <div className="d-flex justify-content-end py-1">
        <p className="text-muted pr-4 text-right">{label}</p>
        <p
          className={`text-right${isBlue}` ? ' text-blue' : ''}
          style={{ color: color.bold, minWidth: '80px' }}
        >
          {key === 'totalDiscount' && '-'}
          {U.toMoney(bill[key])}
        </p>
      </div>
    );
  };

  render() {
    const { dispatch, job, leftCom, roles, setTab, tab, type, user, workOrder } = this.props;
    const { chargeModal, editItem, email, emailSent, error, refundModal } = this.state;
    let bill;
    if (tab) {
      bill = workOrder.tasks.find(t => t.id === tab)[type][`to${_.startCase(user)}`];
    } else {
      bill = workOrder[type][`to${_.startCase(user)}`];
    }
    if (!bill) {
      return null;
    }
    const isInvoice = type === 'invoice';
    const tabs = [
      { id: false, name: 'Work Order' },
      ...workOrder.tasks.map(t => ({ id: t.id, name: t.title })),
    ];
    const taskMap = _.keyBy(workOrder.tasks, 'id');
    taskMap.false = workOrder;
    const canEdit = type === 'invoice' || !workOrder.invoice;
    const canCharge = Boolean(
      isInvoice &&
        (T.hasCard(job) ||
          _.intersection(roles, [U.user.Role.ADMIN, U.user.Role.AGENT_MANAGER]).length)
    );
    const options = [];
    if (canEdit) {
      options.push({ name: 'Add Item', onClick: () => this.editItem() });
    }
    if (canCharge) {
      options.push({ name: 'Charge', onClick: this.toggleCharge });
      options.push({ name: 'Refund', onClick: this.toggleRefund });
    }
    if (!emailSent) {
      options.push({ name: `Email ${_.startCase(type)}`, onClick: this.toggleEmail });
    }
    options.push({ name: `Download ${_.startCase(type)}`, onClick: this.onDownload });
    options.push({ name: 'Copy Signature Link', onClick: this.copySignatureLink });
    options.push({ name: `Delete ${_.startCase(type)}`, onClick: this.onDelete });
    options.push({ name: `Refresh ${_.startCase(type)}`, onClick: this.onRefresh });
    if (isInvoice && user === 'customer') {
      options.push({
        name: 'Collections Letter',
        onClick: () =>
          openLink({
            newTab: true,
            url: `/workOrders/${workOrder.id}/collections-letter`,
          }),
      });
    }

    return (
      <section id="invoiceCss">
        <div className="text-danger">{error}</div>

        {/*= =======Header======== */}
        <div className="ic-header d-flex justify-content-between">
          <h1>
            {_.get(tab, 'Work Order')} {_.startCase(type)}
          </h1>

          <Button options={options} size="medium">
            {user === 'org'
              ? _.get(workOrder, 'customer.org.name')
              : _.get(workOrder, 'customer.user.fullName')}
          </Button>
        </div>

        {/*= =======Work Order Tabs======== */}
        {workOrder.tasks.length > 1 && (
          <div className="ic-tabs">
            {tabs.map(
              t =>
                !(t.id && t.id !== job.id) && (
                  <li
                    key={t.id}
                    className={`ic-tab pointer ${tab === t.id ? ' active' : ''}`}
                    onClick={() => {
                      setTab(t);
                    }}
                  >
                    <div
                      className={`ic-link ${tab === t.id ? ' active' : ''}`}
                      style={{ display: 'block' }}
                    >
                      <label className="flex-1 text-center">{t.name}</label>

                      <h3 className="text-center">
                        {U.toMoney(taskMap[t.id][type][`to${_.startCase(user)}`].total)}
                      </h3>
                    </div>
                  </li>
                )
            )}
          </div>
        )}

        {/*= =======Table======== */}
        <table
          id="ic-table"
          className="column-6"
          ref={divElement => {
            this.divElement = divElement;
          }}
        >
          <thead>
            <tr>
              <th className="product-col">
                <p className="muted">Product ({bill.chargeItems.length})</p>
              </th>
              <th>
                <p className="muted">Work Item ({workOrder.tasks.length})</p>
              </th>
              <th>
                <p className="muted">Bill To</p>
              </th>
              <th className="value-col">
                <p className="muted text-right">Unit Price</p>
              </th>
              <th className="value-col">
                <p className="muted text-right">Qty</p>
              </th>
              <th className="value-col">
                <p className="muted text-right">Discount</p>
              </th>
              <th className="value-col">
                <p className="muted text-right">Total Tax</p>
              </th>
              <th className="value-col">
                <p className="muted text-right">Total</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {bill.chargeItems
              .sort((a, b) => _.get(taskMap[a.taskId], 'title') > _.get(taskMap[b.taskId], 'title'))
              .map(item => {
                const isCurrentItem = !(
                  _.get(taskMap[item.taskId], 'id') && taskMap[item.taskId].id !== job.id
                );
                return (
                  <tr
                    key={item._id}
                    onClick={
                      isCurrentItem
                        ? () => canEdit && this.editItem(item)
                        : () => dispatch(push(`/tasks/${item.taskId}/estimate`))
                    }
                    className="pointer"
                    title={
                      isCurrentItem
                        ? 'Edit Line Item'
                        : "Please visit work-item's page to edit this item"
                    }
                  >
                    <td className="product-col">
                      <p className="bold">{item.description}</p>
                    </td>
                    <td>
                      {!isCurrentItem ? (
                        <div className="flex items-center">
                          <a
                            href={`/tasks/${_.get(taskMap[item.taskId], 'id')}/${type}2`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {_.get(taskMap[item.taskId], 'metadata.taskQuestions.Make')}{' '}
                            {_.get(taskMap[item.taskId], 'title')}
                            &nbsp;
                            <Icon color="primaryCTA" name="open_in_new" />
                          </a>
                        </div>
                      ) : (
                        <p>
                          {_.get(taskMap[item.taskId], 'metadata.taskQuestions.Make')}{' '}
                          {_.get(taskMap[item.taskId], 'title')}
                        </p>
                      )}
                    </td>
                    <td>
                      <div className="flex items-center">
                        <Icon color="grey.dark" name="account_circle" />
                        &nbsp;
                        {user === 'org'
                          ? _.get(workOrder, 'customer.org.name')
                          : _.get(workOrder, 'customer.user.fullName')}
                      </div>
                    </td>
                    <td className="value-col" style={{ background: '#fafafa' }}>
                      <p className="text-right">{U.toMoney(item.unitPrice)}</p>
                    </td>
                    <td className="value-col" style={{ background: '#fafafa' }}>
                      <p className="text-right">{item.quantity}</p>
                    </td>
                    <td className="value-col">
                      <p className="text-right">{U.toMoney(item.discountPrice)}</p>
                    </td>
                    <td className="value-col">
                      {item.type === 'part' && (
                        <p className="text-right">
                          {U.toMoney(item.unitPrice * item.quantity * workOrder.salesTaxRate.rate)}
                        </p>
                      )}
                    </td>
                    <td className="value-col">
                      <p className="bold text-right">
                        {U.toMoney(item.unitPrice * item.quantity - item.discountPrice)}
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="ic-footer">
          <div className="left-com flex-1">{leftCom}</div>

          {/* Total Blurb */}
          <ul className="right-com">
            <li className="ic-total-item">
              <p className="">Subtotal</p>
              <p className="bold">{U.toMoney(bill.labor + bill.parts + bill.shipping)}</p>
            </li>
            <li className="ic-total-item">
              <p className="muted pl-3">Labor</p>
              <p className="muted">{U.toMoney(bill.labor)}</p>
            </li>
            <li className="ic-total-item">
              <p className="muted pl-3">Parts + Shipping</p>
              <p className="muted">{U.toMoney(bill.parts + bill.shipping)}</p>
            </li>
            <li className="ic-total-item mt-2">
              <p className="">Tax</p>
              <p className="bold">{U.toMoney(bill.tax)}</p>
            </li>
            <li className="ic-total-item">
              <p className="">Adjustment</p>
              <p className="bold">{U.toMoney(bill.adjustment)}</p>
            </li>
            <li className="ic-total-item">
              <p className="">Uniprice</p>
              <p className="bold">{U.toMoney(bill.uniprice)}</p>
            </li>
            <hr />
            <li className="ic-total-item">
              <p className="bold text-blue">Total</p>
              <p className="bold text-blue">{U.toMoney(bill.total)}</p>
            </li>

            {type === 'invoice' && !tab && (
              <div>
                <li className="ic-total-item">
                  <p className="">Paid</p>
                  <p className="bold">{U.toMoney(bill.paid)}</p>
                </li>
                <li className="ic-total-item">
                  <p className="">Refunded</p>
                  <p className="bold">{U.toMoney(bill.refund)}</p>
                </li>
                <div
                  className="d-flex justify-content-between mt-4 px-2 py-2"
                  style={{ background: '#fafafa' }}
                >
                  <h4 className="muted">Balance</h4>
                  <h4 className="bold text-blue">{U.toMoney(bill.balance)}</h4>
                </div>
              </div>
            )}
          </ul>
        </div>
        <Email isOpen={email} onClose={this.toggleEmail} type={type} user={user} />
        {workOrder.invoice && (
          <Charge isOpen={chargeModal} onClose={this.toggleCharge} user={user} />
        )}
        {workOrder.invoice && (
          <Refund isOpen={refundModal} onClose={this.toggleRefund} user={user} />
        )}
        {editItem && job && (
          <EditItem id={editItem} task={job} onClose={this.closeEditItem} type={type} />
        )}
      </section>
    );
  }
}

Items.propTypes = {
  dispatch: PropTypes.func.isRequired,
  job: T.propType.isRequired,
  leftCom: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.oneOf(U.user.roleOptions)),
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.string,
  type: PropTypes.oneOf(['estimate', 'invoice']),
  user: PropTypes.oneOf(['customer', 'org']),
  workOrder: W.propType,
};

export default connect(s => {
  const job = s.tasks[global.id()];
  const { roles } = s.me;
  const workOrder = s.workorders[job.metadata.workOrderId];
  return { job, roles, workOrder };
})(Items);
