import U from '@nanaio/util';
import _ from 'lodash';

// MySettings is a json file (`src/mysettings.json`) that is used to
// override the default settings for the app.
export type MySettings = {
  analyticsEnabled?: boolean;
  devApiOrigin?: string;
  expoCurrentPort?: number;
  isProdBuild?: boolean;
  logApi?: boolean;
  showEnvToast?: boolean;
};

// Datadog provides tracing and monitoring for the application.
// These values should only be set for production.
// See https://app.datadoghq.com/rum/application/dc4baf06-83c3-444a-b765-fb3133c8a0b2/edit
export const datadog = {
  applicationId: 'dc4baf06-83c3-444a-b765-fb3133c8a0b2',
  clientToken: 'pub9d39954d73f108fd8da4994b64618303',
};

const trueIsDevBuild = process.env.NODE_ENV === 'development';

const getMySettings = () => {
  if (!trueIsDevBuild) {
    return {};
  }
  let mySettings;
  try {
    // eslint-disable-next-line global-require, import/no-unresolved
    mySettings = require('../mySettings.json') as MySettings;
  } catch (e) {}
  if (mySettings?.isProdBuild) {
    return { isProdBuild: true };
  }
  return mySettings || {};
};

export const mySettings = getMySettings();

// you can edit these 2 variables while developing locally
const devApiOrigin = mySettings.devApiOrigin || process.env.REACT_APP_API_ORIGIN;

const prodApiOrigin = process.env.REACT_APP_API_ORIGIN || global.location.origin;
export const isTestBuild = !!window?.Cypress;
export const isDevBuild = trueIsDevBuild && !mySettings.isProdBuild;
export const isProdBuild = !isDevBuild && !isTestBuild;
const api = U.const.api({ devApiOrigin, isDevBuild: trueIsDevBuild, prodApiOrigin });
export const { apiHost, apiOrigin, isProdDb } = api;

export const analyticsEnabled = isProdBuild || !!mySettings.analyticsEnabled;

export const applyLink =
  'https://www.fountain.com/nana/apply/paid-tech-acquisition-nationwide/embed?utm_source=nanahome.com%2Fposition-appliance&utm_medium=paidads&utm_campaign=nanalp_techacquisition';

export const comsTicketId = isProdDb ? '5cec4ac58fed652984c59b57' : '5cec4a46d1cbac267b0fa252';

export const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// The port being used by Expo when doing active development for the Pro App.
// This is used for generating deep links to the Pro App.
export const expoCurrentPort = mySettings.expoCurrentPort || 19000;

export const fountain = {
  publicAPIToken: '84526e45-b0b9-4455-9054-5fa42b11088f',
};

export const ga = isProdDb ? 'UA-100353614-1' : 'UA-100353614-2';

export const gaTrackingNumber = '8888180626';

export const gaView = '152002057';

export const logApi = isDevBuild && mySettings.logApi;

export const onboardIQKey = '84526e45-b0b9-4455-9054-5fa42b11088f';

// show/hide the toast message showing environment information
export const showEnvToast =
  typeof mySettings.showEnvToast === 'boolean' ? mySettings.showEnvToast : true;

export const social = {
  facebook: 'https://www.facebook.com/nanahomeofficial',
  instagram: 'https://www.instagram.com/nanahomeofficial/',
  linkedIn: 'https://www.linkedin.com/company/nana-io',
  google: 'https://goo.gl/maps/tx136bLqVdP2',
  twitter: 'https://twitter.com/nana',
};

export const segmentWriteKey = isProdDb
  ? '6a2cHIlfwJtdFoVnYPfq435rqmwKRMd1'
  : '1yIiuuOC3I3s5lf1Yxrsthq8Nb5XdUBl';

export const stripeClientId = isProdDb
  ? 'ca_CI9dz6LZDQEwDrV8NdGlEo6uq8q0iYBM'
  : 'ca_CI9dKBalX210StsJ5UlatX6qsyXleT71';

export const stripeKey = U.const.stripeKey(isProdDb);

export const academyUrl = 'https://nanaacademy.org';
export const academyName = 'Bright Repair Academy';
export const appName = 'Bright Pro App';
export const automationEmail = 'automation@nana.io';
export const bearsLicense = '#47662';
export const companyAddress = 'P.O. Box 397';
export const companyCity = 'Alameda';
export const companyState = 'CA';
export const companyZipCode = '94501';
// Transparent background, white logo
export const companyLogoWhite = '/img/logos/domesticAndGeneral/dg-logo-white.svg';
// Transparent background, color logo, black text
export const companyLogoAndName = '/img/logos/domesticAndGeneral/dg-logo-full-mark.svg';
// Transparent background, color logo
export const companyLogo = '/img/logos/domesticAndGeneral/dg-logo.svg';
// Transparent background, gray logo
export const companyLogoGray = '/img/logos/domesticAndGeneral/dg-logo.svg';
export const companyName = 'Bright';
export const companyNameFullLegal = 'Bright Appliance Repair, Inc';
export const companyPhoneNumbers = ['+15103451997', '+15109440351'];
export const confluenceUrls = {
  userRoles:
    'https://domgen-bright.atlassian.net/wiki/spaces/ET2/pages/10584070/User+Accounts+and+Roles',
};
export const liaisonEmail = 'liaison@nana.io';
export const supportEmail = 'brightdev@domesticandgeneral.com';
export const supportPhoneNumber = '(888) 818-0626';
export const supportUrl = 'https://nana7878.zendesk.com/hc/en-us';
export const supportArticleUrls = {
  adminSchedulingHelp: 'https://nana7878.zendesk.com/hc/en-us/articles/360058969632',
  modelStickerHelp: 'https://nana7878.zendesk.com/hc/en-us/articles/4409170646541',
  preScreenHelp: 'https://nana7878.zendesk.com/hc/en-us/articles/6354607184653',
  schedulingHelp:
    'https://nana7878.zendesk.com/hc/en-us/sections/6353909880589-Customer-facing-articles',
};
export const zendeskURL = 'https://nana7878.zendesk.com';
export const zendeskProUrl = 'https://nanapro.zendesk.com';
