// eslint-disable-next-line max-classes-per-file
import '../../style/announcement.scss';

import React from 'react';
import Scrollchor from 'react-scrollchor';
import { Icon } from '@/components';
import { companyName, supportEmail } from '@/config/const';
import Footer from '../../com/ui/footer';

const essential = [
  'Refrigerators',
  'Stoves',
  'Ovens',
  'Dishwashers',
  'Washers',
  'Dryers',
  'Microwaves',
];
const nonEssential = ['Trash compactors', 'Vent hoods', 'Garbage disposals', 'Cleaning services'];

class Accordian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: ``,
    };
    this.expandEssentials = this.expandEssentials.bind(this);
  }

  expandEssentials() {
    this.setState({
      active: 0,
    });
  }

  render() {
    const questions = [
      {
        title: (
          <p id="to-essential">
            <strong>What is considered an essential service?</strong>
          </p>
        ),
        body: (
          <div>
            <p>
              Essential services are defined as those{' '}
              <strong>
                <i>absolutely necessary</i>
              </strong>{' '}
              to protect the health, safety and well being of a family’s household.
            </p>

            <div className="my-4">
              <p>
                The following services are <strong>“essential”</strong>:
              </p>
              <ul>
                {essential.map((t, n) => (
                  <li key={n}>
                    <p>{t}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ),
      },
      {
        title: (
          <p>
            <strong>What is considered a non-essential service?</strong>
          </p>
        ),
        body: (
          <div>
            <p>
              The “non-essential” {companyName} services will be pausing until the city ordinance is
              lifted are:
            </p>
            <div className="my-2">
              <ul>
                {nonEssential.map((t, n) => (
                  <li key={n}>
                    <p>{t}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ),
      },
      {
        title: (
          <p>
            <strong>
              What if I have a previously scheduled appointment that is an “essential service”?
            </strong>
          </p>
        ),
        body: (
          <div>
            <p>
              Repair service appointments for “essential” appliances will be completed as scheduled,
              unless you are notified otherwise.
            </p>
            <br />
            <p>
              If you would like to make an update to your appointment, please contact our customer
              care representatives at{' '}
              <a href={`mailto:${supportEmail}`} className="text-blue">
                {supportEmail}
              </a>
              .
            </p>
          </div>
        ),
      },
      {
        title: (
          <p>
            <strong>
              What if I have a previously scheduled appointment that is a “non-essential service”?
            </strong>
          </p>
        ),
        body: (
          <p>
            We are doing our best to call each customer personally to reschedule after the specified
            ordinance date is lifted. If you have questions regarding your service, please contact
            our customer support team at{' '}
            <a href={`mailto:${supportEmail}`} className="text-blue">
              {supportEmail}
            </a>
            .
          </p>
        ),
      },
      {
        title: (
          <p>
            <strong>
              I live in an area that has a quarantine ordinance but I need a repair ASAP — what do I
              do?
            </strong>
          </p>
        ),
        body: (
          <p>
            {companyName} remains committed to providing{' '}
            <span onClick={this.expandEssentials}>
              <Scrollchor
                to="#to-essential"
                className="text-blue weight-600"
                animate={{ offset: -200, duration: 400 }}
              >
                essential services
              </Scrollchor>
            </span>{' '}
            that protect the well-being of a family’s household.
          </p>
        ),
      },
      {
        title: (
          <p>
            <strong>
              What steps are your technicians taking to increase prevention in my home?
            </strong>
          </p>
        ),
        body: (
          <div>
            <p>
              Safety is our top priority, especially during this time. We have taken additional
              measures to increase prevention and awareness of COVID-19 with our expert Technicians
              such as:
            </p>
            <ul className="my-4">
              <li className="mb-3">
                <p>
                  <strong>Enhanced Cleanliness Protocols: </strong> Increasing the frequency of
                  sanitization of any equipment and vehicles.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  <strong>Hygiene Preparedness: </strong> As needed, technicians will be reimbursed
                  for personal protective equipment, such as masks, gloves, wipes, and sanitizer.
                  Additionally, they will follow a hand washing schedule for the protection of
                  everyone.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  <strong>Professional Interactions:​ </strong> Avoiding direct contact with others
                  (ex. handshakes, hugs, high-fives, etc.) and staying approximately six feet from
                  the nearest person while working, as well as avoiding gatherings in any common
                  areas or large groups.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  <strong>Staying Up to Date: </strong> {companyName} is closely following guidance
                  of the Centers for Disease Control and passing along updates to our technicians.
                  All have been informed on identifying the potential signs and symptoms of the
                  coronavirus. And, if they were to demonstrate potential symptoms, he/she will be
                  evaluated by a medical professional.
                </p>
              </li>
            </ul>
          </div>
        ),
      },
    ];

    return (
      <section className="accordian-group">
        {questions.map((s, i) => {
          const isActive = i === this.state.active;

          return (
            <div key={i} className={`accordian-card ${isActive ? `opened` : ``}`}>
              <div
                className="accordian-header d-flex align-items-center justify-content-between"
                onClick={() => this.setState({ active: isActive ? `` : i })}
              >
                <div className="flex-1 pr-3">
                  <p>{s.title}</p>
                </div>
                {isActive && <Icon name="remove" size={18} />}
                {!isActive && <Icon name="add" size={18} />}
              </div>
              {isActive ? <div className="accordian-body">{s.body}</div> : ``}
            </div>
          );
        })}
      </section>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export default class Covid19 extends React.Component {
  render() {
    return (
      <section id="Announcement">
        <div className="full-width">
          <div className="container">
            <div className="pb-5">
              <h1 className="hidden-md-down text-lg-center text-md-center mb-lg-0 mb-md-0 mb-3 text-left">
                Coronavirus (COVID-19) FAQ
              </h1>
              <h1 className="hidden-md-up text-lg-center text-md-center mb-lg-0 mb-md-0 mb-3 text-left">
                COVID-19 FAQ
              </h1>
              <p className="text-muted text-lg-center text-md-center text-left">
                At {companyName}, we’re committed to giving you peace of mind by connecting you with
                expert Technicians when your essential service needs arise. See below for responses
                to some commonly asked questions.
              </p>
            </div>
            <Accordian />
            <div style={{ height: '300px' }} />
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}
