import React, { useState } from 'react';
import _ from 'lodash';
import Link from '@/com/ui/link';
import { Modal, Text } from '@/components';
import { companyName, supportEmail, supportPhoneNumber, supportUrl } from '@/config/const';

type Faq = {
  question: string;
  answer: string;
  title: string;
  body: string;
  size?: string;
  block?: JSX.Element;
};

const statuses = [
  {
    status: 'pendingConfirmation',
    definition: `When you place a booking with ${companyName}, we mark it as 'pending' while our team find the perfect technician for your job. It can take a few seconds to a couple of hours to receive an update on a pending booking. Once confirmed, you’ll receive a confirmation email.  In the rare case that we cannot fulfill your booking, you don’t need to worry! We’ll email you asap, and you won’t be charged.`,
  },
  {
    status: 'completed',
    definition: 'When the work is done, the technician changes the status to complete.',
  },
  {
    status: 'assigned',
    definition:
      'Once you are matched with a technician, your job is confirmed and we change the status to assigned.',
  },
  {
    status: 'enRoute',
    definition:
      'When your technician is on the way to your service location, the technician changes the status to enroute.',
  },
  { status: 'onSite', definition: 'Your technician arrived to the service location' },
  {
    status: 'leftSite',
    definition:
      'When your assigned tech has completed all the work they can today, they change the status to complete.',
  },
  { status: 'cancelled', definition: 'Job has been cancelled' },
  {
    status: 'waitingOnApproval',
    definition:
      "If you're under home warranty coverage, we'll need to contact them for authorization before the technician can complete the repair.",
  },
  {
    status: 'waitingOnSchedule',
    definition:
      "When your booking is created without your availability, the status is changed to 'waiting on schedule'. You'll need to let us know when you're available for service. You can easily submit your availability online in your booking details!",
  },
  { status: 'partsRequested', definition: 'Your job require parts that need to be yet ordered.' },
  { status: 'partsShipped', definition: 'Your parts have been shipped' },
  { status: 'partsOrdered', definition: 'Your parts have been ordered' },
  {
    status: 'partsReceived',
    definition:
      'Your parts have been either delivered to your residence or picked up by your technician',
  },
  {
    status: 'needsFollowup',
    definition:
      'Once your parts have been received, you are ready to schedule for a follow-up appointment. Please request a follow-up appointment online.',
  },
];

const faq: Faq[] = [
  {
    question: 'How do I schedule a follow-up?',
    answer: '',
    block: (
      <div>
        <ol className="mt-3">
          <li>
            <Text>Go to your booking details</Text>
          </li>
          <li>
            <Text>Tap the "request follow-up" button</Text>
          </li>
          <li>
            <Text>Specify the reason for the follow-up appointment</Text>
          </li>
          <li>
            <Text>Submit your availability</Text>
          </li>
          <li>
            <Text>Once confirmed, we'll send you a text message!</Text>
          </li>
        </ol>
        <img src="/img/followup-tutorial.gif" style={{ width: '100%', marginTop: '1em' }} alt="" />
      </div>
    ),
    title: '',
    body: '',
  },
  {
    question: 'What do the job statuses mean?',
    answer: '',
    title: '',
    body: '',
    size: 'lg',
    block: (
      <table width="100%" className="mt-4">
        <thead>
          <tr className="bg-background-light">
            <th className="px-1 py-2">
              <Text color="grey.dark" type="subtitle-2">
                Status
              </Text>
            </th>
            <th className="px-1 py-2" style={{ maxWidth: '400px' }}>
              <Text color="grey.dark" type="subtitle-2">
                Definition
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((t, n) => (
            <tr key={n} className="border-b border-grey-medium">
              <td className="px-1 py-2 align-top">
                <Text type="button">{_.startCase(t.status)}</Text>
              </td>
              <td className="px-1 py-2" style={{ maxWidth: '400px' }}>
                <Text>{t.definition}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
  },
  {
    question: 'Do I need to be home during my visit?',
    answer:
      'We require that someone over the age of 18 be present at the time of the appointment, and through the duration of the visit.',
    title: '',
    body: '',
  },
  {
    question: 'What is the diagnostic/service call fee?',
    answer:
      'The Diagnostic Fee is a flat fee for the costs associated with the time and travel to diagnose, inspect, and provide recommendations for the system if you decide NOT to go through with the repair. If you proceed with the repair, the diagnostic fee is waived.',
    title: '',
    body: '',
  },
  {
    question: 'How do I cancel an existing appointment?',
    answer: `We know schedules can change quickly. Cancel your job anytime by reaching out to us at ${supportPhoneNumber}.`,
    title: '',
    body: '',
  },
  {
    question: 'When will my technician arrive?',
    answer: 'Your technician will arrive within the 4-hour time window specified in your booking.',
    title: '',
    body: '',
  },
];

export default function UserTasksSidebar(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState<Faq>(faq[0]);

  const handleClick = (item: Faq) => {
    setSelectedFaq(item);
    setIsOpen(true);
  };
  return (
    <>
      <div className="py-lg-5 py-md-5 px-4 py-3">
        <article className="mb-3 border-b border-grey-medium pb-5">
          <Text color="grey.dark" className="mb-2" type="subtitle-2">
            Common Questions:
          </Text>
          {faq.map(item => (
            <Text
              onClick={() => handleClick(item)}
              key={item.question}
              color="primaryCTA"
              className="cursor-pointer py-2 hover:underline"
            >
              {item.question}
            </Text>
          ))}
        </article>
        <div className="mb-3">
          <Text color="grey.dark" className="mb-2" type="subtitle-2">
            Having problems? We can help.
          </Text>
          <Text>
            Read our{' '}
            <Link className="weight-700 text-primaryCTA" to={supportUrl}>
              support docs
            </Link>{' '}
            or email us at{' '}
            <a href={`mailto:${supportEmail}`} className="weight-700 text-primaryCTA">
              {supportEmail}
            </a>
            .
          </Text>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        width={selectedFaq.size === 'lg' ? 800 : 600}
      >
        <Modal.Header title={selectedFaq.question} />
        <Modal.Body>
          <div
            className="px-3 py-4"
            style={{ maxWidth: selectedFaq.size === 'lg' ? '800px' : '600px' }}
          >
            {selectedFaq.block ? (
              selectedFaq.block
            ) : (
              <Text className="mt-2">{selectedFaq.answer}</Text>
            )}
            <div style={{ height: 50 }} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
