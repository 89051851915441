import React from 'react';
import { Part } from '@nanaio/util';
import { Text } from '@/components';

type Props = {
  partsTiedOnlyToThisDx: Part[];
};

export default function TaskWithoutAlternativeDiagnosis({
  partsTiedOnlyToThisDx,
}: Props): JSX.Element {
  return (
    <>
      <Text type="helper" className="text-grey-dark">
        {partsTiedOnlyToThisDx.length} part(s) are currently associated to only this diagnosis:
      </Text>

      <ul className="mt-1 list-inside">
        {partsTiedOnlyToThisDx.map(part => (
          <li key={part.id} className="ml-1 before:text-grey-dark before:content-['•']">
            <Text type="helper" className="ml-1 text-grey-dark" tag="span">
              {`${part.partNumber as string} (${part.desc as string})`}
            </Text>
          </li>
        ))}
      </ul>

      <Text type="helper" className="mt-2 text-grey-dark">
        To delete this diagnosis, please update the associated parts to a new or different diagnosis
        and try again.
      </Text>
    </>
  );
}
