import React, { useEffect, useState } from 'react';
import { T } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import { Copyable, Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import Link from '../../../com/ui/link';
import WorkOrderTags from './WorkOrderTags';

type Props = {
  taskId: string;
};

export default function WorkOrderHeader({ taskId }: Props): JSX.Element {
  const allTasks = useLegacySelector(state => state.tasks);
  const task = allTasks[taskId];
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const { tasks } = workOrder;

  const [workOrderNumCopied, setWorkOrderNumCopied] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (workOrderNumCopied) {
      timeout = setTimeout(() => {
        setWorkOrderNumCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [workOrderNumCopied]);

  return (
    <div>
      <div className="flex">
        <div className="p-4">
          <div className="flex whitespace-pre">
            <Text type="button">Work Order Id: </Text>
            <Text>{workOrder.id}</Text>
          </div>
          {workOrder.workOrderNumber && (
            <div className="flex items-center whitespace-pre ">
              <Text type="button">Work Order Number: </Text>
              <Text>{workOrder.workOrderNumber}</Text>
              <div className=" ml-2 flex items-center justify-center rounded bg-white px-2 shadow-lifted">
                <Copyable text={workOrder.workOrderNumber}>
                  <Icon color="primaryCTA" name="content_copy" size={12} />
                </Copyable>
              </div>
            </div>
          )}
          {workOrder.vendorPortalAccount && (
            <div>
              <strong>Vendor Portal Account: </strong>
              {workOrder.vendorPortalAccount}
            </div>
          )}
        </div>
        <div className="p-4">
          <WorkOrderTags taskId={taskId} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        {tasks.map(t => (
          <div key={t.id} className="w-full flex-1">
            <Link
              to={`/tasks/${t.id}`}
              className={classNames(
                'z-[1] inline-flex w-full items-center justify-center rounded-t py-2 hover:text-secondary',
                { 'bg-white': t.id === task.id }
              )}
            >
              <Text type="button">
                {T.make(t) && `${T.make(t) || ''} `}
                {t.title}
              </Text>
            </Link>
          </div>
        ))}
        <div className="w-full flex-1">
          <Link
            to={`/workorders/${workOrder.id}/tasks/add`}
            className="z-[1] inline-flex w-full items-center justify-center rounded-t py-2 hover:text-primaryCTA"
          >
            <div className="flex items-center justify-center">
              <Icon color="primaryCTA" name="add" />
              <Text type="button" color="primaryCTA">
                {' '}
                Add Task
              </Text>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
