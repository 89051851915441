import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import Truncate from './Truncate';

type ShowMoreTextProps = {
  children: React.ReactNode;
  lines?: number;
  more?: React.ReactNode;
  less?: React.ReactNode;
  anchorClass?: string;
  className?: string;
  onClick?: (expanded: boolean, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  expanded?: boolean;
  width?: number;
  keepNewLines?: boolean;
  truncatedEndingComponent?: React.ReactNode;
  expandByClick?: boolean;
  onTruncate?: () => void;
  style?: CSSProperties;
};

export default function ShowMoreText({
  children,
  className,
  lines = 3,
  more = 'Show more',
  less = 'Show less',
  anchorClass = 'text-primaryCTA cursor-pointer',
  onClick,
  expanded: defaultExpanded = false,
  width = 0,
  keepNewLines = false,
  truncatedEndingComponent = '... ',
  expandByClick = true,
  onTruncate,
  style,
}: ShowMoreTextProps): JSX.Element {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [truncated, setTruncated] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const truncateRef = useRef<any>(null);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleTruncate = (truncatedValue: boolean) => {
    if (truncatedValue !== truncated) {
      setTruncated(truncatedValue);
      if (truncatedValue) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        truncateRef.current.onResize();
      }
      if (onTruncate) {
        onTruncate();
      }
    }
  };

  const toggleLines = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    if (!expandByClick) {
      if (onClick) {
        onClick(!expanded, event);
      }
      return;
    }
    setExpanded(!expanded);
    if (onClick) {
      onClick(!expanded, event);
    }
  };

  return (
    <div className={className} style={style}>
      <Truncate
        width={width}
        lines={!expanded && lines}
        ellipsis={
          <span>
            {truncatedEndingComponent}
            <span className={anchorClass} onClick={toggleLines}>
              {more}
            </span>
          </span>
        }
        onTruncate={handleTruncate}
        ref={truncateRef}
      >
        {keepNewLines && typeof children === 'string'
          ? children.split('\n').map((line, i, arr) => {
              const key = `${i}`;
              const element = <span key={key}>{line}</span>;
              if (i === arr.length - 1) {
                return element;
              } else {
                return [element, <br key={`${key}br`} />];
              }
            })
          : children}
      </Truncate>
      {!truncated && expanded && (
        <span>
          {' '}
          <span className={anchorClass} onClick={toggleLines}>
            {less}
          </span>
        </span>
      )}
    </div>
  );
}
