import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Day from '../Day';
import { availabilityPropType, capacityPropType, rowHeight } from '../util';
import Customer from './Customer';

export default function CustomerRow({
  availability,
  capacity,
  mapIsOpen,
  onSetStatus,
  startTimes,
  toggleAvailability,
  togglePreferred,
  visit,
  workOrder,
}) {
  return {
    key: 'customer',
    height: rowHeight,
    content: [
      <Customer
        mapIsOpen={mapIsOpen}
        onSetStatus={onSetStatus}
        visit={visit}
        workOrder={workOrder}
      />,
      ..._.times(7, i => (
        <Day
          availability={availability.slice(i * 3, (i + 1) * 3)}
          capacity={capacity.slice(i * 3, (i + 1) * 3)}
          dayIndex={i}
          id="cx"
          key={i}
          startTimes={startTimes.slice(i * 3, (i + 1) * 3)}
          {...{ toggleAvailability, togglePreferred, visit }}
        />
      )),
    ],
  };
}

CustomerRow.propTypes = {
  availability: availabilityPropType.isRequired,
  capacity: PropTypes.arrayOf(capacityPropType).isRequired,
  mapIsOpen: PropTypes.bool.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  toggleAvailability: PropTypes.func.isRequired,
  togglePreferred: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
