import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import T from 'prop-types';
import { couldNotContactCx } from '../../../../com/task';
import { Modal } from '../../../../com/ui/form';

class CallAttempted extends React.Component {
  static propTypes = {
    onSubmit: T.func,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { content: p.content };
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  openModal() {
    this.setState({ modal: true, shareWithVendor: false });
  }

  async onSubmit() {
    const r = await couldNotContactCx(this.props.jobId);
    if (!r || r.errMsg) {
      return this.setState({ error: _.get(r, 'errMsg', 'Error') });
    }
    this.setState({ content: '', error: '', modal: false });
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  render() {
    return (
      <div hidden={!this.props.couldNotContactEnabled}>
        <div className="btn btn-primary mr-3" onClick={this.openModal}>
          Call Attempted
        </div>
        <Modal
          className="partModal sm"
          title="Call Attempted"
          saveText="Submit"
          submit={this.onSubmit}
        >
          <div className="text-danger">{this.state.error}</div>
          <div>
            This will contact the vendor and inform them that the customer could not be contacted.
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(s => {
  const jobId = global.id();
  const job = s.tasks[global.id()];
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const couldNotContactEnabled = _.get(org, 'liaison.enabledStatuses', []).includes(
    'couldNotContactCx'
  );
  const content = s.form[`${jobId}note`];
  return { content, jobId, couldNotContactEnabled };
})(CallAttempted);
