import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import Button from '../core/Button'; // eslint-disable-line import/no-cycle
import theme from '../theme';
import type { InputProps } from './Input';

export const getProps = ({ onChange, value, ...rest }: InputProps): Props => ({
  onChange: nullthrows(onChange),
  value: m(value as m.MomentInput).isValid() ? (value as m.MomentInput) : undefined,
  ...rest,
});

export type Props = {
  cypressId?: string;
  disabled?: boolean;
  endOfDay?: boolean;
  error?: string;
  onChange: (value?: m.MomentInput) => void;
  requireConfirmation?: boolean;
  style?: React.CSSProperties;
  value?: m.MomentInput;
};

export default function DateInput({
  cypressId,
  disabled,
  endOfDay,
  error,
  onChange,
  requireConfirmation,
  style = {},
  value,
}: Props): JSX.Element {
  // This component requires a temp value to be saved because when the user uses arrows to change
  // month or year the component's value will change. When the user changes month rather than
  // clicking on a date within a month the cursor position is saved to state and on change is not
  // fired. Reports will use this component. If on change is fired multiple times this could trigger
  // multiple long-running API queries. So instead a temp value is stored until the final date
  // selection is made.

  const [showSave, setShowSave] = useState(false);
  const [textValue, setTextValue] = useState(value ? m(value).format('YYYY-MM-DD') : '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);

    // call on change if value is a valid date or empty
    if (event.target.value && !event.target.value.match(/^[1-9]\d{3}-\d{2}-\d{2}$/)) {
      return;
    }

    // this happens when user presses Clear in date picker
    if (event.target.value === '') {
      onChange(undefined);
      setTextValue('');
      return;
    }
    let value = m(event.target.value);

    if (endOfDay) {
      value = value.endOf('day');
    }
    if (requireConfirmation && m(textValue).date() === value.date()) {
      setShowSave(true);
    } else {
      onChange(value.format());
      setShowSave(false);
    }
  };

  const handleSave = () => {
    onChange(textValue);
  };

  const inputStyle = {
    ...theme.formControl,
    ...(error ? { borderColor: theme.colors.danger } : {}),
    color: disabled ? theme.colors.icons.grey : theme.colors.font.dark,
  };

  useEffect(() => {
    setTextValue(value ? m(value).format('YYYY-MM-DD') : '');
  }, [value]);

  return (
    <div className="flex flex-row" style={style}>
      <input
        autoFocus
        data-cy={cypressId}
        disabled={disabled}
        onChange={handleChange}
        placeholder="YYYY-MM-DD"
        style={inputStyle}
        type="date"
        value={textValue}
      />
      {showSave && (
        <Button className="ml-4 px-4 py-3" onClick={handleSave} variant="primary">
          Save
        </Button>
      )}
    </div>
  );
}
