import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { PartVendor } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import type { Dispatch } from 'redux';
import { APIError, Icon, Input, Loader } from '@/components';
import { Type as InputType } from '@/components/form/Input';
import { useLazyLegacyAPI } from '@/hooks';
import { Link } from '../../com/ui/link';
import { idFromURL } from '../../utils';
import Locations from './Location';
import Profile from './Profile';
type Props = {
  dispatch: Dispatch;
};

function Edit({ dispatch }: Props): JSX.Element {
  const id = nullthrows(idFromURL());
  const [partVendor, setPartVendor] = useState<PartVendor>();
  const [partVendorOptions, setPartVendorOptions] = useState<{ id: string; name: string }[]>([]);
  const [loadPartVendor, loadPartVendorResponse] = useLazyLegacyAPI<PartVendor>(
    `partVendors/${id}`,
    {
      errorRender: ({ error }) => (
        <APIError className="mb-10" error={error} text={<>Unable to load part vendor. {error}</>} />
      ),
      method: 'get',
    }
  );

  const [loadPartVendors, loadPartVendorsResponse] = useLazyLegacyAPI<PartVendor[]>(
    `partVendors/search`,
    {
      errorRender: ({ error }) => (
        <APIError
          className="mb-10"
          error={error}
          text={<>Unable to load part vendor list. {error}</>}
        />
      ),
      method: 'post',
    }
  );

  useEffect(() => {
    if (id && !loadPartVendorResponse.data) {
      void loadPartVendor().then(data => {
        if (data) {
          setPartVendor(data);
        }
      });
    }
  }, [id, loadPartVendor, loadPartVendorResponse.data]);

  const handleChangePartVendor = (id?: string) => {
    if (id) {
      dispatch(push(global.location.pathname.replace(/[a-z0-9]{24}/, id)));
    }
  };

  const handleLoadPartVendors = async () => {
    const data = await loadPartVendors();
    if (data) {
      const partVendorsOptions: { id: string; name: string }[] = data.map(partVendor => {
        return { id: partVendor._id, name: partVendor.name };
      });
      setPartVendorOptions(partVendorsOptions);
    }
  };

  const handleRemove = () => {
    dispatch(push('/part-vendors'));
  };

  if (!partVendor) {
    return <Loader />;
  }
  const pages = [
    { name: 'Locations', to: `/part-vendors/${id}/locations`, com: Locations },
    { name: 'Profile', to: `/part-vendors/${id}/profile`, com: Profile, default: true },
  ];
  const activePage =
    pages.find(p => p.to === global.location.pathname) || pages.find(p => p.default);
  if (!activePage) {
    return <div>page not found</div>;
  }

  return (
    <div className="relative h-[100vh] flex-1 flex-col">
      <section className="bg-grey-light p-4">
        <div className="flex-1">
          <div className="justify-content-between flex">
            <div className="flex-1">
              <div className="flex">
                {loadPartVendorResponse.error}
                {loadPartVendorsResponse.error}
                <Input
                  options={partVendorOptions}
                  onFocus={handleLoadPartVendors}
                  onChange={id => handleChangePartVendor(id as string)}
                  label=""
                  sort
                  style={{ width: '300px' }}
                  type={InputType.SEARCH}
                  value={partVendor.name}
                />
                {loadPartVendorsResponse.loading && (
                  <Icon name="loading" spin className="ml-3" size={16} />
                )}
              </div>
              <div className="align-center flex">
                <div className="px-2">
                  <h5 className="text-muted">
                    ID# <span className="text-black">{partVendor.id}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="relative border-b-[1px] border-grey-medium bg-grey-light">
        <div className="px-8">
          <ul className="flex flex-row">
            {pages.map(p => (
              <li key={p.name} className="flex-1">
                <Link
                  className={classNames(
                    'border-b-0 border-t-4 border-transparent bg-grey-light outline outline-grey-medium',
                    'flex items-center justify-center',
                    'relative cursor-pointer py-2 text-center text-sm font-medium text-grey-dark',
                    'no-underline hover:text-black',
                    {
                      'border-b-0 border-t-4 border-primary bg-white font-semibold text-black':
                        p.name === activePage.name,
                    }
                  )}
                  to={p.to}
                >
                  {p.name}
                  {p.name === activePage.name && (
                    <span className=" absolute bottom-[-1px] left-0 h-[1px] w-full bg-white" />
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-[146px] flex-1 bg-white">
        {React.createElement(activePage.com, { partVendor, onRemove: handleRemove })}
      </div>
    </div>
  );
}

export default connect()(Edit);
