import React from 'react';
import { T, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import theme from '../../theme';
import User from '../User';

export default function Pro({
  index,
  removePro,
  setSearchTechIsOpen,
  setVisit,
  unassignedIndex,
  visit,
  visitPro,
  workOrder,
}) {
  const changeRole = (task, role) => {
    const taskIndex = _.findIndex(visit.pros[index].tasks, proTask => proTask.id === task.id);
    const newVisit = _.cloneDeep(visit);
    newVisit.pros[index].tasks[taskIndex].role = role;
    setVisit(newVisit);
  };

  return (
    <User imageClassName="bg-danger" name={`T ${unassignedIndex + 1}`}>
      <div className="flex-1">
        <Text style={theme.secondary} type="subtitle-1">
          Technician {unassignedIndex + 1}
        </Text>
        {workOrder.tasks.map((task, i) => {
          const role = _.startCase(
            _.get(
              visitPro.tasks.find(proTask => proTask.id === task.id),
              'role'
            )
          );
          return (
            <div className="flex flex-row" key={task.id || i}>
              <Text className="mr-2" color="grey.dark" type="body-2">
                {T.appliance(task)}:
              </Text>
              <Dropdown
                items={_.compact(W.visitProRoleOrder).map(role => ({
                  disabled: !W.visitProCanAddRole({ role, taskId: task.id, visit }),
                  onClick: () => changeRole(task, role),
                  title: _.startCase(role),
                }))}
                trigger={
                  <Text type="body-2" color={role ? 'font.dark' : 'danger'}>
                    {role || 'Select role'}
                  </Text>
                }
              />
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-between">
        <Icon className="mb-4" color="danger" name="close" onClick={() => removePro(index)} />
        <Icon color="primaryCTA" name="search" onClick={() => setSearchTechIsOpen(true)} />
      </div>
    </User>
  );
}

Pro.propTypes = {
  index: PropTypes.number.isRequired,
  removePro: PropTypes.func.isRequired,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  unassignedIndex: PropTypes.number.isRequired,
  visit: W.visitPropType.isRequired,
  visitPro: PropTypes.exact({
    ...W.visitProInterface,
    index: PropTypes.number.isRequired,
    tasks: PropTypes.arrayOf(W.visitProTaskPropType),
  }).isRequired,
  workOrder: W.propType.isRequired,
};
