import React from 'react';
import { Dropdown as RSDropdown, DropdownItem,DropdownMenu, DropdownToggle } from 'reactstrap';
import T from 'prop-types';

export class Dropdown extends React.Component {
  static propTypes = {
    items: T.arrayOf(
      T.shape({
        text: T.string.isRequired,
        modal: T.string,
      })
    ),
    title: T.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    const { title, items } = this.props;
    if (!items || !items.length) {
      return null;
    }
    return (
      <RSDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>{title}</DropdownToggle>
        <DropdownMenu>
          {items.map(link => (
            <DropdownItem key={link.text}>{link.text}</DropdownItem>
          ))}
        </DropdownMenu>
      </RSDropdown>
    );
  }
}
