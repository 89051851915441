import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Day from '../Day';
import Section from '../Section';
import { proPropType, rowHeight, sectionHeight, timeSlotsPropType } from '../util';
import Pro from './Pro';

export default function AssignedRows({
  mapIsOpen,
  mapProId,
  proMap,
  setMapProId,
  setVisit,
  startTimes,
  timeSlots,
  toggleAvailability,
  togglePreferred,
  visit,
  workOrder,
}) {
  const assignedPros = visit.pros.filter(
    pro => pro.id && pro.tasks.find(task => task.role !== W.VisitProRole.INTERESTED)
  );

  const assignedSection = {
    key: 'assignedSection',
    height: sectionHeight,
    content: [
      <Section hasOverlay={mapIsOpen} isFixed title={`Assigned (${assignedPros.length})`} />,
      ..._.times(7, i => <Section date={startTimes[i * 3]} hasOverlay={mapIsOpen} key={i} />),
    ],
  };

  const assignedRows = assignedPros.map(visitPro => ({
    key: `assigned-${visitPro.id}`,
    height: rowHeight,
    content: [
      <Pro
        pro={proMap[visitPro.id]}
        {...{ mapIsOpen, mapProId, setMapProId, setVisit, visit, visitPro, workOrder }}
      />,
      ..._.times(7, i => (
        <Day
          id={visitPro.id}
          key={i}
          pro={proMap[visitPro.id]}
          startTimes={startTimes.slice(i * 3, (i + 1) * 3)}
          timeSlots={timeSlots[visitPro.id]}
          {...{ toggleAvailability, togglePreferred, visit, visitPro }}
        />
      )),
    ],
  }));

  return [assignedSection, ...assignedRows];
}

AssignedRows.propTypes = {
  mapIsOpen: PropTypes.bool.isRequired,
  mapProId: PropTypes.string,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  setMapProId: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  timeSlots: timeSlotsPropType.isRequired,
  toggleAvailability: PropTypes.func.isRequired,
  togglePreferred: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
