import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { loggedOutEvent } from '@/com/analytics';
import { Alert, Button, Input,Text } from '@/components';
import { academyUrl, companyName } from '@/config/const';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function ApplicationForm({ isSubmitting, onSubmit, phoneNumberError, zipCodeError }) {
  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-8 hidden w-full rounded-lg border border-grey-medium bg-white p-2 shadow-md lg:flex"
    >
      <div className="apply-hero__form grid flex-1 gap-2">
        <div>
          <Input
            error={phoneNumberError}
            id="phoneNumber"
            inline
            placeholder="Enter phone number"
            type="phone"
          />
        </div>

        <div className="bg-grey-medium" />

        <div>
          <Input
            error={zipCodeError}
            id="zipCode"
            inline
            placeholder="Enter 5-digit zip code"
            type="zipCode"
          />
        </div>
      </div>

      <Button
        style={{ minHeight: '76px' }}
        disabled={isSubmitting}
        className="ml-2 h-full w-36"
        type="submit"
      >
        Join Now
      </Button>
    </form>
  );
}

ApplicationForm.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  phoneNumberError: PropTypes.string,
  zipCodeError: PropTypes.string,
};

function Hero({
  alert,
  alertType,
  isSubmitting,
  onSubmit,
  phoneNumberError,
  showModal,
  zipCodeError,
}) {
  const heroRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [, breakpointSize] = useBreakpoints();

  useEffect(() => {
    const target = heroRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => setVisible(entry.intersectionRatio !== 1),
      { threshold: 1 }
    );

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [visible]);

  return (
    <>
      <div className="apply-hero flex items-stretch justify-center">
        <div className="apply-container flex flex-col md:flex-row lg:flex-col xl:flex-row">
          <div className="apply-hero__container mx-auto w-full px-4 py-12 text-center md:w-3/5 lg:w-full lg:px-28 lg:text-left xl:w-3/5 xl:px-4">
            <Text type="headline-2" className="apply-hero__title" tag="h1">
              Claim jobs, not leads.
              <br />
              Get paid after each Job.
            </Text>

            <Text
              type={breakpointSize >= Breakpoints.LG ? 'subtitle-2' : 'subtitle-1'}
              color="grey.dark"
              className="mt-4 font-normal"
            >
              Complete 20 jobs per week to{' '}
              <b className="text-font-dark">earn over $7,150 a month</b>.
            </Text>
            <Text
              type={breakpointSize >= Breakpoints.LG ? 'subtitle-2' : 'subtitle-1'}
              color="grey.dark"
              className="mt-4 font-normal"
            >
              {companyName} rewards top techs even more. No monthly fees.
            </Text>

            <div ref={heroRef}>
              <Button
                className="mt-8 w-full lg:hidden"
                onClick={() => {
                  loggedOutEvent().track('v1_getStartedClicked', { source: 'heroButton' });
                  showModal();
                }}
              >
                Join Now
              </Button>

              <ApplicationForm
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  loggedOutEvent().track('v1_getStartedClicked', { source: 'heroForm' });
                  onSubmit(true);
                }}
                phoneNumberError={phoneNumberError}
                zipCodeError={zipCodeError}
              />
            </div>

            {alert ? (
              <Alert variant={alertType} className="mt-6 hidden lg:block">
                {alert}
              </Alert>
            ) : null}

            <div className="mt-14">
              <Text type="subtitle-1" color="grey.dark" className="inline-block">
                New to repair?
              </Text>
              <a href={academyUrl}>
                <Text color="primaryCTA" className="ml-1 inline-block">
                  Learn about our free technician training program
                </Text>
              </a>
            </div>
          </div>

          <div className="apply-hero__image relative h-full w-full bg-cover bg-center md:w-2/5 lg:hidden xl:block xl:w-2/5" />
        </div>
      </div>

      <div className="apply-hero__image-large hidden bg-cover bg-center lg:block xl:hidden" />

      <div
        className={classnames(
          'apply-hero__sticky fixed bottom-0 left-0 right-0 z-50 border border-grey-medium bg-white p-2 transition-opacity lg:hidden',
          {
            'pointer-events-none opacity-0': !visible,
            'opacity-100': visible,
          }
        )}
      >
        <Button
          onClick={() => {
            loggedOutEvent().track('v1_getStartedClicked', { source: 'pageFooter' });
            showModal();
          }}
          className="w-full"
        >
          Join Now
        </Button>
      </div>
    </>
  );
}

Hero.propTypes = {
  alert: PropTypes.string,
  alertType: PropTypes.oneOf(['warning', 'error']),
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  phoneNumberError: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  zipCodeError: PropTypes.string,
};

export default Hero;
