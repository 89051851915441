// eslint-disable-next-line max-classes-per-file
import React from 'react';
import U from '@nanaio/util';
import T from 'prop-types';
import { Button, Icon, Text } from '@/components';
import { companyName } from '@/config/const';
import { Modal } from '../../../com/ui/form';
import Link from '../../../com/ui/link';

class List extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.openModal = this.openModal.bind(this);
  }

  openModal(city) {
    this.setState({ modal: true, city });
  }

  render() {
    return (
      <section>
        <ul className="h-[550px] overflow-x-hidden overflow-y-scroll">
          {this.props.cities.map((i, n) => (
            <Link
              name={`location-${this.state.city}`}
              data-category={i}
              key={n}
              onClick={() => this.openModal(i)}
            >
              <li className="cursor-pointer p-3 hover:bg-background-lightest">
                <Text type="button">{i}</Text>
              </li>
            </Link>
          ))}
        </ul>

        <Modal title="Our Locations" hideCancel hideSave>
          <section className="px-3 py-4">
            <div className="mb-3 flex justify-center">
              <img alt="location" src="/img/location-vector.png" height="150" />
            </div>
            <Text type="headline-3" className="mt-3 text-center">
              {companyName} is in {this.state.city}
            </Text>
            <Text color="grey.dark">
              Great news, we&apos;re here to serve{' '}
              <Link to="/book" name="cityPageLink">
                <u>{this.state.city}</u>
              </Link>
              . {companyName} is passionate about making home services better. That&apos;s why we
              partnered with hundreds of locals who keep your homes & appliance running. Together
              we&apos;re energizing the local economy and revolutionizing the home services
              industry.
            </Text>
            <br />
            <div className="flex justify-center">
              <Link to="/book" name={`book-${this.state.city}`}>
                <Button>Book Online</Button>
              </Link>
            </div>
          </section>
        </Modal>
      </section>
    );
  }
}

class CitySearch extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      cities: [],
      open: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  async componentDidMount() {
    const r = await U.api('post', 'cities/search');
    const cities = r.map(c => `${c.locality}, ${c.region}`);
    this.setState({ cities });
  }

  onChange(e) {
    this.setState({ search: e.target.value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.onSearch, 500);
  }

  async onSearch() {
    const query = { locality: `||${this.state.search}||` };
    const r = await U.api('post', 'cities/search', { query });
    this.setState({ cities: r.map(c => `${c.locality}, ${c.region}`) });
  }

  render() {
    return (
      <section>
        <div className="m-1 max-h-[600px] max-w-[600px] shadow-lifted">
          <div className="relative">
            <div className="absolute left-3 top-0.5 translate-y-1/2">
              <Icon name="near_me" size={16} color="#3C76FC" />
            </div>
            <Link name="input">
              <input
                type="text"
                className="text-grey-darkest h-[50px] w-full items-center border-b border-grey-medium bg-background-lightest py-4 pl-[38px] font-bold outline-none transition-all
                  hover:border-b-2 hover:border-[#3C76FC] hover:bg-[#eef3fe]
                  focus:border-b-2 focus:border-[#3C76FC] focus:bg-[#eef3fe]
                  active:border-b-2 active:border-[#3C76FC] active:bg-[#eef3fe]"
                placeholder="Search Your City"
                onChange={this.onChange}
                autoFocus
              />
            </Link>
          </div>
          <List cities={this.state.cities} />
        </div>
      </section>
    );
  }
}

export default () => <CitySearch />;
