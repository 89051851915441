import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import ServerEntityInfo from './ServerEntityInfo';

export default {
  title: 'Pages/admin/ServerEntityInfo',
  component: ServerEntityInfo,
  args: {
    createTime: 1651594085019,
    entityName: 'Vendor',
    id: '12345',
    lastModified: 1682110395000,
  },
} as ComponentMeta<typeof ServerEntityInfo>;

const Template: ComponentStory<typeof ServerEntityInfo> = args => <ServerEntityInfo {...args} />;

export const Default = Template.bind({});
Default.args = {};
