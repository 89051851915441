import React from 'react';
import * as Table from '@nanaio/table';
import { Table as TableComponent } from '@/components';
import { idFromURL } from '@/utils';

export default function ActivitiesTable() {
  const proId = idFromURL();
  const queryOverride = { columns: [{ key: 'refId', search: { text: proId, value: proId } }] };
  return (
    <TableComponent
      {...Table.databases.default.activity} // eslint-disable-line react/jsx-props-no-spreading
      embed
      queryOverride={queryOverride}
      rowUrl={undefined}
    />
  );
}
