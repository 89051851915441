import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import Notes from '../../com/ui/notes';
import { updateUser } from '../../com/user';

class Profile extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { user: p.user };
    this.createTicket = this.createTicket.bind(this);
    global.document.title = _.get(this.state, 'user.profile.fullName');
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(p.user, this.props.user)) {
      this.setState({ user: p.user });
      global.document.title = _.get(p.user, 'profile.fullName');
    }
  }

  async createTicket() {
    const ticket = await U.api(
      'post',
      'issues',
      { refId: this.props.user.id, type: 'userTicket' },
      true
    );
    const changes = [{ action: 'replace', path: 'metadata.ticketId', value: ticket.id }];
    updateUser(this.props.user.id, changes);
  }

  render() {
    const { ticketId } = this.props;
    return (
      <section id="cx-profile" className="user_notes">
        {!_.get(this.props.user, 'metadata.ticketId') && (
          <div className="cx-profile--container">
            <div className="cx-header flex-row">
              <p className="weight-700 text-dark flex-1">Notes</p>
              <IconButton onClick={this.createTicket} size="medium">
                <Icon name="add" size={25} className="text-grey-dark" />
              </IconButton>
            </div>
            <div className="cx-list">
              <div className="px-4 py-4 text-center">
                <h1>📝</h1>
                <div className="pb-2">
                  <h5>No notes yet</h5>
                </div>
                <p className="text-muted" style={{ fontWeight: 500 }}>
                  Get started by tapping the plus button on the top right hand corner.
                </p>
              </div>
            </div>
          </div>
        )}

        {ticketId && (
          <Notes
            id={ticketId}
            updateFunc={(id, changes) => U.api('put', `issues/${id}`, changes, true)}
            loadPath={`tickets.${ticketId}.notes`}
            savePath="notes"
            isUserProfile
          />
        )}
      </section>
    );
  }
}

export default connect(s => {
  const user = s.users[global.id()];
  const ticketId = _.get(user, 'metadata.ticketId');
  return { ticketId, user };
})(Profile);
