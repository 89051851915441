import React, {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import U from '@nanaio/util';
import classnames from 'classnames';
import _ from 'lodash';
import Button, { Props as ButtonProps } from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import { getArrowClasses, tooltipClasses } from './classes';

const PLACEMENT_OFFSET_DISTANCE = 28;
const PLACEMENT_OFFSET_SKID = 18;

export type Ref = {
  dismiss: () => void;
};

type Props = {
  children?: ReactNode;
  className?: string;
  title?: string;
  innerRef?: ForwardedRef<Ref>;
  description?: ReactNode;
  primaryButtonProps?: Omit<ButtonProps, 'variant'>;
  secondaryButtonProps?: Omit<ButtonProps, 'variant'>;
  placement?: Config['placement'];
  trigger: ReactNode;
};

/**
 * A wrapper class for react-popper-tooltip with clickable buttons
 *
 * @param children  Content the tooltip is in reference to (not trigger or tooltip text)
 * @param trigger this is content that will trigger the tooltip to be shown when moused over
 */
function InteractiveTooltip({
  children,
  className = '',
  title,
  innerRef,
  description,
  primaryButtonProps,
  secondaryButtonProps,
  placement,
  trigger,
}: Props): JSX.Element {
  const [visible, setVisible] = useState(false);
  const placementOffsetSkid = placement?.includes('end')
    ? PLACEMENT_OFFSET_SKID
    : -PLACEMENT_OFFSET_SKID;

  useImperativeHandle(innerRef, () => ({
    dismiss: () => {
      setVisible(false);
    },
  }));

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, state } = usePopperTooltip(
    {
      placement,
      trigger: 'click',
      offset: [placementOffsetSkid, PLACEMENT_OFFSET_DISTANCE],
      visible: visible,
    },
    { strategy: 'fixed' }
  );

  const toggleVisible = useCallback(() => {
    if (visible) {
      setVisible(false);
      return;
    }
    setVisible(true);
  }, [visible]);

  return (
    <div className={`relative flex ${className}`}>
      {children}
      <div ref={setTriggerRef} onClick={toggleVisible} className="cursor-pointer">
        {trigger}
      </div>
      {visible && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div ref={setTooltipRef} {...getTooltipProps({ className: tooltipClasses })}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...getArrowProps({ className: getArrowClasses(state?.placement) })} />
          <div className="w-full">
            <div className="flex space-x-3">
              {/* Title */}
              {title && (
                <Text
                  className={classnames('mt-8 flex-auto self-center text-secondary')}
                  type="page-title"
                >
                  {title}
                </Text>
              )}
              {/* Dismiss Button */}
              <button
                className="flex-none place-self-start justify-self-end"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon className="text-icons-grey" name="close" size={24} />
              </button>
            </div>
            <div>
              {/* Description */}
              {description && (
                <div className="mt-2">
                  {U.isText(description) ? (
                    <Text className="text-grey-dark">{description}</Text>
                  ) : (
                    description
                  )}
                </div>
              )}
              {/* Button(s) */}
              {(primaryButtonProps || secondaryButtonProps) && (
                <div className="mt-4 flex space-x-4">
                  {primaryButtonProps && <Button variant="primary" {...primaryButtonProps} />}
                  {secondaryButtonProps && <Button variant="outline" {...secondaryButtonProps} />}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef<Ref, PropsWithoutRef<Props>>((props, ref) => (
  <InteractiveTooltip innerRef={ref} {...props} />
));
