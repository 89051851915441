import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Col, DropdownItem, Row } from 'reactstrap';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Avatar, Icon } from '@/components';
import { Modal } from '../../com/ui/form';
import Link from '../../com/ui/link';
import Loader from '../../com/ui/loader';
import Log from '../../com/ui/log';
import { loadUser, updateUser } from '../../com/user';
import { PopOver } from '../../task/popOver';
import EditDetails from './editDetails';
import Jobs from './jobs';
import Referrals from './referrals';

class Edit extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { value: '', copied: false, ...this.getUserInfo(p.user) };
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
    loadUser(global.id());
    this.onRemoveCard = this.onRemoveCard.bind(this);
    this.openRemoveCard = this.openRemoveCard.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!this.props.user && p.user) {
      this.setState(this.getUserInfo(p.user));
    }
  }

  getUserInfo(user) {
    if (!user) {
      return {};
    }
    const socialProfile = _.get(user, 'profile.socialProfiles');
    const social = {
      linkedIn: socialProfile ? socialProfile.includes('linkedin') : null,
      facebook: socialProfile ? socialProfile.includes('facebook') : null,
      google: _.includes(_.get(user, 'auth.socialProfiles'), 'google'),
    };

    let address;

    if (_.get(user, 'profile.address')) {
      address =
        `${_.get(user, 'profile.address.streetNumber')} ${_.get(user, 'profile.address.route')}${
          _.get(user, 'profile.address.unit') ? `, ${_.get(user, 'profile.address.unit')}` : ``
        }` +
        `, ${_.get(user, 'profile.address.locality')}, ` +
        `${_.get(user, 'profile.address.region')}, ` +
        `${_.get(user, 'profile.address.postalCode')}`;
    }

    const addressString = encodeURIComponent(address);
    const mapLink = `https://www.google.com/maps/search/?api=1&query=${addressString}`;

    let billingAddress;

    if (_.get(user, 'profile.billingAddress')) {
      billingAddress =
        `${_.get(user, 'profile.billingAddress.streetNumber')} ${_.get(
          user,
          'profile.billingAddress.route'
        )}${
          _.get(user, 'profile.billingAddress.unit')
            ? `, ${_.get(user, 'profile.billingAddress.unit')}`
            : ``
        }` +
        `, ${_.get(user, 'profile.billingAddress.locality')}, ` +
        `${_.get(user, 'profile.billingAddress.region')}, ` +
        `${_.get(user, 'profile.billingAddress.postalCode')}`;
    }

    const billingAddressString = encodeURIComponent(billingAddress);
    const billingMapLink = `https://www.google.com/maps/search/?api=1&query=${billingAddressString}`;

    const referralCode = _.get(user, 'shortId');

    return { social, address, mapLink, billingAddress, billingMapLink, referralCode };
  }

  async onRemoveCard() {
    const cards = _.filter(
      _.get(this.props.user, 'payment.stripe.cards'),
      (c, i) => i !== this.state.removeCardIndex
    );
    const changes = [{ action: 'replace', path: 'payment.stripe.cards', value: cards }];
    await updateUser(this.props.user.id, changes);
    this.setState({ modal: false });
  }

  openRemoveCard(index) {
    this.setState({ removeCardIndex: index, modal: true });
  }

  toggleEditProfile() {
    this.setState({ editProfile: !this.state.editProfile });
  }

  render() {
    const { user } = this.props;
    if (!user) {
      return <Loader />;
    }
    const pages = [
      { name: 'Details', icon: 'user', to: `/users/${global.id()}`, com: Jobs },
      { name: 'All Jobs', icon: 'briefcase', to: `/users/${global.id()}/jobs`, com: Jobs },
      this.props.referrals.length && {
        name: 'Referrals',
        icon: 'share',
        to: `/users/${global.id()}/referrals`,
        com: Referrals,
      },
      { name: 'Activity Log', icon: 'align-justify', to: `/users/${global.id()}/log`, com: Log },
    ].filter(v => v);
    const activePage = pages.find(p => p.to === global.location.pathname);
    if (!activePage) {
      return null;
    }
    const { social, address, mapLink, billingAddress, billingMapLink, referralCode } = this.state;
    return (
      <section id="Admin">
        <main id="cx-profile">
          <div className="cx-profile-body">
            <Modal title="Remove Card" onSubmit={this.onRemoveCard}>
              <h1>Are you sure?</h1>
            </Modal>
            <div className="cx-profile-left">
              {/*= =========MAP============ */}
              <div className="cx-profile-map">
                <div className="h-20 w-full bg-teal-50 bg-cover" />
              </div>
              <div
                className="cx-profile-left--header text-center"
                style={{ marginTop: -60, background: 'none' }}
              >
                <div className="d-flex justify-content-center">
                  <Avatar name={_.get(user, 'profile.fullName')} size={50} />
                </div>

                <div className="py-2">
                  <h5 className="text-dark text-center">{_.get(user, 'profile.fullName')}</h5>
                  <p className="text-muted text-center" style={{ fontWeight: 500 }}>
                    {_.get(user, 'resume.currentCompany') &&
                      `Works at ${_.get(user, 'resume.currentCompany')} • `}
                    {_.reduce(_.values(user.roles).sort().map(_.startCase), (a, b) => `${a}, ${b}`)}
                  </p>
                </div>
                <Row className="no-gutters">
                  <Col>
                    <button className="w-100 white" onClick={this.toggleEditProfile}>
                      Edit Profile
                    </button>
                    <EditDetails isOpen={this.state.editProfile} toggle={this.toggleEditProfile} />
                  </Col>
                </Row>
              </div>

              {/*= =========CONTACT============ */}
              <div className="border-top border-bottom px-3 py-3" id="DMenu">
                <label className="text-muted" style={{ fontWeight: 800 }}>
                  CONTACT
                </label>
                {/*= =========PHONE============ */}
                <div className="d-flex align-items-start py-2">
                  <Icon color="#C5CDD8" name="phone" size={16} />
                  <div className="flex-1 pl-2">
                    {/* PRIMARY PHONE */}
                    {_.get(user, 'profile.phone') ? (
                      <PopOver
                        button={
                          <p className="text-blue">
                            {_.get(user, 'profile.phone') || 'N/A'}{' '}
                            <label className="text-muted">(primary)</label>
                          </p>
                        }
                        items={
                          <div>
                            <DropdownItem>
                              <CopyToClipboard
                                text={_.get(user, 'profile.phone', '')}
                                onCopy={() => this.setState({ copied: true })}
                              >
                                <div className="align-center flex">
                                  <Icon name="content_copy" size={16} />
                                  &nbsp;&nbsp; Copy to clipboard
                                </div>
                              </CopyToClipboard>
                            </DropdownItem>
                            <DropdownItem>
                              <a href={`tel:${_.get(user, 'profile.phone', '')}`}>
                                <div className="align-center flex">
                                  <Icon name="phone" size={16} />
                                  &nbsp;&nbsp; Call number
                                </div>
                              </a>
                            </DropdownItem>
                          </div>
                        }
                      />
                    ) : (
                      <p className="text-muted">No primary number</p>
                    )}
                    {/* SECONDARY PHONE */}
                    {_.get(user, 'profile.altPhone') && (
                      <PopOver
                        button={
                          <p className="text-blue">
                            {_.get(user, 'profile.altPhone') || 'N/A'}{' '}
                            <label className="text-muted">(secondary)</label>
                          </p>
                        }
                        items={
                          <div>
                            <DropdownItem>
                              <CopyToClipboard
                                text={_.get(user, 'profile.altPhone', '')}
                                onCopy={() => this.setState({ copied: true })}
                              >
                                <div className="align-center flex">
                                  <Icon name="content_copy" size={16} />
                                  &nbsp;&nbsp; Copy to clipboard
                                </div>
                              </CopyToClipboard>
                            </DropdownItem>
                            <DropdownItem>
                              <a href={`tel:${_.get(user, 'profile.altPhone', '')}`}>
                                <div className="align-center flex">
                                  <Icon name="phone" size={16} />
                                  &nbsp;&nbsp; Call number
                                </div>
                              </a>
                            </DropdownItem>
                          </div>
                        }
                      />
                    )}
                    {/* GOOGLE PHONE */}
                    {_.get(user, 'metadata.gmailSupportNumber') && (
                      <PopOver
                        button={
                          <p className="text-blue">
                            {_.get(user, 'metadata.gmailSupportNumber') || 'N/A'}{' '}
                            <label className="text-muted">(Google Voice)</label>
                          </p>
                        }
                        items={
                          <div>
                            <DropdownItem>
                              <CopyToClipboard
                                text={_.get(user, 'metadata.gmailSupportNumber', '')}
                                onCopy={() => this.setState({ copied: true })}
                              >
                                <div className="align-center flex">
                                  <Icon name="content_copy" size={16} />
                                  &nbsp;&nbsp; Copy to clipboard
                                </div>
                              </CopyToClipboard>
                            </DropdownItem>
                            <DropdownItem>
                              <a href={`tel:${_.get(user, 'metadata.gmailSupportNumber', '')}`}>
                                <div className="align-center flex">
                                  <Icon name="phone" size={16} />
                                  &nbsp;&nbsp; Call number
                                </div>
                              </a>
                            </DropdownItem>
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>

                {/*= =========EMAIL============ */}
                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="email" size={16} />
                  <div className="flex-1 pl-2">
                    {user.email ? (
                      <div>
                        <PopOver
                          button={
                            <p className="text-blue">
                              {user.email} <label className="text-muted">(primary)</label>
                            </p>
                          }
                          items={
                            <div>
                              <DropdownItem>
                                <CopyToClipboard
                                  text={_.get(user, 'email', '')}
                                  onCopy={() => this.setState({ copied: true })}
                                >
                                  <div className="align-center flex">
                                    <Icon name="content_copy" size={16} />
                                    &nbsp;&nbsp; Copy to clipboard
                                  </div>
                                </CopyToClipboard>
                              </DropdownItem>
                              <DropdownItem>
                                <a href={`mailto:${_.get(user, 'email', '')}`}>
                                  <div className="align-center flex">
                                    <Icon name="email" size={16} />
                                    &nbsp;&nbsp; Send email
                                  </div>
                                </a>
                              </DropdownItem>
                            </div>
                          }
                        />
                        {_.get(user, 'profile.altEmail') && (
                          <PopOver
                            button={
                              <p className="text-blue">
                                {user.profile.altEmail}{' '}
                                <label className="text-muted">(secondary)</label>
                              </p>
                            }
                            items={
                              <div>
                                <DropdownItem>
                                  <CopyToClipboard
                                    text={_.get(user, 'profile.altEmail', '')}
                                    onCopy={() => this.setState({ copied: true })}
                                  >
                                    <div className="align-center flex">
                                      <Icon name="content_copy" size={16} />
                                      &nbsp;&nbsp; Copy to clipboard
                                    </div>
                                  </CopyToClipboard>
                                </DropdownItem>
                                <DropdownItem>
                                  <a href={`mailto:${_.get(user, 'profile.altEmail', '')}`}>
                                    <div className="align-center flex">
                                      <Icon name="email" size={16} />
                                      &nbsp;&nbsp; Send email
                                    </div>
                                  </a>
                                </DropdownItem>
                              </div>
                            }
                          />
                        )}
                      </div>
                    ) : (
                      <d className="d-flex align-items-start py-2">
                        <Icon color="#C5CDD8" name="email" size={16} />
                        <div className="flex-1 pl-2">
                          <p className="text-muted">No email</p>
                        </div>
                      </d>
                    )}
                  </div>
                </div>
                {/*= =========ADDRESS============ */}

                {address ? (
                  <PopOver
                    button={
                      <div className="d-flex align-items-start py-2">
                        <Icon color="#C5CDD8" name="home" size={16} />
                        <div className="flex-1 pl-2">
                          <p className="text-blue">{address}</p>
                        </div>
                      </div>
                    }
                    items={
                      <div>
                        <DropdownItem>
                          <CopyToClipboard
                            text={address}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <div className="align-center flex">
                              <Icon name="content_copy" size={16} />
                              &nbsp;&nbsp; Copy to clipboard
                            </div>
                          </CopyToClipboard>
                        </DropdownItem>
                        <DropdownItem>
                          <a href={mapLink} target="_blank" rel="noopener noreferrer">
                            <div className="align-center flex">
                              <Icon name="place" size={16} />
                              &nbsp;&nbsp; Open in Google Maps
                            </div>
                          </a>
                        </DropdownItem>
                      </div>
                    }
                  />
                ) : (
                  <div className="d-flex align-items-start py-2">
                    <Icon color="#C5CDD8" name="home" size={16} />
                    <div className="flex-1 pl-2">
                      <p className="text-muted">No address</p>
                    </div>
                  </div>
                )}
              </div>

              {/*= =========BILLING============ */}

              <div className="border-bottom px-3 py-3" id="DMenu">
                <label className="text-muted" style={{ fontWeight: 800 }}>
                  BILLING
                </label>

                <div className="d-flex align-items-start py-2">
                  <Icon color="#C5CDD8" name="credit_card_outline" size={16} />
                  <div className="flex-1 pl-2">
                    {!_.get(user, 'payment.stripe.cards') ? (
                      <p className="text-muted">No card</p>
                    ) : _.get(user, 'payment.stripe.cards').length === 0 ? (
                      <p className="text-muted">No card added</p>
                    ) : (
                      _.get(user, 'payment.stripe.cards').map((t, n) => (
                        <p key={n} className="lh-2 d-flex">
                          <b>{t.brand}</b> {t.last4}{' '}
                          <span className="text-muted badge">
                            (expires {t.exp_month}/{t.exp_year})
                          </span>
                          <div className="flex-1" />
                          <Icon
                            name="close_thick"
                            className="pointer"
                            onClick={() => this.openRemoveCard(n)}
                            size={24}
                          />
                        </p>
                      ))
                    )}
                  </div>
                </div>

                {billingAddress ? (
                  <PopOver
                    button={
                      <div className="d-flex align-items-start py-2">
                        <Icon color="#C5CDD8" name="home" size={16} />
                        <div className="flex-1 pl-2">
                          <p className="text-blue">{billingAddress}</p>
                        </div>
                      </div>
                    }
                    items={
                      <div>
                        <DropdownItem>
                          <CopyToClipboard
                            text={billingAddress}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <div className="align-center flex">
                              <Icon name="content_copy" size={16} />
                              &nbsp;&nbsp; Copy to clipboard
                            </div>
                          </CopyToClipboard>
                        </DropdownItem>
                        <DropdownItem>
                          <a href={billingMapLink} target="_blank" rel="noopener noreferrer">
                            <div className="align-center flex">
                              <Icon name="place" size={16} />
                              &nbsp;&nbsp; Open in Google Maps
                            </div>
                          </a>
                        </DropdownItem>
                      </div>
                    }
                  />
                ) : (
                  <div className="d-flex align-items-start py-2">
                    <Icon color="#C5CDD8" name="home" size={16} />
                    <div className="flex-1 pl-2">
                      <p className="text-muted">No address</p>
                    </div>
                  </div>
                )}
              </div>

              {/*= =========ACCOUNT============ */}

              <div className="border-bottom px-3 py-3" id="DMenu">
                <label className="text-muted" style={{ fontWeight: 800 }}>
                  ACCOUNT DETAILS
                </label>
                <div className="d-flex align-items-start py-2">
                  <Icon color="#C5CDD8" name="check_circle_outline" size={16} />
                  <div className="flex-1 pl-2">
                    <p>Status: {_.startCase(_.get(user, 'status'))}</p>
                  </div>
                </div>
                <div className="d-flex align-items-start py-2">
                  <Icon color="#C5CDD8" name="laptop" size={16} />
                  <div className="flex-1 pl-2">
                    <p>Created {m(_.get(user, 'createTime')).format('M/D/YY, h:mmA')}</p>
                  </div>
                </div>

                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="home" size={16} />
                  <div className="d-flex flex-1 pl-2">
                    <p>Referral:&nbsp;</p>
                    <PopOver
                      button={<p className="text-blue">{referralCode}</p>}
                      items={
                        <div>
                          <DropdownItem>
                            <CopyToClipboard
                              text={referralCode}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <div className="align-center flex">
                                <Icon name="content_copy" size={16} />
                                &nbsp;&nbsp; Copy code
                              </div>
                            </CopyToClipboard>
                          </DropdownItem>
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="d-flex align-items-start py-2">
                  <Icon color="#C5CDD8" name="laptop" size={16} />
                  <div className="flex-1 pl-2">
                    <p>
                      Referral Source:{' '}
                      {_.get(user, 'profile.referredBy') ? (
                        `${_.get(user, 'profile.referredBy')}`
                      ) : (
                        <span className="text-muted">none</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="home" size={16} />
                  <div className="d-flex flex-1 pl-2">
                    <p>ID:&nbsp;</p>
                    <PopOver
                      button={<p className="text-blue">{user.id}</p>}
                      items={
                        <div>
                          <DropdownItem>
                            <CopyToClipboard
                              text={user.id}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <div className="align-center flex">
                                <Icon name="content_copy" size={16} />
                                &nbsp;&nbsp; Copy code
                              </div>
                            </CopyToClipboard>
                          </DropdownItem>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="social-block px-3 py-3">
                <label className="text-muted" style={{ fontWeight: 800 }}>
                  SOCIAL PROFILES
                </label>
                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="facebook" size={16} />
                  <div className="flex-1 pl-2">
                    {social.facebook ? (
                      <p className="text-blue">Connected</p>
                    ) : (
                      <p className="text-muted">none</p>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="google_plus" size={16} />
                  <div className="flex-1 pl-2">
                    {social.google ? <p>Connected</p> : <p className="text-muted">none</p>}
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <Icon color="#C5CDD8" name="linkedin" size={16} />
                  <div className="flex-1 pl-2">
                    {social.linkedIn ? (
                      <a href={social.linkedIn} target="_blank" rel="noopener noreferrer">
                        <p className="text-blue">{social.linkedIn}</p>
                      </a>
                    ) : (
                      <p className="text-muted">none</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cx-profile-right">
              <ul className="no-list inline-list cx-tabs">
                {pages.map(
                  (p, n) =>
                    n !== 0 && (
                      <li key={p.name} className="cx-tabs-item">
                        <Link
                          className={`nav-link${p.name === activePage.name ? ' active' : ''}`}
                          to={p.to}
                        >
                          <p>{p.name}</p>
                        </Link>
                      </li>
                    )
                )}
              </ul>

              <div className="cx-tabs--body">{React.createElement(activePage.com, { user })}</div>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export default connect(s => {
  const path = s.router.location.pathname;
  const user = s.users[global.id()];
  const referrals = _.values(_.get(user, 'metadata.referrals'));
  return { path, user, referrals };
})(Edit);
