import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import PropTypes from 'prop-types';
import Badge from '../../core/Badge';
import Text from '../../core/Text';

export default function Popup({
  customer,
  endTime,
  isSealedSystem,
  proStatus,
  startTime,
  timezone,
  title,
}) {
  return (
    <div>
      <Text type="subtitle-2">
        {mt(startTime).tz(timezone).format('h A - ')}
        {mt(endTime).tz(timezone).format('h A, ddd M/D')}
      </Text>
      <Text type="subtitle-1">{_.startCase(proStatus)}</Text>
      <Text type="subtitle-1">
        {title} for {customer}
      </Text>
      {isSealedSystem && <Badge color="primary">Sealed System</Badge>}
    </div>
  );
}

Popup.propTypes = {
  customer: PropTypes.string.isRequired,
  endTime: U.timePropType.isRequired,
  isSealedSystem: PropTypes.bool.isRequired,
  proStatus: PropTypes.oneOf(W.visitProStatusOptions),
  startTime: U.timePropType.isRequired,
  timezone: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
