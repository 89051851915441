import React from 'react';
import { Button, Modal, Text } from '@/components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function HomeWarrantyModal({ isOpen, onClose }: Props): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Home Warranty" />
      <Modal.Body className="p-12 pt-6">
        <Text className="text-center" type="headline-6">
          Is your appliance covered under a warranty?
        </Text>
        <Text className="mt-4 text-center">
          If your appliance is covered under warranty, you will have to call them directly.
        </Text>
        <Text className="mt-6 text-center" type="button">
          Already have an existing booking with us?
        </Text>
        <Button className="mx-auto mt-6" href="/login">
          Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}
