import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Text } from '@/components';

type Props = {
  disabled?: boolean;
  error?: string;
  nextText?: string;
  onNext: () => Promise<void> | void;
  // preserve the space the footer takes up on the page while it is floating, eg to
  // prevent content from being inaccessible and covered by the footer
  preserveLayoutWhileFloating?: boolean;
};

export default function Footer({
  disabled,
  error,
  nextText = 'Next',
  onNext,
  preserveLayoutWhileFloating = true,
}: Props): JSX.Element {
  return (
    <div
      className={classNames({
        'h-20 md:h-auto': preserveLayoutWhileFloating,
      })}
    >
      <div className="fixed bottom-0 left-0 right-0 mt-5 border-t border-grey-medium bg-white p-4 shadow-floating md:static md:border-0 md:px-0 md:shadow-none">
        {!!error && (
          <Text className="mb-4" color="danger">
            {error}
          </Text>
        )}
        <Button className="w-full" disabled={disabled} onClick={onNext}>
          {nextText}
        </Button>
      </div>
    </div>
  );
}
