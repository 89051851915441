import React from 'react';
import { U, WorkOrder } from '@nanaio/util';
import m from 'moment';

type Props = {
  workOrder: WorkOrder;
};

export default function TermsAcceptedDate({ workOrder }: Props): JSX.Element {
  const timezone = U.timezone(workOrder.tasks[0].serviceAddress);

  return (
    <div className="border-bottom py-4">
      <p className="weight-600">Terms and Conditions</p>
      <div className="d-flex justify-content-between py-2">
        <label>Cx Accept Date</label>
        <div>
          {workOrder.cxTermsAcceptDate
            ? m(workOrder.cxTermsAcceptDate).tz(timezone).format('MMMM D, YYYY')
            : 'Not Yet Accepted'}
        </div>
      </div>
    </div>
  );
}
