import React from 'react';
import { Card, CardActions, CardContent, Collapse, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@/components';

const styles = theme => ({
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class RecipeReviewCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes, link, header, content } = this.props;

    return (
      <Card className={classes.card}>
        <div className="m-card-header d-flex align-center">
          <div className="flex-1 px-3">{header}</div>

          <CardActions className={classes.actions} disableActionSpacing>
            <IconButton onClick={link} aria-expanded={this.state.expanded} aria-label="Show more">
              <Icon name="edit" size={25} />
            </IconButton>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <Icon name="expand_more" size={25} />
            </IconButton>
          </CardActions>
        </div>

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div className="m-card-content">{content}</div>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

export default withStyles(styles)(RecipeReviewCard);
