import React from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import Icon from './Icon';

type Props = {
  disabled?: boolean;
  checked?: boolean;
  className?: string;
};

export default function CheckRadio({ disabled, checked, className }: Props): JSX.Element {
  const classes = twMerge(
    classNames(
      'rounded-full h-8 w-8 flex items-center justify-center',
      {
        'border-1.5 border-primaryCTA': !checked,
        'bg-primaryCTA': checked,
        'border-icons-grey bg-grey-light': disabled,
      },
      className
    )
  );

  return (
    <div className={classes}>
      {checked && (
        <Icon
          className={classNames({
            'text-white': !disabled,
            'text-icons-grey': disabled,
          })}
          name="check"
          size={24}
        />
      )}
    </div>
  );
}
