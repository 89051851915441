import React from 'react';
import { NotCoveredService, Task } from '@nanaio/util';
import _ from 'lodash';
import { APIError, Text } from '@/components';
import { useLegacyAPI } from '@/hooks';

const NO_ORG_FOUND = 'NO_ORG_FOUND';

type Props = {
  task: Task;
};
export default function CoveragePolicy({ task }: Props): JSX.Element {
  const orgId = task.customer?.org?.id || NO_ORG_FOUND;
  const orgName = task.customer?.org?.name || NO_ORG_FOUND;

  const {
    loading: isLoadingOrgServices,
    error: loadingOrgServicesError,
    data: orgServices,
  } = useLegacyAPI<NotCoveredService[]>(`orgs/${orgId}/notCoveredServices`);

  if (isLoadingOrgServices || orgName === NO_ORG_FOUND) {
    return <></>;
  }

  return (
    <>
      {loadingOrgServicesError && (
        <APIError error={loadingOrgServicesError as string} text="Error loading vendor services" />
      )}
      <div>
        <Text className="text-secondary" type="page-title">
          Coverage Policy
        </Text>
        <Text className="mt-8 text-secondary-5" type="label">
          Home Warranty Provider:
        </Text>
        <Text className="mt-2">{orgName}</Text>
        {!_.isEmpty(orgServices) && (
          <>
            <Text className="mb-2 mt-8 text-secondary-5" type="label">
              Items Not Covered:
            </Text>
            {orgServices?.map(service => (
              <Text key={service.key}>&nbsp;•&nbsp;&nbsp;{service.name}</Text>
            ))}
          </>
        )}
      </div>
      <div className="my-10 h-[1px] bg-grey-medium" />
    </>
  );
}
