import React from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import propTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Search } from '@/com/ui/form';
import Loader from '@/com/ui/loader';
import { loadProOptions } from '@/com/util';
import { Icon, Table as TableComponent } from '@/components';

// Renders a chart showing a pro's daily performance for several metrics
export default class Performance extends React.Component {
  static propTypes = { proId: propTypes.string };

  static childContextTypes = { t: propTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      appliances: [],
      brandOptions: [],
      chartData: [],
      colors: ['#3498db', '#2ecc71', '#9b59b6', '#e67e22', '#f1c40f', '#e74c3c', '#1abc9c'],
      metricMap: {},
      metrics: [],
      proId: p.proId,
      query: {},
      viewBy: 'appliance',
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onTableFilterChange = this.onTableFilterChange.bind(this);
    this.renderScoreCell = this.renderScoreCell.bind(this);
    this.updateChart = this.updateChart.bind(this);
    loadProOptions().then(proOptions => this.setState({ proOptions }));
    U.api('get', 'performance/config').then(config => {
      const metricMap = _.keyBy(config.metrics, 'name');
      this.setState({ ...config, metricMap });
      if (p.proId) {
        this.updateChart(this.state);
      }
    });
  }

  onFilterChange(key, value) {
    const query = _.merge({}, this.state);
    query[key] = value;
    this.updateChart(query);
  }

  async onTableFilterChange({ key, value }) {
    const query = _.pick(this.state, ['brand', 'proId', 'serviceId']);
    if (key === 'brand') {
      [query.brand] = value;
    } else if (key === 'proName') {
      [query.proId] = value;
    } else if (key === 'serviceName') {
      [query.serviceId] = value;
    } else {
      return;
    } // only the above columns are searchable
    this.updateChart(query);
  }

  renderScoreCell(metric, type) {
    return (
      <td>
        {metric && (
          <div>
            <Icon className="mr-2" name="star" size={16} />
            <span className="mr-3">{metric.score}</span>
            {_.get(type, 'type') === 'duration' && _.round(U.millisecondsToDays(metric.value), 2)}
            {_.get(type, 'type') === 'number' && _.round(metric.value, 2)}
            {_.get(type, 'type') === 'ratio' && U.toPercent(metric.value)}
          </div>
        )}
      </td>
    );
  }

  async updateChart(state) {
    this.setState(state);
    if (!state.proId) {
      return;
    }
    this.setState({ loading: true });
    const request = {
      ..._.pick(state, ['appliance', 'brand', 'proId']),
      metric: _.camelCase(state.metric),
      requests: [{ daily: true, dayCount: 365, viewBy: state.viewBy }],
    };
    const r = await U.api('post', 'performance/score', request);
    const ratings = r[0];
    let brandOptions = [];
    const chartData = ratings
      .map(rating => {
        const out = { name: m(rating.time).format('M/D') };
        _.map(rating, (v, key) => {
          if (_.get(v, 'score')) {
            out[_.startCase(key)] = v.score;
            if (state.viewBy === 'brand' && key !== 'overall') {
              brandOptions.push(key);
            }
          }
        });
        return out;
      })
      .reverse();
    brandOptions = _.uniqBy(brandOptions);
    this.setState({ brandOptions, loading: false, chartData, ratings });
  }

  render() {
    let appliances;
    if (this.state.viewBy === 'appliance') {
      appliances = this.state.appliances.filter(
        v => !this.state.appliance || this.state.appliance === v
      );
    }
    const metric = this.state.metricMap[_.camelCase(this.state.metric) || 'overall'];
    return (
      <div className="p-3">
        <div className="relative" style={{ overflowX: 'auto', width: '100%' }}>
          {/* LINE CHART */}
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={this.state.chartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                wrapperStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #e4e4e4',
                  borderRadius: '8px',
                }}
                contentStyle={{
                  backgroundColor: 'transparent',
                  border: '0px solid #e4e4e4',
                  borderRadius: '8px',
                }}
              />
              <Legend align="left" />
              <Line
                dot={false}
                type="monotone"
                dataKey={_.startCase(this.state.metric) || 'Overall'}
                stroke="black"
              />
              {this.state.viewBy === 'appliance' &&
                this.state.appliances.map((v, i) => (
                  <Line
                    dot={false}
                    key={i}
                    type="monotone"
                    dataKey={_.startCase(v)}
                    stroke={this.state.colors[i]}
                  />
                ))}
              {this.state.viewBy === 'brand' &&
                this.state.brandOptions.map((b, i) => (
                  <Line
                    dot={false}
                    key={i}
                    type="monotone"
                    dataKey={b}
                    stroke={this.state.colors[i]}
                  />
                ))}
              {this.state.viewBy === 'metric' &&
                this.state.metrics.map((v, i) => (
                  <Line
                    dot={false}
                    key={i}
                    type="monotone"
                    dataKey={_.startCase(v.name)}
                    stroke={this.state.colors[i]}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
        {/* FILTERS */}
        <div className="row">
          <Search
            id="proId"
            label="Pro"
            options={this.state.proOptions}
            className="col"
            onChange={v => this.onFilterChange('proId', v)}
            menuPortalTarget={document.body}
          />
          <Search
            id="viewBy"
            options={this.state.breakdowns}
            className="col"
            onChange={v => this.onFilterChange('viewBy', v)}
            menuPortalTarget={document.body}
            cap
          />
          <Search
            id="appliance"
            options={this.state.appliances}
            className="col"
            onChange={v => this.onFilterChange('appliance', v)}
            menuPortalTarget={document.body}
            cap
          />
          <Search
            id="metric"
            options={this.state.metrics.map(m => _.startCase(m.name))}
            className="col"
            onChange={v => this.onFilterChange('metric', v)}
            menuPortalTarget={document.body}
            cap
          />
          <Search
            id="brand"
            options={this.state.brands}
            className="col"
            onChange={v => this.onFilterChange('brand', v)}
            menuPortalTarget={document.body}
          />
        </div>
        {/* RATINGS */}
        {this.state.loading && <Loader />}
        {this.state.ratings && !this.state.loading && (
          <table className="table-sm table-striped table-hover table">
            <thead>
              <tr>
                <th>Date</th>
                <th>{_.startCase(this.state.metric) || 'Overall'}</th>
                {this.state.viewBy === 'appliance' && (
                  <>
                    {appliances.map(a => (
                      <th key={a}>{_.startCase(a)}</th>
                    ))}
                  </>
                )}
                {this.state.viewBy === 'brand' && (
                  <>
                    {this.state.brandOptions.map(b => (
                      <th key={b}>{b}</th>
                    ))}
                  </>
                )}
                {this.state.viewBy === 'metric' && (
                  <>
                    {this.state.metrics.map(m => (
                      <th key={m.name}>{_.startCase(m.name)}</th>
                    ))}
                  </>
                )}
                <th>Job Count</th>
              </tr>
            </thead>
            {U.timeSort(this.state.ratings, 'time')
              .reverse()
              .map((r, i) => (
                <tr key={i}>
                  <td>{m(r.time).format('M/D/YY')}</td>
                  {this.renderScoreCell(r[_.camelCase(this.state.metric) || 'overall'], metric)}
                  {this.state.viewBy === 'appliance' && (
                    <>{appliances.map(a => this.renderScoreCell(r[a], metric))}</>
                  )}
                  {this.state.viewBy === 'brand' && (
                    <>{this.state.brandOptions.map(b => this.renderScoreCell(r[b], metric))}</>
                  )}
                  {this.state.viewBy === 'metric' && (
                    <>{this.state.metrics.map(m => this.renderScoreCell(r[m.name], m))}</>
                  )}
                  <td>{r.jobCount}</td>
                </tr>
              ))}
          </table>
        )}
        {/* BREAKPOINTS */}
        <table
          className="table-sm table-striped table-hover mt-5 table"
          style={{ width: '1000px' }}
        >
          <thead>
            <tr>
              <th>Metric</th>
              <th>Weight</th>
              {_.times(6, i => (
                <th key={i}>{i} star</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.metrics, (m, i) => (
              <tr key={i}>
                <td style={{ width: '250px' }}>{_.startCase(m.name)}</td>
                <td>{U.toPercent(m.weight)}</td>
                {m.breakpoints.map((v, i) => {
                  let value;
                  if (m.type === 'ratio') {
                    value = U.toPercent(v);
                  } else if (m.type === 'number') {
                    value = v;
                  } else if (m.type === 'duration') {
                    value = `${U.millisecondsToDays(v)} days`;
                  }
                  return (
                    <td key={i} style={{ width: '100px' }}>
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {/* DATA */}
        <div className="relative h-screen">
          <TableComponent
            {...Table.databases.default.performance}
            embed
            filters={[
              { key: 'brand', value: { include: U.toArray(this.state.brand) } },
              { key: 'proName', value: { include: U.toArray(this.state.proId) } },
              { key: 'serviceName', value: { include: U.toArray(this.state.serviceId) } },
            ]}
            onFilterChange={this.onTableFilterChange}
          />
        </div>
      </div>
    );
  }
}
