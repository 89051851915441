import React, { useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import { Modal, Text } from '@/components';
import FormStep from './FormStep';
import InformationStep from './InformationStep';

type Props = {
  task: Task;
};

export default function CloseTask({ task }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(1);

  return (
    <>
      <div className="mt-4 flex space-x-2">
        <Text color="grey.dark">Service no longer needed?</Text>
        <Text
          color="primaryCTA"
          onClick={() => {
            setStepIndex(1);
            setOpen(true);
          }}
          type="button"
        >
          Close my repair request
        </Text>
      </div>
      <Modal
        hideOnBackdropClick={false}
        height={1000}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {stepIndex === 1 && <InformationStep onStepClick={() => setStepIndex(2)} task={task} />}
        {stepIndex === 2 && (
          <FormStep
            onStepClick={() => {
              setOpen(false);
            }}
            task={task}
          />
        )}
      </Modal>
    </>
  );
}
