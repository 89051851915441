import React from 'react';
import Button from '@/components/core/Button';
import Text from '@/components/core/Text';
import { companyName } from '@/config/const';

type Props = {
  handleHelpCenterClick: () => void;
};

export default function LandingPageHelpButton({ handleHelpCenterClick }: Props): JSX.Element {
  return (
    <>
      <Text type="page-title">Need Help?</Text>

      <Text className="mt-2 text-grey-dark">
        Visit the {companyName} Help Center to find answers to many common questions.
      </Text>

      <Button
        variant="outline"
        className="mt-4 w-full"
        size="large"
        onClick={handleHelpCenterClick}
      >
        Visit Help Center
      </Button>
    </>
  );
}
