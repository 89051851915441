type BrandLogos = {
  // should match case of Object.keys(U.brands)
  [key: string]: {
    // should be svg although png is supported
    img: string;
    // true if logo should not be displayed on public landing pages
    hidePublic?: boolean;
    // higher number for more popular brands
    // Based off data collected here https://docs.google.com/spreadsheets/d/1OUgUn4NI-r8E5aQGnf8K8rC97ezN2s2DKMI07TccUmQ/edit#gid=2106250375
    popularity: number;
    // name if used in a url (no spaces)
    url: string;
  };
};

export const brandLogos: BrandLogos = {
  'Air King': {
    img: '/img/logos/air-king.png',
    popularity: 0,
    url: 'AirKing',
  },
  'Alfresco Grills': {
    img: '/img/logos/Alfresco.png',
    popularity: 0,
    url: 'AlfrescoGrills',
  },
  'Allied Brass': {
    img: '/img/logos/Allied Brass.png',
    popularity: 0,
    url: 'AlliedBrass',
  },
  'Alto Shaam': {
    img: '/img/logos/AltoShaam.png',
    popularity: 0,
    url: 'AltoShaam',
  },
  Amana: {
    img: '/img/logos/Amana.svg',
    popularity: 5,
    url: 'Amana',
  },
  'American Range': {
    img: '/img/logos/American Range.png',
    popularity: 0,
    url: 'AmericanRange',
  },
  Asko: {
    img: '/img/logos/Asko.png',
    popularity: 0,
    url: 'Asko',
  },
  'Bakers Pride': {
    img: '/img/logos/Bakers Pride.png',
    popularity: 0,
    url: 'BakersPride',
  },
  Beko: {
    img: '/img/logos/Beko.png',
    popularity: 0,
    url: 'Beko',
  },
  Bertazoni: {
    img: '/img/logos/Bertazzoni.png',
    popularity: 1,
    url: 'Bertazoni',
  },
  'Blue Star': {
    img: '/img/logos/Blue Star.png',
    popularity: 0,
    url: 'BlueStar',
  },
  Bosch: {
    img: '/img/logos/Bosch.svg',
    popularity: 20,
    url: 'Bosch',
  },
  Broan: {
    img: '/img/logos/Broan.png',
    popularity: 1,
    url: 'Broan',
  },
  'Broil King': {
    img: '/img/logos/Broil King.png',
    popularity: 0,
    url: 'BroilKing',
  },
  Burton: {
    img: '/img/logos/Burton.png',
    popularity: 0,
    url: 'Burton',
  },
  Dacor: {
    img: '/img/logos/Dacor.svg',
    popularity: 3,
    url: 'Dacor',
  },
  DCS: {
    img: '/img/logos/DCS.png',
    popularity: 0,
    url: 'DCS',
  },
  Electrolux: {
    img: '/img/logos/Electrolux.svg',
    popularity: 16,
    url: 'Electrolux',
  },
  Emerson: {
    img: '/img/logos/Emerson.png',
    popularity: 0,
    url: 'Emerson',
  },
  'Fisher & Paykel': {
    img: '/img/logos/FisherAndPaykel.svg',
    popularity: 3,
    url: 'Fisher&Paykel',
  },
  Frigidaire: {
    img: '/img/logos/Frigidaire.svg',
    popularity: 44,
    url: 'Frigidaire',
  },
  Gaggenau: {
    img: '/img/logos/Gaggenau.png',
    popularity: 0,
    url: 'Gaggenau',
  },
  GE: {
    img: '/img/logos/GE.svg',
    popularity: 17,
    url: 'GE',
  },
  'GE Profile': {
    img: '/img/logos/GE Profile.png',
    popularity: 0,
    url: 'GEProfile',
  },
  'GE Monogram': {
    img: '/img/logos/GE Monogram.svg',
    popularity: 4,
    url: 'GEMonogram',
  },
  Haier: {
    img: '/img/logos/Haier.png',
    popularity: 0,
    url: 'Haier',
  },
  Heartland: {
    img: '/img/logos/Heartland.png',
    popularity: 0,
    url: 'Heartland',
  },
  Hotpoint: {
    img: '/img/logos/Hotpoint.png',
    popularity: 0,
    url: 'Hotpoint',
  },
  Imperial: {
    img: '/img/logos/Imperial.png',
    popularity: 0,
    url: 'Imperial',
  },
  'Jenn-Air': {
    img: '/img/logos/Jenn-Air.svg',
    popularity: 5,
    url: 'JennAir',
  },
  Kenmore: {
    img: '/img/logos/Kenmore.svg',
    popularity: 91,
    url: 'Kenmore',
  },
  'Kenmore Elite': {
    img: '/img/logos/Kenmore Elite.png',
    popularity: 0,
    url: 'KenmoreElite',
  },
  'Kitchen Aid': {
    img: '/img/logos/KitchenAid.svg',
    popularity: 18,
    url: 'KitchenAid',
  },
  Lennox: {
    img: '/img/logos/Lennox.png',
    popularity: 0,
    url: 'Lennox',
  },
  LG: {
    img: '/img/logos/LG.svg',
    popularity: 30,
    hidePublic: true,
    url: 'LG',
  },
  'Magic Chef': {
    img: '/img/logos/MagicChef.svg',
    popularity: 2,
    url: 'MagicChef',
  },
  Marvel: {
    img: '/img/logos/Marvel.png',
    popularity: 0,
    url: 'Marvel',
  },
  Maytag: {
    img: '/img/logos/Maytag.svg',
    popularity: 27,
    url: 'Maytag',
  },
  Miele: {
    img: '/img/logos/Miele.svg',
    popularity: 38,
    url: 'Miele',
  },
  Panasonic: {
    img: '/img/logos/Panasonic.png',
    popularity: 0,
    url: 'Panasonic',
  },
  Samsung: {
    img: '/img/logos/Samsung.svg',
    popularity: 486,
    url: 'Samsung',
  },
  Scotsman: {
    img: '/img/logos/Scotsman.png',
    popularity: 0,
    url: 'Scotsman',
  },
  Sharp: {
    img: '/img/logos/Sharp.png',
    popularity: 0,
    url: 'Sharp',
  },
  Speedqueen: {
    img: '/img/logos/SpeedQueen.png',
    popularity: 0,
    url: 'SpeedQueen',
  },
  'Sub Zero': {
    img: '/img/logos/SubZero.svg',
    popularity: 2,
    url: 'SubZero',
  },
  Summit: {
    img: '/img/logos/summit.png',
    popularity: 0,
    url: 'Summit',
  },
  Thermador: {
    img: '/img/logos/Thermador.svg',
    popularity: 7,
    url: 'Thermador',
  },
  True: {
    img: '/img/logos/True.png',
    popularity: 0,
    url: 'True',
  },
  Viking: {
    img: '/img/logos/Viking.svg',
    popularity: 5,
    url: 'Viking',
  },
  'Vulcan Equipment': {
    img: '/img/logos/Vulcan.png',
    popularity: 0,
    url: 'VulcanEquipment',
  },
  Wedgewood: {
    img: '/img/logos/Wedgewood.png',
    popularity: 1,
    url: 'Wedgewood',
  },
  Whirlpool: {
    img: '/img/logos/Whirlpool.svg',
    popularity: 45,
    url: 'Whirlpool',
  },
  Wolf: {
    img: '/img/logos/Wolf.png',
    popularity: 0,
    url: 'Wolf',
  },
  Zephyr: {
    img: '/img/logos/Zephyr.png',
    popularity: 0,
    url: 'Zephyr',
  },
};
