import React, { useCallback, useEffect, useState } from 'react';
import { Address, PartVendor } from '@nanaio/util';
import { OpenHours, Service, VendorLocation } from '@nanaio/util/dist/partVendor';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import {
  Alert,
  APIError,
  Avatar,
  Form,
  FormControl,
  Input,
  InputType,
  Loader,
  Modal,
  Text,
} from '@/components';
import { useLazyLegacyAPI } from '@/hooks';
import { idFromURL } from '@/utils';
import Hours from '../Profile/Hours';

const serviceOptions = Object.values(Service);

const DEFAULT_OPEN_HOURS = _.times(7, _.constant({}));
const DEFAULT_PART_VENDOR_LOCATION = {
  address: {
    county: '',
    formattedAddress: '',
    gTimezone: '',
    locality: '',
    postalCode: '',
    region: '',
    route: '',
    streetNumber: '',
  },
  phone: '',
  name: '',
  openHours: DEFAULT_OPEN_HOURS,
  services: [],
};

const getDefaultLocationName = (address?: Address): string => {
  if (!address) {
    return '';
  }
  return `${address.locality}, ${address.region}`;
};

type Props = {
  id?: string;
  isEdit?: boolean;
  isOpen: boolean;
  onRemove?: () => void;
  onSuccess: (data: PartVendor) => void;
  toggleOpen: () => void;
};

export default function EditPartVendorLocation({
  id: locationId,
  isEdit = false,
  isOpen,
  onRemove,
  onSuccess,
  toggleOpen,
}: Props): JSX.Element {
  const partVendorId = nullthrows(idFromURL());
  const [partVendor, setPartVendor] = useState<Partial<PartVendor>>({
    locations: [],
  });
  const locationIndex = locationId ? Number(locationId) - 1 : undefined;
  const [partVendorLocation, setPartVendorLocation] = useState<Partial<VendorLocation>>(
    DEFAULT_PART_VENDOR_LOCATION
  );
  const [hasCustomLocationName, setHasCustomLocationName] = useState(false);
  const [hasCustomHours, setHasCustomHours] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [prevAddress, setPrevAddress] = useState<Address | undefined>(undefined);

  partVendorLocation.openHours = partVendorLocation.openHours || [];

  const [updatePartVendor, updatePartVendorResponse] = useLazyLegacyAPI<PartVendor>(
    `partVendors/${partVendorId}`,
    {
      errorRender: ({ error }) => (
        <APIError
          className="mb-10"
          error={error}
          text={<>Unable to update part vendor. {error}</>}
        />
      ),
      method: 'put',
    }
  );

  const [loadPartVendor, loadPartVendorResponse] = useLazyLegacyAPI<PartVendor>(
    `partVendors/${partVendorId}`,
    {
      errorRender: ({ error }) => (
        <APIError className="mb-10" error={error} text={<>Unable to load part vendor. {error}</>} />
      ),
      method: 'get',
    }
  );

  useEffect(() => {
    if (partVendorId && !loadPartVendorResponse.data) {
      void loadPartVendor().then(data => {
        if (data) {
          setPartVendor(data);
          data.locations = data.locations || [];
          if (locationIndex !== undefined) {
            const location = data.locations[locationIndex];
            setPartVendorLocation(data.locations[locationIndex]);
            const hasCustomLocationName =
              location.name !== getDefaultLocationName(location.address);
            setHasCustomLocationName(hasCustomLocationName);
            location.openHours = location.openHours || [];
            const hasCustomHours = location.openHours.some(hours => hours.from || hours.to);
            setHasCustomHours(hasCustomHours);
          }
        }
      });
    }
  }, [partVendorId, loadPartVendor, loadPartVendorResponse.data, locationIndex]);

  useEffect(() => {
    if (
      prevAddress?.locality !== partVendorLocation.address?.locality ||
      prevAddress?.region !== partVendorLocation.address?.region
    ) {
      setPrevAddress(partVendorLocation.address);
      if (!hasCustomLocationName && partVendorLocation.address) {
        const name = hasCustomLocationName
          ? partVendorLocation.name
          : getDefaultLocationName(partVendorLocation.address);
        setPartVendorLocation({
          ...partVendorLocation,
          name,
        });
      }
    }
  }, [hasCustomLocationName, partVendorLocation, prevAddress]);

  const handleRemove = useCallback(async () => {
    if (!partVendor.locations || locationIndex === undefined) {
      return;
    }
    partVendor.locations.splice(locationIndex, 1);
    const changes = [{ action: 'replace', path: 'locations', value: partVendor.locations }];
    const data = await updatePartVendor(changes);
    if (!data) {
      return;
    }
    if (onRemove) {
      onRemove();
      toggleOpen();
    }
  }, [locationIndex, onRemove, partVendor.locations, toggleOpen, updatePartVendor]);

  const submit = useCallback(async () => {
    if (!partVendorLocation.name) {
      return setError('Name required');
    }
    if (!partVendorLocation.address) {
      return setError('Address required');
    }
    if (!partVendorLocation.phone) {
      return setError('Phone required');
    }
    setError(null);

    partVendor.locations = partVendor.locations || [];
    if (locationIndex !== undefined) {
      partVendor.locations[locationIndex] = partVendorLocation as VendorLocation;
    } else {
      partVendor.locations.push(partVendorLocation as VendorLocation);
    }
    const changes = [{ action: 'replace', path: 'locations', value: partVendor.locations }];
    const data = await updatePartVendor(changes);
    if (!data) {
      return;
    }
    onSuccess(data);
  }, [locationIndex, onSuccess, partVendor, partVendorLocation, updatePartVendor]);

  const handleDayChange = useCallback(
    (openHours: OpenHours[]) => {
      setPartVendorLocation({
        ...partVendorLocation,
        openHours,
      });
    },
    [partVendorLocation]
  );

  const handleServiceChange = useCallback(
    (option: string, value: boolean) => {
      let services = partVendorLocation.services || [];
      if (services.includes(option as Service) && !value) {
        services = services.filter(service => service !== option);
      } else {
        services = services.concat(option as Service);
      }
      setPartVendorLocation({
        ...partVendorLocation,
        services,
      });
    },
    [partVendorLocation]
  );

  const handleUseCustomLocationNameChange = useCallback(
    (value: boolean) => {
      setHasCustomLocationName(value);
      if (!value) {
        setPartVendorLocation({
          ...partVendorLocation,
          name: getDefaultLocationName(partVendorLocation.address),
        });
      }
    },
    [partVendorLocation]
  );

  const handleUseCustomHours = useCallback(
    (value: boolean) => {
      setHasCustomHours(value);
      if (!value) {
        setPartVendorLocation({
          ...partVendorLocation,
          openHours: [...DEFAULT_OPEN_HOURS],
        });
      }
    },
    [partVendorLocation]
  );

  const partVendorName = _.startCase(partVendor.name) || '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleOpen}
      onRemove={handleRemove}
      onSave={submit}
      hideOnBackdropClick={false}
      width={1200}
    >
      <Modal.Header title={`${isEdit ? 'Edit' : 'Add'} ${partVendorName} Location`} />
      <Modal.Body className="p-4">
        {loadPartVendorResponse?.loading ? (
          <Loader isLoading />
        ) : (
          <>
            {error && (
              <Alert className="mb-10" variant="error">
                {error}
              </Alert>
            )}
            {updatePartVendorResponse.error}
            <Form
              className="grid grid-cols-1 gap-x-10 gap-y-10 lg:grid-cols-2"
              value={partVendorLocation}
              onChange={setPartVendorLocation}
            >
              <div className="flex flex-row">
                <Avatar
                  iconProps={{ name: 'business', color: 'white' }}
                  size="medium"
                  variant="circle"
                />
                <div className="flex-grow flex-col">
                  <Text type="label">Location Details</Text>
                  <Text color="grey.dark">Set the location profile</Text>
                  <Input
                    disabled={!hasCustomLocationName}
                    id="name"
                    label="Location Name"
                    type={InputType.TEXT}
                    className="mt-6"
                    helper={
                      hasCustomLocationName
                        ? 'Enter a custom location name'
                        : 'Enter an address to auto-populate the location name'
                    }
                    required
                  />
                  <Input
                    className="mb-2"
                    label="Use custom location name"
                    type={InputType.CHECK}
                    onChange={value => handleUseCustomLocationNameChange(value)}
                    value={hasCustomLocationName}
                  />
                  <Input
                    id="address"
                    label="Address"
                    type={InputType.ADDRESS}
                    className="mt-6"
                    value={partVendorLocation.address}
                  />
                  <Input
                    id="phone"
                    label="Phone"
                    type={InputType.PHONE}
                    className="mt-6"
                    required
                  />
                  <FormControl
                    className="mt-6"
                    label="Services"
                    tooltip="What services does this vendor location offer?"
                  >
                    <div className="mt-2">
                      {serviceOptions.map(option => (
                        <Input
                          className="mb-2"
                          key={option}
                          label={_.startCase(_.lowerCase(option))}
                          type={InputType.CHECK}
                          onChange={value => handleServiceChange(option, value)}
                          value={partVendorLocation.services?.includes(option)}
                        />
                      ))}
                    </div>
                  </FormControl>
                </div>
              </div>

              <div className="flex flex-row">
                <Avatar
                  iconProps={{ name: 'schedule', color: 'white' }}
                  size="medium"
                  variant="circle"
                />
                <div className="flex-grow flex-col">
                  <div className="mb-2">
                    <Text type="label">Location Hours</Text>
                    <Text color="grey.dark">Set the hours the business is open</Text>
                  </div>
                  <Input
                    className="mb-4"
                    label="Set custom hours for this location"
                    type={InputType.CHECK}
                    onChange={value => handleUseCustomHours(value)}
                    value={hasCustomHours}
                  />
                  <Hours
                    disabled={!hasCustomHours}
                    formKey="openHours"
                    onChange={handleDayChange}
                    openHours={partVendorLocation.openHours}
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer noValidate />
    </Modal>
  );
}
