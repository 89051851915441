import React, { useState } from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Modal, Text } from '@/components';

function Regions({ onClose, regions, selectedRegions, userId }) {
  const regionOptions = _.sortBy(_.values(regions), 'name');
  const [selectedRegionIds, setSelectedRegionIds] = useState(_.map(selectedRegions, 'id'));

  const renderOptions = type =>
    regionOptions
      .filter(region => region.type === type)
      .map(region => (
        <div
          className="flex cursor-pointer"
          key={region.id}
          onClick={() => setSelectedRegionIds(U.arrayToggle(selectedRegionIds, region.id))}
        >
          <Icon
            name={
              selectedRegionIds.some(id => id === region.id) ? 'check' : 'check_box_outline_blank'
            }
          />
          <Text className="ml-2">{region.name}</Text>
        </div>
      ));

  const save = async () => {
    await U.api(
      'put',
      `users/${userId}`,
      [{ path: 'metadata.regions', value: selectedRegionIds }],
      ['save']
    );
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Regions" />
      <Modal.Body className="p-4">
        <div className="grid grid-cols-2 gap-x-4">
          <div>
            <Text type="button">Markets</Text>
            {renderOptions('market')}
          </div>
          <div>
            <Text type="button">Regions</Text>
            {renderOptions('region')}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer onSave={save} />
    </Modal>
  );
}

Regions.propTypes = {
  onClose: PropTypes.func.isRequired,
  regions: PropTypes.objectOf(U.region.propType),
  selectedRegions: PropTypes.arrayOf(U.region.propType),
  userId: PropTypes.string.isRequired,
};

export default connect(s => ({
  regions: s.regions,
  selectedRegions: U.region.regionsFromReduxMe(s),
  userId: s.me.userId,
}))(Regions);
