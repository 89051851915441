import React, { useEffect, useMemo, useState } from 'react';
import type { NotCoveredService } from '@nanaio/util';
import _ from 'lodash';
import { Form,Input, Modal } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';

type Props = {
  allServices: NotCoveredService[];
  onClose: () => void;
  onSave: (services: NotCoveredService[]) => void;
  orgId: string;
  orgServices: NotCoveredService[];
};

export default function ({
  allServices,
  onClose,
  onSave,
  orgId,
  orgServices,
}: Props): JSX.Element | null {
  const [
    updateOrgNotCoveredServices,
    { loading: isUpdatingOrgServices, data: updatedOrgServices, error: updateOrgServicesError },
  ] = useLazyLegacyAPI<NotCoveredService[]>(`orgs/${orgId}/notCoveredServices`, { method: 'put' });

  const [form, setForm] = useState<{ serviceIds: string[] }>({
    serviceIds: _.map(orgServices, 'id'),
  });

  const options = useMemo(() => {
    return allServices.map(service => _.pick(service, ['id', 'name']));
  }, [allServices]);

  useEffect(() => {
    if (updatedOrgServices) {
      onSave(updatedOrgServices);
    }
  }, [updatedOrgServices, onSave]);

  return (
    <Form onChange={setForm} value={form}>
      <Modal isOpen onClose={onClose}>
        <Modal.Header title="Edit Not Covered Services" />

        <Modal.Body className="p-4">
          {updateOrgServicesError}
          {!updateOrgServicesError && (
            <Input sort multiple options={options} id="serviceIds" label="" />
          )}
        </Modal.Body>

        <Modal.Footer
          disableSaveButton={isUpdatingOrgServices}
          noValidate
          onClose={onClose}
          onSave={() => void updateOrgNotCoveredServices(form.serviceIds)}
        />
      </Modal>
    </Form>
  );
}
