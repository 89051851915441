import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import compression from 'browser-image-compression';
import _ from 'lodash';
import m from 'moment';
import { Icon, Text } from '@/components';
import { updateTask } from '../../../com/task';
import Loader from '../../../com/ui/loader';
import { apiOrigin } from '../../../config/const';

class Photos extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async onSave(event) {
    this.setState({ loading: true });
    const options = {
      maxSizeMB: 0.75,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const reader = new FileReader();
    if (_.startsWith(event.target.files[0].type, 'image')) {
      const blob = await compression(event.target.files[0], options);
      reader.readAsDataURL(blob);
    } else {
      reader.readAsDataURL(event.target.files[0]);
    }
    const This = this;
    reader.onloadend = async () => {
      const changes = [
        {
          action: 'add',
          path: 'metadata.taskProblemQuestions.pictures',
          value: reader.result,
        },
      ];
      const r = await updateTask(global.id(), changes);
      if (r.errMsg) {
        return This.setState({ error: r.errMsg, loading: false });
      }
      This.setState({ loading: false });
    };
  }

  async onRemove(id) {
    const changes = [
      {
        action: 'remove',
        path: `metadata.taskProblemQuestions.pictures.${id}`,
      },
    ];
    const r = await updateTask(this.props.job.id, changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    if (this.state.active === id) {
      this.setState({ active: false });
    }
  }

  setActive(index) {
    this.setState({ active: index });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    const files = _.groupBy(
      _.sortBy(
        _.map(_.get(this.props.job, 'metadata.taskProblemQuestions.pictures'), (p, id) => {
          return { ...p, id, url: `${apiOrigin}/images${p.url}`, type: U.fileType(p.url) };
        }),
        'time'
      ),
      'type'
    );
    const parts = T.parts(this.props.job);
    const photos = _.get(files, 'image', []).map(photo => {
      const associatedPart = parts.filter(part => part.images.includes(photo.checksum))[0];
      const formattedTag = _.startCase(photo.tag);
      const tag = associatedPart ? `${formattedTag} - ${associatedPart.partNumber}` : formattedTag;
      return { ...photo, tag };
    });
    return (
      <section id="AdminTaskDetails">
        <div className="t-photo-gallery p-3" style={{ padding: 0 }}>
          <div className="row mb-4 p-2">
            <div className="col">
              Left-click to zoom, right-click to download
              <div className="text-danger">{this.state.error}</div>
            </div>
            <div className="col">
              <div className="form-control">
                <label>Add Image</label>
                <input className="form-control" type="file" onChange={this.onSave} />
              </div>
            </div>
          </div>
          {files.video && (
            <div className="mb-3">
              <div>Videos</div>
              {files.video.map((video, i) => (
                <div key={i} className="d-inline-block mx-2">
                  <video width="320" height="240" controls src={video.url} />
                  <div className="d-flex justify-content-between">
                    <span>{m(video.time).format('M/D/YY h:mma')}</span>
                    <Icon
                      name="trash_can_outline"
                      className="pointer"
                      onClick={() => this.onRemove(video.id)}
                      size={16}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {photos && (
            <div className="mb-3">
              <div>Photos</div>
              {photos.map((photo, i) => (
                <div
                  key={i}
                  className="d-inline-block pointer mx-2"
                  onClick={() => this.setActive(i)}
                >
                  <div className="mt-4">
                    <Text>
                      <b>Tag:</b> {photo.tag}
                    </Text>
                    <img
                      alt={photo.tag}
                      src={photo.url}
                      style={i === this.state.active ? { width: '1000px' } : { width: '500px' }}
                    />
                    <div className="d-flex justify-content-between">
                      <span>{m(photo.time).format('M/D/YY h:mma')}</span>
                      {photo.location && (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link-blue"
                          href={`https://www.google.com/maps/search/?q=${photo.location.latitude},${photo.location.longitude}`}
                        >
                          Open In Maps
                        </a>
                      )}
                      <Icon
                        name="trash_can_outline"
                        className="pointer"
                        onClick={() => this.onRemove(photo.id)}
                        size={16}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {files.file && this.props.roles.customersupport && (
            <div className="mb-3">
              <div>Files</div>
              {files.file.map((file, i) => (
                <div key={i} className="d-inline-block mx-2">
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    <div className="p-3" style={{ border: '1px solid black' }}>
                      Download File
                    </div>
                  </a>
                  <div className="d-flex justify-content-between">
                    <span>{m(file.time).format('M/D/YY h:mma')}</span>
                    <Icon
                      name="trash_can_outline"
                      className="pointer"
                      onClick={() => this.onRemove(file.id)}
                      size={16}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const roles = U.user.roles(s);
  const photos = [];
  _.mapValues(_.get(job, 'metadata.taskProblemQuestions.pictures'), (v, id) =>
    photos.push({ ...v, url: v.url, id })
  );
  return { job, photos, roles };
})(Photos);
