import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Icon, Input, InputType, Text } from '@/components';
import useLegacySelector from '@/hooks/useLegacySelector';
import { updateTask } from '../../com/task';

type Props = {
  taskId: string;
};

export default function PurchaseOrderNumbers({ taskId }: Props): JSX.Element {
  const { purchaseOrderNumbers } = useLegacySelector(state => {
    const task = state.tasks[taskId];
    const purchaseOrderNumbers: string[] = [];
    _.map(_.get(task, 'metadata.purchaseOrderNumber'), (value, number) => {
      if (value) {
        purchaseOrderNumbers.push(number);
      }
    });
    return { purchaseOrderNumbers };
  });

  const [addPON, setAddPON] = useState(false);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');

  const savePON = async () => {
    const changes = [
      {
        action: 'replace',
        path: `metadata.purchaseOrderNumber.${purchaseOrderNumber}`,
        value: true,
      },
    ];
    await updateTask(taskId, changes);
    setPurchaseOrderNumber('');
    setAddPON(false);
  };

  const removePON = (number: string) => {
    const changes = [
      {
        action: 'remove',
        path: `metadata.purchaseOrderNumber.${number}`,
      },
    ];
    void updateTask(taskId, changes);
  };
  return (
    <div>
      {!addPON ? (
        <Button onClick={() => setAddPON(true)} variant="outline">
          Add Purchase Order Number
        </Button>
      ) : (
        <div className="flex items-end space-x-4">
          <Input
            className="mb-0 w-[300px]"
            label="Enter Purchase Order Number"
            onChange={value => setPurchaseOrderNumber(value as string)}
            value={purchaseOrderNumber}
            type={InputType.TEXT}
          />
          <div>
            <Button disabled={!purchaseOrderNumber} onClick={savePON} variant="outline">
              Save
            </Button>
            <Button className="ml-4" onClick={() => setAddPON(false)}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {purchaseOrderNumbers.map(n => (
        <div key={n} className="mt-4 flex space-x-2">
          <Text>{n}</Text>
          <Icon name="close_thick" className="pointer" onClick={() => removePON(n)} size={16} />
        </div>
      ))}
    </div>
  );
}
