import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

const Bool = (p, { t }) => {
  const v = _.get(t.state, p.id);
  // eslint-disable-next-line no-underscore-dangle
  const _onChange = value => {
    if (p.onChange) {
      p.onChange(value);
    }
    onChange(t, p.id, value);
  };
  return (
    <Group {...p}>
      <select
        autoFocus={p.autoFocus}
        className="form-control"
        disabled={p.disabled}
        value={v === true ? 'Yes' : v === false ? 'No' : ''}
        onChange={e =>
          _onChange(e.target.value === 'Yes' ? true : e.target.value === 'No' ? false : undefined)
        }
      >
        <option value="">{p.hint || 'Select'}</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </Group>
  );
};

Bool.contextTypes = { t: T.object.isRequired };

Bool.propTypes = {
  id: T.string.isRequired,
  label: T.string,
};

export default Bool;
