import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { updateTask } from '../../../com/task';
import { Bool, Date,Money, Text } from '../../../com/ui/form';

const props = [
  'metadata.authNumber2',
  'metadata.claimNumber2',
  'metadata.landlord',
  'metadata.workOrderNumber',
  'metadata.workOrderLineId',
  'metadata.altWorkOrderNumbers',
];

class Authorization extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      job: _.cloneDeep(p.job),
      org: p.org,
      workOrder: _.cloneDeep(p.workOrder),
    };
  }

  componentWillReceiveProps(p) {
    if (!this.props.job && p.job) {
      this.setState({ job: _.cloneDeep(p.job) });
    } else if (this.props.job.id !== p.job.id) {
      this.setState({ job: _.cloneDeep(p.job), workOrder: _.cloneDeep(p.workOrder) });
    }
    if (_.isEmpty(this.props.org) && !_.isEmpty(p.org)) {
      this.setState({ org: p.org });
    }
  }

  open = () => {
    this.setState({ modal: true });
  };

  isChange = () => {
    const propNumbers = _.compact(_.get(this.props.job, 'metadata.altWorkOrderNumbers'));
    const stateNumbers = _.compact(_.get(this.state.job, 'metadata.altWorkOrderNumbers'));
    return (
      this.state.workOrder.copay !== this.props.workOrder.copay ||
      this.state.workOrder.billingWorkOrderNumber !== this.props.workOrder.billingWorkOrderNumber ||
      !_.isEqual(propNumbers, stateNumbers) ||
      !!props
        .filter(v => v !== 'metadata.altWorkOrderNumbers')
        .find(p => !_.isEqual(_.get(this.props.job, p), _.get(this.state.job, p)))
    );
  };

  save = async () => {
    // update standard fields
    const job = _.merge({}, this.state.job);
    const llPhone = _.get(job, 'metadata.landlord.phone');
    if (llPhone) {
      job.metadata.landlord.phone = _.replace(llPhone, /\D/g, '');
    }
    const alr = _.get(job, 'metadata.authLimit2.required');
    const ala = _.get(job, 'metadata.authLimit2.amount');
    if (alr && _.isUndefined(ala)) {
      return this.setState({ error: 'Auth Limit Amount required if Auth Limit Required = yes' });
    }
    const cr = _.get(job, 'metadata.copay2.required');
    const ca = _.get(job, 'metadata.copay2.amount');
    if (cr && _.isUndefined(ca)) {
      return this.setState({ error: 'Copay Amount required if Copay Required = yes' });
    }
    const changes = props
      .filter(p => p !== 'metadata.workOrderNumber' && _.get(this.props.job, p) !== _.get(job, p))
      .map(p => ({
        action: 'replace',
        path: p,
        value: _.get(job, p),
      }));
    if (changes.length) {
      const r = await updateTask(global.id(), changes);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
    }

    // update copay
    const copay = _.isNumber(this.state.workOrder.copay) ? this.state.workOrder.copay : undefined;
    if (copay !== this.props.workOrder.copay) {
      await U.api('put', `workOrders/${this.props.workOrder.id}/copay`, { copay }, true);
    }

    // update work order number
    if (
      _.get(this.state.job, 'metadata.workOrderNumber') !==
      _.get(this.props.job, 'metadata.workOrderNumber')
    ) {
      const newWorkOrderNumber = this.state.job.metadata.workOrderNumber || undefined;
      const body = { workOrderNumber: newWorkOrderNumber };
      const r = await U.api(
        'put',
        `workOrders/${this.props.workOrder.id}/workOrderNumber`,
        body,
        true
      );
      if (r.errMsg) {
        return this.setState({ error: r.errMsg });
      }
      await U.api('get', `tasks/${this.props.job.id}`, true);
    }

    // update billing work order number
    const { billingWorkOrderNumber } = this.state.workOrder;
    if (billingWorkOrderNumber !== this.props.workOrder.billingWorkOrderNumber) {
      await U.api(
        'put',
        `workOrders/${this.props.workOrder.id}/billingWorkOrderNumber`,
        { billingWorkOrderNumber },
        true
      );
    }
    this.setState({ modal: false });
  };

  toggleLandlord = () => {
    if (_.get(this.state, 'job.metadata.landlord')) {
      this.setState(s => _.set(s, 'job.metadata.landlord', undefined));
    } else {
      this.setState(s => _.set(s, 'job.metadata.landlord', {}));
    }
  };

  render() {
    if (!this.props.org) {
      return null;
    }
    const hasLandlord = !!_.get(this.state.job, 'metadata.landlord');
    return (
      <div className="bg-white p-3">
        <div className="text-danger">{this.state.error}</div>
        {this.isChange() && (
          <div className="btn btn-primary" onClick={this.save}>
            Save
          </div>
        )}
        <h2 className="mt-3">Work Order</h2>
        <div className="row">
          <div className="col">
            <Text id="job.metadata.workOrderNumber" />
          </div>
          <div className="col">
            <Text id="job.metadata.workOrderLineId" />
          </div>
          <div className="col">
            <Text id="workOrder.billingWorkOrderNumber" />
          </div>
          <div className="col">
            <Text id="workOrder.vendorPortalAccount" disabled />
          </div>
        </div>
        <h2 className="mt-3">{_.get(this.props.vendor, 'name')}</h2>
        <div className="row">
          <div className="col">
            <Bool id="org.copay2.required" label="Copay Required" disabled />
            <Money id="org.copay2.amount" label="Copay Amount" disabled />
          </div>
          <div className="col">
            <Money id="org.authNumber2.amount" label="Auth Number Amount" disabled />
          </div>
          <div className="col">
            <Bool id="org.claimNumber2.required" label="Claim Number Required" disabled />
          </div>
        </div>
        <h2 className="mt-3">Job Override</h2>
        <div className="row">
          <div className="col">
            <div className="row">
              <Money id="workOrder.copay" label="Copay Amount" className="col" />
              {this.props.job.recall?.pastId && (
                <Bool id="job.metadata.copay2.setCopayOnRecall" className="col" />
              )}
            </div>
          </div>
          <div className="col">
            <Money id="job.metadata.authNumber2.amount" label="Auth Number Amount" />
            <Text id="job.metadata.authNumber2.value" label="Auth Number Value" />
            <Date id="job.metadata.claimNumber2.submissionTime" label="Claim Submitted Date" />
          </div>
          <div className="col">
            <Bool id="job.metadata.claimNumber2.required" label="Claim Number Required" />
            <Text id="job.metadata.claimNumber2.value" label="Claim Number Value" />
            <Money id="job.metadata.claimNumber2.amount" label="Claim Amount" />
          </div>
        </div>
        <div className="d-flex mt-3">
          <h2>Landlord</h2>
          <div className="btn btn-primary ml-auto" onClick={this.toggleLandlord}>
            {hasLandlord ? 'Remove' : 'Add'} Landlord
          </div>
        </div>
        {hasLandlord && (
          <div className="row">
            <Text id="job.metadata.landlord.name" className="col" />
            <Text id="job.metadata.landlord.phone" className="col" />
            <Text id="job.metadata.landlord.email" className="col" />
          </div>
        )}
        <div className="text-danger">{this.state.error}</div>
        {this.isChange() && (
          <div className="btn btn-primary" onClick={this.save}>
            Save
          </div>
        )}
      </div>
    );
  }
}

export default connect(s => {
  const job = _.merge({}, s.tasks[global.id()]);
  const workOrder = s.workorders[_.get(job, 'metadata.workOrderId')];
  const org = { ...s.orgs[_.get(job, 'customer.org.id')] };
  if (
    _.get(org, `authNumber2.stateOvr.${_.get(job, 'serviceAddress.region')}.amount`) !== undefined
  ) {
    org.authNumber2.amount = org.authNumber2.stateOvr[_.get(job, 'serviceAddress.region')].amount;
  }
  _.set(job, 'metadata.copay2.amount', T.copayAmount({ job, org }));
  _.set(job, 'metadata.authNumber2.value', T.authNumberValue(job));
  _.set(job, 'metadata.claimNumber2.value', T.claimNumberValue(job));
  return { job, org, workOrder };
})(Authorization);
