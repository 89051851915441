import React, { ReactElement } from 'react';
import classNames from 'classnames';

export type Props = {
  className?: string;
  disabled?: boolean;
  error?: string;
  isSelected: boolean;
  size?: 'small' | 'medium' | 'large';
};

export default function Radio({
  className,
  disabled,
  error,
  isSelected,
  size = 'medium',
}: Props): ReactElement {
  return (
    <div
      className={classNames(
        'qa-radio-button mr-2 shrink-0 rounded-full bg-white ring-inset',
        {
          'cursor-pointer': !disabled,
          'cursor-default': disabled,
          'h-4 w-4': size === 'small',
          'h-5 w-5': size === 'medium',
          'h-6 w-6': size === 'large',
          'ring-[6px]': size === 'small' && isSelected,
          'ring-[7px]': size === 'medium' && isSelected,
          'ring-[9px]': size === 'large' && isSelected,
          'qa-radio-button-marked': isSelected,
          'qa-radio-button-unmarked': !isSelected,
          'ring-primaryCTA group-hover:ring-primaryCTA-7': isSelected && !disabled && !error,
          'ring-1 ring-icons-grey group-hover:ring-primaryCTA': !isSelected && !disabled && !error,
          'ring-danger': isSelected && !disabled && error,
          'ring-1 ring-danger': !isSelected && !disabled && error,
          'ring-icons-grey ': isSelected && disabled,
          'ring-1 ring-icons-grey ': !isSelected && disabled,
        },
        className
      )}
    />
  );
}
