import React from 'react';
import _ from 'lodash';
import { firstRowHeight, statusColor } from '../util';

const style = { top: firstRowHeight + 16, zIndex: 99999999999999 };

export default function Legend() {
  return (
    <div className="absolute right-4 bg-white p-4" style={style}>
      {_.map(statusColor, (color, status) => (
        <div key={status} className="flex justify-between">
          {_.startCase(status)}{' '}
          <img
            className="ml-4 h-4 w-4"
            src={`${global.location.origin}/img/map/${color}-circle.png`}
            alt=""
          />
        </div>
      ))}
    </div>
  );
}
