import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Avatar, { optionsBySize } from './Avatar';

const sizeOptions = Object.keys(optionsBySize);

export default {
  title: 'Core/Avatar',
  component: Avatar,
  args: {
    name: 'Juan Perez',
    badgeProps: { iconProps: { name: 'check' }, color: 'success' },
  },
  argTypes: {
    size: { control: { type: 'inline-radio' }, options: sizeOptions },
  },
} as ComponentMeta<typeof Avatar>;

const Template: ComponentStory<typeof Avatar> = args => <Avatar {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const WithImg = Template.bind({});
WithImg.args = {
  badgeProps: undefined,
  pictureUrl: 'https://avatars.githubusercontent.com/u/18565?s=64&v=4',
};

export const CxProCardExample = Template.bind({});
CxProCardExample.args = {
  badgeProps: { iconProps: { name: 'send' }, color: 'success' },
  name: 'Hugh Grant',
  pictureUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/%D0%A5%D1%8C%D1%8E_%D0%93%D1%80%D0%B0%D0%BD%D1%82.jpg/220px-%D0%A5%D1%8C%D1%8E_%D0%93%D1%80%D0%B0%D0%BD%D1%82.jpg',
  variant: 'circle',
};

export const AdminProCardExample = Template.bind({});
AdminProCardExample.args = {
  badgeProps: undefined,
  name: 'Hugh Grant',
  pictureUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/%D0%A5%D1%8C%D1%8E_%D0%93%D1%80%D0%B0%D0%BD%D1%82.jpg/220px-%D0%A5%D1%8C%D1%8E_%D0%93%D1%80%D0%B0%D0%BD%D1%82.jpg',
  variant: 'circle',
  ringColor: 'success',
};

export const NoBadge = Template.bind({});
NoBadge.args = {
  badgeProps: undefined,
};

export const IconOnly = Template.bind({});
IconOnly.args = {
  name: undefined,
  badgeProps: undefined,
  className: 'bg-primaryCTA bg-opacity-10',
  iconProps: { name: 'car', className: 'text-primaryCTA' },
  size: 'xlarge',
  variant: 'circle',
};

export const NoName = Template.bind({});
NoName.args = {
  name: undefined,
};

export const ErrorState = Template.bind({});
ErrorState.args = {
  name: undefined,
  badgeProps: { iconProps: { name: 'priority_high' }, color: 'danger' },
  className: 'bg-danger',
};
