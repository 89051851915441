import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import { PartVendor, U } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import TableComponent from '@/components/Table';
import { idFromURL } from '@/utils';
import Edit from './Edit';

export default function PartVendorsTable(): JSX.Element {
  const id = idFromURL();
  const loadFunc = async () => {
    const out = await U.api<PartVendor>('get', `partVendors/${nullthrows(id)}`);
    if (!out || 'errMsg' in out) {
      return [];
    }
    const locationsObject = _.map(out.locations, (item, index) => ({
      ...item,
      id: index + 1,
    }));
    return locationsObject;
  };

  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setLoading(true);
  };

  // This is a hack to force a table re-load after a row is removed
  // This is necessary because we are loading the data using this custom loadFunc method.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (loading) {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 100);
    }
    return () => clearTimeout(timeout);
  }, [loading]);

  if (loading) {
    return <></>;
  }

  return (
    <TableComponent
      {...Table.databases.default.partVendorLocation} // eslint-disable-line react/jsx-props-no-spreading
      addUi={(props: Table.AddUiProps) => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
      editUi={(props: Table.EditUiProps) => <Edit {...props} onRemove={handleRemove} />} // eslint-disable-line react/jsx-props-no-spreading
      //@ts-expect-error Current type is impossible to use
      loadFunc={loadFunc}
    />
  );
}
