import { Pro } from '@nanaio/util';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchPro = async (proId: string): Promise<Pro> => {
  const { data: pro } = await AXIOS_INSTANCE.get<Pro>(`/api/pros/${proId}`);

  return pro;
};

export const useGetPro = (proId: string): UseQueryResult<Pro> => {
  return useQuery({
    queryKey: ['pro', proId],
    queryFn: () => fetchPro(proId),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
};
