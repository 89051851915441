import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { T, Task } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Button, Paper, Text } from '@/components';
import { useGetFeatures } from '@/hooks/api/features';

type Props = {
  task: Task;
};

export default function TaskCard({ task }: Props): JSX.Element {
  const dispatch = useDispatch();
  const featureData = useGetFeatures();
  const squareTrade2HourSlots = (featureData.data?.squareTrade2HourSlots as boolean) || false;

  const address = task.serviceAddress.formattedAddress;
  const visitTime = T.lastVisitStart(task);

  return (
    <div
      onClick={() => dispatch(push(`/tasks/${task.id}`))}
      data-cy="appointment-link"
      key={task.id}
    >
      <Paper
        className="mb-1 flex items-center p-4 transition-all hover:scale-[101%]"
        variant="elevated"
      >
        <div className="mr-2 hidden border-r border-grey-medium pr-3 text-center md:block">
          <Text color="grey.dark" type="button">
            {m(visitTime).format('MMM')}
          </Text>
          <Text color="primary" type="button">
            {m(visitTime).format('DD')}
          </Text>
          <Text color="grey.dark" type="button">
            {m(visitTime).format('ddd')}
          </Text>
        </div>
        <div className="flex-1 pl-0 md:pl-3">
          <Text className="hidden md:block" color="grey.dark">
            {squareTrade2HourSlots
              ? T.getTimeSlotDisplay(task, 'start', visitTime).format('h:mm A')
              : m(visitTime).format('h:mm A')}
            -
            {squareTrade2HourSlots
              ? T.getTimeSlotDisplay(task, 'end', m(visitTime).add(4, 'hours')).format('h:mm A')
              : m(visitTime).add(4, 'hours').format('h:mm A')}
          </Text>
          <Text className="block md:hidden" color="grey.dark">
            {squareTrade2HourSlots
              ? T.getTimeSlotDisplay(task, 'start', visitTime).format('ddd, MMM D, YYYY (hA')
              : m(visitTime).format('ddd, MMM D, YYYY (hA')}{' '}
            -{' '}
            {squareTrade2HourSlots
              ? T.getTimeSlotDisplay(task, 'end', m(visitTime).add(4, 'hours')).format('hA)')
              : m(visitTime).add(4, 'hours').format('hA)')}
          </Text>
          <Text type="button">
            {task.title}{' '}
            {_.get(T.lastVisit(task), 'assignee.user.firstName')
              ? `with ${_.get(T.lastVisit(task), 'assignee.user.firstName', '') as string}`
              : ``}
          </Text>
          <Text color="grey.dark" className="hidden md:block">
            {address}
          </Text>
        </div>
        <Button size="medium">View Details</Button>
      </Paper>
    </div>
  );
}
