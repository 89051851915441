import React from 'react';
import U from '@nanaio/util';
import PropTypes from 'prop-types';
import Text from '../core/Text';
import Cell from './Cell';

export default function Section({ children, date, hasOverlay, isFixed, title }) {
  return (
    <Cell {...{ date, hasOverlay, isFixed }}>
      <div className="flex h-14 flex-row justify-between border-b border-grey-medium bg-grey-light p-4">
        <Text type="page-title">{title}</Text>
        {children}
      </div>
    </Cell>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  date: U.timePropType,
  hasOverlay: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool,
  title: PropTypes.string,
};
