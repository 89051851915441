import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import RadioButtonTile from './RadioButtonTile';

export type choiceType = {
  name: string;
  description?: ReactNode;
};

export type Props = {
  choices: choiceType[];
  className?: string;
  arrangement?: 'column' | 'grid' | 'twoColumn';
  variant?: 'row' | 'tile';
  onSelection?: (choice: string | undefined) => void;
  value?: string;
  isMulti?: boolean;
};

export default function RadioTileList({
  choices,
  className,
  variant = 'row',
  arrangement = 'column',
  onSelection,
  value,
  isMulti,
}: Props): JSX.Element {
  const [selected, setSelected] = useState<string | undefined>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleClick = useCallback(
    (title: string) => {
      const value = selected === title ? undefined : title;
      setSelected(value);
      if (onSelection) {
        onSelection(value);
      }
    },
    [selected, onSelection]
  );

  return (
    <div
      className={classnames(className, 'gap-4', {
        'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6':
          arrangement === 'grid',
        'grid grid-cols-2': arrangement === 'twoColumn',
        'flex flex-wrap': arrangement === 'column',
      })}
    >
      {choices?.map(choice => (
        <div className="w-full" key={choice.name}>
          <RadioButtonTile
            variant={variant}
            title={choice.name}
            description={choice.description}
            onClick={handleClick}
            isSelected={selected === choice.name}
            icon={isMulti ? 'check' : 'radio'}
          />
        </div>
      ))}
    </div>
  );
}
