// eslint-disable-next-line max-classes-per-file
import '../../../style/crm.css';

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, Icon, Text, theme } from '@/components';
import Schedule from '@/components/Schedule';
import { Bool } from '../../../com/ui/form';
import { copyText, openLink } from '../../../com/util';
import CancelVisit from './CancelVisit';
import ProAvatar from './ProAvatar';
import ProTaskSection from './ProTaskSection';
import { VisitNotes } from './VisitNotes';

const statusColorByVisitProStatus = {
  [W.VisitProStatus.PENDING]: theme.colors.danger,
  [W.VisitProStatus.CONFIRMED]: theme.colors.primary,
  [W.VisitProStatus.NO_SHOW]: theme.colors.accent.orange,
  [W.VisitProStatus.EN_ROUTE]: theme.colors.accent.purple,
  [W.VisitProStatus.LEFT_SITE]: theme.colors.success,
  [W.VisitProStatus.ON_SITE]: theme.colors.accent.purple,
  [W.VisitProStatus.CANCELLED]: theme.colors.grey.dark,
  [undefined]: theme.colors.grey.dark,
};

class DropDown extends React.Component {
  state = { isOpen: false };

  toggle = () => {
    const { disabled } = this.props;
    const { isOpen } = this.state;
    if (!disabled) {
      this.setState({ isOpen: !isOpen });
    }
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { button, menu, title } = this.props;
    const { isOpen } = this.state;
    return (
      <div id="visit-dropdown-modal">
        <div className="visit-dropdown-button" onClick={this.toggle}>
          {button}
        </div>
        {isOpen && (
          <div
            onMouseLeave={this.close}
            className={isOpen ? `visit-dropdown-menu open` : `visit-dropdown-menu`}
          >
            {title && (
              <div className="flex rounded-t border p-4">
                <Text className="flex-1">{title}</Text>
                <Text color="primaryCTA" onClick={this.close}>
                  Close
                </Text>
              </div>
            )}
            {menu}
          </div>
        )}
      </div>
    );
  }
}

DropDown.propTypes = exact({
  button: PropTypes.node,
  disabled: PropTypes.bool,
  menu: PropTypes.node,
  title: PropTypes.string,
});

class Visits extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(props) {
    super(props);
    this.state = { timezone: U.timezone(props.job.serviceAddress) };
  }

  getChildContext = () => ({ t: this });

  componentWillUnmount() {
    const { timeoutId } = this.state;
    clearTimeout(timeoutId);
  }

  onRefresh = () => {
    const { workOrder } = this.props;
    U.api('put', `workOrders/${workOrder.id}/refresh`, undefined, ['save']);
  };

  onScheduleSave = async ({ notify, visit }) => {
    const { workOrder } = this.props;
    const { visitId } = this.state;
    if (visitId === -1) {
      await U.api('post', `workOrders/${workOrder.id}/visits?notify=${notify}`, visit, ['save']);
    } else {
      await U.api('put', `workOrders/${workOrder.id}/visits/${visitId}?notify=${notify}`, visit, [
        'save',
      ]);
    }
    this.setState({ scheduleIsOpen: false });
  };

  getDays = user => {
    const { timezone } = this.state;
    return U.keySort(
      _.mapValues(
        _.groupBy(user.availability, slot => mt(slot.start).tz(timezone).startOf('day').valueOf()),
        slots =>
          _.times(3, i => slots.find(slot => mt(slot.start).tz(timezone).hour() === 8 + i * 4))
      )
    );
  };

  getHeight = days => {
    return `${_.max([_.keys(days).length * 44, 48])}px`;
  };

  canEditTime = visit => {
    const { cantReopen, roles } = this.props;
    if (roles.admin) {
      return true;
    }
    if (cantReopen) {
      return false;
    }
    const hasPro = !!visit.pros.find(pro => pro.id && W.visitProIsScheduled(pro));
    const cutoffInDays = hasPro ? 1 : 3;
    const lastStartTime = _.get(_.last(W.visitAvailability(visit)), 'start');
    return m(lastStartTime).add(cutoffInDays, 'd').isAfter();
  };

  copyText = (id, value) => {
    copyText(value);
    const timeoutId = setTimeout(() => this.setState({ copied: false }), 3000);
    this.setState({ copied: id, timeoutId });
  };

  openSchedule = visitId => {
    this.setState({ scheduleIsOpen: true, visitId });
  };

  openTask = task => {
    const { dispatch, job } = this.props;
    if (task.id !== job.id) {
      dispatch(push(`/tasks/${task.id}`));
    }
  };

  toggleCancelVisit = () => {
    const { cancelIsOpen } = this.state;
    this.setState({ cancelIsOpen: !cancelIsOpen });
  };

  updateStatus = async (visitId, status, pro, proIndex) => {
    const { workOrder } = this.props;
    if (pro) {
      const visit = _.set({ ...workOrder.visits[visitId] }, `pros.${proIndex}.status`, status);
      U.api('put', `workOrders/${workOrder.id}/visits/${visitId}`, visit, ['save']);
    } else if (status === W.VisitCustomerStatus.CANCELLED) {
      this.setState({ cancelIsOpen: true, visitId });
    } else {
      const visit = _.set({ ...workOrder.visits[visitId] }, 'cx.status', status);
      U.api('put', `workOrders/${workOrder.id}/visits/${visitId}`, visit, ['save']);
    }
  };

  renderAvailability = days => {
    const { timezone } = this.state;
    return _.map(days, (slots, date) => (
      <div key={date} className="mb-1 flex" style={{ height: 40 }}>
        <Text className="mr-1 min-w-[91px]">{mt(+date).tz(timezone).format('ddd M/D/YY')}</Text>
        {_.map(slots, (slot, i) =>
          slot ? (
            <Text
              className={`mr-1 rounded p-2 ${slot.preferred ? 'bg-primary' : 'bg-primary-light-1'}`}
              color={slot.preferred ? 'white' : 'primary'}
              key={slot.start}
              noWrap
              style={{ background: theme.hexToRGB(theme.colors.primary, slot.preferred ? 0 : 0.1) }}
            >
              {mt(slot.start).tz(timezone).format(' ha - ')}
              {mt(slot.end).tz(timezone).format('ha')}
            </Text>
          ) : (
            <div key={i} style={{ width: '100px', margin: '4px' }} />
          )
        )}
      </div>
    ));
  };

  renderProfile = (profile, days, id) => {
    const { copied } = this.state;
    return (
      <div className="hover-show">
        <div className="flex" style={{ minWidth: '415px', minHeight: this.getHeight(days) }}>
          <div className="flex">
            <div>
              <div
                className="pointer flex"
                onClick={() => this.copyText(`${id}.phone`, profile.phone)}
              >
                <Icon className="mr-2" color="primaryCTA" name="content_copy" />
                {copied === `${id}.phone` ? (
                  <Text className="inline" color="success">
                    Copied!
                  </Text>
                ) : (
                  <Text className="inline">{profile.phone}</Text>
                )}
              </div>
              <div
                className="pointer flex"
                onClick={() => this.copyText(`${id}.email`, profile.email)}
              >
                <Icon className="mr-2" color="primaryCTA" name="content_copy" />
                {copied === `${id}.email` ? (
                  <Text className="inline" color="success">
                    Copied!
                  </Text>
                ) : (
                  <Text className="inline overflow-hidden text-ellipsis" style={{ maxWidth: 200 }}>
                    {profile.email}
                  </Text>
                )}
              </div>
            </div>
            <div
              className="pointer ml-3"
              onClick={() => this.copyText(`${id}.address`, U.addressToString(profile.address))}
            >
              <div className="flex">
                {' '}
                <Icon className="mr-2" color="primaryCTA" name="content_copy" />
                {copied === `${id}.address` ? (
                  <Text className="inline" color="success">
                    Copied!
                  </Text>
                ) : (
                  <Text className="inline" noWrap>
                    {U.addressLine12(profile.address)}
                  </Text>
                )}
              </div>
              {copied === `${id}.address` ? (
                <Text className="inline" color="success">
                  Copied!
                </Text>
              ) : (
                <Text className="inline" noWrap>
                  {U.addressLine3(profile.address)}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { cantReopen, hasDispatchPermissions, job, workOrder } = this.props;
    const { cancelIsOpen, copied, scheduleIsOpen, timezone, visitId } = this.state;
    if (!timezone) {
      return null;
    }
    return (
      <div id="VisitList">
        {workOrder.visits.map((visit, visitId) => {
          const customerDays = this.getDays(visit.cx);
          return (
            <div className="mb-4" key={visit.id}>
              {/* VISIT */}
              <div className="visit_card flex p-2" style={{ borderLeftColor: 'transparent' }}>
                <Text type="button">
                  {mt(visit.slot.start).tz(timezone).format('ddd, MMM D, hA - ').toUpperCase()}
                  {mt(visit.slot.end).tz(timezone).format('hA')}
                </Text>
                <Text className="ml-2" color="grey.dark" type="button">
                  {mt(visit.slot.start).tz(timezone).format('YYYY')}
                </Text>
                {this.canEditTime(visit) && (
                  <Button className="ml-4" size="medium" onClick={() => this.openSchedule(visitId)}>
                    Schedule
                  </Button>
                )}
                <VisitNotes visit={visit} task={job} />
                <Text className="ml-auto" color="grey.dark">
                  Created {m(visit.createTime).format('M/D/YY, h:mma')} (
                  {m(visit.createTime).fromNow()})
                </Text>
              </div>
              {/* CUSTOMER */}
              <div
                className="visit_card hover-anchor flex p-2"
                style={{
                  borderLeftColor: statusColorByVisitProStatus[visit.cx.status],
                  minHeight: '66px',
                }}
              >
                <div className="mr-4 w-[190px]">
                  <div className="mb-1 flex">
                    <Icon
                      color="primaryCTA"
                      name="open_in_new"
                      onClick={() => openLink({ url: `/users/${workOrder.cx.id}`, newTab: true })}
                    />
                    <Icon
                      className="ml-4"
                      color="primaryCTA"
                      name="content_copy"
                      onClick={() =>
                        this.copyText('cx.name', U.titleCase(job.customer.user.fullName))
                      }
                    />
                    <Text className="ml-4 inline" color="grey.dark">
                      CX
                    </Text>
                  </div>
                  <div>
                    {copied === 'cx.name' ? (
                      <Text color="success">Copied!</Text>
                    ) : (
                      <Text>{U.titleCase(job.customer.user.fullName)}</Text>
                    )}
                  </div>
                </div>
                <div style={{ width: '383px' }}>
                  <div className="hover-hide bg-background-light">
                    {this.renderAvailability(customerDays)}
                  </div>
                  {this.renderProfile(workOrder.cx, customerDays, 'cx')}
                </div>
                {!!visit.rescheduleReason && visit.cx.status === W.VisitCustomerStatus.PENDING && (
                  <div className="ml-4 mt-2">
                    <Text>Technician Rescheduled</Text>
                  </div>
                )}
                <div className="ml-auto flex flex-col items-end">
                  <DropDown
                    disabled={cantReopen}
                    title="Edit Status"
                    button={
                      <div className={`status-badge ${visit.cx.status}`}>
                        <div
                          style={{
                            background: statusColorByVisitProStatus[visit.cx.status],
                            width: 8,
                            height: 8,
                            borderRadius: 8,
                            marginRight: 8,
                          }}
                        />
                        <Text
                          className="flex-1"
                          style={{ color: statusColorByVisitProStatus[visit.cx.status] }}
                        >
                          {_.startCase(visit.cx.status)}
                        </Text>
                        <Icon color="grey.dark" name="menu_down" />
                      </div>
                    }
                    menu={
                      <div className="options">
                        {W.visitCustomerStatusOptions.map(status => {
                          const isActive = visit.cx.status === status;
                          return (
                            <div
                              className={isActive ? `options-item active` : 'options-item'}
                              onClick={() => this.updateStatus(visitId, status)}
                              key={status}
                            >
                              <Icon
                                color={isActive ? 'primaryCTA' : 'grey.dark'}
                                name={isActive ? 'radio_button_checked' : 'radio_button_unchecked'}
                              />
                              <Text className="ml-4">{_.startCase(status)}</Text>
                            </div>
                          );
                        })}
                        <Bool id={`notify.${visitId}`} label="Send Message" className="px-3" />
                      </div>
                    }
                  />
                  {visit.cx.status === W.VisitCustomerStatus.CANCELLED && (
                    <Text>{visit.cancelReason}</Text>
                  )}
                </div>
              </div>
              {/* PROS */}
              {visit.pros.map((pro, proIndex) => {
                const profile = workOrder.pros.find(proProfile => proProfile.id === pro.id);
                const proDays = this.getDays(pro);
                return (
                  <div
                    key={`${pro.id}-${proIndex}`}
                    className={`visit_card flex p-2 ${profile ? 'hover-anchor' : ''} ${
                      W.visitProIsScheduled(pro) || !pro.id ? '' : ' text-muted'
                    }`}
                    style={{
                      borderLeftColor: statusColorByVisitProStatus[pro.status],
                      minHeight: '66px',
                    }}
                  >
                    <div className="mr-4 flex w-[190px] shrink-0">
                      {pro.id && <ProAvatar proId={pro.id} />}
                      <div className="-ml-2">
                        <div className="mb-1 flex">
                          {pro.id && (
                            <Icon
                              color="primaryCTA"
                              name="open_in_new"
                              onClick={() => openLink({ url: `/pros/${pro.id}`, newTab: true })}
                            />
                          )}
                          {pro.id && (
                            <Icon
                              className="ml-4"
                              color="primaryCTA"
                              name="content_copy"
                              onClick={() => this.copyText(`${visitId}.${pro.id}`, profile.name)}
                            />
                          )}
                          <Text className="ml-4 inline" color="grey.dark">
                            PRO
                          </Text>
                        </div>
                        <div>
                          {copied === `${visitId}.${pro.id}` ? (
                            <Text color="success">Copied!</Text>
                          ) : (
                            <Text>{profile?.name || 'UNCLAIMED'}</Text>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mr-4 w-[383px]">
                      <div className="hover-hide bg-background-light">
                        {this.renderAvailability(proDays)}
                      </div>
                      {profile && this.renderProfile(profile, proDays, `${visitId}.${pro.id}`)}
                    </div>
                    <div className="mt-2">
                      {pro.tasks.map(task => (
                        <ProTaskSection
                          key={task.id}
                          onOpenTask={this.openTask}
                          proId={pro.id}
                          task={job}
                          visitProTask={task}
                          workOrder={workOrder}
                        />
                      ))}
                    </div>
                    {pro.tasks.find(task => task.role !== 'interested') && (
                      <div className="ml-auto">
                        <DropDown
                          disabled={cantReopen}
                          title="Edit Status"
                          button={
                            <div className={`status-badge ${pro.status}`}>
                              <div
                                style={{
                                  background: statusColorByVisitProStatus[pro.status],
                                  width: 8,
                                  height: 8,
                                  borderRadius: 8,
                                  marginRight: 8,
                                }}
                              />
                              <Text
                                className="flex-1"
                                style={{ color: statusColorByVisitProStatus[pro.status] }}
                              >
                                {_.startCase(pro.status)}
                              </Text>
                              <Icon color="grey.dark" name="menu_down" />
                            </div>
                          }
                          menu={
                            <div className="options">
                              {(pro.id
                                ? W.visitProStatusOptions.filter(
                                    option =>
                                      option !== W.VisitProStatus.CONFIRMED ||
                                      hasDispatchPermissions
                                  )
                                : [W.VisitProStatus.PENDING]
                              ).map(status => {
                                const isActive = pro.status === status;
                                return (
                                  <div
                                    className={isActive ? `options-item active` : 'options-item'}
                                    onClick={() =>
                                      this.updateStatus(visitId, status, pro, proIndex)
                                    }
                                    key={status}
                                  >
                                    <Icon
                                      color={isActive ? 'primaryCTA' : 'grey.dark'}
                                      name={
                                        isActive ? 'radio_button_checked' : 'radio_button_unchecked'
                                      }
                                    />
                                    <Text className="ml-4">{_.startCase(status)}</Text>
                                  </div>
                                );
                              })}
                              <Bool
                                id={`notify.${visitId}`}
                                label="Send Message"
                                className="px-3"
                              />
                            </div>
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
        <div className="mx-auto flex" style={{ maxWidth: '1000px' }}>
          <Text className="ml-auto" color="primaryCTA" onClick={this.onRefresh}>
            Refresh
          </Text>
        </div>
        {cancelIsOpen && (
          <CancelVisit onClose={this.toggleCancelVisit} visitId={visitId} workOrder={workOrder} />
        )}
        <Schedule
          isBookingExperiment={_.includes(workOrder?.tags, W.Tag.EXPERIMENTAL_SAMSUNG_BOOKING_FLOW)}
          isOpen={scheduleIsOpen}
          onCancel={() => this.setState({ scheduleIsOpen: false })}
          taskId={global.id()}
          visitId={visitId}
          workOrder={workOrder}
        />
      </div>
    );
  }
}

Visits.propTypes = exact({
  cantReopen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasDispatchPermissions: PropTypes.bool.isRequired,
  job: T.propType.isRequired,
  pros: PropTypes.object,
  roles: U.user.rolesPropType.isRequired,
  workOrder: W.propType.isRequired,
});

export default connect((s, p) => {
  const { pros } = s;
  const roles = U.user.roles(s);
  const cantReopen = T.isClosed(p.job) && !roles.admin && !roles.agentManager && !roles.vm;
  const workOrder = s.workorders[p.job.metadata.workOrderId];
  const hasDispatchPermissions = U.user.hasDispatchPermissions(s.users[s.me.userId]);
  return { cantReopen, hasDispatchPermissions, pros, roles, workOrder };
})(Visits);
