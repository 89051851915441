import React, { CSSProperties } from 'react';
import { brandLogos } from '../../config/brandLogos';

const brandNames = Object.keys(brandLogos);

const valueBySize = {
  small: 24,
  medium: 32,
  large: 48,
  xlarge: 64,
};

export type Props = {
  brandName: (typeof brandNames)[number];
  size: keyof typeof valueBySize | number;
  isPublic?: boolean;
  className?: string;
  style?: CSSProperties;
};

/**
 * Component to display an svg image of an appliance brand logo
 * @param size set height either custom int or small-24px, medium-32px, large-48px, xlarge-64px
 */
export default function BrandLogo({
  brandName,
  size = 'xlarge',
  isPublic = false,
  className = '',
  style = {},
}: Props): JSX.Element | null {
  if (!(brandName in brandLogos)) {
    return <>{brandName}</>;
  }
  const brand = brandLogos[brandName];

  if (brand.hidePublic && isPublic) {
    return null;
  }

  if (!brand.img) {
    return <>{brandName}</>;
  }

  if (typeof size === 'string') {
    size = valueBySize[size];
  }

  style = { ...style, height: size };

  return <img src={brand.img} className={className} style={style} alt={brandName} />;
}
