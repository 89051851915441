import React from 'react';
import { Mention, MentionItem,MentionsInput } from 'react-mentions';
import { Text } from '../../core';
import { FormControl, Input } from '../../form';
import theme from '../../theme';
import { Event, Option } from './types';

type Props = {
  emailBody?: string;
  emailError?: string;
  emailSubject?: string;
  emailTo?: string;
  emailToOptions: string[];
  handleEmailToChange: (value: string) => void;
  handleMessageChange: (id: string, event: Event, mentionItems: MentionItem[]) => void;
  options: Option[];
};

export default function Email({
  emailBody,
  emailError,
  emailSubject,
  emailTo,
  emailToOptions,
  handleEmailToChange,
  handleMessageChange,
  options,
}: Props): JSX.Element {
  return (
    <div>
      <Text color="danger">{emailError}</Text>
      <Input
        capitalize
        label="Email To"
        onChange={value => handleEmailToChange(value as string)}
        options={emailToOptions}
        value={emailTo}
      />
      <FormControl label="Email Subject">
        <MentionsInput
          allowSpaceInQuery
          onChange={(e, _v, _p, m) => handleMessageChange('emailSubject', e as Event, m)}
          singleLine
          style={{ control: theme.formControl }}
          value={emailSubject || ''}
        >
          <Mention trigger="@" data={options} />
        </MentionsInput>
      </FormControl>
      <FormControl label="Email Body">
        <MentionsInput
          allowSpaceInQuery
          onChange={(e, _v, _p, m) => handleMessageChange('emailBody', e as Event, m)}
          style={{ control: { ...theme.formControl, minHeight: 100 } }}
          value={emailBody || ''}
        >
          <Mention trigger="@" data={options} />
        </MentionsInput>
      </FormControl>
    </div>
  );
}
