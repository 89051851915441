import React, { useEffect } from 'react';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Icon, Text } from '@/components';
import { sendPageview } from '../../../com/marketing';
import { BookingState } from './util';

type Props = {
  state: BookingState;
};

export default function Confirmation({ state }: Props): JSX.Element {
  const { firstName, email } = state;
  const brand = _.get(state, 'metadata.taskQuestions.Make') as string;

  const nextSteps = [
    <>
      We'll send a service summary to <b className="font-semibold">{email}.</b>
    </>,
    <>
      <b className="font-semibold">Our {brand} specialist will confirm your appointment,</b> usually
      within 2 hours.
    </>,
    <>
      <b className="font-semibold">Remember, there's no upfront payment required.</b> Payment is due
      after your technician has diagnosed the appliance.
    </>,
  ];

  const job = nullthrows(state.job);

  useEffect(() => {
    sendPageview(`booking-page/confirmation`);
  }, []);

  return (
    <section className="step-container confirmation">
      <div>
        <div>
          <section className="left--pane space-y-6">
            <div className="">
              <img src="/img/appointment/checklist.gif" alt="" width={96} height={96} />
            </div>

            <div className="space-y-2">
              <Text type="headline-6" className="text-secondary">
                Thank You, {firstName}!
              </Text>
              <Text type="body-2" className="">
                Your repair request is underway.
              </Text>
            </div>

            <div className="space-y-4">
              {nextSteps.map((text, index) => (
                <div className="flex" key={index}>
                  <div>
                    <Icon name="check" className="text-primaryCTA" />
                  </div>
                  <Text type="body-2" className="ml-2 text-secondary">
                    {text}
                  </Text>
                </div>
              ))}
            </div>

            <a href={`/tasks/${job.id}`} className="inline-block w-full">
              <button
                data-cy="cta-btn"
                className="w-full rounded-lg bg-primaryCTA px-6 py-2"
                id="next"
              >
                <Text type="button" className="text-white">
                  Manage Booking
                </Text>
              </button>
            </a>
          </section>
        </div>
      </div>
    </section>
  );
}
