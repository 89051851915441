import React, { ReactNode } from 'react';
import classnames from 'classnames';
import Text from './Text';

type CardHeaderProps = {
  rightUI?: ReactNode;
  title?: string;
};

const gutter = 'px-3 py-4';

function CardHeader({ rightUI, title }: CardHeaderProps): JSX.Element | null {
  const classes = classnames('flex items-center border-b border-icons-grey', gutter);

  if (title || rightUI) {
    return (
      <div className={classes}>
        <Text type="page-title" className="flex-1">
          {title}
        </Text>

        <div className="ml-auto">{rightUI}</div>
      </div>
    );
  }

  return null;
}

type Props = {
  children: ReactNode;
  className?: string;
  rightUI?: ReactNode;
  title?: string;
};

export default function Card({ children, className, rightUI, title }: Props): JSX.Element {
  const classes = classnames('rounded border border-icons-grey bg-white', className);

  return (
    <div className={classes}>
      <CardHeader title={title} rightUI={rightUI} />

      <div className={gutter}>{children}</div>
    </div>
  );
}
