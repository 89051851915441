import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Text } from '@/components';

type Props = {
  className?: string;
};

export default function BelowFoldContent({ className }: Props): JSX.Element {
  return (
    <ul className={classNames('mt-6', className)}>
      <li className="mt-4 flex">
        <Icon color="primaryCTA" name="check" />
        <Text className="ml-2 flex-1">
          <Text tag="span" type="button">
            Free 90-day warranty on labor.
          </Text>{' '}
          If you encounter the same issue within the last 90 days of completion, the labor fee will
          be waived.
        </Text>
      </li>

      <li className="mt-4 flex">
        <Icon color="primaryCTA" name="check" />
        <Text className="ml-2 flex-1">
          <Text tag="span" type="button">
            Top-quality parts.
          </Text>{' '}
          We supply our technicians with OEM parts.
        </Text>
      </li>
      <li className="mt-4 flex">
        <Icon color="primaryCTA" name="check" />
        <Text className="ml-2 flex-1">
          <Text tag="span" type="button">
            Highly-rated and licensed technicians.
          </Text>{' '}
          All technicians are factory authorized and background checked
        </Text>
      </li>
    </ul>
  );
}
