import React, { useCallback } from 'react';
import classNames from 'classnames';
import CheckRadio from './CheckRadio';
import Text from './Text';

export type Props = {
  items: Array<Pick<ItemProps, 'checked' | 'disabled' | 'subtext' | 'title'>>;
  className?: string;
  onClick?: (index: number) => void;
};

export default function Checklist({ items, className, onClick }: Props): JSX.Element {
  return (
    <div className={className}>
      {items.map((item, index) => (
        <Item {...item} key={index} index={index} onClick={onClick} />
      ))}
    </div>
  );
}

type ItemProps = {
  checked: boolean;
  disabled?: boolean;
  index: number;
  onClick?: (index: number) => void;
  subtext: string;
  title: string;
};

function Item({
  checked,
  disabled = false,
  index,
  onClick,
  subtext,
  title,
}: ItemProps): JSX.Element {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(index);
    }
  }, [onClick, index]);

  const clickable = !disabled && onClick;

  return (
    <div
      className={classNames(
        'flex w-full cursor-default items-center justify-between rounded-lg px-2 py-3',
        {
          'hover:cursor-pointer hover:bg-background-light': clickable,
        }
      )}
      {...(clickable ? { onClick: handleClick } : {})}
    >
      <CheckRadio checked={checked} disabled={disabled} />
      <Text
        type="button"
        className={classNames('flex-1 px-4 text-left', {
          'text-secondary opacity-50': disabled,
        })}
      >
        {title}
      </Text>
      <Text className={classNames(disabled ? 'text-icons-grey' : 'text-grey-dark')} type="label">
        {subtext}
      </Text>
    </div>
  );
}
