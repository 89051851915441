import React from 'react';
import { connect } from 'react-redux';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import Table from '@/components/Table';
import { settings } from '../workOrder/table';

const JobsTable = ({ pro }) => {
  const queryOverride = {
    columns: [
      {
        key: 'invoiceProPayByProName',
        search: {
          text: pro?.user.fullName,
          value: pro?.user.fullName.toLowerCase(),
        },
      },
      { key: 'payFlags' },
      { key: 'proStatus' },
      { key: 'invoiceProPayByProBalance' },
      { key: 'invoiceProPayByProTotal' },
      { key: 'invoiceProPayByProPaid' },
      { key: 'invoiceProPayByProRefund' },
      { key: 'invoiceTotal' },
      { key: 'invoiceBalance' },
      { key: 'status', search: { text: 'Completed', value: { include: [W.Status.COMPLETED] } } },
    ],
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Table {...settings} queryOverride={queryOverride} />;
};

JobsTable.propTypes = { pro: U.pro.propType.isRequired };

export default connect(s => {
  const pro = s.pros[global.id()];
  return { pro };
})(JobsTable);
