import React, { useState } from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import { Modal } from '../../core';
import EditColumns from '../EditColumns';
import { DatabaseQuery } from '../types';
import { trimQuery } from '../util';

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  onChange: (query: DatabaseQuery) => Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    query: Table.Query;
    rows: Table.Row[];
  }>;
  onClose: () => void;
  query: Table.Query;
};

export default function EditQuery({
  databaseIdToTableIdToColumnIdToColumn,
  onChange,
  onClose,
  query: propQuery,
}: Props): JSX.Element {
  const [query, setQuery] = useState<DatabaseQuery>(trimQuery(propQuery));

  const handleSave = async () => {
    await onChange(query);
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} onSave={handleSave}>
      <Modal.Header title="Edit Table" />
      <Modal.Body className="p-4">
        <EditColumns
          databaseIdToTableIdToColumnIdToColumn={databaseIdToTableIdToColumnIdToColumn}
          onChange={query => setQuery(query)}
          multilevelWidth={250}
          query={query}
        />
      </Modal.Body>
      <Modal.Footer noValidate saveText="Done" />
    </Modal>
  );
}
