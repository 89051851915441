import React from 'react';
import { connect } from 'react-redux';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Date, Modal, Money, Percent, Search, Select } from '@/com/ui/form';
import { loadOrgOptions } from '@/com/util';
import TableComponent from '@/components/Table';

class EditSubsidyUI extends React.Component {
  constructor(p) {
    super(p);
    this.state = { subsidy: { status: 'active' } };
    loadOrgOptions().then(orgs => this.setState({ orgs }));
    if (p.id) {
      U.api('get', `subsidies/${p.id}`).then(subsidy => this.setState({ subsidy }));
    }
  }

  getChildContext = () => ({ t: this });

  onRemove = async () => {
    const { id, onRemove } = this.props;
    const response = await U.api('delete', `subsidies/${id}`);
    if (response.errMsg) {
      return this.setState({ error: response.errMsg });
    }
    onRemove();
    return undefined;
  };

  submit = async () => {
    const { id, onSuccess } = this.props;
    const { orgs, subsidy } = this.state;
    const newSubsidy = _.merge({}, subsidy);
    if (!_.get(newSubsidy, 'org.id')) {
      return this.setState({ error: 'Org required' });
    }
    if (!newSubsidy.startTime) {
      return this.setState({ error: 'Start time required' });
    }
    if (!_.get(newSubsidy, 'limit.amount')) {
      return this.setState({ error: 'Limit amount required' });
    }
    if (!_.get(newSubsidy, 'limit.interval')) {
      return this.setState({ error: 'Limit interval required' });
    }
    if (!newSubsidy.partDiscountRate) {
      return this.setState({ error: 'Part discount rate required' });
    }

    newSubsidy.org.name = orgs.find(org => org.id === newSubsidy.org.id).name;
    let response;
    if (id) {
      response = await U.api('put', `subsidies/${id}`, newSubsidy);
    } else {
      response = await U.api('post', 'subsidies', newSubsidy);
    }
    if (response.errMsg) {
      return this.setState({ error: response.errMsg });
    }
    onSuccess(response);
    return undefined;
  };

  render() {
    const { isEdit, isOpen, regionOptions, toggleOpen } = this.props;
    const { error, orgs } = this.state;
    return (
      <Modal
        title={`${isEdit ? 'Edit' : 'Add'} Subsidy`}
        isOpen={isOpen}
        submit={this.submit}
        onClose={toggleOpen}
        onRemove={this.onRemove}
        className="ui-modal md ui-select-search"
      >
        <p className="text-danger">{error}</p>
        <Search id="subsidy.org.id" label="Vendor" options={orgs} />
        <Search id="subsidy.regionIds" label="Regions" options={regionOptions} multi />
        <Date id="subsidy.startTime" />
        <Date id="subsidy.endTime" label="End Time (optional)" />
        <Money id="subsidy.limit.amount" label="Limit Amount" />
        <Select
          id="subsidy.limit.interval"
          label="Limit Interval"
          options={U.subsidy.intervalOptions}
          cap
        />
        <Percent id="subsidy.partDiscountRate" />
        <Select id="subsidy.status" options={U.subsidy.statusOptions} cap />
      </Modal>
    );
  }
}

EditSubsidyUI.childContextTypes = { t: PropTypes.shape({}) };

EditSubsidyUI.propTypes = {
  id: PropTypes.string,
  isEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  onRemove: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  regionOptions: PropTypes.objectOf(U.region.propType),
  toggleOpen: PropTypes.func.isRequired,
};

// Edit subsidy popup
const EditSubsidy = connect(s => {
  const regionOptions = _.sortBy(_.values(s.regions), 'name');
  return { regionOptions };
})(EditSubsidyUI);

export default function SubsidiesTable() {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableComponent {...Table.databases.default.subsidy} addUi={EditSubsidy} editUi={EditSubsidy} />
  );
}
