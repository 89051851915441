import React, { useRef } from 'react';
import { T, W } from '@nanaio/util';
import InnerHTML from 'dangerously-set-html-content';
import _ from 'lodash';
import { useLegacySelector } from '@/hooks';
import Appointments from './Appointments';
import ConfirmationFlow from './ConfirmationFlow';
import Parts from './Parts';
import PartsStatusMessage from './PartsStatusMessage';
import Questions from './questions';
import ScheduleAlert from './ScheduleAlert';
import Shipments from './Shipments';
import TaskClosed from './TaskClosed';
import TaskHeader from './TaskHeader';

export default function TaskDetails(): JSX.Element {
  const { task, workOrder } = useLegacySelector(state => {
    const taskId = global.id() || '';
    const task = state.tasks[taskId];
    const workOrder = state.workorders[task.metadata.workOrderId];
    return { task, workOrder };
  });

  const isFtcBFConfirmedV2 =
    workOrder.tags.includes(W.Tag.FTC_BOOKING_FLOW_V_2_CUSTOMER_CONFIRMED) ||
    !!workOrder.hasCustomerConfirmed;
  const isInPreScreenStatuses = [
    T.Status.NEEDS_PRE_SCREEN_REVIEW,
    T.Status.WAITING_ON_PRE_SCREEN,
  ].includes(task.status);

  const showConfirmationFlow = useRef(isInPreScreenStatuses || !isFtcBFConfirmedV2);

  let confirmationFlow;
  if (showConfirmationFlow.current) {
    confirmationFlow = <ConfirmationFlow job={task} workOrder={workOrder} />;
  }

  const orgName = task.customer?.org?.name || 'NO_VENDOR';
  const forethoughtSolveWidget = `
    <script
        src="https://solve-widget.forethought.ai/embed.js"
        type="application/javascript"
        data-api-key="8e9895f0-cad2-4e5f-bd0e-7290e96a613f"
        data-ft-Email="${task.customer.user.email}"
        data-ft-First-Name="${task.customer.user.firstName}"
        data-ft-First-Last="${task.customer.user.lastName}"
        data-ft-JobID="${task.shortId}"
        data-ft-User-Type="customer"
        data-ft-workflow-tag="Appliance_owner"
        data-ft-vendor-name="${orgName}"
      ></script>
  `;

  return (
    <>
      <InnerHTML html={forethoughtSolveWidget} />

      <section className="card-section mt-5 flex flex-col items-stretch bg-white px-8 pb-12">
        {[T.Status.COMPLETED, T.Status.CANCELLED].includes(task.status) ? (
          <TaskClosed task={task} workOrder={workOrder} />
        ) : (
          <>
            <TaskHeader task={task} />

            <div className="mb-4">
              <PartsStatusMessage task={task} />

              {confirmationFlow}

              <ScheduleAlert task={task} workOrder={workOrder} />
            </div>

            {isFtcBFConfirmedV2 && <Appointments task={task} workOrder={workOrder} />}

            <Parts task={task} />

            <Shipments task={task} />

            <Questions />
          </>
        )}
      </section>
    </>
  );
}
