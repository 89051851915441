import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import Table from '@/components/Table';
import { settings } from '../workOrder/table';

const JobsTable = ({ pro, user }) => {
  const queryOverride = {
    columns: [
      {
        key: 'proName',
        search: {
          text: _.get(user, 'profile.fullName'),
          value: { include: [pro.id] },
        },
      },
    ],
  };
  return <Table {...settings} queryOverride={queryOverride} />; // eslint-disable-line react/jsx-props-no-spreading
};

JobsTable.propTypes = { pro: U.pro.Pro, user: U.user.User };

export default connect(s => {
  const pro = s.pros[global.id()];
  return { pro };
})(JobsTable);
