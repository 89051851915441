import U from '@nanaio/util';
import _ from 'lodash';

export const getBrand = () => {
  const parts = global.location.pathname
    .split('/')
    .slice(1)
    .map(_.startCase)
    .reverse()
    .map(p => (p === 'Ge' ? 'GE' : p));
  return parts.find(p => U.brands[p]);
};
