import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button,Text } from '@/components';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';
import Stepper from './stepper';

function ApplicationConfirmationStep({ link, name }) {
  const steps = useRef([
    'Complete your application',
    'Our team will send you live updates of your application',
    'Complete a background check and upload some important documentation',
    'Start earning!',
  ]).current;
  const [, breakpointSize] = useBreakpoints();

  return (
    <div className="mx-auto max-w-md flex-1 px-12 py-6 lg:mx-0 lg:max-w-full">
      <div>
        <Text type="page-title" color="grey.dark" className="text-center font-medium lg:text-left">
          Thank you for applying, {name.trim()}!
        </Text>

        <Text
          type={breakpointSize >= Breakpoints.LG ? 'headline-3' : 'headline-5'}
          color="black"
          className="mt-4 text-center lg:text-left lg:font-semibold"
          tag="h2"
        >
          Let’s start the next stage of your application.
        </Text>

        <Text color="grey.dark" className="mt-6 text-center lg:text-left">
          Click the button below to get started. You will be taken to our partner, Fountain, to
          complete your application.
        </Text>

        <Button className="mt-10 hidden w-52 lg:block" href={link} target="fountain-application">
          Complete Application
        </Button>
      </div>

      <div className="my-12 hidden h-0.5 bg-icons-grey opacity-20 lg:block" />

      <Stepper steps={steps} selectedIndex={0} className="mt-8" />
    </div>
  );
}

ApplicationConfirmationStep.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
};

export default ApplicationConfirmationStep;
