type VendorLogos = {
  // should match case of Object.keys(U.org.vendors)
  [key: string]: {
    // should match case the vendor uses for their self
    displayName: string;
    // should be svg although png is supported
    img: string;
    // true if logo should not be displayed on public landing pages
    hidePublic?: boolean;
    // name if used in a url (no spaces)
    url: string;
  };
};

export const vendorLogos: VendorLogos = {
  twoTen: {
    displayName: '2-10',
    img: '/img/logos/2-10.svg',
    url: '2-10',
  },
  assurant: {
    displayName: 'Assurant',
    img: '/img/logos/Assurant.svg',
    url: 'Assurant',
  },
  americanHomeShield: {
    displayName: 'AHS',
    img: '/img/logos/AHS.svg',
    url: 'AHS',
  },
  cinch: {
    displayName: 'Cinch',
    img: '/img/logos/Cinch.svg',
    url: 'Cinch',
  },
  choice: {
    displayName: 'Choice',
    img: '/img/logos/Choice.svg',
    url: 'Choice',
  },
  CPS: {
    displayName: 'CPS',
    img: '/img/logos/CPS.svg',
    url: 'CPS',
  },
  electrolux: {
    displayName: 'Electrolux',
    img: '/img/logos/Electrolux.svg',
    url: 'Electrolux',
  },
  firstAmerican: {
    displayName: 'FAHW',
    img: '/img/logos/FAHW.svg',
    url: 'FAHW',
  },
  ge: {
    displayName: 'GE',
    img: '/img/logos/GE.svg',
    url: 'GE',
  },
  lg: {
    displayName: 'LG',
    img: '/img/logos/LG.svg',
    hidePublic: true,
    url: 'LG',
  },
  miele: {
    displayName: 'Miele',
    img: '/img/logos/Miele.svg',
    url: 'Miele',
  },
  onPoint: {
    displayName: 'OnPoint',
    img: '/img/logos/OnPoint.svg',
    url: 'OnPoint',
  },
  samsung: {
    displayName: 'Samsung',
    img: '/img/logos/Samsung.svg',
    url: 'Samsung',
  },
  squareTrade: {
    displayName: 'SquareTrade',
    img: '/img/logos/SquareTrade.svg',
    url: 'SquareTrade',
  },
};
