import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Text } from '@/components';
import Footer from '../../../com/ui/footer';
import AboutNav from './aboutNav';
import GrowingTeam from './growingTeam';

const NAV_HEADER_OFFSET = 66;

const timeline = [
  {
    date: 'January 2017',
    name: 'Nana is founded',
    src: '/img/historyPage/zamirFamily.jpg',
    alt: 'Zamir Family',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Nana was named after our founder’s mother; a woman who worked tirelessly to provide for
          her family when our founder’s father passed away.
        </Text>
        <Text className="mb-8 text-lg text-font-dark">
          We believe that life is too short and that a streamlined home service solution will enable
          our customers to spend as much time as possible with their loved ones - instead of toiling
          over the tediousness of appliance issues and home maintenance needs.
        </Text>
        <Text className="mb-8 text-lg text-font-dark">
          We strive to make people happy while simultaneously providing the highest level of service
          and peace of mind to every single one of our customers.
        </Text>
      </div>
    ),
  },
  {
    date: 'November 2017',
    name: 'Launch of Nana Academy',
    src: '/img/historyPage/academyLaunch.jpg',
    alt: 'Launch of Nana Academy',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          We launched the very first in person training program with the Nana Academy. The goal of
          the academy is to help up-skill the 30 million people in the U.S. who may lose their jobs
          due to automation.
        </Text>
      </div>
    ),
  },
  {
    date: 'June 2018',
    name: 'Pivot from B2C marketplace to B2B vertically integrated managed marketplace',
    src: '/img/historyPage/pivot.jpg',
    alt: 'Pivot to B2B',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Nana begins to partner with the leading manufacturers, warranty companies and property
          management groups. A vertically integrated marketplace enables technicians to have direct
          access to high paying jobs in their area. For our vendor partners, they now have access to
          thousands of quality technicians via an API.
        </Text>
      </div>
    ),
  },
  {
    date: 'January 2020',
    name: 'Nana raises $4.7M in Pre Seed funding',
    src: '/img/historyPage/preSeedFunding.jpg',
    alt: 'Pre Seed Funding',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Alpha Bridge Ventures, Bob Lee, the Uber Syndicate and several others participate in the
          pre-seed round to help Nana achieve its dream of creating economic opportunities for
          tradespeople.
        </Text>
      </div>
    ),
  },
  {
    date: 'October 2020',
    name: 'Nana is in 12 markets',
    src: '/img/historyPage/twelveMarkets.jpg',
    alt: 'Nana first twelve markets',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Nana had launched in 3 markets as of May 2019 and quickly grew to 12 markets nationwide by
          October of 2020.
        </Text>
      </div>
    ),
  },
  {
    date: 'November 2020',
    name: 'Nana raises $6M in Seed Funding',
    src: '/img/historyPage/seedFunding.jpg',
    alt: 'Seed Funding',
    paragraph: (
      <div>
        <Text className="text-lg text-font-dark">Spero Ventures leads Nana’s seed round:</Text>
        <Text className="ml-4 text-lg italic text-font-dark">
          “We are excited by Nana’s vision of providing training, access and opportunity for
          rewarding, satisfying work while also filling a critical gap in our economy. Nana has
          created a new, scalable approach to giving people the agency, tools and support systems
          they need to build new skills and pursue fulfilling work opportunities.”
        </Text>
        <Text className="mb-8 text-lg text-font-dark"> Shripriya Mahesh</Text>
      </div>
    ),
  },
  {
    date: 'January 2021',
    name: 'The online Nana Academy launches',
    src: '/img/historyPage/onlineAcademy.jpg',
    alt: 'Online Academy',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Nana Academy launches an online program that could be accessed, free of charge, throughout
          the world. The program had so much interest, we had to create a waitlist to join.
        </Text>
      </div>
    ),
  },
  {
    date: 'May 2021',
    name: 'Nana raises $17.5M Series A',
    src: '/img/historyPage/seriesAFunding.jpg',
    alt: 'Series A Funding',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          Andrew Sugrue from Avenir Growth Capital leads Nana’s Series A to help us scale the
          marketplace across the country.
        </Text>
      </div>
    ),
  },
  {
    date: 'September 2021',
    name: 'First Government sponsored cohort',
    src: '/img/historyPage/sponsoredTraining.jpg',
    alt: 'Government Sponsored Training',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          We were awarded a training grant to help upskill 30 students from underserved communities
          with a non-profit organization in the Bay Area.
        </Text>
      </div>
    ),
  },
  {
    date: 'April 2022',
    name: 'Nana expands boot camp and ride-along program outside of California',
    src: '/img/historyPage/academyExpanded.jpg',
    alt: 'Academy Expanded',
    paragraph: (
      <div>
        <Text className="mb-8 text-lg text-font-dark">
          As the pandemic lifts, Nana restarts its bootcamp program and launches it outside of
          California. Students receive classroom instruction from an instructor with years of
          experience followed by a ride-along program where they get in field experience from a
          mentor.
        </Text>
      </div>
    ),
  },
];

const scrollTo = (ele: HTMLDivElement) => {
  if (!ele) {
    return;
  }
  const y = ele.getBoundingClientRect().top + window.pageYOffset - NAV_HEADER_OFFSET;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

const getDimensions = (ele: HTMLDivElement) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export default function NanaHistory(): JSX.Element {
  const [timePointer, setTimePointer] = useState(0);
  const section1Ref = useRef<HTMLDivElement>(null);
  const section2Ref = useRef<HTMLDivElement>(null);
  const section3Ref = useRef<HTMLDivElement>(null);
  const section4Ref = useRef<HTMLDivElement>(null);
  const section5Ref = useRef<HTMLDivElement>(null);
  const section6Ref = useRef<HTMLDivElement>(null);
  const section7Ref = useRef<HTMLDivElement>(null);
  const section8Ref = useRef<HTMLDivElement>(null);
  const section9Ref = useRef<HTMLDivElement>(null);
  const section10Ref = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const sectionRefs = useMemo(
    () => [
      { section: 0, ref: section1Ref },
      { section: 1, ref: section2Ref },
      { section: 2, ref: section3Ref },
      { section: 3, ref: section4Ref },
      { section: 4, ref: section5Ref },
      { section: 5, ref: section6Ref },
      { section: 6, ref: section7Ref },
      { section: 7, ref: section8Ref },
      { section: 8, ref: section9Ref },
      { section: 9, ref: section10Ref },
    ],
    [
      section1Ref,
      section2Ref,
      section3Ref,
      section4Ref,
      section5Ref,
      section6Ref,
      section7Ref,
      section8Ref,
      section9Ref,
      section10Ref,
    ]
  );
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current === null) {
        return;
      }
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight + NAV_HEADER_OFFSET + 1;

      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected) {
        setTimePointer(selected.section);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timePointer, sectionRefs]);

  return (
    <div>
      <AboutNav />
      <div ref={headerRef} />
      <div className="container mx-auto max-w-1200 lg:flex">
        <div className="sticky top-10 hidden flex-none self-start lg:block" style={{ width: 425 }}>
          <div className="absolute z-0 ml-5 mt-14 bg-secondary-3 pl-0.5" style={{ height: 975 }} />
          {timeline.map((item, index) => (
            <div
              key={item.name}
              className="my-10 flex cursor-pointer"
              onClick={() => {
                const ref = sectionRefs[index]?.ref.current;
                if (!ref) {
                  return;
                }
                scrollTo(ref);
              }}
            >
              <div className="z-10 ml-1 animate-pulse self-start">
                {timePointer === index && (
                  <img className="animate-appear" src="/img/historyPage/activeDot.svg" alt="" />
                )}
                {timePointer !== index && <img src="/img/historyPage/dot.svg" alt="" />}
              </div>
              <div style={{ width: 386 }} className="flex-none pl-4">
                <Text
                  className={classNames('text-2xl font-semibold', {
                    'text-font-dark transition-colors': timePointer === index,
                    'text-grey-dark transition-colors': timePointer !== index,
                  })}
                >
                  {item.date}
                </Text>
                <Text
                  className={classNames('text-lg font-normal', {
                    'text-font-dark transition-colors': timePointer === index,
                    'text-grey-dark transition-colors': timePointer !== index,
                  })}
                >
                  {item.name}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-12 lg:flex lg:flex-col lg:pt-4" style={{ maxWidth: 775 }}>
          {timeline.map((item, index) => (
            <div key={item.name} className="mb-12" ref={sectionRefs[index].ref}>
              <div className="h-4" />
              <div className="flex flex-col items-start md:flex-row">
                <img
                  alt={item.alt}
                  src={item.src}
                  className={classNames('mx-auto mb-6 w-full rounded-2xl md:mx-0 md:w-1/2', {
                    'order-last md:order-first md:mr-6': index % 2 === 0,
                    'order-last md:ml-6': index % 2 === 1,
                  })}
                  style={{ maxWidth: 354 }}
                />
                <div className="md:w-1/2">
                  <div>
                    <Text className="mb-2 text-xl font-semibold text-grey-dark">{item.date}</Text>
                    <Text className="mb-6 text-4xl font-semibold text-font-dark">{item.name}</Text>
                  </div>
                  {item?.paragraph}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <GrowingTeam />
      <Footer />
    </div>
  );
}
