import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import { statusClass } from './config';

const getType = ({ date, job }) => {
  if (job.recall?.hasPastNanaRecall) {
    return 'recall';
  }
  if (job.status === 'completed') {
    return 'completed';
  }
  if (job.status === 'cancelled') {
    return 'cancelled';
  }
  if (job.status === 'pendingConfirmation') {
    return 'pending';
  }
  if (T.lastVisitStart(job) > m(date).endOf('day').valueOf()) {
    return 'visitCompleted';
  }
  if (job.status === 'assigned') {
    return 'claimed';
  }
  return 'inProgress';
};

const fetchEntities = async ({ date }) => {
  const timeRange = {
    $gte: date,
    $lt: mt(date).tz('America/Los_Angeles').endOf('day').valueOf(),
  };
  const query = {
    $or: [
      { 'tasks.visits.preferredTimeSlot.startTime': timeRange },
      { 'tasks.visits.availTSlots.startTime': timeRange },
    ],
  };
  const projection = {
    'tasks._id': 1,
    'tasks.customer.org': 1,
    'tasks.customer.user.fullName': 1,
    'tasks.customer.user.id': 1,
    'tasks.metadata.cancelReason': 1,
    'tasks.metadata.oppNotif.pros': 1,
    'tasks.recall': 1,
    'tasks.serviceAddress': 1,
    'tasks.serviceCatalogs.id': 1,
    'tasks.serviceCatalogs.name': 1,
    'tasks.status': 1,
    'tasks.tags': 1,
    'tasks.title': 1,
    'tasks.visits.assignee.id': 1,
    'tasks.visits.availTSlots.endTime': 1,
    'tasks.visits.availTSlots.startTime': 1,
    'tasks.visits.eta': 1,
    'tasks.visits.preferredTimeSlot.endTime': 1,
    'tasks.visits.preferredTimeSlot.startTime': 1,
    'tasks.visits.status': 1,
  };
  const proProjection = {
    'metadata.location': 1,
    status: 1,
    'user.address': 1,
    'user.fullName': 1,
  };
  const response = await Promise.all([
    U.api('post', 'workOrders/search', { projection, query, limit: -1 }),
    U.api('post', 'pros/search', { projection: proProjection, limit: -1 }, ['ignoreRegions']),
    U.api('post', 'partVendors/search', { limit: -1 }),
  ]);
  const tasks = _.flatten(_.map(response[0], 'tasks')).filter(T.isValid);
  const pros = response[1];
  const partVendors = response[2];

  const timeSlots = [];
  tasks.map(task => {
    task.visits.map(visit =>
      T.availTSlots(visit).map(slot => {
        if (
          _.get(visit, 'assignee.id') &&
          !slot.preferred &&
          task.status !== T.Status.PENDING_CONFIRMATION
        ) {
          return;
        }
        if (task.status === T.Status.CANCELLED && !slot.preferred) {
          return;
        }
        timeSlots.push({ ...slot, visit, job: task });
      })
    );
  });
  return { jobs: tasks, partVendors, pros, timeslots: timeSlots };
};

const getGroupsItems = async ({ date, pros, timeslots }) => {
  const groupMap = {
    pendingConfirmation: { id: 'pending', name: 'Pending', title: 'Pending' },
    cancelled: { id: 'cancelled', name: 'Cancelled', title: 'Cancelled' },
    suggested: { id: 'suggested', name: 'Pro Suggested', title: 'Pro Suggested' },
  };
  _.sortBy(pros, 'user.fullName').map(p => {
    const out = {
      ...p,
      home: _.get(p, 'user.address.geoCoordinates'),
      isPro: true,
      name: _.get(p, 'user.fullName', 'No Name'),
      title: _.get(p, 'user.fullName', 'No Name'),
    };
    if (_.get(p, 'metadata.location.coords.latitude')) {
      _.set(out, 'location.lat', p.metadata.location.coords.latitude);
      _.set(out, 'location.lng', p.metadata.location.coords.longitude);
      _.set(out, 'location.timestamp', p.metadata.location.timestamp);
    }
    groupMap[p.id] = out;
  });
  const groups = _.values(groupMap);

  const customerIdMap = {};
  timeslots.map(v => {
    _.set(customerIdMap, `${v.job.customer.user.id}.${v.job.id}`, true);
  });

  let items = timeslots.map((t, i) => {
    const { job } = t;
    const count = _.keys(customerIdMap[job.customer.user.id]).length;
    t.startTime = m(t.startTime).valueOf();
    t.endTime = m(t.endTime).valueOf();
    t.eta = _.get(t, 'visit.eta');
    const type = t.suggested ? 'suggested' : getType({ date, job });
    const routeOrder = _.get(t, 'eta.routeOrder');
    const eta = _.get(t, 'eta.arrivalTime');
    const visitCount = _.uniqBy(job.visits, 'preferredTimeSlot.startTime').length;
    let title = `\`${visitCount}\` `;
    if (count > 1) {
      title += `(${count}) `;
    }
    if (job.status !== 'pendingConfirmation') {
      title += routeOrder ? `${routeOrder}) ` : '';
    }
    const warranty = _.get(job, 'customer.org.name');
    if (warranty) {
      warranty.split(' ').forEach(v => {
        title += _.upperCase(v[0]);
      });
      title += ' - ';
    }
    title += job.customer.user.fullName.toUpperCase();
    title += `, ${_.get(job, 'serviceAddress.locality', '')}, ${job.title}`;
    const endTime = m(t.endTime).valueOf();
    const startTime = m(t.startTime).valueOf();
    let group;
    if (type === 'suggested') {
      group = 'suggested';
    } else if (_.get(t, 'visit.assignee.id')) {
      group = t.visit.assignee.id;
    } else if (job.status === 'pendingConfirmation') {
      group = 'pending';
    } else {
      group = job.status;
    }
    const isAssigned = !!T.proId(job);
    const timeZoneId = U.timezone(job.serviceAddress);
    const out = {
      className: statusClass[type],
      date: mt(t.startTime).tz('America/Los_Angeles').startOf('day').valueOf(),
      end_time: endTime,
      endTime,
      eta,
      eta2: t.eta2,
      group,
      id: `${job.id}-${i}`,
      isAssigned,
      job,
      position: _.get(job, 'serviceAddress.geoCoordinates'),
      preferred: t.preferred || isAssigned,
      resourceId: _.get(t, 'visit.assignee.id'),
      routeOrder: _.get(t, 'visit.eta.routeOrder'),
      serviceId: _.get(job, 'serviceCatalogs.0.id'),
      start_time: startTime,
      startTime,
      timeZoneId,
      title,
      type,
      visitStatus: _.get(t, 'visit.status'),
    };
    return out;
  });
  items = items.filter(i => {
    if (i.date !== date) {
      return;
    }
    if (i.group === 'pending' && i.visitStatus === 'cancelled') {
      return;
    }
    return true;
  });
  return { items, groups, groupMap };
};

const loadItems = async ({ date }) => {
  const { partVendors, pros, jobs, timeslots } = await fetchEntities({ date });
  const { groups, items, groupMap } = await getGroupsItems({
    date,
    pros,
    jobs,
    timeslots,
  });
  return { groups, items, groupMap, partVendors };
};

export default loadItems;
