import React from 'react';
import { Icon, Modal, Text } from '@/components';

type Props = {
  onClose: () => void;
  onSave: () => void;
};

export default function ConfirmRemove({ onClose, onSave }: Props): JSX.Element {
  return (
    <Modal isOpen animate={false} onClose={onClose} onSave={onSave}>
      <Modal.Header />
      <Modal.Body className="flex-column p-8">
        <div className="mx-auto mb-4 flex h-0 w-0 items-center justify-center rounded-full bg-danger p-5">
          <Icon name="delete" color="white" size={24} />
        </div>
        <Text className="mb-4 text-center" type="headline-6">
          Remove the Boost?
        </Text>
        <Text className="text-center" color="grey.dark">
          If you remove a boost, the job will be deprioritized in the pro app. Are you sure you want
          to proceed?
        </Text>
      </Modal.Body>
      <Modal.Footer closeText="Cancel" saveText="Remove" />
    </Modal>
  );
}
