import React, { useState } from 'react';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { APIError, Button, Loader, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import { useGetTask, useUpdateTask } from '@/hooks/api/task';
import BoostJobModal from './BoostJobModal';
import type { DraftBoost } from './BoostJobModal/BoostJob';

export default function BoostJobButton(): JSX.Element {
  const id = nullthrows(global.id());
  const [showModal, setShowModal] = useState(false);
  const [apiError, setAPIError] = useState<Error>();

  const taskQuery = useGetTask(id);
  const taskMutation = useUpdateTask(id);
  const boost = taskQuery.data?.boost;

  const boostedBy = useLegacySelector(state => {
    const me = nullthrows(state.me);
    const user = state.users[me.userId];
    return { id: me.userId, fullName: user.profile.fullName };
  });

  const handleSave = (draftBoost: DraftBoost) => {
    const inputBoost = _.pickBy(draftBoost) as DraftBoost;
    const lastModifiedDate = new Date();
    const optimisticBoost = _.isEmpty(inputBoost)
      ? null
      : {
          boostedBy,
          lastModified: lastModifiedDate.toISOString(),
          notify: !!inputBoost.notify,
          pros: inputBoost.pros?.map(pro => ({ id: pro.id })) ?? null,
          reason: _.camelCase(inputBoost.reason) ?? null,
        };
    const changeList = [
      { path: 'boost', value: _.isEmpty(optimisticBoost) ? undefined : optimisticBoost },
    ];
    taskMutation.mutate(changeList, { onError: error => setAPIError(error as Error) });
  };

  if (taskQuery.isLoading) {
    return <Loader isLoading className="h-20 w-20" />;
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)} size="medium">
        <Text color="white" type="button">
          {boost ? 'Edit Boost' : 'Boost Job'}
        </Text>
      </Button>

      {showModal && (
        <BoostJobModal boost={boost} onClose={() => setShowModal(false)} onSave={handleSave} />
      )}

      {apiError && (
        <APIError
          error={apiError}
          onClose={() => setAPIError(undefined)}
          text="We were unable to apply the boost. Please try again"
          variant="modal"
        />
      )}
    </>
  );
}
