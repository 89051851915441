import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Row from './Row';

const styles = {
  fixedLeft: { background: 'white', position: 'sticky' },
};

export default function Column({
  columnIndex,
  getColumnWidth,
  getRowHeight,
  getRowKey,
  rows,
  stickyColumnCount,
  stickyRowCount,
}) {
  const columnStyle = {
    minWidth: getColumnWidth(columnIndex),
    width: getColumnWidth(columnIndex),
  };

  if (columnIndex < stickyColumnCount) {
    const left = _.sum(_.times(columnIndex, getColumnWidth));
    const zIndex = stickyRowCount + 1;
    return (
      <div key={columnIndex} style={{ ...columnStyle, ...styles.fixedLeft, left, zIndex }}>
        {rows.map((row, rowIndex) => (
          <Row
            {...{
              columnIndex,
              getRowHeight,
              key: getRowKey(rowIndex),
              row,
              rowIndex,
              stickyRowCount,
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div key={columnIndex} style={columnStyle}>
      {rows.map((row, rowIndex) => (
        <Row
          {...{
            columnIndex,
            getRowHeight,
            key: getRowKey(rowIndex),
            row,
            rowIndex,
            stickyRowCount,
          }}
        />
      ))}
    </div>
  );
}

Column.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  getColumnWidth: PropTypes.func.isRequired,
  getRowHeight: PropTypes.func.isRequired,
  getRowKey: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  stickyColumnCount: PropTypes.number.isRequired,
  stickyRowCount: PropTypes.number.isRequired,
};
