import React from 'react';
import PT from 'prop-types';
import { Address,Select, Text } from '../../com/ui/form';

export default function ReturnPartStep2({
  customAddressFrom,
  customAddressTo,
  disabled,
  onShipFromAddressChange,
  onShipToAddressChange,
  shipFromOptions = [],
  shipToOptions = [],
  titleCom,
  toggleCustomAddress,
}) {
  return (
    <div id="AdminForm" className="container">
      {titleCom}
      <div className="subtitle d-flex">
        <div className="from flex-1 pr-5">Ship From</div>
        <div className="to flex-1 pl-5">Ship To</div>
      </div>
      <section id="PartCrm" className="stacked-form row">
        <div className="col pr-5">
          <div className="d-flex align-items-center">
            {customAddressFrom ? (
              <Text
                className="d-flex stacked-form-input flex-1"
                id="partReturn.shipFrom.name"
                label="Ship From"
                hint=""
                disabled={disabled}
              />
            ) : (
              <Select
                className="d-flex stacked-form-input flex-1"
                id="partReturn.shipFrom.name"
                label="Ship From"
                options={shipFromOptions}
                cap
                onChange={onShipFromAddressChange}
                disabled={disabled}
                object
              />
            )}
            {!disabled && (
              <span
                className="blue-text hover-press mb-3 ml-3 cursor-pointer"
                onClick={() => toggleCustomAddress('From')}
              >
                {customAddressFrom ? `Clear` : `+ New Address`}
              </span>
            )}
          </div>
          <Text
            className="d-flex stacked-form-input"
            id="partReturn.shipFrom.name"
            hint=""
            disabled={!customAddressFrom || disabled}
          />
          <Address
            className="d-flex stacked-form-input"
            id="partReturn.shipFrom.address"
            hint=""
            disabled={!customAddressFrom || disabled}
          />
          <Text
            className="d-flex stacked-form-input"
            id="partReturn.shipFrom.phone"
            hint=""
            disabled={!customAddressFrom || disabled}
          />
        </div>
        <div className="col pl-5">
          <div className="d-flex align-items-center">
            {customAddressTo ? (
              <Text
                className="d-flex stacked-form-input flex-1"
                id="partReturn.shipTo.name"
                label="Ship To"
                hint=""
                disabled={disabled}
              />
            ) : (
              <Select
                className="d-flex stacked-form-input flex-1"
                id="partReturn.shipTo.name"
                label="Ship To"
                options={shipToOptions}
                cap
                onChange={onShipToAddressChange}
                disabled={disabled || customAddressTo}
                object
              />
            )}
            {!disabled && (
              <span
                className="blue-text hover-press mb-3 ml-3 cursor-pointer"
                onClick={() => toggleCustomAddress('To')}
              >
                {customAddressTo ? `Clear` : `+ New Address`}
              </span>
            )}
          </div>
          <Text
            className="d-flex stacked-form-input"
            id="partReturn.shipTo.name"
            hint=""
            disabled={!customAddressTo || disabled}
          />
          <Address
            className="d-flex stacked-form-input"
            id="partReturn.shipTo.address"
            hint=""
            disabled={!customAddressTo || disabled}
          />
          <Text
            className="d-flex stacked-form-input"
            id="partReturn.shipTo.phone"
            hint=""
            disabled={!customAddressTo || disabled}
          />
        </div>
      </section>
    </div>
  );
}
ReturnPartStep2.propTypes = {
  customAddressFrom: PT.bool.isRequired,
  customAddressTo: PT.bool.isRequired,
  disabled: PT.bool.isRequired,
  onShipFromAddressChange: PT.func.isRequired,
  onShipToAddressChange: PT.func.isRequired,
  shipFromOptions: PT.array,
  shipToOptions: PT.array,
  titleCom: PT.object.isRequired,
  toggleCustomAddress: PT.func.isRequired,
};
