import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Cell from './Cell';
import Slot from './Slot';
import { availabilityPropType, capacityPropType, proPropType, timeSlotsPropType } from './util';

export default function Day({
  availability,
  capacity,
  dayIndex,
  disableHover,
  id,
  isInterested,
  pro,
  startTimes,
  timeSlots,
  toggleAvailability,
  togglePreferred,
  visit,
}) {
  let { availability: cxAvailability } = visit.cx;
  if (id !== 'cx') {
    cxAvailability = _.get(
      visit.pros.find(pro => pro.id === id),
      'availability'
    );
  }
  cxAvailability = U.timeSort(cxAvailability, 'start');

  return (
    <Cell className="flex flex-row" date={startTimes[0]}>
      {startTimes.map((time, i) => {
        const availabilityIndex = _.findIndex(cxAvailability, slot => m(slot.start).isSame(time));
        const slotTimeSlots = _.uniqBy(
          _.filter(
            timeSlots,
            timeSlot => timeSlot.workOrderId && m(timeSlot.startTime).isSame(time)
          ),
          'workOrderId'
        );
        return (
          <Slot
            {...{ availability: cxAvailability, availabilityIndex, disableHover, isInterested }}
            hasAvailability={!availability || Boolean(availability[i])}
            hasBorder={i === 1}
            isDisabled={capacity && (!capacity[i] || capacity[i].isDisabled)}
            isUnavailable={_.get(pro, `weights.${time}`) === 0 || availability?.[i] === 0}
            key={time}
            slotId={`${id}-${dayIndex * 3 + i}`}
            timeSlots={slotTimeSlots}
            toggleAvailability={toggleAvailability ? () => toggleAvailability(id, time) : undefined}
            togglePreferred={
              togglePreferred
                ? event => togglePreferred(id, availabilityIndex, event, time)
                : undefined
            }
          />
        );
      })}
    </Cell>
  );
}

Day.propTypes = {
  availability: availabilityPropType,
  capacity: PropTypes.arrayOf(capacityPropType),
  dayIndex: PropTypes.number,
  disableHover: PropTypes.bool,
  id: PropTypes.string,
  isInterested: PropTypes.bool,
  pro: proPropType,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  timeSlots: timeSlotsPropType,
  toggleAvailability: PropTypes.func,
  togglePreferred: PropTypes.func,
  visit: W.visitPropType.isRequired,
};
