import React from 'react';
import { Input, InputType } from '@/components';

type Props = {
  disableControls?: boolean;
  errors: Errors;
};

interface Errors {
  endDate: string;
  name: string;
  startDate: string;
}

export default function BasicInformation({ disableControls, errors }: Props): JSX.Element {
  return (
    <>
      <div className="mt-8 grid grid-cols-6 gap-3">
        <Input
          className="col-span-2 mb-0"
          disabled={disableControls}
          error={errors.name}
          id="name"
          label="Incentive Name"
          placeholder="Untitled Incentive"
        />

        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.startDate}
          id="startDate"
          type={InputType.DATE}
        />

        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.endDate}
          id="endDate"
          type={InputType.DATE}
        />
      </div>
      <div className="mt-2 text-center">
        Start time is always 2 AM PDT (1 AM PST) and end time is always 8 PM PDT (7 PM PST)
      </div>
    </>
  );
}
