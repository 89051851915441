import 'rodal/lib/rodal.css';

import React from 'react';
import { connect } from 'react-redux';
import { Col,Row } from 'reactstrap';
import U from '@nanaio/util';
import _ from 'lodash';
import Loader from '../../com/ui/loader';
import Invoice from './com/invoice';
import Details from './com/TaskDetails';

class UserTaskDetails extends React.Component {
  constructor(p) {
    super(p);
    U.api('get', `tasks/${global.id()}`, ['save']).then(job => {
      U.api('get', `workorders/${job.metadata.workOrderId}`, ['save']);
    });
    const orgId = _.get(this.props.job, 'customer.org.id');
    if (orgId) {
      U.api('get', `orgs/${orgId}`, true);
    }
  }

  componentWillReceiveProps(p) {
    if (!this.props.job && p.job) {
      const orgId = _.get(p.job, 'customer.org.id');
      if (orgId) {
        U.api('get', `orgs/${orgId}`, true);
      }
    }
  }

  render() {
    if (!this.props.job || !this.props.workOrder) {
      return <Loader />;
    }
    return (
      <div id="new_user_profile" className={!this.props.me.userId ? 'not-logged-in' : ''}>
        <Row className={!this.props.me.userId ? 'blurred' : ''}>
          <Col lg="9" md="8" sm="12" xs="12">
            <Details />
          </Col>
          <Col lg="3" md="4" sm="12" xs="12">
            <Invoice />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  if (!job) {
    return {};
  }
  const workOrder = s.workorders[job.metadata.workOrderId];
  global.task = job;
  global.workOrder = workOrder;
  const { me } = s;
  const code = _.get(s.users[s.me.userId], 'shortId');
  return { code, job, me, workOrder };
})(UserTaskDetails);
