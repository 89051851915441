import React from 'react';
import { Pro } from '@nanaio/util';
import { Input, Text } from '@/components';

const PRO_RULE_CONDITIONS = [
  { id: 'IS', name: 'is' },
  { id: 'IS_NOT', name: 'is not' },
];

type Props = {
  disableControls?: boolean;
  errors: Errors;
  pros: Pro[];
};

interface Errors {
  proRuleCondition: string;
  proRuleValues: string;
}

export default function ProRulesetPanel({ disableControls, errors, pros }: Props): JSX.Element {
  return (
    <>
      <Text className="mt-2.5 self-start">Technician</Text>

      <div className="self-start">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.proRuleCondition}
          id="proRule.condition"
          label=""
          options={PRO_RULE_CONDITIONS}
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.proRuleCondition}
        </Text>
      </div>

      <div className="col-span-4 self-start">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.proRuleValues}
          id="proRule.values"
          label=""
          multiple
          options={pros}
          placeholder="All technicians"
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.proRuleValues}
        </Text>
      </div>
    </>
  );
}
