import React, { useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import Footer from '../../com/ui/footer';

export default function Terms(): JSX.Element {
  const [html, setHtml] = React.useState('');
  useEffect(() => {
    async function fetchHtml() {
      const html = await (await fetch(`/terms.html`)).text();
      setHtml(html);
    }
    void fetchHtml();
  }, []);

  return (
    <div>
      <InnerHTML html={html} />
      <Footer />
    </div>
  );
}
