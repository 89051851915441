import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companyLogoGray, companyName } from '@/config/const';
import { getParams } from '../../../com/util';

class CompletionReview extends React.Component {
  constructor(p) {
    super(p);
    this.state = getParams();
    this.onContinue = this.onContinue.bind(this);
  }

  onContinue() {
    this.props.dispatch(push(`/review?taskId=${this.state.taskId}`));
  }

  render() {
    return (
      <section id="TaskReview">
        <section className="login--page reviews" style={{ paddingTop: 0 }}>
          <div className="login--container">
            <div className="login--block">
              <div style={{ textAlign: 'center', paddingBottom: '1.5em' }}>
                <img src={companyLogoGray} style={{ height: '25px' }} alt={companyName} />
              </div>
              <h4 className="weight-500">Thanks for your Feedback!</h4>
              <p className="text-muted">
                {this.state.isComplete === 'yes'
                  ? 'Click continue to tell us about your experience.'
                  : 'We will reach out to you to learn more.'}
              </p>
              {this.state.isComplete === 'yes' && (
                <div className="login--form mt-3">
                  <button onClick={this.onContinue}>Continue</button>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default connect(() => {
  return {};
})(CompletionReview);
