import U from '@nanaio/util';
import _ from 'lodash';
import { companyName, ga, isProdDb } from '../config/const';

export const addEvent = event => {
  const { events } = global.s().ga;
  if (!event || _.last(events) !== event) {
    U.redux.set('ga.events', [...events, event]);
  }
};

export const goals = {
  callButton: () => {
    if (isProdDb) {
      global.fbq('track', 'InitiateCheckout', { content_name: 'call button' });
    }
    global.gtag('event', 'add_to_cart', { event_label: 'call button' });
  },
  purchase: ({ promo, serviceId, taskId }) => {
    const service = global.store.getState().services[serviceId];
    if (!service) {
      return;
    }
    const value = service.name === 'Cleaning' ? 90 : 190;
    if (isProdDb) {
      global.fbq('track', 'Purchase', {
        value,
        currency: 'USD',
      });
    }
    global.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: taskId, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: value.toFixed(2), // Total transaction value (incl. tax and shipping)
            tax: '0.00',
            shipping: '0.00',
            coupon: promo,
          },
          products: [
            {
              // List of productFieldObjects.
              name: service.name, // Name or ID is required.
              id: service.id,
              price: value.toFixed(2),
              brand: companyName,
              category: service.ancestors[0],
              // 'variant': 'Gray',
              quantity: 1,
              coupon: promo || '', // Optional fields may be omitted or set to empty string.
            },
          ],
        },
      },
      // event: 'orderCompleted'
    });
    global.gtag('event', 'purchase', { event_label: 'purchase', value });
    global.gtag('event', 'orderCompleted');
  },
  userSignup: () => {
    if (isProdDb) {
      global.fbq('track', 'CompleteRegistration', { content_name: 'user signup' });
    }
    global.gtag('event', 'sign_up', { event_label: 'user signup' });
  },
};

const log = page => {
  global.gtag('config', ga, {
    page_path: page,
  });
  global.gtag('event', page);
};

const markFunnel = ({ name, version = 0, step = 0 }) => {
  const funnel = _.merge({}, global.s().ga.funnel[name]);
  funnel.name = name;
  funnel.version = version;
  if (!funnel.step || step > funnel.step) {
    funnel.step = step;
  }
  U.redux.set(`ga.funnel.${name}`, funnel);
};

export const clickBook = ({ step, version = 0 }) => {
  addEvent(`b${step}`);
  markFunnel({ name: 'book', step, version });
};

export const clickCall = () => {
  goals.callButton();
  addEvent('c');
  markFunnel({ name: 'call' });
};

export const pageView = (page, params, oncePerSession) => {
  const { growth, me } = global.store.getState();
  if (me.roles.length >= 2) {
    return;
  }
  page = page || global.location.pathname;
  if (oncePerSession) {
    if (growth.pageviews.includes(page)) {
      return;
    }
    U.redux.set('growth.pageviews', [...growth.pageviews, page]);
  }
  if (params) {
    _.forEach(params, (val, key) => {
      page += page.includes('?') ? `&${key}=${val}` : `?${key}=${val}`;
    });
  }
  if (global.gtag) {
    log(page);
  } else {
    const interval = setInterval(() => {
      if (!global.gtag) {
        return;
      }
      log(page);
      clearInterval(interval);
    }, 500);
  }
};

// eslint-disable-next-line camelcase
export const sendPageview = page_path => {
  if (global.dataLayer) {
    global.dataLayer.push({ event: page_path });
  }
  if (global.gtag) {
    global.gtag('config', ga, { page_path });
  }
};

export const trackConversion = () => {
  if (isProdDb && global.google_trackConversion) {
    global.google_trackConversion({
      google_conversion_id: 853001086,
      google_conversion_language: 'en',
      google_conversion_format: '3',
      google_conversion_color: 'ffffff',
      google_conversion_label: 'PKWqCK_FiHYQ_obflgM',
      google_remarketing_only: false,
    });
  }
};
