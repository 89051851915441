export const brands = [
  {
    name: 'Thermador',
    img: '/img/brand/Thermador.png',
    url: 'Thermador',
  },
  {
    name: 'Miele',
    img: '/img/brand/Miele.png',
    url: 'Miele',
  },
  {
    name: 'Sub Zero',
    img: '/img/brand/Subzero.png',
    url: 'SubZero',
  },
  {
    name: 'Speedqueen',
    img: '/img/brand/Speed Queen.png',
    url: 'Speedqueen',
  },
  {
    name: 'Gaggenau',
    img: '/img/brand/Gaggenau.png',
    url: 'Gaggenau',
  },
  {
    name: 'Kenmore',
    img: '/img/brand/Kenmore.png',
    url: 'Kenmore',
  },
  {
    name: 'GE',
    img: '/img/brand/GE.png',
    url: 'GE',
  },
  {
    name: 'Imperial',
    img: '/img/brand/Imperial.png',
    url: 'Imperial',
  },
  {
    name: 'Magic Chef',
    img: '/img/brand/Magic Chef.png',
    url: 'MagicChef',
  },
  {
    name: 'Air King',
    img: '/img/brand/air-king.png',
    url: 'AirKing',
  },
  {
    name: 'Emerson',
    img: '/img/brand/Emerson.png',
    url: 'Emerson',
  },
  {
    name: 'Allied Brass',
    img: '/img/brand/Allied Brass.png',
    url: 'AlliedBrass',
  },
  {
    name: 'Heartland',
    img: '/img/brand/Heartland.png',
    url: 'Heartland',
  },
  {
    name: 'Alto Shaam',
    img: '/img/brand/AltoShaam.png',
    url: 'AltoShaam',
  },
  {
    name: 'Alfresco Grills',
    img: '/img/brand/Alfresco.png',
    url: 'AlfrescoGrills',
  },
  {
    name: 'Hotpoint',
    img: '/img/brand/Hotpoint.png',
    url: 'Hotpoint',
  },
  {
    name: 'Amana',
    img: '/img/brand/Amana.png',
    url: 'Amana',
  },
  {
    name: 'Panasonic',
    img: '/img/brand/Panasonic.png',
    url: 'Panasonic',
  },
  {
    name: 'Maytag',
    img: '/img/brand/Maytag.png',
    url: 'Maytag',
  },
  {
    name: 'Frigidaire',
    img: '/img/brand/Frigidaire.png',
    url: 'Frigidaire',
  },
  {
    name: 'Samsung',
    img: '/img/brand/Samsung.png',
    url: 'Samsung',
  },
  {
    name: 'Sharp',
    img: '/img/brand/Sharp.png',
    url: 'Sharp',
  },
  {
    name: 'Beko',
    img: '/img/brand/Beko.png',
    url: 'Beko',
  },
  {
    name: 'Electrolux',
    img: '/img/brand/Electrolux.png',
    url: 'Electrolux',
  },
  {
    name: 'Asko',
    img: '/img/brand/Asko.png',
    url: 'Asko',
  },
  {
    name: 'Jenn-Air',
    img: '/img/brand/Jenn Air.png',
    url: 'JennAir',
  },
  {
    name: 'Kenmore Elite',
    img: '/img/brand/Kenmore Elite.png',
    url: 'KenmoreElite',
  },
  {
    name: 'Summit',
    img: '/img/brand/summit.png',
    url: 'Summit',
  },
  {
    name: 'Bosch',
    img: '/img/brand/Bosch.png',
    url: 'Bosch',
  },
  {
    name: 'Whirlpool',
    img: '/img/brand/Whirlpool.png',
    url: 'Whirlpool',
  },
  {
    name: 'Kitchen Aid',
    img: '/img/brand/KitchenAid.png',
    url: 'KitchenAid',
  },
  {
    name: 'GE Profile',
    img: '/img/brand/GE Profile.png',
    url: 'GEProfile',
  },
  {
    name: 'Fisher & Paykel',
    img: '/img/brand/Fisher Paykel.png',
    url: 'Fisher&Paykel',
  },
  {
    name: 'Zephyr',
    img: '/img/brand/Zephyr.png',
    url: 'Zephyr',
  },
  {
    name: 'Dacor',
    img: '/img/brand/Dacor.png',
    url: 'Dacor',
  },
  {
    name: 'Bakers Pride',
    img: '/img/brand/Bakers Pride.png',
    url: 'BakersPride',
  },
  {
    name: 'Burton',
    img: '/img/brand/Burton.png',
    url: 'Burton',
  },
  {
    name: 'Broil King',
    img: '/img/brand/Broil King.png',
    url: 'BroilKing',
  },
  {
    name: 'American Range',
    img: '/img/brand/American Range.png',
    url: 'AmericanRange',
  },
  {
    name: 'Marvel',
    img: '/img/brand/Marvel.png',
    url: 'Marvel',
  },
  {
    name: 'Scotsman',
    img: '/img/brand/Scotsman.png',
    url: 'Scotsman',
  },
  {
    name: 'Broan',
    img: '/img/brand/Broan.png',
    url: 'Broan',
  },
  {
    name: 'DCS',
    img: '/img/brand/DCS.png',
    url: 'DCS',
  },
  {
    name: 'Blue Star',
    img: '/img/brand/Blue Star.png',
    url: 'BlueStar',
  },
  {
    name: 'Bertazoni',
    img: '/img/brand/Bertazzoni.png',
    url: 'Bertazoni',
  },
  {
    name: 'True',
    img: '/img/brand/True.png',
    url: 'True',
  },
  {
    name: 'Lennox',
    img: '/img/brand/Lennox.png',
    url: 'Lennox',
  },
  {
    name: 'Vulcan Equipment',
    img: '/img/brand/Vulcan.png',
    url: 'VulcanEquipment',
  },
  {
    name: 'Haier',
    img: '/img/brand/Haier.png',
    url: 'Haier',
  },
  {
    name: 'Viking',
    img: '/img/brand/Viking.png',
    url: 'Viking',
  },
  {
    name: 'Wolf',
    img: '/img/brand/Wolf.png',
    url: 'Wolf',
  },
  { name: 'WedgeWood', img: '/img/brand/Wedgewood.png', url: 'WedgeWood' },
];
