import React, { useEffect, useRef, useState } from 'react';
import { MultiGrid } from 'react-virtualized';
import { usePreviousDistinct } from '@nanaio/hooks';
import * as Table from '@nanaio/table';
import U, { Option } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment-timezone';
import nullthrows from 'nullthrows';
import { isProdBuild } from '@/config/const';
import { useDeepCompareMemoRef } from '@/hooks';
import { exportToCsv } from '@/utils';
import { Form } from '../../form';
import CellUI from './Cell';
import handleMoveHelper from './handleMove';
import handleQueryChangeHelper from './handleQueryChange';
import Header from './Header';
import ReportTable from './ReportTable';
import Requests from './Requests';
import Save from './Save';
import Settings from './Settings';
import TableVectorSettings from './TableVectorSettings';
import { CompassDirection, Move, PartialReportTable, View } from './types';
import { getFlippedQuery, getVisibleRows } from './util';
import VectorSettings from './VectorSettings';

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  isLimit1000: boolean;
  lastExportTime: m.MomentInput;
  loadInitialRows: (props: {
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    query?: Table.Query;
  }) => void;
  loadRowsById: (ids: string[]) => Promise<void>;
  name: string;
  onChange: (query: Table.Query) => Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    query: Table.Query;
    rows: Table.Row[];
  }>;
  query: Table.Query;
  rows: Table.Row[];
  setIsLimit1000: (value: boolean) => void;
  setQuery: React.Dispatch<React.SetStateAction<Table.Query | undefined>>;
  setTableVisible: (isVisible: boolean) => void;
  showRows: (rowIds?: string[]) => void;
  updateUrl: (query: Table.Query) => void;
};

export default function Analytics({
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  isLimit1000,
  lastExportTime,
  loadInitialRows,
  loadRowsById,
  name,
  onChange,
  query,
  rows,
  setIsLimit1000,
  setQuery,
  setTableVisible,
  showRows,
  updateUrl,
}: Props): JSX.Element {
  const [activeCellId, setActiveCellId] = useState<string>();
  const [columnHeaderDepth, setColumnHeaderDepth] = useState<number>(-1);
  const [columnTableVectors, setColumnTableVectors] = useState<Table.TableVector[]>([]);
  const [editVectorIndex, setEditVectorIndex] = useState<number>();
  const [generateReportIsOpen, setGenerateReportIsOpen] = useState<boolean>();
  const [headerCell, setHeaderCell] = useState<{ columnIndex: number; rowIndex: number }>();
  const [isLoading, setIsLoading] = useState(Boolean(query.id));
  const rowsRef = useDeepCompareMemoRef<Table.Row[]>(rows);
  const queryRef = useDeepCompareMemoRef<Table.Query>(query);
  const [reportId, setReportId] = useState<string>('live');
  const [reportOptions, setAnalyticsReportOptions] = useState<Option[]>([]);
  const [reports, setReports] = useState<Table.Report[]>([]);
  const [rowGenerationView, setRowGenerationView] = useState<View>();
  const [rowHeaderDepth, setRowHeaderDepth] = useState<number>(-1);
  const [rowTableVectors, setRowTableVectors] = useState<Table.TableVector[]>([]);
  const [reportRows, setReportRows] = useState<Table.Cell[][]>([]);
  const [saveAsPopup, setSaveAsPopup] = useState(false);
  const [settingsIsOpen, setSettingsIsOpen] = useState<boolean>(
    query.id && isProdBuild ? false : true
  );
  const tableRef = useRef<MultiGrid>();
  const [tableVector, setTableVector] = useState<Table.TableVector>();
  const [tableVectorIdToIsCollapsed, setTableVectorIdToIsCollapsed] = useState<
    Record<string, boolean>
  >({});
  const [view, setView] = useState(View.REPORT);
  const [visibleRows, setVisibleRows] = useState<Table.Cell[][]>([]);

  const activeReport = reports.find(analyticsReport => analyticsReport.id === reportId);
  const activeColumnHeaderDepth = activeReport?.columnHeaderDepth || columnHeaderDepth;
  const activeRowHeaderDepth = activeReport?.rowHeaderDepth || rowHeaderDepth;
  const reportTables: PartialReportTable[] = activeReport
    ? activeReport.tables
    : [{ rows: visibleRows }];

  const updateRows = (
    query: Table.Query,
    updatedData: {
      databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
      isLimit1000?: boolean;
      rows?: Table.Row[];
      view?: View;
    } = {}
  ) => {
    if (!rowsRef.current?.length) {
      return;
    }
    const newIsLimit1000 = _.isBoolean(updatedData.isLimit1000)
      ? updatedData.isLimit1000
      : isLimit1000;
    const newRows = updatedData.rows || rowsRef.current;
    const newDatabaseIdToTableIdToColumnKeyToOptionIdToOption =
      updatedData.databaseIdToTableIdToColumnKeyToOptionIdToOption ||
      databaseIdToTableIdToColumnKeyToOptionIdToOption;
    const newView = updatedData.view || view;
    const {
      columnHeaderDepth,
      columnTableVectors,
      rowHeaderDepth,
      rowTableVectors,
      rows,
      visibleRows,
    } = Table.getTable({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        newDatabaseIdToTableIdToColumnKeyToOptionIdToOption,
      query,
      rows: newIsLimit1000 ? newRows.slice(0, Table.sandboxRowLimit) : newRows,
    });
    const tableVectors = [...columnTableVectors, ...rowTableVectors];
    const collapsedRows = getVisibleRows({
      rows: visibleRows,
      tableVectorIdToIsCollapsed,
      tableVectors,
    });

    setColumnHeaderDepth(columnHeaderDepth);
    setColumnTableVectors(columnTableVectors);
    setRowHeaderDepth(rowHeaderDepth);
    setReportRows(rows);
    setRowGenerationView(newView);
    setRowTableVectors(rowTableVectors);
    setVisibleRows(collapsedRows);
    if (tableRef.current) {
      tableRef.current.recomputeGridSize();
    }
  };

  useEffect(() => {
    setTableVisible(false);
    void U.api<Table.AnalyticsRequest | undefined>('get', `analytics/request/${query.id}`).then(
      response => {
        if (response && !('errMsg' in response)) {
          // setAnalyticsRequest(response);
        }
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (query.id) {
      // load most recent analytics table
      void U.api<Table.Report[]>('post', 'analytics/reports/search', {
        limit: 1,
        query: { tableId: query.id },
      }).then(reports => {
        if (Array.isArray(reports)) {
          const analyticsReportId = reports[0]?.id;
          setReportId(analyticsReportId || 'live');
          setReports(reports);
        } else {
          setReports([]);
        }
        setIsLoading(false);
      });

      // load analytics report options
      void U.api<Table.Report[]>('post', 'analytics/reports/search', {
        projection: { _id: 1, createTime: 1 },
        query: { tableId: query.id },
      }).then(analyticsReports => {
        if (Array.isArray(analyticsReports)) {
          const options = analyticsReports.map(analyticsTable => ({
            id: analyticsTable.id,
            name: m(analyticsTable.createTime).format('M/D/YY h:mma'),
          }));
          setAnalyticsReportOptions(options);
        } else {
          setAnalyticsReportOptions([]);
        }
      });
    }
  }, [query.id]);

  // update rows on rows change
  useEffect(() => {
    if (queryRef.current && !isLoading && reportId === 'live') {
      updateRows(queryRef.current);
    }
  }, [reportId, isLoading, rowsRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const onExport = () => {
    for (const report of reportTables) {
      const fileName = `${queryRef.current?.name || 'No Name'}${
        report.timeRange
          ? `${m(report.timeRange.start).format('M/D/YY')}-${m(report.timeRange.end).format(
              'M/D/YY'
            )} `
          : ''
      } created at ${m().format('M/D/YY h:mma')}.csv`;
      exportToCsv(
        fileName,
        report.rows.map(cells =>
          cells.map(cell => (cell.text === undefined ? '' : String(cell.text)))
        )
      );
    }
  };

  const previousLastExportTime = usePreviousDistinct(lastExportTime);
  useEffect(() => {
    if (lastExportTime !== previousLastExportTime) {
      onExport();
    }
  }, [lastExportTime, previousLastExportTime]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAnalyticsReportChange = async (analyticsReportId: string): Promise<void> => {
    setReportId(analyticsReportId);
    if (analyticsReportId === 'live') {
      loadInitialRows({});
    } else {
      if (!reports.find(analyticsReport => analyticsReport.id === analyticsReportId)) {
        setIsLoading(true);
        const result = await U.api<Table.Report>('get', `analytics/report/${analyticsReportId}`);
        if (!('errMsg' in result)) {
          setAnalyticsReportOptions([...reportOptions, result]);
        }
        setIsLoading(false);
      }
    }
  };

  /*const handleCancel = () => {
    const progress = { isCancelRequested: true, step: 'Cancelling', tableId: query.id };
    setAnalyticsRequest(progress);
    void U.api('post', 'analytics/progress', progress);
  };*/

  const deleteAnalyticsTable = () => {
    const analyticsReportIdCopy = reportId;
    void handleAnalyticsReportChange('live');
    setAnalyticsReportOptions(
      reportOptions.filter(option =>
        typeof option === 'string'
          ? option !== analyticsReportIdCopy
          : option.id !== analyticsReportIdCopy
      )
    );
    void U.api('delete', `analytics/reports/${analyticsReportIdCopy}`);
  };

  const handleSetView = (newView: View) => {
    if (
      (view === View.TABLE && newView !== View.TABLE) ||
      (view !== View.TABLE && newView === View.TABLE)
    ) {
      setTableVisible(newView === View.TABLE);
    }
    setView(newView);
    if (newView === View.REPORT && rowGenerationView !== View.REPORT && queryRef.current) {
      updateRows(queryRef.current, { view: newView });
    }
  };

  const handleCellClick = ({
    columnIndex,
    rowIndex,
  }: {
    columnIndex: number;
    rowIndex: number;
  }): void => {
    if (columnIndex < rowHeaderDepth || rowIndex < columnHeaderDepth) {
      setHeaderCell({ columnIndex, rowIndex });
      setTableVector(
        columnIndex < rowHeaderDepth ? rowTableVectors[rowIndex] : columnTableVectors[columnIndex]
      );
    } else {
      if (columnIndex && rowIndex) {
        const cell = _.cloneDeep(reportTables[0].rows[rowIndex][columnIndex]);
        if (reportId !== 'live') {
          /*const response = await U.api<Table.AnalyticsCell[]>('post', 'analytics/cells/search', {
            limit: 1,
            query: { analyticsTableId, cellId: cell.id },
          });
          if (Array.isArray(response)) {
            cell.rowIds = response[0].rowIds;
          }*/
        }
        if (activeCellId === cell.id) {
          setActiveCellId(undefined);
          showRows(undefined);
        } else {
          setActiveCellId(cell.id);
          showRows(cell.rowIds);
          handleSetView(View.TABLE);
          if (reportId !== 'live' && cell.rowIds) {
            void loadRowsById(cell.rowIds);
          }
        }
        tableRef.current?.recomputeGridSize();
      }
    }
  };

  const handleCollapse = ({ columnIndex, rowIndex }: { columnIndex: number; rowIndex: number }) => {
    setHeaderCell(undefined);
    const tableVector =
      columnIndex < rowHeaderDepth ? rowTableVectors[rowIndex] : columnTableVectors[columnIndex];
    const tableVectorIdToIsCollapsedCopy = _.cloneDeep(tableVectorIdToIsCollapsed);
    tableVectorIdToIsCollapsedCopy[tableVector.id] =
      !tableVectorIdToIsCollapsedCopy[tableVector.id];
    setTableVectorIdToIsCollapsed(tableVectorIdToIsCollapsedCopy);
    const visibleRows = getVisibleRows({
      rows: reportRows,
      tableVectorIdToIsCollapsed: tableVectorIdToIsCollapsedCopy,
      tableVectors: [...columnTableVectors, ...rowTableVectors],
    });
    setVisibleRows(visibleRows);
    tableRef.current?.recomputeGridSize();
  };

  const handleFlip = () => {
    const query = getFlippedQuery({ query: queryRef.current });
    setQuery(query);
    updateRows(query);
  };

  const handleMove = ({
    action,
    compassDirection,
    index,
  }: {
    action: Move;
    compassDirection?: CompassDirection;
    index: number;
  }) => {
    if (!queryRef.current) {
      return;
    }
    const query = handleMoveHelper({
      action,
      compassDirection,
      index,
      query: queryRef.current,
    });
    const oldVectors = Table.getValidVectors({
      databaseIdToTableIdToColumnIdToColumn,
      query: queryRef.current,
    });
    const newVectors = Table.getValidVectors({
      databaseIdToTableIdToColumnIdToColumn,
      query: query,
    });
    setQuery(query);
    if (!_.isEqual(oldVectors, newVectors)) {
      updateRows(query);
    }
  };

  /** intercept analytics changes, modify the changed value, and re-generate summary data */
  const handleQueryChange = async (id: string, value: unknown) => {
    if (!queryRef.current) {
      return;
    }
    setIsLoading(true);
    const { query, shouldPatchRows } = handleQueryChangeHelper({
      databaseIdToTableIdToColumnIdToColumn,
      id,
      query: queryRef.current,
      value,
    });
    setQuery(query);
    if (shouldPatchRows) {
      updateRows(query, await onChange(query));
    } else {
      updateRows(query);
    }
    setIsLoading(false);
  };

  const handleSave = () => {
    if (queryRef.current?.id) {
      void U.api('put', 'query', queryRef.current);
    } else {
      setSaveAsPopup(true);
    }
  };

  const handleSetIsLimit1000 = (isLimit1000: boolean) => {
    setIsLimit1000(isLimit1000);
    if (rowsRef.current.length > 1000) {
      updateRows(query, { isLimit1000 });
    }
  };

  const handleSort = ({ sort, vectorId }: { sort: Table.Sort; vectorId: string }) => {
    setHeaderCell(undefined);
    const query = _.cloneDeep(queryRef.current);
    const vector = query.analytics.vectors.find(vector => vector.id === vectorId);
    nullthrows(vector).sort = sort;
    setQuery(query);
    updateRows(query);
  };

  const handleSubmitVector = (vectorFilters: Table.Vector['filters']) => {
    const vector = queryRef.current.analytics.vectors[nullthrows(editVectorIndex)];
    if (!_.isEqual(vector.filters, vectorFilters)) {
      void handleQueryChange(
        `analytics.vectors.${nullthrows(editVectorIndex)}.filters`,
        vectorFilters
      );
    }
    setEditVectorIndex(undefined);
  };

  const handleToggleSettingsIsOpen = () => {
    setSettingsIsOpen(!settingsIsOpen);
    handleSetIsLimit1000(!settingsIsOpen);
  };

  const toggleGenerateReport = () => {
    setGenerateReportIsOpen(!generateReportIsOpen);
  };

  return (
    <Form className="mt-4" onPatch={handleQueryChange} value={query}>
      <Header {...{ name, onSave: handleSave, query, setView: handleSetView, view }} />
      {view === View.REPORT && (
        <>
          <Settings
            {...{
              // analyticsRequest,
              analyticsTableId: reportId,
              analyticsTableOptions: reportOptions,
              databaseIdToTableIdToColumnIdToColumn,
              databaseIdToTableIdToColumnKeyToOptionIdToOption,
              deleteAnalyticsTable,
              isLimit1000,
              isLoading,
              isOpen: settingsIsOpen,
              rows,
              name,
              onAnalyticsReportChange: handleAnalyticsReportChange,
              //onCancel: handleCancel,
              onMove: handleMove,
              openGenerateReport: toggleGenerateReport,
              openSettings: setEditVectorIndex,
              query,
              setIsLimit1000: handleSetIsLimit1000,
              swapAxis: handleFlip,
              toggleIsOpen: handleToggleSettingsIsOpen,
            }}
          />
          {activeColumnHeaderDepth !== undefined &&
            activeRowHeaderDepth !== undefined &&
            [...reportTables].reverse().map((reportTable, reportIndex) => (
              <ReportTable
                {...{
                  columnHeaderDepth: activeColumnHeaderDepth,
                  databaseIdToTableIdToColumnIdToColumn,
                  key: reportIndex,
                  query,
                  renderCell: cell => {
                    if (!reportTable.rows[cell.rowIndex][cell.columnIndex]) {
                      return null;
                    }
                    return (
                      <CellUI
                        {...{
                          cell: reportTable.rows[cell.rowIndex][cell.columnIndex],
                          columnHeaderDepth: activeColumnHeaderDepth,
                          columnIndex: cell.columnIndex,
                          columnTableVectors,
                          isActive:
                            activeCellId === reportTable.rows[cell.rowIndex][cell.columnIndex].id,
                          key: reportTable.rows[cell.rowIndex][cell.columnIndex].id,
                          onClick: () => {
                            void handleCellClick({
                              columnIndex: cell.columnIndex,
                              rowIndex: cell.rowIndex,
                            });
                          },
                          query,
                          rowHeaderDepth: activeRowHeaderDepth,
                          rowIndex: cell.rowIndex,
                          rowTableVectors,
                          showPoints: query.analytics.showPoints,
                          style: cell.style,
                          tableVectorIdToIsCollapsed,
                          vectors: query.analytics.vectors,
                        }}
                      />
                    );
                  },
                  report: activeReport,
                  reportTable,
                  rowHeaderDepth: activeRowHeaderDepth,
                  rows: reportTable.rows,
                  showPoints: query.analytics.showPoints,
                  table: tableRef as React.LegacyRef<MultiGrid>,
                }}
              />
            ))}
        </>
      )}
      {view === View.REQUEST && (
        <Requests
          keyToModule={databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]}
          table={query}
        />
      )}
      {tableVector && headerCell && (
        <TableVectorSettings
          columnIndex={headerCell.columnIndex}
          databaseIdToTableIdToColumnIdToColumn={databaseIdToTableIdToColumnIdToColumn}
          isCollapsed={tableVectorIdToIsCollapsed[tableVector.id]}
          onClose={() => setHeaderCell(undefined)}
          onCollapse={handleCollapse}
          onSort={handleSort}
          query={query}
          rowIndex={headerCell.rowIndex}
          tableVector={tableVector}
        />
      )}
      {_.isNumber(editVectorIndex) && (
        <VectorSettings
          databaseIdToTableIdToColumnIdToColumn={databaseIdToTableIdToColumnIdToColumn}
          databaseIdToTableIdToColumnKeyToOptionIdToOption={
            databaseIdToTableIdToColumnKeyToOptionIdToOption
          }
          idToVector={_.keyBy(query.analytics.vectors, 'id')}
          index={editVectorIndex}
          rows={rows}
          onClose={() => setEditVectorIndex(undefined)}
          onSubmit={handleSubmitVector}
          query={query}
        />
      )}
      {saveAsPopup && (
        <Save
          onClose={() => setSaveAsPopup(false)}
          query={query}
          setQuery={setQuery}
          updateUrl={updateUrl}
        />
      )}
    </Form>
  );
}
