import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import Alert, { colorByVariant, Props as AlertProps } from './Alert';
import Modal from './Modal';
import Spacer from './Spacer';
import Text from './Text';

type Props = {
  children?: string;
  isOpen?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  variant?: AlertProps['variant'];
};

export default function Confirm({
  children = 'Are you sure?',
  isOpen = false,
  onCancel,
  onConfirm,
  variant = 'warning',
}: Props): JSX.Element {
  const color = colorByVariant[variant];
  const cssColor = _.kebabCase(color);

  return (
    <Modal isOpen={isOpen} animate={false}>
      <Alert variant={variant}>
        {U.isText(children) ? (
          <Text className="overflow-hidden" color={color}>
            {children}
          </Text>
        ) : (
          children
        )}
        <Spacer
          className="mt-4"
          leftContent={
            <button
              className={`rounded border border-${cssColor} px-4 py-3`}
              onClick={onCancel}
              type="button"
            >
              <Text color={color} type="button">
                No
              </Text>
            </button>
          }
          rightContent={
            <button
              className={`rounded bg-${cssColor} px-4 py-3`}
              onClick={onConfirm}
              type="button"
            >
              <Text color="white" type="button">
                Yes
              </Text>
            </button>
          }
          width={100}
        />
      </Alert>
    </Modal>
  );
}
