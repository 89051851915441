import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import U from '@nanaio/util';
import _ from 'lodash';
import Loader from '../../../com/ui/loader';
import columns from './com/columns';
import Summary from './com/summary';

const tags = ['askCard', 'homeWarranty', 'partsProvided', 'standard', 'premium', 'luxury'];

class Opportunites extends React.Component {
  constructor(p) {
    super(p);
    this.state = { loading: true };
    this.load();
  }

  load = async () => {
    this.setState({ loading: true });
    const response = await U.api('get', `serviceproviders/me/opportunities/v3/${global.id()}/true`);
    const jobs = _.isArray(_.get(response, 'opportunities')) ? response.opportunities : [];
    const blockingFlags = _.get(response, 'blockingFlags', []);
    const oppBlockedDueToFlags = _.get(response, 'oppBlockedDueToFlags');
    const oppBlockedDueToRecalls = _.get(response, 'oppBlockedDueToRecalls');
    const count = _.sortBy(
      _.map(U.toMapArray(jobs, 'type'), (jobs, type) => ({ count: jobs.length, type })),
      'count'
    ).reverse();
    this.setState({
      blockingFlags,
      loading: false,
      jobs,
      count,
      oppBlockedDueToFlags,
      oppBlockedDueToRecalls,
    });
  };

  getRowHeight = (jobs, index) => {
    if (!index) {
      return 50;
    }
    const job = jobs[index - 1];
    let dateCount = _.values(job.availability).length;
    if (job.type === 'route' && job.jobs.length > dateCount) {
      dateCount = job.jobs.length;
    }
    return dateCount <= 2 ? 50 : dateCount * 25;
  };

  onCellClick = (job, column) => {
    if (!column.noLink && job.type !== 'route') {
      window.open(column.link ? column.link(job) : `/tasks/${job.id || job.tasks[0].id}`, '_blank');
    }
  };

  renderCell = (jobs, cell) => {
    if (!cell.isVisible) {
      return null;
    }
    const column = columns[cell.columnIndex];
    if (!cell.rowIndex) {
      return (
        <div
          style={{ overflow: 'hidden', whiteSpace: 'normal' }}
          key={`${cell.rowIndex}-${cell.columnIndex}`}
        >
          <div style={{ ...cell.style, fontWeight: 600 }} className="p-td">
            {column.name}
          </div>
        </div>
      );
    }
    const job = jobs[cell.rowIndex - 1];
    const cursor = job.type === 'route' ? 'not-allowed' : 'pointer';
    const value = column.getValue && column.getValue({ job, pro: this.props.pro });
    return (
      <div style={{ overflow: 'hidden' }} key={`${cell.rowIndex}-${cell.columnIndex}`}>
        <div
          className="p-td"
          style={{ ...cell.style, cursor }}
          onClick={() => this.onCellClick(job, column)}
        >
          {column.renderCell
            ? column.renderCell({
                blockingFlags: this.state.blockingFlags,
                job,
                pro: this.props.pro,
              })
            : _.isArray(value)
              ? value.map((v, n) => (
                  <div key={n} style={{ height: '22px', overflowX: 'auto' }}>
                    {v}
                  </div>
                ))
              : value}
        </div>
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    const { jobs } = this.state;
    const width = _.sum(columns.map(c => c.width || 100));
    return (
      <div style={{ flex: 1, display: 'flex', height: '100vh' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {this.state.oppBlockedDueToFlags && (
            <div className="text-danger">Opportunities blocked due to flags</div>
          )}
          {this.state.oppBlockedDueToRecalls && (
            <div className="text-danger">Opportunities blocked due to recalls</div>
          )}
          <Summary count={this.state.count} pro={this.props.pro} width={width} />
          <div
            style={{ flex: 1, width: '100%', whiteSpace: 'nowrap' }}
            className="p-table bg-white"
          >
            <AutoSizer>
              {({ width, height }) => (
                <MultiGrid
                  height={height}
                  columnCount={columns.length}
                  columnWidth={({ index }) => columns[index].width || 100}
                  rowHeight={({ index }) => this.getRowHeight(jobs, index)}
                  rowCount={jobs.length + 1}
                  estimatedRowSize={50}
                  fixedRowCount={1}
                  width={width}
                  cellRenderer={c => this.renderCell(jobs, c)}
                />
              )}
            </AutoSizer>
            <style>{'.ReactVirtualized__Grid__innerScrollContainer { margin: 0 auto; }'}</style>
          </div>
        </div>
        <table className="table-sm table-striped table-hover ml-3 table">
          <thead>
            <tr>
              <th>Filters</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Route Distance</td>
              <td>{_.get(this.props.pro, 'metadata.routeDistance', 10)}</td>
            </tr>
            {tags.map(t => (
              <tr key={t}>
                <td>{_.startCase(t)}</td>
                <td>
                  {_.get(this.props.pro, `metadata.opFilters.${t}`) === false ? 'hide' : 'show'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(s => {
  const pro = s.pros[global.id()] || { id: global.id() };
  return { pro };
})(Opportunites);
