import { useState } from 'react';
import { U } from '@nanaio/util';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';
import SegmentLogger from '../../com/analytics/SegmentLogger';
import { analyticsEnabled } from '../../config/const';

type Features = Record<string, unknown>;

const fetchFeatures = async (loggedOutUserId: string): Promise<Features> => {
  const { data: features } = await AXIOS_INSTANCE.get<Features>(
    `/api/features?loggedOutUserId=${loggedOutUserId}`
  );

  // This is only used by the Sentry logger
  U.redux.set('features', _.isObject(features) ? features : {});

  // TODO If there were a me Hook we could call identify(me, features) here
  return features;
};

export const useGetFeatures = (): UseQueryResult<Features> => {
  // loggedOutUserId is used to identify the user in Segment before they have a user account.
  // Normally this is just used within Segment, but we want to send it to the features
  // endpoint so that we can optionally put non-logged in users into different test buckets.
  const [loggedOutUserId, setLoggedOutUserId] = useState('');

  if (loggedOutUserId === '' && analyticsEnabled) {
    // The loggedOutUserId response that we get will change after identify(me, features) is called
    // However we don't want to get the loggedOutUserId again after that because we will have switched
    // to using the me.userId as the identifier for features at that point.
    // So just get it once, and then don't get it again (which would cause a re-render)
    void SegmentLogger.analytics?.user().then(user => {
      setLoggedOutUserId(user.anonymousId() || '');
    });
  }

  return useQuery({
    queryKey: ['features', loggedOutUserId],
    queryFn: () => fetchFeatures(loggedOutUserId),
    enabled: !!loggedOutUserId || !analyticsEnabled,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
