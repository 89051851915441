import Papa from 'papaparse';
import { readFile } from './file-reader';

export function parseCSVFile(file, columns) {
  const mapItems = data =>
    Papa.parse(data)
      .data.slice(1)
      .filter(row => row.length === columns.length);

  if (!file) {
    return Promise.resolve([]);
  }

  return new Promise((resolve, reject) => {
    return readFile(file)
      .then(data => resolve(mapItems(data)))
      .catch(e => reject(e));
  });
}
