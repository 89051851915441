import React, { ReactNode, useContext } from 'react';
import Icon from '../Icon';
import Spacer from '../Spacer';
import Text from '../Text';
import Context from './Context';

type Props = {
  children?: ReactNode;
  leftContent?: ReactNode;
  onBack?: () => void;
  progress?: number;
  rightContent?: ReactNode;
  spacerContentWidth?: number;
  subtitle?: string;
  title?: string;
};

export default function Header({
  children,
  leftContent,
  onBack,
  progress,
  rightContent,
  spacerContentWidth = 40,
  subtitle,
  title,
}: Props): JSX.Element {
  const { onClose } = useContext(Context);

  return (
    <div className="relative rounded-t border-b border-grey-medium bg-white px-6 py-3 shadow-md">
      <Spacer
        className="items-center"
        leftContent={
          leftContent ||
          (onBack && (
            <span onClick={onBack}>
              <Icon
                className="-ml-2 text-grey-dark hover:cursor-pointer hover:text-secondary"
                name="chevron_left"
                size={28}
              />
            </span>
          ))
        }
        rightContent={
          rightContent ||
          (onClose && (
            <span className="flex justify-end" data-cy="close-modal" onClick={onClose}>
              <Icon
                className="-mr-1 text-grey-dark hover:cursor-pointer hover:text-secondary"
                name="close"
                size={24}
              />
            </span>
          ))
        }
        width={spacerContentWidth}
      >
        <div>
          {title && (
            <Text className="-mb-1 truncate" noWrap type="page-title">
              {title}
            </Text>
          )}
          {subtitle && (
            <Text className="mt-1.5 text-center" color="grey.dark" type="helper">
              {subtitle}
            </Text>
          )}
        </div>
      </Spacer>
      {children}
      {progress != null && (
        <div className="-mx-6 -mb-3 mt-1.5 h-1 bg-background-light">
          <div className="h-1 bg-primaryCTA" style={{ width: `${progress * 100}%` }} />
        </div>
      )}
    </div>
  );
}
