import React from 'react';
import { T, W } from '@nanaio/util';
import _ from 'lodash';
import { Text } from '@/components';
import { useLegacySelector } from '@/hooks';

type Props = {
  taskId: string;
};

export default function VisitConfirmStatus({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = workOrders[task.metadata.workOrderId];

  const lastVisit = T.lastVisit(task);
  const proHasConfirmed = _.find(
    _.get(_.last(workOrder.visits), 'pros'),
    pro => pro.status === W.VisitProStatus.CONFIRMED
  );
  const proHasNotConfirmed = _.find(
    _.get(_.last(workOrder.visits), 'pros'),
    pro => pro.status === W.VisitProStatus.PENDING
  );

  return (
    <div className="mr-3">
      {_.get(lastVisit, 'confirmed') ? (
        <Text className="text-success">Cx Has Confirmed</Text>
      ) : (
        <Text className="text-danger">Cx Has Not Confirmed</Text>
      )}
      {proHasConfirmed ? (
        <Text className="text-success">Tech Has Confirmed</Text>
      ) : proHasNotConfirmed ? (
        <Text className="text-danger">Tech Has Not Confirmed</Text>
      ) : undefined}
    </div>
  );
}
