import './com/data.css';
import './style.css';

import { ComponentClass, createElement, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import partVendors from '../partVendor';
import samsungModels from '../samsungModels';
import brands from './Brands';
import capacity from './Capacity';
import catalogPartCategories from './catalogPartCategories';
import catalogParts from './catalogParts';
import cities from './cities';
import demand from './demand';
import deposits from './deposits';
import earnings from './Earnings';
import flags from './flags';
import inAppCommunications from './inAppCommunications';
import incentives from './incentives';
import locations from './Locations';
import mentorship from './mentorship';
import notCoveredServices from './notCoveredServices';
import opportunitiesByPro from './opportunitiesByPro';
import payments from './Payments';
import performance from './Performance';
import permissions from './permissions';
import pricingPackages from './pricing/Packages';
import pricingRegions from './pricing/Regions';
import pricingRules from './pricing/Rules';
import pricingVendors from './pricing/vendors';
import promos from './promos/index';
import proPerformance from './proPerformance';
import queries from './Queries';
import regions from './regions';
import resources from './Resources';
import statuses from './status/Graph';
import subsidies from './subsidies';
import TableOfContents from './TableOfContents';
import tags from './tags';
import tools from './tools';

const reports: Record<string, string | FunctionComponent | ComponentClass> = {
  brands,
  capacity,
  catalogPartCategories,
  catalogParts,
  cities,
  demand,
  deposits,
  earnings,
  flags,
  inAppCommunications,
  incentives,
  locations,
  mentorship,
  notCoveredServices,
  opportunitiesByPro,
  partVendors,
  samsungModels,
  payments,
  performance,
  permissions,
  pricingPackages,
  pricingRegions,
  pricingRules,
  pricingVendors,
  proPerformance,
  promos,
  queries,
  regions,
  resources,
  statuses,
  subsidies,
  tags,
  tools,
};

const Reports = ({ report }: { report: string }) =>
  createElement(reports[report] || TableOfContents);

export default connect(s => {
  const path = s.router.location?.pathname || '';
  const parts = path
    .slice(9)
    .split('/')
    .map(v => _.camelCase(v));
  const matchFound = !!reports[parts[0]];
  let report = 'tableOfContents';
  if (matchFound) {
    report = _.camelCase(_.split(s.router.location?.pathname, '/')[2]);
  }
  return { path, report };
})(Reports);
