import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import U, { ServerEntity } from '@nanaio/util';
import _ from 'lodash';
import { idFromURL, openLink } from '@/utils';
import { Button, Modal, Text } from '../../core';
import { Form,Input, InputType } from '../../form';
import { trimQuery } from '../util';

export default function EditModule(props: Table.AddUiProps | Table.EditUiProps): JSX.Element {
  const { id, keyToModule, onSuccess, table, toggleOpen } = props;
  const onRemove = 'onRemove' in props ? props.onRemove : undefined; // eslint-disable-line react/destructuring-assignment
  const [error, setError] = useState<string>();
  const [queries, setQueries] = useState<Table.Query[]>();
  const [module, setModule] = useState<Partial<Table.Column>>({ table: table.table });
  const [replaceWith, setReplaceWith] = useState<string>();

  useEffect(() => {
    if (id) {
      if (idFromURL(id)) {
        void U.api<Table.Column>('get', `column/${id}`).then(response => {
          if (!('errMsg' in response)) {
            setModule(response);
          }
        });
      } else {
        const module = _.pickBy(keyToModule?.[id], field => !_.isFunction(field));
        if (module) {
          setModule(module);
        }
      }
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = async () => {
    if (queries?.length && module.id) {
      if (replaceWith) {
        for (const query of queries) {
          for (const vector of query.analytics.vectors) {
            if (module.key && vector.formula.includes(module.key)) {
              vector.formula = vector.formula.map(moduleId =>
                moduleId === module.key ? replaceWith : moduleId
              );
            }
            if (vector.moduleId === module.key) {
              vector.moduleId = replaceWith;
            }
          }
          for (const column of query.columns) {
            if (column.key === module.key) {
              column.key = replaceWith;
            }
          }
          if (query.sort?.key === module.key) {
            query.sort.key = replaceWith;
          }
          await U.api('put', 'query', trimQuery(query));
        }
        setReplaceWith(undefined);
      }
      void U.api('delete', `column/${module.id}`);
      return onRemove ? onRemove() : undefined;
    }

    const paths = [
      'analytics.vectors.formula',
      'analytics.vectors.moduleId',
      'columns.key',
      'sort.key',
    ];
    const newQueries = await U.api<Table.Query[]>('post', 'query/search', {
      query: { $or: paths.map(path => ({ [path]: module.key })), table },
    });
    if (_.isArray(newQueries) && newQueries.length) {
      setQueries(newQueries);
    } else if (module.id) {
      void U.api('delete', `column/${module.id}`);
      return onRemove ? onRemove() : undefined;
    }
    return undefined;
  };

  const handleRemoveOption = () => {
    setModule({
      ...module,
      options: _.filter(
        module.options,
        option => typeof option === 'object' && 'id' in option && option.id !== undefined
      ),
    });
  };

  const handleSave = async () => {
    let response;
    if (module._id) {
      response = await U.api<ServerEntity>('put', `column/${module._id}`, module);
    } else {
      response = await U.api<ServerEntity>('post', 'column', module);
    }
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else if ('id' in response) {
      onSuccess(response);
    }
  };

  return (
    <Modal hideOnBackdropClick={false} isOpen>
      <Form onChange={setModule} value={module}>
        <Modal.Header title={id ? 'Edit Column' : 'Create Column'} />
        <Modal.Body className="p-4">
          {queries?.length ? (
            <div>
              <Text>Are you sure? This module is used by the following tables:</Text>
              {queries.map(query => (
                <Text
                  color="primaryCTA"
                  key={query.id}
                  onClick={() =>
                    openLink({
                      newTab: true,
                      url: `${window.location.href.replace(
                        /\/query\/[a-fA-F0-9]{24}\b/,
                        ''
                      )}/query/${query.id}`,
                    })
                  }
                >
                  {query.name}
                </Text>
              ))}
              <Input
                className="mt-4"
                label="Replace With (optional)"
                onChange={setReplaceWith}
                options={_.map(keyToModule, module => ({ id: module.key, name: module.name }))}
                value={replaceWith}
              />
            </div>
          ) : (
            <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
              <Text color="danger">{error}</Text>
              <Input
                id="description"
                multiline
                required
                tooltip="This will be displayed in a tooltip in the Add Module dropdowns."
              />
              <Input
                id="group"
                array
                tooltip="Organize the Add Module dropdown by moving this module under the below group. For example, if this module displays a city you could move it to an Address group."
              />
              <Input
                id="key"
                required
                tooltip="A unique identifier for the module. No spaces allowed."
                transformOutput={value =>
                  typeof value === 'string' ? _.replace(value, / /g, '') : undefined
                }
              />
              {!!U.length(module.group) && (
                <Input
                  id="menuName"
                  tooltip='If this module has a group the menu name will be displayed when viewing the module within the Add Module dropdown. For example, if this module has a name "Address City" and it is under the "Address" group then it might make sense to set the menu name to "City".'
                />
              )}
              <Input id="name" tooltip="This name will be displayed in the column header." />
              <Input
                id="notes"
                multiline
                tooltip='Additional notes about this module. For example, "We should delete this".'
              />
              <Input
                id="path"
                array
                tooltip='A path to the data the module should display. Indicate the presence of arrays within the path by splitting the path into pieces and by putting them in separate text boxes. For example, the path to part cost is tasks.parts.rowsArray.costPrice. Enter "tasks" into the first input, "parts.rowsArray" into the second input, and "costPrice" into the third input.'
              />
              <Input
                id="type"
                capitalize
                options={Table.columnTypeOptions}
                tooltip="The type of data the module displays."
              />
              <Text className="text-center">Additional Fields</Text>
              <Input id="camelCase" type={InputType.BOOL} />
              <Input id="copyWithModifyFirst" type={InputType.BOOL} />
              <Input id="defaultDuration" capitalize options={Table.durationOptions} />
              <Input id="defaultOperation" options={Table.operationOptions} />
              <Input id="defaultTimeModuleId" />
              <Input id="dependsOnModuleId" array />
              <Input id="disabled" type={InputType.BOOL} />
              <Input id="editPath" array />
              <Input id="hideFilter" type={InputType.BOOL} />
              <Input id="hideHeader" type={InputType.BOOL} />
              <Input id="idPath" />
              <Input id="isArray" type={InputType.BOOL} />
              <Input id="isCounty" type={InputType.BOOL} />
              <Input id="isEmail" type={InputType.BOOL} />
              <Input id="isName" type={InputType.BOOL} />
              <Input id="isPhone" type={InputType.BOOL} />
              <Input id="loadOptionsOnMount" type={InputType.BOOL} />
              <Input id="modify" options={Table.modifyOptions} />
              <Input id="modifyAllRowsKey" />
              <Input id="noExport" type={InputType.BOOL} />
              <Input id="noPath" type={InputType.BOOL} />
              <Input id="operations" multiple options={Table.operationOptions} />
              <Input id="parentClass" />
              <Input id="pathText" array />
              <Input id="projection" array />
              <Input id="queryExactMatch" type={InputType.BOOL} />
              <Input id="storeKey" />
              <Input id="textFilterEnabled" type={InputType.BOOL} />
              <Input id="width" type={InputType.NUMBER} />
              {_.times(U.length(module, 'options') + 1, i => (
                <div className="flex" key={i}>
                  <Input
                    id={`options.${i}.id`}
                    className="flex-1"
                    label={`Option ${i + 1} Id`}
                    onRemove={handleRemoveOption}
                    removable={_.get(module, `options.${i}.id`) !== undefined}
                  />
                  <Input
                    id={`options.${i}.name`}
                    className="ml-4 flex-1"
                    label={`Option ${i + 1} Name`}
                  />
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer onClose={toggleOpen} onSave={handleSave}>
          {id ? (
            <Button onClick={handleRemove} variant="secondary">
              {queries ? 'Yes, I want to remove' : 'Remove'}
            </Button>
          ) : undefined}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
