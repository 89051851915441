import './review.css';

import React, { Component } from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import { Icon } from '@/components';
import TableComponent from '@/components/Table';

export default class Reviews extends Component {
  state = { reviews: [] };

  onLoad = reviews => {
    this.setState({ reviews });
  };

  render() {
    const { reviews } = this.state;
    const filteredReviews = _.values(reviews).filter(review => review.id);
    const ratingCounts = _.times(
      5,
      i => filteredReviews.filter(review => review.rating === i + 1).length
    );
    const avg = _.mean(reviews.map(review => review.rating)).toFixed(2);

    return (
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <TableComponent {...Table.databases.default.review} onLoad={this.onLoad} />
        <div className="newTable-rating-footer">
          <Icon name="star_circle" color="#5195FF" size={25} />
          <div className="nt-rating-item">
            <h1>{avg}</h1>
            <label>Average Rating ({filteredReviews.length})</label>
          </div>
          <div className="nt-graph">
            {_.times(5, i => (
              <div key={i} className="nt-graph-item">
                <label>{5 - i}</label>
                <Icon name="star" color="#9499A3" size={12} />
                <div className="nt-graph-line-container">
                  <div
                    className="nt-graph-line"
                    style={{
                      width: filteredReviews.length
                        ? (500 * ratingCounts[4 - i]) / filteredReviews.length
                        : 0,
                    }}
                  />
                </div>
                <label className="text-black">{ratingCounts[4 - i]}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
