import * as Table from '@nanaio/table';

export const getCanAddSouthEast = ({
  index,
  module,
  vectors,
}: {
  index: number;
  module?: Table.Column;
  vectors: Table.Vector[];
}): boolean =>
  Table.moduleIsDimension(module) && !(vectors[index + 1] && !vectors[index + 1].moduleId);

export const getCanMoveEast = ({
  databaseIdToTableIdToColumnIdToColumn,
  index,
  query,
  vectors,
}: {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  index: number;
  query: Table.Query;
  vectors: Table.Vector[];
}): boolean =>
  vectors.some(
    (vector, i) =>
      i < index &&
      vector.depth === vectors[index].depth &&
      Table.moduleIsDimension(
        vector.moduleId
          ? databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][vector.moduleId]
          : undefined
      )
  );

export const getCanMoveNorth = ({
  index,
  vectors,
}: {
  index: number;
  vectors: Table.Vector[];
}): boolean => {
  for (let i = index - 1; i >= 0; i--) {
    if (vectors[i].depth === vectors[index].depth && vectors[i].moduleId) {
      return true;
    }
    if (vectors[i].depth < vectors[index].depth) {
      break;
    }
  }

  return false;
};

export const getCanMoveSouth = ({
  index,
  vectors,
}: {
  index: number;
  vectors: Table.Vector[];
}): boolean => {
  for (let i = index + 1; i <= vectors.length - 1; i++) {
    if (vectors[i].depth === vectors[index].depth && vectors[i].moduleId) {
      return true;
    }
  }

  return false;
};
