import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import { getParams } from '@/com/util';
import { Icon } from '@/components';
import { companyName } from '@/config/const';

export default class Positive extends React.Component {
  constructor(p) {
    super(p);
    const { rate } = getParams();
    this.state = {
      isAhs: _.get(p.job, 'customer.org.name') === 'American Home Shield',
      rating: this.props.rating,
    };

    if (rate) {
      this.state.rating = +rate;
    }
    U.api('post', 'reviews', { refId: this.props.taskId, rating: this.props.rating });
    U.api('get', `tasks/${this.props.taskId}/reviewUrl`).then(reviewUrl =>
      this.setState({ reviewUrl })
    );
  }

  render() {
    return (
      <section id="TaskReview">
        <section className="login--page reviews" style={{ paddingTop: 0 }}>
          <div className="login--container">
            <div className="login--block">
              <ul
                className={`no-list inline-list star-row d-flex align-items-center justify-content-center ${this.props.rating}`}
              >
                {_.times(5, n => (
                  <li className={`star-item ${n + 1}`} key={n}>
                    <Icon
                      name="star"
                      size={32}
                      color={n + 1 <= this.state.rating ? '#E6B647' : '#B5B8C1'}
                    />
                  </li>
                ))}
              </ul>
              {this.state.isAhs ? (
                <div>
                  <div className="mb-3">Thank you for submitting your review.</div>
                  <div className="mb-3">
                    In a few days you may receive a survey from American Home Shield on how your
                    service experience was with your {companyName} Technician.
                  </div>
                  <div>
                    This 5-star survey is our report card and it would mean the world to us if you
                    took a few minutes to fill it out when you receive it.
                  </div>
                </div>
              ) : (
                <div>
                  <h4 className="weight-500">Yay! We&apos;d love to hear more</h4>
                  <p className="text-muted">
                    Welcome to the family. A minute of your time will impact thousands of people at
                    {companyName}. Please share your feedback by leaving a review on the platform of
                    your choice.
                  </p>
                </div>
              )}

              <div className="login--form mt-3">
                <a
                  name="google-review"
                  href={this.state.reviewUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn google flex items-center p-2">
                    <img
                      src="https://asset.brandfetch.io/id6O2oGzv-/idvNIQR3p7.svg"
                      className="mr-4 h-8 w-8"
                      alt="google"
                    />{' '}
                    Share on Google
                  </button>
                </a>
              </div>
              <div className="login--form mt-3">
                <a
                  name="google-review"
                  href="https://www.yelp.com/biz/nana-alameda-8?osq=nana+appliance+repair"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn google flex items-center p-2">
                    <img
                      src="https://asset.brandfetch.io/idvwtgSsRX/idZgxaFf9y.svg"
                      className="mr-4 h-8 w-8"
                      alt="yelp"
                    />{' '}
                    Share on Yelp
                  </button>
                </a>
              </div>
              <div className="login--form mt-3">
                <a
                  name="google-review"
                  href="https://www.bbb.org/us/ca/oakland/profile/appliance-repair/nanaio-1116-896772"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn google flex items-center p-2">
                    <img
                      src="https://asset.brandfetch.io/idgWX7bilS/idNC-feLyW.svg"
                      className="mr-4 h-8 w-8"
                      alt="bbb"
                    />{' '}
                    Share on BBB
                  </button>
                </a>
              </div>
              <div className="login--form mt-3">
                <a
                  name="google-review"
                  href="https://www.trustpilot.com/evaluate/www.nana.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn google flex items-center p-2">
                    <img
                      src="https://asset.brandfetch.io/idjtYmGVfB/id3zNdRMSi.svg"
                      className="mr-4 h-8 w-8"
                      alt="trustpilot"
                    />{' '}
                    Share on Trustpilot
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
