import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import phoneFormatter from 'phone-formatter';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Icon } from '@/components';
import Availability from '@/pages/pro/availability';
import { Search } from '../../com/ui/form';
import { Link } from '../../com/ui/link';
import Loader from '../../com/ui/loader';
import { loadUser } from '../../com/user';
import { idFromURL } from '../../utils';
import Brands from './brands';
import Events from './events';
import Jobs from './jobs';
import Opportunities from './opportunities';
import Pay from './pay';
import Performance from './performance';
import Preference from './preference';
import Profile from './Profile';
import Skills from './skills';
import WorkArea from './workArea';

class ManagePro extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    this.state = {
      pro: idFromURL(), // eslint-disable-line react/no-unused-state
    };
    if (_.get(p.pro, 'user.fullName')) {
      document.title = p.pro.user.fullName;
    }
    U.api('get', `pros/${idFromURL()}`, true);
  }

  getChildContext = () => ({ t: this });

  UNSAFE_componentWillReceiveProps(p) {
    const { pro } = this.props;
    if (p.pro && !p.user) {
      loadUser(p.pro.user.id);
    }
    if (_.get(p.pro, 'user.fullName') !== _.get(pro, 'user.fullName')) {
      document.title = _.get(p.pro, 'user.fullName');
    }
  }

  onChangePro = id => {
    const { dispatch } = this.props;
    dispatch(push(global.location.pathname.replace(/[a-z0-9]{24}/, id)));
  };

  loadPros = () => {
    const { prosLoaded } = this.state;
    if (prosLoaded) {
      return;
    }
    this.setState({ loadingPros: true });
    const query = { status: { $in: ['active', 'notServingNow', 'onHold'] } };
    U.api('post', 'pros/search', { query, limit: -1 }, true);
    this.setState({ loadingPros: false, prosLoaded: true });
  };

  render() {
    const { phoneString, pro, pros, roles, statusName, user } = this.props;
    const { loadingPros } = this.state;
    if (!pro || !user) {
      return <Loader />;
    }
    const id = idFromURL();
    const pages = [
      { name: 'Availability', to: `/pros/${id}/availability`, com: Availability },
      { name: 'Brands', to: `/pros/${id}/brands`, com: Brands },
      { name: 'Events', to: `/pros/${id}/events`, com: Events },
      { name: 'Jobs', to: `/pros/${id}/jobs`, com: Jobs },
      { name: 'Opportunities', to: `/pros/${id}/opportunities`, com: Opportunities },
      roles.propay && { name: 'Pay', to: `/pros/${id}/pay`, com: Pay },
      { name: 'Performance', to: `/pros/${id}/performance`, com: Performance },
      { name: 'Preference', to: `/pros/${id}/preference`, com: Preference },
      { name: 'Profile', to: `/pros/${id}/profile`, com: Profile, default: true },
      { name: 'Skills', to: `/pros/${id}/skills`, com: Skills },
      { name: 'Work Area', to: `/pros/${id}/work-area`, com: WorkArea },
    ].filter(v => v);
    const activePage =
      pages.find(p => p.to === global.location.pathname) || pages.find(p => p.default);
    if (!activePage) {
      return <div>page not found</div>;
    }
    return (
      <div
        id="AdminTaskDetails"
        className="crm-nana"
        style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <section className="crm-user-head d-flex p-3">
          <div className="flex-1">
            <div className="d-flex justify-content-between">
              <div className="flex-1">
                <div className="d-flex">
                  <Search
                    id="pro"
                    options={pros}
                    style={{ width: '300px' }}
                    onFocus={this.loadPros}
                    onChange={this.onChangePro}
                    label=""
                  />
                  {loadingPros && <Icon name="loading" spin className="ml-3" size={16} />}
                </div>
                <div className="d-flex align-center">
                  <div className="pr-2">
                    <h5 className="text-muted">
                      Status: <span className={`pro-text ${pro.status}`}>{statusName}</span>
                    </h5>
                  </div>
                  <div className="px-2">
                    <h5 className="text-muted">
                      Type: <span className="text-black">{_.startCase(pro.payrollType)}</span>
                    </h5>
                  </div>
                  <div className="px-2">
                    <h5 className="text-muted">
                      ID# <span className="text-black">{pro.id}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="no-list">
            <li className="border-bottom py-2">
              <div className="copy-to-clipboard">
                <p className="copy-select">{user.email ? user.email : 'N/A'}</p>
                <a href={`mailto:${user.email}`}>
                  <button className="copy-btn mr-1" type="button">
                    <Icon name="email" size={16} />
                  </button>
                </a>
                <CopyToClipboard text={user.email ? `${user.email}` : ' '}>
                  <button className="copy-btn" type="button">
                    <Icon name="content_copy" size={16} />
                  </button>
                </CopyToClipboard>
              </div>
            </li>
            <li className="py-2">
              <div className="copy-to-clipboard d-flex">
                <p className="copy-select">{phoneString}</p>
                <a href={`tel:${phoneString}`}>
                  <button className="copy-btn mr-1" type="button">
                    <Icon name="phone" size={16} />
                  </button>
                </a>
                <CopyToClipboard text={phoneString}>
                  <button className="copy-btn" type="button">
                    <Icon name="content_copy" size={16} />
                  </button>
                </CopyToClipboard>
              </div>
            </li>
          </ul>
        </section>
        <div className="cp-tabs">
          <div className="container">
            <ul className="cp-nav">
              {pages.map(p => (
                <li key={p.name} className="nav-item">
                  <Link
                    className={`nav-link${p.name === activePage.name ? ' active' : ''}`}
                    to={p.to}
                  >
                    {p.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="cp-body" style={{ position: 'relative' }}>
          {user && React.createElement(activePage.com, { pro, user })}
        </div>
      </div>
    );
  }
}

ManagePro.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  phoneString: PropTypes.string.isRequired,
  pro: U.pro.propType,
  pros: PropTypes.arrayOf(U.optionPropType).isRequired,
  roles: U.user.rolesPropType,
  statusName: PropTypes.string,
  user: U.user.propType,
});

export default connect(s => {
  const pros = _.sortBy(
    _.map(s.pros, p => ({ id: p.id, name: _.get(p, 'user.fullName', 'No Name') })),
    'name'
  );
  const path = s.router.location.pathname;
  const pro = s.pros[idFromURL(path)];
  const statusName = _.get(
    U.pro.statusOptions.find(s => s.id === _.get(pro, 'status')),
    'name'
  );
  let phoneString = 'No phone';
  if (_.get(pro, 'user.phone')) {
    phoneString = phoneFormatter.format(_.get(pro, 'user.phone'), '(NNN) NNN-NNNN');
  }
  const user = s.users[_.get(pro, 'user.id')];
  const roles = U.user.roles(s);
  return { phoneString, pro, pros, roles, statusName, user };
})(ManagePro);
