import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { usePreviousDistinct } from '@nanaio/hooks';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import PropTypes from 'prop-types';
import store from 'store2';
import { Button, FormControl, Loader, SearchInput, Text, theme } from '@/components';
import { exportToCsv } from '../../../utils';
import CellModal from './CellModal';

const metrics = [
  {
    label: 'Schedule Duration',
    color: 'success',
    text: ({ duration }) => {
      const out = _.round(U.millisecondsToDays(duration));
      return _.isNaN(out) ? undefined : out;
    },
  },
  {
    label: 'Fulfillment Rate',
    color: 'accent.purple',
    text: ({ fulfillmentRate }) => U.toPercent(fulfillmentRate),
  },
  { label: 'Error Rate', color: 'accent.orange', text: ({ errorRate }) => U.toPercent(errorRate) },
  { label: 'Visit Capacity', color: 'black', text: ({ capacity }) => _.round(capacity.capacity) },
  { label: 'Claimed Visits', color: 'primary', text: ({ counts }) => _.round(counts.claimed) },
  { label: 'Pending Visits', color: 'danger', text: ({ counts }) => _.round(counts.pending) },
  {
    label: 'Cancelled Visits',
    color: 'grey.dark',
    text: ({ counts }) => _.round(counts.cancelled),
  },
];
const CAPACITY_TO_BOOK = 0.5;
const DAYS_IN_FUTURE = 14;
const WEEKS_IN_PAST = 8;
// for some reason scrollToColumn doesn't work so i chose a number that causes the table to
// scroll to the right-ish location
const SCROLL_TO_COLUMN = WEEKS_IN_PAST * 21 + 34;

function Capacity({ regionOptions, regions, roles }) {
  const [activeCell, setActiveCell] = useState();
  const [activeRegions, setActiveRegions] = useState(
    _.isArray(store.get('capacity.activeRegions')) ? store.get('capacity.activeRegions') : []
  );
  const [baseCapacity, setBaseCapacity] = useState();
  const [cellData, setCellData] = useState();
  const [idToWorkOrder, setIdToWorkOrder] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [minScheduleTime] = useState(T.minScheduleTime());
  const [rowRegionIds, setRowRegionIds] = useState([]);
  const [rows, setRows] = useState();

  const idToRegion = useMemo(() => _.keyBy(regions, 'id'), [regions]);

  const getCapacity = ({
    baseCapacity,
    claimedCount,
    date,
    fulfillmentRate,
    pendingCount,
    region,
    slotIndex,
    today = m().startOf('d'),
  }) => {
    const slotBaseCapacity = baseCapacity[region?.id]?.[`${date.day()}-${slotIndex}`] || 0;
    const capacity = slotBaseCapacity;
    let agentCanBook = capacity - claimedCount - pendingCount >= CAPACITY_TO_BOOK;
    // expand capacity if high fulfillment rate
    if (!agentCanBook && date.isSameOrAfter(today)) {
      const minFillRate = _.isNumber(region?.minFillRateToAllowBooking)
        ? region.minFillRateToAllowBooking
        : U.region.minFillRateToAllowBooking;
      agentCanBook = fulfillmentRate >= minFillRate;
    }
    let customerCanBook = agentCanBook;
    if (date.clone().hour([8, 12, 16][slotIndex]).isBefore(minScheduleTime)) {
      customerCanBook = false;
    }
    return { agentCanBook, baseCapacity: slotBaseCapacity, capacity, customerCanBook };
  };

  const getCapacityCallback = useCallback(getCapacity, [minScheduleTime]);

  const getCounts = slots => {
    const counts = _.mapValues(_.groupBy(slots, 'status'), slots => _.sumBy(slots, 'weight') || 0);
    counts.cancelled = counts.cancelled || 0;
    counts.claimed = counts.claimed || 0;
    counts.pending = counts.pending || 0;
    return counts;
  };

  const loadVisits = async () => {
    setIsLoading(true);
    const startTime = m().subtract(WEEKS_IN_PAST, 'w').startOf('d');
    const endTime = m().add(DAYS_IN_FUTURE, 'd').startOf('d');
    const dayCount = endTime.diff(startTime, 'd');
    // load work orders
    const projection = {
      cx: 1,
      id: 1,
      'tasks.metadata.cancelReason': 1,
      'tasks.tags': 1,
      'tasks.title': 1,
      visits: 1,
    };
    const query = {
      'cx.address.gTimezone': { $exists: true },
      $or: [
        { 'visits.slot.start': { $gte: startTime, $lt: endTime } },
        { 'visits.cx.availability.start': { $gte: startTime, $lt: endTime } },
      ],
    };
    let workOrders = await U.search({
      isParallel: true,
      limit: -1,
      path: 'workOrders/search',
      projection,
      query,
    });
    workOrders = workOrders
      .filter(workOrder => workOrder.tasks.some(T.isValid))
      .map(workOrder => {
        const regionId = U.region.regionFromAddress(workOrder.cx.address, regions)?.id;
        const timezone = U.timezone(workOrder.cx.address, true);
        return { ...workOrder, regionId, timezone };
      });
    const idToWorkOrder = _.keyBy(workOrders, 'id');

    // modify visits
    const visits = _.flatten(
      workOrders.map(workOrder =>
        workOrder.visits.map(visit => {
          const newVisit = _.cloneDeep(visit);
          const slots = _.sortBy(
            _.uniqBy([...newVisit.cx.availability, newVisit.slot], slot => m(slot.start).valueOf()),
            slot => m(slot.start).valueOf()
          );

          // get visit status
          if (newVisit.cx.status === W.VisitCustomerStatus.CANCELLED) {
            newVisit.status = 'cancelled';
          } else if (newVisit.pros[0].status === W.VisitProStatus.PENDING) {
            newVisit.status = 'pending';
          } else {
            newVisit.status = W.visitProClaimedStatuses.includes(newVisit.pros[0].status)
              ? 'claimed'
              : 'cancelled';
          }

          // get visit availability
          if (newVisit.status === 'claimed') {
            newVisit.availability = [newVisit.slot];
          } else {
            newVisit.availability = slots;
            if (newVisit.status === 'pending') {
              const futureSlots = slots.filter(slot => m(slot.end).isAfter());
              if (futureSlots.length) {
                newVisit.availability = futureSlots;
              }
            }
          }
          if (!newVisit.availability.some(slot => slot.preferred)) {
            newVisit.availability[0].preferred = true;
          }
          const total = _.sumBy(newVisit.availability, slot => (slot.preferred ? 2 : 1));
          newVisit.availability = newVisit.availability
            .map(slot => {
              const newSlot = _.cloneDeep(slot);
              // preferred slots are twice as likely to be chosen as an alternate slot
              newSlot.weight = (slot.preferred ? 2 : 1) / total;
              return newSlot;
            })
            .filter(slot => m(slot.start).isBetween(startTime, endTime));

          newVisit.workOrderId = workOrder.id;
          return newVisit;
        })
      )
    );

    // generate data
    const cellData = {};
    let baseCapacity = {};
    const today = m().startOf('d');
    visits.forEach(visit => {
      const workOrder = idToWorkOrder[visit.workOrderId];
      visit.availability.forEach(slot => {
        const date = m(slot.start).startOf('d');
        let slotIndex = 0;
        if (mt(slot.start).tz(workOrder.timezone).hour() === 12) {
          slotIndex = 1;
        } else if (mt(slot.start).tz(workOrder.timezone).hour() === 16) {
          slotIndex = 2;
        }
        _.set(cellData, `${workOrder.regionId}.regionId`, workOrder.regionId);
        const data = {
          status: visit.status,
          visitId: visit.id,
          weight: slot.weight,
          workOrderId: workOrder.id,
        };
        if (visit.status === 'claimed') {
          data.duration = m(slot.start).valueOf() - m(visit.createTime).valueOf();
        }
        U.setPush(cellData, `${workOrder.regionId}.slots.${date.valueOf()}-${slotIndex}`, data);
        U.setAdd(cellData, `${workOrder.regionId}.totalWeight`, slot.weight);
        if (visit.status === 'claimed' && date.isBefore(today)) {
          U.setAdd(baseCapacity, `${workOrder.regionId}.${date.day()}-${slotIndex}`, slot.weight);
        }
      });
    });
    baseCapacity = _.mapValues(baseCapacity, region =>
      _.mapValues(region, claimedCount => claimedCount / WEEKS_IN_PAST)
    );

    // render rows
    const errorRateStart = m().subtract(1, 'w').startOf('d');
    const errorRateEnd = m().startOf('d');
    const headerRow = [
      undefined,
      ..._.flatten(
        _.times(dayCount, index => {
          const color = index >= WEEKS_IN_PAST * 7 ? 'black' : 'grey.dark';
          return [
            <div>
              <Text color={color} style={{ height: '24px' }}>
                {m(startTime).add(index, 'd').format('ddd')}
              </Text>
              <Text color={color}>8-12</Text>
            </div>,
            <div>
              <Text color={color} style={{ height: '24px' }}>
                {m(startTime).add(index, 'd').format('M/D')}
              </Text>
              <Text color={color}>12-4</Text>
            </div>,
            <Text className="mt-6" color={color}>
              4-8
            </Text>,
          ];
        })
      ),
    ];
    const regionIds = _.sortBy(_.values(cellData), 'totalWeight')
      .reverse()
      .map(region => region.regionId);
    const regionIdToError = {};
    const bodyRows = regionIds.map(regionId => {
      const region = idToRegion[regionId];
      const regionName = region?.name || 'No Matching Region';
      let errorInLastWeek = 0;
      let claimedInLastWeek = 0;
      const cells = [
        undefined,
        ..._.times(dayCount * 3, index => {
          const dateIndex = _.floor(index / 3);
          const date = m(startTime).add(dateIndex, 'd');
          const slotIndex = index % 3;
          const slots = _.get(cellData, `${regionId}.slots.${date.valueOf()}-${slotIndex}`, []);
          const counts = getCounts(slots);
          const fulfillmentRate = counts.claimed / (counts.claimed + counts.pending);
          const capacity = getCapacityCallback({
            baseCapacity,
            claimedCount: counts.claimed,
            date,
            fulfillmentRate,
            pendingCount: counts.pending,
            region,
            slotIndex,
            today,
          });
          const duration = _.mean(_.compact(_.map(slots, 'duration')));
          const isFuture = date.isSameOrAfter(today);
          const errorRate = Math.abs(counts.claimed - capacity.capacity) / counts.claimed;
          const props = { capacity, counts, duration, errorRate, fulfillmentRate };
          const ui = (
            <div
              className="cursor-pointer"
              onClick={() => setActiveCell({ regionId, date, slotIndex })}
            >
              {metrics.map(metric => (
                <div key={metric.label} style={{ minHeight: 20 }}>
                  <Text color={metric.color}>{metric.text(props)}</Text>
                </div>
              ))}
            </div>
          );
          if (m(date).isBetween(errorRateStart, errorRateEnd, undefined, '[)')) {
            errorInLastWeek += Math.abs(capacity.capacity - counts.claimed);
            claimedInLastWeek += counts.claimed;
          }

          return {
            capacity,
            counts,
            duration,
            errorRate,
            fulfillmentRate,
            isFuture,
            ui,
          };
        }),
      ];
      const errorRate = errorInLastWeek / claimedInLastWeek;
      regionIdToError[regionId] = errorRate;
      cells[0] = {
        text: regionName,
        ui: (
          <div>
            <Text>{regionName}</Text>
            <Text color="grey.dark" type="helper">
              {U.toPercent(errorRate)} error
            </Text>
          </div>
        ),
      };
      return cells;
    });
    const errorRate = _.mean(
      workOrders
        .map(workOrder => regionIdToError[workOrder.regionId])
        .slice(0, 100)
        .filter(error => U.isNumber(error))
    );
    headerRow[0] = (
      <Text color="grey.dark" type="helper">
        {U.toPercent(errorRate)} error
      </Text>
    );

    setBaseCapacity(baseCapacity);
    setCellData(cellData);
    setRowRegionIds(regionIds);
    setRows([headerRow, ...bodyRows]);
    setIdToWorkOrder(_.keyBy(workOrders, 'id'));
    setIsLoading(false);
  };

  const loadVisitsCallback = useCallback(loadVisits, [getCapacityCallback, idToRegion, regions]);
  const regionsLoaded = regions.length;
  const prevRegionsLoaded = usePreviousDistinct(regionsLoaded);
  useEffect(() => {
    document.title = 'Capacity';
    if (regionsLoaded && !prevRegionsLoaded) {
      loadVisitsCallback();
    }
  }, [loadVisitsCallback, prevRegionsLoaded, regionsLoaded]);

  const filterRows = (rows = []) => {
    if (activeRegions.length) {
      return rows.filter((row, i) => !i || activeRegions.includes(rowRegionIds[i - 1]));
    }
    return rows;
  };

  const handleSetActiveRegions = regions => {
    setActiveRegions(regions);
    store.set('capacity.activeRegions', regions);
  };

  const visibleRows = filterRows(rows);

  const handleExport = () => {
    const headerRow1 = [
      undefined,
      ..._.flatten(
        _.times(DAYS_IN_FUTURE, i => [
          m().add(i, 'd').format('ddd'),
          m().add(i, 'd').format('M/D'),
          undefined,
        ])
      ),
    ];
    const headerRow2 = [
      undefined,
      ..._.flatten(_.times(DAYS_IN_FUTURE, () => ['8-12', '12-4', '4-8'])),
    ];
    const bodyRows = _.flatten(
      visibleRows.slice(1).map(row => {
        const out = [];
        out.push([row[0].text]);
        const startColumn = 1 + WEEKS_IN_PAST * 21;
        out.push([
          'Agent Can Book',
          ...row.slice(startColumn).map(cell => (cell.capacity.agentCanBook ? 'Yes' : 'No')),
        ]);
        metrics.map(metric => out.push([metric.label, ...row.slice(startColumn).map(metric.text)]));
        return out;
      })
    );
    exportToCsv('Capacity Report.csv', [headerRow1, headerRow2, ...bodyRows]);
  };

  return (
    <div>
      <div className="flex whitespace-nowrap">
        <div className="ml-4 flex-1">
          <FormControl label="Regions">
            <SearchInput
              multiple
              onChange={handleSetActiveRegions}
              options={regionOptions}
              value={activeRegions}
            />
          </FormControl>
        </div>
        {roles[U.user.Role.EXPORT] && (
          <Button className="ml-4" onClick={handleExport}>
            Export
          </Button>
        )}
        <div className="ml-4">
          <div
            className="p-3"
            style={{ backgroundColor: theme.hexToRGB(theme.colors.success, 0.2) }}
          >
            Booking Allowed
          </div>
          <div className="bg-white p-3">Booking Not Allowed</div>
        </div>
        <div className="mx-4">
          {metrics.map(metric => (
            <Text color={metric.color} key={metric.label}>
              {metric.label}
            </Text>
          ))}
        </div>
      </div>
      {isLoading ? (
        <div className="mt-4 text-center">
          <Loader isLoading />
        </div>
      ) : (
        <div style={{ height: '100vh', maxWidth: '100%' }} className="bg-white">
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                cellRenderer={cell => {
                  const data = visibleRows[cell.rowIndex][cell.columnIndex];
                  const canBook = data?.capacity?.agentCanBook;
                  const backgroundColor = canBook
                    ? theme.hexToRGB(theme.colors.success, 0.2)
                    : undefined;
                  return (
                    <div
                      key={`${cell.rowIndex} ${cell.columnIndex}`}
                      style={{ ...cell.style, backgroundColor }}
                      className={`cell p-td border p-1 ${
                        (cell.columnIndex - 1) % 3 ? '' : 'border-left-black'
                      } ${cell.rowIndex % 2 || canBook ? '' : 'bg-light'}`}
                    >
                      {data?.ui || data}
                    </div>
                  );
                }}
                columnCount={rows[0].length}
                columnWidth={({ index }) => (index ? 41 : 100)}
                fixedColumnCount={1}
                fixedRowCount={1}
                height={height}
                rowCount={visibleRows.length}
                rowHeight={({ index }) => (index ? 150 : 50)}
                scrollToColumn={SCROLL_TO_COLUMN}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      )}
      {activeCell && (
        <CellModal
          {...{
            activeCell,
            baseCapacity,
            cellData,
            getCapacity,
            getCounts,
            idToRegion,
            idToWorkOrder,
            onClose: () => setActiveCell(false),
          }}
        />
      )}
    </div>
  );
}

Capacity.propTypes = {
  regionOptions: PropTypes.arrayOf(U.region.propType).isRequired,
  regions: PropTypes.arrayOf(U.region.propType).isRequired,
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default connect(s => {
  const regions = _.sortBy(_.values(s.regions), 'name');
  const regionOptions = [{ id: 'all', name: 'All Regions' }, ...regions];
  const roles = U.user.roles(s);
  return { regionOptions, regions, roles };
})(Capacity);
