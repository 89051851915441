import React from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Icon, Text } from '@/components';
import BelowFoldContent from './com/BelowFoldContent';
import { PATH_BY_SERVICE_ID } from './Details-V2';
import { BookingState } from './util';

type Props = {
  state: BookingState;
  stepName: string;
};

const diagnosisSteps = [
  {
    image: <Icon name="tag_outline" className="text-success" size={20} />,
    text: (
      <>
        Our competitors charge <b className="font-semibold">$89 to $159</b> for diagnosis
      </>
    ),
  },
  {
    image: <Icon name="update" className="text-success" size={20} />,
    text: (
      <>
        <b className="font-semibold">No payment</b> until your diagnosis is complete
      </>
    ),
  },
  {
    image: <Icon name="check" className="text-success" size={20} />,
    text: (
      <>
        Diagnosis fee is <b className="font-semibold">waived with repair</b>
      </>
    ),
  },
];

type ServiceDetailsProps = {
  serviceName: string;
  state: BookingState;
};

type StateProp = {
  state: BookingState;
};

const ServiceDetails = ({ serviceName, state }: ServiceDetailsProps) => {
  const brand = _.get(state, 'metadata.taskQuestions.Make') as string;

  if (!serviceName) {
    return null;
  }
  const bookingId = state.job?.shortId;

  return (
    <>
      {!!bookingId && (
        <div className="flex">
          <Text type="body-2" className="w-[96px] text-grey-dark">
            Booking ID:
          </Text>
          <Text type="body-2" className="ml-4 text-secondary">
            {bookingId}
          </Text>
        </div>
      )}
      <div className="flex">
        <Text type="body-2" className="w-[96px] text-grey-dark">
          Service:
        </Text>
        <Text type="body-2" className="ml-4 text-secondary">
          {brand} {serviceName}
        </Text>
      </div>
    </>
  );
};

const ApplianceDetails = ({ state }: StateProp) => {
  const issue = _.get(
    state,
    PATH_BY_SERVICE_ID[state?.serviceId as keyof typeof PATH_BY_SERVICE_ID]
  ) as string;
  const description = _.get(state, 'metadata.taskQuestions.Tell us more') as string;
  const modelNumber = _.get(state, 'metadata.taskQuestions.Model') as string;

  if (!issue) {
    return null;
  }

  return (
    <>
      <div className="flex">
        <Text type="body-2" className="w-[96px] text-grey-dark">
          Issue(s):
        </Text>
        <Text type="body-2" className="ml-4 text-secondary">
          {issue}
        </Text>
      </div>
      {!!description && (
        <div className="flex">
          <Text type="body-2" className="w-[96px] text-grey-dark">
            Description:
          </Text>
          <Text type="body-2" className="ml-4 text-secondary">
            {description}
          </Text>
        </div>
      )}
      {!!modelNumber && (
        <div className="flex">
          <Text type="body-2" className="w-[96px] text-grey-dark">
            Model:
          </Text>
          <Text type="body-2" className="ml-4 text-secondary">
            {modelNumber}
          </Text>
        </div>
      )}
    </>
  );
};

const SelectedSlots = ({ state }: StateProp) => {
  const selectedDates = Object.keys(state.availTSlots);

  if (!state.zip.value || !selectedDates.length) {
    return null;
  }
  const tz = U.timezone({ postalCode: state.zip.value });

  const days = [
    ...new Set(selectedDates.map(timeslot => mt(+timeslot).tz(tz).startOf('d').valueOf())),
  ];

  const timeslotsByDay = _.groupBy(_.toPairs(state.availability), ([timeslot]) =>
    mt(+timeslot).tz(tz).startOf('d').valueOf()
  );

  return (
    <div className="flex">
      <Icon name="clock_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">When</Text>
        {days.map(day => (
          <div key={day} className="flex">
            <Text className="w-[120px] text-secondary" type="button">
              {mt(day).tz(tz).format('ddd, MMM D')}
            </Text>
            <div className="ml-4 grid w-full grid-cols-3 justify-items-center gap-2">
              {timeslotsByDay[day].map(([timeslot]) => {
                const isActive = !!_.get(state, `availTSlots.${timeslot}`);
                const slotStart = mt(+timeslot).tz(tz);
                const slotEnd = mt(+timeslot).tz(tz).add(4, 'h');
                return (
                  <Text key={timeslot} type="body-2" className="text-secondary">
                    {!isActive && '-'}
                    {isActive && (
                      <>
                        {slotStart.format('h')} - {slotEnd.format('h')} PM
                      </>
                    )}
                  </Text>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ContactDetails = ({ state }: StateProp) => {
  const { firstName, lastName, email, phone } = state;

  if (!firstName || !lastName || !email || !phone) {
    return null;
  }

  return (
    <div className="flex">
      <Icon name="phone_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">Contact</Text>
        <Text type="body-2">
          {firstName} {lastName}
        </Text>
        <Text type="body-2">{email}</Text>
        <Text type="body-2">{U.trimPhone(phone, '(NNN) NNN-NNNN')}</Text>
      </div>
    </div>
  );
};

const LocationDetails = ({ state }: StateProp) => {
  if (!state.address?.route) {
    return null;
  }

  return (
    <div className="flex">
      <Icon name="map_marker_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">Where</Text>
        <Text>
          {U.addressLine1(state.address)}
          <br />
          {U.addressLine3(state.address)}
        </Text>
      </div>
    </div>
  );
};

const ArrivalInstructions = ({ state }: StateProp) => {
  const addressInstructions = state.address?.arrivalInstructions;

  if (!addressInstructions) {
    return null;
  }

  return (
    <div className="flex">
      <Icon name="text_box_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">Arrival Instructions</Text>
        <Text type="body-2">{addressInstructions}</Text>
      </div>
    </div>
  );
};

const PaymentInfo = ({ state }: StateProp) => {
  const card = state.job?.payment?.card;

  if (!card) {
    return null;
  }

  const { last4, brand } = card;

  if (!last4) {
    return null;
  }

  return (
    <div className="flex">
      <Icon name="credit_card_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">Payment Method</Text>
        <Text type="body-2">
          {brand} ****{last4}
        </Text>
      </div>
    </div>
  );
};

const BillingInfo = ({ state }: StateProp) => {
  const info = state.job?.payment?.card;

  if (!info) {
    return null;
  }

  return (
    <div className="flex">
      <Icon name="person_outline" className="text-grey-dark" size={16} />
      <div className="ml-2.5 flex flex-col space-y-2">
        <Text type="label">Billing Information</Text>
        <Text>
          {info.address_line1}
          <br />
          {info.address_city} {info.address_state} {info.address_zip}
        </Text>
      </div>
    </div>
  );
};

export default function AppointmentSummary({ state, stepName }: Props): JSX.Element {
  const isConfirmationPage = stepName === 'Confirmation';
  const serviceName = state.services.find(service => service.id === state.serviceId)?.name;

  return (
    <div>
      <Text type="subtitle-2">{isConfirmationPage ? 'Booking' : 'Appointment'} Summary</Text>
      <div className="mt-6 border-b border-grey-medium pb-4">
        {serviceName ? (
          <div className="flex flex-col space-y-8">
            <div className="flex">
              <Icon name="toolbox_outline" className="text-grey-dark" size={16} />
              <div className="ml-2.5 flex flex-col space-y-2">
                <Text type="label">What</Text>
                <ServiceDetails serviceName={serviceName} state={state} />
                <ApplianceDetails state={state} />
              </div>
            </div>
            <SelectedSlots state={state} />
            <ContactDetails state={state} />
            <LocationDetails state={state} />
            <ArrivalInstructions state={state} />
            <PaymentInfo state={state} />
            <BillingInfo state={state} />
          </div>
        ) : (
          <div className="flex">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-secondary-5 bg-opacity-5">
              <Icon name="map_marker_outline" className="text-grey-dark opacity-50" size={16} />
            </div>
            <div className="ml-3 flex flex-col space-y-2">
              <Text type="button" className="text-secondary text-opacity-75">
                Check Coverage To Get Started
              </Text>
              <Text type="body-2" className="text-secondary text-opacity-50">
                To get started, select a service
              </Text>
            </div>
          </div>
        )}
        {isConfirmationPage && (
          <div className="mt-8 rounded-lg bg-background-light p-4">
            <div className="flex justify-between">
              <Text type="page-title">Diagnosis Fee:</Text>
              <div className="flex items-center">
                <Text type="subtitle-2">$70.00</Text>
                <Text type="body-2" className="ml-2 line-through">
                  $89
                </Text>
              </div>
            </div>
            {diagnosisSteps.map((step, index) => (
              <div key={index} className="mt-3 flex items-center">
                {step.image}
                <Text type="body-2" className="ml-2">
                  {step.text}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
      <BelowFoldContent className="mb-20 md:mb-6" />
    </div>
  );
}
