import { CSSProperties } from 'react';
import * as Table from '@nanaio/table';
import { ErrorResponse, ServerEntity } from '@nanaio/util';
import _ from 'lodash';

export type BulkUpdateResult = {
  error?: string;
  failed?: number;
  responses?: { id: number | string; response?: ErrorResponse | ServerEntity }[];
  succeeded?: number;
  total?: number;
};

export type DatabaseQuery = Omit<Partial<Table.Query>, 'columns'> & {
  columns: Pick<Table.Column, 'databaseId' | 'id' | 'key' | 'linkBy' | 'table'>[];
  databaseId: Table.DatabaseId;
  table: string;
};

export type EditCell = {
  columnIndex: number;
  error?: string;
  originalValues: Table.IdsAndValue[];
  values: Table.IdsAndValue[];
};

export const exportOptions = [
  { id: 'analytics', name: 'Analytics' },
  { id: 'table', name: 'Table' },
];

export enum Export {
  ANALYTICS = 'analytics',
  TABLE = 'table',
}

export type TableCell = {
  columnIndex: number;
  key: string;
  rowIndex: number;
  style: CSSProperties;
};
