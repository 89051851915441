import React from 'react';
import _ from 'lodash';
import { Input, Text } from '@/components';
import Panel from './Panel';

const JOBS_COMPLETED_INPUT_OPTIONS = _.range(5, 101, 5).map(i => i.toString(10));

type Props = {
  disableControls?: boolean;
  errors: Errors;
};

interface Errors {
  jobsCompleted: string;
}

export default function JobRulesetPanel({ disableControls, errors }: Props): JSX.Element {
  return (
    <Panel className="mt-4" title="If Technician has completed" icon="build_circle">
      <div className="col-span-2">
        <Input
          className="col-span-2 mb-0"
          disabled={disableControls}
          error={errors.jobsCompleted}
          id="jobsCompleted"
          label=""
          options={JOBS_COMPLETED_INPUT_OPTIONS}
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.jobsCompleted}
        </Text>
      </div>

      <Text className="mt-2.5 self-start">Jobs</Text>
    </Panel>
  );
}
