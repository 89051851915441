import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Text } from '@/components';
import { companyName } from '@/config/const';
import { openLink } from '@/utils';
import Footer from './com/Footer';
import { BookingState } from './util';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function NoAvailabilityV2({ ...props }: Props): JSX.Element {
  const dispatch = useDispatch();

  const handleNext = () => {
    openLink({ dispatch, url: '/' });
  };

  return (
    <section>
      <div className="mb-20 mt-10">
        <img
          className="mx-auto"
          src="/img/unsupported-area.png"
          style={{ height: 167 }}
          alt="Unserviced Area"
        />
        <Text className="text-center" type="headline-6">
          Sorry, {companyName} Does Not Service Your Area
        </Text>
        <Text className="mt-4 text-center">
          We’re continuously expanding our operational areas, and we can’t wait to serve your
          appliance repair needs in the near future.
        </Text>
      </div>
      <Footer {...props} onNext={handleNext} nextText="Return to Home Page" />
    </section>
  );
}
