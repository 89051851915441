import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { U, User } from '@nanaio/util';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { exception } from '@/com/analytics';
import { sendPageview } from '@/com/marketing';
import { saveIncludeRoles } from '@/com/user';
import {
  Address as AddressInput,
  Alert,
  FormControl,
  Paper,
  Switch,
  Text,
  TextInput,
} from '@/components';
import { useLegacySelector } from '@/hooks';
import BelowFoldContent from '../com/BelowFoldContent';
import Footer from '../com/Footer';
import { BookingState, postWorkOrder } from '../util';
import FeeBox from './FeeBox';
import TermsCheckbox from './TermsCheckbox';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Card({ onChange, onNext, state, ...props }: Props): JSX.Element {
  const { landingPage } = useLegacySelector(state => {
    const { landingPage } = state.ga;
    return { landingPage };
  });

  // stripeErrors are set onChange from stripe elements
  const [stripeErrors, setStripeErrors] = useState<Record<string, string>>({});
  // stateErrors are set on submit
  const [stateErrors, setStateErrors] = useState<Record<string, string>>({});
  const [didFailToBook, setDidFailToBook] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    sendPageview(`booking-page/card`);
  }, []);

  const cardHolderName = _.startCase([state.firstName, state.lastName].join(' '));

  const hasBillingName = !!state.billingName;
  const hasBillingAddress = !!state.billingAddress;

  const getStateErrors = useCallback(() => {
    const errors: Record<string, string> = {};
    if (!state.useShippingForBilling) {
      if (!hasBillingName) {
        errors.billingName = 'Cardholder name is required';
      }
      if (!hasBillingAddress) {
        errors.billingAddress = 'Address is required';
      }
    }
    if (!state.terms) {
      errors.terms = 'Check the box to agree to our terms and conditions';
    }
    return errors;
  }, [state.useShippingForBilling, state.terms, hasBillingName, hasBillingAddress]);

  // new state errors should only be shown on submit, we only care about the status
  // of existing state errors
  const errors = useMemo(() => {
    return { ...stripeErrors, ..._.pick(getStateErrors(), _.keys(stateErrors)) };
  }, [stripeErrors, stateErrors, getStateErrors]);

  const error = useMemo(() => {
    if (didFailToBook) {
      return 'There was a problem booking your job. Please call (866) 213-6262 to speak to a booking agent';
    }
    if (_.isEmpty(errors)) {
      return '';
    }
    return _.size(errors) === 1 ? _.values(errors)[0] : 'Please correct all errors';
  }, [errors, didFailToBook]);

  const bookJob = useCallback(
    async (state: BookingState) => {
      const request = {
        email: state.email,
        profile: _.pick(state, ['firstName', 'lastName', 'phone', 'address']),
      };

      const userResp = await U.api<{ user: User; token: string | null }>(
        'post',
        'users?includeRoles=true',
        request
      );

      if ('errMsg' in userResp) {
        // if the user already exists the error response may include the existing user id
        if (!userResp.userId) {
          exception().capture(userResp.errMsg);
          setDidFailToBook(true);
          return;
        }
      } else {
        // we only want to save the user to redux if it's a complete user, the user id is sufficient
        // to create the job but ultimately an existing user will need to login before they can access
        // the customer portal
        saveIncludeRoles(userResp);
      }

      const startTimes: number[] = [];
      _.mapValues(state.availTSlots, (value, time) => {
        if (value) {
          startTimes.push(+time);
        }
      });
      const userId = 'errMsg' in userResp ? (userResp.userId as string) : userResp.user.id;

      const workOrderResp = await postWorkOrder({
        ...state,
        startTimes,
        userId,
        landingPage,
      });

      if ('errMsg' in workOrderResp) {
        exception().capture(workOrderResp.errMsg);
        setDidFailToBook(true);
        return;
      }

      U.redux.set(`tasks.${workOrderResp.tasks[0].id}`, workOrderResp.tasks[0]);
      return workOrderResp.tasks[0];
    },
    [landingPage]
  );

  const handleNext = useCallback(async () => {
    const stateErrors = getStateErrors();
    const errors = { ...stripeErrors, ...stateErrors };

    // if there are any errors abort, set stateErrors here so the user gets feedback
    // when they're resolved
    if (!_.isEmpty(errors)) {
      setStateErrors(stateErrors);
      return;
    }

    const name = state.useShippingForBilling ? cardHolderName : state.billingName;
    const address = nullthrows(state.useShippingForBilling ? state.address : state.billingAddress);

    const data = {
      name,
      address_line1: U.addressLine1(address),
      address_city: address.locality,
      address_state: address.region,
      address_zip: address.postalCode,
      address_country: 'USA',
    };

    const element = nullthrows(nullthrows(elements).getElement(CardNumberElement));

    // this also triggers onChange for all stripe elements
    const result = await nullthrows(stripe).createToken(element, data);
    if (result.error) {
      setStripeErrors({ cardNumber: result.error.message || '' });
      return;
    }

    const payment = { stripeToken: result.token.id };
    onChange('payment', payment);
    // stripeToken isn't available in state until next render
    const job = await bookJob({ ...state, payment });
    if (!job) {
      return;
    }
    onChange('job', job);
    await onNext();
  }, [
    bookJob,
    cardHolderName,
    elements,
    getStateErrors,
    onChange,
    onNext,
    state,
    stripe,
    stripeErrors,
  ]);

  return (
    <section>
      <Text type="headline-6">Let's Complete Your Booking</Text>
      <Text className="mt-2">Set up a payment method to complete your booking.</Text>

      <FeeBox />

      <div className="mt-6">
        {!!error && (
          <Alert className="mb-10" variant="error">
            {error}
          </Alert>
        )}

        <div className="mb-8">
          <FormControl label="Card number" className="mb-4" error={errors.cardNumber} required>
            <CardNumberElement
              className="border border-icons-grey"
              options={{ showIcon: true }}
              onChange={event =>
                setStripeErrors(stripeErrors =>
                  _.pickBy({ ...stripeErrors, cardNumber: event.error?.message || '' })
                )
              }
            />
          </FormControl>

          <div className="flex justify-between">
            <FormControl label="Expiry" className="w-1/2 pr-2" error={errors.cardExpiry} required>
              <CardExpiryElement
                className="border border-icons-grey"
                onChange={event =>
                  setStripeErrors(stripeErrors =>
                    _.pickBy({ ...stripeErrors, cardExpiry: event.error?.message || '' })
                  )
                }
              />
            </FormControl>

            <FormControl label="CVC" className="w-1/2 pl-2" error={errors.cardCvc} required>
              <CardCvcElement
                className="border border-icons-grey"
                onChange={event =>
                  setStripeErrors(stripeErrors =>
                    _.pickBy({ ...stripeErrors, cardCvc: event.error?.message || '' })
                  )
                }
              />
            </FormControl>
          </div>

          <div className="mt-2 flex items-center">
            <Text color="grey.dark">Powered by</Text>
            <img src="/img/stripe-logo.png" alt="Stripe" className="-mt-0.5 ml-1.5 h-5" />
          </div>
        </div>

        <div className="mb-4 flex justify-between">
          <Text>Billing information is same as booking</Text>
          <Switch
            onChange={e => {
              onChange('useShippingForBilling', e);
            }}
            value={state.useShippingForBilling}
          />
        </div>

        {state.useShippingForBilling ? (
          <Paper variant="elevated" className="p-4">
            <Text type="button" className="mb-2">
              {cardHolderName}
            </Text>
            <Text>
              {U.addressLine1(state.address)}
              <br />
              {U.addressLine3(state.address)}
            </Text>
          </Paper>
        ) : (
          <div>
            <FormControl label="Name on card" required error={errors.billingName}>
              <TextInput
                onChange={event => {
                  onChange('billingName', event);
                }}
                value={state.billingName}
              />
            </FormControl>

            <FormControl label="Address" required error={errors.billingAddress}>
              <AddressInput
                onChange={event => {
                  onChange('billingAddress', event);
                }}
                value={state.billingAddress}
                noUnit
              />
            </FormControl>
          </div>
        )}

        <TermsCheckbox error={errors.terms} state={state} onChange={onChange} />

        <BelowFoldContent />

        <Footer
          {...props}
          nextText="Complete Booking"
          error={error}
          onNext={handleNext}
          disabled={didFailToBook}
        />
      </div>
    </section>
  );
}
