import React, { ReactNode } from 'react';
import classnames from 'classnames';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function Body({ children, className }: Props): JSX.Element {
  return <div className={classnames('flex-1 overflow-y-auto', className)}>{children}</div>;
}
