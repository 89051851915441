// eslint-disable-next-line max-classes-per-file
import './style.scss';
import '../../../style/bootstrap.css';

import React from 'react';
import { Col, Modal, ModalFooter,ModalHeader, Row } from 'reactstrap';
import { Icon } from '@/components';
import Footer from '../../../com/ui/footer';
import Link from '../../../com/ui/link';
import { applyLink, companyName } from '../../../config/const';

class Rodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className={this.props.class} onClick={this.toggle}>
          {this.props.button}
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`partModal ${this.props.size ? this.props.size : `md`}`}
          style={{ width: '95%' }}
        >
          {!this.props.hideHeader && (
            <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
          )}
          {this.props.hideHeader && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="flex-1" />
              <div
                onClick={this.toggle}
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 50,
                  height: 50,
                  cursor: 'pointer',
                }}
              >
                <Icon name="close" color="#8898AA" />
              </div>
            </div>
          )}
          {this.props.body}
          {!this.props.hideFooter && <ModalFooter>{this.props.footer}</ModalFooter>}
        </Modal>
      </div>
    );
  }
}

const list = [
  {
    icon: '/img/lightning/blue/date_input.svg',
    title: 'Build your own schedule',
    text: `Work on the weekends or every day of the week. It's up to you!`,
  },
  {
    icon: '/img/lightning/blue/money.svg',
    title: 'Competitive, reliable pay',
    text: 'Get paid daily via direct deposit to your bank account',
  },
  {
    icon: '/img/lightning/blue/custom_apps.svg',
    title: 'Get jobs at no cost',
    text: 'We handle marketing so you can focus on making people happy.',
  },
  {
    icon: '/img/lightning/blue/bot_training.svg',
    title: 'Technician support ',
    text: 'Get full support from dedicated operations team and support staff',
  },
  {
    icon: '/img/lightning/blue/quotes.svg',
    title: 'Discounted Parts',
    text: 'Get discounts on parts with our partnerships with distributors.',
  },
  {
    icon: '/img/lightning/blue/gift.svg',
    title: 'Get rewarded',
    text: `Earning on ${companyName} is the icing on the cake, get rewarded for excellence.`,
  },
];

export default class ProHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: '',
      isTablet: '',
      modal: false,
    };
    this.open = this.open.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 480,
          isTablet: window.innerWidth < 767,
        });
      },
      false
    );
  }

  open() {
    this.setState({ modal: true });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <div id="JobPost" className="join-home">
        <main id="Bootstrapped" style={{ paddingTop: '60px' }}>
          <section className="container py-3">
            {/*= ========== JUMBOTRON =========== */}

            <div className="join-jumbotron mt-3">
              <h2 className="text-center text-white">Do the work you love</h2>
              <h5 className="mt-2 text-center" style={{ fontWeight: '400', color: '#fff' }}>
                Earn more money on the go.
              </h5>
              <div className="d-block d-lg-flex d-md-flex justify-content-center mt-3">
                <a href={applyLink}>
                  <btn className="white" style={{ height: '55px' }}>
                    Apply Now &nbsp;&nbsp;
                    <Icon name="arrow_right" color="#2453D5" />
                  </btn>
                </a>
              </div>
            </div>

            {/*= ========== PERKS =========== */}
            <div className="py-5" style={{ borderBottom: '1px solid #E2E7F4' }}>
              <h3 className="mb-3 text-center">Why join our team?</h3>

              <Row>
                {list.map((t, n) => (
                  <Col
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                    key={n}
                    className="d-flex d-lg-block d-md-block py-4"
                  >
                    <img src={t.icon} style={{ height: '36px' }} alt="" />
                    <div className="mt-lg-3 mt-md-3 ml-lg-0 ml-md-0 ml-3 mt-0">
                      <h5 className="mb-1">{t.title}</h5>
                      <p>{t.text}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            {/*= ========== PRESS =========== */}
            <Row className="press-row py-5">
              <Col lg="3" md="3" sm="12" xs="12">
                <h4 className="text-lg-left text-md-left mb-lg-0 mb-md-0 mb-3 text-center">
                  Featured In
                </h4>
              </Col>
              <Col
                lg="3"
                md="3"
                sm="4"
                xs="4"
                className="align-items-center press-item text-center"
              >
                <img src="/img/press/forbes.png" style={{ height: '50px' }} width="auto" alt="" />
              </Col>
              <Col
                lg="3"
                md="3"
                sm="4"
                xs="4"
                className="align-items-center press-item text-center"
              >
                <img src="/img/press/inc.png" style={{ height: '50px' }} width="auto" alt="" />
              </Col>
              <Col
                lg="3"
                md="3"
                sm="4"
                xs="4"
                className="align-items-center press-item text-center"
              >
                <img src="/img/press/vault.png" style={{ height: '50px' }} width="auto" alt="" />
              </Col>
            </Row>
          </section>

          {/*= ========== APP =========== */}
          <section className="pro-app-container">
            <div className="d-block d-lg-flex d-md-flex justify-content-between text-lg-left text-md-left container py-5 text-center">
              <section className="flex-1">
                <div
                  className="pr-lg-5 pr-md-5 pt-lg-5 pt-md-4 pr-0 pt-0"
                  style={{ maxWidth: 550 }}
                >
                  <h3 className="text-white">
                    See why {companyName} Technicians enjoy working with us
                  </h3>
                  <h5 className="mt-2" style={{ color: 'rgba(255,255,255,0.75)' }}>
                    Pick and choose the jobs you want. We bring jobs to you and make it easy to
                    coordinate your schedule, so you can focus on more important stuff.
                  </h5>
                  <br />
                  <a href={applyLink}>
                    <btn className="white">Apply Now</btn>
                  </a>
                  <br />
                  <br />
                </div>
              </section>
              <section style={{ width: '100%', maxWidth: '380px', margin: '0px auto' }}>
                <div className="iphone-container">
                  <div className="screen-container">
                    <div className="screen" />
                  </div>
                  <div className="iphone">
                    <img src="/img/lightning/iphoneX.png" alt="" />
                  </div>
                </div>
              </section>
            </div>
          </section>

          {/*= ========== CONCLUSION =========== */}
          <section className="py-5">
            <div className="container mb-5">
              <h2 className="mb-3 text-center">Do you have what it takes to join us?</h2>
              <ul className="no-margin-list" style={{ maxWidth: '700px', margin: '0px auto' }}>
                <li style={{ marginBottom: 8 }}>
                  <p style={{ color: '#2A3F58' }}>
                    Professional experience in appliance repair, hvac, cleaning, or handyman work.
                  </p>
                </li>
                <li style={{ marginBottom: 8 }}>
                  <p style={{ color: '#2A3F58' }}>
                    Be able to pass background check and be authorized to work in the US
                  </p>
                </li>
                <li style={{ marginBottom: 8 }}>
                  <p style={{ color: '#2A3F58' }}>Provide your own tools and transportation</p>
                </li>
                <li style={{ marginBottom: 8 }}>
                  <p style={{ color: '#2A3F58' }}>
                    Passionate about helping others and providing high quality service
                  </p>
                </li>
                <li style={{ marginBottom: 8 }}>
                  <p style={{ color: '#2A3F58' }}>
                    Be able to represent yourself in a courteous and professional manner
                  </p>
                </li>
              </ul>
              <br />
              <div className="d-flex justify-content-center mt-3">
                <label className="text-muted text-center">
                  *{companyName} is not an employer, but simply connects independent service
                  professionals with customers.
                </label>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <a href={applyLink}>
                  <btn className="white">Apply Now</btn>
                </a>
              </div>
            </div>
          </section>
          {/*= ========== ACADEMY =========== */}
          <section className="container">
            <div
              className="d-block d-lg-flex d-md-flex align-items-center mb-3 px-3 py-3"
              style={{ borderRadius: 4, backgroundColor: '#F7F9FC' }}
            >
              <img
                src="/img/nav-icons/book.png"
                style={{ height: '25px' }}
                className="hidden-md-down"
                alt=""
              />
              <div className="pl-lg-3 pl-md-3 mb-lg-0 mb-md-0 mb-2 flex-1 pl-0">
                <p
                  style={{
                    fontWeight: 700,
                    color: '#051033',
                    fontFamily: 'Myriad',
                    fontSize: 16,
                  }}
                >
                  Looking for a career change? Learn how to fix appliances for <u>free</u>
                </p>
              </div>
              <Link to="/academy" name="academy">
                <btn
                  style={{
                    fontSize: 14,
                    padding: '0.5em 1em',
                    fontFamily: 'Myriad',
                    fontWeight: 700,
                    minWidth: 'auto',
                  }}
                >
                  Learn more
                </btn>
              </Link>
            </div>
          </section>
        </main>
        <Rodal />

        <Footer />
      </div>
    );
  }
}
