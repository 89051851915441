import React, { createElement, MutableRefObject } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import * as Table from '@nanaio/table';
import { Option, ServerEntity } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Form } from '../form';
import Analytics from './Analytics';
import Cell from './Cell';
import Header from './Header';
import Message from './Message';
import ChangeQuery from './popups/ChangeQuery';
import EditCellUI from './popups/EditCell';
import EditColumn from './popups/EditColumn';
import EditQuery from './popups/EditQuery';
import Query from './popups/Query';
import EditTable from './Query';
import { BulkUpdateResult, EditCell, TableCell } from './types';
import {
  checkColumnWidth,
  defaultColumnWidth,
  fixedColumnCount,
  fixedRowCount,
  headerHeight,
} from './util';

type Props = {
  addIsOpen?: boolean;
  addUi?: Table.AddUi;
  analyticsDefaults?: Table.Analytics;
  bulkUpdate?: Table.BulkUpdate;
  bulkUpdateResult?: BulkUpdateResult;
  changeQueryIsOpen: boolean;
  checkedRows: Record<string, boolean>;
  closeEditCell: () => void;
  closeEditColumn: () => void;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  databaseIdToTableIdToLastPaginatedQuery: Table.Depth2<Record<string, unknown> | string>;
  databaseIdToTableIdToRows: Table.Depth2<Table.Row[]>;
  databaseIdToTableIdToTable: Table.Depth2<Table.Table>;
  defaultQuery?: Table.Table['defaultQuery'];
  editCell?: EditCell;
  editColumn?: Table.Column;
  editId?: string;
  editQueryPopupIsOpen: boolean;
  editTableIsOpen: boolean;
  editUi?: Table.EditUi;
  embed?: boolean;
  endOfResults?: boolean;
  exportToCsv: (id?: string) => void;
  getRowHeight?: (props: { row?: Table.Row }) => number | undefined;
  initialQuery?: Table.Query;
  isLimit1000: boolean;
  isLoading: boolean;
  isModules?: boolean;
  lastExportTime?: m.MomentInput;
  loadInitialRows: (props: {
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    query?: Table.Query;
  }) => void;
  loadRowsById: (ids: string[]) => Promise<void>;
  loadQueryOptions: (force?: boolean) => Promise<void>;
  messageIsOpen?: boolean;
  name: string;
  onAdd: () => void;
  onAnalyticsChange: (query: Table.Query) => Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    query: Table.Query;
    rows: Table.Row[];
  }>;
  onBulkUpdate: ({ checkedRows }: { checkedRows: Table.Row[] }) => Promise<void>;
  onClearFilters: () => void;
  onEditCellChange: (index: number, value: unknown) => void;
  onFilterChange: (value?: unknown, propEditColumn?: Table.Column) => void;
  onLoadColumn: (columnIndex: number) => Promise<void>;
  onQueryChange: (query: Table.PartialQuery) => Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    query: Table.Query;
    rows: Table.Row[];
  }>;
  onQueryChangeFromHeader: (props: {
    databaseId: Table.DatabaseId;
    queryId: string;
    tableId: string;
  }) => Promise<void>;
  onRefresh: () => Promise<void> | void;
  onSearch: (props: {
    columnIndex?: number;
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    limit?: number;
    query?: Table.Query;
    refreshIds?: string[];
    refreshQuery?: Record<string, unknown>;
    value?: unknown;
  }) => Promise<void>;
  onSearchTypeChange: (type: keyof typeof Table.SearchType) => void;
  onSetQuery: (id: string, value: unknown) => Promise<void>;
  onSort: (columnIndex: number) => void;
  onTextFilterChange: (columnIndex: number, value: string) => void;
  openEditCell: ({ columnIndex, row }: { columnIndex: number; row: Table.Row }) => void;
  openEditColumn: (module: Table.Column, clickedSettingsIcon?: boolean) => void;
  openLink: (row: Table.Row) => void;
  parentModuleIdToModule?: Record<string, Table.Column>;
  patching?: boolean;
  query: Table.Query;
  queryIsOpen: boolean;
  queryOptions?: Table.Query[];
  removeRow: () => void;
  resetBulkUpdate: () => void;
  resetEditCell: () => void;
  roles: Record<string, string>;
  rowUrl?: (props: {
    row: Table.Row;
    pivotColumn?: Table.Column;
    pivotValue?: unknown;
  }) => string | undefined;
  rows: Table.Row[];
  saveEditCell: () => Promise<void>;
  setBulkUpdate: React.Dispatch<React.SetStateAction<Table.BulkUpdate | undefined>>;
  setEditUi: (row: Table.Row) => void;
  setIsLimit1000: (value: boolean) => void;
  setQuery: React.Dispatch<React.SetStateAction<Table.Query | undefined>>;
  setTableVisible: (tableVisible: boolean) => void;
  showRows: (rowIds?: string[]) => void;
  table: Table.Table;
  tableRef?: MutableRefObject<MultiGrid>;
  tableVisible?: boolean;
  timezoneColumn?: string;
  toggleAddIsOpen: () => void;
  toggleAllRowsChecked: () => void;
  toggleAnalytics: () => Promise<void>;
  toggleChangeQueryIsOpen: () => void;
  toggleEditIsOpen: () => void;
  toggleEditQueryPopup: () => void;
  toggleEditTableIsOpen: () => void;
  toggleRowCheck: (row: Table.Row) => void;
  toggleQueryIsOpen: () => void;
  toggleMessage: () => void;
  toggleModulesIsOpen: () => void;
  updateRow: (row: ServerEntity) => void;
  updateUrl: (query: Table.Query) => void;
};

export default function TableUi({
  addIsOpen,
  addUi,
  analyticsDefaults,
  bulkUpdate,
  bulkUpdateResult,
  changeQueryIsOpen,
  checkedRows: idToIsChecked,
  closeEditCell,
  closeEditColumn,
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  databaseIdToTableIdToLastPaginatedQuery,
  databaseIdToTableIdToRows,
  databaseIdToTableIdToTable,
  defaultQuery,
  editCell,
  editColumn,
  editId,
  editQueryPopupIsOpen,
  editTableIsOpen,
  editUi,
  embed,
  endOfResults,
  exportToCsv,
  getRowHeight,
  initialQuery,
  isLimit1000,
  isLoading,
  isModules,
  lastExportTime,
  loadInitialRows,
  loadRowsById,
  loadQueryOptions,
  messageIsOpen,
  name,
  onAdd,
  onAnalyticsChange,
  onBulkUpdate,
  onClearFilters,
  onEditCellChange,
  onFilterChange,
  onLoadColumn,
  onQueryChange,
  onQueryChangeFromHeader,
  onRefresh,
  onSearch,
  onSearchTypeChange,
  onSetQuery,
  onSort,
  onTextFilterChange,
  openEditCell,
  openEditColumn,
  openLink,
  parentModuleIdToModule,
  patching,
  query,
  queryIsOpen,
  queryOptions,
  removeRow,
  resetBulkUpdate,
  resetEditCell,
  roles,
  rowUrl,
  rows,
  saveEditCell,
  setBulkUpdate,
  setEditUi,
  setIsLimit1000,
  setQuery,
  setTableVisible,
  showRows,
  table,
  tableRef,
  tableVisible,
  timezoneColumn,
  toggleAddIsOpen,
  toggleAllRowsChecked,
  toggleAnalytics,
  toggleChangeQueryIsOpen,
  toggleEditIsOpen,
  toggleEditQueryPopup,
  toggleEditTableIsOpen,
  toggleRowCheck,
  toggleQueryIsOpen,
  toggleMessage,
  toggleModulesIsOpen,
  updateRow,
  updateUrl,
}: Props): JSX.Element {
  if (editTableIsOpen) {
    return (
      <EditTable
        {...{
          analyticsDefaults,
          databaseIdToTableIdToColumnIdToColumn,
          databaseIdToTableIdToTable,
          defaultQuery,
          loadQueryOptions,
          name,
          onChange: onQueryChange,
          query,
          queryOptions,
          roles,
          table,
          tableName: name,
          timezoneColumn,
          updateUrl,
        }}
      />
    );
  }

  const checkedRows = rows.filter(row => idToIsChecked[row._id]);
  const checkedRowCount = checkedRows.length;
  const tableWidth = (_.sumBy(query?.columns, 'width') || 0) + checkColumnWidth;
  const headerWidth = _.max([1400, tableWidth]);
  const tableHeight = (rows.length + fixedRowCount) * Table.rowHeight + headerHeight;
  const rowsHeight =
    (rows.length + fixedRowCount) * Table.rowHeight + (query?.analytics?.isOpen ? 300 : 0);

  return (
    <div className="p-table noPadding absolute inset-0 w-full bg-white">
      <Form
        className="bg-white"
        onPatch={onSetQuery}
        originalValue={initialQuery}
        style={embed ? { minHeight: tableHeight, position: 'relative' } : {}}
        value={query}
      >
        <div style={{ maxWidth: headerWidth || 'inherit', position: 'relative' }}>
          <Header
            {...{
              checkedRowCount,
              databaseIdToTableIdToColumnIdToColumn,
              databaseIdToTableIdToRows,
              endOfResults,
              exportToCsv,
              isLimit1000,
              isLoading,
              isModules,
              onAdd,
              onClearFilters,
              onRefresh,
              onSearch,
              openChangeQuery: toggleChangeQueryIsOpen,
              patching,
              query: query,
              roles,
              rows,
              table,
              toggleAnalytics,
              toggleEditTableIsOpen,
              toggleMessage,
              toggleModulesIsOpen,
              toggleQueryIsOpen: toggleQueryIsOpen,
            }}
          />
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 overflow-y-scroll"
          style={{ top: headerHeight, ...(embed ? { height: rowsHeight } : {}) }}
        >
          {query?.analytics?.isOpen && (
            <div className={embed ? '' : 'ml-4'}>
              <Analytics
                {...{
                  databaseIdToTableIdToColumnIdToColumn,
                  databaseIdToTableIdToColumnKeyToOptionIdToOption,
                  isLimit1000,
                  lastExportTime,
                  loadInitialRows,
                  loadRowsById,
                  name,
                  onChange: onAnalyticsChange,
                  query,
                  rows,
                  setIsLimit1000,
                  setQuery,
                  setTableVisible,
                  showRows,
                  updateUrl,
                }}
              />
            </div>
          )}
          {!!query?.columns?.length && (tableVisible || !query.analytics?.isOpen) && (
            <div
              className="h-screen w-full flex-1 overflow-x-auto"
              data-cy="table"
              style={{
                height: embed ? rowsHeight : window.innerHeight,
                marginTop: query?.analytics?.isOpen ? -16 : -headerHeight,
                paddingTop: query?.analytics?.isOpen ? 0 : headerHeight,
              }}
            >
              <AutoSizer>
                {/* eslint-disable-next-line react/no-unused-prop-types */}
                {({ height, width }: { height: number; width: number }) => (
                  <MultiGrid
                    {...{
                      cellRenderer: (cell: TableCell) => (
                        <Cell
                          {...{
                            cell,
                            checkedRows: idToIsChecked,
                            databaseIdToTableIdToColumnKeyToOptionIdToOption,
                            editUi,
                            key: cell.key,
                            rows: rows,
                            onLoadColumn: () => onLoadColumn(cell.columnIndex - fixedColumnCount),
                            onSearch,
                            onSort,
                            onTextFilterChange,
                            openEditCell,
                            openEditColumn,
                            openEditQueryPopup: toggleEditQueryPopup,
                            openLink,
                            query,
                            rowUrl,
                            setEditUi,
                            style: cell.style,
                            toggleAllRowsChecked,
                            toggleRowCheck,
                          }}
                        />
                      ),
                      columnCount: query.columns.length + fixedColumnCount,
                      columnWidth: ({ index }: { index: number }) =>
                        index
                          ? query.columns[index - fixedColumnCount].width || defaultColumnWidth
                          : checkColumnWidth,
                      fixedColumnCount,
                      fixedRowCount,
                      height,
                      ref: tableRef,
                      rowCount: rows.length + fixedRowCount,
                      rowHeight: ({ index }: { index: number }) =>
                        getRowHeight
                          ? getRowHeight({
                              row: rows[index - fixedRowCount],
                            }) || Table.rowHeight
                          : Table.rowHeight,
                      width,
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          )}
        </div>
        {addIsOpen &&
          addUi &&
          createElement(addUi, {
            id: undefined,
            isOpen: addIsOpen,
            keyToModule: databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table],
            onSuccess: updateRow,
            table: query,
            toggleOpen: toggleAddIsOpen,
          } as Table.AddUiProps)}
        {changeQueryIsOpen && (
          <ChangeQuery
            onClose={toggleChangeQueryIsOpen}
            onSubmit={onQueryChangeFromHeader}
            query={query}
          />
        )}
        {editCell && (
          <EditCellUI
            {...{
              columnIndex: editCell.columnIndex,
              databaseIdToTableIdToColumnKeyToOptionIdToOption,
              error: editCell.error,
              onChange: onEditCellChange,
              onClose: closeEditCell,
              onReset: resetEditCell,
              onSave: saveEditCell,
              query,
              values: editCell.values,
            }}
          />
        )}
        {editColumn && (
          <Form onPatch={onSetQuery} value={query}>
            <EditColumn
              {...{
                bulkUpdate,
                bulkUpdateResult,
                checkedRowCount,
                column: editColumn,
                databaseIdToTableIdToColumnIdToColumn,
                databaseIdToTableIdToColumnKeyToOptionIdToOption,
                isBulkUpdating: !!bulkUpdate,
                onBulkUpdate: () => onBulkUpdate({ checkedRows }),
                onBulkUpdateActionChange: action =>
                  setBulkUpdate(bulkUpdate => ({ ...bulkUpdate, action })),
                onBulkUpdateValueChange: value =>
                  setBulkUpdate(bulkUpdate => ({ ...bulkUpdate, value })),
                onClose: closeEditColumn,
                onFilterChange,
                onSearchTypeChange,
                query,
                resetBulkUpdate,
                rows: databaseIdToTableIdToRows[table.databaseId]?.[table.name],
                table,
              }}
            />
          </Form>
        )}
        {editUi &&
          !!editId &&
          createElement(editUi, {
            id: editId,
            isEdit: true,
            isOpen: !!editId,
            keyToModule:
              parentModuleIdToModule ||
              databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table],
            onRemove: removeRow,
            onSuccess: updateRow,
            row: databaseIdToTableIdToRows[table.databaseId]?.[table.name]?.find(
              row => row.id === editId
            ),
            table: query,
            toggleOpen: toggleEditIsOpen,
          })}
        {messageIsOpen && (
          <Message
            {...{
              checkedRows,
              databaseIdToTableIdToColumnIdToColumn,
              databaseIdToTableIdToColumnKeyToOptionIdToOption,
              databaseIdToTableIdToRows,
              databaseIdToTableIdToTable,
              onClose: toggleMessage,
              query,
              roles,
              rows,
              table,
            }}
          />
        )}
      </Form>
      {editQueryPopupIsOpen && (
        <EditQuery
          databaseIdToTableIdToColumnIdToColumn={databaseIdToTableIdToColumnIdToColumn}
          onChange={onQueryChange}
          onClose={toggleEditQueryPopup}
          query={query}
        />
      )}
      {queryIsOpen && (
        <Query
          databaseIdToTableIdToLastPaginatedQuery={databaseIdToTableIdToLastPaginatedQuery}
          onClose={toggleQueryIsOpen}
          query={query}
        />
      )}
    </div>
  );
}
