/* eslint-disable react/no-string-refs */
import React from 'react';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import U from '@nanaio/util';
import _ from 'lodash';
import { companyNameFullLegal } from '@/config/const';

class Sign extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.onBegin = this.onBegin.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSave = this.onSave.bind(this);
    if (!p.job) {
      U.api('get', `tasks/${global.id()}`, ['save']).then(job => {
        U.api('get', `workOrders/${job.metadata.workOrderId}`, ['save']);
      });
    } else if (!p.workOrder) {
      U.api('get', `workOrders/${p.job.metadata.workOrderId}`, ['save']);
    }
  }

  onBegin() {
    this.setState({ showSave: true });
  }

  onClear() {
    this.refs.signature.clear();
    this.setState({ showSave: false });
  }

  onSave() {
    if (this.props.job) {
      const value = this.refs.signature.toDataURL();
      U.api('put', `workOrders/${this.props.job.metadata.workOrderId}/signature`, {
        value,
      });
      this.setState({ saved: true });
    }
  }

  render() {
    return (
      <div className="container" style={{ marginTop: '150px' }}>
        <div className="mb-3 text-center">
          <strong>Terms & Conditions</strong>
        </div>
        <div className="mb-3">
          <strong className="text-center">Terms & Conditions</strong>
        </div>
        <strong>Payment</strong>
        <p className="mb-3">
          I authorize the services to be performed at the price quoted and understand that the
          service call and deposit (if applicable) are non-refundable. All payments are due upon
          receipt of invoice. If a job is canceled less than 24 hours after diagnosis, a 25%
          restocking fee will apply. I agree to payment of a late-payment fee of 3% of the total
          overdue amount, to be charged monthly, and prorated for periods of less than one month. In
          the event my account becomes seriously delinquent, I understand that the{' '}
          {companyNameFullLegal}
          will have no choice but to resort to collection proceedings and I agree to be responsible
          for it attorney’s fees and costs incurred in those proceedings.
        </p>
        <strong>Warranty</strong>
        <p className="mb-3">
          {companyNameFullLegal} provides a 90-day labor warranty and 1-year warranty on replaced
          parts. No other parts of the appliance will be included in the warranty. If a repair was
          completed without replacing a part, the amount that was paid is non-refundable. It can
          only be credited towards any replacement parts (if applicable) and labor. There is no
          warranty for parts provided by any source other than {companyNameFullLegal}. If a part is
          provided by an outside source, {companyNameFullLegal} cannot guarantee work and refunds
          cannot be provided on labor. This warranty is not transferable and will be automatically
          voided if the manufacturer discontinues production of the replaced part.
        </p>
        <strong>Liability</strong>
        <p className="mb-5">
          {companyNameFullLegal} always does its best to leave the workplace as clean as it was
          before, but I understand that some jobs require moving appliances around and I acknowledge
          that
          {companyNameFullLegal} and their technicians will not be held liable for damage done to
          floors, walls, etc. IN NO EVENT SHALL NANA SERVICES INC BE LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, including BUT NOT LIMITED TO, water damage
          and food spoilage. In addition, in no event shall its LIABILITY EXCEED THE COMPENSATION
          PAID FOR THE SERVICES UNDER THIS AGREEMENT. “An estimate as required (Section 9844 of the
          California Business and Professions Code) for repairs shall be given to the customer by
          the service dealer in writing, and the service dealer may not charge for work done or
          parts supplied in excess of the estimate without the prior consent of the customer. Where
          provided in writing, the service dealer may charge a reasonable fee for services provided
          in determining the nature of the malfunction in preparation of a written estimate for
          repair. For information contact the Bureau of Electronics and Appliance Repair, Department
          of Consumer Affairs, Sacramento 95814”
        </p>
        {_.get(this.props.workOrder, 'estimate') && (
          <h3 className="text-center">
            Estimate Total: {U.toMoney(this.props.workOrder.estimate.toCustomer.total)}
          </h3>
        )}
        <div className="d-flex align-items-center justify-content-center flex-column">
          <strong>Please Sign Below</strong>
          <div style={{ height: 200, width: 500, position: 'relative' }}>
            <SignatureCanvas
              ref="signature"
              penColor="green"
              backgroundColor="#eee"
              canvasProps={{ height: 200, width: 500, className: 'sigCanvas' }}
              onBegin={this.onBegin}
            />
          </div>
        </div>
        {this.state.showSave && (
          <div className="mt-5 text-center">
            <div className="btn btn-primary" onClick={this.onSave}>
              Save
            </div>
            <div className="btn btn-secondary ml-5" onClick={this.onClear}>
              Clear
            </div>
          </div>
        )}
        {this.state.saved && <h3 className="mt-3 text-center text-success">Thank You</h3>}
        <div style={{ height: '500px' }} />
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  if (!job) {
    return {};
  }
  const workOrder = s.workorders[job.metadata.workOrderId];
  return { job, workOrder };
})(Sign);
