import React from 'react';
import * as Table from '@nanaio/table';
import U, { Range } from '@nanaio/util';
import _ from 'lodash';
import { Input, InputType } from '../../form';

const filterUi = ({
  onChange,
  type,
  value,
}: {
  column: Table.Column;
  onChange?: (id: string, value?: number) => void;
  type?: 'days' | 'hours';
  value?: Range<number>;
}): JSX.Element => {
  const millisecondsPerUnit = type === 'days' ? U.millisecondsInDay : U.millisecondsInHour;
  const transformInput = (value: number) => (value ? value / millisecondsPerUnit : value);
  const transformOutput = (value: number) => (value ? value * millisecondsPerUnit : value);
  return (
    <div>
      <label>Set duration range in {type}</label>
      <div className="d-flex align-items-center mt-1">
        <Input
          className="m-0 flex-1"
          label="Min"
          onChange={v => onChange && onChange('start', v)}
          transformInput={value => transformInput(value)}
          transformOutput={value => transformOutput(value)}
          type={InputType.NUMBER}
          value={value?.start}
        />
        <div className="d-inline-flex align-center px-3">
          <p>-</p>
        </div>
        <Input
          className="m-0 flex-1"
          label="Max"
          onChange={v => onChange && onChange('end', v)}
          transformInput={value => transformInput(value)}
          transformOutput={value => transformOutput(value)}
          type={InputType.NUMBER}
          value={value?.end}
        />
      </div>
    </div>
  );
};

const durationModule: Partial<Table.Column> = { filterUi };

export default durationModule;
