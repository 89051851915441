import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

const Select = (p, { t }) => {
  let options = _.map(
    _.filter(p.options, option => option !== undefined && option !== null),
    option => ({
      disabled: option.disabled,
      id: p.idPath ? _.get(option, p.idPath) : (option.id || option._id) ?? option,
      name: p.nameKey ? _.get(option, p.nameKey) : option.name ?? option,
    })
  ).filter(option => _.isString(option.name) || _.isNumber(option.name));
  if (p.cap) {
    options = _.map(options, option => ({ ...option, name: _.startCase(option.name) }));
  }
  if (p.sort) {
    options = _.sortBy(options, 'name');
  }
  const value = p.object
    ? _.get(_.get(t.state, p.id, p.def ?? ''), 'id')
    : _.get(t.state, p.id, p.def ?? '');
  // eslint-disable-next-line no-underscore-dangle
  const _onChange = e => {
    let { value } = e.target;
    if (value === '_') {
      value = undefined;
    }
    if (p.object) {
      value = _.values(p.options).find(o => o.id === e.target.value);
    }
    const option = options.find(o => _.toString(o.id) === value);
    if (option) {
      value = option.id;
    }
    if (_.get(option, 'disabled')) {
      return;
    }
    onChange(t, p.id, value);
    if (p.onChange) {
      p.onChange(value, option);
    }
  };
  if (options.find(o => !_.isString(o.name) && !_.isNumber(o.id))) {
    return (
      <Group {...p}>
        <div className="text-danger">Invalid options</div>
      </Group>
    );
  }
  if ('firstSelected' in p && p.firstSelected === true && _.isEmpty(value) && options.length) {
    onChange(t, p.id, options[0].id);
  }
  const selectedOption = options.find(o => o.id === value);
  const displayCustomValue = !p.hideCustomValue && !!value && _.isString(value) && !selectedOption;
  if (displayCustomValue) {
    options = [{ id: value, name: p.warnCustomValue ? `FIX - ${value}` : value }, ...options];
  }
  return (
    <Group {...p}>
      <select className="form-control" value={value} disabled={p.disabled} onChange={_onChange}>
        <option value="_">{p.hint || 'Select'}</option>
        {options.map((o, n) => (
          <option key={n} value={o.id} disabled={o.disabled}>
            {o.name}
          </option>
        ))}
      </select>
    </Group>
  );
};

Select.contextTypes = { t: T.object.isRequired };

Select.propTypes = {
  cap: T.bool,
  def: T.string,
  disabled: T.bool,
  firstSelected: T.bool,
  hideCustomValue: T.bool,
  hint: T.string,
  id: T.string.isRequired,
  idPath: T.string,
  label: T.string,
  nameKey: T.string,
  object: T.bool,
  options: T.array,
  req: T.bool,
  sort: T.bool,
  type: T.string,
  warnCustomValue: T.bool,
};

export default Select;
