import React from 'react';
import { Row } from 'reactstrap';
import T from 'prop-types';
import { Icon } from '@/components';
import { Address,Modal, Phone, Text } from '../../com/ui/form';

export class AddPartOrderAddress extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      address: {
        name: '',
        email: '',
        phone: '',
        type: 'customer',
        address: {},
      },
    };
    this.openModal = this.openModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!this.props.isOpen && p.isOpen) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({
      modal: true,
    });
  }

  submit() {
    this.props.addPartOrderAddress(this.state.address);
    this.props.onClose();
    this.setState({ modal: false });
  }

  render() {
    const header = (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <span className="back-button" />
        <span className="header-title">Add Shipping Address</span>
      </div>
    );

    const modalBody = (
      <section
        id="AdminForm"
        className="inline-group"
        style={{ maxWidth: '700px', margin: '0px auto' }}
      >
        {this.state.error && (
          <Row className="admin_alert-bar mb-3">
            <Icon name="alert_circle_outline" size={16} color="#D64727" />
            <span className="ml-2">{this.state.error}</span>
          </Row>
        )}
        <div className="px-3">
          <Text id="address.name" label="Full Name" hint="Jane Doe" req />
          <Phone id="address.phone" label="Phone Number" req />
          <Text id="address.email" label="Email Address" hint="janedoe@test.com" req />
          <Address id="address.address" label="Shipping Address" req />
        </div>
      </section>
    );

    return (
      <Modal
        submit={this.submit}
        titleCom={header}
        wrapClassName="admin-modal overlayed"
        modalClassName="admin-modal_container"
        backdropClassName="admin-modal_backdrop"
        className="admin-modal_dialog "
        contentClassName="admin-modal_dialog_inner_container"
        headerClassName="admin-modal_header"
        bodyClassName="admin-modal_body"
        footerClassName="admin-modal_footer"
        centered
        onClose={this.props.onClose}
        // hideFooter={this.state.showPartSearch}
        footerCom={
          <div className="d-flex justify-content-end">
            <button className="admin_button" onClick={this.submit}>
              Save Address&nbsp;&nbsp;
              <Icon name="arrow_right" size={16} color="#fff" />
            </button>
          </div>
        }
      >
        {modalBody}
      </Modal>
    );
  }
}
