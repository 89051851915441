import flatten from 'flat';
import _ from 'lodash';

function hexToRGB(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

const colors = {
  primaryCTA: '#479DC8',
  primary: '#0070C9',
  secondary: '#141B41',
  background: { medium: '#F1F1F1', light: '#F1F3F6', lightest: '#FAFAFA' },
  grey: { 4: '#BDBDBD', dark: '#878787', medium: '#E4E4E4', light: '#F9F9F9' },
  font: { dark: '#030301' },
  icons: { grey: '#CCCCCC', orange: '#FFBC42' },
  danger: '#DD2D4A',
  success: '#26A96C',
  accent: { orange: '#E88F0A', purple: '#3C1642' },
  white: '#FFFFFF',
  black: '#000000',
  teal: {
    100: 'rgba(71, 157, 200, 1)',
    75: 'rgba(71, 157, 200, 0.75)',
    50: 'rgba(71, 157, 200, 0.5)',
    25: 'rgba(71, 157, 200, 0.25)',
    15: 'rgba(71, 157, 200, 0.15)',
  },
  blue: {
    100: 'rgba(0, 112, 201, 1)',
    75: 'rgba(0, 112, 201, 0.75)',
    50: 'rgba(0, 112, 201, 0.5)',
    25: 'rgba(0, 112, 201, 0.25)',
    15: 'rgba(0, 112, 201, 0.15)',
  },
  purple: {
    100: 'rgba(60,22, 66, 1)',
    75: 'rgba(60,22, 66, 0.75)',
    50: 'rgba(60,22, 66, 0.5)',
    25: 'rgba(60,22, 66, 0.25)',
    15: 'rgba(60,22, 66, 0.15)',
  },
  red: {
    100: 'rgba(221, 45, 74, 1)',
    75: 'rgba(221, 45, 74, 0.75)',
    50: 'rgba(221, 45, 74, 0.5)',
    25: 'rgba(221, 45, 74, 0.25)',
    15: 'rgba(221, 45, 74, 0.15)',
  },
  orange: {
    100: 'rgba(232, 143, 10, 1)',
    75: 'rgba(232, 143, 10, 0.75)',
    50: 'rgba(232, 143, 10, 0.5)',
    25: 'rgba(232, 143, 10, 0.25)',
    15: 'rgba(232, 143, 10, 0.15)',
  },
  yellow: {
    100: 'rgba(255, 188, 66, 1)',
    75: 'rgba(255, 188, 66, 0.75)',
    50: 'rgba(255, 188, 66, 0.5)',
    25: 'rgba(255, 188, 66, 0.25)',
    15: 'rgba(255, 188, 66, 0.15)',
  },
  green: {
    100: 'rgba(38, 169, 108, 1)',
    75: 'rgba(38, 169, 108, 0.75)',
    50: 'rgba(38, 169, 108, 0.5)',
    25: 'rgba(38, 169, 108, 0.25)',
    15: 'rgba(38, 169, 108, 0.15)',
  },
};

const flatColors: Record<string, string> = flatten(colors);

const theme = {
  colors,
  flatColors,
  formControl: {
    backgroundClip: 'padding-box',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    color: '#212529',
    display: 'block',
    fontFamily: 'Myriad',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    padding: '.375rem .75rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    width: '100%',
  },
  hexToRGB,
  shadowBottom: { boxShadow: '0 8px 8px rgba(0,0,0,0.05)' },
  shadowLeft: { boxShadow: '-8px 0 rgba(0,0,0,0.05)' },
  shadowRight: { boxShadow: '8px 0 8px rgba(0,0,0,0.05)' },
  shadowTop: { boxShadow: '0 -8px 8px rgba(0,0,0,0.05)' },
  shadowXl: {
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)',
  },
};

export default theme;
