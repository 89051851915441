import React, { useCallback, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import { workOrderEvent } from '@/com/analytics';
import { APIError, Modal } from '@/components';
import { useLazyLegacyAPI,useLegacySelector } from '@/hooks';
import { Step, useUpdateFtcBFSteps } from '@/hooks/api/workOrderFtcBF';
import ApplianceStep from './ApplianceStep';
import AppointmentStep from './AppointmentStep';
import LandingPage from './LandingPage';
import ProfileStep from './ProfileStep';
import TermsStep from './TermsStep';

const StepByName = {
  appliance: ApplianceStep,
  appointment: AppointmentStep,
  profile: ProfileStep,
  terms: TermsStep,
} as const;

type Props = {
  steps: ReadonlyArray<Step>;
  deadline: moment.Moment;
  taskId: string;
  onClose: () => void;
  workOrderId: string;
};

export type StepProps = {
  currentStep: Step;
  task: Task;
  subStepNum: number;
  onCloseStep: () => void;
  onPreviousSubStep: () => void;
  onNextSubStep: () => Promise<void>;
};

export type SubStepProps = Pick<StepProps, 'task' | 'onNextSubStep'>;

export default function ConfirmationFlowModal({
  steps,
  deadline,
  taskId,
  onClose,
  workOrderId,
}: Props): JSX.Element {
  // ensure we re-render every time the workorder is updated in the store
  const { task, workOrder } = useLegacySelector(state => {
    const task = state.tasks[taskId];
    const workOrder = state.workorders[workOrderId];
    return { task, workOrder };
  });
  const [stepIndex, setStepIndex] = useState(-1);
  const [subStepNum, setSubStepNum] = useState(1);
  const currentStep = steps[stepIndex];

  const updateWorkOrderFtcBFNew = useUpdateFtcBFSteps(workOrder.id);

  const [confirmWorkOrder, { error: confirmError }] = useLazyLegacyAPI(
    `workorders/${workOrder.id}/ftcBFV2CustomerConfirm`,
    {
      errorRender: ({ error }) => (
        <APIError
          error={error}
          text={
            <>
              There was an error confirming your repair. Please contact our{' '}
              <a className="text-primaryCTA" href="/contact">
                customer support team
              </a>{' '}
              if you would like to continue service.
            </>
          }
          variant="modal"
        />
      ),
      save: true,
    }
  );

  const handleGoToStep = useCallback(
    (index: number) => {
      const currentStep = steps[index];
      if (currentStep) {
        setStepIndex(index);
      } else {
        setStepIndex(-1);
      }
    },
    [steps]
  );

  const handleCloseStep = useCallback(() => {
    setStepIndex(-1);
  }, []);

  const completeSubStep = useCallback(async () => {
    const newSteps = _.cloneDeep(steps) as Step[];
    const newCurrentStep = newSteps[stepIndex];
    newCurrentStep.completed += 1;
    await updateWorkOrderFtcBFNew.mutateAsync(
      { steps: newSteps },
      {
        onSuccess: () => {
          if (newCurrentStep.completed === newCurrentStep.total) {
            if (newCurrentStep === _.last(newSteps)) {
              void confirmWorkOrder();
              const eventInfo = {
                variant: 'ftc',
                step: 'complete',
              };
              workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);
            }
            setSubStepNum(1);
            handleGoToStep(stepIndex + 1);
          } else {
            setSubStepNum(subStepNum + 1);
          }
        },
      }
    );
  }, [steps, stepIndex, updateWorkOrderFtcBFNew, handleGoToStep, confirmWorkOrder, subStepNum]);

  const handleNextSubStep = useCallback(async () => {
    if (subStepNum - 1 === currentStep.completed) {
      await completeSubStep();
    } else if (subStepNum === currentStep.total) {
      handleCloseStep();
    } else {
      setSubStepNum(subStepNum + 1);
    }
  }, [subStepNum, currentStep, completeSubStep, handleCloseStep]);

  const handlePreviousSubStep = useCallback(() => {
    if (subStepNum > 1) {
      setSubStepNum(subStepNum - 1);
    } else {
      handleCloseStep();
    }
  }, [handleCloseStep, subStepNum]);

  let component;
  if (currentStep?.name && currentStep.name in StepByName) {
    // TODO remove future-proof enums from relay compiler
    const Component = StepByName[currentStep.name as keyof typeof StepByName];
    component = (
      <Component
        {...{
          currentStep,
          subStepNum,
          task,
          onCloseStep: handleCloseStep,
          onNextSubStep: handleNextSubStep,
          onPreviousSubStep: handlePreviousSubStep,
        }}
      />
    );
  } else {
    component = (
      <LandingPage
        steps={steps}
        deadline={deadline}
        onLeave={onClose}
        onStepClick={handleGoToStep}
        workOrder={workOrder}
      />
    );
  }

  return (
    <Modal isOpen onClose={onClose} hideOnBackdropClick={false} height={1000}>
      {component}
      {confirmError}
    </Modal>
  );
}
