import React from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Button } from '@/components';
import { useLegacySelector } from '@/hooks';

export default function ExitViewAs(): JSX.Element {
  const { isViewAs, me } = useLegacySelector(state => {
    const { me } = state;
    const isViewAs = U.user.isViewAsUser(me);
    return { isViewAs, me };
  });

  const exitViewAsUser = () => {
    const newMe = _.cloneDeep(me);
    newMe.userId = nullthrows(newMe.trueMe?.userId);
    newMe.proId = newMe.trueMe?.proId;
    newMe.roles = nullthrows(newMe.trueMe?.roles);
    U.redux.set('me', newMe);
  };

  if (!isViewAs) {
    return <></>;
  }

  return (
    <div className="fixed bottom-2 left-2 z-[10000]">
      <Button onClick={exitViewAsUser} size="small" variant="danger">
        Exit View as User
      </Button>
    </div>
  );
}
