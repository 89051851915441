import React from 'react';
import { U } from '@nanaio/util';
import m from 'moment';
import PropTypes from 'prop-types';
import Button from '../../core/Button';
import Icon from '../../core/Icon';
import Loader from '../../core/Loader';
import Text from '../../core/Text';
import theme from '../../theme';

const styles = {
  container: {
    height: '154px',
    ...theme.shadowBottom,
    zIndex: 1,
    ...theme.shadowRight,
  },
};

export default function WeekSelector({
  isLoading,
  loadWeek,
  mapIsOpen,
  scheduleStart,
  setGoToDateIsOpen,
  setMapIsOpen,
  setSearchTechIsOpen,
  weekIsLoading,
}) {
  const isToday = m(scheduleStart).isSame(m().startOf('day'));

  let title = 'This Week';
  if (!isToday) {
    if (m(scheduleStart).isSame(m().startOf('day').add(1, 'week'))) {
      title = 'Next Week';
    } else if (m(scheduleStart).isSame(m().startOf('day').subtract(1, 'week'))) {
      title = 'Last Week';
    } else {
      title = `Week ${m(scheduleStart).week()}`;
    }
  }

  return (
    <div className="border-b border-r border-grey-medium p-4" style={styles.container}>
      <div className="mb-4 flex flex-row">
        <Icon
          className="mr-4"
          name="chevron_left"
          onClick={() => loadWeek(m(scheduleStart).subtract(1, 'week'))}
          size={24}
        />
        <div className="flex-1">
          <Text className="mb-1" type="headline-6">
            {title}
          </Text>
          <Text color="grey.dark" type="body-2">
            {m(scheduleStart).format('ddd, MMM D - ')}
            {m(scheduleStart).add(1, 'week').format('ddd, MMM D, YYYY')}
          </Text>
        </div>
        <Loader className="mr-4" isLoading={isLoading} />
        <Icon
          name="chevron_right"
          onClick={() => loadWeek(m(scheduleStart).add(1, 'week'))}
          size={24}
        />
      </div>
      <div className="flex flex-row">
        <Button
          className="mr-2 flex-1"
          onClick={() => (isToday ? setGoToDateIsOpen(true) : loadWeek(m().startOf('day')))}
          variant="secondary"
        >
          {`Go to ${isToday ? 'Date' : 'Today'}`}
        </Button>
        <Button
          className="mr-2 flex-1"
          disabled={weekIsLoading}
          onClick={() => setMapIsOpen(!mapIsOpen)}
          variant="primary"
        >
          {mapIsOpen ? 'Exit' : 'Map'}
        </Button>
        <Button
          className="mr-2 flex-1"
          disabled={weekIsLoading}
          onClick={() => setSearchTechIsOpen(true)}
          variant="secondary"
        >
          Search Tech
        </Button>
      </div>
    </div>
  );
}

WeekSelector.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadWeek: PropTypes.func.isRequired,
  mapIsOpen: PropTypes.bool.isRequired,
  scheduleStart: U.timePropType,
  setGoToDateIsOpen: PropTypes.func.isRequired,
  setMapIsOpen: PropTypes.func.isRequired,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  weekIsLoading: PropTypes.bool.isRequired,
};
