import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { usePreviousDistinct } from '@nanaio/hooks';
import * as Table from '@nanaio/table';
import U, { Me, Option } from '@nanaio/util';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import pluralize from 'pluralize';
import { useDeepCompareMemoRef } from '@/hooks';
import { Button, Icon, Text, Tooltip } from '../core';
import { Form, Input, InputType } from '../form';
import EditModules from './EditColumns';
import * as typeToModuleUi from './moduleTypes';
import { DatabaseQuery } from './types';
import { trimQuery } from './util';

type ErrorResponse = { code?: number; errmsg?: string; errMsg?: string };

type Group = { group: string; queries: Table.Query[] };

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToTable: Table.Depth2<Table.Table>;
  loadQueryOptions: (force?: boolean) => Promise<void>;
  me: Me;
  name: string;
  onChange: (query: Table.PartialQuery) => Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    query: Table.Query;
    rows: Table.Row[];
  }>;
  query: Table.Query;
  queryOptions?: Table.Query[];
  roles: Record<string, string>;
  tableName: string;
  updateUrl: (query: Table.Query) => void;
};

function QueryComponent({
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToTable,
  loadQueryOptions,
  me,
  name,
  onChange,
  query: propsQuery,
  queryOptions,
  roles,
  tableName,
  updateUrl,
}: Props): JSX.Element {
  const [error, setError] = useState<string>();
  const [query, setQuery] = useState<DatabaseQuery>(trimQuery(propsQuery));
  const queryRef = useDeepCompareMemoRef(query);
  const [search, setSearch] = useState<string>();
  const [shared, setShared] = useState<boolean>();

  useEffect(() => {
    void loadQueryOptions(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prevPropsQuery = usePreviousDistinct(propsQuery);
  const propsQueryChanged = !_.isEqual(propsQuery, prevPropsQuery);
  useEffect(() => {
    setQuery(trimQuery(propsQuery));
  }, [propsQueryChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyQuery = (event: React.MouseEvent<HTMLElement>, query: Table.Query) => {
    event.stopPropagation();
    const queryCopy: DatabaseQuery = _.pick(trimQuery(query), [
      'analytics',
      'columns',
      'databaseId',
      'description',
      'group',
      'isPublic',
      'table',
    ]);
    for (const column of queryCopy.columns) {
      column.id = column.id || ObjectID().toHexString();
    }
    queryCopy.name = `${query.name} copy`;
    setQuery(queryCopy);
  };

  /** create a new query instead of editing the one you're on */
  const createNewQuery = async () => {
    const { query: queryCopy } = await Table.loadOrUpdateQuery({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToTable,
      roles,
      query: queryRef.current,
      typeToModuleUi,
      useDefaultQuery: true,
    });
    setQuery(queryCopy);
  };

  /** filters query list based on search by name */
  const getTableListColumns = () => {
    let options = _.map(queryOptions, option => (option.name === query.name ? query : option));
    options = _.map(options, option => {
      const newOption = option.id === query.id ? query : option;
      return {
        ...newOption,
        group: newOption.group || (newOption.analytics?.isOpen ? 'Reports' : 'Tables'),
      };
    });
    if (search) {
      const newSearch = search.toLowerCase();
      options = options.filter(option => _.includes(option.name?.toLowerCase(), newSearch));
    }
    const groups = U.keySort(_.groupBy(options, 'group'));
    const tableListColumns: Group[][] = [[], [], []];
    _.forEach(groups, (queries, group) => {
      const columnLengths = tableListColumns.map((groups, i) => ({
        columnIndex: i,
        length: _.sumBy(groups, group => group.queries.length),
      }));
      const indexToAdd = _.sortBy(columnLengths, 'length')[0].columnIndex;
      tableListColumns[indexToAdd].push({ group, queries });
    });
    return tableListColumns;
  };

  /** returns true if the query being edited is different then the one in the database */
  const queryChanged = () => {
    const props = [
      'analytics',
      'columns',
      'description',
      'group',
      'isDefault',
      'isPublic',
      'isVerified',
      'name',
      'sort',
    ];
    const originalQuery = _.find(queryOptions, option => option.id === query.id);
    return !_.isEqual(_.pick(query, props), _.pick(originalQuery, props));
  };

  /** close this page and return to the table */
  const onClose = () => {
    void onChange(query);
  };

  /** delete table query */
  const onDelete = async () => {
    if (query.id) {
      await U.api('delete', `query/${query.id}`);
    }
    void U.api('put', `users/${me.userId}`, [{ action: 'remove', path: `queries.${tableName}` }]);
    void loadQueryOptions(true);
    window.history.pushState(undefined, pluralize(name), global.location.href.split('/query')[0]);
  };

  /** switch to a new table setting */
  const onQueryChange = (id: string) => {
    const query = _.find(queryOptions, option => option.id === id);
    if (query) {
      setQuery(query);
      updateUrl(query);
    }
  };

  /** revert back to current query */
  const onReset = async () => {
    const response = await U.api<Table.Query[]>('post', 'query/search', {
      query: { _id: query.id },
    });
    if (_.isArray(response)) {
      const [queryCopy] = response;
      void onChange(queryCopy);
    }
  };

  /** create new query or update existing table query based on query name */
  const onSave = async () => {
    let response;
    if (!query.id) {
      response = await U.api<Table.Query>('post', 'query', query);
    } else {
      response = await U.api<Table.Query>('put', 'query', query);
    }
    if ((response as ErrorResponse).code === 11000) {
      return setError('Name taken');
    }
    if ((response as ErrorResponse).errmsg || (response as ErrorResponse).errMsg) {
      return setError((response as ErrorResponse).errmsg || (response as ErrorResponse).errMsg);
    }
    await loadQueryOptions(true);
    setQuery(response as Table.Query);
    updateUrl(response as Table.Query);
    return undefined;
  };

  /** indicate that the link has been copied */
  const onShare = () => {
    setShared(true);
    setTimeout(() => setShared(false), 3000);
  };

  /** returns true if the save button should show */
  const showSave = () => {
    if (!queryChanged()) {
      return false;
    }
    if (!query.id) {
      return true;
    }
    if (query.isVerified && !roles[U.user.Role.VERIFIED_TABLE]) {
      return false;
    }
    return !query.isDefault || !_.some(queryOptions, option => option.isDefault);
  };

  const isChange = queryChanged();
  const isMine = query.user_id === me.userId;
  const shareLink = query.id
    ? `${global.location.href.replace(/query.*/, '').replace(/\/$/, '')}/query/${query.id}`
    : undefined;

  return (
    <Form className="relative h-screen bg-white" debounce={1000} onChange={setQuery} value={query}>
      <div
        className="absolute left-0 right-0 flex p-4"
        style={{ alignItems: 'center', height: 72 }}
      >
        <div className="mr-4 flex-1">
          <Input className="mb-0" label="Search" onChange={setSearch} value={search} />
        </div>
        {query.id && shareLink && (
          <CopyToClipboard text={shareLink} onCopy={onShare}>
            <Button className="mr-4" variant="secondary">
              {shared ? 'Copied' : 'Share'}
            </Button>
          </CopyToClipboard>
        )}
        {isChange && query.id && (
          <Button className="mr-4" onClick={onReset} variant="secondary">
            Undo
          </Button>
        )}
        {isMine && query.id && (
          <Button className="mr-4" onClick={onDelete} variant="secondary">
            Delete
          </Button>
        )}
        {query.id && (
          <Button className="mr-4" onClick={createNewQuery} variant="secondary">
            Create new query
          </Button>
        )}
        {showSave() && (
          <Button className="mr-4" onClick={onSave} submit variant="secondary">
            Save
          </Button>
        )}
        <Button onClick={onClose}>Back</Button>
      </div>
      <div className="absolute bottom-0 left-0 right-0 overflow-y-auto p-4" style={{ top: 72 }}>
        <div className="flex h-full items-start">
          <div style={{ flex: 3 }} className="h-full overflow-y-auto">
            <div className="grid grid-cols-3 gap-x-4">
              {getTableListColumns().map((groups, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  {groups.map(group => (
                    <div key={group.group} className="mb-4">
                      <Text type="subtitle-2">{group.group}</Text>
                      {group.queries.map(option => (
                        <div
                          className="border-bottom group flex cursor-pointer border-grey-dark py-1"
                          key={option.id || 'noId'}
                          onClick={() => onQueryChange(option.id)}
                        >
                          {option.isVerified && (
                            <Icon
                              name="verified"
                              className="mr-4"
                              color="primary"
                              tooltip={{ node: 'Verified' }}
                            />
                          )}
                          {option.id === query?.id && (
                            <Icon name="radio_button_checked" color="primaryCTA" className="mr-4" />
                          )}
                          <Tooltip className="mr-auto" node={option.description}>
                            <Text className="flex-1">{option.name}</Text>
                          </Tooltip>
                          <Icon
                            className="hidden group-hover:block"
                            color="primaryCTA"
                            name="content_copy"
                            onClick={event => copyQuery(event, option)}
                            tooltip={{ node: 'Copy Table' }}
                          />
                          {option.isDefault && (
                            <Icon className="ml-4" name="star" color="grey.dark" />
                          )}
                          {!option.isPublic && (
                            <Icon className="ml-4" name="lock" color="grey.dark" />
                          )}
                          <Text className="ml-4" color="grey.dark" type="helper">
                            {option.views}
                          </Text>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="h-full flex-1 overflow-y-auto p-4">
            <Text color="danger">{error}</Text>
            <div>
              <Input id="name" required />
              <Input id="description" multiline required />
              <Input id="group" required />
              <div className="grid grid-cols-3 gap-x-4">
                <Input
                  id="isPublic"
                  disabled={Boolean(!isMine && query.id)}
                  type={InputType.BOOL}
                />
                <Input id="analytics.isOpen" label="Is Report" type={InputType.BOOL} />
                <Input
                  id="isVerified"
                  disabled={!roles[U.user.Role.VERIFIED_TABLE]}
                  type={InputType.BOOL}
                />
              </div>
              <EditModules
                {...{
                  databaseIdToTableIdToColumnIdToColumn,
                  multilevelPlacement: 'left',
                  onChange: setQuery,
                  query,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default connect(s => ({ me: s.me }))(QueryComponent);
