import React from 'react';
import { Task, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Avatar } from '@/components';
import { apiOrigin, companyName } from '@/config/const';

type Props = {
  task: Task;
};

export default function Parts({ task }: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);

  return (
    <div className="card-box">
      <div className="card-box--header">Parts ({_.values(_.get(task, 'parts.items')).length})</div>
      {_.values(_.get(task, 'parts.items')).length > 0 ? (
        <div className="card-box--content no-padding">
          {_.values(task.parts?.items).map((part, index) => {
            if (part) {
              let imageUrl;
              if (part.images[0]) {
                const imagePath = _.values(task.metadata.taskProblemQuestions?.pictures).find(
                  picture => picture.checksum === part.images[0]
                )?.url;
                imageUrl = imagePath ? `${apiOrigin}/images${imagePath}` : '';
              }
              const pricePerUnit = (part.pricePerUnit ? part.pricePerUnit : 0) / 100;
              return (
                <article key={index} className="card-part">
                  <Avatar
                    className="hidden-md-down bg-background-medium"
                    pictureUrl={imageUrl !== '' ? imageUrl : undefined}
                    badgeProps={{
                      color: 'grey-dark',
                      children: part.unit.value,
                      variant: 'outline',
                    }}
                    badgePosition="topRight"
                    iconProps={{ name: 'image', color: 'grey.dark' }}
                  />
                  <div className="flex-1">
                    <p className="text-bold weight-600">
                      {part.desc} {part.partNumber && ` (#${part.partNumber})`}
                    </p>
                    <p className="text-muted">
                      {part.status} &bull; Qty {part.unit.value} &bull; ${pricePerUnit} each &bull;
                      {` Supplied by ${part.owner.name}`}
                    </p>
                  </div>
                  <div className="hidden-md-down text-right">
                    <p>${(pricePerUnit * part.unit.value).toFixed(2)} + Tax</p>
                    {part.track?.expectedDelivery && (
                      <p className="text-muted">
                        ETA {mt(part.track.expectedDelivery).tz(timezone).format('ddd, MM/DD/YY')}
                      </p>
                    )}
                  </div>
                </article>
              );
            }
          })}
        </div>
      ) : (
        <div className="card--block card-block--border card-shadow px-4 py-4 text-center">
          <br />
          <img src="/img/part-icon.png" className="mx-auto" style={{ height: 50 }} alt="" />
          <br />
          <h4 className="weight-600">No Parts Added</h4>
          <p className="text-muted">
            Your {companyName} Technician will add parts after their diagnosis on the day of the
            appointment. In good news, not all jobs requires parts to complete the repair.
          </p>
          <br />
        </div>
      )}
    </div>
  );
}
