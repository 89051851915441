import React from 'react';
import { connect } from 'react-redux';
import * as Table from '@nanaio/table';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from '@/components';
import { Input } from '@/components/form';
import TableComponent from '@/components/Table';

const statusIdToName = _.keyBy(T.statusOptions, option => option.id || option);

const statusToColor = {
  [T.Status.ASSIGNED]: 'primary',
  [T.Status.CANCELLED]: 'grey-dark',
  [T.Status.CANCEL_REQUESTED]: 'grey-dark',
  [T.Status.COMPLETED]: 'success',
  [T.Status.COMPLETION_REVIEW]: 'success',
  [T.Status.COMPLETION_REVIEW_FAILED]: 'danger',
  [T.Status.CUSTOMER_NO_SHOW]: 'danger',
  [T.Status.EN_ROUTE]: 'accent-purple',
  [T.Status.LEFT_SITE]: 'accent-orange',
  [T.Status.NEEDS_PRE_SCREEN_REVIEW]: 'accent-orange',
  [T.Status.ON_SITE]: 'accent-purple',
  [T.Status.PARTS_BACKORDERED]: 'accent-purple',
  [T.Status.PARTS_NOT_AVAILABLE]: 'danger',
  [T.Status.PARTS_ORDERED]: 'accent-purple',
  [T.Status.PARTS_RECEIVED]: 'accent-purple',
  [T.Status.PARTS_REQUESTED]: 'accent-orange',
  [T.Status.PARTS_RESEARCH]: 'accent-purple',
  [T.Status.PARTS_SHIPPED]: 'accent-purple',
  [T.Status.PENDING_CONFIRMATION]: 'danger',
  [T.Status.PRO_NO_SHOW]: 'danger',
  [T.Status.PURCHASING_PARTS]: 'accent-purple',
  [T.Status.READY_FOR_FOLLOWUP]: 'danger',
  [T.Status.REPLACEMENT_REVIEW]: 'accent-orange',
  [T.Status.WAITING_ON_APPROVAL]: 'danger',
  [T.Status.WAITING_ON_PRE_SCREEN]: 'accent-purple',
  [T.Status.WAITING_ON_SCHEDULE]: 'accent-purple',
};

const uiModuleIdToModule = {
  proName: {
    editUi: ({ key, onChange }) => (
      <Input key={key} label="Unassign Pro" onChange={onChange} type="bool" />
    ),
  },
  status: {
    cellUi: ({ value }) => (
      <Badge color={statusToColor[value]}>
        {statusIdToName[value]?.name || _.startCase(value)}
      </Badge>
    ),
  },
  taskStatus: {
    cellUi: ({ value }) =>
      value.map((status, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Badge color={statusToColor[status]} key={i}>
          {statusIdToName[status]?.name || _.startCase(status)}
        </Badge>
      )),
  },
};

export const settings = { ...Table.databases.default.workOrder, uiModuleIdToModule };

function WorkOrdersTable({ services }) {
  if (_.isEmpty(services)) {
    return null;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TableComponent {...settings} />;
}

WorkOrdersTable.propTypes = { services: PropTypes.objectOf(U.service.propType).isRequired };

export default connect(s => ({ services: s.services }))(WorkOrdersTable);
