import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import m from 'moment';
import Link from './link';

class _Head extends React.Component {
  constructor(p) {
    super(p);
    this.toggle = this.toggle.bind(this);
    this.state = { open: false };
  }

  toggle = () => this.setState({ open: !this.state.open });

  render() {
    const t = this;
    const p = t.props;
    const phone = _.get(p.user, 'profile.phone');
    const address = _.get(p.user, 'profile.address.formattedAddress');
    return (
      <div className="cp-client">
        <div className="sty-f1 header">
          <br />
          <br />
          <h5>{_.get(p.user, 'fullName', 'Welcome')}</h5>
          <small>Joined in {m(_.get(p.user, 'createTime')).format('MMM YYYY')}</small>
        </div>
        <div className="sty-f1 li">
          <label>Email</label>
          <p>{_.get(p.user, 'email')}</p>
        </div>
        <div className="sty-f1 li">
          <label>Phone</label>
          <p>
            {phone || (
              <Link to="/profile" className="blue-text">
                Add number
              </Link>
            )}
          </p>
        </div>
        <div className="sty-f1 li">
          <label>Primary Address</label>
          <p>
            {address || (
              <Link to="/profile" className="blue-text">
                Add Address
              </Link>
            )}
          </p>
        </div>
      </div>
    );
  }
}

export const Head = connect(s => ({
  me: s.me,
  user: s.users[s.me.userId],
}))(_Head);

export default Head;
