export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const onLoadEnd = data => resolve(data);
    const onError = error => reject(error);

    reader.addEventListener('loadend', () => {
      reader.removeEventListener('loadend', onLoadEnd);
      onLoadEnd(reader.result);
    });

    reader.addEventListener('error', () => {
      reader.removeEventListener('error', onError);
      onError(reader.error);
    });

    reader.readAsText(file);
  });
}
