import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { updateTask } from '../../com/task';
import { Textbox } from '../../com/ui/form';
import Signature from './Signature';

const color = { muted: '#9FA4A8' };

class Footer extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);

    this.state = {
      uploadSignature: null,
      uploadRepairConfirmationSignature: null,
      addNote: true,
      diagnosis: p.job.diagnosis || '',
      notesForCustomer: _.get(p.job, 'metadata.notesForCustomer', ''),
      declineReasonMap: _.keyBy(T.invoiceDeclineReasons, 'id'),
    };

    this.saveNotes = this.saveNotes.bind(this);
    this.showSaveNotes = this.showSaveNotes.bind(this);
    this.cancelNote = this.cancelNote.bind(this);
  }

  saveSignature = async isRepairConfirmationSignature => {
    const qs = isRepairConfirmationSignature ? '?repairConfirmation=1' : '';
    const value = isRepairConfirmationSignature
      ? this.state.uploadRepairConfirmationSignature
      : this.state.uploadSignature;

    const response = await U.api(
      'put',
      `workOrders/${this.props.workOrder.id}/signature${qs}`,
      { value },
      ['save']
    );

    if (response.errMsg) {
      this.setState({ error: response.errMsg });
    }
  };

  removeSignature = async isRepairConfirmationSignature => {
    const qs = isRepairConfirmationSignature ? '?repairConfirmation=1' : '';
    const response = await U.api('delete', `workOrders/${this.props.workOrder.id}/signature${qs}`, [
      'save',
    ]);

    if (response.errMsg) {
      this.setState({ error: response.errMsg });
    }
  };

  cancelNote() {
    this.setState({ notesForCustomer: _.get(this.props.job, 'metadata.notesForCustomer', '') });
  }

  showSaveNotes() {
    return this.state.notesForCustomer !== _.get(this.props.job, 'metadata.notesForCustomer', '');
  }

  async saveNotes() {
    const changes = [{ path: 'metadata.notesForCustomer', value: this.state.notesForCustomer }];
    const r = await updateTask(this.props.job.id, changes);
    this.setState({ addNote: false });
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    }
  }

  render() {
    const { job } = this.props;
    return (
      <div id="invoiceCss">
        <div className="text-danger">{this.state.error}</div>
        {_.get(job, 'metadata.invDeclineReason') && (
          <div className="border-bottom pb-3 ">
            <label className="text-muted" style={{ color: color.muted, fontWeight: 800 }}>
              DECLINE REASON:
            </label>
            <p className="text-bold">
              {_.get(this.state.declineReasonMap[job.metadata.invDeclineReason], 'name') ||
                job.metadata.invDeclineReason}
            </p>
          </div>
        )}

        {/*= ======Note Input======= */}
        <div className="mb-4">
          <p className="flex-1">Notes to Client</p>
          {this.state.addNote && (
            <div>
              <Textbox
                label=""
                hint="Enter Notes"
                placeholder="Enter Notes"
                id="notesForCustomer"
                className=""
              />
              {this.showSaveNotes() && (
                <div>
                  <btn className="primary" onClick={this.saveNotes}>
                    Save Note
                  </btn>
                  <btn className="secondary ml-2" onClick={this.cancelNote}>
                    Cancel
                  </btn>
                </div>
              )}
            </div>
          )}
        </div>

        <p>Customer invoice signature</p>
        <Signature
          signature={this.props.workOrder.signature}
          workOrderId={this.props.workOrder.id}
        />

        <p>Customer repair confirmation signature</p>
        <Signature
          signature={this.props.workOrder.repairConfirmationSignature}
          workOrderId={this.props.workOrder.id}
          isRepairConfirmationSignature
        />
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const workOrder = s.workorders[job.metadata.workOrderId];
  return { job, workOrder };
})(Footer);
