import React, { Fragment,memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '@/components';
import { appName, companyName } from '@/config/const';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function FAQ() {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const questions = useRef([
    {
      question: `What is ${companyName}?`,
      answer: `${companyName} is a platform that connects you to customers who need their appliances repaired. You use the app to sign-in, claim jobs, and then complete repairs. You will get paid within 48 hours of job completion.`,
    },
    {
      question: `Where is ${companyName} available?`,
      answer: `${companyName} is available in 50+ cities across: Arizona, California, Colorado, District of Columbia, Florida, Kansas, Missouri, Nevada, Oklahoma, Texas, Virginia and Washington. More coming soon!`,
    },
    {
      question: 'What are the requirements to apply?',
      answer: `In order to be eligible to claim jobs on the ${companyName} Platform you must:\n• Be at least 18 years of age\n• Have legal authorization to work in the U.S. as a 1099 contractor\n• Be able to lift up to 40lbs without accommodation\n• Have professional experience in appliance repair\n• Have your own appliance repair tools\n• Have access to a reliable car\n• iOS or Android smartphone\n• Pass a free background check\n• General Liability Insurance\n• Licenses and certification to perform your trade`,
    },
    {
      question: `How do I become a technician on the ${companyName} Platform?`,
      answer: `Complete the sign-up process and pass a background check to get started. Once approved, you can sign in to the ${appName} and begin claiming jobs.`,
    },
    {
      question: 'How long is my background check going to take?',
      answer: 'Background checks normally process within 7-10 business days',
    },
    {
      question: 'How do I get paid?',
      answer: `Technicians are paid through direct deposit within 48 hours of job completion.\n\n${companyName} provides estimates for your earnings on every job and estimates for weekly earnings. The amount you earn depends on the number and brand of appliances serviced, the parts installed, and other services rendered. You keep 100% of the mark-up on parts purchased yourself.`,
    },
    {
      question: `Does ${companyName} pay for gas, mileage, or car maintenance?`,
      answer: `There is no gas, mileage, or car maintenance reimbursement with ${companyName}. However, as an independent contractor, you may be able to write off some of these expenses as business-related expenses when filing your taxes. Please consult a tax advisor if you have any questions.`,
    },
    {
      question: `What is the typical repair process with ${companyName}?`,
      answer: `The ${appName} helps you:\n• Accept the job\n• Get driving directions to your customer\n• Collect appliance info\n• Troubleshoot and provide diagnosis\n• Look up and order replacement parts\n• Install the parts\n• Get paid fast`,
    },
  ]).current;
  const [, breakpointSize] = useBreakpoints();

  return (
    <div className="apply-faq">
      <div className="apply-container">
        <div className="px-4 py-20 lg:px-44">
          <Text
            type={breakpointSize >= Breakpoints.LG ? 'headline-2' : 'headline-3'}
            className="text-center"
            tag="h3"
          >
            FAQ
          </Text>

          <div className="mt-10">
            {questions.map((question, index) => (
              <Fragment key={question.question}>
                {index > 0 ? <div className="my-6 h-0.5 bg-icons-grey opacity-20" /> : null}

                <Question
                  answer={question.answer}
                  isSelected={selectedIndex === index}
                  key={question.question}
                  onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
                  question={question.question}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Question({ isSelected, question, answer, onClick }) {
  const maxHeight = useRef(0);
  const answerRef = useRef(null);
  const [height, setHeight] = useState(null);
  const buttonClasses = classnames('apply-faq-question__button__button', {
    'text-primaryCTA': isSelected,
    '-rotate-90 transform text-icons-grey': !isSelected,
  });

  useLayoutEffect(() => {
    maxHeight.current = answerRef.current.getBoundingClientRect().height;
  }, []);

  useEffect(() => {
    setHeight(isSelected ? maxHeight.current : 0);
  }, [isSelected, maxHeight]);

  return (
    <div className="apply-faq-question grid gap-x-3">
      <div className="apply-faq-question__button">
        <button onClick={onClick} type="button" className={buttonClasses}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 2.25342C14.41 2.25342 18 5.84342 18 10.2534C18 14.6634 14.41 18.2534 10 18.2534C5.59 18.2534 2 14.6634 2 10.2534C2 5.84342 5.59 2.25342 10 2.25342V2.25342ZM10 0.253418C4.48 0.253418 0 4.73342 0 10.2534C0 15.7734 4.48 20.2534 10 20.2534C15.52 20.2534 20 15.7734 20 10.2534C20 4.73342 15.52 0.253418 10 0.253418ZM10 13.2534L6 9.25342H14L10 13.2534Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>

      <Text onClick={onClick} className="apply-faq-question__question pt-0.5" type="subtitle-2">
        {question}
      </Text>

      <div
        ref={answerRef}
        className="apply-faq-question__answer"
        style={{ maxHeight: maxHeight.current ? `${height}px` : 'none' }}
      >
        <ul>
          {answer.split('\n').map(line => (
            <li key={line} className={classnames('pt-2', { 'ml-2': line.startsWith('•') })}>
              <Text color="grey.dark">{line}</Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Question.propTypes = {
  answer: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
};

export default memo(FAQ);
