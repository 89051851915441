import _ from 'lodash';
import nullthrows from 'nullthrows';

export const OTHER_OPTION = { label: 'Other', value: 'other' };

type SimpleOption =
  | number
  | string
  | {
      _id?: string;
      description?: string;
      disabled?: boolean;
      group?: string[];
      id?: string;
      menuName?: string;
      name?: string;
    };

export type Option = SimpleOption | { name: string; options: SimpleOption[] };

type SimpleReactSelectOption = {
  children?: ReactSelectOption[];
  description?: string;
  disabled?: boolean;
  group?: string[];
  label: string;
  menuLabel?: string;
  value?: string | number;
};

export type ReactSelectOption = SimpleReactSelectOption & { options?: SimpleReactSelectOption[] };

export const getOptionId = (option?: Option): number | string | undefined => {
  if (!option) {
    return undefined;
  }
  if (typeof option === 'string' || typeof option === 'number') {
    return option;
  }
  if ('id' in option) {
    return option.id;
  }
  if ('_id' in option) {
    return option._id;
  }
  return JSON.stringify(option);
};

export const transformOption = (option: Option): ReactSelectOption => {
  let label;
  if (typeof option === 'string' || typeof option === 'number') {
    label = String(option);
  } else if (option.name) {
    label = option.name;
  } else if ('id' in option) {
    label = _.startCase(option.id);
  } else if ('_id' in option) {
    label = _.startCase(option._id);
  }

  const value = getOptionId(option);
  nullthrows(
    value || (typeof option !== 'string' && typeof option !== 'number' && 'options' in option)
  );

  if (typeof option === 'string' || typeof option === 'number') {
    return { disabled: false, label: String(label), value };
  }

  return {
    description: 'description' in option ? option.description : undefined,
    disabled: 'disabled' in option ? Boolean(option.disabled) : false,
    group: 'group' in option ? option.group : undefined,
    label: String(label),
    menuLabel: 'menuName' in option ? option.menuName : undefined,
    value,
  };
};

export const isOther = (value: unknown): boolean => {
  return typeof value === 'string' && value.trim().toUpperCase() === 'OTHER';
};

type GetFormattedOptionsProps = {
  capitalize?: boolean;
  freeformOther?: boolean;
  injectOtherOption?: boolean;
  grouped?: boolean;
  multiple?: boolean;
  options: Option[];
  sort?: boolean;
  value?: unknown;
};

// TODO move into util with unit test
export const getFormattedOptions = ({
  capitalize,
  freeformOther,
  injectOtherOption,
  grouped,
  multiple,
  options,
  sort,
  value,
}: GetFormattedOptionsProps): ReactSelectOption[] => {
  let formattedOptions = _.map(options, (option: Option) => {
    const out = transformOption(option);
    if (
      grouped &&
      typeof option !== 'string' &&
      typeof option !== 'number' &&
      'options' in option
    ) {
      out.options = option.options.map(transformOption);
    }
    return out;
  });
  if (capitalize) {
    formattedOptions = formattedOptions.map(option => ({
      ...option,
      label: _.startCase(option.label),
    }));
  }
  if (sort) {
    formattedOptions = _.sortBy(formattedOptions, 'label');
  }
  if (injectOtherOption && !multiple) {
    const isOtherInOptions = formattedOptions.some(option => isOther(option.value));
    if (!isOtherInOptions) {
      formattedOptions.push(OTHER_OPTION);
    }
  }

  if (!freeformOther) {
    //if current value does not exist in options array push it in
    if (typeof value === 'string' && value && !_.find(formattedOptions, { value })) {
      formattedOptions.push({ label: value, value: value });
    }
  }
  return formattedOptions;
};

type GetSelectedOptionProps = {
  formattedOptions: ReactSelectOption[];
  isMatch?: ({ option, value }: { option: Option; value?: unknown | unknown[] }) => boolean;
  multiple?: boolean;
  object?: boolean;
  options: Option[];
  value?: unknown | unknown[];
};

export const getSelectedOption = ({
  formattedOptions,
  isMatch,
  multiple,
  object,
  options,
  value,
}: GetSelectedOptionProps): ReactSelectOption | ReactSelectOption[] | undefined => {
  if (isMatch) {
    const match = options.find(option => isMatch({ option, value }));
    if (match) {
      return transformOption(match);
    }
  }
  if (object) {
    return multiple
      ? formattedOptions.filter(option =>
          _.includes(
            _.map(value as Option[], value => getOptionId(value)),
            option.value
          )
        )
      : formattedOptions.find(option => getOptionId(value as Option) === option.value);
  }
  return multiple
    ? formattedOptions.filter(option => _.includes(value as string[], option.value))
    : formattedOptions.find(option => (value as string) === option.value);
};
