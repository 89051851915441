import React, { memo, useEffect,useState } from 'react';
import { Modal,Text } from '@/components';
import { companyName } from '@/config/const';

function Mission() {
  const [modalOpen, setModalOpen] = useState(false);
  const [youTubeAPIReady, setYouTubeAPIReady] = useState(false);

  useEffect(() => {
    // Reference: https://developers.google.com/youtube/iframe_api_reference
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => setYouTubeAPIReady(true);
  }, []);

  useEffect(() => {
    if (modalOpen && youTubeAPIReady) {
      const player = new window.YT.Player('player', {
        height: '360',
        width: '640',
        videoId: 'y2DzF_kQlfM',
        events: {
          onReady: event => {
            player.unMute();
            event.target.playVideo();
          },
        },
      });
    }
  }, [modalOpen, youTubeAPIReady]);

  return (
    <div className="apply-mission">
      <div className="bg-background-light p-4">
        <div className="apply-container">
          <button
            type="button"
            onClick={() => setModalOpen(true)}
            className="apply-mission__container relative m-auto flex w-full bg-cover bg-center"
          >
            <div className="absolute bottom-0 right-0 m-6 grid grid-flow-col items-center gap-3.5 md:right-auto md:m-12">
              <img src="/svg/play-video.svg" alt="play video" />
              <Text type="headline-6" color="white" className="text-left leading-6">
                Why Join {companyName}
              </Text>
            </div>
          </button>
        </div>
      </div>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} width={1200}>
        <Modal.Header />

        <Modal.Body>
          <div className="bg-white p-6">
            <div className="apply-mission__video relative flex">
              <div id="player" className="absolute h-full w-full" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(Mission);
