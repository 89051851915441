/* eslint-disable no-async-promise-executor */
import '../service_style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import { loggedOutEvent } from '@/com/analytics';
import { stripeKey } from '@/config/const';
import Address from './Address';
import AppointmentSummary from './AppointmentSummary';
import Availability from './Availability';
import Card from './Card-V2';
import HeaderV2 from './com/Header-V2';
import ConfirmationV2 from './Confirmation-V2';
import ContactV2 from './Contact-V2';
import DetailsV2 from './Details-V2';
import Model from './Model';
import NoAvailabilityV2 from './NoAvailability-V2';
import ServiceV2 from './Service-V2';
import { BookingState, StepName } from './util';

// https://stripe.com/docs/stripe-js/react
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey);

type Props = {
  onChange: (key: string, value: unknown) => void;
  state: BookingState;
};

export default function Steps({ onChange, state }: Props): JSX.Element {
  const steps = useRef([
    'Service',
    'Details',
    'Model',
    'Availability',
    'Contact',
    'Address',
    'Card',
    'Confirmation',
  ] as StepName[]).current;

  const stepRef = useRef(state.step);

  const getStepName = useCallback(
    (step: number): StepName => {
      if (state.noAvailability) {
        return 'NoAvailability';
      }
      return steps[step];
    },
    [steps, state.noAvailability]
  );

  const serviceName = state.services.find(service => service.id === state.serviceId)?.name;

  useEffect(() => {
    const data = { service_name: serviceName, zip: state.zip?.value };
    // only advancing to a new step for the first time should log the previous step as completed
    if (state.step > stepRef.current) {
      const stepName = getStepName(stepRef.current);
      loggedOutEvent().track('v1_b2cBookingFlowStepCompleted', {
        stepName,
        ...data,
      });
      // for b2cddCreditCardV3 the funnel effectively completes after the card step, but in order
      // to A/B test we need to log the Terms step otherwise conversion will always show as 0
      if (stepName === 'Card') {
        loggedOutEvent().track('v1_b2cBookingFlowStepCompleted', {
          stepName: 'Terms',
          ...data,
        });
      }
      // for b2cApplianceDetailsV3 the service step combines the zip step
      if (stepName === 'Service') {
        loggedOutEvent().track('v1_b2cBookingFlowStepCompleted', {
          stepName: 'Zip',
          ...data,
        });
      }
      stepRef.current = state.step;
    }
  }, [getStepName, state.zip, serviceName, state.step]);

  // eslint-disable-next-line @typescript-eslint/require-await
  const onNext = useCallback(async () => {
    onChange('step', state.step + 1);
  }, [onChange, state.step]);

  const renderBody = () => {
    const stepComponents = {
      Service: ServiceV2,
      Availability,
      Details: DetailsV2,
      Contact: ContactV2,
      Confirmation: ConfirmationV2,
      Model,
      NoAvailability: NoAvailabilityV2,
      Card,
      Address,
    };

    return React.createElement(stepComponents[getStepName(state.step)], {
      onChange,
      state,
      onNext,
    });
  };

  const { step } = state;
  const stepName = getStepName(step);

  const [prevStepName, setPrevStepName] = useState(stepName);
  const [stateSummary, setStateSummary] = useState(_.cloneDeep(state));

  useEffect(() => {
    if (prevStepName !== stepName) {
      setPrevStepName(stepName);
      setStateSummary(_.cloneDeep(state));
    }
  }, [prevStepName, stepName, state]);

  return (
    <Elements stripe={stripePromise}>
      <section>
        <div>
          <section>
            <section className="step-body mx-auto max-w-[1100px]">
              <HeaderV2
                step={step}
                steps={steps}
                onChange={onChange}
                state={state}
                stepName={stepName}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24">
                <section className="step-container animated fadeIn mt-6 max-w-[576px] px-5 md:px-0">
                  {renderBody()}
                </section>
                <aside
                  className={`${stepName !== 'Confirmation' ? 'hidden' : ''} step-container mt-8 max-w-[380px] px-5 md:mt-6 md:block md:px-0`}
                >
                  <AppointmentSummary state={stateSummary} stepName={stepName} />
                </aside>
              </div>
            </section>
          </section>
        </div>
      </section>
    </Elements>
  );
}
