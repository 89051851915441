/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
import React from 'react';
import { isMobile } from 'react-device-detect';
import onEnter from 'react-onenterkeydown';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import store from 'store2';
import { forwardToZendesk } from '@/com/user/zendeskUtil';
import { Icon } from '@/components';
import { generateProAppDeepLink, getParams } from '../../../com/util';
import { apiOrigin, companyName } from '../../../config/const';
import { socialLogin } from './util';

const EnhancedInput = onEnter('input');

class Step1 extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      value: p.getStore().email,
      login: global.location.pathname.startsWith('/login'),
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
    this.afterSocialLogin();
  }

  componentDidMount() {
    const { sso } = getParams();

    // a user with a mobile device is attempting to login to a different product using his Pro App credentials
    if (isMobile && sso === 'zendesk') {
      global.location = generateProAppDeepLink({ sso });
    }
  }

  async afterSocialLogin() {
    let token;
    if (_.startsWith(global.location.search, '?nanaToken')) {
      token = global.location.search.slice(11);
    }
    if (!token) {
      return;
    }
    const r = await fetch(`${apiOrigin}/api/users/me?includeRoles=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then(r => r.json());
    if (r.errMsg === 'jwt malformed') {
      return;
    }
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    if (!r.user) {
      return;
    }
    let changes = [`users.${r.user.id}`, r.user];
    const me = {
      roles: r.user.roles,
      token,
      userId: r.user.id,
    };
    if (_.get(r.roles, 'serviceprovider')) {
      me.proId = r.roles.serviceprovider.id;
      changes = [...changes, `pros.${r.roles.serviceprovider.id}`, r.roles.serviceprovider];
    }
    changes = [...changes, 'me', me];
    U.redux.set(changes);
    const returnTo = store.get('login.returnTo');
    if (returnTo) {
      void forwardToZendesk({
        token: r.token,
        returnTo,
        setError: value => this.setState({ error: value }),
      });
    } else {
      const redirect = store.get('login.redirect') || 'workorders';
      store.remove('login.redirect');
      try {
        global.store.dispatch(push(`/${redirect}`));
      } catch (e) {}
    }
  }

  onChange(e) {
    this.setState({ value: e.target.value.toLowerCase() });
  }

  async onSubmit() {
    if (!this.state.value) {
      return this.setState({ error: 'Email or phone required' });
    }
    const type = _.includes(this.state.value, '@') ? 'email' : 'phone';
    const value = type === 'phone' ? this.state.value.replace(/\D/, '') : this.state.value;
    this.props.updateStore({ [type]: this.state.value, type });
    const r = await U.api('get', `users/me/${type}?${type}=${encodeURIComponent(value)}`);
    if (_.isEmpty(r) || r.errMsg) {
      this.props.updateStore({ flow: 'create account' });
      return this.props.jumpToStep(2);
    }
    if (_.get(r.auth, 'hasPwd')) {
      this.props.updateStore({ flow: 'login' });
      this.props.jumpToStep(1);
    } else {
      this.props.updateStore({ flow: 'create password' });
      this.props.jumpToStep(9);
    }
  }

  toggleLogin() {
    this.setState({ login: !this.state.login });
  }

  render() {
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 style={{ fontWeight: 500, color: '##001837' }}>
              {this.state.login ? 'Welcome Home' : 'Create an Account'}
            </h3>
            {this.props.hasFakeEmail && <div className="text-danger">Login with phone number</div>}

            <div className="login--form">
              <EnhancedInput
                value={this.state.value}
                onChange={this.onChange}
                onEnterKeyDown={this.onSubmit}
                className="user-input mt-4"
                autoFocus={window.innerWidth >= 576}
                placeholder={this.props.hasFakeEmail ? 'Enter phone' : 'Enter email or phone'}
              />
            </div>
            <button onClick={this.onSubmit} className="primary mt-3">
              <b>Continue</b>
            </button>
            {this.state.error && (
              <p className="fadeInAnim py-3" style={{ color: '#f75c43' }}>
                {this.state.error}
              </p>
            )}
            {!this.props.hasFakeEmail && (
              <div>
                <p className="text-muted mt-2">or</p>
                <button
                  onClick={() => socialLogin('google')}
                  className="d-flex align-items-center justify-content-between google mt-2"
                >
                  <Icon color="#c63d2d" name="google" size={25} />
                  <span className="weight-600 flex-1 text-center">
                    <b>Continue with Google</b>
                  </span>
                  <Icon color="#c63d2d" name="google" size={25} style={{ opacity: 0 }} />
                </button>
              </div>
            )}

            <label className="text-muted mt-3">
              By continuing, I accept the {companyName}{' '}
              <a href="/terms" className="text-blue">
                terms of service
              </a>{' '}
              and{' '}
              <a href="/privacy" className="text-blue">
                privacy policy.
              </a>
            </label>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(s => s.form)(Step1);
