import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import arrayMove from 'array-move';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { useDeepCompareMemoRef } from '@/hooks';
import { Button, Icon, Loader, Text, Tooltip } from '../core';
import { Input } from '../form';
import { Props as SearchProps } from '../form/Search';
import { DatabaseQuery } from './types';

type SortableItemProps = {
  module: Partial<Table.Column>;
  onClearSearch: (module: Partial<Table.Column>) => void;
  onClearSort: () => void;
  onRemove: (moduleId: string) => void;
  sort?: Table.Query['sort'];
};

const SortableItem = SortableElement<SortableItemProps>(
  ({ module, onClearSearch, onClearSort, onRemove, sort }: SortableItemProps): JSX.Element => (
    <li className="flex items-center justify-between p-2 bg-white cursor-move border-bottom border-grey-dark">
      <Icon name="drag_indicator" color="grey.dark" className="mr-4" />
      <div className="flex-1">
        <div className="flex">
          <Tooltip node={module.description}>
            <Text>{module.name}</Text>
          </Tooltip>
        </div>
        {sort?.key === module.key && (
          <Text color="grey.dark">
            sort: <span>{sort?.isDescending ? 'descending' : 'ascending'}</span>
          </Text>
        )}
        {module.search?.text && <Text color="grey.dark">search: {module.search.text}</Text>}
        {_.isString(module.search?.type) && (
          <Text color="grey.dark">search type: {module.search?.type}</Text>
        )}
      </div>
      {module.search && (
        <Button
          className="mr-4"
          onClick={() => onClearSearch(module)}
          variant="secondary"
          size="small"
        >
          Clear Search
        </Button>
      )}
      {sort?.key === module.key && (
        <Button className="mr-4" onClick={onClearSort} variant="secondary" size="small">
          Clear Sort
        </Button>
      )}
      <Icon name="close" color="grey.dark" onClick={() => onRemove(nullthrows(module.key))} />
    </li>
  )
);

type SortableListProps = {
  modules: Partial<Table.Column>[];
  onClearSearch: SortableItemProps['onClearSearch'];
  onClearSort: SortableItemProps['onClearSort'];
  onRemove: SortableItemProps['onRemove'];
  sort?: SortableItemProps['sort'];
};

const SortableList = SortableContainer<SortableListProps>(
  ({ modules, onClearSearch, onClearSort, onRemove, sort }: SortableListProps): JSX.Element => (
    <ul className="no-list">
      {modules.map((module, index) => (
        <SortableItem
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          module={module}
          sort={sort}
          onClearSearch={onClearSearch}
          onClearSort={onClearSort}
          onRemove={onRemove}
        />
      ))}
    </ul>
  )
);

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  multilevelPlacement?: SearchProps['multilevelPlacement'];
  multilevelWidth?: SearchProps['multilevelWidth'];
  onChange: (value: React.SetStateAction<DatabaseQuery>) => void;
  query: DatabaseQuery;
};

export default function EditColumns({
  databaseIdToTableIdToColumnIdToColumn,
  multilevelPlacement,
  multilevelWidth,
  onChange,
  query,
}: Props): JSX.Element {
  const [databaseId, setDatabaseId] = useState(query.databaseId);
  const [databaseIdToTableIds, setDatabaseIdToTableIds] = useState<Table.Depth1<string[]>>(
    _.mapValues(databaseIdToTableIdToColumnIdToColumn, tableIdToColumnIdToColumn =>
      _.keys(tableIdToColumnIdToColumn)
    )
  );
  const databaseIdToTableIdsRef = useDeepCompareMemoRef(databaseIdToTableIds);
  const [isLoading, setIsLoading] = useState(false);
  const [tableId, setTableId] = useState(query.table);

  const handleChangeDatabaseId = async (databaseId: Table.DatabaseId) => {
    if (!databaseIdToTableIdsRef.current[databaseId]) {
      setIsLoading(true);
      const tables = await U.api<
        { database: Table.DatabaseId; isTemporary: boolean; tableName: string }[]
      >('get', `database/${databaseId}/tables`);

      if (Array.isArray(tables)) {
        setDatabaseIdToTableIds({
          ...databaseIdToTableIdsRef.current,
          [databaseId]: _.map(tables, 'tableName'),
        });
      }

      setIsLoading(false);
    }

    setDatabaseId(databaseId);
    setTableId(databaseId === Table.DatabaseId.DEFAULT ? 'workOrder' : 'work_orders');
  };

  const onAddAllModules = () => {
    onChange({
      ...query,
      columns: _.map(
        databaseIdToTableIdToColumnIdToColumn[Table.DatabaseId.DEFAULT].modules,
        module => ({ key: module.id })
      ).filter(({ key }) => !key.endsWith('Rate')) as Table.Column[],
    });
  };

  /** add a module to the table */
  const onAddModule = (key: string) => {
    if (!key) {
      return;
    }
    onChange(query => ({
      ...query,
      columns: [
        { databaseId: databaseId, id: ObjectId().toHexString(), key, table: tableId },
        ...(query.columns || []),
      ],
    }));
  };

  /** remove search from a module */
  const onClearSearch = (module: Partial<Table.Column>) => {
    const modules = query.columns?.map(lModule =>
      lModule.key === module.key ? _.omit(lModule, 'search') : lModule
    ) as Table.Column[];
    onChange(query => ({ ...query, columns: modules }));
  };

  /** remove ascending / descending sort from a table module */
  const onClearSort = () => onChange({ ...query, sort: {} });

  /** remove a module from the table */
  const onRemoveModule = (id: string) => {
    const module = query.columns?.filter(module => module.key !== id);
    onChange(query => ({ ...query, columns: module }));
  };

  /** reorder modules */
  const onSortEnd = ({ oldIndex, newIndex }: { newIndex: number; oldIndex: number }) => {
    const modules = arrayMove(query.columns || [], oldIndex, newIndex);
    onChange(query => ({ ...query, columns: modules }));
  };

  const columns = query.columns
    .filter(column => column.key)
    .map(column => {
      const name =
        databaseIdToTableIdToColumnIdToColumn[column.databaseId][column.table][column.key]?.name;
      return {
        ...column,
        description:
          databaseIdToTableIdToColumnIdToColumn[column.databaseId][column.table][column.key]
            ?.description,
        name,
      };
    });

  return (
    <div>
      {isLoading && <Loader isLoading />}
      <div className="grid grid-cols-2 gap-x-4">
        <Input
          label="Database"
          onChange={value => handleChangeDatabaseId(value as Table.DatabaseId)}
          options={Table.databaseOptions}
          value={databaseId}
        />
        <Input
          capitalize
          label="Table"
          onChange={value => setTableId(value as string)}
          options={databaseIdToTableIds[databaseId]}
          sort
          value={tableId}
        />
      </div>
      <Input
        capitalize
        cypressId="add-module"
        label="Add Column"
        labelRightUI={
          <Text color="primaryCTA" onClick={onAddAllModules}>
            Add All
          </Text>
        }
        multilevel
        multilevelPlacement={multilevelPlacement}
        multilevelWidth={multilevelWidth}
        onChange={moduleId => onAddModule(moduleId as string)}
        options={databaseIdToTableIdToColumnIdToColumn[databaseId][tableId]}
        sort
      />
      <SortableList
        distance={1}
        modules={columns}
        onClearSearch={onClearSearch}
        onClearSort={onClearSort}
        onRemove={onRemoveModule}
        onSortEnd={onSortEnd}
        sort={query.sort}
      />
    </div>
  );
}
