import React from 'react';
import _ from 'lodash';
import type { StepProps as Props } from './ConfirmationFlowModal';
import ProfileAddressStep from './ProfileAddressStep';
import ProfileCardStep from './ProfileCardStep';
import ProfileContactStep from './ProfileContactStep';
import StepModalHeader from './StepModalHeader';

const componentBySubStepNum = {
  1: ProfileContactStep,
  2: ProfileAddressStep,
  3: ProfileCardStep,
};

export default function ProfileStep({
  currentStep,
  task,
  subStepNum,
  onCloseStep,
  onNextSubStep,
  onPreviousSubStep,
}: Props): JSX.Element {
  const title = 'Confirm Your Profile';

  const Component =
    componentBySubStepNum[subStepNum as unknown as keyof typeof componentBySubStepNum];

  return (
    <>
      <StepModalHeader {...{ onPreviousSubStep, title, subStepNum, onCloseStep, currentStep }} />
      <Component {...{ onNextSubStep, task }} />
    </>
  );
}
