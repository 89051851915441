import React from 'react';
import Textarea from 'react-textarea-autosize';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

class Textbox extends React.Component {
  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => this.textarea.focus(), 0);
    }
  }

  render() {
    const p = this.props;
    const { t } = this.context;
    return (
      <Group {...p}>
        <Textarea
          disabled={p.disabled}
          className="form-control"
          style={{ backgroundColor: p.disabled ? '#eee' : 'white' }}
          placeholder={p.hint}
          value={_.get(t.state, p.id, '') || ''}
          onChange={e => {
            onChange(t, p.id, e.target.value);
            if (p.onChange) {
              p.onChange(e.target.value);
            }
          }}
          onKeyPress={e =>
            p.enter && (e.keyCode || e.which === 13) && p.enter(_.get(t.state, p.id, ''))
          }
          ref={textarea => {
            this.textarea = textarea;
          }}
          minRows={4}
        />
      </Group>
    );
  }
}

Textbox.contextTypes = { t: T.object.isRequired };

Textbox.propTypes = {
  disabled: T.bool,
  id: T.string.isRequired,
  label: T.string,
  type: T.string,
  enter: T.func,
};

export default Textbox;
