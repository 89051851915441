import React, { useState } from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import nullthrows from 'nullthrows';
import Button from '@/components/core/Button';
import Form, { Ref } from './Form';
import Input, { Type } from './Input';

const ref = React.createRef<Ref>();

const handleClick = () => {
  const success = nullthrows(ref.current).submit();
  alert(success ? 'success' : 'failed validation');
};

export default {
  title: 'Form/Form',
  component: Form,
  decorators: [
    Story => (
      <div className="flex">
        <div>
          <Story />
          <Button onClick={handleClick} size="medium">
            Submit
          </Button>
        </div>
      </div>
    ),
  ],
} as ComponentMeta<typeof Form>;

const Template: ComponentStory<typeof Form> = ({ originalValue, ...args }) => {
  const [value, setValue] = useState(originalValue);
  return <Form {...args} onChange={setValue} value={value} originalValue={originalValue} />;
};

export const Default = Template.bind({});
Default.args = {};

export const SubmitRef = Template.bind({});
SubmitRef.args = {
  children: (
    <>
      <Input id="name" label="Name" type={Type.TEXT} required />
      <Input id="email" label="Email" type={Type.EMAIL} required />
      <Input id="phone" label="Phone" type={Type.PHONE} required />
    </>
  ),
  originalValue: { email: '', name: '', phone: '' },
  ref,
};

export const LeftIconVariants = Template.bind({});
LeftIconVariants.args = {
  children: (
    <>
      <Input
        id="name"
        label="Google"
        placeholder="Placeholder Text"
        type={Type.TEXT}
        leftIcon="google"
      />
      <Input
        id="email"
        label="Account Circle"
        placeholder="Placeholder Text"
        type={Type.EMAIL}
        leftIcon="account_circle"
      />
      <Input id="none" label="None" placeholder="Placeholder Text" type={Type.PHONE} />
      <Input
        id="error"
        label="Error"
        placeholder="Placeholder Text"
        type={Type.TEXT}
        error="Error Text"
      />
    </>
  ),
  originalValue: { email: '', name: '', phone: '' },
  ref,
};

const searchOptions = [
  { id: 'option1', name: 'Option 1' },
  { id: 'option2', name: 'Option 2' },
  { id: 'option3', name: 'Option 3' },
  { id: 'option4', name: 'Option 4' },
  { id: 'option5', name: 'Option 5' },
];

export const Search = Template.bind({});
Search.args = {
  children: (
    <div className="w-[300px]">
      <Input
        id="name"
        label="Search Test"
        options={searchOptions}
        placeholder="Placeholder Text"
        type={Type.SEARCH}
      />
      <Input readOnly id="name" label="Selected Value" type={Type.TEXT} />
    </div>
  ),
  originalValue: { name: undefined },
  ref,
};

export const SearchWithOther = Template.bind({});
SearchWithOther.args = {
  children: (
    <div className="w-[300px]">
      <Input
        freeformOther
        id="name"
        injectOtherOption
        label="Search Test With Other"
        options={searchOptions}
        placeholder="Placeholder Text"
        type={Type.SEARCH}
      />
      <Input readOnly id="name" label="Selected Value" type={Type.TEXT} />
    </div>
  ),
  originalValue: { name: undefined },
  ref,
};

export const TextWithCharacterCount = Template.bind({});
TextWithCharacterCount.args = {
  children: (
    <div className="w-[300px]">
      <Input
        id="name"
        label="Text With Character Count"
        multiline
        placeholder="Placeholder Text"
        showCharacterCount
        type={Type.TEXT}
      />
    </div>
  ),
  originalValue: { name: undefined },
  ref,
};
