import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import m from 'moment';
import { Icon } from '@/components';
import { loadTasks } from '../../com/task';

class Referrals extends React.Component {
  componentWillMount() {
    loadTasks({ query: { 'customer.user.id': global.id() } });
  }

  render() {
    return (
      <table className="table-sm table-striped table-hover table">
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Time</th>
            <th>From Job</th>
            <th>To Job</th>
          </tr>
        </thead>
        <tbody>
          {this.props.referrals.map((r, n) => {
            const fromUser = r.isReferrer
              ? _.get(this.props.user, 'profile.fullName')
              : _.get(r, 'user.fullName');
            const fromUserId = r.isReferrer ? _.get(this.props.user, 'id') : _.get(r, 'user.id');
            const toUser = r.isReferrer
              ? _.get(r, 'user.fullName')
              : _.get(this.props.user, 'profile.fullName');
            const toUserId = r.isReferrer ? _.get(r, 'user.id') : _.get(this.props.user, 'id');
            const fromJob = r.isReferrer ? r.myTaskId : r.theirTaskId;
            const toJob = r.isReferrer ? r.theirTaskId : r.myTaskId;
            return (
              <tr key={n}>
                <td>
                  <div className="flex">
                    <a
                      href={`/users/${fromUserId}`}
                      className="link-blue mr-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="open_in_new" size={16} />
                    </a>
                    <span>{fromUser}</span>
                  </div>
                </td>
                <td>
                  <div className="flex">
                    <a
                      href={`/users/${toUserId}`}
                      className="link-blue mr-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="open_in_new" size={16} />
                    </a>
                    <span>{toUser}</span>
                  </div>
                </td>
                <td>{m(r.time).format('M/D/YY h:mm a')}</td>
                <td>
                  {fromJob && (
                    <div className="flex">
                      <a
                        href={`/tasks/${fromJob}`}
                        className="link-blue"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="open_in_new" size={16} />
                      </a>
                    </div>
                  )}
                </td>
                <td>
                  {toJob && (
                    <div className="flex">
                      <a
                        href={`/tasks/${toJob}`}
                        className="link-blue"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="open_in_new" size={16} />
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default connect(s => {
  const user = s.users[global.id()];
  let referrals = _.values(_.get(user, 'metadata.referrals'));
  referrals = _.sortBy(referrals, 'time').reverse();
  return { referrals, user };
})(Referrals);
