import * as Table from '@nanaio/table';
import _ from 'lodash';

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  directionToVectors: Record<Table.Direction, Table.Vector[]>;
  id: string;
  isModuleChange: boolean;
  newQuery: Table.Query;
  oldQuery: Table.Query;
  vectorPath: string;
};

export default function updateSort({
  databaseIdToTableIdToColumnIdToColumn,
  directionToVectors,
  id,
  isModuleChange,
  newQuery,
  oldQuery,
  vectorPath,
}: Props): void {
  if (isModuleChange || _.endsWith(id, 'intervalLength') || _.endsWith(id, 'operations')) {
    const oldVector = _.get(oldQuery, vectorPath) as Table.Vector | undefined;
    const newVector = _.get(newQuery, vectorPath) as Table.Vector;
    if (newVector.moduleId) {
      const oldColumn = oldVector
        ? databaseIdToTableIdToColumnIdToColumn[newQuery.databaseId][newQuery.table][
            oldVector.moduleId as string
          ]
        : undefined;
      const newColumn =
        databaseIdToTableIdToColumnIdToColumn[newQuery.databaseId][newQuery.table][
          newVector.moduleId
        ];
      const oldIsDimension =
        oldColumn &&
        oldVector &&
        Table.vectorIsDimension({
          column: oldColumn,
          databaseIdToTableIdToColumnIdToColumn,
          query: newQuery,
          vector: oldVector,
        });
      const newIsDimension = Table.vectorIsDimension({
        column: newColumn,
        databaseIdToTableIdToColumnIdToColumn,
        query: newQuery,
        vector: newVector,
      });
      const sortByVector =
        directionToVectors[
          newVector.direction === Table.Direction.COLUMN
            ? Table.Direction.ROW
            : Table.Direction.COLUMN
        ][0];
      if (!oldIsDimension && newIsDimension) {
        const nestedVectors = Table.getNestedVectors(
          Table.getValidVectors({ databaseIdToTableIdToColumnIdToColumn, query: newQuery })
        ).find(nestedVectors => nestedVectors[0].id === sortByVector.id);
        let sort: Table.Sort = {
          isDescending: true,
          tableVector: nestedVectors?.map(vector => ({ id: vector.id })) || [{ id: 'total' }],
          type: Table.SortType.POINTS,
        };
        if (_.endsWith(id, 'intervalLength')) {
          sort = {
            isDescending: false,
            tableVector: [{ id: newVector.id }],
            type: Table.SortType.VALUE,
          };
        } else if (newColumn.type === Table.ColumnType.TIME) {
          sort = {
            isDescending: false,
            tableVector: [{ id: newVector.id }],
            type: Table.SortType.TIME,
          };
        }
        _.set(newQuery, `${vectorPath}.sort`, sort);
      } else if (oldIsDimension && !newIsDimension) {
        _.set(newQuery, `${vectorPath}.sort`, undefined);
      }
    }
  }

  const oldNestedVectors = Table.getNestedVectors(
    Table.getValidVectors({ databaseIdToTableIdToColumnIdToColumn, query: oldQuery })
  );
  const newNestedVectors = Table.getNestedVectors(
    Table.getValidVectors({ databaseIdToTableIdToColumnIdToColumn, query: newQuery })
  );
  for (let i = 0; i < Number(_.max([oldNestedVectors.length, newNestedVectors.length])); i++) {
    const oldVectorIds = _.map(oldNestedVectors[i], 'id');
    const newVectorIds = _.map(newNestedVectors[i], 'id');
    if (!_.isEqual(oldVectorIds, newVectorIds)) {
      for (const vector of newQuery.analytics.vectors) {
        const sortVectorIds = _.map(vector.sort?.tableVector, 'id');
        if (_.isEqual(oldVectorIds, sortVectorIds) && vector.sort?.tableVector) {
          vector.sort.tableVector = _.times(newVectorIds.length, i => ({
            ...(vector.sort?.tableVector?.[i] || {}),
            id: newVectorIds[i],
          }));
        }
      }
    }
  }
}
