/* eslint-disable array-callback-return */
import { T } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';

export default ({ groups, items, me, vendorFilter }) => {
  if (vendorFilter.length) {
    items = items.filter(i => vendorFilter.includes(T.orgId(i.job)));
  }
  const groupRowIndex = {};
  groups.map((g, n) => {
    groupRowIndex[g.id] = n;
  });
  const hourColMap = { 8: 0, 12: 1, 16: 2 };
  let rows = groups.map(g => ({
    ...g,
    proId: g.id,
    name: g.name,
    cells: [[], [], []],
  }));
  items.map(i => {
    const rowIndex = groupRowIndex[i.group];
    const hour = mt(i.startTime).tz(i.timeZoneId).hour();
    const colIndex = hourColMap[hour] || 0;
    if (!rows[rowIndex]) {
      return;
    }
    if (!rows[rowIndex].cells[colIndex]) {
      return false;
    }
    rows[rowIndex].cells[colIndex].push(i);
    rows[rowIndex].hasJobs = true;
  });
  const hasJobsRows = [];
  let noJobsRows = [];
  let pending;
  let cancelled;
  let suggested;
  rows = _.sortBy(rows, 'name');
  rows.map(r => {
    if (r.name === 'Pro Suggested') {
      suggested = r;
    } else if (r.name === 'Pending') {
      pending = r;
    } else if (r.name === 'Cancelled') {
      cancelled = r;
    } else if (r.hasJobs) {
      hasJobsRows.push(r);
    } else if (r.status === 'active') {
      noJobsRows.push(r);
    }
  });
  rows = rows.map(r => {
    r.cells = r.cells.map(v => _.sortBy(v, 'eta'));
    return r;
  });
  const counties = _.get(me, 'metadata.counties', []);
  if (counties.length) {
    noJobsRows = noJobsRows.filter(v => _.includes(counties, _.get(v, 'user.address.county')));
  }
  const x = [pending, suggested, ...hasJobsRows, cancelled, ...noJobsRows];
  return x;
};
