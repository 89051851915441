import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Alert, Button, Icon, Input, Text } from '@/components';
import { supportArticleUrls } from '@/config/const';
import Footer from './com/Footer';
import { BookingState } from './util';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Model({ onChange, onNext, state, ...props }: Props): JSX.Element {
  const brand = state.metadata.taskQuestions.Make;
  const value = state.metadata.taskQuestions.Model;
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <section>
      <Text type="headline-6">Next, Let’s Speed Up Your Repair</Text>
      <Text className="mt-2">
        The model number is easy to find and reduces the time it takes to fix your appliance.
      </Text>

      <Button
        className="mt-6 flex px-0 py-0"
        variant="link"
        href={supportArticleUrls.modelStickerHelp}
        target="_blank"
      >
        <Icon name="open_in_new" color="primaryCTA" />
        <Text className="ml-1 text-primaryCTA hover:text-primaryCTA-hover" type="button">
          Where can I find it?
        </Text>
      </Button>

      <Input
        leftIcon="barcode"
        id="metadata.taskQuestions.Model"
        label="Model Number"
        onChange={e => {
          onChange('metadata.taskQuestions.Model', e);
        }}
        value={value}
        placeholder="Enter here"
        className="mt-6"
        inputRef={ref => {
          inputRef.current = ref as HTMLInputElement;
        }}
      />

      <Text className="mt-6">
        Can't find the model number?{' '}
        <button
          className="font-semibold text-primaryCTA hover:text-primaryCTA-hover"
          onClick={onNext}
        >
          Skip this step
        </button>
      </Text>

      <Alert icon="clipboard_pulse_outline" className="mt-7">
        <Text className="text-secondary-5">
          <strong className="font-semibold">
            Your {brand} specialist relies on the model number
          </strong>{' '}
          to run diagnostics, find parts and complete the repair.
        </Text>
      </Alert>

      <Footer {...props} onNext={onNext} disabled={!value} />
    </section>
  );
}
