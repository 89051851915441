import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import CheckList from './CheckList';

const allPending = [
  {
    checked: false,
    subtext: '0 of 3 done',
    title: 'Verify washer details',
  },
  {
    checked: false,
    subtext: '0 of 3 done',
    title: 'Verify dryer details',
  },
  { checked: false, subtext: '0 of 3 done', title: 'Complete your profile' },
  {
    checked: false,
    subtext: '0 of 1 done',
    title: 'Request an appointment',
  },
];

const partiallyComplete = [
  {
    checked: true,
    subtext: 'Complete',
    title: 'Verify washer details',
  },
  {
    checked: true,
    subtext: 'Complete',
    title: 'Verify dryer details',
  },
  { checked: false, subtext: '1 of 3 done', title: 'Complete your profile' },
  {
    checked: false,
    subtext: '0 of 1 done',
    title: 'Request an appointment',
    disabled: true,
  },
];

export default {
  title: 'Core/CheckList',
  component: CheckList,
  args: {
    items: allPending,
    onClick: index => alert(`clicked ${index}!`),
  },
} as ComponentMeta<typeof CheckList>;

const Template: ComponentStory<typeof CheckList> = args => <CheckList {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const PartiallyComplete = Template.bind({});
PartiallyComplete.args = {
  items: partiallyComplete,
};
