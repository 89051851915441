import React, { useState } from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Text from '../../core/Text';
import theme from '../../theme';
import { capacityPropType, rowHeight } from '../util';

const styles = {
  hasAvailability: { backgroundColor: theme.hexToRGB(theme.colors.success, 0.05) },
};

export default function Slot({
  capacity,
  hasAvailability,
  hasBorder,
  isActive,
  isDisabled,
  slotIndex,
  startTime,
  title,
  updateSlot,
}) {
  const [isHover, setIsHover] = useState(false);

  const handleHover = isHover => !isDisabled && setIsHover(isHover);

  let className = `border-b-3 ${hasAvailability ? 'border-success' : 'border-transparent'}`;
  if (isDisabled) {
    className += ' opacity-50';
  } else if (!isActive) {
    className += ' cursor-pointer';
  }

  const roundTo = 0;

  let capacityText = [{ text: 'No Region' }];
  if (_.isNumber(_.get(capacity, 'visits'))) {
    capacityText = [{ text: `${_.round(capacity.visits, roundTo)} / ` }];
    if (_.isNumber(capacity.manualLimit) && _.isNumber(capacity.capacityLimit)) {
      capacityText.push(
        { text: `${_.round(capacity.manualLimit, roundTo)} ` },
        { text: _.round(capacity.capacityLimit, roundTo), className: 'line-through' }
      );
    } else if (_.isNumber(capacity.capacityLimit)) {
      capacityText.push({ text: _.round(capacity.capacityLimit, roundTo) });
    } else if (_.isNumber(capacity.manualLimit)) {
      capacityText.push({ text: _.round(capacity.manualLimit, roundTo) });
    } else {
      capacityText.push({ text: '∞' });
    }
    capacityText.push({ text: ' full' });
  }

  return (
    <div
      className={className}
      data-cy={`slot-${slotIndex}`}
      onClick={() => !isActive && !isDisabled && updateSlot('slot', startTime)}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      style={{ height: rowHeight, ...(hasAvailability ? styles.hasAvailability : {}) }}
    >
      <div
        className={hasBorder ? 'border-l border-r border-grey-medium' : ''}
        style={{ height: rowHeight, overflow: 'auto' }}
      >
        <div
          className={`align-items-center m-2 grid h-20 justify-items-center rounded ${
            isActive || isHover ? 'bg-primary' : ''
          } ${isHover && !isActive ? 'opacity-50' : ''}`}
          style={{ width: 100 }}
        >
          <div>
            <Text
              className={`text-center ${isActive || isHover ? 'text-white' : ''}`}
              type="button"
            >
              {title}
            </Text>
            {capacity && (
              <Text
                className={isActive || isHover ? 'text-white opacity-60' : 'text-grey-dark'}
                type="button"
                weight={500}
              >
                {capacityText.map(v => (
                  <span className={v.className} key={v.text}>
                    {v.text}
                  </span>
                ))}
              </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Slot.propTypes = {
  capacity: capacityPropType,
  hasAvailability: PropTypes.bool.isRequired,
  hasBorder: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  slotIndex: PropTypes.number.isRequired,
  startTime: U.timePropType,
  title: PropTypes.string.isRequired,
  updateSlot: PropTypes.func.isRequired,
};
