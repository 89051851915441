import React, { useEffect, useState } from 'react';
import { Tag, Task, TaskTag, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Modal, SearchInput, Text } from '@/components';
import { useLegacySelector } from '@/hooks';

export type Props = {
  taskId: string;
};

type TagOption = {
  id: string;
  name: string;
};

export default function Tags({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const orgs = useLegacySelector(state => state.orgs);
  const orgId = task.customer.org?.id || '';
  const org = orgs[orgId];

  const [isOpen, setIsOpen] = useState(false);
  const [originalTags, setOriginalTags] = useState<TaskTag[]>([]);
  const [tagOptions, setTagOptions] = useState<TagOption[]>([]);
  const [tags, setTags] = useState<TaskTag[]>(_.uniq(task.tags));

  const tagsString = JSON.stringify(task.tags);

  useEffect(() => {
    setTags(_.uniq(JSON.parse(tagsString) as TaskTag[]));
  }, [tagsString]);

  const handleOpen = () => {
    if (!tagOptions.length) {
      void U.api<Tag[]>('post', 'tags/search', { limit: -1 }).then(response => {
        if (response && !('errMsg' in response)) {
          const options = response.map(tag => ({ id: tag.name, name: tag.displayName }));
          for (const tag of task.tags) {
            if (!options.find(option => option.id === tag)) {
              options.push({ id: tag, name: _.startCase(tag) });
            }
          }
          setTagOptions(options);
        }
      });
    }
    setIsOpen(true);
    setOriginalTags(_.uniq(task.tags));
  };

  const handleSave = async () => {
    const originalTask = _.cloneDeep(task);
    void U.api<Task>('put', `tasks/${task.id}`, [{ path: 'tags', value: tags }], ['save']);
    setIsOpen(false);

    // save claim submitted tag to all jobs in the same work order
    if (_.get(org, 'billsByWorkOrder') && _.get(task, 'metadata.workOrderNumber')) {
      if (
        originalTask.tags.find(tag => tag === T.Tag.CLAIM_SUBMITTED) !==
        tags.find(tag => tag === T.Tag.CLAIM_SUBMITTED)
      ) {
        const query = {
          _id: { $ne: task.id },
          'metadata.workOrderNumber': task.metadata.workOrderNumber,
        };
        const tasks = await U.api<Task[]>('post', 'tasks/search', { query });
        if (tasks && !('errMsg' in tasks)) {
          tasks.forEach(task => {
            if (
              task.tags.find(tag => tag === T.Tag.CLAIM_SUBMITTED) !==
              tags.find(tag => tag === T.Tag.CLAIM_SUBMITTED)
            ) {
              const changes = [
                {
                  path: 'tags',
                  // @ts-expect-error U.setArrayToggle is not typed
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value: U.setArrayToggle(task, 'tags', T.Tag.CLAIM_SUBMITTED).tags,
                },
              ];
              void U.api<Task>('put', `tasks/${task.id}`, changes, ['save']);
            }
          });
        }
      }
    }
  };

  const tagToString = (tag: string) => {
    const name = tagOptions.find(option => option.id === tag)?.name || _.startCase(tag);
    const createTimes = _.map(
      _.filter(task.tagCreateTimes, tagCreateTime => tagCreateTime.tag === tag),
      'createTime'
    );
    if (createTimes.length) {
      const formattedCreateTimes = createTimes.map(createTime =>
        m(createTime).format('M/D/YY h:mma')
      );
      return `${name} ${formattedCreateTimes.join(', ')}`;
    }
    return name;
  };

  const removedTags = originalTags.filter(tag => !tags.includes(tag)).map(tagToString);

  const tagNames = tags.map(tagToString);

  return (
    <>
      <div className="flex cursor-pointer" onClick={handleOpen}>
        <Text className="mr-2 underline" color="primaryCTA" type="button">
          Tags:
        </Text>
        <div>
          <Text>{tagNames.filter((tag, i) => !(i % 2)).join(', ')}</Text>
          <Text>{tagNames.filter((tag, i) => i % 2).join(', ')}</Text>
        </div>
      </div>
      <Modal isOpen={isOpen && !!tagOptions.length} onClose={() => setIsOpen(false)}>
        <Modal.Header title="Edit Tags" />
        <Modal.Body className="p-4">
          <SearchInput
            multiple
            onChange={value => setTags(value as TaskTag[])}
            options={tagOptions}
            sort
            value={tags}
          />
          {!!removedTags.length && (
            <Text className="mt-4" color="danger">
              Removed Tags: {removedTags.join(', ')}
            </Text>
          )}
        </Modal.Body>
        <Modal.Footer askConfirmation={!!removedTags.length} onSave={handleSave} />
      </Modal>
    </>
  );
}
