import React from 'react';
import { connect } from 'react-redux';
import { api, user } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Select, Text } from '../../../com/ui/form';

const formatDate = function (d) {
  return !d ? '' : m(d).format('M/D/YY h:mm A');
};

/**
 * Page to view and create the mapping from vendor to customer type, for pricing purposes.
 * This is how we apply pricing rules vendor by vendor.
 * If a vendor does not have an explicit mapping here it will get mapped to our default customer type
 * and therefore not have any special pricing rules.
 */
class PricingVendors extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      customerTypes: [],
      existingVendors: [],
    };
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onNewType = this.onNewType.bind(this);
    this.onNewTypeCancel = this.onNewTypeCancel.bind(this);
    this.onSave = this.onSave.bind(this);

    api('get', 'pricing/vendor/existing').then(existingVendors => {
      const customerTypes = _.uniq(_.compact(_.map(existingVendors, 'customerType')));
      this.setState({ customerTypes, existingVendors });
    });
  }

  onEdit(i) {
    this.setState({ editing: i });
  }

  onCancel() {
    this.setState({ editing: null, customType: false, selectedType: null });
  }

  onNewType() {
    this.setState({ customType: true });
  }

  onNewTypeCancel() {
    this.setState({ customType: false, newVendor: {} });
  }

  async onSave() {
    if (!this.state.selectedType) {
      return this.setState({ error: 'No type selected.' });
    }
    const vendor = _.clone(this.state.existingVendors[this.state.editing]);
    const newVendorPricing = { orgId: vendor.orgId, name: vendor.name };
    newVendorPricing.customerType =
      this.state.selectedType === 'Self Typed' ? vendor.name : this.state.selectedType;

    const result = await api('post', 'pricing/vendor/create', newVendorPricing);
    if (!result || result.errMsg) {
      const reason = !result ? 'reason unknown' : result.errMsg;
      return this.setState({ error: `Vendor pricing creation failed: ${reason}` });
    }

    const existingVendors = await api('get', 'pricing/vendor/existing');
    const customerTypes = _.uniq(_.compact(_.map(existingVendors, 'customerType')));
    this.setState({
      customerTypes,
      existingVendors,
      editing: null,
      customType: false,
      selectedType: null,
      error: null,
    });
  }

  render() {
    return (
      <div>
        <div>
          <strong>Existing Vendors</strong>
        </div>
        <table className="table-striped table-sm table-hover table">
          <thead>
            <tr>
              <th>Vendor</th>
              <th>Customer Type</th>
              <th>Start Time</th>
              <th>End Time</th>
              {this.props.roles.admin && <th />}
            </tr>
          </thead>

          <tbody>
            {_.map(this.state.existingVendors, (vendor, i) => {
              if (this.state.editing === i) {
                return (
                  <tr key={i}>
                    <td>{vendor.name}</td>
                    <td>
                      <p className="text-danger">{this.state.error}</p>
                      {!this.state.customType && (
                        <div className="row">
                          <Select
                            id="selectedType"
                            options={['Self Typed', ...this.state.customerTypes]}
                            className="col-8"
                          />
                          <div className="col my-auto">
                            <div className="btn btn-primary" onClick={this.onNewType}>
                              New Type
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.customType && (
                        <div className="row">
                          <Text id="selectedType" label="New Customer Type" className="col-8" />
                          <div className="col my-auto">
                            <div className="btn btn-secondary ml-2" onClick={this.onNewTypeCancel}>
                              Cancel
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    <td>{formatDate(vendor.startTime)}</td>
                    <td>{formatDate(vendor.endTime)}</td>
                    <td>
                      <div className="btn btn-primary" onClick={this.onSave}>
                        Save
                      </div>
                      <div className="btn btn-secondary ml-2" onClick={this.onCancel}>
                        Cancel
                      </div>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={i}>
                  <td>{vendor.name}</td>
                  <td>{vendor.customerType || 'UNSET'}</td>
                  <td>{formatDate(vendor.startTime) || 'N/A'}</td>
                  <td>{vendor.startTime ? formatDate(vendor.endTime) : 'N/A'}</td>
                  {this.props.roles.admin && (
                    <td>
                      {!_.isInteger(this.state.editing) && (
                        <div className="btn btn-primary" onClick={() => this.onEdit(i)}>
                          Edit
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(s => ({ roles: user.roles(s) }))(PricingVendors);
