import './form.css';

import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

export default class Address extends React.Component {
  static contextTypes = { t: T.object };

  static propTypes = {
    id: T.string.isRequired,
    hint: T.string,
    label: T.string,
    city: T.bool,
    county: T.bool,
    change: T.func,
    r: T.any,
    noUnit: T.bool,
    disabled: T.bool,
  };

  state = { hold: false };

  componentDidMount() {
    const { id } = this.props;
    const init = () => {
      let option = {
        scrollwheel: false,
        zoom: 13,
        fields: ['address_components', 'formatted_address', 'geometry', 'name'],
      };
      if (this.props.city) {
        option = { ...option, componentRestrictions: { country: 'us' }, types: ['(cities)'] };
      } else if (this.props.county) {
        option = { ...option, componentRestrictions: { country: 'us' }, types: ['(regions)'] };
      }
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(`autocomplete-${id}`),
        option
      );
      this.autocomplete.addListener('place_changed', () => {
        let out = U.addressFromPlace(
          this.autocomplete.getPlace(),
          !!this.props.county // fall back to PST for counties
        );
        if (this.props.county) {
          out = _.pick(out, [
            'county',
            'formattedAddress',
            'geoCoordinates',
            'region',
            'gTimezone',
          ]);
        }
        if (_.get(this.context.t.state, `${this.props.id}.unit`)) {
          out.unit = _.get(this.context.t.state, `${this.props.id}.unit`);
        }

        onChange(this.context.t, id, out);
        if (this.props.onChange) {
          this.props.onChange(out);
        }
      });
    };
    if (window.google) {
      init();
    } else {
      const interval = setInterval(() => {
        if (window.google) {
          init();
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  onChange = e => {
    if (e.target.value === '') {
      onChange(this.context.t, this.props.id, undefined);
    } else {
      onChange(this.context.t, `${this.props.id}.formattedAddress`, e.target.value);
    }
  };

  onUnitChange = e => {
    if (this.props.onChange) {
      const value = _.merge({}, _.get(this.context.t.state, this.props.id));
      value.unit = e.target.value;
      this.props.onChange(value);
    }
    onChange(this.context.t, `${this.props.id}.unit`, e.target.value);
  };

  renderAddress = () => {
    const value = _.get(this.context.t.state, `${this.props.id}.formattedAddress`) || '';
    return (
      <input
        id={`autocomplete-${this.props.id}`}
        placeholder={this.props.hint}
        onFocus={this.geolocate}
        className="form-control"
        value={value}
        onChange={this.onChange}
        disabled={this.props.disabled}
      />
    );
  };

  renderUnit = () => {
    const value = _.get(this.context.t.state, `${this.props.id}.unit`) || '';
    return (
      <input
        placeholder="Unit"
        className="form-control px-1"
        value={value}
        onChange={this.onUnitChange}
      />
    );
  };

  render() {
    const noUnit = this.props.city || this.props.county || this.props.noUnit;
    return (
      <Group {...this.props} req={false}>
        {noUnit ? (
          this.renderAddress()
        ) : (
          <div className="address-input d-flex flex-1">
            <div className="address-input-street grow-1 mr-3">{this.renderAddress()}</div>
            <div style={{ width: '50px' }}>{this.renderUnit()}</div>
          </div>
        )}
      </Group>
    );
  }
}
