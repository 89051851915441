import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Modal, Money, Search, Select, Textbox } from '../../../com/ui/form';
import { getTagOptions, paymentMethodOptions } from './util';

export default class AddTransfer extends React.Component {
  static propTypes = {
    isOpen: T.bool,
    onSuccess: T.func.isRequired,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    U.api('post', 'pros/search', {
      query: { 'payment.account.stripe_user_id': { $exists: true } },
      limit: -1,
    }).then(pros => {
      const newPros = pros.map(pro => ({ ...pro, name: pro.user.fullName }));
      this.setState({ pros: _.sortBy(newPros, 'name') });
    });
  }

  submit = async () => {
    if (!_.get(this.state, 'transfer.user.proId')) {
      return this.setState({ error: 'User required' });
    }
    if (!_.isNumber(_.get(this.state, 'transfer.amount'))) {
      return this.setState({ error: 'Amount required' });
    }
    if (!_.get(this.state, 'transfer.description')) {
      return this.setState({ error: 'Description required' });
    }
    if (!_.get(this.state, 'transfer.paymentMethod')) {
      return this.setState({ error: 'Payment method required' });
    }
    const r = await U.api('post', 'transfers', this.state.transfer);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.setState({ transfer: {} });
    this.props.onSuccess(r);
  };

  render() {
    return (
      <Modal
        title="Add Deposit"
        isOpen={this.props.isOpen}
        submit={this.submit}
        onClose={this.props.toggleOpen}
        className="ui-modal md ui-select-search"
      >
        <p className="text-danger">{this.state.error}</p>
        <Search id="transfer.user.proId" label="User" options={this.state.pros} />
        <Select id="transfer.type" options={['credit', 'debit']} cap />
        <Money id="transfer.amount" />
        <Textbox id="transfer.description" />
        <Select id="transfer.paymentMethod" options={paymentMethodOptions} cap />
        <Search id="transfer.tags" options={getTagOptions()} multi />
      </Modal>
    );
  }
}
