import React, { useMemo, useState } from 'react';
import { T, Task, U, Visit, W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { loggedInEvent } from '@/com/analytics';
import { APIError, Button, Icon, Paper, Text } from '@/components';
import { supportArticleUrls } from '@/config/const';
import { useLazyLegacyAPI } from '@/hooks';
import ScheduleModal from '../ScheduleModal';
import { Confirmation } from './Confirmation';
import CountdownBanner from './CountdownBanner';
import ProCard from './ProCard';
import StatusTitle, { Props as StatusTitleProps } from './StatusTitle';
import TimeFrames from './TimeFrames';

export type Variant =
  | 'searchingForProPhase1'
  | 'searchingForProPhase2'
  | 'searchingForProPhase3'
  | 'awaitingProConfirm'
  | 'awaitingCxConfirm'
  | 'confirmed';

type VariantCopy = {
  [index in Variant]: {
    countdownBannerClass?: string;
    title: string;
    description: string | JSX.Element;
    status: StatusTitleProps['status'];
    timeTitle: string;
    scheduleButtonText: string;
    secondaryButtonText: string;
    zendeskLink: string;
  };
};

type Props = {
  task: Task;
  visit: Visit;
  workOrder: WorkOrder;
};

export default function LastVisit({ task, visit, workOrder }: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);
  const lastVisitSlot = _.last(W.visitAvailability(visit));
  const lastVisitStart = lastVisitSlot?.start;
  // Pending confirmation phase 2 starts 24 before last visit start
  const pendingPhase2StartTime = mt(lastVisitStart).subtract(24, 'hours');
  // Pending confirmation phase 3 starts 4 hours after last visit start
  const pendingPhase3StartTime = mt(lastVisitStart).add(4, 'hours');

  const variant = useMemo((): Variant => {
    const primaryPro = W.getPrimaryPro(visit);
    if (visit.pros.every(pro => pro?.id === undefined) || !primaryPro?.id) {
      if (mt().isAfter(pendingPhase3StartTime)) {
        return 'searchingForProPhase3';
      }
      if (mt().isAfter(pendingPhase2StartTime)) {
        return 'searchingForProPhase2';
      }
      return 'searchingForProPhase1';
    } else if (
      visit.cx.status !== W.VisitCustomerStatus.CONFIRMED &&
      visit.pros.some(pro => pro.status === W.VisitProStatus.CONFIRMED && pro.id === primaryPro.id)
    ) {
      return 'awaitingCxConfirm';
    } else if (
      visit.cx.status !== W.VisitCustomerStatus.CONFIRMED &&
      visit.pros.some(pro => pro.status !== W.VisitProStatus.CONFIRMED && pro.id === primaryPro.id)
    ) {
      // This edge case only happens when a customer reschedules
      return 'awaitingProConfirm';
    } else if (
      visit.cx.status === W.VisitCustomerStatus.CONFIRMED &&
      visit.pros.some(pro => pro.status !== W.VisitProStatus.CONFIRMED && pro.id === primaryPro.id)
    ) {
      return 'awaitingProConfirm';
    }
    return 'confirmed';
  }, [pendingPhase2StartTime, pendingPhase3StartTime, visit]);

  const proName = T.proName(task) || 'Technician';
  const proFirstName = proName.split(' ')[0];

  const displaySlot = visit.slot;
  const displayTime = `${mt(displaySlot.start).tz(timezone).format('ddd, MMM D')} between ${mt(
    displaySlot.start
  )
    .tz(timezone)
    .format('hA')} - ${mt(displaySlot.end).tz(timezone).format('hA (z)')}`;

  const copyByVariant = useMemo((): VariantCopy => {
    return {
      searchingForProPhase1: {
        countdownBannerClass: 'bg-secondary',
        title: 'Searching For A Technician',
        description:
          'We are searching for an available repair technician in your area. Once a technician has confirmed an appointment time, we will let you know. Expect to hear from us within 1 business day.',
        status: 'pending',
        timeTitle: 'Your Preferred Date and Times',
        scheduleButtonText: 'Edit Availability',
        secondaryButtonText: 'Get Help',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
      searchingForProPhase2: {
        countdownBannerClass: 'bg-accent-orange',
        title: 'Search In Progress',
        description:
          'We know this repair is important to you and are continuing to search for a technician. Once a technician has confirmed an appointment time, we will let you know.',
        status: 'pending',
        timeTitle: 'Your Preferred Date and Times',
        scheduleButtonText: 'Edit Availability',
        secondaryButtonText: 'Get Help',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
      searchingForProPhase3: {
        countdownBannerClass: 'bg-danger',
        title: 'Unable To Find Available Technician',
        description: (
          <>
            Unfortunately, we were unable to find an available technician for any of the appointment
            times you provided. We deeply apologize for this inconvenience and understand the
            urgency required. <br />
            <br />
            Please add additional availability and restart the search.
          </>
        ),
        status: 'pending',
        timeTitle: 'Your Preferred Date and Times',
        scheduleButtonText: 'Edit Availability',
        secondaryButtonText: 'Get Help',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
      awaitingProConfirm: {
        title: 'Awaiting Tech Confirmation',
        description: `We sent your request to your assigned technician, ${proFirstName}, for final confirmation`,
        status: 'pending',
        timeTitle: 'Your Preferred Date and Times',
        scheduleButtonText: 'Edit Availability',
        secondaryButtonText: 'Get Help',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
      awaitingCxConfirm: {
        title: 'Review Proposed Times',
        description: `Your technician has requested your appointment on ${displayTime} be reschedule and has proposed new times.`,
        status: 'actionNeeded',
        timeTitle: `${proFirstName} is available at these times:`,
        scheduleButtonText: 'Accept New Time',
        secondaryButtonText: 'None of these times work',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
      confirmed: {
        title: `Arrival Time: ${displayTime}`,
        description: '',
        status: 'confirmed',
        timeTitle: 'Before your appointment, here’s how to prepare:',
        scheduleButtonText: 'Reschedule',
        secondaryButtonText: 'Get Help',
        zendeskLink: supportArticleUrls.schedulingHelp,
      },
    };
  }, [displayTime, proFirstName]);

  const availability = useMemo(() => {
    if (variant === 'awaitingCxConfirm') {
      return _.groupBy(visit.pros[0].availability, slot =>
        mt(slot.start).tz(timezone).startOf('day').valueOf()
      );
    }
    return _.groupBy(visit.cx.availability, slot =>
      mt(slot.start).tz(timezone).startOf('day').valueOf()
    );
  }, [timezone, variant, visit]);

  const [selected, setSelected] = useState<mt.MomentInput>();

  const [showSuggestTimes, setShowSuggestTimes] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);

  const [updateVisit, { error: updateVisitError }] = useLazyLegacyAPI<WorkOrder>(
    `workOrders/${workOrder.id}/visits/${visit.id}`,
    {
      save: true,
      method: 'put',
      errorRender: ({ error }) => <APIError error={error} variant="alert" className="my-4" />,
    }
  );

  const handleAcceptNewTime = async () => {
    void (await updateVisit({
      ...visit,
      cx: { ...visit.cx, status: 'confirmed' },
      slot: { start: selected, end: mt(selected).add('4', 'h') },
    }));
  };
  const handleHelpCenterClick = () => {
    const eventInfo = {
      location: 'visitCardSupportLink',
    };
    loggedInEvent().track('v1_userRequestedSupport', eventInfo);
    window.open(copyByVariant[variant].zendeskLink, '_blank')?.focus();
  };

  const primaryPro = W.getPrimaryPro(visit);
  const isSearchingForProVariant = [
    'searchingForProPhase1',
    'searchingForProPhase2',
    'searchingForProPhase3',
  ].includes(variant);

  return (
    <>
      <Paper className="mt-4 overflow-hidden" variant="elevated">
        {/* Countdown Banner */}
        {isSearchingForProVariant && (
          <CountdownBanner
            className={copyByVariant[variant].countdownBannerClass}
            phase2StartTime={pendingPhase2StartTime}
            phase3StartTime={pendingPhase3StartTime}
            task={task}
            variant={variant}
          />
        )}
        <div className="mt-4 px-4 pb-4">
          {updateVisitError}
          {/* status */}
          {!isSearchingForProVariant && <StatusTitle status={copyByVariant[variant].status} />}
          {/* title and description */}
          <Text className="mt-6" type="page-title">
            {copyByVariant[variant].title}
          </Text>
          <Text className="mt-2.5 text-grey-dark">{copyByVariant[variant].description}</Text>
          {variant === 'awaitingProConfirm' && (
            <div className="mt-2.5 flex py-2">
              <Icon name="shimmer" className="mr-2.5 text-primaryCTA" size={16} />
              <Text type="helper" className="text-secondary">
                Tip: Calling your technician can speed up confirmation.
              </Text>
            </div>
          )}
          {isSearchingForProVariant && (
            <div className="mt-2.5 flex py-2">
              <Icon name="shimmer" className="mr-2.5 text-primaryCTA" size={16} />
              <Text type="helper" className="text-secondary">
                Repairs with more flexible appointment times get confirmed faster
              </Text>
            </div>
          )}
          {/* Pro Card */}
          {!isSearchingForProVariant && (
            <ProCard className="mt-6 flex" task={task} pro={primaryPro} variant={variant} />
          )}
          <div className="mx-4 mt-6 border-t border-grey-medium" />
          {/* dates and times */}
          <Text className="mt-6" type="button">
            {copyByVariant[variant].timeTitle}
          </Text>
          {variant === 'confirmed' ? (
            <Confirmation />
          ) : (
            <TimeFrames
              availability={availability}
              task={task}
              onChange={variant === 'awaitingCxConfirm' ? val => setSelected(val) : undefined}
            />
          )}
          {/* buttons */}
          {variant === 'awaitingCxConfirm' ? (
            <div className="mt-10 flex flex-col space-x-4 space-y-2">
              <Button
                onClick={handleAcceptNewTime}
                size="large"
                variant="primary"
                disabled={!selected}
              >
                {copyByVariant[variant].scheduleButtonText}
              </Button>
              <Button onClick={() => setShowSuggestTimes(true)} size="large" variant="link">
                {copyByVariant[variant].secondaryButtonText}
              </Button>
            </div>
          ) : (
            <div className="mt-10 flex space-x-4">
              <Button
                className="grow"
                onClick={() => setShowReschedule(true)}
                size="large"
                variant="primary"
              >
                {copyByVariant[variant].scheduleButtonText}
              </Button>
              <Button onClick={handleHelpCenterClick} size="large" variant="outline">
                {copyByVariant[variant].secondaryButtonText}
              </Button>
            </div>
          )}
        </div>
      </Paper>
      <ScheduleModal
        isOpen={showSuggestTimes}
        onClose={() => setShowSuggestTimes(false)}
        variant="confirm"
      />
      <ScheduleModal
        isOpen={showReschedule}
        onClose={() => setShowReschedule(false)}
        variant="reschedule"
      />
    </>
  );
}
