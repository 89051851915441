import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Table } from 'reactstrap';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Button, Icon } from '@/components';
import { updateTask } from '../../com/task';
import { Modal } from '../../com/ui/form';
import { AddPartOrderAddress } from './addPartOrderAddress';

export function AutoOrderPartOrder(props) {
  const sum = _.get(props.partOrder, 'lineItems').reduce((a, b) => {
    return a + +_.get(b, 'rowEstTotalPrice');
  }, 0);

  return (
    <div className="mb-4">
      <div className="selectable-card d-block readOnly">
        <div className="selectable-card-header d-flex justify-content-between">
          <div className="flex-1">
            <label className="muted">Order Number</label>
            <p className="text-blue">{_.get(props.partOrder, 'orderNumber')}</p>
          </div>
          <div className="flex-1">
            <label className="muted">Vendor</label>
            <p>{_.startCase(_.get(props.partOrder, 'vendor', ''))}</p>
          </div>
          <div className="flex-1">
            <label className="muted">Shipping from:</label>
            <p>{_.get(props.partOrder.lineItems[0], 'vendorLocation')}</p>
          </div>
          <div className="flex-1">
            <label className="muted">Order Total</label>
            <p>
              {U.toMoney(sum)} ({props.partOrder.lineItems.length} items)
            </p>
          </div>
        </div>

        {props.partOrder.lineItems.map((part, index) => {
          return (
            <div
              className="selectable-card-row d-flex"
              key={`${index}-${_.get(part, 'partNumber')}`}
            >
              <div className="flex-1">
                <p className="weight-600">{_.get(part, 'partNumber')}</p>
                <label className="muted">{U.toMoney(_.get(part, 'estPricePerUnit'))}/unit</label>
              </div>
              <div className="border-all mr-3 px-2" style={{ borderRadius: 4 }}>
                <p className="weight-600">{_.get(part, 'quantity')}</p>
              </div>
              {/* <p className='muted'>{U.toMoney(_.get(part, 'estPricePerUnit'))}/unit</p> */}
              <p className="weight-600">
                {U.toMoney(_.get(part, 'rowEstTotalPrice'))}{' '}
                <span className="price-label">total</span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function AutoOrderPartAddress(props) {
  return (
    <Row
      className={`selectable-card d-flex align-items-center animated fadeIn mb-2 ${
        props.isSelected ? 'active' : ''
      }`}
      onClick={() => props.updateSelectedIndex(props.index)}
    >
      <div className={props.isSelected ? 'radio_icon checked' : 'radio_icon'} />

      <div className="ml-2 flex-1">
        <p className="weight-600">
          {_.get(props.address, 'name')} ({_.get(props.address, 'type')})
        </p>
        <p>{_.get(props.address, 'address.formattedAddress')}</p>
        {_.get(props.address, 'address.unit') && <p>Unit {_.get(props.address, 'address.unit')}</p>}
      </div>
    </Row>
  );
}

export class AutoOrderParts extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    Promise.all(p.proIds.map(async proId => U.api('get', `pros/${proId}`))).then(pros =>
      this.setState({ pros })
    );
    this.state = {
      partOrderAddresses: [],
      selectedPartOrderAddressIndex: 0,
      addPartOrderAddress: false,
      showConfirmationPage: false,
    };
    this.openAddPartOrderAddressModal = this.openAddPartOrderAddressModal.bind(this);
    this.closeAddPartOrderAddressModal = this.closeAddPartOrderAddressModal.bind(this);
    this.createPartOrderAddresses = this.createPartOrderAddresses.bind(this);
    this.openModal = this.openModal.bind(this);
    this.createPartOrderAddresses = this.createPartOrderAddresses.bind(this);
    this.submit = this.submit.bind(this);
    this.updateSelectedPartOrderAddressIndex = this.updateSelectedPartOrderAddressIndex.bind(this);
    this.addPartOrderAddress = this.addPartOrderAddress.bind(this);
    this.createVendorPartOrders = this.createVendorPartOrders.bind(this);
    this.placePartOrders = this.placePartOrders.bind(this);
    this.removeAllPartOrders = this.removeAllPartOrders.bind(this);
  }

  createVendorPartOrders(task, shippingAddress) {
    const autoOrderVendors = ['reliable', 'encompass', 'marcone'];
    const shortTaskId = _.get(task, 'shortId');
    const shippingAddressHash = U.hashCode(
      _.get(shippingAddress, 'address.formattedAddress', '')
    ).toString();
    const partOrders = {};

    for (const part of _.get(task, 'parts.itemsArray', [])) {
      let partVendor = _.get(part, 'vendor', '');
      // part vendor is sometimes null instead of an empty string
      if (_.isString(partVendor)) {
        partVendor = partVendor.toLowerCase().trim();
      }
      if (
        _.includes(autoOrderVendors, partVendor) &&
        _.get(part, 'status') === T.PartStatus.READY_FOR_AUTO_ORDERING
      ) {
        const partVendorLocation = _.get(part, 'vendorLocation', '');
        const partOrderHash = _.compact([
          shortTaskId,
          partVendor,
          partVendorLocation,
          shippingAddressHash.toString(),
        ])
          .join('-')
          .toUpperCase();
        if (!(partOrderHash in partOrders)) {
          const partShipFromState = _.trim(partVendorLocation.split(',')[1]);
          let partOrderNumber = _.compact([shortTaskId, partVendor[0], partShipFromState])
            .join('-')
            .toUpperCase();
          // check the number of existing partOrders that start with the same prefix and add 1
          const placedPartOrders = _.get(task, 'parts.ordersArray', []);
          const currentPartOrders = _.map(partOrders, po => po.orderNumber);
          const placedPartOrderNumbers = _.union(
            _.map(placedPartOrders, po => po.orderNumber),
            currentPartOrders
          );

          const re = new RegExp(`^${partOrderNumber}(.*)`, 'g');
          const suffix =
            _.filter(placedPartOrderNumbers, partOrderNumber => partOrderNumber.match(re)).length +
            1;
          partOrderNumber += `-${suffix}`;

          const nameBits = _.get(shippingAddress, 'name', '').split(/\s+/);
          const firstName = nameBits.shift();
          const lastName = nameBits.join(' ');
          const partOrderShippingAddress = T.convertPartOrderAddress(
            firstName,
            lastName,
            _.get(shippingAddress, 'address', {})
          );
          partOrders[partOrderHash] = {
            orderNumber: partOrderNumber,
            vendor: partVendor,
            lineItems: [],
            shippingAddress: partOrderShippingAddress,
          };
        }
        const quantity = _.get(part, 'unit.value', 1);
        const ppu = _.get(part, 'pricePerUnit', 1);
        partOrders[partOrderHash].lineItems.push({
          partId: _.get(part, '_id'),
          partNumber: _.get(part, 'partNumber'),
          mfgCode: _.get(part, 'mfgCode'),
          quantity,
          vendorLocation: partVendorLocation,
          estPricePerUnit: ppu,
          rowEstTotalPrice: ppu * quantity,
        });
      }
    }

    return partOrders;
  }

  async placePartOrders() {
    // get current address
    const currentAddress = this.state.partOrderAddresses[this.state.selectedPartOrderAddressIndex];
    // create part orders with current address
    const partOrders = this.createVendorPartOrders(this.props.job, currentAddress);
    this.setState({ partOrders });
    // place part orders with backend, commented out for now
    const partOrdersArray = _.values(partOrders);

    const result = await U.api('post', `tasks/${this.props.job.id}/parts/part-orders`, {
      partOrders: partOrdersArray,
    });

    if (result.errMsg) {
      return this.setState({ error: result.errMsg });
    }

    const { task } = result;
    U.redux.set(`tasks.${task.id}`, task);
    this.setState({ showConfirmationPage: true });
  }

  async removeAllPartOrders() {
    // get current part orders
    const partOrderIds = _.keys(_.get(this.props, 'job.parts.orders', {}));
    const changes = _.map(partOrderIds, poid => ({
      action: 'remove',
      path: `parts.orders`,
      value: poid,
    }));
    const res = await updateTask(this.props.job.id, changes);

    if (res.errMsg) {
      return this.setState({ error: res.errMsg });
    }
  }

  componentWillReceiveProps(p) {
    if (!this.props.isOpen && p.isOpen) {
      this.openModal();
    }
  }

  createPartOrderAddresses() {
    // get addresses
    const serviceAddress = {
      name: _.get(this.props.job, 'customer.user.fullName'),
      phone: _.get(this.props.job, 'customer.user.phone'),
      address: _.get(this.props.job, 'serviceAddress'),
      type: 'customer',
    };

    const partOrderAddresses = [serviceAddress];
    for (const pro of this.state.pros) {
      const proAddress = {
        name: _.get(pro, 'user.fullName'),
        phone: _.get(pro, 'user.phone'),
        address: _.get(pro, 'user.address'),
        type: 'technician',
      };
      partOrderAddresses.push(proAddress);
    }
    this.setState({ partOrderAddresses });
  }

  openModal() {
    this.createPartOrderAddresses();

    const currentAddress = this.state.partOrderAddresses[this.state.selectedPartOrderAddressIndex];
    // create default part orders

    const partOrders = this.createVendorPartOrders(this.props.job, currentAddress);

    this.setState({
      partOrders,
      showConfirmationPage: false,
      modal: true,
    });
  }

  submit() {
    this.setState({ modal: false });
  }

  addPartOrderAddress(newAddress) {
    const numAddresses = this.state.partOrderAddresses.push(newAddress);
    this.setState({
      partOrderAddresses: this.state.partOrderAddresses,
      selectedPartOrderAddressIndex: numAddresses - 1,
    });
  }

  openAddPartOrderAddressModal() {
    this.setState({ addPartOrderAddress: true });
  }

  closeAddPartOrderAddressModal() {
    this.setState({ addPartOrderAddress: false });
  }

  updateSelectedPartOrderAddressIndex(index) {
    this.setState({ selectedPartOrderAddressIndex: index });
  }

  getTotalQty(partOrders) {
    let totalQty = 0;
    for (const partOrder of partOrders) {
      for (const lineItem of partOrder.lineItems) {
        totalQty += lineItem.quantity;
      }
    }
    return totalQty;
  }

  getFormattedTotalPrice(partOrders) {
    let total = 0;
    for (const partOrder of partOrders) {
      for (const lineItem of partOrder.lineItems) {
        total += lineItem.rowEstTotalPrice;
      }
    }
    return U.toMoney(total);
  }

  render() {
    const partOrderAddresses = _.get(this.state, 'partOrderAddresses', []);
    const partOrders = _.sortBy(_.values(_.get(this.state, 'partOrders', {})), ['orderNumber']);
    const currentAddress = partOrderAddresses[this.state.selectedPartOrderAddressIndex];
    const { roles } = this.props;

    let header = '';
    let modalBody = '';

    if (this.state.showConfirmationPage) {
      header = (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <span className="back-button" />
          <span className="header-title" />
        </div>
      );

      modalBody = (
        <Container id="AdminForm">
          {this.state.error && (
            <Row className="admin_alert-bar mb-3">
              <Icon name="alert_circle_outline" size={16} color="#D64727" />
              <span className="ml-2">{this.state.error}</span>
            </Row>
          )}
          <Row>
            <Col xs={2} />
            <Col>
              <div className="mt-4 pt-4 text-center">
                <img src="/img/servicepage/check.png" style={{ height: '50px' }} alt="check" />
                <div className="section_title mt-3" style={{ fontSize: '2.4em' }}>
                  Orders Placed
                </div>
              </div>

              <div className="py-4">
                <p className="muted">Shipping to:</p>
                <div className="mt-2">
                  <p className="weight-600">{_.get(currentAddress, 'name')}</p>
                  <p>{_.get(currentAddress, 'address.formattedAddress')}</p>
                  {_.get(currentAddress, 'address.unit') && (
                    <p>Unit {_.get(currentAddress, 'address.unit')}</p>
                  )}
                </div>
              </div>

              <Table className="admin_order_summary_table">
                <thead>
                  <tr>
                    <th>Order No.</th>
                    <th>Contents</th>
                    <th>Order Date</th>
                    <th className="text-right">Order Total</th>
                  </tr>
                </thead>
                <tbody>
                  {partOrders.map(partOrder => {
                    return (
                      <tr key={partOrder.orderNumber}>
                        <td>
                          <p className="weight-600">{partOrder.orderNumber}</p>
                        </td>
                        <td>
                          {partOrder.lineItems.map(li => {
                            return (
                              <div key={`${partOrder.orderNumber}-${li.partId}`}>
                                <p>
                                  {li.partNumber}{' '}
                                  <small className="muted">
                                    ({li.quantity} x {U.toMoney(li.estPricePerUnit)})
                                  </small>
                                </p>
                              </div>
                            );
                          })}
                        </td>
                        <td>
                          <p>{m(partOrder.partOrderedDate).format('M/D/YY h:mma')}</p>
                        </td>
                        <td>
                          <p className="weight-600 text-right">
                            {this.getFormattedTotalPrice([partOrder])}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            <Col xs={2} />
          </Row>
        </Container>
      );
    } else {
      header = (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <span className="back-button" />
          <span className="header-title">Auto Order Parts</span>
        </div>
      );

      modalBody = (
        <section id="AdminForm">
          {this.state.error && (
            <Row className="admin_alert-bar mb-3">
              <Icon name="alert_circle_outline" size={16} color="#D64727" />
              <span className="ml-2">{this.state.error}</span>
            </Row>
          )}

          <Row className="no-gutters">
            <Col lg="8" md="8" sm="12" xs="12">
              <section style={{ padding: '3em' }}>
                <div className="mb-4">
                  <div className="d-flex align-items-center mb-4">
                    <div className="section_number">1</div>
                    <div className="section_title">Shipping Address</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="weight-600 text-muted">Ship To</p>
                    <Button
                      className="d-inline-flex align-items-center"
                      onClick={this.openAddPartOrderAddressModal}
                      size="small"
                      variant="secondary"
                    >
                      <Icon name="add" size={16} color="#0070c9" />
                      <p className="inline-link ml-1">Add Address</p>
                    </Button>
                  </div>
                  {partOrderAddresses.map((partOrderAddress, index) => {
                    return (
                      <AutoOrderPartAddress
                        key={`address-${index}`}
                        address={partOrderAddress}
                        index={index}
                        isSelected={this.state.selectedPartOrderAddressIndex === index}
                        updateSelectedIndex={this.updateSelectedPartOrderAddressIndex}
                      />
                    );
                  })}
                </div>
                <br />
                <br />
                <div className="mb-4">
                  <div className="d-flex align-items-center mb-4">
                    <div className="section_number">2</div>
                    <div className="section_title">Shipments</div>
                  </div>
                  {partOrders.map((partOrder, index) => {
                    return (
                      <AutoOrderPartOrder
                        key={_.get(partOrder, 'orderNumber', '')}
                        partOrder={partOrder}
                        index={index}
                      />
                    );
                  })}
                </div>
              </section>
            </Col>
            <Col lg="4" className="px-4 py-5" style={{ backgroundColor: '#f2f2f2' }}>
              <div className="section_title mb-3">Order Summary</div>
              <div>
                <label className="muted weight-600 mb-2">Ship to: </label>
                <p className="weight-600">{_.get(currentAddress, 'name')}</p>
                <p>{_.get(currentAddress, 'address.formattedAddress')}</p>
                {_.get(currentAddress, 'address.unit') && (
                  <p>Unit {_.get(currentAddress, 'address.unit')}</p>
                )}

                <div className="border-top mt-4 py-4">
                  <label className="muted weight-600 mb-2">Order Total:</label>
                  <div className="d-flex justify-content-between py-1">
                    <p>Items ({this.getTotalQty(partOrders)}):</p>
                    <p className="weight-600">{this.getFormattedTotalPrice(partOrders)}</p>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    className="admin_button green"
                    onClick={this.placePartOrders}
                    style={{ width: '100%', flex: 1 }}
                  >
                    <Icon name="shopping_cart" size={16} color="#fff" />
                    &nbsp;&nbsp;Place Order
                  </button>
                  {roles.admin && (
                    <button
                      className="admin_button mt-4 bg-danger"
                      onClick={this.removeAllPartOrders}
                      style={{ width: '100%', flex: 1 }}
                    >
                      <Icon name="shopping_cart" size={16} color="#fff" />
                      &nbsp;&nbsp;Remove part orders
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </section>
      );
    }

    return (
      <div>
        <AddPartOrderAddress
          isOpen={this.state.addPartOrderAddress}
          onClose={this.closeAddPartOrderAddressModal}
          partOrderAddresses={this.state.partOrderAddresses}
          addPartOrderAddress={this.addPartOrderAddress}
        />
        <Modal
          submit={this.submit}
          titleCom={header}
          wrapClassName="admin-modal"
          modalClassName="admin-modal_container"
          backdropClassName="admin-modal_backdrop"
          className="admin-modal_dialog"
          contentClassName="admin-modal_dialog_inner_container"
          headerClassName="admin-modal_header"
          bodyClassName="admin-modal_body autoOrderModal"
          footerClassName="admin-modal_footer"
          centered
          onClose={this.props.onClose}
          hideFooter
        >
          {modalBody}
        </Modal>
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const isSupport = U.user.roles(s).customersupport;
  const proIds = _.compact(_.uniq(_.values(job.visits).map(v => _.get(v, 'assignee.id'))));
  const part = _.get(job, `parts.items.${p.id}`);
  if (part && !part.taxable) {
    part.taxable = true;
  }
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const isV2 = m(job.createTime).valueOf() >= 1549872000000;
  const me = s.users[s.me.userId];
  const maxProOwnedPartPrice = _.get(org, 'metadata.maxProOwnedPartPrice');
  const roles = U.user.roles(s);
  return { proIds, part, job, isV2, me, maxProOwnedPartPrice, org, isSupport, roles };
})(AutoOrderParts);
