import React, { useEffect, useState } from 'react';
import { ErrorResponse, T, Task, U, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import {
  APIError,
  BoolInput,
  FormControl,
  Modal,
  SearchInput,
  Text,
  TextInput,
} from '@/components';
import { companyName } from '@/config/const';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import { sendRecallEmail } from '../../com/task';

type Props = {
  onClose: () => void;
  taskId: string;
};

type RecallOption = {
  id: string;
  name: string;
  lastVisitStart: m.MomentInput;
};

export default function MarkAsRecall({ onClose, taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const workOrders = useLegacySelector(state => state.workorders);
  const workOrderId = task.metadata.workOrderId;
  const recallOptions: RecallOption[] = _.flatten(
    _.values(workOrders)
      .filter(
        workOrder =>
          workOrder.cx.id === task.customer.user.id ||
          workOrder.tasks.some(task => task.id === task.recall?.pastId)
      )
      .map(workOrder => workOrder.tasks)
  )
    .filter(task => task.id !== taskId)
    .map(task => {
      const lastVisitStart = T.lastVisitStart(task);
      const lastVisit = T.lastVisit(task);
      const pro = _.get(lastVisit, 'assignee.user.fullName', 'Unassigned') as string;
      const name =
        `${m(lastVisitStart).format('M/D/YY')}, ` +
        `${task.title.split(' ')[0]}, ${pro}, ${task.shortId}`;
      return { id: task.id, name, lastVisitStart };
    });

  const recallOptionMap: Record<string, RecallOption> = {};
  for (const option of recallOptions) {
    recallOptionMap[option.id] = option;
  }

  const [isPlatformRecall, setIsPlatformRecall] = useState(T.isNanaRecall(task));
  const [isVendorRecall, setIsVendorRecall] = useState(T.isVendorRecall(task));
  const [recallFor, setRecallFor] = useState(task.recall?.pastId);
  const [error, setError] = useState('');
  const [notes, setNotes] = useState('');

  const recallJob = tasks[recallFor || ''];

  const [markRecallWO, woToMarkRecall] = useLazyLegacyAPI<WorkOrder>(
    `workOrders/${workOrderId}/tasks/${taskId}/markRecall`,
    {
      errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
      save: true,
      method: 'put',
    }
  );

  const [unMarkRecallWO, woToUnMarkRecall] = useLazyLegacyAPI<WorkOrder>(
    `workOrders/${workOrderId}/tasks/${taskId}/unmarkRecall`,
    {
      errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
      save: true,
      method: 'put',
    }
  );

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${taskId}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  useEffect(() => {
    if (recallFor && !recallJob) {
      void U.api<Task>('get', `tasks/${recallFor}`).then(task => {
        if (!('errMsg' in task)) {
          void U.api<WorkOrder>('get', `workOrders/${task.metadata.workOrderId}`, ['save']);
        }
      });
    }
  }, [recallFor, recallJob]);

  const handleRecallForChange = (recallForParam: string) => {
    setRecallFor(recallForParam);
    if (!recallFor && recallForParam) {
      setIsPlatformRecall(true);
      setIsVendorRecall(true);
    } else if (recallFor && !recallForParam) {
      setIsPlatformRecall(false);
      setIsVendorRecall(false);
    }
  };

  const onSubmit = async () => {
    setError('');
    const wasVendorRecall = T.isVendorRecall(task);
    if (wasVendorRecall) {
      if (!_.isBoolean(isPlatformRecall)) {
        return setError(`Is ${companyName} Recall required`);
      }
      if (!_.isBoolean(isVendorRecall)) {
        return setError('Is Vendor Recall required');
      }
    }
    if (isPlatformRecall && !isVendorRecall) {
      return setError(`If a job is a ${companyName} recall it must be a Vendor recall as well`);
    }
    if (!notes) {
      return setError('Notes required');
    }
    if (!wasVendorRecall && !recallFor) {
      return setError('Recall for required');
    }
    let response: WorkOrder | ErrorResponse | undefined;
    if (isPlatformRecall && isVendorRecall) {
      response = await markRecallWO({ pastRecallId: recallFor });
    } else if (!isPlatformRecall) {
      response = await unMarkRecallWO({ unmarkVendorRecall: !isVendorRecall });
    }
    const changes = [
      {
        action: 'add',
        path: 'notes',
        value: {
          content: `Recall change: ${notes}`,
          type: T.NoteType.RECALL_REQUEST_RECALL_REQUESTED_REFERRED_BACK_TO_VENDOR,
        },
      },
    ];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }
    if (response && 'errMsg' in response) {
      return setError(response.errMsg);
    }
    if (!response) {
      return;
    }

    if (isPlatformRecall && isVendorRecall) {
      sendRecallEmail(task.customer.user.email);
    }
    onClose();
  };

  const getDaysAgo = () => {
    if (recallFor) {
      const lastVisitStart = _.get(recallOptionMap[recallFor], 'lastVisitStart', 0);
      const sanitizedLastVisitStart = m(lastVisitStart).valueOf();
      const createTime = m(task.createTime).valueOf();
      return _.floor(U.millisecondsToDays(createTime - sanitizedLastVisitStart) || 0);
    }
  };

  return (
    <Modal isOpen onSave={onSubmit} onClose={onClose}>
      <Modal.Header title="Mark As Recall" />
      <Modal.Body className="p-4">
        <Text className="mb-4" color="danger" type="button">
          {error}
        </Text>
        {taskToUpdate.error}
        {woToMarkRecall.error}
        {woToUnMarkRecall.error}
        <FormControl label="Recall For" required>
          <SearchInput
            value={recallFor}
            onChange={value => handleRecallForChange(value as string)}
            options={recallOptions}
          />
        </FormControl>
        {T.isVendorRecall(task) && (
          <FormControl className="mt-4" label={`Is ${companyName} Recall`}>
            <BoolInput
              value={isPlatformRecall}
              onChange={value => setIsPlatformRecall(value as boolean)}
            />
          </FormControl>
        )}
        {T.isVendorRecall(task) && (
          <FormControl className="mt-4" label="Is Vendor Recall">
            <BoolInput
              value={isVendorRecall}
              onChange={value => setIsVendorRecall(value as boolean)}
            />
          </FormControl>
        )}
        {recallFor && (
          <Text className="mb-4 text-danger" color="danger" type="button">
            This job was {getDaysAgo()} days ago
          </Text>
        )}
        <FormControl className="mt-4" label="Notes" required>
          <TextInput value={notes} onChange={value => setNotes(value as string)} multiline />
        </FormControl>
        <Text className="mt-4" type="subtitle-2">
          WHAT IS A RECALL APPOINTMENT
        </Text>
        <Text className="mt-4">
          {
            '-Prior part that was installed and has failed from a repair conducted within 1yr from repair date. '
          }
          <strong>
            (If customer is stating this, please advise them that if the original part was not the
            failure a new service call fee of $90 applies).
          </strong>
        </Text>
        <Text className="mt-4">
          -Labor only stands for 90 days, any repair after 90 days is accessed a new labor charge.
        </Text>
        <Text className="mt-4">
          {
            '-123 rule applies for scheduling a recall. We will not service a call after 3 visits (diagnostic, repair, failed repair correction). '
          }
          <strong>
            If the repair fails on the third visit the pro needs to remove the original parts
            installed and contact the office for a refund.
          </strong>
        </Text>
        <Text className="mt-4">
          Repairs with no part installed is considered a service and adjustment and DOES NOT qualify
          as a recall.
        </Text>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
