import React from 'react';
import _ from 'lodash';
import { Icon, Text } from '../../core';
import { Row } from './types';

type Props = {
  disableRow: (index: number) => void;
  emailBody?: string;
  emailCount?: number;
  emailSubject?: string;
  emailTo?: string;
  nameKey?: string;
  rows: Row[];
  sms?: string;
  smsCount: number;
  smsTo?: string;
};

export default function Preview({
  disableRow,
  emailBody,
  emailCount,
  emailSubject,
  emailTo,
  nameKey,
  rows,
  sms,
  smsCount,
  smsTo,
}: Props): JSX.Element {
  return (
    <table className="table-sm table-striped table-hover table">
      <thead>
        <tr>
          <th />
          {nameKey ? (
            <th>
              <Text>Name</Text>
            </th>
          ) : undefined}
          {emailTo ? (
            <th>
              <Text>
                {_.startCase(emailTo)} (send to {emailCount})
              </Text>
            </th>
          ) : undefined}
          {smsTo ? (
            <th>
              <Text>
                {_.startCase(smsTo)} (send to {smsCount})
              </Text>
            </th>
          ) : undefined}
          {emailSubject ? (
            <th>
              <Text>Email Subject</Text>
            </th>
          ) : undefined}
          {emailBody ? (
            <th>
              <Text>Email Body</Text>
            </th>
          ) : undefined}
          {sms ? (
            <th>
              <Text>Sms</Text>
            </th>
          ) : undefined}
        </tr>
      </thead>
      <tbody>
        {_.map(rows, (row, i) => (
          <tr key={i} className="pointer" onClick={() => disableRow(i)}>
            <td>
              <Icon name={row.disabled ? 'check_box_outline_blank' : 'check_box'} />
            </td>
            {nameKey ? (
              <td>
                <Text>{row.cells[nameKey]?.values[0]?.text}</Text>
              </td>
            ) : undefined}
            {emailTo ? (
              <td>
                <Text>{row.cells[emailTo]?.values[0]?.text}</Text>
              </td>
            ) : undefined}
            {smsTo ? (
              <td>
                <Text>{row.cells[smsTo]?.values[0]?.text}</Text>
              </td>
            ) : undefined}
            {emailSubject ? (
              <td>
                <Text>{row.emailSubject}</Text>
              </td>
            ) : undefined}
            {emailBody ? (
              <td>
                <Text>{row.emailBody}</Text>
              </td>
            ) : undefined}
            {sms ? (
              <td>
                <Text>{row.sms}</Text>
              </td>
            ) : undefined}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
