import { T, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment';
import { companyName, companyPhoneNumbers, supportEmail } from '@/config/const';

export default async function load({ notesOnly, roles, task, workOrder }) {
  const timezone = U.timezone(task.serviceAddress);
  const hour = mt().tz(timezone).hour();
  // this is the cutoff where messages are not send but saved for tomorrow
  const lastSendTime = mt()
    .tz(timezone)
    .add(hour >= 21 ? 0 : -1, 'd')
    .startOf('day')
    .hour(17);
  const tzCode = mt().tz(timezone).format('z');

  const [activities, messages, calls, gmailEmails, texts, reviews, conversations] = notesOnly
    ? []
    : await Promise.all([
        U.api('post', 'activities/search', {
          limit: -1,
          query: {
            $or: [
              { callerNumber: `+1${task.customer.user.phone}`, type: 'Call' },
              { refId: task.id, type: 'TaskStatus' },
            ],
          },
        }),
        U.api('post', 'messages/search', {
          query: { 'email.to.0': { $exists: true }, jobId: task.id },
        }),
        U.api('post', 'messages/searchCalls', { phone: `+1${task.customer.user.phone}` }),
        U.api('post', 'messages/searchEmails', { q: task.customer.user.email }),
        U.api('post', 'messages/searchMessages', { phone: `+1${task.customer.user.phone}` }),
        U.api('post', 'reviews/search', { query: { refId: task.id } }),
      ]);

  const phoneToName = {};
  companyPhoneNumbers.forEach(phoneNumber => {
    phoneToName[phoneNumber] = companyName;
  });
  phoneToName[task.customer.user.phone] = task.customer.user.fullName;

  const out = [];

  if (_.isArray(activities)) {
    for (const activity of activities) {
      if (activity.type === 'Call') {
        out.push({
          audio: activity.audio,
          body: [..._.compact(activity.tagList), activity.title].join(', '),
          from: activity.callDirection === 'inbound' ? companyName : task.customer.user.fullName,
          time: activity.createTime,
          to: activity.callDirection === 'inbound' ? task.customer.user.fullName : companyName,
          type: 'call',
          url: roles[U.user.Role.RECORDINGS] && activity.audio ? activity.audio : undefined,
          userId: activity.callDirection === 'inbound' ? undefined : workOrder.id,
        });
      } else {
        out.push({
          body: activity.title,
          from: activity.byUser?.firstName,
          time: activity.createTime,
          type: 'status',
          userId: activity.byUser?.id,
        });
      }
    }
  }

  if (_.isArray(calls)) {
    for (const call of calls) {
      out.push({
        body: `${call.duration} seconds`,
        from: phoneToName[call.from] || call.from,
        to: phoneToName[call.to] || call.to,
        time: call.dateCreated,
        type: 'call',
      });
    }
  }

  if (_.isArray(conversations)) {
    out.push(...conversations);
  }

  if (_.isArray(gmailEmails)) {
    for (const email of gmailEmails) {
      const headers = email.data?.payload?.headers || [];
      const fromHeader = headers.find(header => header.name === 'From')?.value;
      const messageId = _.get(
        headers.find(header => ['Message-Id', 'Message-ID'].includes(header.name)),
        'value'
      );
      const toHeader = headers.find(header => header.name === 'To')?.value;
      let from;
      let to;
      if (fromHeader) {
        const fromEmail = fromHeader.match(/<(.+)>/)?.[1] || fromHeader;
        if (fromEmail === supportEmail) {
          from = companyName;
        } else if (fromEmail === task.customer.user.email) {
          from = task.customer.user.fullName;
        }
      }
      if (toHeader) {
        const toEmail = toHeader.match(/<(.+)>/)?.[1] || toHeader;
        if (toEmail === supportEmail) {
          // don't show emails to the company cuz there will be a Zendesk item with the same info
          continue;
        } else if (toEmail === task.customer.user.email) {
          to = task.customer.user.fullName;
        }
      }
      out.push({
        body: `${headers.find(header => header.name === 'Subject')?.value || ''} ${
          email.data?.snippet || ''
        } ...`,
        from,
        getUrl: user =>
          `https://mail.google.com/mail/u/${
            user?.metadata?.gmailSupportNumber
          }/#search/${encodeURIComponent(`rfc822msgid:${messageId}`)}`,
        messageId,
        time: headers.find(v => v.name === 'Date')?.value,
        to,
        type: 'email',
      });
    }
  }

  if (_.isArray(messages)) {
    for (const message of messages) {
      for (const to of message.email.to) {
        out.push({
          body: `${_.startCase(message.type)} Email`,
          from: companyName,
          time: message.createTime,
          to,
          type: 'email',
        });
      }
    }
  }

  for (const note of _.values(task.notes)) {
    let noteBody = note.content;
    if (note.deleted) {
      noteBody = '[Deleted]';
    } else {
      const outsideBusinessHours = T.outsideBusinessHours(task);
      // if true a warning will be displayed to agents indicating the message will be sent next morning
      const showWarning =
        note.type === 'message' &&
        outsideBusinessHours &&
        mt(note.createTime).isAfter(lastSendTime) &&
        outsideBusinessHours &&
        mt(note.createTime).add(1, 'd').isAfter();
      if (showWarning) {
        noteBody = `(Messages will be sent at 8AM ${tzCode}) ${note.content}`;
      }
      if (note.visitId !== undefined) {
        const visit = workOrder.visits.find(v => v.id === note.visitId);
        const startTime = visit ? visit.slot.start : undefined;
        const endTime = visit ? visit.slot.end : undefined;
        noteBody = `Visit #${visit.id + 1} on ${mt(startTime)
          .tz(timezone)
          .format('ddd, MMM D, h A')} - ${mt(endTime).tz(timezone).format('h A (z)')}: ${
          note.content
        }`;
      }
    }
    out.push({
      _id: note._id,
      body: noteBody,
      deleted: note.deleted,
      from: note.user?.fullName,
      noteType: note.type,
      onBehalfOf: note.from,
      shareWithPro: note.shareWithPro,
      time: note.createTime,
      to: note.shareWithVendor ? `Vendor/Pro/${companyName}` : `Pro/${companyName}`,
      type: 'note',
      userId: note.user?.id,
    });
  }

  if (_.isArray(reviews)) {
    for (const review of reviews) {
      out.push({
        body: _.compact([
          `${review.rating} star review`,
          review.message,
          _.reduce(review.tags, (a, b) => `${a}, ${b}`),
        ]).join(' - '),
        from: task.customer.user.fullName,
        to: companyName,
        time: review.createTime,
        type: 'review',
        url: `/reviews/${review.id}`,
      });
    }
  }

  if (_.isArray(texts)) {
    const otherTaskIds = _.map(workOrder.tasks, 'id').filter(id => task.id !== id);
    for (const text of texts) {
      if (!otherTaskIds.some(id => _.includes(text.body, id))) {
        out.push({
          body: text.body,
          from: phoneToName[text.from] || text.from,
          taskId: text.body.match(/[a-f0-9]{24}/)?.[0],
          time: text.dateCreated,
          to: phoneToName[text.to] || text.to,
          type: 'text',
        });
      }
    }
  }

  return _.sortBy(
    _.map(out, (item, id) => ({ ...item, id })),
    item => mt(item.time).valueOf()
  ).reverse();
}
