import React from 'react';
import * as Table from '@nanaio/table';
import TableComponent from '@/components/Table';
import Edit from './Edit';
// import Add from './Add';
import samsungModelsTable from './helper';

export default function SamsungModelsTable(): JSX.Element {
  return (
    <TableComponent
      {...samsungModelsTable} // eslint-disable-line react/jsx-props-no-spreading
      addUi={(props: Table.AddUiProps) => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
      editUi={(props: Table.EditUiProps) => <Edit {...props} />} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}
