import React from 'react';
import { Text } from '@/components';
import RefundTooltip from '../CloseTask/RefundTooltip';

export default function RefundPolicy(): JSX.Element {
  return (
    <div>
      <Text className="text-secondary" type="page-title">
        Refund Policy
      </Text>
      <Text className="mt-8 text-secondary-5">All refunds are subject to review.</Text>
      <Text className="mb-4 mt-4 text-secondary-5">
        If your work order is eligible for a refund, we will submit a claim. If your claim is
        approved, we’ll process a refund to your original payment method within 1-2 weeks. The
        actual refund amount may vary based on plan benefits and other factors.
      </Text>
      <RefundTooltip />
      <Text className="mt-8 text-grey-dark" type="helper">
        Note: Copays, diagnosis, trip fees, or deductibles are non-refundable if a technician has
        been on-site previously. This helps support our technicians and covers the cost of travel
        (gas, insurance).
      </Text>
    </div>
  );
}
