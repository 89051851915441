import React from 'react';
import { Icon, InteractiveTooltip, Text } from '@/components';
import { supportEmail } from '@/config/const';

export default function ReturnTooltip(): JSX.Element {
  return (
    <InteractiveTooltip
      title="Haven’t received my return label yet?"
      description={
        <div>
          <Text className="text-grey-dark">
            It can take up to 7 business days to process your return label. Please check your
            spam/junk folder if you haven't received the email by then.
          </Text>
          <Text className="mt-4 text-grey-dark">
            Still nothing? Reach out via live chat or email us at {supportEmail} so we can help you
            out."
          </Text>
        </div>
      }
      placement="bottom"
      trigger={
        <div className="flex space-x-1">
          <Icon name="info_outline" className="text-primaryCTA" size={16} />
          <Text className="text-primaryCTA" type="button">
            Haven't received my return label yet?
          </Text>
        </div>
      }
    />
  );
}
