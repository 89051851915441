import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import APIError from './APIError';

const handleClose = () => {
  alert('On Close');
};

export default {
  title: 'Core/APIError',
  component: APIError,
  args: {
    error: 'Error Message Text',
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof APIError>;

const Template: ComponentStory<typeof APIError> = args => <APIError {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Alert = Template.bind({});
Alert.args = { variant: 'alert' };

export const Icon = Template.bind({});
Icon.args = { variant: 'icon' };

export const Modal = Template.bind({});
Modal.args = { variant: 'modal', onClose: handleClose };

export const AlertWithText = Template.bind({});
AlertWithText.args = { variant: 'alert', text: 'Text Prop Supplied' };

export const IconWithText = Template.bind({});
IconWithText.args = { variant: 'icon', text: 'Text Prop Supplied' };

export const ModalWithText = Template.bind({});
ModalWithText.args = { variant: 'modal', text: 'Text Prop Supplied', onClose: handleClose };

//Stories Showing How to Use APIErrorBoundary?
