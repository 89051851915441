import 'react-calendar/dist/Calendar.css';
import './calendar.css';

import React from 'react';
import ReactCalendar, { CalendarProps, CalendarTileProperties } from 'react-calendar';
import moment from 'moment';
import Icon from '../Icon';
import Text from '../Text';

const defaultTileContent = ({ date }: CalendarTileProperties) => (
  <div className="tile-inner">
    <Text type="body-2" color="font.dark">
      {moment(date).format('D')}
    </Text>
  </div>
);

export default function Calendar({
  calendarType = 'US',
  className = '',
  locale = 'en-US',
  minDate,
  minDetail = 'month',
  onClickDay,
  tileContent = defaultTileContent,
  view = 'month',
  value,
}: CalendarProps): JSX.Element {
  return (
    <ReactCalendar
      calendarType={calendarType}
      className={`nana-calendar ${className as string}`}
      locale={locale}
      minDate={minDate}
      minDetail={minDetail}
      next2Label={null}
      nextLabel={<Icon color="black" name="chevron_right" size={28} />}
      onClickDay={onClickDay}
      prev2Label={null}
      prevLabel={<Icon color="black" name="chevron_left" size={28} />}
      tileContent={tileContent}
      view={view}
      value={value}
    />
  );
}
