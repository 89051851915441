import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLazyLegacyAPI } from '@nanaio/hooks';
import { ServerEntity } from '@nanaio/util';
import _ from 'lodash';
import { APIError, Input, InputType, Loader, Modal,SearchInput, Text } from '@/components';
import { loadSymptoms } from './helper';
import { SamsungModel, SymptomsList } from './types';

type Props = {
  isOpen: boolean;
  toggleOpen: () => void;
  onSuccess: (result: ServerEntity) => void;
  onRemove?: () => void;
  id?: string;
};

function Edit({ isOpen, toggleOpen, id, onSuccess, onRemove }: Props): JSX.Element {
  const [samsungModel, setSamsungModel] = useState<Partial<SamsungModel>>({});
  const [symptoms, setSymptoms] = useState<SymptomsList>({
    symptoms1: [],
    symptoms2: [],
    symptoms3: [],
  });
  const isEdit = Boolean(id);

  const [loadSamsungModel, loadSamsungModelResponse] = useLazyLegacyAPI<SamsungModel>(
    `samsungModels/${id as string}`,
    { method: 'get' }
  );

  useEffect(() => {
    if (id && !loadSamsungModelResponse.data) {
      void loadSamsungModel().then(data => {
        if (data) {
          setSamsungModel(data);
        }
      });
    }

    void loadSymptoms().then(data => {
      if (data) {
        setSymptoms(data);
      }
    });
  }, [id, loadSamsungModel, loadSamsungModelResponse.data]);

  const errorRender = ({ error }: { error: string }) => (
    <APIError
      className="mb-10"
      error={error}
      text={<>Unable to update update/create samsung model. {error}</>}
    />
  );

  const [createSamsungModel, createSamsungModelResponse] = useLazyLegacyAPI<SamsungModel>(
    `samsungModels`,
    { errorRender, method: 'post' }
  );

  const [updateSamsungModel, updateSamsungModelResponse] = useLazyLegacyAPI<SamsungModel>(
    `samsungModels/${id as string}`,
    { errorRender, method: 'put' }
  );

  const [removeSamsungModel, removeSamsungModelResponse] = useLazyLegacyAPI<SamsungModel>(
    `samsungModels/${id as string}`,
    { errorRender, method: 'delete' }
  );

  const onSubmit = async () => {
    let data;
    if (isEdit) {
      const changes = Object.keys(samsungModel).map(path => ({
        action: 'replace',
        path,
        value: samsungModel[path as keyof SamsungModel],
      }));
      data = await updateSamsungModel(changes);
    } else {
      data = await createSamsungModel(samsungModel);
    }

    if (data) {
      onSuccess(data);
    }
  };

  const remove = async () => {
    if (onRemove) {
      await removeSamsungModel();
      onRemove();
    } else {
      toggleOpen();
    }
  };

  if (isEdit && !samsungModel.id) {
    return <Loader />;
  }

  if (!symptoms.symptoms1.length) {
    return <Loader />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleOpen}
      onRemove={remove}
      onSave={onSubmit}
      hideOnBackdropClick={false}
    >
      <Modal.Header title={`${isEdit ? 'Edit' : 'Add'} Samsung Model`} />
      <Modal.Body className="p-4">
        {createSamsungModelResponse.error}
        {updateSamsungModelResponse.error}
        {removeSamsungModelResponse.error}
        <Input
          value={samsungModel.make}
          label="Make"
          type={InputType.TEXT}
          onChange={(make?: string) => setSamsungModel({ ...samsungModel, make })}
        />
        <Input
          value={samsungModel.model}
          label="Model"
          type={InputType.TEXT}
          onChange={(model?: string) => setSamsungModel({ ...samsungModel, model })}
        />
        <Text>Symptom 1</Text>
        <SearchInput
          value={samsungModel.symptom1}
          onChange={(symptom1: string | unknown) =>
            setSamsungModel({
              ...samsungModel,
              symptom1: symptom1 as string,
              symptom2: undefined,
              symptom3: undefined,
            })
          }
          options={symptoms.symptoms1.map(s => ({
            id: s.code,
            name: `${s.code} - ${s.description}`,
          }))}
        />
        <Text>Symptom 2</Text>
        <SearchInput
          value={samsungModel.symptom2}
          onChange={(symptom2: string | unknown) =>
            setSamsungModel({ ...samsungModel, symptom2: symptom2 as string, symptom3: undefined })
          }
          options={symptoms.symptoms2
            .filter(s => s.symptom1 === samsungModel.symptom1)
            .map(s => ({ id: s.code, name: `${s.code} - ${s.description}` }))}
        />
        <Text>Symptom 3</Text>
        <SearchInput
          value={samsungModel.symptom3}
          onChange={(symptom3: string | unknown) =>
            setSamsungModel({ ...samsungModel, symptom3: symptom3 as string })
          }
          options={symptoms.symptoms3
            .filter(
              s => s.symptom1 === samsungModel.symptom1 && s.symptom2 === samsungModel.symptom2
            )
            .map(s => ({ id: s.code, name: `${s.code} - ${s.description}` }))}
        />
        <Input
          value={samsungModel.manufacturerDateMin}
          label="Manufacturer Date Min"
          type={InputType.TEXT}
          onChange={(manufacturerDateMin?: string) =>
            setSamsungModel({ ...samsungModel, manufacturerDateMin })
          }
        />
        <Input
          value={samsungModel.manufacturerDateMax}
          label="Manufacturer Date Max"
          type={InputType.TEXT}
          onChange={(manufacturerDateMax?: string) =>
            setSamsungModel({ ...samsungModel, manufacturerDateMax })
          }
        />
        <Input
          value={samsungModel.pcb}
          label="PCB"
          type={InputType.TEXT}
          onChange={(pcb?: string) => setSamsungModel({ ...samsungModel, pcb })}
        />
        <Input
          value={samsungModel.requiresControlBoard}
          label="Requires Control Board?"
          type={InputType.SWITCH}
          onChange={requiresControlBoard => {
            setSamsungModel({
              ...samsungModel,
              requiresControlBoard: requiresControlBoard as boolean,
            });
          }}
        />
      </Modal.Body>
      <Modal.Footer noValidate />
    </Modal>
  );
}

export default connect()(Edit);
