import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import Link from '../../com/ui/link';
import Jobs from './jobs';
import Profile from './profile';
import Users from './users';

class EditVendor extends React.Component {
  constructor(p) {
    super(p);
    U.api('get', `orgs/${global.id()}`, true);
    const title = _.get(this.props.org, 'name');
    if (title) {
      document.title = title;
    }
  }

  componentWillReceiveProps(p) {
    const title = _.get(p.org, 'name');
    if (title) {
      document.title = title;
    }
  }

  render() {
    const pages = [
      { name: 'Profile', icon: 'user', to: `/vendors/${global.id()}`, com: Profile },
      { name: 'Jobs', icon: 'briefcase', to: `/vendors/${global.id()}/jobs`, com: Jobs },
      { name: 'Representatives', icon: 'users', to: `/vendors/${global.id()}/users`, com: Users },
    ];
    const activePage = pages.find(p => p.to === this.props.path);
    if (!this.props.org || !activePage) {
      return null;
    }
    return (
      <div id="ClientProfile">
        <div className="cp-header">
          <div className="d-flex align-items-center p-3">
            <h5 className="mr-auto">{this.props.org.name}</h5>
            <div style={{ color: 'black' }}>{_.startCase(this.props.org.type)}</div>
          </div>
        </div>
        <div className="cp-tabs">
          <div className="container">
            <ul className="cp-nav">
              {pages.map(p => (
                <li key={p.name} className="nav-item">
                  <Link
                    className={`nav-link${p.name === activePage.name ? ' active' : ''}`}
                    to={p.to}
                  >
                    <span>{p.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="cp-body p-3" style={{ position: 'relative' }}>
          {React.createElement(activePage.com)}
        </div>
      </div>
    );
  }
}

export default connect(s => {
  return {
    path: s.router.location.pathname,
    org: s.orgs[global.id()],
  };
})(EditVendor);
