import type { ListItemProps } from '@/components/pages/user/FTCBookingFlow/List';
import { companyName } from '@/config/const';

export const preDiagnosed: ListItemProps[] = [
  {
    title: `${companyName} Orders & Ships Parts To Your Home`,
    description:
      'We use data to predict which parts will be required for your repair and will send you an update on the expected delivery date.',
    timeEstimate: 'Takes 1 to 2 days',
  },
  {
    title: `${companyName} Looks for a Technician Based on Your Availability`,
    description:
      'We will ask you to provide time when you’re free. Once your technician confirms your appointment, we will send you an update.',
    timeEstimate: 'Takes 1 to 3 days',
  },
  {
    title: 'Technician Arrives and Repairs the Appliance',
    description:
      'You will be notified when your technician is on the way. Once on-site, your technician will diagnose the issue, install the parts, and complete the repair.',
  },
  {
    title: 'You Confirm the Repair and Rate the Experience',
    description:
      'After the repair is completed, ' +
      companyName +
      ' will reach out to confirm the job has been completed to your satisfaction.',
  },
];

export const preDiagnosedAndStashedVisits: ListItemProps[] = [
  {
    title: `${companyName} Orders & Ship Parts To Your Home`,
    description:
      'We use data to predict which parts will be required for your repair and will send you an update on the expected delivery date. If the parts do not arrive in time for your appointment, we will reach out to you to reschedule.',
    timeEstimate: 'Takes 1 to 2 days',
  },
  {
    title: `${companyName} Looks for a Technician Based on Your Availability`,
    description:
      'We have been informed of your availability by your warranty provider. Once your technician confirms the appointment, we will reach out to you to confirm the details.',
    timeEstimate: 'Takes 1 to 3 days',
  },
  {
    title: 'Technician Arrives and Repairs the Appliance',
    description:
      'You will be notified when your technician is on the way. Once on-site, your technician will diagnose the issue, install the parts, and complete the repair.',
  },
  {
    title: 'You Confirm the Repair and Rate the Experience',
    description:
      'After the repair is completed, ' +
      companyName +
      ' will reach out to confirm the job has been completed to your satisfaction.',
  },
];

export const needsDiagnosis: ListItemProps[] = [
  {
    title: `${companyName} Looks for a Technician Based on Your Availability`,
    description:
      'We will ask you to provide time when you are free. Once your technician confirms your appointment, we will send you an update.',
    timeEstimate: 'Takes 1 to 3 days',
  },
  {
    title: 'Technician Arrives and Diagnoses the Issue',
    description:
      'You will be notified when your technician is on the way. Once on-site, your technician will diagnose the issue and complete the repair. A follow visit will be scheduled if replacement parts are required.',
  },
  {
    title: `${companyName} Will Order & Ship Parts to Your Home`,
    description:
      'Once your vendor approves the parts, ' +
      companyName +
      ' will ship them to you. Some parts may be backordered. If they are not delivered before your next appointment, we will contact you to reschedule.',
    timeEstimate: 'Takes 2 to 4 days, if needed',
  },
  {
    title: 'You Confirm the Repair and Rate the Experience',
    description:
      'After the repair is completed, ' +
      companyName +
      ' will reach out to confirm the job has been completed to your satisfaction.',
  },
];

export const needsDiagnosisAndStashedVisits: ListItemProps[] = [
  {
    title: `${companyName} Looks for a Technician Based on Your Availability`,
    description:
      'We have been informed of your availability by your warranty provider. Once your technician confirms the appointment, we will reach out to you to confirm the details.',

    timeEstimate: 'Takes 1 to 3 days',
  },
  {
    title: 'Technician Arrives and Diagnoses the Issue',
    description:
      'You will be notified when your technician is on the way. Once on-site, your technician will diagnose the issue and complete the repair. A follow up visit will be scheduled if replacement parts are required.',
  },
  {
    title: `${companyName} Will Order & Ship Parts to Your Home`,
    description:
      'Once your vendor approves the parts, ' +
      companyName +
      ' will ship them to you. Some parts may be backordered. If they are not delivered before your next appointment, we will contact you to reschedule.',
    timeEstimate: 'Takes 2 to 4 days, if needed',
  },
  {
    title: 'You Confirm the Repair and Rate the Experience',
    description:
      'After the repair is completed, ' +
      companyName +
      ' will reach out to confirm the job has been completed to your satisfaction.',
  },
];
