import React from 'react';
import _ from 'lodash';
import { Icon } from '@/components';
import { useLegacySelector } from '@/hooks';
import AddCardLink from '../../../actions/addCard';

type Props = {
  taskId: string;
};

export default function PaymentButton({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  return (
    <AddCardLink
      content={
        <div className="card-block--border mr-3 cursor-pointer bg-white shadow-lifted">
          {_.get(task, 'payment.card') ? (
            <div>
              <h4 style={{ fontWeight: 600, color: '#1069C9' }}>
                {_.get(task, 'payment.card.brand')} &bull;&bull;&bull;&bull;{' '}
                {_.get(task, 'payment.card.last4')}
              </h4>
              <label className="text-muted d-flex align-center">
                <Icon name="credit_card_outline" color="#A1A4AB" size={15} />
                &nbsp; Payment &nbsp; <span className="link-blue">(edit)</span>
              </label>
            </div>
          ) : (
            <label className="text-blue d-flex align-center">
              <Icon name="add_circle" color="#3297d3" size={15} />
              &nbsp;Add Card
            </label>
          )}
        </div>
      }
    />
  );
}
