import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import U, { Address } from '@nanaio/util';
import _ from 'lodash';
import { types } from './Address'; // eslint-disable-line import/no-cycle
import Context from './Context';
import { Type } from './Input'; // eslint-disable-line import/no-cycle
import { isValid } from './TextInput'; // eslint-disable-line import/no-cycle

export type Input = { id: string; required?: boolean; type: Type };

export const isEmpty = ({ type, value }: { type: Type; value: unknown }): boolean =>
  types.includes(type)
    ? !(value as Address)?.geoCoordinates
    : U.emptyValues.some(emptyValue => _.isEqual(emptyValue, value));

export type Ref = {
  submit: () => boolean;
};

type Props<T> = {
  children: ReactNode;
  className?: string;
  debounce?: number;
  onChange?: React.Dispatch<React.SetStateAction<T>>;
  onPatch?: (id: string, value: unknown) => void;
  originalValue?: T;
  style?: CSSProperties;
  value: T;
};

function Form<T>(props: Props<T>, ref: React.Ref<Ref>): ReactElement {
  const { children, className, debounce, onChange, onPatch, originalValue, style, value } = props;
  const [inputs, setInputs] = useState<Input[]>([]);
  const [validate, setValidate] = useState(false);

  const handleChange = useCallback(
    (id: string, inputValue: unknown) => {
      if (onChange) {
        onChange(value => _.set(_.cloneDeep(value) || {}, id, inputValue) as T);
      }
      if (onPatch) {
        onPatch(id, inputValue);
      }
    },
    [onChange, onPatch]
  );

  // id will look like foo, foo.1, or foo.bar.1
  const handleRemove = useCallback(
    (id: string) => {
      if (onChange) {
        onChange(value => {
          const ARRAY_ID_REGEX = /^(.+)\.(\d+)$/;
          const [, parentId, arrayIndex] = id.match(ARRAY_ID_REGEX) || [];
          const parentValue = _.get(value, parentId) as unknown;
          let newValue = value;
          if (_.isArray(parentValue)) {
            const newArray = parentValue.filter((_, i) => i !== Number(arrayIndex));
            if (_.isPlainObject(value)) {
              newValue = _.set<T>(_.cloneDeep(value) as unknown as object, parentId, newArray);
            }
          } else if (_.isPlainObject(value)) {
            newValue = _.omit(value as unknown as object, id) as T;
          }
          return newValue;
        });
      } else if (onPatch) {
        onPatch(id, undefined);
      }
    },
    [onChange, onPatch]
  );

  const handleSubmitClick = useCallback(() => setValidate(true), []);

  const register = useCallback((input: Input) => {
    if (input.id) {
      setInputs(inputs => _.uniqBy([input, ...inputs], 'id'));
    }
  }, []);

  const submitIsDisabled =
    _.isEqual(originalValue, value) ||
    inputs.some(input => {
      const inputValue = _.get(value, input.id) as unknown;
      const inputIsEmpty = isEmpty({ type: input.type, value: inputValue });
      if (input.required && inputIsEmpty) {
        return true;
      }
      if (!(input.type === Type.EMAIL || input.type === Type.PHONE)) {
        return false;
      }
      if (typeof inputValue !== 'string') {
        return false;
      }
      return !inputIsEmpty && isValid[input.type] && !isValid[input.type](inputValue);
    });

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmitClick();
      return !submitIsDisabled;
    },
  }));

  const unregister = useCallback((id: string) => {
    if (id) {
      setInputs(inputs => inputs.filter(input => input.id !== id));
    }
  }, []);

  return (
    <Context.Provider
      value={{
        debounce,
        onChange: handleChange,
        onRemove: handleRemove,
        onSubmitClick: handleSubmitClick,
        register,
        submitIsDisabled,
        unregister,
        validate,
        value,
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Context.Provider>
  );
}

// https://stackoverflow.com/a/58473012
export default forwardRef(Form) as <T>(p: Props<T> & { ref?: React.Ref<Ref> }) => ReactElement;
