import React, { useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import { Button, Text } from '@/components';
import CloseWorkOrder from './CloseTask';
import HelpModal from './HelpModal';
import ScheduleModal from './ScheduleModal';

type Props = {
  task: Task;
};

export default function TaskHeader({ task }: Props): JSX.Element {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const orgName = task.customer?.org?.name || 'NO_VENDOR';

  return (
    <>
      <div className="mb-4 bg-white">
        <div className="flex flex-col justify-between pb-0 pt-1 md:flex-row">
          <div className="mb-lg-0 mb-md-0 mb-3">
            <div className="flex space-x-1">
              <Text type="subtitle-2">
                {task.serviceAddress.streetNumber} {task.serviceAddress.route},
              </Text>
              <Text className="text-grey-dark" type="subtitle-2" style={{ fontWeight: 500 }}>
                {task.serviceAddress.locality}, {task.serviceAddress.region}
              </Text>
            </div>
            <Text className="text-primary" type="headline-5" style={{ fontWeight: 500 }}>
              {task.title}
            </Text>
            <Text className="text-grey-dark" type="subtitle-1">
              for {task.customer.user.fullName}
              {_.get(task, 'customer.org.id') && ` (${orgName})`}
            </Text>
          </div>

          <div>
            <Button className="w-full" onClick={() => setIsHelpOpen(true)} variant="secondary">
              Help
            </Button>
            <CloseWorkOrder task={task} />
          </div>
        </div>
      </div>
      <HelpModal isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
      <ScheduleModal
        isOpen={isScheduleOpen}
        onClose={() => setIsScheduleOpen(false)}
        variant="reschedule"
      />
    </>
  );
}
