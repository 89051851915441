import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import { brandLogos } from '../../config/brandLogos';
import BrandLogo, { Props } from './BrandLogo';

const brandNames = Object.keys(brandLogos);

export default {
  title: 'Core/Brand Logo',
  component: BrandLogo,
  args: { brandName: 'Samsung' },
  argTypes: {
    brandName: {
      control: { type: 'select' },
      options: brandNames,
    },
  },
} as ComponentMeta<typeof BrandLogo>;

const Template: ComponentStory<typeof BrandLogo> = args => <BrandLogo {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Sizes = (args: Props): JSX.Element => (
  <div className="flex-0-0 flex-col">
    <p>Size prop options</p>
    <p className="mt-6">small (24px)</p>
    <Default {...args} size="small" />
    <p className="mt-6">medium (32px)</p>
    <Default {...args} size="medium" />
    <p className="mt-6">large (48px)</p>
    <Default {...args} size="large" />
    <p className="mt-6">xlarge (64px)</p>
    <Default {...args} size="xlarge" />
  </div>
);

export const BadName = Template.bind({});
BadName.args = { brandName: 'Brand Name Does Not Match' };
