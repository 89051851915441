import { useEffect, useState } from 'react';
import { useWindowSize } from '@nanaio/hooks';

export const Breakpoints = {
  XS: 0,
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536,
};

type Breakpoint = keyof typeof Breakpoints;

function getCurrentBreakpoint(width: number): Breakpoint {
  const entries = Object.entries(Breakpoints) as [Breakpoint, number][];

  for (let i = 0; i < entries.length - 1; i++) {
    const [lowerBoundaryName, lowerBoundarySize] = entries[i];
    const [_, upperBoundarySize] = entries[i + 1];

    if (lowerBoundarySize <= width && width < upperBoundarySize) {
      return lowerBoundaryName;
    }
  }

  return entries[entries.length - 1][0];
}

export default function useBreakpoints(): [Breakpoint, number] {
  const { width } = useWindowSize();
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint(width));

  useEffect(() => setCurrentBreakpoint(getCurrentBreakpoint(width)), [width]);

  return [currentBreakpoint, Breakpoints[currentBreakpoint]];
}
