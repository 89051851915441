import React from 'react';
import { Alert, Button, Switch, Text } from '@/components';

type Props = {
  alert?: HeaderAlert;
  disableSaveButton?: boolean;
  isActiveSwitchValue: boolean;
  onCancelClick: () => void;
  onIsActiveSwitchChange: () => void;
  onSaveClick: () => void;
  resetAlert: () => void;
};

interface HeaderAlert {
  message: string;
  type: 'error' | 'success';
}

export default function Header({
  alert,
  disableSaveButton,
  isActiveSwitchValue,
  onCancelClick,
  onIsActiveSwitchChange,
  onSaveClick,
  resetAlert,
}: Props): JSX.Element {
  return (
    <div className="flex h-12 items-stretch justify-end">
      {alert?.type ? (
        <Alert dismissAfterSeconds={5} onDismissed={resetAlert} variant={alert.type}>
          {alert.message}
        </Alert>
      ) : null}

      <div className="ml-4 flex flex-col">
        <Switch isActive={isActiveSwitchValue} onClick={onIsActiveSwitchChange} />
        <Text className="mt-2">{isActiveSwitchValue ? 'Active' : 'Inactive'}</Text>
      </div>

      <Button variant="secondary" className="ml-4" onClick={onCancelClick}>
        Cancel
      </Button>

      <Button className="ml-4" disabled={disableSaveButton} onClick={onSaveClick}>
        Save
      </Button>
    </div>
  );
}
