import './loader.css';

import React from 'react';

export default (): JSX.Element => (
  <section id="Loader">
    <div className="loader-container">
      <div className="center">
        <div>
          <div className="loader">
            <span />
          </div>
        </div>
      </div>
      <div className="loader-title">Loading</div>
    </div>
  </section>
);
