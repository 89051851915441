import '../../style/admin-modal.css';

import React, { useMemo } from 'react';
import U, { PartVendor } from '@nanaio/util';
import { Service } from '@nanaio/util/dist/partVendor';
import _ from 'lodash';
import { APIError, Icon, Loader } from '@/components';
import { useLegacyAPI } from '@/hooks';
import { Select } from '../../com/ui/form';

type AvailableLocation = {
  city: string;
  state: string;
  qtyAvailable: number;
};

type PartData = {
  available?: boolean;
  availableLocation: AvailableLocation[];
  costPrice: number;
  externalApi: string;
  desc?: string;
  partNumber?: string;
  mfgCode?: string;
  retailPrice: number;
  substitutePart?: string;
  url: string;
};

type Props = {
  index: number;
  isSelected: boolean;
  partData: PartData;
  updateSelectedIndex: (index: number) => void;
};

export default function PartSearchData({
  index,
  isSelected,
  partData,
  updateSelectedIndex,
}: Props): JSX.Element {
  const { loading, error, data } = useLegacyAPI<PartVendor>(
    `partVendors/findbyname/${partData.externalApi}`,
    {
      errorRender: ({ error }) => (
        <APIError className="mb-10" error={error} text={<>Unable to find part vendor. {error}</>} />
      ),
      method: 'get',
    }
  );

  const isCheapest = index === 0;

  const locationOptions = useMemo(() => {
    if (data) {
      const pickUpLocations =
        data.locations?.filter(
          l =>
            l.services?.includes(Service.PICKUP) &&
            (data.defaultOpenHours?.some(d => d.from) || l.openHours?.some(d => d.from))
        ) || [];
      const bestAvailable = {
        id: 'vendorLocation-0:Best Available',
        name: 'Best Available',
        selected: true,
      };
      const locationOptions = data.hasBestAvailableOrdering ? [bestAvailable] : [];
      for (const loc of partData.availableLocation) {
        // Checks if this location is in the pickup list
        // Best way we can do for now is matching by name (city + state) and removing commas
        const isPickup = pickUpLocations.some(
          l => l.name.replace(',', '') === `${loc.city} ${loc.state}`
        );
        let name = `${loc.city}, ${loc.state} - ${loc.qtyAvailable} available`;
        if (isPickup) {
          name = `${name} (Pickup available)`;
        }
        const selectOption = {
          id: `vendorLocation-${index}:${loc.city}, ${loc.state}`,
          name,
          selected: false,
        };
        locationOptions.push(selectOption);
      }
      if (locationOptions.length) {
        locationOptions[0].selected = true;
      }
      return locationOptions;
    }
  }, [data, index, partData.availableLocation]);

  if (loading) {
    return <Loader isLoading />;
  }

  return (
    <div
      className={`selectable-card d-flex align-items-center animated fadeIn mb-3 hover:border-primaryCTA ${
        isSelected ? 'active' : ''
      }`}
      onClick={() => updateSelectedIndex(index)}
    >
      {isCheapest && (
        <div className="d-inline-flex selectable-card-banner">
          <Icon name="tag" size={14} color="#fff" />
          <span className="ml-1">Cheapest</span>
        </div>
      )}
      <img
        src={`/img/vendors/${partData.externalApi}.png`}
        alt="part vendor logo"
        className="selectable-card-img"
      />
      <div className="border-right flex-1">
        <div className="selectable-card-title">
          {partData.mfgCode} {partData.desc?.toLowerCase()}
        </div>
        <div className="flex items-center space-x-2">
          <span className="muted">P/N:</span>
          <span>{_.toUpper(partData.partNumber)}</span>
          {partData.substitutePart && (
            <>
              <span className="muted">|</span>
              <span className="muted">Substitute P/N:</span>
              <span>{partData.substitutePart}</span>
            </>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <p>
            <span className="muted">Sold by:</span>
          </p>
          <a
            href={partData.url}
            target="_blank"
            rel="noopener noreferrer"
            className="d-inline-flex inline-link"
          >
            <p className="text-blue mr-1">{_.startCase(partData.externalApi)}</p>
            <Icon name="open_in_new" size={14} color="#0070c9" />
          </a>
        </div>
        {error}
        {partData.available ? (
          <div className="select-dropdown">
            <Select
              id={`vendorLocation-${index}`}
              firstSelected
              label="Warehouse:"
              options={locationOptions}
            />
          </div>
        ) : (
          <div className="alert-warning">
            <p>This item is currently out-of-stock</p>
          </div>
        )}
      </div>
      <div className="align-self-start px-4">
        <div className="d-flex align-items-end">
          <h5>
            {U.toMoney(partData.costPrice)} <span className="price-label">cost</span>
          </h5>
        </div>
        <div className="d-flex align-items-end">
          <h5>
            {U.toMoney(partData.retailPrice)} <span className="price-label">retail</span>
          </h5>
        </div>
      </div>
    </div>
  );
}
