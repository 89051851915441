import React from 'react';
import { Text } from '@/components';
import Footer from '../../../com/ui/footer';
import CitySearch from './cities-search';

export default () => (
  <div style={{ marginTop: '66px' }}>
    <div className="py-5" style={{ minHeight: '80vh' }}>
      <Text type="headline-3" className="text-center">
        Cities we service
      </Text>
      <Text type="subtitle-1" color="grey.dark" className="mt-2 text-center">
        Providing home services in a city near you
      </Text>
      <div className="mt-4 flex justify-center">
        <div style={{ width: '100%', maxWidth: '600px' }}>
          <CitySearch block region="CA" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
