import React, { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import { copyText } from '@/utils';
import { Icon, Text } from '../../core';
import theme from '../../theme';
import { checkColumnWidth, fixedColumnCount, fixedRowCount } from '../util';

type Props = {
  checkedRows: Record<string, boolean>;
  columnIndex: number;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  editUi?: Table.EditUi;
  openEditCell: ({ columnIndex, row }: { columnIndex: number; row: Table.Row }) => void;
  openLink: (row: Table.Row) => void;
  query: Table.Query;
  rowIndex: number;
  rowUrl?: (props: { row: Table.Row; pivotColumn: Table.Column; pivotValue: unknown }) => void;
  rows: Table.Row[];
  setEditUi: (row: Table.Row) => void;
  style: CSSProperties;
  toggleRowCheck: (row: Table.Row) => void;
};

export default function Body({
  checkedRows,
  columnIndex,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  editUi,
  openEditCell,
  openLink,
  query,
  rowIndex,
  rowUrl,
  rows,
  setEditUi,
  style,
  toggleRowCheck,
}: Props): JSX.Element | null {
  const [isCopied, setIsCopied] = useState<boolean>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(
    () => () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  const fixedColumnIndex = columnIndex - fixedColumnCount;
  const fixedRowIndex = rowIndex - fixedRowCount;
  const column = query.columns[fixedColumnIndex];
  const row = rows[fixedRowIndex];
  const newStyle = { ...style, width: columnIndex ? column?.width : checkColumnWidth };
  let containerClass =
    'hover:z-10 hover:overflow-visible overflow-x-auto border p-1 group border-grey-medium';
  if (checkedRows[row._id]) {
    newStyle.backgroundColor = theme.hexToRGB(theme.colors.primary, 0.1);
  } else if (fixedRowIndex % 2) {
    containerClass += ' bg-grey-light';
  }

  // check cell
  if (!columnIndex) {
    const hasLeftCell = row.cells.leftCell || row.cells.leftCell === null;
    return (
      <div
        className={`${containerClass} align-center flex cursor-pointer justify-center`}
        onClick={hasLeftCell ? undefined : () => toggleRowCheck(row)}
        style={newStyle}
      >
        {hasLeftCell ? (
          row.cells.leftCell
        ) : (
          <Icon
            className={checkedRows[row._id] ? 'text-primaryCTA' : ''}
            cypressId={`check-${rowIndex - fixedRowCount}`}
            name={checkedRows[row._id] ? 'check_box' : 'check_box_outline_blank'}
            size={20}
          />
        )}
      </div>
    );
  }

  const { values } = row.cells[column.id] || { values: [] };
  let onClick;
  if (editUi) {
    onClick = () => setEditUi(row);
  } else if (rowUrl) {
    onClick = () => openLink(row);
  }
  const parentClass = `${containerClass} ${column.parentClass ? column.parentClass : ''} ${
    onClick ? 'cursor-pointer' : ''
  } ${row.cells.rowClass || ''}`;
  const text = _.join(_.map(values, 'text'), ', ');

  const handleCopy = (event: MouseEvent) => {
    event.stopPropagation();
    setIsCopied(true);
    copyText(text);
    setTimeoutId(setTimeout(() => setIsCopied(false), 3000));
  };

  const handleOpenEditCell = (event: MouseEvent) => {
    event.stopPropagation();
    openEditCell({ columnIndex: fixedColumnIndex, row });
  };

  return (
    <div
      className={parentClass}
      data-cy={
        _.isFunction(column.cellUi) ? `cell-${rowIndex - fixedRowCount}-${column.key}` : undefined
      }
      key={`${row.id}-${columnIndex}`}
      onClick={onClick}
      style={newStyle}
    >
      <div className="h-full overflow-y-auto">
        {column.cellUi ? (
          column.cellUi({
            column: column,
            optionsMap:
              databaseIdToTableIdToColumnKeyToOptionIdToOption[column.databaseId]?.[column.table]?.[
                column.key
              ],
            row,
            value: _.map(values, 'value'),
          })
        ) : (
          <Text cypressId={`cell-${rowIndex - fixedRowCount}-${column.key}`}>{text}</Text>
        )}
        <div className="align-center absolute bottom-0 right-0 top-0 hidden group-hover:flex">
          {Boolean(text) && text !== Table.NA && (
            <Icon
              color={isCopied ? 'success' : 'primaryCTA'}
              name={isCopied ? 'check' : 'content_copy'}
              onClick={handleCopy}
            />
          )}
          {column.isEditable && (
            <Icon
              className="ml-2 rounded border bg-grey-light p-2"
              color="primaryCTA"
              name="edit"
              onClick={handleOpenEditCell}
            />
          )}
        </div>
      </div>
    </div>
  );
}
