import React from 'react';
import type { Input } from './Form';

interface ContextInterface {
  debounce?: number;
  onChange?: (id: string, inputValue: unknown) => void;
  onRemove?: (id: string) => void;
  onSubmitClick?: () => void;
  register?: (input: Input) => void;
  submitIsDisabled?: boolean;
  unregister?: (id: string) => void;
  validate?: boolean;
  value?: unknown;
}

export default React.createContext<ContextInterface>({} as ContextInterface);
