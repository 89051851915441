import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import U from '@nanaio/util';
import classnames from 'classnames';
import Radio from '../form/Radio';
import Icon from './Icon';
import Text from './Text';

export type Props = {
  description?: ReactNode;
  className?: string;
  onClick?: (title: string) => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  title: string;
  variant: 'row' | 'tile';
  icon?: 'radio' | 'check';
};

export default function RadioButtonTile({
  description,
  className,
  onClick,
  isDisabled: disabled,
  isSelected: selected,
  title,
  variant = 'row',
  icon,
}: Props): JSX.Element {
  const [isSelected, setIsSelected] = useState(Boolean(selected));
  const [isDisabled, setIsDisabled] = useState(Boolean(disabled));

  const boxClassNames = classnames(
    'w-full min-w-[120px] overflow-hidden rounded p-4 ring-1.5 focus:outline-none',
    className,
    {
      'bg-grey-medium opacity-60 ring-grey-4 ': isDisabled,
      'bg-primaryCTA bg-opacity-5 ring-primaryCTA': isSelected,
      'bg-white bg-opacity-100 ring-grey-medium': !isSelected && !isDisabled,
    }
  );

  const handleClick = useCallback(() => {
    setIsSelected(isSelected => !isSelected);
    if (onClick) {
      onClick(title);
    }
  }, [onClick, title]);

  useEffect(() => {
    setIsDisabled(Boolean(disabled));
  }, [disabled]);

  useEffect(() => {
    setIsSelected(Boolean(selected));
  }, [selected]);

  return (
    <button className={boxClassNames} onClick={handleClick} disabled={isDisabled}>
      {variant === 'row' && (
        <div className="flex">
          <div className="flex flex-1 flex-col items-start">
            <Text className="text-secondary" type="page-title">
              {title}
            </Text>
            {U.isText(description) ? (
              <Text className="text-grey-dark">{description}</Text>
            ) : (
              description
            )}
          </div>
          {isSelected && icon === 'check' && <Icon name="check" color="primaryCTA" />}
          {icon === 'radio' && <Radio isSelected={isSelected} />}
        </div>
      )}
      {variant === 'tile' && (
        <div className="flex flex-col">
          {U.isText(description) ? (
            <Text className="mt-2 text-grey-dark">{description}</Text>
          ) : (
            <div className="mt-2 self-center">{description}</div>
          )}
          <Text className="mt-5 text-grey-dark" type="label">
            {title}
          </Text>
        </div>
      )}
    </button>
  );
}
