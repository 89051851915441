import React, { ReactNode, useEffect, useState } from 'react';
import U from '@nanaio/util';
import classnames from 'classnames';
import _ from 'lodash';
import theme from '../theme';
import Button, { Props as ButtonProps } from './Button';
import Icon, { Props as IconProps } from './Icon';
import Text from './Text';

export const colorByVariant = {
  default: 'icons.grey',
  error: 'danger',
  info: 'primary',
  success: 'success',
  warning: 'accent.orange',
};

export const iconColorByVariant = {
  default: 'text-primaryCTA',
  error: 'text-danger',
  info: 'text-primaryCTA',
  success: 'text-success',
  warning: 'text-accent-orange',
};

const iconByVariant: Record<string, IconProps['name']> = {
  error: 'alert_rhombus_outline',
  info: 'info_outline',
  success: 'check_circle_outline',
  warning: 'warning_amber',
};

const borderByVariant = {
  default: 'ring-icons-grey',
  error: 'ring-danger',
  info: 'ring-primaryCTA',
  success: 'ring-success',
  warning: 'ring-accent-orange',
};

export type Props = {
  children: ReactNode;
  className?: string;
  dismissAfterSeconds?: number;
  hideIcon?: boolean;
  icon?: IconProps['name'];
  onDismissed?: () => void;
  dismissable?: boolean;
  variant?: keyof typeof colorByVariant;
  title?: string;
  primaryButtonProps?: Omit<ButtonProps, 'variant'>;
  secondaryButtonProps?: Omit<ButtonProps, 'variant'>;
  helperText?: ReactNode;
};

export default function Alert({
  children,
  className,
  dismissAfterSeconds,
  hideIcon = false,
  icon,
  onDismissed,
  dismissable,
  variant = 'default',
  title,
  primaryButtonProps,
  secondaryButtonProps,
  helperText,
}: Props): JSX.Element | null {
  const [isDismissed, setIsDismissed] = useState(false);

  const color = colorByVariant[variant];
  const boxClassNames = classnames(
    'overflow-hidden rounded bg-white ring-1',
    borderByVariant[variant],
    className
  );
  const bodyMarginClassNames = classnames({ 'ml-8': !hideIcon }, { 'mr-8': dismissable });

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (dismissAfterSeconds && dismissAfterSeconds > 0) {
      timeout = setTimeout(() => setIsDismissed(true), dismissAfterSeconds * 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dismissAfterSeconds]);

  useEffect(() => {
    if (isDismissed && onDismissed) {
      onDismissed();
    }
  }, [isDismissed, onDismissed]);

  if (isDismissed) {
    return null;
  }

  const iconPath = icon ? icon : iconByVariant[variant];

  return (
    <div className={boxClassNames}>
      <div
        className="p-4"
        style={{
          backgroundColor: theme.hexToRGB(_.get(theme.colors, color) as string, 0.05),
        }}
      >
        <div className="flex space-x-3">
          {/* Icon */}
          {!hideIcon && (
            <Icon
              className={classnames('flex-none place-self-start', iconColorByVariant[variant])}
              name={iconPath}
              size={20}
            />
          )}
          {/* Title */}
          {title ? (
            <Text
              className={classnames('flex-auto self-center text-secondary', bodyMarginClassNames)}
              type="button"
            >
              {title}
            </Text>
          ) : (
            <div className={classnames('flex-auto', bodyMarginClassNames)}>
              {U.isText(children) ? <Text className="text-secondary-5">{children}</Text> : children}
            </div>
          )}
          {/* Dismiss Button */}
          {dismissable && (
            <button className="flex-none place-self-start" onClick={() => setIsDismissed(true)}>
              <Icon
                name="close"
                size={24}
                style={{
                  color: theme.hexToRGB(_.get(theme.colors, 'secondary'), 0.5),
                }}
              />
            </button>
          )}
        </div>
        <div className={bodyMarginClassNames}>
          {/* Description */}
          {title && (
            <div>
              {U.isText(children) ? <Text className="text-secondary-5">{children}</Text> : children}
            </div>
          )}
          {/* Button(s) */}
          {(primaryButtonProps || secondaryButtonProps) && (
            <div className="mt-4 flex space-x-4">
              {primaryButtonProps && <Button variant="primary" {...primaryButtonProps} />}
              {secondaryButtonProps && <Button variant="link" {...secondaryButtonProps} />}
            </div>
          )}
          {/* Helper */}
          {helperText && (
            <div className="mt-4 flex space-x-1">
              <Icon name="info_outline" className="text-grey-dark" size={14} />
              {U.isText(helperText) ? (
                <Text className="text-grey-dark" type="helper">
                  {helperText}
                </Text>
              ) : (
                helperText
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
