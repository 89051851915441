import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Form, Input, Text } from '@/components';
import Notes from '../../com/ui/notes';
import NotCoveredServices from './com/NotCoveredServices';

function Profile({ org: originalOrg, regions }) {
  const [error, setError] = useState();
  const [org, setOrg] = useState(originalOrg);

  const originalOrgString = JSON.stringify(originalOrg);

  const regionsArray = _.values(regions);
  const regionNames = regionsArray.length ? regionsArray.map(region => region.name) : [];

  useEffect(() => {
    setOrg(JSON.parse(originalOrgString));
  }, [originalOrgString]);

  const handleAddCertificate = () => setOrg(U.setPush(_.cloneDeep(org), 'resaleCertificates', {}));

  const handleAddOverride = state =>
    setOrg(_.set(_.cloneDeep(org), `authNumber2.stateOvr.${state}`, { state }));

  const handleSave = async () => {
    const changes = _.keys(org)
      .filter(key => !_.isEqual(org[key], originalOrg[key]))
      .map(key => ({ path: key, value: org[key] }));

    const response = await U.api('put', `orgs/${org.id}`, changes, ['save']);

    setError(response.errMsg);
  };

  const removeCertificate = index =>
    setOrg({
      ...org,
      resaleCertificates: _.values(org.resaleCertificates).filter((_, cIndex) => cIndex !== index),
    });

  const removeStateOverride = state => setOrg(_.omit(org, `authNumber2.stateOvr.${state}`));

  return (
    <div className="container mx-auto mt-4">
      <NotCoveredServices orgId={org.id} />

      <Form onChange={setOrg} originalValue={originalOrg} value={org}>
        <div className="grid grid-cols-4 gap-x-4">
          <Input id="address" type="address" />
          <Input id="allowProPurchasedParts" type="bool" />
          <Input id="billsByWorkOrder" type="bool" />
          <Input id="claimNumber2.required" label="Claim Number Required" type="bool" />
          <Input id="completionReviewRedirect" />
          <Input
            id="copay2.amount"
            label="Copay Amount"
            type="money"
            required={org.copay2?.required}
          />
          <Input id="copay2.isOptional" label="Copay Collection Optional" type="bool" />
          <Input id="copay2.required" label="Copay Required" type="bool" />
          <Input
            id="daysToPayInvoice"
            label={`Days to Pay Invoice (Net ${org.daysToPayInvoice || 0})`}
            type="number"
          />
          <Input id="diagnosisReviewRequired" type="bool" />
          <Input id="displayName" />
          <Input id="metadata.forwardReview" type="bool" />
          <Input id="metadata.jobLimits.limitByDay" type="number" />
          <Input id="metadata.jobLimits.limitByDayEnabled" type="bool" />
          <Input id="metadata.jobLimits.limitByWeek" type="number" />
          <Input id="metadata.jobLimits.limitByWeekEnabled" type="bool" />
          <Input id="metadata.maxProOwnedPartPrice" type="money" />
          <Input id="name" required />
          <Input id="partsCostReviewLimit" type="money" />
          <Input id="partsCountReviewLimit" type="number" />
          <Input id="paysShipping" type="bool" />
          <Input id="priority" type="percent" />
          <Input id="proPerformanceIgnoreParts" type="bool" />
          <Input id="recallWindow" type="number" />
          <Input id="rep.name" />
          <Input id="rep.title" />
          <Input id="metadata.requiresDrugScreen" type="bool" />
          <Input id="status" capitalize options={U.org.statusOptions} />
          <Input id="type" capitalize options={U.org.typeOptions} required />
          <Input id="website" />
        </div>
        <div className="grid grid-cols-4 gap-x-4">
          <Input label="Add State Override" onChange={handleAddOverride} options={U.states} />
          <Input id="authNumber2.perAppliance" label="Auth Limit Per Appliance" type="bool" />
          <Input id="authNumber2.amount" label="Auth Number Amount" type="money" />
          <Input id="email" label="Authorization Email" />
          <Input id="phone" label="Authorization Phone" />
          <Input id="phoneExt" label="Authorization Phone Ext" />
          <Input id="completeAuthLimit" type="money" />
          <Input id="onlyAuthorizesOwnBrand" type="bool" />
          <Input
            id="authNumber2.recallWithPartsRequireAuth"
            label="Recall With Parts Require Auth"
            type="bool"
          />
          <Input
            id="authNumber2.sealedSystemRequireAuth"
            label="Sealed System Require Auth"
            type="bool"
          />
          <Input id="shouldCacheVisitsAtCreate" type="bool" />
          <Input
            id="shouldCacheVisitsIfInRegions"
            label="Cache Visits if in Regions (leave blank for any region)"
            options={regionNames}
            type="search"
            multiple
          />
        </div>
        {_.keys(org.authNumber2?.stateOvr).map(state => (
          <div className="grid grid-cols-4 gap-x-4" key={state}>
            <Input
              id={`authNumber2.stateOvr.${state}.state`}
              disabled
              label="Auth Limit Override State"
              options={U.states}
              required
            />
            <Input
              id={`authNumber2.stateOvr.${state}.amount`}
              label="Auth Limit Override Amount"
              required
              type="money"
            />
            <div>
              <Button onClick={() => removeStateOverride(state)} variant="secondary">
                Remove
              </Button>
            </div>
          </div>
        ))}
        {_.map(org.resaleCertificates, (_, index) => (
          <div className="grid grid-cols-5 gap-x-4" key={index}>
            <Input id={`resaleCertificates.${index}.state`} options={U.states} required />
            <Input id={`resaleCertificates.${index}.startDate`} required type="date" />
            <Input id={`resaleCertificates.${index}.expirationDate`} required type="date" />
            <Input id={`resaleCertificates.${index}.url`} required />
            <div>
              <Button onClick={() => removeCertificate(index)} variant="secondary">
                Remove
              </Button>
            </div>
          </div>
        ))}
        <Text color="primaryCTA" onClick={handleAddCertificate}>
          Add Resale Certificate
        </Text>
        <div className="mt-4 flex">
          <Button onClick={handleSave} submit>
            Save Changes
          </Button>
          <Text className="pl-4" color="danger">
            {error}
          </Text>
        </div>
        <Notes
          updateFunc={(id, changes) => U.api('put', `orgs/${id}`, changes, ['save'])}
          savePath="notes"
          loadPath={`orgs.${global.id()}.notes`}
        />
      </Form>
    </div>
  );
}

Profile.propTypes = { org: U.org.propType, regions: PropTypes.objectOf(U.region.propType) };

export default connect(s => ({
  org: s.orgs[global.id()],
  regions: s.regions,
}))(Profile);
