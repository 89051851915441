import React, { useState } from 'react';
import _ from 'lodash';
import { Icon, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import ChangeVendor from '../../../actions/ChangeVendor';
import AuthInfo from './AuthInfo';
import HoverPopup from './HoverPopup';

type Props = {
  taskId: string;
};

export default function VendorButton({ taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const [editVendor, setEditVendor] = useState(false);

  const toggleEditVendor = () => {
    setEditVendor(!editVendor);
  };

  return (
    <>
      <div className="ml-4">
        {task.customer.org?.id ? (
          <div
            className="card-block--border pointer mr-3 bg-white shadow-lifted"
            onClick={toggleEditVendor}
          >
            <HoverPopup
              button={
                <div>
                  <Text color="primaryCTA" type="button">
                    {task.customer.org?.name}
                  </Text>
                  <label className="flex items-center">
                    <Icon name="business" color="grey.dark" size={15} />
                    <Text className="ml-2" color="grey.dark" type="button">
                      Vendor
                    </Text>
                  </label>
                </div>
              }
              menu={
                <div className="p-4">
                  <AuthInfo />
                </div>
              }
            />
          </div>
        ) : (
          <div
            className="card-block--border pointer mr-3 bg-white shadow-lifted"
            onClick={toggleEditVendor}
          >
            <label className="flex items-center">
              <Icon name="add_circle" color="primaryCTA" size={15} />
              <Text className="ml-2" color="primaryCTA" type="button">
                Vendor
              </Text>
            </label>
          </div>
        )}
      </div>

      {editVendor && <ChangeVendor onClose={toggleEditVendor} taskId={taskId} />}
    </>
  );
}
