/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MultiGrid } from 'react-virtualized';
import * as Table from '@nanaio/table';
import U, { ErrorResponse, Me, Option, ServerEntity } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import pluralize from 'pluralize';
import { apiOrigin } from '@/config/const';
import { useDeepCompareMemoRef } from '@/hooks';
import { exportToCsv, idFromURL, openLink } from '@/utils';
import { Loader } from '../core';
import Columns from './Columns'; // eslint-disable-line import/no-cycle
import * as typeToModuleUi from './moduleTypes';
import TableUI from './Table';
import { BulkUpdateResult, EditCell, Export } from './types';

export * from './CommonModules';

declare global {
  var moduleTable: object; // eslint-disable-line no-var
  var table: object; // eslint-disable-line no-var
}

type Props = Table.Table & {
  me: Me;
  roles: Record<string, string>;
};

function TableComponent(props: Props): JSX.Element {
  const { databaseId, me, name, roles } = props;
  const [addIsOpen, setAddIsOpen] = useState<boolean>(false);
  const [bulkUpdate, setBulkUpdate] = useState<Table.BulkUpdate>();
  const [bulkUpdateResult, setBulkUpdateResult] = useState<BulkUpdateResult>();
  const [changeQueryIsOpen, setChangeQueryIsOpen] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<Record<string, boolean>>({});
  const [databaseIdToTableIdToColumnIdToColumn, setDatabaseIdToTableIdToColumnIdToColumn] =
    useState<Table.Depth3<Table.Column>>({});
  const databaseIdToTableIdToColumnIdToColumnRef = useDeepCompareMemoRef(
    databaseIdToTableIdToColumnIdToColumn
  );
  const [databaseIdToTableIdToColumnKeyToIndex, setDatabaseIdToTableIdToColumnKeyToIndex] =
    useState<Table.Depth3<unknown>>({});
  const databaseIdToTableIdToColumnKeyToIndexRef = useDeepCompareMemoRef(
    databaseIdToTableIdToColumnKeyToIndex
  );
  const [
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    setDatabaseIdToTableIdToColumnKeyToOptionIdToOption,
  ] = useState<Table.Depth4<Option>>({});
  const databaseIdToTableIdToColumnKeyToOptionIdToOptionRef = useDeepCompareMemoRef<
    Table.Depth4<Option>
  >(databaseIdToTableIdToColumnKeyToOptionIdToOption);
  const [databaseIdToTableIdToEarliestCreateTime, setDatabaseIdToTableIdToEarliestCreateTime] =
    useState<Table.Depth2<m.MomentInput>>({});
  const databaseIdToTableIdToEarliestCreateTimeRef = useDeepCompareMemoRef(
    databaseIdToTableIdToEarliestCreateTime
  );
  const [databaseIdToTableIdToEndOfResults, setDatabaseIdToTableIdToEndOfResults] = useState<
    Table.Depth2<boolean>
  >({});
  const databaseIdToTableIdToEndOfResultsRef = useDeepCompareMemoRef(
    databaseIdToTableIdToEndOfResults
  );
  const [databaseIdToTableIdToLastPaginatedQuery, setDatabaseIdToTableIdToLastPaginatedQuery] =
    useState<Table.Depth2<Record<string, unknown> | string>>({});
  const databaseIdToTableIdToLastPaginatedQueryRef = useDeepCompareMemoRef(
    databaseIdToTableIdToLastPaginatedQuery
  );
  const [databaseIdToTableIdToRows, setDatabaseIdToTableIdToRows] = useState<
    Table.Depth2<Table.Row[]>
  >({});
  const databaseIdToTableIdToRowsRef = useDeepCompareMemoRef(databaseIdToTableIdToRows);
  const [databaseIdToTableIdToTable, setDatabaseIdToTableIdToTable] = useState<
    Table.Depth2<Table.Table>
  >(_.set(_.cloneDeep(Table.databases), `${databaseId}.${name}`, props));
  const databaseIdToTableIdToTableRef = useDeepCompareMemoRef(databaseIdToTableIdToTable);
  const [editCell, setEditCell] = useState<EditCell>();
  const [editColumn, setEditColumn] = useState<Table.Column>();
  const [editId, setEditId] = useState<string>();
  const [editQueryPopupIsOpen, setEditQueryPopupIsOpen] = useState<boolean>(false);
  const [editTableIsOpen, setEditTableIsOpen] = useState<boolean>(false);
  const [endOfResults, setEndOfResults] = useState<boolean>(false);
  const [isLimit1000, setIsLimit1000] = useState(true);
  const isLimit1000Ref = useDeepCompareMemoRef(isLimit1000);
  const [isMounted, setIsMounted] = useState(false);
  const [rowIds, setRowIds] = useState<string[]>();
  const rowIdsRef = useDeepCompareMemoRef(rowIds);
  const [initialQuery, setInitialQuery] = useState<Table.Query>();
  const initialQueryRef = useDeepCompareMemoRef(initialQuery);
  const [initialLoad, setInitialLoad] = useState(true);
  const [lastExportTime, setLastExportTime] = useState<m.MomentInput>();
  const [lastQuery, setLastQuery] = useState<Table.Query>();
  const [lastRefresh, setLastRefresh] = useState(m().valueOf());
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [moduleIdToStore, setModuleIdToStore] = useState<Record<string, unknown>>({});
  const moduleIdToStoreRef = useDeepCompareMemoRef(moduleIdToStore);
  const [modulesIsOpen, setModulesIsOpen] = useState(false);
  const multiGridRef = useRef<MultiGrid>();
  const [patching, setPatching] = useState(false);
  const [query, setQuery] = useState<Table.Query>();
  const table = query ? databaseIdToTableIdToTable[query.databaseId][query.table] : props;
  const filtersRef = useDeepCompareMemoRef(table.filters);
  const queryRef = useDeepCompareMemoRef(query);
  const [queryIsOpen, setQueryIsOpen] = useState(false);
  const [queryOptions, setQueryOptions] = useState<Table.Query[]>([]);
  const [refreshIntervalId, setRefreshTimeoutId] = useState<NodeJS.Timeout>();
  const [requestToIsLoading, setRequestToIsLoading] = useState({});
  const isLoading = Boolean(_.keys(requestToIsLoading).length);
  const requestToIsLoadingRef = useDeepCompareMemoRef(requestToIsLoading);
  const [rows, setRows] = useState<Table.Row[]>([]);
  const newNamePlural = _.startCase(
    table.namePlural || (_.endsWith(table.name, 's') ? `${table.name}es` : `${table.name}s`)
  );
  const namePlural = newNamePlural;
  const tableRef = useDeepCompareMemoRef(table);
  const [tableToLastQuery, setTableToLastQuery] = useState<
    Record<string, Record<string, unknown> | string | undefined>
  >({});
  const tableToLastQueryRef = useDeepCompareMemoRef(tableToLastQuery);
  const [tableVisible, setTableVisible] = useState(true);

  global.table = {
    columnIdToName: _.mapValues(_.keyBy(query?.columns, 'id'), 'name'),
    columnIds: _.map(query?.columns, 'id'),
    databaseIdToTableIdToColumnIdToColumn,
    databaseIdToTableIdToColumnKeyToIndex,
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    databaseIdToTableIdToEarliestCreateTime,
    databaseIdToTableIdToLastPaginatedQuery,
    databaseIdToTableIdToRows,
    databaseIdToTableIdToTable,
    moduleIdToStore,
    query,
    queryOptions,
    rows,
    table,
    tableToLastQuery,
    vectors: query?.analytics?.vectors,
  };

  /** closes edit cell popup */
  const closeEditCell = () => {
    setEditCell(undefined);
  };

  /** close edit column popup */
  const closeEditColumn = () => {
    setBulkUpdate(undefined);
    setBulkUpdateResult({});
    setEditColumn(undefined);
  };

  /** exports visible rows to CSV */
  const exportToCsvController = (id?: string) => {
    if (modulesIsOpen) {
      setLastExportTime(Date.now());
      return;
    }
    if (query?.analytics.isOpen && id !== Export.TABLE) {
      setLastExportTime(Date.now());
    } else {
      let newRows = _.cloneDeep(rows);
      if (newRows.some(row => checkedRows[row._id])) {
        newRows = newRows.filter(row => checkedRows[row._id]);
      }
      if (rowIds) {
        const rowIdMap = _.keyBy(rowIds);
        newRows = newRows.filter(row => rowIdMap[row._id]);
      }
      const exportableColumns = query?.columns.filter(column => !column.noExport) || [];
      const exportRows = [
        _.compact([
          tableRef.current.rowUrl ? 'Link' : undefined,
          ..._.map(exportableColumns, 'name'),
        ]),
        ...newRows.map(row => {
          const path = tableRef.current.rowUrl ? tableRef.current.rowUrl({ row }) : undefined;
          const url = tableRef.current.rowUrl && path ? [apiOrigin + path] : [];
          return [
            ...url,
            ...exportableColumns.map(column =>
              _.join(_.map(row.cells[column.id].values, 'text'), ', ')
            ),
          ];
        }),
      ];
      exportToCsv(`${_.startCase(namePlural)}.csv`, exportRows);
    }
  };

  /** intercept table query changes and optionally modify them before saving */
  const handleSetQuery = async (id: string, value: unknown) => {
    const queryCopy = _.cloneDeep(queryRef.current);
    if (!queryCopy) {
      return;
    }
    _.set(queryCopy, id, value);

    if (id.endsWith('search.type')) {
      // only allow one module to be pivoted at a time
      if (value === Table.SearchType.PIVOT) {
        const match = id.match(/\d+/)?.[0];
        const updatedModuleIndex = match ? Number(match) : undefined;
        for (const [i, module] of queryCopy.columns.entries()) {
          if (i !== updatedModuleIndex && module.search?.type === Table.SearchType.PIVOT) {
            delete module.search.type;
          }
        }
      }

      // refresh column when unique filter added or removed
      const oldValue = _.get(queryRef.current, id) as Table.SearchType;
      if (oldValue === Table.SearchType.UNIQUE || value === Table.SearchType.UNIQUE) {
        const columnIndex = id.match(/\d+/)?.[0];
        if (columnIndex) {
          const refreshColumnKey = queryCopy.columns[Number(columnIndex)].key;
          const result = await getQueryController({ query: queryCopy, refreshColumnKey });
          setDatabaseIdToTableIdToRows(result.databaseIdToTableIdToRows);
        }
      }
    }

    setQuery(queryCopy);
  };

  const init = async (table: Table.Table) => {
    const databaseIdToTableIdToColumnIdToColumn =
      await Table.getDatabaseIdToTableIdToColumnIdToColumn({
        isModules: tableRef.current.isModules,
        table,
        typeToModuleUi,
      });
    setDatabaseIdToTableIdToColumnIdToColumn(databaseIdToTableIdToColumnIdToColumn);

    // column options
    const databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option> = {};
    _.forEach(databaseIdToTableIdToColumnIdToColumn, tableIdToColumnIdToColumn => {
      _.forEach(tableIdToColumnIdToColumn, columnIdToColumn => {
        _.forEach(columnIdToColumn, column => {
          if (column.options) {
            _.set(
              databaseIdToTableIdToColumnKeyToOptionIdToOption,
              `${column.databaseId}.${column.table}.${column.key}`,
              _.keyBy(U.toOptions(column.options), 'id')
            );
          }
        });
      });
    });
    setDatabaseIdToTableIdToColumnKeyToOptionIdToOption(
      databaseIdToTableIdToColumnKeyToOptionIdToOption
    );

    const queryId = idFromURL();
    void getQueryController({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToColumnKeyToOptionIdToOption,
      initialLoad: true,
      queryIds: tableRef.current.isModules || !queryId ? undefined : [queryId],
    });
    if (!tableRef.current.disablePolling && !tableRef.current.isModules) {
      setRefreshTimeoutId(setInterval(() => void onRefresh(), 1000 * 60 * 3));
    }
    setIsMounted(true);
  };

  const loadRowsById = async (ids: string[]): Promise<void> => {
    await onSearchController({ refreshIds: ids });
  };

  const loadInitialRows = ({
    databaseIdToTableIdToColumnIdToColumn,
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    databaseIdToTableIdToRows,
    query,
  }: {
    databaseIdToTableIdToColumnIdToColumn?: Table.Depth3<Table.Column>;
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    databaseIdToTableIdToRows?: Table.Depth2<Table.Row[]>;
    query?: Table.Query;
  }) => {
    const queryCopy = nullthrows(query || queryRef.current);
    let limit = 100;
    if (queryCopy.analytics.isOpen) {
      setIsLimit1000(!queryCopy.id);
      limit = 1000;
    }
    void onSearchController({
      databaseIdToTableIdToColumnIdToColumn,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOption ||
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      databaseIdToTableIdToRows,
      limit,
      query: queryCopy,
    });
  };

  /** load options for 'pick' modules */
  const loadModuleIdToOptions = async (column: Table.Column) => {
    const result = await Table.loadModuleIdToOptions({
      column,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
    });
    if (result) {
      const databaseIdToTableIdToColumnKeyToOptionIdToOptionCopy = _.cloneDeep(
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current
      );
      _.set(
        databaseIdToTableIdToColumnKeyToOptionIdToOptionCopy,
        `${column.databaseId}.${column.table}.${column.key}`,
        result.optionIdToOption
      );
      setDatabaseIdToTableIdToColumnKeyToOptionIdToOption(
        databaseIdToTableIdToColumnKeyToOptionIdToOptionCopy
      );
    }
    return result;
  };

  /** update table when the query changes or when the table first loads */
  const getQueryController = async ({
    databaseIdToTableIdToColumnIdToColumn,
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    initialLoad,
    query,
    queryIds,
    refreshColumnKey,
    tempChange,
  }: {
    databaseIdToTableIdToColumnIdToColumn?: Table.Depth3<Table.Column>;
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    initialLoad?: boolean;
    query?: Table.PartialQuery;
    queryIds?: string[];
    refreshColumnKey?: string;
    tempChange?: boolean;
  }): Promise<{
    databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
    databaseIdToTableIdToRows: Table.Depth2<Table.Row[]>;
    databaseIdToTableIdToTable: Table.Depth2<Table.Table>;
    query: Table.Query;
  }> => {
    setRequestToIsLoading({ ...requestToIsLoadingRef.current, loadQuery: true });
    const response = await Table.getQuery({
      databaseIdToTableIdToColumnIdToColumn:
        databaseIdToTableIdToColumnIdToColumn || databaseIdToTableIdToColumnIdToColumnRef.current,
      databaseIdToTableIdToColumnKeyToIndex: databaseIdToTableIdToColumnKeyToIndexRef.current,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOption ||
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      databaseIdToTableIdToRows: databaseIdToTableIdToRowsRef.current,
      databaseIdToTableIdToTable: databaseIdToTableIdToTableRef.current,
      initialQuery: initialQueryRef.current,
      initialLoad,
      isLimit1000: isLimit1000Ref.current,
      moduleIdToStore: moduleIdToStoreRef.current,
      query: query || { columns: [], databaseId: databaseId, table: name },
      queryIds: queryIds,
      queryOptions: queryOptions,
      queryOverride: tableRef.current.queryOverride,
      refreshColumnKey,
      roles,
      tempChange,
      typeToModuleUi,
    });
    setDatabaseIdToTableIdToColumnIdToColumn(response.databaseIdToTableIdToColumnIdToColumn);
    setDatabaseIdToTableIdToColumnKeyToOptionIdToOption(
      response.databaseIdToTableIdToColumnKeyToOptionIdToOption
    );
    setDatabaseIdToTableIdToRows(response.databaseIdToTableIdToRows);
    setDatabaseIdToTableIdToTable(response.databaseIdToTableIdToTable);
    setQuery(response.query);
    setQueryOptions(response.queryOptions);
    setModuleIdToStore(response.moduleIdToStore);
    updateUrlController(response.query);
    if (!tempChange) {
      setInitialQuery(_.cloneDeep(response.query));
      setEditTableIsOpen(false);
    }
    if (initialLoad) {
      let shouldLoadRows = true;

      // don't load rows if a pre-generated report exists
      if (response.query.analytics.isOpen) {
        const reports = await U.api<Table.Report[]>('post', 'analytics/reports/search', {
          limit: 1,
          projection: { _id: 1 },
          query: { tableId: response.query.id },
        });
        if (Array.isArray(reports)) {
          shouldLoadRows = !reports.length;
        }
      }

      if (shouldLoadRows) {
        loadInitialRows({
          databaseIdToTableIdToColumnIdToColumn: response.databaseIdToTableIdToColumnIdToColumn,
          databaseIdToTableIdToColumnKeyToOptionIdToOption:
            response.databaseIdToTableIdToColumnKeyToOptionIdToOption,
          databaseIdToTableIdToRows: response.databaseIdToTableIdToRows,
          query: response.query,
        });
      } else {
        setInitialLoad(false);
      }
    }

    if (multiGridRef?.current) {
      multiGridRef.current.recomputeGridSize();
    }
    if (!tableRef.current.hideTitle) {
      document.title = response.query.name || pluralize(_.startCase(tableRef.current.name));
    }
    setRequestToIsLoading(_.omit(requestToIsLoadingRef.current, 'loadQuery'));
    return response;
  };

  /** loads table queries that are public or created by current user */
  const loadQueryOptions = async (force?: boolean) => {
    if (!force && U.length(queryOptions) > 1) {
      return undefined;
    }
    const query = {
      $or: [
        { isPublic: true, table: tableRef.current.name },
        { user_id: me.userId, table: tableRef.current.name },
      ],
    };
    let queryOptionsCopy = await U.api<Table.Query[]>('post', 'query/search', {
      query,
      limit: -1,
    });
    if ('errMsg' in queryOptionsCopy) {
      return undefined;
    }
    queryOptionsCopy = _.map(_.sortBy(queryOptionsCopy, 'name'), query =>
      Table.addQueryDefaults({
        databaseIdToTableIdToColumnIdToColumn,
        databaseIdToTableIdToTable: databaseIdToTableIdToTableRef.current,
        query,
      })
    );
    setQueryOptions(queryOptionsCopy);
    return undefined;
  };

  /** triggered when user clicks add button on the top-right */
  const onAdd = () => {
    if (tableRef.current.addUrl) {
      openLink({ newTab: true, url: tableRef.current.addUrl });
    } else if (tableRef.current.addUi) {
      toggleAddIsOpen();
    }
  };

  /** update the table when analytics changes */
  const onAnalyticsChange = async (query: Table.Query) => {
    const queryCopy = _.cloneDeep(query);
    let keys: string[] = [];
    if (queryCopy.analytics.isOpen) {
      keys = Table.getAnalyticsModuleIds({ query: queryCopy });
    }
    for (const key of keys) {
      const column = queryCopy.columns.find(column => column.key === key);
      if (column) {
        column.unloaded = false;
      }
    }
    const result = await getQueryController({ query: queryCopy });
    return { ...result, rows: result.databaseIdToTableIdToRows[table.databaseId]?.[table.name] };
  };

  /** save updates to checked rows */
  const onBulkUpdate = async ({ checkedRows }: { checkedRows: Table.Row[] }) => {
    if (!bulkUpdate || !editColumn) {
      return undefined;
    }
    if (editColumn.isArray && !bulkUpdate.action) {
      return setBulkUpdateResult({ error: 'Action required' });
    }
    const requests = [];
    const bulkUpdateLimit = 500;
    const idToFilteredRow = _.keyBy(checkedRows, 'id');
    const rowIdToRow = _.keyBy(
      databaseIdToTableIdToRowsRef.current[table.databaseId]?.[table.name],
      'id'
    );
    const rowsToUpdate = checkedRows
      .slice(0, bulkUpdateLimit)
      .map(checkedRow => rowIdToRow[checkedRow.id]);

    for (const row of rowsToUpdate) {
      if (bulkUpdate.action === Table.BulkUpdateAction.ADD && editColumn.bulkAdd) {
        requests.push({
          id: row.id,
          request: () => editColumn.bulkAdd({ row, value: bulkUpdate.value }),
        });
      } else {
        const { values } = idToFilteredRow[row.id].cells[editColumn.id];
        for (const value of values) {
          const originalValue = _.map(row.cells[editColumn.id].values, 'value');
          let newValue = bulkUpdate.value;
          if (bulkUpdate?.action === Table.BulkUpdateAction.ADD) {
            newValue = _.uniq([..._.values(originalValue), ...(bulkUpdate.value || [])]);
          } else if (bulkUpdate?.action === Table.BulkUpdateAction.REMOVE) {
            newValue = _.uniq([
              ..._.values(originalValue).filter(row => !(bulkUpdate.value || []).includes(row)),
            ]);
          }
          requests.push({
            id: value.ids[0] as string,
            request: () =>
              editColumn.save({
                bulkUpdate,
                column: editColumn,
                ids: value.ids,
                module: editColumn,
                originalValue,
                value: newValue,
              }),
          });
        }
      }
    }
    const chunks = _.chunk(requests, 6);
    const responses: { id: string; response: ErrorResponse | ServerEntity }[] = [];
    for (const chunk of chunks) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        chunk.map(async request =>
          responses.push({ id: request.id, response: (await request.request()) as ServerEntity })
        )
      );
      setBulkUpdateResult({
        total: requests.length,
        succeeded: responses.filter(
          response => !response.response || !('errMsg' in response.response)
        ).length,
        failed: responses.filter(response => !response.response || !('errMsg' in response.response))
          .length,
        responses,
      });
    }
    if (responses.length) {
      await onSearchController({ refreshIds: _.uniq(_.map(responses, 'id')) });
    }
    return undefined;
  };

  /** clear all table filters */
  const onClearFilters = () => {
    if (!query) {
      return;
    }
    const queryCopy = _.cloneDeep(query);
    queryCopy.columns = queryCopy.columns.map(module => _.omit(module, 'search'));
    setQuery(queryCopy);
  };

  const onEditCellChange = (index: number, value: unknown) => {
    if (!editCell) {
      return;
    }
    const newEditCell = _.cloneDeep(editCell);
    _.set(newEditCell, `values.${index}.value`, value);
    setEditCell(newEditCell);
  };

  /** update the filter text which is shown at the top of the column */
  const onFilterChange = (value?: unknown, propEditColumn?: Table.Column) => {
    const column = propEditColumn || editColumn;
    if (!column) {
      return;
    }
    if (table.onFilterChange && !propEditColumn) {
      table.onFilterChange({ key: column.key, value });
    }
    const columnIndex = _.findIndex(queryRef.current?.columns, module => module.key === column.key);
    let out = '';
    if (column.filterIsValid ? column.filterIsValid(value) : value) {
      const optionIdToOption =
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current[column.databaseId]?.[
          column.table
        ]?.[column.key] || column.options;
      out = column.filterText
        ? column.filterText({
            column,
            optionsMap: optionIdToOption,
            rows: databaseIdToTableIdToRowsRef.current[column.databaseId]?.[column.table],
            search: value,
          })
        : String(value);
    }
    setQuery(query => {
      if (query) {
        const queryCopy = _.cloneDeep(query);
        _.set(queryCopy, `columns.${columnIndex}.search.text`, out);
        _.set(queryCopy, `columns.${columnIndex}.search.value`, value);
        if (!queryCopy.analytics.isOpen && !propEditColumn) {
          void onSearchController({ query: queryCopy });
        }
        return queryCopy;
      }
      return query;
    });
    setEndOfResults(false);
  };

  /** fetch data for a currently unloaded column */
  const onLoadColumn = async (columnIndex: number) => {
    if (!queryRef.current) {
      return;
    }
    const queryCopy = _.set(
      _.cloneDeep(queryRef.current),
      `columns.${columnIndex}.unloaded`,
      false
    );
    await patchRowsController({ query: queryCopy, saveQuery: true });
    if (multiGridRef.current) {
      multiGridRef.current.recomputeGridSize();
    }
  };

  /** update the table when query is changed from the queries page */
  const onQueryChange = async (query: Table.PartialQuery, tempChange?: boolean) => {
    const results = await getQueryController({ query, tempChange });
    return { ...results, rows: results.databaseIdToTableIdToRows[table.databaseId]?.[table.name] };
  };

  /** update the table when query is changed from the header dropdown */
  const onQueryChangeFromHeader = async ({
    databaseId,
    queryId,
    tableId,
  }: {
    databaseId: Table.DatabaseId;
    queryId: string;
    tableId: string;
  }) => {
    setChangeQueryIsOpen(false);
    let query: Table.PartialQuery = { columns: [], databaseId, table: tableId };

    if (queryId !== 'Default') {
      const response = await U.api<Table.Query>('get', `query/${queryId}`);

      if (!('errMsg' in response)) {
        query = response;
      }
    }

    const { query: newQuery, databaseIdToTableIdToTable } = await getQueryController({ query });

    if (!newQuery.analytics.isOpen) {
      await onSearchController({ databaseIdToTableIdToTable, query: newQuery });
    }
  };

  /** executes when the table periodically refreshes itself */
  const onRefresh = () => {
    if (document.hidden || queryRef.current?.analytics.isOpen) {
      return undefined;
    }
    const refreshQuery = {
      $or: [{ createTime: { $gte: lastRefresh } }, { lastModified: { $gte: lastRefresh } }],
    };
    setLastRefresh(m().valueOf());
    return onSearchController({ refreshQuery });
  };

  /** fetch records from the api */
  const onSearchController = async ({
    columnIndex,
    databaseIdToTableIdToColumnIdToColumn,
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    databaseIdToTableIdToRows,
    databaseIdToTableIdToTable,
    limit,
    query: propQuery,
    refreshIds,
    refreshQuery,
    value,
  }: {
    columnIndex?: number;
    databaseIdToTableIdToColumnIdToColumn?: Table.Depth3<Table.Column>;
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    databaseIdToTableIdToRows?: Table.Depth2<Table.Row[]>;
    databaseIdToTableIdToTable?: Table.Depth2<Table.Table>;
    limit?: number | 'reset';
    query?: Table.Query;
    refreshIds?: string[];
    refreshQuery?: Record<string, unknown>;
    value?: unknown;
  }) => {
    if (limit === 'reset') {
      setDatabaseIdToTableIdToRows({});
      return;
    }
    setRequestToIsLoading({ ...requestToIsLoadingRef.current, onSearch: true });
    const result = await Table.handleSearch({
      columnIndex,
      databaseIdToTableIdToColumnIdToColumn:
        databaseIdToTableIdToColumnIdToColumn || databaseIdToTableIdToColumnIdToColumnRef.current,
      databaseIdToTableIdToColumnKeyToIndex: databaseIdToTableIdToColumnKeyToIndexRef.current,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOption ||
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      databaseIdToTableIdToEarliestCreateTime: databaseIdToTableIdToEarliestCreateTimeRef.current,
      databaseIdToTableIdToEndOfResults: databaseIdToTableIdToEndOfResultsRef.current,
      databaseIdToTableIdToLastPaginatedQuery: databaseIdToTableIdToLastPaginatedQueryRef.current,
      databaseIdToTableIdToRows: databaseIdToTableIdToRows || databaseIdToTableIdToRowsRef.current,
      databaseIdToTableIdToTable:
        databaseIdToTableIdToTable || databaseIdToTableIdToTableRef.current,
      initialLoad,
      isLimit1000: isLimit1000Ref.current,
      limit,
      loading: isLoading,
      moduleIdToStore: moduleIdToStoreRef.current,
      query: propQuery,
      refreshIds,
      refreshQuery,
      stateQuery: query,
      tableToLastQuery: tableToLastQueryRef.current,
      value,
    });
    if (result) {
      setDatabaseIdToTableIdToColumnKeyToIndex(result.databaseIdToTableIdToColumnKeyToIndex);
      setDatabaseIdToTableIdToEarliestCreateTime(result.databaseIdToTableIdToEarliestCreateTime);
      setDatabaseIdToTableIdToEndOfResults(result.databaseIdToTableIdToEndOfResults);
      setDatabaseIdToTableIdToLastPaginatedQuery(result.databaseIdToTableIdToLastPaginatedQuery);
      setDatabaseIdToTableIdToRows(result.databaseIdToTableIdToRows);
      setModuleIdToStore(result.moduleIdToStore);
      setTableToLastQuery(result.tableToLastQuery);
    }
    setInitialLoad(false);
    setRequestToIsLoading(_.omit(requestToIsLoadingRef.current, 'onSearch'));
  };

  const onSearchTypeChange = () => {
    setEndOfResults(false);
  };

  /** update column sort settings */
  const onSort = (columnIndex: number) => {
    if (!query) {
      return;
    }
    const queryCopy = _.cloneDeep(query);
    const column = queryCopy.columns[columnIndex];
    if (queryCopy.sort.key === column.key) {
      U.setToggle(queryCopy, 'sort.isDescending');
    } else {
      queryCopy.sort = { key: column.key };
    }
    setQuery(queryCopy);
  };

  /** called when you type into a 'text' type column filter */
  const onTextFilterChange = (columnIndex: number, value: unknown) => {
    setEndOfResults(false);
    if (!queryRef.current) {
      return;
    }
    const queryCopy = _.cloneDeep(queryRef.current);
    _.set(queryCopy, `columns.${columnIndex}.search.text`, value);
    _.set(queryCopy, `columns.${columnIndex}.search.value`, value);
    setQuery(queryCopy);
  };

  /** opens edit cell popup */
  const openEditCell = ({ columnIndex, row }: { columnIndex: number; row: Table.Row }) => {
    if (!queryRef.current) {
      return;
    }
    const module = queryRef.current.columns[columnIndex];
    const values = module.getEditIdsAndValues
      ? module.getEditIdsAndValues({ row })
      : Table.getIdsAndValues(row, _.isEmpty(module.editPath) ? module.path : module.editPath);

    const editCell = {
      columnIndex,
      originalValues: _.cloneDeep(values),
      values: _.cloneDeep(values),
    };
    setEditCell(editCell);
    void loadModuleIdToOptions(module);
  };

  /** open a popup window that lets you enter filters for a module */
  const openEditColumn = (module: Table.Column, clickedSettingsIcon?: boolean) => {
    const shouldOpenPopup = Boolean(module.filterUi || clickedSettingsIcon);
    if (shouldOpenPopup) {
      setEditColumn(module);
      void loadModuleIdToOptions(module);
    }
  };

  /** open a table record (such as a job) in a new tab */
  const openLinkController = (row: Table.Row) => {
    if (!tableRef.current.rowUrl) {
      return;
    }
    const pivotColumn = queryRef.current?.columns.find(
      column => _.get(column, 'search.type') === 'pivot'
    );
    const pivotValue = pivotColumn ? _.map(row.cells[pivotColumn.key].values, 'value') : undefined;
    const url = tableRef.current.rowUrl({ row, pivotColumn, pivotValue });
    if (url) {
      openLink({ newTab: true, url });
    }
  };

  /** reload rows when projection changes */
  const patchRowsController = async ({
    databaseIdToTableIdToColumnKeyToOptionIdToOption,
    query: propQuery,
    saveQuery,
  }: {
    databaseIdToTableIdToColumnKeyToOptionIdToOption?: Table.Depth4<Option>;
    query: Table.Query;
    saveQuery?: boolean;
  }): Promise<Table.Depth2<Table.Row[]>> => {
    setPatching(true);
    const result = await Table.handlePatch({
      databaseIdToTableIdToColumnIdToColumn: databaseIdToTableIdToColumnIdToColumnRef.current,
      databaseIdToTableIdToColumnKeyToIndex: databaseIdToTableIdToColumnKeyToIndexRef.current,
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOption ||
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      databaseIdToTableIdToRows: databaseIdToTableIdToRowsRef.current,
      databaseIdToTableIdToTable: databaseIdToTableIdToTableRef.current,
      isLimit1000,
      lastQuery: lastQuery || initialQueryRef.current,
      moduleIdToStore: moduleIdToStoreRef.current,
      query: propQuery || queryRef.current,
    });
    setDatabaseIdToTableIdToRows(result.databaseIdToTableIdToRows);
    if (saveQuery) {
      setQuery(propQuery || query);
    }
    setLastQuery(propQuery);
    setPatching(false);
    return result.databaseIdToTableIdToRows;
  };

  /** remove an row from the table */
  const removeRow = () => {
    const databaseIdToTableIdToRows = _.cloneDeep(databaseIdToTableIdToRowsRef.current);
    _.set(
      databaseIdToTableIdToRows,
      `${tableRef.current.databaseId}.${tableRef.current.name}`,
      _.filter(
        databaseIdToTableIdToRows[tableRef.current.databaseId]?.[tableRef.current.name],
        row => row._id !== editId
      )
    );
    setEditId(undefined);
    setDatabaseIdToTableIdToRows(databaseIdToTableIdToRows);
  };

  /** reverts any unsaved bulk updates */
  const resetBulkUpdate = () => {
    setBulkUpdate(undefined);
  };

  /** reverts any unsaved changes to a cell's value */
  const resetEditCell = () => {
    if (!editCell) {
      return;
    }
    const newEditCell = { ...editCell, values: editCell.originalValues };
    setEditCell(newEditCell);
  };

  /** updates a property of an row */
  const saveEditCell = async () => {
    if (!editCell || !queryRef.current) {
      return undefined;
    }
    const { columnIndex, originalValues, values } = editCell;
    const column = queryRef.current.columns[columnIndex];
    for (const [i, value] of values.entries()) {
      if (_.isEqual(value, originalValues[i])) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-await-in-loop
      const response = (await column.save({
        column,
        ids: value.ids,
        module: column,
        originalValue: originalValues[i].value,
        value: value.value,
      })) as ErrorResponse | Table.Row;
      if ('errMsg' in response) {
        const newEditCell = { ...editCell, error: response.errMsg };
        return setEditCell(newEditCell);
      }
    }
    await onSearchController({ refreshIds: _.uniq(_.map(values, 'ids.0')) });
    setEditCell(undefined);
    return undefined;
  };

  /** opens edit row ui */
  const setEditUi = (row: Table.Row) => {
    setEditId(row._id || row.id);
  };

  /** only show the selected row ids */
  const showRows = (rowIds?: string[]) => {
    setRowIds(rowIds);
  };

  /** opens add record popup */
  const toggleAddIsOpen = () => {
    if (addIsOpen) {
      void onSearchController({});
    }
    setAddIsOpen(!addIsOpen);
  };

  /** checks or un-checks all rows */
  const toggleAllRowsChecked = () => {
    if (!queryRef.current) {
      return;
    }
    const rows = Table.getRows({
      databaseIdToTableIdToColumnKeyToOptionIdToOption:
        databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      databaseIdToTableIdToRows: databaseIdToTableIdToRowsRef.current,
      moduleIdToModule:
        databaseIdToTableIdToColumnIdToColumnRef.current[queryRef.current.databaseId]?.[
          queryRef.current.table
        ],
      query: queryRef.current,
      table,
    });
    if (rows.find(row => !checkedRows[row._id])) {
      const newCheckedRows: Record<string, boolean> = {};
      for (const row of rows) {
        newCheckedRows[row._id] = true;
      }
      setCheckedRows(newCheckedRows);
    } else {
      setCheckedRows({});
    }
  };

  /** show or hide analytics */
  const toggleAnalytics = async () => {
    setLastQuery(query);
    if (!queryRef.current) {
      return;
    }
    const queryCopy = _.cloneDeep(queryRef.current);
    queryCopy.analytics.isOpen = !queryCopy.analytics.isOpen;
    const updates = await onAnalyticsChange(queryCopy);
    if (updates.query.analytics.isOpen) {
      setDatabaseIdToTableIdToRows({});
      void onSearchController({ query: updates.query });
    }
  };

  /** hide edit popup */
  const toggleEditIsOpen = () => {
    setEditId(undefined);
  };

  /** checks or un-checks an row */
  const toggleRowCheck = (row: Table.Row) => {
    const newCheckedRows = { ...checkedRows, [row._id]: !checkedRows[row._id] };
    setCheckedRows(newCheckedRows);
  };

  /** open query popup */
  const toggleQueryIsOpen = () => {
    setQueryIsOpen(!queryIsOpen);
  };

  /** show or hide bulk message */
  const toggleMessage = () => {
    setMessageIsOpen(!messageIsOpen);
  };

  /** called when filter values passed via props change */
  const updateFilters = async () => {
    if (!filtersRef.current || !queryRef.current) {
      return;
    }
    for (const { key, value } of filtersRef.current) {
      await loadModuleIdToOptions(
        databaseIdToTableIdToColumnIdToColumnRef.current[queryRef.current.databaseId][
          queryRef.current.table
        ][key]
      );
      const index = _.findIndex(queryRef.current.columns, module => module.key === key);
      if (index === -1) {
        return;
      }
      const module = queryRef.current.columns[index];
      setQuery(query => (query ? _.set(query, `columns.${index}.search.value`, value) : undefined));
      onFilterChange(value, module);
    }
  };

  /** close edit popup after loading newly added / edited row */
  const updateRow = (row: ServerEntity) => {
    if (row) {
      void onSearchController({ refreshQuery: { _id: row._id } });
    }
    setAddIsOpen(false);
    setEditId(undefined);
  };

  /** update url when query changes */
  const updateUrlController = (query: Table.Query) => {
    if (query.id) {
      const url = `${global.location.pathname.replace(/query.*/, '').replace(/\/$/, '')}/query/${
        query.id
      }`;
      window.history.pushState(undefined, query.name, url);
    }
  };

  useEffect(() => {
    void init(props);
    return () => {
      if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted) {
      void updateFilters();
    }
  }, [filtersRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      const databaseIdCopy = queryRef.current?.databaseId || databaseId;
      const tableId = queryRef.current?.table || name;
      setRows(
        Table.getRows({
          databaseIdToTableIdToColumnKeyToOptionIdToOption:
            databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
          databaseIdToTableIdToRows: databaseIdToTableIdToRowsRef.current,
          excludeFromAnalytics: tableRef.current.excludeFromAnalytics,
          moduleIdToModule:
            databaseIdToTableIdToColumnIdToColumnRef.current[databaseIdCopy]?.[tableId],
          query: queryRef.current,
          rowIds: rowIdsRef.current,
          table: tableRef.current,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      databaseIdToTableIdToRowsRef.current,
      databaseIdToTableIdToColumnKeyToOptionIdToOptionRef.current,
      queryRef.current,
      rowIdsRef.current,
      tableRef.current,
    ]
  );

  // if the query changes the rows may change so we should recompute row heights
  // i don't know why all query changes don't trigger getQueryController which
  // handles this case 🤷
  useEffect(() => {
    if (multiGridRef?.current) {
      multiGridRef.current.recomputeGridSize();
    }
  }, [query, multiGridRef]);

  if (!query || !isMounted) {
    return <Loader fullscreen isLoading size="large" />;
  }

  if (modulesIsOpen) {
    return (
      <Columns
        moduleIdToModule={databaseIdToTableIdToColumnIdToColumn[query.databaseId]?.[query.table]}
        onClose={() => setModulesIsOpen(false)}
        table={tableRef.current.name}
      />
    );
  }

  return (
    <TableUI
      {...{
        addIsOpen,
        addUi: tableRef.current.addUi,
        analyticsDefaults: tableRef.current.analyticsDefaults,
        bulkUpdate,
        bulkUpdateResult,
        changeQueryIsOpen,
        checkedRows,
        closeEditCell,
        closeEditColumn,
        databaseIdToTableIdToColumnIdToColumn,
        databaseIdToTableIdToColumnKeyToOptionIdToOption,
        databaseIdToTableIdToLastPaginatedQuery,
        databaseIdToTableIdToRows,
        databaseIdToTableIdToTable,
        defaultQuery: tableRef.current.defaultQuery,
        editCell,
        editColumn,
        editId,
        editQueryPopupIsOpen,
        editTableIsOpen,
        editUi: tableRef.current.editUi,
        embed: tableRef.current.embed,
        endOfResults,
        exportToCsv: exportToCsvController,
        getRowHeight: tableRef.current.getRowHeight,
        initialQuery,
        isLimit1000,
        isLoading,
        isModules: tableRef.current.isModules,
        lastExportTime,
        loadInitialRows,
        loadRowsById,
        loadQueryOptions,
        messageIsOpen,
        name: tableRef.current.name,
        onAdd,
        onAnalyticsChange,
        onBulkUpdate,
        onClearFilters,
        onEditCellChange,
        onFilterChange,
        onLoadColumn,
        onQueryChange,
        onQueryChangeFromHeader,
        onSearchTypeChange,
        onSetQuery: handleSetQuery,
        openEditCell,
        openEditColumn,
        openLink: openLinkController,
        onRefresh,
        onSearch: onSearchController,
        onSort,
        onTextFilterChange,
        parentModuleIdToModule: tableRef.current.parentModuleIdToModule,
        patching,
        query,
        queryIsOpen,
        queryOptions,
        removeRow,
        resetBulkUpdate,
        resetEditCell,
        roles,
        rowUrl: tableRef.current.rowUrl,
        rows,
        saveEditCell,
        setBulkUpdate,
        setEditUi,
        setIsLimit1000,
        setQuery,
        setTableVisible,
        showRows,
        table,
        tableRef: multiGridRef as React.MutableRefObject<MultiGrid>,
        tableVisible,
        timezoneColumn: tableRef.current.timezoneColumn,
        toggleAddIsOpen,
        toggleAllRowsChecked,
        toggleAnalytics,
        toggleChangeQueryIsOpen: () => setChangeQueryIsOpen(!changeQueryIsOpen),
        toggleEditIsOpen,
        toggleEditTableIsOpen: () => setEditTableIsOpen(!editTableIsOpen),
        toggleEditQueryPopup: () => setEditQueryPopupIsOpen(!editQueryPopupIsOpen),
        toggleRowCheck,
        toggleQueryIsOpen,
        toggleMessage,
        toggleModulesIsOpen: () => {
          setModulesIsOpen(!modulesIsOpen);
          if (tableRef.current.onCloseModules) {
            tableRef.current.onCloseModules();
          }
        },
        updateRow,
        updateUrl: updateUrlController,
        userId: me.userId,
      }}
    />
  );
}

export default connect(s => ({ me: s.me, roles: U.user.roles(s) }))(TableComponent);
