import React from 'react';
import ReactSelect from 'react-select';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

const Search = (p, { t }) => {
  let options = _.map(
    _.filter(p.options, o => o !== undefined && o !== null),
    o => ({
      disabled: o.disabled,
      label: p.nameKey ? _.get(o, p.nameKey) : o.name || o,
      value: p.idPath ? _.get(o, p.idPath) : o.id || o,
    })
  ).filter(o => _.isString(o.label));
  if (p.cap) {
    options = _.map(options, o => ({ ...o, label: _.startCase(o.label) }));
  }
  if (p.sort) {
    options = _.sortBy(options, 'label');
  }
  const value = p.multi ? _.get(t.state, p.id, p.def || []) : _.get(t.state, p.id, '');
  // eslint-disable-next-line no-underscore-dangle
  const _onChange = v => {
    let value;
    if (p.multi) {
      value = v === null ? [] : _.compact(_.map(v, 'value'));
    } else {
      value = v === null ? undefined : v.value;
    }
    if (!p.blockChanges) {
      onChange(t, p.id, value);
    }
    if (p.onChange) {
      p.onChange(value);
    }
  };
  const selectedOption = p.multi
    ? _.map(value, v => _.find(options, o => o.value === v))
    : _.find(options, o => o.value === value);
  return (
    <Group {...p}>
      <div style={{ width: '100%' }}>
        <ReactSelect
          autoFocus={p.autoFocus}
          autosize
          disabled={p.disabled}
          inputProps={{ autoComplete: 'new-password' }}
          isClearable
          isMulti={p.multi}
          menuPortalTarget={p.menuPortalTarget}
          onChange={_onChange}
          onFocus={() => p.onFocus && p.onFocus()}
          onInputChange={v => p.onInputChange && p.onInputChange(v)}
          options={options}
          placeholder={p.hint}
          styles={{
            menuList: base => ({ ...base, maxHeight: '50vh' }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          value={selectedOption}
        />
      </div>
    </Group>
  );
};

Search.contextTypes = { t: T.object.isRequired };

Search.propTypes = {
  autofocus: T.bool,
  blockChanges: T.bool,
  cap: T.bool,
  disabled: T.bool,
  hint: T.string,
  id: T.string.isRequired,
  idPath: T.string,
  label: T.string,
  multi: T.bool,
  nameKey: T.string,
  onInputChange: T.func,
  onChange: T.func,
  onFocus: T.func,
  req: T.bool,
  sort: T.bool,
  type: T.string,
};

export default Search;
