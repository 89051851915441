import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import * as IconPath from '@mdi/js';
import { Icon as MDIIcon } from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import theme from '../theme';
import Tooltip from './Tooltip';

export const iconByName = {
  account_balance: IconPath.mdiBank,
  account_circle: IconPath.mdiAccountCircle,
  account_multiple_outline: IconPath.mdiAccountMultipleOutline,
  account_outline: IconPath.mdiAccountOutline,
  add: IconPath.mdiPlus,
  add_circle: IconPath.mdiPlusCircle,
  add_circle_outline: IconPath.mdiPlusCircleOutline,
  alert_circle: IconPath.mdiAlertCircle,
  alert_circle_outline: IconPath.mdiAlertCircleOutline,
  alert_rhombus_outline: IconPath.mdiAlertRhombusOutline,
  analytics: IconPath.mdiChartBox,
  arrow_left: IconPath.mdiArrowLeft,
  arrow_right: IconPath.mdiArrowRight,
  barcode: IconPath.mdiBarcode,
  briefcase: IconPath.mdiBriefcase,
  build: IconPath.mdiWrench,
  build_circle: IconPath.mdiWrench,
  business: IconPath.mdiDomain,
  book: IconPath.mdiBookOutline,
  briefcase_outline: IconPath.mdiBriefcaseVariantOutline,
  calendar: IconPath.mdiCalendarBlank,
  calendar_blank: IconPath.mdiCalendarBlank,
  calendar_range: IconPath.mdiCalendarRange,
  calendar_month: IconPath.mdiCalendarMonth,
  car: IconPath.mdiCar,
  cash_refund: IconPath.mdiCashRefund,
  cellphone: IconPath.mdiCellphone,
  chat_processing_outline: IconPath.mdiChatProcessingOutline,
  check_box: IconPath.mdiCheckboxMarked,
  check_box_marked_circle_outline: IconPath.mdiCheckboxMarkedCircleOutline,
  check_box_outline: IconPath.mdiCheckboxOutline,
  check_box_outline_blank: IconPath.mdiCheckboxBlankOutline,
  check: IconPath.mdiCheck,
  check_circle: IconPath.mdiCheckCircle,
  check_circle_outline: IconPath.mdiCheckCircleOutline,
  chevron_left: IconPath.mdiChevronLeft,
  chevron_right: IconPath.mdiChevronRight,
  circle: IconPath.mdiCircle,
  circle_in_circle: IconPath.mdiCircleSlice8,
  clipboard_pulse_outline: IconPath.mdiClipboardPulseOutline,
  clock_outline: IconPath.mdiClockOutline,
  clock_fast: IconPath.mdiClockFast,
  close_fullscreen: IconPath.mdiArrowCollapse,
  close: IconPath.mdiClose,
  close_octagon_outline: IconPath.mdiCloseOctagonOutline,
  close_thick: IconPath.mdiCloseThick,
  comment_text_outline: IconPath.mdiCommentTextOutline,
  comment_quote: IconPath.mdiCommentQuote,
  content_copy: IconPath.mdiContentCopy,
  credit_card_outline: IconPath.mdiCreditCardOutline,
  delete_outline: IconPath.mdiDeleteOutline,
  delete: IconPath.mdiDelete,
  door: IconPath.mdiDoor,
  download: IconPath.mdiDownload,
  drag_indicator: IconPath.mdiDragVertical,
  edit: IconPath.mdiPencil,
  edit_outline: IconPath.mdiPencilOutline,
  email: IconPath.mdiEmail,
  email_outline: IconPath.mdiEmailOutline,
  error: IconPath.mdiAlertCircle,
  event: IconPath.mdiCalendar,
  event_note: IconPath.mdiCalendarText,
  exit_to_app: IconPath.mdiExitToApp,
  expand_less: IconPath.mdiChevronUp,
  expand_more: IconPath.mdiChevronDown,
  export_variant: IconPath.mdiExportVariant,
  facebook: IconPath.mdiFacebook,
  facebook_messenger: IconPath.mdiFacebookMessenger,
  filter_alt: IconPath.mdiFilter,
  google: IconPath.mdiGoogle,
  google_plus: IconPath.mdiGooglePlus,
  grid: IconPath.mdiGrid,
  heart: IconPath.mdiHeart,
  heart_outline: IconPath.mdiHeartOutline,
  help: IconPath.mdiHelp,
  help_circle: IconPath.mdiHelpCircle,
  help_outline: IconPath.mdiHelpCircleOutline,
  home: IconPath.mdiHome,
  home_circle: IconPath.mdiHomeCircle,
  home_outline: IconPath.mdiHomeOutline,
  info: IconPath.mdiInformation,
  info_outline: IconPath.mdiInformationOutline,
  image: IconPath.mdiImage,
  launch: IconPath.mdiOpenInNew, // duplicate
  loading: IconPath.mdiLoading,
  laptop: IconPath.mdiLaptop,
  lifebuoy: IconPath.mdiLifebuoy,
  linkedin: IconPath.mdiLinkedin,
  list: IconPath.mdiFormatListBulleted,
  list_box_outline: IconPath.mdiListBoxOutline,
  lock: IconPath.mdiLock,
  lock_outline: IconPath.mdiLockOutline,
  map: IconPath.mdiMap,
  map_marker: IconPath.mdiMapMarker,
  map_marker_outline: IconPath.mdiMapMarkerOutline,
  medical_bag: IconPath.mdiMedicalBag,
  menu: IconPath.mdiMenu,
  menu_down: IconPath.mdiMenuDown,
  menu_up: IconPath.mdiMenuUp,
  menu_left: IconPath.mdiMenuLeft,
  menu_right: IconPath.mdiMenuRight,
  message: IconPath.mdiMessageText,
  minus_thick: IconPath.mdiMinusThick,
  mode_edit: IconPath.mdiPencil,
  navigation_variant_outline: IconPath.mdiNavigationVariantOutline,
  near_me: IconPath.mdiNearMe,
  note_outline: IconPath.mdiNoteOutline,
  north: IconPath.mdiArrowUp,
  north_east: IconPath.mdiArrowTopRight,
  north_west: IconPath.mdiArrowTopLeft,
  notifications: IconPath.mdiBell,
  notifications_none: IconPath.mdiBellOutline,
  open_in_new: IconPath.mdiOpenInNew,
  paid: IconPath.mdiCurrencyUsd,
  package_closed: IconPath.mdiPackageVariantClosed,
  person: IconPath.mdiAccount,
  person_add: IconPath.mdiAccountPlus,
  person_outline: IconPath.mdiAccountOutline,
  phone: IconPath.mdiPhone,
  phone_outline: IconPath.mdiPhoneOutline,
  pivot_table_chart: IconPath.mdiTablePivot,
  place: IconPath.mdiMapMarker,
  play_circle_filled: IconPath.mdiPlayCircle,
  power_settings_new: IconPath.mdiPower,
  priority_high: IconPath.mdiExclamation,
  publish: IconPath.mdiPublish,
  question_answer: IconPath.mdiForum,
  radio_button_checked: IconPath.mdiRadioboxMarked,
  radio_button_unchecked: IconPath.mdiRadioboxBlank,
  refresh: IconPath.mdiRefresh,
  remove: IconPath.mdiMinus,
  report_off: IconPath.mdiInformationOff,
  schedule: IconPath.mdiClockOutline,
  search: IconPath.mdiMagnify,
  send: IconPath.mdiSend,
  send_circle: IconPath.mdiSendCircle,
  settings: IconPath.mdiCog,
  shield_outline: IconPath.mdiShieldOutline,
  shield_check: IconPath.mdiShieldCheck,
  shield_lock_outline: IconPath.mdiShieldLockOutline,
  shimmer: IconPath.mdiShimmer,
  shopping_cart: IconPath.mdiCart,
  show_chart: IconPath.mdiChartLineVariant,
  south: IconPath.mdiArrowDown,
  south_east: IconPath.mdiArrowBottomRight,
  south_west: IconPath.mdiArrowBottomLeft,
  star: IconPath.mdiStar,
  star_circle: IconPath.mdiStarCircle,
  star_half: IconPath.mdiStarHalf,
  star_outline: IconPath.mdiStarOutline,
  star_rate: IconPath.mdiStar, // duplicate
  store: IconPath.mdiStore,
  tag: IconPath.mdiTag,
  tag_outline: IconPath.mdiTagOutline,
  tags: IconPath.mdiTagMultiple,
  text_box_check: IconPath.mdiTextBoxCheck,
  text_box_outline: IconPath.mdiTextBoxEditOutline,
  thumb_up: IconPath.mdiThumbUp,
  thumb_down: IconPath.mdiThumbDown,
  thumb_down_outline: IconPath.mdiThumbDownOutline,
  timeline: IconPath.mdiChartTimelineVariant,
  toolbox_outline: IconPath.mdiToolboxOutline,
  trash_can_outline: IconPath.mdiTrashCanOutline,
  truck_cargo_container: IconPath.mdiTruckCargoContainer,
  truck_fast_outline: IconPath.mdiTruckFastOutline,
  school: IconPath.mdiSchool,
  support: IconPath.mdiLifebuoy,
  update: IconPath.mdiUpdate,
  verified: IconPath.mdiCheckDecagram,
  visibility: IconPath.mdiEye,
  visibility_off: IconPath.mdiEyeOff,
  warning: IconPath.mdiAlert,
  warning_amber: IconPath.mdiAlertOutline,
  work: IconPath.mdiBriefcase,
};

export type Props = {
  className?: string;
  color?: string;
  cypressId?: string;
  name: keyof typeof iconByName;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size?: number;
  spin?: boolean | number;
  style?: React.CSSProperties;
  tooltip?: { node: ReactNode };
};

export default function Icon({
  className = '',
  color,
  cypressId,
  name,
  onClick,
  size = 18,
  spin,
  style = {},
  tooltip,
}: Props): ReactElement {
  const iconStyle = {
    ...(color && { color: _.get(theme.colors, color, color) as string }),
    fontSize: size,
    ...style,
  } as React.CSSProperties;

  // If the correct icon is not found show something so that if the icon had a onClick or tooltip
  // prop it will still be functional.
  const iconPath = iconByName[name] || IconPath.mdiAlertRhombus;

  const body = (
    <div className="inline-block" onClick={onClick}>
      <MDIIcon
        className={classNames(className, { 'cursor-pointer': onClick })}
        path={iconPath}
        style={iconStyle}
        data-cy={cypressId}
        size={`${size}px`}
        spin={spin}
      />
    </div>
  );

  if (tooltip) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Tooltip {...tooltip} trigger={body} />;
  }

  return body;
}
