import React from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import Day from '../Day';
import Section from '../Section';
import { proPropType, rowHeight, sectionHeight, timeSlotsPropType } from '../util';
import Pro from './Pro';

export default function EligibleRows({
  mapIsOpen,
  mapProId,
  proMap,
  pros,
  setMapProId,
  setSortBy,
  setVisit,
  sortBy,
  startTimes,
  timeSlots,
  visit,
  workOrder,
}) {
  let eligiblePros = pros.filter(pro => !visit.pros.find(visitPro => visitPro.id === pro.id));
  if (sortBy === 'Home') {
    eligiblePros = _.sortBy(eligiblePros, pro => _.get(pro, 'homeDistance', Infinity));
  } else if (sortBy === 'Rating') {
    eligiblePros = _.sortBy(eligiblePros, pro => _.get(pro, 'stats.ratingAutomatic', -1)).reverse();
  } else if (sortBy === 'Last Job') {
    eligiblePros = _.sortBy(eligiblePros, pro =>
      m(_.get(pro, 'stats.lastVisit', 0)).valueOf()
    ).reverse();
  } else if (sortBy === 'Age on Platform') {
    eligiblePros = U.timeSort(eligiblePros, 'createTime');
  }

  const sortItems = [
    { title: 'Home' },
    { title: 'Rating' },
    { title: 'Last Job' },
    { title: 'Age on Platform' },
  ].map(item => ({
    ...item,
    disabled: item.title === sortBy,
    onClick: () => setSortBy(item.title),
  }));

  const eligibleSection = {
    key: 'eligibleSection',
    height: sectionHeight,
    content: [
      <Section hasOverlay={mapIsOpen} isFixed title={`Eligible (${eligiblePros.length})`}>
        <div className="flex flex-row">
          <Text className="mr-2" color="grey.dark" type="body-2">
            Sort By:
          </Text>
          <Dropdown items={sortItems} trigger={<Text type="body-2">{sortBy}</Text>} />
        </div>
      </Section>,
      ..._.times(7, i => <Section date={startTimes[i * 3]} hasOverlay={mapIsOpen} key={i} />),
    ],
  };

  const eligibleRows = eligiblePros.map(visitPro => ({
    key: `eligible-${visitPro.id}`,
    height: rowHeight,
    content: [
      <Pro
        pro={proMap[visitPro.id]}
        {...{ mapIsOpen, mapProId, proMap, setMapProId, setVisit, visit, workOrder }}
      />,
      ..._.times(7, i => (
        <Day
          disableHover
          id={visitPro.id}
          key={i}
          pro={proMap[visitPro.id]}
          startTimes={startTimes.slice(i * 3, (i + 1) * 3)}
          timeSlots={timeSlots[visitPro.id]}
          {...{ visit, visitPro }}
        />
      )),
    ],
  }));

  return [eligibleSection, ...eligibleRows];
}

EligibleRows.propTypes = {
  mapIsOpen: PropTypes.bool.isRequired,
  mapProId: PropTypes.string,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  pros: PropTypes.arrayOf(proPropType).isRequired,
  setMapProId: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  sortBy: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  timeSlots: timeSlotsPropType,
};
