import 'react-sticky-table/dist/react-sticky-table.css';

import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Icon } from '@/components';
import getRows from './getRows';

const colorByStatus = {
  claimed: '#3b9cff',
  completed: '#1dc09b',
  visitCompleted: '#27ae60',
  visitCancelled: '#878787',
  preferred: '#d23544',
  pending: '#e67e22',
  suggested: '#e67e22',
  waitingOnSchedule: '#ee6a94',
  inProgress: '#8e44ad',
  recall: '#ffc107',
};

class Timeline extends Component {
  static propTypes = {
    groups: T.array.isRequired,
    openView: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(props) {
    super(props);
    this.state = {};
    this.renderCell = this.renderCell.bind(this);
    this.openRoute = this.openRoute.bind(this);
    this.closeRoute = this.closeRoute.bind(this);
  }

  renderCell(items, slot, proId) {
    const availability =
      _.get(this.props.availability, proId) || _.get(this.props.availability, 'default', []);
    return (
      <td key={slot} style={{ position: 'relative' }}>
        {_.times(
          4,
          n =>
            availability[slot * 4 + n] === false && (
              <div
                key={n}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: `${n * 25}%`,
                  width: '25%',
                  backgroundColor: '#333',
                }}
              />
            )
        )}
        <div className="cell" style={{ position: 'relative' }}>
          {items.map((i, n) => {
            if (i.group === 'abandoned') {
              return (
                <CopyToClipboard key={n} text={i.title.split(' ')[0]}>
                  <div className="abandoned pointer">{i.title}</div>
                </CopyToClipboard>
              );
            }
            const address = i.job.serviceAddress || {};
            const eta = _.get(i, 'eta2.arrive') || _.get(i, 'eta.arrivalTime');
            const order = _.get(i, 'eta2.order');
            const className = `schedule-slot ${i.type} ${
              i.visitStatus === 'cancelled' ? 'visitCancelled' : ''
            } ${i.preferred ? 'preferred' : ''}`;
            const status =
              i.preferred && (i.type === 'pending' || i.type === 'suggested')
                ? 'preferred'
                : i.visitStatus === 'cancelled'
                  ? 'visitCancelled'
                  : i.type;
            return (
              <Tooltip
                key={n}
                classes={{
                  tooltip: 'tool-tip',
                  popper: 'tool-popper',
                }}
                title={
                  <>
                    <h2>
                      {i.job.title} in {address.locality}, {address.region}
                    </h2>
                    <p>
                      Status: <span className="text-blue">{_.startCase(i.job.status)}</span>
                    </p>
                    <p>
                      Client:{' '}
                      <span className="text-blue">{_.startCase(i.job.customer.user.fullName)}</span>
                    </p>
                    {eta && (
                      <p>
                        Status: <span className="text-blue">{m(eta).format('h:mm A')}</span>
                      </p>
                    )}
                    {i.routeOrder && (
                      <p>
                        Order: <span className="text-blue">#{i.routeOrder} in their route</span>
                      </p>
                    )}
                    {i.job.status === 'cancelled' && (
                      <p>
                        Cancellation Reason:{' '}
                        <span className="text-blue">
                          {' '}
                          {_.get(i.job, 'visits.0.metadata.cancelledReason')}
                        </span>
                      </p>
                    )}
                  </>
                }
              >
                <a
                  href={`/tasks/${i.job.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={className}
                  style={{ height: '23px' }}
                >
                  <Tooltip title={<p>View job in the map</p>}>
                    <div className="map-button">
                      <Icon
                        name="place"
                        onClick={e => {
                          e.preventDefault();
                          this.props.openView('map', i.id);
                        }}
                        color={colorByStatus[status]}
                        size={16}
                      />
                    </div>
                  </Tooltip>
                  {i.preferred && i.group === 'pending' && (
                    <Icon className="mr-2" name="star" size={16} />
                  )}
                  <span className="title-text">
                    {order && `${order}`}
                    {i.title
                      .toLowerCase()
                      .split(' ')
                      .map(a => a.charAt(0).toUpperCase() + a.substr(1))
                      .join(' ')}
                  </span>
                </a>
              </Tooltip>
            );
          })}
        </div>
      </td>
    );
  }

  openRoute(rowIndex) {
    this.setState({ activeRowIndex: rowIndex });
  }

  closeRoute() {
    this.setState({ activeRowIndex: '' });
  }

  getIconColor(row) {
    const isToday =
      m().startOf('day').valueOf() === m(row.location.timestamp).startOf('day').valueOf();
    return isToday ? '#26A96C' : '#3c577b';
  }

  viewPro(proId) {
    window.open(`/pros/${proId}`, '_blank').focus();
  }

  render() {
    if (!this.props.groups.length || !this.props.availability) {
      return null;
    }
    const rows = getRows(this.props);
    if (!rows) {
      return null;
    }
    return (
      <div className="timeline">
        <table className="table-striped table-hover table-sm table">
          <thead>
            <tr>
              <th className="text-left" style={{ width: '150px' }}>
                {m().format('h:mm A')}
              </th>
              <th>8 AM - 12 PM</th>
              <th>12 PM - 4 PM</th>
              <th>4 PM - 8 PM</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ width: '150px', cursor: r.isPro ? 'pointer' : 'inherit' }}
                  onClick={r.isPro ? () => this.viewPro(r.proId) : undefined}
                >
                  <div className="d-flex group">
                    <div className="flex-1" style={{ overflow: 'hidden', width: '150px' }}>
                      {r.name}
                    </div>
                    {r.location && (
                      <div className="lastLogin">
                        <Icon
                          name="circle_in_circle"
                          className="group-hover:hidden"
                          color={this.getIconColor(r)}
                        />
                        <div className="time">{m(r.location.timestamp).format('M/D h:mm A')}</div>
                      </div>
                    )}
                  </div>
                </td>
                {r.cells.map((cell, n) => this.renderCell(cell, n, r.proId))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(s => {
  const me = s.users[s.me.userId];
  return { me };
})(Timeline);
