import React from 'react';
import { connect } from 'react-redux';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import propTypes from 'prop-types';
import store from 'store2';
import { DateRange, Modal, Number, Search, Select, Text } from '../../com/ui/form';
import Loader from '../../com/ui/loader';

class Demand extends React.Component {
  static childContextTypes = { t: propTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    let selectedRegions = store.get('demand.selectedRegions');
    if (!_.isArray(selectedRegions)) {
      selectedRegions = [];
    }
    this.state = {
      capacity: 'new',
      dateRange: {
        startTime: mt().startOf('week').valueOf(),
        endTime: mt().endOf('week').valueOf(),
      },
      loading: true,
      selectedRegions,
      service: 'all',
    };
    this.loadVisits(this.state.dateRange);
    document.title = 'Demand / Supply';
  }

  aggregateVisits = visits => {
    const regions = {};
    visits.map(visit => {
      ['all', ...visit.workOrder.regionIds].map(regionId => {
        ['all', ..._.map(visit.workOrder.tasks, 'serviceCatalogs.0.id')].map(serviceId => {
          U.setPush(
            regions,
            `${regionId}.${serviceId}.${visit.dayIndex}.${visit.hourIndex}`,
            visit
          );
        });
      });
    });
    return regions;
  };

  filterRegions = () => {
    if (U.length(this.state, 'selectedRegions')) {
      return this.props.regionOptions.filter(o => this.state.selectedRegions.includes(o.id));
    }
    return this.props.regionOptions;
  };

  getRows = () => {
    const dayCount = _.round(
      U.millisecondsToDays(this.state.dateRange.endTime - this.state.dateRange.startTime)
    );
    const headerRow = [
      undefined,
      ..._.flatten(
        _.times(dayCount, index => [
          <div className="bold">
            <div style={{ height: '24px' }}>
              {mt(this.state.dateRange.startTime).add(index, 'd').format('ddd, M/D')}
            </div>
            <div>8 - 12</div>
          </div>,
          <div className="bold" style={{ marginTop: '24px' }}>
            12 - 4
          </div>,
          <div className="bold" style={{ marginTop: '24px' }}>
            4 - 8
          </div>,
        ])
      ),
    ];
    let regionRows = this.filterRegions().map(region => [
      {
        cell: (
          <a
            href={`/reports/regions/${region.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="bold"
          >
            {region.name}
          </a>
        ),
      },
      ..._.flatten(
        _.times(dayCount, dayIndex => {
          return _.times(3, slotIndex => {
            const visits = _.get(
              this.state.regions,
              `${region.id}.${this.state.service}.${dayIndex}.${slotIndex}`,
              []
            );
            const pendingCount = visits.filter(visit => visit.isPending).length;
            const recallCount = visits.filter(visit => visit.recall?.pastId).length;
            const visitCount = visits.length;
            const claimedCount = visits.length - pendingCount;
            const visitLimit = this.getVisitLimit(region, dayIndex, slotIndex);
            const visitCapacity = this.getVisitCapacity(region, dayIndex, slotIndex);
            const cell = (
              <div
                className={`pointer ${visitCount > visitLimit ? 'text-danger' : ''}`}
                onClick={() => this.showWorkOrders(region, dayIndex, slotIndex)}
                style={{ height: '50px' }}
              >
                <div>
                  {this.state.capacity === 'new' ? claimedCount : visitCount} / {visitCapacity} /{' '}
                  {visitLimit}
                </div>
                <div>
                  {pendingCount} / {recallCount}
                </div>
              </div>
            );
            return { cell, visitCount };
          });
        })
      ),
    ]);
    regionRows = _.sortBy(regionRows, '4.visitCount').reverse();
    return [headerRow, ...regionRows];
  };

  getVisitCapacity = (region, dayIndex, slotIndex) => {
    return _.floor(
      _.get(
        this.state.capacity === 'new' ? this.state.newCapacity : this.state.oldCapacity,
        `${region.id}.${this.state.service}.${dayIndex}.${slotIndex}`,
        0
      )
    );
  };

  getVisitLimit = (region, dayIndex, slotIndex) => {
    const day = mt(this.state.dateRange.startTime).add(dayIndex, 'd').day();
    let limit = _.get(region, `jobLimit.${slotIndex}.${day + 1}`);
    if (!U.isNumber(limit)) {
      limit = _.get(region, `jobLimit.${slotIndex}.0`);
    }
    if (!U.isNumber(limit)) {
      limit = '∞';
    }
    return limit;
  };

  loadVisits = async dateRange => {
    if (!dateRange.startTime || !dateRange.endTime) {
      return;
    }
    this.setState({ loading: true });
    const proQuery = { status: 'active', role: { $ne: 'mentee' } };
    const proProjection = {
      capacity: 1,
      id: 1,
      jobsPerTimeslot: 1,
      serviceCatalogIds: 1,
      'user.address': 1,
      'user.fullName': 1,
    };
    const query = {
      status: { $ne: 'cancelled' },
      $or: [
        { 'visits.slot.start': { $gte: dateRange.startTime, $lt: dateRange.endTime } },
        { 'visits.cx.availability.start': { $gte: dateRange.startTime, $lt: dateRange.endTime } },
      ],
    };
    const projection = {
      cx: 1,
      id: 1,
      'tasks.recall': 1,
      'tasks.serviceCatalogs': 1,
      'tasks.status': 1,
      'tasks.title': 1,
      visits: 1,
    };
    // eslint-disable-next-line prefer-const
    let [pros, workOrders] = await Promise.all([
      U.api('post', 'pros/search', { query: proQuery, projection: proProjection, limit: -1 }),
      U.api('post', 'workOrders/search', { query, projection, limit: -1 }),
    ]);
    const pstStartTime = U.toPSTDate(dateRange.startTime);
    const pstEndTime = U.toPSTDate(mt(dateRange.endTime).add(1, 'd'));
    const proChunks = _.chunk(pros, 6);
    for (let i = 0; i < proChunks.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      proChunks[i] = await Promise.all(
        proChunks[i].map(async pro => {
          pro.availability = await U.api('post', 'pros/availability', {
            proId: pro.id,
            startTime: pstStartTime,
            endTime: pstEndTime,
          });
          pro.regionIds = U.region.fromAddress(pro.user.address, this.props.regions);
          return pro;
        })
      );
    }
    pros = _.flatten(proChunks);
    const availablePros = {};
    const newCapacity = {};
    const oldCapacity = {};
    pros.forEach(pro => {
      const serviceIds = [
        'all',
        ...this.props.serviceOptions
          .filter(
            service =>
              service.id !== 'all' &&
              pro.serviceCatalogIds.some(id =>
                U.service.isSubcategory(service, this.props.services[id])
              )
          )
          .map(service => service.id),
      ];
      ['all', ...pro.regionIds].map(regionId => {
        _.map(pro.availability, ({ hours }, dayIndex) => {
          _.times(3, hourIndex => {
            const oldSlotCapacity =
              (_.compact(hours.slice(4 * hourIndex, 4 * (hourIndex + 1))).length / 4) *
              _.get(pro, 'jobsPerTimeslot', 3);
            const newSlotCapacity =
              (_.compact(hours.slice(4 * hourIndex, 4 * (hourIndex + 1))).length / 4) *
              _.get(pro, `capacity.${dayIndex}-${hourIndex * 4 + 8}`, 0);
            serviceIds.map(serviceId => {
              U.setAdd(
                oldCapacity,
                `${regionId}.${serviceId}.${dayIndex}.${hourIndex}`,
                oldSlotCapacity
              );
              U.setPush(availablePros, `${regionId}.${serviceId}.${dayIndex}.${hourIndex}`, {
                ...pro,
                oldCapacity: oldSlotCapacity,
              });
              U.setAdd(
                newCapacity,
                `${regionId}.${serviceId}.${dayIndex}.${hourIndex}`,
                newSlotCapacity
              );
              U.setPush(availablePros, `${regionId}.${serviceId}.${dayIndex}.${hourIndex}`, {
                ...pro,
                newCapacity: newSlotCapacity,
              });
            });
          });
        });
      });
    });

    const visits = _.flatten(
      workOrders
        .filter(workOrder => workOrder.tasks.some(T.isValid))
        .map(workOrder => {
          workOrder.regionIds = U.region.fromAddress(workOrder.cx.address, this.props.regions);
          const timezone = U.timezone(workOrder.cx.address);
          return _.flatten(
            workOrder.visits
              .map(visit => ({
                ...visit,
                availability: W.visitPossibleAvailability(visit).filter(slot =>
                  mt(slot.start).isBetween(dateRange.startTime, dateRange.endTime)
                ),
              }))
              .filter(
                visit =>
                  visit.availability.length &&
                  visit.pros.some(
                    pro =>
                      (!pro.id && pro.status !== W.VisitProStatus.CANCELLED) ||
                      W.visitProIsScheduled(pro, true)
                  )
              )
              .map(visit => {
                visit.isPending = visit.pros.some(pro => pro.status === W.VisitProStatus.PENDING);
                visit.isRecall = workOrder.tasks.some(t => t.recall?.pastId);
                const { address } = workOrder.cx;
                visit.search =
                  `${address.locality} ${address.region} ${address.postalCode}`.toLowerCase();
                visit.workOrder = workOrder;

                return visit.availability.map(slot => {
                  const newVisit = _.cloneDeep(visit);
                  newVisit.startHour = mt(slot.start).tz(timezone).hour();
                  newVisit.hourIndex =
                    newVisit.startHour === 16 ? 2 : newVisit.startHour === 12 ? 1 : 0;
                  newVisit.dayIndex = _.floor(
                    U.millisecondsToDays(mt(slot.start) - mt(dateRange.startTime))
                  );
                  return newVisit;
                });
              })
          );
        })
    );
    const regions = this.aggregateVisits(visits);
    this.setState({ loading: false, availablePros, newCapacity, oldCapacity, regions, visits });
  };

  recalculate = search => {
    search = search.toLowerCase();
    let { visits } = this.state;
    if (search) {
      visits = visits.filter(visit => visit.search.includes(search));
    }
    const regions = this.aggregateVisits(visits);
    this.setState({ regions });
  };

  renderModalBody = () => {
    if (!this.state.modal) {
      return {};
    }
    const { region, dayIndex, slotIndex } = this.state.modal;
    const date = mt(this.state.dateRange.startTime).add(dayIndex, 'd');
    const title = `${region.name} - ${_.get(
      this.props.services[this.state.service],
      'name',
      'All Services'
    )} - ${date.hour(8 + 4 * slotIndex).format('ddd, M/D h-')}${date
      .hour(12 + 4 * slotIndex)
      .format('h')}`;
    const visits = _.get(
      this.state.regions,
      `${region.id}.${this.state.service}.${dayIndex}.${slotIndex}`,
      []
    );
    const visitGroups = {};
    visits.map(visit => {
      visit.pros
        .filter(pro => !pro.id || W.visitProIsScheduled(pro, true))
        .map(pro => U.setPush(visitGroups, pro.id, visit));
    });
    const pros = _.get(
      this.state.availablePros,
      `${region.id}.${this.state.service}.${dayIndex}.${slotIndex}`,
      []
    ).map(pro => ({ ...pro, visitCount: U.length(visitGroups, pro.id) }));

    const body = (
      <div>
        <div className="d-flex mb-3">
          <div className="flex-1">Visit Count: {visits.length}</div>
          <div className="flex-1">
            Visit Capacity: {this.getVisitCapacity(region, dayIndex, slotIndex)}
          </div>
          <Number id="visitLimit" className="flex-1" />
        </div>
        <div>Jobs</div>
        {_.chunk(visits, 2).map((visits, i) => (
          <div key={i} className="d-flex">
            {visits.map((visit, i) => (
              <div className="flex-1" key={i}>
                <a
                  href={`/workOrders/${visit.workOrder.id}`}
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {visit.workOrder.cx.name} {W.title(visit.workOrder)}
                  {visit.isPending && ' (pending)'}
                  {visit.isRecall && ' (recall)'}
                </a>
              </div>
            ))}
          </div>
        ))}
        <div className="mt-3">Pros</div>
        <table className="table-sm table-striped table-hover table">
          <thead>
            <tr>
              <th>Pro</th>
              <th>Visit Count</th>
              <th>Visit Capacity</th>
            </tr>
          </thead>
          <tbody>
            {_.map(_.sortBy(pros, ['visitCount', 'visitCapacity']).reverse(), (pro, i) => (
              <tr key={i}>
                <td>
                  <a
                    href={`/pros/${pro.id}/availability`}
                    className="link-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {pro.user.fullName}
                  </a>
                </td>
                <td>{pro.visitCount}</td>
                <td>{this.state.capacity === 'new' ? pro.newCapacity : pro.oldCapacity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
    return { body, title };
  };

  saveRegionLimit = async () => {
    const { region, dayIndex, slotIndex } = this.state.modal;
    const oldVisitLimit = this.getVisitLimit(region, dayIndex, slotIndex);
    if (this.state.visitLimit !== oldVisitLimit) {
      const dayOfWeek = mt(this.state.dateRange.startTime).add(dayIndex, 'd').day();
      const changes = [
        {
          action: U.isNumber(this.state.visitLimit) ? 'replace' : 'remove',
          path: `jobLimit.${slotIndex}.${dayOfWeek + 1}`,
          value: this.state.visitLimit,
        },
      ];
      await U.api('put', `regions/${region.id}`, changes, ['save']);
    }
    this.setState({ modal: false });
  };

  saveSelectedRegions = ids => {
    store.set('demand.selectedRegions', ids);
  };

  showWorkOrders = (region, dayIndex, slotIndex) => {
    const visitLimit = this.getVisitLimit(region, dayIndex, slotIndex);
    this.setState({ modal: { region, dayIndex, slotIndex }, visitLimit });
  };

  render() {
    const { body, title } = this.renderModalBody();
    const rows = this.getRows();

    return (
      <div className="p-3">
        <div className="d-flex">
          <DateRange id="dateRange" onChange={this.loadVisits} endOfDay />
          <Text id="locationSearch" className="ml-3 flex-1" debounce={this.recalculate} />
          <Search
            id="selectedRegions"
            multi
            options={this.props.regionOptions}
            className="mx-3 flex-1"
            onChange={this.saveSelectedRegions}
          />
          <Search id="service" options={this.props.serviceOptions} className="flex-1" />
          <Select id="capacity" options={['new', 'old']} cap className="mx-3" />
          <div>
            <div>{this.state.capacity === 'new' ? 'Claimed' : 'Total'} Visits / </div>
            <div>Visit Capacity / </div>
            <div style={{ borderBottom: '1px solid black' }}>Visit Limit</div>
            <div>Pending Visits / </div>
            <div>Recall Visits</div>
          </div>
        </div>
        {this.state.loading ? (
          <div style={{ position: 'relative' }}>
            <Loader />
          </div>
        ) : (
          <div style={{ height: '100vh', maxWidth: '100%' }} className="bg-white">
            <AutoSizer>
              {({ width, height }) => (
                <MultiGrid
                  cellRenderer={cell => (
                    <div
                      key={`${cell.rowIndex} ${cell.columnIndex}`}
                      style={cell.style}
                      className={`cell p-td border p-1 ${cell.rowIndex % 2 ? '' : 'bg-light'}`}
                    >
                      {rows[cell.rowIndex][cell.columnIndex]?.cell ||
                        rows[cell.rowIndex][cell.columnIndex]}
                    </div>
                  )}
                  columnCount={rows[0].length}
                  columnWidth={({ index }) => (index ? 110 : 200)}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  height={height}
                  rowCount={rows.length}
                  rowHeight={50}
                  width={width}
                />
              )}
            </AutoSizer>
          </div>
        )}
        <Modal title={title} onSubmit={this.saveRegionLimit}>
          {body}
        </Modal>
      </div>
    );
  }
}

export default connect(s => {
  const regions = _.sortBy(_.values(s.regions), 'name');
  const regionOptions = [{ id: 'all', name: 'All Regions' }, ...regions];
  const serviceOptions = [
    { id: 'all', name: 'All Services' },
    ..._.sortBy(
      _.values(s.services).filter(
        service =>
          service.ancestorTaxonomies.includes('Appliance') && service.name.includes('Repair')
      ),
      'name'
    ),
  ];
  const roles = U.user.roles(s);
  const { services } = s;
  return { regionOptions, regions, roles, serviceOptions, services };
})(Demand);
