import React from 'react';
import Link from '@/com/ui/link';
import { Icon, Text } from '@/components';
import { iconByName } from '@/components/core/Icon';
import { companyName } from '@/config/const';

const howWeWork = [
  {
    title: 'Schedule a Service',
    text: 'Request a service, get it fixed, and relax. Receive text notifications whenever your appliance repair specialist is enroute and finished with your job.',
    icon: 'calendar_month',
    link: 'Book Now',
    to: '/book',
  },
  {
    title: 'Manage it Online',
    text: 'View or manage your job anytime. No fees are applied.',
    icon: 'grid',
    link: 'Login',
    to: '/login',
  },
  {
    title: 'Get it Fixed',
    text: `We will fix it the right way. All Appliance repairs are covered by the ${companyName} 365-day 100% satisfaction guarantee.`,
    icon: 'check_box_marked_circle_outline',
    link: 'Explore Services',
    to: '/services',
  },
];

export default function HowWork(): JSX.Element {
  return (
    <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
      {howWeWork.map((t, n) => (
        <div key={n} className="block flex-1 justify-center p-4 text-center">
          <Icon
            className="mx-auto my-0 self-center"
            name={t.icon as keyof typeof iconByName}
            color="primary"
            size={35}
          />
          <div>
            <Text className="mt-4" type="headline-6">
              {t.title}
            </Text>
            <Text className="mt-2 text-grey-dark">{t.text}</Text>
            <br />
            <Link to={t.to} className="flex justify-center text-center">
              <Text className="mx-auto my-0 text-primary">{t.link}</Text>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
