import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import { City, County, PricingRegion, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import nullthrows from 'nullthrows';
import { Form, Input, InputType, Modal, Table as TableComponent, Text } from '../../../components';

type AddPricingRegion = {
  cities: City[];
  counties: County[];
  name: string;
  states: string[];
  zips: string;
};

function AddRegion({ onSuccess, toggleOpen }: Table.AddUiProps): JSX.Element {
  const [addPricingRegion, setAddPricingRegion] = useState<AddPricingRegion>({
    cities: [],
    counties: [],
    name: '',
    states: [],
    zips: '',
  });
  const [error, setError] = useState<string>();
  const [pricingRegions, setPricingRegions] = useState<PricingRegion[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>();

  useEffect(() => {
    void U.api<PricingRegion[]>('get', 'pricing/region/existing').then(pricingRegions => {
      if (Array.isArray(pricingRegions)) {
        setPricingRegions(pricingRegions);
      }
    });
  }, []);

  const handleSave = async () => {
    const pricingRegion: Partial<PricingRegion> = { areas: [], name: addPricingRegion.name };
    nullthrows(pricingRegion.areas).push(
      ...addPricingRegion.cities.map(city => _.pick(city, ['county', 'locality', 'region']))
    );
    nullthrows(pricingRegion.areas).push(...addPricingRegion.counties);
    nullthrows(pricingRegion.areas).push(...addPricingRegion.states.map(region => ({ region })));
    const regex = new RegExp(/\d{5}/, 'g');
    let matches;
    while ((matches = regex.exec(addPricingRegion.zips))) {
      nullthrows(pricingRegion.areas).push({ zip: matches[0] });
    }
    if (!pricingRegion.areas) {
      return setError('Region must have at least one area');
    }

    const affected = [];
    for (const existingRegion of pricingRegions) {
      const intersection = _.intersectionWith(
        existingRegion.areas,
        (pricingRegion as PricingRegion).areas,
        (a, b) =>
          _.get(a, 'county') === _.get(b, 'county') &&
          _.get(a, 'locality') === _.get(b, 'locality') &&
          _.get(a, 'region') === _.get(b, 'region') &&
          _.get(a, 'zip') === _.get(b, 'zip')
      );
      if (existingRegion.name === pricingRegion.name || intersection.length > 0) {
        affected.push(existingRegion);
      }
    }
    if (affected.length && !showWarning) {
      setError(
        `The following regions will be overwritten by this change: ${_.map(affected, 'name').join(
          ', '
        )}`
      );
      return setShowWarning(true);
    }

    const response = await U.api<PricingRegion>('post', 'pricing/region/create', pricingRegion);
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else {
      onSuccess(response);
    }
  };

  return (
    <Form onChange={setAddPricingRegion} value={addPricingRegion}>
      <Modal isOpen>
        <Modal.Header title="Create Region" />
        <Modal.Body className="p-4">
          <Text className="mb-4" color="danger">
            {error}
          </Text>
          {_.times(U.length(addPricingRegion, 'cities') + 1, i => (
            <Input
              id={`cities.${i}`}
              key={i}
              label={`Cities ${i + 1}`}
              removable={Boolean(i)}
              type={InputType.CITY}
            />
          ))}
          {_.times(U.length(addPricingRegion, 'counties') + 1, i => (
            <Input
              id={`counties.${i}`}
              key={i}
              label={`Counties ${i + 1}`}
              removable={Boolean(i)}
              type={InputType.COUNTY}
            />
          ))}
          <Input id="name" required />
          <Input id="states" multiple options={U.states} />
          <Input id="zips" />
        </Modal.Body>
        <Modal.Footer
          onClose={toggleOpen}
          onSave={handleSave}
          saveText={showWarning ? 'Are you sure?' : undefined}
        />
      </Modal>
    </Form>
  );
}

function EditRegion({ id, onSuccess, toggleOpen }: Table.EditUiProps): JSX.Element {
  const [error, setError] = useState<string>();
  const [pricingRegion, setPricingRegion] = useState<PricingRegion>();

  useEffect(() => {
    void U.api<PricingRegion>('get', `pricing/region/${id}`).then(response => {
      if (!('errMsg' in response)) {
        setPricingRegion(response);
      }
    });
  }, [id]);

  const handleSave = async () => {
    if (pricingRegion?.endTime && m(pricingRegion.endTime).isBefore(m().startOf('d'))) {
      return setError('End time must be today or in the future');
    }
    const response = await U.api<PricingRegion>('put', `pricing/region/${id}`, [
      { path: 'endTime', value: pricingRegion?.endTime },
    ]);
    if ('errMsg' in response) {
      setError(response.errMsg);
    } else {
      onSuccess(response);
    }
  };

  return (
    <Form onChange={setPricingRegion} value={pricingRegion}>
      <Modal isOpen>
        <Modal.Header title="Update Region" />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          <Input id="endTime" type={InputType.DATE} />
        </Modal.Body>
        <Modal.Footer onClose={toggleOpen} onSave={handleSave} />
      </Modal>
    </Form>
  );
}

export default function PricingRegionsTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.pricingRegion} // eslint-disable-line react/jsx-props-no-spreading
      addUi={AddRegion}
      editUi={EditRegion}
    />
  );
}
