import { Service } from '@nanaio/util';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchServices = async (): Promise<Service[]> => {
  const { data } = await AXIOS_INSTANCE.get<Service[]>('/api/catalogs/search/everything?q=*');

  const services = data.filter(s => s.name.endsWith(' Repair'));

  return services;
};

export const useGetServices = (): UseQueryResult<Service[]> => {
  return useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
