import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Avatar, Icon } from '@/components';
import { apiOrigin } from '../../config/const';
import AvatarCard from './avatarCard';
import { Modal, Textbox } from './form';

class Notes extends React.Component {
  static propTypes = {
    savePath: T.string.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      modal: false,
      task: { notes: `${apiOrigin}${global.location.pathname}` },
    };
    this.addNote = this.addNote.bind(this);
    this.editNote = this.editNote.bind(this);
    this.openModal = this.openModal.bind(this);
    this.removeNote = this.removeNote.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    if (!this.state.content) {
      return this.setState({ error: 'Content required' });
    }
    this.addNote();
  }

  async addNote() {
    const changes = this.state.id
      ? [
          {
            action: 'replace',
            path: this.props.saveOvr
              ? `${this.props.saveOvr}.notes.${this.state.id}.content`
              : `notes.${this.state.id}.content`,
            value: this.state.content,
          },
        ]
      : [
          {
            action: 'add',
            path: this.props.savePath,
            value: {
              content: this.state.content,
            },
          },
        ];
    const r = await this.props.updateFunc(this.props.id || global.id(), changes);
    if (!r || r.errMsg) {
      return this.setState({ error: _.get(r, 'errMsg', 'Error') });
    }
    this.setState({ content: '', error: '', modal: false });
    U.redux.set(`form.${global.id()}note`, '');
  }

  openModal() {
    this.setState({ modal: true, content: this.props.content || '', id: '' });
  }

  editNote(note) {
    this.setState({ modal: true, content: note.content, id: note._id });
  }

  async removeNote() {
    const note = this.props.notes.find(n => n._id === this.state.id);
    const changes = [
      {
        action: 'replace',
        path: `${this.props.savePath}.${this.state.id}.deleted`,
        value: !_.get(note, 'deleted'),
      },
    ];
    const r = await this.props.updateFunc(this.props.id || global.id(), changes);
    if (!r || r.errMsg) {
      return this.setState({ error: _.get(r, 'errMsg', 'Error') });
    }
    this.setState({ content: '', error: '', modal: false });
  }

  onChange(value) {
    U.redux.set(`form.${global.id()}note`, value);
  }

  render() {
    const note = this.props.notes.find(n => n._id === this.state.id);
    const removeText = _.get(note, 'deleted') ? 'Restore' : 'Remove';
    return this.props.isUserProfile ? (
      <section id="cx-profile">
        <div className="cx-profile--container">
          <div className="cx-header flex-row">
            <p className="weight-700 text-dark flex-1">Notes</p>
            <IconButton onClick={this.openModal} size="medium">
              <Icon name="add" size={25} className="text-grey-dark" />
            </IconButton>
          </div>
          <div className="cx-list">
            {this.props.notes.map((note, n) => (
              <div
                key={n}
                onClick={() => this.editNote(note)}
                className="cx-list---item align-items-start border-bottom px-3 py-3"
              >
                <Avatar name={_.get(note, 'user.fullName', 'No Name')} size={40} />
                <div className="flex-1 pl-3">
                  <p className="text-dark" style={{ fontWeight: 500 }}>
                    {note.deleted ? '[Deleted]' : note.content}
                  </p>
                  <div
                    className="mt-1"
                    style={{
                      fontFamily: 'Myriad',
                      fontWeight: 600,
                      fontSize: 12,
                      color: '#90a4ae',
                    }}
                  >
                    Posted by {_.get(note, 'user.fullName', 'No Name')} on{' '}
                    {m(note.createTime).format('MMM D, h:mma')} ({m(note.createTime).fromNow()})
                  </div>
                </div>
              </div>
            ))}

            {this.props.notes.length === 0 && (
              <div className="px-4 py-4 text-center">
                <h1>📝</h1>
                <div className="pb-2">
                  <h5>No notes yet</h5>
                </div>
                <p className="text-muted" style={{ fontWeight: 500 }}>
                  Get started by tapping the plus button on the top right hand corner.
                </p>
              </div>
            )}
          </div>
        </div>
        <Modal
          className="partModal sm"
          title="Add Note"
          submit={this.submit}
          onRemove={this.state.id && this.removeNote}
          removeText={removeText}
        >
          <div className="text-danger">{this.state.error}</div>
          <Textbox
            id="content"
            hint='Write a new note ✍️ Press "Enter" to submit'
            onChange={this.onChange}
          />
        </Modal>
      </section>
    ) : (
      <div id="new-tabContent">
        <div id="task_notes">
          <div
            id="AdminTaskDetails"
            className="section--title sm d-flex justify-content-between align-center"
          >
            <div className="flex-1">{this.props.title}</div>
            <IconButton onClick={this.openModal}>
              <Icon name="edit" size={15} className="text-grey-dark" />
            </IconButton>
          </div>
          {this.props.notes.map((note, n) => (
            <div className="mb-2" key={n}>
              <AvatarCard
                link={() => this.editNote(note)}
                text={note.deleted ? '[Deleted]' : note.content}
                author={_.get(note, 'user.fullName', 'No Name')}
                date={m(note.createTime).format('MMM DD, YYYY, h:mma')}
              />
            </div>
          ))}
          {this.props.notes.length === 0 && (
            <div className="card-block--border card-shadow">
              <h3 className="text-muted text-center">No notes</h3>
            </div>
          )}
        </div>
        <Modal
          className="partModal sm"
          title="Add Note"
          submit={this.submit}
          onRemove={this.state.id && this.removeNote}
          removeText={removeText}
        >
          <div className="text-danger">{this.state.error}</div>
          <Textbox
            id="content"
            hint='Write a new note ✍️ Press "Enter" to submit'
            onChange={this.onChange}
          />
        </Modal>
      </div>
    );
  }
}

export default connect((s, p) => {
  let notes = _.values(_.get(s, p.loadPath)).map(n => ({
    ...n,
    createTime: m(n.createTime).valueOf(),
  }));
  notes = _.sortBy(notes, 'createTime').reverse();
  const ticketOwner = {
    id: s.me.userId,
    name: _.get(s.users[s.me.userId], 'profile.fullName'),
  };
  const content = _.get(s.form, `${global.id()}note`);
  return { content, notes, ticketOwner };
})(Notes);
