import React from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { DateRange, Search } from '../../com/ui/form';
import Loader from '../../com/ui/loader';

/* Given a job create date and pro return all opportunities they were eligible 
  for, what happened to the opportunities, and the notifications sent out for 
  each opportunity. */
export default class OpportunitiesByPro extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      jobCreateTime: {
        startTime: m().startOf('week').valueOf(),
        endTime: m().valueOf(),
      },
    };
    this.onJobCreateTimeChange = this.onJobCreateTimeChange.bind(this);
    this.onProIdChange = this.onProIdChange.bind(this);
    const query = { status: { $ne: 'applicant' } };
    global
      .api('post', 'pros/search', { limit: -1, query })
      .then(pros => this.setState({ pros: _.keyBy(pros, 'id') }));
  }

  async loadJobs(jobCreateTime) {
    if (!_.get(jobCreateTime, 'startTime') || !_.get(jobCreateTime, 'endTime')) {
      return [];
    }
    const query = {
      createTime: { $gte: jobCreateTime.startTime, $lt: jobCreateTime.endTime },
    };
    const r = await U.api('post', 'tasks/search', { query, limit: -1 });
    return r.filter(T.isValid).map(j => ({ ...j, proId: T.proId(j) }));
  }

  async loadOpportunities(jobs, proId) {
    const out = [];
    const groups = _.ceil(jobs.length / U.const.requestBatchSize);
    for (let i = 0; i < groups; i++) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        _.times(U.const.requestBatchSize, async n => {
          const job = jobs[i * U.const.requestBatchSize + n];
          if (job) {
            const query = { proId, jobId: job.id };
            const r = await U.api('post', 'availability', query);
            if (r.isAvailable || r.reason === 'Outside working hours') {
              const claimedByMe = job.proId === proId;
              const claimed = !!job.proId;
              out.push({ ...job, claimedByMe, claimed });
            }
          }
        })
      );
    }
    return out;
  }

  async onJobCreateTimeChange(jobCreateTime) {
    const pro = this.state.pros[this.state.proId];
    if (!pro) {
      return;
    }

    this.setState({ loading: true });
    const jobs = await this.loadJobs(jobCreateTime);
    const opportunities = await this.loadOpportunities(jobs, this.state.proId);
    this.setState({ jobs, opportunities, loading: false });
  }

  async onProIdChange(proId) {
    this.setState({ loading: true });
    const jobs = this.state.jobs || (await this.loadJobs(this.state.jobCreateTime));
    const opportunities = await this.loadOpportunities(jobs, proId);
    this.setState({ opportunities, loading: false });
  }

  render() {
    return (
      <div>
        <div className="d-flex">
          <Search
            id="proId"
            label="Pro"
            sort
            nameKey="user.fullName"
            className="mr-3"
            menuPortalTarget={document.body}
            options={this.state.pros}
            onChange={this.onProIdChange}
            style={{ width: '300px' }}
          />
          <DateRange id="jobCreateTime" onChange={this.onJobCreateTimeChange} />
        </div>
        {this.state.loading ? (
          <div style={{ position: 'relative' }}>
            <Loader />
          </div>
        ) : !this.state.opportunities ? null : (
          <table className="table-sm table-striped table-hover table">
            <thead>
              <tr>
                <th>Claimed By</th>
                <th>Notifications</th>
                <th>Visit Time</th>
                <th>Customer</th>
                <th>City</th>
                <th>Service</th>
                <th>Brand</th>
                <th>Status</th>
                <th>Job</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.state.opportunities, o => (
                <tr key={o.id}>
                  <td>
                    {o.claimedByMe
                      ? 'Me'
                      : o.claimed
                        ? 'Different Pro'
                        : o.status === 'waitingOnSchedule'
                          ? 'Waiting on Schedule'
                          : 'Available'}
                  </td>
                  <td>
                    {_.get(o, `metadata.oppNotif.pros.${this.state.proId}.notifyTime`) &&
                      m(o.metadata.oppNotif.pros[this.state.proId].notifyTime).format(
                        'M/D/YY h:mma'
                      )}
                  </td>
                  <td>
                    {o.status !== 'waitingOnSchedule' &&
                      m(T.firstVisitStart(o)).format('ddd, M/D h:mma')}
                  </td>
                  <td>{T.userName(o)}</td>
                  <td>{o.serviceAddress.locality}</td>
                  <td>{o.title}</td>
                  <td>{T.make(o)}</td>
                  <td>{_.startCase(o.status)}</td>
                  <td>
                    <a
                      className="link-blue"
                      href={`/tasks/${o.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Job
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
