import React from 'react';

export default class Step9 extends React.Component {
  constructor(p) {
    super(p);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  render() {
    return (
      <section>
        <div className="container">
          <div style={{ width: '100%', height: '140px' }} />
          <div className="animatedContent">
            <h1>Successfully Logged In!</h1>
            <button onClick={this.goBack} className="primary mt-4">
              Try Again
            </button>
          </div>
        </div>
      </section>
    );
  }
}
