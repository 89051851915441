import U from '@nanaio/util';
import { cloneDeep, isEqual,times } from 'lodash';
import mt from 'moment-timezone';

export const initialState = {
  isActive: null,
  loading: true,
  mentorshipWeek: null,
  originalIsActive: null,
  originalMentorshipWeek: null,
  originalWeek: null,
  saving: false,
  timezone: null,
  week: null,
};

async function loadMentorshipWeek(proId) {
  let result;
  const query = { 'purpose.0.targetType': 'mentorAvailability', 'resource.id': proId };
  const response = await U.api('post', 'timeslots/search', { query });
  const schedule = response?.[proId]?.[0]?.schedule;

  if (schedule) {
    result = schedule.week.map(timeSlots => {
      const hours = times(12, () => false);

      timeSlots.forEach(({ startHour, endHour }) => {
        for (let index = startHour - 8; index < endHour - 8; index++) {
          hours[index] = true;
        }
      });

      return { hours };
    });
  } else {
    result = times(7, () => ({ hours: times(12, () => false) }));
  }

  return result;
}

export async function loadAvailability(dispatch, timezone, proId) {
  dispatch({ type: 'LOAD_AVAILABILITY' });

  const startTime = mt().tz(timezone).startOf('week');
  const startTimePST = mt(startTime)
    .tz('America/Los_Angeles')
    .year(startTime.year())
    .month(startTime.month())
    .date(startTime.date());
  const endTimePST = mt(startTimePST).tz('America/Los_Angeles').endOf('week');
  const [week, mentorshipWeek] = await Promise.all([
    U.api('post', 'pros/availability', {
      proId,
      startTime: startTimePST,
      endTime: endTimePST,
      type: 'workingHours',
    }),
    loadMentorshipWeek(proId),
  ]);

  dispatch({
    type: 'LOAD_AVAILABILITY_FINISHED',
    payload: {
      originalWeek: cloneDeep(week),
      originalMentorshipWeek: cloneDeep(mentorshipWeek),
      week,
      mentorshipWeek,
    },
  });
}

export function setTimezone(dispatch, timezone) {
  dispatch({ type: 'SET_TIMEZONE', payload: { timezone } });
}

export function setProStatus(dispatch, pro, setOriginalStatus = true) {
  const isActive = pro.status === 'active';
  const payload = { isActive };

  if (setOriginalStatus) {
    payload.originalIsActive = isActive;
  }

  dispatch({ type: 'SET_PRO_STATUS', payload });
}

export function getSlotIsActive(week, dayIndex, slotIndex) {
  if (!week?.[dayIndex]) {
    return false;
  }

  const hours =
    slotIndex === undefined
      ? week[dayIndex].hours
      : week[dayIndex].hours.slice(slotIndex * 4, (slotIndex + 1) * 4);

  return hours.every(Boolean);
}

function toggle(week, dayIndex, slotIndex) {
  const isActive = getSlotIsActive(week, dayIndex, slotIndex);
  const newWeek = cloneDeep(week);

  newWeek[dayIndex].hours = newWeek[dayIndex].hours.map((hour, i) => {
    if (slotIndex === undefined || (i >= slotIndex * 4 && i < (slotIndex + 1) * 4)) {
      return !isActive;
    }

    return hour;
  });

  return newWeek;
}

export function toggleMentorship(dispatch, mentorshipWeek, dayIndex) {
  const newMentorshipWeek = toggle(mentorshipWeek, dayIndex);

  dispatch({ type: 'SET_MENTORSHIP', payload: { mentorshipWeek: newMentorshipWeek } });
}

export function toggleSlot(dispatch, week, dayIndex, slotIndex) {
  const newWeek = toggle(week, dayIndex, slotIndex);

  dispatch({ type: 'SET_WEEK', payload: { week: newWeek } });
}

export function getCanMentor(week, dayIndex) {
  return times(3).some(slotIndex => getSlotIsActive(week, dayIndex, slotIndex));
}

async function saveWeek(week, targetType, title, proId) {
  const newWeek = week.map(day => {
    const out = [];
    let start;

    day.hours.forEach((isAvailable, i) => {
      if (isAvailable) {
        if (start === undefined) {
          start = i;
        }
      } else if (start !== undefined) {
        out.push({ startHour: start + 8, endHour: i + 8 });
        start = undefined;
      }
    });

    if (start !== undefined) {
      out.push({ startHour: start + 8, endHour: 20 });
    }

    return out;
  });

  const newWorkingHours = {
    title,
    resource: { id: proId, resourceType: 'serviceprovider' },
    purpose: [{ targetType }],
    schedule: { week: newWeek },
  };

  await U.api('post', 'timeslots', newWorkingHours);
}

export async function save(
  dispatch,
  isActive,
  originalIsActive,
  pro,
  proId,
  week,
  originalWeek,
  mentorshipWeek,
  originalMentorshipWeek,
  timezone
) {
  dispatch({ type: 'SAVE' });

  if (isActive !== originalIsActive) {
    const changes = [{ path: 'status', value: isActive ? 'active' : 'notServingNow' }];
    const pro = await U.api('put', `pros/${proId}`, changes, ['offline', 'save']);

    setProStatus(dispatch, pro);
  }

  const updates = [];

  if (!isEqual(week, originalWeek)) {
    updates.push(saveWeek(week, 'workingHours', `Working Hours of ${pro.user.fullName}`, proId));
  }

  const newMentorshipWeek = cloneDeep(mentorshipWeek);

  times(7).forEach(dayIndex => {
    const hours = times(12, () => false);
    const canMentor = getCanMentor(week, dayIndex);

    if (!canMentor) {
      newMentorshipWeek[dayIndex] = { hours };
    }
  });

  if (!isEqual(newMentorshipWeek, originalMentorshipWeek)) {
    updates.push(
      saveWeek(
        newMentorshipWeek,
        'mentorAvailability',
        `Mentoring Hours of ${pro.user.fullName}`,
        proId
      )
    );
  }

  if (updates.length) {
    await Promise.all(updates);
    await loadAvailability(dispatch, timezone, proId);
  }

  dispatch({ type: 'SAVE_FINISHED' });
}

export function reducer(state, { type, payload }) {
  switch (type) {
    default:
      return state;

    case 'LOAD_AVAILABILITY':
      return {
        ...state,
        loading: true,
      };

    case 'LOAD_AVAILABILITY_FINISHED':
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case 'SET_MENTORSHIP':
    case 'SET_PRO_STATUS':
    case 'SET_TIMEZONE':
    case 'SET_WEEK':
      return {
        ...state,
        ...payload,
      };

    case 'SAVE':
      return {
        ...state,
        saving: true,
      };

    case 'SAVE_FINISHED':
      return {
        ...state,
        saving: false,
      };
  }
}
