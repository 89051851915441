import React from 'react';
import _ from 'lodash';
import { loggedInEvent } from '@/com/analytics';
import { Modal, Text } from '@/components';
import { supportEmail,supportUrl } from '@/config/const';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function HelpModal({ isOpen, onClose }: Props): JSX.Element {
  const handleSupportClick = () => {
    const eventInfo = {
      location: 'helpModalSupportLink',
    };
    loggedInEvent().track('v1_userRequestedSupport', eventInfo);
    window.open(supportUrl, '_blank')?.focus();
  };
  const handleEmailClick = () => {
    const eventInfo = {
      location: 'helpModalEmailLink',
    };
    loggedInEvent().track('v1_userRequestedSupport', eventInfo);
    window.open(`mailto:${supportEmail}`);
  };
  return (
    <Modal height={800} hideOnBackdropClick={false} isOpen={isOpen} onClose={onClose} width={700}>
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col justify-center p-4 text-center">
          <img
            className="mx-auto my-8 aspect-auto h-[500px]"
            alt="right"
            src="/img/faq-right-image.jpg"
          />
          <Text className="mb-3 text-center" type="headline-6">
            Contact Us
          </Text>
          <div className="flex text-center">
            <Text>Having problems? We can help. Email us at</Text>
            <Text className="ml-1" onClick={handleEmailClick} type="button" color="primaryCTA">
              {supportEmail}
            </Text>
            <Text className="ml-1">or visit our</Text>
            <Text className="ml-1" onClick={handleSupportClick} type="button" color="primaryCTA">
              Help Center
            </Text>
            <Text>.</Text>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
