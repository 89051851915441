import React, { memo, useRef } from 'react';
import { Text } from '@/components';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function Social() {
  const images = useRef(['tech-crunch', 'business-insider', 'forbes', 'inc', 'vault']).current;
  const [, breakpointSize] = useBreakpoints();

  return (
    <div className="apply-container items-center justify-between px-4 py-12 lg:flex lg:px-28 lg:py-8">
      <Text
        type={breakpointSize >= Breakpoints.LG ? 'subtitle-2' : 'headline-6'}
        color="grey.dark"
        className="whitespace-nowrap text-center lg:flex-initial lg:text-left"
        tag="h3"
      >
        Featured In
      </Text>

      <div className="mt-6 flex w-full flex-wrap lg:mt-0 lg:flex-1 lg:pl-20">
        {images.map(image => (
          <div className="flex h-12 w-1/3 flex-auto lg:w-1/5" key={image}>
            <img
              className="m-auto h-full object-contain"
              src={`/img/apply/social-${image}.png`}
              alt={image}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(Social);
