import type { LocationChangeAction } from 'react-router-redux';
import { LOCATION_CHANGE } from 'react-router-redux';
import U, { Action, Me, User } from '@nanaio/util';
import _ from 'lodash';
import store from 'store2';
import { SymptomsList } from '@/admin/samsungModels/types';
import { gaTrackingNumber } from '../config/const';
import { idFromURL } from '../utils';
import { getParams } from './util';

const params = getParams();
if (params.me && !_.isArray(params.me)) {
  try {
    store.set('me', JSON.parse(params.me) as Me);
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}

let me = store.get('me') as unknown;
let users = {};
if (_.get(me, 'id')) {
  // @ts-expect-error TODO update definition in util
  users = { [me.id]: _.pick(me, 'roles') };
}
if (!me || me === 'undefined') {
  me = { roles: [] };
}

type Router = {
  idPath?: string;
  location?: LocationChangeAction['payload'];
  locationBeforeTransitions?: string | null;
};
const initialState = {
  ...U.redux.initialState,
  ga: {
    landingPage: global.location.pathname,
    events: [],
    path: global.location.pathname,
    initRoute: null,
    funnel: {},
  },
  growth: {
    goals: [],
    pageviews: [],
  },
  me: me as Me,
  phone: gaTrackingNumber,
  // TODO why does the initial value have no overlapping properties with
  // the result of the location change action
  router: { locationBeforeTransitions: null } as Router,
  samsungModelsSymptoms: {} as SymptomsList,
  users: users as Record<string, User>,
  vendors: {},
};

type State = typeof initialState;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state: State = initialState, action: Action): State {
  if (action.type === LOCATION_CHANGE) {
    return {
      ...state,
      router: {
        idPath: idFromURL((action as LocationChangeAction).payload.pathname),
        location: (action as LocationChangeAction).payload,
      },
    };
  }
  // TODO type util
  return U.redux.reducer(state, action) as State;
}
