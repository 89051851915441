import React from 'react';
import { connect } from 'react-redux';
import { Col,Row } from 'reactstrap';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import { Icon, Text } from '@/components';
import { apiOrigin, companyName } from '../../../config/const';
import EditCard from './editCard';
import ReferCard from './ReferCard';

class Invoice extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      view: p.workOrder.invoice ? 'invoice' : p.workOrder.estimate ? 'estimate' : undefined,
    };
  }

  downloadPdf = async () => {
    if (this.state.pdfDownloading) {
      return;
    }
    this.setState({ pdfDownloading: true });
    const { id: workOrderId } = this.props.workOrder;
    const pdfResp = await U.api('get', `workOrders/${workOrderId}/invoice/pdf/customer`);
    const { filename } = pdfResp;

    // thanks for blob decoding instructions https://stackoverflow.com/a/45872086/331994
    // base64 string
    const base64str = pdfResp.blob;

    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    this.setState({ pdfDownloading: false });
  };

  getBalance = bill => {
    if (!bill) {
      return 0;
    }
    const copay = _.values(_.get(bill, 'chargeItems')).find(i => i.type === 'copay');
    if (copay) {
      return copay.amount - (copay.totalPaid || 0) + (copay.totalRefund || 0);
    }
    return bill.total * 100 - (bill.totalPaid || 0) + (bill.totalRefund || 0);
  };

  toggleView = () => {
    this.setState({ view: this.state.view === 'invoice' ? 'estimate' : 'invoice' });
  };

  render() {
    const { job, org } = this.props;
    if (!job) {
      return null;
    }
    const bill = this.props.workOrder[this.state.view];
    const customerBill = _.get(bill, 'toCustomer');
    const signature =
      _.get(bill, 'approval.signature') && `${apiOrigin}/images/${bill.approval.signature}`;

    return (
      <section className="u-right card-section">
        {!!_.get(job, 'metadata.taskQuestions.diagnosis.diagnosis') && (
          <div>
            <div style={{ fontWeight: 600 }}>Diagnosis</div>
            {_.get(job, 'metadata.taskQuestions.diagnosis.diagnosis')}
          </div>
        )}
        <EditCard />
        {!bill && (
          <div className="invoice-card">
            <div className="invoice-header">
              <h2>
                {this.state.view ? (
                  _.startCase(this.state.view)
                ) : (
                  <div className="card--block card-block--border card-shadow px-4 py-4 text-center">
                    <br />
                    <img alt="pay" src="/img/pay-icon.svg" height="50px" />
                    <br />
                    <h3 className="weight-600" style={{ fontSize: 24 }}>
                      No Estimate
                    </h3>
                    <Text className="text-grey-dark" type="subtitle-1">
                      No estimate was created yet. Your {companyName} Technician will provide the
                      estimate with their diagnosis on the day of the appointment.
                    </Text>
                    <br />
                  </div>
                )}
              </h2>
            </div>
          </div>
        )}
        {!!customerBill && (
          <div className="invoice-card">
            <div className="invoice-header">
              <h2>{this.state.view ? _.startCase(this.state.view) : 'No Estimate'}</h2>
            </div>

            <br />
            {/*= ======INVOICE ITEMS ======== */}
            <ul className="no-list">
              {_.values(customerBill.chargeItems)
                .filter(i => i.type === 'copay' || T.type(org) === 'b2c')
                .map((i, n) => (
                  <li
                    key={n}
                    className="border-bottom d-flex align-content-top justify-content-between py-3"
                  >
                    <div className="flex-1">
                      <p className="weight-600">{i.description}</p>
                      <label className="text-muted">
                        Qty {i.quantity} &bull; {U.toMoney(i.unitPrice)}/unit
                      </label>
                    </div>
                    <p className="weight-600 text-blue">{U.toMoney(i.quantity * i.unitPrice)}</p>
                  </li>
                ))}
              <br />
              <Row className="py-2">
                <Col lg="8" md="6" sm="6" xs="6">
                  <p className="weight-600 text-right">Subtotal</p>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6">
                  <p className="weight-600 text-right">
                    {U.toMoney(customerBill.labor + customerBill.parts)}
                  </p>
                </Col>
              </Row>
              <Row className="py-2">
                <Col lg="8" md="6" sm="6" xs="6">
                  <p className="weight-600 text-right">Tax</p>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6">
                  <p className="weight-600 text-right">{U.toMoney(customerBill.tax)}</p>
                </Col>
              </Row>
              {customerBill.shipping > 0 && (
                <Row className="py-2">
                  <Col lg="8" md="6" sm="6" xs="6">
                    <p className="weight-600 text-right">Shipping</p>
                  </Col>
                  <Col lg="4" md="6" sm="6" xs="6">
                    <p className="weight-600 text-right">{U.toMoney(customerBill.shipping)}</p>
                  </Col>
                </Row>
              )}

              {!!_.keys(job.promos).length && (
                <Row className="py-2">
                  <Col lg="8" md="6" sm="6" xs="6">
                    <p className="weight-600 text-right">
                      Discount ({_.reduce(_.keys(job.promos), (a, b) => `${a}, ${b}`)})
                    </p>
                  </Col>
                  <Col lg="4" md="6" sm="6" xs="6">
                    <p className="weight-600 text-right">
                      -
                      {_.sumBy(
                        _.values(_.get(customerBill, 'chargeItems')).filter(
                          i => i.type === 'discount'
                        ),
                        'amount'
                      ) / 100}
                    </p>
                  </Col>
                </Row>
              )}

              <Row className="py-2">
                <Col lg="8" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">Total</p>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">{U.toMoney(customerBill.total)}</p>
                </Col>
              </Row>
              <Row className="py-2">
                <Col lg="8" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">Paid</p>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">{U.toMoney(customerBill.paid)}</p>
                </Col>
              </Row>
              <Row className="py-2">
                <Col lg="8" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">Balance</p>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6">
                  <p className="weight-600 text-blue text-right">
                    {U.toMoney(customerBill.balance)}
                  </p>
                </Col>
              </Row>
              {customerBill.refund > 0 && (
                <Row className="py-2">
                  <Col lg="8" md="6" sm="6" xs="6">
                    <p className="weight-600 text-blue text-right">Refunded</p>
                  </Col>
                  <Col lg="4" md="6" sm="6" xs="6">
                    <p className="weight-600 text-blue text-right">
                      {U.toMoney(customerBill.refund)}
                    </p>
                  </Col>
                </Row>
              )}
            </ul>
            {/*= ======END OF INVOICE ITEMS ======== */}
            <br />
            {T.hasOrg(job) && (
              <div className="copay-alert">
                {T.type(org) === 'b2c' ? (
                  <>
                    Your balance is <u>{U.toMoney(customerBill.balance)}</u>
                  </>
                ) : (
                  <>
                    Your copay is <u>{U.toMoney(customerBill.balance)}</u> and{' '}
                    {U.org.name(_.get(job, 'customer.org'))} covers the rest
                  </>
                )}
              </div>
            )}
            <div className="text-danger">{this.state.error}</div>
            {this.state.view === 'invoice' && (
              <button onClick={this.downloadPdf} className="button-light w-100 d-block text-center">
                {this.state.pdfDownloading ? (
                  <Icon name="loading" spin size={16} />
                ) : (
                  'Download Invoice'
                )}
              </button>
            )}
            <br />
            <div className="signature-field">
              {!!signature && <label className="text-muted weight-600">Signature</label>}
              {!!signature && (
                <div className="signature-box">
                  {signature && <img alt="signature" src={signature} height="80" />}
                </div>
              )}
              <br />
              {this.props.job.invoice && (
                <button
                  className="button-light w-100 d-block text-center"
                  onClick={this.toggleView}
                >
                  View {this.state.view === 'invoice' ? 'Estimate' : 'Invoice'}
                </button>
              )}
            </div>
          </div>
        )}
        {this.props.code && <ReferCard code={this.props.code} />}
      </section>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const workOrder = s.workorders[job.metadata.workOrderId];
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const code = _.get(s.users[s.me.userId], 'shortId');
  return { code, job, org, workOrder };
})(Invoice);
