import React, { useState } from 'react';
import { U } from '@nanaio/util';
import { Button, Form, Input, InputType, Modal } from '@/components';

type Props = {
  partIds: string[];
  taskId: string;
};

export default function ResendCommunications({ partIds, taskId }: Props): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState({ sendCommunications: '', sendCommunicationsNotify: '' });

  const submit = async () => {
    if (!form.sendCommunications) {
      return setError('Choose a communication type please.');
    }

    if (!form.sendCommunicationsNotify) {
      return setError('Choose who to notify please.');
    }

    const body = {
      partIds,
    };

    await U.api(
      'post',
      `tasks/${taskId}/parts-notify/${form.sendCommunications}/${form.sendCommunicationsNotify}`,
      body
    );

    setOpenModal(false);
  };
  return (
    <>
      <Button className="mt-4" onClick={() => setOpenModal(true)} variant="outline">
        Resend Comms
      </Button>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header title="Send Communications" />
        <Modal.Body className="p-4">
          <Form value={form} onChange={setForm}>
            <div className="text-danger">{error}</div>
            <Input
              id="sendCommunications"
              options={[
                { id: 'ordered', name: 'Ordered Comm' },
                { id: 'shipped', name: 'Shipped Comm' },
                { id: 'received', name: 'Received Comm' },
              ]}
              required
              type={InputType.SEARCH}
            />
            <Input
              id="sendCommunicationsNotify"
              options={['customer', 'pro', 'both']}
              required
              type={InputType.SEARCH}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer onSave={submit} saveText="Send" />
      </Modal>
    </>
  );
}
