import React from 'react';
import { Region } from '@nanaio/util';
import { Input, Text } from '@/components';

const MARKET_RULE_CONDITIONS = [
  { id: 'IS', name: 'is' },
  { id: 'IS_NOT', name: 'is not' },
];

type Props = {
  disableControls?: boolean;
  errors: Errors;
  regions: Region[];
};

interface Errors {
  marketRuleCondition: string;
  marketRuleValues: string;
}

export default function MarketRulesetPanel({
  disableControls,
  errors,
  regions,
}: Props): JSX.Element {
  return (
    <>
      <Text className="mt-2.5 self-start">Work Order Market</Text>

      <div className="self-start">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.marketRuleCondition}
          id="marketRule.condition"
          label=""
          options={MARKET_RULE_CONDITIONS}
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.marketRuleCondition}
        </Text>
      </div>

      <div className="col-span-4 self-start">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.marketRuleValues}
          id="marketRule.values"
          label=""
          multiple
          options={regions}
          placeholder="All markets and regions"
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.marketRuleValues}
        </Text>
      </div>
    </>
  );
}
