import React from 'react';
import { connect } from 'react-redux';
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { T, U } from '@nanaio/util';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Select, Text } from '../../com/ui/form';
import { onChange } from '../../com/ui/form/group';

const styles = () => ({
  controls: {
    margin: 'auto',
    maxWidth: '60%',
  },
  radio: {
    padding: 8,
    paddingTop: 4,
  },
  radioGroup: {
    marginLeft: -10,
  },
});

class CreateInvoice extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      acceptances: _.map(p.workorder.tasks, t => ({ taskId: t.id })),
    };
    this.createInvoice = this.createInvoice.bind(this);
  }

  async createInvoice() {
    const acceptances = this.state.acceptances.map(v => ({
      ...v,
      declineReason: v.declineReason === 'other' ? v.other : v.declineReason,
    }));
    const r = await U.api(
      'post',
      `workorders/${this.props.workorder.id}/invoice`,
      { acceptances },
      true
    );
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
  }

  handleChange = event => {
    onChange(this, event.target.name, event.target.value === 'Yes');
  };

  render() {
    const { classes, workorder } = this.props;
    if (workorder.invoice) {
      return null;
    }
    const canCreate = this.state.acceptances.every(
      t => t.accepted === true || (t.declineReason && t.declineReason !== 'other') || t.other
    );
    return (
      <div className="px-3 py-3">
        <div className="text-danger">{this.state.error}</div>
        <div className=" text-center">
          <h2 className="px-3 pb-1 pt-3">Create Invoice</h2>
          <div className={classnames('text-left', classes.controls)}>
            {_.map(workorder.tasks, (t, i) => {
              const name = `acceptances.${i}.accepted`;
              const value = _.get(this.state, name);
              return (
                <div className="pb-3" key={t.id}>
                  <div className="text-center">
                    <strong>{t.title}</strong> {t.shortId}
                  </div>
                  <FormControl className="pt-3">
                    <FormLabel>Estimate Accepted</FormLabel>
                    <RadioGroup
                      className={classes.radioGroup}
                      value={value === true ? 'Yes' : value === false ? 'No' : undefined}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio className={classes.radio} color="primary" name={name} />}
                        label={
                          <Typography>
                            <strong>Yes</strong> - create invoice from estimate
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio className={classes.radio} color="primary" name={name} />}
                        label={
                          <Typography>
                            <strong>No</strong> - create diagnosis-only invoice
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    <FormHelperText className="d-flex">
                      <Icon name="info" size={16} />
                      <span className="pl-1">
                        Items can be added or removed from the invoice after creation.
                      </span>
                    </FormHelperText>
                  </FormControl>
                  {this.state.acceptances[i].accepted === false && (
                    <div>
                      <Select
                        className="pt-3"
                        id={`acceptances.${i}.declineReason`}
                        options={T.invoiceDeclineReasons}
                        label="Declined Reason"
                      />
                      {_.get(this.state, `acceptances.${i}.declineReason`) === 'other' && (
                        <Text
                          className="pt-1"
                          id={`acceptances.${i}.other`}
                          label="Please Specify"
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="text-danger">{this.state.error}</div>
          <div className="border-top px-3 py-3" style={{ background: '#fafafa' }}>
            <Fab
              disabled={!canCreate}
              variant="extended"
              aria-label="Create"
              className="m-invoice-btn blue mr-2"
              onClick={this.createInvoice}
            >
              Create Invoice
            </Fab>
          </div>
        </div>
      </div>
    );
  }
}

const StyledCreateInvoice = withStyles(styles)(CreateInvoice);

export default connect(s => {
  const job = s.tasks[global.id()];
  const workorder = s.workorders[job.metadata.workOrderId];
  return { job, workorder };
})(StyledCreateInvoice);
