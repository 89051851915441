import React from 'react';
import { ComponentMeta, ComponentStory } from '@storybook/react';
import classNames from 'classnames';
import moment from 'moment';
import Text from '@/components/core/Text';
import Calendar from '.';

// Fixed date so calendar is always the rendered the same for storybook tests
const CALENDAR_START_DATE = '2021-07-10';

export default {
  title: 'Core/Calendar',
  component: Calendar,
  args: {
    value: moment(CALENDAR_START_DATE).startOf('day').toDate(),
  },
} as ComponentMeta<typeof Calendar>;

const Template: ComponentStory<typeof Calendar> = args => <Calendar {...args} />;

export const Default = Template.bind({});
Default.args = {};

const DATE_FORMAT = 'YYYY-MM-DD';
const days: string[] = [];
const dayOffset = 3;
days.push(moment(CALENDAR_START_DATE).add(dayOffset, 'days').format(DATE_FORMAT));
days.push(
  moment(CALENDAR_START_DATE)
    .add(dayOffset * 2, 'days')
    .format(DATE_FORMAT)
);

export const WithRangeAndSelections = Template.bind({});
WithRangeAndSelections.args = {
  minDate: moment(CALENDAR_START_DATE).startOf('day').toDate(),
  tileContent: ({ date }: { date: Date }) => {
    const classes = classNames('tile-inner', {
      'tile-inner--selected': days.includes(moment(date).format(DATE_FORMAT)),
    });

    return (
      <div className={classes}>
        <Text type="body-2" color="font.dark">
          {moment(date).format('D')}
        </Text>
      </div>
    );
  },
};
