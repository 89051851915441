import React from 'react';
import U from '@nanaio/util';
import PropTypes from 'prop-types';

export default function Spacer({
  children,
  className = '',
  leftContent,
  rightContent,
  style = {},
  width,
}) {
  return (
    <div className={`flex flex-row justify-between ${className}`} style={style}>
      <div style={{ width: `${width}px` }}>{leftContent}</div>
      {children}
      <div className="text-right" style={{ width: `${width}px` }}>
        {rightContent}
      </div>
    </div>
  );
}

Spacer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  style: U.stylePropType,
  width: PropTypes.number.isRequired,
};
