import React from 'react';
import { companyName } from '@/config/const';
import Link from '../../com/ui/link';

export default () => (
  <section>
    <div className="container px-4 py-5">
      <div
        className="text-center"
        style={{ padding: '150px 16px', borderRadius: 5, background: '#FAFAFA' }}
      >
        <h1 style={{ fontFamily: 'Myriad', color: '#032C3D', margin: '0px' }}>
          Hello, Welcome to {companyName}.
        </h1>
        <p style={{ fontFamily: 'Myriad', color: '#556B6F' }}>
          Get started today by booking your first appointment.
        </p>
        <Link to="/book">
          <button
            style={{
              fontFamily: 'Myriad',
              fontSize: '18px',
              outline: 'none',
              border: 'none',
              borderRadius: 4,
              padding: '0.5em 1.5em',
              background: '#032C3D',
              color: '#fff',
            }}
          >
            Book online
          </button>
        </Link>
      </div>
    </div>
  </section>
);
