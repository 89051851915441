import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Card from '../../com/ui/card';

const Skills = ({ skills }) => (
  <div className="container">
    <Card title="My Skills">
      {skills.map(s => (
        <div key={s}>{s}</div>
      ))}
    </Card>
  </div>
);

export default connect(s => {
  const serviceIds = _.get(s.pros[s.me.proId], 'serviceIds', []);
  return {
    skills: serviceIds
      .map(i => _.get(s.services[i], 'name'))
      .filter(v => v)
      .sort(),
  };
})(Skills);
