import React from 'react';
import { Tooltip } from '@material-ui/core';
import T from 'prop-types';
import { Icon } from '@/components';

const colors = { muted: '#9FA4A8' };

const EditBtn = ({ tip, onClick, className, text, icon, block, color }) => (
  <Tooltip title={tip}>
    <button onClick={onClick} className={`rounded-btn + ${className}`} aria-label={text}>
      {block || <Icon name={icon || 'edit'} size={15} color={color || colors.muted} />}
    </button>
  </Tooltip>
);

EditBtn.propTypes = {
  tip: T.string,
  onClick: T.func.isRequired,
  className: T.string,
  block: T.any,
  color: T.string,
};

export default EditBtn;
