import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment';
import PropTypes from 'prop-types';
import { openLink } from '../../../utils';
import {
  firstColumnWidth,
  firstRowHeight,
  proPropType,
  statusColor,
  timeSlotsPropType,
} from '../util';
import Legend from './Legend';
import Popup from './Popup';

const styles = {
  map: { left: firstColumnWidth, top: firstRowHeight, zIndex: 99999999999999 },
};

export default function Map({ mapProId, mapDate, proMap, timeSlots, timezone, workOrder }) {
  const [infoWindow, setInfoWindow] = useState();
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const mapInstance = new global.google.maps.Map(document.getElementById('map'), {
      center: workOrder.cx.address.geoCoordinates,
      zoom: 10,
    });
    const trafficLayer = new global.google.maps.TrafficLayer();
    trafficLayer.setMap(mapInstance);
    setInfoWindow(new global.google.maps.InfoWindow({ disableAutoPan: true, map: mapInstance }));
    // eslint-disable-next-line no-new
    new global.google.maps.Marker({
      icon: `${global.location.origin}/img/map/cx-home.png`,
      map: mapInstance,
      position: workOrder.cx.address.geoCoordinates,
    });
    setMap(mapInstance);
  }, [workOrder]);

  useEffect(() => {
    if (!map) {
      return;
    }
    markers.map(marker => marker.setMap(null));
    if (!mapProId) {
      return;
    }
    const pro = proMap[mapProId];
    let newMarkers = [];
    if (_.get(pro, 'user.address.geoCoordinates')) {
      newMarkers.push({
        icon: `${global.location.origin}/img/map/pro-home.png`,
        position: pro.user.address.geoCoordinates,
      });
    }
    if (
      _.get(pro, 'metadata.location') &&
      mt(pro.metadata.location.timestamp).isAfter(mt().startOf('day'))
    ) {
      newMarkers.push({
        position: {
          lat: pro.metadata.location.coords.latitude,
          lng: pro.metadata.location.coords.longitude,
        },
      });
    }
    newMarkers = newMarkers.map(marker => new global.google.maps.Marker({ ...marker, map }));
    const proTimeSlots = _.filter(
      timeSlots[mapProId],
      slot => slot.address.geoCoordinates && mt(slot.startTime).startOf('day').isSame(mapDate)
    );
    proTimeSlots.forEach(slot => {
      let routeOrder = mt(slot.startTime).tz(timezone).hour() / 4 - 1;
      if (_.isNumber(slot.routeOrder)) {
        routeOrder = slot.routeOrder + 1;
      }
      const color = statusColor[slot.proStatus] || 'blue';
      const icon = `${global.location.origin}/img/map/${color}-${routeOrder}.png`;
      const marker = new global.google.maps.Marker({
        icon,
        map,
        position: slot.address.geoCoordinates,
      });
      marker.addListener('click', () => openLink({ newTab: true, url: `/tasks/${slot.taskId}` }));
      marker.addListener('mouseover', () => {
        infoWindow.setContent(
          renderToString(
            <Popup
              customer={slot.customer}
              endTime={slot.endTime}
              isSealedSystem={slot.isSealedSystem}
              proStatus={slot.proStatus}
              startTime={slot.startTime}
              timezone={timezone}
              title={slot.title}
            />
          )
        );
        if (marker) {
          infoWindow.open(map, marker);
        }
      });
      newMarkers.push(marker);
    });
    setMarkers(newMarkers);
  }, [infoWindow, map, mapDate, mapProId, markers, proMap, timeSlots, timezone]);

  return (
    <div>
      <div id="map" className="absolute bottom-0 right-0" style={styles.map} />
      <Legend />
    </div>
  );
}

Map.propTypes = {
  mapDate: U.timePropType.isRequired,
  mapProId: PropTypes.string,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  timeSlots: PropTypes.objectOf(timeSlotsPropType).isRequired,
  timezone: PropTypes.string.isRequired,
  workOrder: W.propType,
};
