import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import { useDeepCompareMemoRef } from '@/hooks';
import { Button, Loader, Modal, Text, Tooltip } from '../../core';
import { Input } from '../../form';

type Props = {
  onClose: () => void;
  query: Table.Query;
  onSubmit: (props: {
    databaseId: Table.DatabaseId;
    queryId: string;
    tableId: string;
  }) => Promise<void>;
};

type Group = { group: string; queries: Table.Query[] };

const defaultOption: Partial<Table.Query> = { id: 'Default', name: 'Default' };

const getColumns = (queries: Partial<Table.Query>[] = []) => {
  const options: { group: string }[] = queries.map(query => ({
    ...query,
    group: query.group || (query.analytics?.isOpen ? 'Reports' : 'Table'),
  }));
  const groups = U.keySort(_.groupBy(options, 'group'));
  const tableListColumns: Group[][] = [[], [], []];
  _.forEach(groups, (queries, group) => {
    const columnLengths = tableListColumns.map((groups, i) => ({
      columnIndex: i,
      length: _.sumBy(groups, group => group.queries.length),
    }));
    const indexToAdd = _.sortBy(columnLengths, 'length')[0].columnIndex;
    tableListColumns[indexToAdd].push({ group, queries });
  });
  return tableListColumns;
};

export default function ChangeQuery({ onClose, query, onSubmit }: Props): JSX.Element {
  const [databaseId, setDatabaseId] = useState(query.databaseId);
  const [databaseIdToTableIdToQueries, setDatabaseIdToTableIdToQueries] = useState<
    Table.Depth2<Partial<Table.Query>[]>
  >({ [Table.DatabaseId.DEFAULT]: _.mapValues(Table.databases.default, () => [defaultOption]) });
  const databaseIdToTableIdToQueriesRef = useDeepCompareMemoRef(databaseIdToTableIdToQueries);
  const [hoverId, setHoverId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [queryId, setQueryId] = useState<string | undefined>(query.id || 'Default');
  const [tableId, setTableId] = useState(query.table);

  const loadQueries = async (databaseId: string, tableId: string) => {
    if (databaseIdToTableIdToQueriesRef.current[databaseId]?.[tableId]?.length > 1) {
      return;
    }
    setIsLoading(true);
    const queries = await U.api<Table.Query[]>('post', 'query/search', {
      limit: -1,
      projection: { _id: 1, description: 1, group: 1, isPublic: 1, name: 1, table: 1, user_id: 1 },
      query: {
        $or: [
          { isPublic: true, table: tableId },
          { user_id: U.redux.get('me.userId'), table: tableId },
        ],
      },
    });
    if (Array.isArray(queries)) {
      setIsLoading(false);
      setDatabaseIdToTableIdToQueries(
        _.set(_.cloneDeep(databaseIdToTableIdToQueriesRef.current), `${databaseId}.${tableId}`, [
          defaultOption,
          ..._.sortBy(queries, 'name'),
        ])
      );
    }
  };

  useEffect(() => {
    void loadQueries(databaseId, tableId);
  }, [databaseId, tableId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDatabaseId = async (databaseId: Table.DatabaseId) => {
    if (!databaseIdToTableIdToQueriesRef.current[databaseId]) {
      setIsLoading(true);
      const tables = await U.api<
        { database: Table.DatabaseId; isTemporary: boolean; tableName: string }[]
      >('get', `database/${databaseId}/tables`);

      if (Array.isArray(tables)) {
        setDatabaseIdToTableIdToQueries({
          ...databaseIdToTableIdToQueriesRef.current,
          [databaseId]: _.mapValues(_.keyBy(tables, 'tableName'), () => [defaultOption]),
        });
      }

      setIsLoading(false);
    }

    setDatabaseId(databaseId);
    setQueryId('Default');
    setTableId(databaseId === Table.DatabaseId.DEFAULT ? 'workOrder' : 'work_orders');
  };

  const handleChangeTableId = (tableId: string) => {
    void loadQueries(databaseId, tableId);
    setQueryId('Default');
    setTableId(tableId);
  };

  const handleSubmit = () => {
    if (queryId) {
      void onSubmit({ databaseId, queryId, tableId });
    }
  };

  return (
    <Modal isOpen onClose={onClose} width={1000}>
      <Modal.Header title="Change Query" />
      <Modal.Body className="p-4">
        <div className="grid grid-cols-3 gap-x-4">
          <Input
            label="Database"
            onChange={value => handleChangeDatabaseId(value as Table.DatabaseId)}
            options={Table.databaseOptions}
            value={databaseId}
          />
          <Input
            capitalize
            label="Table"
            onChange={value => handleChangeTableId(value as string)}
            options={_.keys(databaseIdToTableIdToQueries[databaseId])}
            value={tableId}
          />
          <Input
            cypressId="query-input"
            label="Query"
            onChange={value => setQueryId(value as Table.DatabaseId)}
            options={databaseIdToTableIdToQueries[databaseId]?.[tableId]}
            value={queryId}
          />
        </div>
        <Loader isLoading={isLoading} />
        <div className="flex items-start">
          <div className="grid grid-cols-3 gap-x-4">
            {getColumns(databaseIdToTableIdToQueries[databaseId]?.[tableId]).map((groups, i) => (
              <div key={groups[0]?.group || i}>
                {groups.map(group => (
                  <div className="mb-4" key={group.group}>
                    <Text type="subtitle-1">{group.group}</Text>
                    {group.queries.map(query => (
                      <Tooltip key={query.id} node={query.description}>
                        <Text
                          color={hoverId === query.id ? 'primaryCTA' : undefined}
                          onClick={() => setQueryId(query.id)}
                          onMouseOut={() => setHoverId(undefined)}
                          onMouseOver={() => setHoverId(query.id)}
                        >
                          {query.name}
                        </Text>
                      </Tooltip>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button cypressId="change-query-submit" disabled={!queryId} onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
