// eslint-disable-next-line max-classes-per-file
import React from 'react';
import onEnter from 'react-onenterkeydown';
import { Tooltip } from '@material-ui/core';
import U from '@nanaio/util';
import { Icon } from '@/components';

const EnhancedInput = onEnter('input');

const color = {
  bg: '#f7fafc',
  bold: '#2E3B48',
  muted: '#9FA4A8',
  border: '#e3e8ee',
  blue: '#1A6BC6',
  green: '#40D78F',
  orange: '#FD9926',
  red: '#E90F09',
  darkGreen: '#24b47e',
  purple: '#8E69EC',
  lightblue: '#0DB1ED',
  pink: '#E84F89',
};

// eslint-disable-next-line react/prefer-stateless-function
class EditBtn extends React.Component {
  render() {
    return (
      <Tooltip title={this.props.tip}>
        <button
          onClick={this.props.onClick}
          className={`rounded-btn + ${this.props.className}`}
          aria-label={this.props.text}
        >
          <Icon
            name={this.props.icon ? this.props.icon : 'edit'}
            size={15}
            color={this.props.color ? this.props.color : color.muted}
          />
        </button>
      </Tooltip>
    );
  }
}

export default class Step7 extends React.Component {
  constructor(p) {
    super(p);
    this.state = { password: '', confirm: '' };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.toggleShowNewPassword = this.toggleShowNewPassword.bind(this);
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  toggleShowNewPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }

  onChange(id, e) {
    this.setState({ [id]: e.target.value });
  }

  async onSubmit() {
    const { password, confirm } = this.state;
    const { email, phone, token } = this.props.getStore();
    if (!password) {
      return this.setState({ error: 'Password required' });
    }
    if (password.length < 8) {
      return this.setState({ error: '8-character password required' });
    }
    if (!/[A-Z]/.test(password)) {
      return this.setState({ error: 'Password must contain uppercase' });
    }
    if (!/[a-z]/.test(password)) {
      return this.setState({ error: 'Password must contain lowercase' });
    }
    if (!/[0-9]/.test(password)) {
      return this.setState({ error: 'Password must contain number' });
    }
    if (password !== confirm) {
      return this.setState({ error: 'Passwords do not match' });
    }
    const identifier = email || phone;
    const body = { identifier, token, newPassword: password };
    const r = await U.api('put', 'users/passwordreset', body);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.jumpToStep(7);
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  render() {
    const t = this;
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 className="weight-500">Reset your Password</h3>
            <p className="text-muted">Requires 8+ characters, uppercase, lowercase, number</p>

            <div className="login--form">
              <div className="login--form--icon mt-4">
                <input
                  placeholder="Enter your new password"
                  className="pass-input"
                  autoFocus
                  value={t.state.password}
                  onChange={e => t.onChange('password', e)}
                  type={this.state.showNewPassword ? `text` : `password`}
                />
                <div className="form-icon right">
                  {this.state.showNewPassword && (
                    <EditBtn
                      tip="Hide Password"
                      icon="visibility_off"
                      onClick={this.toggleShowNewPassword}
                    />
                  )}
                  {!this.state.showNewPassword && (
                    <EditBtn
                      tip="Show Password"
                      icon="visibility"
                      onClick={this.toggleShowNewPassword}
                    />
                  )}
                </div>
              </div>

              <div className="login--form--icon">
                <EnhancedInput
                  onEnterKeyDown={() => t.props.jumpToStep(7)}
                  className="pass-input mt-2"
                  type={this.state.showPassword ? `text` : `password`}
                  placeholder="Confirm your new password"
                  value={t.state.confirm}
                  onChange={e => t.onChange('confirm', e)}
                />

                <div className="form-icon right">
                  {this.state.showPassword && (
                    <EditBtn
                      tip="Hide Password"
                      icon="visibility_off"
                      onClick={this.toggleShowPassword}
                    />
                  )}
                  {!this.state.showPassword && (
                    <EditBtn
                      tip="Show Password"
                      icon="visibility"
                      onClick={this.toggleShowPassword}
                    />
                  )}
                </div>
              </div>
            </div>
            <button onClick={t.onSubmit} className="primary mt-3">
              Reset my password
            </button>
            {t.state.error && (
              <p className="fadeInAnim py-3" style={{ color: '#f75c43' }}>
                {t.state.error}
              </p>
            )}
          </div>
        </div>
      </section>
    );
  }
}
