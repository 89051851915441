import React from 'react';
import { Avatar, Icon, Text } from '@/components';
import { TYPES as TextStyle } from '@/components/core/Text';
import { companyName } from '@/config/const';

// md breakpoint from tailwind config
const MD = 768;

export const trustedAmount = '180,000+';
const RATING = 4.7;

const data = [
  {
    author: 'Dan S.',
    text: 'Setting up my appointment and the follow up to get to the appointment was really easy and the technician that came to my home was personable, professional, polite, courteous, and very knowledgeable and explained the issue and resolution to me - duplicate that man!!!',
  },
  {
    author: 'Jamie',
    text: 'Our experience so far has been pretty seamless! After submitting our claim we received confirmation emails of parts ordered and appointment(s) made. Our first appointment technician was professional, on-time, and knowledgeable. We hope to see him again to complete the job!',
  },
  {
    author: 'Joe K.',
    text: 'Excellent communication and efficient and effective service. We had a disastrous experience with a repair service with our Frigidaire freezer, and I feared that would be the case with our Samsung freezer, but we were delighted at how quickly and well our refrigerator/freezer was fixed. I highly recommend!',
  },
  {
    author: 'Mary H.',
    text: "I am so pleased to recommend Jose. He called last evening to confirm the appointment and give me an estimate of arrival time. He called today as he was coming to the appointment as he said he would. He was able to fix the clothes washer without delay. It was a pleasure to work with a true professional! I hope I won't need to use this company again, but if I do I know I'll have great service.",
    photo: 'https://s3-media2.fl.yelpcdn.com/photo/BkoYqWYaQ3tarRtJwufLIg/120s.jpg',
  },
];

export default () => (
  <div className="p-4">
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <div>
        <div className="inline space-x-2">
          <Text
            className="inline mb-4 text-normal"
            type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}
          >
            That is why {trustedAmount} customers
          </Text>
          <Icon name="heart" size={40} className="mt-1 -mb-1 text-primary" />
          <Text
            className="inline mb-4 text-normal"
            type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}
          >
            {companyName}
          </Text>
          <Text color="grey.dark">
            Built by an appliance technician, designed for customers and technicians {companyName}{' '}
            knows what you need.
          </Text>
        </div>
      </div>
      <div>
        <a
          href="https://www.trustpilot.com/review/www.nana.io?stars=5&utm_medium=trustbox&utm_source=MicroReviewCount"
          className="flex items-center cursor-pointer hover:no-underline"
          style={{
            padding: '1em 0em',
            borderBottomWidth: 1,
            borderBottomStyle: 'dashed',
            borderColor: '#e4e4e4',
          }}
          target="_blank"
          rel="noreferrer"
        >
          <div className="relative">
            <span className="text-icons-orange opacity-40" style={{ fontSize: '25px' }}>
              ★★★★★
            </span>
            <span
              className="absolute left-0 overflow-hidden text-icons-orange"
              style={{ fontSize: '25px', width: RATING * 20 + '%' }}
            >
              ★★★★★
            </span>
          </div>

          <Text className="pl-3" type="subtitle-2">
            {RATING}-star average on TrustPilot
          </Text>
        </a>
      </div>
    </div>

    <div className="grid grid-cols-1 gap-4 mt-12 md:grid-cols-2 lg:grid-cols-4">
      {data.map((t, n) => (
        <div key={n}>
          <div className="w-full mb-4 border rounded border-grey-medium md:mb-0">
            <div className="flex items-center p-4 border-b border-grey-medium">
              {t.photo ? (
                <img
                  alt={t.author}
                  src={t.photo}
                  style={{
                    height: 25,
                    width: 25,
                    borderRadius: 25,
                    objectFit: 'cover',
                    marginRight: 16,
                  }}
                />
              ) : (
                <Avatar name={t.author} size="small" variant="circle" />
              )}
              <Text color="primary" type="button">
                @{t.author}
              </Text>
            </div>

            <div className="p-4 text-grey-dark" style={{ ...TextStyle['body-2'] }}>
              <Text color="grey.dark" lines={4}>
                {t.text}
              </Text>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
