import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import { brandLogos } from '../../config/brandLogos';
import BrandLogo from './BrandLogo';
import RadioTileList, { choiceType } from './RadioTileList';

const NUMBER_OF_BRANDS_TO_SHOW = 20;
const choices = Object.entries(brandLogos);
const sortedChoices = choices
  .sort((a, b) => b[1].popularity - a[1].popularity)
  .slice(0, NUMBER_OF_BRANDS_TO_SHOW);
const brandChoices: choiceType[] = sortedChoices.map(choice => ({
  name: choice[0],
  description: <BrandLogo brandName={choice[0]} size="small" />,
}));

const defaultChoices = [
  { name: 'choice 1', description: 'choice 1 description' },
  { name: 'choice 2', description: 'choice 2 description' },
  { name: 'choice 3', description: 'choice 3 description' },
  { name: 'choice 4', description: 'choice 4 description' },
];

export default {
  title: 'Core/Radio Tile List',
  component: RadioTileList,
  args: {
    choices: defaultChoices,
  },
  argTypes: {
    variant: { control: { type: 'inline-radio' } },
  },
} as ComponentMeta<typeof RadioTileList>;

const Template: ComponentStory<typeof RadioTileList> = args => <RadioTileList {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const ResponsiveGrid = Template.bind({});
ResponsiveGrid.args = {
  arrangement: 'grid',
  variant: 'tile',
  choices: brandChoices,
};

export const OneColumn = Template.bind({});
OneColumn.args = {
  arrangement: 'column',
  variant: 'tile',
  choices: brandChoices,
};

export const TwoColumn = Template.bind({});
TwoColumn.args = {
  arrangement: 'twoColumn',
  variant: 'tile',
  choices: brandChoices,
};

export const SelectionFeedback = Template.bind({});
SelectionFeedback.args = {
  arrangement: 'grid',
  variant: 'tile',
  choices: brandChoices,
  value: 'Samsung',
  onSelection: (choice: string | undefined) => {
    if (choice) {
      alert(`${choice} has been selected`);
    } else {
      alert(`Nothing selected`);
    }
  },
};
