import React, { useCallback, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { Alert, APIError, Button, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import Input, { Type } from '@/components/form/Input';
import { useLazyLegacyAPI } from '@/hooks';
import type { SubStepProps as Props } from './ConfirmationFlowModal';
import InfoProvidedNotice from './InfoProvidedNotice';

type EventType = {
  variant: string;
  step: string;
  changes?: { path: string; value: unknown }[];
  changesMade: boolean;
};

export default function ProfileAddressStep({ task, onNextSubStep }: Props): JSX.Element {
  const origForm = _.pick(task, ['serviceAddress']);
  const [form, setForm] = useState(_.cloneDeep(origForm));
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();
  const [addressError, setAddressError] = useState<JSX.Element | undefined>();

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to save changes to address</>} />
    ),
    save: true,
    method: 'put',
  });

  const isValidAddress = useCallback((): boolean => {
    let addressErrorMsg = undefined;
    // The formattedAddress is only broken into sub-components if a valid address is selected
    // Otherwise the sub-components remain as they were prior to the change and only formattedAddress changes
    //
    // Can't check for route here because street, avenue, etc are abbreviated in formattedAddress
    // Can't check for locality here because sometimes the city is Los Angeles but the address shows a more local city like Canoga Park
    if (
      !(
        form.serviceAddress.formattedAddress.includes(form.serviceAddress.streetNumber) &&
        form.serviceAddress.formattedAddress.includes(form.serviceAddress.region)
      )
    ) {
      addressErrorMsg = 'Select a valid address to continue';
    }
    if (form.serviceAddress.streetNumber === 'undefined') {
      addressErrorMsg = 'Street number required to continue.';
    }
    if (form.serviceAddress.locality === 'undefined') {
      addressErrorMsg = 'City required to continue.';
    }
    if (form.serviceAddress.region === 'undefined') {
      addressErrorMsg = 'State required to continue.';
    }
    if (form.serviceAddress.route === 'undefined') {
      addressErrorMsg = 'Street name required to continue.';
    }
    if (addressErrorMsg) {
      setAddressError(
        <Alert className="mb-10" variant="error">
          {addressErrorMsg}
        </Alert>
      );
      return false;
    }
    setAddressError(undefined);
    return true;
  }, [form.serviceAddress]);

  const handleNext = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }
    if (!isValidAddress()) {
      return;
    }

    const eventInfo: EventType = {
      variant: 'ftc',
      step: 'addressUpdated',
      changesMade,
    };

    if (changesMade) {
      const changes = [{ path: 'serviceAddress', value: form.serviceAddress }];
      const data = await updateTask(changes);
      if (!data) {
        return;
      }
      eventInfo.changes = changes;
    }

    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);

    await onNextSubStep();
  }, [changesMade, form.serviceAddress, onNextSubStep, submitRef, updateTask, isValidAddress]);

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          <Form onChange={setForm} value={form} ref={submitRef}>
            {!taskToUpdate.error && !addressError && <InfoProvidedNotice task={task} />}
            {taskToUpdate.error}
            {addressError}
            <Text className="text-center text-font-dark" type="headline-6">
              Confirm Your Service Address
            </Text>
            <Input
              className="mt-10"
              id="serviceAddress"
              label="Address"
              required
              type={Type.ADDRESS}
              noUnit
            />
            <Text className="-mt-3 mb-6 text-grey-dark" type="helper">
              Your technician and any necessary repair parts will arrive at your designated service
              address.
            </Text>
            <Input
              className="mt-6"
              id="serviceAddress.unit"
              label="Suite, floor, etc."
              type={Type.TEXT}
            />
            <Input
              className="mt-6"
              id="serviceAddress.arrivalInstructions"
              label="Arrival Instructions"
              type={Type.TEXT}
              placeholder="What is the gate code? Where should the technician park?"
              multiline
            />
            <Text className="-mt-3 mb-6 text-grey-dark" type="helper">
              We’ll share this with your technician on the day of your appointment.
            </Text>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={handleNext} submit>
          {changesMade ? 'Save' : 'Next'}
        </Button>
      </Modal.Footer>
    </>
  );
}
