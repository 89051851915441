import { WorkOrder } from '@nanaio/util';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchWorkOrder = async (id: string): Promise<WorkOrder> => {
  const { data: workOrder } = await AXIOS_INSTANCE.get<WorkOrder>(`/api/workOrders/${id}`);

  // TODO - remove this once the consumers have been changes to use the useGetWorkOrder hook
  U.redux.set(`workorders[${id}]`, workOrder);

  return workOrder;
};

export const useGetWorkOrder = (id: string): UseQueryResult<WorkOrder> => {
  return useQuery({
    queryKey: ['workOrder', id],
    queryFn: () => fetchWorkOrder(id),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
};

export const useCreateWorkOrder = (): UseMutationResult<WorkOrder> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: newWorkOrder =>
      AXIOS_INSTANCE.post<WorkOrder>('/api/workOrders', newWorkOrder).then(
        response => response.data
      ),
    onSuccess: data => {
      queryClient.setQueryData(['workOrder', id], data);
      const task = data.tasks[0];
      queryClient.setQueryData(['task', task.id], task);
    },
  });
};
