import React from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import { openLink } from '@/utils';
import { Button, Text } from '../../core';
import { Input, InputType } from '../../form';
import { View } from './types';

type Props = {
  error?: string;
  onSave: () => void;
  query: Table.Query;
  setView: (view: View) => void;
  view: View;
};

export default function Header({ error, onSave, query, setView, view }: Props): JSX.Element {
  return (
    <div className="align-items-end border-bottom mb-4 flex pr-4">
      <Button
        className="mr-4"
        onClick={() => setView(View.REPORT)}
        size="medium"
        variant={view === View.REPORT ? 'primary' : 'secondary'}
      >
        Report
      </Button>
      <Button
        className="mr-4"
        onClick={() => setView(View.TABLE)}
        size="medium"
        variant={view === View.TABLE ? 'primary' : 'secondary'}
      >
        Data & Filters
      </Button>
      <Button
        className="mr-4"
        onClick={() => setView(View.REQUEST)}
        size="medium"
        variant={view === View.REQUEST ? 'primary' : 'secondary'}
      >
        Requests
      </Button>
      <Button
        options={[
          {
            name: 'Aging Report',
            onClick: () =>
              openLink({ newTab: true, url: 'https://vimeo.com/676482571/abaad94ced' }),
          },
          {
            name: 'Pro Performance Report',
            onClick: () =>
              openLink({ newTab: true, url: 'https://vimeo.com/675754693/45fb47f42a' }),
          },
        ]}
        size="medium"
        variant="secondary"
      >
        Tutorials
      </Button>
      <div className="align-items-end ml-auto flex">
        <div className="mr-4">
          <Button onClick={onSave} size="medium">
            Save{query.id ? '' : ' As'}
          </Button>
          <Text color="danger">{error}</Text>
        </div>
        <Input
          id="analytics.hideSubtotals"
          className="mb-0 mr-4"
          label="Hide Subtotals"
          tooltip="When viewing nested dimensions like Address State > Address County, hide rows showing Address State subtotals"
          type={InputType.BOOL}
          width={120}
        />
        <Input
          id="analytics.showPoints"
          className="mb-0 mr-4"
          label="Show Points"
          tooltip="Display count of data points contributing to each cell"
          type={InputType.BOOL}
          width={100}
        />
      </div>
    </div>
  );
}
