import React from 'react';

export default function Overlay() {
  return (
    <div
      className="pointer-events-none absolute inset-0 bg-black opacity-50"
      style={{ zIndex: 1 }}
    />
  );
}
