import React from 'react';
import { Part, T } from '@nanaio/util';
import { Text } from '@/components';

type Props = {
  mostRecentDiagnosis: NonNullable<ReturnType<typeof T.getMostRecentDiagnosis>>;
  partsTiedOnlyToThisDx: Part[];
  partsTiedToMultipleDx: Part[];
};

export default function TaskWithAlternativeDiagnosis({
  mostRecentDiagnosis,
  partsTiedOnlyToThisDx,
  partsTiedToMultipleDx,
}: Props): JSX.Element {
  return (
    <>
      <Text type="helper" className="text-grey-dark">
        {partsTiedOnlyToThisDx.length + partsTiedToMultipleDx.length} part(s) are currently
        associated to this diagnosis. Once you delete this diagnosis, these parts:
      </Text>

      <ul className="mt-1 list-inside">
        {partsTiedOnlyToThisDx.map(part => (
          <li key={part.id} className="ml-1 before:text-grey-dark before:content-['•']">
            <Text type="helper" className="ml-1 text-grey-dark" tag="span">
              {`${part.partNumber as string} (${part.desc as string})`}
            </Text>
          </li>
        ))}
      </ul>

      <Text type="helper" className="mt-2 text-grey-dark">
        Will be auto moved to the next most recent diagnosis:
      </Text>

      <Text type="helper" className="ml-2 mt-2 text-grey-dark">
        {T.diagnosisCodeToString(mostRecentDiagnosis.diagnosis, false)}
      </Text>
    </>
  );
}
