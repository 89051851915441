import React from 'react';
import { Task, W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import { Text } from '@/components';
import ProCard from '../Appointments/ProCard';

type Props = {
  task: Task;
  workOrder: WorkOrder;
};
export default function PostVisitSummary({ task, workOrder }: Props): JSX.Element {
  const taskIndex = workOrder.tasks.findIndex(t => t.id === task.id);

  // Get all pros who have visited
  const pros = workOrder.visits
    .flatMap(visit =>
      visit.pros.filter(
        pro =>
          pro.tasks[taskIndex].role === W.VisitProRole.PRIMARY &&
          pro.status !== W.VisitProStatus.CANCELLED &&
          pro.status !== W.VisitProStatus.PENDING
      )
    )
    // Show most recent first
    .reverse();
  // Filter out older visits from the same pro.
  const uniquePros = _.uniqBy(pros, 'id');

  return (
    <div className="mt-10">
      <Text className="text-secondary" type="page-title">
        Post Service Summary
      </Text>
      <Text className="mt-8 text-secondary-5" type="label">
        Service:
      </Text>
      <Text className="mt-2 text-secondary-5">
        {task.metadata.taskQuestions?.Make && <>{task.metadata.taskQuestions?.Make} </>}
        {task.title}
        {task.metadata.taskQuestions?.Model && <> (Model: {task.metadata.taskQuestions?.Model})</>}
      </Text>
      {task.metadata.taskQuestions?.partFailure && (
        <>
          <Text className="mt-8 text-secondary-5" type="label">
            Issues:
          </Text>
          <Text className="mt-2 text-secondary-5">{task.metadata.taskQuestions?.partFailure}</Text>
        </>
      )}
      {!_.isEmpty(uniquePros) && (
        <>
          <Text className="mt-8 text-secondary-5" type="label">
            Your Technician(s):
          </Text>
          <div className="flex flex-col space-y-2">
            {uniquePros.map(pro => (
              <ProCard className="mt-2 flex" key={pro.id} task={task} pro={pro} variant="closed" />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
