import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import Date from './date';

export default class DateRange extends React.Component {
  static contextTypes = {
    t: T.object.isRequired,
  };

  static propTypes = {
    endOfDay: T.bool,
    groupClass: T.string,
    id: T.string.isRequired,
    onChange: T.func,
    value: T.shape({
      startTime: T.oneOfType([T.number, T.string]),
      endTime: T.oneOfType([T.number, T.string]),
    }),
  };

  constructor(p) {
    super(p);
    this.onChange = this.onChange.bind(this);
  }

  onChange(id, time) {
    if (this.props.onChange) {
      const value = _.merge({}, _.get(this.context.t.state, this.props.id));
      _.set(value, id, time);
      this.props.onChange(value);
    }
  }

  render() {
    let { label } = this.props;
    if (_.isUndefined(label)) {
      label = _.startCase(_.last(this.props.id.split('.')));
    }
    return (
      <div className={this.props.className}>
        <div className="d-flex">
          <Date
            {...this.props}
            endOfDay={false}
            id={`${this.props.id}.startTime`}
            label={`${label} (start)`}
            onChange={v => this.onChange('startTime', v)}
            className="flex-1"
          />
          <Date
            {...this.props}
            id={`${this.props.id}.endTime`}
            dateRange
            label={`${label} (end)`}
            onChange={v => this.onChange('endTime', v)}
            className="flex-1"
            endOfDay={this.props.endOfDay}
          />
        </div>
      </div>
    );
  }
}
