import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Part, PartStatus, T, Task, U } from '@nanaio/util';
import { APIError,Button, Input, Modal, Text } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';
import { useGetNextPartStatuses } from '@/hooks/api/nextPartStatuses';

type PartUpdateResponse = {
  task: Task;
};

type Props = {
  onClose: () => void;
  parts: Part[];
  status: PartStatus;
  task: Task;
};

export default function EditStatus({ onClose, parts, status, task }: Props): JSX.Element {
  const [newPartStatus, setNewPartStatus] = useState<PartStatus | undefined>(status);
  const [error, setError] = useState<string>();

  const startingPartStatus = useRef<PartStatus>(status);
  const [statusOptions, setStatusOptions] = useState<typeof T.partStatusOptions>();

  const nextPartStatusesQuery = useGetNextPartStatuses(startingPartStatus.current);

  const nextStatusKeys = useMemo(
    () => nextPartStatusesQuery.data?.nextStatuses || [startingPartStatus.current],
    [nextPartStatusesQuery.data?.nextStatuses]
  );

  useEffect(() => {
    const allowedStatuses = [...nextStatusKeys, startingPartStatus.current];
    const options = T.partStatusOptions.filter(option => allowedStatuses.includes(option.id));
    setStatusOptions(options);
  }, [nextStatusKeys, startingPartStatus]);

  const [replaceParts, replacedParts] = useLazyLegacyAPI<PartUpdateResponse>(
    `tasks/${task.id}/parts/replaceParts`,
    {
      errorRender: ({ error }) => (
        <APIError className="mb-10" error={error} text={<>Unable to edit part status. {error}</>} />
      ),
      method: 'put',
    }
  );

  const submit = useCallback(async () => {
    // Shouldn't be possible to submit without a new status, just for TS
    if (!newPartStatus) {
      return;
    }

    let hasTransitionError = false;
    parts.forEach(part => {
      if (
        newPartStatus === T.PartStatus.ORDERED &&
        part.deliveryMethod === U.part.DeliveryMethod.PICKUP &&
        !part.vendorOrderNumber
      ) {
        setError('Vendor order number required for pickup orders');
        hasTransitionError = true;
        return;
      }
      part.status = newPartStatus;
    });
    if (hasTransitionError) {
      return;
    }

    // update the part
    const response = await replaceParts({ parts });

    if (response) {
      void U.redux.updateTask(response.task);
      onClose();
    }
  }, [newPartStatus, onClose, parts, replaceParts]);

  const disableSave = !newPartStatus;

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Edit Part Status" />
      <Modal.Body className="p-4">
        {replacedParts.error}
        {error && <APIError className="mb-10" error={error} text={error} />}
        <Text className="mb-2">Part status may only make one step change at a time.</Text>
        <Text className="mb-4">
          If frequently you need to make multiple manual status changes for a part, contact eng
          about automating this workflow.
        </Text>
        <Input
          id="status"
          options={statusOptions}
          required
          value={newPartStatus}
          onChange={value => setNewPartStatus(value as PartStatus | undefined)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={disableSave} onClick={submit} submit>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
