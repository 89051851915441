import * as Table from '@nanaio/table';
import _ from 'lodash';
import nullthrows from 'nullthrows';

export const getShouldPatchRows = ({
  newQuery,
  oldQuery,
}: {
  newQuery: Table.Query;
  oldQuery: Table.Query;
}): { modules: Table.Column[]; shouldPatchRows: boolean } => {
  const modules = _.cloneDeep(newQuery.columns);
  const newModuleIds = _.uniq(_.flatten(_.map(newQuery.analytics.vectors, Table.getModuleIds)));
  const oldModuleIds = _.uniq(_.flatten(_.map(oldQuery.analytics.vectors, Table.getModuleIds)));
  const addedModuleIds = newModuleIds.filter(moduleId => !oldModuleIds.includes(moduleId));
  const shouldPatchRows = addedModuleIds.some(moduleId => {
    const module = oldQuery.columns.find(module => module.id === moduleId);
    return !module || module.unloaded;
  });

  for (const moduleId of addedModuleIds) {
    const index = _.findIndex(
      oldQuery.columns,
      module => module.id === moduleId && Boolean(module.unloaded)
    );
    if (index !== -1) {
      modules[index].unloaded = false;
    }
  }

  return { modules, shouldPatchRows };
};

export const updateOperations = ({
  databaseIdToTableIdToColumnIdToColumn,
  defaultOperation,
  id,
  newVector,
  oldVector,
  query,
}: {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  defaultOperation?: Table.Operation;
  id: string;
  newVector: Table.Vector;
  oldVector: Table.Vector;
  query: Table.Query;
}): void => {
  if (_.endsWith(id, 'intervalLength') && newVector.intervalLength) {
    newVector.operations = [];
  } else if (_.endsWith(id, 'operations')) {
    const addedOperation = newVector.operations.find(
      operation => !oldVector.operations.includes(operation)
    );

    if (addedOperation) {
      const operationIdToOperation = _.keyBy(Table.operationOptions, 'id');
      const { compatibleWith } = nullthrows(
        Table.operationOptions.find(option => option.id === addedOperation)
      );
      newVector.operations = [
        ...newVector.operations.filter(operation => compatibleWith.includes(operation)),
        addedOperation,
      ];

      if (addedOperation === Table.Operation.PERCENTILE && !newVector.percentile) {
        newVector.percentile = 0.5;
      }

      if (!newVector.operations.some(id => !operationIdToOperation[id].isFilter)) {
        newVector.operations.push(Table.Operation.SUM);
      }
    }
  }
  if (
    Table.vectorIsMetric({ databaseIdToTableIdToColumnIdToColumn, query, vector: newVector }) &&
    !newVector.operations.length
  ) {
    newVector.operations.push(defaultOperation || Table.Operation.SUM);
  }
};
