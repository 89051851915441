import '../../style/config/public';

import _ from 'lodash';
import config from './public.json';

const routes = {};

function importComponentsFromConfig(config) {
  Object.keys(config).map(entry => {
    const route = config[entry];

    Object.keys(route).forEach(component => {
      if (route[component].length > 0) {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        const importedComponent = require(`../../${route[component]}`).default;
        _.set(routes, `${entry}.${component}`, importedComponent);
      }
    });
  });
}

importComponentsFromConfig(config);

export default routes;
