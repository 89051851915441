import React from 'react';
import * as Table from '@nanaio/table';
import TableComponent from '@/components/Table';
import Add from './Add';

export default function PartVendorsTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.partVendor} // eslint-disable-line react/jsx-props-no-spreading
      addUi={(props: Table.AddUiProps) => <Add {...props} />} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}
