import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Icon } from '@/components';
import { updateTask } from '../../com/task';

class EscalateButton extends React.Component {
  constructor(p) {
    super(p);
    this.toggleEscalate = this.toggleEscalate.bind(this);
  }

  toggleEscalate() {
    let tags = _.values(this.props.job.tags);
    if (this.props.isEscalation) {
      tags = tags.filter(t => t !== 'escalate');
    } else {
      tags = [...tags, 'escalate'];
    }
    const changes = [{ action: 'replace', path: 'tags', value: tags }];
    updateTask(global.id(), changes);
  }

  render() {
    return (
      <cbutton className={this.props.isEscalation ? 'red' : 'gray'} onClick={this.toggleEscalate}>
        <Icon name="warning" color="#fff" />
      </cbutton>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const isEscalation = _.includes(job.tags, 'escalate');
  return { job, isEscalation };
})(EscalateButton);
