import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import Loader from './loader';

class Log extends React.Component {
  static propTypes = {
    type: T.string,
    manualInit: T.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      show: !props.manualInit,
      loading: true,
    };
    this.load = this.load.bind(this);
    if (!props.manualInit) {
      this.load();
    }
  }

  async load() {
    let r = await U.api('post', 'activities/search', { query: { refId: global.id() } });
    if (!_.isArray(r)) {
      return;
    }
    if (this.props.type) {
      r = r.filter(i => i.type === this.props.type);
    }
    const out = {};
    r.map(activity => {
      const date = m(activity.createTime).startOf('day').valueOf();
      if (!out[date]) {
        out[date] = [activity];
      } else {
        out[date].push(activity);
      }
    });
    const days = _.keys(out)
      .sort()
      .map(date => {
        return {
          date,
          activity: out[date],
        };
      });
    this.setState({
      loading: false,
      logs: r.map(
        l => ({
          title: l.title,
          user: _.get(l, 'byUser.fullName') || _.get(l, 'byUser.firstName'),
          time: m(l.createTime).format('ddd, MM/DD/YY, h:mm a'),
          timestamp: m(l.createTime).fromNow(),
          type: _.startCase(l.type),
        }),
        days
      ),
    });
  }

  componentWillUpdate(props, s) {
    if (!this.state.show && s.show) {
      this.load();
    }
  }

  render() {
    const t = this;
    if (!t.props.roles.customersupport) {
      return null;
    }
    if (!t.state.show) {
      return (
        <div className="btn btn-primary" onClick={() => t.setState({ show: true })}>
          Show Activity
        </div>
      );
    }
    if (t.state.loading) {
      return <Loader />;
    }
    if (!t.state.logs.length) {
      return null;
    }

    return (
      <div id="Admin">
        <div id="crm-activity">
          <div className="crm-activity--header">
            <p className="weight-700 text-dark">All Activity ({t.state.logs.length})</p>
          </div>
          {t.state.logs.map((l, n) => (
            <div className="activity-item d-flex align-items-start" key={n}>
              <div className="mr-3 flex-1">
                <p className="text-blue weight-800">{l.title}</p>
                <div className="d-flex">
                  <label className="text-muted weight-600">
                    Type: <span className="text-dark">{l.type}</span>
                  </label>
                  {l.user && (
                    <label className="text-muted weight-600">
                      &nbsp;&nbsp;&bull;&nbsp;&nbsp;User:{' '}
                      <span className="text-dark">{l.user}</span>
                    </label>
                  )}
                </div>
              </div>
              <p className="timestamp-badge">{l.time}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(s => ({ roles: U.user.roles(s) }))(Log);
