import React from 'react';
import _ from 'lodash';
import T from 'prop-types';
import { blue, green } from '../../../../com/ui/color';

// eslint-disable-next-line react/prefer-stateless-function
export default class Summary extends React.Component {
  static propTypes = {
    count: T.array,
    pro: T.object,
    width: T.number.isRequired,
  };

  render() {
    if (!this.props.count || !this.props.pro) {
      return null;
    }
    return (
      <div style={{ width: `${this.props.width}px`, margin: '0 auto' }}>
        <table className="table-sm table-striped table-hover mb-3 table">
          <thead>
            <tr>
              {this.props.count.map(c => (
                <th key={c.type}>{_.startCase(c.type)}</th>
              ))}
              <th>Legend</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.props.count.map(c => (
                <td key={c.type}>{c.count}</td>
              ))}
              <td>
                <div className="d-inline-block mr-3 text-white" style={{ background: green }}>
                  Cx + Pro Available
                </div>
                <div className="d-inline-block mr-3 text-white" style={{ background: blue }}>
                  Cx Available
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
