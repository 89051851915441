import React from 'react';
import { W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { openLink } from '../../../utils';
import Copyable from '../../core/Copyable';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import Dropdown from '../../form/Dropdown';
import User from '../User';

const descriptions = {
  confirmed: 'Customer has agreed to time',
  pending: 'Customer has not agreed to time',
};

export default function Customer({ mapIsOpen, onSetStatus, visit, workOrder }) {
  const openProfile = () => openLink({ url: `/users/${workOrder.cx.id}`, newTab: true });

  const statusItems = W.visitCustomerStatusOptions.map(status => ({
    description: descriptions[status],
    disabled: visit.cx.status === status,
    onClick: () => onSetStatus(status),
    title: _.startCase(status),
  }));

  return (
    <User
      hasOverlay={mapIsOpen}
      badgeProps={{ iconProps: { name: 'check' }, color: 'success' }}
      className="bg-purple-100"
      name={workOrder.cx.name}
    >
      <div className="flex-1">
        <Text color="primaryCTA" onClick={openProfile} type="subtitle-1">
          {workOrder.cx.name}
        </Text>
        <Dropdown
          cypressId="cx-status"
          items={statusItems}
          trigger={
            <Text type="body-2" color={visit.cx.status ? 'font.dark' : 'danger'}>
              {_.startCase(visit.cx.status) || 'Select status'}
            </Text>
          }
        />
      </div>
      <Copyable text={workOrder.cx.phone}>
        <Icon color="primaryCTA" name="phone" />
      </Copyable>
    </User>
  );
}

Customer.propTypes = {
  mapIsOpen: PropTypes.bool.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
