import React, { useEffect, useMemo, useState } from 'react';
import type { NotCoveredService } from '@nanaio/util';
import _ from 'lodash';
import { Button, Card, Icon, Loader, Text } from '@/components';
import { useLegacyAPI } from '@/hooks';
import NotCoveredServicesModal from './NotCoveredServicesModal';

type Props = {
  orgId: string;
};

export default function ({ orgId }: Props): JSX.Element | null {
  const {
    loading: isLoadingOrgServices,
    error: loadingOrgServicesError,
    data: orgServices,
  } = useLegacyAPI<NotCoveredService[]>(`orgs/${orgId}/notCoveredServices`);

  const { error: loadingAllServicesError, data: allServices } = useLegacyAPI<NotCoveredService[]>(
    `notCoveredServices/search`,
    { method: 'post', body: { limit: -1 } }
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [currentOrgServices, setCurrentOrgServices] = useState<NotCoveredService[]>([]);

  const sortedOrgServices = useMemo(() => {
    if (currentOrgServices.length) {
      return _.sortBy(currentOrgServices, 'name');
    }

    return undefined;
  }, [currentOrgServices]);

  const handleSave = (services: NotCoveredService[]) => {
    setCurrentOrgServices(services);
    setModalOpen(false);
  };

  useEffect(() => {
    if (orgServices?.length) {
      setCurrentOrgServices(orgServices);
    }
  }, [orgServices]);

  if (loadingOrgServicesError || loadingAllServicesError) {
    return React.cloneElement((loadingOrgServicesError || loadingAllServicesError) as JSX.Element, {
      className: 'mb-10',
    });
  }

  if (isLoadingOrgServices || !orgServices || !allServices) {
    return <Loader isLoading className="mb-10 text-center" />;
  }

  if (!isLoadingOrgServices && !orgServices?.length && !allServices?.length) {
    return null;
  }

  return (
    <>
      <Card
        className="mb-10"
        title="Services Not Covered"
        rightUI={
          <Button
            className="flex items-center"
            onClick={() => setModalOpen(true)}
            size="small"
            variant="primary"
          >
            <Icon name="edit" className="-mt-1 mr-1" color="white" />
            <Text color="white" type="button">
              Edit
            </Text>
          </Button>
        }
      >
        {sortedOrgServices?.length ? (
          <>
            <Text type="button">The warranty does not cover the following:</Text>

            <ul className="ml-2 mt-1 list-inside list-disc">
              {sortedOrgServices.map(service => (
                <li key={service.id}>
                  <Text className="inline-block">{service.name}</Text>
                </li>
              ))}
            </ul>

            <Text className="mt-4" type="helper" color="grey.dark">
              <b>Note:</b> This information is shown to technicians in the pro app before they
              accept an opportunity. These items are read-only and will not impact the work flow.
            </Text>
          </>
        ) : (
          <div className="flex items-center">
            <Icon name="list" className="-mt-0.5 mr-2" size={25} color="grey.dark" />
            <Text color="grey.dark">No services added</Text>
          </div>
        )}
      </Card>

      {modalOpen && allServices?.length && (
        <NotCoveredServicesModal
          allServices={allServices}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          orgId={orgId}
          orgServices={currentOrgServices}
        />
      )}
    </>
  );
}
