import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import Icon from '../core/Icon';
import type { InputProps } from './Input';

export const getProps = ({ value, ...rest }: InputProps): Props => ({
  value: typeof value === 'boolean' ? value : undefined,
  ...rest,
});

export type Props = {
  className?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  style?: CSSProperties;
  value?: boolean;
};

export default function Check({
  className = '',
  disabled,
  onChange,
  style = {},
  value,
}: Props): JSX.Element {
  const classes = classnames('relative flex cursor-pointer self-start', className);

  const handleClick = () => {
    if (!disabled && onChange) {
      onChange(!value);
    }
  };

  return (
    <div className={classes} onClick={handleClick} style={style}>
      <Icon
        className="mr-2"
        color={disabled ? 'grey.dark' : 'primaryCTA'}
        name={value ? 'check_box' : 'check_box_outline_blank'}
        style={{ marginBottom: -1 }}
      />
    </div>
  );
}
