import React, { useEffect, useMemo, useState } from 'react';
import { useLazyLegacyAPI, useLegacyAPI } from '@nanaio/hooks';
import { Option, Pro, T, Tool, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { APIError, Button, Form, Input, InputType } from '@/components';
import UserProfile from '@/pages/user/profile';

type Props = {
  pro: Pro;
};

export default function Profile({ pro: originalPro }: Props): JSX.Element {
  const [menteeOptions, setMenteeOptions] = useState<Option[]>([]);
  const [pro, setPro] = useState(originalPro);
  const [ratingOptions] = useState([
    { id: '100', name: '100 (Best)' },
    ..._.times(99, i => ({ id: (99 - i).toString(), name: (99 - i).toString() })),
  ]);
  const [toolOptions, setToolOptions] = useState<Option[]>([]);

  const betaPrograms = _.values(U.pro.BetaProgram);
  const betaProgramOptions = betaPrograms.map(program => ({
    id: program,
    name: _.startCase(program),
  }));

  const mentees = useLegacyAPI<Pro[]>('pros/search', {
    body: { query: { role: U.pro.Role.MENTEE } },
    method: 'post',
  });

  const tools = useLegacyAPI<Tool[]>('tools/search', {
    method: 'post',
  });

  const [updatePro, updatedPro] = useLazyLegacyAPI<Pro>(`pros/${pro.id}`, {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text={<>Unable to update pro.</>} />
    ),
    method: 'put',
    save: true,
  });

  useEffect(() => {
    if (mentees.data) {
      const newMenteeOptions = mentees.data.reduce<Option[]>((arr, { user }) => {
        if (user.id !== pro.user.id) {
          arr.push({ id: user.id, name: user.fullName });
        }
        return arr;
      }, []);
      setMenteeOptions(newMenteeOptions);
    }
  }, [mentees.data, pro.user.id]);

  useEffect(() => {
    if (tools.data) {
      const newToolOptions = tools.data.reduce<Option[]>((arr, tool) => {
        arr.push({ id: tool.id, name: tool.name });
        return arr;
      }, []);
      setToolOptions(newToolOptions);
    }
  }, [tools.data]);

  const handleSave = async () => {
    const changes = (_.keys(pro) as (keyof Pro)[])
      .filter(key => !_.isEqual(pro[key], originalPro[key]))
      .map(key => ({ path: key, value: pro[key] }));

    if (changes.length === 0) {
      return;
    }

    const data = await updatePro(changes);

    if (data) {
      setPro(data);
    }
  };

  const terms = useMemo(() => {
    // if the latest accepted terms are stored as a boolean value, then we're dealing
    // with the legacy way of saving the T&Cs. if it's an object, then it's the new way.
    const proTerms = pro.metadata?.terms;
    const latestAcceptedTermsKey = _.last(
      _.keys(proTerms).sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    );
    if (!proTerms || !latestAcceptedTermsKey) {
      return undefined;
    }
    const terms = proTerms[latestAcceptedTermsKey];
    if (_.isBoolean(terms)) {
      // legacy terms, prior to V3
      return 'No v3';
    }
    return `${terms.description}, Date: ${m(terms.date).format('MM/DD/YYYY')}`;
  }, [pro]);

  return (
    <Form
      className="container mx-auto mt-4"
      onChange={setPro}
      originalValue={originalPro}
      value={pro}
    >
      {updatedPro.error}
      <div className="grid grid-cols-4 gap-x-4">
        <Input disabled label="Accepted Terms" value={terms} />
        <Input id="availability.warningLevel" options={[{ id: '1', name: '1' }]} />{' '}
        <Input id="blockPayBeforeTime" type={InputType.DATE} />
        <Input id="createTime" disabled type={InputType.DATE} />
        <Input id="criminalScreen.startTime" type={InputType.DATE} />
        <Input id="criminalScreen.endTime" type={InputType.DATE} />
        <Input disabled id="metadata.device.appVersion" />
        <Input disabled id="metadata.device.os" />
        <Input id="drivingScreen.startTime" type={InputType.DATE} />
        <Input id="drivingScreen.endTime" type={InputType.DATE} />
        <Input id="drugScreen.startTime" type={InputType.DATE} />
        <Input id="drugScreen.endTime" type={InputType.DATE} />
        <Input id="metadata.eContact.name" />
        <Input id="metadata.eContact.phone" />
        <Input id="metadata.fountainProfile" />
        <Input
          id={`licenses.${U.service.Modifier.CONSTRUCTION_PPE}`}
          label="Has Construction PPE"
          type={InputType.BOOL}
        />
        <Input id="insurance.companyName" />
        <Input id="insurance.policyNumber" />
        <Input id="insurance.startTime" type={InputType.DATE} />
        <Input id="insurance.endTime" type={InputType.DATE} />
        <Input id="insuredThroughNana" type={InputType.BOOL} />
        <Input id="jobsPerTimeslot" placeholder="3" type={InputType.NUMBER} />
        <Input
          capitalize
          disabled
          id="locationPermission"
          options={U.pro.locationPermissionOptions}
        />
        <Input capitalize id="payrollType" options={U.pro.payrollOptions} required />
        <Input id="metadata.plusOneId" />
        <Input id="stats.ratingOverride" label="Rating Override" options={ratingOptions} />
        <Input capitalize id="metadata.referralSource" options={U.pro.referralSourceOptions} />
        <Input id="receivedNanaGrant" type={InputType.BOOL} />
        <Input id="referredByProId" />
        <Input capitalize id="role" options={U.pro.roleOptions} required />
        <Input id="metadata.routeDistance" type={InputType.NUMBER} />
        <Input id="savePicturesToPhone" type={InputType.BOOL} />
        <Input
          id={`licenses.${U.service.Modifier.SEALED_SYSTEM}.expiration`}
          label="Sealed Systems License Expiration"
          type={InputType.DATE}
        />
        <Input
          id={`licenses.${U.service.Modifier.SEALED_SYSTEM}.number`}
          label="Sealed System License Number"
        />
        {pro.role === U.pro.Role.MENTOR && (
          <Input id="mentees" multiple options={menteeOptions} sort />
        )}
        <Input id="metadata.samsungId" />
        <Input
          capitalize
          id="metadata.shippingPreference.type"
          label="Shipping Preference Type"
          options={T.shippingPreferenceOptions}
        />
        {pro.metadata?.shippingPreference?.type === T.ShippingPreference.OTHER && (
          <Input
            id="metadata.shippingPreference.address"
            label="Shipping Preference Address"
            type={InputType.ADDRESS}
          />
        )}
        <Input id="skipPartsApproval" type={InputType.BOOL} />
        <Input id="status" options={U.pro.statusOptions} required />
        <Input id="metadata.test" type={InputType.BOOL} />
        <Input id="workArea.radius" required type={InputType.NUMBER} />
      </div>
      <Input id="metadata.notes" multiline />
      <div className="grid grid-cols-3 gap-x-4">
        <Input
          id="tools"
          label="Requirements Checklist"
          tooltip="Select all that apply. These attributes will be used to determine eligibility for jobs in the future."
          multiple
          type={InputType.AUDITED_CHECKBOXES}
          options={toolOptions}
          sort
        />
        <Input
          id="betaPrograms"
          label="Opt into Beta Programs"
          tooltip="Select the beta program(s) you would like to opt the user into"
          multiple
          type={InputType.AUDITED_CHECKBOXES}
          options={betaProgramOptions}
          sort
        />
      </div>
      <div className="flex">
        <Button onClick={handleSave} submit>
          Save Changes
        </Button>
      </div>
      <div className="mt-4">
        <UserProfile
          onChange={() => U.api('get', `pros/${pro.id}`, ['save'])}
          userId={pro.user.id}
        />
      </div>
    </Form>
  );
}
