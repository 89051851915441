import React from 'react';
import _ from 'lodash';
import m from 'moment';
import mdf from 'moment-duration-format';
import T from 'prop-types';
import store from 'store2';
import { Select } from '../../../com/ui/form';
import Link from '../../../com/ui/link';
import Loader from '../../../com/ui/loader';
import { filterMin, getIntervals, sortRows } from './util';

mdf(m);

const sortOptions = [
  'None',
  ..._.flatten(
    ['alphabetical', 'denominator', 'numerator', 'ratio'].map(v => [
      `${v}Ascending`,
      `${v}Descending`,
    ])
  ),
];
const settings = { sort: 'alphabeticalAscending', type: 'day' };

export default class Chart extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  state = store.get('data-report', settings);

  componentWillUpdate(p, s) {
    store.set('data-report', s);
  }

  render() {
    const t = this;
    const { data, start, end, min, negative } = t.props;
    const { interval, sort } = t.state;
    if (_.isEmpty(data)) {
      return <Loader text={_.startCase(_.last(global.location.pathname.split('/')))} />;
    }
    const intervals = getIntervals({ data, interval, start, end });
    let rows = _.values(
      _.mapValues(data, (row, id) => ({
        data: row.data || row,
        id,
        ..._.pick(row, ['average', 'name', 'negative', 'type']),
      }))
    );
    rows = filterMin({ rows, min });
    rows = rows.map(r => {
      r.negative = negative || r.negative;
      const type = _.isNumber(r.data[0]) ? 'number' : 'ratio';
      return {
        id: r.id,
        name: r.name || _.startCase(r.id),
        cells: intervals.map(i => {
          const values = r.data.slice(i.start, i.end + 1);
          const average = r.average || t.props.average;
          if (type === 'number') {
            const rowDailyAverage = _.sum(values) / values.length;
            const out = {
              line1: _.round(_.sum(values), 1),
            };
            if (average) {
              if (!r.negative) {
                out.className = rowDailyAverage > average ? 'text-success' : 'red';
              } else {
                out.className = rowDailyAverage < average ? 'red' : 'text-success';
              }
            }
            return out;
          }
          const top = _.round(_.sumBy(values, 'top'), 1);
          const bot = _.round(_.sumBy(values, 'bot'), 1);
          let round = 0;
          if (r.type !== 'number' && average < 0.1) {
            round = 1;
          }
          const out = {
            top,
            bot,
            line:
              bot === 0
                ? '-'
                : r.type === 'number'
                  ? _.round(top / bot, 1)
                  : `${_.round((top / bot) * 100, round)}`,
            line1:
              bot === 0
                ? '-'
                : r.type === 'number'
                  ? _.round(top / bot, 1)
                  : `${_.round((top / bot) * 100, round)}%`,
            line2: `${top}/${bot}`,
          };
          if (t.props.format === 'time') {
            out.line1 = m.duration(out.line1, 'milliseconds').format('h:mm:ss');
            out.line2 = `${out.line1}/${bot}`;
          }
          if (average) {
            if (bot === 0) {
              out.className = 'grey';
            } else if (!r.negative) {
              out.className = top / bot > average ? 'text-success' : 'red';
            } else {
              out.className = top / bot > average ? 'red' : 'text-success';
            }
          }
          return out;
        }),
      };
    });
    rows = sortRows({ rows, sort, interval });
    return (
      <div id="reports">
        <div className="row">
          <Select id="interval" options={['day', 'week', 'month']} cap className="col" />
          <Select id="sort" options={sortOptions} cap className="col" />
          <div className="lastupdate col text-right">
            <label className="text-muted">LAST UPDATED</label>
            <p>{m(end).format('dddd, MMM D, h:mm a')}</p>
          </div>
        </div>
        <div id="table_v2" className="full-width">
          <table className="table-hover table">
            <thead>
              <tr>
                <td />
                {intervals.map((i, n) => (
                  <td
                    key={n}
                    className={`month ${
                      interval === 'day' ? m(end).add(-n, 'd').format('ddd') : ''
                    }`}
                  >
                    {m(end).add(-i.start, 'd').format('MMM')}
                    {i.start !== i.end && `-${m(end).add(-i.end, 'd').format('MMM')}`}
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {intervals.map((i, n) => (
                  <td
                    key={n}
                    className={`day ${interval === 'day' ? m(end).add(-n, 'd').format('ddd') : ''}`}
                  >
                    {m(end).add(-i.start, 'd').format('D')}
                    {i.start !== i.end && `-${m(end).add(-i.end, 'd').format('D')}`}
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {intervals.map((i, n) => (
                  <td
                    key={n}
                    className={`week ${
                      interval === 'day' ? m(end).add(-n, 'd').format('ddd') : ''
                    }`}
                  >
                    {m(end).add(-i.start, 'd').format('ddd')}
                    {i.start !== i.end && `-${m(end).add(-i.end, 'd').format('ddd')}`}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((r, n) => (
                <tr key={n}>
                  <td className="name" style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                    {t.props.linkBase && n ? (
                      <Link to={`/${t.props.linkBase}/${r.id}`} className="link-blue">
                        {r.name}
                      </Link>
                    ) : (
                      <strong>{r.name}</strong>
                    )}
                  </td>
                  {r.cells.map((v, n) => (
                    <td key={n} className={m(end).add(-n, 'd').format('ddd')}>
                      <strong className={v.className}>{v.line1}</strong>
                      {v.line2 ? (
                        <div className={v.line === '-' ? 'badge gray' : 'badge'}>{v.line2}</div>
                      ) : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
