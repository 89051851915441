import React from 'react';
import Link from '@/com/ui/link';
import { Button, Icon, Text } from '@/components';
import { companyName } from '@/config/const';

// md breakpoint from tailwind config
const MD = 768;

const links = [
  {
    name: 'Refrigerator',
    link: '/services/refrigerator',
  },
  {
    name: 'Dishwasher',
    link: '/services/dishwasher',
  },
  {
    name: 'Oven',
    link: '/services/oven',
  },
  {
    name: 'Stove',
    link: '/services/stove',
  },
  {
    name: 'Washer',
    link: '/services/washer',
  },
  {
    name: 'Dryer',
    link: '/services/dryer',
  },
];

export default function ReadyToGetItFixed(): JSX.Element {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      {[
        <div key={0} className="flex-1 p-4">
          <Text className="text-normal" type={window.innerWidth < MD ? 'headline-5' : 'headline-2'}>
            Ready to get it Fixed?
          </Text>
          <Text color="grey.dark">
            Get $25 OFF labor when online booking. Book a trusted, local technician with{' '}
            {companyName}.
          </Text>
          <Button
            className="mt-10 flex w-full flex-row content-center justify-center md:max-w-[400px]"
            href="/book"
            variant="primary"
          >
            <Icon color="white" name="calendar" size={15} />
            <Text className="ml-2" color="white" type="button">
              Schedule Now
            </Text>
          </Button>
        </div>,
        <div key={1} className="mt-4 flex-1 p-4 md:mt-0">
          <Text className="mb-2 uppercase" type="label">
            Still Reading?
          </Text>
          <div className="grid grid-cols-1 md:grid-cols-2">
            {links.map((t, n) => (
              <div key={n}>
                <Link
                  to={t.link}
                  name="conclusion-to-service"
                  className="flex"
                  style={{
                    textDecoration: 'none',
                    padding: '0.75em 0em',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'dashed',
                    borderColor: '#e4e4e4',
                  }}
                >
                  <Text color="primary" type="button">
                    {t.name} Services &nbsp;&nbsp;
                  </Text>
                </Link>
              </div>
            ))}
          </div>
        </div>,
      ]}
    </div>
  );
}
