import React from 'react';
import { Icon, Text } from '@/components';
import { companyName } from '@/config/const';
import Footer from '../../../com/ui/footer';
import Link from '../../../com/ui/link';
import AboutNav from './aboutNav';
import GrowingTeam from './growingTeam';

const valuesRowOne = [
  {
    src: '/img/aboutPage/iconBook.svg',
    name: 'Humble Curious Effective',
    desc: 'Invest in the Most Humble,\n Curious, & Effective Talent',
  },
  {
    src: '/img/aboutPage/iconPuzzle.svg',
    name: 'Diversity and Inclusion',
    desc: 'Commit to Diversity &\n Inclusion',
  },
  {
    src: '/img/aboutPage/iconTradespeople.svg',
    name: 'Start with Tradespeople',
    desc: 'Think 360 Starting With\n Our Tradespeople',
  },
];

const valuesRowTwo = [
  {
    src: '/img/aboutPage/iconData.svg',
    name: 'Debate with Data',
    desc: 'Debate Respectfully\n and Openly With Data',
  },
  {
    src: '/img/aboutPage/iconWall.svg',
    name: 'Address Hard Problems',
    desc: 'Address the Hard Problems\n & Persevere ',
  },
  {
    src: '/img/aboutPage/iconClock.svg',
    name: 'Move Fast',
    desc: 'Move Fast &\n Fix Things',
  },
  {
    src: '/img/aboutPage/iconTrophy.svg',
    name: 'Debrief and Evolve',
    desc: 'Debrief &\n Evolve',
  },
];

const cultureList = [
  'We Are Present',
  'We Are Compassionate',
  'We Are Joyful',
  'We Are Impactful',
  'We Have Integrity',
  'We Guide',
];

const culturePicsOne = [
  {
    src: '/img/aboutPage/team1.jpg',
    name: 'Catan',
  },
  {
    src: '/img/aboutPage/team2.jpg',
    name: 'David Office',
  },
  {
    src: '/img/aboutPage/team3.jpg',
    name: 'Gong',
  },
];

const culturePicsTwo = [
  {
    src: '/img/aboutPage/team4.jpg',
    name: 'Leadership Team',
  },
  {
    src: '/img/aboutPage/team5.jpg',
    name: 'Veteran Staff',
  },
  {
    src: '/img/aboutPage/team6.jpg',
    name: 'Full Team',
  },
];

const testimonials = [
  {
    src: '/img/aboutPage/testimonialsJoi.png',
    name: 'Joe',
    city: 'San Francisco, CA',
    quote: `“Classes give you confidence. After just a couple of classes I started going out on some calls... First week I did just a few jobs. This last week I did about 18-19.”`,
  },
  {
    src: '/img/aboutPage/testimonialsRaphael.png',
    name: 'Raphael',
    city: 'Austin, TX',
    quote: `“I used to be a limousine driver...now with ${companyName} I just work Monday through Friday, 8 to 5, and I make a lot more money than before. ”`,
  },
  {
    src: '/img/aboutPage/testimonialsMilan.png',
    name: 'Milan',
    city: 'Sacramento, CA',
    quote: `“The ${companyName} platform has a huge influence on people’s lives. Simply by offering the opportunity to be in control of your life.”`,
  },
];

export default function NanaCulture(): JSX.Element {
  return (
    <div>
      <AboutNav />
      <div className="container mx-auto grid max-w-1300 grid-cols-1 grid-rows-8 sm:grid-cols-2 sm:grid-rows-4">
        <div className="row-span-1">
          <br />
          <br />
          <Text type="headline-6" className="mb-2 text-center text-grey-dark sm:text-left">
            We built {companyName} to find time to do the things we love
          </Text>
          <Text className="text-center text-4xl font-semibold text-font-dark sm:text-left sm:text-6xl sm:font-bold">
            Making people happy
          </Text>
          <br />
        </div>
        <div className="row-span-4">
          <img
            src="../../img/aboutPage/nanaCultureCollage.png"
            alt="Culture Collage"
            className="hidden sm:inline-block"
          />
        </div>
        <div className="row-span-2">
          <Text className="text-center text-lg text-font-dark sm:text-left">
            We believe that life is too short and that a streamlined home service solution will
            enable our customers to spend as much time as possible with their loved ones - instead
            of toiling over the tediousness of appliance issues and home maintenance needs.
          </Text>
          <br />
          <Link
            to="/history"
            className="flex w-full items-center justify-center border-0 align-middle no-underline sm:w-auto sm:justify-start"
          >
            <Text className="mr-2 mt-1 text-lg text-primaryCTA hover:text-primaryCTA-5">
              Learn More
            </Text>
            <Icon name="arrow_right" color="primaryCTA" size={16} />
          </Link>
          <br />
        </div>

        <div className="h-16">
          <div className="absolute">
            <img
              className="w-full lg:w-auto"
              src="../../img/aboutPage/nanaTeam.png"
              alt="Our Team"
              style={{ maxWidth: 590 }}
            />
          </div>
        </div>
      </div>
      {/******** Vision Mission *********/}
      <div className="bg-primaryCTA-3">
        <div className="container mx-auto max-w-1300 grid-cols-2 grid-rows-2 sm:grid">
          <div className="row-span-2 pt-48 sm:pt-0" />
          <div className="h-64">
            <div className="sm:ml-32">
              <div className="mb-4 mt-12 rounded-sm bg-white p-12">
                <Text type="headline-6" className="text-center text-grey-dark">
                  Vision
                </Text>
                <Text className="text-center text-lg text-font-dark">
                  Empower the tradespeople of tomorrow to control their economic destiny.
                </Text>
              </div>
            </div>
          </div>
          <div className="h-64">
            <div className="sm:ml-32">
              <div className="mb-12 rounded-sm bg-white p-12 sm:mt-4">
                <Text type="headline-6" className="text-center text-grey-dark">
                  Mission
                </Text>
                <Text className="text-center text-lg text-font-dark">
                  Create economic opportunities by transforming the trades through education,
                  community, and technology.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/******** Values *********/}
      <div>
        <div className="container mx-auto max-w-1300">
          <Text className="mt-12 text-center text-4xl font-semibold text-font-dark sm:mt-24">
            Values
          </Text>
          <div className="flex flex-col items-center justify-center sm:flex-row">
            {valuesRowOne.map(item => (
              <div
                key={item.name}
                className="mx-4 my-10 flex flex-col items-center justify-center p-4 text-center"
                style={{ maxWidth: 250 }}
              >
                <img src={item.src} alt={item.name} />
                <Text
                  className="mt-2 text-center text-lg text-font-dark"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {item.desc}
                </Text>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center sm:flex-row">
            {valuesRowTwo.map(item => (
              <div
                key={item.name}
                className="mx-4 my-10 flex flex-col items-center justify-center p-4 text-center"
                style={{ maxWidth: 250 }}
              >
                <img src={item.src} alt={item.name} />
                <Text
                  className="mt-2 text-center text-lg text-font-dark"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {item.desc}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/******** Culture *********/}
      <div className="bg-primaryCTA-3">
        <div
          className="container mx-auto grid-cols-3 content-center sm:grid"
          style={{ maxWidth: 1300 }}
        >
          <div className="place-self-center">
            <Text className="pb-8 pt-12 text-center text-4xl font-semibold text-font-dark sm:my-10 sm:py-0">
              Culture
            </Text>
            {cultureList.map(item => (
              <Text
                key={item}
                className="text-center text-xl font-semibold text-font-dark sm:my-10"
              >
                {item}
              </Text>
            ))}
          </div>
          <div className="my-6 place-self-end">
            {culturePicsOne.map(item => (
              <img
                className="m-3 mx-auto rounded-2xl sm:mx-3"
                key={item.name}
                src={item.src}
                alt={item.name}
                style={{ maxWidth: 300 }}
              />
            ))}
          </div>
          <div className="self-center">
            {culturePicsTwo.map(item => (
              <img
                className="m-3 mx-auto rounded-2xl sm:mx-3"
                key={item.name}
                src={item.src}
                alt={item.name}
                style={{ maxWidth: 340 }}
              />
            ))}
          </div>
        </div>
      </div>
      {/******** Testimonials ********/}
      <div>
        <div className="container mx-auto max-w-1300">
          <Text className="mt-12 text-center text-4xl font-semibold text-font-dark sm:mt-24">
            Testimonials
          </Text>
          <div className="flex flex-col items-center justify-center sm:flex-row">
            {testimonials.map(item => (
              <div
                key={item.name}
                className="mx-4 my-10 flex flex-col items-center text-center sm:self-start"
                style={{ maxWidth: 364 }}
              >
                <img src={item.src} alt={item.name} style={{ maxWidth: 140 }} />
                <div className="flex">
                  <Text className="mr-3 mt-2 text-center text-lg text-font-dark">{item.name}</Text>
                  <Text className="mt-2 text-center text-lg text-grey-dark">{item.city}</Text>
                </div>
                <Text className="mt-2 text-left text-sm italic text-secondary-5">{item.quote}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      <GrowingTeam />
      <Footer />
    </div>
  );
}
