import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Card from '../../com/ui/card';

const ServiceArea = ({ cities }) => (
  <div className="container">
    <Card title="Service Areas">
      {cities.map(city => (
        <div key={city}>{city}</div>
      ))}
    </Card>
  </div>
);

export default connect(s => ({
  cities: _.get(s.pros[s.me.proId], 'serviceAreas', []).map(a => a.locality),
}))(ServiceArea);
