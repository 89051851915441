import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLazyLegacyAPI } from '@nanaio/hooks';
import { WorkOrder } from '@nanaio/util';
import { Link } from '@/com/ui/link';
import { Button, Icon, Input, InputType, Modal } from '@/components';
import { apiOrigin } from '@/config/const';
import { File } from '../../com/ui/form';

type Props = {
  signature: string;
  workOrderId: string;
  isRepairConfirmationSignature: boolean;
};

type SaveSignature = {
  value?: string;
  url?: string;
};

function Signature({
  signature: workOrderSignature,
  workOrderId,
  isRepairConfirmationSignature,
}: Props): JSX.Element {
  const [signature, setSignature] = useState(workOrderSignature);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState('');
  const [uploadSignature, setUploadSignature] = useState('');

  const qs = isRepairConfirmationSignature ? '?repairConfirmation=1' : '';
  const [updateSignature, { error: updateSignatureError }] = useLazyLegacyAPI<WorkOrder>(
    `workOrders/${workOrderId}/signature${qs}`,
    {
      method: 'put',
    }
  );
  const [removeSignature, { error: removeSignatureError }] = useLazyLegacyAPI<void>(
    `workOrders/${workOrderId}/signature${qs}`,
    {
      method: 'delete',
    }
  );

  const signatureIsImage = () => {
    return signature.endsWith('.png') || signature.endsWith('.jpg');
  };

  const save = async (body: SaveSignature) => {
    const workOrder = await updateSignature(body);
    if (!workOrder) {
      return;
    }
    setSignature(workOrder?.signature as string);
    setUploadSignature('');
    setModalIsOpen(false);
  };

  const remove = async () => {
    await removeSignature();
    setSignature('');
    setUploadSignature('');
    setModalIsOpen(false);
  };

  return (
    <>
      <Button size="small" onClick={() => setModalIsOpen(true)}>
        Add signature link
      </Button>
      <File id="uploadSignature" label="" onChange={setUploadSignature} />
      {uploadSignature && (
        <div className="btn btn-primary" onClick={() => save({ value: uploadSignature })}>
          Save
        </div>
      )}
      {updateSignatureError}
      {removeSignatureError}
      {signature && (
        <div className="row">
          <div className="col-sm-3">
            <Icon className="float-right cursor-pointer" name="close" onClick={remove} size={16} />
            {signatureIsImage() ? (
              <img
                alt="signature"
                src={`${apiOrigin}/images${signature}`}
                style={{ width: '100%' }}
              />
            ) : (
              <Link to={signature}>
                <p>{signature}</p>
              </Link>
            )}
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onSave={() => save({ url: signatureUrl })}
        onClose={() => setModalIsOpen(false)}
      >
        <Modal.Header title="Add Signature Link" />
        <Modal.Body className="p-4">
          <p className="text-center">
            Adding a link (e.g. zendesk link) will override the current signature (if it exists)
          </p>
          <Input
            value={signatureUrl}
            label="Signature Url"
            type={InputType.TEXT}
            onChange={(url?: string) => setSignatureUrl(url as string)}
          />
        </Modal.Body>
        <Modal.Footer noValidate />
      </Modal>
    </>
  );
}

export default connect()(Signature);
