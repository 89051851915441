import React from 'react';
import { Task } from '@nanaio/util';
import { Button, Icon, Modal, Text } from '@/components';
import { companyName } from '@/config/const';
import RefundTooltip from './RefundTooltip';
import ReturnTooltip from './ReturnTooltip';

type Props = {
  onStepClick: () => void;
  task: Task;
};

export default function InformationStep({ onStepClick, task }: Props): JSX.Element {
  const vendorFullName = task.customer.org ? task.customer.org.name : 'your home warranty company';
  return (
    <>
      <Modal.Header title="Close Work Order" subtitle="1 of 2 steps" progress={0.5} />
      <Modal.Body>
        <div className="p-6">
          <Text className="text-center" type="headline-6">
            Are you sure you want to close this work order?
          </Text>
          <Text className="mt-2 text-center" color="grey.dark">
            Here’s what to expect if you do:
          </Text>
          <div className="mt-12 flex">
            <Icon className="mr-4" name="close_octagon_outline" color="danger" />
            <div>
              <Text type="button">{companyName} will discontinue service immediately</Text>
              <Text className="mt-4" color="grey.dark">
                We’ll stop service on this appliance and send your work order back to{' '}
                {vendorFullName} for further assistance. All future {companyName} appointments for
                this work order will be cancelled.
              </Text>
            </div>
          </div>
          <div className="mt-12 flex">
            <Icon className="mr-4" name="truck_cargo_container" color="danger" />
            <div>
              <Text type="button">You’ll need to return ordered parts</Text>
              <Text className="mb-4 mt-4" color="grey.dark">
                We’ll send a return label with further instructions to your email within 5 to 7
                business days.
              </Text>
              <ReturnTooltip />
              <Text className="mt-8" color="grey.dark" type="helper">
                Note: Installed parts will no longer be returnable and may not be covered under your
                home warranty provider policy. If your policy does not cover the cost of the
                installed parts, we will reach out for further instructions.
              </Text>
            </div>
          </div>
          <div className="mt-12 flex">
            <Icon className="mr-4" name="cash_refund" color="danger" />
            <div>
              <Text type="button">All refunds are subject to review</Text>
              <Text className="mb-4 mt-4" color="grey.dark">
                If your work order is eligible for a refund, we will submit a claim. If your claim
                is approved, we’ll process a refund to your original payment method within 1-2
                weeks. The actual refund amount may vary based on plan benefits and other factors.
              </Text>
              <RefundTooltip />
              <Text className="mt-8" color="grey.dark" type="helper">
                Note: Copays, diagnosis, trip fees, or deductibles are non-refundable if a
                technician has been on-site previously. This helps support our technicians and
                covers the cost of travel (gas, insurance).
              </Text>
            </div>
          </div>
          <div className="mt-12 flex">
            <Icon className="mr-4" name="heart_outline" color="danger" />
            <div>
              <Text type="button">We’ll always be happy to have you back</Text>
              <Text className="mt-4" color="grey.dark">
                If you’d like to recontinue service with us, you’re always welcome! Just contact{' '}
                {vendorFullName} and request us.
              </Text>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={() => onStepClick()} variant="danger">
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
}
