export const types = ['cancelled', 'claimed', 'completed', 'pending', 'recall'];

export const statusClass = {
  cancelled: 'bg-white text-dark border-dark text-black',
  claimed: 'bg-primary',
  completed: 'bg-success text-white border-success',
  inProgress: 'bg-purple text-white',
  pending: 'bg-danger text-white border-danger',
  recall: 'bg-warning border-warning',
  suggested: 'bg-danger text-white border-danger',
};

export const statusIcons = {
  cancelled: `${global.location.origin}/img/map/cancelled.svg`,
  claimed: `${global.location.origin}/img/map/claimed.svg`,
  completed: `${global.location.origin}/img/map/completed.svg`,
  home: `${global.location.origin}/img/map/home.svg`,
  inProgress: `${global.location.origin}/img/map/inProgress.svg`,
  location: `${global.location.origin}/img/map/currentLocation.svg`,
  parts: `${global.location.origin}/img/map/parts.svg`,
  pending: `${global.location.origin}/img/map/pending.svg`,
  recall: `${global.location.origin}/img/map/recall.svg`,
  suggested: `${global.location.origin}/img/map/suggested.svg`,
  unconfirmed: `${global.location.origin}/img/map/unconfirmed.svg`,
};

export const statusIconLegend = [
  'cancelled',
  'claimed',
  'completed',
  'inProgress',
  'pending',
  'recall',
  'suggested',
  'unconfirmed',
  'home',
  'location',
  'parts',
];
