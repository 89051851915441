import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Icon } from '@/components';
import { confluenceUrls } from '@/config/const';
import { Text } from '../../com/ui/form';
import Loader from '../../com/ui/loader';

const descriptions = {
  admin: 'Edit user permissions',
  customersupport: 'Use internal tools',
  discount: 'Add discounts to jobs',
  export: 'Export data as CSV',
  partsApi: 'Parts API user',
  promanager: 'Edit pro rating',
  promo: 'Add or remove job promos',
  propay: 'View pro pay',
  recordings: 'Listen to call recordings',
  refund: 'Give job refunds',
  schedule: 'No restrictions on creating visits such weekend vendor restrictions',
  serviceprovider: 'A pro',
  tester: 'View test jobs',
  user: 'All users have this',
};

class Permissions extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    this.state = { loading: true };
    U.api('post', 'users/search', {
      limit: -1,
      query: { roles: U.user.Role.CUSTOMERSUPPORT },
    }).then(users => {
      let newUsers = users.map(user => ({ ...user, roleMap: U.toMap(user.roles) }));
      newUsers = _.sortBy(newUsers, 'profile.fullName');
      this.setState({ users: newUsers, loading: false });
    });
  }

  getChildContext = () => ({ t: this });

  filterUsers = () => {
    const { search, users } = this.state;
    if (search) {
      const lowerSearch = search.toLowerCase();
      return users.filter(user => _.includes(user.profile.fullName.toLowerCase(), lowerSearch));
    }
    return users;
  };

  async toggleRole(user, role) {
    const { users } = this.state;
    const validRoles = U.toMap(_.map(U.toOptions(U.user.roleOptions), 'id'));
    let roles;
    if (_.includes(user.roles, role)) {
      roles = user.roles.filter(r => r !== role && validRoles[r]);
    } else {
      roles = [...user.roles, role].filter(r => validRoles[r]);
    }
    const changes = [{ action: 'replace', path: 'roles', value: roles }];
    const response = await U.api('put', `users/${user.id}`, changes);
    if (response.errMsg) {
      return this.setState({ error: response.errMsg });
    }
    response.roleMap = U.toMap(response.roles);
    const newUsers = users.map(u => (u.id === user.id ? response : u));
    this.setState({ users: newUsers });
  }

  openUser = user => {
    window.open(`/users/${user.id}`, '_blank').focus();
  };

  render() {
    const { roles } = this.props;
    const { error, loading } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <Button className="m-2" href={confluenceUrls.userRoles} target="_blank">
          See Confluence Page For Role Information
        </Button>
        <div className="text-danger">{error}</div>
        <Text className="m-2" id="search" />
        <table className="table-sm table-striped table-hover table">
          <thead>
            <tr>
              <th>Users</th>
              {U.toOptions(U.user.roleOptions).map(role => (
                <th key={role.id}>{_.startCase(role.name)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.filterUsers().map(u => (
              <tr key={u.id}>
                <td className="pointer" onClick={() => this.openUser(u)}>
                  {_.get(u, 'profile.fullName')}
                </td>
                {U.toOptions(U.user.roleOptions).map(role =>
                  roles.admin ? (
                    <td
                      key={role.id}
                      className="pointer"
                      onClick={() => this.toggleRole(u, role.id)}
                    >
                      {u.roleMap[role.id] ? (
                        <Icon name="check_box_outline" />
                      ) : (
                        <Icon name="check_box_outline_blank" />
                      )}
                    </td>
                  ) : (
                    <td key={role.id}>{u.roleMap[role.id] && <Icon name="check" />}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <table className="table-sm table-striped table-hover mt-5 table">
          <thead>
            <tr>
              <th>Role</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {_.map(descriptions, (description, role) => (
              <tr key={role}>
                <td>{_.startCase(role)}</td>
                <td>{description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

Permissions.propTypes = { roles: U.user.rolesPropType };

export default connect(s => ({ roles: U.user.roles(s) }))(Permissions);
