import React from 'react';
import { T, Task, W, WorkOrder } from '@nanaio/util';
import type { RestrictedPro } from '@nanaio/util/dist/workOrder';
import _ from 'lodash';
import { Text } from '@/components';

type WorkOrderVisitProTask = WorkOrder['visits'][0]['pros'][0]['tasks'][0];

function getRestrictedProContent(restrictedProData?: RestrictedPro) {
  if (!restrictedProData) {
    return undefined;
  }

  // This covers up some bad data created between 5/26/23 and 6/8/23 where the notes field was being populated with formatted text
  if (restrictedProData.notes.startsWith('Task Unclaimed by')) {
    return restrictedProData.notes;
  }

  const reasonsById = _.keyBy(W.restrictReasonOptions, 'id');
  const reasonNames = restrictedProData.reasons.map(reason => reasonsById[reason].name);
  const unclaimJobReason = `Reason(s): ${reasonNames.join(', ')}. ${
    restrictedProData.notes ? 'Notes: '.concat(restrictedProData.notes) : ''
  }`;

  return `Unclaimed, ${unclaimJobReason}`;
}

type Props = {
  onOpenTask: (task: WorkOrderVisitProTask) => void;
  proId: string;
  task: Task;
  visitProTask: WorkOrderVisitProTask;
  workOrder: WorkOrder;
};

export default function ProTaskSection({
  onOpenTask,
  proId,
  task,
  visitProTask,
  workOrder,
}: Props): JSX.Element {
  const localTask = W.getTaskById({ taskId: visitProTask.id, workOrder });
  if (!localTask) {
    return <></>;
  }
  const isCurrentViewedTask = visitProTask.id === task.id;

  const appliance = T.appliance(localTask);
  const proRole = _.startCase(visitProTask.role || 'primary');

  const restrictedProData = workOrder.restrictedPros?.find(
    restrictedPro => restrictedPro.proId === proId
  );

  const restrictedProContent = getRestrictedProContent(restrictedProData);

  return (
    <Text
      className={isCurrentViewedTask ? '' : 'pointer link-blue'}
      onClick={isCurrentViewedTask ? undefined : () => onOpenTask(visitProTask)}
    >
      {appliance}: {restrictedProContent ? restrictedProContent : proRole}
    </Text>
  );
}
