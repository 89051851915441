import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Select } from '../../com/ui/form';

class Ledger extends React.Component {
  static propTypes = {
    reloadTransactions: PropTypes.func.isRequired,
    transactions: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['estimate', 'invoice']),
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { paidBy: p.paidBy, transactionsMap: _.keyBy(p.transactions, '_id') };
    this.paidByChange = this.paidByChange.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(this.props.transactions, p.transactions)) {
      this.setState({ transactions: _.keyBy(p.transactions, '_id') });
    }
  }

  async paidByChange(transaction) {
    await U.api('put', `payments/${transaction.id}/toggleChargee`);
    this.props.reloadTransactions();
    U.api('get', `workorders/${this.props.workorder.id}`, ['save']);
  }

  toDate = date => date && m(date).format('ddd, MMM DD, YYYY, h:mm a');

  timestamp = date => date && m(date).fromNow();

  render() {
    const isInvoice = this.props.type === 'invoice';
    const ledger = _.values(_.get(this.props.workorder, 'ledger.customer'));
    return (
      <div style={{ overflowX: 'auto' }}>
        {isInvoice && !!ledger && (
          <table id="vc-table" className="td-border-all">
            <thead>
              <tr className="ledgerHead">
                {T.hasOrg(this.props.workorder) && (
                  <th>
                    <p>Paid By</p>
                  </th>
                )}
                {[
                  'Type',
                  'Amount',
                  'User',
                  'Time',
                  'Gateway',
                  'Check#',
                  'Payment#',
                  'Payment Date',
                  'Bank Name',
                  'Deposit Acct',
                  'Transit Id',
                ].map(c => (
                  <th key={c} style={{ whiteSpace: 'nowrap' }}>
                    <p>{c}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.transactions.map(v => (
                <tr key={v.id} className="ledgerRow">
                  {T.hasOrg(this.props.workorder) && (
                    <td valign="top">
                      <Select
                        id={`transactions.${v.id}.chargeeId`}
                        label=""
                        onChange={() => this.paidByChange(v)}
                        options={this.props.paidByOptions}
                        style={{ width: '200px' }}
                      />
                    </td>
                  )}
                  <td valign="top">
                    <p className="weight-700 text-blue">
                      {_.startCase(v.tranType)}{' '}
                      {_.get(v, 'last4') ? `to •••• ${_.get(v, 'last4')}` : ``}{' '}
                      {_.get(v, 'metadata.methodOfPayment')
                        ? `to ${_.startCase(_.get(v, 'metadata.methodOfPayment'))}`
                        : ``}
                    </p>
                  </td>
                  <td valign="top">
                    <p>{U.toMoney(v.amount)}</p>
                  </td>
                  <td valign="top">
                    <p>{v.byUserName}</p>
                  </td>
                  <td valign="top">
                    <p>{this.toDate(v.createTime)}</p>
                    <span className="text-muted timestamp">({this.timestamp(v.createTime)})</span>
                  </td>
                  <td valign="top">
                    <p>
                      {_.get(v, 'metadata.paymentGateway.paymentGateway') ||
                        _.get(v, 'metadata.paymentGateway') ||
                        _.get(v, 'metadata.last4')}
                    </p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.checkNumber')}</p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.paymentNumber')}</p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.paymentDate')}</p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.bankName')}</p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.depositAcct')}</p>
                  </td>
                  <td valign="top">
                    <p>{_.get(v, 'metadata.transitId')}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const workorder = s.workorders[job.metadata.workOrderId];
  const paidByOptions = [
    { id: job.customer.user.id, name: job.customer.user.fullName },
    job.customer.org,
  ];
  return { job, paidByOptions, workorder };
})(Ledger);
