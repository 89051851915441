import * as Table from '@nanaio/table';
import _ from 'lodash';

export const checkColumnWidth = 50;

export const defaultColumnWidth = 150;

export const fixedColumnCount = 1;

export const fixedRowCount = 2;

export const headerHeight = 56;

/** remove query properties that are not stored in mongo */
export const trimQuery = (
  query: Table.Query
): Table.Query & { columns: Partial<Table.Column>[] } => {
  const columns = _.map(query.columns, column => {
    const out = _.pick(column, [
      'databaseId',
      'id',
      'key',
      'linkBy',
      'table',
    ]) as Partial<Table.Column>;
    if (column.search?.type) {
      out.search = { type: column.search.type };
    }
    const search = column.search?.value;
    if (column.filterIsValid ? column.filterIsValid(search) : search) {
      out.search = column.search;
    }
    return out;
  });
  return { ...query, columns } as Table.Query & { columns: Partial<Table.Column>[] };
};
