import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import Badge from '../../core/Badge';
import Spacer from '../../core/Spacer';
import Text from '../../core/Text';
import theme from '../../theme';
import Cell from '../Cell';
import { availabilityPropType, capacityPropType } from '../util';
import Slot from './Slot';

export default function DayHeader({
  availability,
  capacity,
  dayIndex,
  date,
  mapDate,
  mapIsOpen,
  setMapDate,
  startTimes,
  updateSlot,
  visit,
}) {
  const isToday = m(date).isSame(m().startOf('day'));
  const textFormat = isToday ? '[Today, ]MMM D' : 'ddd, MMM D';
  const visitCount = _.sumBy(capacity, 'visits');
  const visitLimit = _.sumBy(capacity, ({ limit }) => (_.isNumber(limit) ? limit : Infinity));
  const capacityPercent = visitCount / visitLimit;

  let badgeColor = 'danger';
  if (capacityPercent < 0.6) {
    badgeColor = 'success';
  } else if (capacityPercent < 0.75) {
    badgeColor = 'icons-orange';
  }

  return (
    <Cell
      date={date}
      hasOverlay={mapIsOpen && !m(mapDate).isSame(date)}
      onMouseEnter={() => setMapDate(date)}
      style={theme.shadowBottom}
    >
      <Spacer
        className="border-b border-grey-medium p-4"
        rightContent={
          capacity[0] &&
          !_.isNaN(capacityPercent) && (
            <Badge color={badgeColor}>{U.toPercent(capacityPercent)}</Badge>
          )
        }
        style={{ height: '57px' }}
        width={60}
      >
        <Text className={isToday ? 'text-primary' : ''} type="page-title">
          {m(date).format(textFormat)}
        </Text>
      </Spacer>
      <div className="flex flex-row">
        {_.times(3, i => (
          <Slot
            capacity={capacity[i]}
            hasAvailability={!!availability[i]}
            hasBorder={i === 1}
            isActive={visit.slot && m(visit.slot.start).isSame(startTimes[i])}
            isDisabled={!capacity[i] || capacity[i].isDisabled}
            key={i}
            slotIndex={dayIndex * 3 + i}
            startTime={startTimes[i]}
            title={['8:00 AM', '12:00 PM', '4:00 PM'][i]}
            updateSlot={updateSlot}
          />
        ))}
      </div>
    </Cell>
  );
}

DayHeader.propTypes = {
  availability: availabilityPropType.isRequired,
  capacity: PropTypes.arrayOf(capacityPropType).isRequired,
  date: U.timePropType.isRequired,
  dayIndex: PropTypes.number.isRequired,
  mapDate: U.timePropType,
  mapIsOpen: PropTypes.bool.isRequired,
  setMapDate: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  visit: W.visitPropType.isRequired,
  updateSlot: PropTypes.func.isRequired,
};
