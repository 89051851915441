import React, { useCallback, useEffect, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import { workOrderEvent } from '@/com/analytics';
import { Alert, APIError, Button, Icon, Modal, Text } from '@/components';
import { useLazyLegacyAPI } from '@/hooks';
import { initStripe } from '../../../../com/user';
import type { SubStepProps as Props } from './ConfirmationFlowModal';

export default function ProfileCardStep({ task, onNextSubStep }: Props): JSX.Element {
  const [cardError, setCardError] = useState<JSX.Element | undefined>();

  useEffect(() => {
    initStripe();
  }, []);

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={
          <>
            Unable to save credit card.
            <br />
            {error}
          </>
        }
      />
    ),
    save: true,
    method: 'put',
  });

  const handleNext = useCallback(async () => {
    setCardError(undefined);
    const stripeResponse = await global.stripe.createToken(global.card);
    if (stripeResponse.error) {
      setCardError(
        <Alert className="mb-10" variant="error" title="Card Error">
          {stripeResponse.error.message}
        </Alert>
      );
      return;
    }

    const cardChanges = [{ path: 'payment', value: { stripeToken: stripeResponse.token.id } }];
    const data = await updateTask(cardChanges);
    if (!data) {
      return;
    }
    const eventInfo = {
      variant: 'ftc',
      step: 'creditCardAdded',
    };
    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);
    await onNextSubStep();
  }, [onNextSubStep, updateTask]);

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          <div>
            {!taskToUpdate.error && !cardError && (
              <Alert className="mb-10" variant="info" icon="credit_card_outline">
                You will not be charged until you provide authorization.
              </Alert>
            )}
            {taskToUpdate.error}
            {cardError}
            <Text className="mb-4 text-center text-font-dark" type="headline-6">
              Add Payment Method
            </Text>
            <Text className="mb-2 text-center">
              A credit card is required in order to confirm your repair
            </Text>
            <Text className="mb-8 ml-2 list-disc text-grey-dark" tag="ul">
              <Text tag="li" className="mb-1 text-grey-dark">
                A $70 diagnostic fee will be charged during your first visit.
              </Text>
              <Text tag="li" className="text-grey-dark">
                This diagnostic fee will be waived if you choose to proceed with the repair.
              </Text>
            </Text>
            <div id="card-element" />
            <div id="card-errors" className="h-14" role="alert" />
            <hr />
            <div className="mt-10 flex justify-between">
              <div className="flex">
                <Icon name="lock_outline" className="h-5" />
                <Text className="ml-2 text-secondary" type="label">
                  Guaranteed safe &amp; secure
                </Text>
              </div>
              <img src="../../../img/logos/stripe/powered_by_stripe.svg" alt="" className="h-7" />
            </div>
            <div className="mb-10 mt-4 flex space-x-4">
              <img src="../../../img/logos/stripe/Visa.svg" alt="" className="h-5" />
              <img src="../../../img/logos/stripe/MasterCard.svg" alt="" className="h-5" />
              <img src="../../../img/logos/stripe/JCB.svg" alt="" className="h-5" />
              <img src="../../../img/logos/stripe/Diners Club.svg" alt="" className="h-5" />
              <img src="../../../img/logos/stripe/Union Pay.svg" alt="" className="h-5" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={handleNext} submit>
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
