import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { T, Task, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { sendPageview } from '@/com/marketing';
import { Alert, Button, Icon, Input, RadioTileList, Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import LoadingIndicator from '@/pages/book/steps-V2/com/LoadingIndicator';
import useLoadAvailability from '@/pages/book/steps-V2/hooks/useLoadAvailability';
import Footer from './com/Footer';
import { BookingState } from './util';

export const PATH_BY_SERVICE_ID = {
  '591ca9ef4be0b58f4e3df491': "metadata.taskQuestions.What's the problem?", // Refrigerator repair
  '591ca9ef4be0b58f4e3df495': "metadata.taskQuestions.What's the problem?", // Dishwasher repair
  '591ca9ef4be0b58f4e3df4a4': 'metadata.taskQuestions.The stove needs?',
  '591ca9ef4be0b58f4e3df4a8': 'metadata.taskQuestions.The Oven needs?',
  '591ca9ef4be0b58f4e3df499': 'metadata.taskQuestions.The Washer needs?',
  '591ca9ef4be0b58f4e3df49d': 'metadata.taskQuestions.The Dryer needs?',
} as const;

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Details({ onChange, state, ...props }: Props): JSX.Element {
  const { brands, services } = useLegacySelector(state => {
    const { brands, services } = state;
    return { brands, services };
  });

  const hasAdditionalInfo = !!_.get(state, 'metadata.taskQuestions.Tell us more');
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(hasAdditionalInfo);

  const { serviceId, startDate } = state;
  const issuePath = PATH_BY_SERVICE_ID[serviceId as keyof typeof PATH_BY_SERVICE_ID];
  const value = _.get(state, issuePath) as string;
  const isOther = _.toUpper(_.trim(value)) === 'OTHER';
  const serviceName = state.services.find(service => service.id === state.serviceId)?.name;
  const brand = nullthrows(state.metadata.taskQuestions.Make);

  const [loadAvailability, { loading }] = useLoadAvailability({
    onChange,
    postalCode: nullthrows(state.zip.value),
    serviceId: nullthrows(serviceId),
    startDate,
    serviceName,
    brand,
  });

  useLayoutEffect(() => {
    if (!state.availability && !loading) {
      void loadAvailability();
    }
  }, [loadAvailability, loading, state.availability]);

  useEffect(() => {
    if (isOther && !showAdditionalInfo) {
      setShowAdditionalInfo(true);
    }
  }, [isOther, showAdditionalInfo]);

  const choices = useMemo(() => {
    const task = { serviceCatalogs: [{ id: serviceId }] } as unknown as Task;
    const workOrder = { tasks: [task] } as unknown as WorkOrder;
    const questions = T.questions({ brands, services, task, workOrder });
    const question = questions.find(q => q.id === issuePath);
    return nullthrows(question?.options as string[]).map(name => ({
      name,
    }));
  }, [serviceId, brands, services, issuePath]);

  useEffect(() => {
    sendPageview(`booking-page/issue`);
  }, []);

  return (
    <>
      <section>
        <Alert variant="success">
          <Text className="mt-px text-secondary-5">
            <strong className="font-semibold">Great!</strong> We have coverage in{' '}
            <strong className="font-semibold">{state.zip.value}</strong>
          </Text>
        </Alert>

        <Text className="mt-6" type="headline-6">
          What issue is your {serviceName?.replace(' Repair', '')?.toLowerCase()} having?
        </Text>
        <Text className="mt-2">Select the most urgent issue.</Text>

        <div className="mt-6">
          <RadioTileList
            className="mb-8"
            choices={choices}
            value={value}
            onSelection={value => onChange(issuePath, value)}
          />

          {!showAdditionalInfo && (
            <Button
              className="flex px-0 py-0"
              variant="link"
              onClick={() => setShowAdditionalInfo(true)}
            >
              <Icon name="edit_outline" color="primaryCTA" />
              <Text className="ml-1 text-primaryCTA hover:text-primaryCTA-hover" type="button">
                Add Additional Info
              </Text>
            </Button>
          )}

          {showAdditionalInfo && (
            <Input
              id="metadata.taskQuestions.Tell us more"
              label="Describe issue"
              onChange={e => {
                onChange('metadata.taskQuestions.Tell us more', e);
              }}
              value={state.metadata.taskQuestions['Tell us more']}
              placeholder="Enter here"
              multiline
              className="mb-9"
            />
          )}

          <Alert hideIcon className="mt-7">
            <div className="flex items-center">
              <img
                style={{ height: 48 }}
                src="/img/specialists.png"
                alt={`high rated, ${brand} specialists`}
              />
              <Text className="ml-4 text-secondary-5">
                We have over 100+{' '}
                <strong className="font-semibold">
                  background checked, high rated, {brand} specialists
                </strong>{' '}
                in our network.
              </Text>
            </div>
          </Alert>

          <Footer {...props} disabled={!value} />
        </div>
      </section>

      <LoadingIndicator isLoading={loading} text="Searching for a technician..." />
    </>
  );
}
