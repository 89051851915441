import React, { useState } from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import { Button, Modal, Text, TextInput } from '@/components';
import { zendeskURL } from '@/config/const';
import { openLink } from '../../../utils';

function Tickets({ task, tickets }) {
  const [comment, setComment] = useState();
  const [error, setError] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onSave = async () => {
    if (!comment) {
      setError('comment required');
    }
    const response = await U.api('post', 'chats', {
      comment,
      jobId: task.id,
      submittedByAgent: true,
      userId: task.customer.user.id,
      workOrderId: task.metadata.workOrderId,
    });
    if (response.errMsg) {
      setError(response.errMsg);
    } else {
      openLink({ newTab: true, url: `${zendeskURL}/agent/tickets/${response.id}` });
      setIsOpen(false);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex justify-end">
        <Button onClick={() => setIsOpen(true)} variant="primary">
          Create New
        </Button>
      </div>
      <table className="table-sm table-striped table-hover table">
        <thead>
          <tr>
            <th>
              <Text type="page-title">Subject</Text>
            </th>
            <th>
              <Text type="page-title">Create Time</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(tickets, ticket => (
            <tr key={ticket.id}>
              <td>
                <Text>{ticket.displayName}</Text>
              </td>
              <td>
                <Text>{m(ticket.created_at).format('M/D/YY h:mma')}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header title="Create Ticket" />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          <Text>Comment</Text>
          <TextInput multiline onChange={setComment} value={comment} />
        </Modal.Body>
        <Modal.Footer onSave={onSave} />
      </Modal>
    </div>
  );
}

export default connect(s => {
  const task = s.tasks[global.id()];
  return { task };
})(Tickets);
