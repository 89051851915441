import React from 'react';
import T from 'prop-types';
import Group, { onChange } from './group';

class File extends React.Component {
  constructor(p) {
    super(p);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const This = this;
    const file = document.querySelector(`#${this.props.id}`).files[0];
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        if (This.props.onChange) {
          This.props.onChange(reader.result);
        }
        onChange(This.context.t, This.props.id, reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  render() {
    const t = this;
    return (
      <Group {...t.props}>
        <input className="form-control" id={t.props.id} onChange={t.onChange} type="file" />
      </Group>
    );
  }
}

File.contextTypes = { t: T.object.isRequired };

File.propTypes = {
  id: T.string.isRequired,
  label: T.string,
  type: T.string,
  hint: T.string,
  enter: T.func,
  disabled: T.bool,
  trans: T.func,
  req: T.bool,
  debounce: T.func,
  debounceLength: T.number,
  secure: T.bool,
  onChange: T.func,
};

export default File;
