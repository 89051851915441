import * as Table from '@nanaio/table';
import _ from 'lodash';
import { CompassDirection } from '../types';
import { getVectorAndChildIndexes } from '../util';

type Props = {
  compassDirection: CompassDirection;
  directionIndex: number;
  index: number;
  vectors: Table.Vector[];
};

export default function handleMove({
  compassDirection,
  directionIndex,
  index,
  vectors,
}: Props): Table.Vector[] {
  const vectorsCopy = _.cloneDeep(vectors);

  if ([CompassDirection.EAST, CompassDirection.WEST].includes(compassDirection)) {
    if (compassDirection === CompassDirection.EAST) {
      const { depth } = vectorsCopy[directionIndex];
      for (let i = directionIndex; i < vectorsCopy.length; i++) {
        vectorsCopy[i].depth += compassDirection === CompassDirection.EAST ? 1 : -1;
        if (i !== directionIndex && vectorsCopy[i].depth <= depth) {
          return vectorsCopy;
        }
      }
    }
    const { depth } = vectorsCopy[directionIndex];
    for (let i = directionIndex; i < vectorsCopy.length; i++) {
      vectorsCopy[i].depth += compassDirection === CompassDirection.EAST ? 1 : -1;
      if (i !== directionIndex && vectorsCopy[i].depth <= depth) {
        return vectorsCopy;
      }
    }
  }

  const currentIndexes = getVectorAndChildIndexes({
    index: directionIndex,
    vectors: vectorsCopy,
  });
  let otherIndex;

  for (
    let i = directionIndex;
    true /* eslint-disable-line no-constant-condition */;
    compassDirection === CompassDirection.NORTH ? (i -= 1) : (i += 1)
  ) {
    if (i !== index && vectorsCopy[directionIndex].depth === vectorsCopy[i].depth) {
      otherIndex = i;
      break;
    }
  }

  const otherIndexes = getVectorAndChildIndexes({ index: otherIndex, vectors: vectorsCopy });
  return [
    ...vectorsCopy.slice(0, _.min([index, otherIndex])),
    ...(compassDirection === CompassDirection.NORTH ? currentIndexes : otherIndexes).map(
      i => vectorsCopy[i]
    ),
    ...(compassDirection === CompassDirection.NORTH ? otherIndexes : currentIndexes).map(
      i => vectorsCopy[i]
    ),
    ...vectorsCopy.slice(
      (_.max([index + currentIndexes.length - 1, otherIndex + otherIndexes.length - 1]) as number) +
        1
    ),
  ];
}
