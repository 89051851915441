import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Modal, Phone, Select, Text } from '../../com/ui/form';

export default class AddVendor extends React.Component {
  static propTypes = {
    isOpen: T.bool,
    toggleOpen: T.func.isRequired,
  };

  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  async submit() {
    if (!this.state.name) {
      return this.setState({ error: 'Name required' });
    }
    if (!this.state.email) {
      return this.setState({ error: 'Email required' });
    }
    if (!this.state.phone) {
      return this.setState({ error: '10-digit phone required' });
    }
    if (!this.state.status) {
      return this.setState({ error: 'Status required' });
    }
    if (!this.state.type) {
      return this.setState({ error: 'Type required' });
    }
    const body = _.pick(this.state, ['phone', 'name', 'email', 'status', 'type']);
    const r = await U.api('post', 'orgs', body, true);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.toggleOpen();
    this.setState(_.mapValues(this.state, () => ''));
    global.open(`/vendors/${r.id}`, '_blank').focus();
  }

  render() {
    return (
      <Modal
        className="ui-modal md ui-select-search"
        title="Add Vendor"
        submit={this.submit}
        isOpen={this.props.isOpen}
        onClose={this.props.toggleOpen}
      >
        <p className="text-danger">{this.state.error}</p>
        <Text id="name" req />
        <Text id="email" req />
        <Phone id="phone" req />
        <Select id="status" options={U.org.statusOptions} cap req />
        <Select id="type" options={U.org.typeOptions} cap req />
      </Modal>
    );
  }
}
