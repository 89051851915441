import React, { createElement, CSSProperties, isValidElement, ReactNode, useState } from 'react';
import U from '@nanaio/util';
import classNames from 'classnames';
import invariant from 'invariant';
import _ from 'lodash';
import theme from '../../theme';
import ShowMoreText from './ShowMoreText';

export type TextTypes =
  | 'body-2'
  | 'button'
  | 'smallButton'
  | 'headline-1'
  | 'headline-2'
  | 'headline-3'
  | 'headline-5'
  | 'headline-6'
  | 'helper'
  | 'label'
  | 'page-title'
  | 'subtitle-1'
  | 'subtitle-2';

export const TYPES: Record<TextTypes, CSSProperties> = {
  'body-2': {
    fontFamily: 'Myriad',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  button: {
    fontFamily: 'Myriad',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  smallButton: {
    fontFamily: 'Myriad',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  'headline-1': {
    fontFamily: 'Myriad',
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: '70px',
    letterSpacing: '-1%',
  },
  'headline-2': {
    fontFamily: 'Myriad',
    fontSize: '48px',
    fontWeight: 600,
    lineHeight: '57.6px',
    letterSpacing: '0em',
  },
  'headline-3': {
    fontFamily: 'Myriad',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '43px',
    letterSpacing: '0em',
  },
  'headline-5': {
    fontFamily: 'Myriad',
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '35px',
  },
  'headline-6': {
    fontFamily: 'Myriad',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '31px',
    letterSpacing: '0.15px',
  },
  helper: {
    fontFamily: 'Myriad',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.4px',
    letterSpacing: '0.25px',
  },
  label: {
    fontFamily: 'Myriad',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.8px',
    letterSpacing: '0.4px',
  },
  'page-title': {
    fontFamily: 'Myriad',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.41px',
  },
  'subtitle-1': {
    fontFamily: 'Myriad',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  'subtitle-2': {
    fontFamily: 'Myriad',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
} as const;

type Props = {
  [key: string]: unknown;
  children?: ReactNode;
  className?: string;
  color?: keyof typeof theme.flatColors;
  cypressId?: string;
  lines?: number;
  noWrap?: boolean;
  onClick?: (event: Event) => Promise<void> | void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  style?: CSSProperties;
  tag?: React.ElementType;
  title?: boolean;
  type?: TextTypes;
  weight?: number;
};

export default function Text({
  children,
  className = '',
  color = 'font.dark',
  cypressId,
  lines,
  noWrap,
  onClick,
  onMouseOut,
  onMouseOver,
  style = {},
  tag = 'p',
  title,
  type = 'body-2',
  weight,
  ...props
}: Props): JSX.Element | null {
  const [isExpanded, setIsExpanded] = useState(false);

  if (children === undefined || children === '') {
    return null;
  }

  const handleClick = (event: UIEvent) => {
    event.stopPropagation();

    if (!onClick) {
      return;
    }

    void onClick(event);
  };

  let newChildren = children;
  if (_.isPlainObject(children) && !isValidElement(children)) {
    try {
      newChildren = JSON.stringify(children);
    } catch (e) {} // eslint-disable-line no-empty
  }

  const whiteSpaceStyle: CSSProperties = { whiteSpace: 'nowrap' };

  const textStyle = {
    ...TYPES[type],
    ...(noWrap ? whiteSpaceStyle : {}),
    ...(_.get(theme.colors, color) ? { color: _.get(theme.colors, color, 'black') as string } : {}),
    ...style,
    ...(weight ? { fontWeight: weight } : {}),
  };

  invariant(!title || U.isText(newChildren), 'If title is entered children must be text');

  const combinedClassNames = classNames('mb-0', className, {
    'cursor-pointer': !!onClick,
  });

  const textUI = createElement(
    tag,
    {
      ...props,
      className: isExpanded ? '' : combinedClassNames,
      'data-cy': cypressId,
      onClick: onClick && handleClick,
      onMouseOut,
      onMouseOver,
      style: textStyle,
      title: title ? newChildren : undefined,
    },
    newChildren
  );

  if (lines) {
    textStyle.letterSpacing = '0px';
    textStyle.width = '100%';
    return (
      <ShowMoreText
        anchorClass="text-primaryCTA cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        lines={lines}
        style={textStyle}
        className={combinedClassNames}
      >
        {textUI}
      </ShowMoreText>
    );
  }

  return textUI;
}
