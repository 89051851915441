import '../../style/page/invoice.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import store from 'store2';
import CreateEstimate from './CreateEstimate';
import CreateInvoice from './CreateInvoice';
import Footer from './Footer';
import Items from './Items';
import Ledger from './Ledger';

function Bill({ org, taskId, type, workOrder }) {
  const cachedTabId = store.get('bill.tab');
  const [tabId, setTabId] = useState(
    _.isString(cachedTabId) &&
      cachedTabId.length === 24 &&
      workOrder.tasks.find(task => task.id === cachedTabId)
      ? cachedTabId
      : undefined
  );
  const [transactions, setTransactions] = useState();

  const loadTransactions = async () => {
    const transactions = await U.api('post', 'payments/search', {
      query: {
        'reference.id': [workOrder.id, ..._.map(workOrder.tasks, 'id')],
        tranType: { $in: ['charge', 'refund'] },
      },
    });
    const users = await U.api('post', 'users/search', {
      projection: { 'profile.fullName': 1 },
      query: { _id: { $in: _.uniq(_.map(transactions, 'byUserId')) } },
    });
    for (const transaction of transactions) {
      transaction.byUserName = users.find(
        user => user.id === transaction.byUserId
      )?.profile?.fullName;
    }
    setTransactions(transactions);
  };

  useEffect(() => {
    loadTransactions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!workOrder) {
    return null;
  }
  if (type === 'estimate' && !workOrder[type]) {
    return <CreateEstimate taskId={taskId} />;
  }
  if (type === 'invoice' && !workOrder.invoice) {
    return <CreateInvoice taskId={taskId} />;
  }

  const handleTabChange = tab => {
    store.set('bill.tab', tab.id);
    setTabId(tab.id);
  };
  const orgId = T.orgId(workOrder);
  const bills = _.compact([
    T.hasOrg(workOrder) &&
      T.type(org) === 'b2b' && {
        bill: workOrder[type].toOrg,
        transactions: _.filter(transactions, transaction => orgId === transaction.chargeeId),
        user: 'org',
      },
    {
      bill: workOrder[type].toCustomer,
      transactions: _.filter(
        transactions,
        transaction => workOrder.customer.user.id === transaction.chargeeId
      ),
      user: 'customer',
    },
  ]);

  return (
    <div id="Admin">
      {_.compact(bills).map(bill => (
        <div className="mb-3" key={bill.user}>
          <div className="mb-5">
            <Items
              bill={bill.bill}
              leftCom={<Footer type={type} />}
              setTab={handleTabChange}
              tab={tabId}
              type={type}
              user={bill.user}
            />
          </div>
          {!tabId && (
            <Ledger
              reloadTransactions={loadTransactions}
              transactions={bill.transactions}
              type={type}
            />
          )}
        </div>
      ))}
    </div>
  );
}

Bill.propTypes = {
  org: U.org.propType,
  type: PropTypes.oneOf(['estimate', 'invoice']),
  workOrder: W.propType,
  // eslint-disable-next-line react/no-unused-prop-types
  taskId: PropTypes.string,
};

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const workOrder = s.workorders[job.metadata.workOrderId];
  const org = s.orgs[T.orgId(workOrder)];
  return { org, workOrder };
})(Bill);
