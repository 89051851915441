import '../../../style/admin-modal.css';

import React from 'react';
import { connect } from 'react-redux';
import { U } from '@nanaio/util';
import _ from 'lodash';
import PT from 'prop-types';
import { Modal, Search,Text } from '../../../com/ui/form';

class EditCatalogPart extends React.Component {
  static propTypes = {
    id: PT.string,
    isEdit: PT.bool,
    isOpen: PT.bool,
    onRemove: PT.func,
    onSuccess: PT.func.isRequired,
    toggleOpen: PT.func.isRequired,
    catalogPartCategories: PT.any,
  };

  static childContextTypes = { t: PT.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      loading: true,
      catalogPart: {
        name: '',
        partNumber: '',
        desc: '',
        mfgCode: '',
        category: '',
        appliance: '',
        brand: '',
        model: '',
        allCategories: [],
        allAppliances: [],
        allBrands: [],
        allModels: [],
        availability: '',
      },
    };
    this.loadCatalogPartIntoState(p);
  }

  loadCatalogPartIntoState = async newProps => {
    const catalogPart = newProps.id ? await U.api('get', `catalogparts/${newProps.id}`) : {};
    this.loadCatalogPartCategories();
    this.setState({ loading: false, catalogPart });
  };

  onRemove = async () => {
    const r = await U.api('delete', `catalogparts/${this.props.id}`);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.props.onRemove();
  };

  loadCatalogPartCategories = async () => {
    const r = await U.api('post', 'catalogpartcategories/search', { limit: -1 }, ['save']);
    return r;
  };

  submit = async () => {
    if (!this.state.catalogPart.partNumber) {
      return this.setState({ errMsg: 'Part Number required' });
    }

    if (!this.state.catalogPart.name) {
      return this.setState({ errMsg: 'Name required' });
    }

    this.setState({ error: false });

    let apiResult;
    if (this.props.id) {
      // update existing
      apiResult = await U.api('put', `catalogparts/${this.props.id}`, this.state.catalogPart);
    } else {
      // add new
      apiResult = await U.api('post', 'catalogparts', this.state.catalogPart);
    }
    if (apiResult.errMsg) {
      return this.setState({ error: apiResult.errMsg });
    }
    this.props.onSuccess(apiResult);
  };

  render() {
    return (
      <Modal
        title={`${this.props.isEdit ? 'Edit' : 'Add'} Catalog Part`}
        isOpen={this.props.isOpen}
        submit={this.submit}
        onClose={this.props.toggleOpen}
        onRemove={this.onRemove}
        wrapClassName="admin-modal"
        modalClassName="admin-modal_container"
        backdropClassName="admin-modal_backdrop"
        className="admin-modal_dialog ui-modal md ui-select-search"
        contentClassName="admin-modal_dialog_inner_container"
        headerClassName="admin-modal_header"
        bodyClassName="admin-modal_body"
        footerClassName="admin-modal_footer"
      >
        <p className="text-danger">{this.state.error}</p>
        <Text id="catalogPart.partNumber" label="Part Number" />
        <Text id="catalogPart.mfgCode" label="Mfg Code" />
        <Text id="catalogPart.name" label="Name" />
        <Text id="catalogPart.desc" label="Desc" />
        <Text id="catalogPart.category" label="Category" />
        <Search
          id="catalogPart.category"
          label="Category"
          className="col-sm-6"
          options={this.props.catalogPartCategories}
          idPath="name"
          cap
        />
        <Search
          id="catalogPart.allCategories"
          label="More Categories"
          multi
          className="col-sm-6"
          options={this.props.catalogPartCategories}
          idPath="name"
          cap
        />
      </Modal>
    );
  }
}

export default connect(s => {
  const catalogPartCategories = _.sortBy(_.values(s.catalogpartcategories), 'name');
  return {
    catalogPartCategories,
  };
})(EditCatalogPart);
