import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Button from './Button';
import Card from './Card';

export default {
  title: 'Core/Card',
  component: Card,
  args: {
    children:
      'Yupanqui was born Héctor Roberto Chavero Aramburu in Pergamino (Buenos Aires Province), in the Argentine pampas, about 200 kilometers away from Buenos Aires. His father was a mestizo of Quechua and Basque origins, while his mother was born in the Basque country.',
  },
  decorators: [
    Story => (
      <div className="flex">
        <Story />
      </div>
    ),
  ],
} as ComponentMeta<typeof Card>;

const Template: ComponentStory<typeof Card> = args => <Card {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const WithTitle = Template.bind({});
WithTitle.args = {
  title: 'Atahualpa Yupanqui',
};

export const WithRightUI = Template.bind({});
WithRightUI.args = {
  title: 'Atahualpa Yupanqui',
  rightUI: (
    <Button size="small" onClick={() => alert('All your base are belong to us...')}>
      Click me!
    </Button>
  ),
};
