import React from 'react';
import _ from 'lodash';
import type { Dispatch } from 'redux';
import { Icon, IconProps, Text } from '@/components';
import { openLink } from '../../utils';

type Props = {
  dispatch: Dispatch;
  highlightPath?: string;
  icon?: IconProps['name'];
  id: string;
  name?: string;
  navPath?: string;
  onClick?: () => void;
  path: string;
};

export default function Link({
  dispatch,
  highlightPath,
  icon,
  id,
  name,
  navPath,
  onClick,
  path,
}: Props): JSX.Element {
  // the root url should highlight the work orders tab in the sidebar
  const linkPath = path === '/' ? '/workOrders' : path;
  let isActive;
  if (id === 'jobs') {
    isActive =
      linkPath === '/tasks' ||
      (_.startsWith(linkPath, '/tasks/') && !linkPath.match(/\/tasks\/[a-f0-9]{24}/));
  } else {
    isActive = _.startsWith(linkPath, `/${highlightPath || id}`) || _.startsWith(linkPath, navPath);
    if (id === 'workOrders' && linkPath.match(/\/tasks\/[a-f0-9]{24}/)) {
      isActive = true;
    }
  }
  const url = navPath || id;
  const newTab = url.startsWith('http');
  return (
    <div
      className={`hover:bg-black hover:bg-opacity-20 ${isActive ? 'bg-black bg-opacity-20' : ''}`}
      onClick={() => (onClick ? onClick() : openLink({ newTab, url, dispatch }))}
    >
      <div className="flex cursor-pointer px-2 py-3">
        {icon && <Icon className="mr-2" color="white" name={icon} />}
        <Text color="white" noWrap type="button">
          {name || _.startCase(id)}{' '}
        </Text>
      </div>
    </div>
  );
}
