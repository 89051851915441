import React from 'react';
import { supportEmail } from '@/config/const';
import { addEvent } from '../../com/marketing';
import Footer from '../../com/ui/footer';
import { Link } from '../../com/ui/link';

export default class Contact extends React.Component {
  constructor(p) {
    super(p);
    addEvent('co');
  }

  render() {
    return (
      <div id="contact2">
        <div className="jumbo-contact">
          <div className="container mt-5 text-center">
            <h1>Support available 7 days a week</h1>
            <br />
            <p>We look forward to helping you.</p>
          </div>
        </div>

        <div className="content-contact" style={{ paddingBottom: '20vh' }}>
          <div className="container max-w-[800px] text-center">
            <div className="card-contact">
              <div className="icon-contact">
                <img alt="email" src="/img/email-icon.png" height="60px" />
              </div>
              <h4>Email and we’ll promptly reply.</h4>
              <br />
              <Link className="hidden-sm-down" name="go-call-desktop" to={`mailto:${supportEmail}`}>
                <button className="btn primary" type="button">
                  Email {supportEmail}
                </button>
              </Link>
              <Link className="hidden-sm-up" name="go-call-mobile" to={`mailto:${supportEmail}`}>
                <button className="btn primary" type="button">
                  Email {supportEmail}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
