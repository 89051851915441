import React from 'react';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import {
  companyAddress,
  companyCity,
  companyNameFullLegal,
  companyZipCode,
  supportPhoneNumber,
} from '@/config/const';
import Loader from '../com/ui/loader';

export default class CollectionsLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    U.api('get', `workOrders/${global.id()}`).then(workOrder => this.setState({ workOrder }));
  }

  render() {
    if (!this.state.workOrder) {
      return <Loader />;
    }

    const { workOrder } = this.state;
    return (
      <div className="container mt-5">
        <h1 className="mb-5 pb-2" style={{ borderBottom: '5px solid black' }}>
          {companyNameFullLegal}
        </h1>
        <div>{companyAddress}</div>
        <div>
          {companyCity}, {companyCity} {companyZipCode}
        </div>
        <div>{supportPhoneNumber}</div>
        <div className="mt-5">Date: {m().format('MM/DD/YYYY')}</div>
        <div className="mt-5">
          <strong>{workOrder.cx.name}</strong>,
        </div>
        <div className="mt-5">
          On <strong>{m(W.lastVisitStart(workOrder)).format('MMMM D, YYYY')}</strong>, a technician
          from {companyNameFullLegal}, <strong>{W.proName({ workOrder })}</strong>, went out to your
          residence at <strong>{U.addressToString(workOrder.cx.address)}</strong> in regard to your{' '}
          <strong>{W.title(workOrder, T.makeAppliance)}</strong>.
        </div>
        <div className="mt-5">
          We have reached out to you on several different occasions to collect payment.
        </div>
        <div className="mt-5">
          Please understand the diagnosis and/or repair has been completed. However, the balance has
          yet to be paid. We are requesting that you pay the balance of{' '}
          <strong>{U.toMoney(_.get(workOrder, 'invoice.toCustomer.balance'))}</strong>. If we do not
          receive payment from you within 10 days of your receipt of this letter, we will have to
          commence proceedings to recover the balance.
        </div>
        <div className="mt-5">
          Please call us at <strong>888.818.0626</strong> for payment options.
        </div>
        <div style={{ marginTop: '10rem' }}>Sincerely,</div>
        <div className="mt-5">
          <strong>{companyNameFullLegal}</strong>
        </div>
        <style>{'strong { font-size: 20px !important; };'}</style>
      </div>
    );
  }
}
