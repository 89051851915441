import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import store from '@/com/store';
import { forwardToZendesk } from '@/com/user/zendeskUtil';
import { Loader, Text } from '@/components';
import { zendeskProUrl } from '@/config/const';
import { getParams } from '../../../utils';

export default function Zendesk() {
  const dispatch = useDispatch();

  const [error, setError] = useState();

  const params = getParams();

  const returnTo = params.return_to || zendeskProUrl;
  const token = params.token || store.getState()?.me?.token;

  useEffect(() => {
    // If you are not logged in and you are trying to access zendesk, you will be redirected to the login page
    if (!token) {
      return dispatch(push(`/login?return_to=${encodeURIComponent(returnTo)}`));
    }

    // Otherwise use your platform token to get a zendesk token
    U.redux.set('me.token', token);
    void forwardToZendesk({ token, returnTo, setError });
  }, [dispatch, returnTo, token]);

  return (
    <div className="flex h-screen content-center justify-center">
      {error ? (
        <Text color="danger" type="headline-6">
          {error}
        </Text>
      ) : (
        <Loader isLoading />
      )}
    </div>
  );
}
