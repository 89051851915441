import { Task } from '@nanaio/util';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchTask = async (id: string): Promise<Task> => {
  const { data: task } = await AXIOS_INSTANCE.get<Task>(`/api/tasks/${id}`);

  // TODO - remove this once the consumers have been changes to use the useGetWorkOrder hook
  U.redux.set(`tasks[${id}]`, task);

  return task;
};

export const useGetTask = (id: string): UseQueryResult<Task> => {
  return useQuery({
    queryKey: ['task', id],
    queryFn: () => fetchTask(id),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateTask = (id: string): UseMutationResult<Task> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeList =>
      AXIOS_INSTANCE.put<Task>(`/api/tasks/${id}`, changeList).then(response => response.data),
    onSuccess: data => {
      queryClient.setQueryData(['task', id], data);
    },
  });
};
