import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Date,Modal, Text } from '../../../com/ui/form';

export default class EditTag extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool,
    onRemove: PropTypes.func,
    onSuccess: PropTypes.func.isRequired,
    toggleOpen: PropTypes.func.isRequired,
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {
      communication: p.row || { key: '', publishDate: '' },
      error: '',
      loading: false,
    };
  }

  saveCommunication = async () => {
    let response;
    const communication = _.pick(this.state.communication, ['key', 'publishDate']);

    if (this.props.id) {
      response = await U.api('put', `inAppCommunications/${this.props.id}`, communication);
    } else {
      response = await U.api('post', 'inAppCommunications', communication);
    }

    if (response.errMsg) {
      this.setState({ error: response.errMsg });
    } else {
      this.props.onSuccess(response);
    }
  };

  handleRemove = async () => {
    const response = await U.api('delete', `inAppCommunications/${this.props.id}`);

    if (response.errMsg) {
      this.setState({ error: response.errMsg });
    } else {
      this.props.onRemove();
    }
  };

  handleSave = async () => {
    const { key, publishDate } = this.state.communication;

    if (!key) {
      this.setState({ error: 'Key required' });
    } else if (!publishDate) {
      this.setState({ error: 'Publish Date required' });
    } else {
      this.saveCommunication();
    }
  };

  render() {
    return (
      <Modal
        title={`${this.props.isEdit ? 'Edit' : 'Add'} In-App Communication`}
        isOpen={this.props.isOpen}
        submit={this.handleSave}
        onClose={this.props.toggleOpen}
        onRemove={this.handleRemove}
        className="ui-modal md ui-select-search"
      >
        <p className="text-danger">{this.state.error}</p>
        <Text id="communication.key" label="Key (ex v4.9.8-new-license-agreement)" />
        <Date id="communication.publishDate" label="Publish date" />
      </Modal>
    );
  }
}
