import React from 'react';
import _ from 'lodash';
import { Alert, Text } from '../../core';

export default function Errors({ errors }: { errors: (string | undefined)[] }): JSX.Element {
  return (
    <div className="mb-4">
      <div className="grid grid-cols-3 gap-x-4">
        <Alert variant="warning">Total: {errors.length}</Alert>
        <Alert variant="success">Succeeded: {errors.length - _.compact(errors).length}</Alert>
        <Alert variant="error">Failed: {_.compact(errors).length}</Alert>
      </div>
      {_.map(_.groupBy(_.compact(errors)), (errors, i) => (
        <Text color="danger" key={i}>
          {`${errors.length} occurrences: ${errors[0]}`}
        </Text>
      ))}
    </div>
  );
}
