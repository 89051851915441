import React from 'react';
import { Pro, U } from '@nanaio/util';
import _ from 'lodash';
import type { Props as AvatarProps } from '@/components/core/Avatar';
import Avatar from '@/components/core/Avatar';
import { kebabColors } from '@/components/core/Badge';
import Tooltip from '@/components/core/Tooltip';
import { useGetPro } from '@/hooks/api/pro';
import { apiOrigin } from '../../../config/const';

const colorByStatus: { [key in Pro['status']]?: (typeof kebabColors)[number] } = {
  [U.pro.Status.ACTIVE]: 'success',
  [U.pro.Status.ACCOUNT_ON_HOLD]: 'accent-orange',
  [U.pro.Status.NOT_SERVING_NOW]: 'accent-orange',
  [U.pro.Status.SUSPENDED]: 'danger',
  [U.pro.Status.REJECTED]: 'danger',
};

type Props = AvatarProps & {
  className: string;
  proId: string;
};

export default function ProAvatar({
  className,
  proId,
  badgeProps,
  size = 'large',
  variant = 'circle',
}: Props): JSX.Element {
  const proQuery = useGetPro(proId);

  const proName = proQuery.data?.user.fullName;
  const pictureUrl = !!proQuery.data?.user.pictureUrl
    ? U.normalizePictureUrl(apiOrigin, proQuery.data.user.pictureUrl)
    : undefined;

  const tooltipText = U.pro.statusOptions.find(
    option => option.id === _.camelCase(proQuery.data?.status)
  )?.name;

  const status = proQuery.data?.status || U.pro.Status.ACTIVE;
  const ringColor = colorByStatus[status] || 'grey-dark';

  return (
    <>
      <div className={className}>
        <Tooltip
          node={tooltipText}
          placement="top"
          trigger={
            <Avatar
              badgeProps={badgeProps}
              name={proName}
              pictureUrl={pictureUrl}
              ringColor={ringColor}
              size={size}
              variant={variant}
            />
          }
        />
      </div>
    </>
  );
}
