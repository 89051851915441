import React from 'react';
import T from 'prop-types';

const sCard = {};
const sTitle = {
  fontFamily: 'Myriad',
  textAlign: 'center',
  fontSize: '1.3rem',
  fontWeight: '500',
  letterSpacing: '.1rem',
  textTransform: 'uppercase',
  margin: '1rem 0rem',
  marginBottom: '2rem',
  lineHeight: '2rem',
};

export const Card = ({ children, title, right }) => (
  <div style={sCard}>
    {right ? <div style={{ float: 'right' }}>{right}</div> : null}
    {title ? <div style={sTitle}>{title}</div> : null}
    {children}
  </div>
);

Card.propTypes = {
  title: T.string,
  right: T.element,
};

export default Card;
