import React from 'react';
import * as Table from '@nanaio/table';
import { Range } from '@nanaio/util';
import _ from 'lodash';
import { Input, InputType } from '../../form';

const editUi = ({
  id,
  key,
  label,
}: {
  id?: string;
  key?: number | string;
  label: string;
}): JSX.Element => <Input id={id} key={key} label={label} type={InputType.NUMBER} />;

const filterUi = ({
  onChange,
  value,
}: {
  onChange?: (id: string, value: number) => void;
  value?: Range<number>;
}): JSX.Element => (
  <div className="d-flex justify-content-between align-items-center">
    <Input
      className="flex-1"
      label="Minimum"
      onChange={value => onChange && onChange('start', value as number)}
      type={InputType.PERCENT}
      value={value?.start}
    />
    <div className="px-2">
      <p className="text-muted">to</p>
    </div>
    <Input
      className="flex-1"
      label="Maximum"
      onChange={value => onChange && onChange('end', value as number)}
      type={InputType.PERCENT}
      value={value?.end}
    />
  </div>
);

const ratioModule: Partial<Table.Column> = { editUi, filterUi };

export default ratioModule;

export type Value = { bot: number; top: number };
