import React from 'react';
import { U } from '@nanaio/util';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Bool, Number, Select } from '../../com/ui/form';

export default function ReturnPartStep1({
  disabled,
  partReturn,
  returnableParts,
  titleCom,
  toggleReturnablePartCheck,
}) {
  return (
    <div id="AdminForm" className="container">
      {titleCom}
      <div className="subtitle">Select Items to Return</div>
      {returnableParts.map((part, index) => (
        <div className="d-flex part align-items-center" key={index}>
          <button
            onClick={() => toggleReturnablePartCheck(index)}
            className={`check-icon mr-3 ${part.status === 'returnRequested' ? `active` : ``}`}
          >
            {part.status === 'returnRequested' && <Icon name="check" color="#fff" size={16} />}
          </button>
          <div className="mr-3">
            <p className="mb-2">{part.desc}</p>
            <table className="desc">
              <tbody>
                <tr>
                  <td className="text-muted pr-2">P/N:</td>
                  <td>{part.partNumber}</td>
                </tr>
                <tr>
                  <td className="text-muted pr-2">Return ID:</td>
                  <td>{partReturn?.referenceNumber}</td>
                </tr>
                <tr>
                  <td className="text-muted pr-2">Return Value:</td>
                  <td>{U.toMoney(part.returnQuantity * part.pricePerUnit)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {part.status === 'returnRequested' && (
            <section className="d-flex">
              {part?.unit?.value > 1 && (
                <div className="mr-3">
                  <Number
                    id={`returnableParts[${index}].returnQuantity`}
                    max={part?.unit?.value}
                    label="Return Qty"
                    min={0}
                    req
                    disabled={disabled}
                  />
                </div>
              )}
              <Select
                id="part.returnReason"
                label="Reason for Return"
                options={U.part.returnReasons}
                req
                cap
                disabled={disabled}
                className="mr-3"
              />
              <Bool
                id={`returnableParts[${index}].returnIsUnopenedPackaging`}
                label="In Original Packaging?"
                req
                disabled={disabled}
                className="mr-3"
              />
              <div className="mr-3">
                <label>Weight</label>
                <div className="d-flex">
                  <Number
                    id={`returnableParts[${index}].unitWeight.value`}
                    min={0}
                    label=""
                    disabled={disabled}
                    className="sm-input mr-2"
                  />
                  <Select
                    id={`returnableParts[${index}].unitWeight.unit`}
                    label=""
                    options={['pound']}
                    cap
                    disabled
                    className="sm-input"
                  />
                </div>
              </div>
              <div>
                <label>Dimensions (W x L x H)</label>
                <div className="d-flex">
                  <Number
                    id={`returnableParts[${index}].unitWidth.value`}
                    min={0}
                    hint="W"
                    label=""
                    disabled={disabled}
                    className="sm-input mr-2"
                  />
                  <Number
                    id={`returnableParts[${index}].unitLength.value`}
                    hint="L"
                    label=""
                    disabled={disabled}
                    className="sm-input mr-2"
                  />
                  <Number
                    id={`returnableParts[${index}].unitHeight.value`}
                    min={0}
                    hint="H"
                    label=""
                    disabled={disabled}
                    className="sm-input mr-2"
                  />
                  <Select
                    id={`returnableParts[${index}].unitHeight.unit`}
                    label=""
                    options={['inch']}
                    cap
                    disabled
                    className="sm-input"
                  />
                </div>
              </div>
            </section>
          )}
        </div>
      ))}
    </div>
  );
}
ReturnPartStep1.propTypes = {
  disabled: PropTypes.bool.isRequired,
  partReturn: PropTypes.object,
  returnableParts: PropTypes.array,
  titleCom: PropTypes.object.isRequired,
  toggleReturnablePartCheck: PropTypes.func.isRequired,
};
