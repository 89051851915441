// eslint-disable-next-line max-classes-per-file
import React from 'react';
import onEnter from 'react-onenterkeydown';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import store from 'store2';
import { forwardToZendesk } from '@/com/user/zendeskUtil';
import { Icon } from '@/components';
import { login } from '../../../com/user';

const EnhancedInput = onEnter('input');

const color = {
  bg: '#f7fafc',
  bold: '#2E3B48',
  muted: '#9FA4A8',
  border: '#e3e8ee',
  blue: '#1A6BC6',
  green: '#40D78F',
  orange: '#FD9926',
  red: '#E90F09',
  darkGreen: '#24b47e',
  purple: '#8E69EC',
  lightblue: '#0DB1ED',
  pink: '#E84F89',
};

// eslint-disable-next-line react/prefer-stateless-function
class EditBtn extends React.Component {
  render() {
    return (
      <Tooltip title={this.props.tip}>
        <button
          onClick={this.props.onClick}
          className={`rounded-btn + ${this.props.className}`}
          aria-label={this.props.text}
        >
          <Icon
            name={this.props.icon ? this.props.icon : 'edit'}
            size={15}
            color={this.props.color ? this.props.color : color.muted}
          />
        </button>
      </Tooltip>
    );
  }
}

class Step2 extends React.Component {
  constructor(p) {
    super(p);
    this.state = { password: '' };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  onChange(e) {
    this.setState({ password: e.target.value });
  }

  async onSubmit() {
    if (!this.state.password) {
      return this.setState({ error: 'Password required' });
    }
    const { email, phone } = this.props.getStore();
    const { password } = this.state;
    const r = await login({ email, phone, password });
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    const returnTo = store.get('login.returnTo');
    if (returnTo) {
      void forwardToZendesk({
        token: r.token,
        returnTo,
        setError: value => this.setState({ error: value }),
      });
    } else {
      const redirect = _.replace(store.get('login.redirect') || 'workorders', /^\//, '');
      store.remove('login.redirect');
      this.props.dispatch(push(`/${redirect}`));
    }
  }

  goBack() {
    this.props.jumpToStep(0);
  }

  forgotPassword() {
    this.props.updateStore({ flow: 'forgot password' });
    this.props.jumpToStep(5);
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { email, phone } = this.props.getStore();
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 className="weight-500">Welcome Back</h3>
            <p className="text-muted">Please enter your password to log in.</p>
            <div className="login--form">
              <div className="login--form--icon mt-4">
                <EnhancedInput disabled value={email || phone} className="pass-input" />
              </div>
              <div className="login--form--icon">
                <EnhancedInput
                  onEnterKeyDown={this.onSubmit}
                  value={this.state.password}
                  onChange={this.onChange}
                  className="pass-input mt-2"
                  type={this.state.showPassword ? `text` : `password`}
                  placeholder="Enter password"
                  autoFocus
                />
                <div className="form-icon right">
                  {this.state.showPassword && (
                    <EditBtn
                      tip="Hide Password"
                      icon="visibility_off"
                      onClick={this.toggleShowPassword}
                    />
                  )}
                  {!this.state.showPassword && (
                    <EditBtn
                      tip="Show Password"
                      icon="visibility"
                      onClick={this.toggleShowPassword}
                    />
                  )}
                </div>
              </div>
            </div>
            <button onClick={this.onSubmit} className="primary mt-3">
              <b>Sign In</b>
            </button>
            {this.state.error && (
              <p className="fadeInAnim py-3" style={{ color: '#f75c43' }}>
                {this.state.error}
              </p>
            )}
            <p className="text-muted mt-3">
              or{' '}
              <span className="text-blue cursor-pointer" onClick={this.forgotPassword}>
                Forgot your Password?
              </span>
            </p>
            <p className="text-muted mt-4">
              <span className="text-blue cursor-pointer" onClick={this.goBack}>
                Back to Login
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default connect()(Step2);
