import React from 'react';
import type { Task } from '@nanaio/util';
import { T } from '@nanaio/util';
import _ from 'lodash';
import { Alert, Text } from '@/components';

type Props = {
  task: Task;
};

export default function PartsStatusMessage({ task }: Props): JSX.Element | null {
  const parts = task.parts?.itemsArray ?? [];

  const hasOrderedParts = parts.some(part => part.status === T.PartStatus.ORDERED);

  // Parts Ordered Message
  if (hasOrderedParts) {
    return (
      <Alert hideIcon variant="info" className="mb-4">
        <Text type="page-title" className="mb-2 flex">
          Ordering your Parts
        </Text>

        <Text type="body-2" color="grey.dark">
          We are ordering your parts. Most parts are delivered within 3-5 business days (subject to
          availability). Once shipped, you will receive an invitation to schedule the repair.
        </Text>
      </Alert>
    );
  }

  return null;
}
