import React from 'react';
import { T, U } from '@nanaio/util';
import { companyLogoAndName, companyName } from '@/config/const';
import { addEvent } from '../../com/marketing';
import Link from '../../com/ui/link';
import Loader from '../../com/ui/loader';
import { getParams } from '../../com/util';

// confirmation page after the cx chooses one of the pro-suggested followup times following cx no-show
export default class RescheduleConfirmation extends React.Component {
  constructor(p) {
    super(p);
    const { jobid } = getParams();
    this.state = {};
    addEvent('co');
    U.api('get', `tasks/${jobid}`).then(job => {
      const timezone = U.timezone(job.serviceAddress);
      this.setState({ job, timezone });
    });
  }

  render() {
    if (!this.state.job) {
      return <Loader />;
    }
    const lastVisit = T.lastVisit(this.state.job);
    return (
      <div>
        <div
          className="animated fadeIn py-lg-4 py-md-4 px-lg-5 px-md-5 container px-2 py-3"
          style={{ background: 'white', borderRadius: 10, maxWidth: 600 }}
        >
          <div className="d-flex justify-content-center mb-5">
            <Link to="/">
              <img src={companyLogoAndName} style={{ height: 32 }} alt={companyName} />
            </Link>
          </div>
          <div className="py-5">
            <h1
              className="hidden-md-down text-center"
              style={{
                fontFamily: 'Myriad',
                fontWeight: 500,
                color: '#1A4858',
                lineHeight: '1.25em',
              }}
            >
              Your response has been submitted!
            </h1>
            <h2
              className="hidden-md-up text-center"
              style={{
                fontFamily: 'Myriad',
                fontWeight: 500,
                color: '#1A4858',
                lineHeight: '1.25em',
              }}
            >
              Your response has been submitted!
            </h2>
            <h6
              className="text-center"
              style={{
                fontFamily: 'avenir',
                fontWeight: 300,
                color: '#878787',
                lineHeight: '1.5em',
              }}
            >
              Thank you! A confirmation has been sent to your email (
              {this.state.job.customer.user.email})
            </h6>

            <div className="mx-2 mt-5" style={{ border: '1px solid #e4e4e4', borderRadius: '5px' }}>
              <div className="px-3 py-4 text-center" style={{ borderBottom: '1px solid #e4e4e4' }}>
                <h4
                  style={{
                    fontFamily: 'avenir',
                    fontWeight: 300,
                    color: '#333',
                    lineHeight: '1.5em',
                    marginBottom: 0,
                    textAlign: 'center',
                  }}
                >
                  {U.timeslotToString({
                    format: 'ddd, MMM D, YYYY',
                    timeslot: lastVisit.preferredTimeSlot,
                    timezone: this.state.timezone,
                  })}
                </h4>
                <p
                  style={{
                    fontFamily: 'avenir',
                    fontWeight: 300,
                    color: '#878787',
                    marginBottom: 0,
                    textAlign: 'center',
                  }}
                >
                  Arrival:{' '}
                  {U.timeslotToString({
                    format: 'h:mma - h:mma',
                    timeslot: lastVisit.preferredTimeSlot,
                    timezone: this.state.timezone,
                  })}
                </p>
                <Link to={`/tasks/${this.state.job.id}`}>
                  <button
                    className="mt-2"
                    style={{
                      background: '#047298',
                      borderRadius: '4px',
                      padding: '8px 25px',
                      color: 'white',
                      fontFamily: 'avenir',
                      fontSize: '16px',
                      outline: 'none',
                      border: 'none',
                    }}
                  >
                    View booking
                  </button>
                </Link>
              </div>
              <div
                className="d-block d-lg-flex d-md-flex justify-content-between px-3 py-3"
                style={{ borderBottom: '1px solid #e4e4e4' }}
              >
                <p
                  style={{ fontFamily: 'avenir', fontWeight: 500, color: '#333', marginBottom: 0 }}
                >
                  Technician:
                </p>
                <p
                  className="text-lg-right text-md-right text-left"
                  style={{
                    flex: 1,
                    fontFamily: 'avenir',
                    fontWeight: 300,
                    color: '#878787',
                    marginBottom: 0,
                  }}
                >
                  {T.proFirstName(this.state.job)}
                </p>
              </div>
              <div
                className="d-block d-lg-flex d-md-flex justify-content-between px-3 py-3"
                style={{ borderBottom: '1px solid #e4e4e4' }}
              >
                <p
                  style={{ fontFamily: 'avenir', fontWeight: 500, color: '#333', marginBottom: 0 }}
                >
                  Service:
                </p>
                <p
                  className="text-lg-right text-md-right text-left"
                  style={{
                    flex: 1,
                    fontFamily: 'avenir',
                    fontWeight: 300,
                    color: '#878787',
                    marginBottom: 0,
                  }}
                >
                  {T.makeService(this.state.job)}
                </p>
              </div>
              <div className="d-block d-lg-flex d-md-flex justify-content-between px-3 py-3">
                <p
                  style={{ fontFamily: 'avenir', fontWeight: 500, color: '#333', marginBottom: 0 }}
                >
                  Service Address:
                </p>
                <p
                  className="text-lg-right text-md-right text-left"
                  style={{
                    flex: 1,
                    fontFamily: 'avenir',
                    fontWeight: 300,
                    color: '#878787',
                    marginBottom: 0,
                  }}
                >
                  {U.addressToString(this.state.job.serviceAddress)}
                </p>
              </div>
            </div>

            <img src="/img/servicepage/min/perks.jpg" width="100%" className="mt-5" alt="" />
          </div>
        </div>
      </div>
    );
  }
}
