import React, { useEffect, useRef,useState } from 'react';
import { useDeepCompareEffect } from '@nanaio/hooks';
import type { Brand, Org,Tool } from '@nanaio/util';
import _ from 'lodash';
import { Form, Input, Switch, Text } from '@/components';

export const getRequiredFieldValidationError = (value?: string | string[]): string => {
  let newValue = value;

  if (!Array.isArray(newValue)) {
    newValue = newValue?.trim();
  }

  return newValue?.length ? '' : 'This field is required';
};

type Props = {
  appliances: string[];
  brands: Brand[];
  keyAlreadySavedError: boolean;
  onChange: (form: Tool) => void;
  orgs: Org[];
  tool?: Tool;
};

const emptyTool: Tool = {
  id: '',
  toolRequired: false,
  name: '',
  key: '',
  description: '',
  appliances: [],
  brandIds: [],
  vendorIds: [],
  createTime: new Date(),
};

export default function ToolForm({
  appliances,
  brands,
  keyAlreadySavedError,
  onChange,
  orgs,
  tool = emptyTool,
}: Props): JSX.Element {
  const isReady = useRef(false);
  const [form, setForm] = useState<Tool>({ ...tool });
  const [errors, setErrors] = useState({ name: '', key: '', description: '', appliances: '' });

  useEffect(() => {
    if (isReady.current) {
      setForm(form => ({ ...form, key: _.camelCase(form.name) }));
    }
  }, [form.name]);

  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, name: getRequiredFieldValidationError(form.name) }));
    }
  }, [form.name]);

  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({ ...errors, key: getRequiredFieldValidationError(form.key) }));
    }
  }, [form.key]);

  useEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        description: getRequiredFieldValidationError(form.description),
      }));
    }
  }, [form.description]);

  useDeepCompareEffect(() => {
    if (isReady.current) {
      setErrors(errors => ({
        ...errors,
        appliances: getRequiredFieldValidationError(form.appliances),
      }));
    }
  }, [form.appliances]);

  useEffect(() => {
    if (!isReady.current) {
      isReady.current = true;
    }
  }, []);

  useEffect(() => {
    onChange(form);
  }, [onChange, form]);

  useEffect(() => {
    if (keyAlreadySavedError) {
      setErrors(errors => ({ ...errors, key: 'This key has already been saved' }));
    }
  }, [keyAlreadySavedError]);

  return (
    <Form onChange={setForm} value={form}>
      <div className="mb-4 flex items-center justify-between">
        <Text type="label">Tool Required?</Text>

        <div className="flex items-center">
          <Text className="mr-2">{form.toolRequired ? 'Yes' : 'No'}</Text>
          <Switch
            isActive={form.toolRequired}
            onClick={toolRequired => setForm({ ...form, toolRequired })}
          />
        </div>
      </div>

      <Input required error={errors.name} id="name" placeholder="Example: Step Ladder" />

      <Input required error={errors.key} id="key" placeholder="Example: stepLadder" />

      <Input
        error={errors.description}
        id="description"
        multiline
        placeholder="Example: A step ladder will help you reach stacked or mounted units"
        required
      />

      <Input
        error={errors.appliances}
        id="appliances"
        multiple
        options={appliances}
        required
        sort
      />

      <Input sort multiple options={brands} id="brandIds" label="Brands" />

      <Input sort multiple options={orgs} id="vendorIds" label="Vendors" />
    </Form>
  );
}
