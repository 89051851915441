import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';

export default ({ transactions }) => {
  let rows = _.map(transactions, t => {
    const out = {
      type: _.startCase(t.tranType),
      amount: U.toMoney(t.amount),
      checkNumber: _.get(t, 'metadata.checkNumber'),
      methodOfPayment: _.startCase(_.get(t, 'metadata.methodOfPayment')),
      proName: t.forUserName,
      reason: _.get(t, 'metadata.reason'),
      tranBy: t.byUserName,
      createTimeString: m(t.createTime).format('ddd, MMM DD, YYYY, h:mm a'),
      createTime: t.createTime,
      link:
        _.get(t, 'metadata.stripeResponse') &&
        `https://dashboard.stripe.com/${t.metadata.stripeResponse.destination}/payments/${t.metadata.stripeResponse.destination_payment}`,
    };
    return out;
  });
  rows = _.sortBy(rows, 'createTime');
  return (
    <div id="ledger" className="mx-3">
      <h5 className="text-muted">Ledger Info</h5>
      <div className="border" id="ledgerTable">
        <table className="mb-3 table">
          <tbody>
            <tr className="ledgerHead">
              {[
                'Stripe',
                'Type',
                'Amount',
                'Method',
                'Check Number',
                'Pro',
                'Tran By',
                'Time',
                'Reason',
              ].map(c => (
                <td key={c}>{c}</td>
              ))}
            </tr>
            {rows.map((r, i) => (
              <tr key={i} className="ledgerRow">
                <td>
                  {r.link && (
                    <a
                      href={r.link}
                      className="btn btn-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open
                    </a>
                  )}
                </td>
                <td>{r.type}</td>
                <td>{r.amount}</td>
                <td>{r.methodOfPayment}</td>
                <td>{r.checkNumber}</td>
                <td>{r.proName}</td>
                <td>{r.tranBy}</td>
                <td>{r.createTimeString}</td>
                <td>{r.reason}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
