import React, { MouseEvent, useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import classNames from 'classnames';
import { copyText } from '@/utils';
import { Icon, IconProps, Text } from '../../../core';

type Props = {
  cell: Table.Cell;
  icons?: IconProps['name'][];
  isActive: boolean;
  isCollapsed?: boolean;
  isHeader?: boolean;
  isTopLeft?: boolean;
  rowIndex: number;
  showPoints?: boolean;
};

export default function Cell({
  cell,
  icons = [],
  isActive,
  isCollapsed,
  isHeader,
  isTopLeft,
  rowIndex,
  showPoints,
}: Props): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  const cellClass = classNames(
    'border border-grey-medium group h-full overflow-x-auto overflow-y-hidden relative p-1',
    {
      'bg-primaryCTA': isActive,
      'bg-background-light': !isActive && rowIndex % 2 === 0,
      'cursor-pointer': !isTopLeft,
    }
  );
  const handleCopy = (event: MouseEvent) => {
    event.stopPropagation();
    copyText(cell.text);
    setIsCopied(true);
    setTimeoutId(
      setTimeout(() => {
        setIsCopied(false);
      }, 3000)
    );
  };
  const iconsClass = classNames('absolute bg-opacity-50 flex h-[30px] items-center right-0 top-0', {
    'bg-background-light': !isActive && rowIndex % 2 === 0,
    'bg-primaryCTA': isActive,
    'bg-white': !isActive && rowIndex % 2 === 1,
  });
  let iconColor = isCopied ? 'success' : 'primaryCTA';
  iconColor = isActive ? 'white' : iconColor;

  return (
    <div className="group relative h-full">
      <div className={cellClass}>
        <Text
          className={classNames({ 'font-semibold': isHeader })}
          color={isActive ? 'white' : undefined}
          noWrap
          title={isHeader}
        >
          {cell.text}
        </Text>
        {showPoints && cell.pointCount !== undefined && (
          <Text color={isActive ? 'white' : 'grey.dark'}>{cell.pointCount} points</Text>
        )}
      </div>
      <div className={iconsClass}>
        {cell.text !== undefined && (
          <Icon
            className={classNames({ 'hidden group-hover:block': !isCopied })}
            color={iconColor}
            name={isCopied ? 'check' : 'content_copy'}
            onClick={handleCopy}
          />
        )}
        {isCollapsed && <Icon name="close_fullscreen" />}
        {icons.map(icon => (
          <div className="h-[30px] overflow-hidden" key={icon}>
            <Icon className="-mx-1.5" name={icon} size={30} />
          </div>
        ))}
      </div>
    </div>
  );
}
