import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@/components';
import useBreakpoints, { Breakpoints } from '@/hooks/useBreakpoints';

function ApplicationModal({ isOpen, isSubmitting, onClose, onClick, buttonText, children }) {
  const [, breakpointSize] = useBreakpoints();

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={1200}>
      <Modal.Header />

      <Modal.Body className="bg-white">
        <div className="h-full items-start lg:flex lg:px-12 lg:py-6">
          <div className="apply-application-modal__image hidden flex-initial rounded-xl bg-cover bg-center lg:block" />

          {children}
        </div>
      </Modal.Body>

      {breakpointSize < Breakpoints.LG ? (
        <Modal.Footer hideCloseButton>
          <Button onClick={onClick} disabled={isSubmitting} className="w-full">
            {buttonText}
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}

ApplicationModal.propTypes = {
  buttonText: PropTypes.string,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApplicationModal;
