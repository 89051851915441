import React, { createElement, memo,ReactElement } from 'react';
import InputMask from 'react-input-mask';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import Address, { getProps as getAddressProps, types as addressTypes } from '../Address'; // eslint-disable-line import/no-cycle
import AuditedCheckboxes, { getProps as getCheckboxesProps } from '../AuditedCheckboxes';
import BoolInput, { getProps as getBoolProps } from '../BoolInput'; // eslint-disable-line import/no-cycle
import Check, { getProps as getCheckProps } from '../Check'; // eslint-disable-line import/no-cycle
import DateInput, { getProps as getDateProps } from '../DateInput'; // eslint-disable-line import/no-cycle
import Dropdown, { getProps as getDropdownProps } from '../Dropdown'; // eslint-disable-line import/no-cycle
import { isEmpty } from '../Form'; // eslint-disable-line import/no-cycle
import FormControl from '../FormControl'; // eslint-disable-line import/no-cycle
import RadioGroup, { getProps as getRadioProps } from '../RadioGroup'; // eslint-disable-line import/no-cycle
import SearchInput, { getProps as getSearchProps } from '../Search'; // eslint-disable-line import/no-cycle
import Switch, { getProps as getSwitchProps } from '../Switch'; // eslint-disable-line import/no-cycle
import TextInput, { // eslint-disable-line import/no-cycle
  getProps as getTextProps,
  isValid,
  Props as TextProps,
  types as textTypes,
} from '../TextInput';
import { Type } from '.'; // eslint-disable-line import/no-cycle

type Props = {
  className?: string;
  disabled?: boolean;
  error?: string;
  helper?: string;
  id?: string;
  inline?: boolean;
  inputRef?: (ref: HTMLInputElement | HTMLTextAreaElement | InputMask | null) => void;
  label?: string;
  labelLeftUI?: ReactElement;
  labelRightUI?: ReactElement;
  onChange: (value: unknown) => void;
  onRemove?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  options?: Option[] | Record<string, Option>;
  required?: boolean;
  style?: object;
  tooltip?: string | string[];
  type: Type;
  validate?: boolean;
  value?: unknown;
};

const UI = memo(
  ({
    className = '',
    disabled,
    error,
    helper,
    id,
    inline,
    inputRef,
    label,
    labelLeftUI,
    labelRightUI,
    onChange,
    onRemove,
    required,
    style,
    tooltip,
    type,
    validate,
    value,
    ...rest
  }: Props): ReactElement => {
    const props = {
      disabled,
      error,
      id,
      inline,
      inputRef,
      onChange,
      type: [...addressTypes, ...textTypes].some(t => t === type) ? type : undefined,
      value,
      ...rest,
    };

    let input;
    if (addressTypes.some(t => t === type)) {
      input = createElement(Address, getAddressProps(props));
    } else if (type === Type.BOOL) {
      input = createElement(BoolInput, getBoolProps(props));
    } else if (type === Type.CHECK) {
      input = createElement(Check, getCheckProps(props));
    } else if (type === Type.AUDITED_CHECKBOXES) {
      input = createElement(AuditedCheckboxes, getCheckboxesProps(props));
    } else if (type === Type.DATE) {
      input = createElement(DateInput, getDateProps(props));
    } else if (type === Type.DROPDOWN) {
      input = createElement(Dropdown, getDropdownProps(props));
    } else if (type === Type.RADIO) {
      input = createElement(RadioGroup, getRadioProps(props));
    } else if (type === Type.SEARCH || rest.options) {
      input = createElement(SearchInput, getSearchProps(props));
    } else if (type === Type.SWITCH) {
      input = createElement(Switch, getSwitchProps(props));
    } else if (textTypes.includes(type)) {
      input = createElement<TextProps>(TextInput, getTextProps(props));
    }

    const newLabel =
      id && label === undefined ? _.startCase(_.replace(id, 'metadata.', '')) : label;

    let validationError;
    if (validate && required && isEmpty({ type, value })) {
      validationError = 'Required';
    } else if (
      validate &&
      type in isValid &&
      !isValid[type as keyof typeof isValid](value as string)
    ) {
      validationError = 'Invalid format';
    } else {
      validationError = error;
    }

    return (
      <FormControl
        {...{
          className,
          disabled,
          error: validationError,
          helper,
          inline,
          label: newLabel,
          labelLeftUI,
          labelRightUI,
          onLabelClick: !disabled && type === Type.CHECK ? () => onChange(!value) : undefined,
          onRemove,
          required,
          style,
          tooltip,
          type,
        }}
      >
        {nullthrows(input)}
      </FormControl>
    );
  }
);

export default UI;
