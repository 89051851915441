import React, { ReactNode, useContext } from 'react';
import U from '@nanaio/util';
import classNames from 'classnames';
import Icon from '@/components/core/Icon';
import Text from '@/components/core/Text';

export type ListItemProps = {
  title?: string;
  description: ReactNode;
  link?: { title: string; url: string };
  timeEstimate?: string;
};

type ListProps = {
  items: ListItemProps[];
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  variant?: 'welcome' | 'steps';
};

const ListItemContext = React.createContext({ number: 1, variant: 'welcome' });

export default function List({
  items,
  header,
  footer,
  className = '',
  variant = 'welcome',
}: ListProps): JSX.Element {
  return (
    <div className={className}>
      {U.isText(header) ? (
        <Text
          className={classNames('text-secondary', {
            'mb-6': variant === 'welcome',
            'mb-4': variant === 'steps',
          })}
          type="button"
        >
          {header}
        </Text>
      ) : (
        header
      )}
      <div
        className={classNames({
          'space-y-11': variant === 'welcome',
          'space-y-4': variant === 'steps',
        })}
      >
        {items.map((item, index) => (
          <ListItemContext.Provider key={index} value={{ number: index + 1, variant: variant }}>
            <ListItem {...item} />
          </ListItemContext.Provider>
        ))}
      </div>
      {U.isText(footer) ? (
        <Text className="mt-6 text-grey-dark" type="helper">
          {footer}
        </Text>
      ) : (
        footer
      )}
    </div>
  );
}

function ListItem({ title, description, link, timeEstimate }: ListItemProps): JSX.Element {
  const itemContext = useContext(ListItemContext);
  return (
    <div className="flex">
      <div className="mr-4 flex h-8 w-8 rounded-2xl bg-primaryCTA bg-opacity-10">
        <Text className="w-8 place-self-center text-center text-primaryCTA" type="page-title">
          {itemContext.number}
        </Text>
      </div>
      <div className="flex flex-col">
        <Text className="mb-1 text-secondary" type="button">
          {title}
        </Text>
        {U.isText(description) ? (
          <Text
            className={classNames({
              'text-grey-dark': itemContext.variant === 'welcome',
              'text-font-dark': itemContext.variant === 'steps',
            })}
          >
            {description}
          </Text>
        ) : (
          description
        )}
        {link && (
          <a className="mt-2 flex" href={link.url} target="_blank" rel="noreferrer">
            <Icon
              name="open_in_new"
              className="flex-none place-self-start text-primaryCTA"
              size={14}
            />
            <Text className="ml-2 place-self-center text-primaryCTA" type="label">
              {link.title}
            </Text>{' '}
          </a>
        )}
        {timeEstimate && (
          <div className="mt-4 flex">
            <Icon
              className="flex-none place-self-start text-primaryCTA"
              name="schedule"
              size={14}
            />
            <Text className="ml-2 place-self-center font-semibold text-secondary" type="helper">
              {timeEstimate}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
