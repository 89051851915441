import React from 'react';
import { connect } from 'react-redux';
import { T, U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { companyName } from '@/config/const';
import { updateTask } from '../../../../com/task';
import { Bool, Modal, Search, Select, Text,Textbox } from '../../../../com/ui/form';
import { loadProOptions } from '../../../../com/util';

class SendMessage extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  constructor(p) {
    super(p);
    this.state = { recipient: _.get(p, 'workOrder.cx.id') };
  }

  getChildContext = () => ({ t: this });

  onOpen = async () => {
    const { job } = this.props;
    const user = await U.api('get', `users/${job.customer.user.id}`);
    const name = _.get(user, 'profile.fullName');
    const options = [
      { name, type: 'phone', selected: true, value: U.trimPhone(_.get(user, 'profile.phone')) },
      { name, type: 'altPhone', value: U.trimPhone(_.get(user, 'profile.altPhone')) },
      { name, type: 'email', selected: true, value: user.email },
      { name, type: 'altEmail', value: _.get(user, 'profile.altEmail') },
      { name, type: 'other', value: '' },
    ].filter(o => o.type === 'other' || o.value);
    this.setState({
      options,
      message: undefined,
      modal: true, // eslint-disable-line react/no-unused-state
      sendNow: false,
      shareWithPro: false,
      subject: `${companyName} here, keeping you informed about your repair`,
    });
  };

  componentWillUnmount() {
    const { timeoutId } = this.state;
    clearTimeout(timeoutId);
  }

  onRecipientChange = id => {
    const { pros } = this.state;
    if (!pros && id === 'other') {
      loadProOptions().then(proOptions => this.setState({ proOptions }));
    }
  };

  onSend = async () => {
    const { job, updateEmails, updateSms, workOrder } = this.props;
    const { message, options, pro, recipient, sendNow, shareWithPro, subject } = this.state;
    if (recipient !== workOrder.cx.id) {
      if (!message) {
        return this.setState({ error: 'Message required' });
      }
      const proId = recipient === 'other' ? pro : recipient;
      if (!proId) {
        return this.setState({ error: 'Pro required' });
      }
      if (updateSms) {
        U.redux.unset(`conversations.${workOrder.id}`);
        updateSms();
      }
      return this.setState({ error: 'Pro messaging not currently supported' });
    } else {
      if (!options.find(o => o.selected)) {
        return this.setState({ error: 'Check one or more recipients' });
      }
      let sentSms;
      let sentEmail;
      if (options.find(o => _.includes(o.value, '@')) && !subject) {
        return this.setState({ error: 'Email subject required' });
      }
      await Promise.all(
        options
          .filter(option => option.selected)
          .map(option => {
            option.value = _.trim(option.value);
            const request = { job: job.id, sendNow, type: 'custom' };
            if (_.includes(option.value, '@')) {
              sentEmail = true;
              request.email = {
                body: message,
                subject,
                to: option.value,
              };
            } else {
              sentSms = true;
              request.sms = { body: message, to: _.replace(option.value, /\D/g, '') };
            }
            return U.api('post', 'messages', request);
          })
      );
      const changes = [
        {
          action: 'add',
          path: 'notes',
          value: { content: message, shareWithPro, type: 'message' },
        },
      ];
      await updateTask(job.id, changes);
      if (sentSms && updateSms) {
        updateSms();
      }
      if (sentEmail && updateEmails) {
        updateEmails();
      }
    }
    this.setState({ modal: false }); // eslint-disable-line react/no-unused-state
  };

  toggleOption = index => {
    const { options } = this.state;
    const newOptions = options.map((o, n) => {
      if (n === index) {
        o.selected = !o.selected;
      }
      return o;
    });
    this.setState({ options: newOptions });
  };

  render() {
    const { job, options: propOptions, workOrder } = this.props;
    const { error, options, proOptions, recipient } = this.state;
    const isCx = recipient === workOrder.cx.id;
    return (
      <div>
        <div className="btn btn-primary mr-3" onClick={this.onOpen}>
          Send Message
        </div>
        <Modal saveText="Send" title="Send Message" submit={this.onSend}>
          <div className="text-danger">{error}</div>
          <Select id="recipient" options={propOptions} onChange={this.onRecipientChange} />
          {recipient === 'other' && <Search id="pro" options={proOptions} />}
          {isCx && (
            <table className="table-sm table-striped table-hover table">
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Type</th>
                  <th>Recipient</th>
                </tr>
              </thead>
              <tbody>
                {_.map(options, (o, n) => (
                  <tr key={n}>
                    <td>
                      {o.selected ? (
                        <Icon
                          className="cursor-pointer"
                          name="check_box_outline"
                          onClick={() => this.toggleOption(n)}
                          size={16}
                        />
                      ) : (
                        <Icon
                          className="cursor-pointer"
                          name="check_box_outline_blank"
                          onClick={() => this.toggleOption(n)}
                          size={16}
                        />
                      )}
                    </td>
                    <td>{o.name}</td>
                    <td>{_.startCase(o.type)}</td>
                    <td>
                      {o.type === 'other' ? (
                        <Text label="" id={`options.${n}.value`} hint="User Id, Phone, Email" />
                      ) : (
                        o.value
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <Textbox multiline id="message" hint="Write a message" className="" />
          {isCx && <Text id="subject" label="Subject (email only)" />}
          {isCx && <Bool id="shareWithPro" label="Share Note With Pro" />}
          {isCx && T.outsideBusinessHours(job) && <Bool id="sendNow" />}
        </Modal>
      </div>
    );
  }
}

SendMessage.propTypes = {
  job: T.propType.isRequired,
  options: PropTypes.arrayOf(U.optionPropType).isRequired,
  updateSms: PropTypes.func,
  updateEmails: PropTypes.func,
  workOrder: W.propType.isRequired,
};

export default connect(s => {
  const job = s.tasks[global.id()];
  const workOrder = s.workorders[job.metadata.workOrderId];
  const options = [workOrder.cx, { id: 'other', name: 'Other' }];
  return { job, options, workOrder };
})(SendMessage);
