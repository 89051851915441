import React, { useState } from 'react';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, RadioGroup, SearchInput, Switch, Text, TextInput } from '@/components';

const options = [
  {
    name: 'Fulfillment',
    options: [
      'Brand not Provided',
      'Duration',
      'Not Covered Under Warranty',
      'Out of Service Area',
      'Rejected Job',
      'Service not Provided',
    ],
  },
  {
    name: 'No Service Needed',
    options: [
      'Chose a Different Company',
      'DIY Repair',
      'In Warranty, Sent to OEM',
      'Issue is Resolved',
      'Replacing Unit',
      'Resolved Issue over Phone',
    ],
  },
  { name: 'Parts', options: ['Parts no Longer Available'] },
  { name: 'Pricing', options: ['Beyond Economical Repair', 'Pricing is too Expensive'] },
  { name: 'Scheduling', options: ['Customer Unsatisfied with Scheduling'] },
  {
    name: 'Service Issues',
    options: [
      'Customer Cancelled On-Site',
      'Customer Damaged Unit',
      'Customer Refuses Service',
      'Poor Customer Behavior',
      'Repeat Repair',
      'Service Quality',
      'Technician Damaged Unit',
    ],
  },
  {
    name: 'System',
    options: [
      'Billing Work Order',
      'Cancelled by Accident by Liaison',
      'Created as a Test',
      'Created by Accident',
      'Duplicate',
      'No Record in Vendor Portal',
      'Vendor Auto Cancelled',
    ],
  },
];

export default function Cancel({ onClose, taskId }) {
  const [cancelledBy, setCancelledBy] = useState();
  const [cancelInVendor, setCancelInVendor] = useState(false);
  const [error, setError] = useState();
  const [notify, setNotify] = useState('both');
  const [reason, setReason] = useState();
  const [showVendorCancelExplanation, setShowVendorCancelExplanation] = useState(false);
  const [vendorReason, setVendorReason] = useState();
  const [vendorReasonDetails, setVendorReasonDetails] = useState();
  const [vendorReasons, setVendorReasons] = useState([]);

  const handleCancelInVendor = async () => {
    setCancelInVendor(!cancelInVendor);
    if (!vendorReasons.length) {
      const response = await U.api('get', `tasks/${taskId}/cancelReasons`);
      setShowVendorCancelExplanation(response?.explanationField);
      setVendorReasons(
        _.map(response?.reasons, reason => ({ id: reason.value, name: reason.name }))
      );
    }
  };

  const handleSave = async () => {
    if (!reason) {
      return setError('Select a cancellation reason');
    }
    const response = await U.api(
      'put',
      `tasks/${taskId}?notify=${notify}`,
      [{ path: 'status', value: { status: T.Status.CANCELLED, reason, cancelledBy } }],
      ['save']
    );
    if (response.errMsg) {
      return setError(response.errMsg);
    }
    if (cancelInVendor) {
      if (!vendorReason) {
        return setError('Select a vendor cancel reason');
      }
      const response = await U.api('post', `tasks/${taskId}/cancelInPortal`, {
        explanation: vendorReasonDetails,
        value: vendorReason,
      });
      if (response.errMsg) {
        return setError(
          'There was an error while trying to cancel the job on portal, please update it manually there.'
        );
      }
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Cancel Task" />
      <Modal.Body className="p-4">
        <Text className="text-center" color="grey.dark">
          Are you sure you want to cancel this request?
        </Text>
        <Text className="mt-4" type="label">
          Reason for Cancellation*
        </Text>
        <SearchInput grouped onChange={setReason} options={options} value={reason} />
        <Text className="mt-4" type="label">
          Notify
        </Text>
        <SearchInput
          capitalize
          onChange={setNotify}
          options={['both', 'customer', 'none', 'pro']}
          value={notify}
        />
        <Text className="mt-4" type="label">
          Cancelled By*
        </Text>
        <RadioGroup
          options={[
            { text: 'Customer', value: 'customer' },
            { text: 'Pro', value: 'pro' },
            { text: 'Nana', value: 'nana' },
            { text: 'Vendor', value: 'vendor' },
          ]}
          value={cancelledBy}
          onClick={setCancelledBy}
        />
        <div className="mt-4 flex-row">
          <Text className="mr-4" type="label">
            Cancel in Vendor
          </Text>
          <Switch isActive={cancelInVendor} onClick={handleCancelInVendor} />
        </div>
        {cancelInVendor && (
          <>
            <Text className="mt-4" type="label">
              Vendor Cancel Reason
            </Text>
            <SearchInput onChange={setVendorReason} options={vendorReasons} value={vendorReason} />
          </>
        )}
        {cancelInVendor && showVendorCancelExplanation && (
          <>
            <Text className="mt-4" type="label">
              Vendor Cancel Explanation
            </Text>
            <TextInput multiline onChange={setVendorReasonDetails} value={vendorReasonDetails} />
          </>
        )}
        <Text color="danger">{error}</Text>
      </Modal.Body>
      <Modal.Footer onSave={handleSave} />
    </Modal>
  );
}

Cancel.propTypes = {
  onClose: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};
