import React from 'react';
import { U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PT from 'prop-types';
import { Icon } from '@/components';
import { Money, Select } from '../../com/ui/form';

export default function ReturnPartStep4({
  changeReturnStatus,
  originalReturnCredit,
  partReturn,
  partsInShipment,
  pro,
  resendEmail,
  returnCredit,
  saveReturnCredit,
}) {
  const subtotal = _.sum(partsInShipment.map(part => part.returnQuantity * part.pricePerUnit));
  return (
    <div id="AdminForm" className="container">
      <div className="success d-flex mb-3 p-3">
        <span className="success-icon d-flex align-items-center justify-content-center mr-3">
          <Icon name="check" color="#fff" size={16} />
        </span>
        An email containing return label and instructions has been sent to
        <a className="text-blue ml-2" href={`mailto:${pro?.user?.email}`}>
          {pro?.user?.email}
        </a>
        .
      </div>

      <div className="d-flex justify-content-end mb-5">
        <a
          href={partReturn.labelDownloadPdf}
          className="button secondary mr-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Label
        </a>
        <button className="primary" onClick={resendEmail}>
          Resend Email
        </button>
      </div>

      <div className="status mb-5">
        <h1 className="mb-3 text-center">Return Confirmed</h1>

        <p className=" text-muted text-center">
          Shipping label has been created. The status will be updated once the return is shipped.
        </p>
      </div>

      {!_.isEqual(returnCredit, originalReturnCredit) && (
        <div className="text-center">
          <button className="primary mb-3" onClick={saveReturnCredit}>
            Save Return Credit
          </button>
        </div>
      )}
      <div className="confirmation-summary text-muted p-3">
        <div className="mb-5">
          <h2 className="text-dark mb-4 pb-3">Return Summary</h2>
          <div className="row mb-4">
            <div className="col pr-4">
              <table className="full-width">
                <tbody>
                  <tr>
                    <td>RMA #:</td>
                    <td className="text-dark text-right">{partReturn.referenceNumber}</td>
                  </tr>
                  <tr>
                    <td>External RMA #:</td>
                    <td className="text-dark text-right">
                      {partReturn.externalReferenceNumber || '--'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col pl-4">
              <table className="full-width">
                <tbody>
                  <tr>
                    <td>Item Subtotal ({partsInShipment.length}):</td>
                    <td className="text-dark text-right">{U.toMoney(subtotal)}</td>
                  </tr>
                  <tr>
                    <td>Return Approved On:</td>
                    <td className="text-dark text-right">
                      {m(partReturn.approvedDate).format('ddd, MMM D, YYYY')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table className="full-width">
            <thead>
              <tr>
                <th className="pb-3">
                  <strong className="text-dark">Product</strong>
                </th>
                <th className="pb-3">
                  <strong className="text-dark">Return ID</strong>
                </th>
                <th className="pb-3">
                  <strong className="text-dark">Return Qty</strong>
                </th>
                <th className="pb-3">
                  <strong className="text-dark">Return Value</strong>
                </th>
                <th className="pb-3">
                  <strong className="text-dark">Credit Status</strong>
                </th>
                <th className="pb-3">
                  <strong className="text-dark">Credit Amount</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {partsInShipment.map((part, i) => (
                <tr key={i}>
                  <td>
                    <span className="text-dark">{part.desc}</span>
                    <br />
                    {part.partNumber}
                  </td>
                  <td>{part.returnShortId}</td>
                  <td>
                    {part.returnQuantity} of {part?.unit?.value}
                  </td>
                  <td>{U.toMoney(part.returnQuantity * part.pricePerUnit)}</td>
                  <td>
                    <Select
                      label=""
                      id={`returnCredit.${i}.status`}
                      options={['credited', 'rejected', 'unknown']}
                      cap
                      onChange={status => changeReturnStatus(i, status)}
                      className="mr-3"
                    />
                  </td>
                  <td>
                    {_.get(returnCredit, `${i}.status`) === 'credited' && (
                      <Money label="" id={`returnCredit.${i}.amount`} style={{ width: '150px' }} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row mb-5">
          <div className="col pr-4">
            <h2 className="text-dark mb-4 pb-3">Shipping Details</h2>
            <p className="mb-3">
              <strong className="text-dark">Ship From:</strong>
            </p>
            <p className="mb-4">
              {partReturn.shipFrom.name}
              <br />
              {U.addressLine12(partReturn.shipFrom.address)}
              <br />
              {U.addressLine3(partReturn.shipFrom.address)}
              <br />
              {U.trimPhone(partReturn.shipFrom.phone, true)}
            </p>

            <p className="mb-3">
              <strong className="text-dark">Ship To:</strong>
            </p>
            <p>
              {partReturn.shipTo.name}
              <br />
              {U.addressLine12(partReturn.shipTo.address)}
              <br />
              {U.addressLine3(partReturn.shipTo.address)}
              <br />
              {U.trimPhone(partReturn.shipTo.phone, true)}
            </p>
          </div>
          <div className="col pl-4">
            <h2 className="text-dark mb-4 pb-3">Delivery Method</h2>
            <p className="mb-3">
              <strong className="text-dark">{partReturn?.track?.carrier}</strong>
            </p>
            <table className="full-width">
              <tbody>
                <tr>
                  <td>Tracking No:</td>
                  <td className="text-dark text-right">
                    <a
                      href={partReturn?.track?.url}
                      className="link-blue ml-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {partReturn?.track?.id}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Dimensions (W x L x H):</td>
                  <td className="text-dark text-right">
                    {partReturn?.width?.value}” x {partReturn?.length?.value}” x{' '}
                    {partReturn?.height?.value}”
                  </td>
                </tr>
                <tr>
                  <td>Weight:</td>
                  <td className="text-dark text-right">{partReturn?.weight?.value} lbs</td>
                </tr>
                <tr>
                  <td>Shipping Cost:</td>
                  <td className="text-dark text-right">
                    {U.toMoney(partReturn.returnShippingCost)}
                  </td>
                </tr>
                <tr>
                  <td>Expected to Ship:</td>
                  <td className="text-dark text-right">
                    {m(partReturn?.track?.expectedToShip).format('ddd, MMM 1, YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
ReturnPartStep4.propTypes = {
  partReturn: PT.object.isRequired,
  partsInShipment: PT.array.isRequired,
  pro: PT.object.isRequired,
  resendEmail: PT.func.isRequired,
};
