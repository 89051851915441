import React, { useEffect, useMemo,useState } from 'react';
import { Org, ServerEntity,Tool, U } from '@nanaio/util';
import _ from 'lodash';
import { APIError, APIErrorDefaultMessage,Button, Loader, Modal } from '@/components';
import { useLazyLegacyAPI, useLegacyAPI, useLegacySelector } from '@/hooks';
import ToolForm, { getRequiredFieldValidationError } from './com/ToolForm';

type Props = {
  id?: string;
  onRemove?: () => void;
  onSuccess: (tool: ServerEntity & Tool) => void;
  toggleOpen: () => void;
};

export default function ToolModal({ id, onRemove, onSuccess, toggleOpen }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTool, setCurrentTool] = useState<Tool | null>(null);
  const [saveError, setSaveError] = useState({ keyTaken: false, unexpected: false });

  const [showRemoveWarning, setShowRemoveWarning] = useState(false);

  const { appliances, brands } = useLegacySelector(state => {
    const appliances = Object.values(state.services).reduce((acc, service) => {
      if (service.ancestorTaxonomies.includes('Appliance')) {
        const name = service.name.replace(/( Repair)|( Maintenance)|( Install)/g, '');

        if (!acc.includes(name)) {
          acc.push(name);
        }
      }

      return acc;
    }, [] as string[]);
    const { brands } = state;
    const brandsArray = Object.values(brands);

    return { appliances, brands: brandsArray };
  });

  const orgs = useLegacyAPI<Org[]>('orgs/search', {
    body: { limit: -1 },
    method: 'post',
  });

  const [loadToolToEdit, toolToEdit] = useLazyLegacyAPI<Tool>(`/tools/${id as string}`, {
    method: 'get',
  });

  const [removeTool, toolToRemove] = useLazyLegacyAPI<Tool>(`/tools/${id as string}`, {
    method: 'delete',
  });

  const disableSaveButton = useMemo(() => {
    let disable = isSaving;

    if (!disable) {
      const toolRequiredFields = _.pick(currentTool, [
        'name',
        'key',
        'description',
        'appliances',
      ]) as Pick<Tool, 'name' | 'key' | 'description' | 'appliances'>;

      disable = Object.values(toolRequiredFields).some(field =>
        getRequiredFieldValidationError(field)
      );
    }

    return disable;
  }, [isSaving, currentTool]);

  const hasError = orgs.error || toolToEdit.error || toolToRemove.error || saveError.unexpected;
  let showForm = !hasError && !orgs.loading && !toolToEdit.loading && !isLoading;

  if (id) {
    showForm = Boolean(showForm && toolToEdit.data);
  }

  const handleFormChange = (form: Tool) => setCurrentTool(form);

  const handleSave = () => {
    setSaveError({ keyTaken: false, unexpected: false });
    setIsSaving(true);

    let promise;

    if (currentTool?.id) {
      promise = U.api<Tool>('put', `/tools/${currentTool.id}`, currentTool);
    } else {
      promise = U.api<Tool>('post', '/tools', currentTool);
    }

    void promise
      .then(response => {
        if ('errMsg' in response) {
          if (response.errMsg?.includes('This key has already been saved')) {
            setSaveError({ ...saveError, keyTaken: true });
          } else {
            setSaveError({ ...saveError, unexpected: true });
          }
        } else {
          onSuccess(response as ServerEntity & Tool);
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleRemove = async () => {
    if (id) {
      if (showRemoveWarning) {
        await removeTool();
      } else {
        setShowRemoveWarning(true);
      }
    }
  };

  useEffect(() => {
    if (id) {
      void loadToolToEdit();
    }
  }, [id, loadToolToEdit]);

  useEffect(() => {
    if (!orgs.loading && !toolToEdit.loading) {
      setIsLoading(false);
    }
  }, [orgs.loading, toolToEdit.loading]);

  useEffect(() => {
    if (toolToRemove.data && onRemove) {
      onRemove();
    }
  }, [toolToRemove.data, onRemove]);

  return (
    <Modal isOpen onClose={toggleOpen}>
      <Modal.Header title={`${id ? 'Edit' : 'Add'} Tool`} />

      <Modal.Body className="p-4">
        <Loader className="text-center" isLoading={isLoading} />

        {hasError && <APIError error={APIErrorDefaultMessage} />}

        {showForm && (
          <ToolForm
            appliances={appliances}
            brands={brands}
            keyAlreadySavedError={saveError.keyTaken}
            onChange={handleFormChange}
            orgs={orgs.data || []}
            tool={toolToEdit.data}
          />
        )}
      </Modal.Body>

      {showForm && (
        <Modal.Footer customUI>
          {id && (
            <Button
              disabled={toolToRemove.loading}
              onClick={handleRemove}
              size="small"
              variant="secondary"
            >
              {showRemoveWarning ? 'Are you sure?' : 'Remove'}
            </Button>
          )}

          <Button
            className="ml-auto"
            disabled={disableSaveButton}
            onClick={handleSave}
            size="small"
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
