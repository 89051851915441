import React from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Button, Modal } from '../../core';
import { Input, InputType } from '../../form';

type Props = {
  columnIndex: number;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  isCollapsed?: boolean;
  onCollapse: (props: { columnIndex: number; rowIndex: number }) => void;
  onClose: () => void;
  onSort: (props: { sort: Table.Sort; vectorId: string }) => void;
  query: Table.Query;
  rowIndex: number;
  tableVector: Table.TableVector;
};

export default function TableVectorSettings({
  columnIndex,
  databaseIdToTableIdToColumnIdToColumn,
  isCollapsed,
  onCollapse,
  onClose,
  onSort,
  query,
  rowIndex,
  tableVector,
}: Props): JSX.Element {
  const idToVector = _.keyBy(query.analytics.vectors, 'id');
  const vector = query.analytics.vectors.find(vector =>
    tableVector.vectors.some(tableVectorVector => tableVectorVector.id === vector.id)
  );
  const dimension =
    vector && Table.vectorIsDimension({ databaseIdToTableIdToColumnIdToColumn, query, vector })
      ? vector
      : undefined;
  let dimensionLabel;
  let dimensionOptions;
  let dimensionValue;
  if (dimension) {
    const name = Table.getVectorName({
      databaseIdToTableIdToColumnIdToColumn,
      idToVector,
      query,
      vector: dimension,
      vectors: query.analytics.vectors,
    });
    dimensionLabel = `Sort ${name} ${dimension.direction}s by ${name} ${dimension.direction}`;
    const type =
      vector?.moduleId &&
      databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][vector?.moduleId]
        ?.type === Table.ColumnType.TIME
        ? Table.SortType.TIME
        : Table.SortType.ALPHABETICAL;
    dimensionOptions = [
      { isDescending: false, name: `${_.startCase(type)} (ascending)`, type },
      { isDescending: true, name: `${_.startCase(type)} (descending)`, type },
    ];
    dimensionValue = dimension?.sort?.tableVector?.some(
      tableVector => tableVector.id === dimension.id
    )
      ? dimension.sort
      : undefined;
  }
  const oppositeDimensions = query.analytics.vectors.filter(
    v =>
      v.direction !== vector?.direction &&
      Table.vectorIsDimension({ databaseIdToTableIdToColumnIdToColumn, query, vector: v })
  );
  const vectorName = String(
    _.findLast(tableVector.vectors, vector => vector.value !== undefined)?.text ||
      tableVector.vectors[0].text
  );

  const handleSort = ({ optionId, vectorId }: { optionId: string; vectorId: string }) => {
    const { isDescending, type } = nullthrows(JSON.parse(optionId) as Table.Sort);
    const sort = {
      isDescending,
      tableVector: tableVector.vectors.map(tableVectorVector =>
        U.trimUndefined({
          id: tableVectorVector.id,
          value: tableVectorVector.value,
        })
      ),
      type,
    };
    onSort({ sort, vectorId });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Header Settings" />
      <Modal.Body className="p-4">
        {dimension && dimensionOptions && (
          <Input
            isMatch={({ option, value }) =>
              _.isEqual(_.pick(option, ['isDescending', 'type']), value)
            }
            label={dimensionLabel}
            onChange={option => handleSort({ optionId: option as string, vectorId: dimension.id })}
            options={dimensionOptions}
            type={InputType.SEARCH}
            value={_.pick(dimensionValue, ['isDescending', 'type'])}
          />
        )}
        {oppositeDimensions.map(dimension => {
          let label = `Sort ${Table.getVectorName({
            databaseIdToTableIdToColumnIdToColumn,
            idToVector,
            query,
            vector: dimension,
            vectors: query.analytics.vectors,
          })} ${dimension.direction}s`;
          if (vector) {
            label += ` by ${vectorName} ${vector.direction}`;
          }
          const value = dimension?.sort?.tableVector?.some(
            tableVector => tableVector.id === vector?.id
          )
            ? dimension.sort
            : undefined;
          const options = _.flatten(
            [Table.SortType.POINTS, Table.SortType.VALUE].sort().map(type => {
              const sortName =
                type === Table.SortType.POINTS ? 'Data Point Count' : _.startCase(type);
              return [
                { isDescending: false, name: `${sortName} (ascending)`, type },
                { isDescending: true, name: `${sortName} (descending)`, type },
              ];
            })
          );

          return (
            <Input
              isMatch={({ option, value }) =>
                _.isEqual(_.pick(option, ['isDescending', 'type']), value)
              }
              key={dimension.id}
              label={label}
              onChange={option =>
                handleSort({ optionId: option as string, vectorId: dimension.id })
              }
              options={options}
              type={InputType.SEARCH}
              value={_.pick(value, ['isDescending', 'type'])}
            />
          );
        })}
        {vector &&
          Table.vectorIsDimension({ databaseIdToTableIdToColumnIdToColumn, query, vector }) && (
            <Button
              onClick={() => onCollapse({ columnIndex, rowIndex })}
              size="medium"
              variant={isCollapsed ? 'primary' : 'secondary'}
            >
              {isCollapsed ? 'Expand' : 'Collapse'} {vectorName} {vector.direction}s
            </Button>
          )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
