import React, { useEffect } from 'react';
import { Pro, U } from '@nanaio/util';
import CxNav from '@/com/ui/CxNav';
import ProNav from '@/com/ui/ProNav';
import { useLegacySelector } from '@/hooks';

type Props = {
  children: React.ReactNode;
};

export default function CxAndProLayout({ children }: Props): JSX.Element {
  const me = useLegacySelector(state => state.me);

  const pros = useLegacySelector(state => state.pros);
  const proId = me?.proId;
  const pro = proId ? pros[proId] : undefined;

  useEffect(() => {
    if (me.proId && !pro) {
      void U.api<Pro>('get', `/serviceproviders/${me.proId}`, ['save']);
    }
  }, [me.proId, pro]);

  return (
    <div id="layout-up">
      {pro ? <ProNav pro={pro} /> : <CxNav />}
      <div style={{ height: '60px' }} />
      <section id="Admin">{children}</section>
    </div>
  );
}
