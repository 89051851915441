import React, { useMemo, useState } from 'react';
import { Task } from '@nanaio/util';
import type { BoostReason } from '@nanaio/util/dist/task';
import _ from 'lodash';
import { Confirm, Form, Modal } from '@/components';
import type { DraftBoost } from './BoostJob';
import BoostJob from './BoostJob';
import ConfirmRemove from './ConfirmRemove';
import TargetAudience from './TargetAudience';

type ConfirmType = 'cancel' | 'remove' | undefined;
type Props = {
  boost: Task['boost'];
  onClose: () => void;
  onSave: (draftBoost: DraftBoost) => void;
};

export default function BoostJobModal({ boost, onClose, onSave }: Props): JSX.Element {
  const initialBoost = useMemo<DraftBoost>(
    () => ({
      notify: boost?.notify || false,
      pros: boost?.pros?.map(pro => ({ id: pro.id })),
      reason: boost?.reason as BoostReason,
    }),
    [boost]
  );

  const [draftBoost, setDraftBoost] = useState<DraftBoost>(initialBoost);
  const [showTargetAudienceSelector, setShowTargetAudienceSelector] = useState(false);
  const [showConfirm, setShowConfirm] = useState<ConfirmType>();

  const handleHideConfirm = () => {
    setShowConfirm(undefined);
  };

  const handleSaveTargetAudience = (draftAudience: string[]) => {
    setDraftBoost({
      ...draftBoost,
      pros: draftAudience.length ? draftAudience.map(proId => ({ id: proId })) : undefined,
    });
    setShowTargetAudienceSelector(false);
  };

  const handleRemove = () => {
    onSave({});
    onClose();
  };

  const handleSave = () => {
    onSave(draftBoost);
    onClose();
  };

  const handleSelectReason = (reason: BoostReason) => {
    setDraftBoost({ ...draftBoost, reason });
  };

  return (
    <>
      <Form<DraftBoost>
        onChange={setDraftBoost}
        originalValue={boost === null ? undefined : (boost as DraftBoost)}
        value={draftBoost}
      >
        <Modal
          isOpen
          onClose={_.isEqual(initialBoost, draftBoost) ? onClose : () => setShowConfirm('cancel')}
          onSave={handleSave}
        >
          {showTargetAudienceSelector ? (
            <TargetAudience
              draftBoost={draftBoost}
              onCancel={() => setShowTargetAudienceSelector(false)}
              onSave={handleSaveTargetAudience}
            />
          ) : (
            <BoostJob
              boost={boost}
              draftBoost={draftBoost}
              initialBoost={initialBoost}
              onRemove={() => setShowConfirm('remove')}
              onSelectTargetAudience={() => setShowTargetAudienceSelector(true)}
              onSelectReason={handleSelectReason}
            />
          )}
        </Modal>
      </Form>

      {showConfirm === 'cancel' && (
        <Confirm isOpen onCancel={handleHideConfirm} onConfirm={onClose}>
          Proceed with cancel? Changes will be lost
        </Confirm>
      )}

      {showConfirm === 'remove' && (
        <ConfirmRemove onClose={handleHideConfirm} onSave={handleRemove} />
      )}
    </>
  );
}
