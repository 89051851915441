import { U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const availabilityPropType = PropTypes.arrayOf(PropTypes.oneOf([0, 1]));

export const capacityPropType = PropTypes.shape({
  isDisabled: PropTypes.bool,
  limit: PropTypes.number,
  visits: PropTypes.number,
});

export const getUnassignItems = ({ setVisit, visit, visitPro, workOrder }) => {
  const deleteRole = () => {
    const index = _.findIndex(visit.pros, pro => pro.id === visitPro.id);
    const pros = visit.pros.filter((pro, i) => i !== index);
    setVisit({ ...visit, pros });
  };

  const redispatchRole = () => {
    const index = _.findIndex(visit.pros, pro => pro.id === visitPro.id);
    const pros = visit.pros.filter((pro, i) => i !== index);
    const newVisit = { ...visit, pros };
    const roles = [W.VisitProRole.PRIMARY, W.VisitProRole.HELPER, W.VisitProRole.INTERESTED];
    setVisit({
      ...newVisit,
      pros: [
        ...pros,
        {
          availability: [],
          status: W.VisitProStatus.PENDING,
          tasks: workOrder.tasks.map(task => ({
            id: task.id,
            role: roles.find(role =>
              W.visitProCanAddRole({ role, taskId: task.id, visit: newVisit })
            ),
          })),
        },
      ],
    });
  };

  return [
    {
      description: 'Unassigns and generates an unassigned role to redispatch to another tech.',
      onClick: redispatchRole,
      title: 'Redispatch Role',
    },
    {
      description:
        'Unassigns and removes role. This role does not need to be redispatched to another tech.',
      disabled: visit.pros.length <= 1,
      onClick: deleteRole,
      title: 'Delete Role',
    },
  ];
};

export const getUnassignedPros = visit =>
  visit.pros
    .map((pro, index) => ({ ...pro, index }))
    .filter(pro => !pro.id && pro.status !== W.VisitProStatus.CANCELLED);

export const firstColumnWidth = 354;

export const firstRowHeight = 154;

export const proPropType = PropTypes.exact({
  ...U.pro.propType,
  homeDistance: PropTypes.number,
  name: PropTypes.string,
  weights: PropTypes.objectOf(PropTypes.oneOf([0])),
});

export const rowHeight = 97;

export const sectionHeight = 57;

export const startTimesPropType = PropTypes.arrayOf(U.timePropType);

export const statusColor = {
  [W.VisitProStatus.CANCELLED]: 'red',
  [W.VisitProStatus.CONFIRMED]: 'blue',
  [W.VisitProStatus.EN_ROUTE]: 'orange',
  [W.VisitProStatus.LEFT_SITE]: 'green',
  [W.VisitProStatus.NO_SHOW]: 'red',
  [W.VisitProStatus.ON_SITE]: 'orange',
  [W.VisitProStatus.PENDING]: 'red',
};

export const timeSlotsPropType = PropTypes.arrayOf(
  PropTypes.exact({
    address: U.addressPropType,
    customer: PropTypes.string,
    distance: PropTypes.number,
    endTime: U.timePropType,
    isSealedSystem: PropTypes.bool,
    proStatus: PropTypes.oneOf(W.visitProStatusOptions),
    routeOrder: PropTypes.number,
    startTime: U.timePropType,
    taskId: PropTypes.string,
    title: PropTypes.string,
    workOrderId: PropTypes.string,
  })
);
