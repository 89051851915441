import React, { ReactElement } from 'react';
import U from '@nanaio/util';
import Text from '../../core/Text';

type Props = {
  cypressId?: string;
  description?: string;
  disabled?: boolean;
  index: number;
  isLast: boolean;
  onClick?: () => void;
  title: string;
};

export default function Item({
  cypressId,
  description,
  disabled,
  index,
  isLast,
  onClick,
  title,
}: Props): ReactElement {
  return (
    <div
      className={`border-t${index ? '' : '-0'} ${
        disabled || !onClick ? '' : 'cursor-pointer'
      } border-grey-medium py-2 ${isLast ? 'pb-0' : ''} ${
        disabled || !onClick ? 'opacity-50' : ''
      }`}
      data-cy={cypressId}
      onClick={onClick}
    >
      <Text color="secondary" type="button">
        {title}
      </Text>
      {U.toArray(description).map(description => (
        <Text color="grey.dark" key={description} type="helper">
          {description}
        </Text>
      ))}
    </div>
  );
}
