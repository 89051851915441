import React from 'react';
import { connect } from 'react-redux';
import { Fab } from '@material-ui/core';
import U from '@nanaio/util';
import T from 'prop-types';

class CreateEstimate extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = {};
    this.createEstimate = this.createEstimate.bind(this);
  }

  async createEstimate() {
    const r = await U.api('post', `workorders/${this.props.workorder.id}/estimate`);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    }
    // passing the save option to U.api is causing the parent component to rerender, which is causing
    // this component to reinitialize from scratch so we lose the error message render without this
    // fix is to manually save the response to the redux store instead of using the U.api option
    else {
      U.redux.set(`workorders.${r.id}`, r);
    }
  }

  render() {
    if (!this.props.workorder || this.props.workorder.estimate) {
      return null;
    }
    if (this.props.workorder.invoice) {
      return (
        <div>An invoice has already been created and changes to the estimate are blocked.</div>
      );
    }
    return (
      <div className="border-top p-5 text-center" style={{ background: '#fafafa' }}>
        <div className="text-danger">{this.state.error}</div>
        <Fab
          variant="extended"
          aria-label="Create"
          className="m-invoice-btn blue"
          onClick={this.createEstimate}
        >
          Create Estimate
        </Fab>
      </div>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const workorder = s.workorders[job.metadata.workOrderId];
  return { job, workorder };
})(CreateEstimate);
