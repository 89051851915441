import { PartStatus } from '@nanaio/util';
import { useQuery,UseQueryResult } from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

export type NextPartStatusResponse = {
  categoryKey: string;
  key: PartStatus;
  nextStatuses: PartStatus[];
};

const fetchNextPartStatuses = async (statusKey: PartStatus): Promise<NextPartStatusResponse> => {
  const { data } = await AXIOS_INSTANCE.get<NextPartStatusResponse>(
    `/api/statusEngine/category/part/status/${statusKey}`
  );

  return data;
};

export const useGetNextPartStatuses = (
  statusKey: PartStatus
): UseQueryResult<NextPartStatusResponse> => {
  return useQuery({
    queryKey: ['nextPartStatus', statusKey],
    queryFn: () => fetchNextPartStatuses(statusKey),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
