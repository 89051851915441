/* eslint-disable array-callback-return */
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Col,Row } from 'reactstrap';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Badge, Icon } from '@/components';
import { supportEmail } from '@/config/const';
import { Address, Bool, Number, Search, Select, Text } from '../../com/ui/form';
import { referredBy, statusOptions, updateUser } from '../../com/user';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const props = [
  'profile.firstName',
  'profile.lastName',
  'profile.phone',
  'profile.phoneExt',
  'profile.altPhone',
  'profile.altEmail',
  'email',
  'payment.stripe.customer.id',
  'profile.address',
  'roles',
  'status',
  'metadata.gmailSupportNumber',
  'profile.referredBy',
  'metadata.notificationPreference',
  'blockEmail',
  'blockSms',
  'zendeskId',
];

class EditDetails extends React.Component {
  constructor(p) {
    super(p);
    this.state = { user: p.user };
    global.document.title = p.user?.profile?.fullName;
    if (p.user?.metadata?.ticketId && !p.ticket) {
      U.api('get', `issues/${p.user.metadata.ticketId}`, true);
    }
    if (!p.blockLoad) {
      U.api('get', `users/${p.user?.id || p.me.userId}`, ['save']);
    }
    const bankCode = _.get(/code=([a-zA-Z_0-9]+)/.exec(global.location.search), '[1]');
    if (bankCode) {
      window.history.pushState('Profile', 'Profile', '/profile');
      U.api('post', 'serviceproviders/me/payment/account', {
        authCode: bankCode,
      }).then(response => {
        if (response.errMsg) {
          this.setState({ error: response.errMsg });
        }
      });
    }
  }

  getChildContext = () => ({ t: this });

  UNSAFE_componentWillReceiveProps(p) {
    const { isOpen, user } = this.props;
    // clear error messages on modal open
    if (!isOpen && p.isOpen) {
      this.setState({ error: false });
    }

    if (!_.isEqual(user, p.user)) {
      this.setState(p.user);
    }
    if (!user && p.user) {
      const ticketId = _.get(p.user, 'metadata.ticketId');
      if (ticketId && !p.ticket) {
        U.api('get', `issues/${ticketId}`, true);
      }
    }
    if (!_.isEqual(p.user, user)) {
      this.setState({ user: p.user });
      global.document.title = _.get(p.user, 'profile.fullName');
    }
  }

  isChanged = () => {
    const { user } = this.props;
    const { user: stateUser } = this.state;
    return !!props.find(p => !_.isEqual(_.get(stateUser, p), _.get(user, p)));
  };

  save = async () => {
    const { user } = this.props;
    const { user: stateUser } = this.state;
    const changes = props
      .filter(p => !_.isEqual(_.get(stateUser, p), _.get(user, p)))
      .map(p => ({
        action: 'replace',
        path: p,
        value: _.get(stateUser, p),
      }));
    if (!changes.length) {
      return;
    }
    if (changes.find(c => c.path.endsWith('Name'))) {
      changes.push({
        action: 'replace',
        path: 'profile.fullName',
        value: `${stateUser.profile.firstName} ${stateUser.profile.lastName}`,
      });
    }
    const r = await updateUser(global.id(), changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    return this.setState({ error: false });
  };

  render() {
    const { isOpen, roles, toggle, user } = this.props;
    const { error, errors } = this.state;
    if (!isOpen) {
      return null;
    }
    const isChanged = this.isChanged();
    return (
      <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={toggle} style={customStyles}>
        <div className="rmc_header d-flex justify-content-between align-items-center">
          <div className="rmc_header-btn left" onClick={toggle}>
            <Icon name="close" />
          </div>
          <h5 className="rmc_title flex-1 text-center">Edit Profile</h5>
          <div className="rmc_header-btn right" onClick={this.save}>
            <div className={`rmc-btn ${isChanged ? `active` : `disabled`}`}>
              <h6>{isChanged ? `Save changes` : `No changes`}</h6>
            </div>
          </div>
        </div>

        <div className="rmc_body">
          <div className="rmc_body--container">
            {_.values(errors).map(e => (
              <div key={e} className="text-danger">
                {e}
              </div>
            ))}
            <p className="text-danger">{error}</p>
            <div className="py-4">
              <div className="mb-3">
                <h5 className="weight-700 text-center">Contact Details</h5>
              </div>
              <Row>
                <Col>
                  <Text id="user.profile.firstName" />
                </Col>
                <Col>
                  <Text id="user.profile.lastName" />
                </Col>
              </Row>
              <div className="row">
                <Text id="user.email" className="col" />
                <Text id="user.profile.altEmail" className="col" />
              </div>
              <Row>
                <Col>
                  <Text id="user.profile.phone" />
                </Col>
                <Col>
                  <Text id="user.profile.phoneExt" />
                </Col>
                <Col>
                  <Text id="user.profile.altPhone" />
                </Col>
              </Row>
              <label>Home address</label>
              <Address id="user.profile.address" label="" />
              <Row>
                <Col>
                  <Text id="user.profile.address.arrivalInstructions" />
                </Col>
                <Col>
                  <Number id="user.zendeskId" />
                </Col>
              </Row>
            </div>
            <div className="mb-2">
              <label>Payment Methods</label>
            </div>
            {_.get(user, 'payment.stripe.cards', []).length === 0 && (
              <p className="text-muted py-2">No cards added</p>
            )}
            {_.get(user, 'payment.stripe.cards', []).map(card => (
              <div
                className="box-select mb-2"
                key={card.id}
                style={{ cursor: 'default', padding: '8px' }}
              >
                <img
                  src={`/img/cards/${card.brand.toLowerCase()}.png`}
                  height="20"
                  className="mr-3"
                  alt=""
                />
                <p className="weight-600 flex-1">
                  {card.brand} &bull;&bull;&bull;&bull; {card.last4}
                </p>
                <Badge color="grey-dark">
                  {card.exp_month}/{card.exp_year}
                </Badge>
              </div>
            ))}
            <div className="mt-2 px-2 py-2" style={{ background: '#fafafa' }}>
              <small className="text-muted weight-600">
                *To add/edit their payment method, visit one of their bookings.
              </small>
            </div>
            <Number
              id="user.metadata.gmailSupportNumber"
              label={`Gmail Support Number, found in ${supportEmail} mailbox url: https://mail.google.com/mail/u/{gmailSupportNumber}/#inbox`}
            />
            <Select
              id="user.metadata.notificationPreference"
              options={['both', 'push', 'sms']}
              cap
            />
            <Bool id="user.blockEmail" />
            <Bool id="user.blockSms" />
            <div className="py-4">
              <div className="mb-3">
                <h5 className="weight-700 text-center">Account</h5>
              </div>
              <Select id="user.status" className="select-container" options={statusOptions} cap />
              {roles.admin && <Search id="user.roles" options={U.user.roleOptions} multi />}
              <Select
                id="user.profile.referredBy"
                className="select-container"
                options={referredBy}
              />
              <Text id="user.payment.stripe.customer.id" label="Stripe Id" />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

EditDetails.childContextTypes = { t: PropTypes.object }; // eslint-disable-line react/forbid-prop-types

EditDetails.propTypes = {
  isOpen: PropTypes.bool,
  roles: U.user.rolesPropType.isRequired,
  toggle: PropTypes.func.isRequired,
  user: U.user.propType,
};

export default connect(s => {
  const user = _.merge({}, s.users[global.id()]);
  const ticket = s.issues[_.get(user, 'metadata.ticketId')];
  const roles = U.user.roles(s);
  return { referralCode: _.get(user, 'shortId'), me: s.me, user, ticket, roles };
})(EditDetails);
