import React, { useState } from 'react';
import { T, Task, U } from '@nanaio/util';
import { Slot } from '@nanaio/util/dist/workOrder';
import classNames from 'classnames';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Text } from '@/components';
import Chip from '@/components/core/Chip';

type Props = {
  availability: _.Dictionary<[Slot, ...Slot[]]>;
  task: Task;
  onChange?: (date: mt.MomentInput) => void;
};
export default function TimeFrames({ availability, task, onChange }: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);
  const spTimeBand = task.metadata?.servicePowerTimeBand;
  const slotInSPTimeBand =
    spTimeBand && Object.keys(T.SQUARE_TRADE_SERVICEPOWER_TIME_BAND_SLOTS_MAP).includes(spTimeBand);
  const squareTrade2HourWindowActive = T.orgName(task, true) === 'Square Trade' && slotInSPTimeBand;
  const timeSlotArray = squareTrade2HourWindowActive
    ? ['8-10 AM', '1-3 PM']
    : ['8-12 PM', '12-4 PM', '4-8 PM'];

  const getTimeString = (slot: Slot) => {
    const startTime = T.getTimeSlotDisplay(task, 'start', slot.start).format('h');
    const endTime = T.getTimeSlotDisplay(task, 'end', slot.end).format('h A');
    return `${startTime}-${endTime}`;
  };

  const [selected, setSelected] = useState(0);
  return (
    <div className="mt-4 flex flex-col space-y-2">
      {_.keys(availability)
        .map(date => +date)
        .sort()
        .map(date => (
          <div key={date} className="flex items-center space-x-2">
            <Text className="w-1/4 max-w-[90px]" type="label">
              {mt(date).tz(timezone).format('ddd, MMM D')}
            </Text>
            {timeSlotArray.map(timeString => {
              const slot = availability[date].find(slot => getTimeString(slot) === timeString);

              if (slot) {
                const start = mt(slot.start);
                const inThePast = start.isBefore();
                return onChange ? (
                  <Chip
                    key={timeString}
                    className="w-1/4 max-w-[80px]"
                    variant={selected === start.valueOf() ? 'primary' : 'secondary'}
                    onClick={() => {
                      if (inThePast) {
                        return;
                      }
                      setSelected(start.valueOf());
                      onChange?.(start);
                    }}
                    disabled={inThePast}
                  >
                    {timeString}{' '}
                  </Chip>
                ) : (
                  <div
                    key={timeString}
                    className={classNames('w-1/4 max-w-[80px] px-1 py-2 text-center', {
                      'text-secondary line-through opacity-50': inThePast,
                    })}
                  >
                    <Text type="body-2">{timeString}</Text>
                  </div>
                );
              } else {
                return (
                  <div
                    key={timeString}
                    className="w-1/4 max-w-[80px] px-1 py-2 text-center text-icons-grey"
                  >
                    <Text type="body-2" className="text-secondary opacity-50">
                      -
                    </Text>
                  </div>
                );
              }
            })}
          </div>
        ))}
    </div>
  );
}
