import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import pluralize from 'pluralize';
import { isDevBuild } from '@/config/const';
import { openLink } from '@/utils';
import { Button, Loader, Text } from '../core';
import { exportOptions } from './types';
import { headerHeight } from './util';

type Props = {
  checkedRowCount: number;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToRows: Table.Depth2<Table.Row[]>;
  endOfResults?: boolean;
  exportToCsv: (id?: string) => void;
  isLimit1000?: boolean;
  isLoading: boolean;
  isModules?: boolean;
  onAdd: () => void;
  onClearFilters: () => void;
  onRefresh: () => Promise<void> | void;
  onSearch: ({ limit }: { limit?: number }) => Promise<void>;
  openChangeQuery: () => void;
  patching?: boolean;
  query: Table.Query;
  roles: Record<string, string>;
  rows: Table.Row[];
  table: Table.Table;
  toggleAnalytics: () => Promise<void>;
  toggleEditTableIsOpen: () => void;
  toggleMessage: () => void;
  toggleModulesIsOpen: () => void;
  toggleQueryIsOpen: () => void;
};

/** Renders the table title and header buttons above the table */
export default function Header({
  checkedRowCount,
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToRows,
  endOfResults,
  exportToCsv,
  isLimit1000,
  isLoading,
  isModules,
  onAdd,
  onClearFilters,
  onRefresh,
  onSearch,
  openChangeQuery,
  patching,
  query,
  roles,
  rows,
  table,
  toggleAnalytics,
  toggleEditTableIsOpen,
  toggleMessage,
  toggleModulesIsOpen,
  toggleQueryIsOpen,
}: Props): JSX.Element {
  const [queryOptions, setQueryOptions] = useState<Table.Query[]>([]);

  useEffect(() => {
    if (!queryOptions.length && query?.id) {
      setQueryOptions([query]);
    }
  }, [query, queryOptions.length]);

  const canMessage = _.values(
    databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]
  ).some(module => module.isEmail || module.isPhone);

  const filterCount = _.filter(query?.columns, Table.filterIsValid).length;
  let requestLimit = isDevBuild ? 500 : 2000;
  if (query?.analytics?.isOpen) {
    requestLimit = 10000;
  }

  const showLoadButton =
    !isModules &&
    !endOfResults &&
    !table.hideLoadButton &&
    !(query?.analytics?.isOpen && isLimit1000 && rows.length >= 1000);

  const tableCounts: string[] = [];
  _.forEach(databaseIdToTableIdToRows, tableIdToRows =>
    _.forEach(tableIdToRows, (rows, tableId) => {
      if (rows.length) {
        tableCounts.push(`${rows.length} ${_.startCase(pluralize(tableId))}`);
      }
    })
  );

  return (
    <div
      className={`absolute left-0 right-0 top-0 flex items-center px-4 py-2 ${
        table.embed ? 'pl-0' : ''
      }`}
      style={{ height: headerHeight }}
    >
      {showLoadButton && (
        <Button
          cypressId="Load"
          onClick={limit => onSearch({ limit: (limit as number) || requestLimit })}
          options={[
            {
              id: 'reset',
              name: 'Reset',
              tooltip: 'This will clear all loaded rows and improve performance.',
            },
            { id: 1000, name: '1,000' },
            { id: 2000, name: '2,000' },
            { id: 5000, name: '5,000' },
            { id: 10000, name: '10,000' },
            { id: 20000, name: '20,000' },
            { id: 30000, name: '30,000' },
            { id: 40000, name: '40,000' },
            { id: 50000, name: '50,000' },
          ]}
          size="medium"
          variant="primary"
        >
          Load
        </Button>
      )}

      <Button
        className="ml-4"
        cypressId="change-query"
        onClick={openChangeQuery}
        size="medium"
        variant="secondary"
      >
        {`${_.startCase(query.databaseId)} > ${_.startCase(query.table)} > ${
          query.name || 'Default'
        }`}
      </Button>

      <div className={`ml-4 flex flex-1 ${query?.analytics.isOpen ? '' : 'overflow-x-auto'}`}>
        <div className="mr-4 whitespace-nowrap">
          <div className="flex space-x-1">
            <Text cypressId="row-count" type="subtitle-1">
              {rows.length}
            </Text>
            <Text type="subtitle-1">
              rows
              {!!checkedRowCount && ` (${checkedRowCount} selected)`}
            </Text>
          </div>
          <Text color="grey.dark" type="helper">
            {tableCounts.join(', ')}
          </Text>
        </div>
        <Loader cypressId="loader" isLoading={isLoading || patching} />
        {!isLoading && !patching && (!query || !query.columns.length) && (
          <Text className="ml-4" color="danger">
            No Columns Added
          </Text>
        )}

        <div className="flex-1" />

        {!!filterCount && (
          <Button
            className="ml-4"
            cypressId="clear-filters"
            onClick={() => onClearFilters()}
            size="medium"
            variant="secondary"
          >
            Clear filters ({filterCount})
          </Button>
        )}

        <Button className="ml-4" onClick={toggleAnalytics} size="medium" variant="secondary">
          {query?.analytics.isOpen ? 'Table' : 'Analytics'}
        </Button>

        <Button className="ml-4" onClick={toggleModulesIsOpen} size="medium" variant="secondary">
          {isModules ? 'Exit ' : ''}Columns
        </Button>

        {roles.export && (
          <Button
            className="ml-4"
            cypressId="Export"
            onClick={id => exportToCsv(id as string | undefined)}
            options={query?.analytics.isOpen && !isModules ? exportOptions : undefined}
            size="medium"
            variant="secondary"
          >
            Export
          </Button>
        )}

        {!isModules && roles.bulkMessage && canMessage && !query?.analytics.isOpen && (
          <Button className="ml-4" onClick={toggleMessage} size="medium" variant="secondary">
            Message
          </Button>
        )}

        {table.primaryButtonUi}

        {!table.primaryButtonUi && table.addUi && (
          <Button className="ml-4" onClick={onAdd} size="medium" variant="secondary">
            New
          </Button>
        )}

        {!table.primaryButtonUi && !table.addUi && table.addUrl && (
          <Button
            className="ml-4"
            onClick={() => openLink({ newTab: true, url: String(table.addUrl) })}
            size="medium"
            variant="secondary"
          >
            New
          </Button>
        )}

        {table.additionalButtonsUi}

        <Button className="ml-4" onClick={toggleQueryIsOpen} size="medium" variant="secondary">
          Query
        </Button>

        {!isModules && !query?.analytics.isOpen && (
          <Button className="ml-4" onClick={onRefresh} size="medium" variant="secondary">
            Refresh
          </Button>
        )}

        {!isModules && (
          <Button className="ml-4" onClick={toggleEditTableIsOpen} size="medium" variant="primary">
            Edit Table
          </Button>
        )}
      </div>
    </div>
  );
}
