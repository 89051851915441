import U from '@nanaio/util';
import _ from 'lodash';
import store from 'store2';
import { loggedOutEvent } from '../analytics';

/**
 * This is used to report logout events; we have from time-to-time had issues
 * with unexpected logouts and this allows us to track what caused the event.
 * @param {*} props
 */
const reportLogoutEvent = props => {
  // Report to segment, report as a logged out event in case the users token is expired or for some other reason they aren't logged in
  loggedOutEvent().track('v1_userLoggedOut');

  // Report to server
  const body = {
    property: 'web',
    location: global.location.href,
  };

  if (_.get(props, 'body')) {
    body.body = props.body;
  }
  if (_.get(props, 'method')) {
    body.method = props.method;
  }
  if (_.get(props, 'path')) {
    body.path = props.path;
  }
  if (_.get(props, 'comment')) {
    body.path = props.comment;
  }
  if (_.get(props, 'state.me.token')) {
    body.token = props.state.me.token;
  }

  // disabled because this is causing an infinite loop
  // U.api('post', 'health/logout', body);
};

export const logout = props => {
  reportLogoutEvent(props);

  store.remove('jobsMissingNotes');
  store.remove('region');
  store.remove('me');
  store.remove('login.redirect');
  store.remove('login.returnTo');
  store.remove('roleOverride');

  const shouldLoginRedirect = !['/login', '/academy'].find(v =>
    _.startsWith(global.location.pathname, v)
  );

  if (shouldLoginRedirect) {
    global.location.pathname = '/login';
  }
};

export const loadUsers = async () => {
  const r = await U.api('post', 'users/search');
  if (r) {
    U.redux.merge('users', r);
  }
};

export const getRole = user => {
  const roles = user.roles || [];
  if (roles.find(r => [U.user.Role.CUSTOMERSUPPORT, U.user.Role.ADMIN].includes(r))) {
    return 'admin';
  }
  if (roles.includes(U.user.Role.SERVICEPROVIDER)) {
    return 'pro';
  }
  if (roles.includes(U.user.Role.USER)) {
    return 'user';
  }
  return 'guest';
};
