import React, { useMemo, useState } from 'react';
import Select, { components as Components, OptionProps } from 'react-select';
import U from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { Button, FormControl, Icon, Input, InputType, Paper, Radio, Text } from '@/components';
import theme from '@/components/theme';
import Footer from '../com/Footer';
import { BookingState, Brand } from '../util';
import HomeWarrantyModal from './HomeWarrantyModal';

type OptionType = { label: string; value: string };

export const APPLIANCES = {
  Refrigerator: true,
  Dishwasher: true,
  Stove: true,
  Oven: true,
  Washer: true,
  Dryer: true,
};

const SELECT_STYLES = {
  option: (baseStyles: React.CSSProperties, state: OptionProps<OptionType, false>) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? theme.colors.background.medium : 'white',
    height: 48,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const Option = (props: OptionProps<OptionType, false>) => {
  const data = props.data as OptionType;
  return (
    <Components.Option {...props}>
      <div className="flex h-full items-center border-b border-grey-medium">
        <Radio isSelected={props.isSelected} />
        <Text className="ml-2 flex-1">{data.label}</Text>
        {data.label in APPLIANCES && (
          <img alt={data.label} src={`/img/services/${data.label}.png`} style={{ height: 32 }} />
        )}
      </div>
    </Components.Option>
  );
};

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function ServiceV2({ onChange, onNext, state, ...props }: Props): JSX.Element {
  const [zipError, setZipError] = useState('');
  const [zip, setZip] = useState(state.zip.value || '');
  const [serviceId, setServiceId] = useState(state.serviceId);
  const [brand, setBrand] = useState<Brand | undefined>(state.metadata.taskQuestions.Make);
  const [showHomeWarrantyModal, setShowHomeWarrantyModal] = useState(false);

  const serviceOptions = useMemo(
    () =>
      state.services.map(service => ({
        label: service.name.replace(' Repair', ''),
        value: service.id,
      })),
    [state]
  );

  const brandOptions = useMemo(() => {
    return _.keys(U.brands)
      .sort()
      .map(value => ({ label: value, value }));
  }, []) as { label: string; value: string }[];

  const isDisabled = !serviceId || !brand || !zip;

  const handleNext = async () => {
    if (zip.length !== 5) {
      setZipError('Enter a 5-digit zip code before continuing');
      return;
    }
    // reset availability if zip, service or brand changes
    if (
      zip !== _.get(state, 'zip.value') ||
      serviceId !== state.serviceId ||
      brand !== _.get(state, 'metadata.taskQuestions.Make')
    ) {
      onChange('availability', null);
    }
    onChange('serviceId', serviceId);
    onChange('metadata.taskQuestions.Make', brand);
    onChange('zip', { value: zip });
    await onNext();
  };

  return (
    <>
      <section className="-mx-5 md:mx-0">
        <img src="/img/hero-book.jpg" className="w-full" alt="" />

        <div className="relative m-5 -mt-11">
          <Paper className="p-4 pt-7" variant="elevated">
            <Text type="headline-6">Welcome, Let’s Find The Right Technician For You</Text>
            <Text className="mt-2">
              We’re in your neighborhood and we’ll fix it, no matter where you bought it.
            </Text>

            <Button
              className="mt-6 flex px-0 py-0"
              variant="link"
              onClick={() => setShowHomeWarrantyModal(true)}
            >
              <Icon name="info_outline" color="primaryCTA" />
              <Text className="ml-1 text-primaryCTA hover:text-primaryCTA-hover" type="button">
                Covered under a warranty plan?
              </Text>
            </Button>

            <FormControl label="Appliance" required className="mt-6">
              <Select
                isSearchable={false}
                components={{ Option }}
                value={serviceOptions.find(v => v.value === serviceId)}
                onChange={b => setServiceId(nullthrows(b?.value))}
                options={serviceOptions}
                placeholder="Select"
                styles={SELECT_STYLES}
              />
            </FormControl>

            <FormControl label="Brand" required className="mt-6">
              <Select
                components={{ Option }}
                value={brandOptions.find(v => v.value === brand)}
                onChange={b => setBrand(nullthrows(b?.value as string) as Brand)}
                options={brandOptions}
                placeholder="Select"
                styles={SELECT_STYLES}
              />
            </FormControl>

            <Input
              className="mt-6"
              error={zipError}
              placeholder="Enter zip code"
              label="Zip Code"
              required
              onChange={value => setZip(String(value))}
              value={zip}
              type={InputType.NUMBER}
            />

            <Text className="-mb-4 mt-6 pb-1">
              Already have an account?{' '}
              <a
                className="font-semibold text-primaryCTA hover:text-primaryCTA-hover"
                href="/login"
              >
                Log in
              </a>
            </Text>

            <Footer
              {...props}
              preserveLayoutWhileFloating={false}
              nextText="Book a Repair"
              onNext={handleNext}
              disabled={isDisabled}
              error={zipError}
            />
          </Paper>

          <div className="mb-8 mt-8 flex h-11">
            <img
              className="ml-6 h-full"
              src="/img/trustpilot.png"
              alt="Trustpilot 2,700 reviews, 4.7 rating"
            />

            <img
              className="ml-6 h-full"
              src="/img/bbb-2.png"
              alt="Better Business Bureau A+ rating"
            />
          </div>
        </div>
      </section>

      <HomeWarrantyModal
        isOpen={showHomeWarrantyModal}
        onClose={() => setShowHomeWarrantyModal(false)}
      />
    </>
  );
}
