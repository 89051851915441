import React, { useCallback, useState } from 'react';
import { Task } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { APIError, Button, Input, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import List, { ListItemProps } from '@/components/pages/user/FTCBookingFlow/List';
import { supportArticleUrls } from '@/config/const';
import { useLazyLegacyAPI } from '@/hooks';
import type { ModelStepProps as Props } from './ApplianceModelStep';
import InfoProvidedNotice from './InfoProvidedNotice';
import ModelNumToolTip from './ModelNumTooltip';

type EventType = {
  variant: string;
  step: string;
  changes?: { path: string; value: unknown }[];
  changesMade: boolean;
  couldNotFind: boolean;
  photoUploaded: boolean;
};

const instructionListItems: ListItemProps[] = [
  {
    description: 'Locate the model number sticker on your appliance',
    link: {
      title: 'Where can I find this?',
      url: supportArticleUrls.modelStickerHelp,
    },
  },
  {
    description: 'Enter the model number and serial number on the sticker',
  },
  {
    description: 'Double check each field to make sure they are correct',
  },
];

export default function ApplianceModelManualStep({
  task,
  onNextSubStep,
  onToggleEntryMethod,
  onCantFind,
  updateError,
}: Props): JSX.Element {
  const origForm = _.pick(task.metadata.taskQuestions, ['Model', 'Serial #']);
  const [form, setForm] = useState({ ...origForm });
  const showVerifyMessage = !_.isEmpty(_.pickBy(origForm));
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={
          <>
            Unable to save changes to model number
            <br />
            {error}
          </>
        }
      />
    ),
    save: true,
    method: 'put',
  });

  const handleNext = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }

    const eventInfo: EventType = {
      variant: 'ftc',
      step: 'modelAndSerial',
      couldNotFind: false,
      changesMade,
      photoUploaded: false,
    };

    if (changesMade) {
      const changes = [
        { path: 'metadata.taskQuestions.Model', value: form.Model },
        { path: 'metadata.taskQuestions.Serial #', value: form['Serial #'] },
      ];
      const data = await updateTask(changes);
      if (!data) {
        return;
      }
      eventInfo.changes = changes;
    }

    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);

    await onNextSubStep();
  }, [changesMade, form, onNextSubStep, submitRef, updateTask]);

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          <Form onChange={setForm} value={form} ref={submitRef}>
            {!taskToUpdate.error && showVerifyMessage && <InfoProvidedNotice task={task} />}
            {taskToUpdate.error}
            {updateError}
            <Text className="mt-6 text-center text-font-dark" type="headline-6">
              Enter The Model No. and Serial No.
            </Text>
            <div className="mt-4 flex w-full justify-center">
              <ModelNumToolTip />
            </div>
            <Input id="Model" label="Model Number" placeholder="Enter Model Number" required />
            <Input id="Serial #" label="Serial Number" placeholder="Enter Serial Number" required />
            <div className="relative mt-6 w-full rounded-lg bg-background-light ring-[1px] ring-icons-grey">
              <img
                className="mx-auto p-1"
                src="../../../../img/customerPortal/modelStickerPlaceholder.png"
                alt=""
              />
            </div>

            <List
              className="mt-12"
              header="Instructions"
              items={instructionListItems}
              variant="steps"
            />
            <div className="flex  h-[18px] w-full justify-center border-b-[1px] border-grey-dark ">
              <Text className="w-[48px] bg-white px-4 py-2.5 text-grey-dark" type="label">
                or
              </Text>
            </div>

            <Button className="mt-6 w-full" onClick={onToggleEntryMethod} variant="outline">
              Upload Photo
            </Button>
            <div className="flex w-full justify-center">
              <Button className="mt-2 w-full" onClick={onCantFind} variant="link">
                I couldn't find the sticker
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={handleNext} submit>
          {changesMade ? 'Save' : 'Next'}
        </Button>
      </Modal.Footer>
    </>
  );
}
