import { ErrorResponse, User } from '@nanaio/util';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AXIOS_INSTANCE } from '@/com/axiosInstance';

const fetchUser = async (id: string): Promise<User> => {
  const { data: user } = await AXIOS_INSTANCE.get<User>(`/api/users/${id}`);

  return user;
};

export const useGetUser = (id: string): UseQueryResult<User> => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => fetchUser(id),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
};

type CreateUserResponse = {
  user: User;
  token: string | null;
};

export const useCreateUser = (): UseMutationResult<CreateUserResponse | ErrorResponse> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: newUser =>
      AXIOS_INSTANCE.post<CreateUserResponse | ErrorResponse>(
        '/api/users?includeRoles=true',
        newUser
      ).then(response => response.data),
    onSuccess: data => {
      queryClient.setQueryData(['user', id], data);
    },
  });
};
