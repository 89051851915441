import _ from 'lodash';
import m from 'moment';

export const cancel = () => {
  const body = [{ action: 'replace', path: 'status', value: 'cancelled' }];
  return { method: 'put', path: `tasks/${_.id()}`, body };
};

export const partsFollowup = ({ chosenTimeslot }) => {
  const body = [{ action: 'add', path: 'visits', body: { chosenTimeslot, type: 'partsFollowup' } }];
  return { method: 'put', path: `tasks/${_.id()}`, body };
};

export const warrantyFollowup = ({ chosenTimeslot }) => {
  const body = [
    { action: 'add', path: 'visits', body: { chosenTimeslot, type: 'warrantyFollowup' } },
  ];
  return { method: 'put', path: `tasks/${_.id()}`, body };
};

export const visitStatusOptions = status => {
  switch (status) {
    case 'assigned':
      return ['enRoute'];
    case 'enRoute':
      return ['arrived'];
    case 'arrived':
      return ['customerNoShow'];
    default:
      return [];
  }
};

export const sortTasks = tasks => {
  // eslint-disable-next-line no-underscore-dangle
  let _tasks = _.values(tasks).filter(task => _.last(_.values(task.visits)).chosenTimeslot);
  _tasks = _tasks.sort((a, b) => {
    const aLastVisit = _.last(_.values(a.visits));
    const bLastVisit = _.last(_.values(b.visits));
    const aStart = _.m(aLastVisit.chosenTimeslot.startTime).valueOf();
    const bStart = _.m(bLastVisit.chosenTimeslot.startTime).valueOf();
    return aStart < bStart ? 1 : aStart > bStart ? -1 : 0;
  });
  _tasks = _tasks.map(task => ({
    ...task,
    start: _.last(_.values(task.visits)).chosenTimeslot.startTime,
  }));
  return _tasks;
};

export const getVisits = tasks => {
  let visits = [];
  _.mapValues(tasks, task =>
    _.mapValues(task.visits, visit => {
      const start = _.get(visit.chosenTimeslot || visit.preferredTimeSlot, 'startTime');
      visits.push({ task, visit, start: m(start).valueOf() });
    })
  );
  visits = _.sortBy(visits, 'start');
  return visits;
};
