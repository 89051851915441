import React, { useCallback, useState } from 'react';
import { U, User } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { workOrderEvent } from '@/com/analytics';
import { APIError, Button, Modal, Text } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import Input, { Type } from '@/components/form/Input';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';
import type { SubStepProps as Props } from './ConfirmationFlowModal';
import InfoProvidedNotice from './InfoProvidedNotice';

type EventType = {
  variant: string;
  step: string;
  changes?: { path: string; value: unknown }[];
  changesMade: boolean;
};

export default function ProfileContactStep({ task, onNextSubStep }: Props): JSX.Element {
  // This is hitting the user endpoint and not the task endpoint (although the task will get
  // updated). So we either need to refetch the task after updating the user, or use the user
  // for the inputs. The latter is preferred but there's no guarantee the user has been fetched
  // on first render. So we can use the user if available, or fallback to the task and avoid
  // any additional fetches completely.
  const origForm = useLegacySelector(state => {
    const user = state.users[task.customer.user.id];
    return _.pick(_.defaults({ ...user, ...user?.profile }, task.customer.user), [
      'firstName',
      'lastName',
      'email',
      'phone',
    ]);
  });

  const [form, setForm] = useState({
    ...origForm,
    email: U.isFakeEmail(origForm.email) ? '' : origForm.email,
  });
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();
  const [emailError, setEmailError] = useState<JSX.Element | null>(null);

  const [updateUser, userToUpdate] = useLazyLegacyAPI<User>(`users/${task.customer.user.id}`, {
    errorRender: ({ error }) => (
      <APIError
        className="mb-10"
        error={error}
        text={<>Unable to save changes to user profile</>}
      />
    ),
    save: true,
    method: 'put',
  });

  const handleNext = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }
    if (U.isFakeEmail(form.email)) {
      const errorMessage = 'Please enter a valid email address';
      setEmailError(<APIError className="mb-10" error={errorMessage} text={errorMessage} />);
      return;
    } else {
      setEmailError(null);
    }

    const eventInfo: EventType = {
      variant: 'ftc',
      step: 'contactInfoUpdated',
      changesMade,
    };

    if (changesMade) {
      const changes = [
        { path: 'profile.firstName', value: form.firstName },
        { path: 'profile.lastName', value: form.lastName },
        { path: 'email', value: form.email },
        { path: 'profile.phone', value: form.phone },
        { path: 'contactConfirmed', value: true },
      ];
      const data = await updateUser(changes);
      if (!data) {
        return;
      }
      eventInfo.changes = changes;
    }

    workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);

    await onNextSubStep();
  }, [changesMade, form, onNextSubStep, submitRef, updateUser]);

  return (
    <>
      <Modal.Body>
        <div className="p-4">
          <Form onChange={setForm} value={form} ref={submitRef}>
            {!userToUpdate.error && !emailError && <InfoProvidedNotice task={task} />}
            {userToUpdate.error}
            {emailError}
            <Text className="text-center text-font-dark" type="headline-6">
              Confirm Your Contact Details
            </Text>
            <div className="mb-2 mt-10 grid w-full grid-cols-2 gap-4">
              <Input id="firstName" label="First Name" required />
              <Input id="lastName" label="Last Name" required />
            </div>
            <Input id="email" label="Email Address" required type={Type.EMAIL} />
            <Text className="-mt-3 mb-6 text-grey-dark" type="helper">
              We’ll email you booking confirmation, receipts, and support messages.
            </Text>
            <Input id="phone" label="Phone Number" required type={Type.PHONE} />
            <Text className="-mt-3 mb-6 text-grey-dark" type="helper">
              We’ll send you service reminders and updates. Your phone number is always private and
              will not be shared with your technician.
            </Text>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer hideCloseButton>
        <Button className="w-full" onClick={handleNext} submit>
          {changesMade ? 'Save' : 'Next'}
        </Button>
      </Modal.Footer>
    </>
  );
}
