import React, { useEffect, useState } from 'react';
import * as Table from '@nanaio/table';
import U, { Brand } from '@nanaio/util';
import _ from 'lodash';
import { APIError, Form, Input, Modal, Table as TableComponent } from '@/components';

function EditBrand({ id, onSuccess, toggleOpen }: Table.AddUiProps | Table.EditUiProps) {
  const [brand, setBrand] = useState<Partial<Brand>>({});
  const [error, setError] = useState<string>();
  const [originalBrand, setOriginalBrand] = useState({});

  useEffect(() => {
    if (id) {
      void U.api<Brand>('get', `brands/${id}`).then(brand => {
        if (!('errMsg' in brand)) {
          setBrand(brand);
          setOriginalBrand(brand);
        }
      });
    }
  }, [id]);

  const submit = async () => {
    let response;
    if (id) {
      const changes = _.keys(brand).map(path => ({
        path,
        value: brand[path as keyof typeof brand],
      }));
      response = await U.api<Brand>('put', `brands/${id}`, changes);
    } else {
      response = await U.api<Brand>('post', 'brands', brand);
    }
    if ('errMsg' in response) {
      return setError(response.errMsg);
    }
    onSuccess(response);
    return undefined;
  };

  return (
    <Modal isOpen onClose={toggleOpen}>
      <Modal.Header title={`${id ? 'Edit' : 'Add'} Brand`} />
      <Modal.Body className="p-4">
        <Form onChange={setBrand} originalValue={originalBrand} value={brand}>
          {error && <APIError error={error} text="Error updating brand record" />}
          <Input id="mfgCode" label="Manufacturing Code" />
          <Input id="name" required />
          <Input id="tier" capitalize options={U.brand.tierOptions} required />
        </Form>
      </Modal.Body>
      <Modal.Footer onSave={submit} />
    </Modal>
  );
}

export default function BrandsTable(): JSX.Element {
  return <TableComponent {...Table.databases.default.brand} addUi={EditBrand} editUi={EditBrand} />; // eslint-disable-line react/jsx-props-no-spreading
}
