import React from 'react';
import { useDispatch } from 'react-redux';
import type { Pro as ServerPro } from '@nanaio/util';
import _ from 'lodash';
import { Avatar, Icon, Text } from '@/components';
import { openLink } from '../../../../utils';

// the pro returned from the availability endpoint is a different shape from
// the pro endpoint
export type AvailabilityPro = ServerPro & {
  homeDistance?: number;
  name: string;
  declinedReasons?: boolean | { brief: string; furtherInfo?: string }[];
  stats?: { ratingAutomatic: number };
};

type Props = {
  onSelect: (proId: string) => void;
  isSelected: boolean;
  pro: AvailabilityPro;
};

export default function Pro({ isSelected, onSelect, pro }: Props): JSX.Element {
  const dispatch = useDispatch();

  const handleOpenProfile = () => {
    openLink({ dispatch, url: `/pros/${pro.id}`, newTab: true });
  };

  const handleSelect = () => {
    onSelect(pro.id);
  };

  let declinedReasons;
  if (pro.declinedReasons) {
    declinedReasons = Array.isArray(pro.declinedReasons)
      ? _.map(pro.declinedReasons, reason => _.upperFirst(_.lowerCase(reason.brief))).join(', ')
      : // Legacy
        'Not interested';
  }

  return (
    <div className="flex flex-row border-b border-grey-medium bg-white px-2 py-4">
      <div className="relative">
        <Avatar
          name={pro.name}
          badgeProps={
            declinedReasons != null
              ? { iconProps: { name: 'thumb_down' }, color: 'danger' }
              : undefined
          }
        />
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="flex flex-row">
          <Text color="primaryCTA" noWrap onClick={handleOpenProfile} type="subtitle-1">
            {pro.name}
          </Text>
          {pro.stats && _.isNumber(pro.stats.ratingAutomatic) && (
            <div className="flex flex-row items-start">
              <Icon className="mx-1" color="icons.orange" name="star" />
              <Text type="body-2">{_.round(pro.stats.ratingAutomatic, 1)}</Text>
            </div>
          )}
        </div>
        <div>
          {pro.homeDistance != null && (
            <div className="flex flex-row">
              <Text className="mr-2" color="grey.dark" type="body-2">
                Home:
              </Text>
              <Text type="body-2">{pro.homeDistance} mi away</Text>
            </div>
          )}
        </div>
        <div>
          {declinedReasons != null && (
            <div className="flex flex-row">
              <Text
                noWrap
                className="mr-2 overflow-hidden overflow-ellipsis"
                color="danger"
                type="body-2"
              >
                {declinedReasons}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className="justify-between">
        {isSelected ? (
          <Icon color="danger" name="close" onClick={handleSelect} />
        ) : (
          <Icon color="primaryCTA" name="add" onClick={handleSelect} />
        )}
      </div>
    </div>
  );
}
