import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Loader } from '@/components';
import { updateTask } from '../../com/task';
import {
  Address,
  Bool,
  Date,
  Modal,
  Money,
  Search,
  Select,
  Text,
  Textbox,
} from '../../com/ui/form';
import { isDevBuild } from '../../config/const';

const messagePrefix =
  'Congratulations! Your part has been shipped to you.' +
  ' Upon receipt please contact us so we can schedule a follow up appointment to complete your repair.';

class EditShipment extends React.Component {
  static childContextTypes = { t: PropTypes.object };

  state = { events: [], services: [], shipment: {} };

  getChildContext = () => ({ t: this });

  UNSAFE_componentWillReceiveProps(p) {
    const { id } = this.props;
    if (p.isTaskUpdatedByShipmentLabel) {
      const shipment = _.last(U.timeSort(_.get(p.job, 'parts.shippingArray')));
      this.setState({ shipment });
    }
    if (id !== p.id && p.id) {
      this.openModal(p.id);
    }
  }

  UNSAFE_componentWillUpdate(p, s) {
    const { shipment } = this.state;
    const canLoadEvents = shipment.carrier && _.get(shipment, 'track.id');
    const willLoadEvents = s.shipment.carrier && _.get(s.shipment, 'track.id');
    if (!canLoadEvents && willLoadEvents) {
      this.loadEvents(s.shipment);
    }
  }

  onTrackChange = trackingId => {
    const { message } = this.state;
    if (_.startsWith(message, messagePrefix)) {
      this.setState({
        message: trackingId
          ? `${messagePrefix} Your tracking number is ${trackingId}`
          : messagePrefix,
      });
    }
  };

  onVendorChange = (prop, id) => {
    const { spAddress, spType, vendors } = this.props;
    const { shipment } = this.state;
    const vendor = [...vendors].find(v => v.id === id);
    const value = _.pick(vendor, ['id', 'name', 'address', 'type', 'phone']);
    if (id === 'other') {
      if (spType === 'other' && spAddress) {
        value.address = spAddress;
      }
      const pro = vendors.find(v => v.type === 'pro');
      value.phone = _.get(pro, 'phone');
    }
    value.name = _.replace(value.name, ' (preferred)', '');
    this.setState({ shipment: { ...shipment, [prop]: value } });
  };

  emptyShipment = () => {
    const { org } = this.props;
    return {
      parts: [],
      price: { total: T.type(org) === 'b2b' ? 0 : 1000 },
    };
  };

  loadEvents = async shipment => {
    if (shipment.carrier && _.get(shipment, 'track.id')) {
      const r = await U.api('post', 'shipengine', {
        method: 'get',
        path: `tracking?carrier_code=${shipment.carrier}&tracking_number=${shipment.track.id}`,
      });
      const events = _.get(r, 'events', []);
      if (_.isArray(events) && events.length) {
        this.setState({ events });
      }
    }
  };

  loadServices = async account => {
    if (!account) {
      return this.setState({ serviceOptions: [] });
    }
    const request = { method: 'get', path: `carriers/${account}/services` };
    const r = await U.api('post', 'shipengine', request);
    const serviceOptions = _.get(r, 'services', []).map(s => ({ ...s, id: s.service_code }));
    if (_.get(r, 'services')) {
      this.setState({ serviceOptions });
    } else {
      this.setState({ error: "Can't load carrier services" });
    }
  };

  openModal = shippingId => {
    const { job } = this.props;
    const phone = U.trimPhone(job.customer.user.phone);
    if (shippingId === 'add') {
      this.setState({
        phone,
        message: messagePrefix,
        modal: true, // eslint-disable-line react/no-unused-state
        sendMessage: true,
        shipment: this.emptyShipment(),
      });
    } else {
      const shipment = _.merge({}, _.get(job, `parts.shipping.${shippingId}`));
      this.setState({
        phone,
        message: messagePrefix,
        events: [],
        sendMessage: false,
        modal: true, // eslint-disable-line react/no-unused-state
        shipment,
      });
      if (shipment.account) {
        this.loadServices(shipment.account);
      }
    }
    const proIds = _.uniq(_.values(job.visits).map(v => _.get(v, 'assignee.id')));
    U.api('post', 'pros/search', { query: { _id: { $in: proIds } } }, true);
    U.api('post', 'orgs/search', { query: { 'metadata.type': 'part' } }, true);
  };

  printLabel = async () => {
    const { job, printLabel } = this.props;
    const { shipment } = this.state;
    let id = shipment._id;
    if (!_.isEqual(shipment, _.get(job, `parts.shipping.${shipment._id}`))) {
      const job = await this.submit(true);
      if (!id) {
        id = _.get(_.last(U.timeSort(_.get(job, 'parts.shippingArray'))), '_id');
      }
    }
    return printLabel(id);
  };

  remove = async () => {
    const { job, onClose } = this.props;
    const { shipment } = this.state;
    const changes = [{ action: 'remove', path: `parts.shipping.${shipment._id}` }];
    const r = await updateTask(job.id, changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    this.setState({ modal: false }); // eslint-disable-line react/no-unused-state
    onClose();
  };

  submit = async keepModalOpen => {
    const { job, onClose } = this.props;
    const { message, phone, sendMessage, shipment } = this.state;
    if (!shipment._id) {
      if (!_.get(shipment, 'from.id')) {
        return this.setState({ error: 'From required' });
      }
      if (!_.get(shipment, 'from.name')) {
        return this.setState({ error: 'From name required' });
      }
      if (!_.get(shipment, 'from.phone')) {
        return this.setState({ error: 'From phone required' });
      }
      if (!_.get(shipment, 'to.id')) {
        return this.setState({ error: 'To required' });
      }
      if (!_.get(shipment, 'to.name')) {
        return this.setState({ error: 'To name required' });
      }
      if (!_.get(shipment, 'to.phone')) {
        return this.setState({ error: 'To phone required' });
      }
      if (!_.values(shipment.parts).length) {
        return this.setState({ error: 'Shipment parts required' });
      }
      if (_.get(shipment, 'price.total') === undefined) {
        return this.setState({ error: 'Shipment total required' });
      }
    }
    this.setState({ loading: true });
    const changes = [];
    if (shipment._id) {
      changes.push({
        action: 'replace',
        path: `parts.shipping.${shipment._id}`,
        value: shipment,
      });
    } else {
      changes.push({ action: 'add', path: 'parts.shipping', value: shipment });
    }
    const r = await updateTask(job.id, changes);
    this.setState(s => ({ ...s, loading: false }));
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }
    if (!shipment._id) {
      this.setState({ shipment: _.last(U.timeSort(r.parts.shippingArray)) });
    }
    const shouldSendMessage =
      _.get(shipment, 'from.type') === 'nana' &&
      _.get(shipment, 'to.type') === 'customer' &&
      sendMessage;
    if (shouldSendMessage) {
      U.api('post', 'messages', {
        job: job.id,
        sms: { body: message, to: phone },
        type: 'shipment',
      });
    }
    if (!keepModalOpen) {
      this.setState({ modal: false }); // eslint-disable-line react/no-unused-state
      onClose();
    }
    return r;
  };

  render() {
    const { job, onClose, toOptions, vendors } = this.props;
    const { error, events, serviceOptions, shipment } = this.state;
    const isEdit = !!shipment?._id;
    const partOptions = _.map(_.get(job, 'parts.items'), v => ({
      id: v._id,
      name: v.desc,
    }));

    const title = isEdit ? 'Edit Shipment' : 'Add Shipment';
    const sendMessage =
      _.get(shipment, 'from.type') === 'nana' && _.get(shipment, 'to.type') === 'customer';
    const labelErrors = [];
    if (!_.get(shipment, 'account')) {
      labelErrors.push('account');
    }
    if (!_.get(shipment, 'service')) {
      labelErrors.push('service');
    }
    if (!_.get(shipment, 'to.address.geoCoordinates')) {
      labelErrors.push('to address');
    }
    if (!_.get(shipment, 'from.address.geoCoordinates')) {
      labelErrors.push('from address');
    }
    const shipmentAccountId = isDevBuild ? 'se-438810' : 'se-105106';

    return (
      <Modal
        title={title}
        submit={() => this.submit()}
        className="partModal"
        onClose={onClose}
        onRemove={isEdit && this.remove}
      >
        <p className="text-danger">{error}</p>
        <div className="section--title">Shipment Information</div>
        <div className="row">
          <Select id="shipment.carrier" options={T.carrierCodes} className="col-sm-6 col-lg-3" />
          <Text
            id="shipment.track.id"
            label="Tracking Id"
            onChange={this.onTrackChange}
            className="col-sm-6 col-lg-3"
          />
          <Select
            id="shipment.account"
            options={[{ id: shipmentAccountId, name: 'Fedex' }]}
            className="col-sm-6 col-lg-3"
            onChange={this.loadServices}
          />
          <Select id="shipment.service" options={serviceOptions} className="col-sm-6 col-lg-3" />
        </div>
        <div className="row">
          <Select
            id="shipment.from.id"
            label="From"
            className="col"
            options={vendors}
            req
            onChange={id => this.onVendorChange('from', id)}
          />
          <Text id="shipment.from.name" label="From Name" className="col" req />
          <Text id="shipment.from.phone" label="From Phone" className="col" req />
          <Address id="shipment.from.address" label="From Address" className="col" />
        </div>
        <div className="row">
          <Select
            id="shipment.to.id"
            label="To"
            className="col"
            options={toOptions}
            req
            onChange={id => this.onVendorChange('to', id)}
          />
          <Text id="shipment.to.name" label="To Name" className="col" req />
          <Text id="shipment.to.phone" label="To Phone" className="col" req />
          <Address id="shipment.to.address" label="To Address" className="col" />
        </div>
        <div className="row">
          <Search id="shipment.parts" className="col-sm-12" options={partOptions} multi req />
          <Money id="shipment.price.total" className="col-sm-6 col-lg-4" req />
          <Date id="shipment.receiveTime" label="ETA" className="col-sm-6 col-lg-4" req />
          <Address
            id="shipment.billingAddress"
            label="Billing Address"
            className="col-sm-6 col-lg-4"
          />
          <div className="col-sm-6 col-lg-4">
            <label className="d-block">Shipment Label</label>
            {shipment.shipmentLabelPdf ? (
              <a href={shipment.shipmentLabelPdf} target="_blank" rel="noopener noreferrer">
                Download
              </a>
            ) : (
              '-'
            )}
          </div>
          <div className="col-sm-6 col-lg-4">
            <label className="d-block">Save Shipment & Create Label</label>
            {this.state.loading ? (
              <Loader isLoading />
            ) : (
              <div>
                {!labelErrors.length ? (
                  <div className="link-blue pointer" onClick={this.printLabel}>
                    Click here
                  </div>
                ) : (
                  <div className="text-danger">
                    Requires {_.reduce(labelErrors, (a, b) => `${a}, ${b}`)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {sendMessage && (
          <div className="row">
            <div className="col">
              <Bool id="sendMessage" />
              <Text id="phone" />
            </div>
            <Textbox id="message" className="col" />
          </div>
        )}
        {!!events.length && (
          <table className="table-sm table-striped table-hover table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Description</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              {events.map(e => (
                <tr key={e.occurred_at}>
                  <td>{m(e.occurred_at).format('M/D/YY h:mmA')}</td>
                  <td>{e.description}</td>
                  <td>{U.titleCase(e.city_locality)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal>
    );
  }
}

const vendorsPropType = PropTypes.arrayOf(
  PropTypes.exact({
    ...(U.org.propTypeFields || {}),
    address: U.addressPropType,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    type: PropTypes.oneOf(['customer', 'pro', 'nana', 'other', 'vendor']).isRequired,
    preferred: PropTypes.bool.isRequired,
  })
);

EditShipment.propTypes = exact({
  dispatch: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  id: PropTypes.string,
  isTaskUpdatedByShipmentLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  job: T.propType.isRequired,
  onClose: PropTypes.func.isRequired,
  org: U.org.propType,
  printLabel: PropTypes.func.isRequired,
  spAddress: U.addressPropType,
  spType: PropTypes.string,
  toOptions: vendorsPropType.isRequired,
  vendors: vendorsPropType.isRequired,
});

const getShippingAddressesForJob = (proIds, s, job, org, spType) => {
  let shippingAddresses = _.compact([
    ...proIds.map(id => ({
      id: _.get(s.pros[id], 'user.id'),
      name: _.get(s.pros[id], 'user.fullName'),
      phone: _.get(s.pros[id], 'user.phone'),
      address: _.get(s.pros[id], 'user.address'),
      type: 'pro',
    })),
    {
      id: job.customer.user.id,
      name: job.customer.user.fullName,
      phone: job.customer.user.phone,
      address: job.serviceAddress,
      type: 'customer',
    },
    org && { ...org, type: 'vendor' },
    ..._.values(s.orgs)
      .filter(v => _.get(v, 'metadata.type') === 'part')
      .map(v => ({
        ...v,
        type: v.name === 'Nana' ? 'nana' : 'vendor',
      })),
    { id: 'other', name: 'Other', type: 'other' },
  ]).map(v => ({ ...v, preferred: v.type === spType }));
  shippingAddresses = _.uniqBy(shippingAddresses, 'id').filter(v => v.id);
  shippingAddresses.map(saddr => {
    if (saddr.preferred) {
      saddr.name = `${saddr.name} (preferred)`;
    }
    return saddr;
  });
  return shippingAddresses;
};

export default connect(s => {
  const job = s.tasks[global.id()];
  const org = s.orgs[_.get(job, 'customer.org.id')];
  const proIds = _.compact(
    _.uniq(_.values(_.get(job, 'visits')).map(v => _.get(v, 'assignee.id')))
  );
  const shippingPref = _.get(s.pros[_.last(proIds)], 'metadata.shippingPreference');
  const spType = _.get(shippingPref, 'type');
  const spAddress = _.get(shippingPref, 'address');
  const vendors = getShippingAddressesForJob(proIds, s, job, org, spType);
  const toOptions = vendors;
  return { job, org, vendors, spType, spAddress, toOptions };
})(EditShipment);
