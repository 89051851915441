import React, { useCallback, useEffect, useState } from 'react';
import { Activity, Task, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { APIError, Icon, Text } from '@/components';
import { isMTDiagnosis } from './util';

type ActivityWithIndex = Activity & { index: string };

type Props = {
  diagnosisIndex: number;
  questionId: string;
  task: Task;
};

// Displays the information for both tech and MT diagnosis.
// Intended to be used in the Questions component.
export default function DiagnosisActivity({
  diagnosisIndex,
  questionId,
  task,
}: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);
  const [activity, setActivity] = useState<ActivityWithIndex>();
  const [activityError, setActivityError] = useState<JSX.Element | undefined>();

  const saveActivity = useCallback(async () => {
    const activities = await U.api<Array<Activity>>('post', 'activities/search', {
      query: { refId: task.id, type: 'TaskMetadata', title: `||${questionId}||` },
    });
    if (!activities) {
      setActivityError(
        <APIError
          className="mb-10"
          error="Error fetching activity"
          text={<>Unable to pull diagnosis activity</>}
        />
      );
      return;
    }
    if ('errMsg' in activities) {
      setActivityError(
        <APIError
          className="mb-10"
          error={activities.errMsg}
          text={<>Unable to pull diagnosis activity</>}
        />
      );
      return;
    }
    const filteredActivity: ActivityWithIndex[] = activities
      .map(activity => ({
        ...activity,
        index: _.get(activity.title.match(`${questionId}.([0-9])`), 1, '0'),
      }))
      .filter(activity => activity.index);
    const matchingActivity = filteredActivity.find(
      (activity: ActivityWithIndex) => activity.index === diagnosisIndex.toString()
    );
    setActivity(matchingActivity);
  }, [diagnosisIndex, questionId, task.id]);

  useEffect(() => {
    const getActivities = async () => {
      await saveActivity();
    };
    void getActivities();
  }, [saveActivity, task]);

  return (
    <>
      {activityError}
      {activity && (
        <div className="flex space-x-1">
          <Text className="" type="button">
            {_.get(activity, 'byUser.firstName')}
          </Text>
          {isMTDiagnosis(questionId) && <Icon className="text-primaryCTA" name="shield_check" />}
          <Text className="text-grey-dark">
            &bull;&nbsp;{mt(activity.createTime).tz(timezone).format('M/D/YY h:mma (z)')}
          </Text>
        </div>
      )}
    </>
  );
}
