import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import U from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import T from 'prop-types';
import { Icon } from '@/components';
import { Date, Search, Select, Text, Textbox } from '../../com/ui/form';
import Link from '../../com/ui/link';
import Notes from '../../com/ui/notes';
import { loadUsers } from '../../com/user';

const props = [
  'title',
  'desc',
  'owner',
  'status',
  'priority',
  'tags',
  'dueDate',
  'refType',
  'refId',
];

class EditTicket extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    document.title = 'Edit Ticket';
    this.state = {};
    this.isChange = this.isChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.renderViewRef = this.renderViewRef.bind(this);
    this.submit = this.submit.bind(this);
    this.loadJobs = this.loadJobs.bind(this);
    U.api('get', `issues/${global.id()}`, true);
  }

  componentWillMount() {
    if (!_.isEmpty(this.props.ticket)) {
      this.setState(this.props.ticket);
      this.loadJobs(this.props.ticket);
    }
  }

  componentWillReceiveProps(p) {
    if (_.isEmpty(this.props.ticket) && p.ticket) {
      this.setState(p.ticket);
      this.loadJobs(p.ticket);
    }
  }

  async loadJobs(ticket) {
    if (/\d{10} Contact Form/.test(ticket.title)) {
      const number = ticket.title.split(' ')[0];
      const query = { 'customer.user.phone': number };
      const jobs = await U.api('post', 'tasks/search', { query });
      this.setState({ jobs });
    }
  }

  isChange() {
    const p = props.find(p => !_.isEqual(_.get(this.props.ticket, p), this.state[p]));
    return !!p;
  }

  loadUsers() {
    if (!global.supportUsersLoaded) {
      const query = { roles: U.user.Role.CUSTOMERSUPPORT };
      loadUsers({ query });
      global.supportUsersLoaded = true;
    }
  }

  async submit() {
    if (!this.state.title) {
      return this.setState({ error: 'Title required' });
    }
    const body = _.merge({}, this.state);
    if (_.get(body, 'owner.id')) {
      const user = this.props.users.find(u => u.id === _.get(this.state, 'owner.id'));
      if (user) {
        body.owner = user;
      }
    } else {
      body.owner = undefined;
    }
    const changes = props
      .filter(p => !_.isEqual(this.props.ticket[p], body[p]))
      .map(p => ({ action: 'replace', path: p, value: body[p] }));
    const r = await U.api('put', `issues/${global.id()}`, changes);
    if (r.errMsg) {
      return this.setState({ error: r.errMsg });
    }

    this.setState(() => r);
  }

  renderViewRef() {
    if (!this.state.refType || !this.state.refId) {
      return null;
    }
    const refType = U.issue.refTypeOptions.find(rt => rt.id === this.state.refType);
    if (!refType) {
      return null;
    }
    const link = refType.link(this.state.refId);
    return (
      <Link to={link} className="common-Button mr-3">
        View {refType.name}
      </Link>
    );
  }

  render() {
    const priorityOptions = [
      { id: '1', name: '1 (High)' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5 (Low)' },
    ];
    return (
      <div
        id="adminBooking"
        className="container"
        style={{ background: 'transparent', paddingTop: 50 }}
      >
        <Link
          to="/tickets"
          className="common-Button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '120px',
          }}
        >
          <Icon name="arrow_left" />
          &nbsp;&nbsp;&nbsp;Go Back
        </Link>
        <p className="text-danger">{this.state.error}</p>

        <div className="info">
          <div className="card-head">
            <h5 className="title">Edit Ticket</h5>
          </div>
          <div className="card-form">
            <Row>
              <Col style={{ borderRight: '1px solid #e4e4e4' }}>
                <div className="d-flex py-4">
                  {this.renderViewRef()}
                  <div style={{ paddingRight: 16 }}>
                    <label>Created</label>
                    <div>
                      <p>{m(this.state.createTime).format('MMMM D, h:mm A')}</p>
                    </div>
                  </div>
                  <div>
                    <label>By</label>
                    <div>
                      <p>{_.get(this.state, 'creator.name')}</p>
                    </div>
                  </div>
                </div>

                <Text id="title" req />
                <Textbox id="desc" label="Description" />
                <div className="row">
                  <Search
                    id="owner.id"
                    label="Owner"
                    onFocus={this.loadUsers}
                    options={this.props.users}
                    className="col-sm-6"
                  />
                  <Select id="status" options={U.issue.statusOptions} cap className="col-sm-6" />
                  <Select id="priority" className="col-sm-6" options={priorityOptions} />
                  <Search
                    id="tags"
                    label="Tags"
                    multi
                    className="col-sm-6"
                    options={U.issue.tagOptions}
                    cap
                  />
                  <Select id="refType" className="col-sm-6" options={U.issue.refTypeOptions} cap />
                  <Text id="refId" className="col-sm-6" />
                </div>
              </Col>
              <Col style={{ padding: 16 }}>
                <h5 className="title" style={{ textAlign: 'center' }}>
                  Select due date
                </h5>
                <Date id="dueDate" label="" />
              </Col>
            </Row>
            {!!_.values(this.state.jobs).length && (
              <div>
                <h3>Booked Jobs</h3>
                {_.map(this.state.jobs, j => (
                  <div key={j.id}>
                    <a href={`/tasks/${j.id}`} target="_blank" rel="noopener noreferrer">
                      {j.title}
                    </a>
                  </div>
                ))}
              </div>
            )}
            {this.isChange() && (
              <div className="common-Button secondary" onClick={this.submit}>
                Save
              </div>
            )}
          </div>
        </div>
        <Notes
          updateFunc={(id, changes) => U.api('put', `issues/${id}`, changes, true)}
          savePath="notes"
          loadPath={`tickets.${global.id()}.notes`}
        />
      </div>
    );
  }
}

export default connect(s => {
  let ticket = s.issues[global.id()];
  const task = s.tasks[global.id()];
  if (task) {
    ticket = {
      id: task.id,
      title: 'Unpaid Task',
      refType: 'task',
      refId: task.id,
      desc: 'Please resolve',
    };
  }
  let users = _.values(s.users)
    .filter(u => _.includes(u.roles, U.user.Role.CUSTOMERSUPPORT))
    .map(u => ({ id: u.id, name: _.get(u, 'profile.fullName', 'No Name') }));
  users = _.sortBy(users, 'name');
  return { ticket, users };
})(EditTicket);
