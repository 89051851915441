import U, { ApiOption, ErrorResponse, Method } from '@nanaio/util';
import _ from 'lodash';
import { apiOrigin, logApi } from '../config/const';
import { logout } from './user/func';

export default async function api<T>(
  method: Method,
  path: string,
  body: unknown,
  options?: (keyof typeof ApiOption)[]
): Promise<T | ErrorResponse> {
  const state = global.store.getState();
  const r = (await U.apiShared({
    apiOrigin,
    body,
    comment: global.location.pathname,
    log: logApi,
    method,
    options,
    path,
    state,
  })) as unknown;
  const type = _.get(r, 'type') as 'logout' | undefined;
  const response = _.get(r, 'response') as T | ErrorResponse;
  if (
    (response as ErrorResponse)?.errMsg === 'Error: Maintenance' &&
    window.location.pathname !== '/maintenance'
  ) {
    window.location.href = '/maintenance';
  }
  if (type === 'logout') {
    logout({ method, path, body, state });
  }
  return response;
}
