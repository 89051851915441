import React, { useState } from 'react';
import * as Table from '@nanaio/table';
import U from '@nanaio/util';
import { Modal, Text } from '../../core';
import { Input, InputType } from '../../form';

type Props = {
  onClose: () => void;
  query: Table.Query;
  setQuery: (query: Table.Query) => void;
  updateUrl: (query: Table.Query) => void;
};

type Response = { code?: number; errMsg?: string; errmsg?: string } | Table.Query;

export default function Save({ onClose, query, setQuery, updateUrl }: Props): JSX.Element {
  const [error, setError] = useState<string>();

  const onSave = async () => {
    const DUPLICATE_KEY_ERROR_CODE = 11000;
    const response = (await U.api('post', 'query', query)) as Response;
    if ('code' in response && response.code === DUPLICATE_KEY_ERROR_CODE) {
      return setError('Name taken');
    }
    if ('errMsg' in response || 'errmsg' in response) {
      return setError(response.errmsg || response.errMsg);
    }
    setQuery({ ...query, id: (response as Table.Query).id });
    updateUrl(response as Table.Query);
    onClose();
    return undefined;
  };

  return (
    <Modal isOpen onClose={onClose} onSave={onSave}>
      <Modal.Header title="Save As" />
      <Modal.Body className="p-4">
        <Text color="danger">{error}</Text>
        <Input id="name" debounceDelay={1000} required />
        <Input id="description" debounceDelay={1000} required />
        <Input id="group" debounceDelay={1000} required />
        <Input id="isPublic" default required type={InputType.BOOL} />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
