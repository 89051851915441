import React from 'react';

const data = [
  {
    name: 'Forbes',
    img: '/img/press/Forbes.svg',
    url: 'https://www.forbes.com/sites/amitchowdhry/2018/01/30/how-nana-io-is-improving-the-gig-economy-by-providing-appliance-repair-training/?sh=351ffb1f5d88',
    width: 90,
  },
  {
    name: 'Good Morning America',
    img: '/img/press/GMA.svg',
    url: 'https://twitter.com/DavidZamir/status/1546513123091222528?s=20&t=AoJ0Y-GA2fv19cRqKCbARg',
    width: 61,
  },
  {
    name: 'Inc',
    img: '/img/press/INC.svg',
    url: 'https://www.inc.com/christina-desmarais/heres-how-a-5-minute-freezing-cold-shower-can-help.html',
    width: 60,
  },
  {
    name: 'WSJ',
    img: '/img/press/WSJ.svg',
    url: 'https://urldefense.com/v3/__https:/www.wsj.com/articles/jobless-workers-fuel-surge-in-demand-for-startups-offering-retraining-11595930400__;!!Gci76Wc7rXg!piRTeXp1AC5L9Tjj2WgtQTaUsCTUkzUauzAjGaKr5Z1W0m4u24Noe15pKSro9Zc$',
    width: 41,
  },
  {
    name: 'TechCrunch',
    img: '/img/press/TechCrunch.svg',
    url: 'https://urldefense.com/v3/__https:/techcrunch.com/2020/11/12/nana-nabs-6m-for-an-online-academy-and-marketplace-dedicated-to-appliance-repair/__;!!Gci76Wc7rXg!piRTeXp1AC5L9Tjj2WgtQTaUsCTUkzUauzAjGaKr5Z1W0m4u24Noe15ps2BYpR0$',
    width: 46,
  },
  {
    name: 'NYT',
    img: '/img/press/NYT.svg',
    url: 'https://twitter.com/DavidZamir/status/1518985869847195650?s=20&t=9zKOTVAF8lWznLNhXWIr9w',
    width: 187,
  },
  {
    name: 'KGO',
    img: '/img/press/ABC7.svg',
    url: 'https://twitter.com/abc7david/status/1501231159761457154?s=20&t=9zKOTVAF8lWznLNhXWIr9w',
    width: 44,
  },
];

export default function PressTabs(): JSX.Element {
  return (
    <div className="mt-16 block h-[150px] w-full">
      <div className="flex flex-wrap">
        {data.map((t, n) => (
          <a href={t.url} key={n} target="_blank" rel="noreferrer">
            <img
              className="mb-[16px] mr-[32px]"
              alt={t.name}
              src={t.img}
              height={32}
              width={t.width}
            />
          </a>
        ))}
      </div>
    </div>
  );
}
