import React from 'react';
import { renderToString } from 'react-dom/server';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import mt from 'moment-timezone';
import { statusIcons } from './config';

const CityPopover = ({ city, pros }) => (
  <div>
    <div style={{ fontWeight: 600 }}>
      ({pros.length}) {city}
    </div>
    {_.sortBy(pros, 'name').map(p => (
      <div key={p.id}>
        <a
          href={`/pros/${p.id}/availability`}
          target="_blank"
          rel="noopener noreferrer"
          className="map-popover"
        >
          {p.name}
        </a>
      </div>
    ))}
  </div>
);

const HomePopover = ({ group }) => (
  <a
    href={`/pros/${_.get(group, 'id')}`}
    target="_blank"
    rel="noopener noreferrer"
    className="map-popover"
  >
    <b>{`${_.get(group, 'user.fullName')}'s Home`}</b>
    <label>{_.get(group, 'user.address.formattedAddress')}</label>
  </a>
);

const LocationPopover = ({ group }) => {
  const time = _.get(group, 'metadata.location.timestamp');
  return (
    <a
      href={`/pros/${_.get(group, 'id')}`}
      target="_blank"
      rel="noopener noreferrer"
      className="map-popover"
    >
      <b>{_.get(group, 'user.fullName')}&apos;s Location</b>
      <label>
        Last updated: {m(time).format('h:mmA')} ({m(time).fromNow()})
      </label>
    </a>
  );
};

// convert hhmm to today at hh:mm
const timeConvert = timeIn => {
  const hour = Number(timeIn.slice(0, 2));
  const minute = Number(timeIn.slice(2, 4));
  return m().hour(hour).minute(minute).format('h:mm A');
};

const PartDistributorPopover = ({ name, address, phone, openHours }) => {
  const hours = openHours.map((dayHours, dayIndex) => {
    const day = m().day(dayIndex).format('ddd');
    if (!dayHours.from || !dayHours.to) {
      return `${day}: Closed`;
    }

    const open = timeConvert(dayHours.from);
    const close = timeConvert(dayHours.to);

    return `${day}: ${open} - ${close}`;
  });

  return (
    <div className="map-popover">
      <div style={{ fontWeight: 600 }}>{name}</div>
      <div>
        {address.streetNumber} {address.route}
      </div>
      <div>
        {address.locality}, {address.region} {address.postalCode}
      </div>
      <div>{phone}</div>
      {hours.length > 0 && (
        <>
          <div style={{ fontWeight: 600 }}>Hours</div>
          {hours.map(dayHours => (
            <div key={dayHours}>{dayHours}</div>
          ))}
        </>
      )}
    </div>
  );
};

const VisitPopover = ({ group, item }) => {
  if (!item) {
    return null;
  }
  const visitDate = m(item.startTime).valueOf();
  const timezone = item.job.serviceAddress.gTimezone || 'America/Los_Angeles';
  const today = mt().tz(timezone).startOf('day').valueOf();
  const isToday = mt(visitDate).tz(timezone).startOf('day').isSameOrAfter(today);
  const visit = _.values(item.job.visits).find(
    v => m(_.get(v, 'preferredTimeSlot.startTime')).valueOf() === item.startTime
  );
  const ats = T.availTSlots(visit).slice(1);

  return (
    <div className="map-popover">
      <div className="d-flex">
        {!item.job.waitingOnSchedule && (
          <b className="mr-auto">
            {mt(item.startTime).tz(timezone).format('h A')} -{' '}
            {mt(item.endTime).tz(timezone).format('h A')}{' '}
            {isToday ? `, Today` : `, on ${mt(item.startTime).tz(timezone).format('ddd, MM/DD')}`}
          </b>
        )}
        <div className="badge-default">{_.startCase(item.job.status)}</div>
      </div>
      <a href={`/tasks/${_.get(item, 'job.id')}`} target="_blank" rel="noopener noreferrer">
        <b>
          {item.job.title} {_.get(group, 'isPro') && `(${group.title})`}
        </b>
      </a>
      <label>
        <a
          href={`/users/${_.get(item, 'job.customer.user.id')}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          for {item.job.customer.user.fullName}
        </a>{' '}
        - {item.job.serviceAddress.formattedAddress}
      </label>
      {!!ats.length && (
        <div>
          <div>Alternate Timeslots</div>
          {ats.map((v, n) => (
            <div key={n}>
              {m(v.startTime).format('M/D hA')}-{m(v.endTime).format('hA')}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const getMarkers = ({
  date,
  groupMap,
  groups,
  hideMapType,
  itemId,
  items,
  map,
  me,
  partVendors,
  proFilter,
  serviceFilter,
  vendorFilter,
  view,
}) => {
  if (view !== 'map') {
    return [];
  }
  const { mapElement, infoWindow, markers } = map;

  let newMarkers = [];
  const today = m().startOf('day').valueOf();
  const counties = _.get(me, 'metadata.counties', []);

  groups
    .filter(g => {
      if (counties.length && !_.includes(counties, _.get(g, 'user.address.county'))) {
        return;
      }
      if (proFilter.length) {
        return proFilter.includes(g.id);
      }
      return g.isPro && (g.hasJobs || g.status === 'active');
    })
    .map(group => {
      if (group.home && (!hideMapType.home || proFilter.length)) {
        newMarkers.push({
          icon: statusIcons.home,
          position: group.home,
          type: 'home',
          map: map.mapElement,
          group,
        });
      }
      if (
        group.location &&
        group.location.timestamp > today &&
        (!hideMapType.location || proFilter.length)
      ) {
        newMarkers.push({
          icon: statusIcons.location,
          position: group.location,
          type: 'location',
          map: map.mapElement,
          group,
          zIndex: 99999999,
        });
      }
    });

  const newItems = items.map(item => {
    const type = item.visitStatus === 'cancelled' ? 'cancelled' : item.type;
    item.mapType = type;
    if (type === 'pending' && item.isAssigned) {
      item.mapType = 'unconfirmed';
    } else if (type === 'visitCompleted') {
      item.mapType = 'claimed';
    }
    return item;
  });
  newItems
    .filter(item => item.date === date && item.position)
    .filter(item => {
      if (itemId) {
        return itemId === item.id;
      }
      if (item.mapType === 'suggested' && item.job.status !== 'pendingConfirmation') {
        return;
      }
      if (item.group === 'waitingOnSchedule' && !proFilter.includes('waitingOnSchedule')) {
        return false;
      }
      if (
        vendorFilter.length &&
        !vendorFilter.find(id => id === _.get(item, 'job.customer.org.id'))
      ) {
        return false;
      }
      if (proFilter.length) {
        if (proFilter.includes(item.group)) {
          return true;
        }
        return (
          item.mapType === 'pending' &&
          proFilter.some(proId => _.get(item, `job.metadata.oppNotif.pros.${proId}.notifyTime`))
        );
      }
      if (hideMapType[item.mapType]) {
        return;
      }
      if (serviceFilter.length && !serviceFilter.includes(item.job.serviceCatalogs[0].id)) {
        return false;
      }
      return true;
    })
    .forEach(item => {
      const group = groupMap[item.group];
      const icon = statusIcons[item.mapType];
      const marker = {
        position: item.position,
        type: 'visit',
        map: map.mapElement,
        group,
        item,
        icon,
      };
      newMarkers.push(marker);
    });

  if (!hideMapType.parts && !proFilter.length) {
    // standardize open hours
    for (const vendor of partVendors) {
      for (const location of vendor.locations) {
        let openHours = vendor.defaultOpenHours.length > 0 ? vendor.defaultOpenHours : [];
        openHours = location.openHours.length > 0 ? location.openHours : openHours;
        location.openHours = openHours;
      }
    }

    // flatten partVendors array to show all locations on map
    const partVendorLocations = _.flatten(partVendors.map(v => v.locations));

    // filter out locations that are not warehouses or pickup locations
    const warehouseAndPickupLocations = partVendorLocations.filter(location =>
      location.services.includes(U.partVendor.Service.PICKUP)
    );

    const uniqueVendorLocations = _.uniqBy(warehouseAndPickupLocations, 'address.formattedAddress');

    uniqueVendorLocations
      .filter(v => {
        if (counties.length) {
          return _.includes(counties, v.county);
        }
        return true;
      })
      .map(d =>
        newMarkers.push({
          partDistributor: d,
          icon: statusIcons.parts,
          position: d.address.geoCoordinates,
          type: 'partDistributor',
          map: map.mapElement,
        })
      );
  }

  markers.map(m => m.setMap(null));
  newMarkers = newMarkers.map(m => {
    m = new global.google.maps.Marker(m);
    if (_.get(m, 'item.id', '') === itemId) {
      setTimeout(() => {
        infoWindow.setContent(renderToString(<VisitPopover {...m} />));
        if (m) {
          infoWindow.open(mapElement, m);
        }
      }, 1000);
    }
    if (m.type === 'city') {
      m.addListener('click', () => {
        const content = <CityPopover {...m} />;
        infoWindow.setContent(renderToString(content));
        if (m) {
          infoWindow.open(mapElement, m);
        }
      });
    } else {
      m.addListener('mouseover', () => {
        let content = '';
        if (m.type === 'visit') {
          content = <VisitPopover {...m} />;
        } else if (m.type === 'home') {
          content = <HomePopover {...m} />;
        } else if (m.type === 'location') {
          content = <LocationPopover {...m} />;
        } else if (m.type === 'partDistributor') {
          content = <PartDistributorPopover {...m.partDistributor} />;
        }
        infoWindow.setContent(renderToString(content));
        if (m) {
          infoWindow.open(mapElement, m);
        }
      });
    }
    return m;
  });
  return newMarkers;
};
