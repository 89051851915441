/* eslint-disable array-callback-return */
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { loggedInEvent } from '@/com/analytics';
import { Badge, Icon } from '@/components';
import { Address, Text } from '../../com/ui/form';
import { loadUser, updateUser } from '../../com/user';
import { emailRe, stripeClientId } from '../../config/const';

const props = [
  'profile.firstName',
  'profile.lastName',
  'profile.phone',
  'profile.altPhone',
  'profile.altEmail',
  'email',
  'profile.address',
];
// eslint-disable-next-line camelcase
const cRole_sRole = {
  admin: U.user.Role.ADMIN,
  support: U.user.Role.CUSTOMERSUPPORT,
  pro: U.user.Role.SERVICEPROVIDER,
  tester: U.user.Role.TESTER,
  user: U.user.Role.USER,
};

class Profile extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { user: p.user };
    this.save = this.save.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.getEmailChanges = this.getEmailChanges.bind(this);
    this.getProfileChanges = this.getProfileChanges.bind(this);
    this.getOrgsChanges = this.getOrgsChanges.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.viewPayments = this.viewPayments.bind(this);
  }

  async componentDidMount() {
    if (!this.props.blockLoad) {
      await loadUser(_.get(this.props.user, 'id') || this.props.me.userId);
    }
    const bankCode = _.get(/code=([a-zA-Z_0-9]+)/.exec(global.location.search), '[1]');
    if (bankCode) {
      const r = await U.api('post', 'serviceproviders/me/payment/account', { authCode: bankCode });
      if (r.errMsg) {
        this.setState({ error: r.errMsg });
      }
      this.props.dispatch(push('/profile'));
    }
  }

  componentWillReceiveProps(p) {
    if (!_.isEqual(this.props.user, p.user)) {
      this.setState({ user: p.user });
    }
  }

  getEmailChanges() {
    if (this.props.user.email !== this.state.email) {
      return [
        {
          action: 'replace',
          path: 'email',
          value: (this.state.email || '').toLowerCase(),
        },
      ];
    }
  }

  getProfileChanges() {
    const before = this.props.user.profile;
    const after = {
      ..._.mapValues(this.state.profile, _.trim),
      address: _.get(this.state.profile, 'address'),
      billingAddress: _.get(this.state.profile, 'billingAddress'),
    };
    if (!_.isEqual(before, after)) {
      return [
        {
          action: 'replace',
          path: 'profile',
          value: {
            ...after,
            fullName: `${after.firstName} ${after.lastName}`,
          },
        },
      ];
    }
  }

  getOrgsChanges() {
    const before = _.values(this.props.user.orgs);
    const after = _.values(this.state.orgs);
    if (!_.isEqual(before, after)) {
      return [
        ...before
          .filter(id => !after.includes(id))
          .map(id => ({
            action: 'remove',
            path: 'orgs',
            value: { id },
          })),
        ...after
          .filter(id => !before.includes(id))
          .map(id => ({
            action: 'add',
            path: 'orgs',
            value: { id },
          })),
      ];
    }
  }

  getErrors() {
    const paths = ['email', 'profile.firstName', 'profile.lastName', 'profile.phone'];
    const errors = paths
      .map(p => {
        if (_.isEmpty(_.get(this.state, p))) {
          return `${_.startCase(_.last(p.split('.')))} required`;
        }
      })
      .filter(v => v);
    if (!emailRe.test(this.state.email.toLowerCase())) {
      errors.push('Invalid email format');
    }
    return errors;
  }

  async save() {
    const changes = props
      .filter(p => !_.isEqual(_.get(this.state.user, p), _.get(this.props.user, p)))
      .map(p => {
        let value = _.get(this.state.user, p);
        if (p === 'roles') {
          value = value.map(v => cRole_sRole[v]);
        }
        return {
          action: 'replace',
          path: p,
          value,
        };
      });
    if (!changes.length) {
      return;
    }
    if (changes.find(c => c.path.endsWith('Name'))) {
      changes.push({
        action: 'replace',
        path: 'profile.fullName',
        value: `${this.state.user.profile.firstName} ${this.state.user.profile.lastName}`,
      });
    }
    const r = await updateUser(this.props.user.id, changes);
    if (r.errCode === 'u100') {
      return this.setState({ error: r.errMsg });
    }
    if (this.props.onChange) {
      this.props.onChange(r);
    }
    loggedInEvent().track('v1_userProfileUpdated');
    return this.setState({ error: false });
  }

  async updateBank() {
    const r = await U.api('put', 'serviceproviders/me/payment/account');
    global.location = r;
  }

  isChanged = () => {
    return !!props.find(p => !_.isEqual(_.get(this.state.user, p), _.get(this.props.user, p)));
  };

  handleChange = () => {
    if (this.isChanged()) {
      this.save();
    }
  };

  async viewPayments() {
    const r = await U.api('get', `pros/stripe-login/${this.props.me.proId}`);
    global.window.open(r.url, '_blank');
  }

  render() {
    const t = this;
    const p = t.props;
    const showAddBank = p.roles.serviceprovider && global.location.pathname === '/profile';
    const isNameEditable = !p.roles.serviceprovider || p.roles.customersupport;
    let bankLink = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${global.location.href}&client_id=${stripeClientId}&state=${t.props.me.proId}`;
    bankLink += '&stripe_user[business_type]=individual';
    const hasBank = _.get(t.props.pro, 'payment.account');
    return (
      <section className="user-layout-container">
        <section className="user-layout-header hidden-md-down">
          <div className="d-flex justify-content-between container">
            <p className="flex-1">Edit Profile</p>
            {showAddBank && !hasBank && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={bankLink}
                className="button-light card-shadow mr-2"
              >
                <p>Link Bank Account</p>
              </a>
            )}
            {showAddBank && hasBank && (
              <div className="button-light card-shadow mr-2" onClick={this.updateBank}>
                <p>Update Bank Account</p>
              </div>
            )}
            {hasBank && (
              <div className="button-light card-shadow mr-2" onClick={this.viewPayments}>
                <p>View Payments</p>
              </div>
            )}
            <div
              className={t.isChanged() ? `button-dark` : `button-dark disabled`}
              onClick={this.handleChange}
            >
              Save Changes
            </div>
          </div>
        </section>
        <section className="user-layout-box">
          <div className="container">
            <div className="user-layout-right">
              <h1 className="text-center">My Profile</h1>
              <br />
              {hasBank && <div className="alter alert-success mb-3">Bank Account Linked</div>}

              {_.values(t.state.errors).map((e, n) => (
                <div key={n} className="text-danger">
                  {e}
                </div>
              ))}
              <p className="text-danger">{this.state.error}</p>
              <div className="row">
                <Text id="user.profile.firstName" className="col-sm-6" disabled={!isNameEditable} />
                <Text id="user.profile.lastName" className="col-sm-6" disabled={!isNameEditable} />
                <Text id="user.profile.phone" className="col-sm-6" />
                <Text id="user.profile.altPhone" className="col-sm-6" />
                <Text id="user.email" className="col-sm-6" />
                <Text id="user.profile.altEmail" className="col-sm-6" />
                <Address id="user.profile.address" className="col-sm-6" />
                <CopyToClipboard className="col-sm-6 form-group" text={this.props.referralCode}>
                  <div>
                    <label>Share Referral Code with a Friend</label>
                    <div className="flex flex-row items-center">
                      {this.props.referralCode}{' '}
                      <Icon name="content_copy" className="link-blue ml-2" />
                    </div>
                  </div>
                </CopyToClipboard>
                <Text id="user.profile.address.arrivalInstructions" className="col-sm-6" />
              </div>
              <section className="hidden-md-up">
                <br />
                <div
                  className={
                    t.isChanged() ? `button-dark w-100 mb-2` : `button-dark w-100 disabled mb-2`
                  }
                  onClick={this.handleChange}
                >
                  Save Changes
                </div>

                {showAddBank && !hasBank && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={bankLink}
                    className="w-100 button-light card-shadow mb-2"
                  >
                    <p className="weight-600">Link Bank Account</p>
                  </a>
                )}
                {showAddBank && hasBank && (
                  <div className="w-100 button-light card-shadow mb-2" onClick={t.updateBank}>
                    <p className="weight-600">Update Bank Account</p>
                  </div>
                )}
              </section>
              <br />
              <br />
              {_.get(p.user, 'payment.stripe.cards', []).map(card => (
                <div className="box-select mb-2" key={card.id} style={{ cursor: 'pointer' }}>
                  <img
                    src={`/img/cards/${card.brand.toLowerCase()}.png`}
                    height="20"
                    className="mr-3"
                    alt=""
                  />
                  <p className="weight-600 flex-1">
                    {card.brand} &bull;&bull;&bull;&bull; {card.last4}
                  </p>
                  <Badge color="grey-dark">
                    {card.exp_month}/{card.exp_year}
                  </Badge>
                </div>
              ))}
              {_.get(p.user, 'payment.stripe.cards', []).length === 0 && (
                <div className="card--block card-block--border card-shadow px-4 py-4 text-center">
                  <br />
                  <img src="/img/pay-icon.svg" height="50px" alt="" />
                  <br />
                  <br />
                  <h3 className="weight-600">No Active Cards</h3>
                  <p className="text-muted">
                    This account does not have any payment methods added.
                  </p>
                  <br />
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default connect((s, p) => {
  const user = _.merge({}, s.users[p.userId || s.me.userId]);
  const orgOptions = _.sortBy(_.isEmpty(s.orgs) ? _.values(user.orgs) : _.values(s.orgs), 'name');
  user.orgs = _.map(user.orgs, o => o.id);
  const roles = U.user.roles(s);
  return {
    roles,
    referralCode: _.get(user, 'shortId'),
    me: s.me,
    pro: s.pros[global.id() || s.me.proId],
    orgOptions,
    user,
  };
})(Profile);
