import React from 'react';
import { Slot as VisitSlot } from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Text } from '@/components';
import { useLegacySelector } from '@/hooks';
import { useGetFeatures } from '@/hooks/api/features';

type Props = {
  onClick: () => void;
  isActive: boolean;
  isDisabled: boolean;
  slotStart: mt.Moment;
};

export default function Slot({ onClick, isActive, isDisabled, slotStart }: Props): JSX.Element {
  // During booking flow we don't have a taskId or pro or workOrder but this component
  // is used in CX portal where we do. So we need to handle both cases.
  const tasks = useLegacySelector(state => state.tasks);
  const taskId = global.id();
  const task = taskId ? tasks[taskId] : null;
  const workOrders = useLegacySelector(state => state.workorders);
  const workOrder = task && workOrders[task.metadata.workOrderId];
  const lastVisit = workOrder && W.lastVisit(workOrder);
  const proId = workOrder && W.proId(workOrder);
  const pro = lastVisit && lastVisit.pros.find(pro => pro.id === proId);
  let proAvailabilityArray: VisitSlot[] = [];
  if (pro) {
    proAvailabilityArray = pro.availability || [];
  }
  const proAvailability = _.keyBy(proAvailabilityArray, slot => mt(slot.start).valueOf());
  const proAvailable = !!proAvailability[slotStart.valueOf()];

  const featureData = useGetFeatures();
  const squareTrade2HourSlots = (featureData.data?.squareTrade2HourSlots as boolean) || false;

  const slotEnd = slotStart.clone().add(4, 'h');
  let startName, endName;
  if (squareTrade2HourSlots && task) {
    startName = T.getTimeSlotDisplay(task, 'start', slotStart).format('ha');
    endName = T.getTimeSlotDisplay(task, 'end', slotEnd).format('ha');
  } else {
    startName = slotStart.format('ha');
    endName = slotEnd.format('ha');
  }

  const label = isDisabled ? ' - ' : `${startName} - ${endName}`;

  return (
    <div
      className={classNames(
        'align-center ease m-2 flex h-14 w-[116px] flex-col justify-center rounded transition',
        {
          'bg-[#041662] text-white': isActive,
          'bg-[#eff2f5] text-[#041662] hover:bg-[#d0d9e1]': !isActive,
          'cursor-pointer': !isDisabled,
          'cursor-default bg-white hover:bg-white': isDisabled,
        }
      )}
      onClick={isDisabled ? undefined : onClick}
    >
      <Text color={isActive ? 'white' : '#041662'} type="button">
        {label}
      </Text>
      {proAvailable && !isDisabled && (
        <Text color="success" type="button">
          Tech Available
        </Text>
      )}
    </div>
  );
}
