import React from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import { Button, Icon } from '../../core';
import { Input, InputType } from '../../form';
import getMoveOptions from './getMoveOptions';
import { CompassDirection, Move } from './types';
import {
  getDirectionIndex,
  getModuleOptions,
  getVectorAndChildIndexes,
  getVectorOperationOptions,
} from './util';

const GREY_VERTICAL_LINE_HEIGHT = 77;
const WARNING_TEXT_HEIGHT = 20;

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  directionToVectors: Record<string, Table.Vector[]>;
  index: number;
  isLoading: boolean;
  rows: Table.Row[];
  onMove: (props: { action: Move; compassDirection?: CompassDirection }) => void;
  openSettings: () => void;
  query: Table.Query;
  vector: Table.Vector;
  warning?: string;
};

export default function Vectors({
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  directionToVectors,
  index,
  isLoading,
  rows,
  onMove,
  openSettings,
  query,
  vector,
  warning,
}: Props): JSX.Element {
  const { depth, direction, moduleId } = vector;
  const directionIndex = getDirectionIndex({ direction, directionToVectors, index });
  const moduleOptions = getModuleOptions({
    databaseIdToTableIdToColumnIdToColumn,
    directionToVectors,
    index,
    query,
    vector,
  });
  const vectors = directionToVectors[direction];
  const vectorAndChildIndexes = getVectorAndChildIndexes({ index: directionIndex, vectors });
  const column = moduleId
    ? Table.getModuleInAnalytics({
        columnKey: moduleId,
        databaseIdToTableIdToColumnIdToColumn,
        query,
      })
    : undefined;
  const isDimension = Table.vectorIsDimension({ column, query, vector });
  const showButtons = column || Table.getIsFormula(vector) || Table.vectorIsVirtual(vector);

  // move options
  const moveOptions = getMoveOptions({
    column,
    databaseIdToTableIdToColumnIdToColumn,
    direction,
    directionToVectors,
    index: directionIndex,
    query,
    vectorAndChildIndexes,
  }).map(({ action, compassDirection, icon, tooltip }) => ({
    id: _.compact([action, compassDirection]).join('-'),
    name: (
      <div className="flex">
        {action === Move.ADD && <Icon name="add" />}
        {action === Move.REMOVE && <Icon name="remove" />}
        {icon && <Icon name={icon} />}
      </div>
    ),
    onClick: () => onMove({ action, compassDirection }),
    tooltip,
  }));

  // vertical line rows
  let verticalLineRows = 0;
  for (let j = 0; j < vectorAndChildIndexes.length; j++) {
    if (vectors[directionIndex + j].depth === depth + 1) {
      verticalLineRows = j;
    }
  }

  return (
    <div
      className="relative mb-4 flex items-end"
      key={_.compact([moduleId, direction]).join('-')}
      style={{ marginLeft: 16 * depth }}
    >
      <div className="flex overflow-x-auto">
        <div
          className="absolute bg-grey-dark"
          style={{
            width: 1,
            height:
              (GREY_VERTICAL_LINE_HEIGHT + (warning ? WARNING_TEXT_HEIGHT : 0)) * verticalLineRows,
            top: 39,
            left: 0,
          }}
        />
        {!!depth && (
          <div
            className="absolute bg-grey-dark"
            style={{ width: 16, height: 1, top: 40, left: -16 }}
          />
        )}
        {vector.name && (
          <Input
            id={`analytics.vectors.${index}.name`}
            className="mb-0"
            debounceWait={1000}
            disabled={isLoading}
            label="Name"
            width={150}
          />
        )}
        <Input
          id={`analytics.vectors.${index}.moduleId`}
          className={vector.name ? 'mb-0 ml-4' : 'mb-0'}
          disabled={isLoading}
          error={warning !== 'Select a module' ? warning : undefined}
          isClearable={false}
          isMatch={
            Table.getIsFormula(vector)
              ? ({ option }) => (option as Table.Column).id === vector.id
              : undefined
          }
          label="Module"
          multilevel
          options={moduleOptions as Option[]}
          sort
          style={{ minWidth: 200 }}
          type={InputType.SEARCH}
        />
        {isDimension && column?.type === Table.ColumnType.TIME && !vector.intervalLength && (
          <Input
            id={`analytics.vectors.${index}.duration`}
            capitalize
            className="mb-0 ml-4"
            disabled={isLoading}
            label="Duration"
            options={Table.durationOptions}
            width={150}
          />
        )}
        {vector.filters
          .filter(filter =>
            databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][
              filter.moduleId
            ]?.filterIsValid(filter.value)
          )
          .map(filter => (
            <div onClick={openSettings} key={filter.moduleId}>
              <Input
                className="pointer-events-none mb-0 ml-4"
                label={`${
                  databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][
                    filter.moduleId
                  ].name
                } Filter`}
                value={databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][
                  filter.moduleId
                ].filterText({
                  column:
                    databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][
                      filter.moduleId
                    ],
                  rows,
                  optionsMap:
                    databaseIdToTableIdToColumnKeyToOptionIdToOption[query.databaseId]?.[
                      query.table
                    ]?.[filter.moduleId],
                  search: filter.value,
                })}
              />
            </div>
          ))}
        {vector.intervalLength && (
          <Input
            id={`analytics.vectors.${index}.intervalLength`}
            className="mb-0 ml-4"
            label="Interval Length"
            tooltip="Generate a column/row for values in each interval. Ex 0-100, 100-200, 200-300."
            type={InputType.NUMBER}
          />
        )}
        {Boolean(vector.operations.length) && (
          <Input
            id={`analytics.vectors.${index}.operations`}
            className="mb-0 ml-4"
            disabled={isLoading}
            label="Operations"
            multiple
            options={getVectorOperationOptions({
              databaseIdToTableIdToColumnIdToColumn,
              query,
              vector,
            })}
            width={230}
          />
        )}
        {vector.operations.includes(Table.Operation.PERCENTILE) && (
          <Input
            id={`analytics.vectors.${index}.percentile`}
            className="mb-0 ml-4"
            disabled={isLoading}
            label="Percentile"
            type={InputType.PERCENT}
            width={75}
          />
        )}
        {!isDimension && vector.timeModuleId && (
          <Input
            id={`analytics.vectors.${index}.timeModuleId`}
            className="mb-0 ml-4"
            disabled={isLoading}
            isClearable
            isMatch={
              Table.getIsFormula(vector)
                ? ({ option }) => (option as Table.Column).id === vector.id
                : undefined
            }
            label="Time Module"
            multilevel
            options={
              moduleOptions.filter(option => option.type === Table.ColumnType.TIME) as Option[]
            }
            sort
            style={{ minWidth: 200 }}
            tooltip="Base this metric off a different time module than the rest of the report"
            type={InputType.SEARCH}
          />
        )}
      </div>
      <Button
        className="ml-4"
        onClick={showButtons ? openSettings : undefined}
        options={moveOptions}
        size="medium"
        variant="secondary"
      >
        Settings
      </Button>
    </div>
  );
}
