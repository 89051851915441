import U from '@nanaio/util';
import _ from 'lodash';
import { clickBook, goals, pageView, trackConversion } from './marketing';

export const loadTasks = async (query = {}) => {
  const { me } = global.s();
  if (U.user.isViewAsUser(me)) {
    if (!query.query) {
      query.query = { 'customer.user.id': me.userId };
    } else {
      query.query['customer.user.id'] = me.userId;
    }
  }
  const r = await U.api('post', 'tasks/search', query);
  if (!r || r.errMsg) {
    return;
  }
  const tasks = U.toMap(r);
  U.redux.merge({ tasks });
};

export const sendRecallEmail = email => {
  const request = {
    to: email,
    subject: 'What is a recall appointment',
    body:
      'What is a recall appointment? ' +
      'Prior part that was installed and has failed from a repair conducted within 1yr from repair date. ' +
      'If the original part was not the failure a new service call fee of $90 applies. ' +
      'Labor only stands for 90 days, any repair after 90 days is accessed a new labor charge. ' +
      'We will not service a call after 3 visits (diagnostic, repair, failed repair correction). ' +
      'If the repair fails on the third visit the pro needs to remove the original parts installed and contact the office for a refund. ' +
      'Repairs with no part installed is considered a service and adjustment and does not qualify as a recall.',
  };
  U.api('post', 'messages', { email: request, type: 'recallInfo' });
};

export const postJob = async ({
  address,
  customReferredBy,
  customer,
  isSupport,
  landlord,
  metadata,
  notify,
  payment,
  prevMaintenance,
  priceOptionId,
  promo,
  promos,
  recall,
  referralCode,
  referredBy,
  serviceCatalogs,
  serviceId,
  source,
  userId,
  vendor,
  visit = {},
}) => {
  serviceId = serviceId || _.get(serviceCatalogs, '0.id');
  const orgId = _.get(vendor, 'id') || _.get(customer, 'org.id');
  trackConversion();
  pageView(isSupport ? '/book - booked by support' : '/book - booked by customer');
  const taskQuestions = {};
  _.mapValues(_.get(metadata, 'taskQuestions'), (value, key) => {
    if (Array.isArray(value)) {
      value.map(label => _.set(taskQuestions, `${key}.${label}`, true));
    } else {
      taskQuestions[key] = value;
    }
  });
  const getPromos = () => {
    if (promo) {
      return { [promo]: {} };
    }
    if (promos) {
      const out = {};
      promos.forEach(p => {
        out[p] = {};
      });
      return out;
    }
    return {};
  };
  const task = {
    customer: { userId },
    metadata: {
      priceOptionId,
      prevMaintenance,
      source,
      taskQuestions,
      workOrderNumber: _.get(vendor, 'workOrderNumber'),
      landlord,
    },
    recall,
    notify,
    payment,
    promos: getPromos(),
    serviceAddress: { ...address, postalCode: _.trim(address.postalCode) },
    serviceCatalogIds: [serviceId],
  };
  if (!_.isEmpty(visit)) {
    task.visits = { 0: visit };
  }
  if (referralCode) {
    task.metadata.referral = { code: referralCode.toUpperCase() };
  }
  const copay = _.get(vendor, 'coPay');
  const authLimit = _.get(vendor, 'authLimit');
  if (!_.isUndefined(copay)) {
    _.set(task, 'metadata.copay2', { amount: copay, required: true });
  }
  if (!_.isUndefined(authLimit)) {
    _.set(task, 'metadata.authNumber2', { amount: authLimit, required: true });
  }
  if (referredBy || customReferredBy) {
    _.set(task, 'metadata.referredBy', referredBy === 'Other' ? customReferredBy : referredBy);
  }
  if (orgId) {
    task.customer.org = { id: orgId };
    const tellUsMore = _.get(task, 'metadata.taskQuestions.Tell us more', '');
    task.metadata.taskQuestions['Tell us more'] = `${tellUsMore}\nHOME WARRANTY JOB`;
  }
  let tags;
  const workOrder = await U.api('post', `workOrders?notify=${notify}`, {
    minScheduleTime: U.redux.get('minScheduleTime'),
    tags,
    tasks: [task],
  });
  if (workOrder.errMsg) {
    return workOrder;
  }
  if (workOrder.tasks[0]) {
    if (workOrder.tasks[0].recall?.pastId) {
      sendRecallEmail(workOrder.tasks[0].customer.user.email);
    }
    global.modal = null;
    if (!isSupport) {
      clickBook({ step: 8 });
    }
    const taskId = workOrder.tasks[0].id;
    if (!isSupport) {
      goals.purchase({ promo, serviceId, taskId });
    }
    return workOrder.tasks[0].id;
  }
};

export const updateTask = async (idOrUrl, changes) => {
  if (!_.isString(idOrUrl)) {
    return { errMsg: 'Invalid job id' };
  }
  const url = idOrUrl.startsWith('tasks') ? idOrUrl : `tasks/${idOrUrl}`;
  changes = _.map(changes, c => {
    if (_.isString(c.value)) {
      c.value = _.trim(c.value);
    }
    return c;
  });
  const r = await U.api('put', url, changes);
  if (r && !r.id && !r.errMsg && r.message) {
    return { errMsg: r.message };
  }
  if (!_.isEmpty(r) && !_.get(r, 'errMsg')) {
    const workorder = await U.api('get', `workorders/${r.metadata.workOrderId}`);
    U.redux.set([`tasks.${r.id}`, r, `workorders.${workorder._id}`, workorder]);
  }
  return r;
};

export const couldNotContactCx = async id => {
  if (!_.isString(id)) {
    return { errMsg: 'Invalid job id' };
  }
  const url = `tasks/${id}/couldNotContactCx`;
  const r = await U.api('post', url, {});
  if (r && !r.id && !r.errMsg && r.message) {
    return { errMsg: r.message };
  }
  if (!_.isEmpty(r) && !_.get(r, 'errMsg')) {
    U.redux.set(`tasks.${r.id}`, r);
  }
  return r;
};

export const taskStatuses = [
  'assigned',
  'cancelled',
  'completed',
  'customerNoShow',
  'enRoute',
  'leftSite',
  'onSite',
  'partsOrdered',
  'partsReceived',
  'partsRequested',
  'partsResearch',
  'partsShipped',
  'pendingConfirmation',
  'proNoShow',
  { id: 'needsFollowup', name: 'Ready for Followup' },
  'waitingOnApproval',
  'waitingOnSchedule',
];
