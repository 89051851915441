import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import PropTypes from 'prop-types';
import { Form, Icon, Loader, Modal, Text } from '@/components';
import AuthorizationInfo from './EditPart/AuthorizationInfo';

function Event({ event }) {
  const [showMore, setShowMore] = useState(false);

  const { background, icon } = {
    [T.PartApprovalStatus.APPROVED]: { background: 'bg-success', icon: 'done' },
    [T.PartApprovalStatus.NOT_APPROVED]: { background: 'bg-danger', icon: 'close' },
    [T.PartApprovalStatus.NOT_REQUIRED]: { background: 'bg-grey-dark', icon: 'remove' },
    [T.PartApprovalStatus.REQUESTED]: { background: 'bg-accent-orange', icon: 'warning' },
  }[event.approvalStatus];

  return (
    <div className="flex items-start" key={event.time}>
      <div className={`${background} flex h-6 w-6 items-center justify-center rounded-full`}>
        <Icon color="white" name={icon} />
      </div>
      <div className="relative ml-3 pb-12 ">
        <div
          className="absolute bg-grey-medium"
          style={{ height: `84%`, width: 1, left: -24, top: 24 }}
        />
        <Text type="label">
          {U.getOptionName(T.partApprovalStatusOptions, event.approvalStatus)}
        </Text>
        <Text color="grey.dark" type="helper">
          {m(event.time).format('ddd, MM/DD/YY [at] h:mma')}
        </Text>
        <div className="mt-2 rounded bg-background-light p-2">
          <div className="flex">
            <Text color="grey.dark" type="helper">
              {[T.PartApprovalStatus.REQUESTED, T.PartApprovalStatus.NOT_REQUIRED].includes(
                event.approvalStatus
              )
                ? 'Requested by'
                : 'Approver'}
              :
            </Text>
            <Text className="ml-2" type="helper">
              {event.user}
            </Text>
          </div>
          <div className="flex">
            <Text color="grey.dark" type="helper">
              Reason:
            </Text>
            <Text className="ml-2" type="helper">
              {event.approvalReason || '-'}
            </Text>
          </div>
          <div className="flex align-top">
            <Text color="grey.dark" type="helper">
              Note:
            </Text>
            <div className="ml-2">
              <Text type="helper">
                {_.slice(event.approvalNotes, 0, showMore ? undefined : 40)}
                {U.length(event.approvalNotes) > 40 && (
                  <span>
                    <Text
                      type="helper"
                      color="primaryCTA"
                      className="cursor-pointer"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? ' ...show less' : ' ...more'}
                    </Text>
                  </span>
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Event.propTypes = {
  event: PropTypes.exact({
    approvalNotes: PropTypes.string,
    approvalReason: PropTypes.string,
    approvalStatus: PropTypes.string.isRequired,
    time: U.timePropType.isRequired,
    user: PropTypes.string.isRequired,
  }),
};

function EditApproval({ onClose, me, part: originalPart, task }) {
  const [events, setEvents] = useState();
  const [error, setError] = useState();
  const [part, setPart] = useState(originalPart);

  useEffect(() => {
    U.api('post', 'activities/search', {
      query: { refId: task.id, partId: part._id, type: { $in: ['PartAdd', 'PartsReplace'] } },
    }).then(activity => {
      const events = activity
        .map(activity => {
          let body;
          if (activity.type === 'PartAdd') {
            body = JSON.parse(activity.title.split('added - ')[1]);
          } else {
            body = JSON.parse(activity.title.split(' => ')[1] || activity.title.split(' with ')[1]);
          }
          const user = activity.byUser?.firstName || 'System';
          return {
            approvalReason: _.map(body.approvalReason, id =>
              U.optionToString(T.partApprovalReasonOptions, id)
            ).join(', '),
            approvalNotes: body.approvalNotes,
            approvalStatus: body.approvalStatus,
            time: activity.createTime,
            user,
          };
        })
        .filter(event => event.approvalStatus)
        .reverse()
        .filter((event, i, events) => !i || events[i - 1].approvalStatus !== event.approvalStatus);
      for (const [i, event] of events.entries()) {
        if (i) {
          events[i].approvalNotes = event.approvalNotes || events[i - 1].approvalNotes;
          events[i].approvalReason = event.approvalReason || events[i - 1].approvalReason;
        }
      }
      setEvents(events);
    });
  }, [part._id, task.id]);

  const onSave = async () => {
    const response = await U.api(
      'put',
      `tasks/${task.id}`,
      [{ path: `parts.items.${part._id}`, value: part }],
      ['save']
    );
    if (response.errMsg) {
      setError(response.errMsg);
    } else {
      onClose();
    }
  };

  return (
    <Form initialValue={originalPart} onChange={setPart} value={part}>
      <Modal isOpen onClose={onClose} width={1000}>
        <Modal.Header title="Edit Part Approval" />
        <Modal.Body className="bg-white">
          <div className="flex items-start">
            <div className="p-4" style={{ flex: 3 }}>
              <Text color="danger">{error}</Text>
              <AuthorizationInfo me={me} part={part} />
            </div>
            <div className="bg-background-light p-4" style={{ flex: 2, minHeight: 528 }}>
              {events ? (
                <div>
                  <Text type="page-title">Approval History{events && ` (${events.length})`}</Text>
                  <div className="mt-4 rounded border border-grey-medium bg-white p-4 pb-0">
                    {events.map(event => (
                      <Event event={event} key={event.time} />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <Loader isLoading />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer onSave={onSave} />
      </Modal>
    </Form>
  );
}

EditApproval.propTypes = {
  onClose: PropTypes.func.isRequired,
  me: U.user.mePropType.isRequired,
  part: T.partPropType.isRequired,
  task: T.propType.isRequired,
};

export default connect(s => ({ me: s.me }))(EditApproval);
