import React, { useState } from 'react';
import U from '@nanaio/util';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Text } from '@/components';

export default function Add({ toggleOpen }) {
  const [error, setError] = useState();
  const [user, setUser] = useState({});

  const handleSave = async () => {
    const pro = await U.api('post', 'pros', user);
    if (pro.errMsg) {
      setError(pro.errMsg);
    } else {
      U.redux.set(`pros.${pro.id}`, pro);
      toggleOpen();
      window.open(`/pros/${pro.id}`, '_blank').focus();
    }
  };

  return (
    <Modal isOpen onClose={toggleOpen}>
      <Form onChange={setUser} value={user}>
        <Modal.Header title="Add Pro" />
        <Modal.Body className="p-4">
          <Text color="danger">{error}</Text>
          <Input id="address" required type="address" />
          <Input id="email" required type="email" />
          <Input id="firstName" required />
          <Input id="lastName" required />
          <Input id="phone" required type="phone" />
        </Modal.Body>
        <Modal.Footer onSave={handleSave} />
      </Form>
    </Modal>
  );
}

Add.propTypes = { toggleOpen: PropTypes.func.isRequired };
