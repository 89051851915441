import React from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import U from '@nanaio/util';
import classnames from 'classnames';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { updateTask } from '../../../com/task';
import Authorization from './authorization';
import Communication from './communication';
import OpportunityNotifications from './OpportunityNotifications';
import Photos from './photos';
import Promos from './promos';
import Questions from './Questions';
import Tickets from './Tickets';

// renders tabs at the bottom of job details
class Tabs extends React.Component {
  static childContextTypes = { t: T.object };

  getChildContext = () => ({ t: this });

  state = { activeTab: 0 };

  componentWillUpdate(p, s) {
    if (!_.isEqual(this.state.promos, s.promos)) {
      const value = {};
      s.promos.forEach(p => {
        value[p] = {};
      });
      updateTask(this.props.job.id, [{ path: 'promos', value }]);
    }
  }

  editPromos = () => {
    this.setState({ editPromos: true });
    U.api('post', 'promos/search', undefined, true);
  };

  getTabs = () => {
    const { chats, job, workOrder } = this.props;
    const pictures = _.map(_.get(job, 'metadata.taskProblemQuestions.pictures'), (v, id) => ({
      url: v.url,
      id,
    }));
    const promoCount = U.length(workOrder, 'promos');

    return _.compact([
      { id: 0, text: 'Communication' },
      { id: 1, text: 'Questions' },
      { id: 2, text: 'Authorization' },
      { id: 3, text: `Photos${pictures.length ? ` (${pictures.length})` : ''}` },
      { id: 4, text: `Tickets${U.length(chats) ? ` (${chats.length})` : ''}` },
      { id: 5, text: `Promos${promoCount ? ` (${promoCount})` : ''}` },
      _.get(job, 'metadata.referral') && { id: 6, text: 'Referral' },
      { id: 7, text: 'Opportunity Log' },
      { id: 8, text: 'Device' },
    ]);
  };

  loadDevice = async () => {
    const query = { userId: this.props.job.customer.user.id };
    let device = _.first(await U.api('post', 'userAgents/search', { query }));
    device = device && _.omit(device, ['__v', 'id', '_id', 'tags', 'userId']);
    this.setState({ device });
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    if (tab === 8) {
      this.loadDevice();
    }
  };

  render() {
    const { chats, job } = this.props;
    const recallId = job.recall?.pastId;

    return (
      <section id="innerJobTabs">
        <Nav tabs>
          {this.getTabs().map(tab => (
            <NavItem key={tab.id}>
              <NavLink
                className={classnames({ active: this.state.activeTab === tab.id })}
                onClick={() => this.toggle(tab.id)}
              >
                {tab.text}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={0}>
            <Communication />
          </TabPane>

          <TabPane tabId={1}>
            {!!job && <Questions isOpen={this.state.activeTab === 1} task={job} />}
          </TabPane>

          <TabPane tabId={2}>
            <div>
              <Authorization />
              {recallId && (
                <div className="card card-body">
                  <a
                    href={`/tasks/${recallId}`}
                    className="link-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Recall For Task Id
                  </a>
                </div>
              )}
            </div>
          </TabPane>

          <TabPane tabId={3}>
            <Photos />
          </TabPane>

          <TabPane tabId={4}>
            <Tickets tickets={chats} />
          </TabPane>

          <TabPane tabId={5}>
            <Promos />
          </TabPane>

          {_.get(job, 'metadata.referral.code') && (
            <TabPane tabId={6}>
              <div className="form-group">
                <div style={{ fontWeight: 600 }}>Referral Code</div>
                {job.metadata.referral.code}
              </div>
              {job.metadata.referral.by && (
                <div className="form-group">
                  <div style={{ fontWeight: 600 }}>Referred By</div>
                  {job.metadata.referral.by && (
                    <div className="flex space-x-2">
                      <a
                        href={`/users/${job.metadata.referral.by.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-blue mr-3"
                      >
                        <Icon name="open_in_new" />
                      </a>
                      {job.metadata.referral.by.fullName}
                    </div>
                  )}
                </div>
              )}
              {job.metadata.referral.error && (
                <div className="form-group">
                  <div style={{ fontWeight: 600 }}>Error</div>
                  <div className="text-danger">{job.metadata.referral.error}</div>
                </div>
              )}
            </TabPane>
          )}

          <TabPane tabId={7}>
            <OpportunityNotifications task={job} />
          </TabPane>

          <TabPane tabId={8}>
            {this.state.device && (
              <div className="p-3">
                <table className="table-sm table-striped table-hover table">
                  <tbody>
                    {_.chunk(_.keys(this.state.device).sort(), 3).map((k, i) => (
                      <tr key={i}>
                        <th>{_.startCase(k[0])}</th>
                        <td>{JSON.stringify(this.state.device[k[0]])}</td>
                        <th>{_.startCase(k[1])}</th>
                        <td>{JSON.stringify(this.state.device[k[1]])}</td>
                        <th>{_.startCase(k[2])}</th>
                        <td>{JSON.stringify(this.state.device[k[2]])}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </TabPane>
        </TabContent>
      </section>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[p.taskId];
  const workOrder = s.workorders[job.metadata.workOrderId];
  const chats = s.conversations?.[workOrder.id];
  return { chats, job, workOrder };
})(Tabs);
