import React, { useState } from 'react';
import * as Table from '@nanaio/table';
import U, { Option } from '@nanaio/util';
import _ from 'lodash';
import { Modal } from '../../core';
import { Input, InputType } from '../../form';
import { getModuleOptions, getVectorOperationOptions } from './util';

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  idToVector: Record<string, Table.Vector>;
  index: number;
  rows: Table.Row[];
  onClose: () => void;
  onSubmit: (vectorFilters: Table.Vector['filters']) => void;
  query: Table.Query;
};

export default function VectorSettings({
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  idToVector,
  index,
  rows,
  onClose,
  onSubmit,
  query,
}: Props): JSX.Element {
  const vector = query.analytics.vectors[index];
  const [filters, setFilters] = useState<Table.Vector['filters']>(vector.filters);
  const column = vector.moduleId
    ? databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][vector.moduleId]
    : undefined;
  const isDimension = Table.vectorIsDimension({ column, query, vector });
  const moduleOptions = getModuleOptions({
    databaseIdToTableIdToColumnIdToColumn,
    directionToVectors: _.groupBy(query.analytics.vectors, 'direction'),
    index,
    query,
    vector,
  });
  const showIntervalLength =
    column?.type === Table.ColumnType.MONEY ||
    column?.type === Table.ColumnType.NUMBER ||
    Table.getIsFormula(vector);
  const warning = Table.getVectorWarnings({ databaseIdToTableIdToColumnIdToColumn, query: query })[
    index
  ];

  const handleFilterModuleIdChange = (index: number, moduleId?: string) => {
    const filtersCopy = _.cloneDeep(filters);
    _.set(filtersCopy, `${index}.moduleId`, moduleId);
    setFilters(filtersCopy);
  };

  const handleFilterValueChange = (index: number, path?: string, value?: unknown) => {
    const filtersCopy = _.cloneDeep(filters);
    const filter = filtersCopy[index];
    if (path) {
      if (!filter.value) {
        filter.value = {};
      }
      if (typeof filter.value === 'object') {
        filter.value[path] = value;
      }
    } else {
      filter.value = _.toString(value);
    }
    setFilters(filtersCopy);
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header
        title={`Edit ${Table.getVectorName({
          databaseIdToTableIdToColumnIdToColumn,
          idToVector,
          query,
          vector,
          vectors: query.analytics.vectors,
        })}`}
      />
      <Modal.Body className="p-4">
        {U.length(query, `analytics.vectors.${index}.formula`) ? (
          _.times(vector.formula.length + 1, i =>
            i % 2 ? (
              <Input
                id={`analytics.vectors.${index}.formula.${i}`}
                className="ml-4"
                key={i}
                error={warning}
                label="Build Formula"
                options={Table.mathOptions}
                removable={Boolean(vector.formula[i])}
              />
            ) : (
              <Input
                id={`analytics.vectors.${index}.formula.${i}`}
                className={i ? 'ml-4' : undefined}
                error={warning}
                key={i}
                label="Module"
                multilevel
                options={moduleOptions}
                sort
              />
            )
          )
        ) : (
          <>
            <Input
              id={`analytics.vectors.${index}.moduleId`}
              error={warning}
              isClearable={false}
              label="Module"
              multilevel
              options={moduleOptions}
              sort
            />
            <Input
              id={`analytics.vectors.${index}.formula.1`}
              error={warning}
              label="Build Formula"
              options={Table.mathOptions}
            />
          </>
        )}
        {isDimension && column?.type === Table.ColumnType.TIME && !vector.intervalLength && (
          <Input
            id={`analytics.vectors.${index}.duration`}
            capitalize
            label="Duration"
            options={Table.durationOptions}
          />
        )}
        {showIntervalLength && (
          <Input
            id={`analytics.vectors.${index}.intervalLength`}
            label="Interval Length"
            tooltip="Generate a column/row for values in each interval. Ex 0-100, 100-200, 200-300."
            type={InputType.NUMBER}
          />
        )}
        <Input id={`analytics.vectors.${index}.name`} debounceDelay={1000} label="Name" />
        <Input
          id={`analytics.vectors.${index}.operations`}
          label="Operations"
          multiple
          options={getVectorOperationOptions({
            databaseIdToTableIdToColumnIdToColumn,
            query,
            vector,
          })}
        />
        {vector.operations.includes(Table.Operation.PERCENTILE) && (
          <Input
            id={`analytics.vectors.${index}.percentile`}
            label="Percentile"
            type={InputType.PERCENT}
          />
        )}
        {!isDimension && (
          <Input
            id={`analytics.vectors.${index}.timeModuleId`}
            isClearable
            label="Time Module"
            multilevel
            options={_.values(
              databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]
            ).filter(option => option.type === Table.ColumnType.TIME)}
            sort
            style={{ minWidth: 200 }}
            tooltip="Base this metric off a different time module than the rest of the report"
          />
        )}
        {_.times(filters.length + 1, i => {
          const filterModule =
            databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][
              filters[i]?.moduleId
            ];
          return (
            <div key={i}>
              <Input
                label={filterModule ? `Filter By ${filterModule.name}` : `Filter By`}
                multilevel
                onChange={moduleId => handleFilterModuleIdChange(i, moduleId)}
                options={databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table]}
                sort
                tooltip="Add a filter to this vector without impacting other vectors or the table."
                value={filters[i]?.moduleId}
              />
              {filterModule?.filterUi && (
                <div className="ml-4">
                  {filterModule.filterUi({
                    column: filterModule,
                    onChange: (path, value) => handleFilterValueChange(i, path, value),
                    optionsMap:
                      databaseIdToTableIdToColumnKeyToOptionIdToOption[query.databaseId]?.[
                        query.table
                      ]?.[filterModule.id],
                    rows,
                    value: filters[i]?.value,
                  })}
                </div>
              )}
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer onSave={() => onSubmit(filters)} saveText="Done" />
    </Modal>
  );
}
