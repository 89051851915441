import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import { sendPageview } from '@/com/marketing';
import { Alert, Input, InputType, Text } from '@/components';
import { isCompleteAddress } from '@/components/form/Address';
import Footer from './com/Footer';
import { BookingState } from './util';

type Props = {
  onChange: (key: string, value: unknown) => void;
  onNext: () => Promise<void>;
  state: BookingState;
};

export default function Address({ onChange, onNext, state, ...props }: Props): JSX.Element {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    sendPageview(`booking-page/contact`);
  }, []);

  const isDisabled = !state.address;

  const getError = useCallback(() => {
    return !isCompleteAddress(state.address) ? 'Please choose an address from the suggestions' : '';
  }, [state.address]);

  const error = hasError ? getError() : '';

  const handleNext = useCallback(async () => {
    const error = getError();
    if (error) {
      setHasError(true);
      return;
    }
    onChange('address', {
      ...state.address,
      unit: state.unit,
      arrivalInstructions: state.arrivalInstructions,
    });
    await onNext();
  }, [getError, onNext, onChange, state.address, state.unit, state.arrivalInstructions]);

  const brand = nullthrows(state.metadata.taskQuestions.Make);

  return (
    <section>
      <Text type="headline-6">What is the service address?</Text>
      <Text className="mt-2">
        Our nearest top-rated technician to your address will be assigned for your repair.
      </Text>

      <div className="mt-6">
        {!!error && (
          <Alert className="mb-10" variant="error">
            {error}
          </Alert>
        )}

        <Input
          id="address"
          label="Service Address"
          onChange={e => {
            onChange('address', e);
          }}
          value={state.address}
          placeholder="Search address"
          required
          leftIcon="map_marker_outline"
          type={InputType.ADDRESS}
          className="mt-2"
          noUnit
          error={error}
        />

        <Input
          id="unit"
          label="Apartment, suite, etc."
          onChange={e => {
            onChange('unit', e);
          }}
          value={state.unit}
          placeholder="Enter here"
          leftIcon="door"
          className="mt-5"
        />

        <Input
          id="arrivalInstructions"
          label="Arrival Instructions"
          onChange={e => {
            onChange('arrivalInstructions', e);
          }}
          value={state.arrivalInstructions}
          placeholder="Enter here"
          helper="Please provide any necessary details (like gate codes or landmarks) to aid your technician’s arrival at your location."
          className="mt-5"
          multiline
        />

        <Alert icon="comment_text_outline" className="mt-7">
          <div className="flex items-center">
            <Text className="ml-4 text-secondary-5">
              You will be notified when your {brand} specialist is{' '}
              <strong className="font-semibold">30 minutes away.</strong>
            </Text>
          </div>
        </Alert>

        <Footer {...props} onNext={handleNext} disabled={isDisabled} error={error} />
      </div>
    </section>
  );
}
