import * as Table from '@nanaio/table';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import type { IconProps } from '@/components';
import { CompassDirection, Move } from '../types';
import { getCanAddSouthEast, getCanMoveEast, getCanMoveNorth, getCanMoveSouth } from './util';

type Props = {
  column?: Table.Column;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  direction: Table.Direction;
  directionToVectors: Record<Table.Direction, Table.Vector[]>;
  index: number;
  query: Table.Query;
  vectorAndChildIndexes: number[];
};

export default function getMoveOptions({
  column,
  databaseIdToTableIdToColumnIdToColumn,
  direction,
  directionToVectors,
  index,
  query,
  vectorAndChildIndexes,
}: Props): {
  action: Move;
  compassDirection?: CompassDirection;
  icon?: IconProps['name'];
  tooltip?: string;
}[] {
  if (!column) {
    return index === 0 ? [] : [{ action: Move.REMOVE }];
  }

  const lastChildIndex = nullthrows(_.last(vectorAndChildIndexes));
  const vectors = directionToVectors[direction];
  const canAddNorth = !vectors[index - 1] || vectors[index - 1].moduleId;
  const canAddSouth = !vectors[lastChildIndex + 1] || vectors[lastChildIndex + 1].moduleId;
  const canAddSouthEast = getCanAddSouthEast({ index, module: column, vectors });
  const canMoveEast = getCanMoveEast({
    databaseIdToTableIdToColumnIdToColumn,
    index,
    query,
    vectors,
  });
  const canMoveNorth = getCanMoveNorth({ index, vectors });
  const canMoveSouth = getCanMoveSouth({ index, vectors });

  return _.compact([
    canAddSouth && {
      action: Move.ADD,
      compassDirection: CompassDirection.SOUTH,
      icon: 'south',
      tooltip: `Insert unrelated ${direction} ${
        direction === Table.Direction.COLUMN ? 'right' : 'below'
      }`,
    },
    canAddNorth && {
      action: Move.ADD,
      compassDirection: CompassDirection.NORTH,
      icon: 'north',
      tooltip: `Insert unrelated ${direction} ${
        direction === Table.Direction.COLUMN ? 'left' : 'above'
      }`,
    },
    {
      action: Move.ADD,
      compassDirection: CompassDirection.NORTH_WEST,
      icon: 'north_west',
      tooltip: `Insert parent ${direction} ${
        direction === Table.Direction.COLUMN ? 'left' : 'above'
      }`,
    },
    canAddSouthEast && {
      action: Move.ADD,
      compassDirection: CompassDirection.SOUTH_EAST,
      icon: 'south_east',
      tooltip: `Insert child ${direction} ${
        direction === Table.Direction.COLUMN ? 'right' : 'below'
      }`,
    },
    canMoveSouth && {
      action: Move.MOVE,
      compassDirection: CompassDirection.SOUTH,
      icon: 'south',
      tooltip: `Move ${direction} ${direction === Table.Direction.COLUMN ? 'right' : 'below'}`,
    },
    canMoveNorth && {
      action: Move.MOVE,
      compassDirection: CompassDirection.NORTH,
      icon: 'north',
      tooltip: `Move ${direction} ${direction === Table.Direction.COLUMN ? 'left' : 'above'}`,
    },
    vectors[index].depth && {
      action: Move.MOVE,
      compassDirection: CompassDirection.WEST,
      icon: 'north_west',
      tooltip: `Unmake ${direction} child of ${
        direction === Table.Direction.COLUMN ? 'left' : 'above'
      } ${direction}`,
    },
    canMoveEast && {
      action: Move.MOVE,
      compassDirection: CompassDirection.EAST,
      icon: 'south_east',
      tooltip: `Make ${direction} child of ${
        direction === Table.Direction.COLUMN ? 'left' : 'above'
      } ${direction}`,
    },
    column && { action: Move.REMOVE, tooltip: `Remove ${direction}` },
  ]);
}
