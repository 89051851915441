import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import { supportArticleUrls } from '@/config/const';
import LandingPageHelpButton from './LandingPageHelpButton';

const handleHelpCenterClick = () => {
  window.open(supportArticleUrls.preScreenHelp, '_blank')?.focus();
};

export default {
  title: 'pages/user/FTCBookingFlow/Landing Page Help Button',
  component: LandingPageHelpButton,
  args: {
    handleHelpCenterClick,
  },
} as ComponentMeta<typeof LandingPageHelpButton>;

const Template: ComponentStory<typeof LandingPageHelpButton> = args => (
  <LandingPageHelpButton {...args} />
);

export const Default = Template.bind({});
Default.args = {};
