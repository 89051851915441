import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import Alert from './Alert';
import Icon from './Icon';
import Modal from './Modal';
import Tooltip from './Tooltip';

export const DEFAULT_ERROR_MESSAGE =
  'We were unable to complete this operation at this time. Please try again later.';

export type Props = {
  className?: string;
  error: Error | string;
  onClose?: () => void;
  text?: string | JSX.Element | null;
  variant?: 'alert' | 'icon' | 'modal';
};

export default function APIError({
  className,
  error,
  onClose,
  text = DEFAULT_ERROR_MESSAGE,
  variant = 'alert',
}: Props): JSX.Element {
  // TODO @aglemann reenable when determine why this is throwing a hooks error
  // useEffect(() => {
  //   Sentry.addBreadcrumb({
  //     category: 'error',
  //     message: error instanceof Error ? error.message : error,
  //   });
  // }, [error]);

  let message = text;
  if (!message) {
    message = error instanceof Error ? error.message : String(error);
  }

  if (variant === 'icon') {
    return (
      <Tooltip
        node={message}
        trigger={
          <Icon
            name="alert_rhombus_outline"
            size={24}
            className={classNames(className, 'text-danger')}
          />
        }
      />
    );
  }

  let component = (
    <Alert className={className} variant="error">
      {message}
    </Alert>
  );

  if (variant === 'modal') {
    component = (
      <Modal isOpen onClose={onClose}>
        {component}
      </Modal>
    );
  }

  return component;
}

type ErrorBoundaryProps = Omit<Props, 'error' | 'onClose'> & {
  children: ReactNode;
};

export function APIErrorBoundary({ children, ...props }: ErrorBoundaryProps): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={({ error }) => <APIError {...props} error={error} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
