import React, { useCallback } from 'react';
import _ from 'lodash';
import mt from 'moment-timezone';
import nullthrows from 'nullthrows';
import { Input, InputType } from '.';
import type { InputProps } from './Input';

export const getProps = ({ onChange, options, value, ...rest }: InputProps): Props => ({
  onChange: nullthrows(onChange),
  options: _.values(options) as CheckboxesOption[],
  values: value as CheckboxesOption[],
  ...rest,
});

type CheckboxesOption = {
  id: string;
  name: string;
  value: boolean;
  time: mt.MomentInput;
};

export type Props = {
  cypressId?: string;
  onChange: (value: unknown) => void;
  options: CheckboxesOption[];
  values?: CheckboxesOption[];
};

export default function AuditedCheckboxes({
  cypressId,
  onChange,
  options,
  values,
}: Props): JSX.Element {
  const filteredOptions = options.filter(option => !values?.find(v => v.id === option.id));
  const newOptions = [...(values || []), ...filteredOptions];

  const handleChange = useCallback(
    (option: CheckboxesOption, value: boolean) => {
      const newValue = _.cloneDeep(values) || [];
      const index = newValue.findIndex(v => v.id === option.id);
      if (index === -1) {
        newValue.push({ id: option.id, name: option.name, value: value, time: new Date() });
      } else {
        newValue[index].value = value;
        newValue[index].time = new Date();
      }
      onChange(newValue);
    },
    [onChange, values]
  );

  // The timezone is guessed relevant to the user's browser settings.
  const timezone = mt.tz.guess();

  return (
    <div data-cy={cypressId} className="mt-2">
      {newOptions.map((option: CheckboxesOption) => {
        let helper = undefined;
        if (option.value !== undefined) {
          helper = option.value
            ? `Added on ${mt(option.time).tz(timezone).format('M/D/YY, h:mm A (z)')}`
            : `Removed on ${mt(option.time).tz(timezone).format('M/D/YY, h:mm A (z)')}`;
        }
        return (
          <Input
            helper={helper}
            key={option.id}
            label={option.name}
            value={option.value}
            onChange={value => handleChange(option, value)}
            type={InputType.CHECK}
          />
        );
      })}
    </div>
  );
}
