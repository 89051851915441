import React from 'react';
import _ from 'lodash';
import { Icon, Text } from '@/components';

export default function FeeBox(): JSX.Element {
  return (
    <div className="mt-6 rounded-lg bg-background-light p-4">
      <div className="flex justify-between">
        <Text type="page-title">Diagnostic Fee:</Text>
        <div className="flex">
          <Text type="page-title">$70.00</Text>
          <Text color="secondary" className="ml-3 line-through">
            $89
          </Text>
        </div>
      </div>
      <ul>
        <li className="mt-3 flex">
          <Icon name="tag_outline" color="success" className="mr-2" size={20} />
          <Text>
            Our competitors charge <strong className="font-semibold">$89 to $159</strong> for
            diagnosis
          </Text>
        </li>
        <li className="mt-3 flex">
          <Icon name="update" color="success" className="mr-2" size={20} />
          <Text>
            <strong className="font-semibold">No payment</strong> until your diagnosis is complete
          </Text>
        </li>
        <li className="mt-3 flex">
          <Icon name="check" color="success" className="mr-2" size={20} />
          <Text>
            Diagnosis fee is <strong className="font-semibold">waived with repair</strong>
          </Text>
        </li>
      </ul>
    </div>
  );
}
