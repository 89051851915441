import * as Table from '@nanaio/table';
import { Range } from '@nanaio/util';
import _ from 'lodash';
import nullthrows from 'nullthrows';
import duration from './duration';

const filterUi = ({
  column,
  rows,
  onChange,
  value,
}: {
  column: Table.Column;
  rows: Table.Row[];
  onChange: (id?: string, value?: number) => void;
  value?: Range<number>;
}): JSX.Element => nullthrows(duration.filterUi)({ column, rows, onChange, type: 'days', value });

const durationInDaysModule: Partial<Table.Column> = { filterUi };

export default durationInDaysModule;
