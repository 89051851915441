import React from 'react';
import ReactCodeInput from 'react-code-input';
import U from '@nanaio/util';
import { supportEmail } from '@/config/const';

export default class Step6 extends React.Component {
  constructor(p) {
    super(p);
    this.state = { code: '' };
    this.goBack = this.goBack.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    const { email, phone } = p.getStore();
    U.api('post', 'users/forgotpassword', { identifier: email || phone }).then(r => {
      if (r && r.errCode === 'duplicate') {
        this.setState({ duplicate: true });
      }
    });
  }

  goBack() {
    this.props.jumpToStep(0);
  }

  onChange(code) {
    this.setState({ code });
  }

  onSubmit() {
    if (!this.state.code) {
      return this.setState({ error: 'Enter the code sent to the email' });
    }
    if (this.state.code.length !== 6) {
      return this.setState({ error: '6 digits required' });
    }
    this.props.updateStore({ token: this.state.code });
    const { flow } = this.props.getStore();
    if (flow === 'forgot password') {
      this.props.jumpToStep(6);
    } else {
      this.props.jumpToStep(flow === 'create password' ? 2 : 3);
    }
  }

  render() {
    const { email } = this.props.getStore();
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            {this.state.duplicate ? (
              <div className="text-center">
                <div className="text-danger">Duplicate accounts found</div>
                <div>Please contact {supportEmail} for assistance.</div>
              </div>
            ) : (
              <div>
                <h3 className="weight-500">Verification Code</h3>
                <p className="text-muted">
                  Please enter the 6-digit code sent to <span className="text-blue">{email}</span>
                </p>

                <div className="login--form 6num mt-3">
                  <ReactCodeInput
                    type="number"
                    fields={6}
                    value={this.state.code}
                    onChange={this.onChange}
                  />
                </div>
                <button onClick={this.onSubmit} className="primary mt-3">
                  <b>Verify</b>
                </button>
                {this.state.error && (
                  <p className="fadeInAnim py-3" style={{ color: '#f75c43' }}>
                    {this.state.error}
                  </p>
                )}
              </div>
            )}
            <p className="text-muted mt-4">
              <span className="text-blue cursor-pointer" onClick={this.goBack}>
                Back to Login
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
