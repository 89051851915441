import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Text from '../../core/Text';
import Section from '../Section';
import { getUnassignedPros, proPropType, rowHeight, sectionHeight } from '../util';
import Day from './Day';
import Pro from './Pro';

export default function UnassignedRows({
  mapIsOpen,
  removePro,
  setSearchTechIsOpen,
  setVisit,
  startTimes,
  visit,
  workOrder,
}) {
  const addUnassignedPro = () => {
    const roles = [W.VisitProRole.PRIMARY, W.VisitProRole.HELPER, W.VisitProRole.INTERESTED];
    setVisit({
      ...visit,
      pros: [
        ...visit.pros,
        {
          availability: [],
          status: W.VisitProStatus.PENDING,
          tasks: workOrder.tasks.map(task => ({
            id: task.id,
            role: roles.find(role => W.visitProCanAddRole({ role, taskId: task.id, visit })),
          })),
        },
      ],
    });
  };

  const unassignedPros = getUnassignedPros(visit);

  const unassignedSection = {
    key: 'unassignedSection',
    height: sectionHeight,
    content: [
      <Section hasOverlay={mapIsOpen} isFixed title={`Unassigned Roles (${unassignedPros.length})`}>
        <Text color="primaryCTA" onClick={addUnassignedPro} type="body-2">
          Add Role
        </Text>
      </Section>,
      ..._.times(7, i => <Section date={startTimes[i * 3]} hasOverlay={mapIsOpen} key={i} />),
    ],
  };

  const unassignedRows = unassignedPros.map((visitPro, unassignedIndex) => ({
    key: `unassigned-${unassignedIndex}`,
    height: rowHeight,
    content: [
      <Pro
        index={visitPro.index}
        {...{
          removePro,
          setSearchTechIsOpen,
          setVisit,
          unassignedIndex,
          visit,
          visitPro,
          workOrder,
        }}
      />,
      ..._.times(7, i => <Day key={i} startTimes={startTimes.slice(i * 3, (i + 1) * 3)} />),
    ],
  }));

  return [unassignedSection, ...unassignedRows];
}

UnassignedRows.propTypes = {
  mapIsOpen: PropTypes.bool.isRequired,
  proMap: PropTypes.objectOf(proPropType).isRequired,
  removePro: PropTypes.func.isRequired,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  setVisit: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  visit: W.visitPropType.isRequired,
  workOrder: W.propType.isRequired,
};
