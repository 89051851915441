import React from 'react';
import { ServerEntity } from '@nanaio/util';
import mt from 'moment-timezone';
import { Button, Icon, Text } from '@/components';

type Props = Pick<ServerEntity, 'createTime' | 'id' | 'lastModified'> & {
  entityName: string;
  onRemove?: () => void;
};

export default function ServerEntityInfo({
  createTime,
  entityName,
  id,
  lastModified,
  onRemove,
}: Props): JSX.Element {
  const timezone = mt.tz.guess();
  return (
    <div className="flex-col space-y-4 rounded-lg bg-grey-light p-4">
      <div className="flex flex-row space-x-4">
        <Text color="grey.dark">Created On:</Text>
        <Text>{mt(createTime).tz(timezone).format('ddd, MMM, D, YYYY h:mm A (z)')}</Text>
      </div>
      <div className="flex flex-row space-x-4">
        <Text color="grey.dark">Last Modified:</Text>
        <Text>{mt(lastModified).tz(timezone).format('ddd, MMM, D, YYYY h:mm A (z)')}</Text>
      </div>
      <div className="flex flex-row space-x-4">
        <Text color="grey.dark">{entityName} ID:</Text>
        <Text>{id}</Text>
      </div>
      {onRemove && (
        <div>
          <Button className="flex space-x-2 px-0" onClick={onRemove} variant="link" size="medium">
            <Icon color="danger" name="trash_can_outline" />
            <Text color="danger" type="button">
              Delete {entityName}
            </Text>
          </Button>
        </div>
      )}
    </div>
  );
}
