import React from 'react';
import * as Table from '@nanaio/table';
import { Option } from '@nanaio/util';
import _ from 'lodash';
import { Input, InputType } from '../../form';
import { Search as PickSearch } from './pick';

const editUi = ({
  key,
  label,
  onChange,
}: {
  key?: number | string;
  label: string;
  onChange: (value: string) => void;
}): JSX.Element => <Input key={key} label={label} onChange={value => onChange(value as string)} />;

const getOptions = ({ column, rows }: { column: Table.Column; rows: Table.Row[] }): Option[] =>
  _.uniq(_.flatten(rows.map(row => _.map(row.cells[column.id]?.values, 'value') as string[])))
    .filter(value => value !== undefined)
    .sort()
    .map(text => ({ id: text, name: text }));

const filterUi = ({
  column,
  rows,
  onChange,
  value,
}: {
  column: Table.Column;
  rows: Table.Row[];
  onChange?: (path: 'exclude' | 'include', value: string) => void;
  value?: PickSearch;
}): JSX.Element => {
  const options = getOptions({ column, rows });

  return (
    <div>
      <Input
        capitalize={column.camelCase}
        label="Include"
        multiple
        onChange={value => onChange && onChange('include', value as string)}
        options={options}
        type={InputType.SEARCH}
        value={value?.include}
      />
      <Input
        capitalize={column.camelCase}
        label="Exclude"
        multiple
        onChange={value => onChange && onChange('exclude', value as string)}
        options={options}
        type={InputType.SEARCH}
        value={value?.exclude}
      />
    </div>
  );
};

const textModule: Partial<Table.Column> = { editUi, filterUi };

export default textModule;
