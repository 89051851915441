import React from 'react';
import {
  companyAddress,
  companyCity,
  companyNameFullLegal,
  companyState,
  companyZipCode,
  supportEmail,
} from '@/config/const';
import Footer from '../../com/ui/footer';

export default function Privacy() {
  return (
    <div className="mt-12 pt-5">
      <div id="privacy" className="container pb-5 pt-5">
        <div>
          <h5>Legal</h5>
          <h1>Privacy Policy</h1>
          <label>Last Updated August 6, 2021</label>
        </div>
        <div className="row">
          <div className="col-lg-10 col-xl-9">
            <div className="section mb-5 pb-4 pt-4">
              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Overview</h4>
                <p>
                  This is the privacy policy of {companyNameFullLegal} As described in greater
                  detail in this Privacy Policy, we are committed to protecting your personal
                  information. We consider this to be a vital part of our relationship with you.
                  This Privacy Policy is intended to inform you of our policies and practices
                  regarding the collection, use and disclosure of information you share with us, or
                  that we collect from you through this site and various other channels. We share
                  Personal Information only as specified in this policy or as required by law. We
                  commit that we will not share your personal information unless you’ve asked us to
                  share, we’ve told you in advance that we will share as part of providing our
                  services, we’ve removed information that could identify you, or legal rights are
                  affected.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Scope</h4>
                <p>
                  This privacy policy applies to users of our mobile app and
                  website, https://nanahome.com, and related services. We refer to our mobile app,
                  website and services in this Privacy Policy collectively as our services. It
                  describes the personal information that we collect from you, how we use this
                  information and how we protect this information. If you do not agree with our
                  collection and use of your personal information, it is your choice not to use the
                  mobile app, website, and related services.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>How Our Services Work</h4>
                <p>
                  Our services are designed to provide on-demand and property management home
                  services to residential and commercial users.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Information We Collect</h4>
                <p>
                  We may collect your personal information and other information from you in the
                  following circumstances:
                </p>
                <p>
                  <b>Personal Information You Provide.</b> We collect personal information, such as
                  contact information from you when you voluntarily provide this information to us,
                  such as when you such as when you register for access to our services, contact us
                  with inquiries, link your accounts, respond to offers from third parties, respond
                  to surveys, authorize us to contact third parties on your behalf, sign in as a
                  user on our website or mobile App, using our social login sign in option. Personal
                  information that you voluntarily provide may include your name, address, email
                  address, phone number, tax identification number (such as U.S. social security
                  number) or other government-issued ID number, and date of birth.
                </p>

                <p>
                  <b>Information Collected Automatically</b>: We automatically collect detail about
                  visits to our Site, specifically traffic information, location information, logs,
                  and other communication information and the resources that you access and use on
                  the Site. We may also collect information about your computer and internet
                  connection, specifically your IP address, operating system, hardware model,
                  operating system, and browser type, browser version, the pages of our Site that
                  you visit, the time and date of your visit, the time spent on those pages and
                  other statistics.  The information we automatically collect is used for various
                  reasons, such as to determine our usage patterns; store information about your
                  preferences, allowing us to customize our Site according to your individual
                  interests; and recognize you when you return to our Site.  In addition, we may use
                  third party services such as Facebook and Google Analytics that collect,
                  monitor, and analyze these information.  Information we collect automatically,
                  which may not be personal information, is treated as personal information when it
                  is attached to other personal information.  
                  <br /> <b>Information from Third Parties</b>: We may receive information from our
                  affiliates and business partners that you provide to them. This includes
                  information you provide to distributors or resellers of {companyNameFullLegal},
                  information you provide in response to surveys. 
                </p>

                <p>
                  <b>Other Information We Collect.</b>
                </p>

                <div className="bullet-list">
                  <ul>
                    <li>
                      <p>
                        <b>Non-Identifiable Information.</b> When you interact with our service, we
                        receive and store certain personally non-identifiable information. Such
                        information, which is collected passively using various technologies, cannot
                        presently be used to specifically identify you. We may store this
                        information itself or it may be included in systems owned and maintained by
                        our agents or service providers. Our services may use this information and
                        pool it with other information to track, for example, the total number of
                        visitors to our website, the number of visitors to each page of our website,
                        and the domain names of our visitors&apos; internet service providers.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Cookies.</b> In operating the services, we may use a technology
                        called &quot;cookies&quot;. A cookie is a piece of information that the
                        computer that hosts our services gives to your browser when you access our
                        services. Cookies help provide additional functionality to our services and
                        help us analyze usage more accurately. For instance, our website may set a
                        cookie on your browser that allows you to access our services without
                        needing to remember and then enter a password more than once during a visit
                        to our website. In all cases in which we use cookies, we will not collect
                        personal information except with your permission. On most web browsers, you
                        will find a “help” section on the toolbar. Please refer to this section for
                        information on how to receive notification when you are receiving a new
                        cookie and how to turn cookies off. We recommend that you leave cookies
                        turned on because they allow you to take advantage of additional features of
                        our services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Aggregated Personal Information.</b> In an ongoing effort to better
                        understand and serve our users, we may conduct research on our customer
                        demographics, interests and behavior based on anonymized service statistics
                        and information. This research may be compiled and analyzed on an anonymized
                        aggregate basis, and we may share this aggregate information with our agents
                        and business partners. This aggregate information does not identify any
                        individual or household. We may also disclose aggregated user statistics in
                        order to describe our services to current and prospective business partners,
                        and to other third parties for other lawful purposes.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Third Party Analytics.</b> We may allow third party service providers to
                        use cookies or similar technologies to collect information about your
                        browsing activities over time and across different websites following your
                        use of the services. These third party service providers help us analyze how
                        our users use our services, and can enhance your experience when you use our
                        services. For example, we use Google Analytics, a web analytics service
                        provided by Google, Inc. Google Analytics uses cookies to help us analyze
                        how users use our service and website. For more information on how Google
                        uses this information, go to www.google.com/policies/privacy/partners/.”
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Location Information.</b> Our services may collect and use your location
                        information (for example, by using the GPS on your mobile device) in order
                        to help us authenticate activity related to your account and provide the
                        services to you. We may also use your location information in an aggregate
                        way, as described below in the “Aggregated or De-Identified” section. We do
                        not share your location information with other users of the services. If you
                        choose to enable mobile location features, your location information may be
                        publicly displayed within our service. Please keep in mind that other users
                        can see this information about you, and they may use it or disclose it to
                        other individuals or entities outside of our control and without your
                        knowledge. Your location information may be subject to abuse, misuse, and
                        monitoring by others, so please be careful if you choose to enable location
                        functionality.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Mobile Information.</b> You are able to download our mobile app through
                        Google Play for Android, and the Apple App Store for iOS. When you use our
                        mobile apps, we collect from your mobile device a unique identification
                        number (including the Apple-recommended CFUUID (the Core Foundation
                        Universally Unique Identifier) if you have an iPhone). When you use certain
                        features of our mobile app, we may collect information about your location
                        to provide you with location-based services and information. Many devices
                        allow you to turn off location services, but if you do so, you may be unable
                        to use all the features of our services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Do Not Track Signals.</b> We may (and we may allow third party service
                        providers to) use cookies or other technologies to collect information about
                        your browsing activities over time and across different websites following
                        your use of the Services. You may set a “Do Not Track” (DNT) privacy
                        preference in your web browser. When you turn on DNT in your browser, we
                        will use commercially reasonable efforts to stop collecting the information
                        that allows us to tailor suggestions based on your recent use of our
                        services and your visits to websites within our advertising partner
                        networks. Specifically, we stop collecting the unique cookie that links your
                        browser to use of our services and visits to these websites for tailoring
                        suggestions or ads
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Recordings of the Services.</b> We may record video of service activities
                        in order to permit you to view recordings and live streams of such
                        activities and to improve our services. For more information about such
                        recordings and live streams, please see our Terms of Service
                        at https://nanahome.com/terms. Such recordings and live streams may capture
                        information, including personal information, that is viewable by the
                        recording device.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>How We Use Your Information</h4>
                <p>
                  We will use the personal information you give us to provide you with our services,
                  in a way that is consistent with this Privacy Policy. Please note that if you
                  provide your personal information for one part of our services, we may use it for
                  another part of our services. For example, if you contact us by email, we may use
                  the personal information you’ve submitted to us to provide you with the answer to
                  your question or resolve your problem. Also, if you provide personal information
                  in order to obtain access to our services or to link your financial accounts, we
                  will use your personal information to provide you with access to these services
                  and features and to monitor your use of these services
                </p>
                <p>
                  In addition, to those examples, we use Personal Information, and information
                  coupled with Personal Information for the following purposes:
                </p>
                <p>
                  To respond to your inquiries and fulfill your requests, including to contact you
                  if necessary;
                  <br />
                  To process and fulfill your order, for example by confirming your order and your
                  billing and shipping information, processing your payments, delivering your
                  products and communicating with you regarding your order;
                  <br />
                  To send to you important information regarding our Site, changes to our terms,
                  conditions and policies and other administrative information;
                  <br />
                  To send to you information by email, postal mail, telephone and other means about
                  our products, services, contests, and other promotions;
                  <br />
                  To help us improve the customer experience, including by learning more about your
                  preferences to help personalize your experience with us;
                  <br />
                  To analyze trends and statistics in connection with our businesses and Internet
                  properties, including to measure the success of marketing campaigns and to compile
                  statistics about usage and response rates;
                  <br />
                  To protect the security or integrity of our businesses and Internet properties;
                  and As we believe to be necessary or appropriate: (a) under applicable law,
                  including laws outside your country of residence; (b) to comply with legal
                  process; (c) to respond to requests from public and government authorities,
                  including public and government authorities outside your country of residence; (d)
                  to enforce our terms and conditions; (e) to protect our operations or those of any
                  of our Affiliates; (f) to protect our rights, privacy, safety or property, or that
                  of you, our Affiliates or others; and (g) to allow us to pursue available remedies
                  or to limit the damages that we may sustain.
                  <br />
                  We may use this information to contact you in the future to tell you about
                  services we believe will be of interest to you. If we do so, each marketing
                  communication we send you will contain instructions permitting you to
                  &quot;opt-out&quot; of receiving future marketing communications. In addition, if
                  at any time you wish not to receive any future marketing communications or you
                  wish to have your name deleted from our mailing lists, please contact us as
                  indicated below.
                  <br />
                  If we plan to use your personal information in any manner that is not consistent
                  with this Privacy Policy, you will be informed prior to or at the time when your
                  personal information is collected.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>How We Can Disclose Your Information</h4>
                <p>
                  We are committed to protecting your personal information. We consider this to be a
                  vital part of our relationship with you. We commit that if we share your
                  information, it will be only in the following circumstances.
                </p>
                <div className="bullet-list">
                  <ul>
                    <li>
                      <p>
                        <b>You’ve Asked Us To Share.</b> We may share your information where you
                        have consented or otherwise given us permission to share your information,
                        such as when you request our services. We will not share sensitive personal
                        information, including government identification information with third
                        party product providers without your express consent. Note that if you
                        decide to pursue an offer from a third party product provider, you may be
                        asked to provide information directly to that provider, including your
                        personal information. Using our services does not require that you provide
                        any information directly to such providers, unless you wish to.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Aggregated or De-Identified.</b> Although we will not share your without
                        your consent, we may share information that is aggregated or de-identified,
                        so that it cannot reasonably be used to identify you. For example, we may
                        share aggregated or de-identified information with third party providers to
                        permit them to provide services that are more relevant to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>With Third Parties Needed to Provide Our Services.</b> We may share your
                        information with third party service providers who we use in delivering our
                        service. Examples include members of the {companyNameFullLegal} professional
                        network and providers who provide us with technology services such as
                        hosting providers, identity verification, support, payment, and email
                        service providers. We provide these providers with only the information they
                        need to perform the specific function.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Legal Rights.</b> We may share your information if required by applicable
                        law or legal process, or if we believe it is in accordance with applicable
                        law or legal process. This can include protecting the rights, property and
                        safety of our company, our users and the public, including, for example, in
                        connection with court proceedings, or to detect or prevent criminal
                        activity, fraud, material misrepresentation, or to establish our rights or
                        defend against legal claims and with law enforcement, regulatory, and
                        government authorities.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Business Transfers.</b> As we develop our business, we might sell or buy
                        businesses or assets. In the event of a corporate sale, merger,
                        reorganization, dissolution or similar event, your personal information may
                        be part of the transferred assets.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Related Companies.</b> We may also share your information with our
                        related companies (including our parent and subsidiary companies) consistent
                        with this Privacy Policy.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Updating or Deleting Your Information</h4>
                <p>
                  If you need to update your personal information, please contact us at
                  {supportEmail} or login to your profile and make the necessary changes. We will
                  take reasonable steps to update or correct your personal information in our
                  possession that you have previously submitted through our services. You can also
                  choose to not provide us with personal information, but you may not be able to use
                  some of our services. You may terminate your account with us at any time by
                  submitting a request to {supportEmail}. Please note that we may retain your
                  personal information and other information beyond the end of your relationship
                  with us in accordance with our internal record-keeping policies and with
                  applicable law, including to comply with legal reporting obligations.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Unsolicited Information Not Covered</h4>
                <p>
                  This Privacy Policy does not apply to any unsolicited information you provide to
                  us. This includes, but is not limited to, information posted to any public areas
                  of our website, such as forums, any ideas for new products or modifications to
                  existing products, and other unsolicited submissions. If you make an unsolicited
                  submission, we will not treat this as confidential and we can reproduce, use,
                  disclose, and distribute this information to others without limitation or
                  attribution. For the safety and security of your information, please do not submit
                  information to us other than when we specifically ask you for it.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Children</h4>
                <p>
                  We do not knowingly collect personal information from individuals under the age of
                  18, and our website and services are not intended for use by children. If you are
                  under the age of 18, please do not submit personal information to us. If you have
                  reason to believe that a user under the age of 18 has provided personal
                  information to us, please contact us, and we will endeavor to delete that
                  information we maintain, subject to applicable law.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Links to Other Websites or Services</h4>
                <p>
                  This Privacy Policy applies only to our website and our services. Our website or
                  services may contain links to other websites or services not operated or
                  controlled by us, including websites and services operated by the third party
                  product providers who may make offers available to you through our services. The
                  policies and procedures we described here do not apply to the websites of these
                  third parties. The links from the services do not imply that we endorse or have
                  reviewed the websites of these third parties or their respective privacy policies.
                  We suggest you contacting those websites directly for information on their privacy
                  policies.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Integrating Social Networking Services</h4>
                <p>
                  One of the features of our services is you can connect various social networking
                  services like Facebook, Linkedin, Google or Twitter to your account with us. By
                  directly integrating these services, we aim to make your online experiences richer
                  and more personalized. To take advantage of this feature, we will ask you to log
                  into or grant us permission via the relevant social networking services. When you
                  add a social networking service account to our service or log into our service
                  using your social networking services account, we will collect relevant
                  information necessary to enable our services to access that social networking
                  service and your data contained those social networking services. As part of this
                  integration, the social networking services will provide us with access to certain
                  information that you have provided to the social networking services, and we will
                  use, store and disclose such information in accordance with this Privacy Policy.
                  However, please remember that the manner in which social networking services use,
                  store and disclose your information is governed by the policies of those third
                  parties, and we have no liability or responsibility for the privacy practices or
                  other actions of any social networking services that may be enabled within our
                  services. You may also have the option of posting your account activities to
                  social networking services when you access content through our services (for
                  example, you may post to Facebook that you performed an activity using our
                  services). If you choose to use this feature, your friends, followers and
                  subscribers on any social networking services you have enabled will be able to
                  view such activity.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Security</h4>
                <p>
                  We takes reasonable steps to protect your personal information from loss, misuse,
                  and unauthorized access, disclosure, alteration, or destruction. However, no
                  Internet or email transmission is ever fully secure or error free.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Changes to This Policy</h4>
                <p>
                  Our business and services may change from time to time. As a result, at times it
                  may be necessary to make changes to this Privacy Policy. If we make changes, we
                  will notify you by revising the date at the top of this page. If we make material
                  changes, we will do so in accordance with applicable legal requirements, and we
                  will post a notice on our website and mobile app alerting you to the material
                  changes prior to such changes becoming effective.
                </p>
              </div>

              <div className="ml-lg-4 p-lg-3 mb-3 ml-0 p-0">
                <h4>Contact Us</h4>
                <p>
                  You may contact us by mail at {companyAddress}, {companyCity} {companyState}{' '}
                  {companyZipCode}
                  and by email at {supportEmail}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
