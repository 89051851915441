import React from 'react';
import * as Table from '@nanaio/table';
import { Table as TableComponent } from '@/components';
import EditPackage from './EditPackage';

export default function PricingPackagesTable(): JSX.Element {
  return (
    <TableComponent
      {...Table.databases.default.pricingPackage} // eslint-disable-line react/jsx-props-no-spreading
      addUi={EditPackage}
      editUi={EditPackage}
    />
  );
}
