import React from 'react';
import PropTypes from 'prop-types';

const sizes = {
  1: '1fr',
  2: '1fr 1fr',
  3: '1fr 1fr 1fr',
  4: '1fr 1fr 1fr 1fr',
  5: '1fr 1fr 1fr 1fr 1fr',
  6: '1fr 1fr 1fr 1fr 1fr 1fr',
};

const bp = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export default class Grid extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.string,
    gap: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
  };

  constructor(p) {
    super(p);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const p = this.props;
    const { width } = this.state;
    const isXl = width >= bp.xl;
    const isLg = width >= bp.lg;
    const isMd = width >= bp.md;
    const isSm = width >= bp.sm;
    const isXs = width >= bp.xs;

    return (
      <div
        className={p.className}
        style={{
          display: 'grid',
          gridTemplateColumns: isXl
            ? sizes[p.xl]
            : isLg
              ? sizes[p.lg]
              : isMd
                ? sizes[p.md]
                : isSm
                  ? sizes[p.sm]
                  : isXs
                    ? sizes[p.xs]
                    : sizes[p.size],
          gridGap: p.gap ? p.gap : '1em',
          width: p.width ? p.width : '100%',
          clear: 'both',
        }}
      >
        {p.items.map((t, n) => (
          <div key={n}>{t}</div>
        ))}
      </div>
    );
  }
}
