import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ProAvatar from '@/task/details/com/ProAvatar';
import Avatar from '../core/Avatar';
import Cell from './Cell';
import Overlay from './Overlay';
import { rowHeight } from './util';

export default function User({
  children,
  hasOverlay,
  badgeProps,
  className = '',
  style = {},
  name,
  onMouseEnter,
  proId,
}) {
  return (
    <Cell
      className={`flex flex-row border border-t-0 border-grey-medium bg-white p-4 ${
        hasOverlay ? 'relative' : ''
      }`}
      isFixed
      onMouseEnter={onMouseEnter}
      style={{ height: rowHeight }}
    >
      {hasOverlay && <Overlay />}
      {proId ? (
        <ProAvatar proId={proId} variant="circle" size="xlarge" badgeProps={badgeProps} />
      ) : (
        <Avatar
          {...{
            className,
            badgeProps,
            name,
            style,
          }}
          variant="circle"
        />
      )}
      {children}
    </Cell>
  );
}

User.propTypes = {
  children: PropTypes.node.isRequired,
  hasOverlay: PropTypes.bool,
  badgeProps: PropTypes.any,
  className: PropTypes.string,
  style: U.stylePropType,
  name: PropTypes.string,
  onMouseEnter: PropTypes.func,
  proId: PropTypes.string,
};
