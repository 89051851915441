import React from 'react';
import { ComponentMeta,ComponentStory } from '@storybook/react';
import Tooltip from '.';

export default {
  title: 'Core/Tooltip',
  component: Tooltip,
  args: {},
  argTypes: {
    placement: { control: { type: 'inline-radio' } },
    iconColor: { control: { type: 'text' } },
  },
  decorators: [
    Story => (
      <div>
        Tooltip story is centered so that you can more easily test placement.
        <div className="flex h-96 w-full items-center justify-center">
          <Story />
        </div>
      </div>
    ),
  ],
} as ComponentMeta<typeof Tooltip>;

const Template: ComponentStory<typeof Tooltip> = args => <Tooltip {...args} />;

export const Default = Template.bind({});
Default.args = {
  node: 'This is the default tooltip',
};

export const UsingChildren = Template.bind({});
UsingChildren.args = {
  children: 'Children Defined',
  node: 'This is a an example using children. Note: they do not trigger the tooltip.',
};

export const UsingTrigger = Template.bind({});
UsingTrigger.args = {
  trigger: <button className="bg-accent-orange">Button Trigger</button>,
  node: 'This is a using a button as the trigger, but it could use any ReactNode',
};

export const NoWrap = Template.bind({});
NoWrap.args = {
  node: 'This is the default tooltip using the no wrap option.  Long text will get clipped which is very ugly.  Not sure why we would support this option',
  noWrap: true,
};

export const IconColor = Template.bind({});
IconColor.args = {
  node: 'This is using a custom icon color',
  iconColor: 'red-100',
};
