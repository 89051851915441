import React from 'react';
import { Input, InputType, Text } from '@/components';
import Panel from './Panel';

type Props = {
  disableControls?: boolean;
  errors: Errors;
};

interface Errors {
  payment: string;
}

export default function PaymentPanel({ disableControls, errors }: Props): JSX.Element {
  return (
    <Panel className="mt-4" variant="success" title="Then Pay" icon="paid">
      <div className="col-span-2">
        <Input
          className="mb-0"
          disabled={disableControls}
          error={errors.payment}
          id="payment"
          label=""
          placeholder="Enter the amount of payment"
          type={InputType.MONEY}
        />

        <Text color="danger" type="helper" className="mt-1">
          {errors.payment}
        </Text>
      </div>
    </Panel>
  );
}
