import React from 'react';
import { connect } from 'react-redux';
import * as Table from '@nanaio/table';
import U, { Option } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import pluralize from 'pluralize';
import { Button, Text } from '../../core';
import { Input, InputType } from '../../form';
import { CompassDirection, Move } from './types';
import VectorUI from './Vector';

type Props = {
  analyticsTableId?: string;
  analyticsTableOptions: Option[];
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  databaseIdToTableIdToColumnKeyToOptionIdToOption: Table.Depth4<Option>;
  deleteAnalyticsTable: () => void;
  errors: { time: m.MomentInput; url: string }[];
  isLimit1000: boolean;
  isLoading: boolean;
  isOpen: boolean;
  name: string;
  onAnalyticsReportChange: (analyticsTableId: string) => Promise<void>;
  onMove: (props: { action: Move; compassDirection?: CompassDirection; index: number }) => void;
  openSettings: (index: number) => void;
  query: Table.Query;
  rows: Table.Row[];
  setIsLimit1000: (value: boolean) => void;
  swapAxis: () => void;
  toggleIsOpen: () => void;
};

function Settings({
  analyticsTableId,
  analyticsTableOptions,
  databaseIdToTableIdToColumnIdToColumn,
  databaseIdToTableIdToColumnKeyToOptionIdToOption,
  deleteAnalyticsTable,
  errors,
  isLimit1000,
  isLoading,
  isOpen,
  name,
  onAnalyticsReportChange,
  onMove,
  openSettings,
  query,
  rows,
  setIsLimit1000,
  swapAxis,
  toggleIsOpen,
}: Props): JSX.Element {
  const directionToVectors: Record<string, Table.Vector[]> = _.groupBy(
    query.analytics.vectors,
    'direction'
  );
  const warnings = Table.getVectorWarnings({ databaseIdToTableIdToColumnIdToColumn, query: query });

  return (
    <div className="mr-4">
      <div className="mb-4 flex items-center">
        {!!analyticsTableOptions.length && (
          <Input
            className="mr-4"
            label="Generated Reports"
            onChange={id => onAnalyticsReportChange(String(id))}
            options={[{ id: 'live', name: 'Live' }, ...analyticsTableOptions]}
            tooltip="Select a pre-saved report, or build one in real-time using Live mode."
            value={analyticsTableId}
            width={170}
          />
        )}
        <Button onClick={toggleIsOpen} size="medium">
          {isOpen ? 'Hide Settings' : 'Settings'}
        </Button>
        {isOpen && (
          <Button
            className="ml-4"
            onClick={swapAxis}
            size="medium"
            tooltip={{ node: 'Swap columns and rows.' }}
            variant="secondary"
          >
            Flip
          </Button>
        )}
        <Input
          className="mb-0 ml-4"
          label={`Limit 1000 ${_.startCase(pluralize(name))}`}
          onChange={value => setIsLimit1000(Boolean(value))}
          type={InputType.CHECK}
          value={isLimit1000}
        />
        <div className="ml-auto">
          {errors.length ? (
            <div className="flex justify-between">
              <Text>Failed Requests</Text>
              <Text
                className="ml-4"
                color="primaryCTA"
                onClick={() => U.redux.set('api.errors', [])}
              >
                Clear
              </Text>
            </div>
          ) : undefined}
          {errors.map(error => (
            <Text color="danger" key={m(error.time).valueOf()}>
              {m(error.time).format('h:mma')} {error.url}
            </Text>
          ))}
        </div>
        {analyticsTableId && analyticsTableId !== 'live' && (
          <Button
            onClick={deleteAnalyticsTable}
            size="medium"
            tooltip={{ node: 'Delete generated report.' }}
            variant="secondary"
          >
            Delete
          </Button>
        )}
      </div>
      {isOpen && (
        <div className="grid grid-cols-2 gap-x-4">
          {_.map(directionToVectors, (vectors, direction) => (
            <div key={direction}>
              <Text type="subtitle-2">{_.startCase(direction)}s</Text>
              {vectors.map((vector, i) => {
                const index =
                  i +
                  (direction === Table.Direction.ROW
                    ? directionToVectors[Table.Direction.COLUMN].length
                    : 0);
                return (
                  <VectorUI
                    {...{
                      databaseIdToTableIdToColumnIdToColumn,
                      databaseIdToTableIdToColumnKeyToOptionIdToOption,
                      directionToVectors,
                      index,
                      isLoading,
                      rows,
                      key: vector.id,
                      onMove: ({
                        action,
                        compassDirection,
                      }: {
                        action: Move;
                        compassDirection?: CompassDirection;
                      }) => onMove({ action, compassDirection, index }),
                      openSettings: () => openSettings(index),
                      query,
                      vector,
                      warning: warnings[index],
                    }}
                  />
                );
              })}
            </div>
          )).reverse()}
        </div>
      )}
    </div>
  );
}

export default connect(s => ({ errors: s.api?.errors }))(Settings);
