import React, { useEffect, useMemo, useState } from 'react';
import { Org, T,Task, U, W, WorkOrder } from '@nanaio/util';
import _ from 'lodash';
import moment from 'moment';
import { workOrderEvent } from '@/com/analytics';
import { APIError } from '@/components';
import ConfirmRepairBanner from '@/components/pages/user/FTCBookingFlow/ConfirmRepairBanner';
import { useLazyLegacyAPI } from '@/hooks';
import { useGetWorkOrder } from '@/hooks/api/workOrder';
import { Step, useUpdateFtcBFSteps } from '@/hooks/api/workOrderFtcBF';
import ConfirmationFlowModal from './ConfirmationFlowModal';

const HOURS_TO_CONFIRM = 48;

type Props = {
  job: Task;
  workOrder: WorkOrder;
};

export default function CustomerConfirmationFlow({ job, workOrder }: Props): JSX.Element {
  const [apiError, setAPIError] = useState<Error>();
  const [showModal, setShowModal] = useState(false);

  const updateWorkOrderFtcBF = useUpdateFtcBFSteps(workOrder.id);
  const workOrderQuery = useGetWorkOrder(workOrder.id);

  const ftcBF = workOrderQuery?.data?.ftcBF;

  const status = useMemo(() => {
    if (workOrder.status === W.Status.CANCELLED) {
      return 'cancelled';
    }
    if (
      workOrder.tags.includes(W.Tag.FTC_BOOKING_FLOW_V_2_CUSTOMER_CONFIRMED) ||
      !!workOrder.hasCustomerConfirmed
    ) {
      return 'reviewing';
    }
    if (!ftcBF || !ftcBF.steps?.length) {
      return 'confirm';
    }
    if (ftcBF && !ftcBF.steps.every(step => step.completed === step.total)) {
      return 'continue';
    }
    return 'reviewing';
  }, [ftcBF, workOrder]);

  const orgId = job.customer.org?.id || '';

  const [loadOrg, orgToView] = useLazyLegacyAPI<Org>(`orgs/${orgId}`, { method: 'get' });

  useEffect(() => {
    if (orgId) {
      void loadOrg();
    }
  }, [orgId, loadOrg]);

  const isCardCollectionRequired = useMemo(() => {
    if (orgToView.data) {
      return T.askCard({ job, org: orgToView.data });
    }
    return false;
  }, [orgToView, job]);

  const isChangeMakeAllowed = !(job.customer.org?.type === 'OEM');

  const totalApplianceSteps = useMemo(() => {
    let total = 1;
    if (isChangeMakeAllowed) {
      total += 1;
    }
    return total;
  }, [isChangeMakeAllowed]);

  const initFtcBF = async () => {
    const steps: Step[] = [
      ...workOrder.tasks.map(_ => ({
        name: 'appliance' as const,
        completed: 0,
        total: totalApplianceSteps,
      })),
      { name: 'profile' as const, completed: 0, total: isCardCollectionRequired ? 3 : 2 },
    ];
    const hasFutureAvailability = workOrder.visits?.some(visit =>
      visit.cx.availability.some(slot => moment(slot.start).isAfter(moment()))
    );
    const hasCachedVisits = workOrder.visitsAtCreate.length > 0;
    const hasParts = job.parts?.itemsArray && job.parts?.itemsArray?.length > 0;
    const mtReviewFlag = T.hasFlag(job, T.Flags.SAMSUNG_MT_REVIEW);

    const isAppointmentNeeded =
      !hasFutureAvailability && !hasCachedVisits && !hasParts && !mtReviewFlag;
    if (isAppointmentNeeded) {
      steps.push({ name: 'appointment' as const, completed: 0, total: 1 });
    }
    steps.push({ name: 'terms' as const, completed: 0, total: 1 });
    await updateWorkOrderFtcBF.mutateAsync(
      { steps },
      { onError: error => setAPIError(error as Error) }
    );
  };

  const handleClick = async () => {
    if (status === 'confirm') {
      await initFtcBF();
      const eventInfo = {
        variant: 'ftc',
        step: 'start',
      };
      workOrderEvent().track('v1_workOrderTaskBookingFlowStepCompleted', eventInfo);
    }
    setShowModal(true);
  };

  const timezone = U.timezone(workOrder.cx.address);
  const enteredWOPSTime =
    T.getLastStatusStartTime(T.Status.WAITING_ON_PRE_SCREEN, job) || job.createTime;
  const deadline = moment(enteredWOPSTime).tz(timezone).add(HOURS_TO_CONFIRM, 'hours');

  return (
    <div className="mb-4">
      <ConfirmRepairBanner
        dateTime={deadline}
        name={workOrder.cx.firstName}
        onButtonClick={handleClick}
        status={status}
        timezone={timezone}
        vendorName={workOrder.org?.name || 'Unknown Vendor'}
      />

      {showModal && ftcBF?.steps && (
        <ConfirmationFlowModal
          steps={ftcBF.steps}
          deadline={deadline}
          onClose={() => setShowModal(false)}
          taskId={job.id}
          workOrderId={workOrder.id}
        />
      )}

      {apiError && (
        <APIError error={apiError} onClose={() => setAPIError(undefined)} variant="modal" />
      )}
    </div>
  );
}
