import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InputMask from 'react-input-mask';
import U, { Address, Option } from '@nanaio/util';
import _ from 'lodash';
import m from 'moment';
import type { Props as AddressProps } from '../Address';
import type { Props as BoolProps } from '../BoolInput';
import type { Props as CheckProps } from '../Check';
import Context from '../Context';
import type { Props as DateProps } from '../DateInput';
import type { DropdownItem, Props as DropdownProps } from '../Dropdown';
import type { Props as RadioProps, RadioOption } from '../RadioGroup';
import type { Props as SearchProps } from '../Search';
import type { Props as SwitchProps } from '../Switch';
import type { Props as TextProps } from '../TextInput';
import UI from './UI'; // eslint-disable-line import/no-cycle

export type InputProps = {
  disabled?: boolean;
  error?: string;
  id?: string;
  inline?: boolean;
  inputRef?: (ref: HTMLInputElement | HTMLTextAreaElement | InputMask | null) => void;
  items?: DropdownItem[];
  onChange?: (value: unknown) => void;
  onClick?: (value?: unknown) => void;
  options?: Option[] | Record<string, Option> | RadioOption[];
  type?: Type;
  value?: unknown;
};

export enum Type {
  ADDRESS = 'address',
  AUDITED_CHECKBOXES = 'auditedCheckboxes',
  BOOL = 'bool',
  CITY = 'city',
  CHECK = 'check',
  COUNTY = 'county',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  EMAIL = 'email',
  MONEY = 'money',
  NUMBER = 'number',
  PERCENT = 'percent',
  PHONE = 'phone',
  RADIO = 'radio',
  SEARCH = 'search',
  SWITCH = 'switch',
  TEXT = 'text',
  ZIP_CODE = 'zipCode',
}

type BaseProps = {
  className?: string;
  debounceDelay?: number;
  default?: unknown;
  helper?: string;
  id?: string;
  inline?: boolean;
  inputRef?: (ref: HTMLInputElement | HTMLTextAreaElement | InputMask | null) => void;
  label?: string;
  labelRightUI?: ReactElement;
  onChange?: (value?: unknown) => void;
  onDebounce?: (value?: unknown) => void;
  onRemove?: (id: string) => void;
  options?: Option[] | Record<string, Option>;
  removable?: boolean;
  readOnly?: boolean;
  renderOption?: (props: { label: string; value: unknown }) => JSX.Element;
  required?: boolean;
  style?: CSSProperties;
  tooltip?: string;
  transformInput?: (value: unknown) => unknown;
  transformOutput?: (value: unknown) => unknown;
  type?: Type;
  value?: unknown;
  width?: number;
};

type InputComponentProps =
  | (Omit<AddressProps, 'onChange'> &
      Omit<BaseProps, 'onChange'> & {
        onChange?: (value?: Address) => void;
        type: Type.ADDRESS | Type.CITY | Type.COUNTY;
      })
  | (Omit<BoolProps, 'onChange'> &
      Omit<BaseProps, 'onChange'> & { onChange?: (value?: boolean) => void; type: Type.BOOL })
  | (CheckProps & Omit<BaseProps, 'onChange'> & { type: Type.CHECK })
  | (Omit<DateProps, 'onChange'> &
      Omit<BaseProps, 'onChange'> & { onChange?: (value?: m.MomentInput) => void; type: Type.DATE })
  | (DropdownProps & BaseProps & { type: Type.DROPDOWN })
  | (Omit<TextProps, 'onChange'> &
      Omit<BaseProps, 'onChange' | 'transformInput' | 'transformOutput'> & {
        onChange?: (value?: number) => void;
        transformInput?: (value: number) => unknown;
        transformOutput?: (value: number) => unknown;
        type: Type.MONEY | Type.NUMBER | Type.PERCENT;
      })
  | (RadioProps & BaseProps & { type: Type.RADIO })
  | (Omit<SearchProps, 'onChange'> &
      Omit<BaseProps, 'onChange'> & {
        onChange?: (value?: number | string | (number | string)[]) => void;
        type: Type.AUDITED_CHECKBOXES | Type.SEARCH;
      })
  | (SwitchProps & BaseProps & { type: Type.SWITCH })
  | (Omit<TextProps, 'onChange'> &
      Omit<BaseProps, 'onChange'> & {
        onChange?: (value?: string) => void;
        type?: Type.EMAIL | Type.PHONE | Type.TEXT | Type.ZIP_CODE;
      });

function Input({
  debounceDelay,
  default: defaultValue,
  id,
  inline,
  onChange: propsOnChange,
  onDebounce,
  onRemove: propOnRemove,
  options,
  readOnly,
  removable,
  required,
  style,
  transformInput,
  transformOutput,
  type = Type.TEXT,
  value: valueProp,
  width,
  ...rest
}: InputComponentProps): ReactElement {
  const { debounce, onChange, onRemove, register, unregister, validate, value } =
    useContext(Context);

  const componentDebounce = debounceDelay || debounce;
  const debounceRef: { current: { value?: unknown } } = useRef({});
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

  const saveValue = useCallback(
    (value: unknown) => {
      if (id && onChange && !readOnly) {
        void onChange(id, value);
      }
      if (propsOnChange) {
        // @ts-expect-error not sure how to fix
        propsOnChange(value);
      }
    },
    [id, onChange, propsOnChange, readOnly]
  );

  const handleChange = useCallback(
    (inputValue: unknown) => {
      // @ts-expect-error coercing types here so that it's not required when using the component
      const newValue = transformOutput ? transformOutput(inputValue) : inputValue;
      if (componentDebounce && onDebounce) {
        debounceRef.current.value = newValue;
        setTimeoutId(timeoutId => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          return setTimeout(() => onDebounce(debounceRef.current.value), componentDebounce);
        });
      } else if (componentDebounce) {
        debounceRef.current.value = newValue;
        setTimeoutId(timeoutId => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          return setTimeout(() => saveValue(debounceRef.current.value), componentDebounce);
        });
      } else {
        saveValue(newValue);
      }
    },
    [componentDebounce, onDebounce, saveValue, transformOutput]
  );

  useEffect(() => {
    if (defaultValue !== undefined) {
      handleChange(defaultValue);
    }
  }, [defaultValue, handleChange]);

  useEffect(() => {
    if (register && id) {
      register({ id, required, type });
    }
    if (unregister && id) {
      return () => unregister(id);
    }
  }, [id, register, required, type, unregister]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleRemove = useCallback(() => {
    if (id && onRemove) {
      onRemove(id);
    }
    if (id && propOnRemove) {
      propOnRemove(id);
    }
  }, [id, onRemove, propOnRemove]);

  let newStyle = style;
  if (width) {
    newStyle = { ...(style || {}), width };
  }

  let inputValue = valueProp;
  if (valueProp === undefined) {
    inputValue = id ? _.get(value, id) : valueProp;
  }
  if (transformInput) {
    // @ts-expect-error coercing types here so that it's not required when using the component
    inputValue = transformInput(inputValue);
  }

  return (
    <UI
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{
        ...rest,
        id,
        inline,
        onChange: handleChange,
        //@ts-expect-error TODO Fix types in Util
        options: U.memoize(useMemo, options),
        onRemove: removable ? handleRemove : undefined,
        required,
        //@ts-expect-error TODO Fix types in Util
        style: U.memoize(useMemo, newStyle),
        type,
        validate,
        //@ts-expect-error TODO Fix types in Util
        value: U.memoize(useMemo, inputValue),
      }}
    />
  );
}

type Props = InputComponentProps & {
  array?: boolean;
  arrayClass?: string;
  id?: string;
  label?: string;
};

export default function Group({
  array,
  arrayClass = 'flex',
  id,
  label,
  ...rest
}: Props): JSX.Element {
  const { value } = useContext(Context);
  if (array && id) {
    return (
      <div className={arrayClass}>
        {_.times(U.length(value, id) + 1, i => (
          <Input
            {...rest} // eslint-disable-line react/jsx-props-no-spreading
            id={`${id}.${i}`}
            className={`flex-1 ${i ? 'ml-4' : ''}`}
            key={i}
            label={`${label || _.startCase(id)} ${i + 1}`}
            removable={Boolean(i || _.get(value, `${id}.${i}`) !== undefined)}
          />
        ))}
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Input {...{ id, label, ...rest }} />;
}
