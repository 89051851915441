import React from 'react';
import moment from 'moment';
import Alert from '@/components/core/Alert';
import { Props as ButtonProps } from '@/components/core/Button';
import Text from '@/components/core/Text';
import { supportPhoneNumber } from '@/config/const';

const alertVariantByStatus = {
  confirm: 'warning',
  continue: 'warning',
  cancelRequested: 'error',
  cancelled: 'error',
  reviewing: 'success',
};

type Props = {
  status: keyof typeof alertVariantByStatus;
  dateTime: moment.MomentInput;
  name: string;
  vendorName: string;
  onButtonClick?: () => Promise<void> | void;
  timezone: string;
};

/**
 * Local component for FTC Flow Displays the current status on the CX Portal Page
 * And has a button to launch into the new flow screens.
 */
export default function ConfirmRepairBanner({
  status,
  dateTime,
  name,
  vendorName,
  onButtonClick,
  timezone,
}: Props): JSX.Element {
  const copyByStatus = {
    confirm: {
      title: 'Confirm Your Repair Details',
      description: `Before your repair appointment can be scheduled, please confirm the information we have received from ${vendorName} is correct.`,
    },
    continue: {
      title: 'Confirm Your Repair Details',
      description: `Before your repair appointment can be scheduled, please confirm the information we have received from ${vendorName} is correct.`,
    },
    cancelRequested: {
      title: 'Cancelation in Progress',
      description:
        'The window to confirm your repair has expired. Please contact an agent to prevent it from being canceled.',
    },
    cancelled: {
      title: 'Your Work Order Has Been Canceled',
      description: `The window to confirm your repair has expired. For further assistance, please contact ${vendorName} directly.`,
    },
    reviewing: {
      title: 'We’re Getting Everything Ready For Your Repair',
      description: `Thank you, ${name}! We will reach out within the next 48 hours.`,
    },
  };

  const handleButtonClick = () => {
    if (onButtonClick) {
      void onButtonClick();
    }
  };

  const supportButtonProps: ButtonProps = {
    size: 'medium',
    children: 'Contact Support',
    href: 'tel:+' + supportPhoneNumber,
  };

  const startButtonProps: ButtonProps = {
    size: 'medium',
    children: 'Start',
    onClick: () => handleButtonClick(),
  };

  const continueButtonProps: ButtonProps = {
    size: 'medium',
    children: 'Continue',
    onClick: () => handleButtonClick(),
  };

  const buttonOneByStatus = {
    confirm: startButtonProps,
    continue: continueButtonProps,
    cancelRequested: supportButtonProps,
    cancelled: undefined,
    reviewing: undefined,
  };
  const buttonTwoByStatus = {
    confirm: undefined,
    continue: undefined,
    cancelRequested: undefined,
    cancelled: undefined,
    reviewing: undefined,
  };
  const date = moment(dateTime).tz(timezone).format('MMM D, YYYY');
  const time = moment(dateTime).tz(timezone).format('h:mma (zz)');

  const confirmContinueHelperText = (
    <div className="flex place-items-center space-x-1">
      <Text className="text-grey-dark" type="helper">
        Please confirm by <b>{date}</b> at <b>{time}</b> or your work order may be canceled
      </Text>
    </div>
  );

  const cancelHelperText = (
    <div className="flex place-items-center space-x-1">
      <Text className="text-grey-dark" type="helper">
        Expired on <b>{date}</b> at <b>{time}</b>
      </Text>
    </div>
  );

  const helperTextByStatus = {
    confirm: confirmContinueHelperText,
    continue: confirmContinueHelperText,
    cancelRequested: cancelHelperText,
    cancelled: cancelHelperText,
    reviewing: undefined,
  };

  return (
    <Alert
      variant={alertVariantByStatus[status] as 'warning' | 'error' | 'success'}
      title={copyByStatus[status].title}
      primaryButtonProps={buttonOneByStatus[status]}
      secondaryButtonProps={buttonTwoByStatus[status]}
      helperText={helperTextByStatus[status]}
    >
      {copyByStatus[status].description}{' '}
    </Alert>
  );
}
