import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import store from 'store2';

class Step8 extends React.Component {
  constructor(p) {
    super(p);
    this.next = this.next.bind(this);
  }

  next() {
    if (this.props.me.userId) {
      const returnTo = store.get('login.returnTo');
      if (returnTo) {
        global.location = returnTo;
      } else {
        const redirect = store.get('login.redirect') || 'tasks';
        store.remove('login.redirect');
        this.props.dispatch(push(`/${redirect}`));
      }
    } else {
      this.props.jumpToStep(0);
    }
  }

  render() {
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 className="weight-500">Successful Password Reset</h3>
            <p className="text-muted">
              You can now use your new password to log in to your account! 🙌
            </p>
            <button onClick={this.next} className="primary mt-4">
              {this.props.me.userId ? 'Go to my account' : 'Login'}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(s => ({
  me: s.me,
}))(Step8);
