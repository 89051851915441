import React, { useState } from 'react';
import * as Table from '@nanaio/table';
import { copyText } from '@/utils';
import { Icon, Modal,Text } from '../../core';
import theme from '../../theme';

type Props = {
  databaseIdToTableIdToLastPaginatedQuery: Table.Depth2<Record<string, unknown> | string>;
  onClose: () => void;
  query: Table.Query;
};

export default function Query({
  databaseIdToTableIdToLastPaginatedQuery,
  onClose,
  query,
}: Props): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);

  const lastPaginatedQuery =
    databaseIdToTableIdToLastPaginatedQuery[query.databaseId]?.[query.table];
  const queryText =
    typeof lastPaginatedQuery === 'object'
      ? JSON.stringify(lastPaginatedQuery)
      : String(lastPaginatedQuery);

  const copyQuery = () => {
    copyText(queryText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Query" />
      <Modal.Body className="flex-row p-4">
        <div className="flex-1">
          <Text>{queryText}</Text>
        </div>
        <Icon
          color={isCopied ? theme.colors.success : theme.colors.primaryCTA}
          name={isCopied ? 'check' : 'content_copy'}
          onClick={copyQuery}
        />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
