import React from 'react';
import { Task } from '@nanaio/util';
import { Alert } from '@/components';
import type { Props as AlertProps } from '@/components/core/Alert';
import type { Status } from '.';

const NO_ORG_FOUND = 'NO_ORG_FOUND';

type AlertOptions = {
  [K in Status]: {
    variant: AlertProps['variant'];
    title: string;
    children: string;
  };
};

type Props = {
  task: Task;
  status: Status;
};
export default function TaskClosedAlert({ task, status }: Props): JSX.Element {
  const orgName = task.customer?.org?.name || NO_ORG_FOUND;

  const alertByStatus: AlertOptions = {
    closed: {
      variant: 'success',
      title: 'Your Work Order Has Been Closed',
      children: `For further assistance, please contact ${orgName} directly.`,
    },
    returnParts: {
      variant: 'warning',
      title: 'Action Required: Return Parts',
      children:
        'We’ll send a return label with instructions to your email within 5 to 7 business days.',
    },
  };

  const alert = alertByStatus[status];

  return (
    <Alert className="mb-1" variant={alert.variant} title={alert.title}>
      {alert.children}
    </Alert>
  );
}
