import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChargeItem, ChargeItemType, ChargeType, T, Task, U } from '@nanaio/util';
import _, { Dictionary } from 'lodash';
import nullthrows from 'nullthrows';
import { Button, Input, InputType,Modal } from '@/components';
import Form, { Ref } from '@/components/form/Form';
import { useLegacySelector } from '@/hooks';

const additionalChargeTypeOptions = [
  'Bonus',
  'Copay',
  'Discount',
  'Insurance / Claim Correction',
  'Labor Paid on Recall',
  'Overpayment',
  'Part Credit',
  'Part Shipping',
  'Propay Correction',
  'Recall',
  'Tax Collection',
  'Vendor Underpayment',
];

const OTHER_MIN_LENGTH = 25;

type ChargeOptionsResponse = { chargeTypes: ChargeType[]; taskId: string }[];

type Props = {
  id: string;
  onClose: () => void;
  task: Task;
  type: 'invoice' | 'estimate';
};

export default function EditItem({ id, onClose, task, type }: Props): JSX.Element {
  const isNew = id === 'new';
  const { item, org, roles, workOrder } = useLegacySelector(state => {
    const workOrder = state.workorders[task.metadata.workOrderId];
    const item = isNew ? undefined : workOrder[type]?.chargeItems.find(item => item._id === id);
    const orgId = task.customer?.org?.id || '';
    const org = state.orgs[orgId];
    const roles = U.user.roles(state);
    return { item, org, roles, task, workOrder };
  });

  const [chargeTypes, setChargeTypes] = useState<Dictionary<ChargeType>>();
  const [error, setError] = useState<string>();
  const origForm = _.pick(item, [
    'customerOwner',
    'chargeType',
    'description',
    'discountOwner',
    'discountPrice',
    'proOwner',
    'proPrice',
    'quantity',
    'type',
    'unitPrice',
  ]);
  const [form, setForm] = useState(_.cloneDeep(origForm));
  const changesMade = !_.isEqual(form, origForm);
  const submitRef = React.createRef<Ref>();

  useEffect(() => {
    const getChargeOptions = async () => {
      const response = await U.api<ChargeOptionsResponse>(
        'get',
        `workOrders/${workOrder.id}/chargeOptions/support`
      );
      if (response && 'errMsg' in response) {
        setError(response.errMsg);
      } else {
        const newChargeTypes = _.keyBy(
          _.map(response.find(option => option.taskId === task.id)?.chargeTypes, option => ({
            ...option,
            id: option.type,
          })),
          'type'
        );

        // TODO Using role to filter here is not the proper way to solve the problem, really the API should be
        // filtering.  However the user check in the API call is being overidden by the /support option.
        // There are also other this.props.roles checks on this screen that should probably be refactored.
        if (!roles.agentManager && !roles.dispatch && !roles.vm) {
          newChargeTypes.adjustment.hide = true;
        }
        setChargeTypes(newChargeTypes);
      }
    };
    void getChargeOptions();
  }, [roles.agentManager, roles.dispatch, roles.vm, task.id, workOrder.id]);

  const handleRemove = useCallback(async () => {
    const response = await U.api<ChargeItem>('delete', `chargeItems/${id}`);
    if (response && 'errMsg' in response) {
      return setError(response.errMsg);
    }
    await U.api('get', `workOrders/${workOrder.id}`, ['save']);
    onClose();
  }, [id, onClose, workOrder.id]);

  const handleSubmit = useCallback(async () => {
    if (!nullthrows(submitRef.current).submit()) {
      return;
    }
    if (!form.type || !chargeTypes) {
      return;
    }
    const typeSettings = chargeTypes[form.type];
    if (!typeSettings) {
      return setError('Invalid type');
    }
    if (
      form.type === 'adjustment' &&
      form.description &&
      !additionalChargeTypeOptions.includes(form.description) &&
      form.description.length < OTHER_MIN_LENGTH
    ) {
      return setError(`Description must be at least ${OTHER_MIN_LENGTH} characters`);
    }

    const submitItem = _.cloneDeep(form);

    if (submitItem.type === 'parking') {
      submitItem.proPrice = submitItem.unitPrice;
    }
    let response;
    if (isNew) {
      response = await U.api<ChargeItem>('post', 'chargeItems', submitItem);
    } else {
      const updatedItem = _.pick(submitItem, [
        'description',
        'unitPrice',
        'proPrice',
        'proOwner',
        'quantity',
        'discountPrice',
        'discountOwner',
        'customerOwner',
      ]);
      response = await U.api<ChargeItem>('put', `chargeItems/${id}`, updatedItem);
    }
    if (response && 'errMsg' in response) {
      return setError(response.errMsg);
    }
    await U.api('get', `workOrders/${workOrder.id}`, ['save']);
    onClose();
  }, [form, chargeTypes, isNew, workOrder.id, onClose, id, submitRef]);

  const handleTypeChange = useCallback(
    (typeId: string) => {
      if (!chargeTypes) {
        return;
      }
      const chargeType = chargeTypes[typeId];

      if (!chargeType) {
        return;
      }
      // reset the price
      setForm({
        description: chargeType.descriptionRequired ? undefined : chargeType.name,
        isInvoice: type === 'invoice',
        proOwner: '',
        proPrice: chargeType.propay,
        quantity: 1,
        taskId: task.id,
        type: chargeType.type as ChargeItemType,
        unitPrice: chargeType.pricePerUnit || 0,
        workOrderId: workOrder.id,
        customerOwner: '',
      });
    },
    [chargeTypes, task.id, type, workOrder.id]
  );

  const typeSettings = useMemo(() => {
    if (!chargeTypes || !form.type) {
      return undefined;
    }
    return chargeTypes[form.type];
  }, [chargeTypes, form?.type]);

  return (
    <Modal isOpen={!!id} onClose={onClose} hideOnBackdropClick={false}>
      <Modal.Header title={isNew ? 'Add Item' : 'Edit Item'} />
      <Modal.Body className="p-4">
        <Form onChange={setForm} ref={submitRef} value={form}>
          <div className="text-danger">{error}</div>
          {isNew && (
            <Input
              id="type"
              options={_.values(chargeTypes).filter(type => !type.hide)}
              onChange={value => handleTypeChange(value as string)}
              required
              sort
              type={InputType.SEARCH}
            />
          )}

          {form?.type && (
            <div>
              {form.type === 'adjustment' ? (
                <Input
                  id="description"
                  label="Adjustment Reason"
                  options={additionalChargeTypeOptions}
                  required
                  sort
                  type={InputType.SEARCH}
                  freeformOther
                  injectOtherOption
                  otherMinLength={OTHER_MIN_LENGTH}
                />
              ) : (
                <Input id="description" label="Description" required type={InputType.TEXT} />
              )}
              <Input
                id="unitPrice"
                disabled={!typeSettings?.priceEditable}
                required
                type={InputType.MONEY}
              />
              {typeSettings?.payEditable && <Input id="proPrice" type={InputType.MONEY} />}
              <Input
                id="quantity"
                label="Qty"
                disabled={typeSettings?.quantityFixed}
                type={InputType.NUMBER}
              />
              {typeSettings?.ownerOptions && (
                <Input
                  id="proOwner"
                  label="Owner"
                  options={typeSettings.ownerOptions}
                  required={typeSettings?.ownerRequired}
                  type={InputType.SEARCH}
                />
              )}
              {roles.discount && (
                <div>
                  <Input id="discountPrice" type={InputType.MONEY} />
                  <Input
                    id="discountOwner"
                    options={[
                      { id: 'nana', name: 'Nana' },
                      ...(typeSettings?.ownerOptions ? typeSettings.ownerOptions : []),
                      { id: 'shared', name: 'Shared' },
                    ]}
                    required={!!form.discountPrice}
                    type={InputType.SEARCH}
                  />
                </div>
              )}
              {T.type(org) === 'b2b' && (
                <Input
                  id="customerOwner"
                  label="Customer Owner"
                  options={[
                    // @ts-expect-error TODO name field is not on User profile type but is returned from server
                    { id: workOrder.cx.id, name: workOrder.cx.name }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
                    { id: workOrder.org?.id, name: workOrder.org?.name },
                  ]}
                  type={InputType.SEARCH}
                />
              )}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isNew && (
          <Button onClick={handleRemove} size="medium">
            Remove
          </Button>
        )}
        <Button onClick={handleSubmit} disabled={!changesMade} size="medium" submit>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
