import React from 'react';
import { connect } from 'react-redux';
import { T, U } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@/components';
import { Modal, Money, Select, Text } from '../../com/ui/form';

class Charge extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.oneOf(['customer', 'org']),
  };

  static childContextTypes = { t: PropTypes.object };

  getChildContext = () => ({ t: this });

  constructor(p) {
    super(p);
    this.state = { uncheckedItems: {} };
    this.getGatewayOptions = this.getGatewayOptions.bind(this);
    this.onGatewayChange = this.onGatewayChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.toggleOverrideMaxAmount = this.toggleOverrideMaxAmount.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!this.props.isOpen && p.isOpen) {
      this.setState({
        uncheckedItems: {},
        amount: p.bill.balance,
        paymentGateway: p.user === 'customer' && T.hasCard(p.workorder) ? 'card' : false,
      });
    }
  }

  getGatewayOptions() {
    return _.compact([
      this.props.user === 'customer' &&
        T.hasCard(this.props.workorder) && {
          id: 'card',
          name: `Card ending in ${_.get(this.props.workorder, 'payment.card.last4')}`,
        },
      this.props.user === 'org' && { id: 'bankAccount', name: 'Bank Account' },
      'Cash',
      'Cheque',
      this.props.user === 'customer' && 'QuickBooks',
      'Square',
      'Stripe',
      this.props.user === 'org' &&
        T.hasCard(this.props.workorder) && {
          id: 'card',
          name: `Card ending in ${_.get(this.props.workorder, 'payment.card.last4')}`,
        },
    ]);
  }

  onGatewayChange(gateway) {
    if (gateway === 'bankAccount' && T.hasOrg(this.props.workorder, 'americanHomeShield')) {
      this.setState({
        checkNumber: '',
        bankName: 'SILICON VALLEY BANK',
        depositAcct: '0055',
        transitId: '0399',
      });
    } else {
      this.setState({ checkNumber: '', bankName: '', depositAcct: '', transitId: '' });
    }
  }

  async onSubmit() {
    const { bill } = this.props;
    if (!this.state.overrideMaxAmount) {
      if (bill.balance >= 0 && this.state.amount > bill.balance) {
        return this.setState({ error: `Max amount is ${U.toMoney(bill.balance)}` });
      }
      if (bill.balance < 0 && this.state.amount < bill.balance) {
        return this.setState({ error: `Max amount is ${U.toMoney(bill.balance)}` });
      }
    }
    if (this.state.amount !== bill.balance && !this.state.reason) {
      return this.setState({ error: 'Please enter a reason for partial charge' });
    }
    if (!this.state.paymentGateway) {
      return this.setState({ error: 'Payment gateway required' });
    }
    if (this.state.paymentGateway === 'Cheque' && !this.state.checkNumber) {
      return this.setState({ error: 'Check number required' });
    }
    if (this.state.paymentGateway === 'bankAccount') {
      if (!this.state.bankName) {
        return this.setState({ error: 'Bank Account required' });
      }
      if (!this.state.depositAcct) {
        return this.setState({ error: 'Deposit Account required' });
      }
    }
    const body = {
      amount: this.state.amount,
      metadata: _.pick(this.state, [
        'checkNumber',
        'paymentNumber',
        'paymentDate',
        'bankName',
        'depositAcct',
        'transitId',
      ]),
      paymentGateway: this.state.paymentGateway,
      toOrg: this.props.user === 'org',
    };
    const r = await U.api('put', `workorders/${this.props.workorder.id}/charge`, body, true);
    if (r.errMsg) {
      this.setState({ error: r.errMsg });
    } else if (this.state.reason) {
      const changes = [{ action: 'add', path: 'notes', value: { content: this.state.reason } }];
      U.api('put', `tasks/${this.props.job.id}`, changes, true);
      this.props.onClose();
    }
  }

  toggleItem(item) {
    const { bill, items } = this.props;
    this.setState(s => {
      s.uncheckedItems[item._id] = !s.uncheckedItems[item._id];
      s.amount = _.sum(
        _.values(items)
          .filter(i => !s.uncheckedItems[i._id])
          .map(i => i.quantity * i.unitPrice)
      );
      if (bill.balance >= 0 && s.amount > bill.balance) {
        s.amount = bill.balance;
      } else if (bill.balance < 0 && s.amount < bill.balance) {
        s.amount = bill.balance;
      }
      return s;
    });
  }

  toggleOverrideMaxAmount() {
    this.setState({ overrideMaxAmount: !this.state.overrideMaxAmount });
  }

  render() {
    if (!this.props.workorder.invoice) {
      return null;
    }
    return (
      <Modal
        title="Charge"
        submit={this.onSubmit}
        isOpen={this.props.isOpen}
        saveText="Charge"
        className="partModal sm"
        onClose={this.props.onClose}
      >
        {_.startsWith(this.state.error, 'Max amount is') && (
          <div
            className="pointer flex  flex-row items-center"
            onClick={this.toggleOverrideMaxAmount}
          >
            {this.state.overrideMaxAmount ? (
              <Icon name="check_box_outline" className="text-danger" size={16} />
            ) : (
              <Icon name="check_box_outline_blank" className="text-danger" size={16} />
            )}
            {' Override max amount warning'}
          </div>
        )}
        <p className="text-danger">{this.state.error}</p>
        {_.map(this.props.workorder.invoice.chargeItems, i => (
          <div
            key={i._id}
            className="pointer flex flex-row items-center"
            onClick={() => this.toggleItem(i)}
          >
            {this.state.uncheckedItems[i._id] ? (
              <Icon name="check_box_outline_blank" size={16} />
            ) : (
              <Icon name="check_box_outline" size={16} />
            )}
            <div className="ml-3" style={{ minWidth: '65px' }}>
              {U.toMoney(i.unitPrice * i.quantity)}
            </div>
            <div className="ml-3">
              {i.description}
              {i.type === 'part' && ' (tax included)'}
            </div>
          </div>
        ))}
        <Money id="amount" className="mt-3" />
        <Select
          id="paymentGateway"
          options={this.getGatewayOptions()}
          onChange={this.onGatewayChange}
        />
        {this.state.amount !== _.get(this.props.bill, 'balance') && (
          <Text id="reason" label="Reason for partial charge" />
        )}
        {this.state.paymentGateway === 'Cheque' && <Text id="checkNumber" req />}
        {this.state.paymentGateway === 'bankAccount' && (
          <div>
            <Text id="bankName" req />
            <Text id="depositAcct" req />
            <Text id="transitId" />
            <Text id="paymentNumber" />
            <Text id="paymentDate" />
          </div>
        )}
      </Modal>
    );
  }
}

export default connect((s, p) => {
  const job = s.tasks[global.id()];
  const workorder = s.workorders[job.metadata.workOrderId];
  const bill = _.get(workorder, `invoice.to${_.startCase(p.user)}`);
  const items = _.merge({}, _.get(bill, 'chargeItems'));
  _.mapValues(items, (i, id) => {
    if (i.type === 'tax') {
      delete items[id];
    } else if (i.type === 'discount') {
      items[id].amount *= -1;
    }
    if (items[_.get(i, 'metadata.partChargeItemId')]) {
      items[i.metadata.partChargeItemId].amount += i.amount;
    }
  });
  return { bill, items, job, workorder };
})(Charge);
