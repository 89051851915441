import React from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import m from 'moment';
import { Input, InputType } from '../../form';

const filterUi = ({
  onChange,
  value,
}: {
  onChange?: (
    path: 'end' | 'endDaysAgo' | 'start' | 'startDaysAgo',
    value?: m.MomentInput | number
  ) => void;
  value?: Range;
}): JSX.Element => (
  <div>
    <div className="d-flex justify-content-between align-items-center">
      <Input
        className="flex-1"
        cypressId="start-date"
        label="From"
        onChange={value => onChange && onChange('start', value)}
        type={InputType.DATE}
        value={value?.start}
      />
      <div className="px-2">
        <p className="text-muted">to</p>
      </div>
      <Input
        className="flex-1"
        cypressId="end-date"
        endOfDay
        label="To"
        onChange={value => onChange && onChange('end', value)}
        type={InputType.DATE}
        value={value?.end}
      />
    </div>
    <div className="d-flex justify-content-between align-items-center">
      <Input
        className="flex-1"
        label="Start Days Ago"
        onChange={value => onChange && onChange('startDaysAgo', value)}
        type={InputType.NUMBER}
        value={value?.startDaysAgo}
      />
      <div className="px-2">
        <p className="text-muted">to</p>
      </div>
      <Input
        className="flex-1"
        label="End Days Ago"
        onChange={value => onChange && onChange('endDaysAgo', value)}
        type={InputType.NUMBER}
        value={value?.endDaysAgo}
      />
    </div>
  </div>
);

export type Range = {
  end?: m.MomentInput;
  endDaysAgo?: number;
  start?: m.MomentInput;
  startDaysAgo?: number;
};

const timeModule: Partial<Table.Column> = { filterUi };

export default timeModule;
