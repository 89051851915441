import React, { ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

export const borderByVariant = {
  elevated: 'border border-grey-medium',
  flat: '',
  floating: 'border border-grey-medium',
  lifted: 'border border-grey-medium',
};

export const shadowByVariant = {
  elevated: 'shadow-elevation',
  flat: '',
  floating: 'shadow-floating',
  lifted: 'shadow-lifted',
};

export type Props = {
  children: ReactNode;
  className?: string;
  variant: keyof typeof borderByVariant;
};

export default function Paper({ children, className, variant }: Props): JSX.Element | null {
  const boxClassNames = classnames(
    'rounded-lg bg-white',
    borderByVariant[variant],
    shadowByVariant[variant],
    className
  );

  return <div className={boxClassNames}>{children}</div>;
}
