import React from 'react';
import _ from 'lodash';
import { Text } from '@/components';
import { companyName } from '@/config/const';
import { useLegacySelector } from '@/hooks';

export default function ReferCard(): JSX.Element {
  const users = useLegacySelector(state => state.users);
  const me = useLegacySelector(state => state.me);
  const code = _.get(users[me.userId], 'shortId');

  return (
    <div className="mt-5 flex flex-col rounded bg-background-lightest md:flex-row">
      <div className="w-full px-3 py-4 md:w-1/2">
        <Text type="headline-6">Invite a friend to {companyName}, and you both get $10!</Text>
        <Text color="grey.dark" className="mt-1">
          We value friendship. Get $10 credit when they complete their first job.
        </Text>

        <Text color="primaryCTA" className="mt-3">
          Share your code, {code}
        </Text>
      </div>
      <div className="w-full md:w-1/2">
        <img
          alt="refer a friend"
          src="/img/refer-friend.jpg"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </div>
    </div>
  );
}
