import React from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import PropTypes from 'prop-types';
import { availabilityPropType, capacityPropType, firstRowHeight } from '../util';
import DayHeader from './DayHeader';
import WeekSelector from './WeekSelector';

export default function HeaderRow({
  availability,
  capacity,
  isLoading,
  loadWeek,
  mapDate,
  mapIsOpen,
  scheduleStart,
  setGoToDateIsOpen,
  setMapDate,
  setMapIsOpen,
  setSearchTechIsOpen,
  startTimes,
  updateSlot,
  visit,
  weekIsLoading,
  workOrder,
}) {
  return {
    key: 'header',
    height: firstRowHeight,
    content: [
      <WeekSelector
        {...{
          isLoading,
          loadWeek,
          mapIsOpen,
          scheduleStart,
          setGoToDateIsOpen,
          setMapIsOpen,
          setSearchTechIsOpen,
          weekIsLoading,
          workOrder,
        }}
      />,
      ..._.times(7, i => {
        return (
          <DayHeader
            {...{
              availability: availability.slice(i * 3, (i + 1) * 3),
              capacity: capacity.slice(i * 3, (i + 1) * 3),
              date: mt(scheduleStart).clone().add(i, 'day'),
              dayIndex: i,
              key: i,
              mapDate,
              mapIsOpen,
              setMapDate,
              startTimes: startTimes.slice(i * 3, (i + 1) * 3),
              updateSlot,
              visit,
            }}
          />
        );
      }),
    ],
  };
}

HeaderRow.propTypes = {
  availability: availabilityPropType.isRequired,
  capacity: PropTypes.arrayOf(capacityPropType).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadWeek: PropTypes.func.isRequired,
  mapDate: U.timePropType,
  mapIsOpen: PropTypes.bool.isRequired,
  scheduleStart: U.timePropType.isRequired,
  setGoToDateIsOpen: PropTypes.func.isRequired,
  setMapDate: PropTypes.func.isRequired,
  setMapIsOpen: PropTypes.func.isRequired,
  setSearchTechIsOpen: PropTypes.func.isRequired,
  startTimes: PropTypes.arrayOf(U.timePropType).isRequired,
  updateSlot: PropTypes.func.isRequired,
  visit: W.visitPropType.isRequired,
  weekIsLoading: PropTypes.bool,
  workOrder: W.propType.isRequired,
};
