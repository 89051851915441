import React, { useState } from 'react';
import { U, W } from '@nanaio/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, RadioGroup, SearchInput, Text } from '@/components';

const options = [
  {
    name: 'Fulfillment',
    options: ['No Eligible Technicians Available', 'Technician Resent Job'],
  },
  { name: 'No Service Needed', options: ['Needs Time to Consider Options'] },
  {
    name: 'Other',
    options: ['Health & Safety Concerns', 'Personal Emergency', 'Unknown', 'Weather'],
  },
  { name: 'Parts', options: ['Parts Backordered', 'Parts not Arrived'] },
  {
    name: 'Scheduling',
    options: [
      'Customer not Available',
      'Technician is not available',
      'Unable to Meet Requested Time Frame',
    ],
  },
  {
    name: 'Service Issues',
    options: [
      'Customer is not responsive',
      'Customer no Show',
      'Customer Requested Another Technician',
      'Poor Technician Behavior',
      'Punctuality',
      'Technician is not Responsive',
      'Technician no Show',
      'Unable to Diagnose or Repair',
    ],
  },
  {
    name: 'System',
    options: [
      'Cancelled Job',
      'Created as a Test',
      'Created by Accident',
      'Duplicate',
      'Incorrect Address',
    ],
  },
];

export default function CancelVisit({ onClose, visitId, workOrder }) {
  const [cancelledBy, setCancelledBy] = useState();
  const [error, setError] = useState();
  const [notify, setNotify] = useState('both');
  const [reason, setReason] = useState();

  const handleSave = async () => {
    if (!reason) {
      return setError('Select a cancellation reason');
    }
    const visit = _.cloneDeep(workOrder.visits[visitId]);
    visit.cancelledBy = cancelledBy;
    visit.cancelReason = reason;
    visit.cx.status = W.VisitCustomerStatus.CANCELLED;
    const response = await U.api(
      'put',
      `workOrders/${workOrder.id}/visits/${visitId}?notify=${notify}`,
      visit,
      ['save']
    );
    if (response.errMsg) {
      return setError(response.errMsg);
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Cancel Visit" />
      <Modal.Body className="p-4">
        <Text className="text-center" color="grey.dark">
          Are you sure you want to cancel this visit?
        </Text>
        <Text className="mt-4" type="label">
          Reason for Cancellation*
        </Text>
        <SearchInput grouped onChange={setReason} options={options} value={reason} />
        <Text className="mt-4" type="label">
          Notify
        </Text>
        <SearchInput
          capitalize
          onChange={setNotify}
          options={['both', 'customer', 'none', 'pro']}
          value={notify}
        />
        <Text className="mt-4" type="label">
          Cancelled By*
        </Text>
        <RadioGroup
          options={[
            { text: 'Customer', value: 'customer' },
            { text: 'Pro', value: 'pro' },
            { text: 'Nana', value: 'nana' },
            { text: 'Vendor', value: 'vendor' },
          ]}
          value={cancelledBy}
          onClick={setCancelledBy}
        />
        <Text color="danger">{error}</Text>
      </Modal.Body>
      <Modal.Footer onSave={handleSave} />
    </Modal>
  );
}

CancelVisit.propTypes = {
  onClose: PropTypes.func.isRequired,
  visitId: PropTypes.number.isRequired,
  workOrder: W.propType,
};
