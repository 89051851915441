import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Link from '@/com/ui/link';
import { Avatar, Button, Icon, Text } from '@/components';
import { companyLogo, companyLogoAndName, companyName } from '@/config/const';
import type { BookingState, StepName } from '@/pages/book/steps-V2/util';
import HelpModal from './HelpModal';

const labelByStepName = {
  Details: 'Issue',
  Model: 'Model',
  Availability: 'Schedule',
  Contact: 'Contact',
  Address: 'Arrival',
  Card: 'Review',
};

type Props = {
  onChange: (key: string, value: unknown) => void;
  state: BookingState;
  step: number;
  stepName: StepName;
  steps: StepName[];
};

export default function HeaderV2({ onChange, state, step, steps, stepName }: Props): JSX.Element {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const stepCount = steps.length - 2;
  const progress = (100 * step + 1) / stepCount;
  const inFunnel = step > 0 && !['NoAvailability', 'Confirmation'].includes(stepName);
  const visibleSteps = steps.filter(stepName => stepName in labelByStepName) as Array<
    keyof typeof labelByStepName
  >;

  const handleBack = () => {
    onChange('step', state.step - 1);
  };

  return (
    <>
      <div>
        <div className="relative flex items-center justify-between">
          {inFunnel ? (
            <Icon
              className="ml-4"
              name="arrow_left"
              onClick={handleBack}
              size={24}
              color="secondary"
            />
          ) : (
            <Link to="/home" name="home" className="relative mx-5 h-8 w-[158px]">
              <img
                src={companyLogoAndName}
                className="absolute h-8 opacity-0 md:opacity-100"
                alt={companyName}
              />
              <img
                src={companyLogo}
                className="absolute h-8 opacity-100 md:opacity-0"
                alt={companyName}
              />
            </Link>
          )}

          {inFunnel && (
            <Text
              type="page-title"
              color="secondary"
              className="absolute left-1/2 -ml-10 w-20 text-center"
            >
              {step} of {stepCount}
            </Text>
          )}

          <Button className="flex w-auto" variant="link" onClick={() => setShowHelpModal(true)}>
            <Icon className="mr-1 hidden md:block" name="help_outline" color="primaryCTA" />
            <Text color="primaryCTA" type="button">
              Help
            </Text>
          </Button>
        </div>

        {inFunnel && (
          <>
            <div className="mx-5 h-1 rounded bg-background-medium md:hidden">
              <div
                className="h-1 rounded bg-gradient-to-r from-[#141B41] to-[#479DC8] transition-all"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="mb-8 ml-5 mt-3 hidden md:flex">
              {visibleSteps.map((visibleStepName, i) => {
                return (
                  <div className="flex items-center" key={visibleStepName}>
                    {i > 0 && (
                      <Icon
                        name="chevron_right"
                        color="icons.grey"
                        size={24}
                        className="ml-4 mr-3"
                      />
                    )}
                    <Avatar
                      size="small"
                      variant="circle"
                      className={classNames('bg-secondary', {
                        'opacity-50': stepName !== visibleStepName,
                      })}
                      name={String(i + 1)}
                    />
                    <Text type="button" className="-ml-2">
                      {labelByStepName[visibleStepName]}
                    </Text>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <HelpModal isOpen={showHelpModal} onClose={() => setShowHelpModal(false)} />
    </>
  );
}
