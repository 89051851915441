import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Controls,
  MarkerType,
  Position,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { T } from '@nanaio/util';
import { APIError, Button, Text } from '@/components';
import { useLazyLegacyAPI, useLegacyAPI } from '@/hooks';

type NodeData = {
  categoryKey: string;
  statusKey: string;
  entryEffects: string[];
  exitEffects: string[];
  x?: number;
  y?: number;
};

type EdgeData = {
  startStatusKey: string;
  endStatusKey: string;
  effects: [];
};

type GraphData = { categories: { nodes: NodeData[]; edges: EdgeData[] }[] };

type SaveResponse = { success: true };

type NodeLabelProps = {
  data: NodeData;
  color: string;
};

const colors = ['red', 'darkorange', 'gold', 'green', 'blue', 'dimgrey', 'purple', 'brown'];
function getColor(statusKeys: string[], statusKey: string) {
  const index = statusKeys.findIndex(s => s === statusKey);
  return colors[index % colors.length];
}

const getStatusName = (categoryKey: string, statusKey: string) => {
  if (categoryKey === 'part') {
    return T.partStatusOptions.find(option => option.id === statusKey)?.name || statusKey;
  }
  return 'Implement Name Lookup For This Category';
};

function NodeLabel({ color, data }: NodeLabelProps): JSX.Element {
  const { categoryKey, entryEffects, exitEffects, statusKey } = data;
  const statusName = getStatusName(categoryKey, statusKey);
  return (
    <>
      <Text style={{ color }} type="button">
        {statusName}
      </Text>
      {!!entryEffects.length && (
        <>
          <hr />
          <Text>On entry:</Text>
          {entryEffects.map(effect => (
            <Text key={effect}>{effect}</Text>
          ))}
        </>
      )}
      {!!exitEffects.length && (
        <>
          <hr />
          <Text>On exit:</Text>
          {exitEffects.map(effect => (
            <Text key={effect}>{effect}</Text>
          ))}
        </>
      )}
    </>
  );
}

// The following constants are used if there is no saved positioning for any nodes
// so that we can at the very least space them out on the page a bit.
const DEFAULT_NODE_COLUMN_WIDTH = 225;
const DEFAULT_NODE_ROW_WIDTH = DEFAULT_NODE_COLUMN_WIDTH * 5;
const DEFAULT_NODE_ROW_HEIGHT = 150;
const DEFAULT_GRAPH_MARGIN = 50;

function getXPosition(undefinedCount: number, x?: number) {
  if (x !== undefined) {
    return x;
  }
  // try determine a sane (or at least, not too insane) default position
  return (
    ((undefinedCount * DEFAULT_NODE_COLUMN_WIDTH) % DEFAULT_NODE_ROW_WIDTH) + DEFAULT_GRAPH_MARGIN
  );
}

function getYPosition(maxDefinedY: number, undefinedCount: number, y?: number) {
  if (y !== undefined) {
    return y;
  }
  // try determine a sane (or at least, not too insane) default position
  return (
    Math.floor((undefinedCount * DEFAULT_NODE_COLUMN_WIDTH) / DEFAULT_NODE_ROW_WIDTH) *
      DEFAULT_NODE_ROW_HEIGHT +
    DEFAULT_GRAPH_MARGIN +
    maxDefinedY
  );
}

export default function Graph(): JSX.Element {
  const { data } = useLegacyAPI<GraphData>('statusEngine/graph', {
    method: 'get',
  });

  const [updateGraph, updatedGraph] = useLazyLegacyAPI<SaveResponse>('statusEngine/graph', {
    errorRender: ({ error }) => (
      <APIError className="mb-10" error={error} text="Unable to save changes to graph" />
    ),
    method: 'put',
  });

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();

  const onSave = useCallback(async () => {
    if (rfInstance) {
      const rfObject = rfInstance.toObject();
      const nodes = rfObject.nodes.map(node => ({
        categoryKey: 'part',
        statusKey: node.id,
        x: node.position.x,
        y: node.position.y,
      }));
      await updateGraph({ data: nodes });
    }
  }, [rfInstance, updateGraph]);

  useEffect(() => {
    if (data) {
      // small hack because we only have 1 category right now and I haven't decided how it should look with multiple categories
      const category = data.categories[0];
      const maxDefinedY = Math.max(...category.nodes.map(n => n.y || 0));
      const statusKeys = category.nodes.map(n => n.statusKey);

      let undefinedCount = -1;
      setNodes(
        category.nodes.map(node => {
          if (node.x === undefined || node.y === undefined) {
            undefinedCount += 1;
          }
          return {
            id: node.statusKey,
            position: {
              x: getXPosition(undefinedCount, node.x),
              y: getYPosition(maxDefinedY, undefinedCount, node.y),
            },
            data: {
              label: <NodeLabel data={node} color={getColor(statusKeys, node.statusKey)} />,
            },
            targetPosition: Position.Left,
            sourcePosition: Position.Right,
            className: 'w-min whitespace-nowrap',
          };
        })
      );

      setEdges(
        data.categories[0].edges.map(edge => ({
          id: `${edge.startStatusKey}-${edge.endStatusKey}`,
          source: edge.startStatusKey,
          target: edge.endStatusKey,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: getColor(statusKeys, edge.endStatusKey),
          },
          label: edge.effects.join(', '),
          style: {
            stroke: getColor(statusKeys, edge.endStatusKey),
            strokeWidth: 2,
          },
        }))
      );
    }
  }, [data, setNodes, setEdges]);

  return (
    <div className="h-screen">
      {updatedGraph?.error}
      <ReactFlowProvider>
        <ReactFlow
          defaultNodes={nodes}
          defaultEdges={edges}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onInit={setRfInstance}
        >
          <div className="absolute right-4 top-4 z-10">
            <Button onClick={onSave}>Save</Button>
          </div>
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
}
