import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Loader } from '@/components';
import { useLegacySelector } from '@/hooks';

type RouteDetails = {
  body: () => JSX.Element;
  layout?: ({ children }: { children: JSX.Element }) => JSX.Element;
  nav?: () => JSX.Element;
};

type RoleRoutes = Record<string, RouteDetails>;

type AllLoadedRoutes = Record<string, RoleRoutes>;

export default function Routes(): JSX.Element {
  const router = useLegacySelector(state => state.router);
  const path = router.location?.pathname || '';
  const me = useLegacySelector(state => state.me);
  const roles = _.keyBy(me.roles);

  const role = useMemo(() => {
    if (roles.customersupport) {
      return 'support';
    } else if (roles.serviceprovider) {
      return 'pro';
    } else if (roles.user) {
      return 'user';
    }
    return 'public';
  }, [roles]);

  const [active, setActive] = useState<RouteDetails>();
  const [routes, setRoutes] = useState<AllLoadedRoutes>({});

  const loadRoutes = useCallback(async () => {
    const newRoutes: AllLoadedRoutes = {};
    if (!newRoutes[role]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      newRoutes[role] = (await import(`./${role}`)).default as RoleRoutes;
    }
    setRoutes(prevRoutes => ({ ...prevRoutes, ...newRoutes }));
  }, [role]);

  const loadActiveRoute = useCallback(() => {
    const newPath = path
      .replace(/[a-f\d]{24}/g, 'id')
      .replace(/tasks\/[a-zA-Z0-9]{6}$/, 'tasks/id')
      .toLowerCase()
      .split('/')
      .filter(v => v);
    let activePath = '';
    for (let i = 0; i < newPath.length; i++) {
      const pathString = _.reduce(newPath.slice(0, i + 1), (a, b) => `${a}/${b}`) || '';
      if (routes[role][pathString]) {
        activePath = pathString;
      }
    }
    const active = routes[role][activePath] || routes[role][''];
    setActive(active);
  }, [path, routes, role]);

  useEffect(() => {
    if (_.isEmpty(routes[role])) {
      void loadRoutes();
    }
  }, [loadRoutes, role, routes]);

  useEffect(() => {
    if (!_.isEmpty(routes[role])) {
      loadActiveRoute();
    }
  }, [loadActiveRoute, role, routes]);

  if (!active) {
    return <Loader />;
  }
  const Body = active.body;
  const Layout = active.layout;
  const Nav = active.nav;
  if (Layout) {
    return (
      <Layout>
        <Body />
      </Layout>
    );
  }
  if (Nav) {
    return (
      <div>
        <Nav />
        <Body />
      </div>
    );
  }
  return <Body />;
}
