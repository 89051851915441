import * as Table from '@nanaio/table';
import _ from 'lodash';
import updateSort from './updateSort';
import { getShouldPatchRows, updateOperations } from './util';

type Props = {
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  id: string;
  query: Table.Query;
  value: unknown;
};

export default function handleQueryChange({
  databaseIdToTableIdToColumnIdToColumn,
  id,
  query: oldQuery,
  value,
}: Props): { query: Table.Query; shouldPatchRows?: boolean } {
  const newQuery = _.cloneDeep(oldQuery);
  _.set(newQuery, id, value);

  const vectorIndexString = id && id.match(/analytics\.vectors\.(\d+)\./)?.[1];
  if (!id.includes('vectors') || !vectorIndexString) {
    return { query: newQuery };
  }
  const vectorIndex = Number(vectorIndexString);

  const directionToVectors: Record<Table.Direction, Table.Vector[]> = _.groupBy(
    newQuery.analytics.vectors,
    'direction'
  ) as unknown as Record<Table.Direction, Table.Vector[]>;
  const isModuleChange = _.endsWith(id, 'moduleId') && !id.includes('filters');
  const oldVector = oldQuery.analytics.vectors[vectorIndex];
  const newVector = newQuery.analytics.vectors[vectorIndex];
  const newModule = newVector.moduleId
    ? databaseIdToTableIdToColumnIdToColumn[newQuery.databaseId][newQuery.table][newVector.moduleId]
    : undefined;
  const defaultOperation = newModule ? newModule.defaultOperation : undefined;

  // update duration
  for (const vector of newQuery.analytics.vectors) {
    const module =
      databaseIdToTableIdToColumnIdToColumn[newQuery.databaseId][newQuery.table][
        String(vector.moduleId)
      ];
    if (vector.moduleId && module?.type === Table.ColumnType.TIME && !vector.duration) {
      vector.duration = module?.defaultDuration || Table.defaultDuration;
    }
  }

  // remove filters
  newVector.filters = newVector.filters.filter(filter => filter.moduleId);

  // create partial formula
  if (_.endsWith(id, 'formula.1') && !newVector.formula[0] && newVector.moduleId) {
    newVector.formula[0] = newVector.moduleId;
  }

  // create formula
  if (_.endsWith(id, 'formula.2')) {
    if (!newVector.operations.length) {
      newVector.operations = [defaultOperation || Table.Operation.SUM];
    }
  }

  // delete formula
  if (isModuleChange && !_.startsWith(value as string, 'formula')) {
    newVector.formula = [];
  }

  updateOperations({
    databaseIdToTableIdToColumnIdToColumn,
    defaultOperation,
    id,
    newVector,
    oldVector,
    query: newQuery,
  });

  // module changed
  const vectorPath = id
    .replace('.intervalLength', '')
    .replace('.moduleId', '')
    .replace('.operations', '');
  if (isModuleChange) {
    const newVector = _.get(newQuery, vectorPath) as Table.Vector;
    const newModule = newVector.moduleId
      ? databaseIdToTableIdToColumnIdToColumn[newQuery.databaseId][newQuery.table][
          newVector.moduleId
        ]
      : undefined;
    if (newModule?.defaultTimeModuleId) {
      newVector.timeModuleId = newModule.defaultTimeModuleId;
    }
  }

  updateSort({
    databaseIdToTableIdToColumnIdToColumn,
    directionToVectors,
    id,
    isModuleChange,
    newQuery,
    oldQuery,
    vectorPath,
  });

  const { modules, shouldPatchRows } = getShouldPatchRows({ newQuery, oldQuery });
  newQuery.columns = modules;

  return { query: newQuery, shouldPatchRows };
}
