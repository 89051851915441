import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';
import { Paper } from '@/components';

type Props = {
  code: string;
};

export default function ReferCard({ code }: Props): JSX.Element {
  const [copied, setCopied] = useState(false);

  return (
    <Paper className="user-referral-card mx-4 mb-4 p-3" variant="elevated">
      <div className="block">
        <div className="user-card-left pr-4">
          <h6 className="mb-2 font-semibold">Give $10, Get $10</h6>
          <small className="mb-2 block" style={{ lineHeight: 1.5, color: '#5F5F5F' }}>
            Share your code with friends and get $10 in credit*
          </small>
        </div>
        <div className="user-card-right mb-4">
          <small
            style={{
              fontSize: 10,
              textTransform: 'uppercase',
              lineHeight: '1em',
              color: '#5F5F5F',
              fontWeight: '600',
              letterSpacing: '0.01ch',
            }}
          >
            Referral link
          </small>
          <div className="referral-input flex items-center justify-between">
            <p className="flex-1">{code}</p>
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
              <button
                className="button-dark sm text-center font-semibold"
                style={{ fontSize: 12, padding: '0.3em 1em' }}
              >
                {copied ? 'Copied' : 'Copy'}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <small
        className="text-muted disclaimer block"
        style={{ fontSize: 10, color: '#8898aa', lineHeight: 1.5 }}
      >
        * Restrictions apply. Referral credit only redeemable when person(s) referred books and
        completes their first booking.
      </small>
    </Paper>
  );
}
