import React from 'react';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import Group, { onChange } from './group';

export default class Phone extends React.Component {
  static contextTypes = { t: T.object.isRequired };

  static propTypes = {
    disabled: T.bool,
    id: T.string.isRequired,
    label: T.string,
    onChange: T.func,
    req: T.bool,
  };

  constructor(p, c) {
    super(p);
    this.state = { value: _.get(c.t.state, p.id) || '' };
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(p) {
    this.setState({ value: _.get(this.context.t.state, p.id) });
  }

  onBlur(e) {
    const value = U.trimPhone(e.target.value);
    if (value.length === 10) {
      onChange(this.context.t, this.props.id, value);
      if (this.props.onChange) {
        onChange(this.context.t, this.props.id, value);
      }
      if (this.state.error) {
        this.setState({ error: false });
      }
    } else if (value.length) {
      this.setState({ error: 'Valid number required' });
    } else if (!e.target.value.length) {
      onChange(this.context.t, this.props.id, '');
      if (this.props.onChange) {
        onChange(this.context.t, this.props.id, '');
      }
    }
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <Group {...this.props}>
        <input
          className="form-control"
          id={this.props.id}
          value={this.state.value}
          onBlur={this.onBlur}
          onChange={this.onChange}
          disabled={this.props.disabled}
        />
        <div className="text-danger">{this.state.error}</div>
      </Group>
    );
  }
}
