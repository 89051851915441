import React, { CSSProperties } from 'react';
import { MultiGrid } from 'react-virtualized';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import m from 'moment-timezone';
import { Text } from '../../core';
import { PartialReportTable } from './types';

const COLUMN_WIDTH = 100;
const ROW_HEIGHT = 30;
const ROW_WITH_POINTS_HEIGHT = 50;

type Props = {
  columnHeaderDepth: number;
  databaseIdToTableIdToColumnIdToColumn: Table.Depth3<Table.Column>;
  query: Table.Query;
  renderCell: (props: {
    columnIndex: number;
    rowIndex: number;
    style: CSSProperties;
  }) => JSX.Element | null;
  report?: Table.Report;
  reportTable: PartialReportTable;
  rowHeaderDepth: number;
  rows: Table.Cell[][];
  showPoints: boolean;
  table: React.LegacyRef<MultiGrid> | undefined;
};

export default function ReportTable({
  columnHeaderDepth,
  databaseIdToTableIdToColumnIdToColumn,
  query,
  renderCell,
  report,
  reportTable,
  rowHeaderDepth,
  rows,
  showPoints,
  table,
}: Props): JSX.Element | null {
  if (!rows?.length) {
    return null;
  }

  const timeModuleName = report?.timeModuleKey
    ? databaseIdToTableIdToColumnIdToColumn[query.databaseId][query.table][report.timeModuleKey]
        ?.name
    : undefined;

  return (
    <div className="mb-4">
      {reportTable.timeRange && (
        <Text>
          {timeModuleName && `${timeModuleName}: `}
          {m(reportTable?.timeRange?.start).format('M/D/YY')} -{' '}
          {m(reportTable?.timeRange?.end).format('M/D/YY')}
        </Text>
      )}
      <MultiGrid
        cellRenderer={cell =>
          renderCell(cell as { columnIndex: number; rowIndex: number; style: CSSProperties })
        }
        columnCount={rows[0].length}
        columnWidth={COLUMN_WIDTH}
        fixedColumnCount={rowHeaderDepth + 1}
        fixedRowCount={columnHeaderDepth + 1}
        height={rows.length * (showPoints ? ROW_WITH_POINTS_HEIGHT : ROW_HEIGHT)}
        ref={table}
        rowCount={rows.length}
        rowHeight={showPoints ? ROW_WITH_POINTS_HEIGHT : ROW_HEIGHT}
        width={rows[0]?.length * COLUMN_WIDTH}
      />
    </div>
  );
}
