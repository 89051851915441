// eslint-disable-next-line max-classes-per-file
import React from 'react';
import onEnter from 'react-onenterkeydown';
import U from '@nanaio/util';
import _ from 'lodash';
import { Icon } from '@/components';
import { login, signup, updateUser } from '../../../com/user';
import { companyName, emailRe } from '../../../config/const';

const EnhancedInput = onEnter('input');
const color = {
  bg: '#f7fafc',
  bold: '#2E3B48',
  muted: '#9FA4A8',
  border: '#e3e8ee',
  blue: '#1A6BC6',
  green: '#40D78F',
  orange: '#FD9926',
  red: '#E90F09',
  darkGreen: '#24b47e',
  purple: '#8E69EC',
  lightblue: '#0DB1ED',
  pink: '#E84F89',
};

// eslint-disable-next-line react/prefer-stateless-function
class EditBtn extends React.Component {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        className={`rounded-btn + ${this.props.className}`}
        aria-label={this.props.text}
      >
        <Icon
          name={this.props.icon ? this.props.icon : 'edit'}
          size={15}
          color={this.props.color ? this.props.color : color.muted}
        />
      </button>
    );
  }
}

export default class Step3 extends React.Component {
  state = _.pick(this.props.getStore(), ['name', 'email', 'phone', 'password']);

  handleChange = (e, { value }) => this.setState({ value });

  constructor(p) {
    super(p);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  onChange(id, e) {
    if (id === 'agree') {
      this.setState({ agree: !this.state.agree });
    } else {
      this.setState({ [id]: e.target.value });
    }
  }

  async onSubmit() {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });
    const store = this.props.getStore();
    const { name, password, agree } = this.state;
    const email = this.state.email || store.email;
    const phone = this.state.phone || store.phone;
    const firstName = (name || '').split(' ')[0];
    const lastName = (name || '').split(' ')[1];
    if (!name) {
      return this.setState({ error: 'Name required', loading: false });
    }
    if (!email) {
      return this.setState({ error: 'Email required', loading: false });
    }
    if (!emailRe.test(email)) {
      return this.setState({ error: 'Invalid email', loading: false });
    }
    if (!phone) {
      return this.setState({ error: 'Phone required', loading: false });
    }
    if (phone.replace(/\D/g, '').length !== 10) {
      return this.setState({ error: '10-digit phone required', loading: false });
    }
    if (!password) {
      return this.setState({ error: 'Password required', loading: false });
    }
    if (password.length < 8) {
      return this.setState({ error: '8-character password required', loading: false });
    }
    if (!/[A-Z]/.test(password)) {
      return this.setState({ error: 'Password must contain uppercase', loading: false });
    }
    if (!/[a-z]/.test(password)) {
      return this.setState({ error: 'Password must contain lowercase', loading: false });
    }
    if (!/[0-9]/.test(password)) {
      return this.setState({ error: 'Password must contain number', loading: false });
    }
    if (!agree) {
      return this.setState({ error: 'Agree required', loading: false });
    }
    if (store.flow === 'create password') {
      const store = this.props.getStore();
      const body = {
        identifier: store.email || store.phone,
        token: store.token,
        newPassword: password,
      };
      const r = await U.api('put', 'users/passwordreset', body);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg, loading: false });
      }
      const r2 = await login({
        email: store.email,
        phone: store.phone,
        password,
        isResettingPassword: true,
      });
      if (r2.errMsg) {
        return this.setState({ error: r.errMsg, loading: false });
      }
      const newConfirmedContacts = [];
      const changes = [{ action: 'replace', path: 'contactConfirmed', value: true }];
      if (email !== r2.user.email) {
        changes.push({ action: 'replace', path: 'email', value: email });
        newConfirmedContacts.push(email);
      }
      const firstName = name.split(' ')[0];
      if (firstName !== r2.user.profile.firstName) {
        changes.push({ action: 'replace', path: 'profile.firstName', value: firstName });
      }
      const lastName = name.slice(firstName.length + 1);
      if (lastName !== r2.user.profile.lastName) {
        changes.push({ action: 'replace', path: 'profile.lastName', value: lastName });
      }
      if (name !== r2.user.profile.fullName) {
        changes.push({ action: 'replace', path: 'profile.fullName', value: name });
      }
      if (phone !== r2.user.profile.phone) {
        changes.push({ action: 'replace', path: 'profile.phone', value: phone });
        newConfirmedContacts.push(phone);
      }
      if (newConfirmedContacts.length) {
        const confirmedContacts = _.uniq(
          newConfirmedContacts.concat(r2.user.profile.confirmedContacts)
        );
        changes.push({
          action: 'replace',
          path: 'profile.confirmedContacts',
          value: confirmedContacts,
        });
      }
      if (changes.length) {
        const r3 = await updateUser(r2.user.id, changes);
        if (r3.errMsg) {
          return this.setState({ error: r.errMsg, loading: false });
        }
      }
      this.props.jumpToStep(7);
    } else {
      const body = { firstName, lastName, email, password, phone };
      const r = await signup(body);
      if (r.errMsg) {
        return this.setState({ error: r.errMsg, loading: false });
      }
      this.props.jumpToStep(7);
    }
  }

  goBack() {
    const { stepHistory } = this.props.getStore();
    this.props.jumpToStep(stepHistory[stepHistory.length - 2]);
  }

  render() {
    const { email, phone } = this.props.getStore();
    return (
      <section className="login--page">
        <div className="login--container">
          <div className="login--block">
            <h3 className="weight-500">Create Profile</h3>
            <p className="text-muted">Complete your {companyName} Home profile</p>

            <div className="login--form">
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Name"
                  autoFocus
                  value={this.state.name}
                  onChange={e => this.onChange('name', e)}
                />
                {phone && (
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Email address"
                      value={this.state.email}
                      onChange={e => this.onChange('email', e)}
                    />
                  </div>
                )}
                {email && (
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Phone Number"
                      value={this.state.phone}
                      onChange={e => this.onChange('phone', e)}
                    />
                  </div>
                )}

                <div className="login--form--icon">
                  <EnhancedInput
                    value={this.state.password}
                    onChange={e => this.onChange('password', e)}
                    className="pass-input mt-2"
                    type={this.state.showPassword ? `text` : `password`}
                    placeholder="Create password (8 char min)"
                    autoFocus
                  />
                  <div className="form-icon right">
                    {this.state.showPassword && (
                      <EditBtn
                        tip="Hide Password"
                        icon="visibility_off"
                        onClick={this.toggleShowPassword}
                      />
                    )}
                    {!this.state.showPassword && (
                      <EditBtn
                        tip="Show Password"
                        icon="visibility"
                        onClick={this.toggleShowPassword}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <p className="d-flex align-center justify-content-center mt-3">
              {this.state.agree && (
                <EditBtn
                  icon="check_circle"
                  className="sm"
                  onClick={() => this.onChange('agree')}
                />
              )}
              {!this.state.agree && (
                <EditBtn
                  icon="radio_button_unchecked"
                  className="sm"
                  onClick={() => this.onChange('agree')}
                />
              )}

              <span style={{ marginLeft: '0.2em' }}>
                I accept the{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </a>
              </span>
            </p>
            <button onClick={this.onSubmit} className="primary mt-3">
              Create Account
            </button>
            {this.state.error && (
              <p className="fadeInAnim py-3" style={{ color: '#f75c43' }}>
                {this.state.error}
              </p>
            )}
            <p className="text-muted mt-3">
              or{' '}
              <span className="text-blue cursor-pointer" onClick={() => this.props.jumpToStep(0)}>
                go back
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
