import React from 'react';
import { connect } from 'react-redux';
import U from '@nanaio/util';
import _ from 'lodash';
import T from 'prop-types';
import { Icon } from '@/components';
import { Money, Search, Text } from '../../../com/ui/form';

class Authorization extends React.Component {
  static propTypes = {
    loadDuplicates: T.func.isRequired,
    setReferredBy: T.func.isRequired,
    state: T.object.isRequired,
  };

  constructor(p) {
    super(p);
    this.onVendorBlur = this.onVendorBlur.bind(this);
    U.api('post', 'orgs/search', { limit: -1 }, true);
  }

  onVendorBlur() {
    if (!_.get(this.props.state, 'profile.referredBy')) {
      this.props.setReferredBy('Home Warranty');
    }
  }

  render() {
    return (
      <div className="info">
        <div className="card-head">
          <h5 className="title flex items-center">
            <div className="icon flex items-center justify-center">
              <Icon name="home" size={16} />
            </div>
            Authorization
          </h5>
        </div>
        <div className="card-form">
          <Search
            id="customer.org.id"
            label="Business"
            options={this.props.orgs}
            onChange={this.onVendorBlur}
          />
          {_.get(this.props.state, 'customer.org.id') && (
            <div className="row">
              <Money id="vendor.authLimit" className="col-sm" />
              <Money id="vendor.coPay" className="col-sm" />
              <Text
                id="vendor.workOrderNumber"
                className="col-sm"
                onBlur={this.props.loadDuplicates}
              />
            </div>
          )}
          <div className="row">
            <Text hint="full name" id="landlord.name" label="Landlord Name" className="col-sm-4" />
            <Text hint="(  )  -" id="landlord.phone" label="Landlord Phone" className="col-sm-4" />
            <Text
              hint="email address"
              id="landlord.email"
              label="Landlord Email"
              className="col-sm-4"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => {
  let orgs = _.values(s.orgs)
    .filter(v => v.phone && v.email)
    .map(v => ({ ...v, name: `${v.name} (${_.get(v, 'status', 'active')})` }));
  orgs = _.sortBy(orgs, 'name');
  return { orgs };
})(Authorization);
