import React from 'react';
import { T, Task, U } from '@nanaio/util';
import _ from 'lodash';
import mt from 'moment-timezone';
import { Text } from '@/components';

type Props = {
  task: Task;
};
export default function StatusAndReason({ task }: Props): JSX.Element {
  const timezone = U.timezone(task.serviceAddress);

  if (!task.statusChanges || _.isEmpty(task.statusChanges)) {
    return <></>;
  }

  const lastTaskStatus = task.statusChanges[task.statusChanges.length - 1];

  return (
    <>
      <Text className="mt-8 text-secondary-5" type="label">
        Work Order Status:
      </Text>
      <Text className="mt-2">
        {_.startCase(lastTaskStatus.status)} on{' '}
        {mt(lastTaskStatus.startTime).tz(timezone).format('M/D/YY, h:mm A (z)')}
      </Text>
      {task.metadata.cxCloseReason && (
        <div className="mt-8">
          <Text className="text-secondary-5" type="label">
            Reason:
          </Text>
          <Text className="mt-2">
            {T.closeReasons.find(reason => reason.id === task.metadata.cxCloseReason)?.name}
            {task.metadata.cxCloseFeedback && ` - ${task.metadata.cxCloseFeedback}`}
          </Text>
        </div>
      )}
    </>
  );
}
