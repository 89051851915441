import React, { useMemo } from 'react';
import { T } from '@nanaio/util';
import _ from 'lodash';
import ApplianceBrandStep from './ApplianceBrandStep';
import ApplianceModelStep from './ApplianceModelStep';
import type { StepProps as Props } from './ConfirmationFlowModal';
import StepModalHeader from './StepModalHeader';

export default function ApplianceStep({
  currentStep,
  task,
  subStepNum,
  onCloseStep,
  onNextSubStep,
  onPreviousSubStep,
}: Props): JSX.Element {
  const applianceName = T.appliance(task);
  const title = `Verify ${applianceName} Details`;

  const isChangeMakeAllowed = !(task.customer.org?.type === 'OEM');

  const componentBySubStepNum = useMemo(() => {
    const steps = [];
    if (isChangeMakeAllowed) {
      steps.push(ApplianceBrandStep);
    }
    steps.push(ApplianceModelStep);

    return steps.reduce(
      (prev, curr, index) => {
        prev[String(index + 1)] = curr;
        return prev;
      },
      {} as Record<string, (typeof steps)[number]>
    );
  }, [isChangeMakeAllowed]);

  const Component =
    componentBySubStepNum[subStepNum as unknown as keyof typeof componentBySubStepNum];

  return (
    <>
      <StepModalHeader {...{ onPreviousSubStep, title, subStepNum, onCloseStep, currentStep }} />
      <Component {...{ onNextSubStep, task }} />
    </>
  );
}
