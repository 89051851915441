import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { T } from '@nanaio/util';
import _ from 'lodash';
import Rodal from 'rodal';
import { workOrderEvent } from '@/com/analytics';
import { Badge, Icon } from '@/components';
import { updateTask } from '../../../com/task';
import { initStripe,loadUser } from '../../../com/user';

class EditCard extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.addCard = this.addCard.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidUpdate(p, s) {
    if (this.state.isOpen && !s.isOpen) {
      loadUser(this.props.me.userId);
      setTimeout(() => {
        const isTest = _.values(this.props.job.tags).includes('test');
        initStripe(isTest ? 'test' : 'default');
      }, 500);
    }
  }

  toggleModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async handleChange(value) {
    this.setState({ value });
    const changes = [{ action: 'replace', path: 'payment', value: { stripeCardId: value } }];
    const r = await updateTask(global.id(), changes);
    if (r.errMsg) {
      return this.setState({ changeCardError: r.errMsg });
    }
    this.toggleModal();
  }

  async addCard() {
    const eventInfo = { error: '' };
    const r = await global.stripe.createToken(global.card);
    if (r.error) {
      eventInfo.error = r.error.message;
      this.setState({ error: r.error.message });
    } else {
      this.setState({ error: '' });
      const changes = [{ action: 'replace', path: 'payment', value: { stripeToken: r.token.id } }];
      const r0 = await updateTask(global.id(), changes);
      if (r0.errMsg) {
        this.setState({ addCardError: r0.errMsg });
      } else {
        this.toggleModal();
      }
    }
    workOrderEvent().track('v1_workOrderTaskCardAdded', eventInfo);
  }

  render() {
    const { job } = this.props;
    return (
      <div>
        <button
          className="payment-card d-flex justify-content-between"
          onClick={this.toggleModal}
          style={{
            outline: 'none',
            background: 'white',
            width: '100%',
            border: 'none',
            borderBottom: '1px solid #e4e4e4',
          }}
        >
          <p className="weight-600 flex-1 text-left">Payment Method</p>
          <p className="weight-600 text-blue">
            {T.hasCard(job) ? `•••• ${job.payment.card.last4}` : 'No Card'}
          </p>
          <Icon className="ml-2" name="chevron_right" color="#3297d3" size={25} />
        </button>
        <Rodal
          closeMaskOnClick={false}
          visible={this.state.isOpen}
          onClose={this.toggleModal}
          showMask
          className="rodal_modal"
        >
          <div className="rodal-body">
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <div className="rodal-title lh-2 text-center">Payment Method</div>
            <div className="rodal-h4 text-muted mb-3 text-center">
              Add or edit your payment method
            </div>
            <div className="text-danger" id="card-errors">
              {this.state.changeCardError}
            </div>
            <div className="box-select-radios">
              {this.props.cards.map((c, n) => (
                <div className="box-select mb-2" key={n} onClick={() => this.handleChange(c.id)}>
                  <img alt="" src="/img/card-svg.svg" height="20" className="mr-3" />
                  <p className="weight-600 flex-1">
                    {c.brand} &bull;&bull;&bull;&bull; {c.last4}
                  </p>
                  {c.id === job.payment.card.id && <Badge color="primary">Selected</Badge>}
                </div>
              ))}
            </div>
            <br />

            <div className="card-box--shadow">
              <p className="weight-600 mb-3">Add Payment Method</p>
              <div className="text-danger">{this.state.addCardError}</div>
              {this.state.isOpen && <div id="card-element" />}
              <button
                onClick={this.addCard}
                className="button-dark w-100 d-block d-flex justify-content-center align-center mb-2 mt-3 text-center"
              >
                Add Card
              </button>
            </div>
          </div>
        </Rodal>
      </div>
    );
  }
}

export default connect(s => {
  const job = s.tasks[global.id()];
  const { me } = s;
  const cards = _.get(s.users[me.userId], 'payment.stripe.cards', []);
  return { job, me, cards };
})(EditCard);
