import React, { CSSProperties } from 'react';
import * as Table from '@nanaio/table';
import _ from 'lodash';
import { Button, Icon, Text, Tooltip } from '../../core';
import { checkColumnWidth, fixedColumnCount } from '../util';

type Props = {
  columnIndex: number;
  onSort: (nonFixedColumnIndex: number) => void;
  openEditQueryPopup: () => void;
  query: Table.Query;
  style: CSSProperties;
};

export default function Header({
  columnIndex,
  onSort,
  openEditQueryPopup,
  query,
  style,
}: Props): JSX.Element {
  const nonFixedColumnIndex = columnIndex - fixedColumnCount;
  const module = query.columns[nonFixedColumnIndex];
  const newStyle = { ...style, width: columnIndex ? module?.width : checkColumnWidth };

  // check header
  if (!columnIndex) {
    return (
      <div className="align-center flex justify-center border border-grey-medium" style={newStyle}>
        <Button cypressId="edit-columns" onClick={openEditQueryPopup} size="medium">
          <Icon color="white" name="mode_edit" />
        </Button>
      </div>
    );
  }

  const { sort } = query;
  const sortState = `th-btn ${
    sort.key === module.key && sort.isDescending ? 'active down' : 'active up'
  }${sort.key === module.key ? ' active' : ' inactive'}`;
  const isActive = module.filterIsValid
    ? module.filterIsValid(module?.search?.value)
    : Boolean(module?.search?.value);

  return (
    <div
      className={`group flex cursor-pointer justify-between border border-grey-medium p-1 ${
        isActive ? 'active' : ''
      }`}
      data-cy={`header-${module.key}`}
      onClick={() => onSort(nonFixedColumnIndex)}
      style={newStyle}
    >
      {!module.hideHeader && (
        <Text style={{ fontSize: module.name?.length >= 18 ? 14 : 16 }}>{module.name}</Text>
      )}
      <div className={sortState}>
        {module.description && (
          <Tooltip
            node={module.description}
            placement="right"
            trigger={<Icon color="grey.dark" name="info" />}
          />
        )}
        <Icon
          className={`${sort.key === module.key ? '' : 'hidden'} group-hover:inherit`}
          color={sort.key === module.key ? 'black' : 'grey.dark'}
          name={sort.isDescending ? 'menu_down' : 'menu_up'}
          size={30}
        />
      </div>
    </div>
  );
}
