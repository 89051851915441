import React, { useState } from 'react';
import { Task, U, User } from '@nanaio/util';
import { APIError, FormControl, Modal, Text, TextInput } from '@/components';
import { useLazyLegacyAPI, useLegacySelector } from '@/hooks';

type Props = {
  onClose: () => void;
  taskId: string;
};

export default function ChangeCustomer({ onClose, taskId }: Props): JSX.Element {
  const tasks = useLegacySelector(state => state.tasks);
  const task = tasks[taskId];

  const [email, setEmail] = useState(task.customer.user.email);
  const [error, setError] = useState('');

  const [updateTask, taskToUpdate] = useLazyLegacyAPI<Task>(`tasks/${task.id}`, {
    errorRender: ({ error }) => <APIError className="mb-4" error={error} />,
    save: true,
    method: 'put',
  });

  const submit = async () => {
    setError('');
    if (!email) {
      return setError('Email required');
    }
    const query = { email: email };
    const userResponse = await U.api<User[]>('post', 'users/search', { query });
    if (!userResponse || !userResponse.length || 'errMsg' in userResponse) {
      return setError('User not found');
    }
    const changes = [{ action: 'replace', path: 'customer.userId', value: userResponse[0]._id }];
    const taskUpdateResponse = await updateTask(changes);
    if (!taskUpdateResponse || 'errMsg' in taskUpdateResponse) {
      return;
    }
    onClose();
  };

  return (
    <Modal isOpen onSave={submit} onClose={onClose}>
      <Modal.Header title="Change Customer" />
      <Modal.Body className="p-4">
        <Text className="mb-4" color="danger" type="button">
          {error}
        </Text>
        {taskToUpdate.error}
        <Text>Enter the email address of the correct customer</Text>
        <FormControl className="mt-4" label="Email" required>
          <TextInput value={email} onChange={value => setEmail(value as string)} />
        </FormControl>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
