import React, { useMemo } from 'react';
import U from '@nanaio/util';
import classNames from 'classnames';
import _ from 'lodash';
import Badge, { kebabColors, Props as BadgeProps } from './Badge';
import Icon, { Props as IconProps } from './Icon';
import Text, { TextTypes } from './Text';

export const badgePositionByVariant = {
  bottomLeft: 'left-0 -bottom-3.5',
  bottomRight: 'right-0 -bottom-3.5',
  topLeft: 'left-0 -top-2',
  topRight: ' right-0 -top-1.5',
};

export const badgeTranslateByVariant = {
  bottomLeft: '-translate-x-1/2',
  bottomRight: 'translate-x-1/2',
  topLeft: '-translate-x-1/2',
  topRight: 'translate-x-1/2',
};

export const colors = [
  'bg-accent-purple',
  'bg-danger',
  'bg-yellow-100',
  'bg-success',
  'bg-primaryCTA',
];

const sizeToString = (size: number): keyof typeof optionsBySize => {
  if (size > 32) {
    return 'small';
  }
  if (size > 48) {
    return 'medium';
  }
  if (size > 64) {
    return 'large';
  }
  if (size > 96) {
    return 'xlarge';
  }
  return '2xlarge';
};
type Sizes = 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge';

type AvatarOptions = {
  [K in Sizes]: {
    iconSize: number;
    size: number;
    textType: TextTypes;
    ringSize: number;
  };
};

export const optionsBySize: AvatarOptions = {
  small: { iconSize: 11, size: 24, textType: 'helper', ringSize: 1.5 },
  medium: { iconSize: 15, size: 32, textType: 'button', ringSize: 1.5 },
  large: { iconSize: 22, size: 48, textType: 'page-title', ringSize: 2 },
  xlarge: { iconSize: 22, size: 64, textType: 'page-title', ringSize: 3 },
  '2xlarge': { iconSize: 30, size: 96, textType: 'headline-5', ringSize: 3 },
};

export type Props = {
  badgeProps?: Omit<BadgeProps, 'float'>;
  badgePosition?: keyof typeof badgePositionByVariant;
  className?: string;
  iconProps?: Omit<IconProps, 'size'>;
  name?: string;
  onClick?: () => void;
  pictureUrl?: string;
  ringColor?: (typeof kebabColors)[number];
  size?: Sizes | number;
  style?: React.CSSProperties;
  variant?: 'circle' | 'rounded';
};
/*
 * This component shows either the picture from the pictureUrl prop,
 * the passed icon component, or initials derived from the name prop,
 * in that order.
 */
export default function Avatar({
  badgeProps,
  badgePosition = 'bottomRight',
  className = '',
  iconProps,
  name,
  onClick,
  pictureUrl,
  ringColor,
  size = 'xlarge',
  style = {},
  variant = 'rounded',
}: Props): JSX.Element {
  const background = useMemo(() => {
    if (!_.includes(className, 'bg-')) {
      if (name) {
        return colors[U.hashCode(name) % colors.length];
      }
      return colors[0];
    }
    return '';
  }, [className, name]);

  const initials = useMemo(() => {
    if (pictureUrl || iconProps) {
      return '';
    }
    if (name) {
      const parts = _.trim(name).toUpperCase().split(' ');
      const firstInitial = parts[0][0] || '';
      const lastNameIndex = parts.length;
      const lastInitial = lastNameIndex > 1 ? parts[lastNameIndex - 1][0] : '';
      return `${firstInitial}${lastInitial}`;
    }
    return '';
  }, [iconProps, name, pictureUrl]);

  const styleSize = typeof size === 'string' ? optionsBySize[size].size : size;
  size = typeof size === 'number' ? sizeToString(size) : size;

  style = { ...style, height: styleSize, width: styleSize };

  const hasRing = !!ringColor;
  let ringSize = typeof size === 'string' ? optionsBySize[size].ringSize : 3;
  if (!hasRing) {
    ringSize = 0;
  }

  // These string fix an issue where storybook can't handle dynamic ringSizes i.e. ring-[${ringSize}px
  let ringString = `ring-[3px]`;
  let borderString = `border-[3px]`;
  if (ringSize === 1.5) {
    ringString = `ring-[1.5px]`;
    borderString = `border-[1.5px]`;
  } else if (ringSize === 2) {
    ringString = `ring-[2px]`;
    borderString = `border-[2px]`;
  }

  const ringClass = hasRing
    ? `border-${ringColor} ${borderString} ring-inset ring-white ${ringString}`
    : '';

  if (pictureUrl) {
    style = {
      ...style,
      backgroundImage: `url(${pictureUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
    iconProps = undefined;
  }

  return (
    <div
      className={classNames(
        'align-items-center relative mr-5 grid justify-items-center',
        {
          'rounded-full': variant === 'circle',
          rounded: variant === 'rounded',
          'cursor-pointer': onClick,
          [ringClass]: hasRing,
        },
        background,
        className
      )}
      onClick={onClick}
      style={style}
    >
      {initials && (
        <Text
          color="white"
          type={optionsBySize[size].textType}
          weight={500}
          style={{ lineHeight: 'inherit' }}
        >
          {initials}
        </Text>
      )}
      {iconProps && <Icon {...iconProps} size={optionsBySize[size].iconSize} />}
      {badgeProps && (
        <div className={classNames('absolute', badgePositionByVariant[badgePosition])}>
          <div className={badgeTranslateByVariant[badgePosition]}>
            <Badge {...badgeProps} float />
          </div>
        </div>
      )}
    </div>
  );
}
